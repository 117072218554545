import React from "react";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
import EditEnterpriseProfile from "../pageComponents/EnterpriseDashboard/EnterpriseProfile/EditEnterpriseProfile.jsx";

const EnterprisePublicProfileEdit = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
     <div className="container-dash eprofile edash edash3 pb-5">
       <EditEnterpriseProfile />
     </div>
      <Footer />
    </>
  )
}

export default EnterprisePublicProfileEdit