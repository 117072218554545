import React from "react";
import "./style.css";

const Explore = () => {
  return (
    <>
      <div className="explore main-padding">
        <div className="container-lg px-0 pt-sm-5">
         <div className="row">
            <div className="col-12 explore__border">
            <div className="explore__content  pb-5">
            <h1 className="explore__content__heading section2__content__heading pt-sm-5 pt-4 pb-2">
              Explore more than 1000+ jobs
            </h1>
            <p className="explore__content__para fw-semibold section2__content__para">
              We’re a team of entrepreneurs from technology, consulting, and
              professional services. We have decades of experience in helping
              organizations to optimize their teams, helping people hire, and in
              designing digital solutions that make processes simple.
            </p>
            <p className="explore__content__para  section2__content__para">
              We’ve also spent a long time thinking about the Future of Work,
              about data and AI, and helping people work smarter in the future.
              Sadly we’ve also experienced bias, inequality, and unfairness,
              both personally and generally in the world of work.
            </p>
          </div>
            </div>
         </div>
        </div>
      </div>
    </>
  );
};

export default Explore;
