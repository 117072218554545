import React, { useState, useEffect } from "react";
import {HiArrowLeft} from "react-icons/hi2"


function TimeSeries({ start, interval }) {
    const [times, setTimes] = useState([]);
    const [activeTime, setActiveTime] = useState(null);
    useEffect(() => {
      const startTime = new Date(`1/1/2000 ${start}`);
      const endTime = new Date(`1/1/2000 11:59 PM`);
  
      const times = [];
      for (let time = startTime; time <= endTime; time.setTime(time.getTime() + interval * 60 * 1000)) {
        times.push(time.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }));
      }
      setTimes(times);
    }, [start, interval]);

    const handleTimeClick = (time) => {
        setActiveTime(time);
      };
  
    return (
      <div className="timeSchedule">
        {times.map((time, i) => (
          <div key={i} className="text-start py-2 px-3 fm timeSchedule--div mb-2 me-2" onClick={() => handleTimeClick(time)}
          style={{
            backgroundColor: activeTime === time ? "#12d3db" : "",
            color: activeTime === time ? "#fff" : "",
            borderColor: activeTime === time ? "#12d3db" : ""
          }}
          >{time}</div>
        ))}
      </div>
    );
  }

const MeetingTime = (props) => {
    const Profile = ()=>{
        props.confirm(false);
        props.profile(true)
        props.meeting(false)
        props.time(false)
        props.detail(false)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }
    const Details = ()=>{
        props.confirm(false);
        props.profile(false)
        props.meeting(false)
        props.time(false)
        props.detail(true)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }
    const Meeting  = ()=>
    {
        props.confirm(false);
        props.profile(false)
        props.meeting(true)
        props.time(false)
        props.detail(false)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  return (
    <div className="Dashboard__container edash">
      <div className="container-lg pb-4 pt-5 meeting__container d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column align-items-center text-center">
          <span className="meeting__container__step fm">
            Step <span className="fw-semibold">2/3</span>
          </span>
          <h5 className="fm darkGrey fw-light mt-2 fs-5 pb-3">Select your preferred <span className="fw-semibold">time for meeting</span></h5>
          <div className="d-flex justify-content-between w-100 ms-1 pb-2 mb-2 timeSchedule__top me-4">
            <small className="fm darkGrey pointer" onClick={Meeting}><HiArrowLeft className="me-2" />Back</small>
            <small className="fm midGrey">Friday, January 24 , 2023</small>
          </div>
          <TimeSeries start="1:00 AM" interval={30} />
        </div>

        <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-5">
              <button
                className={`Get__left__content__para draft__button border-darkGrey darkGrey mb-3 me-sm-2`}
                onClick={Profile}
              >
                Cancel
              </button>

              <button
                className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`} 
                onClick={Details}
                >
                Next
              </button>
            </div>
      </div>
    </div>
  );
};

export default MeetingTime;
