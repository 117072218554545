import React from "react";
import "./style.css";
import { FiArrowRight } from "react-icons/fi";

const values__data = [
  {
    text: "We value honesty.",
  },
  {
    text: "We are pragmatic.",
  },
  {
    text: "We love ambition.",
  },
  {
    text: "We challenge the status quo.",
  },
  {
    text: "We champion talent.",
  },
  {
    text: "We support growth.",
  },
];

const Values = () => {
  return (
    <>
      <div className="values main-padding">
        <div className="container-lg py-lg-5 py-3">
          <h1 className="values__header pt-md-4">Our values</h1>
          <p className="values__para mb-0">
            As a global, bold and ambitious team, we enjoy working with
            like-minded people.<span className="values__para fw-semibold"> We like to get stuff done.</span>
          </p>
          
          <div className="row h-100 pb-4 pt-2">
            {values__data.map((item) => (
              <div className="col-md-4 col-12 px-md-0">
                <div className="values__content d-flex justify-content-start align-items-center pt-4">
                  <div className="values__content__icon h-100 d-flex align-items-center">
                    <FiArrowRight className="values__content__icon__arrow" />
                  </div>
                  <h4 className="values__content__text mb-0 ps-2">
                    {item.text}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;
