import React, { useEffect, useState } from "react";
import {
  Avatar,
  Progress,
  Tabs,
  Dropdown,
  Menu,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  message,
} from "antd";
import { IoIosArrowRoundDown } from "react-icons/io";
import { HiOutlineTrash } from "react-icons/hi2";
import {
  CreateMemberrHandler,
  getMembersByCompany,
} from "../../../../services/auth";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import "./style.css";

const userList = [
  {
    id: 1,
    name: "Ben Smith",
    department: "Hr",
    role: "Accepted",
  },
  {
    id: 2,
    name: "Ben Smith",
    department: "Accounts",
    role: "Accepted",
  },
  {
    id: 3,
    name: "Ben Smith",
    department: "Software",
    role: "Accepted",
  },
  {
    id: 4,
    name: "Ben Smith",
    department: "Admin",
    role: "Pending",
  },
];

const SUsers = () => {
  const [editNameVisible, setEditNameVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const { Option } = Select;

  const EditNameModal = () => {
    setEditNameVisible(true);
  };
  const EditNamehandleOk = () => {
    setEditNameVisible(false); // Close the modal after submission
  };
  const EditNamehandleCancel = () => {
    setEditNameVisible(false); // Close the modal without saving changes
  };

  // add member form

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    department: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    department: "",
  });

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    // If `value` is an object representing the selected option, access its `value` property
    if (value && value.hasOwnProperty("value")) {
      value = value.value;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      department: "",
    };

    // Add your validation logic here
    if (formData.firstName.trim() === "") {
      isValid = false;
      errors.firstName = "First name is required";
    }

    if (formData.lastName.trim() === "") {
      isValid = false;
      errors.lastName = "Last name is required";
    }

    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      isValid = false;
      errors.email = "Invalid email address";
    }

    if (formData.role === "") {
      isValid = false;
      errors.role = "Role is required";
    }

    if (formData.department === "") {
      isValid = false;
      errors.department = "Department is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Add email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddButtonClick = async () => {
    if (validateForm()) {
      setLoading(true);
      const enterpriseId = localStorage.getItem("enterpriseID");
      const payload = {
        enterpriseID: enterpriseId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.email,
        role: formData.role,
        usertype: "member",
        department: formData.department,
      };

      const responseAddMember = await CreateMemberrHandler(payload);
      setLoading(false);
      setEditNameVisible(false);
      getTeamMembers();
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        department: "",
      });
    }
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    setMembersLoading(true);
    const enterpriseId = localStorage.getItem("enterpriseID");
    const payloadGetMembers = {
      enterpriseID: enterpriseId,
    };

    const responseTeamMembers = await getMembersByCompany(payloadGetMembers);

    if (responseTeamMembers.status == 200) {
      setMembers(responseTeamMembers.data.data);
      setMembersLoading(false);
    }
  };

  return (
    <>
      <style>
        {`
        .add-member-popup-form-buttons button {
          font-size: 16px;
          height: 40px;
      }
      
      .add-member-popup-form-buttons button.desc__applynow:hover {
          color: #b2da66;
          background: #fff;
          outline: none;
          border: 1px solid #b2da66;
      }
      
      .add-member-popup .ant-modal-title {
          font-size: 24px;
          font-weight: 600;
          color: #000;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 30px;
      }
      
      .add-member-popup .ant-modal-close svg {
          font-size: 16px;
      }
      .form-box label {
        width: 100%;
        font-size:16px;
        font-weight:600;
        }
        
        .form-box .ant-select {
            width: 100%;
            font-size:16px;
        }
        .form-box .ant-select .ant-select-arrow{
          display:block !important;
          color:#fff;
          font-size:16px;
        }
        .form-box input{
            height:46px;
        }
        .add-member-popup-form-buttons button {
          font-size: 16px;
          height: 40px;
      }
      
      .add-member-popup-form-buttons button.desc__applynow:hover {
          color: #b2da66;
          background: #fff;
          outline: none;
          border: 1px solid #b2da66;
      }
    `}
      </style>
      <div className="Suser pb-5 selectboxes">
        <div className="Dashboard__container pe-2">
          <div className="Dashboard__container__content">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-5 pe-lg-4 pe-0">
                  <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-lg-4">
                    Team Members
                  </p>
                  <p className="fm darkGrey mb-2 mt-0 text-14">
                    Our IndexScore is an effective measure of candidate’s
                    ability to succeed. To ensure a comprehensive approach, we
                    have built an integrated solution to allow you to
                    automatically invite colleagues to participate in the
                    interview process. It’s simple; just add potential
                    interviewers from various departments and assign them to a
                    role or number of roles during your role creation process.
                    Once the right candidate has been identified, these members
                    can then participate in an interview process. You can add
                    and delete members at anytime in the process. This is truly
                    future of work and candidate engagement.
                  </p>
                  <button
                    onClick={EditNameModal}
                    className={`fm invite-button mt-2 text-14 jobd__btn `}
                  >
                    Add Member
                  </button>
                  <Modal
                    className="add-member-popup"
                    title="Add Member"
                    visible={editNameVisible}
                    onOk={EditNamehandleOk}
                    onCancel={EditNamehandleCancel}
                    footer={null}
                    width={600}
                  >
                    <div className="add-member-popup-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="General">
                            <label className="w-100">
                              First name
                              <input
                                type="text"
                                name="firstName"
                                className="form-control form-control-md w-100 Dashboard__input mb-2"
                                placeholder="Enter first name"
                                value={formData.firstName}
                                onChange={(value) =>
                                  handleInputChange(value, "firstName")
                                }
                              />
                              <div className="error-message">
                                {formErrors.firstName}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="General">
                            <label className="w-100">
                              Last name
                              <input
                                type="text"
                                name="lastName"
                                className="form-control form-control-md w-100 Dashboard__input mb-2"
                                placeholder="Enter last name"
                                value={formData.lastName}
                                onChange={(value) =>
                                  handleInputChange(value, "lastName")
                                }
                              />
                              <div className="error-message">
                                {formErrors.lastName}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="General">
                            <label className="w-100">
                              Email
                              <input
                                type="email"
                                name="email"
                                className="form-control form-control-md w-100 Dashboard__input mb-2"
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={(value) =>
                                  handleInputChange(value, "email")
                                }
                              />
                              <div className="error-message">
                                {formErrors.email}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="General">
                            <label className="w-100">
                              Select Role
                              <Select
                                className="w-100 Login--Input"
                                placeholder="Select role"
                                value={formData.role}
                                onChange={(value) =>
                                  handleSelectChange(value, "role")
                                }
                              >
                                <Option value="hr">HR</Option>
                                <Option value="interviewer">Interviewer</Option>
                              </Select>
                              <div className="error-message">
                                {formErrors.role}
                              </div>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="General">
                            <label className="w-100">
                              Select Department
                              <Select
                                name="department"
                                placeholder="Select Department"
                                value={formData.department}
                                onChange={(value) =>
                                  handleSelectChange(value, "department")
                                }
                                className="Login--Input w-100"
                              >
                                <Option value="hr">HR</Option>
                                <Option value="accounts">Accounts</Option>
                                <Option value="UI Department">
                                  UI Department
                                </Option>
                                <Option value="Design Department">
                                  Design Department
                                </Option>
                                <Option value="AI Department">
                                  AI Department
                                </Option>
                              </Select>
                              <div className="error-message">
                                {formErrors.department}
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 mt-5 d-flex justify-content-end add-member-popup-form-buttons">
                      <Button
                        className="primary"
                        onClick={EditNamehandleCancel}
                        style={{ marginRight: "8px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="desc__applynow py-2 fm transition px-3 ml-2 mb-2"
                        onClick={handleAddButtonClick}
                      >
                        {loading ? <Loader /> : "Add"}
                      </Button>
                    </div>
                  </Modal>
                </div>
                <div className="col-lg-7 pt-4 pe-lg-2 pe-0">
                  <div className="Susers__right px-3 rounded p-4">
                    {membersLoading ? (
                      <Loader />
                    ) : members && members.length > 0 ? (
                      members.map((item) => (
                        <div
                          className="Susers__right__item d-flex align-items-sm-center pb-3"
                          key={item.id}
                        >
                          <Avatar
                            className="Dashboard__avatar cursor-default"
                            src={"/assets/images/Users/user1.png"}
                          />
                          <div className="d-flex justify-content-sm-between w-100 flex-sm-nowrap flex-wrap align-items-center my-2">
                            <div className="ps-2 w-100">
                              <p className="black mb-1 fw-semibold cursor-default text-14">
                                {item.firstName} {item.lastName}
                              </p>
                              <p className="darkGrey mb-0 cursor-default text-14 text-capitalize">
                                <strong>Department: </strong>
                                {item.department}
                              </p>
                            </div>
                            <div className="d-inline-flex align-items-center my-2 ms-sm-auto ms-2">
                              <button className="download__button text-14 d-flex justify-content-center text-capitalize align-items-center ps-xl-3 ps-2 py-1">
                                {item.role}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="darkBlue fm mb-0 fw-semibold text-14">
                        No team members exist
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row mt-5">
                <div className="col-lg-5 pe-lg-4 pe-0">
                  <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                    Pending invites
                  </p>
                  <p className="fm darkGrey mb-2">
                    Now it’s time to get your company set up on Index Core so
                    you’re ready to start sharing roles and finding new hires. Now
                    it’s time to get your company set up on Index Core.
                  </p>
                  <button className={`fm invite-button mt-2 `}>
                    Invite People
                  </button>
                </div>
                <div className="col-lg-7 pt-4 pe-lg-2 pe-0">
                  <div className="Susers__right px-3 py-4 rounded">
                    <div
                      className="Susers__right__item  d-flex flex-wrap justify-content-between align-items-center"
                    >
                      <div className="d-flex align-items-center me-3 my-2">
                        <Avatar
                          className=" Dashboard__avatar cursor-default"
                          src={"/assets/images/Users/user1.png"}
                        />
                        <div className="ps-2 w-100 h-100">
                          <p className=" black mb-1 fw-semibold cursor-default text-14">
                            Ben Smith
                          </p>
                          <p className=" darkGrey mb-0 cursor-default text-14">
                            Bensmith@email.com
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center my-2">
                        <button className="download__button me-2 text-14 d-flex justify-content-center align-items-center py-2">
                          Resend invite
                        </button>
                        <button className="error__button fw-semibold text-14 d-flex justify-content-center align-items-center py-2">
                          Revoke
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SUsers;
