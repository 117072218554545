import React, { useState, useContext } from "react";
import { Upload, Button, Select } from "antd";
import { BsCheckCircle, BsPlusCircle, BsLink45Deg } from "react-icons/bs";
import { BsHourglassSplit } from "react-icons/bs";
import { Country } from "../../../Utilities/Country.js";
import { useNavigate,Link } from "react-router-dom";
import Persona from "persona";
import environment from "../../../environment/index";
import { MyContext } from "../../../Utilities/MyContextProvider";
import {
  accurtateCreateCandidate,
  accurtateCreateOrder,
} from "../../../services/auth";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";

const Identity = () => {
  const { Option, OptGroup } = Select;
  const myContext = useContext(MyContext);
  const myData = myContext;
  // console.log("myData from context" + myData[0]);

  const [options, setOptions] = useState({
    templateId: "itmpl_g5dqDwogkc37Dos1GhiqkJvw",
  });
  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: environment.Persona_Environment,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (inquiryId) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        //fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // const { contextData, setContextData } = useContext(MyContext);
  const [state, setState] = useState({
    govtIdCountryName: "",
    govtIdVerification: "",
    // resume: '',
    required: false,
    verificationLoader: false,
    verificationCheck: "",
    verificationCheckLoader: false,
  });
  const {
    loading,
    required,
    verificationLoader,
    govtIdCountryName,
    govtIdVerification,
    verificationCheck,
    verificationCheckLoader,
    // resume,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled = !govtIdCountryName || !verificationCheck;
  // !govtid ||
  // !resume

  const handleVerification = async () => {
    if (!myContext.contextData.emailAddress) {
      enqueueSnackbar("You have not signed up yet", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      handleChange("verificationCheckLoader", true);
      try {
        var createcandidate = {
          firstName: "Bugs",
          lastName: "Bunny",
          middleName: null,
          suffix: "Jr",
          dateOfBirth: "1973-09-10",
          ssn: "444401223",
          email: myContext.contextData.emailAddress,
          phone: "2063695205",
          address: "12345 Mockingbird",
          city: "Hollywood",
          region: "WA",
          country: "US",
          postalCode: "98117",
        };
        var responseCreatecandidate = await accurtateCreateCandidate(
          createcandidate
        );
      
        if (responseCreatecandidate.status == 200) {
          var createOrder = {
            jobLocation: {
              country: "US",
              region: "CA",
              region2: "Orange",
              city: "Irvine",
            },
            candidateId: responseCreatecandidate.data.data.data.id,
            packageType: "PKG_BASIC",
            workflow: "EXPRESS",
          };

          console.log("createOrder payload" + JSON.stringify(createOrder));
          var responseCreateOrder = await accurtateCreateOrder(createOrder);
          console.log(
            "accurtateCreateOrder response" +
              JSON.stringify(responseCreateOrder)
          );
         
          if (responseCreatecandidate.status == 200) {
            handleChange("verificationCheckLoader", false);
            var payloadData = {
              accurate: responseCreatecandidate.data.data.data,
            };

            myContext.updateContextData(payloadData);

            handleChange("verificationCheck", "pending");
            enqueueSnackbar("Verification is in progress, you can proceed!", {
              variant: "pending",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
          }
        }
      } catch (error) {}
    }
  };


  const handlePersonaLink = ()=>{
    const currentUrl = window.location.href;
    const personaLink = `https://withpersona.com/verify?inquiry-template-id=itmpl_8puaBDx8HQ6MGwTf1AdbmnkZ&environment-id=env_njehZjmGfeDjy6wovb73DRpp&redirect-uri=${currentUrl}`;
    window.location.href = personaLink;

  }

  const handleSignup = async () => {
    // alert();
    try {
      if (disabled) {
        // console.log("iskay ander arha");
        handleChange("required", true);
        return;
      }
      // history.push('/signup/email-verification')
      handleChange("required", false);

      var payload = {
        govtIdCountryName: govtIdCountryName,
        livingCountry: govtIdCountryName,
        govtIdVerification: true,
        govtIdRightToWork: "12345678",
        employeeBackgroundCheck: true,
        criminalBackgroundCheck: true,
      };
      myContext.updateContextData(payload);
      navigate(`/professional/signup/online-presence`);
    } catch (error) {}
  };

  return (
    <div className="Signup main-padding">
      <style>
        {`.persona-widget__overlay {z-index: 999999;}
          .loader-icon {width: 40px;}
          .loader-icon span {position: relative;}
          .accurate-image{max-height: 48px;max-width: initial;object-fit: cover;border-radius: 8px;}
            
        }`}
      </style>

      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-2">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Identification
          </h1>
          <p className="Get__left__content__para Signup__para mb-2">
            Your online presence contributes to your score. Employers often like
            to look up employees online once they shortlist résumés.
          </p>
          {/* <form> */}
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="Login--Input mx-0 d-block Login--select w-100"
            size="large"
            placeholder="Your country"
            onChange={(value) => {
              // alert(value)
              handleChange("govtIdCountryName", value);
            }}
          >
            <OptGroup>
              <Option value="US" label="united states">
                United States
              </Option>

              {Country.map((item) => (
                <Option
                  value={item.code}
                  label={item.name.toLowerCase()}
                  key={item.code}
                >
                  {item.name}
                </Option>
              ))}
            </OptGroup>
          </Select>
          {required && !govtIdCountryName && (
            <div>
              <small className="error__message">Country is required!</small>
            </div>
          )}
          <p className="Get__left__content__para Get__right__para fw-semibold mt-sm-5 mt-4">
            Government Issued Identification:
          </p>
          <p className="Get__left__content__para Signup__para">
            We complete all identify verification activities to ensure the
            hiring process is streamlined to get you hired in days
          </p>

          {/* <Upload
            name="logo"
            action="/upload.do"
            listType="document"
            maxCount={1}
            className="px-0"          
          > */}
          <Button
            onClick={handlePersonaLink}
          // to={'https://withpersona.com/verify?inquiry-template-id=itmpl_8puaBDx8HQ6MGwTf1AdbmnkZ&environment-id=env_njehZjmGfeDjy6wovb73DRpp&redirect-uri=https://demo.indexscore.com/professional/signup/identification'}
            className="Get__right__upload mb-2 Get__left__bottom__info__content__para"
          >
            {!verificationLoader ? "Verify" : <Loader />}
          </Button>
          {/* <small className="Get__right__upload__small ps-sm-3">
              File formats: Word or PDF
            </small>
          </Upload> */}
          {/* {required && !govtid && (
                <div>
                  <small className="error__message"> Government Identification is required!</small>
                </div>
              )} */}

          {/* <button
            // onClick={() => handleSignup()}
            onClick={createClient} 
            type="button"
            className={`Get__left__content__para Get__right__button Get__right__button--blue mt-5`}
          >
            Verify
          </button> */}
          {/* <div className="d-flex align-items-center mt-3">
              <BsPlusCircle className="Extra__icon me-2 fs-2 " />
              <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
                Add another right to work documentation?
              </p>
            </div> */}
          <p className="Get__left__content__para Get__right__para fw-semibold mt-sm-5 mt-4">
            Publications and Certifications:
          </p>
          <p className="Get__left__content__para Signup__para">
            You may have other achievements that we don’t know about. Please
            tell us about awards, publications and accreditations.
          </p>
          <Upload
            name="logo"
            action="/upload.do"
            listType="document"
            maxCount={1}
            className="px-0"
            // onChange={value =>
            //   handleChange('resume', value.target.value)
            // }
          >
            <Button className="Get__right__upload mb-2 Get__left__bottom__info__content__para">
              Upload your resume / CV
            </Button>
            <small className="Get__right__upload__small ps-sm-3">
              File formats: Word or PDF
            </small>
          </Upload>
          {/* {required && !resume && (
                <div>
                  <small className="error__message"> Resume is required!</small>
                </div>
              )} */}
          {/* <div className="d-flex align-items-center mt-3">
            <BsPlusCircle className="Extra__icon me-2 fs-2 " />
            <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
              Add another right to work documentation?
            </p>
          </div> */}
          {/* <Link
              to={"/professional/signup/online-presence"}
              className="text-decoration-none text-white"
            > */}
          <p className="Get__left__content__para Get__right__para fw-semibold mt-sm-5 mt-4">
            Background Verification :
          </p>
          <p className="Get__left__content__para Signup__para">
          We complete the background check activities in advance to enable employers make faster hiring decision  
          </p>
          <div className={"col-sm-6 mt-3  mb-3 "}>
            <div className={`link__box ${!verificationCheck ? "" : " "}`}>
              {/* <input
                type="url"
                className="form-control form-control-lg"
                placeholder="Enter your SSN"
                value={verificationCheck}
                readOnly
                onClick={handleVerification}
              /> */}
              <img
                src={`/assets/images/accurate.png`}
                onClick={handleVerification}
                alt=""
                className="DNavbar__logo--img Navbar__container__logo--img accurate-image"
              />
              <span onClick={handleVerification}>
                {!verificationCheck && !verificationCheckLoader ? (
                  <BsLink45Deg className="fs-3" />
                ) : (
                  ""
                )}{" "}
                {verificationCheckLoader ? (
                  <Loader className="fs-3 loader-icon" />
                ) : (
                  ""
                )}{" "}
                {verificationCheck && !verificationCheckLoader ? (
                  <BsHourglassSplit
                    style={{ fontSize: "24px", marginLeft: "3px" }}
                  />
                ) : (
                  ""
                )}
              </span>
            </div>
            <p className="mt-2 Get__left__content__para Signup__para">
              {verificationCheck == "pending"
                ? "Verification process will take 3 to 5 days for completion of your background check. "
                : ""}
            </p>
            {required && !verificationCheck && (
              <div>
                <small className="error__message">
                  Verification is required!
                </small>
              </div>
            )}
          </div>
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--blue mt-5`}
          >
            Continue
          </button>
          {/* </Link> */}
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Identity;
