import React, { useState, useContext, memo } from "react";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import { useSnackbar } from "notistack";
import { createAppointment, composeEmail, sendInvite, sendCallInvite } from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import { BsCheckCircle, BsCalendar2Date } from "react-icons/bs";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { Checkbox, Radio } from "antd";
import { ScheduleMeeting } from 'react-schedule-meeting';


const MeetingDate = memo((props) => {
  const [value, onChange] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  var getSlot = [];
  var getPonka = '234324324';
  const myContext = useContext(MyContext);
  const myData = myContext;
  const { clearContextData,setUserData, getUserData } = useContext(MyContext);  
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  

  const newDataArray = props.interviewers.map(({ _id, firstName, lastName, emailAddress, department }) => ({
    id: _id,
    name: `${firstName} ${lastName}`,
    email: emailAddress,
    department: department,
    status: 'pending',

  }));

  
  
  const [state, setState] = useState({
    title: "",
    startDate: "",
    endDate: "",
    location: "",
    required: false,
  });
  const { loading, required, title, startDate, endDate, location } = state;
  const disabled = !title || !startDate || !endDate || !location;


  const handleSubmit = async () => {
    try {
      if (disabled) {
        handleChange("required", true);
        return;
      }
      handleChange("required", false);
      handleChange("loading", true);
      const payload = {
        title: title,
        startDate: startDate,
        endDate: endDate,
        createdBy: userData?.companyID,
        receivedBy: myData.contextData[0]._id,
        location: location,
      };
      

      let res;

      res = await createAppointment(payload);
      

      if (res.data.statusCode === 1) {
        const payload = {
          subject: title,
          senderID: localStorage.getItem("userEmail"),
          receiverIDs: myData.contextData[0].emailAddress,
          body:
            title +
            " " +
            moment(new Date()).format("YYYY-MM-DD hh:mm:ss a") +
            " " +
            moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
        };
        let resp;
        resp = await composeEmail(payload);
        
        if (resp.data.statusCode === 1) {
          handleChange("loading", false);
          enqueueSnackbar(`Appointment Submitted Successfully`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });

          Profile();
        }
      }
    } catch (error) {
      handleChange("loading", false);
      console.log("errssssssss", error);
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };


  const [selectedOption, setSelectedOption] = useState('');


  const onChangeSelection = (event) => {
    setSelectedOption(selectedOption === event.target.id ? '' : event.target.id);
};


  const handleChange = (key, value) => {
    
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const Profile = () => {
    props.confirm(false);
    props.profile(true);
    props.meeting(false);
    props.time(false);
    props.detail(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const Confirm = async () => {
    handleChange("loading", true);
    

    if (selectedOption === 'check1') {

      newDataArray.forEach(item => {
        item.startDate = startDate;
      });


      const payloadSendInvite = {
        jobID: props.data.jobId,
        job_title: props.data.jobTitle,
        candidateName: props.data.name,
        candidateEmail: props.data.candidateEmail,
        candidateID: props.data.id,
        candidateData: props.data.id,
        interview_stage_order: 1,
        manager: localStorage.getItem('userEmail'),
        managerID: userData?.companyID,
        enterpriseData: userData?.companyID,
        intervieSLots: selectedSlotArray,
        interviewers: newDataArray,
        job_role: props.data.jobTitle,
        location: props.data.jobLocation,
        interview_start_Date: selectedSlotArray[0],
        interview_end_Date: selectedSlotArray[0],
        status: "sent",
        attendance: 'Zoom',
        company_name: props.data.jobCompany,
        applicationID: props.data.applicantID,
      }
      
      const resPayloadSendInvite = await sendInvite(payloadSendInvite);
      
      if(resPayloadSendInvite.data.statusCode == 1)
      {
        const payload = {
          phoneNumber: props.data.candidatePhoneNumber,
          jobID: props.data.jobId,
          job_title: props.data.jobTitle,
          candidateName: props.data.name.split(' ')[0],
          candidateEmail: props.data.candidateEmail,
          candidateID: props.data.id,
          candidateData: props.data.id,
          interview_stage_order: 1,
          manager: localStorage.getItem('userEmail'),
          managerID: userData?.companyID,
          enterpriseData: userData?.companyID,
          intervieSLots: selectedSlotArray,
          interview_start_Date: selectedSlotArray[0],
          interview_end_Date: selectedSlotArray[0],
          interviewers: newDataArray,
          job_role: props.data.jobTitle,
          location: props.data.jobLocation,
          status: "sent",
          attendance: 'Zoom',
          company_name: props.data.jobCompany,
          applicationID: props.data.applicantID,
        }
        
        const res = sendCallInvite(payload);        
        handleChange("loading", false);
        enqueueSnackbar(`Interview call has scheduled`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
  
        props.confirm(true);
        props.profile(false);
        props.meeting(false);
        props.time(false);
        props.detail(false);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }      
    }
    else {

      newDataArray.forEach(item => {
        item.startDate = startDate;
      });

      const payload = {
        jobID: props.data.jobId,
        job_title: props.data.jobTitle,
        candidateName: props.data.name,
        candidateEmail: props.data.candidateEmail,
        candidateID: props.data.id,
        candidateData: props.data.id,
        interview_stage_order: 1,
        manager: localStorage.getItem('userEmail'),
        managerID: userData?.companyID,
        enterpriseData: userData?.companyID,
        intervieSLots: selectedSlotArray,
        interviewers: newDataArray,
        job_role: props.data.jobTitle,
        location: props.data.jobLocation,
        interview_start_Date: selectedSlotArray[0],
        interview_end_Date: selectedSlotArray[0],
        status: "sent",
        attendance: 'Zoom',
        company_name: props.data.jobCompany,
        applicationID: props.data.applicantID,
      }
      
      const res = await sendInvite(payload);      
      if (res.data.statusCode == 1) {

        handleChange("loading", false);
        enqueueSnackbar(`Interview Invite sent`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        props.confirm(true);
        props.profile(false);
        props.meeting(false);
        props.time(false);
        props.detail(false);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
     

    }




  };
  const Time = () => {
    props.confirm(false);
    props.profile(false);
    props.meeting(false);
    props.time(true);
    props.detail(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [selectedSlot, setSelectedSlot] = useState(new Date());
  const [selectedSlotArray, setSelectedSlotArray] = useState([]);

  const getSLotTIme = (value) => {
    
    // handleChange('startTime',value);
    setSelectedSlot(value);
  }
  const generateTimeSlots = () => {
    const currentDate = new Date();
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);

    const nextTwoMonths = new Date(currentDate);
    nextTwoMonths.setMonth(currentDate.getMonth() + 2);
  
    const timeSlots = [];
    let currentTime = new Date(nextWeek);
  
    // Round up the current time to the next hour if it's not exactly on the hour
    if (currentTime.getMinutes() > 0) {
      currentTime.setHours(currentTime.getHours() + 2);
      currentTime.setMinutes(0);
      currentTime.setSeconds(0);
    }
  
    while (currentTime < nextTwoMonths) {
      if (currentTime.getDay() !== 0 && currentTime.getDay() !== 6) {
        if (currentTime.getHours() >= 9 && currentTime.getHours() < 18) {
          const startTime = new Date(currentTime);
          const endTime = new Date(currentTime.getTime() + 60 * 60 * 1000);
          timeSlots.push({
            startTime,
            endTime,
          });
        }
      }
      currentTime = new Date(currentTime.getTime() + 60 * 60 * 1000);
    }
  
    return timeSlots;
  };
  
  // Example usage
  const availableTimeslots = generateTimeSlots().map((slot, id) => ({ id, ...slot }));
  




  return (
    <div className="">
      <div className="container-lg pb-4 pt-lg-4 d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column  meeting__container ">
          {/* <span className="meeting__container__step fm">
            Step <span className="fw-semibold">1/3</span>
          </span> */}
          <h5 className="fm darkGrey fw-normal mt-2 fs-3 pb-4 text-center">
            Select a interview arangement
          </h5>
          <div className="col-12 mb-4">
            <div className="meeting__box">
              <div className="meeting__box__inner py-2 px-3 d-flex align-items-center">
                <div className="meeting__box__inner__check">
                  <BsCheckCircle className="main me-2 fs-4" />
                </div>
                <div>
                  <small className="fm midGrey">Applicant Details</small>
                  <p className="fm mb-0 text-14 fw-semibold darkGrey d-flex flex-wrap">
                    {/* <span>Benjamin Smith</span>,<span> 07881123277</span>,
                    <span> ben@endeavour.agency</span> */}
                    <span>{props.data.name}</span>,<span>{props.data.candidateEmail}</span>
                  </p>
                </div>
              </div>
              <div className="meeting__box__inner py-2 px-3 d-flex align-items-center">
                <div className="meeting__box__inner__check">
                  <BsCheckCircle className="main  me-2 fs-4" />
                </div>
                <div>
                  <small className="fm midGrey">Confirmation</small>
                  <p className="fm mb-0 text-14 fw-semibold darkGrey">
                    Interview request
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4 mt-2">
            <div className="row">
              {/* <p className="darkGrey fm mb-2 fw-semibold fs-6">
                Schedule
              </p> */}
              <div className="col-md-6 mb-2 pe-md-1">
                <div
                  // htmlFor="check1"
                  className=" meeting-box py-1 w-100 d-flex justify-content-between align-items-center pointer"
                >
                  {/* <input
                    type="checkbox"
                    onChange={onChangeSelection}
                    name="attendance"
                    id="check1"
                    checked={selectedOption === 'check1'}
                  /> */}
                   <div className="auto-meeting-box">
                  <Checkbox id="check1" className="w-100 d-flex align-items-center" checked={selectedOption === 'check1'}  onChange={onChangeSelection}>
                 
                    <div className="d-flex align-items-center ">
                      <MdOutlineWifiCalling3 />
                      <div>
                        <small className="fm darkGrey fw-semibold">Auto call</small>
                        <p className="fm midGrey text-12 mb-1">
                          Call via chatbot 
                        </p>
                      </div>
                    

                  </div>
                </Checkbox>
                </div>

                  {/* <Radio type="radio" onChange={onChangeSelection} name="attendance" id="check1" ></Radio> */}

                </div>
              </div>

              {/* <div className="col-md-6 mb-2 ps-md-1">
                <label
                  htmlFor="check2"
                  className=" meeting-box px-2 py-1 w-100 d-flex justify-content-between align-items-center pointer"
                >
                  <input
                    type="radio"
                    onChange={onChangeSelection}
                    name="attendance"
                    id="check2"
                    checked={selectedOption === 'check2'}
                  />
                  <div>
                    <div className="d-flex align-items-center">
                      <BsCalendar2Date />
                      <div>
                        <small className="fm darkGrey fw-semibold">Manual</small>
                        <p className="fm midGrey text-12 mb-1">
                          Via calendar to schedule a meeting
                        </p>
                      </div>
                    </div>

                  </div>


                </label>
              </div> */}


            </div>
          </div>

        </div>       
          <div className="meeting__scheduler">
            <ScheduleMeeting
              borderRadius={10}
              primaryColor="#B2DA66"
              eventDurationInMinutes={60}
              availableTimeslots={availableTimeslots}
              onStartTimeSelect={(time) => {                

                // Check if the selected time already exists in the array
                const index = selectedSlotArray.findIndex((slot) => slot === time.startTime);

                if (index !== -1) {
                  // If the selected time exists, replace it
                  const updatedArray = [...selectedSlotArray];
                  updatedArray[index] = time.startTime;
                  setSelectedSlotArray(updatedArray);
                } else {
                  // If the selected time doesn't exist, add it to the array
                  setSelectedSlotArray((prevArray) => [...prevArray, time.startTime]);
                }

                
              }}
            />
          </div>        

        <div className="w-100 mx-3 slots--box">
          {selectedSlotArray.length > 0 ? (
            <>
              <h4 className="d-flex slots--heading">Selected Slots</h4>
              {selectedSlotArray.map((item, index) => (
                <span className="slots--chips" key={index}>{item.toLocaleString()} </span>
              ))}
            </>
          ) : null}
        </div>
        <div className="d-flex flex-column  meeting__container ">
          <div className="d-flex flex-wrap w-100 justify-content-center pt-sm-5">
            <button
              className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2 border-darkGrey`}
              onClick={Profile}
            >
              Cancel
            </button>

            <button
              className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
              onClick={Confirm}
            // onClick={() => handleSubmit()}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeetingDate;
