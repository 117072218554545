import React from "react";
import { Link } from "react-router-dom";

const UHSection4 = () => {
  return (
    <div className="uhs6 main-padding d-flex align-items-center">
      <div className="container-xl px-0 ">
        <h1 className="fm fs-1 text-center mx-auto main uhs6__heading">
          Unblocking the bottle-neck to ESG and inclusion
        </h1>
        <p className="text-white fm text-center mx-auto uhs6__para">
          In a world where people remain the main filter for hiring decisions,
          bias is still a knee-jerk reaction. And for ESG goals where inclusion
          is important, hiring bias leaves a lot to answer.
        </p>
        <div className="d-flex justify-content-center">
          <Link to={"/about-us"} className="text-decoration-none">
            <button className="btn--main-light text-dark mx-auto fw-semibold fm rounded-2 mt-4">
              Find more about us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UHSection4;
