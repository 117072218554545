import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

// const features_data = [
//   {
//     key: 1,
//     text: "One simple score that includes everything",
//   },
//   {
//     key: 2,
//     text: "Fair and unbiased competition",
//   },
//   {
//     key: 3,
//     text: "Get your personality across in a video message",
//   },
//   {
//     key: 4,
//     text: "Save time, no more tests or forms",
//   },
//   {
//     key: 5,
//     text: "Apply for jobs with just one click",
//   },
//   {
//     key: 6,
//     text: "And it’s free, you can’t say fairer than that!",
//   },
// ];

const PSection1 = () => {
  return (
    <>
      {/* <div className="main-padding">
        <div className="container-lg px-0">
          <div className="py-5">
            <p className="fm darkBlue fs-5 HS1__text mb-0">
              Say goodbye to the days of sending custom applications, and assessments tests for each and every job you apply.
              IndexScore allows you to apply for any job with one click and within seconds after successfully completing your
              IndexScore profile
            </p>
            <p className="fm darkGrey fs-6 HS1__text mb-0">
              IndexScore works similar to a credit score for finance.
            </p>
            <p className="fm darkGrey fs-6 HS1__text mb-0">
              We take absolutely everything about a candidate and verify it,
              such as their identity, education, work experience, social media
              presence and references — and we boil that down to one number,
              their IndexScore.
            </p>
            <p className="fm darkGrey fs-6 HS1__text mb-0">
              Their IndexScore allows you to quickly and easily compare
              candidates, without having to read piles of CVs, perform identity
              checks or do due diligence on the validity of their resume, we’ve
              already done it.
            </p>
          </div>
        </div>

        <div className="container-lg px-0">
          <div className="row pt-4 pb-5">
            <h1 className="fs-2 main fw-light text-center">
              Why should you sign up?
            </h1>
            {features_data.map((item) => (
              <div className="col-xl-4 col-md-6" key={item.key}>
                <div className="features__content w-100  pt-sm-5 pt-4  mb-4">
                  <div className="features__content__image h-100 w-100 d-flex justify-content-center">
                    <img
                      src={`/assets/images/Launch/professional${item.key}.svg`}
                      alt=""
                      className="features__content__image--img h-100"
                    />
                  </div>
                  <div className="features__content__text w-100 pt-4 d-flex justify-content-center">
                    <h4
                      className={`mb-0 features__content__text__heading fw-normal darkGreen fs-5 text-center ps1__title${item.key}`}
                    >
                      {item.text}
                    </h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <Link to={"/professional/signup"} className="text-decoration-none">
        <div className="lightBlueBar main-padding lightGreenBar d-flex align-items-center pointer">
          <div className="container-lg px-0 h-100 py-3">
            <div className="lightBlueBar__content w-100 h-100 d-flex justify-content-between align-items-center">
              <div className="lightBlueBar__content__heading fw-normal">
              Your next opportunity is just a click away. Create your IndexScore account today!
              </div>
              <img
                src={`/assets/images/individuals/arrow-right-thin-green.svg`}
                alt=""
                className="lightBlueBar__content__icon__image"
              />
            </div>
          </div>
        </div>
      </Link>
      <Link to={"/enterprise/signup"} className="text-decoration-none">
        <div className="lightBlueBar main-padding darkBlueBar darkGreenBar d-flex align-items-center pointer">
          <div className="container-lg px-0 h-100 py-3">
            <div className="lightBlueBar__content w-100 h-100 d-flex justify-content-between align-items-center ">
              <div className="lightBlueBar__content__heading darkBlueBar__heading text-white">
                Need an Enterprise account?
              </div>
              <img
                src={`/assets/images/individuals/arrow-right-thin-white.svg`}
                alt=""
                className="lightBlueBar__content__icon__image"
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default PSection1;
