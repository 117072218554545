import React, { useState, useContext } from "react";
import "./style.css";
import { Upload, Button, Select } from "antd";
import { Link } from "react-router-dom";
import { RxArrowTopRight } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import { isEmail, isLinkedInCompany, isPassword } from "../../Utilities/utilities";
import { companies } from "../../Utilities/Companies.js";
import { MyContext } from "../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { Loader, Uploader } from "rsuite";
import {
  enterpiseSignup
} from "../../services/auth";
import "rsuite/dist/rsuite.min.css";


const SignupAsEnterprise = () => {
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const { clearContextData } = useContext(MyContext);
  const { Option, OptGroup } = Select;

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    referenceSource: "",
    companyName: "",
    companyAddress: "",
    linkedinPublicUrl: "",
    password: "",
    confirmPassword: "",
    loading: false,
    invalidEmail: false,
    invalidConfirmPassword: false,
    invalidPassword: false,
    invalidPhone: false,
    required: false,
  });
  const {
    loading,
    required,
    firstName,
    lastName,
    companyName,
    companyAddress,
    emailAddress,
    phoneNumber,
    linkedinPublicUrl,
    password,
    confirmPassword,
    invalidConfirmPassword,
    invalidEmail,
    invalidPassword,

  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !firstName ||
    !lastName ||
    !companyName ||
    !companyAddress ||
    !emailAddress ||
    !phoneNumber ||
    !linkedinPublicUrl ||
    isLinkedInCompany(linkedinPublicUrl) ||
    !password ||
    !confirmPassword ||
    password !== confirmPassword;

    const handleSignup = async () => {
      clearContextData();
    
      try {
        handleChange("loading", true);
    
        if (disabled) {
          handleChange("required", true);
          return;
        }
        const payload = {
          firstName,
          lastName,
          companyName,
          emailAddress,
          phoneNumber,
          role: 'admin',
          linkedinPublicUrl,
          password,
          usertype: "enterprise",
          Identification: {
            companyName,
            corpContact: companyAddress,
          },
        };

        
    
        const res = await enterpiseSignup(payload);
        if (res.data.statusCode === 1) {
          handleChange("loading", false);
          setSubmit(!submit);
    
          enqueueSnackbar("Verification link sent on your email!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
    
      } catch (error) {
        handleChange("loading", false);
        console.error("Error:", error.message, error); 
        enqueueSnackbar(`Error: ${error.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    };
    
    

  return (
    <div className="sc top-padding">
      <div className="main-padding pb-5">
        <div className="container-lg px-0">
          <div>
            <h1 className="fm darkBlue fs-1 fw-light">
              Welcome, let’s create your account
            </h1>
            <div className="row mt-5">
              {!submit ? (
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-6 pe-sm-0 mb-3">
                      <label htmlFor="fname" className="fm darkGrey mb-1">
                        First name
                      </label>
                      <input
                        id="fname"
                        type="text"
                        className="form-control"
                        placeholder="Your first name"
                        onChange={(value) =>
                          handleChange("firstName", value.target.value)
                        }
                      />
                      {required && !firstName && (
                        <div>
                          <small className="error__message fm ps-1">
                            {" "}
                            First Name is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label htmlFor="lname" className="fm darkGrey mb-1">
                        Last name
                      </label>
                      <input
                        id="lname"
                        type="text"
                        className="form-control"
                        placeholder="Your last name"
                        onChange={(value) =>
                          handleChange("lastName", value.target.value)
                        }
                      />
                      {required && !lastName && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Last Name is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label htmlFor="lname" className="fm darkGrey mb-1">
                        Company name
                      </label>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        className="Login--Input  select-signup mb-2 mx-0 d-block w-100"
                        size="small"
                        placeholder="Company Name"
                        onChange={(value) => {
                          handleChange("companyName", value);
                        }}
                      >
                        <OptGroup>
                          {companies.map((item) => (
                            <Option
                              value={item.name}
                              label={item.name.toLowerCase()}
                              key={item.name}
                            >
                              {item.name}
                            </Option>
                          ))}
                        </OptGroup>
                      </Select>
                      {required && !companyName && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Company name is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label htmlFor="lname" className="fm darkGrey mb-1">
                        Corporate office address
                      </label>
                      <input
                        id="lname"
                        type="text"
                        className="form-control"
                        placeholder="Corporate office address"
                        onChange={(value) =>
                          handleChange("companyAddress", value.target.value)
                        }
                      />
                      {required && !companyAddress && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Corporate office address is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="email" className="fm darkGrey mb-1">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Your email address"
                        onChange={(value) =>
                          handleChange("emailAddress", value.target.value)
                        }
                      />
                      {emailAddress && isEmail(emailAddress) && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Email must be a valid email address !
                          </small>
                        </div>
                      )}
                      {required && !emailAddress && (
                        <div>
                          <small className="error__message fm">
                            Email Address is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="phone" className="fm darkGrey ">
                        Phone Number
                      </label>
                      <PhoneInput
                        id="phone"
                        country={'us'}
                        className=""
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(value) =>
                          handleChange("phoneNumber", value)
                        }
                      />
                      {required && !phoneNumber && (
                        <div>
                          <small className="error__message fm">
                            Phone number is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="pass" className="fm darkGrey mb-1">
                        Password
                      </label>
                      <input
                        id="pass"
                        type="password"
                        className="form-control"
                        placeholder="Must be 12 characters"
                        onChange={(value) =>
                          handleChange("password", value.target.value)
                        }
                      />
                      {password && isPassword(password) && (
                        <div>
                          {/* <small >passWord is required</small> */}
                          <small className="error__message fm">
                            Must be at least 8 characters long and include upper and
                            lowercase letters and at least one number
                          </small>
                        </div>
                      )}
                      {required && !password && (
                        <div>
                          <small className="error__message fm">
                            Password is required!
                          </small>
                        </div>
                      )}
                      <PasswordStrengthBar
                        style={{ marginTop: 10 }}
                        password={password}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="pass" className="fm darkGrey mb-1">
                        Confirm Password
                      </label>
                      <input
                        id="pass"
                        type="password"
                        className="form-control"
                        placeholder="Must be 12 characters"
                        onChange={(value) =>
                          handleChange("confirmPassword", value.target.value)
                        }
                      />
                      {confirmPassword && password !== confirmPassword && (
                        <div>
                          <small className="error__message fm">
                            Passwords must match!
                          </small>
                        </div>
                      )}
                      {required && !confirmPassword && (
                        <div>
                          <small className="error__message fm">
                            Confirm password is required!
                          </small>
                        </div>
                      )}

                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="link" className="fm darkGrey mb-1">
                        Corporate LinkedIn page link
                      </label>
                      <input
                        id="link"
                        type="text"
                        className="form-control"
                        placeholder="Paste your Corporate LinkedIn URL"
                        onChange={(value) =>
                          handleChange("linkedinPublicUrl", value.target.value)
                        }
                      />
                      {linkedinPublicUrl && isLinkedInCompany(linkedinPublicUrl) && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Use a valid linkedin profile!
                          </small>
                        </div>
                      )}
                      {required && !linkedinPublicUrl && (
                        <div>
                          <small className="error__message fm">
                            Corporate LinkedIn URL is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-12 mb-3">
                      <div className="mt-4">
                        <p className="fm darkGrey text-14">

                          <label
                            className="form-check-label Get__left__content__para Get__right__label d-flex"
                            htmlFor="gridCheck1"
                          >
                            <input
                              style={{
                                marginRight: '10px'
                              }}
                              className="form-check-input mr-2"
                              type="checkbox"
                              id="terms"
                            />
                            <span style={{
                              width: "90%"
                            }}
                            >
                              By clicking the button below, I agree to IndexScore’s{" "}
                              <Link
                                to={"/terms-of-use"}
                                className="text-decoration-none fm darkGrey fw-semibold"
                              >
                                Terms of Service
                              </Link>
                              , including{" "}
                              <Link
                                to={"privacy-policy"}
                                className="text-decoration-none fm darkGrey fw-semibold"
                              >
                                Privacy Policy
                              </Link>
                              .
                            </span>
                          </label>
                        </p>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={loading}
                          className={`Get__left__content__para Get__right__button Get__right__button--blue me-3`}
                          // onClick={() => setSubmit(!submit)}
                          onClick={() => handleSignup()}
                        >
                          {loading ? <Loader /> : "Continue"}
                        </button>
                        <small className="fm darkGrey">
                          Already joined?{" "}
                          <Link
                            to={"/log-in"}
                            className="fm darkGrey fw-semibold text-decoration-none"
                          >
                            Sign in
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <h1 className="fm blue fw-light fs-4 pb-2">
                    Thank you for signing up!
                  </h1>
                  <p className="fm darkGrey text-14 sc__right__submitext">
                    You will shortly
                    receive an email
                    from
                    IndexScore.com to
                    verify your account.
                    Please verify your
                    account to complete
                    the registration
                    process.
                  </p>
                  <p className="fm midGrey text-14 pb-4">Email sent to: {state ? emailAddress : ' '}</p>
                  {/* <p className="fm darkGrey text-14 fw-light">Did not receive your email? Please <Link to={'/'} className="darkGrey fw-normal">click here</Link> to resend.</p> */}
                </div>
              )}

              <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-3">
                <div className="sc__right sc__right2 rounded-3  d-flex flex-column">
                  <div className="px-3 pt-4">
                    <h1 className="fm text-white fs-2 mb-0">
                      Not an Enterprise?
                    </h1>
                    <h1 className="fs-2 fm text-white fw-light">
                      Need a job Candidate IndexScore account
                    </h1>
                    <p className="fm text-white fw-light mt-3">
                      Find out more about how job Candidates are using IndexScore to secure job opportunities in record time. No more unnecessary long job application and interview process. Create your IndexScore account today!
                    </p>
                  </div>

                  <div className="d-flex justify-content-end align-items-end">
                    <img
                      src="/assets/images/signup_group2.png"
                      alt=""
                      className="sc__right--img2 mb-5"
                    />
                  </div>
                  <Link
                    to={"/professional/signup"}
                    className="text-decoration-none text-white"
                  >
                    <div className="sc__right__icon rounded-circle d-flex justify-content-center align-items-center transition">
                      <RxArrowTopRight className="fs-2 text-white transition" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupAsEnterprise;
