import React from "react";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import JobPublicDetail from "../pageComponents/ProfessionalDashboard/Jobs/JobPublicDetail";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const JobPublic = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
     <div className="container-dash eprofile edash edash3 pb-5">
       <JobPublicDetail profileView="public" />
     </div>
      <Footer />
    </>
  );
};

export default JobPublic;
