import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import { getEnterpriseDashboard,getMembersByCompany } from "../../services/auth";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useSnackbar } from "notistack";
import { MyContext, useAuth } from "../../Utilities/MyContextProvider";
import { Country } from "../../Utilities/Country";
import "rsuite/dist/rsuite.min.css";
import HrJobs from "./Jobs/Jobs";
import "./style.css";

const HrPortal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { clearUserData, clearContextData, getUserData, logoutAction } =
    useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const { dispatch } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [dashbordloading, setdashbordLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [members, setMembers] = useState([]);


  const fetchEnterpriseDashboard = async () => {
    // handleChange("loading", true);
    setdashbordLoading(true);
    try {
      const payload = {
        _id: userData?.companyID,
      };
      const response = await getEnterpriseDashboard(payload);
      console.log("response", response);
      setDashboardData(response.data.data);
      setdashbordLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchEnterpriseDashboard();
  }, []);

  function logOutUser() {
    navigate(`/log-in`);
    localStorage.clear();
    clearUserData();
    clearContextData();
    logoutAction();
  }


  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    setMembersLoading(true);
    // getMembersByCompany

    const payloadGetMembers = {
      enterpriseID: userData?.companyID,
    };

    const responseTeamMembers = await getMembersByCompany(payloadGetMembers);

    if (responseTeamMembers.status == 200) {
      // Reverse the order of the data array
      const reversedData = responseTeamMembers.data.data.slice().reverse();
      setMembers(reversedData);
      setMembersLoading(false);
    }
  };


  const items = [
    {
      key: "1",
      label: (
        <button
          className="d-flex align-items-center justify-content-center w-100 menu-button"
          onClick={logOutUser}
        >
          <BiLogOut /> Logout
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="edash">
        <div className="fm overflow-hidden">
          <section className="hr interviewer-main-section">
            <div className="container-dash mt-4">
              <div className="row ">
                <div className="col-md-6 ">
                  <div className="interviewerHeader align-items-lg-center align-items-start p-3">
                    <div className="imageBox">
                      {/* <img src="/assets/images/userAvatar.svg" /> */}
                    </div>
                    <div className="interviewerDetail fm">
                      <div className="interviewer-name">
                        <h3>{localStorage.getItem("userProfileName")}</h3>
                      </div>
                      <div className="emailBox text-start">
                        <span className="text-14 word-wrap">
                          {localStorage.getItem("userEmail")}
                        </span>
                      </div>
                      {/* <div className="departmentBox">
                    <BsFillBuildingsFill /> Department:{" "}
                    <span>{memberData && memberData[0].department}</span>
                  </div> */}
                      {/* <div className="headerButtons"> */}
                      {/* <button className='primary-button'>first button</button> */}
                      {/* <button className='secondary-button'>second</button> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 mt-md-0 mt-3">
                  <Link
                    to={"/hr/dashboard/department"}
                    className="text-decoration-none h-50"
                  >
                    <div className="dashboardScreen__active__job_roles mt-0 outlined__box edashboard__box position-relative p-3">
                      {/* {dashbordloading ? (
                        <Loader />
                      ) : (
                        <span>
                          {dashboardData && dashboardData?.allApplicants?.length}
                        </span>
                      )} */}
                      {membersLoading ? (
                        <Loader />
                      ) : (
                        <span>{members  && members.length}</span>
                      )}
                      <br />
                      <span className="fm fs-6 fw-normal">Team Members</span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-md-2 mt-md-0 mt-3">
                  <Link
                    to={"/hr/dashboard/jobs"}
                    className="text-decoration-none h-50"
                  >
                    <div className="dashboardScreen__active__job_roles mt-0 outlined__box edashboard__box position-relative p-3">
                      {dashbordloading ? (
                        <Loader />
                      ) : (
                        <span>{dashboardData && dashboardData?.totalJobs}</span>
                      )}
                      <br />
                      <span className="fm fs-6 fw-normal">
                        Active roles
                      </span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-md-2 mt-md-0 mt-3">
                  <Link
                    to={"/hr/dashboard/jobs"}
                    className="text-decoration-none h-50"
                  >
                    <div className="dashboardScreen__active__draft_roles mt-0 mb-0 outlined__box edashboard__box position-relative p-3">
                      {dashbordloading ? <Loader /> : <span>{dashboardData && dashboardData?.DraftJobs?.length}</span>}
                      <br />
                      <span className="fm fs-6 fw-normal">Drafted roles</span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="hr-dashboard-jobs">
          <HrJobs />
        </div>
      </div>
    </>
  );
};

export default HrPortal;
