import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { FiShare, FiHelpCircle } from "react-icons/fi";
import { BsCheck2 } from "react-icons/bs";
import ProgressBar from "react-bootstrap/ProgressBar";
import GradientSVG from "./gradientSVG";
import { Spin, Progress, Tooltip, Empty } from "antd";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import {
  getJobById,
  getEnterpriseByID,
  getJobs,
  getProfessionalById,
  getInboxChats,
  getEnterpriseDashboard,
  getClientAppointments,
  getPaymentMethods,
  subscriptionListAll,
} from "../../../services/auth";
import { getScores } from "../../../services/criteria";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import moment from "moment";
import { IoIosArrowRoundForward } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  // Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const plansData = [
  {
    name: "Base",
    features: ["IndexScore", "Job Relevance"],
    disabledFeatures: [
      "ATS Integration",
      "Compliance Report",
      "Purpose Alignment score",
      "Integrated candidates engagement",
      "Offer and Contract management",
      "Talent availability insights",
    ],
  },
  {
    name: "Grow",
    features: [
      "IndexScore",
      "Job Relevance",
      "ATS Integration",
      "Compliance Report",
    ],
    disabledFeatures: [
      "Purpose Alignment score",
      "Integrated candidates engagement",
      "Offer and Contract management",
      "Talent availability insights",
    ],
  },
  {
    name: "Scale",
    features: [
      "IndexScore",
      "Job Relevance",
      "ATS Integration",
      "Compliance Report",
      "Purpose Alignment score",
      "Integrated candidates engagement",
      "Offer and Contract management",
    ],
    disabledFeatures: ["Talent availability insights"],
  },
  {
    name: "Predict",
    features: [
      "IndexScore",
      "Job Relevance",
      "ATS Integration",
      "Compliance Report",
      "Purpose Alignment score",
      "Integrated candidates engagement",
      "Offer and Contract management",
      "Talent availability insights",
    ],
    disabledFeatures: [],
  },
];

// const DATA_COUNT = 7;
// const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

// const labels = Utils.months({count: 7});

const DashboardScreen = (props) => {
  const [val, setVal] = useState(0);
  const [val1, setVal1] = useState(0);
  const [indexAvg, setIndexAvg] = useState(0);
  const [candidates, setCandidates] = useState([]);
  const [jobsData, setJobsData] = useState([]);

  const [allCandidatesData, setAllCandidatesData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [isCandidateDataFetched, setIsCandidateDataFetched] = useState(false);

  const [inboxEmails, setInboxEmails] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [applicantsTab, setApplicantsTab] = useState(true);
  const [rolesTab, setRolesTab] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [stepsLeft, setStepsLeft] = useState(0);
  const [mostPopularRole, setMostPopularRole] = useState(null);
  const [selectedValue, setSelectedValue] = useState("monthly");

  const handleSelectedChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  const [chartData, setChartData] = useState([]);

  const func_applicants = () => {
    setApplicantsTab(true);
    setRolesTab(false);
  };
  const func_roles = () => {
    setApplicantsTab(false);
    setRolesTab(true);
  };

  useEffect(() => {
    fetchJobs();
    fetchCandidates();
    fetchAppointments();
    fetchEnterpriseDashboard();
    const orderId = "OrderId12345";
    getScores(orderId).then((res) => {});
  }, []);
  const idCSS = "hello";
  useEffect(() => {
    const timerId = setTimeout(() => {
      setVal(650);
      setVal1(600);
    }, 500);
    return () => clearTimeout(timerId);
  }, []);

  var myObjectEnterprise;

  var fetchedCandidatesList;

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const response = await subscriptionListAll();
        if (response.data.statusCode === 1) {
          const sortOrder = ["Base", "Grow", "Scale", "Predict"];
          const sortedData = response.data.data.sort(
            (a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
          );
          
          setPlans(sortedData);
        } else {
          console.error("Error fetching plans:", response.data.statusDesc);
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    if (allCandidatesData.length > 0 && !isCandidateDataFetched) {
      fetchCandidateData();
    }
  }, [allCandidatesData, isCandidateDataFetched]);

  const fetchCandidates = async () => {
    try {
      var payLoadtoGetEnt = {
        _id: userData?.companyID,
      };

      const respGetEnterpriseByID = await getEnterpriseByID(payLoadtoGetEnt);
      myObjectEnterprise = respGetEnterpriseByID.data.data.find(
        (obj) => obj._id === userData?.companyID
      );
      const enterpriseJobids = myObjectEnterprise.jobIds;
      const uniqueCandidates = new Set();

      for (let jobId of enterpriseJobids) {
        var payload = {
          _id: jobId,
        };
        const response = await getJobById(payload);
        const jobData = response.data.data;
        if (jobData && jobData.length > 0 && jobData[0].candidates) {
          const jobsCandidatesData = jobData[0].candidates;

          jobsCandidatesData.forEach((candidate) => {
            uniqueCandidates.add(candidate);
          });
        }
      }

      const allCandidatesDataRetreived = Array.from(uniqueCandidates);
      if (allCandidatesDataRetreived.length === 0) {
      } else {
        setAllCandidatesData(allCandidatesDataRetreived);
      }
    } catch (error) {}
  };

  const fetchCandidateData = async () => {
    const candidatePromises = allCandidatesData.map(async (id) => {
      try {
        var payloadPro = {
          _id: id,
        };
        const responseProfessional = await getProfessionalById(payloadPro);
        return responseProfessional.data.data;
      } catch (error) {
        console.log(`Error fetching candidate with ID ${id}:`, error);
        return null;
      }
    });

    try {
      const candidateResults = await Promise.all(candidatePromises);
      const modifiedResults = candidateResults.map((item) => item[0]);

      fetchedCandidatesList = modifiedResults.filter(Boolean).map((person) => ({
        id: person._id,
        indexCore: Math.round(person.ai_data.index_core),
        CultureFit: Math.round(
          person.ai_data.company_fit_percentage[
            userData?.ai_data?.company_data?.company_name
          ]
        ),
      }));

      const indexCoreArray = fetchedCandidatesList.map(
        (candidate) => candidate.CultureFit
      );
      const indexCoreSum = indexCoreArray.reduce((acc, curr) => acc + curr, 0);
      const indexCoreAvg = indexCoreSum / indexCoreArray.length;

      setCandidateData(fetchedCandidatesList);

      setIndexAvg(indexCoreAvg.toFixed(0));
      setIsCandidateDataFetched(true);
    } catch (error) {
      console.log("Error fetching candidate data:", error);
    }
  };

  const fetchJobs = async () => {
    // handleChange("loading2", true);

    try {
      const response = await getJobs();

      var myObjectEnterprise;
      myObjectEnterprise = response.data.data.filter(
        (obj) => obj.enterpriseID === userData?.companyID
      );
      setJobsData(
        myObjectEnterprise.map((jobs) => ({
          key: jobs._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  function calculateStepsLeft(ratio) {
    const percentageLeft = 100 - ratio * 100;

    if (percentageLeft == 100) {
      return 5;
    } else if (percentageLeft == 80) {
      return 4;
    } else if (percentageLeft == 60) {
      return 3;
    } else if (percentageLeft == 40) {
      return 2;
    } else if (percentageLeft == 20) {
      return 1;
    } else {
      return 0;
    }
  }

  const fetchEnterpriseDashboard = async () => {
    // handleChange("loading", true);
    setDashboardLoading(true);
    try {
      const payload = {
        _id: userData?.companyID,
      };
      const response = await getEnterpriseDashboard(payload);
      setDashboardData(response.data.data);
      console.log("response.data.data",response.data.data);
      const allApplicants = response?.data?.data?.allApplicants;

      if (allApplicants && Array.isArray(allApplicants)) {
        const mappedData = allApplicants.map((item) => ({
          label: new Date(item.createdAt).toLocaleString("default", {
            month: "long",
          }),
          data: [item.createdAt], // You may need to format these dates as needed
          backgroundColor: "#b2da66",
        }));

        const groupedData = {};
        mappedData.forEach((item) => {
          if (!groupedData[item.label]) {
            groupedData[item.label] = [];
          }
          groupedData[item.label].push(item);
        });

        // Create an array with all months
        const allMonths = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Fill in missing months with empty data
        const labels = allMonths;
        const dataCounts = allMonths.map((month) =>
          groupedData[month] ? groupedData[month].length : 0
        );
        
        setChartData(
          labels.map((month, index) => ({
            progress: dataCounts[index] * 10, // Generate a random progress value between 0 and 100
            title: month,
          }))
        );
        

        const titleCounts = allApplicants.reduce((acc, curr) => {
          const title = curr.job_title;
          acc[title] = (acc[title] || 0) + 1;
          return acc;
        }, {});

        const topTitle = Object.entries(titleCounts).reduce(
          (maxTitle, [title, count]) =>
            count > titleCounts[maxTitle] ? title : maxTitle,
          Object.keys(titleCounts)[0]
        );
        setMostPopularRole(topTitle);
        

        // console.log("data after manipluation", data);
        // setChartData({
        //   labels: labels,
        //   datasets: [
        //     {
        //       label: "Applicants",
        //       data: dataCounts,
        //       backgroundColor: "#b2da66",
        //     },
        //   ],
        // });
      } else {
        console.error("Invalid or missing data for allApplicants");
      }

      const ratio = response.data.data.progress?.total
        ? response.data.data.progress?.total / 100
        : 0 / 100;
      if (ratio !== undefined) {
        const stepsLeft = calculateStepsLeft(ratio);
        setStepsLeft(stepsLeft);
      } else {
      }

      setDashboardLoading(false);
    } catch (error) {}
  };

  const navigateTo = () => {
    navigate("/enterprise/dashboard/plan");
  };
  const fetchAppointments = async () => {
    // handleChange("loading2", true);

    try {
      var payload = {
        _id: userData?.companyID,
      };
      const responseAppointments = await getClientAppointments(payload);
      if (responseAppointments.data.statusCode == 1) {
        const appointmentsData = responseAppointments.data.data;
        setAppointments(appointmentsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const CreateNewJob = () => {
    props.Newjob(true);
    props.dashboard(false);
  };

  const validateProfile = () => {
    if (userData?.role === "hr") {
      navigate("/enterprise/dashboard/create-new-job");
    } else {
      if (userData?.progress?.total == 100 && userData?.ai_data?.company_data) {
        if (!userData.subscription) {
          enqueueSnackbar("Please subscribe a plan to create job", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        } else {
          navigate("/enterprise/dashboard/create-new-job");
        }
      } else {
        enqueueSnackbar("Please complete profile to create job", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };
  const [progress, setProgress] = useState(10);
  const colors = [
    "#00C9C1",
    "#000000",
    "#C4C4C4",
    "#0D2159",
    "#618CFC",
    "#B5E36E",
  ];

  const applicants = [
    { progress: 80, title: "Title 1" },
    { progress: 50, title: "Title 2" },
    { progress: 40, title: "Title 3" },
    { progress: 70, title: "Title 4" },
    { progress: 100, title: "Title 5" },
    { progress: 60, title: "Title 6" },
    { progress: 67, title: "Title 7" },
    { progress: 10, title: "Title 8" },
    { progress: 60, title: "Title 9" },
    { progress: 27, title: "Title 10" },
    { progress: 80, title: "Title 11" },
    { progress: 30, title: "Title 12" },
  ];
  const roles = [
    { progress: 80, title: "Title 1" },
    { progress: 50, title: "Title 2" },
    { progress: 40, title: "Title 3" },
    { progress: 70, title: "Title 4" },
    { progress: 100, title: "Title 5" },
    { progress: 60, title: "Title 6" },
    { progress: 67, title: "Title 7" },
    { progress: 10, title: "Title 8" },
    { progress: 60, title: "Title 9" },
    { progress: 27, title: "Title 10" },
    { progress: 80, title: "Title 11" },
    { progress: 30, title: "Title 12" },
  ];
  

  const renderBars = (bars) => {
    return bars.map((bar, index) => {
      const colorIndex = index % colors.length;
      const color = colors[colorIndex];
      const gradientColor = `${color} 20%, transparent 100%`;

      return (
        <Tooltip
          title={`${bar.title} - ${bar.progress}`}
          key={index}
          color={color}
        >
          <div
            className="dash__graph__bar position-relative"
            style={{
              height: `${bar.progress}%`,
              backgroundColor: color,
              background: `linear-gradient(to bottom, ${gradientColor})`,
              transition: `height 1s ${index * 0.2}s`,
            }}
          >
            <div className="position-absolute dash__graph__bar__name fm darkGrey text-12 w-100 text-center">{bar.title}</div>
          </div>
        </Tooltip>
      );
    });
  };
  const getMaxProgressTitle = (bars) => {
    let maxProgressBar = bars[0];
    for (let i = 1; i < bars.length; i++) {
      if (bars[i].progress > maxProgressBar.progress) {
        maxProgressBar = bars[i];
      }
    }
    return maxProgressBar.title;
  };

  const applicantsGraph = renderBars(chartData && chartData);
  const applicantsMaxProgress = getMaxProgressTitle(applicants);
  const rolesGraph = renderBars(roles);
  const rolesMaxProgress = getMaxProgressTitle(roles);

  return (
    <div>
      <style>
        {`
          .dashboardScreen__chart__count{
            z-index: 2;
            position: relative;
          }
        `}
      </style>
      <div className="edash">
        <div className="pt-4">
          <div className=" overflow-hidden">
            <div className="dashboardScreen__content">
              {/* jobs hiring starts */}
              {/*dashboard cards  hiring ends */}
              <div className="row ">
                <div className="col-xxl-3 col-lg-4 col-md-6 order-1 pe-md-0 mb-md-0 mb-3">
                  <div className="dashboard__profile d-flex align-items-center h-100">
                    <div className="dashboard__profile__box mb-0 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="dash__avatar border rounded-circle me-xxl-3 me-2 border d-flex justify-content-center align-items-center">
                          <img
                            className="dash__avatar--img p-0 w-100 h-100"
                            // src={"/assets/images/userAvatar.svg"}
                            src={`${
                              userData.profileImage
                                ? userData.profileImage.docs
                                : "/assets/images/userAvatar.svg"
                            }`}
                          />
                        </div>

                        <div>
                          <p className="mb-0 text-20 fm fw-semibold mb-0">
                            {userData.firstName.charAt(0).toUpperCase() +
                              userData.firstName.slice(1) +
                              " " +
                              userData.lastName}
                          </p>
                          {userData.uniqueId && (
                            <p className="fm darkGrey text-14 fw-semibold my-1">
                              {userData.uniqueId}
                            </p>
                          )}
                          <p className="my-0 fm darkGrey text-14">
                            {userData?.role === "admin"
                              ? userData?.companyName
                              : userData?.company_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="dashboard__profile__progress">
                      <div className="d-flex justify-content-between">
                        <div className="dashboard__profile__progress__text">
                          <strong>Account completion</strong>
                          <span>
                            {dashboardData && dashboardData?.progress?.total
                              ? dashboardData?.progress?.total
                              : 0}
                            % complete
                          </span>
                        </div>
                        <div className="dashboard__profile__progress__steps">
                          <span>{dashboardData && stepsLeft} step left</span>
                          <IoIosArrowRoundForward />
                        </div>
                      </div>
                      <ProgressBar
                        className={`progressBar__candidates rounded-pill`}
                        now={parseInt(dashboardData?.progress?.total)}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-xxl-7 order-xxl-2 order-3 mt-xxl-0 mt-3">
                  <div className="PDetail__2 position-relative overflow-hidden h-100">
                    <Progress
                      percent={
                        dashboardData && dashboardData?.progress?.total
                          ? dashboardData?.progress?.total
                          : 0
                      }
                      steps={5}
                      showInfo={false}
                      className="w-100 position-absolute top-0 start-0"
                    />
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {dashboardData && dashboardData?.progress?.total
                            ? dashboardData?.progress?.total
                            : 0}
                          % complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>

                    <div className="PDetail__top--blue p-3 h-100">
                      <div className="d-flex flex-md-nowrap w-100 flex-wrap justify-content-between">
                        <div className=" PDetail__top--blue__left d-flex justify-content-start align-items-center me-md-3">
                          {/* <div className="dash__account_image">
                            <Spin />
                          </div> */}
                          <div>
                            {/* <h6 className="fm fw-semibold fs-6 mb-1 nowrap">
                      Social presence
                      </h6> */}
                            <p className="fm darkGrey mb-md-0 my-auto text-14">
                              {/* Complete your profile to unlock company score, you need to have completed profile for creating jobs. */}
                              Congratulations! Your profile is complete. You can
                              now create jobs.
                            </p>
                            {/* <div className="d-sm-none d-flex">
                      <button className="PDetail__top--blue__skip fm  me-2">
                          Skip
                        </button>
                      <button
                        className="PDetail__top--blue__goto fm"
                      >
                        Next
                      </button>
                    </div> */}
                          </div>
                        </div>
                        {/* <div className="d-sm-flex d-none align-items-center justify-content-end">
                      <button className="PDetail__top--blue__skip fm  me-2">
                          Skip
                        </button>
                      <button
                        className="PDetail__top--blue__goto fm"
                      >
                        Next
                      </button>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-2 col-lg-8 col-md-6 ps-xxl-0 order-xxl-3 order-2">
                  <div className="dashboardScreen__content__hiring radius8 d-flex flex-xxl-column flex-xl-row flex-column align-items-xxl-start align-items-xl-center justify-content-between h-100 py-2 px-3">
                    <div className="pe-3 pt-2">
                      <h1 className="text-35 text-white fw-semibold fm">
                        Let's start hiring!
                      </h1>
                    </div>

                    {/* dashboardScreen__content__hiring__btn fm */}
                    <button
                      onClick={validateProfile}
                      className="dashboardScreen__content__hiring__btn--button dash__btn text-14 p-2 mb-xxl-2 mb-lg-0 mb-2"
                    >
                      + Create New Job
                    </button>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-2 pe-lg-0">
                  <div className="pe-xl-0 pe-lg-2 pe-md-0 mb-md-0 mb-3 d-flex flex-column gap-xl-2 gap-3">
                    <Link
                      to={"/enterprise/dashboard/candidates"}
                      className="text-decoration-none h-50"
                    >
                      <div className="dashboardScreen__active__job_roles mt-0 outlined__box edashboard__box position-relative">
                        <span>
                        {dashboardLoading ? (
                        <Loader />
                      ) : (
                        <span>{dashboardData && dashboardData?.allApplicants?.length}</span>
                      )}                          
                      {/* <span className="fm">0</span> */}
                        </span>
                        <br />
                        <span className="fm text-14 fw-normal">
                          New applicants
                        </span>
                        <img
                          src={`/assets/images/Dashboard/angle-arrow.svg`}
                          alt=""
                          className="lightBlueBar__content__icon__image"
                        />
                      </div>
                    </Link>
                    <Link
                      to={"/enterprise/dashboard/jobs"}
                      className="text-decoration-none h-50"
                    >
                      <div className="dashboardScreen__active__job_roles mt-0 outlined__box edashboard__box position-relative">
                        
                        {dashboardLoading ? (
                        <Loader />
                      ) : (
                        <span>{dashboardData && dashboardData?.activeJobs?.length}</span>
                      )}
                      {/* <span className="fm">0</span> */}
                        <br />
                        <span className="fm text-14 fw-normal">
                          Active job roles
                        </span>
                        <img
                          src={`/assets/images/Dashboard/angle-arrow.svg`}
                          alt=""
                          className="lightBlueBar__content__icon__image"
                        />
                      </div>
                    </Link>
                    <Link
                      to={"/enterprise/dashboard/jobs"}
                      className="text-decoration-none h-50"
                    >
                      <div className="dashboardScreen__active__draft_roles mt-0 mb-0 outlined__box edashboard__box position-relative">
                        {dashboardLoading ? (
                        <Loader />
                      ) : (
                        <span>{dashboardData && dashboardData?.DraftJobs?.length}</span>
                      )} 
                      {/* <span className="fm">0</span> */}
                      <br />
                        <span className="fm text-14 fw-normal">Drafted roles</span>
                        <img
                          src={`/assets/images/Dashboard/angle-arrow.svg`}
                          alt=""
                          className="lightBlueBar__content__icon__image"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-5 pe-lg-0 mb-lg-0 mb-3">
                  <div className="dashboar__expiring__jobs__box my-0 overflow-hidden h-100">
                    <div className="expiring__header fm px-3 pt-3 mb-3">
                      <h5 className="fm fs-5 fw-semibold mb-1">
                        Jobs expiring in next 7 days
                      </h5>
                      <p className="fm darkGrey text-14 mb-0">
                        Click below to view candidates
                      </p>
                    </div>
                    <div className="expiring__body">
                      
                      {dashboardLoading ? (
                        <div className="d-flex justify-content-center w-100 my-3">
                          <Loader size="md" className="pt-4"  />
                        </div>
                      ) : (
                        <>
                          {dashboardData &&
                          dashboardData?.willExpiredJobs &&
                          dashboardData?.willExpiredJobs.length > 0 ? (
                            <Link
                              className="py-0 px-0 expiring__body_detail text-decoration-none"
                              to={`/enterprise/dashboard/jobs/${dashboardData?.willExpiredJobs[0]?._id}`}
                            >
                              <div className="expiring__body_detail justify-content-start border-05">
                                <div className="expiring__body_detail_image comany__image rounded-2 d-flex justify-content-center align-items-center">
                                  <img
                                    src={`${userData.companyImage
                                      ? userData.companyImage.docs : '/assets/images/expiring_icon.svg'}`}
                                    alt=""
                                    className="me-0"
                                  />
                                </div>
                                <div>
                                  <div className="expiring__body_detail__info">
                                    <h4 className="mb-sm-2 mb-1 fm darkGrey">
                                      {
                                        dashboardData?.willExpiredJobs[0]
                                          ?.job_title
                                      }
                                    </h4>
                                  </div>
                                  <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-sm-nowrap flex-wrap justify-content-start">
                                    <span className="text-14 me-3 fm mb-1">
                                      {
                                        dashboardData?.willExpiredJobs[0]
                                          ?.company
                                      }{" "}
                                    </span>
                                    <div className="d-flex">
                                      <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                        <HiOutlineSquare3Stack3D className="me-1" />
                                        {
                                          dashboardData?.willExpiredJobs[0]
                                            ?.department
                                        }{" "}
                                      </span>
                                      <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                        <TbCurrencyDollar className="fs-6" />
                                        {
                                          dashboardData?.willExpiredJobs[0]
                                            ?.min_salary
                                        }{" "}
                                        -{" "}
                                        {
                                          dashboardData?.willExpiredJobs[0]
                                            ?.max_salary
                                        }{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="expiring__body_detail__info__tags mt-2">
                                    <ul className="d-flex flex-wrap gap-2">
                                      {dashboardData?.willExpiredJobs &&
                                        Array.isArray(
                                          dashboardData?.willExpiredJobs[0]
                                            ?.job_role_text_keywords
                                        ) &&
                                        dashboardData?.willExpiredJobs[0]?.job_role_text_keywords.slice(0, 5).map(
                                          (item, index) => (
                                            <li
                                              className=" filter__content__tag__content mx-0 px-2 rounded-pill text-black"
                                              key={index}
                                            >
                                              <small className="filter__content__tag__content--small text-capitalize darkGrey fm text-12">
                                                {item}
                                              </small>
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ) : (
                            <div className="d-flex justify-content-center w-100 align-items-center"> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="dashboar__expiring__jobs__box my-0 overflow-hidden h-100">
                    <div className="expiring__header fm px-3 pt-3 mb-3">
                      <h5 className="fm fs-5 fw-semibold mb-1">
                        Jobs closed in last 7 days
                      </h5>
                      <p className="fm darkGrey text-14 mb-0">
                        Click below to view candidates
                      </p>
                    </div>
                    <div className="expiring__body ">
                    {dashboardLoading ? (
                        <div className="d-flex justify-content-center w-100 my-3">
                          <Loader size="md" className="pt-4"  />
                      </div>
                      ) : (
                        <>
                      {dashboardData &&
                      dashboardData?.expiredJobs &&
                      dashboardData?.expiredJobs.length > 0 ? (
                        <Link
                          className="py-0 px-0 expiring__body_detail text-decoration-none"
                          to={`/enterprise/dashboard/jobs/${dashboardData?.expiredJobs[0]?._id}`}
                        >
                          <div className="expiring__body_detail justify-content-start border-05 closed__body">
                            <div className="expiring__body_detail_image company__image rounded-2 d-flex justify-content-center align-items-center">
                              <img
                                src={`${userData.companyImage
                                  ? userData.companyImage.docs : '/assets/images/expiring_icon.svg'}`}
                                alt=""
                                className="me-0"
                              />
                            </div>
                            <div>
                              <div className="expiring__body_detail__info">
                                <h4 className="mb-sm-2 mb-1 fm darkGrey">
                                  {dashboardData?.expiredJobs[0]?.job_title}
                                </h4>
                              </div>
                              <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-sm-nowrap flex-wrap justify-content-start">
                                <span className="text-14 me-3 fm mb-1">
                                  {dashboardData?.expiredJobs[0]?.company}{" "}
                                </span>
                                <div className="d-flex">
                                  <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                    <HiOutlineSquare3Stack3D className="me-1" />
                                    {
                                      dashboardData?.expiredJobs[0]?.department
                                    }{" "}
                                  </span>
                                  <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                    <TbCurrencyDollar className="fs-6" />
                                    {
                                      dashboardData?.expiredJobs[0]?.min_salary
                                    }{" "}
                                    -{" "}
                                    {dashboardData?.expiredJobs[0]?.max_salary}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="expiring__body_detail__info__tags mt-2">
                                <ul className="d-flex flex-wrap gap-2">
                                  {dashboardData?.expiredJobs &&
                                    Array.isArray(
                                      dashboardData?.expiredJobs[0]
                                        ?.job_role_text_keywords
                                    ) &&
                                    dashboardData?.expiredJobs[0]?.job_role_text_keywords.slice(0, 5).map(
                                      (item, index) => (
                                        <li
                                          className="mx-0 filter__content__tag__content px-2 rounded-pill text-black"
                                          key={index}
                                        >
                                          <small className="filter__content__tag__content--small text-capitalize fm text-12">
                                            {item}
                                          </small>
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ) : (
                       <div className="d-flex justify-content-center w-100 align-items-center"> <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                      )}
                      </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <div className="enterprise__chart mt-0 h-100">
                    <div className="enterprise__chart__header mb-3">
                      <div>
                        <h5 className="fm fs-6 fw-semibold">Insights</h5>
                      </div>
                      <div className="chart__filter__box">
                        {/* <div>
                          <select
                            value={selectedValue}
                            onChange={handleSelectedChange}
                            className="filter__dropdown fm"
                          >
                            <option value="monthly" className="">
                              Monthly
                            </option>                            
                          </select>
                        </div>
                        <div>
                          <img
                            src="/assets/images/Dashboard/settings_more_unselected.svg"
                            alt=""
                            className="img-fluid candidates__icon2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button class="dropdown-item" type="button">                                
                              </button>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                    <div className="enterprise__chart__body">
                      <button
                        className={`me-sm-2 transition fm pb-2 px-0 ${
                          applicantsTab ? "active" : ""
                        }`}
                        onClick={func_applicants}
                      >
                        Applicants
                      </button>
                      <button
                        className={`transition px-0 ms-2 pb-2 fm ${
                          rolesTab ? "active" : ""
                        }`}
                        onClick={func_roles}
                      >
                        Roles
                      </button>
                      <div className="enterprise__chart__body__tabs">
                        {applicantsTab && (
                          // <>
                          //   <div className="enterprise__chart__body__info">
                          //     <div>
                          //       <h3>{dashboardData?.allApplicants?.length}</h3>
                          //       <p> Total Applicants</p>
                          //     </div>
                          //     {dashboardData && dashboardData?.totalJobs > 0 ? (
                          //       <div>
                          //         {/* <p>Product design role is most popular active role</p> */}
                          //       </div>
                          //     ) : (
                          //       ""
                          //     )}
                          //   </div>
                          //   <div className="enterprise__chart__body__info__box">
                          //     {/* <img src="/assets/images/chart-image.png" /> */}
                          //     {chartData?.labels ? (
                          //       <Bar options={options} data={chartData} />
                          //     ) : (
                          //       ""
                          //     )}
                          //   </div>
                          // </>

                          <div className="d-lg-flex gap-5">
                            <div className="py-3 d-flex flex-column justify-content-between">
                              <div>
                                <h1 className="enterprise__chart__body__tabs__heading fm darkBlue mb-0">
                                  {dashboardData &&
                                      dashboardData?.allApplicants?.length}
                                </h1>
                                <p className="fm grey text-14">
                                  <span className="main">
                                    {/* {dashboardData &&
                                      dashboardData?.allApplicants?.length} */}
                                  </span>{" "}
                                  New applicants
                                </p>
                              </div>
                              <p className="fm mt-3 grey text-14 enterprise__chart__body__tabs__para">
                                {dashboardData && mostPopularRole
                                  ? mostPopularRole +
                                    " " +
                                    "role is most popular active role"
                                  : " "}
                              </p>
                            </div>
                            <div className="dash__graph pt-4 pb-4 d-flex">
                              <div className="d-flex flex-column justify-content-between pe-2">
                                <span className="darkGrey fm text-12 text-end">100</span>
                                <span className="darkGrey fm text-12 text-end">75</span>
                                <span className="darkGrey fm text-12 text-end">50</span>
                                <span className="darkGrey fm text-12 text-end">25</span>
                                <span className="darkGrey fm text-12 text-end">0</span>
                              </div>
                              <div className="dash__graph__inner fm d-flex align-items-end gap-xxl-4 gap-xl-3 gap-4 border-05">
                                {dashboardData &&
                                dashboardData?.applicantsByMonth
                                  ? renderBars(dashboardData?.applicantsByMonth)
                                  : ""}
                              </div>
                            </div>
                          </div>
                        )}

                        {rolesTab && (
                          // <>
                          //   <div className="enterprise__chart__body__info">
                          //     <div>
                          //       <h3 className="fm">30%</h3>
                          //       <p className="fm">1,293 New applicants</p>
                          //     </div>
                          //     <div>
                          //       <p>
                          //         Product design role is most popular active
                          //         role
                          //       </p>
                          //     </div>
                          //   </div>
                          //   <div className="enterprise__chart__body__info__box">
                          //     {chartData && (
                          //       <Bar options={options} data={chartData} />
                          //     )}
                          //   </div>
                          // </>
                          <div className="d-lg-flex gap-5">
                            <div className="py-3 d-flex flex-column justify-content-between">
                              <div>
                                <h1 className="enterprise__chart__body__tabs__heading fm darkBlue mb-0">
                                  {dashboardData &&
                                      dashboardData?.totalJobs}
                                </h1>
                                <p className="fm grey text-14">
                                  <span className="main">
                                    {/* {dashboardData &&
                                      dashboardData?.allApplicants?.length} */}
                                  </span>{" "}
                                  Total jobs
                                </p>
                              </div>
                              <p>{"  "}</p>
                              {/* <p className="fm mt-3 grey text-14 enterprise__chart__body__tabs__para">
                                {dashboardData && mostPopularRole
                                  ? mostPopularRole +
                                    " " +
                                    "role is most popular active role"
                                  : " "}
                              </p> */}
                            </div>
                            <div className="dash__graph pt-4 pb-4 d-flex">
                            <div className="d-flex flex-column justify-content-between pe-2">
                                <span className="darkGrey fm text-12 text-end">100</span>
                                <span className="darkGrey fm text-12 text-end">75</span>
                                <span className="darkGrey fm text-12 text-end">50</span>
                                <span className="darkGrey fm text-12 text-end">25</span>
                                <span className="darkGrey fm text-12 text-end">0</span>
                              </div>
                              <div className="dash__graph__inner d-flex align-items-end gap-xxl-4 gap-xl-3 gap-4 border-05">
                                {dashboardData && dashboardData?.jobsByMonth
                                  ? renderBars(dashboardData?.jobsByMonth)
                                  : ""}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-4 mb-3">
                  <div className="dashboardScreen__chart__box h-100 ">
                    <h4 className="dashboardScreen__chart__count">{candidateData && candidateData.length ? '+' + candidateData.length : '0'
                    }</h4>
                    <p className="dashboardScreen__chart__stats dash__para__text">30-Day Trend</p>
                    <div className="dashboardScreen__chart">
                      <img src={`/assets/images/Dashboard/chart-image.png`} alt="" className="chart-image img-fluid" />
                    </div>
                    <p className="dashboardScreen__chart__history text-14 mt-3 mb-0">Over the past 30 days</p>
                  </div>
                </div>
                <div className="col-md-4 ps-md-0 mb-3">
                  <div className="dashboardScreen_circular__box enterprise d-flex flex-column align-items-center">
                    <div className="enterprise__progress">
                      <GradientSVG />
                      <CircularProgressbar
                        strokeWidth={5}
                        value={val}
                        text={val}
                        // text={`${((val / 850) * 100).toFixed(2)}`}
                        maxValue={850}
                        styles={

                          {
                            path: { stroke: `url(#${idCSS})`, height: "100%" },
                            trail: {
                              stroke: "#EEEEEE",
                            },
                          }

                        }

                      />
                    </div>
                    <p className="dash__para__text text-center">Average IndexScore of your hires</p>
                  </div>
                </div>
                <div className="col-md-4 ps-md-0 mb-3">
                  <div className="dashboardScreen_circular__box enterprise  d-flex flex-column align-items-center">
                    <div className="enterprise__progress">
                      <GradientSVG />
                      <CircularProgressbar
                        strokeWidth={5}
                        value={indexAvg ? indexAvg : 0}
                        text={indexAvg ? indexAvg + '%' : '0'}
                        maxValue={100}
                        styles={

                          {
                            path: { stroke: `url(#${idCSS})`, height: "100%" },
                            trail: {
                              stroke: "#EEEEEE",
                            },
                          }

                        }

                      />
                    </div>
                    <p className="dash__para__text text-center">Average Purpose Alignment percentage of your applicants</p>
                  </div>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-md-4 ">
                  <Link to={'/enterprise/dashboard/jobs'} className="text-decoration-none">
                    <div className="dashboardScreen__active__job_roles outlined__box edashboard__box position-relative">
                      <span>{jobsData.length}</span> <br />
                      <span className="fm fs-6 fw-normal">Active job roles</span>
                      <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                    </div>
                  </Link>
                  <Link to={'/enterprise/dashboard/inbox'} className="text-decoration-none">
                    <div className="dashboardScreen__messabe__box filled__box edashboard__box position-relative">
                      <span>{inboxEmails.length}</span> <br />
                      <span className="fm fs-6 fw-normal">Unread Messages</span>
                      <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 ps-md-0 ">
                  <Link to={'/enterprise/dashboard/jobs'} className="text-decoration-none">
                    <div className="dashboardScreen__active__draft_roles outlined__box edashboard__box position-relative">
                      <span>0</span> <br />
                      <span className="fm fs-6 fw-normal">Drafts</span>
                      <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                    </div>
                  </Link>
                  <Link to={'/enterprise/dashboard/calendar'} className="text-decoration-none">
                    <div className="dashboardScreen__events__box  filled__box edashboard__box position-relative">
                      <span>{appointments ? appointments.length : 0}</span> <br />
                      <span className="fm fs-6 fw-normal">Events </span>
                      <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                    </div>
                  </Link>
                </div>
                <div className="col-md-4 ps-md-0 pb-5">
                  <div className="dashboardScreen__plan__info h-auto">
                    <div>
                      <h4 className="dashboardScreen__plan_stat"><span className="highlight__val">{100 - jobsData.length}</span> / <span>100</span></h4>
                      <p className="dashboardScreen__plan_title">Remaining Jobs</p>
                      <p className="dashboardScreen__plan_expiry">Renews on 1st December 2024</p>
                    </div>
                    <Link to={'/pricing'} className="text-decoration-none"><button className="dashboardScreen__plan__upgrade__button transition w-100">
                      Upgrade plan
                    </button></Link>
                  </div>

                </div>
              </div> */}
              {/* filter starts */}

              {/* filter ends */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
