import React from "react";
import './style.css'
import { BiDotsVerticalRounded } from "react-icons/bi"

const table = [
    {
        id: 1,
        invoice: "Invoice #005",
        date: "Jan 1 2023",
        price: "$XXX",
        plan: "Plan"
    },
    {
        id: 2,
        invoice: "Invoice #005",
        date: "Jan 1 2023",
        price: "$XXX",
        plan: "Plan"
    },
    {
        id: 3,
        invoice: "Invoice #005",
        date: "Jan 1 2023",
        price: "$XXX",
        plan: "Plan"
    },
    {
        id: 4,
        invoice: "Invoice #005",
        date: "Jan 1 2023",
        price: "$XXX",
        plan: "Plan"
    },
    {
        id: 5,
        invoice: "Invoice #005",
        date: "Jan 1 2023",
        price: "$XXX",
        plan: "Plan"
    }
]

const UpgradePlan = () => {
    return (
        <div className="Plan pb-5">
            <div className="Dashboard__container__content">
                <div className="container-dash pt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                        <h5 className="YDetail__heading darkBlue text-center">Our Plans</h5>
                <p className="darkGrey fm text-center">We are talent advocates – we’ve designed a practical solution for members of our community secure opportunities, fast</p>
                        </div>
                    </div>
                </div>
                <div className=" mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-3 h-100">
                            <div className="Plan__card w-100 border rounded p-3 h-100 plan__box h-100 pro current">
                                <div className="plan__info">
                                    <h6 className="plan__heading">PLANT</h6>
                                    <h2 className="plan__pricing">Free</h2>
                                    <p>Establish your score and apply for job opportunities within seconds</p>
                                    <ul>
                                        <li>IndexScore</li>
                                        <li>Purpose Alignment score</li>
                                    </ul>
                                </div>
                                <div className="plan__cta">
                                    <button class="py-2 px-2 w-100 Plan__card__button rounded current">Current</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 h-100">
                            <div className="Plan__card w-100 border rounded p-3 h-100 plan__box pro h-100">
                                <div className="plan__info">
                                    <h6 className="plan__heading">GROW</h6>
                                    <h2 className="plan__pricing">$1000</h2>
                                    <p>Reach hiring managers / recruiters outside of the platform and improve your score with the IndexScore patented recommendation engine</p>
                                    <ul>
                                        <li>IndexScore</li>
                                        <li>Purpose Alignment score</li>
                                        <li>Sharing of IndexScore</li>
                                        <li>IndexScore Improvements</li>
                                        <li class="indent__text">Resume</li>
                                        <li class="indent__text">Social Media Risk Exposure</li>
                                        <li class="indent__text">Assessment</li>
                                        <li class="indent__text">Background Check</li>
                                        <li class="indent__text">Reference Check</li>
                                        <li class="indent__text">Presentation Skill</li>
                                    </ul>
                                </div>
                                <div className="plan__cta">
                                    <button class="py-2 px-2 w-100 Plan__card__button rounded">Upgrade</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpgradePlan;
