import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Input } from 'antd';
import { isEmail, } from '../../Utilities/utilities'
import { updateProfileStatus } from "../../services/auth";
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'rsuite/dist/rsuite.min.css'


const Enterprise = () => {

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();

  const [disabledState, setDisabledState] = useState(false);
  const { id } = useParams();


  useEffect(() => {
    verifyEnterprise();
  }, []);


  const verifyEnterprise = async () => {
    try {

      var payloadProfessional = {
        _id: id,
        usertype: 'enterprise'
      };
      const responseUpdate = await updateProfileStatus(payloadProfessional);

      if (responseUpdate.data.statusCode == 1) {
        enqueueSnackbar(`Verified Successfully`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
      }

    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };


  return (
    <div className="forgot  d-flex align-items-center w-100 pt-5">
      <div className="main-padding w-100 h-100 ">
        <div className="container-xl px-0 py-5">
          <div className="row">
            <div className="col-md-7">
              <div className="p-sm-4 px-3 py-4 fm">
                <h1 className="fw-light fm darkgrey fs-1 mt-4">
                  Welcome!
                </h1>
                <p >
                  Welcome and thank you for verifying your account. Please click the link below to log into your account to complete your setup process.
                </p>
                <p>Still have questions? No problem; you can reach us at <Link className="darkGrey" to={'help@indexscore.com'}>help@indexscore.com</Link></p>


                <Link to={"/log-in"}>
                  <button className={`verification__button fm py-2 px-3 mt-3 `}>Enter IndexScore</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enterprise;
