import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import { Dropdown, Menu, Button, Progress, Radio, Switch,Tooltip } from "antd";
import { HiArrowLeft } from "react-icons/hi";
import { BsThreeDotsVertical, BsCheckCircle } from "react-icons/bs";
import { IoIosLink } from "react-icons/io";
import { CiEdit, CiMail } from "react-icons/ci";
import { PiUploadLight } from "react-icons/pi";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getProfessionalById } from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Country } from "../../../Utilities/Country.js";
import { useSnackbar } from "notistack";
import { IoLocationOutline } from "react-icons/io5";
import { MdVerified } from "react-icons/md";

const ProfileCreation = (props) => {
  console.log("profileView", props);
  const { id } = useParams();
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    updatedProfessionalData();
  }, []);

  const updatedProfessionalData = async () => {
    const payLoadtoGetPro = {
      _id: id,
    };
    console.log("payLoadtoGetPro",payLoadtoGetPro);
    const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    const responseProfessionalData = respProfessionalByID.data.data;
    console.log("responseProfessionalData", responseProfessionalData[0]);
    setProfileData(responseProfessionalData[0]);
  };

  const [check, setCheck] = useState(true);
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <div className="darkGrey fm d-flex fw-light align-items-center">
          <IoIosLink className="mx-0" />
          Copy Share Link
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          className="text-decoration-none"
          to={"/candidate/dashboard/profile/sharing"}
        >
          <div className="darkGrey fm d-flex fw-light align-items-center">
            <CiMail className="mx-0" />
            Send Via Email
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );
  const onChange = (checked) => {
    setCheck(checked);
  };
  return (
    <div className="edash edashprofile">
      <div className="share pb-5">
        {props.profileView ? (
          " "
        ) : (
          <div className="bg-white mb-0 prof border-05 py-2">
            <div className="container-dash ">
              <div className="d-flex justify-content-between">
                <Link to="/candidate/dashboard/account">
                  <div className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center">
                    <HiArrowLeft className="" />
                  </div>
                </Link>

                <div className="d-flex share">
                  <div className="d-flex justify-content-end mb-sm-0 mb-2">
                    <Dropdown
                      overlayClassName={"shareDropdown"}
                      overlay={menu}
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <Button className="fm">
                        <PiUploadLight className="fs-6 me-1" />
                        Share Your Profile
                      </Button>
                    </Dropdown>
                  </div>
                  <div
                    className="fm darkGrey profile__cell mx-1 pointer nowrap d-flex align-items-center"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDotsVertical />
                  </div>
                  <ul class="dropdown-menu py-0 dropdown-menu-end">
                    <li className="py-1 job__dropdown--li px-2">option 1</li>
                    <li className="py-1 job__dropdown--li px-2">option 2</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container-dash mt-4">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="border radius8 p-3 mb-3">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <div className="dashprofile__avatar resume-avatar mx-sm-0 mx-auto border d-flex justify-content-center align-items-center rounded-circle">
                      <img
                        className="PDetail__2__avatar--img p-0 w-100 h-100 "
                        src={`${
                          profileData && profileData.profileImage
                            ? profileData.profileImage.docs
                            : "/assets/images/userAvatar.svg"
                        }
                                  `}
                      />
                    </div>
                  </div>
                  <div>
                    {profileData && (
                      <h3 className="fm  fw-semibold text-35 mb-2 me-2">
                        {profileData.firstName + " " + profileData.lastName}{" "}
                        <Tooltip
                            title="This profile is verified"
                            placement="top"
                          >
                            <MdVerified className="main ms-1" fontSize={"large"} />
                          </Tooltip>
                        
                      </h3>
                    )}
                    {profileData && (
                      <h4 className="my-0 text-14 mb-2 d-flex align-items-center">
                        {" "}
                        <IoLocationOutline />{" "}
                        {profileData.state + ", " + Country.find(
                                    (country) =>
                                      country.code === profileData.country
                                  )?.name}
                      </h4>
                    )}
                    <span className="tag-completed rounded-pill text-12 main align-items-center fm">
                      Available
                    </span>
                  </div>
                </div>

                {/* <Progress percent={50} strokeColor={"#A6DD4F"} /> */}

                {/* <div className="mt-4 radius8 share__greenbox p-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <h6 className="fm fs-6 my-0 fw-semibold">Recommended</h6>
                    <h6 className="fm fs-6 my-0 fw-semibold">4/4</h6>
                  </div>

                  <ul className="list-unstyled m-0 mt-3">
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Work Experience</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Refrences</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Education</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-1 text-14">
                      <span className="fm main">LinkedIn URL</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                  </ul>
                </div>
                <div className="mt-4 radius8 p-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <h6 className="fm fs-6 my-0 fw-semibold">Additional</h6>
                    <h6 className="fm fs-6 my-0 fw-semibold">3/5</h6>
                  </div>

                  <ul className="list-unstyled m-0 mt-3">
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">{userData && userData?.title}</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Elevator Video</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Open Questions</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm darkGrey">Socials</span>
                      <Radio value={1} className="me-0" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-1 text-14">
                      <span className="fm darkGrey">Awards / Notable work</span>
                      <Radio value={1} className="me-0" />
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="border radius8 p-3 mb-3">
                <h4 className="fm  fw-semibold fs-5 mb-0 me-2 mt-3 mb-3">
                  Elevator Video
                </h4>

                <div className="public__profile__company px-3y">
                  <iframe
                    frameborder="0"
                    className="w-100 h-100 rounded-4 "
                    src={profileData && profileData?.elevatorPitch?.file}
                    title="2"
                  ></iframe>
                </div>
                {/* <Progress percent={50} strokeColor={"#A6DD4F"} /> */}

                {/* <div className="mt-4 radius8 share__greenbox p-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <h6 className="fm fs-6 my-0 fw-semibold">Recommended</h6>
                    <h6 className="fm fs-6 my-0 fw-semibold">4/4</h6>
                  </div>

                  <ul className="list-unstyled m-0 mt-3">
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Work Experience</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Refrences</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Education</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-1 text-14">
                      <span className="fm main">LinkedIn URL</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                  </ul>
                </div>
                <div className="mt-4 radius8 p-3">
                  <div className="d-flex justify-content-between  align-items-center">
                    <h6 className="fm fs-6 my-0 fw-semibold">Additional</h6>
                    <h6 className="fm fs-6 my-0 fw-semibold">3/5</h6>
                  </div>

                  <ul className="list-unstyled m-0 mt-3">
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">{userData && userData?.title}</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Elevator Video</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm main">Open Questions</span>
                      <BsCheckCircle className="main fs-6" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-2 text-14">
                      <span className="fm darkGrey">Socials</span>
                      <Radio value={1} className="me-0" />
                    </li>
                    <li className="d-flex justify-content-between align-items-center mb-1 text-14">
                      <span className="fm darkGrey">Awards / Notable work</span>
                      <Radio value={1} className="me-0" />
                    </li>
                  </ul>
                </div> */}
              </div>
              
              <button className="btn--main radius8 py-2 w-100 fm text-14">
                Hire this candidate
              </button>

              {/* <div className="d-flex justify-content-between gap-1 mt-3">
                {check ? (
                  <span className="fm fw-semibold text-14">
                    Your profile is live, you can hide your profile here:
                  </span>
                ) : (
                  <span className="fm fw-semibold text-14">
                    Your profile is Hidden, set you profile live here:
                  </span>
                )}
                <Switch defaultChecked onChange={onChange} />
              </div> */}
            </div>

            <div className="col-lg-8">
              <div className="d-flex">
                {/* <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                  {userData && userData?.title}
                </h4> */}
                {/* <span className="tag-completed rounded-pill text-12 main d-flex align-items-center fm">
                  Completed <BsCheckCircle className="ms-1" />
                </span> */}
              </div>

              {/* <p className="darkGrey fm mt-2 w-md-80 prof__para">
                {userData && userData?.summary}
              </p> */}

              <div className="radius8 border p-3">
                <div className="d-flex justify-content-between mb-2">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                    {profileData && profileData?.title}
                  </h4>
                  {/* <button className="edit-image d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                    <CiEdit className="darkGrey me-1 fs-6" />
                    Edit
                  </button> */}
                </div>

                <p className="fm darkGrey w-md-90 prof__para">
                  {profileData && profileData?.summary}
                </p>
              </div>

              {/* work experience */}
              <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                    Work Experience
                  </h4>
                  {/* <span className="tag-completed rounded-pill text-12 main d-flex align-items-center fm">
                  Completed <BsCheckCircle className="ms-1" />
                </span> */}
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque
                  temporibus excepturi, omnis nisi inventore voluptate
                  laboriosam qui, non distinctio tenetur dolores accusantium
                  dolorem suscipit a est unde eos earum illo?
                </p>

                <div className="radius8 border p-3">
                {profileData && profileData.workExperience && profileData.workExperience.map((experience, index) => (
    <div key={index} className="work__exp__box__item__content">
        <div className="work__exp__box__item__image">
            <img
                src={`/assets/images/EnterpriseAvatar.svg`}
                alt=""
                className="profile-company-avatar border-0 rounded-0"
            />
        </div>
        <div className="d-flex justify-content-between w-100 align-items-start">
            <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                <div className="">
                    <div className="d-flex justify-content-between align-items-start">
                        <h5 className="fm me-4 nowrap mb-0 fs-6 fw-semibold text-capitalize">
                            {experience.title} - {experience.companyName}
                        </h5>
                    </div>
                    <p className="fm darkGrey prof__para mt-1 w-md-90">
                        {experience.description}
                    </p>
                </div>
                <div className="filter__content__tag__content filter__content__tag__content rounded-pill px-2 mt-3">
                    <small className="filter__content__tag__content--small text-12 filter__content__tag__content fm text-capitalize">
                        {experience.startDate} - {experience.endDate ? experience.endDate : "Present"}
                    </small>
                </div>
            </div>
        </div>
    </div>
))}

                </div>

                {/* <button className="border-0 bg-white d-flex darkGrey align-items-center mt-2">
                  <img
                    src="/assets/images/icon_plus_dark.svg"
                    alt=""
                    className=" plus__icon me-2"
                  />
                  Add more work experience
                </button> */}
              </div>

              {/* eductaion */}
              <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">Education</h4>
                  {/* <span className="tag-completed rounded-pill text-12 main d-flex align-items-center fm">
                  Completed <BsCheckCircle className="ms-1" />
                </span> */}
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic
                  laboriosam vero obcaecati asperiores iure, eaque quae expedita
                  rerum saepe sed ab labore. Nobis aliquam iure minus numquam!
                  Fugit, voluptatibus? Vel.
                </p>

                <div className="radius8 border p-3">
                  <div className="work__exp__box__item__content d-flex align-items-center">
                    <div className="work__exp__box__item__image">
                      <img
                        src={`/assets/images/EnterpriseAvatar.svg`}
                        alt=""
                        className="profile-company-avatar border-0 rounded-0"
                      />
                    </div>
                    <div className="d-flex justify-content-between w-100 align-items-start">
                      <div className="work__exp__box__company__info work__exp__box__item__duration pe-0 ">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-start">
                            <h3 className="fm me-4  mb-0 fs-6 fw-semibold text-capitalize">
                              {profileData && profileData?.education}
                            </h3>
                            {/* <button className="edit-image d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Edit
                            </button> */}
                          </div>
                          {/* <p className="fm darkGrey prof__para mt-2 w-md-90">
                            Description of job/position here of job/position
                            here Description of job/position. of job/position
                            here of job/position here Description of
                            job/position. Description of job/position here of
                            job/position here Description of
                            job/position.Description of job/position here of
                            job/position here Description of job/position. of
                            job/position here of job/position here Description
                            of job/position. Description of job/position here of
                            job/position here Description of job/position.
                          </p> */}
                        </div>
                        {/* <div className="filter__content__tag__content filter__content__tag__content rounded-pill px-2 mt-3">
                          <small className="filter__content__tag__content--small text-12 filter__content__tag__content fm text-capitalize">
                            2017 - Present
                          </small>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <button className="border-0 bg-white d-flex darkGrey align-items-center mt-2">
                  <img
                    src="/assets/images/icon_plus_dark.svg"
                    alt=""
                    className=" plus__icon me-2"
                  />
                  Add more education
                </button> */}
              </div>

              {/* reference */}
              {/* <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">References</h4>                  
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure
                  qui quas sit id accusamus iste quaerat saepe ratione soluta
                  illum commodi quis expedita numquam vitae ducimus nesciunt,
                  molestias in libero.
                </p>

                <div className="radius8 border p-3">
                  <div className="work__exp__box__item__content">
                    <div className="d-flex justify-content-between w-100 align-items-start">
                      <div className="work__exp__box__company__info ps-0 work__exp__box__item__duration pe-0 w-md-90">
                        <div className="">
                          <h5 className="fm me-4  mb-0 fs-6 fw-semibold text-capitalize">
                            Name – company
                          </h5>
                          <p className="fm darkGrey prof__para mt-2 mb-0">
                            Email@email.com
                          </p>
                          <p className="fm darkGrey prof__para mt-1 mb-0">
                            07810274855
                          </p>
                        </div>
                        <div className="filter__content__tag__content filter__content__tag__content rounded-pill px-2 mt-3">
                          <small className="filter__content__tag__content--small text-12 filter__content__tag__content fm text-capitalize">
                            2017 - Present
                          </small>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <button className="border-0 bg-white d-flex darkGrey align-items-center mt-2">
                  <img
                    src="/assets/images/icon_plus_dark.svg"
                    alt=""
                    className=" plus__icon me-2"
                  />
                  Add another reference
                </button>
              </div> */}

              {/* question */}
              {/* <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">Questions</h4>                
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Ducimus placeat voluptas quos dolores similique ipsa
                  aspernatur, quae quis reiciendis. Itaque corrupti eius non
                  architecto amet? Quam dignissimos debitis excepturi
                  laboriosam!
                </p>

                <div className="radius8 border p-3">
                  <div className="work__exp__box__item__content">
                    <div className="d-flex justify-content-between w-100 align-items-start">
                      <div className="work__exp__box__company__info ps-0 work__exp__box__item__duration pe-0">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-start">
                            <h5 className="fm me-4  mb-0 fs-6 fw-semibold text-capitalize">
                              Question Title
                            </h5>
                            <button className="edit-image d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Edit
                            </button>
                          </div>
                          <p className="fm darkGrey prof__para mt-2 w-md-90">
                            Description of job/position here of job/position
                            here Description of job/position. of job/position
                            here of job/position here Description of
                            job/position. Description of job/position here of
                            job/position here Description of
                            job/position.Description of job/position here of
                            job/position here Description of job/position. of
                            job/position here of job/position here Description
                            of job/position. Description of job/position here of
                            job/position here Description of job/position.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button className="border-0 bg-white d-flex darkGrey align-items-center mt-2">
                  <img
                    src="/assets/images/icon_plus_dark.svg"
                    alt=""
                    className=" plus__icon me-2"
                  />
                  Add more questions
                </button>
              </div> */}

              {/* video */}
              {/* <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                    Elevator Video
                  </h4>                 
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut
                  cupiditate dignissimos repellat dolor suscipit accusamus,
                  eaque minus sequi illo molestiae quidem obcaecati fugit atque
                  quasi sint voluptatem tempore qui id.
                </p>

                <div className="radius8 border p-3">
                  <div className="d-flex justify-content-between w-100 align-items-start">
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/images/doc_img.png"
                        alt=""
                        className="doc__img me-3"
                      />
                      <span className="fm darkGrey prof__para">
                        Video–file–name–here.mp4
                      </span>
                    </div>
                    <button className="edit-image d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                  </div>
                </div>
              </div> */}

              {/* award */}
              <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                    Awards / Certifications
                  </h4>                  
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae, non omnis tempore odio dolorem provident sit ipsam
                  delectus porro at optio atque ratione qui veritatis expedita
                  officiis. Magni, rem quod.
                </p>

                <div className="radius8 border p-3">
                  {/* <div className="work__exp__box__item__content">
                    <div className="work__exp__box__item__image">
                      <img
                        src={`/assets/images/EnterpriseAvatar.svg`}
                        alt=""
                        className="profile-company-avatar border-0 rounded-0"
                      />
                    </div>
                    <div className="d-flex justify-content-between w-100 align-items-start">
                      <div className="work__exp__box__company__info work__exp__box__item__duration pe-0 ">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-start">
                            <h5 className="fm me-4  mb-0 fs-6 fw-semibold text-capitalize">
                              Job title – Company
                            </h5>
                          </div>
                          <p className="fm darkGrey prof__para mt-2 w-md-90">
                            Description of job/position here of job/position
                            here Description of job/position. of job/position
                            here of job/position here Description of
                            job/position. Description of job/position here of
                            job/position here Description of
                            job/position.Description of job/position here of
                            job/position here Description of job/position. of
                            job/position here of job/position here Description
                            of job/position. Description of job/position here of
                            job/position here Description of job/position.
                          </p>
                        </div>
                        <div className="filter__content__tag__content filter__content__tag__content rounded-pill px-2 mt-3">
                          <small className="filter__content__tag__content--small text-12 filter__content__tag__content fm text-capitalize">
                            2017 - Present
                          </small>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <ul className="certifications__list my-0">
                  {profileData && profileData.certifications &&
                              profileData.certifications?.length > 0
                                ? profileData?.certifications.map(
                                    (item, index) => <li className="fm text-14 darkGrey my-1">{item.name}</li>
                                  )
                                : "--"}
                   </ul>                                
                </div>               
              </div>

              {/* Skills */}
              <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">
                    Skills
                  </h4>                  
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Molestiae, non omnis tempore odio dolorem provident sit ipsam
                  delectus porro at optio atque ratione qui veritatis expedita
                  officiis. Magni, rem quod.
                </p>

                <div className="radius8 border p-3">                  
                  <ul className="tags my-0 d-flex flex-wrap align-items-start gap-2">
                    {profileData && profileData?.skills ? (
                      profileData.skills.map((item, index) => (
                        <li className="tags-list me-0 d-flex text-14 text-capitalize align-items-center" key={index}>
                          {item}
                        </li>
                      ))
                    ) : (
                      <li className="fm text-14">No tags available</li>
                    )}
                    </ul>
                </div>               
              </div>

              {/* links */}
              {/* <div>
                <div className="d-flex mt-5">
                  <h4 className="fm  fw-semibold fs-5 mb-0 me-2">Links</h4>                  
                </div>

                <p className="darkGrey fm mt-2 w-md-80 prof__para">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Possimus ab unde consectetur asperiores obcaecati praesentium
                  enim sunt, repellendus necessitatibus cupiditate vero dolores
                  eligendi, aspernatur debitis dicta adipisci eaque animi quia!
                </p>

                <div className="radius8 border p-3">
                  <div className="d-flex justify-content-between w-100 align-items-start">
                    <div className="work__exp__box__company__info ps-0 work__exp__box__item__duration pe-0 w-md-90">
                      <div className="">
                        <h5 className="fm me-4  fs-6 fw-semibold text-capitalize pb-2">
                          LinkedIn:{" "}
                          <span className="fw-light fm">
                            linkedin.com/profile/me
                          </span>
                        </h5>

                        <h5 className="fm me-4 nowrap fs-6 fw-semibold text-capitalize">
                          Other links:
                        </h5>
                        <a
                          target="_blank"
                          href="#"
                          className="fm darkGrey prof__para mb-1 text-decoration-none transition d-block"
                        >
                          instagram.com/profile/me
                        </a>
                        <a
                          target="_blank"
                          href="#"
                          className="fm darkGrey prof__para mb-1 text-decoration-none transition d-block"
                        >
                          Twitter.com/profile/me
                        </a>
                        <a
                          target="_blank"
                          href="#"
                          className="fm darkGrey prof__para mb-1 text-decoration-none transition d-block"
                        >
                          facebook.com/profile/me
                        </a>
                      </div>
                    </div>                    
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCreation;
