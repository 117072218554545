import React, { useState, useEffect, useContext, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import "./style.css";
import {
  Avatar,
  Progress,
  Dropdown,
  Menu,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  message,
  DatePicker,
} from "antd";
import { Loader, useToaster, Uploader, Message } from "rsuite";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CiEdit } from "react-icons/ci";
import { BsPlusCircle, BsCheckCircle, BsDownload } from "react-icons/bs";
import { AiFillLike, AiOutlineDelete } from "react-icons/ai";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { isLinkedIn, isTwitter } from "../../../../Utilities/utilities";
import { genderList } from "../../../../Utilities/Genders.js";
import { degreesList } from "../../../../Utilities/Degrees.js";
import { EthnicitiesList } from "../../../../Utilities/Ethnicities.js";
import { Country } from "../../../../Utilities/Country.js";
import skillsData from "../../../../Utilities/skills.json";
import Persona from "persona";
import environment from '../../../../environment/index.js';
import config from "../../../../services/apisConfig";
import {
  getProfessionalById,
  getEnterprises,
  downloadApplicantsFeedback,
  updateProfessional,
  uploadprofileImage,
  questionParser,
  uploadVideo,
  uploadFile,
  accurtateCreateCandidate,
  accurtateCreateOrder,
  authorizeLinkedinUser,
  instaAuthorize
} from "../../../../services/auth";
import { createOrder } from "../../../../services/criteria";
import { useSnackbar } from "notistack";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Typography,
  Box,
} from "@mui/material";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="m-2"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const YDetail = () => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [progress, setProgress] = useState(
    userData?.progress ? userData?.progress?.total : 0
  );
  const { enqueueSnackbar } = useSnackbar();
  const { Option, OptGroup } = Select;
  const skills = skillsData.skills;
  const [options, setOptions] = useState([
    "Energy",
    "Technology",
    "Financial Sevices",
    "Helath Care",
    "Telecommunications",
    "Basic Materials",
    "Industrials",
    "Services",
    "Food Industry",
    "Fashion",
    "Animal",
  ]);
  const optionsArray = [
    {
      values: [
        { name: "Petroleum products and oil" },
        { name: "Natural gas" },
        { name: "Gasoline" },
        { name: "Diesel fuel" },
        { name: "Heating oil" },
        { name: "Nuclear" },
        { name: "Hydropower" },
        { name: "Biofuels such as ethanol" },
        { name: "Wind power" },
        { name: "Solar power" },
        { name: "Chemicals" },
      ],
    },
    {
      values: [
        { name: "Software and services" },
        { name: "Digital Transformation" },
        { name: "Computers and Information" },
        { name: "Technology hardware and equipment " },
        { name: "Semiconductors " },
        { name: "Data Processing & Outsourced Services" },
        { name: "Communication Technology" },
        { name: "Medical Technology" },
        { name: "Product Technology" },
        { name: "Business Technology" },
        { name: "Educational Technology" },
        { name: "Information Technology" },
        { name: "Construction Technology" },
      ],
    },
    {
      values: [
        { name: "Real estate" },
        { name: "Investment" },
        { name: "Trade" },
        { name: "Accounting" },
        { name: "Insurance " },
        { name: "Credit" },
        { name: "Commercial  Banking" },
        { name: "Foreign exchange" },
        { name: "Stock market" },
        { name: "Audit & Advisory" },
        { name: "Wealth Management" },
        { name: "Mortgage" },
        { name: "Capital Markets" },
        { name: "Brokerage" },
        { name: "Consumer Finance" },
        { name: "Financial Exchanges & Data" },
      ],
    },
    {
      values: [
        { name: "Pharmaceutical" },
        { name: "Hospital" },
        { name: "biotechnology" },
        { name: "Medical  equipment" },
        { name: "distribution" },
        { name: "hospital supplies manufacturers" },
      ],
    },
    {
      values: [
        { name: "Entertainment" },
        { name: "Movies" },
        { name: "Music" },
        { name: "Media" },
      ],
    },
    {
      values: [{ name: "Garden" }, { name: "Home" }],
    },
    {
      values: [
        { name: "Mining" },
        { name: "Automotive" },
        { name: "Steel" },
        { name: "Auto Parts & Accessories" },
        { name: "Electronics" },
        { name: "Tools & Equipment" },
        { name: "Toys" },
        { name: "fertilizers" },
      ],
    },
    {
      values: [
        { name: "Academic Services" },
        { name: "Active Life Services" },
        { name: "Agriculutural Services" },
        { name: "Amusement & Recreation Services" },
        { name: "Arts & Marketing Services" },
        { name: "Arts Services" },
        { name: "Automotive Services" },
        { name: "Beauty Services" },
        { name: "Business Services" },
        { name: "Children & Baby Services" },
        { name: "Construction Services" },
        { name: "Consulting Services" },
        { name: "Delivery Services" },
        { name: "Electronic/Technology Services" },
        { name: "Engineering & Management Services" },
        { name: "Entertainment Services" },
        { name: "Concierge Service" },
        { name: "Event Planning Services" },
        { name: "Fashion Services" },
        { name: "Health & Fitness Services" },
        { name: "Health & Medical Services" },
        { name: "Food & Nutrition Services" },
        { name: "Finance Services" },
        { name: "Home Services" },
        { name: "Hospitality Services" },
        { name: "Information Services" },
        { name: "Legal Services" },
        { name: "Lifestyle Services" },
      ],
    },
    {
      values: [
        { name: "Sale and marketing" },
        { name: "Market research" },
        { name: "Content marketing" },
        { name: "Database marketing" },
        { name: "Social media marketing" },
        { name: "Influencer marketing." },
        { name: "Event management" },
      ],
    },
    {
      values: [
        { name: "Books" },
        { name: "Early education" },
        { name: "Primary education" },
        { name: "Secondary" },
        { name: "technical" },
        { name: "vocational" },
        { name: "stationary" },
      ],
    },
    {
      values: [
        { name: "Food & Nutrition" },
        { name: "Hotel" },
        { name: "Restaurant" },
        { name: "beverage" },
        { name: "Dairy" },
        { name: "Fruit and Vegitables" },
        { name: "sugar and confectionery" },
        { name: "Grain an oilseeds" },
        { name: "Bakeries" },
        { name: "Agriculture" },
      ],
    },
    {
      values: [
        { name: "Beauty & Cosmetics" },
        { name: "Lifestyle " },
        { name: "fabrics " },
        { name: "leather Industry" },
        { name: "Footwear" },
      ],
    },
    {
      values: [
        { name: "Pet" },
        { name: "Live stock" },
        { name: "Animal Breeding" },
        { name: "Farming" },
        { name: "Food" },
        { name: "Animal husbandry" },
      ],
    },
    {
      values: [{ name: "sport products" }],
    },
    {
      values: [
        { name: "transport services" },
        { name: "accommodation" },
        { name: "food and drink establishments" },
        { name: "travel agencies" },
        { name: "transport rental and cultural" },
        { name: "sport and recreation services" },
      ],
    },
    {
      values: [{ name: "Bricks" }, { name: "Steel" }, { name: "Cheimical" }],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [visible, setVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [editNameVisible, setEditNameVisible] = useState(false);
  const [editContactVisible, setEditContactVisible] = useState(false);
  const [resumeVisible, setResumeVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [editEducationVisible, setEditEducationVisible] = useState(false);
  const [editAreaFocusVisible, setEditAreaFocusVisible] = useState(false);
  const [editSkillsVisible, setEditSkillsVisible] = useState(false);
  const [loadingInstagram, setLoadingInstagram] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [updateSocialsLoader, setUpdateSocialsLoader] = useState(false);
  const [editSocialsVisible, setEditSocialsVisible] = useState(false);
  const [editAboutYouVisible, setEditAboutYouVisible] = useState(false);
  const [updateProAboutYouLoader, setUpdateProAboutYouLoader] = useState(false);
  const [state, setState] = useState({
    selectedSkills: userData.skills ? userData.skills : [],
    companyLinkedin: userData.linkedinPublicUrl
      ? userData.linkedinPublicUrl
      : " ",
    companyLinkedinData: "",
    twitterURL: "",
    twitterData: "",
    loading: false,
    loading5: false,
    loadingLinkedin: false,
    instagramURL: "",
    loading2: false,
    instagramData: {},
    facebookURL: "",
    facebookData: "",
    loading3: false,
    loading4: false,
    tiktokURL: "",
  });
  const {
    selectedSkills,
    showSkills,
    skilllist,
    email,
    searchInput,
    loading5,
    loadingLinkedin,
    companyLinkedin,
    companyLinkedinData,
    twitterURL,
    twitterData,
    instagramURL,
    instagramData,
    facebookURL,
    facebookData,
    tiktokURL,
  } = state;

  const [emails, setEmails] = useState(
    userData.references
      ? userData.references.map((email) => ({
          referenceEmail: email.referenceEmail,
          referencefirstName: email.referencefirstName,
          referencelastName: email.referencelastName,
        }))
      : [{ referenceEmail: "", referencefirstName: "", referencelastName: "" }]
  );

  const [isEmailUpdate, setIsEmailUpdate] = useState(false);
  const [isReferenceUpdate, setIsReferenceUpdate] = useState(false);

  const [questionsLoading, setQuestionsLoading] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleChangeSkills = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !companyLinkedin || !facebookURL || !facebookData || !selectedSkills;
  isLinkedIn(companyLinkedin);

  const [videoFile, setVideoFile] = useState(null);
  const [videoUrlLoading, setVideoUrlLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(
    userData ? userData.phoneNumber : ""
  );
  const [updateAddress, setUpdateAddress] = useState(
    userData ? userData.address : ""
  );
  const [updateTitle, setUpdateTitle] = useState(
    userData ? userData.title : ""
  );
  const [updateFirstName, setUpdateFirstName] = useState(
    userData ? userData.firstName : ""
  );
  const [updateLastName, setUpdateLastName] = useState(
    userData ? userData.lastName : ""
  );
  const [updateDob, setUpdateDob] = useState(userData ? userData.dob : "");
  const [updateGender, setUpdateGender] = useState(
    userData ? userData.gender : ""
  );
  const [updateEthnicity, setUpdateEthnicity] = useState(
    userData ? userData.ethnicity : ""
  );
  const [updateCountry, setUpdateCountry] = useState(
    userData ? userData.country : ""
  );
  const [updateState, setUpdateState] = useState(
    userData ? userData.state : ""
  );
  const [updatePostalCode, setUpdatePostalCode] = useState(
    userData ? userData.postalCode : ""
  );
  const [errorState, setErrorState] = useState(false);

  const [selectedCertification, setSelectedCertification] = useState("");
  const [updateAchievements, setUpdateAchievements] = useState(
    userData ? userData.acheivementsUrl : ""
  );
  const [updateEducation, setUpdateEducation] = useState(
    userData ? userData.education : ""
  );
  const [addCertificateLoader, setAddCertificateLoader] = useState(false);

  const [resumeFile, setResumeFile] = useState(null);
  const [resumeFileLoader, setResumeFileLoader] = useState(false);

  const [updateContactLoader, setUpdateContactLoader] = useState(false);
  const [contactError, setContactError] = useState("");

  const [updateSector, setUpdateSector] = useState(
    userData ? userData.sector : ""
  );
  const [updateAboutYou, setUpdateAboutYou] = useState(
    userData ? userData.summary : ""
  );

  const [updateLinkedin, setUpdateLinkedin] = useState(
    userData ? userData?.userChannels?.linkedin : ""
  );
  const [updateInstaLink, setUpdateInstaLink] = useState(
    userData ? userData?.userChannels?.insta : ""
  );
  const [updateTwitterLink, setUpdateTwitterLink] = useState(
    userData ? userData?.userChannels?.twitter : ""
  );

  const [updateAboutYouLoader, setUpdateAboutYouLoader] = useState(false);

  const [updateIndustry, setuUpdateIndustry] = useState(
    userData ? userData.industry : ""
  );
  const [updateBusiness, setUpdateBusiness] = useState(
    userData ? userData.businessFunction : ""
  );
  const [updateSalaryRange, setUpdateSalaryRange] = useState(
    userData ? userData.salaryRange : ""
  );
  const [updateAreaFocusLoader, setUpdateAreaFocusLoader] = useState(false);
  const [areaFocusError, setAreaFocusError] = useState("");
  const [searchInputs, setSearchInputs] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [accurateLoader, setAccurateLoader] = useState(false);
  const [criteriaLoader, setCriteriaLoader] = useState(false);
  const [workExperience, setWorkExperience] = useState(
    userData.workExperience ? userData.workExperience : []
  );

  const filteredSkills = skills.filter((skill) => {
    return skill.toLowerCase().includes(searchInputs.toLowerCase());
  });

  const [updateEducationLoader, setUpdateEducationLoader] = useState(false);
  const [educationError, setEducationError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const [updateWorkExpCompanyName, setUpdateWorkExpCompanyName] = useState("");
  const [updateWorkExpTitle, setUpdateWorkExpTitle] = useState("");
  const [updateWorkExpCountry, setUpdateWorkExpCountry] = useState("");
  const [updateWorkExpCity, setUpdateWorkExpCity] = useState("");
  const [updateWorkExpStartDate, setUpdateWorkExpStartDate] = useState("");
  const [updateWorkExpEndDate, setUpdateWorkExpEndDate] = useState("");
  const [updateWorkExpDescription, setUpdateWorkExpDescription] = useState("");
  const [updateWorkExpLoader, setUpdateWorkExpLoader] = useState(false);
  const [editWorkExpVisible, setEditWorkExpVisible] = useState(false);

  const EditWorkExpModal = () => {
    setEditWorkExpVisible(true);
  };
  const EditWorkExphandleOk = () => {
    setEditWorkExpVisible(false); // Close the modal after submission
  };
  const EditWorkExphandleCancel = () => {
    setEditWorkExpVisible(false); // Close the modal without saving changes
  };

  const handleTabClick = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  const handleSnackbar = (message, variant = "success") => {
    enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };

  const handleSuccessSnackbar = (message) => handleSnackbar(message, "success");

  const handleEmailReadySnackbar = () => {
    handleSnackbar("We will send you an email once index score is ready.");
  };

  const handleResponse = (response, successMessage) => {
    if (response.status === 200) {
      updatedProfessionalData();
      handleSuccessSnackbar(successMessage);
      if (response.data.profileStatus === 100) {
        handleEmailReadySnackbar();
      }
    }
  };

  const updateHandler = async (
    payload,
    loaderState,
    visibilityState,
    successMessage
  ) => {
    loaderState(true);
    try {
      const response = await updateProfessional(payload);
      if (response.data.statusCode === 1) {
        handleResponse(response, successMessage);
        visibilityState(false);
      }
    } finally {
      loaderState(false);
    }
  };

  const updatedProfessionalData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    const responseProfessionalData = respProfessionalByID.data.data;
    localStorage.setItem(
      "professionalData",
      JSON.stringify(respProfessionalByID.data.data)
    );
    setUserData(responseProfessionalData);
    setProgress(responseProfessionalData[0]?.progress?.total);
  };

  const uploadPhoto = async () => {
    if (!imageUrl) return;

    setImageLoading(true);
    const formData = new FormData();
    formData.append("files", imageUrl.blobFile);
    formData.append("_id", userData._id);
    formData.append("usertype", "professional");

    try {
      const response = await uploadprofileImage(formData);
      if (response.status === 200) {
        handleResponse(response, "Photo Updated!");
        setPhotoVisible(false);
        setImageUrl(null);
      }
    } catch (error) {
      console.error("Image upload error:", error);
    } finally {
      setLoading(false);
      setImageLoading(false);
    }
  };

  const updateProContactHandler = async () => {
    setContactError("");
    const payload = {
      _id: userData._id,
      title: updateTitle,
      phoneNumber: updatePhoneNumber,
      firstName: updateFirstName,
      lastName: updateLastName,
      dob: updateDob,
      gender: updateGender,
      ethnicity: updateEthnicity,
    };
    await updateHandler(
      payload,
      setUpdateContactLoader,
      setEditContactVisible,
      "Personal Information Updated!"
    );
  };

  const updateProEducationHandler = async () => {
    const payload = {
      _id: userData._id,
      education: updateEducation,
      acheivementsUrl: updateAchievements,
    };
    await updateHandler(
      payload,
      setUpdateEducationLoader,
      setEditEducationVisible,
      "Education Updated!"
    );
  };

  const updateProAreaFocusHandler = async () => {
    const payload = {
      _id: userData._id,
      sector: updateSector,
      industry: updateIndustry,
      businessFunction: updateBusiness,
      salaryRange: updateSalaryRange,
    };
    await updateHandler(
      payload,
      setUpdateAreaFocusLoader,
      setEditAreaFocusVisible,
      "Area Focus Updated!"
    );
  };

  const handleDownload = async (pdfUrl) => {
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume.pdf");
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const handleResumeUpload = async () => {
    setResumeFileLoader(true);
    const formData = new FormData();
    formData.append("_id", userData._id);
    formData.append("usertype", "professional");
    formData.append("files", resumeFile[0].blobFile);

    try {
      const resp = await uploadFile(formData);
      if (resp.status === 200) {
        localStorage.setItem(
          "professionalData",
          JSON.stringify([resp.data.data])
        );
        setUserData([resp.data.data]);
        setResumeFile([]);
        handleSuccessSnackbar("Resume updated successfully");
      }
    } finally {
      setResumeFileLoader(false);
      setResumeVisible(false);
    }
  };

  const handleUpdateClick = async () => {
    const areFieldsValid = Object.values(textAreaValues).every(
      (value) => value.trim() !== ""
    );

    if (!areFieldsValid) {
      enqueueSnackbar("All questions must be answered", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }

    setQuestionsLoading(true);
    const updatedValues = {
      question1: textAreaValues.question1.trim(),
      question2: textAreaValues.question2.trim(),
      question3: textAreaValues.question3.trim(),
      question4: textAreaValues.question4.trim(),
      question5: textAreaValues.question5.trim(),
    };
    const payload = {
      _id: userData._id,
      openAnswers: {
        openQ1: updatedValues.question1,
        openQ2: updatedValues.question2,
        openQ3: updatedValues.question3,
        openQ4: updatedValues.question4,
        openQ5: updatedValues.question5,
      },
    };
    try {
      const response = await updateProfessional(payload);
      if (response.status === 200) {
        handleSuccessSnackbar("Questions Updated!");
        setIsEditing(false);
        setQuestionsLoading(false);
        updateCriteriaHandler();
        updatedProfessionalData();
      }
    } catch (error) {
      console.error("Error updating questions:", error);
    }
  };

  const handleClose = () => {
    handleChangeSkills("showSkills", false);
  };
  useEffect(() => {
    getDropDownLists();
  }, []);

  const getDropDownLists = async () => {
    try {
      handleChangeSkills("loading", true);
      handleChangeSkills("skilllist", skillsData.skills);
      handleChangeSkills("loading", false);
    } catch (error) {
      handleChangeSkills("loading", false);
    }
  };
  const updateProSkillsHandler = async () => {
    const payload = {
      _id: userData._id,
      skills: selectedSkills,
    };
    await updateHandler(payload, handleClose, null, "Skills Updated!");
  };

  const handleChange = (key, value) => {
    if (key === "files" && value.length > 0) {
      const lastIndex = value.length - 1;
        const lastValue = value[lastIndex];
      setImageUrl(lastValue);
    }
  };
  function handleEmailChange(index, key, value) {
    const updatedEmails = [...emails];
    updatedEmails[index][key] = value;
    setEmails(updatedEmails);
    setIsEmailUpdate(true);
  }

  function addEmail() {
    if (emails.length < 3) {
      setEmails([
        ...emails,
        { referenceEmail: "", referencefirstName: "", referencelastName: "" },
      ]);
    }
  }

  const updateProReferencesHandler = async () => {
    // Validate that at least 2 references are provided with all required fields
    const invalidReferences = emails.filter(
      (emailval) =>
        !emailval.referencefirstName?.trim() ||
        !emailval.referencelastName?.trim() ||
        !emailval.referenceEmail?.trim()
    );

    if (invalidReferences.length > 0) {
      setIsReferenceUpdate(false);
      enqueueSnackbar(
        "Please provide all required fields for at least 2 references",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
      return;
    }

    setIsReferenceUpdate(true);

    // Use only the first 2 valid references
    const trimmedEmails = emails.slice(0, 2).map((emailval) => ({
      referenceEmail: emailval.referenceEmail?.trim(),
      referencefirstName: emailval.referencefirstName?.trim(),
      referencelastName: emailval.referencelastName?.trim(),
    }));

    const payloadUpdateReference = {
      _id: userData._id,
      references: trimmedEmails,
    };
    const responseUpdate = await updateProfessional(payloadUpdateReference);

    if (responseUpdate.data.statusCode === 1) {
      updatedProfessionalData();
      setIsEmailUpdate(false);
      setIsReferenceUpdate(false);
      enqueueSnackbar("Reference added", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      if (responseUpdate.data.profileStatus === 100) {
        enqueueSnackbar(
          "We will send you an email once the index score is ready.",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
      }
    } else {
      setIsReferenceUpdate(false);
      enqueueSnackbar("Failed to update references", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const updateAboutYouHandler = async () => {
    // alert();
    setUpdateAboutYouLoader(true);
    try {
      var payload = {
        _id: userData._id,
        summary: updateAboutYou,
      };

      const responseUpdate = await updateProfessional(payload);
      if (responseUpdate.data.statusCode === 1) {
        updatedProfessionalData();
        setUpdateAboutYouLoader(false);
        setAboutYouVisible(false);
        enqueueSnackbar("About You Updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        setUpdateAboutYouLoader(false);
        enqueueSnackbar("Failed to update about you", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      setUpdateAboutYouLoader(false);
    }
  };

  const updateWorkExperienceHandler = async () => {
    try {
      setUpdateWorkExpLoader(true); // Show loader while updating

      const newWorkExperience = {
        companyName: updateWorkExpCompanyName,
        title: updateWorkExpTitle,
        country: updateWorkExpCountry,
        city: updateWorkExpCity,
        startDate: updateWorkExpStartDate,
        endDate: updateWorkExpEndDate,
        description: updateWorkExpDescription,
      };

      const updatedWorkExperience = [...workExperience, newWorkExperience];

      const payload = {
        _id: userData._id,
        workExperience: updatedWorkExperience,
      };

      const response = await updateProfessional(payload); // Update this with your API endpoint
      updatedProfessionalData();
      setUpdateWorkExpLoader(false); // Hide loader after updating
      setEditWorkExpVisible(false); // Close the modal after submission

      console.log("Work experience updated successfully:", response);
    } catch (error) {
      console.error("Error updating work experience:", error);
      setUpdateWorkExpLoader(false); // Hide loader in case of error
    }
  };

  const updateProAboutYouHandler = async () => {
    // alert();
    const payload = {
      _id: userData._id,
      address: updateAddress,
      country: updateCountry,
      state: updateState,
      postalCode: updatePostalCode,
    };
    await updateHandler(
      payload,
      setUpdateProAboutYouLoader,
      setEditAboutYouVisible,
      "Address Information Updated!"
    );
  };

  const updateSocialsHandler = async () => {
    setUpdateSocialsLoader(true);

    const payloadSocials = {
      _id: userData._id,
      userChannels: {
        linkedin: updateLinkedin,
        insta: updateInstaLink,
        twitter: updateTwitterLink,
      },
    };

    const responseUpdate = await updateProfessional(payloadSocials);
    if (responseUpdate.data.statusCode === 1) {
      updatedProfessionalData();
      setUpdateSocialsLoader(false);
      setEditSocialsVisible(false);
      enqueueSnackbar("Social Links updated", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      setUpdateSocialsLoader(false);
      enqueueSnackbar("Failed to update social links", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleChange1 = (key, value) => {
    const selectedIndex = options.indexOf(value);
    setSelectedOptions(null);
    const selectedOptionValues = optionsArray[selectedIndex].values;
    setUpdateSector(value);

    setSelectedOptions(selectedOptionValues);
  };

  const handleTextAreaChange = (e, questionName) => {
    setTextAreaValues({
      ...textAreaValues,
      [questionName]: e.target.value,
    });
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(false); // Close the modal after submission
  };
  const handleCancel = () => {
    setVisible(false); // Close the modal without saving changes
  };

  const [aboutYouVisible, setAboutYouVisible] = useState(false);

  const showAboutYouModal = () => {
    setAboutYouVisible(true);
  };
  const handleAboutYouOk = () => {
    setAboutYouVisible(false); // Close the modal after submission
  };
  const handleAboutYouCancel = () => {
    setAboutYouVisible(false); // Close the modal without saving changes
  };

  const EditAboutYouModal = () => {
    setEditAboutYouVisible(true);
  };
  const EditAboutYouhandleOk = () => {
    setEditAboutYouVisible(false); // Close the modal after submission
  };
  const EditAboutYouhandleCancel = () => {
    setEditAboutYouVisible(false); // Close the modal without saving changes
  };

  const EditSocialsModal = () => {
    setEditSocialsVisible(true);
  };
  const EditSocialshandleOk = () => {
    setEditSocialsVisible(false); // Close the modal after submission
  };
  const EditSocialshandleCancel = () => {
    setEditSocialsVisible(false); // Close the modal without saving changes
  };

  const EditNameModal = () => {
    setEditNameVisible(true);
  };
  const EditNamehandleOk = () => {
    setEditNameVisible(false); // Close the modal after submission
  };
  const EditNamehandleCancel = () => {
    setEditNameVisible(false); // Close the modal without saving changes
  };

  const EditContactModal = () => {
    setEditContactVisible(true);
  };
  const EditContacthandleOk = () => {
    setEditContactVisible(false); // Close the modal after submission
  };
  const EditContacthandleCancel = () => {
    setEditContactVisible(false); // Close the modal without saving changes
  };

  const EditEducationModal = () => {
    setEditEducationVisible(true);
  };
  const EditEducationhandleOk = () => {
    setEditEducationVisible(false); // Close the modal after submission
  };
  const EditEducationhandleCancel = () => {
    setEditEducationVisible(false); // Close the modal without saving changes
  };

  const EditSkillsModal = () => {
    setEditSkillsVisible(true);
  };
  const EditSkillshandleOk = () => {
    setEditSkillsVisible(false); // Close the modal after submission
  };
  const EditSkillshandleCancel = () => {
    setEditSkillsVisible(false); // Close the modal without saving changes
  };

  const EditAreaFocusModal = () => {
    setEditAreaFocusVisible(true);
  };
  const EditAreaFocushandleOk = () => {
    setEditAreaFocusVisible(false); // Close the modal after submission
  };
  const EditAreaFocushandleCancel = () => {
    setEditAreaFocusVisible(false); // Close the modal without saving changes
  };

  //photo
  const EditPhotoModal = () => {
    setPhotoVisible(true);
  };
  const EditPhotohandleOk = () => {
    setPhotoVisible(false);
  };
  const EditPhotohandleCancel = () => {
    setPhotoVisible(false);
  };

  // Resume modal
  const EditResumeModal = () => {
    setResumeVisible(true);
  };
  const EditResumehandleOk = () => {
    setResumeVisible(false);
  };
  const EditResumehandleCancel = () => {
    setResumeVisible(false);
  };

  const EditVideoModal = () => {
    setVideoVisible(true);
  };
  const EditVideohandleOk = () => {
    //
    setVideoVisible(false); // Close the modal after submission
  };
  const EditVideohandleCancel = () => {
    setVideoVisible(false); // Close the modal without saving changes
  };

  const handleDob = (date, dateString) => {
    handleChange("dob", moment(dateString).format("YYYY-MM-DD"));
  };

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return maxDate.toISOString().split("T")[0];
  };

  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 32,
      today.getMonth(),
      today.getDate()
    ); // Set to 16 years before the current date
    return minDate.toISOString().split("T")[0];
  };

  const beforeUpload = (file) => {
    // Check if the selected file is a video
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      // message.error('You can only upload video files!');
    } else {
      setVideoFile(file);
    }
    return false;
  };

  const handleVideoUpload = async () => {
    if (videoFile) {
      setVideoUrlLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", videoFile[0].blobFile);
      formData.append("_id", userData._id);
      formData.append("usertype", "professional");
      try {
        const response = await uploadVideo(formData);
        if (response.status == 200) {
          updatedProfessionalData();
          
          setVideoVisible(false);
          setVideoUrl(null);
          setVideoFile(null);
          setVideoUrlLoading(false);
          enqueueSnackbar("Video Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setVideoUrlLoading(false);
    }
  };

  const DeleteSelectedVideo = () => {
    setVideoFile(null);
  };

  const handleCertificationChange = (value) => {
    setSelectedCertification(value);
  };

  const handleAddCertification = async () => {
    if (selectedCertification === "") {
      handleSnackbar("Please select certification ", "error");
      return;
    }

    setAddCertificateLoader(true);

    const newCertification = { name: selectedCertification };
    const existingCertifications = userData.certifications || [];
    const updatedCertifications = [...existingCertifications, newCertification];

    const payload = {
      certifications: updatedCertifications,
      usertype: "professional",
      _id: userData._id,
    };

    try {
      const response = await updateProfessional(payload);
      if (response.status === 200) {
        handleResponse(response, "Certification added");
        setSelectedCertification(null);
        setVisible(false);
      }
    } catch (error) {
      console.error("Error adding certification:", error);
    } finally {
      setAddCertificateLoader(false);
    }
  };

  const [textAreaValues, setTextAreaValues] = useState({
    question1: userData.openAnswers
      ? userData.openAnswers.openQ1
        ? userData.openAnswers.openQ1
        : ""
      : "",
    question2: userData.openAnswers
      ? userData.openAnswers.openQ2
        ? userData.openAnswers.openQ2
        : ""
      : "",
    question3: userData.openAnswers
      ? userData.openAnswers.openQ3
        ? userData.openAnswers.openQ3
        : ""
      : "",
    question4: userData.openAnswers
      ? userData.openAnswers.openQ4
        ? userData.openAnswers.openQ4
        : ""
      : "",
    question5: userData.openAnswers
      ? userData.openAnswers.openQ5
        ? userData.openAnswers.openQ5
        : ""
      : "",
  });

  // online presence functions

  const FB_ID = environment.FB_ID;
  const InstaID = environment.Insta_ID;
  const redirectUri = window.location.href;
  const uriInsta = window.location.href;

  const getUrlParameter = (e, uri) => {
    e = e.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var t = new RegExp("[\\?&]" + e + "=([^&#]*)").exec(uri);
    return null === t ? null : decodeURIComponent(t[1].replace(/\+/g, " "));
  };

  const buildUrlInsta = () => {
    let params = "fbloged=1";
    // let uriX2 = encodeURIComponent('https://c56e-101-53-228-14.ngrok-free.app/professional/signup/online-presence/');
    let uriX2 = encodeURIComponent(redirectUri);
    return `https://api.instagram.com/oauth/authorize?client_id=${InstaID}&redirect_uri=${uriX2}/&scope=user_profile,user_media&response_type=code`;
  };

  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const [code, setCode] = useState(null);
  var codeGrabbed;
  const instagramOAuth = () => {
    const clientId = InstaID;

    const redirectUriInsta = encodeURIComponent(uriInsta);
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;

    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    setLoadingInstagram(true);
    const popup = window.open(
      authUrl,
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        try {
          if (popup.closed) {
            clearInterval(interval);
            reject(new Error("Popup closed"));
          }

          if (
            popup.location.href.includes(decodeURIComponent(redirectUriInsta))
          ) {
            const url = new URL(popup.location.href);
            codeGrabbed = url.searchParams.get("code");
            clearInterval(interval);
            popup.close();
            setCode(codeGrabbed);
            resolve(codeGrabbed);
            getToken();
            setLoadingInstagram(false);
          }
        } catch (error) {
          setLoadingInstagram(false);
          console.error(error);
        }
      }, 1000);
    });
  };

  const getToken = async () => {
    try {
      handleChangeSkills("loading2", true);
      const payloadVerify = {
        redirect_uri: uriInsta,
        code: codeGrabbed
      }
      const response = await instaAuthorize(payloadVerify);

      const payloadInstagram = {
        _id: userData._id,
        ai_data: {
          ...userData.ai_data,
          insta: response.data,
        },
        instagram_profile_data: response.data,
        instagram_authorised: true,
      };

      const responseInstagram = await updateProfessional(payloadInstagram);
      updatedProfessionalData();

      if (responseInstagram) {
        handleChangeSkills("loading2", false);
        handleSuccessSnackbar("Instagram Authorized!");

        if (responseInstagram.data.profileStatus == 100) {
          handleEmailReadySnackbar();
        }
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    } finally {
      handleChangeSkills("loading2", false);
    }
  };

  const myLinkedinLogin = async () => {
    if (companyLinkedin == "") {
      enqueueSnackbar(
        "Error: Please enter linkedin url!",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        { autoHideDuration: 1000 }
      );
    } else {
      handleChangeSkills("loadingLinkedin", true);

      const payload = {
        _id: userData._id,
        linkedin_purl: companyLinkedin,
        linkedIn_authorised: true,
      };

      const response = await updateProfessional(payload);
      if (response.data.statusCode == 1) {
        setUserData([response.data.user]);
        updatedProfessionalData();
        handleChangeSkills("loadingLinkedin", false);
        enqueueSnackbar(
          "Linkedin Authorized!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );

        if (response.data.profileStatus == 100) {
          enqueueSnackbar("We will send you email once indexscore is ready.", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    }
  };

  const updateCriteriaHandler = async () => {
    setCriteriaLoader(true);
    const criteriaData = {
      packageId: { value: "JB-T6AVVPFQL" },
      orderId: { value: "OrderId12345" },
      candidate: {
        first: userData.firstName,
        last: userData.lastName,
        email: userData.emailAddress,
      },
      sendCandidateEmail: true,
      returnURL: {
        uri: "https://www.criteriacorp.com",
      },
    };
    createOrder(criteriaData).then(async (response) => {
      const payloadCriteria = {
        _id: userData._id,
        employeePsychometricEval: {
          orderId: "OrderId12345",
          candidate: {
            first: "Michael",
            last: "Scott",
            email: "michael@dm.com",
            date: "2021-03-01T12:00:00.000Z",
            eventId: "AAA1111ZZZZ",
            testTakerId: "1122333",
          },
          scores: {
            CCATRawScore: 91,
            CCATPercentile: 93,
            CCATInvalidScore: 0,
            CCATAlternateForm: 0,
            CCATSpatialPercentile: 32,
            CCATVerbalPercentile: 0,
            CCATMathPercentile: 17,
            EPPPercentMatch: 51,
            EPPAchievement: 12,
            EPPMotivation: 21,
            EPPCompetitiveness: 12,
            EPPManagerial: 12,
            EPPAssertiveness: 31,
            EPPExtroversion: 12,
            EPPCooperativeness: 35,
            EPPPatience: 54,
            EPPSelfConfidence: 38,
            EPPConscientiousness: 1,
            EPPOpenness: 3,
            EPPStability: 7,
            EPPStressTolerance: 7,
            EPPInconsistency: 0,
            EPPInvalid: false,
            Accounting: 60,
            AdminAsst: 49,
            Analyst: 17,
            BankTeller: 60,
            Collections: 34,
            CustomerService: 42,
            FrontDesk: 50,
            Manager: 42,
            MedicalAsst: 50,
            Production: 42,
            Programmer: 51,
            Sales: 33,
          },
          reportUrl: "https://www.criteriacorp.com/link_to_report",
          candidateReportUrl: "https://www.criteriacorp.com/link_to_report",
          metAllScoreRanges: "Yes",
        },
      };

      const responseCriteria = await updateProfessional(payloadCriteria);
    });
  };

  const myTwitterLogin = async () => {
    if (twitterURL == "") {
      enqueueSnackbar(
        "Error: Please enter twitter username",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        { autoHideDuration: 1000 }
      );
    } else {
      handleChangeSkills("loading5", true);
      const payload = {
        _id: userData._id,
        twitter_purl: twitterURL,
        twitter_authorised: true,
      };

      const response = await updateProfessional(payload);
      
      if (response.data.statusCode == 1) {
        setUserData([response.data.user]);
        updatedProfessionalData();
        handleChangeSkills("loading5", false);
        enqueueSnackbar(
          "Twitter Authorized!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );

        if (response.data.profileStatus == 100) {
          enqueueSnackbar("We will send you email once indexscore is ready.", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    }
  };

  const disAllowEmails = (event) => {
    const email = event.target.value;

    // Your disallow logic here
    const disallowedDomains = [
      "gmail.com",
      "hotmail.com",
      "yahoo.com",
      "outlook.com",
    ];
    const isDisallowed = disallowedDomains.some((domain) =>
      email.endsWith(`@${domain}`)
    );

    setErrorState(isDisallowed); // Update error state

    return !isDisallowed;
  };

  const [personaOptions, setPersonaOptions] = useState({
    templateId: "itmpl_g5dqDwogkc37Dos1GhiqkJvw",
  });
  const createClient = () => {
    const client = new Persona.Client({
      ...personaOptions,
      environment: "sandbox",
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (inquiryId) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        //fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const handlePersonaLink = () => {
    const currentUrl = window.location.href;
    const personaLink = `https://withpersona.com/verify?inquiry-template-id=itmpl_8puaBDx8HQ6MGwTf1AdbmnkZ&environment-id=env_njehZjmGfeDjy6wovb73DRpp&redirect-uri=${currentUrl}`;
    window.location.href = personaLink;
  };

  const handleVerification = async () => {
    if (!userData?.emailAddress) {
      enqueueSnackbar("You have not signed up yet", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      setAccurateLoader(true);
      try {
        var createcandidate = {
          firstName: "Bugs",
          lastName: "Bunny",
          middleName: null,
          suffix: "Jr",
          dateOfBirth: "1973-09-10",
          ssn: "444401223",
          email: userData?.emailAddress,
          phone: "2063695205",
          address: "12345 Mockingbird",
          city: "Hollywood",
          region: "WA",
          country: "US",
          postalCode: "98117",
        };

        var responseCreatecandidate = await accurtateCreateCandidate(
          createcandidate
        );
        if (responseCreatecandidate.status == 200) {
          var createOrder = {
            jobLocation: {
              country: "US",
              region: "CA",
              region2: "Orange",
              city: "Irvine",
            },
            candidateId: responseCreatecandidate.data.data.data.id,
            packageType: "PKG_BASIC",
            workflow: "EXPRESS",
          };

          var responseCreateOrder = await accurtateCreateOrder(createOrder);
          if (responseCreatecandidate.status == 200) {
            var payloadData = {
              _id: userData._id,
              accurate_authorised: true,
              accurate: responseCreateOrder.data.data.data,
            };

            const responseProUpdate = await updateProfessional(payloadData);

            enqueueSnackbar("Verification is in progress, you can proceed!", {
              variant: "pending",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });

            updatedProfessionalData();
            setAccurateLoader(false);

            if (responseProUpdate.data.profileStatus == 100) {
              enqueueSnackbar(
                "We will send you email once indexscore is ready.",
                {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      } catch (error) {}
    }
  };
  function getBaseUrl() {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    return baseUrl;
  }

  const baseUrl = getBaseUrl();
  const clientIdLinkedin = "77yoapq098cye7";
  const redirectUriLinkedin =`${baseUrl}/candidate/dashboard/account`;
  const clientSecretLinkedin = "cVMoAiA2VK4Vlme2";

  useEffect(() => {
    // Check if the URL contains the authorization code
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      console.log(
        "----------------------------code----------------------",
        code
      );

      getLinkedin(code);
    }
  }, []);


  const getLinkedin = async (codeReceived) => {
    try {
      const currentLoc = `${baseUrl}/profile/${userData._id}`;
      const payload = {
        code: codeReceived,
        title: userData.title,
        description: userData.summary,
        link: currentLoc,
        redirectUrl: redirectUriLinkedin,
        text: "Hire Me",
      };

      const response = await authorizeLinkedinUser(payload);
      console.log("response profile share", response);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const copyPageUrl = (candidateId) => {
    console.log("candidateId", candidateId);
    // window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=foobar&scope=r_basicprofile`;
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientIdLinkedin}&redirect_uri=${redirectUriLinkedin}&state=${candidateId}&scope=r_basicprofile,w_member_social`;
  };

  return (
    <>
      <div className="edash">
        <style>
          {`
        .meeting--button:hover {
          border-color:"#000  "
        }

        .send-contract-icon img {
          transform: rotate(-90deg);
      }
      
      .item-right button {
          float: right;
      }
      
      .invitation-button {
          width: 240px;
          margin-bottom: 20px;
          padding: 14px;
          line-height: 1;
          border-radius: 25px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border: 2px;
      }
      
      .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      
      .certifications-box {
          text-align: center;
          margin-bottom: 20px;
      }
      
      .resume-icons a{
        color:#000;
        text-decoration:none;
        margin-left: 20px;
      }
      
      .resume-icons .download-link img {
        margin-right: 0px;
        }
        .resume-icons a img {
            margin-right: 5px;
            max-width:24px;
        }
        .authorized-icon {
          color: #a6dd50;
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 20px;
          z-index: 999;
      }
      .certifications-list img {
            max-width: 40px;
            margin-right: 20px;
        }
      .certifications-list a{
        text-decoration:none;
        color:#000
      }
      .certifications-box p {
          color: #000;
          margin-bottom: 0px;
      }
      
      .cert-popup-buttons {
          display: flex;
          justify-content: flex-end;
      }
      
      .cert-popup-buttons .primary {
          height: 40px;
      }
      
      .add-cert-form {
          margin-bottom: 200px;
      }
      .add-cert-form.personal__information{
        margin-bottom:50px;
      }
      
      .certification-popup .ant-modal-title {
          color: #000 !important;
          font-weight: 600;
          font-size: 22px;
      }
      
      .certification-popup .ant-modal-header {
          margin-bottom: 30px;
      }
      
      .certification-popup span.anticon.anticon-close.ant-modal-close-icon svg {
          font-size: 16px;
          color: #000;
      }

      .certification-add-dropdown.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
        background: #4a4a49 !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow {
        display: block !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow svg {
        color: #fff;
        font-size: 15px;
        margin-right: 2px;
    }
      
      .edit-name-popup label {
          width: 100%;
          font-size: 16px;
          margin-bottom: 20px;
      }
      
      .edit-name-popup input {
          width: 100%;
          margin-top: 5px;
      }
      
      .profile-image {
          position: relative;
      }
      
      .edit-image {
          position: absolute;
          top: -19px;
          left: -26px;
          width: 40px;
          height: 40px;
      }
      
      .photo-popup.add-cert-form {
          text-align: center;
          margin-bottom: 40px;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-modal-title {
          margin-bottom: 50px;
      }
      
      .upload-photo-box {
          width: 300px;
          height: 300px;
          border: 4px dashed #b8e472;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .video-box.upload-photo-box {
          border-radius: 10px;
          width: 100%;
      }
      
      .upload-video .ant-upload.ant-upload-select {
          width: 100%;
      }
      
      .upload-photo-box p {
          width: 50%;
          font-size: 20px;
          font-weight: 600;
      }
      
      .photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: none;
      }
      .photo-popup.add-cert-form .ant-upload.ant-upload-select{
        // display: none;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: inherit;
          margin: auto;
          width: 100%;
          justify-content: center;
          margin-bottom: 30px;
      }
      
      .video-preview {
          position: relative;
      }
      
      .delete-button {
          background: #ff0000;
          color: #fff !important;
          box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 10px;
          right: 10px;
          z-index:100;
      
      }
      
      .delete-button svg {
          color: #fff !important;
      }


      .browse-photo-box .ant-upload.ant-upload-select {
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
    }

    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text{
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
        display:flex;
        justify-content: center;
        align-items: center;
        margin:0 auto;
        border-radius:50%;
        overflow:hidden;
    }
    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text img{
      border-radius:50%;
    }

    .uploaded-image {
      width: 300px;
      height: 300px;
      max-width: 300px;
      overflow: hidden;
      display: flex;
  }
  .uploaded-image img {
    max-width: 100%;
    width:100%;
    object-fit: cover;
    object-position: center;
}
    
    .browse-photo-box .photo-upload-button span {
        opacity: 0;
        position: absolute;
    }
    
    .browse-photo-box .photo-upload-button div {
        font-size: 20px;
        max-width: 80%;
        margin: 0 auto;
        font-weight: 500;
    }

    .browse-photo-box .ant-upload.ant-upload-select img {
      border-radius: 50%;
    }


    .sharingDropdown h3 {
      font-size: 20px;
      font-weight: 600;
      color: #0d2159;
        }
        
        .sharingDropdown p {
            margin: 0px;
        }
        
        .sharingDropdown li.ant-dropdown-menu-item-divider {
            display: none !important;
        }
        
        .sharingDropdown svg {
            color: #000 !important;
        }
        
        .sharingDropdown button {
            padding: 5px 20px;
            background: #a6dd50;
            color: #fff;
            border-radius: 5px;
        }

        .profile__avatar--img{
          max-width: 100%;
          width: 100%;
          border-radius: 50%;
        }
        .edit-image{
          top: 0px !important;
          left: 0px !important;
        }




        `}
        </style>
        <div className="">
          <div className="YDetail pb-3">
            <div className="position-relative ">
              <Tabs
                selectedIndex={tabIndex}
                className="candidate__bars__tabs"
                onSelect={(index) => setTabIndex(index)}
              >
                {/* <Tab disabled={true}>Profile</Tab>
                <Tab disabled={true}>Purpose Statement Video</Tab>
                <Tab disabled={true}>Interview Questions</Tab>
                <Tab disabled={true}>References</Tab>
                <Tab disabled={true}>Online presence</Tab> */}
                <Tab disabled={true}></Tab>
                <Tab disabled={true}></Tab>
                <Tab disabled={true}></Tab>
                <Tab disabled={true}></Tab>
                <Tab disabled={true}></Tab>

                <TabPanel>
                  <div className="PDetail__2 position-relative overflow-hidden mb-3">
                    <Progress
                      percent={progress}
                      steps={5}
                      showInfo={false}
                      className="w-100 position-absolute top-0 start-0"
                    />
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {progress}% complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>
                    <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap">
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                        <div className=" PDetail__top--blue__left me-md-3">
                          <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                            Profile{" "}
                          </h6>
                          <p className="fm darkGrey mb-md-0 text-14">
                            We need detailed and accurate information to build
                            your IndexScore profile. Please compete all sections
                            as required.
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          {/* <button className="PDetail__top--blue__skip fm  me-2">
                          Skip
                        </button> */}
                          <button
                            onClick={() => {
                              userData?.progress?.profile > 0
                                ? handleTabClick(1)
                                : enqueueSnackbar(
                                    "Please complete this step first",
                                    {
                                      variant: "error",
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                      },
                                    },
                                    { autoHideDuration: 1000 }
                                  );
                            }}
                            className="PDetail__top--blue__goto fm"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="resume__bar radius8 p-3 d-md-flex justify-content-between align-items-center gap-3 mb-3">
                    <p className="fm mb-0 text-14">
                      Edit your public profile this will be shown to companies
                      you apply too, you can edit and update this at anytime
                    </p>
                    <div className="d-flex">
                      <Link
                        to={`/profile/${userData._id}`}
                        target={"_blank"}
                        className="fm text-decoration-none"
                      >
                        <button className="btn--white mt-md-0 mt-3 fm radius8 px-3 text-nowrap">
                          View Profile
                        </button>
                      </Link>
                      <button
                        onClick={() => copyPageUrl(userData._id)}
                        className="fm text-decoration-none btn--white mt-md-0 mt-3 fm radius8 px-3 text-nowrap ms-3"
                      >
                        Share Profile
                      </button>
                    </div>
                  </div>

                  <div className="p-3 pb-4 mb-3 PDetail__2">
                    <div className="pb-2">
                      <div className="">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Personal Information
                          </h6>
                          <button
                            onClick={EditContactModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14 ms-5"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-xl-2 mb-xl-0 mb-4">
                          <div className="d-flex mb-2 align-items-center">
                            <p className="fm darkGrey mb-2 text-14 fw-light">
                              Profile Picture
                            </p>
                            <button
                              onClick={EditPhotoModal}
                              className="d-flex align-items-center bg-white rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14 me-4"
                            >
                              <CiEdit className="darkGrey fs-6" />
                            </button>
                          </div>

                          <Modal
                            className="certification-popup"
                            title="Change photo"
                            visible={photoVisible}
                            onOk={EditPhotohandleOk}
                            onCancel={EditPhotohandleCancel}
                            footer={null}
                            width={600}
                          >
                            <div className="add-cert-form edit-name-popup photo-popup">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="browse-photo-box">
                                    <Uploader
                                      draggable
                                      multiple={false}
                                      listType="picture-text"
                                      fileListVisible={false}
                                      // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                      action="//jsonplaceholder.typicode.com/posts/"
                                      onChange={(value) =>
                                        handleChange("files", value)
                                      }
                                      renderFileInfo={(file, fileElement) => {
                                        return (
                                          <div>
                                            <span>File Name: {file.name}</span>
                                            <p style={{ marginLeft: 13 }}>
                                              File type: {file.blobFile.type}
                                            </p>
                                          </div>
                                        );
                                      }}
                                      className="pt-4"
                                    >
                                      <div>
                                        {imageUrl && (
                                          <div className="uploaded-image">
                                            {imageUrl.blobFile.type.startsWith(
                                              "image/"
                                            ) && (
                                              <img
                                                src={URL.createObjectURL(
                                                  imageUrl.blobFile
                                                )}
                                                alt="File Preview"
                                              />
                                            )}
                                          </div>
                                        )}
                                        {!imageUrl && (
                                          <img src="/assets/images/userAvatar.svg" />
                                        )}
                                      </div>
                                    </Uploader>
                                  </div>
                                </div>
                                <div className="col-md-12 photo-popup-detail">
                                  <p className="mt-3">
                                    Photo Formats: jpeg,png
                                  </p>
                                  <h4>
                                    Show clients the best version of yourself
                                  </h4>
                                  <p>Must be an actual photo of you</p>
                                </div>
                              </div>
                            </div>
                            <div
                              style={{ marginTop: "16px" }}
                              className="cert-popup-buttons"
                            >
                              <Button
                                className="primary"
                                onClick={EditPhotohandleCancel}
                                style={{ marginRight: "8px" }}
                              >
                                Cancel
                              </Button>
                              <button
                                className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                onClick={uploadPhoto}
                              >
                                {imageLoading ? <Loader /> : "Upload Photo"}
                              </button>
                            </div>
                          </Modal>
                          {/* <p className="fm grey mb-2 text-14">
                            Profile Picture
                          </p> */}
                          <div className="dashprofile__avatar mx-sm-0 mx-auto border d-flex justify-content-center align-items-center rounded-circle">
                            <img
                              className="PDetail__2__avatar--img p-0 w-100 h-100"
                              // src={"/assets/images/userAvatar.svg"}
                              src={`${
                                userData.profileImage
                                  ? userData.profileImage.docs
                                  : "/assets/images/userAvatar.svg"
                              }
                              `}
                            />
                          </div>
                        </div>
                        <div className="col-xl-10 col-lg-10">
                          <div className="row">
                            <div className="col-md-12"></div>
                            <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                              <p className="text-14 fm darkGrey mb-0 fw-light ">
                                First name
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.firstName}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-md-0 mb-4 d-md-none">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Last name
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.lastName}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-sm-0 mb-4 d-md-none d-block">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Title
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.title}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                              <p className="text-14 fm darkGrey mb-0 fw-light ">
                                Email address
                              </p>
                              <p className="text-14 fm darkGrey my-0 word-wrap">
                                {userData.emailAddress}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Date of Birth
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.dob ? userData.dob : "--"}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Gender
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.gender ? userData.gender : "--"}
                              </p>
                            </div>
                          </div>

                          <div className="row d-flex mt-4">
                            <div className="col-md-3 col-sm-6 mb-md-0 mb-4 d-md-block d-none">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Last name
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.lastName}
                              </p>
                            </div>

                            <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Phone
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.phoneNumber}
                              </p>
                            </div>

                            {/* <div className="col-md-3 col-sm-6 d-md-none d-block">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Mobile
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.phoneNumber}
                              </p>
                            </div> */}

                            <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Ethnicity
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.ethnicity ? userData.ethnicity : "--"}
                              </p>
                            </div>
                          </div>

                          <div className="row d-flex mt-md-4 d-md-flex d-none">
                            <div className="col-md-3 col-sm-6 mb-sm-0 mb-4 ">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Title
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.title}
                              </p>
                            </div>

                            {/* <div className="col-md-3 col-sm-6">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Mobile
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.phoneNumber}
                              </p>
                            </div> */}

                            {/* <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Gender
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.gender}
                            </p>
                          </div>
                          <div className="col-md-3 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Dob
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.dob}
                            </p>
                          </div> */}
                          </div>
                        </div>

                        {/* <div className="col-lg-2 mt-lg-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Social channels
                          </p>
                          <a className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none">
                            <FaLinkedin className="text-14 me-1" /> LinkedIn
                          </a>
                          <a className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none">
                            <FaInstagram className="text-14 me-1" /> Instagram
                          </a>
                          <a className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none">
                            <FaSquareXTwitter className="text-14 me-1" /> Twitter
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="row equal">
                    <div className="col-md-6 mb-3 pe-md-1">
                      <div className="PDetail__2 p-3 pb-4 d-none">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Information
                          </h6>
                          <button
                            onClick={EditContactModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>

                          <Modal
                            className="certification-popup"
                            title="Edit personal information"
                            visible={editContactVisible}
                            onOk={EditContacthandleOk}
                            onCancel={EditContacthandleCancel}
                            footer={null}
                            width={600}
                          >
                            <div className="add-cert-form personal__information edit-name-popup">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>
                                    Title
                                    <input
                                      type="text"
                                      className="form-control form-control-md w-100 Dashboard__input mb-2"
                                      placeholder="Enter Title eg.. Software Developer"
                                      id="sector1"
                                      value={updateTitle}
                                      onChange={(e) =>
                                        setUpdateTitle(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    First name
                                    <input
                                      type="text"
                                      className="form-control form-control-md w-100 Dashboard__input mb-2"
                                      placeholder="Enter first name"
                                      id="sector1"
                                      value={updateFirstName}
                                      onChange={(e) =>
                                        setUpdateFirstName(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    Last name
                                    <input
                                      type="text"
                                      className="form-control form-control-md w-100 Dashboard__input mb-2"
                                      placeholder="Enter last name"
                                      id="sector1"
                                      value={updateLastName}
                                      onChange={(e) =>
                                        setUpdateLastName(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    Phone number
                                    <PhoneInput
                                      defaultCountry={"us"}
                                      placeholder="Enter your phone number"
                                      value={updatePhoneNumber}
                                      onChange={(phone) =>
                                        setUpdatePhoneNumber(phone)
                                      }
                                    />
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    Date of birth
                                    <input
                                      type="date"
                                      className="form-control form-control-md w-100 Dashboard__input "
                                      placeholder="Date of birth"
                                      title="Select date of birth"
                                      value={updateDob || ""} // Use an empty string as a placeholder
                                      onChange={(value) => {
                                        console.log("dob", value.target.value);
                                        setUpdateDob(value.target.value);
                                      }}
                                      max={getMaxDate()}
                                      min={getMinDate()}
                                    />
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    Gender
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(input)
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                                      size="small"
                                      placeholder="Select gender"
                                      value={updateGender}
                                      onChange={(value) => {
                                        setUpdateGender(value);
                                      }}
                                    >
                                      {genderList.map((item) => (
                                        <Option
                                          value={item.name}
                                          label={item.name.toLowerCase()}
                                          key={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </label>
                                </div>
                                <div className="col-md-6">
                                  <label>
                                    Ethnicity
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(input)
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100"
                                      size="small"
                                      value={updateEthnicity}
                                      placeholder="Select Ethnicities"
                                      onChange={(value) => {
                                        setUpdateEthnicity(value);
                                      }}
                                    >
                                      {EthnicitiesList.map((item) => (
                                        <Option
                                          value={item.name}
                                          label={item.name.toLowerCase()}
                                          key={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {contactError && (
                              <div className="error-message">
                                {contactError}
                              </div>
                            )}
                            <div
                              style={{ marginTop: "16px" }}
                              className="cert-popup-buttons"
                            >
                              <Button
                                className="primary"
                                onClick={EditContacthandleCancel}
                                style={{ marginRight: "8px" }}
                              >
                                Cancel
                              </Button>
                              <button
                                className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                onClick={updateProContactHandler}
                              >
                                {updateContactLoader ? <Loader /> : "Update"}
                              </button>
                            </div>
                          </Modal>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Name
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.industry ? userData.industry : "--"}
                            </p>
                          </div>
                          <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Sector
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.sector}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Website
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.companyUrl
                                ? userData.companyUrl
                                : "----"}
                            </p>
                          </div>

                          <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Phone
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.phoneNumber}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Size (People)
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.people}
                            </p>
                          </div>
                          <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Size (Turnover)
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              ${userData.size}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="PDetail__2 p-3 mb-3 h-100">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Education
                          </h6>
                          <button
                            onClick={EditEducationModal}
                            className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>

                          <Modal
                            className="certification-popup"
                            title="Edit education"
                            visible={editEducationVisible}
                            onOk={EditEducationhandleOk}
                            onCancel={EditEducationhandleCancel}
                            footer={null}
                            width={600}
                          >
                            <div className="add-cert-form personal__information edit-name-popup">
                              <div className="row">
                                <div className="col-md-12">
                                  <label>
                                    Education
                                    <Select
                                      showSearch
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        (option?.label ?? "").includes(input)
                                      }
                                      filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                          .toLowerCase()
                                          .localeCompare(
                                            (optionB?.label ?? "").toLowerCase()
                                          )
                                      }
                                      className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                                      size="small"
                                      placeholder="Select Education"
                                      value={updateEducation}
                                      onChange={(value) => {
                                        setUpdateEducation(value);
                                      }}
                                    >
                                      {degreesList.map((item) => (
                                        <Option
                                          value={item.name}
                                          label={item.name.toLowerCase()}
                                          key={item.name}
                                        >
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </label>
                                </div>
                                <div className="col-md-12">
                                  <label>
                                    Achievements Url
                                    <input
                                      type="text"
                                      className="form-control form-control-md w-100 Dashboard__input mb-2"
                                      placeholder="Enter achievement url"
                                      id="sector1"
                                      value={updateAchievements}
                                      onChange={(e) =>
                                        setUpdateAchievements(e.target.value)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                            {educationError && (
                              <div className="error-message">
                                {educationError}
                              </div>
                            )}
                            <div
                              style={{ marginTop: "16px" }}
                              className="cert-popup-buttons"
                            >
                              <Button
                                className="primary"
                                onClick={EditContacthandleCancel}
                                style={{ marginRight: "8px" }}
                              >
                                Cancel
                              </Button>
                              <button
                                className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                onClick={updateProEducationHandler}
                              >
                                {updateEducationLoader ? <Loader /> : "Update"}
                              </button>
                            </div>
                          </Modal>
                        </div>
                        <div className="PDetail__3__row mt-2 d-flex flex-md-row flex-column">
                          <div className="PDetail__3__row__col">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Education
                            </p>
                            <div className="darkGrey text-14 nowrap mb-3">
                              <span>
                                {userData.education ? userData.education : "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="PDetail__3__row mt-2 d-flex flex-md-row flex-column">
                          <div className="PDetail__3__row__col">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Your achievements:
                            </p>
                            <div className="darkGrey text-14 nowrap mb-3">
                              <span>
                                {userData.acheivementsUrl
                                  ? userData.acheivementsUrl
                                  : "--"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="p-3 pb-4  PDetail__2 h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Address
                          </h6>
                          <button
                            onClick={EditAboutYouModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Country
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.country
                                ? Country.find(
                                    (country) =>
                                      country.code === userData.country
                                  )?.name
                                : "--"}
                            </p>
                          </div>
                          <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              State/City
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.state ? userData.state : "--"}
                            </p>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-lg-4 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Address
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.address ? userData.address : "--"}
                            </p>
                          </div>

                          <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Postcode/Zip
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.postalCode ? userData.postalCode : "--"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row equal">
                    {/* <div className="col-md-6 mb-3 pe-md-1">
                      <div className="p-3 pb-4 PDetail__2 h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Personal Information
                          </h6>
                          <button
                            onClick={EditContactModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>
                        <img
                          src="/assets/images/Dashboard/Persona.png"
                          alt=""
                          className="persona__img mt-4"
                        />
                        <div className="row mt-4">
                          <div className="col-xxl-4">
                            <p className="fm darkGrey text-14 fw-semibold">
                              Identity verification
                            </p>
                          </div>

                          <div className="col-xxl-4 mb-xxl-0 mb-4">
                            <p className="fm darkGrey mb-0 fw-light text-14">
                              Government Identification
                            </p>
                            <div className="darkGrey text-14 nowrap fm d-flex align-items-center gap-1 mb-2">
                              <span>Verified</span> <BsCheckCircle />
                            </div>
                            <div className="dash_tag darkGrey rounded-pill fm d-inline-block text-14 ps-2 pe-1 fw-semibold text-nowrap">
                              Id card–ben smith{" "}
                              <IoIosClose className="fs-4 transition pointer dash_tag_cross" />
                            </div>
                          </div>
                          <div className="col-xxl-4">
                            <p className="fm darkGrey mb-0 fw-light text-14">
                              Right to work
                            </p>
                            <div className="darkGrey text-14 nowrap fm d-flex align-items-center gap-1 mb-2">
                              <span>Verified</span> <BsCheckCircle />
                            </div>
                            <div className="dash_tag darkGrey rounded-pill fm d-inline-block text-14 ps-2 pe-1 fw-semibold text-nowrap">
                              untitled – 01.pdf
                              <IoIosClose className="fs-4 transition pointer dash_tag_cross" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-md-6 mb-3 pe-md-1">
                      <div className="PDetail__2 p-3 mb-3 h-100">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-start">
                            <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                              Your area of focus:
                            </h6>
                            <button
                              onClick={EditAreaFocusModal}
                              className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                            >
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Edit
                            </button>

                            <Modal
                              className="certification-popup"
                              title="Edit Area Focus "
                              visible={editAreaFocusVisible}
                              onOk={EditAreaFocushandleOk}
                              onCancel={EditAreaFocushandleCancel}
                              footer={null}
                              width={600}
                            >
                              <div className="add-cert-form personal__information edit-name-popup">
                                <div className="row">
                                  <div className="col-md-12">
                                    <label>
                                      Sector
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                          (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                              (
                                                optionB?.label ?? ""
                                              ).toLowerCase()
                                            )
                                        }
                                        className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                                        size="small"
                                        placeholder="Select sector"
                                        value={updateSector}
                                        onChange={(value) => {
                                          handleChange1("sector", value);
                                        }}
                                      >
                                        {options.map((option, index) => (
                                          <Option key={index} value={option}>
                                            {option}
                                          </Option>
                                        ))}
                                      </Select>
                                    </label>
                                  </div>
                                  <div className="col-md-12">
                                    <label>
                                      Industry
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          (option?.label ?? "").includes(input)
                                        }
                                        filterSort={(optionA, optionB) =>
                                          (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                              (
                                                optionB?.label ?? ""
                                              ).toLowerCase()
                                            )
                                        }
                                        className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                                        size="small"
                                        placeholder="Select industry"
                                        value={updateIndustry}
                                        onChange={(value) => {
                                          setuUpdateIndustry(value);
                                        }}
                                      >
                                        {selectedOptions?.map((item, index) => (
                                          <Option key={index} value={item.name}>
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </label>
                                  </div>
                                  <div className="col-md-12">
                                    <label>
                                      Business function
                                      <Select
                                        className="Login--Input mt-3 mx-0 d-block Login--select"
                                        size="large"
                                        value={updateBusiness}
                                        placeholder="Business function"
                                        onChange={(value) =>
                                          setUpdateBusiness(value)
                                        }
                                      >
                                        <Option value="Strategy">
                                          Strategy
                                        </Option>
                                        <Option value="Finance">Finance</Option>
                                        <Option value="Sales and marketing">
                                          Sales and marketing
                                        </Option>
                                        <Option value="Research and development">
                                          Research and development
                                        </Option>
                                        <Option value="Information technology">
                                          Information technology
                                        </Option>
                                        <Option value="Customer service">
                                          Customer service
                                        </Option>
                                        <Option value="Human resources">
                                          Human resources
                                        </Option>
                                        <Option value="Design">Design</Option>
                                        <Option value="Communications">
                                          Communications
                                        </Option>
                                        <Option value="Governance">
                                          Governance
                                        </Option>
                                        <Option value="Production">
                                          Production
                                        </Option>
                                        <Option value="Sourcing">
                                          Sourcing
                                        </Option>
                                        <Option value="Quality management">
                                          Quality management
                                        </Option>
                                        <Option value="Distribution">
                                          Distribution
                                        </Option>
                                        <Option value="Operations">
                                          Operations
                                        </Option>
                                      </Select>
                                    </label>
                                  </div>
                                  <div className="col-md-12">
                                    <label>
                                      What salary range are you expecting for
                                      your next job opportunity?
                                      <Select
                                        className="Login--Input mt-3 mx-0 d-block Login--select"
                                        size="large"
                                        placeholder="Expected yearly salary"
                                        value={updateSalaryRange}
                                        onChange={(value) =>
                                          setUpdateSalaryRange(value)
                                        }
                                      >
                                        <Option value="1000 - 5000">
                                          {" "}
                                          1000 - 5000
                                        </Option>
                                        <Option value="5000 - 10000">
                                          {" "}
                                          5000 - 10000
                                        </Option>
                                        <Option value="10000 - 20000">
                                          {" "}
                                          10000 - 20000
                                        </Option>
                                        <Option value="20,000 - 50,000">
                                          {" "}
                                          20,000 - 50,000
                                        </Option>
                                        <Option value="50,000 - 80,000">
                                          {" "}
                                          50,000 - 80,000
                                        </Option>
                                        <Option value="80,000 - 120,000">
                                          {" "}
                                          80,000 - 120,000
                                        </Option>
                                        <Option value="120,000 - 150,000">
                                          {" "}
                                          120,000 - 150,000
                                        </Option>
                                        <Option value="150,000 -200,000">
                                          {" "}
                                          150,000 -200,000
                                        </Option>
                                        <Option value="200,000 - 350,000">
                                          {" "}
                                          200,000 - 350,000
                                        </Option>
                                        <Option value="350,000$ +">
                                          {" "}
                                          350,000$ +
                                        </Option>
                                      </Select>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {areaFocusError && (
                                <div className="error-message">
                                  {areaFocusError}
                                </div>
                              )}
                              <div
                                style={{ marginTop: "16px" }}
                                className="cert-popup-buttons"
                              >
                                <Button
                                  className="primary"
                                  onClick={EditAreaFocushandleCancel}
                                  style={{ marginRight: "8px" }}
                                >
                                  Cancel
                                </Button>
                                <button
                                  className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                  onClick={updateProAreaFocusHandler}
                                >
                                  {updateAreaFocusLoader ? (
                                    <Loader />
                                  ) : (
                                    "Update"
                                  )}
                                </button>
                              </div>
                            </Modal>
                          </div>
                          <div className="PDetail__3__row   mt-2 d-flex justify-content-md-start justify-content-between">
                            <div className="PDetail__3__row__col PDetail__3__row__col1">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Sector
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.sector ? userData.sector : "--"}
                              </p>
                            </div>
                            <div className="PDetail__3__row__col">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Industry
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.industry ? userData.industry : "--"}
                              </p>
                            </div>
                          </div>
                          <div className="PDetail__3__row   mt-4 d-flex justify-content-md-start justify-content-between">
                            <div className="PDetail__3__row__col">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                Business function
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.businessFunction
                                  ? userData.businessFunction
                                  : "--"}
                              </p>
                            </div>
                            <div className="PDetail__3__row__col">
                              <p className="text-14 fm darkGrey mb-0 fw-light">
                                What salary range are you expecting for your
                                next job opportunity?
                              </p>
                              <p className="text-14 fm darkGrey my-0">
                                {userData.salaryRange
                                  ? userData.salaryRange
                                  : "--"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-3 ">
                      <div className="p-3 pb-4 PDetail__2 h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            About you
                          </h6>
                          <button
                            onClick={showAboutYouModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>

                        <p className="text-14 fm darkGrey mb-0 mt-4">
                          {userData && userData?.summary}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-6 mb-3 pe-md-1">
                      <div className="p-3 pb-4 PDetail__2 h-100">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Public Facing Profile
                          </h6>
                          <button
                            onClick={EditContactModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Go to
                          </button>
                        </div>
                        <p className="text-14 fm darkGrey  dash__del__text">
                          Your public profile is located on the ‘My Profile”
                          tab, this will have all employment history, open
                          questions, video and you social/achievement
                          information on either click the go to button here or
                          use the tab on the tool bar.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="row equal">
                    <div className="col-md-6 mb-3 pe-md-1">
                      <div className="PDetail__2 p-3 h-100 mb-3">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Resume
                          </h6>
                          <button
                            onClick={()=>{
                              if(userData?.kycVerification){
                                EditResumeModal()
                              }
                              else{
                                enqueueSnackbar(
                                  "Kyc Verification is required for updating the resume",
                                  {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "right",
                                    },
                                  },
                                  { autoHideDuration: 1000 }
                                );
                                
                              }
                            }}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>
                        <Modal
                          className="certification-popup"
                          title="Update Resume"
                          visible={resumeVisible}
                          onOk={EditResumehandleOk}
                          onCancel={EditResumehandleCancel}
                          footer={null}
                          width={600}
                        >
                          <div className="add-cert-form edit-name-popup photo-popup resume-popup">
                            <div className="row">
                              <div className="col-md-12">
                                <Uploader
                                  listType="picture-text"
                                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  action="//jsonplaceholder.typicode.com/posts/"
                                  onChange={(value) => setResumeFile(value)}
                                  renderFileInfo={(file, fileElement) => {
                                    return (
                                      <div>
                                        <span>File Name: {file.name}</span>
                                        <p style={{ marginLeft: 13 }}>
                                          File type: {file.blobFile.type}
                                        </p>
                                      </div>
                                    );
                                  }}
                                  className="pt-4"
                                >
                                  <div>
                                    <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                                      Upload your resume
                                    </Button>
                                  </div>
                                </Uploader>
                              </div>
                              <div className="col-md-12 photo-popup-detail fm">
                                <h4>
                                  Show clients the best resume of yourself
                                </h4>
                                <p>Must be in PDF format</p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "16px" }}
                            className="cert-popup-buttons"
                          >
                            <Button
                              className="primary"
                              onClick={EditResumehandleCancel}
                              style={{ marginRight: "8px" }}
                            >
                              Cancel
                            </Button>
                            <button
                              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              onClick={handleResumeUpload}
                            >
                              {resumeFileLoader ? <Loader /> : "Upload Resume"}
                            </button>
                          </div>
                        </Modal>
                        <div className="PDetail__3__row mt-2 ">
                          {userData?.resumes && (
                            <div className="col-md-12 pt-2 pb-2 d-flex align-items-center flex-sm-nowrap flex-wrap certifications-list">
                              <img
                                src="/assets/images/resume-icon.png"
                                className="me-sm-3 me-2"
                              />
                              <div className="d-flex align-items-center">
                                <h6 className="darkGrey mb-0 fm">
                                  {" "}
                                  {userData?.resumes?.actualresumefileName}{" "}
                                </h6>
                                <Link
                                  className="download-link"
                                  onClick={() =>
                                    handleDownload(userData?.resumes.Key)
                                  }
                                >
                                  {" "}
                                  <img
                                    src="/assets/images/icon_export.svg"
                                    alt=""
                                    className="img-fluid candidates__button--img ms-2 mb-1"
                                  />
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-1">
                      <div className="PDetail__2 h-100  p-3 mb-3">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Certifications
                          </h6>
                          <button
                            onClick={showModal}
                            className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                          <Modal
                            className="certification-popup"
                            title="Add certifications"
                            visible={visible}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            footer={null}
                            width={700}
                          >
                            <div className="add-cert-form">
                              <Select
                                className="Login--Input mb-2 mx-0 d-block w-100 certification-add-dropdown"
                                size="small"
                                placeholder="Select your certification"
                                onChange={handleCertificationChange}
                                value={selectedCertification}
                              >
                                <Option value="Google IT Support Professional Certificate">
                                  Google IT Support Professional Certificate
                                </Option>
                                <Option value="Coursera Deep Learning Specialization">
                                  Coursera Deep Learning Specialization
                                </Option>
                                <Option value="AWS Certified Solutions Architect">
                                  AWS Certified Solutions Architect
                                </Option>
                                <Option value="Microsoft Certified: Azure Administrator Associate">
                                  Microsoft Certified: Azure Administrator
                                  Associate
                                </Option>
                                <Option value="Certified ScrumMaster (CSM)">
                                  Certified ScrumMaster (CSM)
                                </Option>
                                <Option value="Cisco Certified Network Associate (CCNA)">
                                  Cisco Certified Network Associate (CCNA)
                                </Option>
                                <Option value="CompTIA Security+">
                                  CompTIA Security+
                                </Option>
                                <Option value="Certified Information Systems Security Professional (CISSP)">
                                  Certified Information Systems Security
                                  Professional (CISSP)
                                </Option>
                                <Option value="Certified Ethical Hacker (CEH)">
                                  Certified Ethical Hacker (CEH)
                                </Option>
                                <Option value="Certified Data Scientist">
                                  Certified Data Scientist
                                </Option>
                                <Option value="Certified Kubernetes Administrator (CKA)">
                                  Certified Kubernetes Administrator (CKA)
                                </Option>
                                <Option value="Certified in Risk and Information Systems Control (CRISC)">
                                  Certified in Risk and Information Systems
                                  Control (CRISC)
                                </Option>
                                <Option value="Project Management Professional (PMP)">
                                  Project Management Professional (PMP)
                                </Option>
                                <Option value="Certified Information Systems Auditor (CISA)">
                                  Certified Information Systems Auditor (CISA)
                                </Option>
                                <Option value="Certified in the Governance of Enterprise IT (CGEIT)">
                                  Certified in the Governance of Enterprise IT
                                  (CGEIT)
                                </Option>
                                <Option value="Machine Learning Engineer Nanodegree">
                                  Machine Learning Engineer Nanodegree
                                </Option>
                                <Option value="Digital Marketing Specialist">
                                  Digital Marketing Specialist
                                </Option>
                                <Option value="Certified Business Analysis Professional (CBAP)">
                                  Certified Business Analysis Professional
                                  (CBAP)
                                </Option>
                                <Option value="Certified Six Sigma Green Belt">
                                  Certified Six Sigma Green Belt
                                </Option>
                                <Option value="Certified Artificial Intelligence Practitioner">
                                  Certified Artificial Intelligence Practitioner
                                </Option>
                                <Option value="Certified Blockchain Developer">
                                  Certified Blockchain Developer
                                </Option>
                                <Option value="Certified Information Security Manager (CISM)">
                                  Certified Information Security Manager (CISM)
                                </Option>
                                <Option value="Certified DevOps Engineer">
                                  Certified DevOps Engineer
                                </Option>
                                <Option value="Certified Information Privacy Professional (CIPP)">
                                  Certified Information Privacy Professional
                                  (CIPP)
                                </Option>
                                <Option value="Certified Cloud Security Professional (CCSP)">
                                  Certified Cloud Security Professional (CCSP)
                                </Option>
                                <Option value="Certified Business Intelligence Professional (CBIP)">
                                  Certified Business Intelligence Professional
                                  (CBIP)
                                </Option>
                                <Option value="Certified Professional in Healthcare Information and Management Systems (CPHIMS)">
                                  Certified Professional in Healthcare
                                  Information and Management Systems (CPHIMS)
                                </Option>
                                <Option value="Certified Advanced Social Media Strategy (CASMS)">
                                  Certified Advanced Social Media Strategy
                                  (CASMS)
                                </Option>
                                <Option value="Certified Scrum Product Owner (CSPO)">
                                  Certified Scrum Product Owner (CSPO)
                                </Option>
                                <Option value="Certified Professional in Learning and Performance (CPLP)">
                                  Certified Professional in Learning and
                                  Performance (CPLP)
                                </Option>
                              </Select>
                            </div>
                            <div
                              style={{ marginTop: "16px" }}
                              className="cert-popup-buttons"
                            >
                              <Button
                                className="primary"
                                onClick={handleCancel}
                                style={{ marginRight: "8px" }}
                              >
                                Cancel
                              </Button>
                              <button
                                className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                onClick={handleAddCertification}
                              >
                                {addCertificateLoader ? (
                                  <Loader />
                                ) : (
                                  "Add certification"
                                )}
                              </button>
                            </div>
                          </Modal>
                        </div>
                        <div className="PDetail__3__row mt-2 flex-md-row flex-column">
                          <div className="PDetail__3__row__col">
                            <div className="darkGrey text-14 mb-3">
                              {userData.certifications &&
                              userData.certifications?.length > 0
                                ? userData?.certifications.map(
                                    (item, index) => <div>{item.name}</div>
                                  )
                                : "--"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row equal">
                    <div className="col-md-6 mb-3">
                      <div className="PDetail__2 p-3 mb-3 h-100">
                        <div className="d-flex justify-content-between align-items-start">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Technical Skills
                          </h6>

                          <div class="question_box">
                            <div class="">
                              <button
                                onClick={() =>
                                  handleChangeSkills("showSkills", true)
                                }
                                className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                              >
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Edit
                              </button>
                              <div
                                className="d-flex align-items-center mt-3 pointer"
                                onClick={() =>
                                  handleChangeSkills("showSkills", true)
                                }
                              ></div>
                            </div>
                          </div>

                          <BootstrapDialog
                            onClose={handleClose}
                            maxWidth={"md"}
                            style={{ minwidth: "100%" }}
                            classes={{ paper: "skillDialogue" }}
                            aria-labelledby="customized-dialog-title"
                            open={showSkills}
                          >
                            <BootstrapDialogTitle>
                              <div className="d-flex">
                                {/* <p className='font-24 font-weight-bold m-1'></p> */}
                                <div
                                  style={{ width: "100%" }}
                                  class="logingroup ml-4 inner-addon right-addon"
                                >
                                  <i class="glyphicon fa fa-search"></i>
                                  <input
                                    style={{
                                      width: "100%",
                                      borderRadius: "8px",
                                      padding: "5px 10px",
                                      fontSize: "12px",
                                      border: "1px solid #ccc",
                                    }}
                                    value={searchInput}
                                    onChange={(value) => {
                                      handleChangeSkills(
                                        "searchInput",
                                        value.target.value
                                      );
                                    }}
                                    // onChange={(e) => setSearchInputs(e.target.value)}
                                    type="text"
                                    placeholder="Search here"
                                    min="0"
                                  />
                                </div>
                              </div>
                            </BootstrapDialogTitle>
                            <DialogContent dividers>
                              <Box>
                                {/* <p className='font-24 mb-4'>Select here</p> */}
                                {skilllist
                                  ?.filter((chatIdKey) => {
                                    if (searchInput === "") {
                                      //if query is empty
                                      return "";
                                    } else if (
                                      chatIdKey?.includes(
                                        searchInput?.toLowerCase()
                                      )
                                    ) {
                                      //returns filtered array
                                      // console.log("chatIdKey filtered" + chatIdKey);
                                      return chatIdKey;
                                    }
                                  })
                                  .map((skill, index) => {
                                    // console.log("skill----" + skill);
                                    // console.log("index----" + index);
                                    if (index < 12) {
                                      return (
                                        <Chip
                                          key={index}
                                          onClick={() => {
                                            if (
                                              !selectedSkills?.includes(skill)
                                            ) {
                                              handleChangeSkills(
                                                "selectedSkills",
                                                [...selectedSkills, skill]
                                              );
                                            } else {
                                              selectedSkills?.forEach(
                                                (element, index) => {
                                                  if (element === skill) {
                                                    selectedSkills?.splice(
                                                      index,
                                                      1
                                                    );
                                                  }
                                                }
                                              );
                                              handleChangeSkills(
                                                "selectedSkills",
                                                [...selectedSkills]
                                              );
                                            }
                                          }}
                                          color={"primary"}
                                          variant={
                                            selectedSkills?.includes(skill)
                                              ? "filled"
                                              : "outlined"
                                          }
                                          label={skill}
                                          style={{
                                            marginRight: "10px",
                                          }}
                                          className="mr-5 mb-2 skillsChip"
                                        />
                                      );
                                    }
                                  })}
                              </Box>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                className={`Get__left__content__para Get__right__button Get__right__button--darkGrey `}
                                onClick={handleClose}
                              >
                                Close
                              </Button>
                              <Button
                                className={`Get__left__content__para Get__right__button Get__right__button--blue `}
                                autoFocus
                                onClick={updateProSkillsHandler}
                              >
                                Save
                              </Button>
                            </DialogActions>
                          </BootstrapDialog>
                        </div>
                        <div className="mt-md-0 mt-3">
                          <div className="row">
                            <Box className="d-flex w-100 flex-wrap chips-custom-color">
                              {selectedSkills?.map((skill, index) => (
                                <Chip
                                  key={index}
                                  color={"primary"}
                                  onDelete={() => {
                                    selectedSkills?.forEach(
                                      (element, index) => {
                                        if (element === skill) {
                                          selectedSkills?.splice(index, 1);
                                        }
                                      }
                                    );
                                    handleChangeSkills("selectedSkills", [
                                      ...selectedSkills,
                                    ]);
                                  }}
                                  variant={"outlined"}
                                  label={skill}
                                  style={{
                                    marginRight: "10px",
                                  }}
                                  className="mr-2 mb-2 selectedSkill"
                                />
                              ))}
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <div className="p-3 pb-4 PDetail__2">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                            Social Channels
                          </h6>
                          <button
                            onClick={EditSocialsModal}
                            className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                            Edit
                          </button>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-4">
                            <a
                              className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                              target={"_blank"}
                              href={updateLinkedin}
                            >
                              <FaLinkedin className="fs-6 me-1" /> LinkedIn
                            </a>
                            <a
                              className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                              target={"_blank"}
                              href={updateInstaLink}
                            >
                              <FaInstagram className="fs-6 me-1" /> Instagram
                            </a>
                            <a
                              className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                              target={"_blank"}
                              href={updateTwitterLink}
                            >
                              <FaSquareXTwitter className="fs-6 me-1" /> Twitter
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row equal">
                    <div className="col-md-12 mb-3 pe-md-1">
                      <div className="PDetail__2 p-3 mb-3 h-100">
                        <div className="">
                          <div className="d-flex justify-content-between align-items-start">
                            <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                              Work experience:
                            </h6>
                            <button
                              onClick={EditWorkExpModal}
                              className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                            >
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Add
                            </button>
                          </div>
                          {userData &&
                          userData.workExperience &&
                          userData.workExperience.length > 0 ? (
                            userData.workExperience.map((experience, index) => (
                              <div key={index}>
                                <div className="PDetail__3__row   mt-2 d-flex justify-content-md-start justify-content-between">
                                  <div className="PDetail__3__row__col PDetail__3__row__col1">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Company
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.companyName}
                                    </p>
                                  </div>
                                  <div className="PDetail__3__row__col">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Title
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.title}
                                    </p>
                                  </div>
                                </div>
                                <div className="PDetail__3__row   mt-4 d-flex justify-content-md-start justify-content-between">
                                  <div className="PDetail__3__row__col PDetail__3__row__col1">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Country
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {
                                        Country.find(
                                          (country) =>
                                            country.code === experience.country
                                        )?.name
                                      }
                                    </p>
                                  </div>
                                  <div className="PDetail__3__row__col">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      City
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.city}
                                    </p>
                                  </div>
                                </div>
                                <div className="PDetail__3__row   mt-4 d-flex justify-content-md-start justify-content-between">
                                  <div className="PDetail__3__row__col">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Starting Date
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.startDate}
                                    </p>
                                  </div>
                                  <div className="PDetail__3__row__col">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Ending Date
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.endDate}
                                    </p>
                                  </div>
                                </div>
                                <div className="PDetail__3__row   mt-4 d-flex justify-content-md-start justify-content-between">
                                  <div className="PDetail__3__row__col">
                                    <p className="text-14 fm darkGrey mb-0 fw-light">
                                      Description
                                    </p>
                                    <p className="text-14 fm darkGrey my-0">
                                      {experience.description}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="PDetail__3__row mt-2">
                              <p className="text-14 fm darkGrey my-0">
                                No work experience added
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="PDetail__2 position-relative overflow-hidden mb-3">
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <Progress
                        percent={progress}
                        steps={5}
                        showInfo={false}
                        className="w-100 position-absolute top-0 start-0"
                      />
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {progress}% complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>
                    <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap">
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                        <div className=" PDetail__top--blue__left me-md-3">
                          <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                            Purpose Statement Video{" "}
                          </h6>
                          <p className="fm darkGrey mb-0 text-14">
                            First impression is everything; we humanize our
                            process with your elevator pitch. You are a rockstar
                            – go get it!
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            onClick={() => {
                              handleTabClick(0);
                            }}
                            className="PDetail__top--blue__skip fm  me-2"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              handleTabClick(2);
                            }}
                            className="PDetail__top--blue__goto rounded-2 fm"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="PDetail__2 p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-start">
                      <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                        Video
                      </h6>
                      <button
                        onClick={EditVideoModal}
                        className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                      >
                        <CiEdit className="darkGrey me-1 fs-6" />
                        Edit
                      </button>
                    </div>
                    <div className="yourProfile__videoUpload mb-0 rounded-4 mt-3">
                      <div className="yourProfile__videoUpload__frame h-100">
                        {userData.elevatorPitch && (
                          <iframe
                            frameborder="0"
                            className="w-100 h-100 rounded-4"
                            src={userData.elevatorPitch.file}
                            title="2"
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <Modal
                      className="certification-popup"
                      title="Change Video"
                      visible={videoVisible}
                      onOk={EditVideohandleOk}
                      onCancel={EditVideohandleCancel}
                      footer={null}
                      width={600}
                    >
                      <div className="add-cert-form edit-name-popup photo-popup">
                        <div className="row">
                          <div className="col-md-12">
                            {videoFile && (
                              <div className="video-preview">
                                <button
                                  onClick={DeleteSelectedVideo}
                                  className="delete-button profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                                >
                                  <AiOutlineDelete className="darkGrey me-1 fs-6" />
                                  Delete
                                </button>
                                <video width="100%">
                                  <source
                                    src={
                                      videoFile &&
                                      URL.createObjectURL(videoFile[0].blobFile)
                                    }
                                    type={videoFile.type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            )}
                            {!videoFile && (
                              <Uploader
                                multiple={false}
                                fileListVisible={false}
                                listType="picture-text"
                                accept="video/mp4,video/mov,video/wmv"
                                action="//jsonplaceholder.typicode.com/posts/"
                                onChange={
                                  (value) => {
                                    setVideoFile(value);
                                  }
                                  // handleChange("files", value)
                                }
                                renderFileInfo={(file, fileElement) => {
                                  return (
                                    <div>
                                      <span>File Name: {file.name}</span>
                                      <p style={{ marginLeft: 13 }}>
                                        File type: {file.blobFile.type}
                                      </p>
                                    </div>
                                  );
                                }}
                                className="pt-4"
                              >
                                <div>
                                  {!videoFile && (
                                    <div className="upload-photo-box video-box">
                                      <p>Attach or drag Video here</p>
                                    </div>
                                  )}
                                </div>
                              </Uploader>
                            )}
                          </div>
                          <div className="col-md-12 photo-popup-detail">
                            <p className="mt-3">Video Formats: mp4</p>
                            <h4>Show clients the best version of yourself</h4>
                            {/* <h4 className="px-5">We recommend a professional image for your profile</h4> */}
                            <p>Must be an actual video of you</p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "16px" }}
                        className="cert-popup-buttons"
                      >
                        <Button
                          className="primary"
                          onClick={EditVideohandleCancel}
                          style={{ marginRight: "8px" }}
                        >
                          Cancel
                        </Button>
                        <button
                          className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                          onClick={handleVideoUpload}
                        >
                          {videoUrlLoading ? <Loader /> : "Upload Video"}
                        </button>
                      </div>
                    </Modal>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="PDetail__2 position-relative overflow-hidden mb-3">
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <Progress
                        percent={progress}
                        steps={5}
                        showInfo={false}
                        className="w-100 position-absolute top-0 start-0"
                      />
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {progress}% complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>
                    <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap">
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                        <div className=" PDetail__top--blue__left me-md-3">
                          <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                            Interview Questions{" "}
                          </h6>
                          <p className="fm darkGrey mb-0 text-14">
                            Your answers to these questions are critical input
                            to our profile analysis. We researched and designed
                            questions necessary to help you build Future of Work
                            candidate profile.
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            onClick={() => {
                              handleTabClick(1);
                            }}
                            className="PDetail__top--blue__skip fm  me-2"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              handleTabClick(3);
                            }}
                            className="PDetail__top--blue__goto rounded-2 fm"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="PDetail__2 p-3 mb-3">
                    <div className="d-flex justify-content-between align-items-start">
                      <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                        Interview Questions
                      </h6>
                      {isEditing ? (
                        <button
                          className="profile__update d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          onClick={handleUpdateClick}
                        >
                          {questionsLoading ? (
                            <Loader />
                          ) : (
                            <>
                              <CiEdit className="darkGrey me-1 fs-6" /> Update
                            </>
                          )}
                        </button>
                      ) : (
                        <button
                          className="profile__edit d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          onClick={handleEditClick}
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      )}
                    </div>
                    <div className="yourProfile__questions__list pt-3">
                      <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                        <div className="me-2">1.</div> What do you like doing
                        outside of work and why?
                      </p>
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control-lg form-control mb-3"
                          id="exampleInputEmail1"
                          aria-describedby="question1"
                          placeholder="Your message..."
                          rows={3}
                          readOnly={!isEditing}
                          value={textAreaValues.question1}
                          onChange={(e) => handleTextAreaChange(e, "question1")}
                        />
                      </div>
                      <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                        <div className="me-2">2.</div> What would a perfect role
                        look like for you and who else would be on the team?
                      </p>
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control-lg form-control mb-3"
                          id="exampleInputEmail1"
                          aria-describedby="question1"
                          placeholder="Your message..."
                          rows={3}
                          readOnly={!isEditing}
                          value={textAreaValues.question2}
                          onChange={(e) => handleTextAreaChange(e, "question2")}
                        />
                      </div>
                      <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                        <div className="me-2">3.</div> Which company would you
                        most like to work for and how does their culture align
                        with your values?
                      </p>
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control-lg form-control mb-3"
                          id="exampleInputEmail1"
                          aria-describedby="question1"
                          placeholder="Your message..."
                          rows={3}
                          readOnly={!isEditing}
                          value={textAreaValues.question3}
                          onChange={(e) => handleTextAreaChange(e, "question3")}
                        />
                      </div>
                      <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                        <div className="me-2">4.</div> In 10 years, how will you
                        have fulfilled your purpose?
                      </p>
                      <div className="form-group mb-3">
                        <textarea
                          type="text"
                          className="form-control-lg form-control mb-md-0 mb-4"
                          id="exampleInputEmail1"
                          aria-describedby="question1"
                          placeholder="Your message..."
                          rows={3}
                          readOnly={!isEditing}
                          value={textAreaValues.question4}
                          onChange={(e) => handleTextAreaChange(e, "question4")}
                        />
                      </div>
                      <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                        <div className="me-2">5.</div> In five years, how will
                        you have fulfilled your purpose?
                      </p>
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control-lg form-control mb-md-0 mb-4"
                          id="exampleInputEmail1"
                          aria-describedby="question1"
                          placeholder="Your message..."
                          rows={3}
                          readOnly={!isEditing}
                          value={textAreaValues.question5}
                          onChange={(e) => handleTextAreaChange(e, "question5")}
                        />
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="PDetail__2 position-relative overflow-hidden mb-3">
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <Progress
                        percent={progress}
                        steps={5}
                        showInfo={false}
                        className="w-100 position-absolute top-0 start-0"
                      />
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {progress}% complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>

                    <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap">
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                        <div className=" PDetail__top--blue__left me-md-3">
                          <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                            References{" "}
                          </h6>
                          <p className="fm darkGrey mb-0 text-14">
                            Who your references are, where they work, how long
                            you worked with them, etc. all play important role
                            in building your IndexScore.
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            onClick={() => {
                              handleTabClick(2);
                            }}
                            className="PDetail__top--blue__skip fm  me-2"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() => {
                              handleTabClick(4);
                            }}
                            className="PDetail__top--blue__goto rounded-2 fm"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="PDetail__3 p-3 mb-3">
                    <div className="pb-2">
                      <div className="">
                        <div className="d-flex justify-content-between align-items-start">
                          <div>
                            <h6 className="fm text-20 mb-2 darkBlue fw-semibold">
                              Referrences
                            </h6>
                            <p className="fm darkGrey text-14">
                              Please enter corporate email address of your
                              references
                            </p>
                          </div>
                          <button
                            disabled={userData.references.length == 3}
                            className="profile__edit d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                            onClick={updateProReferencesHandler}
                          >
                            {isReferenceUpdate ? (
                              <Loader />
                            ) : (
                              <>
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Update
                              </>
                            )}
                          </button>
                        </div>
                        <div className="references__emails">
                          {emails.map((emailinput, index) => (
                            <div key={index} className="d-flex">
                              <input
                                type="text"
                                className="form-control form-control-lg mt-3"
                                placeholder="First Name"
                                value={emailinput.referencefirstName}
                                onChange={(e) =>
                                  handleEmailChange(
                                    index,
                                    "referencefirstName",
                                    e.target.value
                                  )
                                }
                              />
                              <input
                                type="text"
                                className="form-control form-control-lg mt-3 mx-2"
                                placeholder="Last Name"
                                value={emailinput.referencelastName}
                                onChange={(e) =>
                                  handleEmailChange(
                                    index,
                                    "referencelastName",
                                    e.target.value
                                  )
                                }
                              />
                              <input
                                type="email"
                                value={emailinput.referenceEmail}
                                className="form-control form-control-lg mt-3"
                                placeholder="Email"
                                onChange={(event) => {
                                  if (disAllowEmails(event)) {
                                    handleEmailChange(
                                      index,
                                      "referenceEmail",
                                      event.target.value
                                    );
                                  }
                                }}
                                required
                              />
                            </div>
                          ))}

                          {errorState && (
                            <div className="invalid-email">
                              Please enter corporate email address of your
                              references
                            </div>
                          )}

                          {emails.length < 3 && (
                            <div
                              className="d-flex align-items-center mt-3 pointer"
                              onClick={addEmail}
                            >
                              <BsPlusCircle className="Extra__icon me-2 fs-2" />
                              <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
                                Add another reference?
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Extra /> */}
                </TabPanel>
                <TabPanel>
                  <div className="PDetail__2 position-relative overflow-hidden mb-3">
                    <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                      <Progress
                        percent={progress}
                        steps={5}
                        showInfo={false}
                        className="w-100 position-absolute top-0 start-0"
                      />
                      <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                        Account completion{" "}
                      </h6>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                          {progress}% complete
                        </span>
                        {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                      </div>
                    </div>

                    <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap">
                      <div className="d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                        <div className=" PDetail__top--blue__left me-md-3">
                          <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                            Online Presence{" "}
                          </h6>
                          <p className="fm darkGrey mb-0 text-14">
                            We analyze what you share publicly to understand
                            thoughtleadership, level of industry influence and
                            also help you understand areas where employers may
                            have questions during job interviews.
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            onClick={() => {
                              handleTabClick(3);
                            }}
                            className="PDetail__top--blue__skip fm  me-2"
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="PDetail__3 p-3 mb-3">
                    <div className="row g-3 mb-3">
                      <div className="col-md-12">
                        <h5 className="YDetail__heading darkBlue ">
                          Online Presense
                        </h5>
                      </div>
                      <div className="col-md-4">
                        <div className="api__boxes h-100 mb-0">
                          <div className="api__box__header">
                            <div>
                              <img src="/assets/images/api/fb.png" />{" "}
                            </div>
                            <div>
                              {/* <button disabled={userData.facebook_authorised} 
                              className={`connect__button ${userData.facebook_authorised ? 'connected' : 'pending'}`} 
                              onClick={() => { myFbLogin(); }}>{userData.facebook_authorised ? 'Connected' : loadingFacebook ? <Loader /> : 'Connect'}
                              </button> */}
                              <FacebookLogin
                                appId={FB_ID}
                                className={`connect__button ${
                                  userData.facebook_authorised
                                    ? "connected"
                                    : "pending"
                                }`}
                                useRedirect={false}
                                children={
                                  <div>
                                    {userData.facebook_authorised
                                      ? "Connected"
                                      : "Connect"}
                                  </div>
                                }
                                fields="id,name,email,picture,posts,friends,likes"
                                onSuccess={(sucessdata) => {}}
                                onFail={(faildata) => {
                                  enqueueSnackbar(
                                    "Facebook not authorized",
                                    {
                                      variant: "error",
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                      },
                                    },
                                    { autoHideDuration: 1000 }
                                  );
                                }}
                                onProfileSuccess={async (onprofilesuccess) => {
                                  const payloadFacebook = {
                                    _id: userData._id,
                                    ai_data: {
                                      ...userData.ai_data,
                                      fb: onprofilesuccess,
                                    },
                                    facebook_profile_data: onprofilesuccess,
                                    facebook_authorised: true,
                                  };

                                  const responseFacebook =
                                    await updateProfessional(payloadFacebook);
                                  if (responseFacebook) {
                                    updatedProfessionalData();
                                    setLoadingFacebook(false);
                                    enqueueSnackbar(
                                      "Facebook Authorized!",
                                      {
                                        variant: "success",
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "right",
                                        },
                                      },
                                      { autoHideDuration: 1000 }
                                    );
                                  }
                                }}
                                initParams={{
                                  version: "v10.0",
                                  xfbml: true,
                                }}
                                dialogParams={{
                                  response_type: "token",
                                }}
                                loginOptions={{
                                  return_scopes: true,
                                }}
                              />
                            </div>
                          </div>
                          <div className="api__box__body">
                            <h4  className="fm darkGrey fs-6">Facebook</h4>
                            <p className="fm darkGrey text-14">
                              While this is not a requirement, we can help you
                              identify and inform you about any potential risks
                              associated with your Facebook page before future
                              employers do.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="api__boxes h-100 mb-0">
                          <div className="api__box__header">
                            <div>
                              <img src="/assets/images/api/insta.png" />{" "}
                            </div>
                            <div>
                              <button
                                disabled={userData.instagram_authorised}
                                className={`connect__button ${
                                  userData.instagram_authorised
                                    ? "connected"
                                    : "pending"
                                }`}
                                onClick={instagramOAuth}
                              >
                                {userData.instagram_authorised ? (
                                  "Connected"
                                ) : loadingInstagram ? (
                                  <Loader />
                                ) : (
                                  "Connect"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="api__box__body">
                            <h4  className="fm darkGrey fs-6">Instagram</h4>
                            <p className="fm darkGrey text-14">
                              While this is not a requirement, we can help you
                              identify and inform you about any potential risks
                              associated with your Instagram page before future
                              employers do.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="api__boxes h-100 mb-0">
                          <div className="api__box__header">
                            <div>
                              <img src="/assets/images/api/twitter.png" />{" "}
                            </div>
                            <div>
                              <button
                                disabled={userData.twitter_authorised}
                                className={`connect__button ${
                                  userData.twitter_authorised
                                    ? "connected"
                                    : "pending"
                                }`}
                                onClick={myTwitterLogin}
                              >
                                {userData.twitter_authorised ? (
                                  "Connected"
                                ) : loading5 ? (
                                  <>
                                    <Loader className="fs-3 loader-icon" />
                                  </>
                                ) : (
                                  "Connect"
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="api__box__body">
                            <h4 className="fm darkGrey fs-6">Twitter</h4>
                            {userData &&
                              userData?.twitter_authorised == false && (
                                <input
                                  type="url"
                                  className="form-control form-control-lg"
                                  placeholder="Twitter"
                                  onChange={(value) =>
                                    handleChangeSkills(
                                      "twitterURL",
                                      value.target.value
                                    )
                                  }
                                />
                              )}
                            <p className="fm darkGrey text-14 mt-0">
                              Employers as part of their hiring process assess
                              candidates social media presence; while 99.9% of
                              the is harmless, we help you understand any
                              potential risk associated with your account.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="api__boxes h-100 mb-0">
                          <div className="api__box__header">
                            <div>
                              <img src="/assets/images/api/linkedin.png" />{" "}
                            </div>
                            <div>
                              <button
                                disabled={userData.linkedIn_authorised}
                                className={`connect__button ${
                                  userData.linkedIn_authorised
                                    ? "connected"
                                    : "pending"
                                }`}
                                onClick={myLinkedinLogin}
                              >
                                {userData.linkedIn_authorised ? (
                                  "Connected"
                                ) : loadingLinkedin ? (
                                  <>
                                    <Loader className="fs-3 loader-icon" />
                                  </>
                                ) : (
                                  "Connect"
                                )}{" "}
                              </button>
                            </div>
                          </div>
                          <div className="api__box__body">
                            <h4 className="fm darkGrey fs-6">Linkedin</h4>
                            {userData &&
                              userData?.linkedIn_authorised == false && (
                                <input
                                  type="url"
                                  className="form-control form-control-lg"
                                  placeholder="LinkedIn url"
                                  value={companyLinkedin}
                                  onChange={(value) =>
                                    handleChangeSkills(
                                      "companyLinkedin",
                                      value.target.value
                                    )
                                  }
                                />
                              )}
                            <p className="fm darkGrey text-14">
                              We use LinkedIn data to help job candidates land
                              opportunities and strengthen professional network.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Online para="Employers check your online activities as part of their hiring process; we assess your online activities to help you identify areas of improvement opportunities."
                    color="blue"
                    page="professional" /> */}
                </TabPanel>
              </Tabs>
            </div>

            <div></div>
            {/* <div className="PDetail__3 p-3 d-flex flex-md-row flex-column justify-content-between align-items-md-center">
              <div className="me-2">
                <h6 className="fm text-20 mb-0 darkGrey fw-semibold">
                  Delete your account
                </h6>
                <p className="fm darkGrey mt-2 PDetail__3__row mb-0">
                  It is possible to delete your account but once deleted there is no
                  way to recover any information linked to the account. Please be sure
                  you want to delete your account before confirming.
                </p>
              </div>
              <button className="PDetail__3__delete fm text-white transition mt-md-0 mt-3">Delete account</button>
            </div> */}
          </div>
        </div>

        <Modal
          className="certification-popup"
          title="About You"
          visible={aboutYouVisible}
          onOk={handleAboutYouOk}
          onCancel={handleAboutYouCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-12">
                <label>
                  <textarea
                    type="text"
                    className="form-control-lg form-control mb-3"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="About You"
                    rows={3}
                    value={updateAboutYou}
                    onChange={(e) => setUpdateAboutYou(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={handleAboutYouCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateAboutYouHandler}
            >
              {updateAboutYouLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>

        <Modal
          className="certification-popup"
          title="Social Channels"
          visible={editSocialsVisible}
          onOk={EditSocialshandleOk}
          onCancel={EditSocialshandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-1">
                <FaLinkedin className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Linkedin url"
                  value={updateLinkedin}
                  onChange={(value) => {
                    setUpdateLinkedin(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaInstagram className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Instagram url"
                  value={updateInstaLink}
                  onChange={(value) => {
                    setUpdateInstaLink(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaSquareXTwitter className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Twitter url"
                  value={updateTwitterLink}
                  onChange={(value) => {
                    setUpdateTwitterLink(value.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditSocialshandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateSocialsHandler}
            >
              {updateSocialsLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>

        <Modal
          className="certification-popup"
          title="Address"
          visible={editAboutYouVisible}
          onOk={EditAboutYouhandleOk}
          onCancel={EditAboutYouhandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Country
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                    size="small"
                    value={updateCountry}
                    placeholder="Job location"
                    onChange={(value) => {
                      // handleChange("job_locations", value);
                      setUpdateCountry(value);
                    }}
                  >
                    <OptGroup>
                      {Country.map((item) => (
                        <Option
                          value={item.code}
                          label={item.name.toLowerCase()}
                          key={item.code}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  State/City
                  <input
                    type="text"
                    className="form-control form-control-md  w-100 Dashboard__input mb-2"
                    placeholder="Enter state/city"
                    id="sector1"
                    value={updateState}
                    onChange={(e) => setUpdateState(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Postal/Zip Code
                  <input
                    type="text"
                    className="form-control form-control-md  w-100 Dashboard__input mb-2"
                    placeholder="Enter postal/zip code"
                    id="sector1"
                    value={updatePostalCode}
                    onChange={(e) => setUpdatePostalCode(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Your address
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Enter your address"
                    id="sector1"
                    value={updateAddress}
                    onChange={(e) => setUpdateAddress(e.target.value)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditAboutYouhandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateProAboutYouHandler}
            >
              {updateProAboutYouLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>

        <Modal
          className="certification-popup"
          title="Work Experience"
          visible={editWorkExpVisible}
          onOk={EditWorkExphandleOk}
          onCancel={EditWorkExphandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Company Name
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Enter company name"
                    id="sector1"
                    value={updateWorkExpCompanyName}
                    onChange={(e) =>
                      setUpdateWorkExpCompanyName(e.target.value)
                    }
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Title
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Enter title"
                    id="sector1"
                    value={updateWorkExpTitle}
                    onChange={(e) => setUpdateWorkExpTitle(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Country
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                    size="small"
                    value={updateWorkExpCountry}
                    placeholder="Job location"
                    onChange={(value) => {
                      // handleChange("job_locations", value);
                      setUpdateWorkExpCountry(value);
                    }}
                  >
                    <OptGroup>
                      {Country.map((item) => (
                        <Option
                          value={item.code}
                          label={item.name.toLowerCase()}
                          key={item.code}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  City/State
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Enter title"
                    id="sector1"
                    value={updateWorkExpCity}
                    onChange={(e) => setUpdateWorkExpCity(e.target.value)}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Starting Date
                  <input
                    type="date"
                    className="form-control form-control-md w-100 Dashboard__input "
                    placeholder="Starting date"
                    title="Select starting date"
                    value={updateWorkExpStartDate || ""}
                    onChange={(value) => {
                      setUpdateWorkExpStartDate(value.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Ending Date
                  <input
                    type="date"
                    className="form-control form-control-md w-100 Dashboard__input "
                    placeholder="Ending date"
                    title="Select ending date"
                    value={updateWorkExpEndDate || ""}
                    onChange={(value) => {
                      setUpdateWorkExpEndDate(value.target.value);
                    }}
                  />
                </label>
              </div>

              <div className="col-md-12">
                <label>
                  <textarea
                    type="text"
                    className="form-control-lg form-control mb-3"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Description"
                    rows={3}
                    value={updateWorkExpDescription}
                    onChange={(e) =>
                      setUpdateWorkExpDescription(e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditWorkExphandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateWorkExperienceHandler}
            >
              {updateWorkExpLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default YDetail;
