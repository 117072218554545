import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Link,
  Font,
} from "@react-pdf/renderer";
import moment from 'moment';

const EnterpriseInvoice = ({invoiceData}) => {
  // console.log("docResponse in hiring report", docResponse);

  Font.register({
    family: "Open Sans",
    fonts: [
      { src: "/assets/fonts/OpenSans-Regular.ttf", fontWeight: 600 },
      { src: "/assets/fonts/OpenSans-Bold.ttf", fontWeight: 800 },
      { src: "/assets/fonts/OpenSans-ExtraBold.ttf", fontWeight: 900 },
      { src: "/assets/fonts/OpenSans-SemiBold.ttf", fontWeight: 700 },
      { src: "/assets/fonts/OpenSans-Light.ttf", fontWeight: 400 },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 30,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 17,
      marginTop: 20,
    },
    logoLarge: {
      width: 225,
      display: "block",
      marginBottom: 40,
      marginTop: 40,
    },
    rating: {
      width: 9,
      height: 9,
      display: "block",
      marginRight: 3,
    },
    heading: {
      display: "block",
      marginBottom: 18,
      fontSize: 24,
      fontWeight: 800,
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 14,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 800,
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
      paddingBottom: 40,
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 18,
      minHeight: 18,
      width: 15,
      height: 15,
      marginRight: 5,
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      color: "#B2DA66",
      fontSize: 9,
      fontWeight: "600",
      display: "block",
      marginRight: 5,
    },
    list: {
      width: "80%",
      marginBottom: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    bulletDot: {
      color: "black",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginRight: 2,
      marginLeft: 3,
    },
    top: {
      display: "flex",
      flexDirection: "row",
      marginBottom: 10,
    },
    font7Bold: {
      color: "#4A4A49",
      fontSize: 7,
      fontFamily: "Open Sans",
      fontWeight: 800,
      marginBottom: 0,
    },
    font7Light: {
      color: "#4A4A49",
      fontSize: 7,
      fontFamily: "Open Sans",
      fontWeight: 400,
      marginBottom: 0,
    },
    font7Normal: {
      color: "#4A4A49",
      fontSize: 7,
      fontFamily: "Open Sans",
      fontWeight: 700,
      marginBottom: 0,
    },
    font8Bold: {
      color: "#4A4A49",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 800,
      marginBottom: 0,
    },
    font8Light: {
      color: "#4A4A49",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 400,
      marginBottom: 0,
    },
    font8Normal: {
      color: "#4A4A49",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 700,
      marginBottom: 0,
    },
    font9Light: {
      color: "#4A4A49",
      fontSize: 9,
      fontFamily: "Open Sans",
      fontWeight: 400,
      marginBottom: 0,
    },
    font9Normal: {
      color: "#4A4A49",
      fontSize: 9,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginBottom: 0,
    },
    font9Bold: {
      color: "#4A4A49",
      fontSize: 9,
      fontFamily: "Open Sans",
      fontWeight: 800,
      marginBottom: 0,
    },
    font12Bold: {
      color: "#0D2159",
      fontSize: 12,
      fontFamily: "Open Sans",
      fontWeight: 800,
      marginBottom: 0,
    },
    font12Light: {
      color: "#0D2159",
      fontSize: 12,
      fontFamily: "Open Sans",
      fontWeight: 400,
      marginBottom: 0,
    },
    table: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 10,
      marginTop: 15,
    },
    tableHeader: {
      backgroundColor: "#0D2159",
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: 5,
      paddingVertical: 7,
    },
    tableHeaderText: {
      color: "#B2DA66",
      fontSize: 9,
      fontFamily: "Open Sans",
      fontWeight: 800,
      marginBottom: 0,
      textTransform: "uppercase",
    },
    tableCol1: {
      width: "40%",
    },
    tableCol2: {
      width: "20%",
      textAlign: "right",
    },
    tableRow: {
      borderBottom: "0.5px solid #000c2c",
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: 5,
      paddingVertical: 7,
    },
    tableSubTotal: {
      backgroundColor: "#E6E6E6",
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: 5,
      paddingVertical: 7,
    },
    tableTotal: {
      backgroundColor: "#B2DA66",
      display: "flex",
      flexDirection: "row",
      paddingHorizontal: 5,
      paddingVertical: 9,
    },
    bottom: {
      display: "flex",
      flexDirection: "column",
      borderTop: "0.5px solid black",
      borderBottom: "0.5px solid black",
      borderBottomStyle: "dotted",
      borderTopStyle: "dotted",
      paddingBottom: 10,
      paddingTop: 12,
      marginTop: 12,
    },
  });
  return (
    // <PDFViewer style={styles.viewer}>
      <Document style={styles.doc}>
        <Page style={styles.page}>
          <View
            style={[
              styles.container,
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              },
            ]}
          >
            <View>
              <Image
                src="/assets/svg/logo.png"
                style={styles.logoLarge}
                alt="logo"
              />

              <Text style={styles.heading}>INVOICE</Text>

              <View style={styles.top}>
                <View style={{ width: "50%" }}>
                  {/* <Text style={styles.font9Light}>
                    {"<<"}Contact_Name{">>"}
                  </Text> */}
                  <Text style={styles.font9Bold}>
                    {/* {"<<"}Company_Name{">>"} */}
                    {invoiceData.account_name}
                  </Text>
                  <Text style={styles.font9Light}>
                    {/* {"<<"}Address_line_1{">>"} */}
                    1309 Coffeen Avenue
                  </Text>
                  <Text style={styles.font9Light}>
                    {/* {"<<"}Address_line_2{">>"} */}
                    Sheridan, Wyoming 82801
                  </Text>
                  {/* <Text style={styles.font9Light}>
                    {"<<"}Address_line_3{">>"}
                  </Text>
                  <Text style={styles.font9Light}>
                    {"<<"}Address_line_4{">>"}
                  </Text>
                  <Text style={styles.font9Light}>
                    {"<<"}Post_code{">>"}
                  </Text> */}
                  <Text style={styles.font9Light}>
                    {/* {"<<"}Country{">>"} */}
                    {invoiceData.account_country}
                  </Text>
                </View>

                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Invoice Date</Text>
                    <Text style={styles.font9Light}>
                      {/* {"<<"}Invoice_Date{">>"} */}
                      {moment(invoiceData.created * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </View>

                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Invoice Number</Text>
                    <Text style={styles.font9Light}>
                      {/* {"<<"}Invoice_Number{">>"} */}
                      {invoiceData.number}
                    </Text>
                  </View>

                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Due Date</Text>
                    <Text style={styles.font9Light}>
                      {/* {"<<"}Due_Date{">>"} */}
                      {moment(invoiceData.effective_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                  </View>
                </View>

                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Account Number</Text>
                    <Text style={styles.font9Light}>
                      {"<<"}Account_Number{">>"}
                    </Text>
                  </View>

                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Reference</Text>
                    <Text style={styles.font9Light}>
                      {"<<"}Reference{">>"}
                    </Text>
                  </View>

                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font9Bold}>Tax Number</Text>
                    <Text style={styles.font9Light}>
                      {"<<"}Tax_Number{">>"}
                    </Text>
                  </View>
                </View>
              </View>

              {/* table */}
              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableHeaderText}>Description</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableHeaderText}>Number</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableHeaderText}>Unit Cost</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableHeaderText}>Amount</Text>
                  </View>
                </View>

                <View>
                  {invoiceData?.lines?.data.map((item) => (
                    <View key={item.id} style={styles.tableRow}>
                      <View style={styles.tableCol1}>
                        <Text style={styles.font9Bold}>
                          {/* Monthly licence fee{" "} */}
                          {item.description} 
                          <Text style={styles.font9Light}>
                            {/* (<<Month>>) */}
                             {item.plan.nickname}
                          </Text>
                        </Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.font9Normal}>
                          {item.quantity}
                        </Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.font9Normal}>
                          ${item.amount / 100} {/* Convert amount from cents to dollars */}
                        </Text>
                      </View>
                      <View style={styles.tableCol2}>
                        <Text style={styles.font9Normal}>
                          ${item.amount / 100}
                          {/* ${item.amount_excluding_tax / 100} */}
                        </Text>
                      </View>
                    </View>
                  ))}
                </View>
                
                <View style={styles.tableSubTotal}>
                  <View style={styles.tableCol1}></View>
                  <View style={styles.tableCol2}></View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.font9Bold}>SUB TOTAL</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.font9Bold}>
                      {/* {"<<"}${">>"} */}
                      ${invoiceData.subtotal / 100}
                    </Text>
                  </View>
                </View>                

                <View style={styles.tableTotal}>
                  <View style={styles.tableCol1}></View>
                  <View style={styles.tableCol2}></View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.font12Bold}>
                      TOTAL <Text style={styles.font12Light}>(USD)</Text>
                    </Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.font12Bold}>
                      {/* {"<<"}${">>"} */}
                      {invoiceData.total/ 100}
                    </Text>
                  </View>
                </View>
              </View>

              {/* notes */}
              <Text style={styles.font7Bold}>NOTES:</Text>
              <Text style={styles.font7Light}>
                <Text style={styles.font7Normal}>DOP1</Text>
                {"<<"}Definition of this drop off point in the application{">>"}
              </Text>
              <Text style={styles.font7Light}>
                <Text style={styles.font7Normal}>DOP2</Text>
                {"<<"}Definition of this drop off point in the application{">>"}
              </Text>
            </View>

            <View style={styles.bottom}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Bank </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_Bank_Name{">>"}
                    </Text>
                  </View>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Account Name </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_account_name{">>"}
                    </Text>
                  </View>
                </View>

                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Account Number </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_Bank_Name{">>"}
                    </Text>
                  </View>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>ACH / Routing number </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_ACH / Routing{">>"}
                    </Text>
                  </View>
                </View>

                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Bank address </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_bank_address_1{">>"}
                    </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_bank_address_2{">>"}
                    </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_bank_address_3{">>"}
                    </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_bank_ZIP{">>"}
                    </Text>
                  </View>
                </View>

                <View style={{ width: "25%" }}>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Payment terms </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_payment_terms{">>"}
                    </Text>
                  </View>
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.font7Bold}>Queries </Text>
                    <Text style={styles.font7Light}>
                      {"<<"}Our_finance_query_email_address{">>"}
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderTop: "0.5px solid black",
                  borderTopStyle: "dotted",
                  paddingTop: 10,
                  marginTop: 10,
                }}
              >
                <View style={{ width: "40%" }}>
                  <Text style={styles.font7Light}>{"<<"}Our_postal_address{">>"}</Text>
                  <Text style={styles.font7Light}>{"<<"}Our_phone_number{">>"}</Text>
                  <Text style={styles.font7Light}>{"<<"}Our_email_address{">>"}</Text>
                </View>

                <View style={{ width: "40%", textAlign: "right" }}>
                  <Text style={styles.font7Light}>{"<<"}Our_regsitered_company_name{">>"}</Text>
                  <Text style={styles.font7Light}>{"<<"}Our_registered_company_number{">>"}</Text>
                  <Text style={styles.font7Light}>{"<<"}Our_tax_number{">>"}</Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    // </PDFViewer>
  );
};


export default EnterpriseInvoice;
