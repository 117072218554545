import React, { useEffect, useContext, useState } from "react";
import './style.css';
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsCheck2 } from "react-icons/bs";
import { useSnackbar } from "notistack";
import { subscriptionListAll, subscribeSubscription, getEnterpriseByID,upgradeSubscription } from "../../../../services/auth";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { Modal, Spin } from 'antd';

const plansData = [
    {
        name: 'Base',
        features: ['IndexScore', 'Job Relevance'],
        disabledFeatures: ['ATS Integration', 'Compliance Report', 'Purpose Alignment score', 'Integrated candidates engagement', 'Offer and Contract management', 'Talent availability insights'],
    },
    {
        name: 'Grow',
        features: ['IndexScore', 'Job Relevance', 'ATS Integration', 'Compliance Report'],
        disabledFeatures: ['Purpose Alignment score', 'Integrated candidates engagement', 'Offer and Contract management', 'Talent availability insights'],
    },
    {
        name: 'Scale',
        features: ['IndexScore', 'Job Relevance', 'ATS Integration', 'Compliance Report', 'Purpose Alignment score', 'Integrated candidates engagement', 'Offer and Contract management'],
        disabledFeatures: ['Talent availability insights'],
    },
    {
        name: 'Predict',
        features: ['IndexScore', 'Job Relevance', 'ATS Integration', 'Compliance Report', 'Purpose Alignment score', 'Integrated candidates engagement', 'Offer and Contract management', 'Talent availability insights'],
        disabledFeatures: [],
    },
];

const UpgradeEnterprisePlan = () => {
    const { setUserData, getUserData } = useContext(MyContext);
    const userDataFromContext = getUserData();
    const userData = userDataFromContext && userDataFromContext[0];
    const { enqueueSnackbar } = useSnackbar();
    const [plans, setPlans] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            try {
                const response = await subscriptionListAll();
                if (response.data.statusCode === 1) {
                    const sortOrder = ['Base', 'Grow', 'Scale', 'Predict'];
                    const sortedData = response.data.data.sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name));
                    console.log("sortedData",sortedData);
                    setPlans(sortedData);
                } else {
                    console.error("Error fetching plans:", response.data.statusDesc);
                }
            } catch (error) {
                console.error("Error fetching plans:", error);
            } finally {
                setLoading(false);
            }
        };
        

        fetchPlans();
    }, []);

    const purchaseSubscription = async (priceIdMonthly) => {
        const payload = {
            enterpriseId: userData.companyID,
            priceId: priceIdMonthly,
            interval: "Monthly"
        };

        setSelectedPlan(payload);
        setShowConfirmModal(true);
    };

    const confirmPurchase = async () => {
        setShowConfirmModal(false);
        setLoading(true);
        try {
            if (userData.subscription) {
                // User is already subscribed, call upgradeSubscription API
                console.log("userData.subscription is already",userData.subscription);
                console.log("selectedPlan",selectedPlan);
                const response = await upgradeSubscription(selectedPlan);
                console.log("userData.subscription is already",response);
                if (response.data.statusCode === 1) {
                    enqueueSnackbar("Subscription upgraded!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                    await updatedEnterpriseData();
                }
            } else {
                // User is not subscribed, call subscribeSubscription API
                const response = await subscribeSubscription(selectedPlan);
                console.log("subscribeSubscription is already",response);
                if (response.data.statusCode === 1) {
                    enqueueSnackbar("Subscribed!", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                    });
                    await updatedEnterpriseData();
                }
            }
        } catch (error) {
            console.error("Error subscribing/upgrading:", error);
        } finally {
            setLoading(false);
        }
    };
    
    // upgradeSubscription

    const cancelPurchase = () => {
        setShowConfirmModal(false);
    };

    const updatedEnterpriseData = async () => {
        const payLoadtoGetPro = {
            _id: userData._id,
        };

        const response = await getEnterpriseByID(payLoadtoGetPro);
        const enterpriseData = response.data.data;
        localStorage.setItem("enterpriseData", JSON.stringify(enterpriseData));
        setUserData(enterpriseData);
    };

    return (
        <div className="Plan pb-5">
            <div className="Dashboard__container__content">
                <h5 className="YDetail__heading darkBlue text-center">Our Plans</h5>
                <p className="darkGrey fm text-center pb-3 fm">We’ve designed a flexible, practical and scalable model to meet your needs</p>
                <div className="">
                    <div className="row mt-5">
                        {plans.map((plan, index) => (
                            <div className={`col-xl-3 col-lg-4 col-sm-6 mb-3 ${plan.name === 'Scale' ? 'most__popular-col' : ''}`} key={plan._id}>
                                <div className={`plan__cards fm h-100 ${userData.subscription && userData.subscription._id === plan._id ? 'current__package' : ''} ${plan.name === 'Scale' ? 'most__popular' : ''}`}>
                                    <h4 className="plan__cards__heading">{plan.name}</h4>
                                    <div className="d-flex align-items-center plan__monthly__pricing justify-content-center">
                                        <h2>{plan.amountPerMonth}</h2><span>/MO</span>
                                    </div>
                                    <ul className="plan__monthly__features">
                                        {plansData[index].features.map(feature => (
                                            <li className="d-flex align-items-start" key={feature}><div><BsCheck2 /></div>{feature}</li>
                                        ))}
                                        {plansData[index].disabledFeatures.map(feature => (
                                            <li  key={feature} className="disabled d-flex align-items-start"><div><BsCheck2 /></div>{feature}</li>
                                        ))}
                                    </ul>
                                    <div className="package__button d-flex align-items-center justify-content-center py-2">
                                        {loading ? (
                                            <div className="spinner-container">
                                                <Spin size="large" />
                                            </div>
                                        ) : (
                                            <button className="buy__now__plans"
                                                disabled={userData.subscription && userData.subscription._id === plan._id}
                                                onClick={() => {
                                                    purchaseSubscription(plan.priceIdMonth)
                                                }}
                                            >Get Started</button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Modal
                title="Confirmation"
                visible={showConfirmModal}
                onOk={confirmPurchase}
                onCancel={cancelPurchase}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to purchase this subscription?</p>
            </Modal>
        </div>
    );
};

export default UpgradeEnterprisePlan;
