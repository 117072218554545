import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  getInterviewById,
  acceptInterviewApi,
  getProDashboard,
  getInterviewPrepById,
  getInterviewPrepByAppId,
  declineInterview,
} from "../../../services/auth";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { BsCloudArrowDown } from "react-icons/bs";

import { Loader } from "rsuite";
import moment from "moment";
import { ScheduleMeeting } from "react-schedule-meeting";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";

const PrepSheet = ({ staticData, indexScore }) => {
  console.log("staticData", staticData);
  const hrQuestionsArray = staticData.sheet.hr_questions_reply.split("\n");
  const filteredHrQuestionsArray = hrQuestionsArray.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredHrQuestionsArray = filteredHrQuestionsArray.filter(
    (question) => question.trim() !== ""
  );
  const interviewQuestionsArray =
    staticData.sheet.interview_questions_reply.split("\n");
  const interviewHrQuestionsArray = interviewQuestionsArray.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredinterviewQuestionsArray =
    interviewHrQuestionsArray.filter((question) => question.trim() !== "");
  const interviewJobCompReply = staticData.sheet.job_comp_reply.split("\n");
  const interviewJobCompReplyArray = interviewJobCompReply.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredCompReplyArray = interviewJobCompReplyArray.filter(
    (question) => question.trim() !== ""
  );

  Font.register({
    family: "Open Sans",
    fonts: [
      { src: "/assets/fonts/OpenSans-Regular.ttf", fontWeight: 600 },
      { src: "/assets/fonts/OpenSans-Bold.ttf", fontWeight: 800 },
      { src: "/assets/fonts/OpenSans-ExtraBold.ttf", fontWeight: 900 },
      { src: "/assets/fonts/OpenSans-SemiBold.ttf", fontWeight: 700 },
      { src: "/assets/fonts/OpenSans-Light.ttf", fontWeight: 400 },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topBar: {
      height: 6,
      backgroundColor: "#B2DA66",
      marginBottom: 10,
    },

    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 30,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 17,
      marginTop: 20,
    },
    rating: {
      width: 9,
      height: 9,
      display: "block",
      marginRight: 3,
    },
    heading: {
      display: "block",
      marginBottom: 10,
      fontSize: 24,
      fontWeight: 800,
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 14,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 800,
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
    },
    card: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#E6E6E6",
      marginTop: 5,
      marginBottom: 25,
    },
    cardTop: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#F5F5F5",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 12,
    },
    cardBottom: {
      fontFamily: "Open Sans",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 10,
      paddingBottom: 18,
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 18,
      minHeight: 18,
      width: 15,
      height: 15,
      marginRight: 5,
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      color: "#B2DA66",
      fontSize: 9,
      fontWeight: "600",
      display: "block",
      marginRight: 5,
    },
    list: {
      width: "80%",
      marginBottom: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    viewer: {
      width: 200,
      height: 400,
    },
    bulletDot: {
      color: "black",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginRight: 2,
      marginLeft: 3,
    },
  });
  return (
    <Document style={styles.doc}>
      <Page style={styles.page}>
        <View>
          <View style={styles.topBar} />
          <View style={styles.container}>
            <Image src="/assets/svg/logo.png" style={styles.logo} alt="logo" />

            <Text style={styles.heading}>Your Interview Prep Sheet</Text>

            <View style={styles.card}>
              {/* card */}
              <View style={styles.cardTop}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Image
                    src="/assets/images/Users/user1.png"
                    style={styles.avatar}
                  />
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                  >
                    {staticData.company_name}
                  </Text>
                </View>
                <Text style={styles.subTitle}>{staticData.job_title}</Text>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 10,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    marginTop: 1,
                  }}
                >
                  {staticData.sheet.job_desc_reply}
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly",
                    marginTop: 8,
                    width: "100%",
                    paddingBottom: 10,
                  }}
                >
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      CEO Recommendation : {staticData.sheet.ceo_recommendation}
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 2,
                      }}
                    >
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.cardBottom}>
                <Text
                  style={{
                    color: "#4A4A49",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                    marginBottom: 5,
                  }}
                >
                  REVIEWS
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{1}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[0].review_summary}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{2}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[1].review_summary}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{3}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[2].review_summary}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{4}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[3].review_summary}
                      </Text>
                    </View>

                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{5}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[4].review_summary}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  width: "45%",
                }}
              >
                Your Index score's in relation to this positon:
              </Text>

              <View>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  INDEXSCORE
                </Text>
                <Text
                  style={{
                    color: "#B2DA66",
                    fontSize: 22,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    marginTop: -5,
                  }}
                >
                  {indexScore}
                </Text>
              </View>
            </View>

            {/* 2nd */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                QUESTIONS HR SHOULD ASK
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{1}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[0]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[1]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{2}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[2]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[3]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{3}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[4]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[5]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* 3rd */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                QUESTIONS TECHNICAL INTERVIEWER SHOULD ASK
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 30,
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{1}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[0]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[1]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{2}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[2]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[3]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{3}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[4]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[5]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{4}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[6]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[7]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{5}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[8]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[9]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* 4th */}
            {withoutEmptyfilteredCompReplyArray && (
              <>
                <View
                  style={{
                    borderBottom: "1.5px solid #B2DA66",
                    borderBottomStyle: "dotted",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                />
                <View>
                  <Text
                    style={{
                      color: "#0D2159",
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      textTransform: "uppercase",
                      fontWeight: 900,
                      marginBottom: 6,
                    }}
                  >
                    JOB COMPANY REPLY
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 30,
                    }}
                  >
                    <View style={{ width: "50%" }}>
                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[0] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{1}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[0]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[1] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{2}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[1]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[2] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{3}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[2]}
                              </Text>
                            </View>
                          </View>
                        )}
                    </View>
                    <View style={{ width: "50%" }}>
                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[3] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{4}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[3]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[4] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{5}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[4]}
                              </Text>
                            </View>
                          </View>
                        )}
                    </View>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#B2DA66",
            padding: 13,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <View>
            <Text
              style={{
                color: "#0D2159",
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 800,
                textTransform: "uppercase",
              }}
            >
              Next Steps
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "#4A4A49",
                fontWeight: 600,
                fontFamily: "Open Sans",
              }}
            >
              Please contact{" "}
              <Text
                style={{
                  color: "#4A4A49",
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                }}
              >
                IndexScore
              </Text>{" "}
              at{" "}
              <Link
                src="help@IndexScore.com"
                style={{
                  color: "#0D2159",
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                help@IndexScore.com
              </Link>{" "}
              if you have any questions.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const MeetingDetailsProffesional = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  
  const [prepSheets, setPrepSheets] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  const { id } = useParams();
  const [state, setState] = useState({
    loading: false,
    loading2: false,
    loading3: false,
    startDate: "",
    endDate: "",
  });
  const [interviewData, setInterviewData] = useState([]);

  const { loading, loading2, loading3, startDate, endDate } = state;
  const disabled = startDate || !endDate;
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [prepSheetsLoading, setPrepSheetsLoading] = useState(true);
  const [appId, setAppId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const fetchInterview = async () => {
    handleChange("loading2", true);
    try {
      

      var payload = {
        _id: id,
      };
      const response = await getInterviewById(payload);
      
      if (response.data.statusCode == 1) {      
        setAppId(response.data.data[0].applicationID);
        setInterviewData(response.data.data);

        const interviewSlots = response.data.data[0].intervieSLots;

        const convertToDateObject = (dateString) => new Date(dateString);
        const dataAvailable = interviewSlots.map((startTime, id) => {
          const endTime = new Date(
            convertToDateObject(startTime).getTime() + 60 * 60 * 1000
          ); // Assuming 1 hour duration
          return {
            id,
            startTime: convertToDateObject(startTime),
            endTime: endTime,
          };
        });
        
        setAvailableTimeslots(dataAvailable);

        handleChange("loading2", false);
      }
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  const fetchProDashboard = async () => {
    try {
      const payload = {
        _id: userData._id,
      };
      const response = await getProDashboard(payload);      
      if (response.data.data) {
        setDashboardData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrepSheets = async (applicationId) => {
    const payload = {
      applicationId: applicationId,
    };

    try {
      const response = await getInterviewPrepByAppId(payload);
      if (response.data.statusCode === 1) {
        setPrepSheets(response?.data?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching prep sheets:", error);
    } finally {
      setPrepSheetsLoading(false);
    }
  };

  useEffect(() => {
    if (appId) {
      fetchPrepSheets(appId);
    }
  }, [appId]);

  useEffect(() => {
    fetchProDashboard();
  }, []);

  useEffect(() => {
    fetchInterview();
  }, []);

  useEffect(() => {
    if (availableTimeslots.length > 0) {
      setSelectedSlot({
        startTime: availableTimeslots[0].startTime,
        endTime: availableTimeslots[0].endTime,
      });
    }
  }, [availableTimeslots]);

  const handleAccepted = async (event) => {
    if (!startDate) {
      enqueueSnackbar(`Please select available slot `, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      handleChange("loading", true);
      const payload = {
        _id: id,
        jobID: interviewData[0].jobID,
        job_title: interviewData[0].job_title,
        candidateName: interviewData[0].candidateName,
        candidateEmail: interviewData[0].candidateEmail,
        candidateID: interviewData[0].candidateID,
        interview_stage_order: interviewData[0].interview_stage_order,
        manager: interviewData[0].managerID,
        interviewers: interviewData[0].interviewers,
        job_role: interviewData[0].job_role,
        location: interviewData[0].location,
        interview_start_Date: startDate,
        interview_end_Date: endDate,
        interview_time: endDate,
        status: "accepted",
        attendance: interviewData[0].attendance,
        company_name: interviewData[0].company_name,
      };

      const res = await acceptInterviewApi(payload);

      if (res.data.statusCode == 1) {
        handleChange("loading", false);
        enqueueSnackbar(`Interview Accepted `, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        navigate(`/candidate/dashboard/interviews`);
      }
    }
  };

  const handleDecline = async (event) => {
    handleChange("loading3", true);
    const payload = {
      _id: id,
      applicationID: interviewData[0].applicationID,
    };
    
    const res = await declineInterview(payload);    
    if (res.data.statusCode == 1) {
      handleChange("loading3", true);
      enqueueSnackbar(`Interview decline `, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      navigate(`/candidate/dashboard/interviews`);
    }
  };

  

  return (
    <>
      <div className="edash">
        <style>
          {`
            .prep-download {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 200px;
              padding: 5px;
              font-size: 14px;
              text-decoration: none;
          }
          .prep-download:hover{
            text-decoration:none;
          }
          .prep-download svg{
            margin-left:20px;
          }
          `}
        </style>
        <div className="pt-4">
          <div className="container-dash">
            {interviewData && interviewData[0] && (
              <div className="Experts__container pb-4 d-flex flex-column align-items-center justify-content-between">
                <div className="d-flex flex-column w-100 align-items-center text-center meeting__container ">
                  <h5 className="fm darkGrey fw-light fs-3 mb-0">
                    Confirm Interview
                  </h5>
                  <p className="fm darkGrey fw-light">For position:</p>

                  <div className=" filter__content__items meeting__company d-flex flex-sm-row flex-column mb-md-0 mb-sm-3  p-3 radius8">
                    <div className="d-flex justify-content-start">
                      <div className="filter__image radius8">
                        <img
                          src={`/assets/images/EnterpriseAvatar.svg`}
                          alt=""
                          className="lightBlueBar__content__icon__image border-0 rounded-0 me-3"
                        />
                      </div>
                      <div>
                        <h3 className="filter__content__heading d-sm-none d-block fs-5 text-start mb-0 fw-semibold">
                          {interviewData[0].job_title}
                        </h3>
                        <p className="pro__dash__jobs__para text-start d-sm-none d-block fw-semibold mb-0 text-capitalize text-16 fm me-3">
                          {interviewData[0].company_name}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between">
                        <h3 className="filter__content__heading d-sm-block d-none text-22 mb-0 fw-semibold">
                          {interviewData[0].job_title}
                        </h3>
                      </div>
                      <div className="filter__content__para mt-0 d-flex flex-wrap justify-content-start align-items-center ">
                        <div className="d-sm-block d-none">
                          <p className="pro__dash__jobs__para fw-semibold mb-0 text-capitalize text-16 fm me-3">
                            {interviewData[0].company_name}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center my-2">
                        {prepSheetsLoading && <Loader />}{" "}
                        {/* Show loader while fetching prep sheets */}
                        {prepSheets && prepSheets.sheet && (
                          <PDFDownloadLink
                            className="fm text-18 text-white bluegradient__btn prep-download mt-2"
                            document={
                              <PrepSheet
                                staticData={prepSheets}
                                indexScore={
                                  dashboardData && dashboardData?.indexScore
                                }
                              />
                            }
                            fileName="PrepSheet.pdf"
                          >
                            {({ loading }) =>
                              loading ? (
                                <Loader />
                              ) : (
                                <>
                                  Interview Prepsheet <BsCloudArrowDown />
                                </>
                              )
                            }
                          </PDFDownloadLink>
                        )}
                      </div>
                    </div>
                  </div>
                  {interviewData[0].interview_time ? (
                    <div className="d-flex flex-column w-100 align-items-center meeting__container text-center justify-content-center">
                      <div className="meetingConfirm mt-4">
                        <div className="py-3 meetingConfirm__body">
                          <small className="fm darkGrey d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/icon_calendar-dark.svg"
                              alt=""
                              className="img-fluid candidates__button--img me-1"
                            />
                            {/* 9:30 – 10:30 AM, Friday, January 24, 2023 */}
                            {interviewData[0].interview_time
                              ? moment(interviewData[0].interview_time).format(
                                  "DD-MMM-YYYY hh:mm A"
                                )
                              : ""}
                          </small>
                          <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                            <img
                              src="/assets/images/icon_calendar-dark.svg"
                              alt=""
                              className="img-fluid candidates__button--img me-1"
                            />
                            GMT Time
                          </small>
                          <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                            <img
                              src="/assets/images/icon_zoom_positive.svg"
                              alt=""
                              className="img-fluid candidates__button--img me-1"
                            />
                            Meeting method :{" "}
                            <a
                              href={interviewData[0]?.zoom_meeting?.start_url}
                              target="_blank"
                            >
                              {" "}
                              Zoom{" "}
                            </a>
                            {/* Attendance: {interviewData[0].attendance} */}
                          </small>
                          <small className="fm midGrey d-flex align-items-center justify-content-center mt-3">
                            {/* <img
                    src="/assets/images/icon_inbox-midGrey.svg"
                    alt=""
                    className="img-fluid candidates__button--img me-1"
                  /> */}
                            {/* To: Ben Smith (email@indexscore.com) */}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {interviewData[0].phoneNumber ? (
                    <div className="d-flex flex-column w-100 align-items-center meeting__container text-center justify-content-center">
                      <div className="meetingConfirm mt-4">
                        <div className="py-3 meetingConfirm__body">
                          <small className="fm darkGrey d-flex align-items-center justify-content-center">
                            <img
                              src="/assets/images/icon_calendar-dark.svg"
                              alt=""
                              className="img-fluid candidates__button--img me-1"
                            />
                            {/* 9:30 – 10:30 AM, Friday, January 24, 2023 */}
                            {interviewData[0].interview_start_Date &&
                            interviewData[0].interview_end_Date
                              ? moment(
                                  interviewData[0].interview_start_Date
                                ).format("DD-MMM-YYYY hh:mm A") +
                                " --  " +
                                moment(
                                  interviewData[0].interview_end_Date
                                ).format("DD-MMM-YYYY hh:mm A")
                              : ""}
                          </small>
                          {/* <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                          <img
                            src="/assets/images/icon_calendar-dark.svg"
                            alt=""
                            className="img-fluid candidates__button--img me-1"
                          />
                          GMT Time
                        </small> */}
                          <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                            <img
                              src="/assets/images/icon_zoom_positive.svg"
                              alt=""
                              className="img-fluid candidates__button--img me-1"
                            />
                            Meeting method :{" "}
                            <a
                              href={interviewData[0]?.zoom_meeting?.start_url}
                              target="_blank"
                            >
                              {" "}
                              Zoom{" "}
                            </a>
                            {/* Attendance: {interviewData[0].attendance} */}
                          </small>
                          <small className="fm midGrey d-flex align-items-center justify-content-center mt-3">
                            {/* <img
                    src="/assets/images/icon_inbox-midGrey.svg"
                    alt=""
                    className="img-fluid candidates__button--img me-1"
                  /> */}
                            {/* To: Ben Smith (email@indexscore.com) */}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {interviewData && interviewData[0].status != "done" && (
                  <div className="meeting__scheduler">
                    {availableTimeslots && availableTimeslots.length > 0 && (
                      <ScheduleMeeting
                        borderRadius={10}
                        primaryColor="#B2DA66"
                        eventDurationInMinutes={60}
                        defaultDate={availableTimeslots[0].startTime}
                        availableTimeslots={availableTimeslots}
                        onStartTimeSelect={(time) => {
                          

                          // Add 59 minutes to get the endDate
                          const endDate = new Date(
                            time.startTime.getTime() + 59 * 60 * 1000
                          );

                          handleChange("startDate", time.startTime);
                          handleChange("endDate", endDate);
                        }}
                      />
                    )}
                  </div>
                )}

                <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-5">
                  {/* <button
            className={`Get__left__content__para draft__button suggest__btn darkGrey border-darkGrey mb-3 me-sm-2`}>
            <Link to={'/enterprise/dashboard/meeting/time_and_date'} className="text-decoration-none darkGrey">
              Suggest alternate time</Link>
          </button> */}

                  {interviewData[0]?.phoneNumber ? (
                    ""
                  ) : interviewData[0].status != "done" ? (
                    <>
                      <button
                        onClick={() => {
                          handleAccepted();
                        }}
                        className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3 me-sm-2`}
                      >
                        {loading ? <Loader /> : "Accept"}
                      </button>
                      <button
                        onClick={() => {
                          handleDecline();
                        }}
                        className="midGrey fm meeting__btn1 d-flex align-items-center mb-3"
                      >
                        {loading3 ? <Loader /> : "Decline"}
                      </button>
                    </>
                  ) : (
                    " "
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetingDetailsProffesional;
