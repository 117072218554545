import React, { useState, useRef, useEffect } from "react";
import {
  downloadAttachment,
  acceptContract,
  uploadOfferLetter,
} from "../../../services/auth";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { Input, Space, Tag, Tooltip, Upload, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const ProContractsInvitations = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const contractData = location.state?.contractData;
  // console.log("props on invitation", contractData);
  const [uploadError, setUploadError] = useState(null);

  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    files: null,
    required: false,
    loading: false,
    loading2: false,
    loading3: false,
  });

  const { loading, loading2, loading3, required, files } = state;
  const disabled = !files;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const handleChange = (key, value) => {
    // console.log("key" + key + ' ->' + value);
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    handleChange("loading3", true);
    var payloadAttachment = {
      Key: pdfUrl,
    };
    
    try {
      const response = await downloadAttachment(payloadAttachment).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contract.pdf");
          document.body.appendChild(link);
          link.click();
          handleChange("loading3", false);
        }
      );

      const responseData = response;
      // console.log("Access token:", responseData);
      // console.log('userID:', userID);
    } catch (error) {
      handleChange("loading3", false);
      console.error("Error fetching access token:", error);
    }
  };

  const handleAcceptContract = async () => {
    try {
      if (!files) {
        // Handle the case where files are not uploaded yet
        setUploadError(
          "Please upload accepted offer document before submitting."
        );

        handleChange("loading", false);
        return;
      }

      setUploadError(null);

      if (files) {
        handleChange("loading", true);
        const formData = new FormData();
        formData.append("contractID", contractData._id);
        formData.append("status", "accepted");
        formData.append("jobID", contractData.job._id);
        formData.append("files", files[0].blobFile);

        const resp = await uploadOfferLetter(formData);

        if (resp.data.statusCode) {
          const payload = {
            _id: contractData._id,
            status: "accepted",
          };

          const responseAccepted = await acceptContract(payload);

          if (responseAccepted.data.statusCode === 1) {
            enqueueSnackbar(`Contract sent successfully`, {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });
            handleChange("loading", false);
            navigate("/candidate/dashboard/contracts/");
          }
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed, such as showing an error message to the user.
      // You can also rethrow the error if necessary.
    }
  };

  const handleMessage = async () => {
    navigate("/candidate/dashboard/inbox");
  };
  const handleRejectContract = async () => {
    try {
      handleChange("loading2", true);
      const payload = {
        _id: contractData._id,
        status: "rejected",
      };

      const responseAccepted = await acceptContract(payload);

      if (responseAccepted.data.statusCode === 1) {
        enqueueSnackbar(`Contract Rejected`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        handleChange("loading2", false);
        navigate("/candidate/dashboard/contracts/");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed, such as showing an error message to the user.
      // You can also rethrow the error if necessary.
    }
  };

  return (
    <div>
      <style>
        {`

        .invite-hd{
          margin-left:40px;
        }
          .invitation-detail {
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 20px;
            margin-left: 30px;
        }
        
        .invitation-job-detail {
            border-right: 1px solid #ccc;
            min-height: 250px;
        }
        .invitation-job-detail h5{
          margin-bottom:20px;
        }
        
        .invitation-detail .job-skills {
            background: #eee;
            padding: 6px 10px;
            border-radius: 20px;
            margin-right: 10px;
            line-height: 1;
            display: inline-block;
            font-weight: 600;
            color: #0d2159;
            margin-bottom:10px;
            
        }

        .job-tags {
          color: #0d2159;
          background: #eee;
          display: inline-block;
          padding: 5px 10px;
          border-radius: 20px;
          margin-right: 20px;
          font-weight:600;
      }

      .invitation-job-description {
        margin-top: 20px;
        padding-right: 40px;
      }

      .invite-skills-hd{
          margin-bottom:20px;
      }


      .invitation-button {width: 90%;margin-bottom: 20px;padding: 14px;line-height: 1;border-radius: 25px;color: #fff;font-size: 20px;font-weight: 500;border: 2px;}

      .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      .invitation-button.download {
        background: #fff;
        border: 2px solid #4e4e4d;
        color:#4e4e4d;
    }
    .invitation-button.download img {
      margin-top: -7px;
      margin-right: 5px;
  
  }

      .invitation-button.rejected {background: #fff;border: 2px solid #14a800;color: #14a800;}
      .invitation-docs{margin-left:40px;margin-top:40px}
      .Pjobs__tabs .react-tabs__tab--selected, .Pjobs__tabs .react-tabs__tab--selected:focus{
        border-bottom:1px solid #41414080 !important;
      }
      
        `}
      </style>
      <div className="edash">
        <div className="pt-4">
          <div className="container-dash">
            <div className="Dashboard__container">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="yourIndex__insights__detail__hd fw-lighter fm mb-5 invite-hd">
                      Contract Invitation{" "}
                    </h2>
                  </div>
                  <div className="col-md-9">
                    <div className="invitation-detail">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="fm darkBlue fw-semibold fs-4 mb-4">
                            Job Details
                          </h3>
                        </div>
                        <div className="col-md-9">
                          <div className="invitation-job-detail">
                            <h5>
                              {contractData && contractData.contract_title}
                            </h5>
                            <div>
                              {/* <span className="job-tags">Mobile App Development</span>  */}
                              <span className="postedDate">
                                Offer Date{" "}
                                {contractData &&
                                  formatDate(contractData.offer_date)}
                              </span>
                            </div>
                            <div className="invitation-job-description">
                              <p>{contractData && contractData.invite_note} </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="job_requirement">
                            <i className="fa fa-user"></i>{" "}
                            <strong>Job Type:</strong>
                            {contractData &&
                              contractData.job.employment_type?.map((item) => (
                                <p>{item}</p>
                              ))}
                          </div>
                          {/* <div className="job_requirement">
                            <i className="fa fa-user"></i> <strong>Max Salary:</strong>
                            <p>$ {contractData && contractData?.job?.max_salary}  </p>
                          </div> */}
                        </div>
                        <div className="col-md-12">
                          <h6 className="invite-skills-hd">
                            Skills and expertise
                          </h6>
                          {contractData &&
                            contractData.job.job_role_text_keywords
                              .slice(0, 5)
                              ?.map((item) => (
                                <span className="job-skills">{item}</span>
                              ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="invite-sidebar">
                      <p>Interesting in discussing this job?</p>
                      <button
                        onClick={handleAcceptContract}
                        className="invitation-button accept"
                      >
                        {loading ? <Loader /> : "Accept offer"}
                      </button>
                      {uploadError && (
                        <p style={{ color: "red" }}>{uploadError}</p>
                      )}
                      <button
                        onCLick={handleRejectContract}
                        className="invitation-button rejected"
                      >
                        {loading2 ? <Loader /> : "Reject offer"}
                      </button>
                      {/* <button
                        onCLick={handleMessage}
                        className="invitation-button rejected"
                      >
                        Message
                      </button> */}

                      <h6>About the Client:</h6>
                      <p>{contractData.company_name}</p>
                      <h6>Location:</h6>
                      <p>{contractData && contractData.job.job_locations}</p>

                      <button
                        onClick={() =>
                          handleDownload(contractData.offered_Letter.Key)
                        }
                        className="invitation-button download"
                      >
                        {" "}
                        {loading3 ? (
                          <Loader />
                        ) : (
                          <>
                            <img src="/assets/images/icon_export.svg" /> Offer
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="invitation-docs mb-5">
                      <h3 className="fm darkBlue fw-semibold fs-4 mb-4">
                        Required Documents
                      </h3>
                      <Uploader
                        listType="picture-text"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        action="//jsonplaceholder.typicode.com/posts/"
                        onChange={(value) => handleChange("files", value)}
                        renderFileInfo={(file, fileElement) => {
                          return (
                            <div>
                              <span>File Name: {file.name}</span>
                              <p style={{ marginLeft: 13 }}>
                                File type: {file.blobFile.type}
                              </p>
                            </div>
                          );
                        }}
                        className="pt-4"
                      >
                        <div>
                          <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                            Upload Offer Letter
                          </Button>
                          <small className="Get__right__upload__small ps-sm-3">
                            File formats: Word or PDF
                          </small>
                        </div>
                      </Uploader>
                      {uploadError && (
                        <p style={{ color: "red" }}>{uploadError}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProContractsInvitations;
