import React from "react";
import "./style.css";
import PSection4 from "./PSection4";

const PSection3 = () => {
  return (
    <div className="ps3 py-xl-3 overflow-hidden ">
      <div className=" w-100 h-100">
        <div className="main-padding h-100">
          <div className="container-xl  h-100 px-0 py-lg-5 py-4 d-flex flex-lg-nowrap flex-wrap align-items-center justify-content-between">
            <div className="ps3__content">
              <h1 className="fm fs-2 fw-semibold darkBlue mb-4">
                Combining Human & Artificial Intelligence
              </h1>

              <p className="fm darkGrey mb-lg-0">
                No more time consuming, expensive recruitment process,
                unnecessary interviews, or discovering unmatched values. Get the
                most comprehensive data on each candidate that exists: boiled
                down to one IndexScore.
              </p>
            </div>
            <img
              src="/assets/images/Launch/pricing_dash.png"
              alt=""
              className=" ps3--img position-static ms-auto"
            />
          </div>
        </div>
      </div>

      <PSection4 />
    </div>
  );
};

export default PSection3;
