import React, { useState, useContext, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import GradientSVG from "./gradientSVG";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Progress, Tabs, Tooltip } from "antd";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { CiLock } from "react-icons/ci";
import "rsuite/dist/rsuite.min.css";
import { useNavigate, useLocation } from "react-router-dom";

const idCSS = "hello";

const ATSCandidatesProfile = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const location = useLocation();
  const atsCandidateData = location.state?.item;
  console.log("props on atsCandidateData", atsCandidateData);
  const [add, setAdd] = useState(false);
  const [img, setImg] = useState(
    `/assets/images/Users/user-${add ? "mid" : "dark"}.svg`
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const TabPane = Tabs.TabPane;

  // console.log("responseProfessionalData[0].certification", responseProfessionalData[0]?.certification);

  return (
    <div className="edash edash3 bg-lightgrey">
      <div className="container-dash pt-4">
        <div className="row pb-4">
          <div className="col-md-4 pb-md-0 pb-4 pe-md-0">
            <div className="profile__left bg-white rounded-3 pb-4 pt-3">
              <div className="profile__left__top d-flex flex-column align-items-center px-3 pb-4 pt-2">
                <div className="profile__avatar border d-flex justify-content-center align-items-center rounded-circle">
                  <div class={`profile-image ${atsCandidateData &&
                        atsCandidateData.photoUrl ? 'candidate__photo__ats' : ''}`}>
                    <img
                      className={`${atsCandidateData &&
                        atsCandidateData.photoUrl ? 'candidate__photo__ats__image' : 'profile__avatar--img'}`}
                      src={atsCandidateData &&
                        atsCandidateData.photoUrl ? atsCandidateData.photoUrl : `/assets/images/userAvatar.svg`}
                    />
                  </div>
                </div>
                <h1 className="darkBlue fs-5 fm fw-semibold mt-2 mb-0">
                  {atsCandidateData.first_name} {atsCandidateData.last_name}
                </h1>
                {/* <div className="d-flex mt-3 profile__icons ">

                      <div className="profile__icon d-flex justify-content-center align-items-center ml-2">
                        <img
                          src="/assets/images/icon_fav.svg"
                          alt=""
                          className="profile__icon--img1 img-fluid"
                        />
                      </div>
                      <div className="profile__icon d-flex justify-content-center align-items-center mx-2">
                        <img
                          src="/assets/images/inbox_dark.svg"
                          alt=""
                          className="profile__icon--img img-fluid"
                        />
                      </div>


                      <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                        <img
                          src="/assets/images/icon_export.svg"
                          alt=""
                          className="img-fluid candidates__button--img"
                        />
                      </div>
                    </div> */}
              </div>

              {/* circular progress */}
              {atsCandidateData && atsCandidateData?.index_score && atsCandidateData?.score ? (
                atsCandidateData?.kycVerification ? (
                  <div className="profile__left__circular w-100 position-relative pb-4">
                    <GradientSVG />
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      value={atsCandidateData?.index_score}
                      className="progressBars mb-md-0 mb-4 px-2 position-relative"
                      maxValue={850}
                      styles={{
                        path: { stroke: `url(#${idCSS})`, height: "100%" },
                        trail: {
                          stroke: "#F8F8F8",
                        },
                      }}
                    ></CircularProgressbarWithChildren>
                    <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fm darkGrey mb-0 fs-6">IndexScore </h5>

                      <strong className="score-value fw-light mb-0 fm">
                        {atsCandidateData?.index_score}
                      </strong>
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-2">
                    <h6 className="fm darkGrey  fs-5 mb-2">
                    IndexScore
                  </h6>
                  <div className="d-flex align-items-center justify-content-center">
                      <p className="">Pending</p>
                  </div>
                  </div>
                )
              ) : (
                <div className="text-center py-2 ">
                  <h6 className="fm darkGrey fs-5 mb-2">
                    IndexScore
                  </h6>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="">Pending</p>
                  </div>
                </div>
              )}

              {/* purpose alignment */}
              {userData.subscription.name === "Predict" ||
              userData.subscription.name === "Scale" ? atsCandidateData?.score && atsCandidateData?.kycVerification ? (
                atsCandidateData?.purposeScore && (
                  <div className="profile__left__purpose py-3 px-3">
                    <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                      <h6 className="fm darkGrey mb-0 fs-6">
                        Purpose Alignment
                      </h6>
                      <strong className="score-value fw-light mb-0 fm">
                        {atsCandidateData && Math.round(atsCandidateData?.purposeScore?.[userData.companyName])}%
                      </strong>
                    </div>
                    <Progress
                      percent={
                        atsCandidateData && Math.round(atsCandidateData?.purposeScore?.[userData.companyName])
                      }
                      showInfo={false}
                      strokeColor={{
                        "0%": "#A7DB5A",
                        "100%": "#A7DB5A",
                      }}
                    />
                  </div>
                )
              ) : 
              <div className="profile__left__purpose py-3 px-3">
              <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                <h6 className="fm darkGrey mb-0 fs-5">
                  Purpose Alignment
                </h6>
              </div>
              <div className="d-flex align-items-center justify-content-center">
               <p className="">Pending</p>
              </div>
            </div>
             : (
                <div className="d-flex align-items-center ">
                  <Tooltip
                    title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                    placement="top"
                  >
                    <CiLock className="lock-item" />
                  </Tooltip>
                </div>
              )}

              {/* contact */}
              <div className="profile__left__contact px-3 pt-3 pb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fs-5 fm darkBlue mb-3">Contact</h5>
                </div>

                <div className="d-flex align-items-center">
                  <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                    <img
                      src="/assets/images/mail_dark.svg"
                      alt=""
                      className="profile__icon--img img-fluid"
                    />
                  </div>
                  <a
                    title={atsCandidateData?.email_addresses[0]?.value}
                    href={`mailto:${atsCandidateData?.email_addresses[0]?.value}`}
                    className="fm darkGrey text-decoration-none email__id"
                  >
                    {atsCandidateData?.email_addresses[0]?.value}
                  </a>
                </div>
                <div className="d-flex align-items-center my-2">
                  <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                    <img
                      src="/assets/images/inbox_dark.svg"
                      alt=""
                      className="profile__icon--img img-fluid"
                    />
                  </div>
                  <a
                    className="fm darkGrey text-decoration-none "
                    href={`tel:${atsCandidateData?.phone_numbers[0]?.value}`}
                  >
                    {atsCandidateData?.phone_numbers[0]?.value}
                  </a>
                </div>
                {/* <div className="d-flex align-items-center">
                      <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                        <IoLocationOutline className="fs-4 darkGrey" />
                      </div>
                      <span className="fm darkGrey">Address</span>
                    </div> */}
                {/* <div className="d-flex align-items-center mt-2">
                      <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                        <CiLinkedin className="fs-4 darkGrey" />
                      </div>
                      <a
                        href="https://www.linkedin.com/"
                        className="fm darkGrey text-decoration-none"
                      >
                        LinkedIn Profile
                      </a>
                    </div> */}
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="profile__right bg-white rounded-3 ">
              <div className="px-3 ">
                {/* <div className="d-flex justify-content-sm-between flex-sm-row flex-column border-05 pb-3">
                      <div className="d-inline-flex flex-sm-nowrap flex-wrap">
                        <div>
                          <p className="fm darkGrey mb-0">Identity</p>
                          <div className="reference__verification--main nowrap mb-3">
                            <span>Verified</span> <BsCheckCircle />
                          </div>
                        </div>
                        <div className="mx-sm-4 mx-3">
                          <p className="fm darkGrey fm mb-0">Right to Work</p>
                          <div className="reference__verification--main nowrap mb-3">
                            <span>Verified</span> <BsCheckCircle />
                          </div>
                        </div>
                        <div className="me-3">
                          <p className="fm darkGrey fm mb-0">Nationality</p>
                          <div className="reference__verification--main nowrap mb-3">
                            <span>United Kingdom</span>
                          </div>
                        </div>

                        <div className="">
                          <p className="fm darkGrey fm mb-0">Sponsorship</p>
                          <div className="reference__verification--main nowrap mb-3">
                            <span>Yes</span> <BsCheckCircle />
                          </div>
                        </div>
                      </div>
                    </div> */}

                <div className="border-05 py-3">
                  <h4 className="fm darkBlue fw-semibold fs-4 mb-0 me-2 ">
                    {atsCandidateData && atsCandidateData?.title}
                  </h4>
                </div>

                <div className="pt-3">
                  <div className="row align-items-start">
                    <div className="col-md-12">
                      <div className="ats__job__data__role__relevance">
                        <h5 className="fm fs-5 fw-semibold ">Job details</h5>
                        {atsCandidateData &&
                          atsCandidateData?.relevance_score && (
                            <div className="relevance__show">
                              <h5 className="fm fs-5 fw-semibold">
                                Job Relavance
                              </h5>
                              <span
                                className={`tag-${
                                  Math.round(
                                    atsCandidateData &&
                                      atsCandidateData?.relevance_score
                                  ) <= 33
                                    ? "low"
                                    : Math.round(
                                        atsCandidateData &&
                                          atsCandidateData?.relevance_score
                                      ) > 33 &&
                                      Math.round(
                                        atsCandidateData &&
                                          atsCandidateData?.relevance_score
                                      ) <= 66
                                    ? "average"
                                    : "high"
                                } fm tag-14 text-capitalize rounded-pill`}
                              >
                                {atsCandidateData?.relevance_score}%
                              </span>
                            </div>
                          )}
                      </div>

                      <p className="fm darkGrey pb-2">
                        {atsCandidateData && atsCandidateData?.jobDetails?.name}
                      </p>
                      <h6 className="fm fs-5 fw-semibold">Job description</h6>
                      <div
                        className="bg-lightgrey p-2 rounded-2"
                        dangerouslySetInnerHTML={{
                          __html: atsCandidateData?.jobDetails?.description,
                        }}
                      />
                    </div>
                    {/* <div className="col-md-4 d-flex justify-content-end">
                          {atsCandidateData && atsCandidateData?.relevance_score && (
                            <div className="profile__left__circular role__relevance w-100 position-relative pb-4">
                              <GradientSVG />
                              <CircularProgressbarWithChildren
                                strokeWidth={5}
                                value={atsCandidateData && atsCandidateData?.relevance_score}
                                className="progressBars mb-md-0 mb-4 px-2 position-relative"
                                maxValue={100}
                                styles={{
                                  path: { stroke: `url(#${idCSS})`, height: "100%" },
                                  trail: {
                                    stroke: "#F8F8F8",
                                  },
                                }}
                              ></CircularProgressbarWithChildren>
                              <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                                <h5 className="fm darkGrey mb-0 fs-6">Role Relevance </h5>

                                <strong className="score-value fw-light mb-0 fm">
                                  {atsCandidateData && atsCandidateData?.relevance_score}%
                                </strong>
                              </div>
                            </div>
                          )}
                        </div> */}
                  </div>

                  {atsCandidateData?.urls &&
                    atsCandidateData.urls.length > 0 && (
                      <h4 className="fm darkBlue fw-semibold fs-4 mt-4">
                        Links
                      </h4>
                    )}
                  <ul className="tags">
                    {atsCandidateData?.urls ? (
                      atsCandidateData.urls.map((item, index) => (
                        <div key={index}>
                          <a href={item.value} target="_blank">
                            {item.value}
                          </a>
                        </div>
                      ))
                    ) : (
                      <li>No links available</li>
                    )}
                  </ul>

                  {atsCandidateData?.tags &&
                    atsCandidateData.tags.length > 0 && (
                      <h4 className="fm darkBlue fw-semibold fs-4 mb-3">
                        Tags
                      </h4>
                    )}
                  <ul className="tags">
                    {atsCandidateData?.tags ? (
                      atsCandidateData.tags.map((item, index) => (
                        <li className="tags-list" key={index}>
                          {item}
                        </li>
                      ))
                    ) : (
                      <li>No tags available</li>
                    )}
                  </ul>
                </div>

                <div className="d-flex justify-content-between align-items-center pt-3">
                  {/* <h4 className="fm darkBlue fw-semibold fs-4 mb-0">
                          Education
                        </h4> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ATSCandidatesProfile;
