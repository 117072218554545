import React from 'react'
import './style.css'

const Container = (props) => {
  return (
    <div className='Container mb-5'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-5 px-0">
                    {props.progress}
                </div>
                <div className="col-lg-7 px-0">
                    {props.right}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Container