export const degreesList = [
    {
      name: "Bachelor of Liberal Arts"
    },
    {
      name: "Doctor of Musical Arts"
    },
    {
      name: "Associate of Arts"
    },
    {
      name: "Associate of Applied Arts"
    },
    {
      name: "Associate of Applied Business"
    },
    {
      name: "Associate in Allied Health"
    },
    {
      name: "Associate of Arts in Nursing"
    },
    {
      name: "Associate in Applied Science"
    },
    {
      name: "Associate of Arts and Sciences"
    },
    {
      name: "Associate of Arts in Teaching"
    },
    {
      name: "Associate of Applied Technology"
    },
    {
      name: "Associate in Business"
    },
    {
      name: "Bachelor of Arts"
    },
    {
      name: "Associate of Business Administration"
    },
    {
      name: "Bachelor of Liberal Arts"
    },
    {
      name: "Associate Degree"
    },
    {
      name: "Associate Degree of Building Design"
    },
    {
      name: "Associate Degree of Building Surveying"
    },
    {
      name: "Associate Degree of Business"
    },
    {
      name: "Associate Degree of Engineering"
    },
    {
      name: "Associate Degree of Information Technology"
    },
    {
      name: "Associate Degree of Journalism"
    },
    {
      name: "Associate Degree of Learning Management"
    },
    {
      name: "Associate Degree of Multimedia Studies"
    },
    {
      name: "Associate Degree in Nursing"
    },
    {
      name: "Associate of Engineering"
    },
    {
      name: "Alternate Entry Master of Science in Nursing"
    },
    {
      name: "Associate of Engineering Science"
    },
    {
      name: "Associate in Engineering Technology"
    },
    {
      name: "Associate of Forestry"
    },
    {
      name: "Associate of Fine Arts"
    },
    {
      name: "Associate of General Studies"
    },
    {
      name: "Associate of Industrial Technology"
    },
    {
      name: "Master of Liberal Arts"
    },
    {
      name: "Associate in Medical Science"
    },
    {
      name: "Associate of Nursing"
    },
    {
      name: "Associate in Occupational Studies"
    },
    {
      name: "Associate in Occupational Technology"
    },
    {
      name: "Associate of Public Service"
    },
    {
      name: "Associate of Political science"
    },
    {
      name: "Associate in Physical Therapy"
    },
    {
      name: "Associate of Science"
    },
    {
      name: "Associate of Science in Dental Hygiene"
    },
    {
      name: "Associate of Science in Nursing"
    },
    {
      name: "Associate in Physical Therapy"
    },
    {
      name: "Associate Degree of Computing"
    },
    {
      name: "Associate Degree of Technology"
    },
    {
      name: "Associate Degree of Technology Management"
    },
    {
      name: "Associate of Technology"
    },
    {
      name: "Doctor of Audiology"
    },
    {
      name: "Bachelor of Arts"
    },
    {
      name: "Bachelor of Applied Arts"
    },
    {
      name: "Bachelor of Applied Arts and Sciences"
    },
    {
      name: "Bachelor of Arts in Communication"
    },
    {
      name: "Bachelor of Aerospace Engineering"
    },
    {
      name: "Bachelor of Arts and Economics"
    },
    {
      name: "Bachelor of Economics"
    },
    {
      name: "Bachelor of Arts in Education"
    },
    {
      name: "Bachelor of Applied Studies"
    },
    {
      name: "Bachelor of Applied Science"
    },
    {
      name: "Bachelor of Architectural Studies"
    },
    {
      name: "Bachelor of Applied Science"
    },
    {
      name: "Bachelor of Art & Technology"
    },
    {
      name: "Bachelor of Applied Technology"
    },
    {
      name: "Bachelor of Accountancy"
    },
    {
      name: "Bachelor of Agricultural Science"
    },
    {
      name: "Bachelor of Applied Finance"
    },
    {
      name: "Bachelor of Applied Science"
    },
    {
      name: "Bachelor of Architecture"
    },
    {
      name: "Bachelor of Asian Studies"
    },
    {
      name: "Bachelor of Business Analysis - Financial"
    },
    {
      name: "Bachelor of Business Administration"
    },
    {
      name: "Bachelor of Built Environment"
    },
    {
      name: "Bachelor of Biosystems Engineering"
    },
    {
      name: "Bachelor of Behavioural Neuroscience"
    },
    {
      name: "Bachelor of Business"
    },
    {
      name: "Bachelor of Commerce and Administration"
    },
    {
      name: "Bachelor of Civil Engineering"
    },
    {
      name: "Bachelor of Computer Information Systems"
    },
    {
      name: "Bachelor of Computer Science"
    },
    {
      name: "Bachelor of Criminal Justice"
    },
    {
      name: "Bachelor of Computer and Mathematical Sciences"
    },
    {
      name: "Bachelor of Computing & Mathematical Sciences"
    },
    {
      name: "Bachelor of Surgery"
    },
    {
      name: "Bachelor of Chemical Engineering"
    },
    {
      name: "Bachelor of Commerce"
    },
    {
      name: "Bachelor of Commerce"
    },
    {
      name: "Bachelor of Communication"
    },
    {
      name: "Bachelor of Computing"
    },
    {
      name: "Bachelor of Computer Operations Technology"
    },
    {
      name: "Bachelor of Communications Studies"
    },
    {
      name: "Bachelor of Design"
    },
    {
      name: "Bachelor of Engineering"
    },
    {
      name: "Bachelor of Electrical Engineering"
    },
    {
      name: "Bachelor of Electronics Engineering Technology"
    },
    {
      name: "Bachelor of Environmental Studies"
    },
    {
      name: "Bachelor of Economics"
    },
    {
      name: "Bachelor of Finance & Economics"
    },
    {
      name: "Bachelor of Economic Science"
    },
    {
      name: "Bachelor of Education"
    },
    {
      name: "Bachelor of Environmental Design"
    },
    {
      name: "Bachelor of Engineering"
    },
    {
      name: "Bachelor of Fine Arts"
    },
    {
      name: "Bachelor of Finance"
    },
    {
      name: "Bachelor of General Studies"
    },
    {
      name: "Bachelor of Game & Simulation Programming"
    },
    {
      name: "Bachelor of Humanities and Arts"
    },
    {
      name: "Bachelor of Hotel Management"
    },
    {
      name: "Bachelor of Health Science"
    },
    {
      name: "Bachelor of Health Sciences"
    },
    {
      name: "Bachelor of Interior Architecture"
    },
    {
      name: "Bachelor of Integrated Studies"
    },
    {
      name: "Bachelor of Interdisciplinary Studies"
    },
    {
      name: "Bachelor of Industrial and Science Engineering"
    },
    {
      name: "Bachelor of Information Technology"
    },
    {
      name: "Bachelor of Industrial Design"
    },
    {
      name: "Bachelor of Information Science"
    },
    {
      name: "Bachelor of Information Technology"
    },
    {
      name: "Bachelor of International Studies"
    },
    {
      name: "Bachelor of Journalism"
    },
    {
      name: "Bachelor of Liberal Arts"
    },
    {
      name: "Bachelor of Landscape Architecture"
    },
    {
      name: "Bachelor of Liberal Studies"
    },
    {
      name: "Bachelor of Languages"
    },
    {
      name: "Bachelor of Music"
    },
    {
      name: "Bachelor of Mechanical Engineering"
    },
    {
      name: "Bachelor of Music Education"
    },
    {
      name: "Bachelor of Materials Engineering"
    },
    {
      name: "Bachelor of Music"
    },
    {
      name: "Bachelor of Public Affairs"
    },
    {
      name: "Bachelor of Polymer and Fiber Engineering"
    },
    {
      name: "Bachelor of Professional Health Science"
    },
    {
      name: "Bachelor of Science"
    },
    {
      name: "Bachelor of Science in Aerospace Engineering"
    },
    {
      name: "Bachelor of Science in Business Administration"
    },
    {
      name: "Bachelor of Science in Biomedical Engineering"
    },
    {
      name: "Bachelor of Science in Commerce Business Administration"
    },
    {
      name: "Bachelor of Science in Civil Engineering"
    },
    {
      name: "Bachelor of Science in Computer & Information Sciences"
    },
    {
      name: "Bachelor of Science in Computer Science"
    },
    {
      name: "Bachelor of Science in Computer Technology"
    },
    {
      name: "Bachelor of Science in Chemical Engineering"
    },
    {
      name: "Bachelor of Science in Chemistry"
    },
    {
      name: "Bachelor of Science in Engineering"
    },
    {
      name: "Bachelor of Science in Electrical Engineering"
    },
    {
      name: "Bachelor of Science in Engineering Technology"
    },
    {
      name: "Bachelor of Science in Education"
    },
    {
      name: "Bachelor of Science in Geology"
    },
    {
      name: "Bachelor of Science in Human Environmental Sciences"
    },
    {
      name: "Bachelor of Science in Mechanical Engineering"
    },
    {
      name: "Bachelor of Science in Manufacturing Engineering Technology"
    },
    {
      name: "Bachelor of Science in Metallurgical Engineering"
    },
    {
      name: "Bachelor of Science in Microbiology"
    },
    {
      name: "Bachelor of Science in Materials Engineering"
    },
    {
      name: "Bachelor of Science in Nursing"
    },
    {
      name: "Bachelor of Science in Social Work"
    },
    {
      name: "Bachelor of Social Work"
    },
    {
      name: "Bachelor of Software Engineering"
    },
    {
      name: "Bachelor of Textile Engineering"
    },
    {
      name: "Bachelor of Technical & Interdisciplinary Studies"
    },
    {
      name: "Bachelor of Textile Management and Technology"
    },
    {
      name: "Bachelor of Wireless Engineering"
    },
    {
      name: "Bachelor of Arts"
    },
    {
      name: "Bachelor of Economics"
    },
    {
      name: "Bachelor of Education"
    },
    {
      name: "Bachelor of Arts and Economics"
    },
    {
      name: "Bachelor of Journalism"
    },
    {
      name: "Bachelor of Architecture"
    },
    {
      name: "Bachelor of Applied Science"
    },
    {
      name: "Bachelor of Applied Studies"
    },
    {
      name: "Bachelor of Applied Science"
    },
    {
      name: "Bachelor of Arts for Teaching"
    },
    {
      name: "Bachelor of Aviation"
    },
    {
      name: "Bachelor of Biomedical Science"
    },
    {
      name: "Bachelor of Business Information Systems"
    },
    {
      name: "Bachelor of Surgery"
    },
    {
      name: "Bachelor of Surgery"
    },
    {
      name: "Bachelor of Dental Surgery"
    },
    {
      name: "Bachelor of Surgery Degree"
    },
    {
      name: "Bachelor of Civil Law"
    },
    {
      name: "Bachelor of Counseling"
    },
    {
      name: "Bachelor of Divinity"
    },
    {
      name: "Bachelor of Divisionnity"
    },
    {
      name: "Bachelor of Design"
    },
    {
      name: "Bachelor of Dental Surgery"
    },
    {
      name: "Bachelor of Dental Science"
    },
    {
      name: "Bachelor of Engineering"
    },
    {
      name: "Bachelor of Economics"
    },
    {
      name: "Bachelor of Economics and Finance"
    },
    {
      name: "Bachelor of Science in Education"
    },
    {
      name: "Bachelor of Engineering"
    },
    {
      name: "Bachelor of Fine Arts"
    },
    {
      name: "Bachelor of Finance"
    },
    {
      name: "Bachelor of General Studies"
    },
    {
      name: "Bachelor of Health & Physical Education"
    },
    {
      name: "Bachelor of Health Science"
    },
    {
      name: "Bachelor of Health Science"
    },
    {
      name: "bachelor of hygiene"
    },
    {
      name: "Bachelor of Information and Communications Technology"
    },
    {
      name: "Bachelor of Integrated Studies"
    },
    {
      name: "Bachelor of Journalism"
    },
    {
      name: "Bachelor of Kinesiology"
    },
    {
      name: "Bachelor of Liberal Arts"
    },
    {
      name: "Bachelor of Landscape Architecture"
    },
    {
      name: "Bachelor of Music (degree)"
    },
    {
      name: "Bachelor of Medicine"
    },
    {
      name: "Bachelor of Biomedical science"
    },
    {
      name: "Bachelor of Medical Science"
    },
    {
      name: "Bachelor of Medical Science"
    },
    {
      name: "Bachelor of Midwifery"
    },
    {
      name: "Bachelor of Ministry"
    },
    {
      name: "Bachelor of Medical Science"
    },
    {
      name: "Bachelor of Biomedical science"
    },
    {
      name: "Bachelor of Medical Science"
    },
    {
      name: "Bachelor of Music"
    },
    {
      name: "Bachelor of Nursing"
    },
    {
      name: "Bachelor of Nursing Science"
    },
    {
      name: "Bachelor of Nursing"
    },
    {
      name: "Bachelor of Physical Education"
    },
    {
      name: "Bachelor of Pharmacy"
    },
    {
      name: "Bachelor of Philosophy"
    },
    {
      name: "bachelor of public health nursing"
    },
    {
      name: "Bachelor of Professional Studies"
    },
    {
      name: "Bachelor of Religious Education"
    },
    {
      name: "Bachelor of Religious Studies"
    },
    {
      name: "Bachelor of Science (clear in education line)"
    },
    {
      name: "Bachelor of Science in Education"
    },
    {
      name: "Bachelor of Science in Business Administration"
    },
    {
      name: "Bachelor of Science (university degree)"
    },
    {
      name: "Bachelor of Science and/with Education"
    },
    {
      name: "Bachelor of Science in Economics"
    },
    {
      name: "Bachelor of Science in Engineering"
    },
    {
      name: "Bachelor of Science in Psychology"
    },
    {
      name: "Bachelor of Nursing Science"
    },
    {
      name: "Bachelor of Science in Education"
    },
    {
      name: "Bachelor of Science in Engineering"
    },
    {
      name: "Bachelor of Science in Education"
    },
    {
      name: "Bachelor of Science in Environmental Health"
    },
    {
      name: "Bachelor of Science in Engineering Technology"
    },
    {
      name: "Bachelor of Science in Family and Consumer Sciences"
    },
    {
      name: "Bachelor of Science in General Studies"
    },
    {
      name: "Bachelor of Journalism"
    },
    {
      name: "Bachelor of Science in Law"
    },
    {
      name: "Bachelor of Science in Medicine"
    },
    {
      name: "Bachelor of Science in Nursing"
    },
    {
      name: "Bachelor of Science in Nuclear Engineering"
    },
    {
      name: "Bachelor of Social Science"
    },
    {
      name: "Bachelor of Science in Public Health"
    },
    {
      name: "Bachelor of Social Work"
    },
    {
      name: "Bachelor of Teaching"
    },
    {
      name: "Bachelor of Technology"
    },
    {
      name: "Bachelor of Theology"
    },
    {
      name: "Bachelor of Theology"
    },
    {
      name: "Bachelor of Veterinary Medicine"
    },
    {
      name: "Bachelor of Veterinary Medicine and Science"
    },
    {
      name: "Bachelor of Surgery"
    },
    {
      name: "Bachelor of Surgery"
    },
    {
      name: "Doctor of Surgery"
    },
    {
      name: "Master in Surgery"
    },
    {
      name: "Doctor of Arts"
    },
    {
      name: "Doctor of Applied Science"
    },
    {
      name: "Doctor of Architecture"
    },
    {
      name: "Doctor of Business Administration"
    },
    {
      name: "Doctor of Criminal Justice"
    },
    {
      name: "Doctor of Comparative Law"
    },
    {
      name: "Doctor of Civil Law"
    },
    {
      name: "Doctor of Computer Science"
    },
    {
      name: "Doctor of Chemistry"
    },
    {
      name: "Doctor of Criminology"
    },
    {
      name: "Doctor of Engineering Science"
    },
    {
      name: "Doctor of Engineering"
    },
    {
      name: "Doctor of Environmental Science and Engineering"
    },
    {
      name: "Doctor of Forestry"
    },
    {
      name: "Doctor of Fine Arts"
    },
    {
      name: "Doctor of Geological Science"
    },
    {
      name: "Doctor of Health Education"
    },
    {
      name: "Doctor of Hebrew Literature/Letters"
    },
    {
      name: "Doctor of Health and Safety"
    },
    {
      name: "Doctor of Hebrew Studies"
    },
    {
      name: "Doctor of Humane Letters"
    },
    {
      name: "Doctor of Information Technology"
    },
    {
      name: "Doctor of Industrial Technology"
    },
    {
      name: "Doctor of Law and Policy"
    },
    {
      name: "Doctor of Library Science"
    },
    {
      name: "Doctor of Literature and Philosophy"
    },
    {
      name: "Doctor of Ministry"
    },
    {
      name: "Doctor of Musical Arts"
    },
    {
      name: "Doctor of Musical Education"
    },
    {
      name: "Doctor of Modern Languages"
    },
    {
      name: "Doctor of Medical Science"
    },
    {
      name: "Doctor of Music Therapy"
    },
    {
      name: "Doctor of Ministry"
    },
    {
      name: "Doctor of Music"
    },
    {
      name: "Doctor of Naprapathic Medicine"
    },
    {
      name: "Doctor of Nursing Science"
    },
    {
      name: "Doctor of Nursing Science"
    },
    {
      name: "Doctor of Public Administration"
    },
    {
      name: "Doctor of Physical Education"
    },
    {
      name: "Doctor of Public Health"
    },
    {
      name: "Doctor of Professional Studies"
    },
    {
      name: "Doctor of Physical Therapy"
    },
    {
      name: "Doctor of Recreation"
    },
    {
      name: "Doctor of Recreation"
    },
    {
      name: "Doctor of Sacred Music"
    },
    {
      name: "Doctor of Social Science"
    },
    {
      name: "Doctor of Social Work"
    },
    {
      name: "Doctor of Science"
    },
    {
      name: "Doctor of Science in Dentistry"
    },
    {
      name: "Doctor of Science and Hygiene"
    },
    {
      name: "Doctor of Science in Veterinary Medicine"
    },
    {
      name: "Doctor of Business Administration"
    },
    {
      name: "Doctor of Chiropractic"
    },
    {
      name: "Doctor of Surgery"
    },
    {
      name: "Doctor of Civil Law"
    },
    {
      name: "Doctor of Clinical Psychology"
    },
    {
      name: "Divinitatis Doctor"
    },
    {
      name: "Doctor of Divisionnity"
    },
    {
      name: "Divisionnitatis Doctor"
    },
    {
      name: "Doctor of Divinity"
    },
    {
      name: "Doctor of Dental Surgery"
    },
    {
      name: "Doctor of Dental Science"
    },
    {
      name: "Doctor of Literature"
    },
    {
      name: "Doctor of Letters"
    },
    {
      name: "Doctor of Medicine"
    },
    {
      name: "Doctor of Dental Medicine"
    },
    {
      name: "Doctor of Medical Education"
    },
    {
      name: "Doctor of Ministry"
    },
    {
      name: "Doctor of Medical Science"
    },
    {
      name: "Doctor of Medical Technology"
    },
    {
      name: "Doctor of Music"
    },
    {
      name: "Doctor of Veterinary Medicine"
    },
    {
      name: "Doctor of Nursing"
    },
    {
      name: "Doctor of Nursing Education"
    },
    {
      name: "Doctor of Nursing Science"
    },
    {
      name: "Doctor of Nursing Science"
    },
    {
      name: "Doctor of Nursing Science"
    },
    {
      name: "Doctor of Osteopathy"
    },
    {
      name: "Doctor of Ocular Science"
    },
    {
      name: "Doctor of Pharmacy"
    },
    {
      name: "Doctor of Public Health"
    },
    {
      name: "Doctor of Philosophy"
    },
    {
      name: "Doctor of Public Health Nursing"
    },
    {
      name: "Doctor of Pediatric Medicine"
    },
    {
      name: "Doctor of Professional Studies"
    },
    {
      name: "Doctor of Practical Theology"
    },
    {
      name: "Doctor of Design"
    },
    {
      name: "Doctor of Public Health"
    },
    {
      name: "Doctor of Science"
    },
    {
      name: "Doctor of Science"
    },
    {
      name: "Doctor of Social Work"
    },
    {
      name: "Doctor of the University"
    },
    {
      name: "Doctor of Veterinary Medicine"
    },
    {
      name: "Doctor of Veterinary Medicine & Surgery"
    },
    {
      name: "Doctor of Veterinary Radiology"
    },
    {
      name: "Doctor of Veterinary Science"
    },
    {
      name: "Doctor of Education"
    },
    {
      name: "Bachelor of Education"
    },
    {
      name: "Doctor of Education"
    },
    {
      name: "Doctor of Engineering"
    },
    {
      name: "Doctor of Canon Law"
    },
    {
      name: "Juris Doctor"
    },
    {
      name: "Doctor of Juridical Science"
    },
    {
      name: "Doctor of Law and Policy"
    },
    {
      name: "Doctor of the Science of Law"
    },
    {
      name: "doctor of letters"
    },
    {
      name: "Master of Laws"
    },
    {
      name: "Legum Baccalaureus (Latin: Bachelor Of Laws)"
    },
    {
      name: "doctor of laws"
    },
    {
      name: "Master of Laws"
    },
    {
      name: "Licensed Master of Social Work"
    },
    {
      name: "Master of Arts in Education"
    },
    {
      name: "Doctor of Medicine"
    },
    {
      name: "Master of Education"
    },
    {
      name: "Master of Fine Arts"
    },
    {
      name: "Master of Interdisciplinary Studies"
    },
    {
      name: "Master of Library and Information Science"
    },
    {
      name: "Master of Ministry"
    },
    {
      name: "Master of Music"
    },
    {
      name: "Master of Occupational Therapy"
    },
    {
      name: "Master of Science in Engineering Technology"
    },
    {
      name: "Master of Science in Education"
    },
    {
      name: "Master of Science in Nursing"
    },
    {
      name: "Master of Social Work"
    },
    {
      name: "Master of Urban Planning"
    },
    {
      name: "Master of Arts"
    },
    {
      name: "Master of Arts in Education"
    },
    {
      name: "Master of Applied Anthropology"
    },
    {
      name: "Master of Accountancy"
    },
    {
      name: "Master of Arts in Christian Education"
    },
    {
      name: "Master of Accountancy"
    },
    {
      name: "Master of Arts in international economics and finance"
    },
    {
      name: "Master of Arts in International Hotel Management"
    },
    {
      name: "Master of Arts in Liberal Studies"
    },
    {
      name: "Master of Arts Management"
    },
    {
      name: "Master of Arts in Public Service"
    },
    {
      name: "Master of Arts in Professional Writing"
    },
    {
      name: "Master of Architecture"
    },
    {
      name: "Master of Archival Studies"
    },
    {
      name: "Master of Engineering"
    },
    {
      name: "Master of Applied Sciences"
    },
    {
      name: "Master of Arts and Teaching"
    },
    {
      name: "Bachelor of Medicine"
    },
    {
      name: "Master of Business Administration"
    },
    {
      name: "Medicinae Baccalaureus, Baccalaureus Chirurgiae (Latin: Bachelor of Medicine, Bachelor of Surgery)"
    },
    {
      name: "Master of Biochemistry"
    },
    {
      name: "Master of Biology"
    },
    {
      name: "Master of Biological Science"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Master of Chemistry"
    },
    {
      name: "Master of Criminal Justice"
    },
    {
      name: "Master of Clinical Dentistry"
    },
    {
      name: "Master of Clinical Medical Science"
    },
    {
      name: "Doctor of Medicine"
    },
    {
      name: "Master of Divisionnity"
    },
    {
      name: "Master of Divinity"
    },
    {
      name: "Master of Digital Media"
    },
    {
      name: "Master of Drama"
    },
    {
      name: "Master of Dental Surgery"
    },
    {
      name: "Master of Engineering"
    },
    {
      name: "Master of Electronic Business"
    },
    {
      name: "Master of Economics"
    },
    {
      name: "Master of Education"
    },
    {
      name: "Master of Environmental Design"
    },
    {
      name: "Master of Engineering"
    },
    {
      name: "Master of Environmental Science"
    },
    {
      name: "Master of Environmental Studies"
    },
    {
      name: "Master of Earth Science"
    },
    {
      name: "Master of Educational Technology"
    },
    {
      name: "Master of Fine Art"
    },
    {
      name: "Master of Forensic Sciences"
    },
    {
      name: "Master of Geography"
    },
    {
      name: "Master of Geology"
    },
    {
      name: "Master of Geophysics"
    },
    {
      name: "Master of Health Administration"
    },
    {
      name: "Master of Health Education"
    },
    {
      name: "Master of Health Science"
    },
    {
      name: "Master of Industrial Design"
    },
    {
      name: "Master of International Development"
    },
    {
      name: "Master of Management in the Network Economy"
    },
    {
      name: "Master of Informatics"
    },
    {
      name: "Master of Engineering"
    },
    {
      name: "Master of Information Systems Management"
    },
    {
      name: "Master of Jurisprudence"
    },
    {
      name: "Master of Labor and Human Resources"
    },
    {
      name: "Master of Librarianship"
    },
    {
      name: "Master of Library and Information Science degree"
    },
    {
      name: "Master of Letters"
    },
    {
      name: "Master of Library Science"
    },
    {
      name: "Master of Liberal Studies"
    },
    {
      name: "Master of Music"
    },
    {
      name: "Master of Mathematics"
    },
    {
      name: "Master of Mathematics and Physics"
    },
    {
      name: "Master of Mass Communications"
    },
    {
      name: "Master of Music Education"
    },
    {
      name: "Master of Ministry"
    },
    {
      name: "Master of Mathematics, Operational Research, Statistics and Economics"
    },
    {
      name: "Master of Marketing Research"
    },
    {
      name: "Master of Medical Science"
    },
    {
      name: "Master of Medical Science"
    },
    {
      name: "Master of Management Sciences"
    },
    {
      name: "Master of Music"
    },
    {
      name: "Master of Nursing"
    },
    {
      name: "Master of Natural Science"
    },
    {
      name: "Master of Science in Nursing"
    },
    {
      name: "Master of Nursing Science"
    },
    {
      name: "Master of Oceanography"
    },
    {
      name: "Master of Occupational Therapy"
    },
    {
      name: "Master of Pharmacy Administration"
    },
    {
      name: "Master of Public Administration"
    },
    {
      name: "Master of Professional Accountancy"
    },
    {
      name: "Master of Physician Assistant Studies"
    },
    {
      name: "Master of Pharmacy"
    },
    {
      name: "Master of Public Health"
    },
    {
      name: "master of pharmacy"
    },
    {
      name: "Master of Philosophy"
    },
    {
      name: "Master of Physics"
    },
    {
      name: "Master of Pacific International Affairs"
    },
    {
      name: "Master of Planning"
    },
    {
      name: "Master of Project Management"
    },
    {
      name: "Master of Public Management"
    },
    {
      name: "Master of Public Policy"
    },
    {
      name: "Master of Professional Studies"
    },
    {
      name: "Master of Professional Studies"
    },
    {
      name: "Master of Physical Therapy"
    },
    {
      name: "Master of Radiology"
    },
    {
      name: "Master of Research"
    },
    {
      name: "Master of Science"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Master of Science in Education"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Master of Science"
    },
    {
      name: "Master in Science"
    },
    {
        name: "Master in Computer Science"
    },
    {
      name: "Master of Science in Dentistry"
    },
    {
      name: "Master of Science in Engineering"
    },
    {
      name: "Master of Science in Electrical Engineering"
    },
    {
      name: "Master of Science in Environmental Health"
    },
    {
      name: "Master of Science in Finance"
    },
    {
      name: "Master of Science in Information"
    },
    {
      name: "Master of Science in Information Systems"
    },
    {
      name: "Master of Science in Information Studies"
    },
    {
      name: "Master of Criminal Justice"
    },
    {
      name: "Master of Science in Library Science"
    },
    {
      name: "Master of Sacred Music"
    },
    {
      name: "Master Of Science In Nursing"
    },
    {
      name: "Master of Science in Natural Sciences"
    },
    {
      name: "Master of Social Science"
    },
    {
      name: "Master of Science In Organizational Leadership"
    },
    {
      name: "Master of Science in Public Health"
    },
    {
      name: "Master of Social Science"
    },
    {
      name: "Master of Science in Strategic Leadership"
    },
    {
      name: "Master of Science in Social Work"
    },
    {
      name: "Master of Studies"
    },
    {
      name: "Master of Science in Taxation"
    },
    {
      name: "Master of Statistics"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Master of Social Work"
    },
    {
      name: "Master of Theology"
    },
    {
      name: "Master of Theology"
    },
    {
      name: "Master of the University"
    },
    {
      name: "Master of Urban Studies"
    },
    {
      name: "Master of Urban and Regional Planning"
    },
    {
      name: "Doctor of Music"
    },
    {
      name: "Bachelor of Music"
    },
    {
      name: "Doctor of Music"
    },
    {
      name: "Master of Music"
    },
    {
      name: "Doctor of Naturopathy"
    },
    {
      name: "Doctor of Optometry"
    },
    {
      name: "Post Master's Graduate Certificate"
    },
    {
      name: "Doctor of Pharmacy"
    },
    {
      name: "Doctor of Philosophy"
    },
    {
      name: "Doctor of Philosophy"
    },
    {
      name: "Doctor of Pharmacy"
    },
    {
      name: "Doctor of Pharmacy"
    },
    {
      name: "Doctor of Philosophy"
    },
    {
      name: "Doctor of Psychology"
    },
    {
      name: "Doctor of Rehabilitation"
    },
    {
      name: "Doctor of Juridical Science"
    },
    {
      name: "Doctor of Sacred Theology"
    },
    {
      name: "Bachelor of Science"
    },
    {
      name: "Doctor of Science"
    },
    {
      name: "Doctor of Engineering Science"
    },
    {
      name: "Scientiar Baccalaureus, Bachelor of Science"
    },
    {
      name: "Doctor of Science"
    },
    {
      name: "Master of Surgery"
    },
    {
      name: "Doctor of Social Science"
    },
    {
      name: "Master of Theology"
    },
    {
      name: "Doctor of Theology"
    },
    {
      name: "Doctor of Practical Theology"
    },
    {
      name: "Bachelor of Theology"
    },
    {
      name: "Doctor of Theology"
    },
    {
      name: "Master of Theology"
    },
    {
      name: "Veterinary Medical Doctor"
    }    
];