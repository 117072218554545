import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import {
  BsFilter,
  BsSearch,
  BsTrash,
  BsPencilSquare,
  BsEye,
  BsThreeDotsVertical,
  BsShareFill,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Badge from "@mui/material/Badge";
import {
  getJobs,
  getInterviewsbyMemberId,
  deleteJob,
  updateJobStatus,
  authorizeLinkedinUser,
  getlinkedInUserId,
  createLinkedinPost,
  getJobById,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { FiArrowDown, FiUsers } from "react-icons/fi";
import { useSnackbar } from "notistack";
import {
  HiOutlineArrowDownCircle,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { LinkedinShareButton } from "react-share";
import { FaUser } from "react-icons/fa";
import axios from "axios";
import {
  Avatar,
  Progress,
  Dropdown,
  Menu,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  message,
  Checkbox,
  Tooltip,
  Table,
  Radio,
  Pagination,
} from "antd";
import moment from "moment";

const Jobs = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const navigate = useNavigate();
  const [departmentCounts, setDepartmentCounts] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [filterData, setFilterData] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [interviewersData, setInterviewersData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState(false);
  const [open1, setOpen1] = useState(false);
  const filterRef = useRef(null);

  const [statusChecked, setStatusChecked] = useState(false);
  const [typeChecked, setTypeChecked] = useState(false);
  const [categoryChecked, setCategoryChecked] = useState(false);
  const [postedChecked, setPostedChecked] = useState(false);
  const [deadlineChecked, setDeadlineChecked] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");

  function filterClick(event) {
    filterRef.current = document.getElementById("filter_btn");
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilter(false);
    }
  }

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  useEffect(() => {
    window.addEventListener("mousedown", filterClick);
    return () => {
      window.removeEventListener("mousedown", filterClick);
    };
  });
  const filterDrop = () => {
    setFilter(!filter);
    setOpen1(!open1);
    // console.log("open:" + open1 + "filter:" + filter);
  };
  const items = [
    {
      label: (
        <div className="d-flex flex-column">
          <Checkbox
            className="fm darkGrey"
            checked={statusChecked}
            onChange={(e) => setStatusChecked(e.target.checked)}
          >
            Role
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            checked={typeChecked}
            onChange={(e) => setTypeChecked(e.target.checked)}
          >
            Type
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            checked={categoryChecked}
            onChange={(e) => setCategoryChecked(e.target.checked)}
          >
            Category
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            checked={postedChecked}
            onChange={(e) => setPostedChecked(e.target.checked)}
          >
            Posted
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            checked={deadlineChecked}
            onChange={(e) => setDeadlineChecked(e.target.checked)}
          >
            Deadline
          </Checkbox>
        </div>
      ),
      key: "0",
    },
  ];

  const { confirm } = Modal;
  useEffect(() => {
    fetchJobs();
    // fetchInterviews();
  }, []);
  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (flag) => {
    setOpen1(flag);
  };

  const fetchJobs = async () => {
    handleChange("loading2", true);

    try {
      const response = await getJobs();
      var myObjectEnterprise;
      const responseData = response.data.data.filter(
        (obj) => obj.enterpriseID === userData?.companyID
      );
      myObjectEnterprise = responseData.slice().reverse();
      console.log("myObjectEnterprise",myObjectEnterprise);
      setFilterData(
        myObjectEnterprise.map((jobs) => ({
          key: jobs._id,
          candidates: jobs.candidates.length,
          small_text: "Accenture",
          heading: jobs.company.toUpperCase(),
          para: jobs.job_title.toUpperCase(),
          companylogo: "/assets/images/Dashboard/logo-5.png",
          department: jobs.department,
          tags: jobs.job_sectors,
          start_date: jobs.start_date,
          end_date: jobs.end_date,
          min_salary: jobs.min_salary,
          max_salary: jobs.max_salary,
          team_members: jobs.team_members,
          employment_type: jobs.employment_type,
          indexScore: jobs.min_index_core,
          culture_fit: jobs.min_culture_fit_score,
          job_role_text: jobs.job_role_text,
          status: jobs.status,
          
        }))
      );

      var myObjectTeamMembers;
      myObjectTeamMembers = response.data.data.filter((obj) =>
        obj.team_members.some((member) => member._id === userData?.companyID)
      );

      setActiveJobs(
        myObjectTeamMembers.map((jobs) => ({
          key: jobs._id,
          small_text: "Accenture",
          heading: jobs.company.toUpperCase(),
          para: jobs.job_title.toUpperCase(),
          companylogo: "/assets/images/Dashboard/logo-5.png",
          tags: jobs.job_sectors,
          start_date: jobs.start_date,
          end_date: jobs.end_date,
          min_salary: jobs.min_salary,
          max_salary: jobs.max_salary,
          team_members: jobs.team_members,
          employment_type: jobs.employment_type,
          indexScore: jobs.min_index_core,
          culture_fit: jobs.min_culture_fit_score,
          job_role_text: jobs.job_role_text,
          status: jobs.status,
        }))
      );

      const counts = myObjectEnterprise.reduce((acc, job) => {
        acc[job.department] = (acc[job.department] || 0) + 1;
        return acc;
      }, {});

      const departmentList = Object.entries(counts).map(
        ([department, count]) => ({
          department,
          count,
        })
      );

      setDepartmentCounts(departmentList);

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  const showPromiseConfirm = (jobId) => {
    confirm({
      title: "Delete a Job",
      content: "Are you sure you want to delete the job",
      onOk() {
        const payload = {
          jobId: jobId,
        };
        deleteJob(payload)
          .then((response) => {
            if (response.statusCode == 1) {
              enqueueSnackbar(`Job Deleted Successfully`, {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              fetchJobs();
            }
          })
          .catch((error) => {
            console.error("Error deleting job:", error);
          });
      },
      onCancel() {},
    });
  };

  const handleDeleteClick = (jobId, event) => {
    // event.preventDefault();
    // Call the showPromiseConfirm function with the jobId as a parameter
    showPromiseConfirm(jobId);
  };

  const updateJob = async (jobid, status) => {
    const payLoadUpdateJobStatus = {
      _id: jobid,
      status: status,
    };

    console.log("payLoadUpdateJobStatus", payLoadUpdateJobStatus);

    const respUpdateJobStatus = await updateJobStatus(payLoadUpdateJobStatus);
    console.log("respUpdateJobStatus", respUpdateJobStatus);
    // const responseProfessionalData = respUpdateJobStatus.data.data;
    fetchJobs();
  };

  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  function getBaseUrl() {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    return baseUrl;
  }

  const clientId = "77yoapq098cye7";
  const baseUrl = getBaseUrl();
  const redirectUri = `${baseUrl}/hr/dashboard/jobs`;  
  const clientSecret = "cVMoAiA2VK4Vlme2";

  useEffect(() => {
    // Check if the URL contains the authorization code
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");

    if (code && state) {
      console.log(
        "----------------------------code----------------------",
        code
      );
      console.log(
        "----------------------------state----------------------",
        state
      );
      fetchSingleJob(code, state);
    }
  }, []);

  const fetchSingleJob = async (code, state) => {
    console.log("code in fetchSingleJob", code);
    try {
      var payload = {
        _id: state,
      };
      const response = await getJobById(payload);
      const myObject = response.data.data.find((obj) => obj._id === state);
      // console.log("myObject",myObject);
      if (myObject) {
        getLinkedin(code, state, myObject.job_title, myObject.job_role_text);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLinkedin = async (codeReceived, state, title, description) => {
    try {
      const currentLoc = `${baseUrl}/hr/dashboard/jobs/${state}`;

      const payload = {
        code: codeReceived,
        title: title,
        description: description,
        link: currentLoc,
        redirectUrl: redirectUri,
        text: "Vacany avialable , Please click on the link to apply",
      };

      const response = await authorizeLinkedinUser(payload);
      if(response.status===200){
        enqueueSnackbar("Successfully Posted on Linkedin", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const copyPageUrl = (jobid) => {
    // window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=foobar&scope=r_basicprofile`;
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${jobid}&scope=r_basicprofile,w_member_social`;
  };

  const selectedCheckboxes = items.filter((item) => {
    const label = item.label.props.children;
    // Check if the label is a Checkbox component and if it's checked
    return (
      label &&
      label.type &&
      label.type.name === "Checkbox" &&
      label.props.checked
    );
  });

  // Determine if any checkbox is selected
  const isAnyCheckboxSelected = selectedCheckboxes.length > 0;

  const filteredJobs = filterData.filter((job) => {
    const { para, department, employment_type, start_date, end_date } = job;
    const search = searchInput.toLowerCase();

    // Check if any checkbox is checked
    if (
      statusChecked ||
      typeChecked ||
      categoryChecked ||
      postedChecked ||
      deadlineChecked
    ) {
      return (
        (statusChecked && para.toLowerCase().includes(search)) ||
        (typeChecked && department.toLowerCase().includes(search)) ||
        (categoryChecked &&
          department.toString().toLowerCase().includes(search)) ||
        (employment_type &&
          employment_type.toString().toLowerCase().includes(search)) ||
        (postedChecked && start_date.toLowerCase().includes(search)) ||
        (deadlineChecked && end_date.toLowerCase().includes(search))
      );
    } else {
      // If no checkbox is checked, search from all fields
      return (
        para.toLowerCase().includes(search) ||
        department.toLowerCase().includes(search) ||
        (employment_type &&
          employment_type.toString().toLowerCase().includes(search)) ||
        start_date.toLowerCase().includes(search) ||
        end_date.toLowerCase().includes(search)
      );
    }
  });

  const validateProfile = () => {
    if (userData?.role === "hr") {
      navigate("/hr/dashboard/create-new-job");
    } else {
      if (userData?.enterpriseData?.progress?.total == 100 && userData?.enterpriseData.ai_data?.company_data) {
        navigate("/hr/dashboard/create-new-job");
      } else {
        enqueueSnackbar("Please complete profile to create job", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };

  const Candidates = (key) => {
    props.candidates(true);
    setJobData(key);
    props.jobDatafromCandidate(key);
    props.job(false);
  };

  const tableColumns = [
    {
      title: (
        <>
          Role{" "}
          <span className="fw-normal">
            ({filteredJobs ? filteredJobs.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 90,
    },
    {
      title: "Applicants",
      dataIndex: "applicant",
      key: "4",
      width: 120,
      sorter: (a, b) => a.applicant - b.applicant,
      render: (applicant) => (
        <span className="darkGrey fm">
          <BsEye className="fs-6 me-1" />
          {applicant}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 120,
      key: "type",
      sorter: (a, b) => {
        const typeA = typeof a.type === "string" ? a.type.toLowerCase() : "";
        const typeB = typeof b.type === "string" ? b.type.toLowerCase() : "";
        return typeA.localeCompare(typeB);
      },
      render: (type) => (
        <span className="job__type darkBlue rounded-pill fm text-13">
          {type}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 120,
      key: "category",
      sorter: (a, b) => {
        const categoryA =
          typeof a.category === "string" ? a.category.toLowerCase() : "";
        const categoryB =
          typeof b.category === "string" ? b.category.toLowerCase() : "";
        return categoryA.localeCompare(categoryB);
      },
    },
    {
      title: "Posted",
      dataIndex: "posted",
      width: 120,
      key: "5",
      sorter: (a, b) => moment(a.posted).unix() - moment(b.posted).unix(),
      render: (posted) => (
        <div className="job-col-1 darkGrey text-14 fm">{posted}</div>
      ),
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      width: 120,
      key: "6",
      sorter: (a, b) => moment(a.deadline).unix() - moment(b.deadline).unix(),
      render: (deadline) => (
        <div className="job-col-1 darkGrey text-14 fm">{deadline}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];
  const tableColumnsDrafts = [
    {
      title: (
        <>
          Role{" "}
          <span className="fw-normal">
            (
            {filteredJobs
              ? filteredJobs.filter((jobs) => jobs.status === "Draft").length
              : ""}
            )
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 90,
    },
    {
      title: "Applicants",
      dataIndex: "applicant",
      key: "4",
      width: 120,
      sorter: (a, b) => a.applicant - b.applicant,
      render: (applicant) => (
        <span className="darkGrey fm">
          <BsEye className="fs-6 me-1" />
          {applicant}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 120,
      key: "type",
      sorter: (a, b) => {
        const typeA = typeof a.type === "string" ? a.type.toLowerCase() : "";
        const typeB = typeof b.type === "string" ? b.type.toLowerCase() : "";
        return typeA.localeCompare(typeB);
      },
      render: (type) => (
        <span className="job__type darkBlue rounded-pill fm text-13">
          {type}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 120,
      key: "category",
      sorter: (a, b) => {
        const categoryA =
          typeof a.category === "string" ? a.category.toLowerCase() : "";
        const categoryB =
          typeof b.category === "string" ? b.category.toLowerCase() : "";
        return categoryA.localeCompare(categoryB);
      },
    },
    {
      title: "Posted",
      dataIndex: "posted",
      width: 120,
      key: "5",
      sorter: (a, b) => moment(a.posted).unix() - moment(b.posted).unix(),
      render: (posted) => (
        <div className="job-col-1 darkGrey text-14 fm">{posted}</div>
      ),
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      width: 120,
      key: "6",
      sorter: (a, b) => moment(a.deadline).unix() - moment(b.deadline).unix(),
      render: (deadline) => (
        <div className="job-col-1 darkGrey text-14 fm">{deadline}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];
  const tableColumnsClosed = [
    {
      title: (
        <>
          Role{" "}
          <span className="fw-normal">
            (
            {filteredJobs
              ? filteredJobs.filter((jobs) => jobs.status === "Draft").length
              : ""}
            )
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "3",
      width: 90,
    },
    {
      title: "Applicants",
      dataIndex: "applicant",
      key: "4",
      width: 120,
      sorter: (a, b) => a.applicant - b.applicant,
      render: (applicant) => (
        <span className="darkGrey fm">
          <BsEye className="fs-6 me-1" />
          {applicant}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 120,
      key: "type",
      sorter: (a, b) => {
        const typeA = typeof a.type === "string" ? a.type.toLowerCase() : "";
        const typeB = typeof b.type === "string" ? b.type.toLowerCase() : "";
        return typeA.localeCompare(typeB);
      },
      render: (type) => (
        <span className="job__type darkBlue rounded-pill fm text-13">
          {type}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      width: 120,
      key: "category",
      sorter: (a, b) => {
        const categoryA =
          typeof a.category === "string" ? a.category.toLowerCase() : "";
        const categoryB =
          typeof b.category === "string" ? b.category.toLowerCase() : "";
        return categoryA.localeCompare(categoryB);
      },
    },
    {
      title: "Posted",
      dataIndex: "posted",
      width: 120,
      key: "5",
      sorter: (a, b) => moment(a.posted).unix() - moment(b.posted).unix(),
      render: (posted) => (
        <div className="job-col-1 darkGrey text-14 fm">{posted}</div>
      ),
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      width: 120,
      key: "6",
      sorter: (a, b) => moment(a.deadline).unix() - moment(b.deadline).unix(),
      render: (deadline) => (
        <div className="job-col-1 darkGrey text-14 fm">{deadline}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];
  const formattedJobs = filteredJobs.map((item, index) => ({
    key: item.id,
    name: (
      <>
        <Link
          className="text-decoration-none"
          to={`/hr/dashboard/jobs/${item.key}`}
        >
          <h6 className="text-14 fm mb-0 darkGrey ">
            {capitalizeWords(item.para)}
          </h6>{" "}
        </Link>
      </>
    ),

    status: (
      <span
        className={`job__status rounded-pill ${
          item.status === "Open" && "status__live"
        } ${item.status === "Draft" && "status__hold"} ${
          item.status === "closed" && "status__closed"
        }`}
      >
        {item.status}
      </span>
    ),

    applicant: item.candidates,
    type: item.employment_type,

    category: (
      <span className="job__category darkBlue rounded-pill text-13">
        {item.department}
      </span>
    ),
    posted: moment(item.start_date).format("L"),
    deadline: moment(item.end_date).format("L"),
    action: (
      <>
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              <Menu.Item>
                <button
                  onClick={() => copyPageUrl(item.key)}
                  className="text-decoration-none text-start ps-0 editButton"
                >
                  <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                    <BsShareFill className="me-1 darkGrey" /> Share
                  </p>
                </button>
              </Menu.Item>
              <Menu.Item key="publish">
                <button
                  onClick={(event) => updateJob(item.key, "Open")}
                  className="text-decoration-none text-start ps-0 editButton"
                >
                  <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                    <BsPencilSquare className="me-1 darkGrey" />
                    Publish
                  </p>
                </button>
              </Menu.Item>
              <Menu.Item key="draft">
                <button
                  onClick={(event) => updateJob(item.key, "Draft")}
                  className="text-decoration-none text-start ps-0 editButton"
                >
                  <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                    <BsPencilSquare className="me-1 darkGrey" />
                    Draft
                  </p>
                </button>
              </Menu.Item>
              <Menu.Item key="archive">
                <button
                  onClick={(event) => updateJob(item.key, "closed")}
                  className="text-decoration-none text-start ps-0 editButton"
                >
                  <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                    <BsPencilSquare className="me-1 darkGrey" />
                    Archive
                  </p>
                </button>
              </Menu.Item>
              <Menu.Item key="edit">
                <Link
                  to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                  className="text-decoration-none text-start ps-0 editButton"
                >
                  <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                    <BsPencilSquare className="me-1 darkGrey" />
                    Edit
                  </p>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={(event) => handleDeleteClick(item.key, event)}
                className="text-decoration-none delete-button red d-flex align-items-center"
              >
                <BsTrash className="" /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));

  const formattedDraftsJobs = filteredJobs
    .filter((candidate) => candidate.status === "Draft")
    .map((item, index) => ({
      key: item.id,
      name: (
        <>
          <Link
            className="text-decoration-none"
            to={`/hr/dashboard/jobs/${item.key}`}
          >
            <h6 className="text-14 fm mb-0 darkGrey ">
              {capitalizeWords(item.para)}
            </h6>{" "}
          </Link>
        </>
      ),

      status: (
        <span
          className={`job__status rounded-pill ${
            item.status === "Open" && "status__live"
          } ${item.status === "Draft" && "status__hold"} ${
            item.status === "closed" && "status__closed"
          }`}
        >
          {item.status}
        </span>
      ),

      applicant: item.candidates,
      type: item.employment_type,

      category: (
        <span className="job__category darkBlue rounded-pill text-13">
          {item.department}
        </span>
      ),
      posted: moment(item.start_date).format("L"),
      deadline: moment(item.end_date).format("L"),
      action: (
        <>
          <Dropdown
            placement="top"
            arrow={{ pointAtCenter: true }}
            overlay={
              <Menu>                
                <Menu.Item key="publish">
                  <button
                    onClick={(event) => updateJob(item.key, "Open")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Publish
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="draft">
                  <button
                    onClick={(event) => updateJob(item.key, "Draft")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Draft
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="archive">
                  <button
                    onClick={(event) => updateJob(item.key, "closed")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Archive
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="edit">
                  <Link
                    to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Edit
                    </p>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={(event) => handleDeleteClick(item.key, event)}
                  className="text-decoration-none delete-button red d-flex align-items-center"
                >
                  <BsTrash className="" /> Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button className="candidates__menu">
              <BsThreeDotsVertical className="text-decoration-none" />
            </Button>
          </Dropdown>
        </>
      ),
    }));

  const formattedClosedJobs = filteredJobs
    .filter((candidate) => candidate.status === "closed")
    .map((item, index) => ({
      key: item.id,
      name: (
        <>
          <Link
            className="text-decoration-none"
            to={`/hr/dashboard/jobs/${item.key}`}
          >
            <h6 className="text-14 fm mb-0 darkGrey ">
              {capitalizeWords(item.para)}
            </h6>{" "}
          </Link>
        </>
      ),

      status: (
        <span
          className={`job__status rounded-pill ${
            item.status === "Open" && "status__live"
          } ${item.status === "Draft" && "status__hold"} ${
            item.status === "closed" && "status__closed"
          }`}
        >
          {item.status}
        </span>
      ),

      applicant: item.candidates,
      type: item.employment_type,

      category: (
        <span className="job__category darkBlue rounded-pill text-13">
          {item.department}
        </span>
      ),
      posted: moment(item.start_date).format("L"),
      deadline: moment(item.end_date).format("L"),
      action: (
        <>
          <Dropdown
            placement="top"
            arrow={{ pointAtCenter: true }}
            overlay={
              <Menu>                
                <Menu.Item key="publish">
                  <button
                    onClick={(event) => updateJob(item.key, "Open")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Publish
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="draft">
                  <button
                    onClick={(event) => updateJob(item.key, "Draft")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Draft
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="archive">
                  <button
                    onClick={(event) => updateJob(item.key, "closed")}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Archive
                    </p>
                  </button>
                </Menu.Item>
                <Menu.Item key="edit">
                  <Link
                    to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                    className="text-decoration-none text-start ps-0 editButton"
                  >
                    <p className="d-flex align-items-center darkGrey fm mb-0 text-14">
                      <BsPencilSquare className="me-1 darkGrey" />
                      Edit
                    </p>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={(event) => handleDeleteClick(item.key, event)}
                  className="text-decoration-none delete-button red d-flex align-items-center"
                >
                  <BsTrash className="" /> Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button className="candidates__menu">
              <BsThreeDotsVertical className="text-decoration-none" />
            </Button>
          </Dropdown>
        </>
      ),
    }));

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedJobs.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  // drafts
  const [currentPageDrafts, setCurrentPageDrafts] = useState(1);
  const [itemsPerPageDrafts] = useState(10);
  const totalItemsDrafts = formattedDraftsJobs.length;

  const onPageChangeDrafts = (page) => {
    setCurrentPageDrafts(page);
  };

  const showTotalDrafts = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPageDrafts);
    const totalPages = Math.ceil(total / itemsPerPageDrafts);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  // archived
  const [currentPageArchived, setCurrentPageArchived] = useState(1);
  const [itemsPerPageArchived] = useState(10);
  const totalItemsArchived = formattedClosedJobs.length;

  const onPageChangeArchived = (page) => {
    setCurrentPageArchived(page);
  };

  const showTotalArchived = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPageArchived);
    const totalPages = Math.ceil(total / itemsPerPageArchived);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  return (
    <div>
      <style>
        {`
          .react-kanban-board{
            max-width: 1020px;
          }
          .space-right{
            margin-right:20px;
          }
          .delete-button{
            background:transparent;
          }
        `}
      </style>
      <div>
        <div className=" edash edash3">
          <div className="container-dash pt-4 ">
            {/* <div className="jobs__content__hiring enterprise d-flex align-items-center justify-content-between p-3 flex-lg-nowrap flex-wrap">
              <div style={{ marginRight: "50px" }}>
                <h1 className="jobs__content__hiring__heading fw-semibold fm">
                  Let’s start hiring!
                </h1>
                <p className="jobs__content__hiring__para me-lg-5 me-0  fm">
                  Create a new job posting here with IndexScore to find the best
                  fitting candidates for you...
                </p>
              </div>
              {userData.subscription ? (
                <Link
                  to={"/hr/dashboard/create-new-job"}
                  className="jobs__content__hiring__btn fm text-decoration-none"
                >
                  <button className="jobs__content__hiring__btn--button p-2 transition">
                    Create a job
                  </button>
                </Link>
              ) : (
                <Tooltip
                  title="This feature is not available on this account, you’ll need to purchase a plan to be able to access this feature."
                  placement="top"
                >
                  <Link className="jobs__content__hiring__btn fm text-decoration-none">
                    <button className="jobs__content__hiring__btn--button p-2 transition">
                      Create a job
                    </button>
                  </Link>
                </Tooltip>
              )}
            </div> */}
            <h1 className="text-35 fm darkBlue">
              You have{" "}
              <strong className="fw-semibold">{filterData.length}</strong> roles
              open
            </h1>
          </div>
        </div>
        <div className="mt-4">
          <div className="">
            <div className="container-dash mb-4 candidates d-md-flex justify-content-between">
              <Radio.Group
                defaultValue="all"
                className="jobd__btn me-2"
                size={"large"}
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                <Radio.Button value="all" className="h-100">
                  All jobs
                </Radio.Button>
                <Radio.Button value="drafts" className="h-100">
                  Drafts
                </Radio.Button>
                <Radio.Button value="closed" className="h-100">
                  Archived
                </Radio.Button>
              </Radio.Group>
              <div className="d-sm-flex mt-md-0 mt-2">
                <div className="d-flex justify-content-xxl-start justify-content-between">
                  <div className="candidates__searchbox jobd__btn d-flex align-items-center w-100 me-2">
                    <BsSearch className="candidates__searchbox__icon mx-2" />
                    <input
                      type="text"
                      className="fm candidates__searchbox__input text-14"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                  {/* <div className=" me-sm-2">
                    <Dropdown
                      ref={filterRef}
                      menu={{
                        items,
                        onClick: handleMenuClick,
                      }}
                      onOpenChange={handleOpenChange}
                      open={open1}
                      trigger={["click"]}
                      overlayClassName={"dropdown__filter"}
                    >
                      <button
                        className="candidates__filter jobd__btn h-100 fm px-2 d-flex text-14 align-items-center fw-500 transition"
                        onClick={filterDrop}
                        style={{
                          border: filter ? "0.5px solid #5F89FF" : "",
                          color: filter ? "#5F89FF" : "",
                        }}
                        id="filter_btn"
                        ref={filterRef}
                      >
                        <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                        Filter
                      </button>
                    </Dropdown>
                  </div> */}
                </div>
                <button
                  onClick={() => {
                    validateProfile();
                  }}
                  className="bluegradient__btn mt-sm-0 text-14 nowrap mt-2 text-white py-xxl-0 py-2 newjob__btn"
                >
                  + Create new job
                </button>
              </div>
            </div>
            {selectedValue === "all" && (
              <>
                <div className="container-dash d-md-block d-none pb-5">
                  {loading2 ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <>
                      <div className="table__outer table__outer__pro  radius8">
                        <Table
                          className="dash__table"
                          columns={tableColumns}
                          dataSource={formattedJobs}
                          pagination={false}
                          showExpandColumn={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {filteredJobs.length > 0 && (
                        <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                          <button className="fm text-12 table__bottom__btn1">
                            Load all jobs ({filteredJobs.length})
                          </button>
                          <button className="fm text-12 table__bottom__btn2">
                            Scroll to load more{" "}
                            <HiOutlineArrowDownCircle className="fs-6" />
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </div>
                <div className="container-dash d-md-none d-block pb-5">
                  <div className="container-fluid">
                    <div className="row ">
                      <div className="col-12 ">
                        {loading2 ? (
                          <Loader size="md" className="" content="loading" />
                        ) : filteredJobs.length > 0 ? (
                          filteredJobs.map((item) => (
                            <Link
                              to={`/hr/dashboard/jobs/${item.key}`}
                              className="filter__border row text-decoration-none jobs__border d-flex flex-column  pt-3 pb-3 transition pointer mb-2"
                              key={item.key}
                              // onClick={() => Candidates(item)}
                            >
                              <div className="d-flex align-items-lg-center w-100 justify-content-between align-items-start">
                                <div className="job-col-4 darkGrey ps-lg-3">
                                  <h6 className="mb-lg-0 mt-0">
                                    {item.para}{" "}
                                    <span className="job__status text-13 d-lg-none d-inline-block rounded-pill">
                                      {item.status}
                                    </span>
                                  </h6>
                                </div>
                                <div className="d-flex">
                                  <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    <span className="job__status rounded-pill">
                                      {item.status}
                                    </span>
                                  </div>
                                  <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    <span className="darkGrey fm d-flex align-items-center">
                                      <FaUser className="fs-6 me-1" />{" "}
                                      {item.candidates}
                                    </span>
                                  </div>
                                  <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    <span className="job__type rounded-pill">
                                      {item.employment_type}
                                    </span>
                                  </div>
                                  <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    <span className="job__category rounded-pill">
                                      {item.department}
                                    </span>
                                  </div>
                                  <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    {item.start_date}
                                  </div>
                                  <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                    {item.end_date}
                                  </div>
                                  <div className="job-col-last justify-content-end darkGrey d-flex align-items-lg-center pe-lg-3">
                                    <Dropdown
                                      placement="bottom"
                                      arrow={{ pointAtCenter: true }}
                                      overlay={
                                        <Menu>
                                          <Menu.Item key="edit">
                                            <Link
                                              to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                                              className="text-decoration-none  align-items-center editButton"
                                            >
                                              <p className="d-flex align-items-center darkGrey  fm mb-0 text-14">
                                                <BsPencilSquare className="me-1 darkGrey" />
                                                Edit
                                              </p>
                                            </Link>
                                          </Menu.Item>
                                          <Menu.Item
                                            key="delete"
                                            onClick={(event) =>
                                              handleDeleteClick(item.key, event)
                                            }
                                            className="text-decoration-none delete-button"
                                          >
                                            Delete
                                          </Menu.Item>
                                        </Menu>
                                      }
                                      trigger={["click"]}
                                    >
                                      <Button className="candidates__menu">
                                        <BsThreeDotsVertical className="text-decoration-none" />
                                      </Button>
                                    </Dropdown>
                                  </div>
                                </div>
                              </div>
                              <div className="w-sm-50 d-lg-none mb-1">
                                <p className="fm darkGrey mb-sm-2 mb-1 nowrap job-title-sm">
                                  Applicants:{" "}
                                  <span className="text-13 fm">
                                    <BsEye className="fs-6" /> {item.candidates}
                                  </span>
                                </p>
                              </div>
                              <div className="d-lg-none d-sm-flex mb-1">
                                <div className="w-sm-50 mb-2">
                                  <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                    Type:{" "}
                                    <span className="job__type text-13 rounded-pill">
                                      {item.employment_type}
                                    </span>
                                  </p>
                                </div>
                                <div className="w-sm-50 mb-1">
                                  <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                    Category:{" "}
                                    <span className="job__type text-13 rounded-pill">
                                      {item.department}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="d-lg-none d-sm-flex">
                                <div className="w-sm-50 mb-1">
                                  <p className="fm darkGrey mb-0 job-title-sm">
                                    Posted:{" "}
                                    <span className="text-13 text-13">
                                      {item.start_date}
                                    </span>
                                  </p>
                                </div>
                                <div className="w-sm-50">
                                  <p className="fm darkGrey mb-0 job-title-sm">
                                    Deadline:{" "}
                                    <span className="text-13 text-13">
                                      {item.end_date}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          ))
                        ) : (
                          <div className="d-flex align-items-center py-3">
                            <h3>No role exists! </h3>
                          </div>
                        )}
                        {/* {filteredJobs.length > 0 && (
                          <div className="d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all jobs ({filteredJobs.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                      </div>

                      {/* <div className="col-xl-3 mb-3 order-xl-2 order-1 d-xl-block d-none">
                    <div className="d-xxl-flex ">
                      <div className="d-flex justify-content-xxl-start justify-content-between">
                        <div className="candidates__searchbox d-flex align-items-center w-100 me-sm-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                        <div className=" me-xxl-2">
                          <Dropdown
                            ref={filterRef}
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            // trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter fm px-2 d-flex text-14 align-items-center fw-500 transition"
                              onClick={filterDrop}
                              style={{
                                border: filter ? "0.5px solid #5F89FF" : "",
                                color: filter ? "#5F89FF" : "",
                              }}
                              id="filter_btn"
                              ref={filterRef}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          validateProfile();
                        }}
                        className="bluegradient__btn mt-xxl-0 text-14 nowrap mt-2 text-white py-xxl-0 py-2 newjob__btn"
                      >
                        + Create new job
                      </button>
                    </div>

                    <div className="mt-2 radius8 px-3 py-4 jobs__box">
                      <h6 className="fm fw-semibold mb-1">Roles by category</h6>
                      <p className="fm text-14 darkGrey">
                        Click below to view all your jobs in category.
                      </p>
                      <div>
                        {departmentCounts.map(({ department, count }) => (
                          <div
                            className="d-flex justify-content-between align-items-center mb-2"
                            key={department}
                          >
                            <p className="mb-0 fw-semibold fm text-14">
                              {department}
                            </p>
                            <button className="jobs__pill rounded-pill fm text-capitalize">
                              {count} Jobs
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </>
            )}

            {selectedValue === "drafts" && (
              <>
                <div className="container-dash d-md-block d-none">
                  {loading2 ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <>
                      <div className="table__outer table__outer__pro  radius8">
                        <Table
                          className="dash__table"
                          columns={tableColumnsDrafts}
                          dataSource={formattedDraftsJobs}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {filteredJobs.filter(
                        (candidate) => candidate.status === "drafts"
                      ).length > 0 && (
                        <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                          <button className="fm text-12 table__bottom__btn1">
                            Load all jobs ({filteredJobs.length})
                          </button>
                          <button className="fm text-12 table__bottom__btn2">
                            Scroll to load more{" "}
                            <HiOutlineArrowDownCircle className="fs-6" />
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </div>
                <div className="container-dash d-md-none d-block">
                  <div className="container-fluid">
                    <div className="row ">
                      <div className="col-12 ">
                        {loading2 ? (
                          <Loader size="md" className="" content="loading" />
                        ) : filteredJobs.length > 0 ? (
                          filteredJobs
                            .filter(
                              (candidate) => candidate.status === "drafts"
                            )
                            .map((item) => (
                              <Link
                                to={`/hr/dashboard/jobs/${item.key}`}
                                className="filter__border row text-decoration-none jobs__border d-flex flex-column  pt-3 pb-3 transition pointer mb-2"
                                key={item.key}
                                // onClick={() => Candidates(item)}
                              >
                                <div className="d-flex align-items-lg-center w-100 justify-content-between align-items-start">
                                  <div className="job-col-4 darkGrey ps-lg-3">
                                    <h6 className="mb-lg-0 mt-0">
                                      {item.para}{" "}
                                      <span className="job__status text-13 d-lg-none d-inline-block rounded-pill">
                                        {item.status}
                                      </span>
                                    </h6>
                                  </div>
                                  <div className="d-flex">
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__status rounded-pill">
                                        {item.status}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="darkGrey fm d-flex align-items-center">
                                        <FaUser className="fs-6 me-1" />{" "}
                                        {item.candidates}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__type rounded-pill">
                                        {item.employment_type}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__category rounded-pill">
                                        {item.department}
                                      </span>
                                    </div>
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      {item.start_date}
                                    </div>
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      {item.end_date}
                                    </div>
                                    <div className="job-col-last justify-content-end darkGrey d-flex align-items-lg-center pe-lg-3">
                                      <Dropdown
                                        placement="bottom"
                                        arrow={{ pointAtCenter: true }}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key="edit">
                                              <Link
                                                to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                                                className="text-decoration-none  align-items-center editButton"
                                              >
                                                <p className="d-flex align-items-center darkGrey  fm mb-0 text-14">
                                                  <BsPencilSquare className="me-1 darkGrey" />
                                                  Edit
                                                </p>
                                              </Link>
                                            </Menu.Item>
                                            <Menu.Item
                                              key="delete"
                                              onClick={(event) =>
                                                handleDeleteClick(
                                                  item.key,
                                                  event
                                                )
                                              }
                                              className="text-decoration-none delete-button"
                                            >
                                              Delete
                                            </Menu.Item>
                                          </Menu>
                                        }
                                        trigger={["click"]}
                                      >
                                        <Button className="candidates__menu">
                                          <BsThreeDotsVertical className="text-decoration-none" />
                                        </Button>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-sm-50 d-lg-none mb-1">
                                  <p className="fm darkGrey mb-sm-2 mb-1 nowrap job-title-sm">
                                    Applicants:{" "}
                                    <span className="text-13 fm">
                                      <BsEye className="fs-6" />{" "}
                                      {item.candidates}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-lg-none d-sm-flex mb-1">
                                  <div className="w-sm-50 mb-2">
                                    <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                      Type:{" "}
                                      <span className="job__type text-13 rounded-pill">
                                        {item.employment_type}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="w-sm-50 mb-1">
                                    <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                      Category:{" "}
                                      <span className="job__type text-13 rounded-pill">
                                        {item.department}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="d-lg-none d-sm-flex">
                                  <div className="w-sm-50 mb-1">
                                    <p className="fm darkGrey mb-0 job-title-sm">
                                      Posted:{" "}
                                      <span className="text-13 text-13">
                                        {item.start_date}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="w-sm-50">
                                    <p className="fm darkGrey mb-0 job-title-sm">
                                      Deadline:{" "}
                                      <span className="text-13 text-13">
                                        {item.end_date}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            ))
                        ) : (
                          <div className="d-flex align-items-center py-3">
                            <h3>No role exists! </h3>
                          </div>
                        )}
                        {/* {filteredJobs.filter(
                          (candidate) => candidate.status === "drafts"
                        ).length > 0 && (
                          <div className="d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all jobs ({filteredJobs.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                      </div>

                      {/* <div className="col-xl-3 mb-3 order-xl-2 order-1 d-xl-block d-none">
                    <div className="d-xxl-flex ">
                      <div className="d-flex justify-content-xxl-start justify-content-between">
                        <div className="candidates__searchbox d-flex align-items-center w-100 me-sm-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                        <div className=" me-xxl-2">
                          <Dropdown
                            ref={filterRef}
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            // trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter fm px-2 d-flex text-14 align-items-center fw-500 transition"
                              onClick={filterDrop}
                              style={{
                                border: filter ? "0.5px solid #5F89FF" : "",
                                color: filter ? "#5F89FF" : "",
                              }}
                              id="filter_btn"
                              ref={filterRef}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          validateProfile();
                        }}
                        className="bluegradient__btn mt-xxl-0 text-14 nowrap mt-2 text-white py-xxl-0 py-2 newjob__btn"
                      >
                        + Create new job
                      </button>
                    </div>

                    <div className="mt-2 radius8 px-3 py-4 jobs__box">
                      <h6 className="fm fw-semibold mb-1">Roles by category</h6>
                      <p className="fm text-14 darkGrey">
                        Click below to view all your jobs in category.
                      </p>
                      <div>
                        {departmentCounts.map(({ department, count }) => (
                          <div
                            className="d-flex justify-content-between align-items-center mb-2"
                            key={department}
                          >
                            <p className="mb-0 fw-semibold fm text-14">
                              {department}
                            </p>
                            <button className="jobs__pill rounded-pill fm text-capitalize">
                              {count} Jobs
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </>
            )}

            {selectedValue === "closed" && (
              <>
                <div className="container-dash d-md-block d-none">
                  {loading2 ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <>
                    <div className="table__outer table__outer__pro  radius8">
                      <Table
                        className="dash__table"
                        columns={tableColumnsClosed}
                        dataSource={formattedClosedJobs}
                        pagination={false}
                        scroll={{
                          x: 992,
                        }}
                      />
                    </div>

                    {/* {filteredJobs
                            .filter(
                              (candidate) => candidate.status === "closed"
                            ).length > 0 && (
                      <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                        <button className="fm text-12 table__bottom__btn1">
                          Load all jobs ({filteredJobs.length})
                        </button>
                        <button className="fm text-12 table__bottom__btn2">
                          Scroll to load more{" "}
                          <HiOutlineArrowDownCircle className="fs-6" />
                        </button>
                      </div>
                    )} */}
                    </>
                  )}
                </div>
                <div className="container-dash d-md-none d-block">
                  <div className="container-fluid">
                    <div className="row ">
                      <div className="col-12 ">
                        {loading2 ? (
                          <Loader size="md" className="" content="loading" />
                        ) : filteredJobs.length > 0 ? (
                          filteredJobs
                            .filter(
                              (candidate) => candidate.status === "closed"
                            )
                            .map((item) => (
                              <Link
                                to={`/hr/dashboard/jobs/${item.key}`}
                                className="filter__border row text-decoration-none jobs__border d-flex flex-column  pt-3 pb-3 transition pointer mb-2"
                                key={item.key}
                                // onClick={() => Candidates(item)}
                              >
                                <div className="d-flex align-items-lg-center w-100 justify-content-between align-items-start">
                                  <div className="job-col-4 darkGrey ps-lg-3">
                                    <h6 className="mb-lg-0 mt-0">
                                      {item.para}{" "}
                                      <span className="job__status text-13 d-lg-none d-inline-block rounded-pill">
                                        {item.status}
                                      </span>
                                    </h6>
                                  </div>
                                  <div className="d-flex">
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__status rounded-pill">
                                        {item.status}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="darkGrey fm d-flex align-items-center">
                                        <FaUser className="fs-6 me-1" />{" "}
                                        {item.candidates}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__type rounded-pill">
                                        {item.employment_type}
                                      </span>
                                    </div>
                                    <div className="job-col-2 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      <span className="job__category rounded-pill">
                                        {item.department}
                                      </span>
                                    </div>
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      {item.start_date}
                                    </div>
                                    <div className="job-col-1 darkGrey text-14 d-lg-flex d-none align-items-center">
                                      {item.end_date}
                                    </div>
                                    <div className="job-col-last justify-content-end darkGrey d-flex align-items-lg-center pe-lg-3">
                                      <Dropdown
                                        placement="bottom"
                                        arrow={{ pointAtCenter: true }}
                                        overlay={
                                          <Menu>
                                            <Menu.Item key="edit">
                                              <Link
                                                to={`/hr/dashboard/jobs/edit-job/${item.key}`}
                                                className="text-decoration-none  align-items-center editButton"
                                              >
                                                <p className="d-flex align-items-center darkGrey  fm mb-0 text-14">
                                                  <BsPencilSquare className="me-1 darkGrey" />
                                                  Edit
                                                </p>
                                              </Link>
                                            </Menu.Item>
                                            <Menu.Item
                                              key="delete"
                                              onClick={(event) =>
                                                handleDeleteClick(
                                                  item.key,
                                                  event
                                                )
                                              }
                                              className="text-decoration-none delete-button"
                                            >
                                              Delete
                                            </Menu.Item>
                                          </Menu>
                                        }
                                        trigger={["click"]}
                                      >
                                        <Button className="candidates__menu">
                                          <BsThreeDotsVertical className="text-decoration-none" />
                                        </Button>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-sm-50 d-lg-none mb-1">
                                  <p className="fm darkGrey mb-sm-2 mb-1 nowrap job-title-sm">
                                    Applicants:{" "}
                                    <span className="text-13 fm">
                                      <BsEye className="fs-6" />{" "}
                                      {item.candidates}
                                    </span>
                                  </p>
                                </div>
                                <div className="d-lg-none d-sm-flex mb-1">
                                  <div className="w-sm-50 mb-2">
                                    <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                      Type:{" "}
                                      <span className="job__type text-13 rounded-pill">
                                        {item.employment_type}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="w-sm-50 mb-1">
                                    <p className="fm darkGrey mb-1 nowrap job-title-sm">
                                      Category:{" "}
                                      <span className="job__type text-13 rounded-pill">
                                        {item.department}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="d-lg-none d-sm-flex">
                                  <div className="w-sm-50 mb-1">
                                    <p className="fm darkGrey mb-0 job-title-sm">
                                      Posted:{" "}
                                      <span className="text-13 text-13">
                                        {item.start_date}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="w-sm-50">
                                    <p className="fm darkGrey mb-0 job-title-sm">
                                      Deadline:{" "}
                                      <span className="text-13 text-13">
                                        {item.end_date}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            ))
                        ) : (
                          <div className="d-flex align-items-center py-3">
                            <h3>No role exists! </h3>
                          </div>
                        )}
                        {filteredJobs
                            .filter(
                              (candidate) => candidate.status === "closed"
                            ).length > 0 && (
                      <div className="d-flex justify-content-center pt-3 gap-2">
                        <button className="fm text-12 table__bottom__btn1">
                          Load all jobs ({filteredJobs.length})
                        </button>
                        <button className="fm text-12 table__bottom__btn2">
                          Scroll to load more{" "}
                          <HiOutlineArrowDownCircle className="fs-6" />
                        </button>
                      </div>
                    )}
                      </div>

                      {/* <div className="col-xl-3 mb-3 order-xl-2 order-1 d-xl-block d-none">
                    <div className="d-xxl-flex ">
                      <div className="d-flex justify-content-xxl-start justify-content-between">
                        <div className="candidates__searchbox d-flex align-items-center w-100 me-sm-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search..."
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </div>
                        <div className=" me-xxl-2">
                          <Dropdown
                            ref={filterRef}
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            // trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter fm px-2 d-flex text-14 align-items-center fw-500 transition"
                              onClick={filterDrop}
                              style={{
                                border: filter ? "0.5px solid #5F89FF" : "",
                                color: filter ? "#5F89FF" : "",
                              }}
                              id="filter_btn"
                              ref={filterRef}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          validateProfile();
                        }}
                        className="bluegradient__btn mt-xxl-0 text-14 nowrap mt-2 text-white py-xxl-0 py-2 newjob__btn"
                      >
                        + Create new job
                      </button>
                    </div>

                    <div className="mt-2 radius8 px-3 py-4 jobs__box">
                      <h6 className="fm fw-semibold mb-1">Roles by category</h6>
                      <p className="fm text-14 darkGrey">
                        Click below to view all your jobs in category.
                      </p>
                      <div>
                        {departmentCounts.map(({ department, count }) => (
                          <div
                            className="d-flex justify-content-between align-items-center mb-2"
                            key={department}
                          >
                            <p className="mb-0 fw-semibold fm text-14">
                              {department}
                            </p>
                            <button className="jobs__pill rounded-pill fm text-capitalize">
                              {count} Jobs
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <Tabs className="jobs__tabs Ejobs__tabs"> */}
        {/* <TabList>
            <Tab>My Jobs</Tab>
            <Tab>Active Jobs</Tab>
            <Tab>
              Interviews
            </Tab>
          </TabList> */}

        {/* <TabPanel>
            <div className="Dashboard__container">
              <div className="jobs fm ms-xxl-5 ms-lg-4 ms-3 mt-lg-0 mt-4 ">
                <div className="container-fluid">
                  <div className="row enterprise__items">
                    {loading2 ? (
                      <Loader size="md" className="pt-4" content="loading" />
                    ) : (
                      activeJobs.map((item) => (
                        <Link
                          to={``}
                          className="col-12 filter__border jobs__border d-flex flex-column pt-md-4 pt-3 pb-3 transition pointer mb-2"
                          key={item.key}
                        // onClick={() => Candidates(item)}
                        >
                          <div className="container-fluid px-0 px-0">
                            <div className="filter__data d-flex align-items-md-start align-items-center">
                              <div className="filter__image radius8 bg-darkGrey d-flex justify-content-center align-items-center">
                                <h1 className="fs-1 fm text-white">
                                  {item.heading.charAt(0)}
                                </h1>
                              </div>
                              <div className="filter__content ">
                                <div className="d-flex flex-sm-row flex-column justify-content-between">
                                  <div className=" filter__content__items d-flex flex-column mb-md-0 mb-sm-3">
                                    <div className="d-flex justify-content-between">
                                      <h3 className="filter__content__heading darkGrey text-22 mb-md-2 mb-0 fw-semibold">
                                        {item.heading}
                                      </h3>
                                    </div>
                                    <div className="filter__content__para mt-0 d-md-flex d-none flex-wrap justify-content-start align-items-center">
                                      <div className="">
                                        <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                          
                                        </p>
                                      </div>
                                      <div className="d-flex flex-wrap ">
                                        <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                          <FiUsers /> 500–4,000
                                        </p>
                                        <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                          <HiOutlineSquare3Stack3D /> Creative &
                                          Strategy
                                        </p>
                                        <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                          <TbCurrencyDollar /> {item.min_salary}
                                          -{item.max_salary} USD
                                        </p>
                                      </div>
                                    </div>
                                    <div className="filter__content__tag d-md-flex d-none flex-wrap mt-2 justify-content-between mt-3">
                                      <div className="d-flex flex-wrap">
                                        {item.tags.map((items) => (
                                          <div className="pe-2 mb-sm-0 mb-2">
                                            <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center ">
                                              <small className="filter__content__tag__content--small text-capitalize text-12">
                                                {items}
                                              </small>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-md-flex flex-column justify-content-between d-none">
                                    <div className="d-flex justify-content-end align-items-end filter__content__dates">
                                      <div className="w-100 d-flex justify-content-end">
                                        <div className="text-end">
                                          <small className="text-muted text-12">
                                            POSTED:
                                          </small>
                                          <p className="text-16 text-muted mb-0">
                                            {item.start_date}
                                          </p>
                                        </div>
                                        <div className="text-end ms-4">
                                          <small className="text-muted text-12">
                                            Deadline:
                                          </small>
                                          <p className="text-16 text-muted mb-0">
                                            {item.end_date}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end mb-2">
                                      <Link
                                        to={""}
                                        className="text-decoration-none "
                                      >
                                        <p className="text-16 fm mb-0">
                                          View <BsArrowUpRight />{" "}
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter__content__para pt-3 mt-0 d-md-none d-flex flex-wrap justify-content-start align-items-center">
                              <div className="">
                                <p className="pro__dash__jobs__para fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                  {item.para}{" "}
                                  <img
                                    className="pro__dash__jobs__icon"
                                    src={`${item.icon}`}
                                    alt=""
                                  />
                                </p>
                              </div>
                              <div className="d-flex flex-wrap align-items-center">
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <FiUsers /> 500–4,000
                                </p>
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <HiOutlineSquare3Stack3D /> Creative &
                                  Strategy
                                </p>
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <TbCurrencyDollar /> 30-40K USD
                                </p>
                              </div>
                            </div>
                            <div className="filter__content__tag d-md-none d-flex flex-md-nowrap pt-3 flex-wrap w-100">
                              {item.tags.map((items) => (
                                <div className="pe-2 mb-sm-0 mb-2">
                                  <div className="filter__content__tag__content px-3 rounded-pill ">
                                    <small className="filter__content__tag__content--small ">
                                      {items}
                                    </small>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Link>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="Dashboard__container">
              <div className="jobs fm ms-xxl-5 ms-lg-4 ms-3 mt-lg-0 mt-4 ">
                <div className="container-fluid">
                  <div className="row enterprise__items">
                    {loading2 ? (
                      <Loader size="md" className="pt-4" content="loading" />
                    ) : (
                      interviewersData.map((item) => (
                        <Link
                          to={`/hr/dashboard/jobs/${item.jobid}`}
                          className="col-12 filter__border jobs__border d-flex flex-column pt-md-4 pt-3 pb-3 transition pointer mb-2"
                          key={item.key}
                        // onClick={() => Candidates(item)}
                        >
                          <div className="container-fluid px-0 px-0">
                            <div className="filter__data d-flex align-items-md-start align-items-center">
                              <div className="filter__image radius8 bg-darkGrey d-flex justify-content-center align-items-center">
                                <h1 className="fs-1 fm text-white">
                                  {item.heading.charAt(0)}
                                </h1>
                              </div>
                              <div className="filter__content ">
                                <div className="d-flex flex-sm-row flex-column justify-content-between">
                                  <div className=" filter__content__items d-flex flex-column mb-md-0 mb-sm-3">
                                    <div className="d-flex justify-content-between">
                                      <h3 className="filter__content__heading darkGrey text-22 mb-md-2 mb-0 fw-semibold">
                                        {item.heading}
                                      </h3>
                                    </div>
                                    <div className="filter__content__para mt-0 d-md-flex d-none flex-wrap justify-content-start align-items-center">
                                      <div className="">
                                        <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">                                          
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-md-flex flex-column justify-content-between d-none">
                                    <div className="d-flex justify-content-end align-items-end filter__content__dates">
                                      <div className="w-100 d-flex justify-content-end">
                                        <div className="text-end">
                                          <small className="text-muted text-12">
                                            POSTED:
                                          </small>
                                          <p className="text-16 text-muted mb-0">
                                            {item.date_start}
                                          </p>
                                        </div>
                                        <div className="text-end ms-4">
                                          <small className="text-muted text-12">
                                            Deadline:
                                          </small>
                                          <p className="text-16 text-muted mb-0">
                                            {item.date_end}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end mb-2">
                                      <Link
                                        to={""}
                                        className="text-decoration-none "
                                      >
                                        <p className="text-16 fm mb-0">
                                          View <BsArrowUpRight />{" "}
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter__content__para pt-3 mt-0 d-md-none d-flex flex-wrap justify-content-start align-items-center">
                              <div className="">
                                <p className="pro__dash__jobs__para fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                  {item.para}{" "}
                                  <img
                                    className="pro__dash__jobs__icon"
                                    src={`${item.icon}`}
                                    alt=""
                                  />
                                </p>
                              </div>
                              <div className="d-flex flex-wrap align-items-center">
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <FiUsers /> 500–4,000
                                </p>
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <HiOutlineSquare3Stack3D /> Creative &
                                  Strategy
                                </p>
                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                  <TbCurrencyDollar /> 30-40K USD
                                </p>
                              </div>
                            </div>
                            
                          </div>
                        </Link>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel> */}
        {/* </Tabs> */}
      </div>
    </div>
  );
};

export default Jobs;
