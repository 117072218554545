import React from "react";
import { Link } from "react-router-dom";
import './style.css'

const ESection2 = () => {
  return (
    <>
      <Link to={"/enterprise/signup"} className="text-decoration-none">
        <div className="lightBlueBar main-padding d-flex align-items-center pointer">
          <div className="container-lg px-0 h-100 py-3">
            <div className="lightBlueBar__content w-100 h-100 d-flex justify-content-between align-items-center">
              <div className="lightBlueBar__content__heading darkBlue fw-normal">
                Get started with IndexScore today and cut your recruitment time
                by 95%
              </div>
              <img
                src={`/assets/images/enterprises/arrow-right-thin-darkBlue.svg`}
                alt=""
                className="lightBlueBar__content__icon__image"
              />
            </div>
          </div>
        </div>
      </Link>
      <Link to={"/professional/signup"} className="text-decoration-none text-white">
        <div className="lightBlueBar main-padding darkBlueBar d-flex align-items-center pointer">
          <div className="container-lg px-0 h-100 py-3">
            <div className="lightBlueBar__content w-100 h-100 d-flex justify-content-between align-items-center">
              <div className="lightBlueBar__content__heading text-white">
                Need a Candidate account?
              </div>
              <img
                src={`/assets/images/individuals/arrow-right-thin-white.svg`}
                alt=""
                className="lightBlueBar__content__icon__image"
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ESection2;
