import React from 'react'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBarEnterprise from "../components/ProgressBarEnterprise/ProgressBarEnterprise.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Open from '../pageComponents/Enterprise/OpenQuestions/Open.jsx';
import UpdatedNav from '../UpdatedFrontend/Navbar/UpdatedNav.jsx';

const EnterpriseOpenQuestion = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBarEnterprise page="5" title="Enterprise" />}
        right={<Open
        color ="green"
        page="enterprise"
        />}
      />
      <Footer />
    </>
  )
}

export default EnterpriseOpenQuestion