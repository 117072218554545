import React from "react";
import { Link } from "react-router-dom";

const UESection2 = () => {
  return (
    <div className="ues3 main-padding mt-4 py-4">
      <div className="container-xl px-0 py-5">
        <div className="text-center">
          <p className="fm darkGrey text-14 text-center  rounded-pill uhs3__title mb-2">
            For Candidates
          </p>
          <h1 className="fs-1 darkBlue text-center text-center fw-semibold mb-0">
            Hire qualified candidates with ease
          </h1>
        </div>

        <div className="row pt-5">
          <div className="col-md-6 pe-md-0 mb-3">
            <div className="ues3__card1 ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm text-white fs-4">Sign-up</h6>
                <p className="fm text-white-50 text-14 fw-light">
                  Sign up to the right plan for you or book a demo for one of
                  our team to walk you through our platform and your choices.
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Enterprise/candidatecard1.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="ues3__card2 ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm darkGrey fs-4">45 minutes or less</h6>
                <p className="fm darkGrey text-14">
                  Get onboarded within 45 minutes or less. No complicated
                  training mannuals or contact structures — just direct access
                  to a simple dashboard.
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Enterprise/candidatecard2.png"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-8 pe-md-0 mb-3">
            <div className="ues3__card3 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="px-4">
                <h6 className="fm darkGrey fs-4">Setup</h6>
                <p className="fm darkGrey text-14">
                  Assign the skills you need for your roles so you can find out
                  what score you need for the ideal candidate
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Enterprise/candidatecard3.png"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="ues3__card4 ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm darkGrey fs-4">Best-fit</h6>
                <p className="fm darkGrey text-14">
                  Choose the best candidates using our platform alone or
                  seamlessly integrated with your existing ATS
                </p>
              </div>

              <div>
                <img
                  src="/assets/UpdatedFrontend/Enterprise/candidatecard4.png"
                  alt=""
                  className="img-fluid"
                />
                <Link to={"/book-a-demo"} className="text-decoration-none">
                  <button className="btn--blue transition fw-semibold rounded-2 mb-4 py-2 fm main ues3__card4__btn">
                    Book a demo
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3 pe-md-0">
            <div className="ues3__card5 ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm darkGrey fs-4 darkBlue">
                  Dive into the IndexScore
                </h6>
                <p className="fm darkGrey text-14 darkBlue fw-normal">
                  While the IndexScore is the overview of candidate made up from
                  over 600+ points, you always have the option of deep diving in
                  to their full details – but we think you'll love not having
                  to!
                </p>
              </div>

              <div>
                <img
                  src="/assets/UpdatedFrontend/Enterprise/candidatecard5.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>

          <div className="col-md-8 mb-3">
            <div className="ues3__card6 ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between bg-darkBlue">
              <div className="pe-4">
                <h6 className="fm text-white fs-4">
                  Manage the whole hiring process in one place
                </h6>
                <p className="fm text-white text-14 fw-light mb-2">
                  No more Excel spreadsheets of candidates, with notes, and
                  their stage in process. Everything can be managed with
                  IndexScore , from job ad creation to signing of their
                  contract.
                </p>
                <p className="fm text-white text-14 fw-light">
                  Or we can fully integrate with your existing ATS if you'd prefer. 
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Enterprise/candidatecard6.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UESection2;
