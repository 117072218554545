import "./App.css";
import { useState, useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
  Switch,
} from "react-router-dom";
import Home from "./pages/Home";
import Individuals from "./pages/Individuals";
import Enterprises from "./pages/Enterprises";
import Aboutus from "./pages/Aboutus";
import SignIn from "./pages/SignIn";
import Jobsforum from "./pages/Jobsforum";
import Contactus from "./pages/Contactus";
import WhyIndexcore from "./pages/WhyIndexCore";
import Pricing from "./pages/Pricing";
import ProfessionalsGetStarted from "./pages/ProfessionalsGetStarted";
import EnterpriseGetStarted from "./pages/EnterpriseGetStarted";
import EnterpriseThankYou from "./pages/EnterpriseThankYou";
import ProfessionalsSignUp from "./pages/ProfessionalsSignUp";
import ProfessionalExtraInfo from "./pages/ProfessionalExtraInfo";
import EnterpriseSignup from "./pages/EnterpriseSignup";
import EnterpriseExtraInfo from "./pages/EnterpriseExtraInfo";
import EnterpriseOnlinePresence from "./pages/EnterpriseOnlinePresence";
import EnterpriseOpenQuestion from "./pages/EnterpriseOpenQuestion";
import EnterpriseVideoIntro from "./pages/EnterpriseVideoIntro";
import EnterpriseSubmit from "./pages/EnterpriseSubmit";
import ProfessionalOpenQuestion from "./pages/ProfessionalOpenQuestions";
import ProfessionalVideoIntro from "./pages/ProfessionalVideoIntro";
import ProfessionalSubmit from "./pages/ProfessionalSubmit";
import ProfessionalOnlinePresence from "./pages/ProfessionalOnlinePresence";
import ProfessionalConsent from "./pages/ProfessionalConsent";
import ProfessionalsThankYou from "./pages/ProfessionalsThankYou";
import EnterpriseIdentification from "./pages/EnterpriseIdentification";
import ProfessionalIdentification from "./pages/ProfessionalIdentification";
import BookADemo from "./pages/BookADemo";
import Errors from "./pages/Error";
import { MyContextProvider, useAuth } from "./Utilities/MyContextProvider";
import Feedback from "./components/FeedbackForm/Feedback";
import Submitted from "./components/FeedbackForm/Submitted";
import DAccount from "./pageComponents/EnterpriseDashboard/YourAccount/DAccount";
import UpgradeEnterprisePlan from "./pageComponents/EnterpriseDashboard/YourAccount/Plan/UpgradeEnterprisePlan";
import DContainer from "./components/Dashboard/DashboradContainer/DContainer";
import DashboardScreen from "./pageComponents/EnterpriseDashboard/DashboardScreen/DashboardScreen";
import ProInbox from "./pageComponents/EnterpriseDashboard/ProInbox/ProInbox";
import DashboardCalender from "./pageComponents/EnterpriseDashboard/DashboardCalender/DashboardCalender";
import Jobs from "./pageComponents/EnterpriseDashboard/Jobs/Jobs";
import ProfessionalsTab from "./pageComponents/EnterpriseDashboard/OffersManagement/OffersTab";
import ProfessionalsList from "./pageComponents/EnterpriseDashboard/OffersManagement/ProfessionalsTab";
import ATSCandidates from "./pageComponents/EnterpriseDashboard/ATS/ATSCandidates";
import ATSCandidatesProfile from "./pageComponents/EnterpriseDashboard/ATS/ATSCandidatesProfile";
import ProfessionalProfile from "./pageComponents/EnterpriseDashboard/OffersManagement/OffersProfile";
import NewJobContainer from "./pageComponents/EnterpriseDashboard/NewJob/NewJobContainer";
import Settings from "./pageComponents/EnterpriseDashboard/Settings/Settings";
import EnterpriseSupport from "./pageComponents/EnterpriseDashboard/Settings/Support/Support";
import Candidates from "./pageComponents/EnterpriseDashboard/Candidates/Candidates";
import ProDashboardScreen from "./pageComponents/ProfessionalDashboard/DashboardScreen/DashboardScreen";
import PAccount from "./pageComponents/ProfessionalDashboard/YourAccount/DAccount";
import UpgradePlan from "./pageComponents/ProfessionalDashboard/YourAccount/Plan/UpgradePlan";
import ProDContainer from "./components/ProDashboard/ProDContainer/ProDContainer";
import ProDInbox from "./pageComponents/ProfessionalDashboard/ProInbox/ProInbox";
import ProDashboardCalender from "./pageComponents/ProfessionalDashboard/DashboardCalender/DashboardCalender";
import ProJobs from "./pageComponents/ProfessionalDashboard/Jobs/Jobs";
import YourProfile from "./pageComponents/ProfessionalDashboard/YourProfile/YourProfile";
import YourIndexScore from "./pageComponents/ProfessionalDashboard/YourIndexScore/YourIndexScore";
import ProSettings from "./pageComponents/ProfessionalDashboard/Settings/Settings";
import Support from "./pageComponents/ProfessionalDashboard/Settings/Support/Support";
import JobApply from "./pageComponents/ProfessionalDashboard/Jobs/JobApply";
import JobDescription from "./pageComponents/ProfessionalDashboard/Jobs/JobDescription";
import MeetingDetails from "./pageComponents/EnterpriseDashboard/Meeting/MeetingDetails";
import SendContract from "./pageComponents/EnterpriseDashboard/Candidates/SendContract";
import MeetingDetailsProffesional from "./pageComponents/ProfessionalDashboard/Meeting/MeetingDetails";
import MeetingTime from "./pageComponents/EnterpriseDashboard/Meeting/MeetingTime";
import MeetingConfirm from "./pageComponents/EnterpriseDashboard/Meeting/MeetingConfirm";
import ProContracts from "./pageComponents/ProfessionalDashboard/Contracts/Contracts";
import ProOffers from "./pageComponents/ProfessionalDashboard/Contracts/Offers";
import ProContractsInvitations from "./pageComponents/ProfessionalDashboard/Contracts/ContractInvitations";
import ProContractDetails from "./pageComponents/ProfessionalDashboard/Contracts/ContractDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ForgotPassword from "./pages/ForgotPassword";
import OtpVerification from "./pages/OtpVerification";
import ResetPassword from "./pages/ResetPassword";
import OffersTab from "./pageComponents/EnterpriseDashboard/OffersManagement/OffersTab";
import DepartmentTab from "./pageComponents/EnterpriseDashboard/OffersManagement/Department";
import AddMemmbers from "./pageComponents/EnterpriseDashboard/OffersManagement/AddMembers";
import ReportContainer from "./pageComponents/EnterpriseDashboard/ComplianceReport/ReportContainer";
import HiringReport from "./pageComponents/HiringReportPDF/HiringReport";
import Landscape from "./pageComponents/EnterpriseDashboard/CandidateLandscape/Landscape";
import Overview from "./pageComponents/EnterpriseDashboard/CandidateLandscape/Overview";
import TalentInsights from "./pageComponents/EnterpriseDashboard/CandidateLandscape/TalentInsights";
import Holding from "./pages/Holding";
import EditJob from "./pageComponents/EnterpriseDashboard/Jobs/EditJob";
import InterviewPortal from "./pageComponents/InterviewPortal/InterviewPortal";
import SharingProfile from "./pageComponents/ProfessionalDashboard/YourProfile/ShareProfile";
import SavingDashboardContainer from "./Launch/SavingCalculator/SavingDashboardContainer";
import SignupAsCandidate from "./Launch/Signup/SignupAsCandidate";
import CandidateSignup from "./pages/CandidateSignup";
import CandidateVerification from "./pages/CandidateVerification";
import EnterpriseVerification from "./pages/EnterpriseVerification";
import CandidatePublicProfile from "./pages/CandidatePublicProfile";
import CandidateProfile from "./pages/CandidateProfile";
import EnterprisePublicProfile from "./pages/EnterprisePublicProfile";
import SignupOps from "./pages/SignupOps";
import EnterpriseNav from "./components/Dashboard/EnterpriseNav";
import RecruitingNav from "./components/Dashboard/RecruitingNav";
import ProfessionalNav from "./components/ProDashboard/ProfessionalNav";
import JobsBox from "./pageComponents/ProfessionalDashboard/Jobs/JobsBox";
import JobsDescriptionBox from "./pageComponents/ProfessionalDashboard/Jobs/JobsDescriptionBox";
import InteriewPrepSheet from "./pageComponents/InteriewPrepSheet";
import Invoice from "./pageComponents/Invoice";
import EnterpriseInvoice from "./pageComponents/EnterpriseInvoice";
import CandidatePage from "./pages/CandidatePage";
import ProfileCreation from "./pageComponents/ProfessionalDashboard/ProfileCreation/ProfileCreation";
import HrPortal from "./pageComponents/HrPortal";
import HrNav from "./components/Dashboard/HrNav";
import HrJobs from "./pageComponents/HrPortal/Jobs/Jobs";
import HrEditJob from "./pageComponents/HrPortal/Jobs/EditJob";
import HrCandidates from "./pageComponents/HrPortal/Candidates/Candidates";
import ProInboxHr from "./pageComponents/HrPortal/ProInbox/ProInboxHr";
import HrRecruitingNav from "./components/Dashboard/HrRecruitingNav";
import DepartmentTabHr from "./pageComponents/HrPortal/OffersManagement/DepartmentTabHr";
import NewJobContainerHr from "./pageComponents/HrPortal/NewJob/NewJobContainerHr";
import EnterpriseProfile from "./pageComponents/EnterpriseDashboard/EnterpriseProfile/EnterpriseProfile";
import EnterprisePublicProfileEdit from "./pages/EnterprisePublicProfileEdit";
import EditEnterpriseProfile from "./pageComponents/EnterpriseDashboard/EnterpriseProfile/EditEnterpriseProfile.jsx";
import JobPublic from "./pages/JobPublic";
import HrOffersTab from "./pageComponents/HrPortal/OffersManagement/OffersTab";
const AuthRoute = ({ element, requiredUserType, allowedUserRoles }) => {
  const { state, dispatch } = useAuth();

  if (state && state.isAuthenticated && state.user) {
    if (
      requiredUserType.includes(state.usertype) &&
      (!allowedUserRoles || allowedUserRoles.includes(state.userRole))
    ) {
      return element;
    } else {
      return <Navigate to="/log-in" replace />;
    }
  } else {
    const savedUser = localStorage.getItem("userData");
    const savedUserType = localStorage.getItem("usertype");
    const savedUserRole = localStorage.getItem("userRole");
    if (savedUser && savedUserType && savedUserRole) {
      const user = JSON.parse(savedUser);
      dispatch({
        type: "LOGIN",
        payload: { user, usertype: savedUserType, userRole: savedUserRole },
      });
      if (
        (savedUserType === "enterprise" &&
          requiredUserType.includes(savedUserType) &&
          (!allowedUserRoles || allowedUserRoles.includes(savedUserRole))) ||
        (savedUserType === "member" &&
          requiredUserType.includes(savedUserType) &&
          (!allowedUserRoles || allowedUserRoles.includes(savedUserRole))) ||
        (savedUserType === "professional" &&
          requiredUserType.includes(savedUserType))
      ) {
        return element;
      } else {
        return <Navigate to="/log-in" replace />;
      }
    } else {
      return <Navigate to="/log-in" replace />;
    }
  }
};

function App() {
  const [jobData, setJobData] = useState(false);
  return (
    <>
      <MyContextProvider>
        <Routes>
          <Route path={"/einvoice"} element={<EnterpriseInvoice />} />
          <Route path={"/invoice"} element={<Invoice />} />
          <Route path={"/"} element={<Home />} />
          <Route path={"/prepsheet"} element={<InteriewPrepSheet />} />
          <Route path={"/feedback/:id"} element={<Feedback />} />
          <Route path={"/feedback/submitted"} element={<Submitted />} />
          <Route path={"/candidate"} element={<CandidatePage />} />
          <Route path={"/book-a-demo"} element={<BookADemo />} />
          <Route path={"/enterprise"} element={<Enterprises />} />
          <Route path={"/about-us"} element={<Aboutus />} />
          <Route path={"/log-in"} element={<SignIn />} />
          <Route path={"/signup"} element={<SignupOps />} />
          <Route path={"/careers"} element={<Jobsforum />} />
          <Route path={"/contact-us"} element={<Contactus />} />
          <Route path={"/why-indexcore"} element={<WhyIndexcore />} />
          <Route path={"/pricing"} element={<Pricing />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"/terms-of-use"} element={<TermsOfUse />} />
          <Route path={"/forgotten-password"} element={<ForgotPassword />} />
          <Route path={"/otp-verification"} element={<OtpVerification />} />
          <Route path={"/resetpassword/:id"} element={<ResetPassword />} />
          <Route path={"/holding"} element={<Holding />} />
          <Route path={"/report"} element={<HiringReport />} />

          {/* <Route path={"/profile/:id"} element={<CandidatePublicProfile />} /> */}
          <Route path={"/profile/:id"} element={<CandidateProfile />} />
          <Route
            path={"/enterprise-profile/:id"}
            element={<EnterprisePublicProfile />}
          />
          <Route path={"/job/:id"} element={<JobPublic />} />
          <Route
            path={"/enterprise-profile-edit/:id"}
            element={<EnterprisePublicProfileEdit />}
          />
          {/* professional signup */}
          {/* <Route path={"/professional/signup"} element={<ProfessionalsGetStarted />} /> */}
          <Route path={"/professional/signup"} element={<CandidateSignup />} />
          <Route
            path={"/professional/verification/:id"}
            element={<CandidateVerification />}
          />
          <Route
            path={"/professional/signup/details/:id"}
            element={<ProfessionalsSignUp />}
          />
          <Route
            path={"/professional/signup/extra-info"}
            element={<ProfessionalExtraInfo />}
          />
          <Route
            path={"/professional/signup/identification"}
            element={<ProfessionalIdentification />}
          />
          <Route
            path={"/professional/signup/online-presence"}
            element={<ProfessionalOnlinePresence />}
          />
          <Route
            path={"/professional/signup/open-questions"}
            element={<ProfessionalOpenQuestion />}
          />
          <Route
            path={"/professional/signup/video-introduction"}
            element={<ProfessionalVideoIntro />}
          />
          <Route
            path={"/professional/signup/consent"}
            element={<ProfessionalConsent />}
          />
          <Route
            path={"/professional/signup/thank-you"}
            element={<ProfessionalsThankYou />}
          />
          <Route
            path={"/professional/signup/submit"}
            element={<ProfessionalSubmit />}
          />
          {/* enterprise signup */}
          <Route
            path={"/enterprise/signup"}
            element={<EnterpriseGetStarted />}
          />
          <Route
            path={"/enterprise/verification/:id"}
            element={<EnterpriseVerification />}
          />
          <Route
            path={"/enterprise/signup/thank-you"}
            element={<EnterpriseThankYou />}
          />
          <Route
            path={"/enterprise/signup/details/:id"}
            element={<EnterpriseSignup />}
          />
          <Route
            path={"/enterprise/signup/extra-info"}
            element={<EnterpriseExtraInfo />}
          />
          <Route
            path={"/enterprise/signup/identification"}
            element={<EnterpriseIdentification />}
          />
          <Route
            path={"/enterprise/signup/online-presence"}
            element={<EnterpriseOnlinePresence />}
          />
          <Route
            path={"/enterprise/signup/open-questions"}
            element={<EnterpriseOpenQuestion />}
          />
          <Route
            path={"/enterprise/signup/video-introduction"}
            element={<EnterpriseVideoIntro />}
          />
          <Route
            path={"/enterprise/signup/submit"}
            element={<EnterpriseSubmit />}
          />

          {/* professional dashboard */}

          <Route
            path={"/candidate/dashboard"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" mobName="dashboard" />
                    <PAccount name="dashboard" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/account"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" mobName="account" />
                    <PAccount name="profile" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/plan"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" mobName="plan" />
                    <PAccount name="plan" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/plan/upgrade"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" mobName="plan" />
                    <UpgradePlan name="plan" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/api"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" />
                    <PAccount />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/kyc-verification"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="dashboard" mobName="kyc" />
                    <PAccount name="kyc" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/inbox"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="inbox" />
                    <ProDInbox />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/calendar"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="calendar" />
                    <ProDashboardCalender />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/profile/:id"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav />
                    <ProfileCreation />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/jobs"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="jobs" />
                    <JobsBox />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/jobs/:id"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="jobs" />
                    <JobsDescriptionBox />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/jobs/:id/apply"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="jobs" />
                    <JobApply />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/profile"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="profile" />
                    <YourProfile page="professional" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/profile/sharing"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="sharing" mobName="dashboard" />
                    <SharingProfile />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/indexscore"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="indexscore" mobName="indexscore" />
                    <YourIndexScore />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/settings"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="settings" mobName="settings" />
                    <ProSettings />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/support"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="support" mobName="support" />
                    <Support />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/jobs/interview/:id"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="interview" />
                    <MeetingDetailsProffesional />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/contracts"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="contracts" />
                    <JobsBox name="contracts" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/interviews"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="interview" />
                    <JobsBox name="interviews" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />
          <Route
            path={"/candidate/dashboard/interview-preparation"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="prep" />
                    <JobsBox name="interviewPreparation" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/offers"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="offers" />
                    <JobsBox name="offers" />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/contracts/invitations"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="contracts" />
                    <ProContractsInvitations />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          <Route
            path={"/candidate/dashboard/contracts/details"}
            element={
              <AuthRoute
                element={
                  <>
                    <ProfessionalNav name="jobs" mobName="contracts" />
                    <ProContractDetails />
                  </>
                }
                requiredUserType={["professional"]}
              />
            }
          />

          {/* old route           */}

          {/* interview dashboard */}
          <Route
            path={"/interviewer/dashboard/"}
            element={
              <AuthRoute
                element={<InterviewPortal />}
                requiredUserType={["member"]}
              />
            }
          />

          {/* enterprise dashboard */}
          <Route
            path={"/enterprise/dashboard"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="dashboard" mobName="dashboard" />
                    <DAccount />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/account"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="dashboard" mobName="profile" />
                    <DAccount name="profile" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/enterprise-profile"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="dashboard" mobName="plan" />
                    <EditEnterpriseProfile />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/plan"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="dashboard" mobName="plan" />
                    <DAccount name="plan" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/plan/upgrade"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="dashboard" mobName="plan" />
                    <UpgradeEnterprisePlan name="plan" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/inbox"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="inbox" />
                    <ProInbox />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/calendar"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="calendar" />
                    <DashboardCalender />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/jobs"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="jobs" />
                    <RecruitingNav name="jobs" />
                    <Jobs />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />

          <Route
            path={`/enterprise/dashboard/jobs/:id`}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="jobs" />
                    <RecruitingNav name="jobs" />
                    <Candidates jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={`/enterprise/dashboard/jobs/edit-job/:id`}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="jobs" />
                    <RecruitingNav name="jobs" />
                    <EditJob jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/candidates"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="candidates" />
                    <RecruitingNav name="candidates" />
                    <ProfessionalsList jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/ats-candidates"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="ats" />
                    <RecruitingNav name="ats" />
                    <ATSCandidates />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/ats-candidates/candidate"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="ats" />
                    <RecruitingNav name="ats" />
                    <ATSCandidatesProfile />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/candidate/:id"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" />
                    <RecruitingNav name="candidates" />
                    <ProfessionalProfile />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/offers"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="offers" />
                    <RecruitingNav name="offers" />
                    <OffersTab />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/department"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="departments" />
                    <RecruitingNav name="departments" />
                    <DepartmentTab />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/department/add-member"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="departments" />
                    <RecruitingNav name="departments" />
                    <AddMemmbers />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />

          {/* <Route path={"/enterprise/dashboard/calculator"}
            element={              
              <AuthRoute element={<><EnterpriseNav name="recruiting" mobName="calc" /><RecruitingNav name="calc" /><SavingDashboardContainer /></>} requiredUserType={['enterprise']} allowedUserRoles={['admin']} 
              />
            }
          /> */}
          <Route
            path={"/enterprise/dashboard/report"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="report" />
                    <ReportContainer />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/candidate-landscape"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="insights" mobName="landscape" />
                    <TalentInsights name="landscape" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/candidate-landscape/overview"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="insights" mobName="landscape" />
                    <TalentInsights name="overview" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
                // <AuthRoute element={<DContainer component={<Overview />} name={"landscape"} />} requiredUserType={['enterprise']} allowedUserRoles={['admin']}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/candidate-landscape/hat"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="insights" mobName="hat" />
                    <TalentInsights name="hat" />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
                // <AuthRoute element={<DContainer component={<Overview />} name={"landscape"} />} requiredUserType={['enterprise']} allowedUserRoles={['admin']}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/create-new-job"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name="recruiting" mobName="newjob" />
                    <RecruitingNav name="newjob" />
                    <NewJobContainer />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/settings"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"settings"} mobName="settings" />
                    <Settings />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/support"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"support"} mobName="support" />
                    <EnterpriseSupport />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/meeting"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"meeting"} />
                    <MeetingDetails />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/contract"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"contract"} />
                    <SendContract />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/meeting/time_and_date"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"contract"} />
                    <MeetingTime />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />
          <Route
            path={"/enterprise/dashboard/meeting/confirm"}
            element={
              <AuthRoute
                element={
                  <>
                    <EnterpriseNav name={"contract"} />
                    <MeetingConfirm />
                  </>
                }
                requiredUserType={["enterprise"]}
                allowedUserRoles={["admin"]}
              />
            }
          />

          {/* Hr routes */}
          <Route
            path={"/hr/dashboard"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name={"dashboard"} />
                    <HrPortal />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={"/hr/dashboard/jobs"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="jobs" />
                    <HrRecruitingNav name="jobs" />
                    <HrJobs />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={"/hr/dashboard/jobs"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="jobs" />
                    <HrJobs />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={"/hr/dashboard/jobs/:id"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="jobs" />
                    <HrCandidates jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={`/hr/dashboard/jobs/edit-job/:id`}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="jobs" />
                    <EditJob jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={`/hr/dashboard/jobs/edit-job/:id`}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="jobs" />
                    <EditJob jobcandidates={jobData} />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={"/hr/dashboard/offers"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="offers" />
                    <HrRecruitingNav name="offers" />
                    <HrOffersTab />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={`/hr/dashboard/inbox`}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="inbox" />
                    <ProInboxHr />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />

          <Route
            path={`/hr/dashboard/department`}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="department" mobName="departments" />
                    <DepartmentTabHr />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />
          <Route
            path={"/hr/dashboard/create-new-job"}
            element={
              <AuthRoute
                element={
                  <>
                    <HrNav name="recruiting" mobName="newjob" />
                    <NewJobContainerHr />
                  </>
                }
                requiredUserType={["member"]}
                allowedUserRoles={["hr"]}
              />
            }
          />
        </Routes>
      </MyContextProvider>
    </>
  );
}

export default App;
