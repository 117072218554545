import React from 'react'
import './style.css'

const faq = [
    {
      id: "one",
      question: "Can I connect IndexScore with my existing applicant tracking systems?",
      answer: "Yes, our flexible platform allows organizations to connect with existing ATS in minutes. We then automatically pull all your applicants and score them for you - going from hundreds of applicants to top 5-10 qualified and pre-cleared candidates helping you focus on what matters!",
    },
    {
      id: "two",
      question: "How is the IndexScore calculated?",
      answer: "We leverage over 600+ data points, including assessment, background check, reference check, certifications, and many more to ensure a comprehensive candidate view.",
    },
    {
      id: "three",
      question: "Can we post our job roles outside of IndexScore?",
      answer: "Yes, companies can post jobs within the IndexScore platform and or outside such as LinkedIn for bigger reach. And because we can integrate with your existing ATS, we can also pull all your applicants from different sources and score them for you.",
    }
  ];

const PSection2 = () => {
  return (
    <div className="ps2 main-padding">
      <div className="container-xl px-0 py-5">
        <h1 className="fm darkBlue fw-semibold fs-1">FAQ</h1>
        <p className='fm darkGrey mt-0 ps2__para'> Below are are most frequently asked questions you may have about IndexScore. If you still can’t find the answer to what you are looking for below please feel free to contact us at <span className='italic'>info@indexscore.com</span>.</p>
        <div class="accordion" id="accordion">
          {faq.map((item)=>(
            <div class="accordion-item border-bottom" key={item.id}>
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed fm text-18 darkGrey fw-semibold pt-4 pb-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${item.id}`}
                aria-expanded="false"
                aria-controls={`#${item.id}`}
              >
                <p className="mb-0 me-4">{item.question}</p>
              </button>
            </h2>
            <div
              id={item.id}
              class="accordion-collapse collapse text-16"
              aria-labelledby={`heading${item.id}`}
              data-bs-parent="#accordion"
            >
              <div class="accordion-body fm text-18 darkGrey">
                {item.answer}
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PSection2