import React from "react";
import './style.css'
import Reporting from "./Reporting";
import Demographics from "./Demographics";

const ReportContainer = () => {
  return (
    <div className="bg-lightgrey edash edash2 min-vh-100 transition-1">
      <Reporting />
      <Demographics />
    </div>
  );
};

export default ReportContainer;
