import React,{useEffect} from "react";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
import UCSection1 from "../UpdatedFrontend/CandidatePage/UCSection1.jsx";
import UCSection2 from "../UpdatedFrontend/CandidatePage/UCSection2.jsx";
import UCSection3 from "../UpdatedFrontend/CandidatePage/UCSection3.jsx";
import PSection1 from "../Launch/Professionals/PSection1";

const CandidatePage = () => {
  useEffect(() => {
    document.body.classList.add('professionals')
    return () => {
      document.body.classList.remove('professionals')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <UCSection1/>
      <UCSection2 />
      <UCSection3 />
      <PSection1 />
      <Footer />
    </>
  );
};

export default CandidatePage;
