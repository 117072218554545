import React from "react";
import "./style.css";
import { ImRadioChecked, ImRadioUnchecked } from "react-icons/im";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RxCircle } from "react-icons/rx";
import Mobile from "./Mobile";

const ProgressBarEnterprise = (props) => {
  return (
    <div className="Bar py-lg-5 d-flex justify-content-lg-end px-lg-5 main-padding">
      <div className="d-lg-none d-block w-100">
        <Mobile page={props.page} />
      </div>
      <div className="Bar__container d-lg-block d-none">
        <h6 className="Bar__container__title Get__left__content__title text-uppercase pb-0 d-lg-block d-none">
          {props.title}
        </h6>
        <h4 className="Bar__container__heading mt-5 pb-5 d-lg-block d-none">
          Sign-up progress
        </h4>

        {/* box1 */}
        <div className="Bar__container__box mt-xxl-4 mt-2">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page === "1" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" && (
              <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            <h6 className="Bar__container__box__top__title Bar__container__box__top__title-main mb-0 ms-2 mt-2">
              Confirm details
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "1"
                  ? "3px solid var(--borderGrey)"
                  : "3px solid var(--main)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Please confirm data collected and <br />
              adjust if necessary
            </p>
          </div>
        </div>
        {/* box2 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page === "1" && (
              <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
            )}
            {props.page === "2" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" && props.page !== "2" && (
              <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "2" ||
                  props.page === "3" ||
                  props.page === "4" ||
                  props.page === "5" ||
                  props.page === "6" ||
                  props.page === "7" ||
                  props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              About your organization
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "3" ||
                props.page === "4" ||
                props.page === "5" ||
                props.page === "6" ||
                props.page === "7" ||
                props.page === "8"
                  ? "3px solid var(--main)"
                  : "3px solid var(--borderGrey)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Please tell us about your industry
            </p>
          </div>
        </div>
        {/* box3 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page !== "8" &&
              props.page !== "7" &&
              props.page !== "6" &&
              props.page !== "5" &&
              props.page !== "4" &&
              props.page !== "3" && (
                <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
              )}
            {props.page === "3" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" && props.page !== "2" && props.page !== "3" && (
              <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "3" ||
                  props.page === "4" ||
                  props.page === "5" ||
                  props.page === "6" ||
                  props.page === "7" ||
                  props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              Identification
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "4" ||
                props.page === "5" ||
                props.page === "6" ||
                props.page === "7" ||
                props.page === "8"
                  ? "3px solid var(--main)"
                  : "3px solid var(--borderGrey)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Please provide your legal entity tax ID number and/or VAT
            </p>
          </div>
        </div>
        {/* box4 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page !== "8" &&
              props.page !== "7" &&
              props.page !== "6" &&
              props.page !== "5" &&
              props.page !== "4" && (
                <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
              )}
            {props.page === "4" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" &&
              props.page !== "2" &&
              props.page !== "3" &&
              props.page !== "4" && (
                <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
              )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "4" ||
                  props.page === "5" ||
                  props.page === "6" ||
                  props.page === "7" ||
                  props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              Your org culture
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "5" ||
                props.page === "6" ||
                props.page === "7" ||
                props.page === "8"
                  ? "3px solid var(--main)"
                  : "3px solid var(--borderGrey)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Candidate / Company purpose alignment
            </p>
          </div>
        </div>
        {/* box5 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page !== "8" &&
              props.page !== "7" &&
              props.page !== "6" &&
              props.page !== "5" && (
                <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
              )}
            {props.page === "5" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" &&
              props.page !== "2" &&
              props.page !== "3" &&
              props.page !== "4" &&
              props.page !== "5" && (
                <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
              )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "6" || props.page === "7" || props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              Additional insight
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "6" || props.page === "7" || props.page === "8"
                  ? "3px solid var(--main)"
                  : "3px solid var(--borderGrey)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              A short questionnaire
            </p>
          </div>
        </div>
        {/* box6 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page !== "8" && props.page !== "7" && props.page !== "6" && (
              <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
            )}
            {props.page === "6" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" &&
              props.page !== "2" &&
              props.page !== "3" &&
              props.page !== "4" &&
              props.page !== "5" &&
              props.page !== "6" && (
                <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
              )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "6" || props.page === "7" || props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              Video introduction
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{
              borderLeft:
                props.page === "7" || props.page === "8"
                  ? "3px solid var(--main)"
                  : "3px solid var(--borderGrey)",
            }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Short video for our job candidates to hear directly from you
            </p>
          </div>
        </div>
        {/* box7 */}
        <div className="Bar__container__box">
          <div className="Bar__container__box__top d-flex align-items-center">
            {props.page !== "8" && props.page !== "7" && (
              <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
            )}
            {props.page === "7" && (
              <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
            )}
            {props.page !== "1" &&
              props.page !== "2" &&
              props.page !== "3" &&
              props.page !== "4" &&
              props.page !== "5" &&
              props.page !== "6" &&
              props.page !== "7" && (
                <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
              )}
            <h6
              className="Bar__container__box__top__title Bar__container__box__top__title-grey mb-0 ms-2"
              style={{
                color:
                  props.page === "7" || props.page === "8"
                    ? "var(--main)"
                    : "var(--darkGrey)",
              }}
            >
              Submit
            </h6>
          </div>
          <div
            className="Bar__container__box__bottom"
            style={{ borderLeft: "0px solid var(--main)" }}
          >
            <p className="Bar__container__box__bottom__para ms-4">
              Complete your IndexScore application
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarEnterprise;
