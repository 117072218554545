import React, { useState, useEffect } from "react";
import General from "./General/General";
import SUsers from "./Users/SUsers";
import Notification from "./Notifications/Notification";
import ProSecurity from "./Security/ProSecurity";
import Support from "./Support/Support";

import { Tabs } from "antd";

const Settings = (props) => {
  const [general, setGeneral] = useState(true);
  const [security, setSecurity] = useState(false);
  const [user, setUser] = useState(false);
  const [notif, setNotif] = useState(false);
  const [support, setSupport] = useState(false);
  const TabPane = Tabs.TabPane;

  const func_General = () => {
    setGeneral(true);
    setSecurity(false);
    setUser(false);
    setNotif(false);
    setSupport(false);
  };
  const func_Security = () => {
    setGeneral(false);
    setSecurity(true);
    setUser(false);
    setNotif(false);
    setSupport(false);
  };
  const func_User = () => {
    setGeneral(false);
    setSecurity(false);
    setUser(true);
    setNotif(false);
    setSupport(false);
  };
  const func_Notif = () => {
    setGeneral(false);
    setSecurity(false);
    setUser(false);
    setNotif(true);
    setSupport(false);
  };
  const func_support = () => {
    setGeneral(false);
    setSecurity(false);
    setNotif(false);
    setSupport(true);
  };

  return (
    <div className="bg-lightgrey edash min-h-100">
      <div className="DAccount bg-lightgrey">
        <div className="DAccount__top d-lg-block d-none  mt-lg-0 mt-4">
          <div className="position-relative">
            <div className="DAccount__top2"></div>
          </div>
          <div className="container-dash py-2 ">
            <button
              className={`DAccount__btn me-sm-4 me-2 transition fm ${
                general ? "fw-normal" : "fw-light"
              }`}
              onClick={func_General}
            >
              General
            </button>
            <button
              className={`DAccount__btn me-sm-4 me-2 transition fm ${
                security ? "fw-normal" : "fw-light"
              }`}
              onClick={func_Security}
            >
              Security
            </button>
            <button
              className={`DAccount__btn me-sm-4 me-2 transition fm ${
                notif ? "fw-normal" : "fw-light"
              }`}
              onClick={func_Notif}
            >
              Notification
            </button>
            <button
              className={`DAccount__btn me-sm-4 me-2 transition fm ${
                support ? "fw-normal" : "fw-light"
              }`}
              onClick={func_support}
            >
              Support
            </button>
            {/* <button
              className={`DAccount__btn me-sm-4 me-2 transition fm ${
                user ? "fw-normal" : "fw-light"
              }`}
              onClick={func_User}
            >
              Users
            </button> */}
          </div>
        </div>
        <div className="d-lg-none container-dash">
          <Tabs>
            <TabPane tab="General" key="tab 1">
              <div className="TabPane">
                <General />
              </div>
            </TabPane>
            <TabPane tab="Security" key="tab 2">
              <div className="TabPane">
                <ProSecurity />
              </div>
            </TabPane>
            <TabPane tab="Users" key="tab 3">
              <div className="TabPane">
                <SUsers />
              </div>
            </TabPane>
            <TabPane tab="Support" key="tab 4">
              <div className="TabPane">
                <Support />
              </div>
            </TabPane>
            {/* <TabPane tab="Notifications" key="tab 4">
              <div className="TabPane">
              <Notification />
              </div>
            </TabPane> */}
          </Tabs>
        </div>
        <div className={`${support ? ' ' : 'container-dash'} d-lg-block d-none`}>
          {general && <General />}
          {security && <ProSecurity />}
          {/* {user && <SUsers />} */}
          {notif && <Notification />}
          {support && <Support />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
