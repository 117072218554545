import React, { useState, useRef, useEffect,useContext } from "react";
import CircularProgressBar from "./CircularProgressBar";
import { Dropdown, Menu, Modal } from "antd";
import { Country } from "../../../Utilities/Country";
import { BsFilter } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { RxEnterFullScreen } from "react-icons/rx";
import { getAllJobDetailsAndCandidateStats } from "../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MyContext } from "../../../Utilities/MyContextProvider";

const Demographics = () => {
  // const gendersData = [
  //   { label: "Female", value: 42, color: "#436C03" },
  //   { label: "Male", value: 39, color: "#A6DD50" },
  //   { label: "???", value: 8, color: "#CFFD5E" },
  //   { label: "??", value: 6, color: "#0D2159" },
  //   { label: "Other", value: 4, color: "#486FD9" },
  //   { label: "not to say", value: 1, color: "#BFD0FF" },
  // ];
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  // const genderTotalValue = 2017; //this is the max value for gender chart
  const colors = [
    "#436C03", "#A6DD50", "#CFFD5E", "#0D2159", "#486FD9", "#BFD0FF", "#2D6980", "#10BAC1", "#12D3DB", "#4A4A49",
    "#969696", "#CBCBCB", "Indigo", "Violet", "Maroon", "Olive", "Navy", "Beige",
    "Turquoise", "SlateGray", "Sienna", "Peru", "Orchid", "Gold", "Silver", "Gray", "Crimson", "Lavender",
    "Azure", "Rose", "Mint", "Lemon", "Peach", "Salmon", "SkyBlue", "Coral", "Plum", "Tomato", "Khaki",
    "Ivory", "ForestGreen", "SandyBrown", "DarkSlateGray", "PowderBlue", "RoyalBlue", "SaddleBrown", "DarkOliveGreen", "SteelBlue"
  ]
  const ageColors = [
    "#436C03", "#A6DD50", "#CFFD5E", "#0D2159", "#486FD9", "#BFD0FF", "#2D6980", "#10BAC1", "#12D3DB", "#4A4A49",
    "#969696", "#CBCBCB", "Teal", "Indigo", "Violet", "Maroon", "Olive", "Navy", "Beige",
    "Turquoise", "SlateGray", "Sienna", "Peru", "Orchid", "Gold", "Silver", "Gray", "Crimson", "Lavender",
    "Azure", "Rose", "Mint", "Lemon", "Peach", "Salmon", "SkyBlue", "Coral", "Plum", "Tomato", "Khaki",
    "Ivory", "ForestGreen", "SandyBrown", "DarkSlateGray", "PowderBlue", "RoyalBlue", "SaddleBrown", "DarkOliveGreen", "SteelBlue"
  ];
  const ethnicityColors = [
    "#436C03", "#A6DD50", "#CFFD5E", "#0D2159", "#486FD9", "#BFD0FF", "#2D6980", "#10BAC1", "#12D3DB", "#4A4A49",
    "#969696", "#CBCBCB", "Yellow", "Tomato", "Aqua", "DarkSlateGray", "SaddleBrown", "Ivory", "SlateGray", "SandyBrown"
  ];
  const educationColors = [
    "#436C03", "#A6DD50", "#CFFD5E", "#0D2159", "#486FD9", "#BFD0FF", "#2D6980", "#10BAC1", "#12D3DB", "#4A4A49",
    "#969696", "#CBCBCB", "Mint", "Lemon", "Peach", "Salmon", "SkyBlue", "Coral", "Plum", "Tomato", "Khaki",
    "Ivory", "ForestGreen", "SandyBrown", "DarkSlateGray", "PowderBlue", "RoyalBlue", "SaddleBrown", "DarkOliveGreen", "SteelBlue"
  ];



  const AgeData = [
    { label: "Group1", value: 2, color: "#436C03" },
    { label: "Group2", value: 13, color: "#A6DD50" },
    { label: "Group3", value: 22, color: "#CFFD5E" },
    { label: "Group4", value: 26, color: "#0D2159" },
    { label: "Group5", value: 32, color: "#486FD9" },
    { label: "Group6", value: 5, color: "#BFD0FF" },
  ];
  // const ageTotalValue = 2017; //this is the max value for age chart
  const nonEmptyGenders = [];
  // const colors = ['#436C03', '#A6DD50', '#CFFD5E', '#0D2159', '#486FD9', '#BFD0FF'];

  const EthnicityData = [
    { label: "Group1", value: 30, color: "#436C03" },
    { label: "Group2", value: 25, color: "#A6DD50" },
    { label: "Group3", value: 12, color: "#CFFD5E" },
    { label: "Group4", value: 6, color: "#0D2159" },
    { label: "Group5", value: 6, color: "#486FD9" },
    { label: "Group6", value: 5, color: "#BFD0FF" },
    { label: "Group7", value: 5, color: "#2D6980" },
    { label: "Group8", value: 5, color: "#10BAC1" },
    { label: "Group9", value: 5, color: "#12D3DB" },
    { label: "Group10", value: 3, color: "#4A4A49" },
    { label: "Group11", value: 2, color: "#969696" },
    { label: "Group12", value: 1, color: "#CBCBCB" },
  ];
  // const ethnicityTotalValue = 2017; //this is the max value for ethnicity chart

  const EducationData = [
    { label: "Group1", value: 6, color: "#436C03" },
    { label: "Group2", value: 25, color: "#A6DD50" },
    { label: "Group3", value: 12, color: "#CFFD5E" },
    { label: "Group4", value: 6, color: "#0D2159" },
    { label: "Group5", value: 30, color: "#486FD9" },
    { label: "Group6", value: 5, color: "#BFD0FF" },
    { label: "Group7", value: 5, color: "#2D6980" },
    { label: "Group8", value: 5, color: "#10BAC1" },
    { label: "Group9", value: 5, color: "#12D3DB" },
    { label: "Group10", value: 3, color: "#4A4A49" },
    { label: "Group11", value: 2, color: "#969696" },
    { label: "Group12", value: 1, color: "#CBCBCB" },
  ];
  // const educationTotalValue = 2017; //this is the max value for ethnicity chart

  const [dropdown0, setDropdown0] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [genderModal, setGenderModal] = useState(false);
  const [ageModal, setAgeModal] = useState(false);
  const [ethnicityModal, setEthnicityModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);

  const inputRef = useRef(null);
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gendersRecord, setGendersRecord] = useState([]);
  const [genderTotalValue, setGenderTotalValue] = useState(null);
  const [ageRecord, setAgeRecord] = useState([]);
  const [ageTotalValue, setAgeTotalValue] = useState(null);
  const [ethnicityRecord, setEthnicityRecord] = useState([]);
  const [ethnicityTotalValue, setEthnicityTotalValue] = useState(null);

  const [educationRecord, setEducationRecord] = useState([]);
  const [educationTotalValue, setEducationTotalValue] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [salaryFilter, setSalaryFilter] = useState(null);



  useEffect(() => {
    // fetchJobs();
    fetchReportData();

  }, [report, locationFilter, timeFilter, salaryFilter]);


  function areObjectsEqual(objA, objB) {
    return JSON.stringify(objA) === JSON.stringify(objB);
  }

  const fetchReportData = async () => {
    setLoading(true);
    try {
      // const enterpriseId = localStorage.getItem("enterpriseID");
      var payloadPro = {
        enterpriseID: userData?.companyID,
        job_locations: locationFilter,
        salary_range: salaryFilter,
        time_period: timeFilter
      };


      const reportData = await getAllJobDetailsAndCandidateStats(payloadPro);      
      setReport((prevReport) => {
        if (!areObjectsEqual(prevReport, reportData.data.data)) {
          return reportData.data.data;
        }
        return prevReport;
      });

      if (report) {

        const genderDataFromApi = report.gender;
        const gendersData = [];
        let totalValue = 0;
        let colorIndex = 0;
        for (const item of genderDataFromApi) {
          const label = Object.keys(item)[0];
          const value = item[label];

          if (gendersData[label]) {
            gendersData[label].value += value;
          } else {
            const color = colors[colorIndex % colors.length];
            colorIndex++;
            gendersData[label] = {
              label: label,
              value: value,
              color: color
            };
          }

          totalValue += value;
        }

        const uniqueGendersData = Object.values(gendersData);

        setGendersRecord(uniqueGendersData);
        setGenderTotalValue(totalValue);




        const ageDataFromApi = report.age;
        const agesData = [];
        let totalAgeValue = 0;
        let colorAgeIndex = 0;
        for (const item of ageDataFromApi) {
          const label = Object.keys(item)[0];
          const value = item[label];

          if (agesData[label]) {
            agesData[label].value += value;
          } else {
            const color = ageColors[colorAgeIndex % ageColors.length];
            colorAgeIndex++;
            agesData[label] = {
              label: label,
              value: value,
              color: color
            };
          }

          totalAgeValue += value;
        }

        const uniqueagesData = Object.values(agesData);


        setAgeRecord(uniqueagesData);
        setAgeTotalValue(totalAgeValue);



        const EthnicityDataFromApi = report.ethnicity;
        const EthnicitysData = [];
        let totalEthnicityValue = 0;
        let colorEthnicityIndex = 0;
        for (const item of EthnicityDataFromApi) {
          const label = Object.keys(item)[0];
          const value = item[label];

          if (EthnicitysData[label]) {
            EthnicitysData[label].value += value;
          } else {
            const color = ethnicityColors[colorEthnicityIndex % ethnicityColors.length];
            colorEthnicityIndex++;
            EthnicitysData[label] = {
              label: label,
              value: value,
              color: color
            };
          }
          totalEthnicityValue += value;
        }

        const uniqueEthnicitysData = Object.values(EthnicitysData);


        setEthnicityRecord(uniqueEthnicitysData);
        setEthnicityTotalValue(totalEthnicityValue);


        const EducationDataFromApi = report.education;
        const EducationsData = [];
        let totalEducationValue = 0;
        let colorEducationIndex = 0;
        for (const item of EducationDataFromApi) {
          const label = Object.keys(item)[0];
          const value = item[label];

          if (EducationsData[label]) {
            EducationsData[label].value += value;
          } else {
            const color = educationColors[colorEducationIndex % educationColors.length];
            colorEducationIndex++;
            EducationsData[label] = {
              label: label,
              value: value,
              color: color
            };
          }
          totalEducationValue += value;
        }

        const uniqueEducationsData = Object.values(EducationsData);


        setEducationRecord(uniqueEducationsData);
        setEducationTotalValue(totalEducationValue);

      }


      setLoading(false);      
      return reportData.data.data; // Assuming the data returned by the API is the candidate object
    } catch (error) {
      console.log(`Error fetching Report`, error);
      setLoading(false);
      return null; // Or handle the error as per your requirements
    }

  };

  const applyTimeFilter = (filterValue) => {

    setTimeFilter(filterValue);
    fetchReportData();
  }

  const applyLocationFilter = async (filterValue) => {
    setLocationFilter(filterValue);
    fetchReportData();
  }


  const applySalaryFilter = async (filterValue) => {
    setSalaryFilter(filterValue);
    fetchReportData();
  }

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  const handleDropdown0 = (visible) => {
    setDropdown0(visible);
  };
  const handleDropdown1 = (visible) => {
    setDropdown1(visible);
  };
  const handleDropdown2 = (visible) => {
    setDropdown2(visible);
  };
  const handleDropdown3 = (visible) => {
    setDropdown3(visible);
  };
  const handleDropdown4 = (visible) => {
    setDropdown4(visible);
  };

  const getPopupContainer = (trigger) => trigger.parentNode;

  // const MenuView = (
  //   <Menu getPopupContainer={getPopupContainer}>
  //     <Menu.Item key="1">
  //       <p className="fm darkGrey mb-0">General Overview</p>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <p className="fm darkGrey mb-0">Experienced</p>
  //     </Menu.Item>
  //     <Menu.Item key="3">
  //       <p className="fm darkGrey mb-0 border-top pt-1">All</p>
  //     </Menu.Item>
  //   </Menu>
  // );
  const Menu1 = (
    <Menu getPopupContainer={getPopupContainer}>
      <Menu.Item key="1" onClick={() => { applyTimeFilter('This month') }}>
        <p className="fm darkGrey mb-0">This month</p>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => { applyTimeFilter('This year') }}>
        <p className="fm darkGrey mb-0">This year</p>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => { applyTimeFilter('Past 2 years') }}>
        <p className="fm darkGrey mb-0">Past 2 years</p>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => { applyTimeFilter(null) }}>
        <p className="fm darkGrey mb-0 border-top pt-1">All Time</p>
      </Menu.Item>
    </Menu>
  );
  const Menu2 = (
    <Menu getPopupContainer={getPopupContainer}>
      <Menu.Item key="1">
        <div className="">
          <div className="d-flex flex-column overflow-hidden transition">
            <input
              type="search"
              placeholder="UK, London…"
              className="fm dropdown--input text-15 darkGrey"
              ref={inputRef}
              onChange={handleInputChange}
              value={searchTerm}
            />
            <ul
              className="m-0 list-unstyled dropdown__list mt-2 pe-2"
              onClick={() => {
                setDlocation(!dlocation);
              }}
            >
              {filteredCountries.map((item) => (
                <li
                  key={item.code}
                  onClick={() => {
                    setDlocation(false);
                    applyLocationFilter(item.code)
                  }}
                  className="text-15 mb-1 pointer country__name dropdown__list__item darkGrey"
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        applyLocationFilter(null)
      }}>
        <p className="fm darkGrey mb-0 border-top pt-1">All</p>
      </Menu.Item>
    </Menu>
  );
  const Menu3 = (
    <Menu getPopupContainer={getPopupContainer}>
      <Menu.Item key="1">
        <p className="fm darkGrey mb-0">Junior</p>
      </Menu.Item>
      <Menu.Item key="2">
        <p className="fm darkGrey mb-0">Intermediate</p>
      </Menu.Item>
      {/* <Menu.Item key="3">
        <p className="fm darkGrey mb-0">Senior</p>
      </Menu.Item> */}
      <Menu.Item key="4">
        <p className="fm darkGrey mb-0 border-top pt-1">All</p>
      </Menu.Item>
    </Menu>
  );
  const Menu4 = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        applySalaryFilter('1000-5000')
      }}>
        <p className="fm darkGrey mb-0">1000$ - 5000$</p>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        applySalaryFilter('5000-10000')
      }}>
        <p className="fm darkGrey mb-0">5000$ - 10,000$</p>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => {
        applySalaryFilter('10000-15000')
      }}>
        <p className="fm darkGrey mb-0">10,000$ - 15,000$</p>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => {
        applySalaryFilter(null)
      }}>
        <p className="fm darkGrey mb-0 border-top pt-1">All</p>
      </Menu.Item>
    </Menu>
  );

  const showModal1 = () => {
    setGenderModal(true);
  };
  const showModal2 = () => {
    setAgeModal(true);
  };
  const showModal3 = () => {
    setEthnicityModal(true);
  };
  const showModal4 = () => {
    setEducationModal(true);
  };


  return (
    <>
      
        <div className="container-dash">
          <div className="bg-lightgrey mb-0 overflow-hidden">
            <div className="Experts__container bg-lightgrey fs-2 stats_container">
              <h1 className="text-35 fm darkBlue border-bottom-dotted">
                Demographics
              </h1>

              {/* dropdowns */}
              <div className="py-3 d-md-flex d-block justify-content-between">
                <div className="d-flex mb-2">
                  {/* <Dropdown
                overlay={MenuView}
                placement="bottomLeft"
                overlayClassName="filters__overlay"
                onVisibleChange={handleDropdown0}
                open={dropdown0}
              >
                <button className="repost__btn filter__btn fm ps-2 pe-3 fw-light d-flex justify-content-center align-items-center nowrap fs-6">
                  <BsFilter className="fs-3 candidates__filter__icon me-1" />{" "}
                  Views
                </button>
              </Dropdown> */}
                  {/* <button className="repost__btn filter__btn fm px-3 fw-light d-flex justify-content-center align-items-center nowrap fs-6 ms-2">
                    <img
                      src="/assets/images/icon_export-white.svg"
                      alt=""
                      className="img-fluid candidates__button--img me-1"
                    />
                    Export
                  </button> */}
                </div>

                <div className="d-flex mb-2 flex-sm-nowrap flex-wrap">
                  <Dropdown
                    overlay={Menu1}
                    placement="bottomLeft"
                    overlayClassName="filters__overlay"
                    onVisibleChange={handleDropdown1}
                    open={dropdown1}
                  >
                    <button
                      className="candidates__filter filter__btn mb-2 fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                      style={{
                        color: dropdown1 ? "#5F89FF" : "",
                        borderColor: dropdown1 ? "#5F89FF" : "",
                      }}
                    >
                      <BsFilter className="fs-4 candidates__filter__icon me-1" />
                      Time
                    </button>
                  </Dropdown>
                  <Dropdown
                    overlay={Menu2}
                    placement="bottomLeft"
                    overlayClassName="filters__overlay location__overlay"
                    onVisibleChange={handleDropdown2}
                    open={dropdown2}
                  >
                    <button
                      className="candidates__filter filter__btn mb-2 fm px-2 d-flex text-14 align-items-center fw-500 transition me-sm-2"
                      style={{
                        color: dropdown2 ? "#5F89FF" : "",
                        borderColor: dropdown2 ? "#5F89FF" : "",
                      }}
                    >
                      <BsFilter className="fs-4 candidates__filter__icon me-1" />
                      Location
                    </button>
                  </Dropdown>
                  {/* <Dropdown
                overlay={Menu3}
                placement="bottomLeft"
                overlayClassName="filters__overlay"
                onVisibleChange={handleDropdown3}
                open={dropdown3}
              >
                <button
                  className="candidates__filter filter__btn mb-2 fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                  style={{
                    color: dropdown3 ? "#5F89FF" : "",
                    borderColor: dropdown3 ? "#5F89FF" : "",
                  }}
                >
                  <BsFilter className="fs-4 candidates__filter__icon me-1" />
                  Seniority
                </button>
              </Dropdown> */}

                  <Dropdown
                    overlay={Menu4}
                    placement="bottomLeft"
                    overlayClassName="filters__overlay"
                    onVisibleChange={handleDropdown4}
                    open={dropdown4}
                  >
                    <button
                      className="candidates__filter filter__btn mb-2 fm px-2 d-flex text-14 align-items-center fw-500 transition"
                      style={{
                        color: dropdown4 ? "#5F89FF" : "",
                        borderColor: dropdown4 ? "#5F89FF" : "",
                      }}
                    >
                      <BsFilter className="fs-4 candidates__filter__icon me-1" />
                      Salary
                    </button>
                  </Dropdown>
                </div>
              </div>

              {/* selected items */}
              <div className="mb-4">
                {timeFilter && (
                  <p className="fm darkGrey text-14 mb-0">
                    Time: <span className="fm midGrey">{timeFilter}</span>
                    <MdCancel
                      onClick={() => {
                        applyTimeFilter(null);
                      }}
                      className="pointer cancel__icon ms-1" />
                  </p>
                )}
                {locationFilter && (
                  <p className="fm darkGrey text-14 mb-0">
                    Location: <span className="fm midGrey">{locationFilter}</span>
                    <MdCancel onClick={() => {
                      applyLocationFilter(null);
                    }} className="pointer cancel__icon ms-1" />
                  </p>
                )}

                {salaryFilter && (
                  <p className="fm darkGrey text-14 mb-0">
                    Salary: <span className="fm midGrey">{salaryFilter}</span>
                    <MdCancel onClick={() => {
                      applySalaryFilter(null);
                    }}
                      className="pointer cancel__icon ms-1" />
                  </p>
                )}
              </div>

              <div className="row">

                {/* progressbar1 */}
                <div className="col-xxl-3 col-md-4 col-sm-6 mb-3">
                  <div className="stat bg-white px-xxl-4 px-3 py-4 h-100">
                    <div className="stat__top d-flex justify-content-between align-items-center mb-3">
                      <h3 className="fm darkGrey text-uppercase text-14 mb-0">
                        Gender
                      </h3>
                      <RxEnterFullScreen
                        className="fs-4 pointer"
                        onClick={showModal1}
                      />
                    </div>
                    <CircularProgressBar
                      segments={gendersRecord}
                      maxValue={genderTotalValue}
                    />

                    {loading ? <Loader /> : <div className="d-flex">
                      <div className="mt-3 chartslice">
                        {gendersRecord.slice(0, 6).map((item) => (
                          <div
                            className="d-flex mb-2 align-items-center"
                            key={item.value}
                          >
                            <div
                              className="bullet me-1 rounded-circle"
                              style={{ background: item.color }}
                            ></div>
                            <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                              <span className="nowrap">{item.label}</span>{" "}
                              <span className="midGrey nowrap ps-1">
                                {item.value}
                                {/* % /{" "}
                                {((item.value * genderTotalValue) / 100).toFixed(0)} */}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                      {gendersRecord.length > 6 && (
                        <div className="mt-3 chartslice">
                          {gendersRecord.slice(6, 13).map((item) => (
                            <div
                              className="d-flex mb-2 align-items-center"
                              key={item.value}
                            >
                              <div
                                className="bullet me-1 rounded-circle"
                                style={{ background: item.color }}
                              ></div>
                              <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                <span className="nowrap">{item.label}</span>{" "}
                                <span className="midGrey nowrap ps-1">
                                  {item.value}
                                  {/* % /{" "}
                                  {((item.value * genderTotalValue) / 100).toFixed(0)} */}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    }
                  </div>
                </div>


                {/* progressbar2 */}
                <div className="col-xxl-3 col-md-4 col-sm-6 mb-3">
                  <div className="stat bg-white px-xxl-4 px-3 py-4 h-100">
                    <div className="stat__top d-flex justify-content-between align-items-center mb-3">
                      <h3 className="fm darkGrey text-uppercase text-14 mb-0">
                        Age
                      </h3>
                      <RxEnterFullScreen
                        className="fs-4 pointer"
                        onClick={showModal2}
                      />
                    </div>
                    <CircularProgressBar
                      segments={ageRecord}
                      maxValue={ageTotalValue}
                    />

                    <div className="d-flex">
                      <div className="mt-3 chartslice">
                        {ageRecord.slice(0, 6).map((item) => (
                          <div
                            className="d-flex mb-2 align-items-center"
                            key={item.value}
                          >
                            <div
                              className="bullet me-1 rounded-circle"
                              style={{ background: item.color }}
                            ></div>
                            <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                              <span className="nowrap">{item.label}</span>{" "}
                              <span className="midGrey nowrap ps-1">
                                {item.value}
                                {/* % /{" "}
                                {((item.value * ageTotalValue) / 100).toFixed(0)} */}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                      {ageRecord.length > 6 && (
                        <div className="mt-3 chartslice">
                          {ageRecord.slice(6, 13).map((item) => (
                            <div
                              className="d-flex mb-2 align-items-center"
                              key={item.value}
                            >
                              <div
                                className="bullet me-1 rounded-circle"
                                style={{ background: item.color }}
                              ></div>
                              <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                <span className="nowrap">{item.label}</span>{" "}
                                <span className="midGrey nowrap ps-1">
                                  {item.value}
                                  {/* % /{" "}
                                  {((item.value * ageTotalValue) / 100).toFixed(0)} */}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* progressbar3 */}
                <div className="col-xxl-3 col-md-4 col-sm-6 mb-3">
                  <div className="stat bg-white px-xxl-4 px-3 py-4 h-100">
                    <div className="stat__top d-flex justify-content-between align-items-center mb-3">
                      <h3 className="fm darkGrey text-uppercase text-14 mb-0">
                        Ethnicity
                      </h3>
                      <RxEnterFullScreen
                        className="fs-4 pointer"
                        onClick={showModal3}
                      />
                    </div>
                    <CircularProgressBar
                      segments={ethnicityRecord}
                      maxValue={ethnicityTotalValue}
                    />

                    <div className="d-flex">
                      <div className="mt-3 chartslice">
                        {ethnicityRecord.slice(0, 6).map((item) => (
                          <div
                            className="d-flex mb-2 align-items-center"
                            key={item.value}
                          >
                            <div
                              className="bullet me-1 rounded-circle"
                              style={{ background: item.color }}
                            ></div>
                            <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                              <span className="nowrap">{item.label}</span>{" "}
                              <span className="midGrey nowrap ps-1">
                                {item.value}
                                {/* % /{" "}
                                {((item.value * ethnicityTotalValue) / 100).toFixed(0)} */}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                      {ethnicityRecord.length > 6 && (
                        <div className="mt-3 chartslice">
                          {ethnicityRecord.slice(6, 13).map((item) => (
                            <div
                              className="d-flex mb-2 align-items-center"
                              key={item.value}
                            >
                              <div
                                className="bullet me-1 rounded-circle"
                                style={{ background: item.color }}
                              ></div>
                              <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                <span className="nowrap">{item.label}</span>{" "}
                                <span className="midGrey nowrap ps-1">
                                  {item.value}
                                  {/* % /{" "}
                                  {((item.value * ethnicityTotalValue) / 100).toFixed(0)} */}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* progressbar4 */}
                <div className="col-xxl-3 col-md-4 col-sm-6 mb-3">
                  <div className="stat bg-white px-xxl-4 px-3 py-4 h-100">
                    <div className="stat__top d-flex justify-content-between align-items-center mb-3">
                      <h3 className="fm darkGrey text-uppercase text-14 mb-0">
                        Education
                      </h3>
                      <RxEnterFullScreen
                        className="fs-4 pointer"
                        onClick={showModal4}
                      />
                    </div>
                    <CircularProgressBar
                      segments={educationRecord}
                      maxValue={educationTotalValue}
                    />

                    <div className="d-flex">
                      <div className="mt-3 chartslice">
                        {educationRecord.slice(0, 6).map((item) => (
                          <div
                            className="d-flex mb-2 align-items-center"
                            key={item.value}
                          >
                            <div
                              className="bullet me-1 rounded-circle"
                              style={{ background: item.color }}
                            ></div>
                            <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                              <span className="nowrap">{item.label}</span>{" "}
                              <span className="midGrey nowrap ps-1">
                                {item.value}
                                {/* % /{" "}
                                {((item.value * educationTotalValue) / 100).toFixed(0)} */}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                      {educationRecord.length > 6 && (
                        <div className="mt-3 chartslice">
                          {educationRecord.slice(6, 13).map((item) => (
                            <div
                              className="d-flex mb-2 align-items-center"
                              key={item.value}
                            >
                              <div
                                className="bullet me-1 rounded-circle"
                                style={{ background: item.color }}
                              ></div>
                              <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                <span className="nowrap">{item.label}</span>{" "}
                                <span className="midGrey nowrap ps-1">
                                  {item.value}
                                  {/* % /{" "}
                                  {((item.value * educationTotalValue) / 100).toFixed(0)} */}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>      

      {/* modal1 */}
      <Modal
        title="GENDER"
        open={genderModal}
        centered
        zIndex={20000}
        onCancel={() => setGenderModal(false)}
        className="stats__modal"
        footer={null}
      >
        <div className="stat bg-white pt-4">
          <CircularProgressBar segments={gendersRecord} maxValue={genderTotalValue} />

          <div className="d-flex">
            <div className="mt-3 chartslice">
              {gendersRecord.slice(0, 6).map((item) => (
                <div
                  className="d-flex mb-2 align-items-center"
                  key={item.value}
                >
                  <div
                    className="bullet me-1 rounded-circle"
                    style={{ background: item.color }}
                  ></div>
                  <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                    <span className="nowrap">{item.label}</span>{" "}
                    <span className="midGrey nowrap ps-1">
                      {item.value}% /{" "}
                      {((item.value * genderTotalValue) / 100).toFixed(0)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
            {gendersRecord.length > 6 && (
              <div className="mt-3 chartslice">
                {gendersRecord.slice(6, 13).map((item) => (
                  <div
                    className="d-flex mb-2 align-items-center"
                    key={item.value}
                  >
                    <div
                      className="bullet me-1 rounded-circle"
                      style={{ background: item.color }}
                    ></div>
                    <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                      <span className="nowrap">{item.label}</span>{" "}
                      <span className="midGrey nowrap ps-1">
                        {item.value}% /{" "}
                        {((item.value * genderTotalValue) / 100).toFixed(0)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* modal2 */}
      <Modal
        title="AGE"
        open={ageModal}
        centered
        zIndex={20000}
        onCancel={() => setAgeModal(false)}
        className="stats__modal"
        footer={null}
      >
        <div className="stat bg-white pt-4">
          <CircularProgressBar segments={ageRecord} maxValue={ageTotalValue} />

          <div className="d-flex">
            <div className="mt-3 chartslice">
              {ageRecord.slice(0, 6).map((item) => (
                <div
                  className="d-flex mb-2 align-items-center"
                  key={item.value}
                >
                  <div
                    className="bullet me-1 rounded-circle"
                    style={{ background: item.color }}
                  ></div>
                  <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                    <span className="nowrap">{item.label}</span>{" "}
                    <span className="midGrey nowrap ps-1">
                      {item.value}% /{" "}
                      {((item.value * ageTotalValue) / 100).toFixed(0)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
            {ageRecord.length > 6 && (
              <div className="mt-3 chartslice">
                {ageRecord.slice(6, 13).map((item) => (
                  <div
                    className="d-flex mb-2 align-items-center"
                    key={item.value}
                  >
                    <div
                      className="bullet me-1 rounded-circle"
                      style={{ background: item.color }}
                    ></div>
                    <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                      <span className="nowrap">{item.label}</span>{" "}
                      <span className="midGrey nowrap ps-1">
                        {item.value}% /{" "}
                        {((item.value * ageTotalValue) / 100).toFixed(0)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>

      {/* modal3 */}
      <Modal
        title="ETHNICITY"
        open={ethnicityModal}
        centered
        zIndex={20000}
        onCancel={() => setEthnicityModal(false)}
        className="stats__modal"
        footer={null}
      >
        <div className="stat bg-white pt-4">
          <CircularProgressBar segments={ethnicityRecord} maxValue={ethnicityTotalValue} />

          <div className="d-flex">
            <div className="mt-3 chartslice">
              {ethnicityRecord.slice(0, 6).map((item) => (
                <div
                  className="d-flex mb-2 align-items-center"
                  key={item.value}
                >
                  <div
                    className="bullet me-1 rounded-circle"
                    style={{ background: item.color }}
                  ></div>
                  <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                    <span className="nowrap">{item.label}</span>{" "}
                    <span className="midGrey nowrap ps-1">
                      {item.value}% /{" "}
                      {((item.value * ethnicityTotalValue) / 100).toFixed(0)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
            {ethnicityRecord.length > 6 && (
              <div className="mt-3 chartslice">
                {ethnicityRecord.slice(7, 13).map((item) => (
                  <div
                    className="d-flex mb-2 align-items-center"
                    key={item.value}
                  >
                    <div
                      className="bullet me-1 rounded-circle"
                      style={{ background: item.color }}
                    ></div>
                    <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                      <span className="nowrap">{item.label}</span>{" "}
                      <span className="midGrey nowrap ps-1">
                        {item.value}% /{" "}
                        {((item.value * ethnicityTotalValue) / 100).toFixed(0)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>


      {/* modal4 */}
      <Modal
        title="EDUCATION"
        open={educationModal}
        centered
        zIndex={20000}
        onCancel={() => setEducationModal(false)}
        className="stats__modal"
        footer={null}
      >
        <div className="stat bg-white pt-4">
          <CircularProgressBar segments={educationRecord} maxValue={educationTotalValue} />

          <div className="d-flex">
            <div className="mt-3 chartslice">
              {educationRecord.slice(0, 6).map((item) => (
                <div
                  className="d-flex mb-2 align-items-center"
                  key={item.value}
                >
                  <div
                    className="bullet me-1 rounded-circle"
                    style={{ background: item.color }}
                  ></div>
                  <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                    <span className="nowrap">{item.label}</span>{" "}
                    <span className="midGrey nowrap ps-1">
                      {item.value}% /{" "}
                      {((item.value * educationTotalValue) / 100).toFixed(0)}
                    </span>
                  </p>
                </div>
              ))}
            </div>
            {educationRecord.length > 6 && (
              <div className="mt-3 chartslice">
                {educationRecord.slice(6, 13).map((item) => (
                  <div
                    className="d-flex mb-2 align-items-center"
                    key={item.value}
                  >
                    <div
                      className="bullet me-1 rounded-circle"
                      style={{ background: item.color }}
                    ></div>
                    <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                      <span className="nowrap">{item.label}</span>{" "}
                      <span className="midGrey nowrap ps-1">
                        {item.value}% /{" "}
                        {((item.value * educationTotalValue) / 100).toFixed(0)}
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Demographics;
