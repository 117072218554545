import React, { useState, useRef, useEffect } from "react";
// import "./style.css";
import { RxCross2 } from "react-icons/rx";

import { FiShare, FiHelpCircle } from "react-icons/fi";
import { BsTrash, BsEyeSlash, BsDownload, BsFlag } from "react-icons/bs";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getInterviews, declineInterview } from "../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { TiArrowUnsorted } from "react-icons/ti";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { CiSearch } from "react-icons/ci";
import Badge from "@mui/material/Badge";
import { RiStarSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Dropdown, Button, Menu } from "antd";
import { PiDotsThreeCircleThin } from "react-icons/pi";

var contracts = [];
var invitations = [];

const ProInterviews = () => {
  const [contractsInvite, setContractsInvite] = useState([]);
  const [offeredContracts, setOfferedContracts] = useState([]);
  const [acceptedContracts, setAcceptedContracts] = useState([]);

  const [desc, setDesc] = useState(false);
  const [currentJob, setCurrentJob] = useState(false);
  const [apply, setApply] = useState(false);
  const [applied, setApplied] = useState(false);

  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [filterData, setFilterData] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [interviewsInvite, setInterviewsInvite] = useState([]);
  const [acceptedInterviewsInvite, setAcceptedInterviewsInvite] = useState([]);
  const [declineInterviewsInvite, setDeclineInterviewsInvite] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [secondSearchText, setSecondSearchText] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;

  const navigate = useNavigate();
  const [selectedContract, setSelectedContract] = useState(null);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const fetchInterviews = async () => {
    handleChange("loading2", true);
    const payload = {
      candidateID: localStorage.getItem("enterpriseID"),
    };
    try {
      const response = await getInterviews(payload);
      const data = response.data.data;
      console.log("data",data);

      setInterviewsInvite(
        data
          .filter(
            (job) =>
              job.status !== "accepted" &&
              job.status !== "done" &&
              job.status !== "declined"
          )
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            companyName: job.company_name.toUpperCase(),
            jobTitle: job.job_title.toUpperCase(),
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            role_overview: job.job_role_text,            
            date_start: job.interview_start_Date,
            date_end: job.interview_end_Date,
            locations: job.job_location,
            status: job.status,
            zoomLink: job?.zoom_meeting?.start_url,
            applicationID: job.applicationID,
          }))
      );

      setAcceptedInterviewsInvite(
        data
          .filter((job) => job.status === "accepted" || job.status === "done")
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            companyName: job.company_name.toUpperCase(),
            jobTitle: job.job_title.toUpperCase(),
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            role_overview: job.job_role_text,
            date_start: job.interview_start_Date,
            date_end: job.interview_end_Date,
            locations: job.job_location,
            status: job.status,
            zoomLink: job?.zoom_meeting?.start_url,
            applicationID: job.applicationID,
          }))
      );

      setDeclineInterviewsInvite(
        data
          .filter((job) => job.status === "declined")
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            companyName: job.company_name.toUpperCase(),
            jobTitle: job.job_title.toUpperCase(),
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            role_overview: job.job_role_text,
            date_start: job.interview_start_Date,
            date_end: job.interview_end_Date,
            locations: job.job_location,
            status: job.status,
            zoomLink: job?.zoom_meeting?.start_url,
            applicationID: job.applicationID,
          }))
      );

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchInterviews();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const handleDecline = async (interviewId, appId) => {
    handleChange("loading", true);
    const payload = {
      _id: interviewId,
      applicationID: appId,
    };

    const res = await declineInterview(payload);
    if (res.data.statusCode == 1) {
      handleChange("loading", false);
      enqueueSnackbar(`Interview decline `, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      fetchInterviews();
    }
  };

  return (
    <>
      <div className="edash">
        <div className="pt-4">
          <div className="">
            <style>
              {`
          .list-of-contracts a {
            text-decoration: none;
            color: inherit;
        }
        .list-of-contracts h5{
          font-weight:500;
        }
        
        .list-of-contracts {
            padding: 15px;
            border-top: 1px solid #4a4a4971;
            border-radius: 0;
        }
        
        .list-of-contracts strong {
            font-weight: 600;
        }
        
        .list-of-contracts p {
            margin-bottom: 0px;
            margin-top:0px;
        }

        .list-of-contracts .text-right p{
          float:right;
        }
        
        
        .list-of-contracts:hover {
            background-color: rgb(242, 247, 242);
        }
        
        .list-of-contracts:first-child {
            border-top: none;
        }

        .Pjobs__tabs .react-tabs__tab--selected, .Pjobs__tabs .react-tabs__tab--selected:focus {
          border-bottom: 0.5px solid #41414080 !important;
        }

        .contracts-top-heading{
          margin-left:40px;
        }

        .invite-job{
          font-weight:600;
          color:#14a800;
        }

        .invitation-button {width: 200px;margin-bottom: 10px;padding: 12px;line-height: 1;border-radius: 25px;color: #fff;font-size: 16px;font-weight: 500;border: 2px;float:right}
        .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      .invite-job img {
        width: 40px;
        margin-right: 20px;
        transform: rotate(-90deg);
    }
        `}
            </style>
            <div className="jobs__content pb-5">
              <div className="">
                <div className="fm">
                  <div className=" fm">
                    <h3 className="text-35 darkBlue">
                      Upcoming{" "}
                    </h3>
                    <p className="fm darkGrey pb-2 text-14">
                      Interviews coming soon, if you need to reschedule please
                      click the more info button
                    </p>
                  </div>
                  <div className="row g-3 dashboard__interview__list justify-content-start">
                    {interviewsInvite &&
                      interviewsInvite.map((item) => (
                        <div className="col-md-6 col-xl-4" key={item.key}>
                          <div className="dashboard__profile  bg-white radius8 p-2">
                            <div className="d-flex">
                              <div className={`expiring__body_detail_image rounded-circle d-flex justify-content-center align-items-center ${item.enterpriseLogo ? 'company__image' : ''}`}>
                                <img
                                  src={item.enterpriseLogo ? item.enterpriseLogo:  `/assets/images/expiring_icon.svg`}
                                  alt=""
                                  className="me-0"
                                />
                              </div>
                              <div className="w-100">
                                <h6 className="fm darkGrey mb-0 fs-6 fw-semibold mt-2">
                                  {capitalizeWords(item.jobTitle)}
                                </h6>
                                <p className="fm darkGrey mb-0 text-14">
                                  {capitalizeWords(item.companyName)}
                                </p>

                                <div className="w-100 mt-3 gap-3">
                                  <div className=" mb-3">
                                    <p className="fm grey text-12 mb-0">
                                      Proposed Date/Time
                                    </p>
                                    <p className="fm darkGrey text-14 my-0 text-capitalize">
                                      {moment(item.date_start).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}{" "}
                                      – <br />
                                      {moment(item.date_end).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}
                                    </p>
                                  </div>

                                  {/* <div className="pe-3 mb-2">
                                      <p className="fm grey text-12 mb-0">
                                        Location
                                      </p>
                                      <p className="fm darkGrey text-14 my-0">
                                        Google Meet
                                      </p>
                                      <p
                                        className="fm text-14 my-0 grey"
                                        onClick={() => {
                                          navigate(
                                            `/candidate/dashboard/jobs/interview/${item.key}`
                                          );
                                        }}
                                        role="button"
                                      >
                                        meet.google.com/bao-bjuh-ngg
                                      </p>
                                    </div> */}
                                </div>
                              </div>
                              {/* <Dropdown
                                  placement="top"
                                  arrow={{ pointAtCenter: true }}
                                  overlay={
                                    <Menu>
                                      <Menu.Item
                                        key="delete"
                                        className="text-decoration-none fm text-12"
                                      >
                                        Reschedule
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  trigger={["click"]}
                                >
                                  <Button className="candidates__menu ms-auto p-0">
                                    <PiDotsThreeCircleThin className="text-decoration-none fs-4" />
                                  </Button>
                                </Dropdown> */}
                            </div>

                            <div className="d-flex gap-2 mt-3">
                              <button
                                className="btn--main text-14 fm radius8 interview__btn"
                                onClick={() => {
                                  navigate(
                                    `/candidate/dashboard/jobs/interview/${item.key}`
                                  );
                                }}
                              >
                                View
                              </button>
                              <button
                                onClick={() => {
                                  handleDecline(item.key, item.applicationID);
                                }}
                                className="text-14 fm btn-red transition w-auto radius8 px-3 ms-auto"
                              >
                                Decline
                              </button>
                              {/* <div className="d-flex justify-content-between w-100">
                              <button className="text-14 fm interview__resch transition fw-semibold radius8 px-xxl-2 px-0">Reschedule?</button>                              
                              </div> */}
                            </div>
                            {/* <button
                                className="desc__applynow py-2 fm transition border-white  application-sent fm radius8 w-100 max-w-none"
                                disabled
                              >
                                Available in 4 days
                              </button> */}
                          </div>
                        </div>
                      ))}

                    {/* <div className="dashboard__interviews col3">
                        
                        <div className="dashboard__interviews__detail">
                          <h6>Senior Product Designer</h6>
                          <p>Meta Co</p>
                          <div className="dashboard__interviews__detail_bottom">
                            <div>
                              <span>Proposed Date/Time</span>
                              <p>16th Dec @ 4:30pm</p>
                            </div>
                            <div>
                              <span>Location</span>
                              <p>Google Meet</p>
                              <Link to={'#'}>meet.google.com/bao-bjuh-ngg</Link>
                            </div>
                          </div>
                        </div>
                        <div className="dashboard__interviews__menu">
                          <img
                            src={`/assets/images/Dashboard/settings_more_unselected.svg`}
                            alt=""
                            className="img-fluid candidates__icon2"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <button
                                class="dropdown-item"

                                type="button"
                              >
                                <BsTrash />
                                Reject
                              </button>
                            </li>
                            <li>
                              <button
                                class="dropdown-item"

                                type="button"
                              >
                                <BsEyeSlash />
                                Remove
                              </button>
                            </li>
                            <li>
                              <button
                                class="dropdown-item"

                                type="button"
                              >
                                <BsDownload />
                                Download full report
                              </button>
                            </li>
                            <li>
                              <button
                                class="dropdown-item"

                                type="button"
                              >
                                <BsFlag />
                                Report
                              </button>
                            </li>
                            <li className="last-menu">
                              <button
                                class="dropdown-item"
                                type="button"
                              >
                                <FiHelpCircle />
                                Support
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                  </div>
                </div>
                <div className="mt-5">
                  <h3 className="text-35 darkBlue">
                    Interviews
                  </h3>
                  {acceptedInterviewsInvite &&
                  acceptedInterviewsInvite.length < 1 ? (
                    <p className="fm darkGrey pb-2 text-14">
                      No Interview request yet, we’ll be in contact as soon a
                      you receive one
                    </p>
                  ) : (
                    <p className="fm darkGrey pb-2 text-14">
                      You’ve been invited to take part in a interview!
                    </p>
                  )}

                  <div className="row g-3 dashboard__interview__list justify-content-start">
                    {acceptedInterviewsInvite &&
                      acceptedInterviewsInvite.map((item) => (
                        <div className="col-md-6 col-xl-4" key={item.key}>
                          <div className="dashboard__profile  bg-white radius8 p-2">
                            <div className="d-flex">
                              <div className={`expiring__body_detail_image rounded-circle d-flex justify-content-center align-items-center ${item.enterpriseLogo ? 'company__image' : ''}`}>
                                <img
                                  src={item.enterpriseLogo  ? item.enterpriseLogo :  `/assets/images/expiring_icon.svg`}
                                  alt=""
                                  className="me-0"
                                />
                              </div>
                              <div className="w-100">
                                <h6 className="fm darkGrey mb-0 fs-6 fw-semibold mt-2">
                                  {capitalizeWords(item.jobTitle)}
                                </h6>
                                <p className="fm darkGrey mb-0 text-14">
                                  {capitalizeWords(item.companyName)}
                                </p>

                                <div className="w-100 justify-content-between mt-3 gap-3">
                                  <div className=" mb-3">
                                    <p className="fm grey text-12 mb-0">
                                      Proposed Date/Time
                                    </p>
                                    <p className="fm darkGrey text-14 my-0 text-capitalize">
                                      {moment(item.date_start).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}{" "}
                                      – <br />
                                      {moment(item.date_end).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}
                                    </p>
                                  </div>
                                  {item?.zoomLink && (
                                    <div className="pe-3 grey mb-2">
                                      <p className="fm  text-14 my-0">
                                        Meeting Link
                                      </p>
                                      <a
                                        className="fm text-14 darkGrey text-decoration-none my-0 "
                                        href={item?.zoomLink}
                                        target={"_blank"}
                                      >
                                        Link
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {/* <RxCross2 className="pointer fs-5 cross__icon transition" /> */}
                            </div>
                            <button
                              className="btn--main fm w-100 radius8 mt-3 text-14"
                              onClick={() => {
                                navigate(
                                  `/candidate/dashboard/jobs/interview/${item.key}`
                                );
                              }}
                            >
                              Join
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="mt-5">
                  <div className=" fm">
                    <h3 className="text-35 darkBlue">
                      Decline{" "}
                    </h3>
                    <p className="fm darkGrey pb-2 text-14">
                      Declined interviews
                    </p>
                  </div>
                  <div className="row g-3 dashboard__interview__list justify-content-start">
                    {declineInterviewsInvite &&
                      declineInterviewsInvite.map((item) => (
                        <div className="col-md-6 col-xl-4" key={item.key}>
                          <div className="dashboard__profile  bg-white radius8 p-2">
                            <div className="d-flex">
                              <div className={`expiring__body_detail_image bg-red rounded-circle d-flex justify-content-center align-items-center ${item.enterpriseLogo && item.enterpriseLogo ? 'company__image' : ''}`}>
                                <img
                                  src={item.enterpriseLogo  ? item.enterpriseLogo :  `/assets/images/expiring_icon.svg`}
                                  alt=""
                                  className="me-0"
                                />
                              </div>
                              <div className="w-100">
                                <h6 className="fm darkGrey mb-0 fs-6 fw-semibold mt-2">
                                  {capitalizeWords(item.jobTitle)}
                                </h6>
                                <p className="fm darkGrey mb-0 text-14">
                                  {capitalizeWords(item.companyName)}
                                </p>

                                <div className="w-100 mt-3 gap-3">
                                  <div className=" mb-3">
                                    <p className="fm grey text-12 mb-0">
                                      Proposed Date/Time
                                    </p>
                                    <p className="fm darkGrey text-14 my-0 text-capitalize">
                                      {moment(item.date_start).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}{" "}
                                      – <br />
                                      {moment(item.date_end).format(
                                        "dddd MMM Do YYYY, h:mm a"
                                      )}
                                    </p>

                                    <p className="fm grey text-12 mb-0">
                                      Status
                                    </p>
                                    <p className="fm darkGrey text-14 my-0 text-capitalize">
                                      Declined
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProInterviews;
