import React, { useState, useEffect } from "react";
import "./mobileStyle.css";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import SignupModal from "../../Launch/SignupModal/SignupModal";

const menu = [
  {
    id: 0,
    title: "Home",
    link: "/",
    class: "home",
  },
  {
    id: 1,
    title: "Candidate",
    link: "/candidate",
    class: "professionals",
  },
  {
    id: 2,
    title: "Enterprise",
    link: "/enterprise",
    class: "enterprise",
  },
  {
    id: 3,
    title: "Pricing",
    link: "/pricing",
    class: "pricing",
  },
  // {
  //   id: 4,
  //   title: "Why IndexScore?",
  //   link: "/why-indexcore",
  //   class: "why-indexcore",
  // },
  {
    id: 5,
    title: "About us",
    link: "/about-us",
    class: "about-us",
  },
  // {
  //   id: 6,
  //   title: "Contact",
  //   link: "/contact-us",
  //   class: "contact-us",
  // },
  {
    id: 7,
    title: "Request a demo",
    link: "/book-a-demo",
    class: "book-a-demo",
  },
];

function MobileSidebar() {
  const [scroll, setScroll] = useState(false);
  const [modal, setModal] = useState(false);
  const changeBorder = () => {
    if (window.scrollY >= 35) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  useEffect(() => {
    changeBorder();
    window.addEventListener("scroll", changeBorder);
    if (modal) {
      document.body.classList.add("smodal");
    } else document.body.classList.remove("smodal");
  });
  return (
    <>
      <div
        className="offcanvas offcanvas-start mobile-sidebar px-0 "
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasLeft"
        aria-labelledby="offcanvasRightLabel"
        style={{
          borderTop: scroll ? "1px solid var(--bordergrey)" : "0px solid",
        }}
      >
        <div className="offcanvas-body d-flex flex-column justify-content-between px-0 pb-0  ">
          <div className="main-padding pt-4">
            <ul className="list-unstyled offcanvas__list d-flex flex-column ">
              {menu.map((item) => (
                <li className="mobile-sidebar__list__item mb-4" key={item.id}>
                  <Link
                    to={item.link}
                    className={`${item.class}-active mobile-sidebar__list__item--Link d-flex justify-content-between text-decoration-none`}
                  >
                    {item.title}
                    <HiArrowRight className="mobile-sidebar__list__item--Link__icon fs-2" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="">
            {/* <small className="fm text-muted ms-3">SIGN-UP AS:</small> */}
            <div className="mobile-sidebar__footer d-flex">
              <div className="mobile-sidebar__footer__button mobile-sidebar__footer__button1 py-3 w-50 ">
                <Link
                  to="/signup"
                  className="mobile-sidebar__footer__button--Link text-decoration-none w-100 d-flex justify-content-center"
                >
                  Signup
                </Link>
                {/* <p
                  onClick={() => setModal(true)}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                  className="mobile-sidebar__footer__button--Link mb-0 text-decoration-none w-100 d-flex justify-content-center"
                >
                  Sign up
                </p> */}
              </div>
              <div className="mobile-sidebar__footer__button mobile-sidebar__footer__button1 py-3 w-50 ">
                {/* <Link
                  to="/enterprise/signup"
                  className="mobile-sidebar__footer__button--Link text-decoration-none w-100 d-flex justify-content-center"
                >
                  Enterprise
                </Link> */}
                <Link
                  to="/log-in"
                  className="mobile-sidebar__footer__button--Link text-decoration-none w-100 d-flex justify-content-center"
                >
                  Login
                </Link>
              </div>
            </div>
            {/* <div className="mobile-sidebar__footer d-flex">
              <div className="mobile-sidebar__footer__button mobile-sidebar__footer__button1 py-3 w-100 ">
                <Link
                  to="/log-in"
                  className="mobile-sidebar__footer__button--Link text-decoration-none w-100 d-flex justify-content-center"
                >
                  Login
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <SignupModal state={modal} func={setModal} />
    </>
  );
}

export default MobileSidebar;
