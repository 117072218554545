import React, { useEffect } from "react";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Hsection1 from "../Launch/Holding/Hsection1.jsx";
import Hsection2 from "../Launch/Holding/Hsection2.jsx";
import Hsection4 from "../Launch/Holding/Hsection4.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";


const Holding = () => {
  useEffect(() => {
    document.body.classList.add('holding')
    return () => {
      document.body.classList.remove('holding')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <Hsection1 />
      <Hsection2 />
      <Hsection4 />
      <Footer />
    </>
  );
};

export default Holding;
