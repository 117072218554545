import React, { useState, useContext } from "react";
import config from '../../../services/apisConfig';
import environment from '../../../environment/index.js';
import { useNavigate } from "react-router-dom";
import { BsCheckCircle, BsPlusCircle, BsLink45Deg } from "react-icons/bs";
import { MyContext } from "../../../Utilities/MyContextProvider";
import {
  isEmail,
  isLinkedIn,
  isPassword,
  isTwitter,
} from "../../../Utilities/utilities";
import { Loader } from "rsuite";
import axios from "axios";
import { useSnackbar } from "notistack";

const Online = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const myData = myContext;

  const FB_ID = environment.FB_ID;
  const InstaID = environment.Insta_ID;

  // const redirectUri = 'http://localhost:3001/professional/signup/online-presence'; // tricky test.
  const redirectUri = window.location.href;
  const uriInsta = window.location.href;

  const getUrlParameter = (e, uri) => {
    e = e.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var t = new RegExp("[\\?&]" + e + "=([^&#]*)").exec(uri);
    return null === t ? null : decodeURIComponent(t[1].replace(/\+/g, " "));
  };

  const buildUrlFb = () => {
    let params = "fbloged=1";
    let uriX2 = encodeURIComponent(redirectUri);
    return `https://www.facebook.com/v8.0/dialog/oauth?client_id=${FB_ID}&redirect_uri=${uriX2}&state=${params}`;
  };

  const buildUrlInsta = () => {
    let params = "fbloged=1";
    // let uriX2 = encodeURIComponent('https://c56e-101-53-228-14.ngrok-free.app/professional/signup/online-presence/');
    let uriX2 = encodeURIComponent(redirectUri);
    console.log("redirectUri" + redirectUri);
    return (`https://api.instagram.com/oauth/authorize?client_id=${InstaID}&redirect_uri=${uriX2}/&scope=user_profile,user_media&response_type=code`);
  }



  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const openFbDialog = async () => {
    return new Promise((resolve, reject) => {
      let uri = buildUrlFb();
      let window01 = popupWindow(uri, "", window, 500, 500);
      // fire this immediately after the user accept the logged in
      window01.addEventListener("load", async (event) => {
        try {
          let uri02 = window01.location.href;
          let token = getUrlParameter("code", uri02);
          resolve(token);
          window01.close();
        } catch (ex) {
          reject(null);
        }
      });
    });
  };

  const [code, setCode] = useState(null);
  var codeGrabbed;
  const instagramOAuth = () => {
    const clientId = InstaID;
    
    const redirectUriInsta = encodeURIComponent(uriInsta);
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;

    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    handleChange("loading2", true);
    const popup = window.open(
      authUrl,
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        try {
          if (popup.closed) {
            clearInterval(interval);
            reject(new Error("Popup closed"));
          }

          console.log(popup.location.href);
          console.log(redirectUriInsta);

          if (popup.location.href.includes(decodeURIComponent(redirectUriInsta))) {
            const url = new URL(popup.location.href);
            codeGrabbed = url.searchParams.get("code");
            console.log("code milgya" + codeGrabbed);
            clearInterval(interval);
            popup.close();
            setCode(codeGrabbed);
            resolve(codeGrabbed);
            getToken();            
          }
        } catch (error) {
          console.error(error);
        }
      }, 1000);
    });
  };

  const getToken = async () => {
    try {
      const response = await axios.post(
        config.insta.insta,
        {
          redirect_uri:uriInsta,
          code: codeGrabbed,
        }                
      );
      handleChange('loading2', false);
      handleChange('instagramData', response.data);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const openInstaDialog = async () => {
    return new Promise((resolve, reject) => {
      let uri = buildUrlInsta();
      let window01 = popupWindow(uri, "", window, 500, 500);
      // fire this immediately after the user accept the logged in
      window01.addEventListener("load", async (event) => {
        try {
          let uri02 = window01.location.href;
          let token = getUrlParameter("code", uri02);
          resolve(token);
          window01.close();
        } catch (ex) {
          reject(null);
        }
      });
    });
  };

  const myLinkedinLogin = async () => {
    if (companyLinkedin == "") {
      enqueueSnackbar(
        "Error: Please enter linkedin url!",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        { autoHideDuration: 1000 }
      );
    } else {
      handleChange("loading", true);

      var userType;
      if (props.page == "enterprise") {
        userType = "enterprise";
      } else {
        userType = "professional";
      }

      axios
        .post(config.linkedinShare.linkedinParser, {
          _id: myContext.contextData._id,
          linkedinUrl: companyLinkedin,
          usertype: userType,
        })
        .then((response) => {          
          handleChange(
            "companyLinkedinData",
            response.data.data.linkedIn_profile_data
          );          
          handleChange("loading", false);
          enqueueSnackbar("Linkedin Authorized!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        });
    }
  };

  const myTwitterLogin = async () => {
    if (!myContext.contextData._id) {
      enqueueSnackbar("You have not signed up yet", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      if (twitterURL == "") {
        enqueueSnackbar(
          "Error: Please enter twitter username",
          {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );
      } else {
        handleChange("loading5", true);
        var userType;
        if (props.page == "enterprise") {
          userType = "enterprise";
        } else {
          userType = "professional";
        }

        axios
          .post(config.twitter.twitterParser, {
            _id: myContext.contextData._id,
            twitterUrl: twitterURL,
            usertype: userType,
          })
          .then((response) => {
            
            handleChange(
              "twitterData",
              response.data.data.twitter_profile_data
            );            
            handleChange("loading5", false);
            enqueueSnackbar(
              "Twitter Authorized!",
              {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              },
              { autoHideDuration: 1000 }
            );
          });
      }
    }
  };
  const myFbLogin = async () => {
    handleChange("loading3", true);
    try {
      let token = await openFbDialog();
      console.log("code-----" + token);
      console.log("redirectUri" + redirectUri);
      handleChange("facebookURL", token);

      const tokenUrl = "https://graph.facebook.com/v12.0/oauth/access_token";
      axios
        .get(tokenUrl, {
          params: {
            client_id: FB_ID,
            client_secret: "dffaa5f1e19aaa3f50b4e1d4df34bcd4",
            redirect_uri: redirectUri,
            code: token,
          },
        })
        .then((response) => {
          const accessToken = response.data.access_token;
          const apiUrl = `https://graph.facebook.com/v12.0/me?access_token=${accessToken}&fields=id,name,email,picture,posts,friends,likes`;
          axios.get(apiUrl).then((response) => {
            const userData = response.data;
            
            handleChange("facebookData", userData);
            handleChange("loading3", false);
            enqueueSnackbar(
              "Facebook Authorized!",
              {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              },
              { autoHideDuration: 1000 }
            );

          });
        });
      
    } catch (ex) {
      console.log("there was an error");
    }
  };

 



  const [state, setState] = useState({
    companyUrl: "",
    companyGlassDoorUrl: "",
    companyLinkedin: "",
    companyLinkedinData: "",
    twitterURL: "",
    twitterData: "",
    loading: false,
    instagramURL: "",
    loading2: false,
    instagramData: {},
    facebookURL: "",
    facebookData: "",
    loading3: false,
    loading4: false,
    tiktokURL: "",
    loading5: false,
    companyCareerUrl: "",
    required: false,
    acheivementsUrl: "",
  });
  const {
    loading,
    loading2,
    loading3,
    loading4,
    loading5,
    required,
    companyUrl,
    companyGlassDoorUrl,
    companyLinkedin,
    companyLinkedinData,
    twitterURL,
    twitterData,
    instagramURL,
    instagramData,
    facebookURL,
    facebookData,
    tiktokURL,
    companyCareerUrl,
    acheivementsUrl,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  // const handleTwitter = (key, value) => {
  //   const url = value;
  //   const username = url.split("/").pop();
  //   setState(pre => ({ ...pre, [key]: username }))
  // }
  const disabled =
    // !companyUrl ||
    // !companyGlassDoorUrl ||
    !companyLinkedin ||
    // !instagramURL ||
    // !twitterURL ||
    !facebookURL ||
    !facebookData ||
    // !tiktokURL ||
    // !companyCareerUrl    ||
    isLinkedIn(companyLinkedin);

  const handleSignup = async () => {
    try {
      if (disabled) {
        console.log("iskay ander arha");
        handleChange("required", true);
        return;
      }

      // history.push('/signup/email-verification')
      handleChange("required", false);

      var payload = {
        companyUrl: companyUrl,
        companyGlassDoorUrl: companyGlassDoorUrl,
        linkedIn_profile_data: companyLinkedinData,
        linkedIn_authorised: true,
        twitter_profile_data: twitterData,
        twitter_authorised: true,
        instagram_profile_data: instagramData,
        instagram_authorised: true,
        facebook_profile_data: facebookData,
        facebook_authorised: true,
        tiktokURL: tiktokURL,
        companyCareerUrl: companyCareerUrl,
        acheivementsUrl: acheivementsUrl,
        personaVerification: true,
        ai_data: {
          fb_sentiment: "",
          insta_sentiment: "",
          twitter_sentiment: "",
          fb: facebookData,
        },
      };

      if (props.page == "enterprise") {
        myContext.updateContextData(payload);
        navigate(`/enterprise/signup/open-questions`);
      } else {
        myContext.updateContextData(payload);
        navigate(`/professional/signup/open-questions`);
      }
    } catch (error) {}
  };

  return (
    <div className="Signup main-padding">
      <style>
        {`.loader-icon {width: 40px;}
          .loader-icon span {position: relative;}
          .instagram-image{max-height: 48px;max-width: initial;object-fit: cover;border-radius: 8px;}  
          .fb-image{max-height: 48px;max-width: initial;object-fit: cover;border-radius: 8px;}  
        }`}
      </style>
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Your Online Presence
          </h1>
          <p className="Get__left__content__para Signup__para mb-5">
            Please help us learn more about your culture; the more we know about
            your organization, the better we can align candidates with similar
            life purposes.
          </p>
          {/* <form> */}
          <div className="row px-lg-2">
            {props.page == "enterprise" && (
              <div className="col-sm-12 ps-lg-1 pe-sm-1 mb-3">
                <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="Company URL"
                  onChange={(value) =>
                    handleChange("companyUrl", value.target.value)
                  }
                />
                {props.page == "enterprise" && required && !companyUrl && (
                  <div>
                    <small className="error__message">
                      Company URL is required !
                    </small>
                  </div>
                )}
              </div>
            )}
            {props.page == "enterprise" && (
              <div className="col-sm-6 ps-lg-1 pe-sm-1 mb-3">
                <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="Company's Glassdoor Profile URL"
                  onChange={(value) =>
                    handleChange("companyGlassDoorUrl", value.target.value)
                  }
                />
                {props.page == "enterprise" &&
                  required &&
                  !companyGlassDoorUrl && (
                    <div>
                      <small className="error__message">
                        Company's Glassdoor Profile URL is required !
                      </small>
                    </div>
                  )}
              </div>
            )}
            <div
              className={
                " pe-lg-1 ps-sm-2 mb-3 " +
                (props.page == "professional" ? "col-sm-12" : "col-sm-6")
              }
            >
              <div
                className={`link__box ${!companyLinkedinData ? "" : "done"}`}
              >
                <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="Company LinkedIn"
                  onChange={(value) =>
                    handleChange("companyLinkedin", value.target.value)
                  }
                />
                <span onClick={myLinkedinLogin}>
                  {!companyLinkedinData && !loading ? (
                    <BsLink45Deg className="fs-3" />
                  ) : (
                    ""
                  )}{" "}
                  {loading ? <Loader className="fs-3 loader-icon" /> : ""}{" "}
                  {companyLinkedinData && !loading ? <BsCheckCircle /> : ""}
                </span>
              </div>
              {companyLinkedin && isLinkedIn(companyLinkedin) && (
                <div>
                  <small className="error__message">
                    {" "}
                    Use a valid linkedin profile!
                  </small>
                </div>
              )}
              {required && !companyLinkedin && (
                <div>
                  <small className="error__message">
                    Company LinkedIn is required !
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="row px-lg-2">
            <div className="col-sm-6 ps-lg-1 pe-sm-1 mb-3">
              <div className={`link__box ${!twitterData ? "" : "done"}`}>
                <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="Twitter"
                  onChange={(value) =>
                    handleChange("twitterURL", value.target.value)
                  }
                />
                <span onClick={myTwitterLogin}>
                  {!twitterData && !loading5 ? (
                    <BsLink45Deg className="fs-3" />
                  ) : (
                    ""
                  )}{" "}
                  {loading5 ? <Loader className="fs-3 loader-icon" /> : ""}{" "}
                  {twitterData && !loading5 ? <BsCheckCircle /> : ""}
                </span>
              </div>
              {required && !twitterURL && (
                <div>
                  <small className="error__message">
                    Twitter is required !
                  </small>
                </div>
              )}
            </div>

            <div className="col-sm-6 pe-lg-1 ps-sm-2 mb-3">
              <div
                onClick={instagramOAuth}
                className={`link__box ${Object.keys(instagramData).length === 0 ? "" : "done"}`}
              >
                {/* <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="Instagram"
                  onChange={value =>
                    handleChange('instagramURL', value.target.value)
                  }
                /> */}
                <img
                  src={`/assets/images/insta-login.png`}
                  alt=""
                  className=" Navbar__container__logo--img instagram-image"
                />
                <span>
                  {Object.keys(instagramData).length === 0 && !loading2 ? (
                    <BsLink45Deg className="fs-3" />
                  ) : (
                    ""
                  )}{" "}
                  {loading2 ? <Loader className="fs-3 loader-icon" /> : ""}{" "}
                  {Object.keys(instagramData).length !== 0 && !loading2 ? <BsCheckCircle /> : ""}
                </span>
              </div>
              {/* {required && !instagramData && (
                <div >
                  <small className="error__message">Instagram is required !</small>
                </div>
              )} */}
            </div>
          </div>
          <div className="row px-lg-2">
            <div
              className={
                " pe-lg-1 ps-sm-2 mb-3 " +
                (props.page == "professional" ? "col-sm-6" : "col-sm-6")
              }
            >
              <div
                onClick={() => {
                  myFbLogin();
                }}
                className={`link__box ${!facebookData ? "" : "done"}`}
              >
                <img
                  src="/assets/images/fb-login.png"
                  alt="facebook"
                  className="DNavbar__logo--img Navbar__container__logo--img fb-image"
                />
                <span>
                  {!facebookData && !loading3 ? (
                    <BsLink45Deg className="fs-3" />
                  ) : (
                    ""
                  )}{" "}
                  {loading3 ? <Loader className="fs-3 loader-icon" /> : ""}{" "}
                  {facebookData && !loading3 ? <BsCheckCircle /> : ""}
                  {/* {loading ? <Loader /> : ''} */}
                </span>
                {/* {loading ? <Loader /> : 'Submit'} */}
                {/* <a href="https://www.facebook.com/v15.0/dialog/oauth?client_id=1945512265840708&redirect_uri=http://localhost:3001/professional/signup/online-presence">click</a> */}
              </div>
              {required && !facebookURL && (
                <div>
                  <small className="error__message">
                    Facebook is required !
                  </small>
                </div>
              )}
            </div>
            {/* <div className="col-sm-6 pe-lg-1 ps-sm-2 mb-3">
              <div className={`link__box ${!tiktokURL ? '' : 'done'}`}>
                <input
                  type="url"
                  className="form-control form-control-lg"
                  placeholder="TikTok"
                  onChange={value =>
                    handleChange('tiktokURL', value.target.value)
                  }
                />
                <span>{!tiktokURL ? <BsLink45Deg className="fs-3" /> : <BsCheckCircle />}</span>
              </div>
              {required && !tiktokURL && (
                <div >
                  <small className="error__message">TikTok is required !</small>
                </div>
              )}
            </div> */}
          </div>
          {props.page == "enterprise" && (
            <input
              type="url"
              className="form-control form-control-lg"
              placeholder="Your career page URL"
              onChange={(value) =>
                handleChange("companyCareerUrl", value.target.value)
              }
            />
          )}
          {props.page == "enterprise" && required && !companyCareerUrl && (
            <div>
              <small className="error__message">
                Career page url is required !
              </small>
            </div>
          )}

          {/* <div className="d-flex align-items-center mt-3">
            <BsPlusCircle className="Extra__icon me-2 fs-2 " />
            <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
              Add another website link?
            </p>
          </div> */}

          {props.color === "blue" && (
            <div>
              <p className="Get__left__content__para Get__right__para fw-semibold mt-sm-5 mt-4">
                Your achievements:
              </p>
              <p className="Get__left__content__para Signup__para">
                You may have other achievements that we don’t know about. Please
                tell us about awards, publications and accreditations.
              </p>
              <input
                type="text"
                name="achievements"
                className="form-control form-control-lg"
                placeholder="Accreditation/Award/Publication URL"
                onChange={(value) =>
                  handleChange("acheivementsUrl", value.target.value)
                }
              />
            </div>
          )}
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-5`}
          >
            Continue
          </button>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Online;
