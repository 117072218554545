import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { shareIndexScore } from "../../../services/auth";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom';
import { Loader } from "rsuite";
import GradientSVG from "./gradientSVG";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useLocation } from 'react-router-dom';
import "./style.css";
import { MyContext } from "../../../Utilities/MyContextProvider";


const SharingProfile = () => {
  const myContextData = useContext(MyContext);
  const { setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];
  console.log("getUserData from context", userData);

  console.log("context data", myContextData);

  const location = useLocation();
  const navigate = useNavigate();

  const propsReceived = location.state;
  console.log("propsReceived", propsReceived);
  const { enqueueSnackbar } = useSnackbar();

  const [indexcore, setIndexcore] = useState(0);
  const [purpose, setPurpose] = useState(0);

  const [email, setEmail] = useState('');
  const [clientFirstName, setClientFirstName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {

      setIndexcore(userData?.ai_data?.index_core);
      // setPurpose(
      //   propsReceived.ai_data.job_relevancy_score
      //     ? propsReceived.ai_data.job_relevancy_score.CultureFit
      //     : 0
      // );

    }, 500);
    // return () => clearTimeout(timerId)
    return () => clearTimeout(timerId);
  }, []);


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleClientFirstNameChange = (e) => {
    setClientFirstName(e.target.value);
  };



  const handleSendEmail = async () => {
    // Perform email validation (you can use a regular expression or any other method)
    if (!isValidEmail(email)) {
      setError('Invalid email address');
      return;
    }
    if (!clientFirstName) {
      setError('Invalid first name');
      return;
    }
    setLoading(true);
    const candidateName = localStorage.getItem("userProfileName");
    const candidateEmail = localStorage.getItem("userEmail");

    const payload = {
      _id: userData._id,
      emailAddress: email,
      CandidateFirstName: candidateName,
      candidateEmail: candidateEmail,
      clientFirstName: clientFirstName,
      indexscore: indexcore
    };

    const responseShare = await shareIndexScore(payload);
    console.log("responseShare", responseShare);

    if (responseShare.status == 200) {
      enqueueSnackbar(`Successfully shared`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setLoading(false);
      navigate(-1);

    }

  };

  const isValidEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };



  const idCSS = "hello";

  return (
    <div className="edash">
      <div className="pt-4">
        <div className="container-dash">
          <style>
            {`
        .error-message{
          color:red;
        }
        `}
          </style>
          <div className="Experts__container pb-4 d-flex flex-column align-items-center justify-content-between">
            <div className="d-flex flex-column w-100 align-items-center text-center meeting__container ">

              <h3 className="fm darkGrey fw-normal fs-3  text-center">
                <strong>Share your profile</strong>
              </h3>
              {/* <p className="fm darkGrey fw-light">For position:</p> */}

              <div className="d-flex flex-column w-100 align-items-center meeting__container text-center justify-content-center">
                <div className="meetingConfirm ">

                  <div className=" w-100 position-relative pb-4">
                    <GradientSVG />
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      value={indexcore}
                      // value={
                      //   props.page === "enterprise" ? props.data.indexCore : "600"
                      // }
                      // text={"600"}
                      className="progressBars mb-md-0 mb-4 px-2 position-relative"
                      maxValue={850}
                      styles={{
                        path: { stroke: `url(#${idCSS})`, height: "100%" },
                        trail: {
                          stroke: "#F8F8F8",
                        },
                      }}
                    ></CircularProgressbarWithChildren>
                    <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fm darkGrey mb-0 fs-6">IndexScore</h5>

                      <strong className="score-value fw-light mb-0 fm">
                        {indexcore}
                      </strong>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column w-100 align-items-center meeting__container">
              <div className="col-9 text-center">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6 mb-5"
                >
                  Make sure you have the right email address of the hiring manager or the recruiter
                </label>
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-1"
                  placeholder="Recipient’s First and Last Name"
                  id="sector1"
                  value={clientFirstName}
                  onChange={handleClientFirstNameChange}
                />
                <input
                  type="email"
                  className="form-control form-control-md w-100 Dashboard__input mb-1 mt-3"
                  placeholder="Enter Recipient’s Email Address"
                  id="sector1"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="d-flex flex-wrap w-100 justify-content-center pt-sm-3">
              <button
                onClick={handleSendEmail}
                className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3 me-sm-2`}
              >
                {loading ? <Loader /> : 'Send'}
              </button>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SharingProfile;
