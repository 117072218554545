import React from "react";
import "./style.css";
const Banner = (props) => {
  return (
    <div>
      <div className="banner " style={{backgroundImage:`url(${props.image})`}}>
        <div className="banner__overlay main-padding">
          <div className="container-lg px-0  h-100 ">
            <div className="row h-100">
              <div className="col-lg-12 h-lg-100 d-flex align-items-sm-center align-items-end  order-lg-1 order-2 justify-content-lg-start justify-content-sm-center justify-content-start">
                <div className="banner__overlay__content pb-sm-0 pb-5 ">
                  <h1 className={`banner__overlay__content__heading banner__overlay__content__heading-${props.class}`}>
                    {props.heading}
                  </h1>
                  <p className="banner__overlay__content__para fw-light mt-4">{props.para}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
