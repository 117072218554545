import React from 'react'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBar from "../components/ProgressBar/ProgressBar.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Submit from '../pageComponents/Enterprise/Submit/Submit.jsx';
import UpdatedNav from '../UpdatedFrontend/Navbar/UpdatedNav.jsx';

const EnterpriseSubmit = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBar page="8" title="Enterprise" />}
        right={<Submit para="Thank you for submitting the form, your account will be set up. We will be in touch when it’s ready. In the meantime, if you need help, contact support@indexscore.com."
        color="green" />}
      />
      <Footer />
    </>
  )
}

export default EnterpriseSubmit