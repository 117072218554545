import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const Hsection2 = () => {
  return (
    <div className="holding2 contact__form__fields">
      <div className="main-padding">
        <div className="container-lg px-0">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="fs-1 fm main fw-light">Secure your dream job</h1>
              <p className="fm text-white pt-4 holding2__para">
                It's time to say goodbye to the days of sending custom
                applications, taking psychometric tests for each and every job
                you apply for and being judged on the whims and whimsy of the
                individual hiring manager.{" "}
              </p>
              <p className="fm text-white holding2__para">
                After setting up your profile on IndexScore you can apply for
                jobs with just <span className="italic">one click</span>.{" "}
                <span className="main">Done.</span>
              </p>
            </div>

            <div className="col-lg-6 pt-lg-0 pt-2">
              <div className="holding2__right p-sm-4 p-3 d-flex flex-column justify-content-between">
                <div>
                <input
                  required
                  type="email"
                  className="form-control-lg form-control signIn__form--input mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                />

                <div class="form-group form-check w-100 mb-0">
                  <input
                    type="checkbox"
                    className="form-check-input  signIn__form--input__checkBox"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label fm text-white text-12  ms-1 pointer"
                    for="exampleCheck1"
                  >
                    By signing up you agree to IndexScore's{" "}
                    <Link
                      to={"/terms-of-use"}
                      className="text-decoration-none holding2__link transition"
                    >
                      Terms and conditions
                    </Link>{" "}
                    and{" "}
                    <Link
                      to={"/privacy-policy"}
                      className="text-decoration-none holding2__link transition"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </label>
                </div>
                </div>

                <div className="d-flex flex-sm-row flex-column mt-4">
                    <Link to={'/log-in'} className="text-decoration-none holding2__btn "><button className="w-100 bg-white rounded-3 fm p-2">Get started</button></Link>
                    <Link to={'/log-in'} className="text-decoration-none holding2__btn2 text-white"><button className="w-100 bg-transparent rounded-3 fm p-sm-2 pt-3 text-center transition">Already have an account?</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hsection2;
