import React, { useContext} from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { MyContext } from "../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";

const RecruitingNav = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validateProfile = () => {
    if (userData?.role === "hr") {
      navigate("/enterprise/dashboard/create-new-job");
    } else {
      if (userData?.progress?.total == 100 && userData?.ai_data?.company_data) {
        if (!userData.subscription) {
          enqueueSnackbar("Please subscribe a plan to create job", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        } else {
          navigate("/enterprise/dashboard/create-new-job");
        }
      } else {
        enqueueSnackbar("Please complete profile to create job", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };

  return (
    <div className="d-lg-block d-none">
      <div className="DAccount__top mt-lg-0 mt-4">
        <div className="container-dash py-2">
          {userData.subscription ? (
            <Link to={"/enterprise/dashboard/jobs"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "jobs" ? "fw-normal" : "fw-light"
                }`}
              >
                Open Roles
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to purchase a plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Open Roles
                </button>
              </Link>
            </Tooltip>
          )}
          {userData.subscription ? (
            <Link to={"/enterprise/dashboard/candidates"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "candidates" ? "fw-normal" : "fw-light"
                }`}
              >
                Candidates
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to purchase a plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Candidates
                </button>
              </Link>
            </Tooltip>
          )}

          {userData.subscription &&
          (userData.subscription.name === "Predict" ||
            userData.subscription.name === "Scale" ||
            userData.subscription.name === "Grow") ? (
            <Link to={"/enterprise/dashboard/ats-candidates"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "ats" ? "fw-normal" : "fw-light"
                }`}
              >
                ATS Candidates
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  ATS Candidates
                </button>
              </Link>
            </Tooltip>
          )}

          {userData.subscription &&
          (userData.subscription.name === "Predict" ||
            userData.subscription.name === "Scale") ? (
            <Link to={"/enterprise/dashboard/offers"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "offers" ? "fw-normal" : "fw-light"
                }`}
              >
                Offer Management
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Offer Management
                </button>
              </Link>
            </Tooltip>
          )}
          {userData.subscription ? (
            <Link to={"/enterprise/dashboard/department"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "departments" ? "fw-normal" : "fw-light"
                }`}
              >
                Departments
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to purchase a plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Departments
                </button>
              </Link>
            </Tooltip>
          )}

          {/* {userData.subscription ? (
            <Link to={"/enterprise/dashboard/calculator"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "calc" ? "fw-normal" : "fw-light"
                }`}
              >
                Saving’s Calculator
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to purchase a plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Saving’s Calculator
                </button>
              </Link>
            </Tooltip>
          )} */}
          <button
            onClick={validateProfile}
            className={`DAccount__btn me-sm-4 me-2 transition fm ${
              props.name === "newjob" ? "fw-normal" : "fw-light"
            }`}
          >
            Create a Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecruitingNav;
