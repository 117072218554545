import React, { useState, useRef, useEffect } from "react";
// import "./style.css";
import { BsFilter, BsFillEmojiSmileFill } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
// import JobDescription from "./JobDescription";
// import JobApply from "./JobApply";
import config from "../../../services/apisConfig";
import { getJobs, applyJob, getInterviews } from "../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { Dropdown, Avatar, Menu } from "antd";

import { UserOutlined } from "@ant-design/icons";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillLike } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";

var comments = [];

const ProContractDetails = () => {
  const location = useLocation();
  const contractData = location.state?.contractData;
  // console.log("props on contract detals", contractData);
  const [loading, setLoading] = useState(false);

  const handleDownload = async (pdfUrl) => {
    try {
      setLoading(true);

      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "accepted-contract.pdf");
      document.body.appendChild(link);
      link.click();

      setLoading(false);
    } catch (error) {
      console.error("Error downloading file:", error);
      setLoading(false);
    }
  };

  return (
    <div className="edash">
      <style>
        {`

        .invite-hd{
          margin-left:40px;
        }
          .invitation-detail {
            padding: 20px;
            border: 1px solid #ccc;
            border-radius: 20px;
            
        }
        
        .invitation-job-detail {
            border-right: 1px solid #ccc;
            min-height: 250px;
        }
        .invitation-job-detail h5{
          margin-bottom:20px;
        }
        
        .invitation-detail .job-skills {
            background: #eee;
            padding: 6px 10px;
            border-radius: 20px;
            margin-right: 10px;
            line-height: 1;
            display: inline-block;
            font-weight: 600;
            color: #0d2159;
            margin-bottom:10px;
            
        }

        .job-tags {
          color: #0d2159;
          background: #eee;
          display: inline-block;
          padding: 5px 10px;
          border-radius: 20px;
          margin-right: 20px;
          font-weight:600;
      }

      .invitation-job-description {
        margin-top: 20px;
        padding-right: 40px;
      }

      .invite-skills-hd{
          margin-bottom:20px;
      }


      .invitation-button {width: 90%;margin-bottom: 20px;padding: 14px;line-height: 1;border-radius: 25px;color: #fff;font-size: 20px;font-weight: 500;border: 2px;}

      .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      .invitation-button.download {
        background: #fff;
        border: 2px solid #4e4e4d;
        color:#4e4e4d;
        cursor:pointer;
    }
    .invitation-button.download:hover {
      background: #eee;
      border: 2px solid #4e4e4d;
      color:#4e4e4d;
      cursor:pointer;
  }
    .invitation-button.download img {
      margin-top: -7px;
      margin-right: 5px;
  
  }

      .invitation-button.rejected {background: #fff;border: 2px solid #14a800;color: #14a800;}
      .invitation-docs{margin-left:40px;margin-top:40px}
      .Pjobs__tabs .react-tabs__tab--selected, .Pjobs__tabs .react-tabs__tab--selected:focus{
        border-bottom:1px solid #41414080 !important;
      }
      .detail-main-hd{
        margin-left:40px
      }
      .detail-main-paragraph{
        margin-left:40px;
        margin-top:20px;
      }
      
        `}
      </style>

      <div className="container-dash fm">
        <div className="Dashboard__container">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-md-9">
                <h2 className="yourIndex__insights__detail__hd fw-lighter fm mb-4 ">
                  Contract Details
                </h2>
                <h3 className="">{contractData.contract_title}</h3>
                {/* <p className="detail-main-paragraph">Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various Software Engineer responsibilities include gathering user requirements, defining system functionality and writing code in various</p> */}
              </div>

              <div className="col-md-12">
                <Tabs className="jobs__tabs Ejobs__tabs">
                  <TabList>
                    <Tab>Overview</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="Dashboard__container">
                      <div className="Experts__container pb-4">
                        <div className="row">
                          <div className="col-md-9 mb-md-0 mb-3">
                            <div className="invitation-detail">
                              <div className="row">
                                <div className="col-md-12">
                                  <h3 className="fm darkBlue fw-semibold fs-4 mb-4">
                                    Job Details
                                  </h3>
                                </div>
                                <div className="col-md-9">
                                  <div className="invitation-job-detail ">
                                    <div className="d-flex justify-content-between">
                                      <h5>{contractData.contract_title}</h5>
                                      <span className="postedDate">
                                        Date:{" "}
                                        {moment(contractData?.createdAt).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </span>
                                    </div>
                                    <div className="invitation-job-description">
                                      <p>{contractData?.invite_note}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  {/* <div className="job_requirement">
                                    <i className="fas fa-user"></i>{" "}
                                    <strong>Experience Level:</strong>
                                    <p>Entry Level</p>
                                  </div> */}
                                  <div className="job_requirement">
                                    <i className="fa fa-user"></i>{" "}
                                    <strong>Job Type:</strong>
                                    {contractData &&
                                      contractData.job.employment_type?.map(
                                        (item) => <p>{item}</p>
                                      )}
                                  </div>
                                  <div className="job_requirement">
                                    <i className="fa fa-user"></i>{" "}
                                    <strong>Max Salary:</strong>
                                    <p>$ {contractData.job.max_salary}</p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <h6 className="invite-skills-hd">
                                    Skills and expertise
                                  </h6>

                                  {contractData &&
                                    contractData.job.job_role_text_keywords
                                      .slice(0, 5)
                                      ?.map((item) => (
                                        <span className="job-skills">
                                          {item}
                                        </span>
                                      ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="invite-sidebar">
                              <h3 className="fm darkBlue fw-semibold fs-4 mb-4">
                                Status:{" "}
                                <span className="text-capitalize">
                                  {contractData.status}
                                </span>
                              </h3>

                              <h6>About the Client:</h6>
                              <p>{contractData.company_name}</p>
                              <h6>Location:</h6>
                              <p>{contractData?.job?.job_locations}</p>
                            </div>

                            <button
                              onClick={() =>
                                handleDownload(contractData.offered_Letter.Key)
                              }
                              className="invitation-button download w-100 transition"
                            >
                              {loading ? (
                                <Loader />
                              ) : (
                                <>
                                  {" "}
                                  <img src="/assets/images/icon_export.svg" />{" "}
                                  Contract
                                </>
                              )}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProContractDetails;
