import React, { useState } from "react";
import EditProfile from "./EditProfile";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { ColorPicker, Input, Select, Switch } from "antd";

const BackgroundColor = () => {
  const [colorHex, setColorHex] = useState("#FFFF");
  const [formatHex, setFormatHex] = useState("hex");
  const hexString = React.useMemo(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );
  return (
    <div className="rounded-border eprofile p-2">
      <ColorPicker
        format={formatHex}
        value={colorHex}
        onChange={setColorHex}
        onFormatChange={setFormatHex}
      />
      <p className="fm text-14 darkGrey mb-0">{hexString}</p>
    </div>
  );
};

const AccentColor = () => {
  const [colorHex, setColorHex] = useState("#566375");
  const [formatHex, setFormatHex] = useState("hex");
  const hexString = React.useMemo(
    () => (typeof colorHex === "string" ? colorHex : colorHex?.toHexString()),
    [colorHex]
  );
  return (
    <div className="rounded-border eprofile p-2">
      <ColorPicker
        format={formatHex}
        value={colorHex}
        onChange={setColorHex}
        onFormatChange={setFormatHex}
      />
      <p className="fm text-14 darkGrey mb-0">{hexString}</p>
    </div>
  );
};

const EditEnterpriseProfile = () => {
  const [userData, setUserData] = useState([]);
  const profileImg = "";
  return (
    <div className="container-dash eprofile edash edash3 pb-5">
      <div className="eprofile">
        <div className="">
          <div className="row g-3">
            <div className="col-lg-4">
              <div className="rounded-border overflow-hidden">
                <div className="d-flex justify-content-between px-3 py-2 align-items-center border-05">
                  <p className="fm text-14 darkGrey my-0 fw-semibold">
                    Brand Page
                  </p>
                  <IoIosCloseCircleOutline className="fs-5 darkGrey pointer" />
                </div>

                <div className="p-3 General">
                  <div className="d-flex align-items-center eprofile__switch p-3 gap-2 mb-3">
                    <Switch defaultChecked />
                    <p className="darkGrey fm text-14 mb-0">
                      Enable live brand page
                    </p>
                  </div>

                  <div className="mb-3">
                    <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                      Company URL
                    </p>
                    <Input
                      addonBefore="https://"
                      placeholder="IndexScore.com/company/acmeco"
                      className=""
                    />
                  </div>

                  <div className="pb-3">
                    <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                      Page title
                    </p>
                    <Select
                      className="Login--Input mx-0 d-block w-100"
                      size="small"
                      placeholder="Acme Co"
                    >
                      <Select.Option value="Acme">Acme Co</Select.Option>
                      <Select.Option value="Google">Google</Select.Option>
                    </Select>
                  </div>

                  <div className=" mt-4">
                    <div className="row g-3">
                      <div className="col-6">
                        <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                          Header Image
                        </p>
                        <div className="rounded-border p-2">
                          <div
                            className={`edit__dp overflow-hidden`}
                            style={{
                              background: userData.companyImage
                                ? "none"
                                : "#E6E6E6",
                            }}
                          >
                            {userData.companyImage ? (
                              userData.companyImage.docs && (
                                <img
                                  src={userData.companyImage.docs}
                                  alt=""
                                  className="eprofile__img"
                                />
                              )
                            ) : (
                              <img
                                src={profileImg}
                                alt=""
                                className="eprofile__img"
                              />
                            )}
                          </div>
                          <p className="fm text-14 grey mb-0 mt-1">???</p>
                        </div>
                      </div>

                      <div className="col-6">
                        <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                          Profile Image
                        </p>
                        <div className="rounded-border p-2">
                          <div
                            className={`edit__dp overflow-hidden`}
                            style={{
                              background: userData.companyImage
                                ? "none"
                                : "#E6E6E6",
                            }}
                          >
                            {userData.companyImage ? (
                              userData.companyImage.docs && (
                                <img
                                  src={userData.companyImage.docs}
                                  alt=""
                                  className="eprofile__img"
                                />
                              )
                            ) : (
                              <img
                                src={profileImg}
                                alt=""
                                className="eprofile__img"
                              />
                            )}
                          </div>
                          <p className="fm text-14 grey mb-0 mt-1">???</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="row g-3">
                      <div className="col-6">
                        <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                          Background Color
                        </p>
                        <BackgroundColor />
                      </div>

                      <div className="col-6">
                        <p className="fm darkGrey mb-1 text-14 mt-0 text-capitalize">
                          Accent Color
                        </p>
                        <AccentColor />
                      </div>

                      <div className="mt-5">
                        <div className="d-flex gap-2 mt-3">
                          <button
                            className="btn--main text-14 fm radius8 interview__btn w-75"
                            style={{ maxWidth: "none" }}
                          >
                            Save
                          </button>
                          <button
                            className="text-14 fm interview__resch transition fw-semibold radius8 px-xxl-2 px-0"
                            style={{ width: "30%" }}
                          >
                            Discard
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="rounded-border overflow-hidden">
                <div className="d-flex justify-content-between px-3 py-2 align-items-center">
                  <p className="fm text-14 darkGrey my-0 fw-semibold">
                    Preview of profile
                  </p>
                  <IoIosCloseCircleOutline className="fs-5 darkGrey pointer" />
                </div>
                <EditProfile radius="no" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEnterpriseProfile;
