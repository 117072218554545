import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBar from "../components/ProgressBar/ProgressBar.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Extra from "../pageComponents/Professionals/ExtraInfo/Extra.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const ProfessionalExtraInfo = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="professional-signup" />
      <MobileSidebar />
      <Container progress={<ProgressBar page= "2" title="Candidate" />} right={<Extra />} />
      <Footer />
    </>
  );
};

export default ProfessionalExtraInfo;
