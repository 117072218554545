import React from "react";
import "./style.css";
import { InlineWidget } from "react-calendly";
import environment from "../../environment";

const Demo = () => {
  const calendlyUrl = "https://calendly.com/" + environment.CALENDY_URL + "/30min";
  return (
    <div className="demo overflow-hidden">
      <div className="main-padding">
        <div className="container-xl">
          <div className="row py-5">
            <h1 className="fs-2 black fw-semibold text-center pt-md-3 mb-lg-0">
              See what IndexScore can do for you
            </h1>
            {/* <div className="col-xxl-4 col-xl-4 d-flex flex-column justify-content-center ">
              <p className="fm text-white fs-6 fw-light mb-0 pt-xl-0 pt-3 text-xl-start text-center pe-xxl-0 pe-xl-4">
                Get a guided demo through IndexScore, and find out how
                IndexScore can help your organization hire and retain qualified
                candidates in seconds.
              </p>
            </div> */}
            <div className="col-xl-8 offset-xl-2  px-0">
              <div className="calendly-box">
                <InlineWidget url={calendlyUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
