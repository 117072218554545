import React, { useState, useContext,useEffect } from "react";
import { useParams } from 'react-router-dom';
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import "./style.css";
import { Checkbox, Select, DatePicker } from "antd";
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { isEmail, isLinkedIn, isPassword } from '../../Utilities/utilities'
import moment from "moment";
import { Loader } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import { getRefById,updateFeedback } from "../../services/auth";

const attributes1 = [
  {
    id: 1,
    att: "Detailed oriented",
  },
  {
    id: 2,
    att: "Reliable",
  },
  {
    id: 3,
    att: "Collaborative",
  },
  {
    id: 4,
    att: "Great listener",
  },
  {
    id: 5,
    att: "A leader",
  },
];
const attributes2 = [
  {
    id: 1,
    att: "Collaborative",
  },
  {
    id: 2,
    att: "Alone",
  },
  {
    id: 3,
    att: "Through influence",
  },
  {
    id: 4,
    att: "Avoided conflict",
  },
  {
    id: 5,
    att: "Required manager to step in",
  },
];
const attributes3 = [
  {
    id: 1,
    att: "Communication",
  },
  {
    id: 2,
    att: "Collaboration",
  },
  {
    id: 3,
    att: "Leadership",
  },
  {
    id: 4,
    att: "Listening Skills",
  },
  {
    id: 5,
    att: "Reliability",
  },
];

const Feedback = () => {
  const { Option } = Select;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  
  const [candidate, setCandidate] = useState([]);
  const { id } = useParams();
  const fetchRefById = async () => {
    try {

      var payloadGetRef = {
        _id: id
      };
      const response = await getRefById(payloadGetRef);
      setCandidate(response.data.data);
      
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };


  
  useEffect(() => {
    fetchRefById();
  }, []);

  const handleStartDateChange = (date, dateString) => {
    handleChange('start_date', moment(dateString).format("YYYY-MM-DD"));
  };
  const handleEndDateChange = (date, dateString) => {
    handleChange('end_date', moment(dateString).format("YYYY-MM-DD"));
  };

  // const [start_date, setstart_date] = useState(null);
  // const [end_date, setend_date] = useState(null);

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    emailAddress: '',
    referenceSource: '',
    phoneNumber: '',
    linkedinPublicUrl: '',
    start_date: '',
    end_date: '',
    howlong: '',
    quality: '',
    loading: false,
    detail_oriendted: '',
    reliable: '',
    collabartive: '',
    listener: '',
    leader: '',
    problemcollabartive: '',
    alone: '',
    influence: '',
    conflict: '',
    reqmanager: '',
    devcollabartive: '',
    communication: '',
    leadership: '',
    listening: '',
    realiability: '',
    Feedback: '',
    overallexperience: '',
    status: '',
    required: false,

  })
  const {
    loading,
    required,
    firstName,
    lastName,
    company,
    division,
    start_date,
    phoneNumber,
    linkedinPublicUrl,
    end_date,
    howlong,
    quality,
    detail_oriendted,
    reliable,
    collabartive,
    listener,
    leader,
    problemcollabartive,
    alone,
    influence,
    conflict,
    reqmanager,
    devcollabartive,
    communication,
    leadership,
    listening,
    realiability,
    Feedback,
    overallexperience

  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))

  }
  const disabled =
    !firstName ||
    !lastName ||
    !howlong ||
    !quality ||
    !linkedinPublicUrl ||
    isLinkedIn(linkedinPublicUrl) ||
    !division ||
    !detail_oriendted ||
    !reliable ||
    !collabartive ||
    !listener ||
    !leader ||
    !problemcollabartive ||
    !alone ||
    !influence ||
    !conflict ||
    !reqmanager ||
    !devcollabartive ||
    !communication ||
    !leadership ||
    !listening ||
    !realiability ||
    !Feedback ||
    !overallexperience


  const handleSubmit = async () => {
    
    try {
      if (disabled) {

        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        _id: id,
        firstName: firstName,
        lastName: lastName,
        company: company,
        division: division,
        start_date: start_date,
        end_date: end_date,
        linkedinPublicUrl: linkedinPublicUrl,
        years_known: howlong,
        quality_rating: quality,
        general_attributes: {
          detail_oriendted: detail_oriendted,
          reliable: reliable,
          collabartive: collabartive,
          listener: listener,
          leader: leader
        },
        project_attributes: {
          colloborative: problemcollabartive,
          alone: alone,
          through_influence: influence,
          avoided_conflict: conflict,
          required_manager_intervention: reqmanager,
        },
        strength_attributes: {
          communication: communication,
          colloboration: devcollabartive,
          leadership: leadership,
          listening_skills: listening,
          realiability: realiability,
        },
        feedback: Feedback,
        overall_experience: overallexperience,
        status: 'done'


      }
      
      let res;

      res = await updateFeedback(payload);
    
      if (res.data.statusCode === 1) {
        handleChange('loading', false);
        enqueueSnackbar(`Updated Successfully!`, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })
        navigate('/feedback/submitted');

      }
      else {

      }


    } catch (error) {
      handleChange('loading', false)
      console.log('errssssssss', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }
  }



  return (
    <div className="bg-black contact__form__fields feedback pt-5">
      <div className="container pb-5 d-flex justify-content-center">
        <div className="feedback-container">
          <Link to={"/"}>
            <div className="Navbar__container__logo d-flex align-items-center justify-content-center mb-md-5 mb-4">
              <img
                src={`/assets/images/navbar/logoWhite.svg`}
                alt=""
                className="Navbar__container__logo--img"
              />
            </div>
          </Link>

          <h1 className="fm main fw-light text-center fs-1 pt-5">
          Reference Feedback Form
          </h1>
          {/* <p className="fm text-white mt-5 pb-3 fw-light">
            Text about reference feedback form over a couple lines why. Text
            about reference feedback form over a couple lines why. Text about
            reference feedback form over a couple lines. reference feedback form
            over a couple lines why. Text about reference feedback form over a
            feedback form over a couple lines why.
          </p> */}

          <form>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="firstName"
                    placeholder="First Name"
                    onChange={value =>
                      handleChange('firstName', value.target.value)
                    }
                  />
                </div>
                {required && !firstName && (
                  <div>
                    <small className="error__message ps-1"> First Name is required!</small>
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="lastName"
                    placeholder="Last name"
                    onChange={value =>
                      handleChange('lastName', value.target.value)
                    }
                  />
                </div>
                {required && !lastName && (
                  <div>
                    <small className="error__message"> Last Name is required!</small>
                  </div>
                )}
              </div>
              <div className="col-sm-6 pe-sm-0">
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="company"
                    placeholder="Company"
                    onChange={value =>
                      handleChange('company', value.target.value)
                    }
                  />
                </div>
                {required && !company && (
                  <div>
                    <small className="error__message"> Company is required!</small>
                  </div>
                )}
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="division"
                    placeholder="Division"
                    onChange={value =>
                      handleChange('division', value.target.value)
                    }
                  />
                </div>
                {required && !division && (
                  <div>
                    <small className="error__message"> Division is required!</small>
                  </div>
                )}
              </div>

              {/* <h6 className="fs-6 fm text-white mt-4 mb-0">
                Relation to candidate:
              </h6>
              <p className="fm text-white fw-light">
                Choose one or multiple options
              </p>
              <div className="d-flex flex-wrap">
                <Checkbox
                checked={employmentType.includes('Full time')}
                  value="Full time"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                  className="fm text-white fw-light me-3 mb-2 fs-6"
                >
                  Full time
                </Checkbox>
                <Checkbox
                checked={employmentType.includes('Part time')}
                  value="Part time"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                  className="fm text-white fw-light me-3 mb-2 fs-6"
                >
                  Part time
                </Checkbox>
                <Checkbox
                  className="fm text-white fw-light me-3 mb-2 fs-6"
                  checked={employmentType.includes('Hybrid')}
                  value="Hybrid"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                >
                  Hybrid
                </Checkbox>
                <Checkbox
                  className="fm text-white fw-light me-3 mb-2 fs-6"
                  checked={employmentType.includes('Remote')}
                  value="Remote"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                >
                  Remote
                </Checkbox>
                <Checkbox
                  className="fm text-white fw-light me-3 mb-2 fs-6"
                  checked={employmentType.includes('Freelance')}
                  value="Freelance"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                >
                  Freelance
                </Checkbox>
                <Checkbox
                  className="fm text-white fw-light mb-3 fs-6"
                  checked={employmentType.includes('Temporary')}
                  value="Temporary"
                  name="employmentType"
                  onChange={handleCheckboxChange}
                >
                  Temporary
                </Checkbox>
              </div> */}

              <p className="fm text-white mt-4 mb-0 pb-2">
              Timeframe you worked with the candidate:
              </p>
              <div className="col-md-4 col-sm-6 mb-2 pe-sm-0">
                <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="Start date"
                  onChange={handleStartDateChange}
                />
                {required && !start_date && (
                  <div>
                    <small className="error__message"> Start Date is required!</small>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-6 mb-2">
                <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="End date"
                  onChange={handleEndDateChange}
                />
                {required && !end_date && (
                  <div>
                    <small className="error__message"> End Date is required!</small>
                  </div>
                )}
              </div>
              <div className="col-12">
                <p className="fm text-white mt-4 mb-0 pb-2">LinkedIn:</p>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="firstName"
                    placeholder="URL"
                    onChange={value =>
                      handleChange(
                        'linkedinPublicUrl',
                        value.target.value
                      )
                    }
                  />
                </div>
                {linkedinPublicUrl && isLinkedIn(linkedinPublicUrl) && (
                  <div>
                    <small className="error__message"> Use a valid linkedin profile!</small>
                  </div>
                )}
                {required && !linkedinPublicUrl && (
                  <div>
                    <small className="error__message">Linkedin public profile is required!</small>
                  </div>
                )}
              </div>

              <div className="col-12">
                <p className="fm text-white mt-4 mb-0 pb-2">
                  Approximately how long did {candidate.length > 0 && candidate[0].professionalName} work for you (for
                  example, 1 year, 1.5 years, etc.)?
                </p>
                <div className="form-group">
                  <input
                    required
                    type="text"
                    className="form-control-lg form-control signIn__form--input mb-3"
                    id="firstName"
                    placeholder="?"
                    onChange={value =>
                      handleChange('howlong', value.target.value)
                    }
                  />
                </div>
                {required && !howlong && (
                  <div>
                    <small className="error__message">Duration is required!</small>
                  </div>
                )}
              </div>

              <p className="fm text-white mt-4 mb-0 pb-2">
                On a scale from 1 to 5 (1 being the worst and 5 the best), how
                would you rate {candidate.length > 0 && candidate[0].professionalName} ability to deliver quality work
                in a fast-paced environment?
              </p>
              <div className="col-sm-2 col-4">
                <Select
                  className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="?"
                  onChange={value =>
                    handleChange('quality', value)
                  }
                >
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                </Select>
              </div>

              <p className="fm text-white mt-4 mb-0 pb-2">
                In order of importance (1 being the lowest and 5 the highest), how
                would you arrange the following attribute about {candidate.length > 0 && candidate[0].professionalName}?
              </p>
              {/* {attributes1.map((item) => ( */}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('detail_oriendted', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Detailed oriented</p>
                </div>
              </div>
              {required && !detail_oriendted && (
                <div>
                  <small className="error__message">Detailed oriented rating is required!</small>
                </div>
              )}

              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('reliable', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Reliable</p>
                </div>
              </div>
              {required && !reliable && (
                <div>
                  <small className="error__message">Reliable rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('collabartive', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Collabartive </p>
                </div>
              </div>
              {required && !collabartive && (
                <div>
                  <small className="error__message">Collabartive rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('listener', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Great listener</p>
                </div>

              </div>
              {required && !listener && (
                <div>
                  <small className="error__message">Listener rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('leader', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">A leader</p>
                </div>
              </div>
              {required && !leader && (
                <div>
                  <small className="error__message">Leader rating is required!</small>
                </div>
              )}
              {/* ))} */}

              <p className="fm text-white mt-4 mb-0 pb-2">
                In order of importance (1 being the lowest and 5 the highest), how
                did {candidate.length > 0 && candidate[0].professionalName} solve project related challenges:
              </p>
              {/* {attributes2.map((item) => ( */}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('problemcollabartive', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Collaborative</p>
                </div>
              </div>
              {required && !problemcollabartive && (
                <div>
                  <small className="error__message">Collaborative rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('alone', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Alone</p>
                </div>
              </div>
              {required && !alone && (
                <div>
                  <small className="error__message">Alone rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('influence', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Through influence</p>
                </div>
              </div>
              {required && !influence && (
                <div>
                  <small className="error__message">Through influence rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('conflict', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Avoided conflict</p>
                </div>
              </div>
              {required && !conflict && (
                <div>
                  <small className="error__message">Avoided Conflict rating is required!</small>
                </div>
              )}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('reqmanager', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Required manager to step in</p>
                </div>
              </div>
              {required && !reqmanager && (
                <div>
                  <small className="error__message">Required manager rating is required!</small>
                </div>
              )}
              {/* ))} */}

              <p className="fm text-white mt-4 mb-0 pb-2">
                From area of development to strength, how would you order the
                attributes (1 being the lowest and 5 the highest) about{" "}
                {candidate.length > 0 && candidate[0].professionalName} below?
              </p>
              {/* {attributes3.map((item) => ( */}
              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('communication', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Communication</p>
                </div>
              </div>
              {required && !communication && (
                <div>
                  <small className="error__message">Communication rating is required!</small>
                </div>
              )}

              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('devcollabartive', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Collaboration</p>
                </div>
              </div>
              {required && !devcollabartive && (
                <div>
                  <small className="error__message">Collaboration rating is required!</small>
                </div>
              )}

              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('leadership', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Leadership</p>
                </div>
              </div>
              {required && !leadership && (
                <div>
                  <small className="error__message">Leadership rating is required!</small>
                </div>
              )}

              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('listening', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Listening Skills</p>
                </div>
              </div>
              {required && !listening && (
                <div>
                  <small className="error__message">Listening Skills rating is required!</small>
                </div>
              )}

              <div className="row" >
                <div className="col-sm-2 col-4">
                  <Select
                    className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="?"
                    onChange={value =>
                      handleChange('realiability', value)
                    }
                  >
                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                  </Select>
                </div>
                <div className="col-sm-10 col-8 d-flex align-items-center ps-sm-0">
                  <p className="fm text-white fw-light mb-2">Reliability</p>
                </div>
              </div>
              {required && !realiability && (
                <div>
                  <small className="error__message">Communication rating is required!</small>
                </div>
              )}
              {/* ))} */}

              <p className="fm text-white mt-4 mb-0 pb-2">
                How would you describe your overall experience with{" "}
                {candidate.length > 0 && candidate[0].professionalName}?
              </p>
              <div className="form-group">
                <textarea
                  required
                  type="text"
                  className="form-control-lg form-control signIn__form--input mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Your message..."
                  rows={6}
                  onChange={value =>
                    handleChange('overallexperience', value.target.value)
                  }
                />
              </div>
              {required && !overallexperience && (
                <div>
                  <small className="error__message">Overall Experience rating is required!</small>
                </div>
              )}

              <p className="fm text-white mt-4 mb-0">Feedback:</p>
              <p className="fm text-white mb-0 pb-2 fw-light">
                Please fill in your company description, if your profile has your
                full company description already this will be pre-filled, you can
                click and add any changes to it below or click next to move to the
                next step.
              </p>
              <div className="form-group">
                <textarea
                  required
                  type="text"
                  className="form-control-lg form-control signIn__form--input mb-3"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Your message..."
                  rows={6}
                  onChange={(value) =>
                    handleChange('Feedback', value.target.value)
                  }
                />
              </div>
              {required && !Feedback && (
                <div>
                  <small className="error__message">Feedback rating is required!</small>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center mt-5">
              {/* <Link */}
              {/* // to={'/feedback/submitted'} */}

              {/* className="w-100 d-flex justify-content-center text-decoration-none"> */}
              <button
                onClick={() => handleSubmit()}
                className="d-flex justify-content-center text-decoration-none feedback__button w-100 btn btn-lg"
                type="button"
              >
                {loading ? <Loader /> : 'Submit'}
              </button>
              {/* </Link> */}
            </div>
          </form>
        </div>
      </div>
      <div className="contactform-border mt-md-5">
        <Footer />
      </div>
    </div>
  );
};

export default Feedback;
