export function isEmail(val) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
        return true;
    }
}

export function disAllowEmails(val) {
    // Disallow Gmail, Hotmail, Yahoo Mail, and Outlook
    let regEmail = /^(?!.*@(gmail\.com|hotmail\.com|yahoo\.com|outlook\.com)$)([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+")@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regEmail.test(val)) {
        return true;
    }
}


export function isLinkedIn(val) {
    let regLinkedIn = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
    if (!regLinkedIn.test(val)) {
        return true;
    }
}

export function isLinkedInCompany(val) {
    let regLinkedIn = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/;
    if (!regLinkedIn.test(val)) {
        return true;
    }
}



export function isTwitter(val) {
    let regTwitter = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
    if (!regTwitter.test(val)) {
        return true;
    }
}

export function isPassword(val) {
    let regPass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;
    if (!regPass.test(val)) {
        return true;
    }
}