import React from "react";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { Link } from "react-router-dom";

const MeetingDetails = (props) => {
  return (
    <div className="edash edash2 ">
      <div className="container-dash pb-4 pt-4 d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column w-100 align-items-center text-center meeting__container ">
          <h5 className="fm darkGrey fw-light mt-2 fs-3 mb-0">
            Confirm Interview
          </h5>
          <p className="fm darkGrey fw-light">For position:</p>

          <div className=" filter__content__items meeting__company d-flex flex-sm-row flex-column mb-md-0 mb-sm-3  p-3 rounded-3">
            <div className="d-flex justify-content-start">
            <div className="filter__image rounded-3">
              <img
                src={`/assets/images/EnterpriseAvatar.svg`}
                alt=""
                className="lightBlueBar__content__icon__image border-0 rounded-0 me-3"
              />
              
            </div>
            <div>
            <h3 className="filter__content__heading d-sm-none d-block fs-5 text-start mb-1 fw-semibold">
                  Senior Product Designer
                </h3>
                <p className="pro__dash__jobs__para text-start d-sm-none d-block fw-semibold mb-0 text-capitalize text-16 fm me-3">
                    Untitled Co
                  </p>
            </div>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <h3 className="filter__content__heading d-sm-block d-none text-22 mb-1 fw-semibold">
                  Senior Product Designer
                </h3>
              </div>
              <div className="filter__content__para mt-0 d-flex flex-wrap justify-content-start align-items-center mt-ms-0 mt-2">
                <div className="d-sm-block d-none">
                  <p className="pro__dash__jobs__para fw-semibold mb-0 text-capitalize text-16 fm me-3">
                    Untitled Co
                  </p>
                </div>
                <div className="d-flex flex-wrap ">
                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                    <HiOutlineSquare3Stack3D /> Creative & Strategy
                  </p>
                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                    <TbCurrencyDollar /> 30-40K USD
                  </p>
                </div>
              </div>
              <div className="filter__content__tag d-flex flex-wrap mt-2 justify-content-between mt-3">
                <div className="d-flex flex-wrap">
                  <div className="pe-2 mb-sm-0 mb-2">
                    <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center ">
                      <small className="filter__content__tag__content--small text-capitalize text-12">
                        Full-Time
                      </small>
                    </div>
                  </div>
                  <div className="pe-2 mb-sm-0 mb-2">
                    <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center ">
                      <small className="filter__content__tag__content--small text-capitalize text-12">
                        remote
                      </small>
                    </div>
                  </div>

                  <div className="pe-2 mb-sm-0 mb-2">
                    <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center ">
                      <small className="filter__content__tag__content--small text-capitalize text-12">
                        UI/UX
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="d-flex flex-column w-100 align-items-center meeting__container text-center justify-content-center">
          <div className="meetingConfirm mt-4">
            <div className="py-3 meetingConfirm__body">
              <small className="fm darkGrey d-flex align-items-center justify-content-center">
                <img
                  src="/assets/images/icon_calendar-dark.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                9:30 – 10:30 AM, Friday, January 24, 2023
              </small>
              <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_calendar-dark.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                GMT Time
              </small>
              <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_zoom_positive.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                Meeting method: Zoom
              </small>
              <small className="fm midGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_inbox-midGrey.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                To: Ben Smith (email@indexscore.com)
              </small>
            </div>
          </div>
        </div>
        </div>

        <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-5">
          <button
            className={`Get__left__content__para draft__button suggest__btn darkGrey border-darkGrey mb-3 me-sm-2`}>
            <Link to={'/enterprise/dashboard/meeting/time_and_date'} className="text-decoration-none darkGrey">
            Suggest alternate time</Link>
          </button>

          <button
            className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3 me-sm-2`}
          >
           <Link to={'/enterprise/dashboard/meeting/confirm'} className="text-decoration-none text-white">
           Accept</Link>
          </button>
          <button className="midGrey fm meeting__btn1 d-flex align-items-center mb-3">Decline</button>
        </div>
      </div>
    </div>
  );
};

export default MeetingDetails;
