import React, { useState, useContext, useEffect, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Input } from "antd";
// import { isEmail, } from '../../Utilities/utilities'
import {
  verifyOTP,
  getProfessionalById,
  getMemberById,
  getEnterpriseByID,
  sendOTP,
} from "../../services/auth";
import { Loader } from "rsuite";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import "rsuite/dist/rsuite.min.css";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { BsShieldCheck } from "react-icons/bs";
import { MyContext, useAuth } from "../../Utilities/MyContextProvider";

const OTP = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { clearContextData, setUserData, loginAction } = useContext(MyContext);
  const { dispatch } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state?.data;
  const AuthInputRef = useRef(null);

  const [state, setState] = useState({
    result: "",
    loading: false,
    required: false,
  });

  const { loading, result } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const disabled = !result;

  const resendOTP = async () => {
    handleChange("result", "");
    AuthInputRef.current?.clear();
    try {
      const otpPayload = {
        userId: receivedData.usertypeId,
        contact: "+" + receivedData.phoneNumber,
        usertype: receivedData.usertype,
      };

      const sendOtpCode = await sendOTP(otpPayload);
      if (sendOtpCode.data.statusCode === 1) {
        // Show success snackbar
        enqueueSnackbar("OTP Resent Successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        // Show error snackbar
        enqueueSnackbar("Failed to Resend OTP. Please try again.", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {      
      enqueueSnackbar("Error resending OTP. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleOTPSubmit = async () => {
    try {
      handleChange("loading", true);

      const verifyOtpPayload = {
        userId: receivedData.usertypeId,
        otp: result,
        usertype: receivedData.usertype,
      };

      const verifyOtpResponse = await verifyOTP(verifyOtpPayload);

      if (verifyOtpResponse.data.statusCode === 1) {
        enqueueSnackbar(`${verifyOtpResponse.data.data.message}`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        if (verifyOtpResponse.data.data.user.usertype === "enterprise") {
          const payLoadtoGetEnt = {
            _id: receivedData.usertypeId,
          };
          localStorage.setItem("enterpriseID", receivedData.usertypeId);
          localStorage.setItem("_id", receivedData._id);
          localStorage.setItem(
            "userEmail",
            verifyOtpResponse.data.data.user.emailAddress
          );
          localStorage.setItem(
            "userProfileName",
            verifyOtpResponse.data.data.user.firstName +
              " " +
              verifyOtpResponse.data.data.user.lastName
          );
          localStorage.setItem(
            "userRole",
            verifyOtpResponse.data.data.user.role
          );

          const respEnterpriseByID = await getEnterpriseByID(payLoadtoGetEnt);
          const responseEnterpriseData = respEnterpriseByID.data.data;

          const user = responseEnterpriseData[0];
          localStorage.setItem("companyID", user.companyID);
          loginAction(
            user,
            "enterprise",
            verifyOtpResponse.data.data.user.role
          );
          setUserData(responseEnterpriseData);
          navigate(`/enterprise/dashboard`);
        } else {
          var payLoadtoGetPro = {
            _id: receivedData.usertypeId,
          };

          localStorage.setItem("enterpriseID", receivedData.usertypeId);
          localStorage.setItem("_id", receivedData._id);
          localStorage.setItem("userEmail", receivedData.emailAddress);
          localStorage.setItem(
            "userProfileName",
            verifyOtpResponse.data.data.user.firstName +
              " " +
              verifyOtpResponse.data.data.user.lastName
          );

          const respProfessionalByID = await getProfessionalById(
            payLoadtoGetPro
          );
          const responseProfessionalData = respProfessionalByID.data.data;
          localStorage.setItem(
            "professionalData",
            JSON.stringify(respProfessionalByID.data.data)
          );
          const user = responseProfessionalData[0];
          loginAction(user, "professional");
          navigate(`/candidate/dashboard`, {
            state: { responseProfessionalData },
          });
          setUserData(responseProfessionalData);
        }
      } else {
        enqueueSnackbar(`${verifyOtpResponse.data.status}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error while verifying OTP`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      handleChange("loading", false);
    }
  };

  return (
    <div className="forgot  d-flex align-items-center w-100 pt-5 mt-5">
      <div className="main-padding w-100 h-100 ">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-md-6  py-5">
              <div className="otp__box mb-4 text-center d-flex justify-content-center flex-column align-items-center">
                <div className="otp__protect__icon">
                  <BsShieldCheck />
                </div>
                <h5 className="mb-4">Two-Factor Authentication</h5>
                <div className="otp__protect__fields">
                  <AuthCode
                    allowedCharacters="numeric"
                    ref={AuthInputRef}
                    onChange={(value) => {
                      handleChange("result", value);
                    }}
                  />
                  <p className="mt-3 text-14 fm">
                    A message with a verification code has been sent to your
                    phone number. Enter the code to continue.
                  </p>
                  <div className="otp__cta">
                    <button
                      onClick={() => {
                        resendOTP();
                      }}
                      className="resend__otp__button fm"
                    >
                      Resend Otp
                    </button>
                    <button
                      onClick={() => handleOTPSubmit()}
                      disabled={loading}
                      className={`forgot__btn fm py-2 px-5 mb-5`}
                    >
                      {loading ? <Loader /> : "Verify OTP"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTP;
