import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBarEnterprise from "../components/ProgressBarEnterprise/ProgressBarEnterprise.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import ESignup from "../pageComponents/Enterprise/Signup/ESignup.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const EnterpriseSignup = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <Container progress={<ProgressBarEnterprise page= "1" title="Enterprise" />} right={<ESignup />} />
      <Footer />
    </>
  );
};

export default EnterpriseSignup;
