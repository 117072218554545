import React, { useState } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { Input } from 'antd';
import { resetPasswordApi } from "../../services/auth";
import { Loader } from 'rsuite'
import PasswordStrengthBar from 'react-password-strength-bar'
import { isPassword } from '../../Utilities/utilities'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'rsuite/dist/rsuite.min.css'
import { useParams } from "react-router-dom";

const Reset = () => {

  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();

  const [disabledState, setDisabledState] = useState(false);



  const [state, setState] = useState({
    password: '',
    loading: false,
    required: false,
  })
  const {
    loading,
    required,
    password,
  } = state


  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const disabled = !password

  const handleReset = async () => {
    try {
      if (disabled) {
        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        _id: id,
        password: password,
      }
      const res = await resetPasswordApi(payload)
      if (res) {
        if (res.status === 200) {

          handleChange('loading', false)
          enqueueSnackbar('Password changed successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          handleChange('password', '');         
          navigate(`/log-in`);
        }
      }
      else {
        handleChange('loading', false);
        enqueueSnackbar(`Error!${res.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });

      }



    } catch (error) {
      handleChange('loading', false)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }


  return (
    <div className="forgot  d-flex align-items-center w-100 pt-5">
      <div className="main-padding w-100 h-100 ">
        <div className="container-xl px-0 py-5">
          <div className="forgot__box p-sm-4 px-3 py-4 fm">
            <h4 className="fs-5 fm darkBlue fw-semibold text-uppercase">
              RESET PASSWORD
            </h4>
            <h1 className="fw-light fm darkgrey fs-2 mt-4">
              New password
            </h1>
            <p className="fm darkGrey text-14">
              Please create a new password for your IndexScore account
            </p>
            <Input.Password size="large" placeholder="Password" value={password} onChange={value =>
              handleChange('password', value.target.value)
            } className='forgot__input mb-2' />
            
            <div className="col-md-8">
              {password && isPassword(password) && (
                <div>
                  {/* <small >passWord is required</small> */}
                  <small className="error__message">
                    Must be at least 8 characters long and include
                    upper and lowercase letters and at least one
                    number
                  </small>
                </div>
              )}
              {required && !password && (
                <div>
                  <small className="error__message">Password is required!</small>
                </div>
              )}
              <PasswordStrengthBar
                style={{ marginTop: 10 }}
                password={password}
              />
            </div>
            {/* <Link to={"/log-in"}> */}
              <button onClick={() => handleReset()} className="forgot__btn fm py-2 px-3 mt-3">Confirm</button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reset