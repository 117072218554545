import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const EThankYou = (props) => {
  return (
    <div className="Get py-lg-5 my-lg-5 pb-sm-5 pb-3">
      <div className="container-fluid py-lg-5 pb-5 mt-xxl-5 mt-lg-4">
        <div className="row">
          <div className="col-lg-6 d-flex justify-content-lg-end px-lg-2 px-0">
            <div className={`Get__left Get__left--${props.color} p-xl-5 p-lg-4 py-4 d-flex flex-column justify-content-between main-padding`}>
              <div className="Get__left__content">
                <h6 className="Get__left__content__title text-uppercase pb-0">
                  {props.title}
                </h6>
                <h1 className="Get__left__content__heading mt-xl-5 mt-lg-4 mb-4">
                  Getting Started
                </h1>
                <p className="Get__left__content__para">
                  {props.para1}
                </p>
                <p className="Get__left__content__para">
                  {props.para2}
                </p>
              </div>

              <div className={`Get__left__bottom Get__left__bottom--${props.color} px-md-4 py-md-5 px-3 py-4 mt-5`}>
                <p className="Get__left__content__para Get__left__bottom--para">
                "This is truly a game changer. I didn't only secure a job in a week as a Beta user, I can now share my score with hiring managers securely in seconds knowing I'm qualified for the open role".
                </p>
                <div className="Get__left__bottom__info d-flex ">
                  <div className="Get__left__bottom__info__block"></div>
                  <div className="Get__left__bottom__info__content ps-3 d-flex flex-column justify-content-center">
                    <p className="Get__left__bottom__info__content__para Get__left__content__para mb-0">
                    Jennifer D. 
                    </p>
                    <p className="Get__left__bottom__info__content__para Get__left__content__para mb-0">
                    Digital Transformation Director <br/>
                    Global Pharma Company
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-2 ps-0 pe-0">
            <div className="Get__right p-xl-5 p-lg-4 py-4 h-100 main-padding">
            <div className="Get__right--width">
              <h1 className="Get__left__content__heading Enterprise__thank__you mt-lg-5 mb-4 pt-xl-3 pt-3">
              Thank you for signing up!
              </h1>
              <p className="Get__left__content__para Get__right__para">
              You will shortly receive an email from IndexScore.com to authenticate your account to continue the sign up process.
                {/* <Link
                  to={"/"}
                  className="text-decoration-none Get__right__para__link"
                >
                  {" "}
                  Sign in here
                  <FiArrowRight className="Get__right__para__link__icon" />
                </Link> */}
              </p>
              {/* <p className="thank__you__email__message">Email sent to: xyz@email.com</p> */}
              {/* <p className="thank__you__not__received__code">Did not receive your email? Please <Link
                  to={"/"}
                  className="text-decoration-none Get__right__para      vbbbbbbbbb__link"
                >
                  {" "}
                  click here
                  
                </Link> to resend.</p> */}
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EThankYou;
