import React, { useState, useEffect } from "react";
import {HiArrowLeft} from "react-icons/hi2"
import Calendar from 'react-calendar';
import { Link } from "react-router-dom";


function TimeSeries({ start, interval }) {
    const [times, setTimes] = useState([]);
    const [activeTime, setActiveTime] = useState(null);
    useEffect(() => {
      const startTime = new Date(`1/1/2000 ${start}`);
      const endTime = new Date(`1/1/2000 11:59 PM`);
  
      const times = [];
      for (let time = startTime; time <= endTime; time.setTime(time.getTime() + interval * 60 * 1000)) {
        times.push(time.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }));
      }
      setTimes(times);
    }, [start, interval]);

    const handleTimeClick = (time) => {
        setActiveTime(time);
      };
  
    return (
      <div className="timeSchedule ps-1">
        {times.map((time, i) => (
          <div key={i} className="text-start py-2 px-3 fm timeSchedule--div mb-2 me-2" onClick={() => handleTimeClick(time)}
          style={{
            backgroundColor: activeTime === time ? "#12d3db" : "",
            color: activeTime === time ? "#fff" : "",
            borderColor: activeTime === time ? "#12d3db" : ""
          }}
          >{time}</div>
        ))}
      </div>
    );
  }

const MeetingTime = (props) => {
  const [value, onChange] = useState(new Date());
  return (
    <div className="edash edash2 ">
      <div className="container-dash pb-4  pt-4  d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column align-items-center text-center meeting__container">
          <h5 className="fm darkGrey fw-normal  mt-2 fs-4 pb-3">Choose alternative date & time</h5>
          <div className="d-flex meetingDate pb-2">
           
            <Calendar onChange={onChange} value={value} />
          </div>
          <TimeSeries start="1:00 AM" interval={30} />
        </div>
        

        <div className="d-flex flex-wrap mt-5 w-100 justify-content-center align-items-center pt-sm-5">
              <button
                className={`Get__left__content__para draft__button border-darkGrey darkGrey mb-3 me-sm-2`}
              >
               <Link to={'/enterprise/dashboard/meeting'} className="text-decoration-none darkGrey">
           Back</Link>
              </button>

              <button
                className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3 me-sm-2`} 
                >
                <Link to={'/enterprise/dashboard/meeting/confirm'} className="text-decoration-none text-white">
           Confirm</Link>
              </button>

              <button className="midGrey fm meeting__btn1 d-flex align-items-center mb-3">Decline</button>
            </div>
      </div>
    </div>
  );
};

export default MeetingTime;
