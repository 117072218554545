import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Link,
  Font,
} from "@react-pdf/renderer";

const ReferenceFeedback = ({ referenceFeedbacks }) => {
  
  Font.register({
    family: "Open Sans",
    fonts: [
      { src: "/assets/fonts/OpenSans-Regular.ttf", fontWeight: 600 },
      { src: "/assets/fonts/OpenSans-Bold.ttf", fontWeight: 800 },
      { src: "/assets/fonts/OpenSans-ExtraBold.ttf", fontWeight: 900 },
      { src: "/assets/fonts/OpenSans-SemiBold.ttf", fontWeight: 700 },
      { src: "/assets/fonts/OpenSans-Light.ttf", fontWeight: 400 },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topBar: {
      height: 6,
      backgroundColor: "#B2DA66",
      marginBottom: 10,
    },

    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 30,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 17,
      marginTop: 20,
    },
    rating: {
      width: 9,
      height: 9,
      display: "block",
      marginRight: 3,
    },
    heading: {
      display: "block",
      marginBottom: 10,
      fontSize: 18,
      fontWeight: 800,
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 14,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 800,
    },
    feedbackTitle: {
      display: "block",
      fontSize: 10,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 600,
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
    },
    card: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#E6E6E6",
      marginTop: 5,
      marginBottom: 25,
    },
    cardTop: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#F5F5F5",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 12,
    },
    cardBottom: {
      fontFamily: "Open Sans",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 10,
      paddingBottom: 18,
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 18,
      minHeight: 18,
      width: 15,
      height: 15,
      marginRight: 5,
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      color: "#B2DA66",
      fontSize: 9,
      fontWeight: "600",
      display: "block",
      marginleft: 30,
    },
    list: {
      width: "80%",
      marginBottom: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: 'space-between'
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    bulletDot: {
      color: "black",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginRight: 2,
      marginLeft: 3,
    },
  });


  const generateStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(
        <Image
          key={i}
          src="/assets/images/star-image.png"
          style={styles.rating}
          alt="star"
        />
      );
    }
    return stars;
  };

  return (
    <Document style={styles.doc}>
      {referenceFeedbacks.map((docResponse, index) => (
      <Page style={styles.page}>
        <View>
          <View style={styles.topBar} />
          <View style={styles.container}>
            <Image
              src="/assets/svg/logo.png"
              style={styles.logo}
              alt="logo"
            />

            <Text style={styles.heading}>Reference Feedback of {docResponse?.professionalName}</Text>

            <View style={styles.card}>
              {/* card */}
              <View style={styles.cardTop}>
                <Text style={styles.subTitle}>{docResponse?.firstName + ' ' + docResponse?.lastName}</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                  >
                    Company : {docResponse?.company}
                  </Text>
                </View>
                <Text style={styles.feedbackTitle}>Feedback:</Text>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 10,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    marginTop: 1,
                  }}
                >
                  {docResponse?.feedback}
                </Text>
                <Text style={styles.feedbackTitle}>Overall experience</Text>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 10,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    marginTop: 1,
                  }}
                >
                  {docResponse?.overall_experience}
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: 8,
                    width: "100%",
                    paddingBottom: 10,
                  }}
                >
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Quality rating
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 2,
                      }}
                    >
                      {generateStars(parseInt(docResponse?.quality_rating))}
                    </View>
                  </View>
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Years Known
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      {docResponse?.years_known}
                    </Text>
                  </View>

                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Start Date:  {docResponse?.start_date}
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      End Date: {docResponse?.end_date}
                    </Text>
                  </View>

                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    marginTop: 8,
                    width: "100%",
                    paddingBottom: 10,
                  }}
                >
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Division:
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      {docResponse?.division}
                    </Text>
                  </View>

                  <View style={{ width: "67%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        textAlign: "start",
                      }}
                    >
                      Linkedin url:
                    </Text>
                    <Link
                      href={docResponse?.linkedinPublicUrl}
                      style={{
                        color: "#0D2159",
                        fontFamily: "Open Sans",
                        fontWeight: 700,
                        fontSize: 8,
                        textDecoration: "none",
                      }}
                    >
                      {docResponse?.linkedinPublicUrl}
                    </Link>
                  </View>
                </View>
              </View>
            </View>

            {/* 1st */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                In order of importance (1 being the lowest and 5 the highest), how would you arrange the following attribute about {docResponse?.professionalName}?
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Detailed oriented:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.general_attributes?.detail_oriendted}</Text>
                    </View>

                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Reliable:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.general_attributes?.reliable}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Collabortive:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.general_attributes?.collabartive}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Great listener:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.general_attributes?.listener}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > A leader:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.general_attributes?.leader}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* 2nd */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                In order of importance (1 being the lowest and 5 the highest), how did {docResponse?.professionalName} solve project related challenges:
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Collaborative:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.project_attributes?.colloborative}</Text>
                    </View>

                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Alone:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.project_attributes?.alone}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Through influence:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.project_attributes?.through_influence}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Avoided conflict:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.project_attributes?.avoided_conflict}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Required manager to step in:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.project_attributes?.required_manager_intervention}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* 3rd */}

            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                From area of development to strength, how would you order the attributes (1 being the lowest and 5 the highest) about {docResponse?.professionalName} below?
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Communication:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.strength_attributes?.communication}</Text>
                    </View>

                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Collaboration:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.strength_attributes?.colloboration}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Leadership:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.strength_attributes?.leadership}</Text>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Listening Skills:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.strength_attributes?.listening_skills}</Text>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    > Reliability:
                    </Text>
                    <View>
                      <Text style={styles.bullet}>{docResponse?.strength_attributes?.realiability}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#B2DA66",
            padding: 13,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <View>
            <Text
              style={{
                color: "#0D2159",
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 800,
                textTransform: "uppercase",
              }}
            >
              Next Steps
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "#4A4A49",
                fontWeight: 600,
                fontFamily: "Open Sans",
              }}
            >
              Please contact{" "}
              <Text
                style={{
                  color: "#4A4A49",
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                }}
              >
                IndexScore
              </Text>{" "}
              at{" "}
              <Link
                src={"help@IndexScore.com"}
                style={{
                  color: "#0D2159",
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                help@IndexScore.com
              </Link>{" "}
              if you have any questions.
            </Text>
          </View>
        </View>
      </Page>
      ))}
    </Document>
  );
};

export default ReferenceFeedback;
