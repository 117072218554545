import Axios from 'axios';
import config from './apisConfig';
import environment from "../environment/index";
let token = environment.Criteria_Token ;

export const getPackages = async () => {    
    try {

        const endpoint = config.criteria.getPackages;
        Axios.defaults.headers.common["authorization"] = `Bearer ${token}`
        const res = await Axios.get(endpoint)
        const data  = res
        return data 
    
    } catch (err) {

        console.log(err)
        return err;
    };
};

export const getStauts = async (payload) => {    
    try {

        const endpoint = config.criteria.getStatus;
        Axios.defaults.headers.common["authorization"] = `Bearer ${token}`
        const res = await Axios.get(endpoint,payload)
        const data  = res
        return data 
    
    } catch (err) {
        
        console.log(err)
        return err;
    };
};

export const getScores = async (payload) => {    
    try {

        const endpoint = config.criteria.getScores;
        Axios.defaults.headers.common["authorization"] = `Bearer ${token}`
        const res = await Axios.get(endpoint+`=${payload}`)
        const data  = res
        return data 
    
    } catch (err) {
        
        console.log(err)
        return err;
    };
};

export const createOrder = async (payload) => {  
    try {

        const endpoint = config.criteria.createOrder;
        Axios.defaults.headers.common["authorization"] = `Bearer ${token}`
        const res = await Axios.post(endpoint,payload)
        const data  = res
        return data
            
    } catch (err) {

        console.log(err)
        return err;

    }
}