import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const cards = [
  {
    id: 1,
    title: "40+ days to hire is cut to 3 days & under",
    para: "With a sizeable 10-15% of an enterprise’s staff being filled by contractors, your recruitment is likely regular, lengthy, and expensive — a process IndexScore vastly reduces for you both in time and cost.",
  },
  {
    id: 2,
    title: "Ensuring retention from the beginning",
    para: "Never wait for mishaps to find out if a candidate aligns with your values. With assessments that objectively cover personality, values, and interests, you’ll know beforehand who’s most likely to stay with you for the long-term.",
  },
  {
    id: 3,
    title: "Supporting the ‘social’ in ESG",
    para: "For all the good intentions of recruiters and recruitment agencies, bias is a too-human element that simply happens unconsciously. With our standardized scoring across all candidates, you’ll ensure your hiring is organically all-inclusive.",
  },
];

const UESection1 = () => {
  return (
    <div className="ues1 main-padding">
      <div className="container-xl px-0">
        <div className="h-100 fm d-flex flex-column align-items-center overflow-hidden top-padding ">
          <h1 className="darkBlue fw-semibold fm fs-1 uhs1__heading text-center">
            The end of quasi-informed hiring decisions
          </h1>
          <p className="darkGrey fm ues1__para text-center text-xxl-16">
            With one score distilling the most comprehensive human + AI data on
            any candidate, Chief HR Officers can now show up as the strategic
            partner: finding talent rapidly, extremely cost-effectively, and
            above all, retain the best talent.
          </p>

          <div className="ues1__image rounded-3 px-md-4 mt-md-4 mt-2">
            <img
              src="/assets/UpdatedFrontend/Enterprise/enterprisedash.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* section2 */}
      <div className="ues2 mt-5">
        <div className="container-xl px-xl-5 px-0 pt-4">
          <div className="row px-xl-5">
            {cards.map((item) => (
              <div className="col-lg-4 col-sm-6 mb-4 pe-lg-0" key={item.id}>
                <div className={`ues2__card ues2__card${item.id}`}>
                  <div
                    className={`ues2__card__top ues2__card${item.id}__top rounded-3 d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={`/assets/UpdatedFrontend/Enterprise/card${item.id}.png`}
                      alt=""
                      className="img-fluid "
                    />
                  </div>

                  <h3 className="fm fs-4 fw-semibold text-center mx-auto mt-3">
                    {item.title}
                  </h3>
                  <p className="fm text-center text-14 mx-auto pt-2">
                    {item.para}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center">
            <Link to={"/enterprise/signup"} className="text-decoration-none">
              <button className="btn--blue text-white fm mx-auto rounded-2 mt-md-3 mb-5">
                Hire Talent
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UESection1;
