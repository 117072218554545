import React from 'react'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBar from "../components/ProgressBar/ProgressBar.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Intro from '../pageComponents/Enterprise/VideoIntro/Intro.jsx';
import UpdatedNav from '../UpdatedFrontend/Navbar/UpdatedNav.jsx';

const   ProfessionalVideoIntro = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="professional-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBar page="6" title="Candidate" />}
        right={<Intro
        color="blue"
        page="/professional/signup/consent" 
        pagename= "professional"
        />}
      />
      <Footer />
    </>
  )
}

export default  ProfessionalVideoIntro