import React from "react";
import "./style.css";

const card=[
  {
    id: 1,
    title: "Flexible pricing",
    para: "Select the pricing the suits you best select from any of our plans or contact us for a bespoke package to fit your enterprises needs."
  },
  {
    id: 2,
    title: "Immediately find candidates that match",
    para: "Select the pricing the suits you best select from any of our plans or contact us for a bespoke package to fit your enterprises needs."
  },
  {
    id: 3,
    title: "Takes weeks off the process",
    para: "IndexScore hiring process is simple. We cut down hiring time by up to 75%."
  },
]

const WhySection1 = () => {
  return (
    <div className="Whysection1 main-padding bg-white">
      <div className="container-lg px-0 py-5">
      <div className="row d-md-none d-flex">
            {card.map((item)=>(
              <div className="col-lg-4 col-sm-6 pt-5 pe-4 mb-3">
              <div className="Whysection1__top d-flex justify-content-center align-items-center p-2">
                <img
                  src={`./assets/images/WhyIndexcore/WhySection1Icon${item.id}.png`}
                  alt=""
                  className="Whysection1__top--img img-fluid"
                />
              </div>
              <p className="fm text-18 darkBlue fw-semibold mt-2 mb-2">{item.title}</p>
              <p className="fm text-18 darkGrey">
               {item.para}
              </p>
            </div>
            ))}
      </div>
    </div>
    </div>
  );
};

export default WhySection1;
