import React from 'react'
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBarEnterprise from "../components/ProgressBarEnterprise/ProgressBarEnterprise.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Intro from '../pageComponents/Enterprise/VideoIntro/Intro.jsx';
import UpdatedNav from '../UpdatedFrontend/Navbar/UpdatedNav.jsx';

const EnterpriseVideoIntro = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBarEnterprise page="6" title="Enterprise" />}
        right={<Intro
        color ="green"
        page="/enterprise/signup/submit" 
        pagename= "enterprise"
        />}
      />
      <Footer />
    </>
  )
}

export default EnterpriseVideoIntro