import React, { useState, useContext, useEffect } from "react";
import { render } from "react-dom";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { useNavigate } from "react-router-dom";
import { Avatar, Modal, Button } from "antd";
import "./style.css";
import { HiDotsVertical } from "react-icons/hi";
import Persona from "persona";
import { isPassword } from "../../../../Utilities/utilities";
import { useSnackbar } from "notistack";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import PasswordStrengthBar from "react-password-strength-bar";
import environment from "../../../../environment/index";
import {
  resetPasswordApi,
  generate2faSecret,
  verify2faSecret,
  updateEnterprise,
  getEnterpriseByID
} from "../../../../services/auth";

// const userList = [
//   {
//     id: 1,
//     name: "Ben Smith",
//     email: "Bensmith@email.com",
//     role: "Administrator",
//   },
//   {
//     id: 2,
//     name: "Ben Smith",
//     email: "Bensmith@email.com",
//     role: "Administrator",
//   },
//   {
//     id: 3,
//     name: "Ben Smith",
//     email: "Bensmith@email.com",
//     role: "Administrator",
//   },
//   {
//     id: 4,
//     name: "Ben Smith",
//     email: "Bensmith@email.com",
//     role: "HR",
//   },
// ];

const authApp = [
  {
    id: 1,
    name: "Authenticator App",
    desc: "Google authenticator app",
    buttontext: "Setup",
  },
  // {
  //   id: 2,
  //   name: "Secondary e-mail",
  //   desc: "Add another email to request code",
  //   buttontext: "Setup",
  // },
  {
    id: 3,
    name: "SMS two-factor",
    desc: "Add phone number to receive code",
    buttontext: "Setup",
  },
];

const devices__list = [
  {
    id: 1,
    name: "iPhone 11",
    desc: "London, UK • Jan 1st at 11:59am",
  },
  {
    id: 2,
    name: "Secondary e-mail",
    desc: "Add another email to request code",
    buttontext: "Setup",
  },
  {
    id: 3,
    name: "SMS two-factor",
    desc: "Add phone number to receive code",
    buttontext: "Setup",
  },
];

const ProSecurity = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { clearContextData, getUserData, logoutAction, clearUserData,setUserData } =
    useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [options, setOptions] = useState({
    templateId: "itmpl_g5dqDwogkc37Dos1GhiqkJvw",
  });
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    loading: false,
    required: false,
  });
  const { loading, required, password, confirmPassword } = state;
  const [googelAuthModalVisible, setGoogelAuthModalVisible] = useState(false);
  const [authVerifyLoading, setAuthVerifyLoading] = useState(false);
  const [secret, setSecret] = useState("");
  const [qrCodeUrlData, setQrCodeUrlData] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(null);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !password || !confirmPassword || password !== confirmPassword;

  const createClient = () => {
    const client = new Persona.Client({
      ...options,
      environment: environment.Persona_Environment,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (inquiryId) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        //fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const handlePersonaLink = () => {
    const currentUrl = window.location.href;
    const personaLink = `https://withpersona.com/verify?inquiry-template-id=itmpl_8puaBDx8HQ6MGwTf1AdbmnkZ&environment-id=env_njehZjmGfeDjy6wovb73DRpp&redirect-uri=${currentUrl}`;
    window.location.href = personaLink;
  };

  const handleReset = async () => {
    try {
      if (disabled) {
        handleChange("required", true);
        return;
      }
      handleChange("required", false);
      handleChange("loading", true);
      const payload = {
        _id: userData.userID,
        password: password,
      };
      console.log("payload", payload);
      const res = await resetPasswordApi(payload);
      console.log("res payload", res);
      if (res) {
        if (res.data.statusCode === 1) {
          handleChange("loading", false);
          enqueueSnackbar(`${res.data.statusDesc}`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          handleChange("password", "");
          handleChange("confirmPassword", "");
          logOutUser();
        }
      } else {
        handleChange("loading", false);
        enqueueSnackbar(`Error!${res.message}`, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      handleChange("loading", false);
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  function logOutUser() {
    navigate(`/log-in`);

    localStorage.clear();
    clearUserData();
    clearContextData();
    logoutAction();
  }

  const gooogleAuthModal = () => {
    setGoogelAuthModalVisible(true);
  };
  const gooogleAuthModalOk = () => {
    setGoogelAuthModalVisible(false);
  };
  const gooogleAuthModalCancel = () => {
    setGoogelAuthModalVisible(false);
  };

  const generateSecret = async () => {
    try {
      const response = await generate2faSecret();
      console.log("res generate2faSecret", response);
      if (response) {
        if (response.data) {
          setSecret(response.data.secret);
          setQrCodeUrlData(response.data.qrCodeUrl);
        }
      }
    } catch (error) {
      console.log("error message", error.message);
    }
  };

  const verifyCode = async () => {
    try {
      const payload = {
        secret,
        code: inputValue,
      };

      const response = await verify2faSecret(payload);
      console.log("res verify2faSecret", response);
      if (response) {
        setIsCodeValid(response.data.verified);
        if(response.data.verified){
          const payload = {
            _id: userData._id,
            twoFa: true,
          };
          const responseTwoFa = await updateEnterprise(payload);
          updatedEnterpriseData();
          gooogleAuthModalOk();
          enqueueSnackbar(
            "Verified",
            {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            },
            { autoHideDuration: 1000 }
          );
        }
        setInputValue("");
      }
    } catch (error) {
      console.log("error message", error.message);
    }
  };

  const updatedEnterpriseData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respEnterpriseByID = await getEnterpriseByID(payLoadtoGetPro);
    const responseEnterpriseData = respEnterpriseByID.data.data;
    localStorage.setItem(
      "enterpriseData",
      JSON.stringify(respEnterpriseByID.data.data)
    );
    setUserData(responseEnterpriseData);    
  };

  return (
    <div className="Suser pb-5">
      <div className="Dashboard__container pe-2">
        <div className="Dashboard__container__content">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-lg-6 pe-lg-4 pe-0">
                <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-lg-4">
                  Two-factor authentication
                </p>
                <p className="fm darkGrey mb-2 mt-0 text-14">
                  Link or add a two-factor authenticator to your account to make
                  sure you account is secure from cyber threats.
                </p>
                <div className=" rounded">
                  <div className="two__factor__auth__form py-sm-3 pt-3 pb-2  Susers__right__item border-bottom d-flex flex-sm-row flex-column justify-content-between align-items-sm-center">
                    <div className="d-flex align-items-center me-3 my-2">
                      <div className=" w-100 h-100">
                        <p className=" black mb-0 fw-semibold cursor-default text-14">
                          ID Verification
                        </p>
                        <p className=" darkGrey mb-0 mt-0 cursor-default text-14">
                          Please submit your identity for verifcation
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      <button
                        onClick={handlePersonaLink}
                        disabled={userData.persona ? true : false}
                        className={`two__factor__buttons transition text-14 d-flex justify-content-center align-items-center px-5  py-2 ${
                          userData.persona ? "connected" : "pending"
                        }`}
                      >
                        Verify{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" rounded">
                  <div className="two__factor__auth__form Susers__right__item py-sm-3 pt-3 pb-2 border-sm-none border-bottom d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-3 my-2">
                      <div className=" w-100 h-100">
                        <p className=" black mb-0 fw-semibold cursor-default text-14">
                          Authenticator App
                        </p>
                        <p className=" darkGrey mb-0 cursor-default text-14 mt-0">
                          Google authenticator app
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      <button
                        onClick={gooogleAuthModal}
                        disabled={userData.twoFa}
                        className="two__factor__buttons transition text-14 d-flex justify-content-center align-items-center px-5  py-2"
                      >
                        {userData && userData.twoFa ? 'Verified': 'Setup'}
                      </button>
                    </div>
                  </div>
                  {/*  */}

                  <div className="two__factor__auth__form Susers__right__item py-sm-3 pt-3 pb-2 border-sm-none border-bottom d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-3 my-2">
                      <div className=" w-100 h-100">
                        <p className=" black mb-0 fw-semibold cursor-default text-14">
                          SMS two-factor
                        </p>
                        <p className=" darkGrey mb-0 cursor-default text-14 mt-0">
                          Add phone number to receive code
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center my-3">
                      <button className="two__factor__buttons transition text-14 d-flex justify-content-center align-items-center px-5  py-2">
                        Setup
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
                <div className="col-lg-6 pe-lg-4 pe-0">
                  <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                    Password
                  </p>
                  <p className="fm darkGrey mb-2 mt-0 text-14">
                    Need to change your password?
                  </p>
                  <div className="col-sm-12 ps-0 mb-4">
                    <label className="darkGrey fm mb-2 fw-semibold text-14">
                      New password
                    </label>
                    <input
                      type="password"
                      className="form-control form-control-lg input mb-3"
                      placeholder="New Password"
                      value={password}
                      onChange={(value) =>
                        handleChange("password", value.target.value)
                      }
                    />
                    {password && isPassword(password) && (
                      <div>
                        {/* <small >passWord is required</small> */}
                        <small className="error__message fm">
                          Must be at least 8 characters long and include upper
                          and lowercase letters and at least one number
                        </small>
                      </div>
                    )}
                    {required && !password && (
                      <div>
                        <small className="error__message fm">
                          Password is required!
                        </small>
                      </div>
                    )}
                    <PasswordStrengthBar
                      style={{ marginTop: 10 }}
                      password={password}
                    />
                    <input
                      type="password"
                      className="form-control form-control-lg input"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(value) =>
                        handleChange("confirmPassword", value.target.value)
                      }
                    />
                    {confirmPassword && password !== confirmPassword && (
                      <div>
                        <small className="error__message fm">
                          Passwords must match!
                        </small>
                      </div>
                    )}
                    {required && !confirmPassword && (
                      <div>
                        <small className="error__message fm">
                          Confirm password is required!
                        </small>
                      </div>
                    )}
                  </div>

                  <button
                    disabled={!password || !confirmPassword || loading}
                    onClick={() => handleReset()}
                    className={`fm invite-button mt-2 `}
                  >
                    {loading ? <Loader /> : "Submit"}
                  </button>
                </div>
              
              {/* <div className="col-lg-6 pt-4 pe-0">
                <div className="Susers__right px-2 rounded">
                  <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4 pt-3">
                    Devices
                  </p>
                  <p className="fm darkGrey mb-2">
                    Current devices that have logged into your account.
                  </p>
                  <button className="remove__devices text-14 d-flex jobd__btn justify-content-center align-items-center px-5  py-2">
                    Remove all devices
                  </button>
                  <div className="devices__connected__list rounded-3">
                    {devices__list.map((item) => (
                      <div
                        className=" border-sm-none border-bottom py-sm-3 pt-3 pb-2 d-flex my-2 justify-content-between align-items-center"
                        key={item.id}
                      >
                        <div className="d-flex align-items-center me-3 my-2">
                          <Avatar
                            className="device__sample cursor-default"
                            src={"/assets/images/Dashboard/device-sample.png"}
                          />
                          <div className="ps-2 w-100 h-100">
                            <p className=" black mb-0 fw-semibold cursor-default text-14">
                              {item.name}
                            </p>
                            <p className=" lightGrey mb-0 mt-0 cursor-default text-14">
                              {item.desc}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center my-2">
                          <HiDotsVertical className="darkGrey fs-4 ms-2" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
            </div>            
          </div>
        </div>
      </div>
      <Modal
        className="certification-popup twofa-modal"
        title="Verify 2FA Google "
        visible={googelAuthModalVisible}
        onOk={gooogleAuthModalOk}
        onCancel={gooogleAuthModalCancel}
        footer={null}
        width={300}
      >
        <div className="add-cert-form edit-name-popup photo-popup">
          <div className="row">
            <div className="px-0">
              <div>
                {qrCodeUrlData && (
                  <img
                    src={qrCodeUrlData}
                    alt="QR Code"
                    className="qr-code-image"
                  />
                )}
              </div>
              <button
                onClick={generateSecret}
                className="desc__applynow generate__button py-2 fm transition px-3 me-2"
              >
                Generate Secret
              </button>
            </div>
            <div style={{ marginTop: 20 }}>Enter verification code</div>
            <input
              type="text"
              value={inputValue}
              className="form-control form-control-lg input mb-3"
              onChange={(e) => setInputValue(e.target.value)}
            />
            <div>
              {isCodeValid !== null && (
                <div className="px-0">
                  {isCodeValid ? "✅ Code is valid" : "❌ Code is invalid"}
                </div>
              )}
            </div>
            <button
              disabled={!inputValue}
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2 my-4 mt-2"
              onClick={verifyCode}
            >
              Verify
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProSecurity;
