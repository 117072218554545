import React from "react";
import {HiOutlineCheckCircle} from "react-icons/hi2"

const MeetingConfirm = (props) => {
  const Profile = () => {
    props.confirm(false);
    props.profile(true);
    props.meeting(false);
    props.time(false);
    props.detail(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="edash ">
      <div className="container-lg pb-4 pt-5 meeting__container d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column w-100 align-items-center text-center">
          <HiOutlineCheckCircle className="main fs-4 mb-3" />
          <h5 className="fm darkGrey fw-light mt-2 fs-5 pb-3 fs-4">Confirmed</h5>
          <div className="meetingConfirm">
            <div className="text-center w-100 pb-3 mb-2">
              <small className="fm darkGrey text-14">
                An event has been{" "}
                <span className="fw-semibold">added to your calendar</span> &
                sent to {`<`}User{`>`}.
              </small>
            </div>
            <div className="py-3 meetingConfirm__body">
              <small className="fm darkGrey d-flex align-items-center justify-content-center">
                <img
                  src="/assets/images/icon_calendar-dark.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                9:30 – 10:30 AM, Friday, January 24, 2023
              </small>
              <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_calendar-dark.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                GMT Time
              </small>
              <small className="fm darkGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_zoom_positive.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                Meeting method: Zoom
              </small>
              <small className="fm midGrey d-flex align-items-center justify-content-center mt-3">
                <img
                  src="/assets/images/icon_inbox-midGrey.svg"
                  alt=""
                  className="img-fluid candidates__button--img me-1"
                />
                To: Ben Smith (email@indexscore.com)
              </small>
            </div>
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-4">
          <button
            className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2 border-darkGrey`}
            onClick={Profile}
          >
            Go to profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default MeetingConfirm;
