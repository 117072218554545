import environment from '../environment/index.js';
const baseUrl = environment.REACT_APP_DEV_PROXY ;
const baseServerUrl = environment.REACT_APP_DEV_SERVER ;

const criteriaBaseUrl = environment.Criteria_Base_URL;

const config = {

    criteria: {
        getPackages: criteriaBaseUrl + '/packages',
        createOrder: criteriaBaseUrl + '/order',
        getStatus: criteriaBaseUrl + '/status?orderId',
        getScores: criteriaBaseUrl + '/scores?orderId',
    },
    server:{
        serverUrl: baseServerUrl ,
    },
    auth: {
        login: baseUrl + '/login',
        verifyEmail: baseUrl + '/email-verification',
        forgotPassword: baseUrl + '/forgot-password',
        resetPassword: baseUrl + '/change-password',
        contactUs: baseUrl + '/contact-us',
        uploadprofileImage: baseUrl + '/upload-profileImage',
        uploadCompanyImage: baseUrl + '/upload-companyImage',
        addMember: baseUrl + '/register/member',
        SendOtp: baseUrl + '/send-otp',
        VerifyOtp: baseUrl + '/verify-otp',
    },
    professionalSignup: {
        register: baseUrl + '/signup',
        updateSimpleProfessional: baseUrl + '/update-simple-professional',
        kycVerifcation: baseUrl + '/kyc-verification'
    },
    enterpriseSignup: {
        register: baseUrl + '/signup',
        updateSimpleEnterprise: baseUrl + '/update-enterpriseProfile',
    },
    fileUpload: {
        fileUpload: baseUrl + '/upload-resumes'
    },
    videoUpload: {
        uploadVideo: baseUrl + '/upload-videos'
    },
    updatePro: {
        updatePro: baseUrl + '/update-professional',
        showHideIndexscore: baseUrl + '/show-indexscore',
    },
    updateEnterprise: {
        updateEnterprise: baseUrl + '/update-enterprise'
    },
    getProfessionals: {
        getProfessionals: baseUrl + '/get-professionals',
        getProfessionalById: baseUrl + '/get-professionalById'
    },
    createJob: {
        createJob: baseUrl + '/create-job',
        getDescription: baseUrl + '/get-jobDescription',
        updateJob: baseUrl + '/update-job',
        deleteJob: baseUrl + '/delete-job',
        updateJobStatus: baseUrl + '/update-job-status',
        sendJobInvite: baseUrl + '/send-job-invite',
        saveJob: baseUrl + '/save-jobs',
        getSavedJobs: baseUrl + '/getSavedJobs',
        draftJobs: baseUrl + '/draft-jobs'
    },
    questionParser: {
        questionParser: baseUrl + '/questions-parser'
    },
    linkedinReferenceParser: {
        linkedinReferenceParser: baseUrl + '/linkedin-reference-parser'
    },
    accurtateCreateCandidate: {
        createCandidate: baseUrl + '/accurtate-createCandidate'
    },
    accurtateCreateOrder: {
        createOrder: baseUrl + '/accurtate-createOrder'
    },
    getJob: {
        getJob: baseUrl + '/get-jobById'
    },
    getJobById: {
        getJobById: baseUrl + '/get-jobById'
    },
    enterpriseByID: {
        enterpriseByID: baseUrl + '/get-enterpriseById'
    },
    getEnterprises: {
        getEnterprises: baseUrl + '/get-enter'
    },
    proApplyJob: {
        proApplyJob: baseUrl + '/apply-job'
    },
    downloadURL: {
        downloadURL: baseUrl + '/download-files'
    },
    insta: {
        insta: baseUrl + '/insta'
    },
    inboxModule: {
        getInbox: baseUrl + '/get-inboxEmails',
        getSent: baseUrl + '/get-sentEmails',
        compose: baseUrl + '/compose-email',
        uploadAttachments: baseUrl + '/upload-attachments',
    },
    feedback: {
        updateFeedback: baseUrl + '/update-feedback',
        getRef: baseUrl + '/get-refbyId'
    },
    appointments: {
        getCandidatesAppointments: baseUrl + '/get-Candidates-appointments',
        getClientAppointments: baseUrl + '/get-client-appointments',
        createAppointment: baseUrl + '/create-appointment'
    },
    downloadAttachment: {
        downloadAttachment: baseUrl + '/download-files'
    },
    updateCandidate: {
        status: baseUrl + '/update-status'
    },
    applicants: {
        getApplications: baseUrl + '/get-applications',
        getAllApplications: baseUrl + '/get-all-applicants'
    },
    sendInvite: {
        sendInvitation: baseUrl + '/send-interview',
        sendCallInvitation: baseUrl + '/call',
    },
    getInterviews: {
        getInterview: baseUrl + '/get-interviewById',
        getInterviewsProffesional: baseUrl + '/get-interviewByProfessional',
        acceptInterviews: baseUrl + '/accept-interview',
        getInterviewsbyMember: baseUrl + '/get-interviewByMembers',
        updateInterviewsStatus: baseUrl + '/update-interview-status',
        getInterviewPrepById: baseUrl + '/get-prepsheet',
        createtInterviewPrep: baseUrl + '/createPrepsheet',
        getInterviewPrepByAppId: baseUrl + '/get-prepsheet-byAppId',        
        declineInterview: baseUrl + '/decline-interview',
    },
    comments: {
        createComment: baseUrl + '/create-comment',
        getComments: baseUrl + '/get-comments',
        createReply: baseUrl + '/create-reply',

    },
    contract: {
        createContract: baseUrl + '/create-contract',
        uploadOfferLetter: baseUrl + '/upload-offer-leter',
        getContracts: baseUrl + '/get-contracts',
        acceptContract: baseUrl + '/update-contract-status',
        updateApplicantsFeedback: baseUrl + '/update-applicants-feedback',
        downloadApplicantsFeedback: baseUrl + '/download-applicants-feedback',
        getContractsById: baseUrl + '/get-contractsById',
        deleteContract: baseUrl + '/delete-contract',
    },
    landscape: {
        createLandscape: baseUrl + '/create-landscape',
        getLandscapes: baseUrl + '/get-landscapes',
        getLandscapeById: baseUrl + '/get-landscapeById',
    },
    improveIndexScore: {
        improveAi: baseUrl + '/improve-ai',
        improveGrammer: baseUrl + '/improve-grammer',
        improveEmotions: baseUrl + '/improve-emotions',
        improveVideo: baseUrl + '/improve-video',
    },
    notifications: {
        getNotifications: baseUrl + '/get-notifications',
    },
    share: {
        shareIndexScore: baseUrl + '/share-indexscore',
    },
    enterpriseTeamMembers: {
        updateMember: baseUrl + '/update-memeber',
        deleteMember: baseUrl + '/delete-memeber',
        getMemberById: baseUrl + '/get-memberById',
        getMembersByCompany: baseUrl + '/get-memberByCompany',
    },
    report: {
        getAllJobDetailsAndCandidateStats: baseUrl + '/compliance-report',
    },
    savingCalculator: {
        savingCalculator: baseUrl + '/saving-calculator',
    },
    CreateVisitorHandler: {
        createVisitorHandler: baseUrl + '/register/visitor',
    },
    Ats: {
        getAtsToken: baseUrl + '/ats-token',
        createAtsCandidatesData: baseUrl + '/create-ats-candidates',
        getAtsCandidates: baseUrl + '/get-ats-candidates',
        requestScore: baseUrl + '/request-score',
        requestAtsScore: baseUrl + '/request-atsScore',
    },
    Dashboard: {
        professionalDashboard: baseUrl + '/get-pro-dashboard',
        enterpriseDashboard: baseUrl + '/get-enterprise-dashboard',
    },
    chat: {
        sendChat: baseUrl + '/send-msg',
        getPrivateChat: baseUrl + '/get-msgs',
        getManagerIdsByCandidateId: baseUrl + '/get-enterpries',
        getChannelsByJobID: baseUrl + '/get-channelsByJobID',
        getChannelsByManagerID: baseUrl + '/get-channelsByManagerID',
        sendMessageTogroup: baseUrl + '/send-group-msg',
        getChatsByManagerID: baseUrl + '/get-chatsByManagerID',
        getChatsByCandidateID: baseUrl + '/get-channelsByCandidatedID',
        sendMessageToChat: baseUrl + '/sendMessageToChat',
        getGroupChatMessages: baseUrl + '/get-getGroupChatMessages',
        getChannelsByMemberID: baseUrl + '/get-channelsByMemberID',
        getChatMessages: baseUrl + '/get-getChatMessages',

    },
    stripe: {
        getPaymentMethods: baseUrl + '/get-payment-methods',
        createEnterpriseSession: baseUrl + '/create-payment-session',
        createCandidateSession: baseUrl + '/create-payment-session',

    },
    payment: {
        subscriptionListAll: baseUrl + '/subscription/list',
        subscribeSubscription: baseUrl + '/subscribe',
        subscriptionIdDetails: baseUrl + '/subscription',
        purchaseCourse: baseUrl + '/purchase/course',
        indexscoreScoreCharge: baseUrl + '/indexscore/charge',
        subscriptionInvoices: baseUrl + '/subscription/invoices',
        candidateInvoices: baseUrl + '/customer/invoices',
    },
    linkedinShare:{
        authorizeLinkedinUser: baseUrl + '/linkedin',
        getlinkedInUserId: baseUrl + '/linkedin-user',
        createLinkedinPost: baseUrl + '/linkedin-post',
        linkedinParser: baseUrl + '/linkedin-parser'
    },
    twoFa:{        
        generate2faSecret: baseUrl + '/generate2faSecret',
        verify2faSecret: baseUrl + '/verify2faSecret',
    },
    twitter:{
        twitterParser: baseUrl + '/twitter-parser',
    }








}
export default config;