import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Avatar,
  Input,
  Space,
  Tag,
  Tooltip,
  Checkbox,
  Select,
  DatePicker,
  Upload,
  Button,
  Radio,
} from "antd";
import "./style.css";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";
import moment from "moment";
import { Country } from "../../../../Utilities/Country.js";
import { getMembersByCompany } from "../../../../services/auth";
import { getPackages } from "../../../../services/criteria";
import { MyContext } from "../../../../Utilities/MyContextProvider";

const EConfirm = ({ handleIndexCore, finalData }) => {
  const myContext = useContext(MyContext);
  const myData = myContext;
  const { getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];
  const [jobPackages, setJobPackages] = useState([]);
  const [options, setOptions] = useState([]);
  const [dataInvited, setDataInvited] = useState(
    myContext.contextData.team_members
  );
  const [response, setResponse] = useState([]);
  const [teamMembers, setTeamMembers] = useState(
    myContext.contextData.team_members
  );
  const [tags, setTags] = useState(
    myContext.contextData && myContext.contextData.job_sectors
      ? myContext.contextData.job_sectors
      : []
  );
  const [inputVisible, setInputVisible] = useState(false);
  const [valueHireMultiple, setValueHireMultiple] = useState(
    myContext.contextData && myContext.contextData.hireMultiple
      ? myContext.contextData.hireMultiple
      : false
  );
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);

      handleChange("job_sectors", [...tags, inputValue]);
      // handleJobTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };

  const { Option, OptGroup } = Select;
  const [value, setValue] = useState(
    myContext.contextData.min_index_core / 850
  );
  const [value2, setValue2] = useState(
    myContext.contextData.min_culture_fit_score / 100
  );
  const [employmentType, setEmploymentType] = useState(
    myContext.contextData.employment_type
  );
  const countryName = Country.find(
    (country) => country.code === myContext.contextData.job_locations
  )?.name;

  // const firstNames = myContext.contextData.team_members.map(
  //   (user) => user.firstName
  // );

  useEffect(() => {
    getPackages().then((data) => {
      setJobPackages(data.data);
      // debugger
    });
  }, []);

  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };

  const [indexCoreData, setIndexCoreData] = useState({
    min_index_core: "",
    min_culture_fit_score: "",
  });

  const { min_index_core, min_culture_fit_score } = indexCoreData;

  const handleChange = (key, value) => {
    const payload = {
      [key]: value,
    };
    myContext.updateContextData(payload);

    // setIndexCoreData((pre) => ({ ...pre, [key]: value }));
    // handleIndexCore(indexCoreData);
  };

  const handleRadioChange = (e) => {
    setValueHireMultiple(e.target.value);
    handleChange("hireMultiple", e.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const myId = localStorage.getItem("enterpriseID");
      const payloadGetMember = {
        enterpriseID: userData?.companyID,
      };

      const response = await getMembersByCompany(payloadGetMember);
      setResponse(response.data.data);

      const newOptions = response.data.data
        .filter(
          (professional) =>
            professional._id !== myId &&
            professional.firstName !== null &&
            professional.firstName.trim() !== " " &&
            professional.role !== "hr"
        )
        .map((professional) => ({
          label: professional.firstName,
          value: professional._id,
        }));

      setOptions(newOptions);
    } catch (error) {}
  };

  const handleDropChange = (value) => {
    const selectedObject = response.find((obj) => obj._id === value);
    if (selectedObject) {
      // If the object exists, copy it to the dataInvited array
      setDataInvited([...dataInvited, selectedObject]);

      // Update team_members array locally
      const updatedTeamMembers = [...teamMembers, selectedObject];
      setTeamMembers(updatedTeamMembers);

      // Update team_members context variable
      const payload = {
        team_members: updatedTeamMembers,
      };
      myContext.updateContextData(payload);
    }
  };

  const handleRemoveDrop = (value) => {
    // Find the index of the object to remove in the dataInvited array
    const indexToRemove = dataInvited.findIndex((obj) => obj._id === value);
    if (indexToRemove !== -1) {
      // If the object exists, remove it from the dataInvited array
      const updatedDataInvited = [...dataInvited];
      updatedDataInvited.splice(indexToRemove, 1);
      setDataInvited(updatedDataInvited);

      // Update team_members array locally
      const updatedTeamMembers = teamMembers.filter(
        (member) => member._id !== value
      );
      setTeamMembers(updatedTeamMembers);

      // Update team_members context variable
      const payload = {
        team_members: updatedTeamMembers,
      };
      myContext.updateContextData(payload);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setEmploymentType([...employmentType, value]);
    } else {
      setEmploymentType(employmentType.filter((item) => item !== value));
    }

    const payload = {
      employment_type: checked
        ? [...employmentType, value]
        : employmentType.filter((item) => item !== value),
    };
    myContext.updateContextData(payload);
  };

  return (
    <div className="Role pb-5 General">
      <div className="Dashboard__container pb-5 pt-4">
        <div className="Dashboard__container__content pt-2">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-xxl-8 col-md-10">
                <p className="lightBlue2 fm mb-5 mt-0 text-14">
                  Please check all listed details below for final review.
                </p>
              </div>
              <div className="col-12">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Full job description
                </label>
                <p className="darkGrey fm mt-0 text-14">
                  One final check before we review and post your job.
                </p>
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  onChange={(value) =>
                    handleChange("job_title", value.target.value)
                  }
                  value={myContext.contextData.job_title}
                  id="sector1"
                />
              </div>
              <div className="col-12 mb-4">
                {/* <input
                  type="hidden"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  value={myContext.contextData.job_locations}
                  id="sector1"
                />
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  value={countryName}
                /> */}

                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  className="Login--Input locations__select Login--select2 new--select  mb-2 mx-0 d-block w-100"
                  size="small"
                  value={myContext.contextData.job_locations}
                  placeholder="Job location"
                  onChange={(value) => {
                    handleChange("job_locations", value);
                  }}
                >
                  <OptGroup>
                    {Country.map((item) => (
                      <Option
                        value={item.code}
                        label={item.name.toLowerCase()}
                        key={item.code}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </div>
              <div className="col-sm-4 pe-sm-0">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Company"
                  value={myContext.contextData.company}
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Division"
                  onChange={(value) => {
                    // alert(value)
                    handleChange("division", value.target.value);
                  }}
                  value={myContext.contextData.division}
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Department"
                  value={myContext.contextData.department}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("department", value.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="darkGrey mt-0 fm mb-0 fw-semibold fs-6">
                  Employment type:
                </p>
                <p className="darkGrey fm mt-0 text-14">
                  Choose one or multiple options
                </p>
                <div className="d-flex flex-wrap">
                  <Checkbox
                    checked={employmentType.includes("Full time")}
                    value="Full time"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                    className="fm darkGrey me-3 mb-2 text-14"
                  >
                    Full time
                  </Checkbox>
                  <Checkbox
                    checked={employmentType.includes("Part time")}
                    value="Part time"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                    className="fm darkGrey me-3 mb-2 text-14"
                  >
                    Part time
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 text-14"
                    checked={employmentType.includes("Hybrid")}
                    value="Hybrid"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Hybrid
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 text-14"
                    checked={employmentType.includes("Remote")}
                    value="Remote"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Remote
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 text-14"
                    checked={employmentType.includes("Freelance")}
                    value="Freelance"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Freelance
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 text-14"
                    checked={employmentType.includes("Temporary")}
                    value="Temporary"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Temporary
                  </Checkbox>
                </div>
              </div>
              <p className="darkGrey fm mb-0 fw-semibold fs-6 mb-2 mt-4">
                Compensation Range:
              </p>
              <div className="col-sm-4 h-100 General">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14 mb-2"
                  placeholder="Min ($)"
                  value={myContext.contextData.min_salary}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("min_salary", value.target.value);
                  }}
                />
              </div>
              <div className="col-sm-4 h-100 General">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14 mb-2"
                  placeholder="Max ($)"
                  value={myContext.contextData.max_salary}
                  onChange={(value) => {
                    handleChange("max_salary", value.target.value);
                  }}
                />
              </div>
              <div className="col-sm-4 h-100 General">
                <Select
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="USD"
                  onChange={(value) => {
                    // alert(value)
                    handleChange("salary_currency", value);
                  }}
                  value={myContext.contextData.salary_currency}
                >
                  <Select.Option value="usd">USD</Select.Option>
                  <Select.Option value="gbp">GBP</Select.Option>
                  <Select.Option value="eur">EUR</Select.Option>
                  <Select.Option value="cad">CAD</Select.Option>
                  <Select.Option value="aud">AUD</Select.Option>
                </Select>
              </div>
              <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-2">
                Application Window:
              </p>
              <div className="col-sm-6 mb-2">
                {/* <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="Start date"
                  format="YYYY-MM-DD"
                  value={myContext.contextData.start_date}
                /> */}
                <input
                  type="date"
                  className="form-control form-control-md w-100 Dashboard__input mb-1"
                  pattern="\d{4}-\d{2}-\d{2}"
                  value={moment(myContext.contextData.start_date).format(
                    "YYYY-MM-DD"
                  )}
                  id="sector1"
                  onChange={(value) => {
                    handleChange("start_date", value.target.value);
                  }}
                />
              </div>
              <div className="col-sm-6 mb-2">
                {/* <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="End date"
                  format="YYYY-MM-DD"
                  value={myContext.contextData.end_date}
                /> */}
                <input
                  type="date"
                  className="form-control form-control-md w-100 Dashboard__input mb-1"
                  pattern="\d{4}-\d{2}-\d{2}"
                  value={moment(myContext.contextData.end_date).format(
                    "YYYY-MM-DD"
                  )}
                  id="sector1"
                  onChange={(value) => {
                    handleChange("end_date", value.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Company Details:
                </label>
                <p className="fm darkGrey mb-3 mt-0 text-14">
                  If you provided detailed background info of your company
                  during your onboarding process, this form will be pre-filled
                  for you else please provide a short description of your
                  company in the following form.
                </p>
                <textarea
                  className="form-control form-control-lg Dashboard__input mb-4 w-100"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  value={myContext.contextData.company_bio}
                  onChange={(value) => {
                    handleChange("company_bio", value.target.value);
                  }}
                />
              </div>

              <div className="col-xxl-12 col-md-12">
                <p className="fm darkGrey mt-0 text-14">
                  Tell us more about the role you are hiring for? What skills
                  are needed what day to day activities will be etc.
                </p>
                <label
                  htmlFor="text"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Role Description:
                </label>
                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="About the role…"
                  id="text"
                  rows={6}
                  onChange={(value) => {
                    handleChange("job_role_text", value.target.value);
                  }}
                  value={myContext.contextData.job_role_text}
                />
              </div>
              <div className="col-xxl-12 col-md-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Role Key Expectations:
                </label>
                <p className="fm darkGrey mb-2 mt-0 text-14">
                  What will the candidate expect to do in the role?
                </p>
                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  onChange={(value) => {
                    handleChange("job_expectation", value.target.value);
                  }}
                  value={myContext.contextData.job_expectation}
                />
              </div>

              <div className="col-xxl-12 col-md-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Your Company Benefits:
                </label>

                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  onChange={(value) => {
                    handleChange("company_benefits", value.target.value);
                  }}
                  value={myContext.contextData.company_benefits}
                />
              </div>

              <div className="create__indexCore__chart d-flex mt-4 flex-sm-row flex-column align-items-center">
                <div className="me-sm-4 me-0 mb-sm-0 mb-5 position-relative d-flex justify-content-center">
                  <div className="circular__text text-center darkGrey fm text-14">
                    Please select your minimum IndexScore for this role:
                    <p className="fs-1 main mb-0 circular__text__num mt-3 fm">
                      {Math.round(value * 850)}
                    </p>
                  </div>
                  <CircularInput
                    className="Indexcore__progress"
                    // value={Math.round(myContext.contextData.min_index_core * 1000)}
                    value={value}
                    onChange={(value) => {
                      // alert(value)
                      handleChange(
                        "min_index_core",
                        Math.round(value * 850).toString()
                      );
                      setValue(value);
                    }}
                  >
                    <CircularTrack strokeWidth={8} stroke="#eee" />
                    <CircularThumb
                      r="16"
                      stroke="#A6DD50"
                      fill="#A6DD50"
                      strokeWidth={0}
                    />
                    <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                  </CircularInput>
                </div>
                <div className="me-sm-4 me-0 position-relative d-flex justify-content-center">
                  <div className="circular__text text-center darkGrey text-14">
                    Please select the minimum Purpose Alignment % for this role:
                    <p className="fs-1 main mb-0 circular__text__num mt-3">
                      {Math.round(value2 * 100)}
                    </p>
                  </div>
                  <CircularInput
                    className="Indexcore__progress"
                    // value={myContext.contextData.min_culture_fit_score}
                    value={value2}
                    // value={Math.round(myContext.contextData.min_culture_fit_score * 1000)}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange(
                      handleChange(
                        "min_culture_fit_score",
                        Math.round(value2 * 100)
                      );
                      setValue2(value);
                    }}
                  >
                    <CircularTrack strokeWidth={8} stroke="#eee" />
                    <CircularThumb
                      r="16"
                      stroke="#A6DD50"
                      fill="#A6DD50"
                      strokeWidth={0}
                    />
                    <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                  </CircularInput>
                </div>
              </div>

              <div className="col-xxl-12 col-md-10">
                <Space className="d-block">
                  <label
                    htmlFor="job"
                    className="darkGrey fm mb-2 fw-semibold text-14"
                  >
                    Job Tags
                  </label>
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    className="w-100 tag__input form-control form-control-sm  mb-2"
                    id="job"
                  />
                  <Space className="d-flex">
                    {tags.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <div className="d-block">
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          </div>
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={index !== -1}
                          style={{
                            userSelect: "none",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== -1) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </Space>
                </Space>
              </div>

              <div className="col-12 mb-3 mt-4">
                <p className="darkGrey fm mb-0 fw-semibold fs-6">
                  Multiple Candidates:
                </p>
                <p className="darkGrey fm mt-0 text-14">
                  Are you looking to hire multiple candidates for this job role?
                </p>
                <div className="d-flex flex-wrap">
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={valueHireMultiple}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="col-12 mb-3 mt-4">
                <hr />
              </div>
              <div className="mt-4">
                <p className="darkGrey fm mb-0 fw-semibold fs-6">
                  Potential Interviewers:
                </p>
                <div className="d-flex flex-md-nowrap flex-wrap">
                  {/* <input
                    type="text"
                    value={firstNames.map((firstName) => {
                      return firstName;
                    })}
                    className="form-control form-control-md w-100  me-2 mt-2"
                  />                   */}
                  <Select
                    className="Login--Input mb-2 mx-0 d-block w-100 Login--Select"
                    mode="multiple"
                    style={{
                      width: "100%",
                      maxWidth: "650px",
                    }}
                    placeholder="Invited Members"
                    onSelect={handleDropChange}
                    onDeselect={handleRemoveDrop}
                    options={options}
                    value={dataInvited.map((member) => member._id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EConfirm;
