import React, { useContext, useState, useEffect } from "react";
import "./style.css";
import { CiSearch } from "react-icons/ci";
import { Dropdown, Tooltip, Drawer } from "antd";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MyContext } from "../../Utilities/MyContextProvider";
import { getNotifications } from "../../services/auth";
import { CiLock } from "react-icons/ci";

const HrNav = (props) => {
  const [full, setFull] = useState(false);
  const myContext = useContext(MyContext);
  const { clearUserData, clearContextData, getUserData, logoutAction } =
    useContext(MyContext);
  const userDataFromContext = getUserData();
  const navigate = useNavigate();
  const userData = userDataFromContext && userDataFromContext[0];
  const [dashboard, setDashbaord] = useState(true);
  const [recruit, setRecruit] = useState(false);
  const [insights, setInsights] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);

  const func_dashboard = () => {
    setRecruit(false);
    setDashbaord((flag) => !flag);
    setInsights(false);
  };
  const func_recruiting = () => {
    setRecruit((flag) => !flag);
    setInsights(false);
    setDashbaord(false);
  };
  const func_insights = () => {
    setRecruit(false);
    setDashbaord(false);
    setInsights((flag) => !flag);
  };

  const getUserNotifications = async () => {
    const currentUserId = localStorage.getItem("companyID");
    const payloadNotifications = {
      _id: currentUserId,
    };

    try {
      const notificationsResponse = await getNotifications(
        payloadNotifications
      );

      const userNotificationsData = notificationsResponse.data.data;

      if (userNotificationsData) {
        setUserNotifications(userNotificationsData);
        filterNotifications(userNotificationsData); // Pass the data to the filter function
      }
    } catch (error) {
      // Handle any errors
      console.error("Error fetching notifications: ", error);
    }
  };

  useEffect(() => {
    getUserNotifications();
  }, []);

  const filterNotifications = (notificationsData) => {
    const filteredItems = notificationsData.map((item) => {
      const createdAt = new Date(item.createdAt);
      const currentTime = new Date();
      const timeDifference = Math.floor((currentTime - createdAt) / 60000); // Calculate the difference in minutes

      let timeAgo;
      if (timeDifference < 1) {
        timeAgo = "just now";
      } else if (timeDifference < 60) {
        timeAgo = `${timeDifference} minute${
          timeDifference > 1 ? "s" : ""
        } ago`;
      } else if (timeDifference < 1440) {
        const hours = Math.floor(timeDifference / 60);
        timeAgo = `${hours} hour${hours > 1 ? "s" : ""} ago`;
      } else {
        const days = Math.floor(timeDifference / 1440);
        timeAgo = `${days} day${days > 1 ? "s" : ""} ago`;
      }

      // console.log("item", item);
      return {
        label: (
          <div>
            {item.message} <br />
            <strong>{timeAgo}</strong>
          </div>
        ),
        icon: <BsFillRocketTakeoffFill />,
      };
    });

    const reversedItems = filteredItems.reverse();

    // reversedItems.push({ label: "See all Notifications" });

    setFilteredNotifications(reversedItems);
  };

  const onClick = ({ key }) => {
    // message.info(`Click on item ${key}`);
  };

  function logOutUser() {
    const rememberedEmail = localStorage.getItem('emailAddress');
    const rememberedPassword = localStorage.getItem('password');
    const rememberedRememberMe = localStorage.getItem('rememberMe');
    navigate(`/log-in`);

    localStorage.clear();
    if (rememberedEmail && rememberedPassword && rememberedRememberMe) {
      localStorage.setItem('emailAddress', rememberedEmail);
      localStorage.setItem('password', rememberedPassword);
      localStorage.setItem('rememberMe', rememberedRememberMe);
    }
    clearUserData();
    clearContextData();
    logoutAction();
  }

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <div className="enav">
        <style>
          {`
        .notification-menu.ant-dropdown-menu li{
            padding:15px 10px !important;
            max-width:300px;
            
            border-bottom:1px solid #eee;
            
        }
        .notification-menu.ant-dropdown-menu li svg {
          font-size: 20px !important;
          width: 30px !important;
          margin-right: 20px !important;
          margin-left: 10px;
          
      }

      .notification-menu.ant-dropdown-menu li:last-child {
          border: none;
      }
      
      .notification-menu.ant-dropdown-menu {
          max-height: 300px;
          overflow-y: auto;
          padding: 0px !important;
      }
      
      .notification-menu.ant-dropdown-menu li:last-child span {
          font-weight: 600;
          
      }
      .enav .has-notif {
        position: relative;
    }
    
    .enav .has-notif:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background: red;
        border-radius: 50%;
        right: 0px;
    }
      `}
        </style>
        <div className="enav__top">
          <div className="container-dash d-lg-none d-flex justify-content-between align-items-center position-relative h-100">
            <Link to={"/hr/dashboard"}>
              <img
                src={`/assets/images/navbar/logoMain.svg`}
                alt=""
                className="enav__top__logo"
              />
            </Link>
            <div className="d-flex">
              {!open && <Dropdown
                className=""
                menu={{
                  className: "notification-menu",
                  items: filteredNotifications.slice(0, 5),
                  onClick,
                }}
              >
                <div
                  // className={`${
                  //   filteredNotifications.length > 1 ? "has-notif" : " "
                  // }`}
                >
                  <img
                    src="/assets/images/Dashboard/notification_light.svg"
                    className=" DSidebar__item__Nicon m-0 DSidebar__item__icon11"
                  />
                </div>
              </Dropdown>}
              <div className="ms-5  d-block">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  className="check enav__burger"
                  onClick={showDrawer}
                  checked={open}
                />
                <div className="ham-menu enav__burger" id="ham-menu">
                  <span className={`line line1 line--white`}></span>
                  <span className={`line line2 line--white`}></span>
                  <span className={`line line3 line--white`}></span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-lg-flex container-dash d-none justify-content-between align-items-center h-100">
            <Link to={"/hr/dashboard"}>
              <img
                src={`/assets/images/navbar/logoMain.svg`}
                alt=""
                className="enav__top__logo"
              />
            </Link>

            <div className="d-flex enav__top__right align-items-center">
              <Dropdown
                className="mx-3"
                menu={{
                  className: "notification-menu",
                  items: filteredNotifications,
                  onClick,
                }}
              >
                <div
                  className={`${
                    filteredNotifications.length > 1 ? "has-notif" : " "
                  }`}
                >
                  <img
                    src="/assets/images/Dashboard/notification_light.svg"
                    className=" DSidebar__item__Nicon DSidebar__item__icon11 me-lg-0 me-3 ms-lg-0 ms-2"
                    style={{ display: full ? "none" : "block" }}
                  />
                </div>
              </Dropdown>
              <button
                id="dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="enav__top__right__dropdown pointer ms-2 rounded-pill d-flex align-items-center"
              >
                <img
                  src={`${
                    userData && userData.profileImage
                      ? userData.profileImage.docs
                      : "/assets/images/userAvatar.jpg"
                  }`}
                  alt=""
                  className="enav__top__right__user rounded-circle"
                />

                <span className="px-1 text-white"> {userData.firstName}</span>
                <MdOutlineKeyboardArrowDown className="text-white mt-1" />
              </button>
              <ul
                class="dropdown-menu dropdown-menu-end enav__top__right__dropdown--ul"
                aria-labelledby="dropdown"
              >                
                <li>
                  <a
                    class="dropdown-item darkGrey text-decoration-none fm"
                    href="javascript:"
                    onClick={logOutUser}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="enav__mid container-dash-mid d-lg-block d-none">
          <div className="d-flex">
            <Link
              to={"/hr/dashboard"}
              className={`enav__mid__link pb-1 text-decoration-none d-flex align-items-center `}
              style={{
                color:
                  props.name === "dashboard" ? "var(--darkBlue)" : "#818DAF",
                background: props.name === "dashboard" ? "#E3EBFF" : "",
                borderBottomLeftRadius: props.name === "dashboard" ? "0px" : "",
                borderBottomRightRadius:
                  props.name === "dashboard" ? "0px" : "",
              }}
            >
              <img
                src={`/assets/images/toolbar/icon_dashboard${
                  props.name === "dashboard" ? "" : "_main"
                }.svg`}
                alt=""
                className="DSidebar__item__icon me-2"
              />
              <span className="fm">Dashboard</span>
            </Link>
            <Link
                to={"/hr/dashboard/jobs"}
                className={`enav__mid__link pb-1 text-decoration-none d-flex align-items-center `}
                style={{
                  color:
                    props.name === "recruiting" ? "var(--darkBlue)" : "#818DAF",
                  background: props.name === "recruiting" ? "#E3EBFF" : "",
                  borderBottomLeftRadius:
                    props.name === "recruiting" ? "0px" : "",
                  borderBottomRightRadius:
                    props.name === "recruiting" ? "0px" : "",
                }}
              >
                <img
                  src={`/assets/images/toolbar/icon_candidates${
                    props.name === "recruiting" ? "" : "_main"
                  }.svg`}
                  alt=""
                  className="DSidebar__item__icon me-2"
                />
                <span className="fm ">Recruiting</span>
              </Link>

              <Link
                to={"/hr/dashboard/department"}
                className={`enav__mid__link pb-1 text-decoration-none d-flex align-items-center `}
                style={{
                  color:
                    props.name === "department" ? "var(--darkBlue)" : "#818DAF",
                  background: props.name === "department" ? "#E3EBFF" : "",
                  borderBottomLeftRadius:
                    props.name === "department" ? "0px" : "",
                  borderBottomRightRadius:
                    props.name === "department" ? "0px" : "",
                }}
              >
                <img
                  src={`/assets/images/toolbar/icon_candidates${
                    props.name === "department" ? "" : "_main"
                  }.svg`}
                  alt=""
                  className="DSidebar__item__icon me-2"
                />
                <span className="fm ">Departments</span>
              </Link>
              {/* <Link
                to={"/hr/dashboard/inbox"}
                className={`enav__mid__link pb-1 text-decoration-none d-flex align-items-center `}
                style={{
                  color: props.name === "inbox" ? "var(--darkBlue)" : "#818DAF",
                  background: props.name === "inbox" ? "#E3EBFF" : "",
                  borderBottomLeftRadius: props.name === "inbox" ? "0px" : "",
                  borderBottomRightRadius: props.name === "inbox" ? "0px" : "",
                }}
              >
                <img
                  src={`/assets/images/toolbar/icon_inbox${
                    props.name === "inbox" ? "" : "_main"
                  }.svg`}
                  alt=""
                  className="DSidebar__item__icon me-2"
                />
                <span className="fm ">Messages</span>
              </Link> */}
          </div>
        </div>
      </div>

      {/* offcanvas */}
      <Drawer
        onClose={onClose}
        open={open}
        placement="left"
        className="bg-darkBlue"
      >
        <div className="d-flex flex-column justify-content-between h-100 pb-0  px-0">
          <div>
            <div className="main-padding">
              <Link to={"/hr/dashboard"} style={{ marginLeft: "8px" }}>
                <img
                  src={`/assets/images/navbar/logoMain.svg`}
                  alt=""
                  className="enav__top__logo"
                />
              </Link>
            </div>
            <div className="main-padding">
              <div className="mt-5">
                <div className="">
                  <div
                    className={`enav__mid__link pb-1 mb-1 text-decoration-none d-flex justify-content-between align-items-center `}
                    onClick={func_dashboard}
                  >
                    <div className="d-flex align-items-center">
                      <img
                        src={`/assets/images/toolbar/icon_dashboard_white.svg`}
                        alt=""
                        className="DSidebar__item__icon me-2"
                      />
                      <span className="fm text-white fw-normal">Dashboard</span>
                    </div>
                    <MdOutlineKeyboardArrowDown
                      className="text-white fs-4 transition"
                      style={{
                        transform: dashboard
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>

                  <div
                    className="overflow-hidden transition"
                    style={{ height: dashboard ? "100%" : "0px" }}
                  >
                    <Link
                      to={"/hr/dashboard"}
                      className={`enav__mid__link enav__mid__link__dropdown text-decoration-none d-flex align-items-center rounded-2 mb-2`}
                      style={{
                        color:
                          props.mobName === "dashboard"
                            ? "var(--darkBlue)"
                            : "#fff",
                        background:
                          props.mobName === "dashboard" ? "#E3EBFF" : "",
                        fontWeight:
                          props.mobName === "dashboard" ? "400" : "300",
                      }}
                    >
                      <span className="fm">Overview</span>
                    </Link>

                                        
                  </div>
                </div>
              </div>
            </div>

            <div className="border-top-05">
              <div className="main-padding">
                <div
                  className={`enav__mid__link  pb-1 my-1 text-decoration-none d-flex justify-content-between align-items-center `}
                  onClick={func_recruiting}
                >
                  <div>
                    <img
                      src={`/assets/images/toolbar/icon_candidates_white.svg`}
                      alt=""
                      className="DSidebar__item__icon me-2"
                    />
                    <span className="fm text-white fw-normal">Recruiting</span>
                  </div>
                  <MdOutlineKeyboardArrowDown
                    className="text-white fs-4 transition"
                    style={{
                      transform: recruit ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </div>

                <div
                  className="overflow-hidden transition"
                  style={{ height: recruit ? "100%" : "0px" }}
                >
                  <Link
                    to={"/hr/dashboard/jobs"}
                    className={`enav__mid__link enav__mid__link__dropdown text-decoration-none d-flex align-items-center rounded-2 mb-2`}
                    style={{
                      color:
                        props.mobName === "jobs" ? "var(--darkBlue)" : "#fff",
                      background: props.mobName === "jobs" ? "#E3EBFF" : "",
                      fontWeight: props.mobName === "jobs" ? "400" : "300",
                    }}
                  >
                    <span className="fm">Open Roles</span>
                  </Link>

                  
                  <Link
                    to={"/hr/dashboard/department"}
                    className={`enav__mid__link enav__mid__link__dropdown text-decoration-none d-flex align-items-center rounded-2 mb-2`}
                    style={{
                      color:
                        props.mobName === "departments"
                          ? "var(--darkBlue)"
                          : "#fff",
                      background:
                        props.mobName === "departments" ? "#E3EBFF" : "",
                      fontWeight:
                        props.mobName === "departments" ? "400" : "300",
                    }}
                  >
                    <span className="fm">Departments</span>
                  </Link>           
                  <Link
                    to={"/hr/dashboard/create-new-job"}
                    className={`enav__mid__link enav__mid__link__dropdown text-decoration-none d-flex align-items-center rounded-2 mb-2`}
                    style={{
                      color:
                        props.mobName === "newjob" ? "var(--darkBlue)" : "#fff",
                      background: props.mobName === "newjob" ? "#E3EBFF" : "",
                      fontWeight: props.mobName === "newjob" ? "400" : "300",
                    }}
                  >
                    <span className="fm">Create a job</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="main-padding border-top-05">
              {userData.subscription &&
              userData.subscription.name === "Predict" ? (
                <div
                  className={`enav__mid__link  pb-1 my-1 text-decoration-none d-flex justify-content-between align-items-center `}
                  onClick={func_insights}
                >
                  <div>
                    <img
                      src={`/assets/images/toolbar/icon_offers_white.svg`}
                      alt=""
                      className="DSidebar__item__icon me-2"
                    />
                    <span className="fm text-white fw-normal">
                      Talent Insights
                    </span>
                  </div>
                  <MdOutlineKeyboardArrowDown
                    className="text-white fs-4 transition"
                    style={{
                      transform: insights ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </div>
              ) : (
                <Tooltip
                  title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                  placement="top"
                >
                  <div
                    className={`enav__mid__link  pb-1 my-1 text-decoration-none d-flex justify-content-between align-items-center `}
                    // onClick={func_insights}
                  >
                    <div>
                      <img
                        src={`/assets/images/toolbar/icon_offers_white.svg`}
                        alt=""
                        className="DSidebar__item__icon me-2"
                      />
                      <span className="fm text-white fw-normal">
                        Talent Insights
                      </span>
                    </div>
                    <MdOutlineKeyboardArrowDown
                      className="text-white fs-4 transition"
                      style={{
                        transform: insights ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                    />
                  </div>
                </Tooltip>
              )}

            </div>

            <div className="border-top-05 main-padding pt-3">
              <Link
                to={"/hr/dashboard/inbox"}
                className={`enav__mid__link  pb-1 text-decoration-none d-flex align-items-center rounded-2 mb-2`}
                style={{
                  color: props.name === "inbox" ? "var(--darkBlue)" : "#fff",
                  background: props.name === "inbox" ? "#E3EBFF" : "",
                  fontWeight: props.name === "inbox" ? "400" : "300",
                }}
              >
                <img
                  src={`/assets/images/toolbar/icon_inbox${
                    props.name === "inbox" ? "" : "_white"
                  }.svg`}
                  alt=""
                  className="DSidebar__item__icon me-2"
                />
                <span className="fm">Messages</span>
              </Link>
            </div>
          </div>
          <div className="main-padding pb-3 pt-5">
            <a
              class="fm text-white enav__mid__link fw-light d-flex align-items-center text-decoration-none"
              href="javascript:"
              onClick={logOutUser}
            >
              <img
                src={`/assets/images/toolbar/icon_logout_white.svg`}
                alt=""
                className="DSidebar__item__icon me-2"
              />
              Logout
            </a>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default HrNav;
