import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { BsFilter } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import config from "../../../services/apisConfig";
import { getContracts } from "../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  HiOutlineArrowDownCircle,
  HiOutlineSquare3Stack3D,
} from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { CiSearch } from "react-icons/ci";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { RiStarSLine } from "react-icons/ri";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { Table, Tooltip } from "antd";

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button
        className="paginate-button"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <IoChevronBack />
      </button>
      <button
        className="paginate-button"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <IoChevronForward />
      </button>

      <span>
        Page {currentPage} of {totalPages}
      </span>
    </div>
  );
};

const ProOffers = () => {
  const [contractsInvite, setContractsInvite] = useState([]);
  const [offeredContracts, setOfferedContracts] = useState([]);
  const [acceptedContracts, setAcceptedContracts] = useState([]);

  const [desc, setDesc] = useState(false);
  const [currentJob, setCurrentJob] = useState(false);
  const [apply, setApply] = useState(false);
  const [applied, setApplied] = useState(false);

  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [filterData, setFilterData] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [interviewsInvite, setInterviewsInvite] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [secondSearchText, setSecondSearchText] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;

  const navigate = useNavigate();
  const [selectedContract, setSelectedContract] = useState(null);

  const handleOfferClick = (contractData) => {
    setSelectedContract(contractData);
    navigate("/candidate/dashboard/contracts/invitations", {
      state: { contractData },
    });
  };

  const handleContractClick = (contractData) => {
    setSelectedContract(contractData);
    navigate("/candidate/dashboard/contracts/details", {
      state: { contractData },
    });
  };

  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "accepted-contract.pdf");
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const fetchContracts = async () => {
    const payload = {
      _id: localStorage.getItem("enterpriseID"),
    };

    const response = await getContracts(payload);
    if (response.data.statusCode == 1) {
      // console.log("response", response.data.data);
      const contractsData = response.data.data;
      console.log("contractsData", contractsData);
      const offered = contractsData.filter(
        (contract) => contract.status === "offered"
      );
      const accepted = contractsData.filter(
        (contract) =>
          contract.status === "accepted" || contract.status === "started"
      );
      console.log("offered", offered);
      setOfferedContracts(offered);
      setAcceptedContracts(accepted);
      setContractsInvite(contractsData);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsLoading, setItemsLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const initialItems = offeredContracts.slice(0, itemsPerPage);
    setCurrentItems(initialItems);
    setTotalItems(offeredContracts.length);
  }, [offeredContracts]); // Update dependency to currentItems

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreItems();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentItems, itemsLoading]); // Update dependencies

  const loadMoreItems = () => {
    if (itemsLoading || currentItems.length >= totalItems) return;

    setItemsLoading(true);
    setTimeout(() => {
      const newItems = offeredContracts.slice(
        currentItems.length,
        currentItems.length + itemsPerPage
      );
      setCurrentItems([...currentItems, ...newItems]);
      setItemsLoading(false);
    }, 500); // Simulated loading delay, replace with actual data fetching
  };

  const tableColumns = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({currentItems ? currentItems.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: true,
    },
    {
      title: "Date Sent",
      dataIndex: "sent",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),
      render: (extended) => (
        <span className="text-14 darkGrey text-capitalize fm">
          {moment(currentItems.offer_date).format("L")}
        </span>
      ),
    },
    {
      title: "Date Expiry",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) =>
        moment(a.expiration).unix() - moment(b.expiration).unix(),
      render: () => (
        <span className="text-14 darkGrey text-capitalize fm">
          {moment(currentItems.offer_expire_date).format("L")}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },
  ];
  const formattedOffers = currentItems.map((item, index) => ({
    key: item.id,
    name: (
      <div
        onClick={() => {
          handleOfferClick(item);
        }}
        className="d-flex align-items-center"
        style={{
          cursor: "pointer",
        }}
      >
        <div
          className={`expiring__body_detail_image candidates__interview__icon jobs__image rounded-circle d-flex justify-content-center align-items-center ${
            item.enterpriseData && item.enterpriseData.companyImage.docs
              ? "company__image"
              : ""
          }`}
        >
          <img
            src={
              item.enterpriseData && item.enterpriseData.companyImage.docs
                ? item.enterpriseData.companyImage.docs
                : `/assets/images/expiring_icon.svg`
            }
            alt=""
            className="me-0"
          />
        </div>
        <h4 className="my-0 text-14">{item.company_name}</h4>
      </div>
    ),

    status: (
      <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${item.status}`}
      >
        {item.status}
      </span>
    ),
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item?.offered_Letter ? (
          item.offered_Letter == "Pending" ? (
            "Pending"
          ) : (
            <Tooltip title="Download Contract" color="#B2DA66">
              <img
                className="ms-0"
                src="/assets/images/pdf_icon.png"
                onClick={() => handleDownload(item.offered_Letter.Key)}
              />
            </Tooltip>
          )
        ) : (
          "--"
        )}
      </div>
    ),

    role: (
      <span className="text-14 darkGrey text-capitalize fm">
        {item.contract_title}
      </span>
    ),
  }));

  return (
    <>
      <div className="edash">
        <div className="pt-4">
          <div className="">
            <style>
              {`
          .list-of-contracts a {
            text-decoration: none;
            color: inherit;
        }
        .list-of-contracts h5{
          font-weight:500;
        }
        
        .list-of-contracts {
            padding: 15px;
            border-top: 1px solid #4a4a4971;
            border-radius: 0;
        }
        
        .list-of-contracts strong {
            font-weight: 600;
        }
        
        .list-of-contracts p {
            margin-bottom: 0px;
            margin-top:0px;
        }

        .list-of-contracts .text-right p{
          float:right;
        }
        
        
        .list-of-contracts:hover {
            background-color: rgb(242, 247, 242);
        }
        
        .list-of-contracts:first-child {
            border-top: none;
        }

        .Pjobs__tabs .react-tabs__tab--selected, .Pjobs__tabs .react-tabs__tab--selected:focus {
          border-bottom: 0.5px solid #41414080 !important;
        }

        .contracts-top-heading{
          margin-left:40px;
        }

        .invite-job{
          font-weight:600;
          color:#14a800;
        }

        .invitation-button {width: 200px;margin-bottom: 10px;padding: 12px;line-height: 1;border-radius: 25px;color: #fff;font-size: 16px;font-weight: 500;border: 2px;float:right}
        .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      .invite-job img {
        width: 40px;
        margin-right: 20px;
        transform: rotate(-90deg);
    }
        `}
            </style>
            <div className="jobs__content fm">
              <div className="Dashboard__container">
                <div className="jobs ">
                  <h1 className="fm dashboardScreen__content__hiring__heading d-md-none darkBlue pb-3">
                    You have{" "}
                    <span className="fw-semibold">
                      {" "}
                      {offeredContracts && offeredContracts.length}
                    </span>{" "}
                    offers
                  </h1>

                  <div className="d-md-block d-none lazy_table">
                    {loading ? (
                      <Loader size="md" className="" content="loading" />
                    ) : (
                      <div>
                        <div className="table__outer position-relative table__outer__pro  radius8">
                          <Table
                            className="dash__table"
                            columns={tableColumns}
                            dataSource={formattedOffers}
                            pagination={false}
                            scroll={{
                              x: 992,
                            }}
                          />
                        </div>
                        {/* {currentItems.length > 0 && (
                          <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all offers ({currentItems.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                      </div>
                    )}
                  </div>
                  <div className="offers__data bg-white d-md-none px-3 fm pb-0">
                    <div className="px-2 pt-lg-0 pt-3">
                      {currentItems && currentItems.length > 0 ? (
                        currentItems.map((item) => (
                          <div
                            onClick={() => handleOfferClick(item)}
                            className="pointer transition row mb-2 offers__list align-items-center"
                          >
                            <div className="col-lg-3 order-1">
                              <div className="d-flex align-items-center">
                                {item.enterpriseData &&
                                item.enterpriseData.companyImage.docs ? (
                                  <img
                                    src={item.enterpriseData.companyImage.docs}
                                  />
                                ) : (
                                  <span className="offers__company">
                                    <RiStarSLine />
                                  </span>
                                )}

                                <h4 className="my-0 text-14">
                                  {item.company_name}
                                </h4>
                              </div>
                            </div>
                            <div className="col-lg-2 order-lg-2 order-3 col-md-4 col-sm-6 mt-lg-0 mt-2">
                              <p className="text-14 my-0 text-capitalize">
                                <span className="fm darkGrey fw-semibold me-1 d-lg-none">
                                  Status:
                                </span>
                                {item.status}
                              </p>
                            </div>
                            <div className="col-lg-2 order-lg-3 order-4 col-md-4 col-sm-6 mt-lg-0 mt-2">
                              <p className="text-14 my-0">
                                <span className="fm darkGrey fw-semibold me-1 d-lg-none">
                                  Date Sent:
                                </span>
                                {moment(item.offer_date).format("DD.MM.YYYY")}
                              </p>
                            </div>
                            <div className="col-lg-2 order-lg-4 order-5 col-md-4 col-sm-6 mt-lg-0 mt-2">
                              <p className="text-14 my-0">
                                <span className="fm darkGrey fw-semibold me-1 d-lg-none">
                                  Date Expiry:
                                </span>
                                {moment(item.offer_expire_date).format(
                                  "DD.MM.YYYY"
                                )}
                              </p>
                            </div>
                            <div className="col-lg-2 col-md-12 order-lg-5 order-2 mt-lg-0 col-sm-6 mt-2">
                              <p className="text-14 my-0 text-capitalize">
                                <span className="fm darkGrey fw-semibold me-1 d-lg-none">
                                  Role:
                                </span>
                                {item.contract_title}
                              </p>
                            </div>

                            <div className="col-lg-1 order-last mt-lg-0 mt-2">
                              <div className="offers__icon">
                                <span className="fm darkGrey fw-semibold me-2 d-lg-none">
                                  Download Contract:
                                </span>
                                {item?.offered_Letter ? (
                                  item.offered_Letter == "Pending" ? (
                                    "Pending"
                                  ) : (
                                    <img
                                      title="Download Contract"
                                      onClick={() =>
                                        handleDownload(item.offered_Letter.Key)
                                      }
                                      src="/assets/images/pdf_icon.png"
                                    />
                                  )
                                ) : (
                                  "--"
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="mt-3 d-flex justify-content-center fm">
                          <h3 className="fs-3 fm">No Offers</h3>
                        </div>
                      )}

                      <div className="d-md-none mb-3">
                        {currentItems && currentItems.length > 0 ? (
                          <div className="d-md-none d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all offers ({currentItems.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProOffers;
