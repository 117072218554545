import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import {
  BsCheckCircle,
  BsFillEmojiSmileFill,
  BsThreeDotsVertical,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { VscArrowRight } from "react-icons/vsc";
import { HiArrowLeft } from "react-icons/hi";
import GradientSVG from "./gradientSVG";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { CiEdit, CiLinkedin, CiTrash, CiMail, CiLock } from "react-icons/ci";
import { MdOutlineFileDownload } from "react-icons/md";
import { PiDownloadSimpleLight } from "react-icons/pi";
import { HiArrowRight } from "react-icons/hi2";
import { IoEyeOutline } from "react-icons/io5";
import {
  Avatar,
  Progress,
  Tabs,
  Dropdown,
  Menu,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  message,
  Tooltip,
  Radio,
  Space,
} from "antd";
import { IoIosArrowDown } from "react-icons/io";
import { UserOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { SlOptionsVertical, SlArrowDown } from "react-icons/sl";
import { AiFillLike, AiOutlineDelete } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import config from "../../../services/apisConfig";
import {
  getProfessionalById,
  getEnterprises,
  downloadApplicantsFeedback,
  UpdateSimpleProfessional,
  uploadprofileImage,
  questionParser,
  uploadVideo,
  uploadFile,
  createComment,
  getComments,
  createReply,
  updateCandidateStatus,
} from "../../../services/auth";
import { useLocation } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";
import ReactPDF, {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
  Image,
  PDFDownloadLink,
  Font,
  PDFViewer,
  pdf,
} from "@react-pdf/renderer";
import { Loader, useToaster, Uploader, Message } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import { fontWeight } from "@mui/system";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import ReferenceFeedback from "../../ReferenceFeedback";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const HiringReport = ({ docResponse }) => {
  Font.register({
    family: "Open Sans",
    src: "/assets/fonts/OpenSans-Regular.ttf",
  });

  const backgroundColor =
    docResponse.CultureFit <= 50
      ? "red"
      : docResponse.CultureFit <= 80
      ? "orange"
      : "#B2DA66";

  const styles = StyleSheet.create({
    topBar: {
      height: 6,
      backgroundColor: "#B2DA66",
      marginBottom: 10,
    },

    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 20,
      marginTop: 20,
    },
    ratingBox: {
      display: "flex",
      flexDirection: "row",
    },
    rating: {
      width: 8,
      height: 8,
    },
    ratingBoxSmall: {
      display: "flex",
      flexDirection: "row",
    },
    ratingSmall: {
      width: 8,
      height: 8,
      marginTop: 2,
    },
    heading: {
      display: "block",
      marginBottom: 5,
      fontSize: 16,
      fontWeight: "600",
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 10,
      fontWeight: "600",
      color: "#9D9EA2",
      fontFamily: "Open Sans",
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
    },
    card: {
      border: "1px solid #B6B7BA",
      marginRight: "20px",
      display: "flex",
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      padding: "15px 10px",
      width: 350,
      height: 90,
      borderRadius: "5px",
      fontFamily: "Open Sans",
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 50,
      minHeight: 50,
      width: 50,
      height: 50,
    },
    tickMark: {
      height: 8,
      width: 8,
      marginTop: 5,
      marginLeft: 10,
      display: "flex",
    },
    progressGrey: {
      position: "relative",
      marginTop: "2px",
      marginBottom: "12px",
      borderRadius: "25px",
      height: 24,
      backgroundColor: "#E3E3E3",
      width: "100%",
    },
    progressFill: {
      position: "absolute",
      left: 0,
      top: 0,
      borderRadius: "25px",
      height: 12,
      backgroundColor: backgroundColor,
      width: `${docResponse.CultureFit}%`,
    },
    tinyTitle: {
      fontSize: 8,
      color: "#CBCBCB",
      fontWeight: "light",
      marginBottom: 6,
      fontFamily: "Open Sans",
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    cardBottom: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      textTransform: "uppercase",
      paddingRight: "40px",
      fontFamily: "Open Sans",
    },
    cardOuter: {
      display: "flex",
      flexDirection: "row",
      width: 600,
      marginTop: 15,
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      // display: "inline-block",
      color: "#B2DA66",
      width: "25px",
      fontWeight: "400",
      marginRight: 30,
    },
    star: {
      display: "inline-block",
      color: "#B2DA66",
      width: "25px",
      fontWeight: "400",
    },
    question: {
      color: "#4A4A49",
      fontSize: 8,
      fontFamily: "Open Sans",
    },
    boldTitleDarkGrey: {
      fontWeight: "800",
      color: "#4A4A49",
      textTransform: "uppercase",
      fontSize: 10,
    },
  });
  return (
    <Document style={styles.doc}>
      <Page style={styles.page}>
        <View style={styles.topBar} />
        <View style={styles.container}>
          <Image src="/assets/svg/logo.png" style={styles.logo} />

          <Text style={styles.heading}>Hiring Recommendation Report</Text>
          <Text style={styles.subTitle}>
            {moment(docResponse.applicationData.createdAt).format(
              "dddd D MMMM, HH:mm"
            )}{" "}
          </Text>
          <Text style={styles.subTitle}>
            Interview Type,{" "}
            <Text style={{ color: "#CBCBCB", fontWeight: "normal" }}>
              Web (Zoom)
            </Text>
          </Text>

          {docResponse.applicationData.feedback_result ? (
            <View style={styles.cardOuter}>
              {/* card */}
              <View style={styles.card}>
                <Image
                  src="/assets/images/Users/user1.png"
                  style={styles.avatar}
                />
                <View style={{ marginLeft: "12px" }}>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#4A4A49",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {docResponse.name}{" "}
                    <Text style={{ fontWeight: "normal", color: "#797978" }}>
                      ({docResponse.pronouns})
                    </Text>
                  </Text>
                  <View style={styles.progressGrey}>
                    <View style={styles.progressFill}></View>
                  </View>
                  <View style={styles.cardBottom}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 30,
                      }}
                    >
                      <Text style={styles.tinyTitle}>APPLICATION DATE</Text>
                      <Text style={styles.date}>
                        {moment(docResponse.applicationData.createdAt).format(
                          "DD.MM.YYYY"
                        )}
                      </Text>
                    </View>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: 40,
                      }}
                    >
                      <Text style={styles.tinyTitle}>INDEXSCORE</Text>
                      <Text style={styles.date}>
                        {docResponse.applicationData.feedback_result.indexScore}
                      </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "column" }}>
                      <Text style={styles.tinyTitle}>PURPOSE ALIGNMENT</Text>
                      <Text style={styles.date}>{docResponse.CultureFit}%</Text>
                    </View>
                  </View>
                  {/* </View> */}
                </View>
              </View>
              <View style={styles.cardRight}>
                <Text
                  style={{
                    textTransform: "uppercase",
                    fontSize: 12,
                    color: "#4A4A49",
                    fontWeight: 600,
                  }}
                >
                  Candidate Summary
                </Text>
                <Text
                  style={{
                    display: "flex",
                    color: "#4A4A49",
                    fontWeight: "light",
                    fontSize: 10,
                  }}
                >
                  Sponsorphip:{" "}
                  <Text
                    style={{
                      fontSize: 10,
                      paddingLeft: "5px",
                      marginRight: 10,
                    }}
                  >
                    Yes{" "}
                  </Text>
                  <Image
                    src="/assets/images/check-mark.png"
                    style={styles.tickMark}
                  />
                </Text>
                <Text
                  style={{
                    display: "flex",
                    color: "#4A4A49",
                    fontWeight: "light",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    fontSize: 10,
                  }}
                >
                  Hiring Recommendation:{" "}
                  <Text style={{ fontSize: 10, paddingLeft: "5px" }}>
                    {
                      docResponse.applicationData.feedback_result
                        .hiring_recommendation
                    }{" "}
                  </Text>
                  <Image
                    src="/assets/images/check-mark.png"
                    style={styles.tickMark}
                  />
                </Text>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Text
                    style={{
                      display: "flex",
                      color: "#4A4A49",
                      fontWeight: "light",
                      fontSize: 10,
                    }}
                  >
                    Interview Rating:{" "}
                  </Text>{" "}
                  <View style={styles.ratingBoxSmall}>
                    {Array.from(
                      {
                        length:
                          docResponse.applicationData.feedback_result
                            .final_rating,
                      },
                      (_, index) => (
                        <Image
                          key={index}
                          src="/assets/images/star-image.png"
                          style={styles.ratingSmall}
                          alt={`Star ${index + 1}`}
                        />
                      )
                    )}
                    {/* <Image src="/assets/images/star-image.png" style={styles.rating} /> */}
                  </View>
                </View>
              </View>
            </View>
          ) : (
            ""
          )}

          {/* dotted border */}
          <View
            style={{
              borderBottom: "2px solid #B2DA66",
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
              borderBottomStyle: "dotted",
            }}
          />

          {/* section2 */}
          <Text
            style={{
              textTransform: "uppercase",
              fontSize: 12,
              color: "#0D2159",
              paddingLeft: 10,
            }}
          >
            Notes Summary
          </Text>
          <View style={{ marginBottom: 10 }}>
            <View style={{ width: "100%", paddingLeft: 10, display: "flex" }}>
              {docResponse.applicationData.feedback_result.feedbacks.map(
                (item, index) => (
                  <Text style={styles.question}>
                    <Text style={styles.bullet}>{index + 1} </Text>
                    {item.note_summary}
                  </Text>
                )
              )}
            </View>
          </View>

          {/* section3 */}
          {docResponse.applicationData.feedback_result &&
            docResponse.applicationData.feedback_result.feedbacks.map(
              (item) => (
                <View>
                  <View style={styles.header}>
                    <Text
                      style={{
                        color: "#4A4A49",
                        fontWeight: "600",
                        fontSize: 10,
                      }}
                    >
                      {item.interviewerName}
                    </Text>
                    <Text
                      style={{
                        color: "#4A4A49",
                        fontWeight: "600",
                        fontSize: 10,
                      }}
                    >
                      Hire{" "}
                      <Image
                        src="/assets/images/check-mark.png"
                        style={styles.tickMark}
                      />
                    </Text>
                    <Text
                      style={{
                        color: "#4A4A49",
                        fontWeight: "600",
                        fontSize: 10,
                        display: "flex",
                      }}
                    >
                      Rating{" "}
                      <View style={styles.ratingBox}>
                        {Array.from({ length: item.rating }, (_, index) => (
                          <Image
                            key={index}
                            src="/assets/images/star-image.png"
                            style={styles.rating}
                            alt={`Star ${index + 1}`}
                          />
                        ))}
                      </View>
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      marginTop: "5px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <View style={{ width: "45%" }}>
                      <Text style={styles.boldTitleDarkGrey}>
                        Question Answers
                      </Text>
                      <Text
                        style={{
                          fontWeight: "400",
                          color: "#9D9EA2",
                          fontSize: 6,
                        }}
                      >
                        Would you recommend to be a part of our organization?:
                      </Text>
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#0D2159",
                          paddingBottom: "20px",
                          fontSize: 8,
                        }}
                      >
                        {item.recommendation}
                      </Text>

                      <Text
                        style={{
                          fontWeight: "400",
                          color: "#9D9EA2",
                          fontSize: 8,
                        }}
                      >
                        If {item.recommendation}:
                      </Text>
                      <Text
                        style={{
                          fontWeight: "600",
                          color: "#0D2159",
                          paddingBottom: "20px",
                          fontSize: 8,
                        }}
                      >
                        {item.feedback}
                      </Text>
                    </View>

                    <View style={{ width: "55%" }}>
                      <Text style={styles.boldTitleDarkGrey}>
                        Notes Summary
                      </Text>
                      <Text style={styles.question}>
                        <Text style={styles.bullet}>1 </Text>{" "}
                        {item.feedbackComment}
                      </Text>
                    </View>
                  </View>
                </View>
              )
            )}
        </View>
      </Page>
    </Document>
  );
};

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text fm mb-0 fm prof__para fw-normal">
      {isReadMore ? text.slice(0, 450) + "..." : text}
      <p
        onClick={toggleReadMore}
        className="read-or-hide fm prof__para blue pointer fw-normal mb-0"
      >
        {isReadMore ? "Read more" : " Show less"}
      </p>
    </p>
  );
};

const social = [
  {
    id: 1,
    name: "Facebook",
    username: "Username",
    profileLink: "",
  },
  {
    id: 3,
    name: "Instagram",
    username: "Username",
    profileLink: "",
  },
  {
    id: 4,
    name: "LinkedIn",
    username: "Username",
    profileLink: "",
  },
  {
    id: 6,
    name: "Twitter",
    username: "Username",
    profileLink: "",
  },
  // {
  //   id: 8,
  //   name: "Persona",
  //   username: "Username",
  //   profileLink: "",
  // },
  // {
  //   id: 9,
  //   name: "Tiktok",
  //   username: "Username",
  //   profileLink: "",
  // },
  // {
  //   id: 10,
  //   name: "Medium",
  //   username: "Username",
  //   profileLink: "",
  // },
];
const awards = [
  {
    id: 1,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
  {
    id: 2,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
  {
    id: 3,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
];
const publications = [
  {
    id: 1,
    name: "Publication",
    year: "2017",
    certificate: "",
  },
];
var comments = [];
// const comments = [
//   {
//     id: 1,
//     name: "Adu Opoki-Boahin",
//     date: "2023.04.30 / 14:54",
//     message:
//       "Comment about user/candidate that reflect job and other issues. Comment about user/candidate that reflect job and other issues. Comment about user/candidate that reflect job and other issues.",
//     replies: [
//       {
//         id: 1,
//         message: "Great work.",
//       },
//       {
//         id: 2,
//         message: "Well done!",
//       },
//     ],
//   },
// ];
const idCSS = "hello";

const experience = [
  {
    id: 1,
    jobname: "Job title",
    company: "Company",
    desc: "Description of job/position here of job/position here Description of job/position. of job/position here of job/position here Description of job/position. Description of job/position here of job/position here Description of job/position.",
    tag: "2017 – Present",
  },
  {
    id: 2,
    jobname: "Job title",
    company: "Company",
    desc: "Description of job/position here of job/position here Description of job/position. of job/position here of job/position here Description of job/position. Description of job/position here of job/position here Description of job/position.",
    tag: "2017 – Present",
  },
];
const reference = [
  {
    id: 1,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
  {
    id: 2,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
  {
    id: 3,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
];
const YourProfile = (props) => {
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [purpose, setPurpose] = useState(0);
  const [showReplies, setShowReplies] = useState(false);
  const [typeReply, setTypeReply] = useState(false);
  const [indexcore, setIndexcore] = useState(0);
  const [add, setAdd] = useState(false);
  const [img, setImg] = useState(
    `/assets/images/Users/user-${add ? "mid" : "dark"}.svg`
  );
  const [text, setText] = useState("Add");
  const buttonRef = useRef(null);
  const [responseEnterprises, setResponseEnterprises] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [feedbackResponse, setFeedbackResponse] = useState([]);

  const [visible, setVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [editNameVisible, setEditNameVisible] = useState(false);
  const [editContactVisible, setEditContactVisible] = useState(false);

  const [resumeVisible, setResumeVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoFile, setVideoFile] = useState(null);

  const [showReplyBoxIndex, setShowReplyBoxIndex] = useState(-1);
  const [newComment, setNewComment] = useState(null);
  const [commentReply, setCommentReply] = useState(null);
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleUpload = (info) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  // const renderAndAddToZip = async (zip, component, fileName) => {
  //   const pdfBlob = await new Promise((resolve, reject) => {
  //     const renderPDF = async () => {
  //       try {
  //         const { pdf } = await component.props.document.toBuffer();
  //         resolve(pdf);
  //       } catch (error) {
  //         reject(error);
  //       }
  //     };
  //     renderPDF();
  //   });

  //   zip.file(fileName, pdfBlob);
  // };

  const downloadFile = () => {
    const filePath = "/assets/pdf/accurate-report.pdf"; // Your static file path
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "accurate-report.pdf"; // File name for download
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadFile1 = () => {
    const filePath = "/assets/pdf/criteria-report.pdf"; // Your static file path
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "criteria-report.pdf"; // File name for download
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadZip = async () => {
    const zip = new JSZip();

    const criteriaResponse = await fetch("/assets/pdf/criteria-report.pdf");
    const criteriaBlob = await criteriaResponse.blob();
    zip.file("CriteriaReport.pdf", criteriaBlob);

    const accurateResponse = await fetch("/assets/pdf/accurate-report.pdf");
    const accurateBlob = await accurateResponse.blob();
    zip.file("AccurateReport.pdf", accurateBlob);

    const pdfUrl = professionalData[0]?.resumes?.Key;
    await handleDownloadAndZip(pdfUrl, zip);

    if (professionalData[0]?.references_feedback) {
      const referencesFeedbackBlob = await fetchReferenceFeedbackBlob(
        professionalData[0]?.references_feedback || []
      );
      if (referencesFeedbackBlob) {
        zip.file("ReferencesFeedback.pdf", referencesFeedbackBlob, {
          binary: true,
        });
      }
    }

    const hiringReportBlob = await fetchHiringReportBlob();
    if (hiringReportBlob) {
      zip.file("HiringRecommendationReport.pdf", hiringReportBlob, {
        binary: true,
      });
    }
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "Reports.zip");
    });
  };

  const fetchReferenceFeedbackBlob = async (referenceFeedbacks) => {
    return new Promise((resolve, reject) => {
      const pdfBlob = pdf(
        <ReferenceFeedback referenceFeedbacks={referenceFeedbacks} />
      ).toBlob();
      pdfBlob.then(resolve).catch(reject);
    });
  };

  const fetchHiringReportBlob = async () => {
    return new Promise((resolve, reject) => {
      if (
        !props.page ||
        !props.data.applicationData.feedback_result ||
        !props.data.managerID ||
        props.data.managerID !== localStorage.getItem("companyID") ||
        !["interviewed", "selected", "offered", "started", "rejected"].includes(
          props.data.status
        )
      ) {
        resolve(null);
        return;
      }

      const pdfBlob = pdf(<HiringReport docResponse={props.data} />).toBlob();
      pdfBlob.then(resolve).catch(reject);
    });
  };

  const beforeUpload = (file) => {
    // Check if the selected file is a video
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      console.log("You can only upload video files!");
      // message.error('You can only upload video files!');
    } else {
      setVideoFile(file);
    }
    return false;
  };

  const DeleteSelectedVideo = () => {
    setVideoFile(null);
  };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoUrlLoading, setVideoUrlLoading] = useState(false);
  // useState('/assets/images/jawad.png');
  const [fileInfo, setFileInfo] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);
  const [resumeFileLoader, setResumeFileLoader] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState(""); // State variable to capture selected certification
  const [addCertificateLoader, setAddCertificateLoader] = useState(false);
  console.log("props.data.status", props.data.status);
  const [stage, setStage] = useState(props.data.status);

  useEffect(() => {
    setStage(props.data.status);
  }, [props.data.status]);

  const toaster = useToaster();

  const handleChange = (key, value) => {
    if (key === "files" && value.length > 0) {
      // Assuming you want to display only the first uploaded file
      console.log("value[0]", value[0]);
      setImageUrl(value[0]);
    }
  };

  function previewFile(file, callback) {
    console.log(file); // Check the 'file' object in the console.
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const getBase64 = (img) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      const url = reader.result;
      // Assign the path to setImageUrl here
      setImageUrl(url);
    });

    reader.readAsDataURL(img);
    setLoading(false);
    // console.log("image url", url);
  };

  const beforePhotoUpload = (file) => {
    console.log("file", file);
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      console.log("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.log("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handlePhotoChange = (info) => {
    if (info) {
      // Get this url from response in the real world.
      // console.log("info.file.originFileObj", info.file.originFileObj);
      getBase64(info.file.originFileObj);
    }
  };

  const uploadPhoto = async () => {
    if (imageUrl) {
      setImageLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", imageUrl.blobFile);
      formData.append("_id", responseProfessionalData[0]._id);
      formData.append("usertype", "professional");

      try {
        const response = await uploadprofileImage(formData);
        if (response.status == 200) {
          localStorage.setItem(
            "professionalData",
            JSON.stringify([response.data.data])
          );
          setUserData([response.data.data]);
          setPhotoVisible(false);
          setImageUrl(null);
          console.log("Image uploaded:", response);
          setImageLoading(false);

          enqueueSnackbar("Photo Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setLoading(false);
    }
  };

  const handleVideoUpload = async () => {
    if (videoFile) {
      setVideoUrlLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", videoFile[0].blobFile);
      formData.append("_id", responseProfessionalData[0]._id);
      formData.append("usertype", "professional");

      try {
        const response = await uploadVideo(formData);
        if (response.status == 200) {
          localStorage.setItem(
            "professionalData",
            JSON.stringify([response.data.data])
          );
          setUserData([response.data.data]);

          setVideoVisible(false);
          setVideoUrl(null);
          setVideoFile(null);
          console.log("Video uploaded:", response);
          setVideoUrlLoading(false);

          enqueueSnackbar("Video Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setVideoUrlLoading(false);
    }
  };

  const handleResumeUpload = async () => {
    setResumeFileLoader(true);

    console.log("resume", resumeFile);
    console.log("resume", resumeFile[0].blobFile);
    const formData = new FormData();
    formData.append("_id", responseProfessionalData[0]._id);
    formData.append("usertype", "professional");
    formData.append("files", resumeFile[0].blobFile);
    var resp = await uploadFile(formData);

    if (resp.status == 200) {
      localStorage.setItem(
        "professionalData",
        JSON.stringify([resp.data.data])
      );
      setUserData([resp.data.data]);
      setResumeFile(null);

      enqueueSnackbar("Resume updated successfully ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }

    // const professionalId = responseProfessionalData[0]._id;
    // var payLoadtoGetPro = {
    //   _id: professionalId
    // };

    // const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    // const responseProfessionalApi = respProfessionalByID.data.data;
    // localStorage.setItem("professionalData", JSON.stringify(respProfessionalByID.data.data));
    // setUserData(respProfessionalByID.data.data);

    setResumeFileLoader(false);
    setResumeVisible(false);
  };

  const handleCertificationChange = (value) => {
    setSelectedCertification(value);
  };

  const handleAddCertification = async () => {
    if (selectedCertification === "") {
      // You can show an error message or handle the validation as per your requirements
      enqueueSnackbar("Please select certification ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }

    setAddCertificateLoader(true);

    const newCertification = { name: selectedCertification };

    const existingCertifications =
      responseProfessionalData[0].certification || [];
    const updatedCertifications = [...existingCertifications, newCertification];

    const payload = {
      certification: updatedCertifications,
      usertype: "professional",
      _id: responseProfessionalData[0]._id,
    };

    const responseCertifications = await UpdateSimpleProfessional(payload);
    if (responseCertifications.status == 200) {
      localStorage.setItem(
        "professionalData",
        JSON.stringify([responseCertifications.data.user])
      );
      setUserData([responseCertifications.data.user]);

      setSelectedCertification(null);

      enqueueSnackbar("Certification added ", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }

    setAddCertificateLoader(false);
    setVisible(false);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [shareEmail, setShareEmail] = useState(null);

  const { confirm } = Modal;
  const { Option, OptGroup } = Select;

  const showPromiseConfirm = () => {
    confirm({
      title: "Subscribe your plan",
      // icon: <AiOutlinePlus />,
      content:
        "You need to purchase a plan in order to improve your indexscore",
      onOk() {
        // return new Promise((resolve, reject) => {
        //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        // }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {},
    });
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setVisible(false); // Close the modal after submission
  };
  const handleCancel = () => {
    setVisible(false); // Close the modal without saving changes
  };

  const EditNameModal = () => {
    setEditNameVisible(true);
  };
  const EditNamehandleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setEditNameVisible(false); // Close the modal after submission
  };
  const EditNamehandleCancel = () => {
    setEditNameVisible(false); // Close the modal without saving changes
  };

  const EditContactModal = () => {
    setEditContactVisible(true);
  };
  const EditContacthandleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setEditContactVisible(false); // Close the modal after submission
  };
  const EditContacthandleCancel = () => {
    setEditContactVisible(false); // Close the modal without saving changes
  };

  //photo
  const EditPhotoModal = () => {
    setPhotoVisible(true);
  };
  const EditPhotohandleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setPhotoVisible(false); // Close the modal after submission
  };
  const EditPhotohandleCancel = () => {
    setPhotoVisible(false); // Close the modal without saving changes
  };

  // Resume modal
  const EditResumeModal = () => {
    setResumeVisible(true);
  };
  const EditResumehandleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setResumeVisible(false); // Close the modal after submission
  };
  const EditResumehandleCancel = () => {
    setResumeVisible(false); // Close the modal without saving changes
  };

  // Video Modal
  const EditVideoModal = () => {
    setVideoVisible(true);
  };
  const EditVideohandleOk = () => {
    console.log(`Name: ${name}, Email: ${email}`);
    setVideoVisible(false); // Close the modal after submission
  };
  const EditVideohandleCancel = () => {
    setVideoVisible(false); // Close the modal without saving changes
  };

  // const [instance, updateInstance] = usePDF({ document: MyDoc(props) });

  // console.log("instance url ", instance);
  // if (instance.loading) return <div>Loading ...</div>;

  // if (instance.error) return <div>Something went wrong: {error}</div>;

  // const handleExportPDF = () => {
  //   const doc = new jsPDF();
  //   doc.text(JSON.stringify(jsonData, null, 2), 10, 10);
  //   doc.save('data.pdf');
  // };
  // const Add_func = () => {
  //   const buttonText = buttonRef.current.innerText;
  //   if (buttonText === "Add") {
  //     setAdd(true);
  //     setText("Added")
  //   }
  // };

  // console.log(" your profile props", props);

  const handleAddItemClick = (ent_item) => {
    if (selectedItems.includes(ent_item)) {
      // If the item is already in selectedItems, remove it

      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== ent_item)
      );
    } else {
      // If the item is not in selectedItems, add it
      setSelectedItems([...selectedItems, ent_item]);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  // const fetchEnterprises = async () => {

  //   try {
  //     const response = await getEnterprises();
  //     setResponseEnterprises(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  const items = [
    {
      key: "1",
      label: <p className="fm darkGrey mb-0">Delete</p>,
    },
    {
      key: "2",
      label: <p className="fm darkGrey mb-0">Edit</p>,
    },
  ];
  const [docData, setDocData] = useState([]);

  useEffect(() => {
    downloadFeedback();
  }, [docData]);

  const downloadFeedback = async () => {
    // if (props.page === "enterprise") {
    //   try {
    //     const payload = {
    //       _id: props.data.applicantID,
    //       feedback: {
    //         indexScore: props.data.indexCore,
    //         feedbacks: props.data.applicationData.interviewers_feedback
    //       }
    //     };
    //     const responseFeedbackDownload = await downloadApplicantsFeedback(payload);
    //     if (responseFeedbackDownload.data.statusCode === 1) {
    //       console.log("data from api" , responseFeedbackDownload.data.data.replace(/'/g, '"'));
    //       setDocData(JSON.parse(responseFeedbackDownload.data.data));
    //       // const parsedData = JSON.parse(responseFeedbackDownload.data.data.replace(/'/g, '"'));
    //       // setDocData(parsedData);
    //       // MyDoc(parsedData);
    //     }
    //   } catch (error) {
    //     console.error("An error occurred:", error);
    //   }
    // }
  };

  // console.log("docData in your profile",docData);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  // console.log("props.interviewersList", props.interviewersList);
  const filteredEnterprises =
    props.page === "enterprise"
      ? props.interviewersList.filter((enterprise) => {
          const { role, firstName, lastName, emailAddress } = enterprise;
          const searchValue = searchText.toLowerCase();

          // Check if the role is not "hr" and name and description exist before applying toLowerCase()
          const isNotHR = role && role.toLowerCase() !== "hr";
          const lowerCaseName = firstName ? firstName.toLowerCase() : "";
          const lowerCaseDescription = lastName ? lastName.toLowerCase() : "";

          return (
            isNotHR &&
            (lowerCaseName.includes(searchValue) ||
              lowerCaseDescription.includes(searchValue))
          );
        })
      : "";

  useEffect(() => {
    const button = buttonRef.current;

    if (button) {
      const handleMouseEnter = () => {
        if (add) {
          setText("Remove");
          setImg(`/assets/images/Users/user-red.svg`);
          button.classList.add("added");
        }
      };

      const handleMouseLeave = () => {
        setText("Added");
        setImg(`/assets/images/Users/user-${add ? "mid" : "dark"}.svg`);
        button.classList.remove("added");
      };

      button.addEventListener("mouseenter", handleMouseEnter);
      button.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        button.removeEventListener("mouseenter", handleMouseEnter);
        button.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [add]);

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };
  const handleMenuClick = (e) => {
    setOpen(true);
  };

  const handleOpenChange2 = (flag) => {
    setOpenSecond(flag);
  };
  const handleMenuClick2 = (e) => {
    setOpenSecond(true);
  };

  const handleOpenShareChange = (flag) => {
    setOpenShare(flag);
  };
  const handleMenuShareClick = (e) => {
    setOpenShare(true);
  };

  // openShare

  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const location = useLocation();
  var respProfessionalByID;
  // var professionalData;
  const [professionalData, setProfessionalData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  // const { responseProfessionalData } = location.state ? location.state : [];
  const responseProfessionalData = JSON.parse(
    localStorage.getItem("professionalData")
  );
  if (props.page === "professional") {
  }

  useEffect(() => {
    fetchProfessional();
  }, [professionalData]);

  const fetchProfessional = async () => {
    // console.log("props.data.id" + props.data.id);
    if (props.page === "enterprise") {
      try {
        const payLoadtoGetPro = {
          _id: props.data.id,
        };
        const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
        setProfessionalData(respProfessionalByID.data.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  //update functions

  const [updateFirstName, setUpdateFirstName] = useState(
    props.page === "professional" ? responseProfessionalData[0].firstName : ""
  );
  const [updateLastName, setUpdateLastName] = useState(
    props.page === "professional" ? responseProfessionalData[0].lastName : ""
  );
  const [updateNameLoader, setUpdateNameLoader] = useState(false);
  const [error, setError] = useState("");

  const updateProNameHandler = async () => {
    if (props.page === "professional") {
      if (!updateFirstName || !updateLastName) {
        setError("Please fill in all fields.");
        return;
      }

      setUpdateNameLoader(true);
      setError(""); // Clear any previous errors

      try {
        const payloadUpdateName = {
          _id: responseProfessionalData[0]._id,
          firstName: updateFirstName,
          lastName: updateLastName,
        };

        const responseUpdate = await UpdateSimpleProfessional(
          payloadUpdateName
        );
        localStorage.setItem(
          "professionalData",
          JSON.stringify([responseUpdate.data.user])
        );
        setUserData([responseUpdate.data.user]);
        setUpdateNameLoader(false);
        setEditNameVisible(false);
      } catch (error) {
        setError("An error occurred while updating. Please try again.");
      }
    }
  };

  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(
    props.page === "professional" ? responseProfessionalData[0].phoneNumber : ""
  );
  const [updateAddress, setUpdateAddress] = useState(
    props.page === "professional" ? responseProfessionalData[0].address : ""
  );
  const [updateContactLoader, setUpdateContactLoader] = useState(false);
  const [contactError, setContactError] = useState("");
  const updateProContactHandler = async () => {
    if (props.page === "professional") {
      setUpdateContactLoader(true);
      setContactError(""); // Clear any previous errors

      if (!updatePhoneNumber) {
        setContactError("Phone number is required");
      } else if (!updateAddress) {
        setContactError("Address is required");
      } else {
        const payloadUpdateContact = {
          _id: responseProfessionalData[0]._id,
          phoneNumber: updatePhoneNumber,
          address: updateAddress,
        };

        const responseUpdate = await UpdateSimpleProfessional(
          payloadUpdateContact
        );

        localStorage.setItem(
          "professionalData",
          JSON.stringify([responseUpdate.data.user])
        );
        setUserData([responseUpdate.data.user]);
        setUpdateContactLoader(false);
        setEditContactVisible(false);
      }
    }
  };

  // console.log("textAreaValues", professionalData);
  const [isEditing, setIsEditing] = useState(false);
  const [textAreaValues, setTextAreaValues] = useState({
    question1:
      props.page === "professional"
        ? responseProfessionalData[0].openAnswers.open_question_one
        : professionalData
        ? professionalData[0].openAnswers.open_question_one
        : "",
    question2:
      props.page === "professional"
        ? responseProfessionalData[0].openAnswers.open_question_two
        : professionalData
        ? professionalData[0].openAnswers.open_question_two
        : "",
    question3:
      props.page === "professional"
        ? responseProfessionalData[0].openAnswers.open_questions_three
        : professionalData
        ? professionalData[0].openAnswers.open_questions_three
        : "",
    question4:
      props.page === "professional"
        ? responseProfessionalData[0].openAnswers.open_questions_four
        : professionalData
        ? professionalData[0].openAnswers.open_questions_four
        : "",
    question5:
      props.page === "professional"
        ? responseProfessionalData[0].openAnswers.open_questions_five
        : professionalData
        ? professionalData[0].openAnswers.open_questions_five
        : "",
  });

  const [textAreaValuesEnterprise, setTextAreaValuesEnterprise] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  });

  useEffect(() => {
    // Check if professionalData is available and set textAreaValues accordingly
    if (props.page === "enterprise" && professionalData) {
      setTextAreaValuesEnterprise({
        question1: professionalData[0].openAnswers.open_question_one || "",
        question2: professionalData[0].openAnswers.open_question_two || "",
        question3: professionalData[0].openAnswers.open_questions_three || "",
        question4: professionalData[0].openAnswers.open_questions_four || "",
        question5: professionalData[0].openAnswers.open_questions_five || "",
      });
    }
  }, [professionalData, props.page]);

  const [questionsLoading, setQuestionsLoading] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateClick = async () => {
    setQuestionsLoading(true);

    // Call your API to update the values here, using textAreaValues
    // After successfully updating, set isEditing to false

    const updatedValues = {
      question1: textAreaValues.question1,
      question2: textAreaValues.question2,
      question3: textAreaValues.question3,
      question4: textAreaValues.question4,
      question5: textAreaValues.question5,
    };

    const currentUserId = localStorage.getItem("enterpriseID");
    const payload = {
      _id: currentUserId,
      usertype: "professional",
      openAnswers: {
        open_question_one: updatedValues.question1,
        open_question_two: updatedValues.question2,
        open_questions_three: updatedValues.question3,
        open_questions_four: updatedValues.question4,
        open_questions_five: updatedValues.question5,
      },
    };

    const res = await questionParser(payload);
    if (res.status == 200) {
      var responseProUpdate = await UpdateSimpleProfessional(payload);
      if (responseProUpdate.status == 200) {
        enqueueSnackbar("Questions Updated!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setIsEditing(false);
        setQuestionsLoading(true);

        localStorage.setItem(
          "professionalData",
          JSON.stringify([responseProUpdate.data.user])
        );
        setUserData([responseProUpdate.data.user]);
      }
    }
  };

  const handleTextAreaChange = (e, questionName) => {
    setTextAreaValues({
      ...textAreaValues,
      [questionName]: e.target.value,
    });
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (props.page === "professional") {
        setIndexcore(responseProfessionalData[0].ai_data.index_core);
        setPurpose(
          responseProfessionalData[0].ai_data.job_relevancy_score
            ? responseProfessionalData[0].ai_data.job_relevancy_score.CultureFit
            : 0
        );
      } else {
        setIndexcore(props.data.indexCore);
        setPurpose(props.data.CultureFit);
      }
    }, 500);
    // return () => clearTimeout(timerId)
    return () => clearTimeout(timerId);
  }, []);

  const handleDownload = async (pdfUrl) => {
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume.pdf");
        document.body.appendChild(link);
        link.click();
      });
      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const handleDownloadAndZip = async (pdfUrl, zip) => {
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data]);
      zip.file("Resume.pdf", blob, { binary: true });
    } catch (error) {
      console.error("Error fetching or adding file to zip:", error);
    }
  };

  const uploadButton = (
    <div className="photo-upload-button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Attach or drag photo here
      </div>
    </div>
  );

  if (props.page === "enterprise") {
    // console.log("current candidate", props.data);
  }

  const Meeting = () => {
    if (selectedItems.length === 0) {
      enqueueSnackbar(`Select Interviewers to proceed`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      console.log("selectedItems", selectedItems);
      props.interviewData(selectedItems);
      props.meeting(true);
      props.confirm(false);
      props.profile(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      myContext.updateContextData(professionalData);
    }
  };
  const Candidates = () => {
    props.confirm(false);
    props.meeting(false);
    props.profile(false);
    props.candidate(true);
    props.contract(false);
    props.updatingBoard();
  };

  const Contract = () => {
    props.confirm(false);
    props.meeting(false);
    props.profile(false);
    props.candidate(false);
    props.contract(true);
  };

  const goToMessaging = async (candidateID) => {
    navigate("/enterprise/dashboard/inbox");
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const nameopt = [
    {
      value: "Admin",
      label: "Admin",
      avatar: "/assets/images/userAvatar2.svg",
    },
    {
      value: "Manager",
      label: "Manager",
      avatar: "/assets/images/userAvatar2.svg",
    },
  ];
  const TabPane = Tabs.TabPane;

  const meetingMenu =
    props.page === "enterprise" ? (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={Meeting}
          >
            <p className="fm blue mb-0">
              Schedule / Request Interview with expert
            </p>{" "}
            <HiArrowRight className="ms-4 fs-6 blue mb-0" />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <div className="d-flex align-items-center">
            <GoSearch className="midGrey fs-5 me-1" />
            <input
              type="search"
              value={searchText}
              onChange={handleSearchChange}
              className="meeting__search"
              placeholder="Search members to add"
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">
          <div>
            <div className="text-center mb-3">
              {/* <p className="fm darkGrey fw-semibold text-center mb-0">
              24 people in Unititled Co.
            </p> */}
              <small className="fm darkGrey text-12">
                Assign interviewers from the list below
              </small>
            </div>

            {filteredEnterprises.map((ent_item) => {
              // const isMember = props.data.interviewers.find(
              //   (member) => member.id === ent_item._id
              // );

              // console.log("isMember ka response" , isMember);

              return (
                <div
                  className="d-flex justify-content-between align-items-center mb-3"
                  key={ent_item.id}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/userAvatar.svg"
                      alt=""
                      className="meeting__user me-2 mb-1"
                    />

                    <div>
                      <p className="fw-semibold fm darkGrey mb-0 meeting__name">
                        {ent_item.firstName + " " + ent_item.lastName}
                      </p>
                      <small className="fm darkGrey mt-0 mb-0 text-start">
                        {ent_item.email}
                      </small>
                    </div>
                  </div>
                  <button
                    className={`meeting--button transition fm ${
                      selectedItems.includes(ent_item) ? "added" : ""
                    }`}
                    // ref={buttonRef}
                    // disabled={ isMember}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent any default browser behavior
                      handleAddItemClick(ent_item);
                    }}
                  >
                    <img
                      src={
                        selectedItems.includes(ent_item)
                          ? "/assets/images/Users/user-red.svg"
                          : img
                      }
                      alt=""
                      // /assets/images/Users/user-red.svg
                      className="meeting--button--img me-2 mb-1 transition"
                    />
                    {/* {selectedItems.includes(ent_item) ? 'Remove' : isMember ? 'Added' : 'Add'} */}
                    {selectedItems.includes(ent_item) ? "Remove" : "Add"}
                  </button>
                </div>
              );
            })}
          </div>
        </Menu.Item>
      </Menu>
    ) : (
      ""
    );

  const meetingMenu2 =
    props.page === "enterprise" ? (
      <Menu onClick={handleMenuClick2}>
        <Menu.Item key="1">
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={Meeting}
          >
            <p className="fm blue mb-0">
              Schedule / Request Interview with expert
            </p>{" "}
            <HiArrowRight className="ms-4 fs-6 blue mb-0" />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <div className="d-flex align-items-center">
            <GoSearch className="midGrey fs-5 me-1" />
            <input
              type="search"
              value={searchText}
              onChange={handleSearchChange}
              className="meeting__search"
              placeholder="Search members to add"
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3">
          <div>
            <div className="text-center mb-3">
              {/* <p className="fm darkGrey fw-semibold text-center mb-0">
              24 people in Unititled Co.
            </p> */}
              <small className="fm darkGrey text-12">
                Assign interviewers from the list below
              </small>
            </div>

            {filteredEnterprises.map((ent_item) => {
              // const isMember = props.data.interviewers.find(
              //   (member) => member.id === ent_item._id
              // );

              // console.log("isMember ka response" , isMember);

              return (
                <div
                  className="d-flex justify-content-between align-items-center mb-3"
                  key={ent_item.id}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/userAvatar.svg"
                      alt=""
                      className="meeting__user me-2 mb-1"
                    />

                    <div>
                      <p className="fw-semibold fm darkGrey mb-0 meeting__name">
                        {ent_item.firstName + " " + ent_item.lastName}
                      </p>
                      <small className="fm darkGrey mt-0 mb-0 text-start">
                        {ent_item.email}
                      </small>
                    </div>
                  </div>
                  <button
                    className={`meeting--button transition fm ${
                      selectedItems.includes(ent_item) ? "added" : ""
                    }`}
                    // ref={buttonRef}
                    // disabled={ isMember}
                    onClick={(e) => {
                      e.preventDefault(); // Prevent any default browser behavior
                      handleAddItemClick(ent_item);
                    }}
                  >
                    <img
                      src={
                        selectedItems.includes(ent_item)
                          ? "/assets/images/Users/user-red.svg"
                          : img
                      }
                      alt=""
                      // /assets/images/Users/user-red.svg
                      className="meeting--button--img me-2 mb-1 transition"
                    />
                    {/* {selectedItems.includes(ent_item) ? 'Remove' : isMember ? 'Added' : 'Add'} */}
                    {selectedItems.includes(ent_item) ? "Remove" : "Add"}
                  </button>
                </div>
              );
            })}
          </div>
        </Menu.Item>
      </Menu>
    ) : (
      ""
    );

  const editProfile = (
    <Menu>
      <Menu.Item key="1">
        <p className="fm darkGrey mb-0">Edit</p>
      </Menu.Item>
      <Menu.Item key="2">
        <p className="fm darkGrey mb-0">Delete</p>
      </Menu.Item>
    </Menu>
  );
  const editComment = (
    <Menu>
      <Menu.Item key="1">
        <p className="fm darkGrey mb-0">Edit</p>
      </Menu.Item>
      <Menu.Item key="2">
        <p className="fm darkGrey mb-0">Delete</p>
      </Menu.Item>
    </Menu>
  );
  const [drop, setDrop] = useState(false);

  // console.log("responseProfessionalData[0].certification", responseProfessionalData[0]?.certification);

  //comments apis

  const fetchComments = async () => {
    try {
      handleChange("loading", true);
      const commentsPayload = {
        jobID: id,
      };

      const commentsResponse = await getComments(commentsPayload);
      if (commentsResponse.status == 200) {
        comments = commentsResponse.data.data;

        handleChange("loading", false);
      }
    } catch (error) {
      console.error("Error while fetching comments:", error);
      handleChange("loading", false);
      // You can handle the error here, for example, showing a notification to the user
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const createNewComment = async () => {
    try {
      handleChange("newCommentLoading", true);

      const payloadComment = {
        jobID: id,
        content: newComment,
        author: {
          name: localStorage.getItem("userProfileName"),
          id: userData?.companyID,
        },
        authorData: userData?.companyID,
      };

      const newCommentResponse = await createComment(payloadComment);
      console.log("newCommentResponse", newCommentResponse);

      if (newCommentResponse.data.statusCode == 1) {
        enqueueSnackbar("Comment Posted!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        await fetchComments();
        setNewComment("");
        handleChange("newCommentLoading", false);
      }
    } catch (error) {
      console.error("Error while creating new comment:", error);
      handleChange("newCommentLoading", false);
      enqueueSnackbar("Failed to post comment. Please try again later.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const toggleReplyBox = (index) => {
    setShowReplyBoxIndex((prevIndex) => (prevIndex === index ? -1 : index));
    setCommentReply(""); // Clear the input box content when toggling
  };

  const createCommentReply = async (replyId) => {
    try {
      handleChange("newCommentLoading", true);

      const payloadComment = {
        commentId: replyId,
        content: commentReply,
        author: {
          name: localStorage.getItem("userProfileName"),
          id: userData?.companyID,
        },
        authorData: userData?.companyID,
      };

      const replyResponse = await createReply(payloadComment);

      if (replyResponse.data.statusCode == 1) {
        enqueueSnackbar("Comment Replied", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        await fetchComments();
        setShowReplyBoxIndex(-1);
        setCommentReply(null);

        handleChange("newCommentLoading", false);
      }
    } catch (error) {
      console.error("Error while creating new comment:", error);
      handleChange("newCommentLoading", false);
      enqueueSnackbar("Failed to post comment. Please try again later.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="bg-white ">
      {/* <style>

      </style>

      <style>
        {
          `.blurLayout {-webkit-filter: blur(5px);
            -moz-filter: blur(5px);
            filter: blur(5px);}                      
        }`
        }
      </style> */}
      <style>
        {`
        .meeting--button:hover {
          border-color:"#000  "
        }

        .send-contract-icon img {
          transform: rotate(-90deg);
      }
      
      .item-right button {
          float: right;
      }
      
      .invitation-button {
          width: 240px;
          margin-bottom: 20px;
          padding: 14px;
          line-height: 1;
          border-radius: 25px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border: 2px;
      }
      
      .invitation-button.accept {
          background: #B2DA66;
          border: 2px solid #B2DA66;
      }

      
      .certifications-box {
          text-align: center;
          margin-bottom: 20px;
      }
      
      .resume-icons a{
        color:#000;
        text-decoration:none;
        margin-left: 20px;
      }
      
      .resume-icons .download-link img {
        margin-right: 0px;
        }
        .resume-icons a img {
            margin-right: 5px;
            max-width:24px;
        }
        .authorized-icon {
          color: #a6dd50;
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 20px;
          z-index: 1;
      }
      .certifications-list img {
            max-width: 40px;
            margin-right: 16px;
        }
      .certifications-list a{
        text-decoration:none;
        color:#000
      }
      .certifications-box p {
          color: #000;
          margin-bottom: 0px;
      }
      
      .cert-popup-buttons {
          display: flex;
          justify-content: flex-end;
      }
      
      .cert-popup-buttons .primary {
          height: 40px;
      }
      
      .add-cert-form {
          margin-bottom: 200px;
      }
      
      .certification-popup .ant-modal-title {
          color: #000 !important;
          font-weight: 600;
          font-size: 22px;
      }
      
      .certification-popup .ant-modal-header {
          margin-bottom: 30px;
      }
      
      .certification-popup span.anticon.anticon-close.ant-modal-close-icon svg {
          font-size: 16px;
          color: #000;
      }

      .certification-add-dropdown.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
        background: #4a4a49 !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow {
        display: block !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow svg {
        color: #fff;
        font-size: 15px;
        margin-right: 2px;
    }
      
      .edit-name-popup label {
          width: 100%;
          font-size: 16px;
          margin-bottom: 20px;
      }
      
      .edit-name-popup input {
          width: 100%;
          margin-top: 5px;
      }
      
      .profile-image {
          position: relative;
      }
      
      .edit-image {
          position: absolute;
          top: -19px;
          left: -26px;
          width: 40px;
          height: 40px;
      }
      
      .photo-popup.add-cert-form {
          text-align: center;
          margin-bottom: 40px;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-modal-title {
          margin-bottom: 50px;
      }
      
      .upload-photo-box {
          width: 300px;
          height: 300px;
          border: 4px dashed #b8e472;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .video-box.upload-photo-box {
          border-radius: 10px;
          width: 100%;
      }
      
      .upload-video .ant-upload.ant-upload-select {
          width: 100%;
      }
      
      .upload-photo-box p {
          width: 50%;
          font-size: 20px;
          font-weight: 600;
      }
      
      .photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: none;
      }
      .photo-popup.add-cert-form .ant-upload.ant-upload-select{
        // display: none;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: inherit;
          margin: auto;
          width: 100%;
          justify-content: center;
          margin-bottom: 30px;
      }
      
      .video-preview {
          position: relative;
      }
      
      .delete-button {
          background: #ff0000;
          color: #fff !important;
          box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 10px;
          right: 10px;
          z-index:100;
      
      }
      
      .delete-button svg {
          color: #fff !important;
      }


      .browse-photo-box .ant-upload.ant-upload-select {
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
    }

    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text{
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
        display:flex;
        justify-content: center;
        align-items: center;
        margin:0 auto;
        border-radius:50%;
        overflow:hidden;
    }
    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text img{
      border-radius:50%;
    }

    .uploaded-image {
      width: 300px;
      height: 300px;
      max-width: 300px;
      overflow: hidden;
      display: flex;
  }
  .uploaded-image img {
    max-width: 100%;
    width:100%;
    object-fit: cover;
    object-position: center;
}
    
    .browse-photo-box .photo-upload-button span {
        opacity: 0;
        position: absolute;
    }
    
    .browse-photo-box .photo-upload-button div {
        font-size: 20px;
        max-width: 80%;
        margin: 0 auto;
        font-weight: 500;
    }

    .browse-photo-box .ant-upload.ant-upload-select img {
      border-radius: 50%;
    }


    .sharingDropdown h3 {
      font-size: 20px;
      font-weight: 600;
      color: #0d2159;
        }
        
        .sharingDropdown p {
            margin: 0px;
        }
        
        .sharingDropdown li.ant-dropdown-menu-item-divider {
            display: none !important;
        }
        
        .sharingDropdown svg {
            color: #000 !important;
        }
        
        .sharingDropdown button {
            padding: 5px 20px;
            background: #a6dd50;
            color: #fff;
            border-radius: 5px;
        }

        .profile__avatar--img{
          width: 100%;
          border-radius: 50%;
        }
        .user-image-resize .profile__avatar--img {
          height: 120px;
      }
        .edit-image{
          top: 0px !important;
          left: 0px !important;
        }




        `}
      </style>

      {props.page === "enterprise" && (
        <div className="bg-white mb-0 prof border-05 py-2">
          <div className="container-dash ">
            <div className="d-flex justify-content-between">
              <div
                className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center"
                onClick={Candidates}
              >
                <HiArrowLeft className="" />
              </div>

              <div className="d-flex flex-sm-row flex-column">
                <div className="d-flex justify-content-end mb-sm-0 mb-2">
                  {props.page === "enterprise" &&
                    props.data.status == "shortlisted" && (
                      <div
                        className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center"
                        onClick={Meeting}
                      >
                        <img
                          src="/assets/images/toolbar/icon_calendar_dark.svg"
                          alt=""
                          className=""
                        />
                      </div>
                    )}
                  {/* {userData.subscription &&
                  (userData.subscription.name === "Predict" ||
                    userData.subscription.name === "Scale") ? (
                    <div
                      className="fm darkGrey profile__cell pointer nowrap ms-1 d-flex align-items-center"
                      onClick={() => {
                        goToMessaging();
                      }}
                    >
                      <img
                        src="/assets/images/toolbar/icon_inbox_dark.svg"
                        alt=""
                        className=""
                      />
                    </div>
                  ) : (
                    <Tooltip
                      title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                      placement="top"
                    >
                      <div
                        className="fm darkGrey profile__cell pointer nowrap ms-1 d-flex align-items-center"                        
                      >
                        <img
                          src="/assets/images/toolbar/icon_inbox_dark.svg"
                          alt=""
                          className=""
                        />
                      </div>
                    </Tooltip>
                  )} */}
                  {/* <div
                    className="fm darkGrey profile__cell mx-1 pointer nowrap d-flex align-items-center"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BsThreeDotsVertical />
                  </div>
                  <ul class="dropdown-menu py-0 dropdown-menu-end">
                    <li className="py-1 job__dropdown--li px-2">option 1</li>
                    <li className="py-1 job__dropdown--li px-2">option 2</li>
                  </ul> */}
                </div>
                {props.page === "enterprise" &&
                  props.data.status == "shortlisted" && (
                    <div className="d-sm-flex profile__cell__last d-none pointer  ms-1">
                      {/* <Space.Compact className="w-100">
                    <Input value={"Assigned Team"} />
                    <Select
                      defaultValue="Vacheron Constantin"
                      className="w-100"
                    >
                      {nameopt.map((option) => (
                        <Select.Option
                          key={option.value}
                          value={option.value}
                          className="fm text-14"
                        >
                          <Avatar src={option.avatar} className="me-1 border" />
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Space.Compact> */}

                      <Dropdown
                        overlayClassName={"meetingDropdown"}
                        overlay={meetingMenu}
                        placement="bottomRight"
                        trigger={["click"]}
                        onOpenChange={handleOpenChange}
                        open={open}
                        meetingDropdown={{
                          backgroundColor: "#F8F8F8",
                        }}
                      >
                        <div
                          className="d-sm-flex d-none pointer w-100"
                          // data-bs-toggle="dropdown"
                          // aria-expanded="false"
                        >
                          <div className="fm darkGrey profile__cell__last--div1 px-2 text-13 d-flex align-items-center nowrap">
                            Assigned Team:
                          </div>
                          <div className="fm darkGrey w-100 px-2 text-13 d-flex align-items-center justify-content-between nowrap">
                            <div className="d-flex align-items-center">
                              {/* <Avatar className="profile__cell__last__avatar me-1" /> */}
                              <Avatar
                                src={"/assets/images/userAvatar2.svg"}
                                className="me-1 border profile__cell__last__avatar"
                              />
                              {filteredEnterprises.length > 0 && (
                                <>
                                  {filteredEnterprises[0].firstName}{" "}
                                  {filteredEnterprises[0].lastName}
                                </>
                              )}
                            </div>
                            <IoIosArrowDown className="mt-1 ms-1" />
                          </div>
                        </div>
                      </Dropdown>
                    </div>
                  )}
              </div>
            </div>
            {props.page === "enterprise" &&
              props.data.status == "shortlisted" && (
                <div className="d-sm-none d-flex profile__cell__last pointer interview__drop">
                  <Dropdown
                    overlayClassName={"meetingDropdown"}
                    overlay={meetingMenu2}
                    placement="bottomRight"
                    trigger={["click"]}
                    onOpenChange={handleOpenChange2}
                    open={openSecond}
                    meetingDropdown={{
                      backgroundColor: "#F8F8F8",
                    }}
                  >
                    <div className="d-flex  pointer w-100">
                      <div className="fm darkGrey profile__cell__last--div1 px-2 text-13 d-flex align-items-center nowrap">
                        Assigned Team:
                      </div>
                      <div className="fm darkGrey w-100 px-2 text-13 d-flex align-items-center justify-content-between nowrap">
                        <div className="d-flex align-items-center">
                          {/* <Avatar className="profile__cell__last__avatar me-1" /> */}
                          <Avatar
                            src={"/assets/images/userAvatar2.svg"}
                            className="me-1 border profile__cell__last__avatar"
                          />
                          {filteredEnterprises.length > 0 && (
                            <>
                              {filteredEnterprises[0].firstName}{" "}
                              {filteredEnterprises[0].lastName}
                            </>
                          )}
                        </div>
                        <IoIosArrowDown className="mt-1 ms-1" />
                      </div>
                    </div>
                  </Dropdown>
                  {/* <Space.Compact className="w-100">
                <Input value={"Assigned Team"} />
                <Select defaultValue="Vacheron Constantin" className="w-100">
                  {nameopt.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}
                      className="fm text-14"
                    >
                      <Avatar src={option.avatar} className="me-1 border" />
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Space.Compact> */}
                </div>
              )}
          </div>
        </div>
      )}

      <div className="Dashboard__container bg-white pt-4">
        <div className="container-dash">
          <div className="row pb-4">
            <div className="col-md-12 item-right">
              {props.page === "enterprise" &&
                props.data.status === "selected" &&
                props.data.managerID === localStorage.getItem("companyID") &&
                (userData.subscription.name === "Predict" ||
                  userData.subscription.name === "Scale") && (
                  <button
                    onClick={Contract}
                    className="invitation-button accept bg-main text-14 jobd__btn py-0"
                  >
                    Send Offer
                  </button>
                )}
            </div>
            <div
              className={`${
                props.page === "enterprise"
                  ? "col-lg-3 pb-lg-0 pb-4 pe-lg-0"
                  : "col-md-4 pb-md-0 pb-4 pe-md-0"
              } `}
            >
              <div className="profile__left bg-white radius8 pb-4 pt-3">
                {props.page === "professional" && (
                  <div className="d-flex justify-content-end px-3">
                    <button
                      onClick={EditNameModal}
                      className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                    >
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit profile
                    </button>

                    <Modal
                      className="certification-popup"
                      title="Edit your name"
                      visible={editNameVisible}
                      onOk={EditNamehandleOk}
                      onCancel={EditNamehandleCancel}
                      footer={null}
                      width={600}
                    >
                      <div className="add-cert-form edit-name-popup">
                        <div className="row">
                          <div className="col-md-12">
                            <label>
                              Your first name
                              <input
                                type="text"
                                className="form-control form-control-md w-100 Dashboard__input mb-2"
                                placeholder="Enter your first name"
                                id="sector1"
                                value={updateFirstName} // Bind the input value to the state
                                onChange={(e) =>
                                  setUpdateFirstName(e.target.value)
                                } // Handle input changes
                              />
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label>
                              Your last name
                              <input
                                type="text"
                                className="form-control form-control-md w-100 Dashboard__input mb-2"
                                placeholder="Enter your last name"
                                id="sector1"
                                value={updateLastName} // Bind the input value to the state
                                onChange={(e) =>
                                  setUpdateLastName(e.target.value)
                                } // Handle input changes
                              />
                            </label>
                          </div>
                          {error && (
                            <div className="col-md-12">
                              <div className="error-message">{error}</div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{ marginTop: "16px" }}
                        className="cert-popup-buttons"
                      >
                        <Button
                          className="primary"
                          onClick={EditNamehandleCancel}
                          style={{ marginRight: "8px" }}
                        >
                          Cancel
                        </Button>
                        <button
                          className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                          onClick={updateProNameHandler}
                        >
                          {updateNameLoader ? <Loader /> : "Update"}{" "}
                        </button>
                      </div>
                    </Modal>
                  </div>
                )}
                <div
                  className={`profile__left__top d-flex flex-column align-items-center px-3 pb-4 pt-2`}
                >
                  <div
                    className={`profile__avatar overflow-hidden ${
                      professionalData &&
                      professionalData[0]?.profileImage?.docs
                        ? "user-image-resize"
                        : ""
                    } candidates-profile-avatar-cont border d-flex justify-content-center align-items-center rounded-circle`}
                  >
                    <div class="profile-image candidates-profile-avatar d-flex align-items-center justify-content-center">
                      {props.page === "professional" && (
                        <>
                          <button
                            onClick={EditPhotoModal}
                            className="edit-image d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                          >
                            <CiEdit className="darkGrey me-1 fs-6" />
                          </button>
                          <div>
                            <Modal
                              className="certification-popup"
                              title="Change photo"
                              visible={photoVisible}
                              onOk={EditPhotohandleOk}
                              onCancel={EditPhotohandleCancel}
                              footer={null}
                              width={600}
                            >
                              <div className="add-cert-form edit-name-popup photo-popup">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="browse-photo-box">
                                      <Uploader
                                        multiple={false}
                                        listType="picture-text"
                                        fileListVisible={false}
                                        // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        action="//jsonplaceholder.typicode.com/posts/"
                                        onChange={(value) =>
                                          handleChange("files", value)
                                        }
                                        renderFileInfo={(file, fileElement) => {
                                          return (
                                            <div>
                                              <span>
                                                File Name: {file.name}
                                              </span>
                                              <p style={{ marginLeft: 13 }}>
                                                File type: {file.blobFile.type}
                                              </p>
                                            </div>
                                          );
                                        }}
                                        className="pt-4"
                                      >
                                        <div>
                                          {imageUrl && (
                                            <div className="uploaded-image">
                                              {imageUrl.blobFile.type.startsWith(
                                                "image/"
                                              ) && (
                                                <img
                                                  src={URL.createObjectURL(
                                                    imageUrl.blobFile
                                                  )}
                                                  alt="File Preview"
                                                />
                                              )}
                                            </div>
                                          )}
                                          {!imageUrl && (
                                            <img
                                              src="/assets/images/userAvatar.svg"
                                              className=""
                                            />
                                          )}
                                        </div>
                                      </Uploader>

                                      {/* <Upload
                                        name="avatar"
                                        listType="picture-circle"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                        beforeUpload={beforePhotoUpload}
                                        onChange={handlePhotoChange}
                                      >
                                        {imageUrl ? (
                                          <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                              width: '100%',
                                            }}
                                          />
                                        ) : (
                                          uploadButton
                                        )}
                                      </Upload> */}
                                    </div>
                                  </div>
                                  <div className="col-md-12 photo-popup-detail">
                                    <p className="mt-3">
                                      Photo Formats: jpeg,png
                                    </p>
                                    <h4>
                                      Show clients the best version of yourself
                                    </h4>
                                    <p>Must be an actual photo of you</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{ marginTop: "16px" }}
                                className="cert-popup-buttons"
                              >
                                <Button
                                  className="primary"
                                  onClick={EditPhotohandleCancel}
                                  style={{ marginRight: "8px" }}
                                >
                                  Cancel
                                </Button>
                                <button
                                  className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                  onClick={uploadPhoto}
                                >
                                  {imageLoading ? <Loader /> : "Upload Photo"}
                                </button>
                              </div>
                            </Modal>
                          </div>
                        </>
                      )}

                      <img
                        className="profile__avatar--img rounded-0 img-fluid"
                        src={`${
                          props.page === "professional"
                            ? responseProfessionalData[0].profileImage
                              ? responseProfessionalData[0].profileImage.docs
                              : "/assets/images/userAvatar2.svg"
                            : professionalData
                            ? professionalData[0]?.profileImage?.docs
                            : "/assets/images/userAvatar2.svg"
                        }
                          `}
                      />
                    </div>
                  </div>
                  <h1 className="darkBlue fs-5 fm fw-semibold mt-2 mb-0">
                    {props.page === "professional"
                      ? responseProfessionalData[0].firstName
                          .charAt(0)
                          .toUpperCase() +
                        responseProfessionalData[0].firstName.slice(1) +
                        " " +
                        responseProfessionalData[0].lastName
                      : props.data?.name.charAt(0).toUpperCase() +
                        props.data?.name.slice(1)}
                  </h1>
                  <span className="fm mt-2 text-14">Applied Date</span>
                  <span className="fm blue  text-14">
                    {moment(props.data.date).format("DD-MM-YYYY")}
                  </span>
                  <div className="d-flex mt-3 profile__icons ">
                    {props.page === "professional" && (
                      <div className="profile__icon d-flex justify-content-center align-items-center mx-2">
                        <Link
                          to="/candidate/dashboard/profile/sharing"
                          state={responseProfessionalData[0]}
                          className="d-flex mr-2"
                        >
                          <div className="profile__icon d-flex justify-content-center align-items-center mr-2">
                            <img
                              src="/assets/images/share.png"
                              alt=""
                              className="profile__icon--img1 img-fluid"
                            />
                          </div>
                        </Link>
                      </div>
                    )}
                    {/* <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <img
                        src="/assets/images/icon_fav.svg"
                        alt=""
                        className="profile__icon--img1 img-fluid"
                      />
                    </div> */}
                    {/* <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <img
                        src="/assets/images/icon_export.svg"
                        alt=""
                        className="img-fluid candidates__button--img"
                      />
                    </div> */}
                    {/* {props.page === "enterprise" &&
                      props.data.status == "shortlisted" && (
                        <Dropdown
                          overlayClassName={"meetingDropdown"}
                          overlay={meetingMenu}
                          placement="bottomLeft"
                          trigger={["click"]}
                          onOpenChange={handleOpenChange}
                          open={open}
                          meetingDropdown={{
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                            <img
                              src="/assets/images/calendar_dark.svg"
                              alt=""
                              className="profile__icon--img img-fluid"
                            />
                          </div>
                        </Dropdown>
                      )} */}
                  </div>
                </div>

                {/* circular progress */}
                {props.data?.score_request ? (
                  props.data?.kycVerification ? (
                    <div className="profile__left__circular w-100 position-relative pb-4">
                      <GradientSVG />
                      <CircularProgressbarWithChildren
                        strokeWidth={5}
                        value={indexcore}
                        className="progressBars mb-md-0 mb-4 px-2 position-relative"
                        maxValue={850}
                        styles={{
                          path: { stroke: `url(#${idCSS})`, height: "100%" },
                          trail: {
                            stroke: "#F8F8F8",
                          },
                        }}
                      ></CircularProgressbarWithChildren>
                      <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                        <h5 className="fm darkGrey mb-0 fs-6">IndexScore</h5>

                        <strong className="score-value fw-light mb-0 fm">
                          {indexcore}
                        </strong>
                      </div>
                    </div>
                  ) : (
                    <div className="profile__left__circular w-100 position-relative pb-4">
                      <GradientSVG />
                      <CircularProgressbarWithChildren
                        strokeWidth={5}
                        value={0}
                        className="progressBars mb-md-0 mb-4 px-2 position-relative"
                        maxValue={850}
                        styles={{
                          path: { stroke: `url(#${idCSS})`, height: "100%" },
                          trail: {
                            stroke: "#F8F8F8",
                          },
                        }}
                      ></CircularProgressbarWithChildren>
                      <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                        <h5 className="fm darkGrey mb-0 fs-6">IndexScore</h5>

                        <strong className="score-value fs-1 fw-light mb-0 fm">
                          Pending
                        </strong>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="profile__left__circular w-100 position-relative pb-4">
                    <GradientSVG />
                    <CircularProgressbarWithChildren
                      strokeWidth={5}
                      value={0}
                      className="progressBars mb-md-0 mb-4 px-2 position-relative"
                      maxValue={850}
                      styles={{
                        path: { stroke: `url(#${idCSS})`, height: "100%" },
                        trail: {
                          stroke: "#F8F8F8",
                        },
                      }}
                    ></CircularProgressbarWithChildren>
                    <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                      <h5 className="fm darkGrey mb-0 fs-6">IndexScore</h5>

                      <strong className="score-value fs-1 fw-light mb-0 fm">
                        Pending
                      </strong>
                    </div>
                  </div>
                )}

                {/* purpose alignment */}
                {userData.subscription.name === "Predict" ||
                userData.subscription.name === "Scale" ? (
                  props.data?.score_request && props.data?.kycVerification ? (
                    <div className="profile__left__purpose py-3 px-3">
                      <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                        <h6 className="fm darkGrey mb-0 fs-6">
                          Purpose Alignment
                        </h6>

                        <strong className="score-value fw-light mb-0 fm">
                          {purpose}%
                        </strong>
                      </div>
                      <Progress
                        percent={purpose}
                        showInfo={false}
                        strokeColor={{
                          "0%": "#A7DB5A",
                          "100%": "#A7DB5A",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="profile__left__purpose py-3 px-3">
                      <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                        <h6 className="fm darkGrey mb-0 fs-5">
                          Purpose Alignment
                        </h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="">Pending</p>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="profile__left__purpose py-3 px-3">
                    <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                      <h6 className="fm darkGrey mb-0 fs-6">
                        Purpose Alignment
                      </h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <Tooltip
                        title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                        placement="top"
                      >
                        <CiLock className="lock-item" />
                      </Tooltip>
                    </div>
                  </div>
                )}

                {/* contact */}
                <div className="profile__left__contact px-3 pt-3 pb-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="fs-5 fw-semibold fm darkBlue mb-3">
                      Contact
                    </h5>
                    {props.page === "professional" && (
                      <div className="d-flex align-items-center">
                        {/* <img
                              src="/assets/images/add_dark.svg"
                              alt=""
                              className="profile__add img-fluid me-sm-2 me-1"
                            /> */}
                        <button
                          onClick={EditContactModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                          {/* <img
                            src="/assets/images/add_dark.svg"
                            alt=""
                            className="profile__add img-fluid me-sm-2 me-1"
                          /> */}
                        </button>

                        <Modal
                          className="certification-popup"
                          title="Edit contact details"
                          visible={editContactVisible}
                          onOk={EditContacthandleOk}
                          onCancel={EditContacthandleCancel}
                          footer={null}
                          width={600}
                        >
                          <div className="add-cert-form edit-name-popup">
                            <div className="row">
                              <div className="col-md-12">
                                <label>
                                  Your phone number
                                  <input
                                    type="text"
                                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                                    placeholder="Enter your phone number"
                                    id="sector1"
                                    value={updatePhoneNumber}
                                    onChange={(e) =>
                                      setUpdatePhoneNumber(e.target.value)
                                    }
                                  />
                                </label>
                              </div>
                              <div className="col-md-12">
                                <label>
                                  Your address
                                  <input
                                    type="text"
                                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                                    placeholder="Enter your address"
                                    id="sector1"
                                    value={updateAddress}
                                    onChange={(e) =>
                                      setUpdateAddress(e.target.value)
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                          {contactError && (
                            <div className="error-message">{contactError}</div>
                          )}
                          <div
                            style={{ marginTop: "16px" }}
                            className="cert-popup-buttons"
                          >
                            <Button
                              className="primary"
                              onClick={EditContacthandleCancel}
                              style={{ marginRight: "8px" }}
                            >
                              Cancel
                            </Button>
                            <button
                              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              onClick={updateProContactHandler}
                            >
                              {updateContactLoader ? <Loader /> : "Update"}
                            </button>
                          </div>
                        </Modal>
                      </div>
                    )}
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <img
                        src="/assets/images/mail_dark.svg"
                        alt=""
                        className="profile__icon--img img-fluid"
                      />
                    </div>
                    <a
                      href={`mailto:${
                        props.page === "professional"
                          ? responseProfessionalData[0].emailAddress
                          : professionalData
                          ? professionalData[0].emailAddress
                          : ""
                      }`}
                      className="fm darkGrey text-decoration-none word-wrap text-14"
                    >
                      {props.page === "professional"
                        ? responseProfessionalData[0].emailAddress
                        : professionalData
                        ? professionalData[0].emailAddress
                        : ""}
                    </a>
                  </div>
                  <div className="d-flex align-items-center my-2">
                    <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <img
                        src="/assets/images/inbox_dark.svg"
                        alt=""
                        className="profile__icon--img img-fluid"
                      />
                    </div>
                    <a
                      className="fm darkGrey text-decoration-none text-14"
                      href={`tel:${
                        props.page === "professional"
                          ? responseProfessionalData[0].phoneNumber
                          : professionalData
                          ? professionalData[0].phoneNumber
                          : ""
                      }`}
                    >
                      {props.page === "professional"
                        ? responseProfessionalData[0].phoneNumber
                        : professionalData
                        ? professionalData[0].phoneNumber
                        : ""}
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <IoLocationOutline className="fs-4 darkGrey" />
                    </div>
                    <span className="fm darkGrey word-wrap text-14">
                      {props.page === "professional"
                        ? responseProfessionalData[0].address
                        : professionalData
                        ? professionalData[0].address
                        : "Address"}
                    </span>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                      <CiLinkedin className="fs-4 darkGrey" />
                    </div>
                    {professionalData && (
                      <a
                        href={professionalData[0].linkedinPublicUrl}
                        className="fm darkGrey text-decoration-none text-14"
                      >
                        LinkedIn Profile
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                props.page === "enterprise" ? "col-lg-6 mb-4" : "col-md-8"
              }`}
            >
              <div className="profile__right bg-white radius8 pb-2 pt-2">
                <Tabs>
                  <TabPane tab="Profile" key="tab 1">
                    <div className="px-3 pt-4">
                      <div className="d-flex justify-content-sm-between flex-sm-row flex-column border-05 pb-3">
                        <div className="d-inline-flex flex-sm-nowrap flex-wrap">
                          <div>
                            <p className="fm darkGrey mb-0">Identity</p>
                            <div className="reference__verification--main nowrap fw-semibold fm d-flex align-items-center gap-1 mb-3">
                              <span>Verified</span> <BsCheckCircle />
                            </div>
                          </div>
                          <div className="mx-sm-4 mx-3">
                            <p className="fm darkGrey fm mb-0">Right to Work</p>
                            <div className="reference__verification--main nowrap fw-semibold fm d-flex align-items-center gap-1 mb-3">
                              <span>Verified</span> <BsCheckCircle />
                            </div>
                          </div>
                          <div className="me-3">
                            <p className="fm darkGrey fm mb-0">Nationality</p>
                            <div className="reference__verification--main nowrap fw-semibold fm d-flex align-items-center gap-1 mb-3">
                              <span>
                                {professionalData && professionalData[0]
                                  ? professionalData[0].country
                                  : ""}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="">
                            <p className="fm darkGrey fm mb-0">Sponsorship</p>
                            <div className="reference__verification--main nowrap fw-semibold fm d-flex align-items-center gap-1 mb-3">
                              <span>Yes</span> <BsCheckCircle />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-05 py-3">
                        <div className="d-flex justify-content-between align-items-sm-center align-items-start">
                          <h4 className="fm darkBlue fw-semibold fs-5 mb-0 me-2">
                            Biography
                          </h4>
                        </div>
                        <p className="darkGrey fm mb-0 mt-3 prof__para">
                          {/* <ReadMore className="darkGrey fm"> */}
                          {professionalData && professionalData[0].summary}
                          {/* </ReadMore> */}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                          Experience
                        </h4>
                      </div>
                      {professionalData &&
                        professionalData[0].workExperience &&
                        professionalData[0].workExperience.map((item) => (
                          <div className="border-05" key={item.id}>
                            <div className="work__exp__box__item pt-3">
                              <div className="work__exp__box__item__content">
                                <div className="work__exp__box__item__image">
                                  <img
                                    src={`/assets/images/EnterpriseAvatar.svg`}
                                    alt=""
                                    className="profile-company-avatar border-0 rounded-0"
                                  />
                                </div>
                                <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                                  <div className="d-flex">
                                    <h5 className="fm me-4 nowrap fw-semibold mb-0 fs-6">
                                      {item.title}{" "}
                                      <span className="midGrey fw-normal">
                                        – {item.companyName}
                                      </span>
                                    </h5>
                                  </div>
                                  <p className="mt-1 fm darkGrey prof__para">
                                    {item.description}
                                  </p>
                                  <div className="filter__content__tag__content px-2 rounded-pill">
                                    <small className="filter__content__tag__content--small text-12  darkBlue fm text-capitalize">
                                      {item.startDate}
                                    </small>
                                    -
                                    <small className="filter__content__tag__content--small text-12  darkBlue fm text-capitalize">
                                      {item.endDate}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                          References
                        </h4>
                      </div>
                      {professionalData &&
                        professionalData[0].references.map((item) => (
                          <div className="border-05" key={item.id}>
                            <div className="work__exp__box__item pt-3 d-flex ">
                              <div className="work__exp__box__item__content">
                                <div className="work__exp__box__item__image">
                                  <img
                                    src={`/assets/images/EnterpriseAvatar.svg`}
                                    alt=""
                                    className="profile-company-avatar border-0 rounded-0"
                                  />
                                </div>
                                <div className="d-flex flex-sm-row flex-column justify-content-sm-between w-100">
                                  <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                                    <div className="">
                                      <h5 className="fm me-4 nowrap mb-0 fs-6 fw-semibold">
                                        {item.referencefirstName +
                                          " " +
                                          item.referencelastName}
                                      </h5>
                                      <span className="darkGrey prof__para">
                                        {/* {item.jobname} – */}
                                        {item.referenceEmail}
                                      </span>
                                    </div>
                                    {/* <div className="filter__content__tag__content filter__content__tag__content-verified rounded-pill px-2 mt-3">
                                    <small className="filter__content__tag__content--small text-12 filter__content__tag__content-verified fm text-capitalize">
                                      {item.tag}
                                    </small>
                                  </div> */}
                                  </div>
                                  {/* <div className="d-flex ms-sm-0 ms-3 mt-sm-0 mt-3">
                                  <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                                    <img
                                      src="/assets/images/mail_dark.svg"
                                      alt=""
                                      className="profile__icon--img img-fluid"
                                    />
                                  </div>
                                  <div className="profile__icon d-flex justify-content-center align-items-center">
                                    <img
                                      src="/assets/images/inbox_dark.svg"
                                      alt=""
                                      className="profile__icon--img img-fluid"
                                    />
                                  </div>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                          Education
                        </h4>
                      </div>
                      <div className="border-05">
                        <div className="work__exp__box__item pt-3 d-flex justify-content-between">
                          <div className="work__exp__box__item__content">
                            <div className="work__exp__box__item__image">
                              <img
                                src={`/assets/images/EnterpriseAvatar.svg`}
                                alt=""
                                className="profile-company-avatar border-0 rounded-0"
                              />
                            </div>
                            <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                              <div className="">
                                <h5 className="fm me-4 nowrap darkGrey mb-0 fs-6 fw-semibold">
                                  {professionalData &&
                                    professionalData[0].education}
                                </h5>
                                {/* <span className="darkGrey">
                                  Qualification • Location
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="border-05 py-3">
                        <div className="d-flex justify-content-between align-items-sm-center align-items-start">
                          {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              {/* <img
                                src="/assets/images/add_dark.svg"
                                alt=""
                                className="profile__add img-fluid me-sm-2 me-1"
                              /> */}
                              <button
                                onClick={EditResumeModal}
                                className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                              >
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Upload resume
                              </button>
                              <div>
                                <Modal
                                  className="certification-popup"
                                  title="Update Resume"
                                  visible={resumeVisible}
                                  onOk={EditResumehandleOk}
                                  onCancel={EditResumehandleCancel}
                                  footer={null}
                                  width={600}
                                >
                                  <div className="add-cert-form edit-name-popup photo-popup resume-popup">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Uploader
                                          listType="picture-text"
                                          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                          action="//jsonplaceholder.typicode.com/posts/"
                                          onChange={
                                            (value) =>
                                              // improveResumeHandler(value)
                                              setResumeFile(value)
                                            // handleChange("resumeFile", )
                                          }
                                          renderFileInfo={(
                                            file,
                                            fileElement
                                          ) => {
                                            return (
                                              <div>
                                                <span>
                                                  File Name: {file.name}
                                                </span>
                                                <p style={{ marginLeft: 13 }}>
                                                  File type:{" "}
                                                  {file.blobFile.type}
                                                </p>
                                              </div>
                                            );
                                          }}
                                          className="pt-4"
                                        >
                                          <div>
                                            <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                                              Upload your resume
                                            </Button>
                                          </div>
                                        </Uploader>
                                      </div>
                                      <div className="col-md-12 photo-popup-detail">
                                        {/* <p className="mt-3">Photo Formats: jpeg,png</p>   */}
                                        <h4>
                                          Show clients the best resume of
                                          yourself
                                        </h4>
                                        <p>Must be in PDF format</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{ marginTop: "16px" }}
                                    className="cert-popup-buttons"
                                  >
                                    <Button
                                      className="primary"
                                      onClick={EditResumehandleCancel}
                                      style={{ marginRight: "8px" }}
                                    >
                                      Cancel
                                    </Button>
                                    <button
                                      className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                      onClick={handleResumeUpload}
                                    >
                                      {resumeFileLoader ? (
                                        <Loader />
                                      ) : (
                                        "Upload Resume"
                                      )}
                                    </button>
                                  </div>
                                </Modal>
                              </div>
                            </div>
                          )}
                        </div>
                        <h4 className="fm darkBlue fw-semibold fs-5 mb-3 me-2">
                          <div className="fs-5 fm darkBlue">
                            {props.page === "professional"
                              ? "Update your resume"
                              : "Professional resume"}
                          </div>

                          {/* {" "}
                            <span className="d-sm-inline d-none">
                              Letter/Intro/Bio
                            </span> */}
                        </h4>
                        {props.page === "professional" &&
                          responseProfessionalData[0]?.resumes && (
                            <div className="col-md-12 pt-2 pb-2 certifications-list">
                              <div className="d-flex flex-sm-nowrap flex-wrap align-items-center justify-content-between">
                                <h6 className="darkBlue text-14">
                                  {" "}
                                  <img src="/assets/images/resume-icon.png" />{" "}
                                  {
                                    responseProfessionalData[0]?.resumes
                                      ?.actualresumefileName
                                  }{" "}
                                </h6>
                                <div className="d-flex mt-sm-0 mt-3 fm resume-icons">
                                  <Link
                                    className="me-2 download-link d-flex align-items-center ms-0"
                                    target="_blank"
                                    to={`${responseProfessionalData[0]?.resumes.resume}`}
                                  >
                                    {" "}
                                    <IoEyeOutline className="fs-5 me-1" /> View
                                  </Link>
                                  <Link
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                      handleDownload(
                                        responseProfessionalData[0]?.resumes.Key
                                      )
                                    }
                                  >
                                    {" "}
                                    <img
                                      src="/assets/images/icon_export.svg"
                                      alt=""
                                      className="img-fluid candidates__button--img me-1"
                                    />{" "}
                                    Download
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}

                        {props.page === "enterprise" &&
                          professionalData &&
                          professionalData[0]?.resumes && (
                            <div className="col-md-12 pt-2 pb-2 certifications-list">
                              <div className="d-sm-flex align-items-center justify-content-between">
                                <h6 className="darkBlue">
                                  {" "}
                                  <img src="/assets/images/resume-icon.png" />{" "}
                                  {
                                    professionalData[0]?.resumes
                                      ?.actualresumefileName
                                  }{" "}
                                </h6>
                                <div className="d-flex mt-sm-0 mt-3 fm resume-icons">
                                  <Link
                                    className="me-2 download-link  d-flex text-14 transition  align-items-center ms-0"
                                    target="_blank"
                                    to={`${professionalData[0]?.resumes.resume}`}
                                  >
                                    {" "}
                                    <IoEyeOutline className="fs-5 me-1" /> View
                                  </Link>
                                  <Link
                                    className="text-14 d-flex darkGrey  transition align-items-center"
                                    onClick={() =>
                                      handleDownload(
                                        professionalData[0]?.resumes.Key
                                      )
                                    }
                                  >
                                    {" "}
                                    <img
                                      src="/assets/images/icon_export.svg"
                                      alt=""
                                      className="img-fluid candidates__button--img me-1"
                                    />{" "}
                                    Download
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                          Certifications
                        </h4>
                        {props.page === "professional" && (
                          <div className="d-flex align-items-center">
                            {/* <img
                              src="/assets/images/add_dark.svg"
                              alt=""
                              className="profile__add img-fluid me-sm-2 me-1"
                            /> */}
                            <button
                              onClick={showModal}
                              className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                            >
                              {/* <CiEdit className="darkGrey me-1 fs-6" />
                              Edit */}
                              <img
                                src="/assets/images/add_dark.svg"
                                alt=""
                                className="profile__add img-fluid me-sm-2 me-1"
                              />
                            </button>

                            <Modal
                              className="certification-popup"
                              title="Add certification"
                              visible={visible}
                              onOk={handleOk}
                              onCancel={handleCancel}
                              footer={null}
                              width={700}
                            >
                              <div className="add-cert-form">
                                <Select
                                  className="Login--Input mb-2 mx-0 d-block w-100 certification-add-dropdown"
                                  size="small"
                                  placeholder="Select your certification"
                                  onChange={handleCertificationChange}
                                  value={selectedCertification}
                                >
                                  <Option value="Google IT Support Professional Certificate">
                                    Google IT Support Professional Certificate
                                  </Option>
                                  <Option value="Coursera Deep Learning Specialization">
                                    Coursera Deep Learning Specialization
                                  </Option>
                                  <Option value="AWS Certified Solutions Architect">
                                    AWS Certified Solutions Architect
                                  </Option>
                                  <Option value="Microsoft Certified: Azure Administrator Associate">
                                    Microsoft Certified: Azure Administrator
                                    Associate
                                  </Option>
                                  <Option value="Certified ScrumMaster (CSM)">
                                    Certified ScrumMaster (CSM)
                                  </Option>
                                  <Option value="Cisco Certified Network Associate (CCNA)">
                                    Cisco Certified Network Associate (CCNA)
                                  </Option>
                                  <Option value="CompTIA Security+">
                                    CompTIA Security+
                                  </Option>
                                  <Option value="Certified Information Systems Security Professional (CISSP)">
                                    Certified Information Systems Security
                                    Professional (CISSP)
                                  </Option>
                                  <Option value="Certified Ethical Hacker (CEH)">
                                    Certified Ethical Hacker (CEH)
                                  </Option>
                                  <Option value="Certified Data Scientist">
                                    Certified Data Scientist
                                  </Option>
                                  <Option value="Certified Kubernetes Administrator (CKA)">
                                    Certified Kubernetes Administrator (CKA)
                                  </Option>
                                  <Option value="Certified in Risk and Information Systems Control (CRISC)">
                                    Certified in Risk and Information Systems
                                    Control (CRISC)
                                  </Option>
                                  <Option value="Project Management Professional (PMP)">
                                    Project Management Professional (PMP)
                                  </Option>
                                  <Option value="Certified Information Systems Auditor (CISA)">
                                    Certified Information Systems Auditor (CISA)
                                  </Option>
                                  <Option value="Certified in the Governance of Enterprise IT (CGEIT)">
                                    Certified in the Governance of Enterprise IT
                                    (CGEIT)
                                  </Option>
                                  <Option value="Machine Learning Engineer Nanodegree">
                                    Machine Learning Engineer Nanodegree
                                  </Option>
                                  <Option value="Digital Marketing Specialist">
                                    Digital Marketing Specialist
                                  </Option>
                                  <Option value="Certified Business Analysis Professional (CBAP)">
                                    Certified Business Analysis Professional
                                    (CBAP)
                                  </Option>
                                  <Option value="Certified Six Sigma Green Belt">
                                    Certified Six Sigma Green Belt
                                  </Option>
                                  <Option value="Certified Artificial Intelligence Practitioner">
                                    Certified Artificial Intelligence
                                    Practitioner
                                  </Option>
                                  <Option value="Certified Blockchain Developer">
                                    Certified Blockchain Developer
                                  </Option>
                                  <Option value="Certified Information Security Manager (CISM)">
                                    Certified Information Security Manager
                                    (CISM)
                                  </Option>
                                  <Option value="Certified DevOps Engineer">
                                    Certified DevOps Engineer
                                  </Option>
                                  <Option value="Certified Information Privacy Professional (CIPP)">
                                    Certified Information Privacy Professional
                                    (CIPP)
                                  </Option>
                                  <Option value="Certified Cloud Security Professional (CCSP)">
                                    Certified Cloud Security Professional (CCSP)
                                  </Option>
                                  <Option value="Certified Business Intelligence Professional (CBIP)">
                                    Certified Business Intelligence Professional
                                    (CBIP)
                                  </Option>
                                  <Option value="Certified Professional in Healthcare Information and Management Systems (CPHIMS)">
                                    Certified Professional in Healthcare
                                    Information and Management Systems (CPHIMS)
                                  </Option>
                                  <Option value="Certified Advanced Social Media Strategy (CASMS)">
                                    Certified Advanced Social Media Strategy
                                    (CASMS)
                                  </Option>
                                  <Option value="Certified Scrum Product Owner (CSPO)">
                                    Certified Scrum Product Owner (CSPO)
                                  </Option>
                                  <Option value="Certified Professional in Learning and Performance (CPLP)">
                                    Certified Professional in Learning and
                                    Performance (CPLP)
                                  </Option>
                                </Select>
                              </div>
                              <div
                                style={{ marginTop: "16px" }}
                                className="cert-popup-buttons"
                              >
                                {/* Custom buttons */}
                                <Button
                                  className="primary"
                                  onClick={handleCancel}
                                  style={{ marginRight: "8px" }}
                                >
                                  Cancel
                                </Button>
                                <button
                                  className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                  onClick={handleAddCertification}
                                >
                                  {addCertificateLoader ? (
                                    <Loader />
                                  ) : (
                                    "Add certification"
                                  )}
                                </button>
                              </div>
                            </Modal>
                          </div>
                        )}
                      </div>
                      {props.page === "enterprise" && professionalData && (
                        <div className="row justify-content-center mt-3">
                          {professionalData[0]?.certifications &&
                          professionalData[0]?.certifications.length > 0 ? (
                            professionalData[0]?.certifications.map(
                              (item, index) => (
                                <div
                                  className="col-md-12 pt-2 pb-2 certifications-list"
                                  key={index}
                                >
                                  <h6 className="darkBlue text-14 fm">
                                    {" "}
                                    <img src="/assets/images/certifications-icon.png" />{" "}
                                    {item.name}
                                  </h6>
                                </div>
                              )
                            )
                          ) : (
                            <div className="row justify-content-center">
                              <div className="col-md-12">
                                <div className="certifications-box">
                                  <img src="/assets/images/certifications.png" />
                                  <p className="fm darkGrey text-14">
                                    Listing your certifications can help prove
                                    your indexscore (%10)
                                  </p>
                                  <p className="fm darkGrey text-14">
                                    You can add them manually
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {props.page === "professional" && (
                        <div className="row justify-content-center mt-3">
                          {responseProfessionalData[0]?.certification &&
                          responseProfessionalData[0]?.certification.length >
                            0 ? (
                            responseProfessionalData[0]?.certification.map(
                              (item, index) => (
                                <div
                                  className="col-md-12 pt-2 pb-2 certifications-list"
                                  key={index}
                                >
                                  <h6 className="darkBlue">
                                    {" "}
                                    <img src="/assets/images/certifications-icon.png" />{" "}
                                    {item.name}
                                  </h6>
                                </div>
                              )
                            )
                          ) : (
                            <div className="row justify-content-center">
                              <div className="col-md-12">
                                <div className="certifications-box">
                                  <img src="/assets/images/certifications.png" />
                                  <p>
                                    Listing your certifications can help prove
                                    your indexscore (%10)
                                  </p>
                                  <p>You can add them manually</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {
                        // experience.map((item) => (
                        // <div className="border-05" key={item.id}>
                        //   <div className="work__exp__box__item pt-3">
                        //     <div className="work__exp__box__item__content">
                        //       <div className="work__exp__box__item__image">
                        //         <img
                        //           src={`/assets/images/EnterpriseAvatar.svg`}
                        //           alt=""
                        //           className="profile-company-avatar border-0 rounded-0"
                        //         />
                        //       </div>
                        //       <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                        //         <div className="d-flex">
                        //           <h5 className="fm me-4 nowrap mb-0">
                        //             {item.jobname}
                        //             <span className="midGrey">
                        //               – {item.company}
                        //             </span>
                        //           </h5>
                        //         </div>
                        //         <p className="mt-1 fm darkGrey">{item.desc}</p>
                        //         <div className="filter__content__tag__content px-3 ">
                        //           <small className="filter__content__tag__content--small darkBlue fm text-capitalize">
                        //             {item.tag}
                        //           </small>
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                        // ))
                      }
                      <div className="d-flex justify-content-between align-items-center pt-3">
                        {/* <h4 className="fm darkBlue fw-semibold fs-4 mb-0">
                          References
                        </h4> */}
                        {props.page === "professional" && (
                          <div className="d-flex align-items-center">
                            {/* <img
                              src="/assets/images/add_dark.svg"
                              alt=""
                              className="profile__add img-fluid me-sm-2 me-1"
                            /> */}
                            {/* <button className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Edit
                            </button> */}
                          </div>
                        )}
                      </div>
                      {props.page === "professional" && (
                        <div className="d-flex justify-content-between align-items-center pt-3">
                          {/* <h4 className="fm darkBlue fw-semibold fs-4 mb-0">
                          Education
                        </h4> */}

                          <div className="d-flex align-items-center">
                            {/* <img
                              src="/assets/images/add_dark.svg"
                              alt=""
                              className="profile__add img-fluid me-sm-2 me-1"
                            /> */}
                            {/* <button className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                              <CiEdit className="darkGrey me-1 fs-6" />
                              Edit
                            </button> */}
                          </div>
                        </div>
                      )}
                      {/* <div className="">
                        <div className="work__exp__box__item pt-3 d-flex justify-content-between">
                          <div className="work__exp__box__item__content">
                            <div className="work__exp__box__item__image">
                              <img
                                src={`/assets/images/EnterpriseAvatar.svg`}
                                alt=""
                                className="profile-company-avatar border-0 rounded-0"
                              />
                            </div>
                            <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                              <div className="">
                                <h5 className="fm me-4 nowrap darkGrey mb-0">
                                  School/Collage Name
                                </h5>
                                <span className="darkGrey">
                                  Qualification • Location
                                </span>
                              </div>
                              <p className="midGrey fm mb-0">
                                <ReadMore>
                                  Description of job/position here of
                                  job/position here Description of job/position.
                                  of job/position here of job/position here
                                  Description of job/position. Description of
                                  job/position here of job/position here
                                  Description of job/position.
                                </ReadMore>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </TabPane>
                  <TabPane tab="Video" key="tab 2">
                    <div className="px-3">
                      <div className="work__exp__box border-05 py-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                            Video
                          </h4>
                          {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              {/* <img
                                src="/assets/images/add_dark.svg"
                                alt=""
                                className="profile__add img-fluid me-sm-2 me-1"
                              /> */}
                              <button
                                onClick={EditVideoModal}
                                className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                              >
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Edit
                              </button>

                              <div>
                                <Modal
                                  className="certification-popup"
                                  title="Change Video"
                                  visible={videoVisible}
                                  onOk={EditVideohandleOk}
                                  onCancel={EditVideohandleCancel}
                                  footer={null}
                                  width={600}
                                >
                                  <div className="add-cert-form edit-name-popup photo-popup">
                                    <div className="row">
                                      <div className="col-md-12">
                                        {videoFile && (
                                          <div className="video-preview">
                                            <button
                                              onClick={DeleteSelectedVideo}
                                              className="delete-button profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                                            >
                                              <AiOutlineDelete className="darkGrey me-1 fs-6" />
                                              Delete
                                            </button>
                                            <video width="100%">
                                              <source
                                                src={
                                                  videoFile &&
                                                  URL.createObjectURL(
                                                    videoFile[0].blobFile
                                                  )
                                                }
                                                type={videoFile.type}
                                              />
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          </div>
                                        )}
                                        {!videoFile && (
                                          <Uploader
                                            multiple={false}
                                            fileListVisible={false}
                                            listType="picture-text"
                                            accept="video/mp4,video/mov,video/wmv"
                                            action="//jsonplaceholder.typicode.com/posts/"
                                            onChange={
                                              (value) => {
                                                console.log(
                                                  "value of video",
                                                  value
                                                );
                                                setVideoFile(value);
                                              }
                                              // handleChange("files", value)
                                            }
                                            renderFileInfo={(
                                              file,
                                              fileElement
                                            ) => {
                                              return (
                                                <div>
                                                  <span>
                                                    File Name: {file.name}
                                                  </span>
                                                  <p style={{ marginLeft: 13 }}>
                                                    File type:{" "}
                                                    {file.blobFile.type}
                                                  </p>
                                                </div>
                                              );
                                            }}
                                            className="pt-4"
                                          >
                                            <div>
                                              {!videoFile && (
                                                <div className="upload-photo-box video-box">
                                                  <p>
                                                    Attach or drag Video here
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </Uploader>
                                        )}
                                      </div>
                                      <div className="col-md-12 photo-popup-detail">
                                        <p className="mt-3">
                                          Video Formats: mp4
                                        </p>
                                        <h4>
                                          Show clients the best version of
                                          yourself
                                        </h4>
                                        <p>Must be an actual video of you</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{ marginTop: "16px" }}
                                    className="cert-popup-buttons"
                                  >
                                    <Button
                                      className="primary"
                                      onClick={EditVideohandleCancel}
                                      style={{ marginRight: "8px" }}
                                    >
                                      Cancel
                                    </Button>
                                    <button
                                      className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                                      onClick={handleVideoUpload}
                                    >
                                      {videoUrlLoading ? (
                                        <Loader />
                                      ) : (
                                        "Upload Video"
                                      )}
                                    </button>
                                  </div>
                                </Modal>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="yourProfile__videoUpload mb-0 rounded-4 mt-3">
                          <div className="yourProfile__videoUpload__frame h-100">
                            {props.page === "professional" && (
                              <iframe
                                title="1"
                                frameborder="0"
                                className="w-100 h-100 rounded-4"
                                src={
                                  responseProfessionalData[0].elevatorPitch.file
                                }
                              ></iframe>
                            )}
                            {props.page === "enterprise" && (
                              <iframe
                                frameborder="0"
                                className="w-100 h-100 rounded-4"
                                src={
                                  professionalData &&
                                  professionalData[0] &&
                                  professionalData[0].elevatorPitch.file
                                }
                                title="2"
                              ></iframe>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="work__exp__box open__questions__box mb-0 pt-4 pb-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="fm darkBlue fw-semibold fs-4 mb-0">
                            Questions
                          </h4>
                          {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              {isEditing ? (
                                <button
                                  className="profile__update d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                                  onClick={handleUpdateClick}
                                >
                                  {questionsLoading ? <Loader /> : 'Update'}
                                </button>
                              ) : (
                                <button
                                  className="profile__edit d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                                  onClick={handleEditClick}
                                >
                                  Edit
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                        {props.page === "professional" && (
                          <div className="yourProfile__questions__list pt-3">

                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">1.</div> What do you like
                              doing outside of work and why?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={!isEditing}
                                value={textAreaValues.question1}
                                onChange={(e) => handleTextAreaChange(e, 'question1')}
                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">2.</div> What would a
                              perfect role look like for you and who else would
                              be on the team?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={!isEditing}
                                value={textAreaValues.question2}
                                onChange={(e) => handleTextAreaChange(e, 'question2')}
                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">3.</div> Which company would
                              you most like to work for and how does their
                              culture align with your values?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={!isEditing}
                                value={textAreaValues.question3}
                                onChange={(e) => handleTextAreaChange(e, 'question3')}
                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">4.</div> In 10 years, how
                              will you have fulfilled your purpose?
                            </p>
                            <div className="form-group mb-3">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-md-0 mb-4"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={!isEditing}
                                value={textAreaValues.question4}
                                onChange={(e) => handleTextAreaChange(e, 'question4')}
                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">5.</div> In five years, how
                              will you have fulfilled your purpose?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-md-0 mb-4"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={!isEditing}
                                value={textAreaValues.question5}
                                onChange={(e) => handleTextAreaChange(e, 'question5')}
                              />
                            </div>                            
                          </div>
                        )}
                        {props.page === "enterprise" && (
                          <div className="yourProfile__questions__list pt-3">
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">1.</div> What do you like
                              doing outside of work and why?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={true}
                                value={textAreaValuesEnterprise.question1}

                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">2.</div> What would a
                              perfect role look like for you and who else would
                              be on the team?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={true}
                                value={textAreaValuesEnterprise.question2}

                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">3.</div> Which company would
                              you most like to work for and how does their
                              culture align with your values?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={true}
                                value={textAreaValuesEnterprise.question3}

                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">4.</div> In 10 years, how
                              will you have fulfilled your purpose?
                            </p>
                            <div className="form-group mb-3">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-md-0 mb-4"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={true}
                                value={textAreaValuesEnterprise.question4}

                              />
                            </div>
                            <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                              <div className="me-2">5.</div> In five years, how
                              will you have fulfilled your purpose?
                            </p>
                            <div className="form-group">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-md-0 mb-4"
                                id="exampleInputEmail1"
                                aria-describedby="question1"
                                placeholder="Your message..."
                                rows={3}
                                readOnly={true}
                                value={textAreaValuesEnterprise.question5}

                              />
                            </div>
                          </div>
                        )}
                      </div> */}
                    </div>
                  </TabPane>
                  <TabPane tab="Online Presence" key="tab 3">
                    <div className="px-3 py-4">
                      <div className="border-05 pb-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                            Social channels
                          </h4>
                          {/* {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              <button className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Edit
                              </button>
                            </div>
                          )} */}
                        </div>

                        {props.page === "professional" && (
                          <div className="d-flex flex-wrap mt-3">
                            {responseProfessionalData[0] &&
                            responseProfessionalData[0].facebook_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social1.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Facebook
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social1.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Facebook
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {responseProfessionalData[0] &&
                            responseProfessionalData[0].instagram_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social3.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Instagram
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social3.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Instagram
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {responseProfessionalData[0] &&
                            responseProfessionalData[0].linkedIn_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social4.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      LinkedIn
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social4.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      LinkedIn
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {responseProfessionalData[0] &&
                            responseProfessionalData[0].twitter_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social6.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Twitter
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social6.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Twitter
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/persona.png`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    ID Verification
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/criteria-icon.jpg`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    Criteria Test
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/accurate-icon.png`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    Background Verification
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        {props.page === "enterprise" && (
                          <div className="d-flex flex-wrap mt-3">
                            {professionalData &&
                            professionalData[0] &&
                            professionalData[0].facebook_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social1.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Facebook
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social1.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Facebook
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {professionalData &&
                            professionalData[0] &&
                            professionalData[0].instagram_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social3.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Instagram
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social3.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Instagram
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {professionalData &&
                            professionalData[0] &&
                            professionalData[0].linkedIn_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social4.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      LinkedIn
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social4.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      LinkedIn
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}
                            {professionalData &&
                            professionalData[0] &&
                            professionalData[0].twitter_authorised ? (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <BsFillCheckCircleFill className="authorized-icon" />
                                <img
                                  src={`/assets/images/profile/social6.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Twitter
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            ) : (
                              <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                                <img
                                  src={`/assets/images/profile/social6.svg`}
                                  alt=""
                                  className="profile__social--img"
                                />
                                <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                  <div className="text-center mt-3">
                                    <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                      Twitter
                                    </h6>
                                    <p className="fm darkGrey text-14">
                                      {/* @{'username'} */}
                                    </p>
                                  </div>
                                  <Link
                                    
                                    className="blue text-decoration-none mb-2 text-14"
                                  >
                                    View <VscArrowRight />
                                  </Link>
                                </div>
                              </div>
                            )}

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/persona.png`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    ID Verification
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/criteria-icon.jpg`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    Criteria Test
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>

                            <div className="profile__social d-flex justify-content-center align-items-center me-2 mb-2">
                              <img
                                src={`/assets/images/accurate-icon.png`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    Background Verification
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    {/* @{'username'} */}
                                  </p>
                                </div>
                                <Link
                                  
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* {social.map((item) => (
                            <div
                              className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                              key={item.id}
                            >
                              <img
                                src={`/assets/images/profile/social${item.id}.svg`}
                                alt=""
                                className="profile__social--img"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    {item.name}
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    @{item.username}
                                  </p>
                                </div>
                                <Link
                                  to={item.profileLink}
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>
                          ))} */}
                      </div>
                      <div className="border-05 pb-3 pt-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                            Awards
                          </h4>
                          {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              {/* <img
                                src="/assets/images/add_dark.svg"
                                alt=""
                                className="profile__add img-fluid me-sm-2 me-1"
                              /> */}
                              <button className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Edit
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-wrap  mt-3">
                          {awards.map((item) => (
                            <div
                              className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                              key={item.id}
                            >
                              <img
                                src="/assets/images/profile/Awards.svg"
                                alt=""
                                className="profile__social--img2"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    {item.name}
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    <span className="fw-semibold">Year :</span>{" "}
                                    {item.year}
                                  </p>
                                </div>
                                <Link
                                  to={item.certificate}
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="pt-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                            Publications
                          </h4>
                          {props.page === "professional" && (
                            <div className="d-flex align-items-center">
                              {/* <img
                                src="/assets/images/add_dark.svg"
                                alt=""
                                className="profile__add img-fluid me-sm-2 me-1"
                              /> */}
                              <button className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                                <CiEdit className="darkGrey me-1 fs-6" />
                                Edit
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex flex-wrap  mt-3">
                          {publications.map((item) => (
                            <div
                              className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                              key={item.id}
                            >
                              <img
                                src="/assets/images/profile/Publications.svg"
                                alt=""
                                className="profile__social--img2"
                              />
                              <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                <div className="text-center mt-3">
                                  <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                    {item.name}
                                  </h6>
                                  <p className="fm darkGrey text-14">
                                    <span className="fw-semibold">Year :</span>{" "}
                                    {item.year}
                                  </p>
                                </div>
                                <Link
                                  to={item.certificate}
                                  className="blue text-decoration-none mb-2 text-14"
                                >
                                  View <VscArrowRight />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  {/* {props.page === "enterprise" && (
                    <TabPane tab="Comments" key="tab 4">
                      <div className="px-3 py-4">
                        <div className="d-flex justify-content-end">
                          <button className="fm text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center">
                            <span className="fs-4 me-1">+</span> New Entry
                          </button>
                        </div>

                        {comments.map((item) => (
                          <div className="profile__comment mt-3">
                            <div className="profile__comment__top border-05 p-2 d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <Avatar
                                  icon={<UserOutlined />}
                                  size="large"
                                  className="me-2"
                                />
                                <div>
                                  <h6 className="text-14 fm darkGrey fw-semibold mb-0">
                                    {item.name}
                                  </h6>
                                  <p className="fm text-12 darkGrey mb-0">
                                    {item.date}
                                  </p>
                                </div>
                              </div>
                              <Dropdown
                                overlay={editProfile}
                                placement="bottomRight"
                              >
                                <SlOptionsVertical className="fs-5 pointer" />
                              </Dropdown>
                            </div>
                            <div className="profile__comment__mid p-2">
                              <p className="darkGrey fm">{item.message}</p>
                            </div>
                            <div className="d-flex p-2 align-items-end">
                              <AiFillLike className="fs-5 me-1 pointer" />
                              <BsFillEmojiSmileFill className="fs-6 pointer" />
                            </div>
                            <div className="px-2 pb-2 d-flex align-items-center">
                              <div
                                className="lightblue__btn d-inline-flex px-2 py-1 pointer align-items-center me-2"
                                onClick={() => setShowReplies(!showReplies)}
                              >
                                <Avatar.Group className="me-2">
                                  {item.replies.map((item) => (
                                    <Avatar
                                      style={{
                                        backgroundColor: "#1890ff",
                                      }}
                                      key={item.id}
                                      size="small"
                                      icon={<UserOutlined />}
                                    />
                                  ))}
                                </Avatar.Group>
                                <span className="fm blue fw-semibold">
                                  {item.replies.length} replies
                                </span>
                              </div>
                              <span
                                className="fm darkGrey fw-semibold fs-6 pointer"
                                onClick={() => setTypeReply(!typeReply)}
                              >
                                {typeReply ? "Done" : "Reply"}
                              </span>
                            </div>
                            {showReplies && (
                              <div className="profile__comment__reply p-2 ">
                                {item.replies.map((item) => (
                                  <p className="border-05 fm border p-2 blue bg-pastel mb-1">
                                    {item.message}
                                  </p>
                                ))}
                              </div>
                            )}
                            {typeReply && (
                              <div className="p-2">
                                <input
                                  type="text"
                                  className="profile__comment--input form-control mb-2"
                                  placeholder="Type a message"
                                />
                                <button className="fm bg-pastel fw-semibold blue px-3 py-2">
                                  Send
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </TabPane>
                  )} */}
                  {/* {props.page === "enterprise" && (
                    <TabPane tab="Candidate Process" key="tab 5">
                      <div className="px-3 py-4">
                        <div className="profile__comment">
                          <div className="border-05 p-2 d-flex flex-sm-row flex-column justify-content-between align-items-xl-center">
                            <div className=" mb-sm-0 mb-2">
                              <h5 className="fs-5 darkGrey mb-0 pb-1">
                                Ben Smith
                              </h5>
                              <p className="fm mb-0 darkGrey">
                                Applied for{" "}
                                <span className="blue">
                                  Senior Product Designer
                                </span>
                              </p>
                            </div>
                            <div className="d-flex ">
                              <Dropdown
                                overlay={editComment}
                                placement="bottomRight"
                              >
                                <button className="profile__more me-2">
                                  <BsThreeDots className="fs-5 darkGrey" />
                                </button>
                              </Dropdown>
                              <button className="profile__btn fm px-2 nowrap">
                                <span className="fw-fw-semibold darkGrey">
                                  Stage:
                                </span>{" "}
                                <span className="blue nowrap">
                                  {" "}
                                  Intervewing <SlArrowDown />
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="p-2 d-flex align-items-center">
                            <img
                              src="/assets/images/inbox_dark.svg"
                              alt=""
                              className="profile__icon--img img-fluid"
                            />
                            <span className="mx-2 darkGrey fm">Messages</span>
                            <div className="message_index text-center darkGrey fm">
                              2
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  )} */}
                  {props.page === "enterprise" && (
                    <TabPane tab="Reports" key="tab 6">
                      <div className="px-3 py-4">
                        <div className="profile__comment mb-md-3 mb-2">
                          <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                            <div className=" d-flex align-items-center mb-0">
                              <h5 className="fs-6 darkGrey mb-0">Resume</h5>
                            </div>
                            <div className="d-flex ">
                              <button
                                onClick={() =>
                                  handleDownload(props.data.resumeUrl)
                                }
                                className="fm text-14 darkGrey bg-white d-inline-flex align-items-center p-0"
                              >
                                <img
                                  src="/assets/images/icon_export.svg"
                                  alt=""
                                  className="img-fluid candidates__button--img me-1"
                                />
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="profile__comment mb-md-3 mb-2">
                          <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-0">
                              <h5 className="fs-6 darkGrey mb-0">
                                Criteria Report
                              </h5>
                            </div>
                            <div className="d-flex ">
                              <button
                                onClick={downloadFile1}
                                className="fm text-14 darkGrey bg-white d-inline-flex align-items-center p-0"
                              >
                                <img
                                  src="/assets/images/icon_export.svg"
                                  alt=""
                                  className="img-fluid candidates__button--img me-1"
                                />
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="profile__comment mb-md-3 mb-2">
                          <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-0">
                              <h5 className="fs-6 darkGrey mb-0">
                                Accurate Report
                              </h5>
                            </div>
                            <div className="d-flex ">
                              <button
                                onClick={downloadFile}
                                className="fm text-14 darkGrey bg-white d-inline-flex align-items-center p-0"
                              >
                                <img
                                  src="/assets/images/icon_export.svg"
                                  alt=""
                                  className="img-fluid candidates__button--img me-1"
                                />
                                Download
                              </button>

                              {/* </button> */}
                            </div>
                          </div>
                        </div>
                        {professionalData &&
                          professionalData[0] &&
                          professionalData[0]?.references_feedback && (
                            <div className="profile__comment mb-md-3 mb-2">
                              <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                                <div className="d-flex align-items-center mb-0">
                                  <h5 className="fs-6 darkGrey mb-0">
                                    References Report
                                  </h5>
                                </div>
                                <div className="d-flex ">
                                  <PDFDownloadLink
                                    className="fm text-14 darkGrey bg-white d-inline-flex align-items-center text-decoration-none"
                                    document={
                                      <ReferenceFeedback
                                        referenceFeedbacks={
                                          professionalData[0]
                                            ?.references_feedback
                                        }
                                      />
                                    }
                                    fileName="ReferencesFeedback.pdf"
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? <Loader /> : <><img
                                    src="/assets/images/icon_export.svg"
                                    alt=""
                                    className="img-fluid candidates__button--img me-1"
                                  />Download</>
                                    }
                                  </PDFDownloadLink>
                                </div>
                              </div>
                            </div>
                          )}

                        {props.page == "enterprise" &&
                        props.data.applicationData.feedback_result &&
                        props.data.managerID ==
                          localStorage.getItem("companyID") ? (
                          props.data.status == "interviewed" ||
                          props.data.status == "selected" ||
                          props.data.status == "offered" ||
                          props.data.status == "started" ||
                          props.data.status == "rejected" ? (
                            <div className="profile__comment mb-md-3 mb-2">
                              <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                                <div className="d-flex align-items-center mb-0">
                                  <h5 className="fs-6 darkGrey mb-0">
                                    Feedback Report
                                  </h5>
                                </div>
                                <div className="d-flex ">
                                  <PDFDownloadLink
                                   className="fm text-14 darkGrey bg-white d-inline-flex align-items-center text-decoration-none"
                                    document={
                                      <HiringReport docResponse={props.data} />
                                    }
                                    fileName="Feedback.pdf"
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? <Loader /> : <><img
                                    src="/assets/images/icon_export.svg"
                                    alt=""
                                    className="img-fluid candidates__button--img me-1"
                                  />Download</>
                                    }
                                  </PDFDownloadLink>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}

                        <hr />

                        <div className="profile__comment mb-sm-3">
                          <div className=" p-3 d-flex flex-sm-nowrap flex-wrap gap-2 justify-content-between align-items-center">
                            <div className="d-flex align-items-center mb-0">
                              <h5 className="fs-6 darkGrey mb-0">
                                Candidate Report Pack
                              </h5>
                            </div>
                            <div className="d-flex ">
                              <button
                                onClick={() => downloadZip()}
                                className="fm text-14 darkGrey bg-white d-inline-flex align-items-center p-0"
                              >
                                <img
                                  src="/assets/images/icon_export.svg"
                                  alt=""
                                  className="img-fluid candidates__button--img me-1"
                                />
                                Download
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </div>

            {props.page === "enterprise" && (
              <div className="col-lg-3 ps-lg-0 profile">
                <div className="profile__right prof profile__right--enter bg-white radius8">
                  {/* <div className="px-2 my-2">
                    <div className="w-100 radius8 border overflow-hidden">
                      <div className="d-flex" onClick={() => setDrop(!drop)}>
                        <button className="drop__btn1 fm darkGrey bg-white text-14 p-2">
                          Role:
                        </button>
                        <button className="drop__btn2 fm darkGrey bg-white text-14 p-2 d-flex justify-content-between align-items-center w-100">
                          <span>
                            Manager{" "}
                            <span className="job__type fw-normal py-1 darkGrey text-12 rounded-pill text-capitalize">
                              Live
                            </span>
                          </span>
                          <IoIosArrowDown className="midGrey mt-1" />
                        </button>
                      </div>
                      {drop && (
                        <div className="drop transition overflow-hidden">
                          <div className="p-2 pb-0 border-05">
                            <p className="blue fm text-12 text-uppercase mb-1">
                              LIVE
                            </p>
                            <ul className="list-unstyled">
                              <li className="fm darkGrey text-14">
                                Data Engineer
                              </li>
                              <li className="fm darkGrey text-14">
                                Senior Engineer (Finance)
                              </li>
                            </ul>
                          </div>
                          <div className="p-2 pb-0">
                            <p className="grey fm text-12 text-uppercase mb-1">
                              Archived
                            </p>
                            <ul className="list-unstyled">
                              <li className="fm grey text-14">
                                Data Engineer (June 2020)
                              </li>
                              <li className="fm grey text-14">
                                Data Engineer (May 2020)
                              </li>
                              <li className="fm grey text-14">
                                Data Engineer (September 2018)
                              </li>
                              <li className="fm grey text-14">
                                Data Engineer (August 2015)
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div> */}
                  {!drop && (
                    <Tabs className="pb-2">
                      <TabPane tab="Activity" key="tab 1">
                        <div>
                          <h6 className="px-3">
                            Current Stage{" "}
                            <span
                              className={`job__type ${
                                stage === "archive" ? "bg-grey" : ""
                              } fw-normal py-1 darkGrey text-12 rounded-pill text-capitalize`}
                            >
                              {stage === "applied" && "Applied"}
                              {stage === "shortlisted" && "Shortlisted"}
                              {stage === "interviewed" && "Interview"}
                              {stage === "selected" && "Selected"}
                              {stage === "offered" && "Offered"}
                              {stage === "started" && "Offer Accepted"}
                              {/* {stage === "archive" && "Rejected"} */}
                            </span>
                          </h6>

                          {stage === "shortlisted" && (
                            <div className="d-flex px-3 justify-content-between align-items-center py-2">
                              <span className="fm grey text-14">Applied</span>
                              <BsCheckCircle className="main" />
                            </div>
                          )}
                          {stage === "interviewed" && (
                            <>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">Applied</span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                                <span className="fm grey text-14">
                                  Shortlisted
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                            </>
                          )}
                          {stage === "selected" && (
                            <>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">Applied</span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">
                                  Shortlisted
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                                <span className="fm grey text-14">
                                  Interview
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                            </>
                          )}
                          {stage === "offered" && (
                            <>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">Applied</span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">
                                  Shortlisted
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">
                                  Interview
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                                <span className="fm grey text-14">
                                  Selected
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                            </>
                          )}
                          {stage === "started" && (
                            <>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">Applied</span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">
                                  Shortlisted
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2">
                                <span className="fm grey text-14">
                                  Interview
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                                <span className="fm grey text-14">
                                  Selected
                                </span>
                                <BsCheckCircle className="main" />
                              </div>
                              <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                                <span className="fm grey text-14">Offered</span>
                                <BsCheckCircle className="main" />
                              </div>
                            </>
                          )}
                          {/* {stage === "archive" && (
                        <>
                          <div className="d-flex px-3 justify-content-between align-items-center py-2">
                            <span className="fm grey text-14">
                              New Applicant
                            </span>
                            <BsCheckCircle className="main" />
                          </div>
                          <div className="d-flex px-3 justify-content-between align-items-center py-2">
                            <span className="fm grey text-14">Review</span>
                            <BsCheckCircle className="main" />
                          </div>
                          <div className="d-flex px-3 justify-content-between align-items-center py-2">
                            <span className="fm grey text-14">Interview</span>
                            <BsCheckCircle className="main" />
                          </div>
                          <div className="d-flex px-3 justify-content-between align-items-center py-2">
                            <span className="fm grey text-14">Offer</span>
                            <BsCheckCircle className="main" />
                          </div>
                          <div className="d-flex px-3 justify-content-between align-items-center py-2 mb-2">
                            <span className="fm grey text-14 blue">
                              Archive
                            </span>
                            <BsCheckCircle className="main" />
                          </div>
                        </>
                      )} */}

                          <div
                            className={`border radius8 mx-2 ${
                              stage === "archive" ? "d-none" : ""
                            }`}
                          >
                            <p className="mb-0 fm blue text-14 px-2 py-1 border-05 text-capitalize">
                              {stage === "applied" ? "New Applicant" : stage}
                            </p>
                            {stage === "applied" && (
                              <div className="d-flex p-2">
                                {/* <button
                              className="bg-white border rounded-2 text-12 p-2 fm"
                              onClick={Meeting}
                            >
                              Schedule Meeting
                            </button> */}
                                <button
                                  disabled={buttonLoader}
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "rejected"
                                    );
                                    setButtonLoader(false);
                                  }}
                                  className="bg-white border rounded-2 px-5 text-12 p-2 fm mx-1"
                                >
                                  {buttonLoader ? <Loader /> : "Reject"}
                                </button>
                                <button
                                  disabled={buttonLoader}
                                  className="bg-white border rounded-2 text-12 p-2 fm ms-auto"
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "shortlisted"
                                    );
                                    setButtonLoader(false);
                                  }}
                                >
                                  {buttonLoader ? (
                                    <Loader />
                                  ) : (
                                    "Move Next Stage"
                                  )}
                                </button>
                              </div>
                            )}
                            {stage === "shortlisted" && (
                              <div className="d-flex p-2">
                                {/* <button
                              className="bg-white border rounded-2 text-12 p-2 fm"
                              onClick={Meeting}
                            >
                              Schedule Meeting
                            </button> */}
                                <button
                                  disabled={buttonLoader}
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "rejected"
                                    );
                                    setButtonLoader(false);
                                  }}
                                  className="bg-white border rounded-2 text-12 p-2 px-5 fm mx-1"
                                >
                                  {buttonLoader ? <Loader /> : "Reject"}
                                </button>
                                <button
                                  disabled={buttonLoader}
                                  className="bg-white border rounded-2 text-12 p-2 fm ms-auto"
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "interviewed"
                                    );
                                    setButtonLoader(false);
                                  }}
                                >
                                  {buttonLoader ? (
                                    <Loader />
                                  ) : (
                                    "Move Next Stage"
                                  )}
                                </button>
                              </div>
                            )}
                            {stage === "interviewed" && (
                              <div className="d-flex p-2">
                                {/* <button
                              className="bg-white border rounded-2 text-12 p-2 fm"
                              onClick={Meeting}
                            >
                              Schedule Meeting
                            </button> */}
                                <button
                                  disabled={buttonLoader}
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "rejected"
                                    );
                                    setButtonLoader(false);
                                  }}
                                  className="bg-white border rounded-2 text-12 p-2 px-5 fm mx-1"
                                >
                                  {buttonLoader ? <Loader /> : "Reject"}
                                </button>
                                <button
                                  disabled={buttonLoader}
                                  className="bg-white border rounded-2 text-12 p-2 fm ms-auto"
                                  // onClick={() => setStage("offer")}
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "selected"
                                    );
                                    setButtonLoader(false);
                                  }}
                                >
                                  {buttonLoader ? (
                                    <Loader />
                                  ) : (
                                    "Move Next Stage"
                                  )}
                                </button>
                              </div>
                            )}
                            {stage === "selected" && (
                              // props.data.managerID === localStorage.getItem("companyID") &&
                              // (userData.subscription.name === "Predict" ||
                              //   userData.subscription.name === "Scale") &&
                              <div className="d-flex p-2">
                                <button
                                  onClick={Contract}
                                  className="bg-white border  rounded-2 text-12 p-2 px-5 fm me-1"
                                >
                                  Extend
                                </button>
                                <button
                                  disabled={buttonLoader}
                                  className="bg-white border rounded-2 text-12 p-2 fm ms-auto"
                                  onClick={() => {
                                    setButtonLoader(true);
                                    props.updateCandidateStage(
                                      props.data.applicantID,
                                      "offered"
                                    );
                                    setButtonLoader(false);
                                  }}
                                >
                                  {buttonLoader ? (
                                    <Loader />
                                  ) : (
                                    "Move Next Stage"
                                  )}
                                </button>
                              </div>
                            )}
                            {/* {stage === "response" && (
                          <div className="d-flex p-2">
                            <button className="bg-blue text-white border rounded-2 text-12 p-2 fm me-1">
                              View contract response
                            </button>
                            <button
                              className="bg-white border rounded-2 text-12 p-2 fm ms-auto"
                              onClick={() => setStage("archive")}
                            >
                              Move Next Stage
                            </button>
                          </div>
                        )} */}
                          </div>
                          {stage === "applied" && (
                            <div className="p-3">
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Shortlisted <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Interview <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Selected <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Offered <Radio value={1} className="me-0" />
                              </div>
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Started <Radio value={1} className="me-0" />
                          </div> */}
                            </div>
                          )}
                          {stage === "shortlisted" && (
                            <div className="p-3">
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Interview <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Selected <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Offered <Radio value={1} className="me-0" />
                              </div>
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                          Started <Radio value={1} className="me-0" />
                          </div> */}
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Archive <Radio value={1} className="me-0" />
                          </div> */}
                            </div>
                          )}
                          {stage === "interviewed" && (
                            <div className="p-3">
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Selected <Radio value={1} className="me-0" />
                              </div>
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Offered <Radio value={1} className="me-0" />
                              </div>
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Started <Radio value={1} className="me-0" />
                          </div> */}
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Contract Sent <Radio value={1} className="me-0" />
                          </div> */}
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Archive <Radio value={1} className="me-0" />
                          </div> */}
                            </div>
                          )}
                          {stage === "selected" && (
                            <div className="p-3">
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Offered <Radio value={1} className="me-0" />
                              </div>
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Started <Radio value={1} className="me-0" />
                          </div> */}
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Archive <Radio value={1} className="me-0" />
                          </div> */}
                            </div>
                          )}
                          {stage === "offered" && (
                            <div className="p-3">
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Started <Radio value={1} className="me-0" />
                          </div> */}
                              {/* <div className="d-flex fm grey justify-content-between mb-2">
                            Archive <Radio value={1} className="me-0" />
                          </div> */}
                            </div>
                          )}
                          {stage === "response" && (
                            <div className="p-3">
                              <div className="d-flex fm grey justify-content-between mb-2">
                                Archive <Radio value={1} className="me-0" />
                              </div>
                            </div>
                          )}

                          {stage === "applied" && (
                            <div className="rounded-2 bg-lightmain px-2 py-3 mx-2 bg-lightmain">
                              <h6 className="fm darkGrey text-14 mb-1">
                                New Applicant!
                              </h6>
                              <p className="fm darkGrey mb-0 text-14">
                                Date of apply:{" "}
                                {moment(props.data.date).format("DD-MM-YYYY")}
                              </p>
                            </div>
                          )}

                          {stage === "shortlisted" && (
                            <div className="rounded-2 bg-lightBlue px-2 text-14 py-3 mx-2">
                              <h6 className="fm darkGrey text-14 mb-0">
                                Please schedule interview with candidate
                              </h6>
                            </div>
                          )}

                          {stage === "interviewed" && (
                            <div className="rounded-2 bg-lightBlue px-2 text-14 py-3 mx-2">
                              <h6 className="fm darkGrey text-14 mb-1">
                                Interview
                              </h6>
                              <p className="fm darkGrey mb-0 text-14">
                                Scheduled with interviewers
                                {/* <strong className="fw-semibold">
                              Vacheron Constantin
                            </strong> */}
                              </p>
                              {/* <p className="fm grey mb-0 text-14">
                            Monday 14th May 2024
                          </p>
                          <a
                            href="meet.google.com/rrh-exee-csz"
                            className="text-decoration-none text-14"
                          >
                            meet.google.com/rrh-exee-csz
                          </a> */}
                            </div>
                          )}

                          {stage === "selected" && (
                            <div className="rounded-2 bg-lightBlue px-2 text-14 py-3 mx-2">
                              <h6 className="fm darkGrey text-14 mb-0">
                                Candidate Selected after interview
                              </h6>
                            </div>
                          )}
                          {stage === "offered" && (
                            <div className="rounded-2 bg-lightBlue px-2 text-14 py-3 mx-2">
                              <h6 className="fm darkGrey text-14 mb-1">
                                Offer has been sent
                              </h6>
                              {/* <p className="fm grey mb-0 text-14">
                          Monday 14th May 2024
                        </p> */}
                            </div>
                          )}
                          {stage === "archive" && (
                            <div className="rounded-2 bg-grey px-2 text-14 py-3 mx-2">
                              <h6 className="fm darkGrey text-14 mb-1">
                                This applicant has been archived
                              </h6>
                              <p className="fm darkGrey mb-0 text-14">
                                Last activity: Offer accepted for {"<"}Role Name
                                {">"}{" "}
                              </p>
                              <p className="fm grey mb-0 text-14 mt-0">
                                Monday 14th May 2024
                              </p>
                            </div>
                          )}
                        </div>
                      </TabPane>
                      <TabPane tab="Notes" key="tab 2">
                        <div className="px-3">
                          {/* <div className="d-flex">
                      <button className="fm text-14 text-white bluegradient__btn w-100 text-center px-3 py-2 d-flex  align-items-center justify-content-center">
                        <span className="fs-6 me-1">+</span> New Entry
                      </button>
                    </div>

                        {comments.map((item) => (
                          <div className="profile__comment mt-3">
                            <div className="profile__comment__top border-05 p-2 d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <Avatar
                                  icon={<UserOutlined />}
                                  size="large"
                                  className="me-2"
                                />
                                <div>
                                  <h6 className="text-14 fm darkGrey fw-semibold mb-0">
                                    {item.name}
                                  </h6>
                                  <p className="fm text-12 darkGrey mb-0">
                                    {item.date}
                                  </p>
                                </div>
                              </div>
                              <Dropdown
                                menu={{
                                  items,
                                }}
                                placement="bottomRight"
                              >
                                <SlOptionsVertical className="fs-5 pointer" />
                              </Dropdown>
                            </div>
                            <div className="profile__comment__mid p-2">
                              <p className="darkGrey fm text-14">
                                {item.message}
                              </p>
                            </div>
                            <div className="d-flex p-2 align-items-end">
                              <AiFillLike className="fs-5 me-1 pointer" />
                              <BsFillEmojiSmileFill className="fs-6 pointer" />
                            </div>
                            <div className="px-2 pb-2 d-flex align-items-center">
                              <div
                                className="lightblue__btn d-inline-flex px-2 py-1 pointer align-items-center me-2"
                                onClick={() => setShowReplies(!showReplies)}
                              >
                                <Avatar.Group className="me-2">
                                  {item.replies.map((item) => (
                                    <Avatar
                                      style={{
                                        backgroundColor: "#1890ff",
                                      }}
                                      key={item.id}
                                      size="small"
                                      icon={<UserOutlined />}
                                    />
                                  ))}
                                </Avatar.Group>
                                <span className="fm blue fw-semibold">
                                  {item.replies.length} replies
                                </span>
                              </div>
                              <span
                                className="fm darkGrey fw-semibold fs-6 pointer"
                                onClick={() => setTypeReply(!typeReply)}
                              >
                                {typeReply ? "Done" : "Reply"}
                              </span>
                            </div>
                            {showReplies && (
                              <div className="profile__comment__reply p-2 ">
                                {item.replies.map((item) => (
                                  <p className="border-05 fm border text-14 p-2 blue bg-pastel mb-1">
                                    {item.message}
                                  </p>
                                ))}
                              </div>
                            )}
                            {typeReply && (
                              <div className="p-2">
                                <input
                                  type="text"
                                  className="profile__comment--input form-control mb-2"
                                  placeholder="Type a message"
                                />
                                <button className="fm bg-pastel fw-semibold blue px-3 py-2">
                                  Send
                                </button>
                              </div>
                            )}
                          </div>
                        ))} */}

                          <div className="Dashboard__container">
                            <div className="Experts__container  mt-2">
                              <textarea
                                type="text"
                                className="form-control-lg form-control mb-3"
                                value={newComment}
                                onChange={(value) =>
                                  setNewComment(value.target.value)
                                }
                                placeholder="Your comments..."
                                rows={3}
                              />
                            </div>
                            <div className="Experts__container pb-4  mt-2">
                              <div className=" pb-4">
                                <div className="d-flex justify-content-end">
                                  <button
                                    onClick={() => createNewComment()}
                                    className="fm text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center"
                                  >
                                    {newCommentLoading ? (
                                      <Loader />
                                    ) : (
                                      <>
                                        <span className="fs-4 me-1">+</span>{" "}
                                        Comment
                                      </>
                                    )}
                                  </button>
                                </div>

                                {comments.map((item, index) => (
                                  <div
                                    className="profile__comment mt-3"
                                    key={index}
                                  >
                                    <div className="profile__comment__top border-05 p-2 d-flex justify-content-between align-items-center">
                                      <div className="d-flex align-items-center">
                                        {/* <Avatar
                                          // icon={<UserOutlined />}
                                          src={item.authorData?.profileImage.}
                                          size="large"
                                          className="me-2"
                                        /> */}
                                        <img
                                          className="comment__image"
                                          src={
                                            item.authorData?.profileImage?.docs
                                          }
                                        />
                                        <div>
                                          <h6 className="text-14 fm darkGrey fw-semibold mb-0">
                                            {item.authorData?.firstName +
                                              " " +
                                              item.authorData?.lastName}
                                          </h6>
                                          <p className="fm text-12 darkGrey mb-0">
                                            {item.date}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <Dropdown
                                      overlay={editProfile}
                                      placement="bottomRight"
                                    >
                                      <SlOptionsVertical className="fs-5 pointer" />
                                    </Dropdown> */}
                                    </div>
                                    <div className="profile__comment__mid p-2">
                                      <p className="darkGrey fm">
                                        {item.content}
                                      </p>
                                    </div>
                                    <div className="d-flex p-2 align-items-end">
                                      {/* <AiFillLike className="fs-5 me-1 pointer midGrey" /> */}
                                      {/* <BsFillEmojiSmileFill className="fs-6 pointer midGrey" /> */}
                                    </div>
                                    <div className="px-2 pb-2 d-flex align-items-center">
                                      <div
                                        className="lightblue__btn d-inline-flex px-2 py-1 pointer align-items-center me-2"
                                        onClick={() => toggleReplyBox(index)}
                                      >
                                        <Avatar.Group className="me-2">
                                          {item.replies.map((replyItem) => (
                                            <Avatar
                                              style={{
                                                backgroundColor: "#1890ff",
                                              }}
                                              key={replyItem.id}
                                              size="small"
                                              icon={<UserOutlined />}
                                            />
                                          ))}
                                        </Avatar.Group>
                                        <span className="fm blue fw-semibold">
                                          {item.replies.length} replies
                                        </span>
                                      </div>
                                      <span
                                        className="fm darkGrey fw-semibold fs-6 pointer"
                                        onClick={() => toggleReplyBox(index)}
                                      >
                                        {showReplyBoxIndex === index
                                          ? "Done"
                                          : "Reply"}
                                      </span>
                                    </div>
                                    {showReplyBoxIndex === index && (
                                      <div className="p-2">
                                        <input
                                          type="text"
                                          onChange={(value) =>
                                            setCommentReply(value.target.value)
                                          }
                                          className="profile__comment--input form-control mb-2"
                                          placeholder="Type a message"
                                        />
                                        <button
                                          onClick={() =>
                                            createCommentReply(item._id)
                                          }
                                          className="fm bg-pastel fw-semibold blue px-3 py-2"
                                        >
                                          Send
                                        </button>
                                      </div>
                                    )}
                                    {showReplyBoxIndex === index && (
                                      <div className="profile__comment__reply p-2">
                                        {item.replies.map((replyItem) => (
                                          <p
                                            className="border-05 fm border p-2 blue bg-pastel mb-1"
                                            key={replyItem.id}
                                          >
                                            <strong>
                                              {replyItem.authorData?.firstName +
                                                " " +
                                                replyItem.authorData
                                                  ?.lastName}{" "}
                                              :{" "}
                                            </strong>{" "}
                                            {replyItem.content}
                                          </p>
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      {/* <TabPane tab="Messages" key="tab 3">
                        <div className="px-3">
                          {userData.subscription.name === "Predict" ||
                          userData.subscription.name === "Scale" ? (
                            <button
                              onClick={() => {
                                goToMessaging();
                              }}
                              className="fm text-14 text-capitalize mb-3 text-white bluegradient__btn w-100 text-center px-3 py-2 d-flex  align-items-center justify-content-center"
                            >
                              Go to messaging
                            </button>
                          ) : (
                            <Tooltip
                              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                              placement="top"
                            >
                              <button className="fm text-14 text-capitalize mb-3 text-white bluegradient__btn w-100 text-center px-3 py-2 d-flex  align-items-center justify-content-center">
                                Go to messaging
                              </button>
                            </Tooltip>
                          )}
                         
                        </div>
                      </TabPane> */}
                    </Tabs>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourProfile;
