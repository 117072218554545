import React, {useEffect} from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import SignupAsCandidate from "../Launch/Signup/SignupAsCandidate.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const CandidateSignup = () => {
  useEffect(() => {
    document.body.classList.add('signup')
    return () => {
      document.body.classList.remove('signup')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <SignupAsCandidate />
      <Footer />
    </>
  );
};

export default CandidateSignup;
