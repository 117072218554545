import React, { useContext} from "react";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { MyContext } from "../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";

const HrRecruitingNav = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validateProfile = () => {
    navigate("/hr/dashboard/create-new-job");
  };

  return (
    <div className="d-lg-block d-none">
      <div className="DAccount__top mt-lg-0 mt-4">
        <div className="container-dash py-2">
        <Link to={"/hr/dashboard/jobs"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "jobs" ? "fw-normal" : "fw-light"
                }`}
              >
                Open Roles
              </button>
            </Link>
            {userData.subscription &&
          (userData.subscription.name === "Predict" ||
            userData.subscription.name === "Scale") ? (
            <Link to={"/hr/dashboard/offers"}>
              <button
                className={`DAccount__btn me-sm-4 me-2 transition fm ${
                  props.name === "offers" ? "fw-normal" : "fw-light"
                }`}
              >
                Offer Management
              </button>
            </Link>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <Link className="grey96">
                <button
                  className={`DAccount__btn me-sm-4 me-2 transition fm fw-light grey96`}
                >
                  Offer Management
                </button>
              </Link>
            </Tooltip>
          )}                       
          <button
            onClick={validateProfile}
            className={`DAccount__btn me-sm-4 me-2 transition fm ${
              props.name === "newjob" ? "fw-normal" : "fw-light"
            }`}
          >
            Create a Job
          </button>
        </div>
      </div>
    </div>
  );
};

export default HrRecruitingNav;
