import React, { useState,useEffect } from "react";
import "./style.css";
import Landscape from "./Landscape";
import Overview from "./Overview";
import Hat from "./Hat";

import { useNavigate } from "react-router-dom";

const TalentInsights = (props) => {
  console.log("props on pro", props);
  const navigate = useNavigate();
  const [overview, setOverview] = useState(true);
  const [candidatelandscape, setCandidatelandscape] = useState(false);
  const [hat, setHat] = useState(false);
  
  const func_overview = () =>{
    setOverview(true)
    setCandidatelandscape(false)
    setHat(false)
    navigate("/enterprise/dashboard/candidate-landscape/overview");
  }
  const func_landscape = () =>{
    setOverview(false)
    setCandidatelandscape(true)
    setHat(false)    
    navigate("/enterprise/dashboard/candidate-landscape/");
  }
  const func_hat = () =>{
    setOverview(false)
    setCandidatelandscape(false)
    setHat(true)
    navigate("/enterprise/dashboard/candidate-landscape/hat");
  }
  

  useEffect(() => {
      // The code parameter is not present in the URL
      if (props.name === "overview") {
        func_overview();
      } else if (props.name === "landscape") {
        func_landscape();
      }
      else if (props.name === "hat") {
        func_hat();
      }
    
  }, [props.name]);
  


  return (
    <div className="DAccount bg-lightgrey">
      <div className="DAccount__top d-lg-block d-none mt-lg-0 mt-4">
        <div className="position-relative">
          <div className="DAccount__top2"></div>
        </div>
        <div className="container-dash py-2 ">
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${overview || candidatelandscape ? "fw-normal": "fw-light"}`} onClick={func_overview}>Talent Availability</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${hat ? "fw-normal": "fw-light"}`} onClick={func_hat}>HAT</button>
          {/* <button className={`DAccount__btn me-sm-4 me-2 transition fm ${candidatelandscape ? "fw-normal": "fw-light"}`} onClick={func_landscape}>Candidate Landscape</button> */}
        </div>
      </div>
      <div className="container-dash">
        {overview && <Overview />}
        {candidatelandscape && <Landscape />} 
        {hat && <Hat />} 
      </div>
    </div>
  );
};

export default TalentInsights;
