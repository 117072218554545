import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const menu = [
    {
        id: 1,
        title: "Enterprise",
        link: "/enterprise",
        class: "enterprise",
      },
    {
      id: 2,
      title: "Candidate",
      link: "/candidate",
      class: "professionals",
    },
    {
      id: 3,
      title: "Pricing",
      link: "/pricing",
      class: "pricing",
    },
    // {
    //   id: 4,
    //   title: "Why IndexScore?",
    //   link: "/why-indexcore",
    //   class:'why-indexcore',
    // },
    {
      id: 5,
      title: "About us",
      link: "/about-us",
      class: "about-us",
    },
    {
        id: 6,
        title: "Login",
        link: "/log-in",
        class: "login",
      },
    {
      id: 6,
      title: "Signup",
      link: "/signup",
      class:'signup'
    },
  ];

const UpdatedNav = () => {
  return (
    <div className='UNav w-100 d-lg-block d-none main-padding'>
        <div className="d-flex justify-content-between align-items-center rounded-pill UNav__container">
            <Link to={"/"} className='text-decoration-none'><img src="/assets/images/navbar/logoWhite.svg" alt="" className='UNav__logo' /></Link>

            <ul className='d-lg-flex d-none list-unstyled mb-0 '>
               {menu.map((item)=> (
                 <li key={item.id}><Link to={item.link} className={`${item.class}-active text-decoration-none UNav__item transition fm`}>{item.title}</Link></li>
               ))}
                <li><Link to={"/book-a-demo"} className='text-decoration-none UNav__item-btn rounded-pill transition fm'>Book a demo</Link></li>
            </ul>
        </div>
    </div>
  )
}

export default UpdatedNav