import React, { useState, useEffect, useRef, useContext } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Checkbox,
  Slider,
  Select,
  Button,
  Table,
  Pagination,
  Menu,
  Modal,
} from "antd";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import {
  BsFilterCircle,
  BsSearch,
  BsFilter,
  BsLinkedin,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { TiArrowUnsorted } from "react-icons/ti";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import YourProfile from "../../ProfessionalDashboard/YourProfile/YourProfile";
import MeetingDate from "./MeetingDate";
import MeetingTime from "./MeetingTime";
import MeetingDetails from "./MeetingDetails";
import MeetingConfirm from "./MeetingConfirm";
import {
  getProfessionalsList,
  downloadAttachment,
  CreateMemberrHandler,
  getMembersByCompany,
  deleteMember,
  updateMember,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import moment from "moment";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { Country } from "../../../Utilities/Country";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";

const DepartmentTab = (props) => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];  
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(false);
  const [candidates, setCandidates] = useState(true);
  const [meeting, setMeeting] = useState(false);
  const [time, setTime] = useState(false);
  const [detail, setDetail] = useState(false);
  const [all, setAll] = useState(true);
  const [fav, setFav] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [filter, setFilter] = useState(false);
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const inputRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [relevance, setRelevance] = useState(true);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [dropfav, setDropfav] = useState(true);
  const [droplist, setDroplist] = useState(true);
  const [dropinterview, setDropinterview] = useState(true);
  const isMediumScreen = useMediaQuery("(max-width:767px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [membersLoading, setMembersLoading] = useState(false);
  const [members, setMembers] = useState([]);

  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [statusChecked, setStatusChecked] = useState(false);
  const [nameChecked, setNameChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [departmentChecked, setDepartmentChecked] = useState(false);
  const [roleChecked, setRoleChecked] = useState(false);
  const [sortingOption, setSortingOption] = useState("1");
  const { Option, OptGroup } = Select;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { confirm } = Modal;
  const [memberUpdateId, setMemberUpdateId] = useState(null);
  // console.log("props from jobs enterprise" + JSON.stringify());

  const dropfav_func = () => {
    if (isMediumScreen) {
      setDropfav(!dropfav);
    }
  };
  const droplist_func = () => {
    if (isMediumScreen) {
      setDroplist(!droplist);
    }
  };
  const dropinterview_func = () => {
    if (isMediumScreen) {
      setDropinterview(!dropinterview);
    }
  };

  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (flag) => {
    setOpen1(flag);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", filterClick);
    return () => {
      window.removeEventListener("mousedown", filterClick);
    };
  });
  const filterDrop = () => {
    setFilter(!filter);
    setOpen1(!open1);    
  };
  function filterClick(event) {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilter(false);
    }
  }
  useEffect(() => {
    filterRef.current = document.getElementById("filter_btn");
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    }
    if (isSmallScreen) {
      setDropfav(true);
      setDroplist(false);
      setDropinterview(false);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  useEffect(() => {
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    setMembersLoading(true);
    // getMembersByCompany

    const payloadGetMembers = {
      enterpriseID: userData?.companyID,
    };

    const responseTeamMembers = await getMembersByCompany(payloadGetMembers);

    if (responseTeamMembers.status == 200) {
      // Reverse the order of the data array
      const reversedData = responseTeamMembers.data.data.slice().reverse();      
      setMembers(reversedData);
      setMembersLoading(false);
    }
  };

  const showPromiseConfirm = (memberId) => {
    confirm({
      title: "Delete a Member",
      content: "Are you sure you want to delete the member",
      onOk() {
        const payload = {
          _id: memberId,
        };        
        deleteMember(payload)
          .then((response) => {
            if (response.data.statusCode == 1) {
              enqueueSnackbar(`Member Deleted Successfully`, {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              getTeamMembers();
            }
          })
          .catch((error) => {
            console.error("Error deleting member:", error);
          });
      },
      onCancel() {},
    });
  };

  const handleDeleteClick = (jobId, event) => {
    // event.preventDefault();
    // Call the showPromiseConfirm function with the jobId as a parameter
    showPromiseConfirm(jobId);
  };

  const handleUpdateClick = (candidate, event) => {    
    setMemberUpdateId(candidate.id);

    setFormData({
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      email: candidate.emailAddress,
      role: candidate.role,
      department: candidate.department,
    });
    setPopupVisible(true);
  };

  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle sorting order if the same field is clicked
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the new sorting field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const fetchedCandidatesList = members.map((person, index) => ({
    id: person._id,
    name: person.firstName + " " + person.lastName,
    firstName: person.firstName,
    lastName: person.lastName,
    department: person.department,
    role: person.role,
    emailAddress: person.emailAddress,
    status: "Approved",
    date: person.createdAt,
  }));

  // const handleChange1 = (key, value) => {
  //   const selectedIndex = options.indexOf(value);
  // };

  const customSort = (a, b) => {
    const valueA = a[sortField];
    const valueB = b[sortField];

    if (typeof valueA === "number" && typeof valueB === "number") {
      return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      return sortOrder === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    } else {
      // Handle other data types (e.g., undefined) by placing them at the end of the list
      return valueA ? -1 : 1;
    }
  };

  const sortedCandidates = [...fetchedCandidatesList].sort(customSort);

  const [state, setState] = useState({
    loading: false,
  });

  const handleSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setSortingOption(key.key);
  };

  const { loading } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const allCandidates = () => {
    setAll(true);
    setFav(false);
    setRejected(false);
  };
  const favCandidates = () => {
    setAll(false);
    setFav(true);
    setRejected(false);
  };
  const rejectedCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedIndex(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (profile) {
    document.body.style.background = "#F8F8F8";
  } else document.body.style.background = "#fff";

  const items = [
    {
      label: (
        <div className="d-flex flex-column pb-1">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox
            checked={nameChecked}
            onChange={(e) => setNameChecked(e.target.checked)}
            className="fm darkGrey"
          >
            Name
          </Checkbox>
          <Checkbox
            checked={emailChecked}
            onChange={(e) => setEmailChecked(e.target.checked)}
            className="fm darkGrey"
          >
            Email
          </Checkbox>
          <Checkbox
            checked={departmentChecked}
            onChange={(e) => setDepartmentChecked(e.target.checked)}
            className="fm darkGrey"
          >
            Department
          </Checkbox>
          <Checkbox
            checked={roleChecked}
            onChange={(e) => setRoleChecked(e.target.checked)}
            className="fm darkGrey"
          >
            Role
          </Checkbox>
          <Checkbox
            checked={statusChecked}
            onChange={(e) => setStatusChecked(e.target.checked)}
            className="fm darkGrey"
          >
            Status
          </Checkbox>
        </div>
      ),
      key: "0",
    },
  ];
  const sortingMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];

  const selectedCheckboxes = items.filter((item) => {
    const label = item.label.props.children;
    // Check if the label is a Checkbox component and if it's checked
    return (
      label &&
      label.type &&
      label.type.name === "Checkbox" &&
      label.props.checked
    );
  });

  // Determine if any checkbox is selected
  const isAnyCheckboxSelected = selectedCheckboxes.length > 0;

  const filteredCandidates = sortedCandidates
    .filter((candidate) => {
      const { name, department, role, emailAddress, status } = candidate;
      const search = searchQuery.toLowerCase();

      // Check if any checkbox is checked
      if (
        statusChecked ||
        departmentChecked ||
        roleChecked ||
        emailChecked ||
        nameChecked
      ) {
        return (
          (statusChecked && status.toLowerCase().includes(search)) ||
          (departmentChecked && department.toLowerCase().includes(search)) ||
          (roleChecked && role.toLowerCase().includes(search)) ||
          (emailChecked && emailAddress.toLowerCase().includes(search)) ||
          (nameChecked && name.toLowerCase().includes(search))
        );
      } else {
        // If no checkbox is checked, search from all fields
        return (
          name.toLowerCase().includes(search) ||
          department.toLowerCase().includes(search) ||
          role.toLowerCase().includes(search) ||
          emailAddress.toLowerCase().includes(search) ||
          status.toLowerCase().includes(search)
        );
      }
    })
    .sort((a, b) => {
      console.log("Sorting option:", sortingOption);
      const sortingNumber = parseInt(sortingOption, 10); // Convert to number
      if (sortingNumber === 1) {
        // Sort by most recent
        return new Date(b.date) - new Date(a.date);
      } else if (sortingNumber === 2) {
        // Sort by oldest
        return new Date(a.date) - new Date(b.date);
      }
      // Default sorting
      return 0;
    });

  const tableColumns = [
    {
      title: (
        <>
          Members{" "}
          <span className="fw-normal">
            ({filteredCandidates ? filteredCandidates.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Email",
      width: 280,
      dataIndex: "email",
      key: "2",
      sorter: (a, b) => {
        const emailA = (a.email || "").toLowerCase();
        const emailB = (b.email || "").toLowerCase();
        return emailA.localeCompare(emailB);
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "3",
      width: 150,
    },
    {
      title: "Permissions",
      dataIndex: "permission",
      key: "4",
      width: 150,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "5",
      width: 150,
      sorter: (a, b) => {
        const departmentA = (a.department || "").toLowerCase();
        const departmentB = (b.department || "").toLowerCase();
        return departmentA.localeCompare(departmentB);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "6",
      width: 130,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "7",
      width: 200,
      sorter: (a, b) => {
        const roleA = (a.role || "").toLowerCase();
        const roleB = (b.role || "").toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
    },
  ];
  const formattedCandidates = filteredCandidates.map((candidate, index) => ({
    key: candidate.id, // Assuming id uniquely identifies each candidate
    name: (
      <>
        <div className="d-flex align-items-center">
          <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
            <img
              src={`/assets/images/userAvatar.svg`}
              alt=""
              className="candidates__avatar--img img-fluid"
            />
          </div>
          <h6 className="Experts__name fm mb-0 ms-2">
            <h6 className="mb-0 me-1 text-14">{candidate.name}</h6>
          </h6>
        </div>
      </>
    ),
    role: candidate.role && candidate.role === "interviewer"
    ? "Interviewer"
    : "HR",
    email: candidate.emailAddress,
    status: (
      <span
        className={`job__type text-13 text-capitalize rounded-pill status__${candidate.status}`}
      >
        {candidate.status}
      </span>
    ),
    team: userData.companyName,
    permission:
      candidate.role && candidate.role === "interviewer"
        ? "Interview"
        : "Management",
    department: candidate.department,
    action: (
      <Dropdown
        placement="top"
        arrow={{ pointAtCenter: true }}
        overlay={
          <Menu>
            <Menu.Item
              key="Edit"
              onClick={(event) => handleUpdateClick(candidate, event)}
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="Delete"
              onClick={(event) => handleDeleteClick(candidate.id, event)}
            >
              Delete
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
      >
        <Button className="candidates__menu">
          <BsThreeDotsVertical className="text-decoration-none" />
        </Button>
      </Dropdown>
    ),
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const totalItems = formattedCandidates.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    document.body.classList.add("candidates");
    return () => {
      document.body.classList.remove("candidates");
    };
  }, []);
  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2 ms-1">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };
  const tableBodyStyle = {
    border: "1px solid #ddd",
    borderRadius: "8px",
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const EditPopupModal = () => {
    setPopupVisible(true);
  };
  const EditPopuphandleOk = () => {
    setPopupVisible(false);
  };
  const EditPopuphandleCancel = () => {
    setPopupVisible(false);
  };

  const [loadingForm, setLoadingForm] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    department: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    department: "",
  });

  const handleInputChangeForm = (e, name) => {
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    // If `value` is an object representing the selected option, access its `value` property
    if (value && value.hasOwnProperty("value")) {
      value = value.value;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      department: "",
    };

    // Add your validation logic here
    if (formData.firstName.trim() === "") {
      isValid = false;
      errors.firstName = "First name is required";
    }

    if (formData.lastName.trim() === "") {
      isValid = false;
      errors.lastName = "Last name is required";
    }

    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      isValid = false;
      errors.email = "Invalid email address";
    }

    if (formData.role === "") {
      isValid = false;
      errors.role = "Role is required";
    }

    if (formData.department === "") {
      isValid = false;
      errors.department = "Department is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Add email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddButtonClick = async () => {
    if (validateForm()) {
      setLoadingForm(true);
      console.log("Form data:", formData);
      // const enterpriseId = localStorage.getItem('enterpriseID');
      if (memberUpdateId) {
        const payload = {
          _id: memberUpdateId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          role: formData.role,
          department: formData.department          
        };

        const responseAddMember = await updateMember(payload);
      } else {
        const payload = {
          enterpriseID: userData?.companyID,
          enterpriseData: userData?.companyID,
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          role: formData.role,
          usertype: "member",
          department: formData.department,
          company_name: userData.companyName,
          company_email: userData.emailAddress,
        };

        const responseAddMember = await CreateMemberrHandler(payload);
      }

      setLoadingForm(false);

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        department: "",
      });

      getTeamMembers();
      EditPopuphandleCancel();
    }
  };
  return (
    <div className={`${profile ? "bg-lightgrey" : ""}`}>
      <style>
        {`:where(.css-dev-only-do-not-override-1e3x2xa).ant-avatar >img {object-fit: contain;
            width: 65%;
            margin: auto;}
        .resume-icon-download{
          text-align:left;
        }
        .linkedin-icon{
          font-size: 28px;
        }
        .resume-icon-download img{
              cursor:pointer;
              width:30px;
          }          
            
        }`}
      </style>

      <div className="edash bg-white">
        <div className="container-dash pb-4 pt-4">
          <div>
            {/* header row */}
            <div className="mb-3 candidates__opt">
              <div className="d-flex flex-lg-row flex-column-reverse align-items-lg-center justify-content-lg-between mb-3">
                <div className="d-sm-flex justify-content-start">
                  <div className="d-flex justify-content-sm-start justify-content-between">
                    <div>
                      <Dropdown
                        menu={{
                          items: sortingMenus,
                          onClick: handleSortingChange,
                        }}
                        className="sorting__menus jobd__btn me-2"
                        placement="bottomLeft"
                        trigger={['click']}
                        
                      >
                        <Button>
                          <>
                            <span className="fm">Sort By: </span>
                            <strong className="fm">
                              {
                                sortingMenus.find(
                                  (menu) => menu.key === sortingOption
                                )?.label
                              }{" "}
                              <IoIosArrowDown />
                            </strong>
                          </>
                        </Button>
                      </Dropdown>
                    </div>
                    {/* <div className=" d-block h-100">
                      <Dropdown
                        ref={filterRef}
                        menu={{
                          items,
                          onClick: handleMenuClick,
                        }}
                        onOpenChange={handleOpenChange}
                        open={open1}
                        trigger={["click"]}
                        overlayClassName={"dropdown__filter"}
                      >
                        <button
                          className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                          onClick={filterDrop}
                          style={{
                            border: filter ? "0.5px solid #5F89FF" : "",
                            color: filter ? "#5F89FF" : "",
                          }}
                          id="filter_btn"
                          ref={filterRef}
                        >
                          <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                          Filter
                        </button>
                      </Dropdown>
                    </div> */}
                  </div>
                </div>
                <div className="d-sm-flex justify-content-end mb-lg-0 mb-2 ">
                  <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                    <BsSearch className="candidates__searchbox__icon mx-2" />
                    <input
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      type="text"
                      className="fm candidates__searchbox__input text-14"
                      placeholder="Search team..."
                    />
                  </div>
                  <button
                    onClick={() => {
                      setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        role: "",
                        department: "",
                      });
                      setMemberUpdateId(null);
                      EditPopupModal();
                    }}
                    className="bluegradient__btn nowrap jobd__btn text-white fm transition me-2 px-3 addcandidate__button text-decoration-none"
                  >
                    Invite member
                  </button>
                </div>
              </div>
            </div>

            {membersLoading ? (
              <div className="d-md-flex d-none align-items-center pt-2">
                <Loader size="md" content="loading" />
              </div>
            ) : (
             <>
              <div className="table__outer  d-md-block d-none rounded-3 position-relative">
                <Table
                  className=" dash__table"
                  columns={tableColumns}
                  dataSource={formattedCandidates}
                  pagination={false}
                  scroll={{
                    x: 1300,
                  }}
                  bodyStyle={tableBodyStyle}
                />
              </div>
              {/* {sortedCandidates.length > 0 && (
                <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                  <button className="fm text-12 table__bottom__btn1">
                    Load all members ({sortedCandidates.length})
                  </button>
                  <button className="fm text-12 table__bottom__btn2">
                    Scroll to load more{" "}
                    <HiOutlineArrowDownCircle className="fs-6" />
                  </button>
                </div>
              )} */}
              </>
            )}
            {/* row1 */}
            {membersLoading ? (
              <div className="d-md-none d-flex align-items-center pt-2">
                <Loader size="md" content="loading" />
              </div>
            ) : (
              sortedCandidates.map((item, index) => (
                <div
                  className={`d-md-none d-flex row enterprise Experts__row ${
                    item.cat === "fav" ? "candidates__fav" : "candidates__all"
                  } mx-0 mt-2 pt-xl-2 pb-xl-2 pt-3 pb-4 my-3 transition pointer`}
                  key={item.id}
                >
                  <div className="col-xl-3 col-12 pe-xl-0 order-1 pb-xl-0 pb-3">
                    <div className="d-flex align-items-center w-100">
                      <div className="d-flex justify-content-between align-items-start w-100">
                        <div className="d-flex align-items-center me-xl-0 me-2">
                          <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                            <img
                              src={`/assets/images/userAvatar.svg`}
                              alt=""
                              className="candidates__avatar--img img-fluid"
                            />
                          </div>
                          <div className="Experts__name fm mb-0 ms-2">
                            <h6 className="mb-0 me-1">{item.name}</h6>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-6 order-xl-3 fm fs-6 order-2 d-flex align-items-xl-center align-items-start justify-content-start mb-xl-0 mb-2">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Email:
                    </span>{" "}
                    <span className="word-wrap">{item.emailAddress}</span>
                  </div>
                  <div className="col-xl-2 col-sm-6 fm fs-6 d-flex align-items-center justify-content-start order-3 mb-xl-0 mb-2">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Department:
                    </span>
                    {item.department}
                  </div>

                  <div className="col-xl-2 col-sm-6 fm fs-6 order-xl-3 order-5 d-flex align-items-center justify-content-start">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Status:
                    </span>
                    {item.status}
                  </div>
                  <div className="col-xl-2 col-sm-6 fm fs-6 order-xl-3 order-4 d-flex align-items-center justify-content-start mb-sm-0 mb-2">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Role:
                    </span>
                    {item.role}
                  </div>

                  <div className="col-xl-3 col-sm-6 order-xl-3 fm fs-6 order-2 d-flex align-items-xl-center align-items-start justify-content-start mb-xl-0 mb-2">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Team:
                    </span>{" "}
                    <span className="word-wrap">{item.team}</span>
                  </div>
                  <div className="col-xl-2 col-sm-6 fm fs-6 d-flex align-items-center justify-content-start order-3 mb-xl-0 mb-2">
                    <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                      Permissions:
                    </span>
                    <div
                      className={`rounded-pill px-2 py-1 pill__${item.color} text-capitalize`}
                    >
                      {/* colors: grey, lightblue, blue, main */}
                      {item.permission}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <Modal
        className="certification-popup"
        title={memberUpdateId ? "Update Member" : "Invite Member"}
        visible={popupVisible}
        onOk={EditPopuphandleOk}
        onCancel={EditPopuphandleCancel}
        footer={null}
        width={600}
      >
        <div className="add-member-popup-form mt-5">
          <div className="row">
            <div className="col-md-6">
              <div className="form-box">
                <label className="w-100">
                  First name
                  <input
                    type="text"
                    name="firstName"
                    className="form-control form-control-md w-100 Dashboard__input mb-2 h-45"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={(value) =>
                      handleInputChangeForm(value, "firstName")
                    }
                  />
                  <div className="error-message">{formErrors.firstName}</div>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-box">
                <label className="w-100">
                  Last name
                  <input
                    type="text"
                    name="lastName"
                    className="form-control form-control-md w-100 Dashboard__input mb-2 h-45"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={(value) =>
                      handleInputChangeForm(value, "lastName")
                    }
                  />
                  <div className="error-message">{formErrors.lastName}</div>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-box">
                <label className="w-100">
                  Email
                  <input
                    type="email"
                    name="email"
                    readOnly={memberUpdateId}
                    className="form-control form-control-md w-100 Dashboard__input mb-2 h-45"
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={(value) => handleInputChangeForm(value, "email")}
                  />
                  <div className="error-message">{formErrors.email}</div>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="General">
                <label className="w-100">
                  Select Role
                  <Select
                    className="w-100 Login--Input"
                    placeholder="Select role"
                    value={formData.role}
                    onChange={(value) => handleSelectChange(value, "role")}
                  >
                    <Option value="hr">HR</Option>
                    <Option value="interviewer">Interviewer</Option>
                  </Select>
                  <div className="error-message">{formErrors.role}</div>
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-box">
                <label className="w-100">
                  Department Name
                  <input
                    type="text"
                    name="department"
                    className="form-control form-control-md w-100 Dashboard__input mb-2 h-45"
                    placeholder="Enter Department Name"
                    value={formData.department}
                    onChange={(value) =>
                      handleInputChangeForm(value, "department")
                    }
                  />
                  <div className="error-message">{formErrors.department}</div>
                </label>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-end mt-5">
              <Button
                onClick={EditPopuphandleCancel}
                className="primary cancel-button jobd__btn fm"
                style={{ marginRight: "8px" }}
              >
                Cancel
              </Button>
              <Button className="desc__applynow jobd__btn" onClick={handleAddButtonClick}>
                {loadingForm ? <Loader /> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DepartmentTab;
