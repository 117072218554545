import React from "react";
import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PSection4 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="">
      <div className=" main-padding pb-4">
        <div className="d-flex flex-column container-lg align-items-center  pt-5 px-0">
          <h1 className="fs-2 darkBlue fw-semibold text-center pb-4">
            Integrates seamlessly with your existing ATS
          </h1>
        </div>
        <div className="container-xl my-4">
          <Slider {...settings}>
            <div className="d-flex justify-content-center  align-items-center mt-lg-4 mt-3">
              <img
                src="/assets/images/Launch/company1.svg"
                alt=""
                className="es1__companyimg1 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center  align-items-center mt-lg-3 mt-2">
              <img
                src="/assets/images/Launch/company2.svg"
                alt=""
                className="es1__companyimg2 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="/assets/images/Launch/company3.svg"
                alt=""
                className="es1__companyimg3 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="/assets/images/Launch/company4.svg"
                alt=""
                className="es1__companyimg4 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-lg-4 mt-3">
              <img
                src="/assets/images/Launch/company5.png"
                alt=""
                className="es1__companyimg5 mb-2"
              />
            </div>
          </Slider>
        </div>
        <p className="fm darkGrey ps4__text mx-auto fs-6 text-center mb-2">
          Have an existing ATS? No problem! We can integrate with your ATS in
          minutes, score all your applicants and help you identify qualified
          candidates in no time.
        </p>
      </div>
    </div>
  );
};

export default PSection4;
