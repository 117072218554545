import React from "react";
import "./style.css";

const cards = [
  {
    id: 1,
    title: "Get found by organizations that value you",
    para: "Through the ebbs and flows of employment & markets, your talent is the constant value. Instead of compressing who you are and what you can do in an attachment to recruiters, we draw on 600+ data points on you to create the most data-rich insight that can possibly capture you—distilled in one comprehensive score.",
  },
  {
    id: 2,
    title: "Improve what employers see about you",
    para: "Similar to a Credit Score, Index Score’s objective is to give you instant clarity on where you stand in terms of employability—while highlighting areas where you can improve. Instead of unintentional background discrepancies putting off employers, we put your data in your control.",
  },
  {
    id: 3,
    title: "Your time to advance on a fair playing field",
    para: "With one score by our system, assigned across all candidates fairly, you’ll finally be in a position with equal employment opportunity.",
  },
];

const UCSection1 = () => {
  return (
    <div className="ues1 main-padding">
      <div className="container-xl px-0">
        <div className="h-100 fm d-flex flex-column align-items-center overflow-hidden top-padding ">
          <h1 className="darkBlue fw-semibold fm fs-1 uhs1__heading text-center">
            Say goodbye to repetitive application processes
          </h1>
          <p className="darkGrey fm ues1__para text-center text-xxl-16">
            We believe it’s time you had a better way to share your experience
            and unique background than a 2-page resume. With a score that sums
            up your experience, skills, working style, thought leadership, and
            more for employers—data puts you at the front as you should be.
          </p>

          <div className="ucs1__image rounded-3 px-md-4 mt-md-4 mt-2">
            <img
              src="/assets/UpdatedFrontend/Candidate/CandidateDash.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* section2 */}
      <div className="ues2 mt-5">
        <div className="container-xl px-xl-5 px-0 pt-4">
          <div className="row px-xl-5">
            {cards.map((item) => (
              <div className="col-lg-4 col-sm-6 mb-4 pe-lg-0" key={item.id}>
                <div className={`ues2__card ues2__card${item.id}`}>
                  <div
                    className={`ues2__card__top ucs2__card${item.id}__top rounded-3 d-flex align-items-center justify-content-center`}
                  >
                    <img
                      src={`/assets/UpdatedFrontend/Candidate/card${item.id}.png`}
                      alt=""
                      className="img-fluid "
                    />
                  </div>

                  <h3 className="fm fs-4 fw-semibold text-center mx-auto mt-3">
                    {item.title}
                  </h3>
                  <p className="fm text-center text-14 mx-auto pt-2">
                    {item.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UCSection1;
