import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getJobById } from "../../../services/auth";







const JobApply = () => {
  // var myObjectEnterprise;
  const [jobData, setJobData] = useState([]);
  const { id } = useParams();
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });



  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };


  const fetchJobs = async () => {
    handleChange("loading2", true);
    try {
      console.log("id parameter" + id);

      var payload = {
        _id: id
      };
      const response = await getJobById(payload);
      setJobData(response.data.data.find((obj) => obj._id === id));

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  useEffect(() => {
    fetchJobs();

  }, []);

  // myObjectEnterprise = props.jobsData.find(obj => obj.key === props.data);

  return (
    <>
      <div className="edash">
        <div className="pt-4">
          <div className="container-dash">
            <div className="Dashboard__container">
              <div className="jobs fm mb-5">
                <div className="pe-xl-5">
                  <h1 className="fs-2 fm darkBlue">
                    Thank you for applying for  &nbsp;
                    {/* {"<"} */}
                    <span style={{
                      textTransform: 'Uppercase'
                    }}>{jobData && jobData['job_title']}
                    </span>
                    {/* {">"} */}
                    <br /> at
                    {/* {"<"} */}&nbsp;
                    <span style={{
                      textTransform: 'Uppercase'
                    }}>{jobData && jobData['company']}</span>
                    {/* {">"} */}
                    .
                  </h1>
                  <p className="fm darkGrey">
                    You’ll shortly receive an email confirming your application, you can
                    view the role in your archived roles tab here. If you’d like to look at
                    more roles IndexScore has to offer click back to search though our list
                    of current positions.
                  </p>
                  <p className="fm darkGrey">
                    In the meantime, if you need help, contact{" "}
                    <a
                      href="mailto:support@indexscore.com"
                      className="text-decoration-none link__main transition"
                    >
                      support@indexscore.com
                    </a>
                    . Or use the support tab in your navigation bar to get in contact with
                    us.
                  </p>
                  <div className="filter__data d-flex align-items-md-center my-5">
                    <div className="filter__image radius8">
                      <img
                        src="/assets/images/EnterpriseAvatar.svg"
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                    <div className="filter__content ">
                      <div className="d-flex justify-content-between">
                        <div className="filter__content__items">
                          <h3 className="filter__content__heading mb-0 fw-semibold fm midGrey" style={{
                            textTransform: 'Uppercase'
                          }}>
                            {jobData && jobData['company']}
                          </h3>
                          <div className="filter__content__para mt-0">
                            <p className="pro__dash__jobs__para mb-0 midGrey fm" style={{
                              textTransform: 'Uppercase'
                            }}>
                              {jobData && jobData['job_title']}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="container-fluid px-0">
                  <Link to={'/candidate/dashboard/jobs'} className="text-decoration-none">
                    <button
                      className="apply__back py-2 fm transition px-3 mb-2"
                    >
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobApply;
