import React, { useState,useEffect } from "react";
import "./style.css";

import JobDescription from "./JobDescription";
import ProOffers from "../Contracts/Offers";
import ProInterviews from "../Contracts/Interviews";
import ProInterviewPrep from "../Contracts/InterviewPrep";
import ProContracts from "../Contracts/Contracts";

import { useNavigate } from "react-router-dom";

const JobsDescriptionBox = (props) => {
  const navigate = useNavigate();
  const [jobsDescriptiontab, setJobsDescriptiontab] = useState(true);
  const [jobstab, setJobstab] = useState(true);
  const [interviews, setInterviews] = useState(false);
  const [offers, setOffers] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [interviewPreparation, setInterviewPreparation] = useState(false);
  
  const func_jobsDescriptiontab = () =>{
    setJobsDescriptiontab(true)
    setInterviews(false)
    setOffers(false)    
    setContracts(false);
    setInterviewPreparation(false);  
    navigate("/candidate/dashboard/jobs");
  }
  const func_jobsDescriptioninterviews = () =>{
    setJobsDescriptiontab(false)
    setInterviews(true)
    setOffers(false)    
    setContracts(false);
    setInterviewPreparation(false);
    navigate("/candidate/dashboard/interviews");
  }
  const func_jobsDescriptionoffers = () =>{
    setJobsDescriptiontab(false)
    setInterviews(false)
    setOffers(true)    
    setContracts(false);
    setInterviewPreparation(false);    
    navigate("/candidate/dashboard/offers");
  }
  const func_jobsDescriptioncontracts = () =>{
    setJobsDescriptiontab(false)
    setInterviews(false)
    setOffers(false)  
    setContracts(true);  
    setInterviewPreparation(false);
    navigate("/candidate/dashboard/contracts");
  }

  const func_jobsDescriptioninterviewPreparation = () =>{
    setJobsDescriptiontab(false)
    setInterviews(false)
    setOffers(false)  
    setContracts(false);  
    setInterviewPreparation(true);
    navigate("/candidate/dashboard/interview-preparation");
  }

  useEffect(() => {
    if (props.name === "offers") {
      func_jobsDescriptionoffers();
    } else if (props.name === "interviews") {
      func_jobsDescriptioninterviews();
    }
    else if (props.name === "contracts") {
      func_jobsDescriptioncontracts();
    }
    else if (props.name === "interviewPreparation") {
      func_jobsDescriptioninterviewPreparation();
    }
  }, [props.name]);
  

  return (
    <div className="DAccount">
      <div className="DAccount__top d-lg-block d-none">
        <div className="container-dash py-2">
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${jobsDescriptiontab  ? "fw-normal": "fw-light"}`} onClick={func_jobsDescriptiontab}>Open Roles</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${interviews ? "fw-normal": "fw-light"}`} onClick={func_jobsDescriptioninterviews}>Interviews</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${interviewPreparation ? "fw-normal": "fw-light"}`} onClick={func_jobsDescriptioninterviewPreparation}>Interview Prep</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${offers ? "fw-normal": "fw-light"}`} onClick={func_jobsDescriptionoffers}>Offers</button>        
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${contracts ? "fw-normal": "fw-light"}`} onClick={func_jobsDescriptioncontracts}>Contracts</button>               
        </div>
      </div>
      <div className="container-dash">
        {jobsDescriptiontab && <JobDescription />}
        {interviews && <ProInterviews />} 
        {interviewPreparation && <ProInterviewPrep />} 
        {offers && <ProOffers/>} 
        {contracts && <ProContracts/>} 
      </div>
    </div>
  );
};

export default JobsDescriptionBox;
