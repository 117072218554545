import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Link,
  Font,
} from "@react-pdf/renderer";

const PrepSheet = ({ docResponse }) => {
  // console.log("docResponse in hiring report", docResponse);

  Font.register({
    family: "Open Sans",
    fonts: [
      { src: "/assets/fonts/OpenSans-Regular.ttf", fontWeight: 600 },
      { src: "/assets/fonts/OpenSans-Bold.ttf", fontWeight: 800 },
      { src: "/assets/fonts/OpenSans-ExtraBold.ttf", fontWeight: 900 },
      { src: "/assets/fonts/OpenSans-SemiBold.ttf", fontWeight: 700 },
      { src: "/assets/fonts/OpenSans-Light.ttf", fontWeight: 400 },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topBar: {
      height: 6,
      backgroundColor: "#B2DA66",
      marginBottom: 10,
    },

    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 30,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 17,
      marginTop: 20,
    },
    rating: {
      width: 9,
      height: 9,
      display: "block",
      marginRight: 3,
    },
    heading: {
      display: "block",
      marginBottom: 10,
      fontSize: 24,
      fontWeight: 800,
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 14,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 800,
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
    },
    card: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#E6E6E6",
      marginTop: 5,
      marginBottom: 25,
    },
    cardTop: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#F5F5F5",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 12,
    },
    cardBottom: {
      fontFamily: "Open Sans",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 10,
      paddingBottom: 18,
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 18,
      minHeight: 18,
      width: 15,
      height: 15,
      marginRight: 5,
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      color: "#B2DA66",
      fontSize: 9,
      fontWeight: "600",
      display: "block",
      marginRight: 5,
    },
    list: {
      width: "80%",
      marginBottom: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    viewer: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    bulletDot: {
      color: "black",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginRight: 2,
      marginLeft: 3,
    },
  });
  return (
    <PDFViewer style={styles.viewer}>
      <Document style={styles.doc}>
        <Page style={styles.page}>
          <View>
            <View style={styles.topBar} />
            <View style={styles.container}>
              <Image
                src="/assets/svg/logo.png"
                style={styles.logo}
                alt="logo"
              />

              <Text style={styles.heading}>Your Interview Prep Sheet</Text>

              <View style={styles.card}>
                {/* card */}
                <View style={styles.cardTop}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: 3,
                    }}
                  >
                    <Image
                      src="/assets/images/Users/user1.png"
                      style={styles.avatar}
                    />
                    <Text
                      style={{
                        color: "#9e9e9e",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Company
                    </Text>
                  </View>
                  <Text style={styles.subTitle}>Job title</Text>
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                      marginTop: 1,
                    }}
                  >
                    A short description of the role
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      marginTop: 8,
                      width: "100%",
                      paddingBottom: 10,
                    }}
                  >
                    <View style={{ width: "33%" }}>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 10,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Name of CEO
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 2,
                        }}
                      >
                        <Image
                          src="/assets/images/star-image.png"
                          style={styles.rating}
                          alt="star"
                        />
                        <Image
                          src="/assets/images/star-image.png"
                          style={styles.rating}
                          alt="star"
                        />
                        <Image
                          src="/assets/images/star-image.png"
                          style={styles.rating}
                          alt="star"
                        />
                        <Image
                          src="/assets/images/star-image.png"
                          style={styles.rating}
                          alt="star"
                        />
                      </View>
                    </View>

                    <View style={{ width: "33%" }}>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 10,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Founded: Date
                      </Text>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 10,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Industry: Name
                      </Text>
                    </View>

                    <View style={{ width: "33%" }}>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 10,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                          textAlign: "start",
                        }}
                      >
                        High-level description of their service
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={styles.cardBottom}>
                  <Text
                    style={{
                      color: "#4A4A49",
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 800,
                      marginBottom: 5,
                    }}
                  >
                    REVIEWS
                  </Text>

                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <View style={{ width: "50%" }}>
                      <View style={styles.list}>
                        <View>
                          <Text style={styles.bullet}>{1}</Text>
                        </View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Review
                        </Text>
                      </View>
                      <View style={styles.list}>
                        <View>
                          <Text style={styles.bullet}>{2}</Text>
                        </View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Review
                        </Text>
                      </View>
                      <View style={styles.list}>
                        <View>
                          <Text style={styles.bullet}>{3}</Text>
                        </View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Review
                        </Text>
                      </View>
                    </View>
                    <View style={{ width: "50%" }}>
                      <View style={styles.list}>
                        <View>
                          <Text style={styles.bullet}>{4}</Text>
                        </View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Review
                        </Text>
                      </View>

                      <View style={styles.list}>
                        <View>
                          <Text style={styles.bullet}>{5}</Text>
                        </View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Review
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    color: "black",
                    fontSize: 14,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                    width: "45%",
                  }}
                >
                  Your Index & alignment score's in reltion to this positon:
                </Text>

                <View>
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                    }}
                  >
                    INDEXSCORE
                  </Text>
                  <Text
                    style={{
                      color: "#B2DA66",
                      fontSize: 22,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      marginTop: -5,
                    }}
                  >
                    789
                  </Text>
                </View>

                <View
                  style={{
                    marginRight: 20,
                  }}
                >
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                    }}
                  >
                    PURPOSE ALIGNMENT
                  </Text>
                  <Text
                    style={{
                      color: "#B2DA66",
                      fontSize: 22,
                      fontFamily: "Open Sans",
                      fontWeight: 400,
                      marginTop: -5,
                    }}
                  >
                    98%
                  </Text>
                </View>
              </View>

              {/* 1st */}
              <View
                style={{
                  borderBottom: "1.5px solid #B2DA66",
                  borderBottomStyle: "dotted",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />
              <View>
                <Text
                  style={{
                    color: "#0D2159",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    textTransform: "uppercase",
                    fontWeight: 900,
                    marginBottom: 6,
                  }}
                >
                  Core competencies
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{1}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Arrival and network with refreshments
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{2}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Arrival and network with refreshments
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{3}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Arrival and network with refreshments
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{4}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Arrival and network with refreshments
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{5}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        Arrival and network with refreshments
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              {/* 2nd */}
              <View
                style={{
                  borderBottom: "1.5px solid #B2DA66",
                  borderBottomStyle: "dotted",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />
              <View>
                <Text
                  style={{
                    color: "#0D2159",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    textTransform: "uppercase",
                    fontWeight: 900,
                    marginBottom: 6,
                  }}
                >
                  QUESTIONS YOU SHOULD ASK ABOUT THIS ROLE
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{1}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{2}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{3}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>

              {/* 3rd */}
              <View
                style={{
                  borderBottom: "1.5px solid #B2DA66",
                  borderBottomStyle: "dotted",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />
              <View>
                <Text
                  style={{
                    color: "#0D2159",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    textTransform: "uppercase",
                    fontWeight: 900,
                    marginBottom: 6,
                  }}
                >
                  COMMONLY ASKED QUESTIONS
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{1}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{2}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{3}</Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          Arrival and network with refreshments
                        </Text>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                          }}
                        >
                          <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                          <Text
                            style={{
                              color: "black",
                              fontSize: 8,
                              fontFamily: "Open Sans",
                              fontWeight: 600,
                            }}
                          >
                            Answer
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: "#B2DA66",
              padding: 13,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
              marginLeft: 40,
              marginRight: 40,
            }}
          >
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 9,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  textTransform: "uppercase",
                }}
              >
                Next Steps
              </Text>
              <Text
                style={{
                  fontSize: 9,
                  color: "#4A4A49",
                  fontWeight: 600,
                  fontFamily: "Open Sans",
                }}
              >
                Please contact{" "}
                <Text
                  style={{
                    color: "#4A4A49",
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                  }}
                >
                  IndexScore
                </Text>{" "}
                at{" "}
                <Link
                  src="help@IndexScore.com"
                  style={{
                    color: "#0D2159",
                    fontFamily: "Open Sans",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  help@IndexScore.com
                </Link>{" "}
                if you have any questions.
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

const InteriewPrepSheet = () => {
  return (
    <div className="fm">
      {/* <PDFDownloadLink
        className="fm text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center text-decoration-none"
        document={<PrepSheet />}
        fileName="Feedback.pdf"
      >
        Click
      </PDFDownloadLink> <br /> */}
      <PrepSheet />
    </div>
  );
};

export default InteriewPrepSheet;
