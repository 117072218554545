import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { Loader } from 'rsuite'
import { useSnackbar } from 'notistack'
import 'rsuite/dist/rsuite.min.css'
import { isEmail, isPassword } from '../../Utilities/utilities'
import { login, getProfessionalById, getMemberById, getEnterpriseByID, sendOTP } from "../../services/auth";
import { useNavigate } from 'react-router-dom'
import Footer from "../../components/Footer/Footer";
import { MyContext, useAuth } from '../../Utilities/MyContextProvider';


const Form = () => {
  const { clearContextData, setUserData, loginAction } = useContext(MyContext);
  const { dispatch } = useAuth();
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();

  const [state, setState] = useState({
    emailAddress: '',
    password: '',
    usertype: false,
    loading: false,
    required: false,
    rememberMe: false,
    invalidPassword: false,
  })
  const {
    loading,
    required,
    emailAddress,
    password,
    rememberMe
  } = state


  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleCheckboxChange = (e) => {
    handleChange('rememberMe', e.target.checked);
  }
  const disabled = !emailAddress || !password

  const handleSignin = async () => {
    try {
      if (disabled) {
        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        emailAddress: emailAddress,
        password,
      }
      const res = await login(payload)
      const dataToPass = res?.data?.data;

      if (res) {
        
        
        if (res.data.statusCode === 1) {
          
          localStorage.setItem('token', res.data.data.token);

          if (rememberMe) {
            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('emailAddress', emailAddress);
            localStorage.setItem('password', password);
          } else {
            localStorage.removeItem('rememberMe');
            localStorage.removeItem('emailAddress');
            localStorage.removeItem('password');
          }
          
          handleChange('loading', false)
          enqueueSnackbar('Login Successfully!', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          localStorage.removeItem("_id");
          localStorage.removeItem("enterpriseID");
          localStorage.removeItem("userProfileName");
          localStorage.removeItem("userEmail");
          clearContextData();


          
          if (res.data.data.usertype === 'enterprise') {            
            localStorage.setItem('enterpriseID', res.data.data.usertypeId);
            localStorage.setItem('_id', res.data.data._id);
            localStorage.setItem('userEmail', res.data.data.emailAddress);
            localStorage.setItem('userProfileName', res.data.data.firstName + " " + res.data.data.lastName);
            localStorage.setItem('userRole', res.data.data.role);
            const payLoadtoGetEnt = {
              "_id": res.data.data.usertypeId
            };
            const respEnterpriseByID = await getEnterpriseByID(payLoadtoGetEnt);
            const responseEnterpriseData = respEnterpriseByID.data.data;
            
            const user = responseEnterpriseData[0];
            localStorage.setItem('companyID', user.companyID);
            loginAction(user, 'enterprise',res.data.data.role);
            setUserData(responseEnterpriseData);
            navigate(`/enterprise/dashboard`);
          }
          else if (res.data.data.usertype === 'member') {

            if (res.data.data.role==='hr') {
              
              localStorage.setItem('enterpriseID', res.data.data.usertypeId);
              localStorage.setItem('_id', res.data.data._id);
              localStorage.setItem('userEmail', res.data.data.emailAddress);
              localStorage.setItem('userProfileName', res.data.data.firstName + " " + res.data.data.lastName);
              localStorage.setItem('userRole', res.data.data.role);
              var payLoadtoGetPro = {
                "_id": res.data.data.usertypeId
              };
              const respProfessionalByID = await getMemberById(payLoadtoGetPro);
              const responseProfessionalData = respProfessionalByID.data.data;
              
              const responseHr = responseProfessionalData.enterpriseData.subscription;
              responseProfessionalData.subscription = responseHr;
              const responseHrData = [responseProfessionalData];
              localStorage.setItem("memberData", JSON.stringify(responseHrData));
              localStorage.setItem('companyID', responseHrData[0].companyID);              
              const user = responseHrData[0];
              loginAction(user, 'member',res.data.data.role);
              setUserData(responseHrData);
              navigate(`/hr/dashboard`);

            }
            else {

              localStorage.setItem('enterpriseID', res.data.data.usertypeId);
              localStorage.setItem('_id', res.data.data._id);
              localStorage.setItem('userEmail', res.data.data.emailAddress);
              localStorage.setItem('userProfileName', res.data.data.firstName + " " + res.data.data.lastName);

              var payLoadtoGetPro = {
                "_id": res.data.data.usertypeId
              };
              const respProfessionalByID = await getMemberById(payLoadtoGetPro);
              const responseProfessionalData = respProfessionalByID.data.data;
              localStorage.setItem("memberData", JSON.stringify(responseProfessionalData));              
              const user = responseProfessionalData[0];
              loginAction(user, 'member');
              setUserData(responseProfessionalData);
              navigate(`/interviewer/dashboard/`);
            }

          }

          // otp code 
          // else {

          //   const otpPayload = {
          //     userId: res.data.data.usertypeId,
          //     contact: "+" + res.data.data.phoneNumber,
          //     usertype: res.data.data.usertype
          //   }
          //   const sendOtpCode = await sendOTP(otpPayload);
          //   debugger;


          //   if (sendOtpCode?.data?.status == "success") {
          //     handleChange('loading', false);
          //     enqueueSnackbar(`${sendOtpCode?.data?.message}`, {
          //       variant: 'success',
          //       anchorOrigin: {
          //         vertical: 'bottom',
          //         horizontal: 'right'
          //       }
          //     });
          //     navigate('/otp-verification', { state: { data: dataToPass } });
          //   }
          //   else {
          //     handleChange('loading', false);
          //     enqueueSnackbar(`Error`, {
          //       variant: 'error',
          //       anchorOrigin: {
          //         vertical: 'bottom',
          //         horizontal: 'right'
          //       }
          //     });
          //   }
          // }
          //otp code end

          else {
            localStorage.setItem('enterpriseID', res.data.data.usertypeId);
            localStorage.setItem('_id', res.data.data._id);
            localStorage.setItem('userEmail', res.data.data.emailAddress);
            localStorage.setItem('userProfileName', res.data.data.firstName + " " + res.data.data.lastName);
            var payLoadtoGetPro = {
              "_id": res.data.data.usertypeId
            };

            const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
            const responseProfessionalData = respProfessionalByID.data.data;
            localStorage.setItem("professionalData", JSON.stringify(respProfessionalByID.data.data));
            const user = responseProfessionalData[0];
            loginAction(user, 'professional');            
            navigate(`/candidate/dashboard`, { state: { responseProfessionalData } });

            setUserData(responseProfessionalData);

          }
        }
        else {
          handleChange('loading', false);
          enqueueSnackbar(`${res.response.data.statusDesc}`, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });

        }


      } else {
        handleChange('loading', false)
        enqueueSnackbar(`Error: ${res.response.data.statusDesc}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        })

      }
    } catch (error) {
      handleChange('loading', false)
      enqueueSnackbar(`Error: Invalid credentials or user does not exist.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }


  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const emailParam = urlSearchParams.get('emailAdress');
    
    if (emailParam) {
      const emailValue = emailParam.replace(/\+/g, '%2B');
      handleChange('emailAddress', emailValue)

    }

    if (localStorage.getItem('rememberMe') === 'true') {
      handleChange('emailAddress', localStorage.getItem('emailAddress') || '');
      handleChange('password', localStorage.getItem('password') || '');
      handleChange('rememberMe', true);
    }

    // Check if query parameters are present and update state accordingly

  }, []);

  return (
    <>
      <div className="signIn d-flex flex-column justify-content-between">
        <div className="pt-4 d-flex flex-column justify-content-center vh-80">
          <div className="container-lg h-100 px-0">
            <div className="Navbar__container d-flex mt-4 justify-content-center align-items-center h-100">
              <Link to={"/"} >
                <div className="Navbar__container__logo h-100 d-flex align-items-center">
                  <img
                    src={`/assets/images/navbar/logoWhite.svg`}
                    alt=""
                    className="Navbar__container__logo--img"
                  />
                </div>
              </Link>
            </div>
          </div>
          <div className="container-lg px-0 pt-5 h-100 mb-3">
            <div className="row h-100 align-items-center justify-content-center py-md-3 mt-xxl-3">
              <div className="col-lg-8 col-md-8 col-sm-9 col-12 mt-xxl-2 ">
                <h1 className="signIn__heading  mb-5 main-padding">
                  Welcome back to IndexScore!
                </h1>
                <form className="signIn__form main-padding">
                  <div className="form-group mt-3 mx-lg-5 mx-md-4 px-xxl-5 px-xl-4">
                    <input
                      type="email"
                      className=" form-control signIn__form--input py-2 px-3"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter your email address"
                      value={emailAddress}
                      onChange={value =>
                        handleChange('emailAddress', value.target.value)
                      }
                    />
                    {emailAddress && isEmail(emailAddress) && (
                      <div>
                        <small className="fm error__message" style={{ marginLeft: 16 }}> Email must be a valid email address !</small>
                      </div>
                    )}
                    {required && !emailAddress && (
                      <div>
                        <small className="fm error__message" style={{ marginLeft: 16 }}>Email Address is required !</small>
                      </div>
                    )}
                    <div className="form-group">
                      {/* <label for="exampleInputPassword1" className="pb-1">
                      Password
                    </label> */}
                      <input
                        type="password"
                        className="form-control  signIn__form--input mt-3 py-2 px-3"
                        id="exampleInputPassword1"
                        placeholder="*********"
                        value={password}
                        onChange={value =>
                          handleChange('password', value.target.value)
                        }
                      />
                      {password && isPassword(password) && (
                        <div className="ps-3 pt-1">
                          <small className="ms-0 error__message fm">
                            Must be at least 8 characters long and include upper and
                            lowercase letters and at least one number !
                          </small>
                        </div>
                      )}
                      {required && !password && (
                        <div className="ps-3 pt-1">
                          <small className="ms-0 error__message fm" >Password is required !</small>
                        </div>
                      )}
                    </div>
                    <div className="d-flex d-block justify-content-between pb-5 mt-3">
                      <div className="form-group form-check w-100 mb-0">
                        <input
                          type="checkbox"
                          className="form-check-input  signIn__form--input__checkBox"
                          id="exampleCheck1"
                          checked={rememberMe}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        <label
                          className="form-check-label fm signIn__label  signIn__form--input__checkBox--label ms-1 pointer"
                          for="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                      <Link
                        to={"/forgotten-password"}
                        className="signIn__form__forgot fm d-flex w-100 mt-1 justify-content-end text-decoration-none transition  text-decoration-none"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        className="signIn__form__button w-100 btn btn-lg d-flex justify-content-center align-items-center"
                        type="button"
                        onClick={() => handleSignin()}
                      >
                        {loading ? <Loader className="my-1" /> : 'Submit'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="contactform-border mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Form;
