import React from "react";
import "./style.css";

const faq = [
  {
    id: "one",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
  {
    id: "two",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
  {
    id: "three",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
  {
    id: "four",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
  {
    id: "five",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
  {
    id: "six",
    question: "So people are independently scored like a credit score?",
    answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta labore repellat a ea deleniti architecto laudantium, facere tenetur dolores, eaque dolorem alias exercitationem ipsum voluptas.",
  },
];

const WhyFaq = () => {
  return (
    <div className="Whyfaq main-padding">
      <div className="container-lg px-0 py-5 mb-3">
        <h1 className="fm darkGrey fw-normal fs-1">FAQ</h1>
        <div class="accordion" id="accordion">
          {faq.map((item)=>(
            <div class="accordion-item border-bottom" key={item.id}>
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed fm text-18 darkGrey fw-semibold pt-4 pb-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${item.id}`}
                aria-expanded="false"
                aria-controls={`#${item.id}`}
              >
                <p className="mb-0 me-4">{item.question}</p>
              </button>
            </h2>
            <div
              id={item.id}
              class="accordion-collapse collapse text-16"
              aria-labelledby={`heading${item.id}`}
              data-bs-parent="#accordion"
            >
              <div class="accordion-body fm text-18 darkGrey">
                {item.answer}
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyFaq;
