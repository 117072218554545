function GradientSVG() {
    const idCSS = "hello";
    const gradientTransform = `rotate(-25)`;
    return (
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id={idCSS} gradientTransform={gradientTransform}>
            <stop offset="5%" stopColor="#D0FB69" />
            <stop offset="60%" stopColor="#D0FB69" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
  
  export default GradientSVG;