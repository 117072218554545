import React from "react";

const Privacy = () => {
  return (
    <div className="Privacy ">
      <div className="py-sm-5">
      <div className="main-padding pt-5">
        <div className="container-xl px-0 py-5 mt-5 mb-3">
          <h1 className="fs-1 fm darkBlue">Privacy Policy</h1>
        </div>
      </div>
      </div>
      <div className="Privacy__bottom py-5">
        <div className="main-padding">
          <div className="container-lg px-0">
            <div className="Privacy__bottom__text">
              {/* <h4 className="fm blue fs-5">1. Lore Ipsum</h4> */}
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                This Privacy Policy governs the manner in which the Provider (
                “IndexScore Inc.,” “Us” Provider” or “we”) collects, uses,
                maintains, and discloses information (including personal
                information) collected from users (each, a "User" customer, or
                "you") of our website located at our website ("Website"), mobile
                applications and other services provided by us or any of its
                subsidiaries (collectively, “Services”).
              </p>

              <p className="fm darkGrey Privacy__para">
                You agree that IndexScore Inc. may use your data accordingly.
                IndexScore Inc. allows you to products (hereinafter referred to
                as "Services") via the platforms or the various apps for mobile
                devices. You can either place your order as a guest or register
                as a user and enjoy the benefits of a personal account and other
                services. We would like to detail the privacy policy as follows.
                IndexScore Inc. also uses the personal data for statistical and
                market analysis purposes. In an anonymous form, for example,
                statistics on customer groups, delivery areas, market areas are
                compiled and evaluated. For special services or actions (eg
                special actions), additional data protection information may
                apply. We will inform you about these at the beginning of the
                use process of the respective service or action.
              </p>

              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                Your use of this Website or any related Services, or otherwise
                providing us information, you agree to our collection, storage,
                use and disclosure of your personal information and other
                information as described in this Privacy Policy. If we are not
                able to collect, store, use, and disclose information about you
                as set out in this Privacy Policy, we may not be able to provide
                you with access to the Website or Services.
              </p>

              <h4 className="fm blue fs-5">Changes to this privacy policy</h4>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                WE HAVE THE DISCRETION TO UPDATE THIS PRIVACY POLICY AT ANY
                TIME. WHEN WE DO, WE WILL REVISE THE UPDATED DATE AT THE BOTTOM
                OF THIS PAGE. WE ENCOURAGE USERS TO FREQUENTLY CHECK THIS PAGE
                FOR ANY CHANGES TO STAY INFORMED ABOUT HOW WE ARE HELPING TO
                PROTECT THE PERSONAL INFORMATION WE COLLECT. YOU ACKNOWLEDGE AND
                AGREE THAT IT IS YOUR RESPONSIBILITY TO REVIEW THIS PRIVACY
                POLICY PERIODICALLY AND BECOME AWARE OF MODIFICATIONS.
              </p>

              <h4 className="fm blue fs-5">Non-personal information</h4>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                We may collect non-personal information about Users whenever
                they interact with our Website or Services. Non-personal
                information may include, but is not limited to, the browser
                name, the type of computer or device, and the technical
                information about Users’ means of connection to our Website or
                Services, such as the operating system, the Internet service
                providers utilized, mobile network information, and other
                similar information.
              </p>

              <h4 className="fm blue fs-5">Web browser cookies</h4>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                Our Website may use "cookies" to enhance User experience. Our
                web browsers keep cookies on Users hard drive for data-keeping
                only and sometimes to trace information about Users. However,
                you may choose to set your web browser not to accept cookies or
                to alert you whenever cookies are being sent. If you don’t do
                so, we note that some parts of the Website may not function
                properly.
              </p>

              <h4 className="fm blue fs-5">How we use information collected</h4>
              <p className="fm darkGrey Privacy__para">
                In general, the personal information we collect is used for the
                primary purposes for which it was collected, including:
              </p>
              <ol className="ms-0 pb-lg-5 pb-4" >
                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To deliver our Services to you
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use your personal data to send the deliverables and
                    any other goods, services, or data that you request from us
                    or any other user of our Website or Services.
                  </p>
                </li>
                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To prevent, detect, and mitigate illegal activities
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use the information you provided to us to prevent,
                    detect, and mitigate fraudulent, counterfeit, or illegal
                    activities.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To provide or improve customer service
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use the information you provided to us to enable us
                    respond to our clients’ service requests and support needs
                    more efficiently.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To personalize user experience
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use the information you provided to us in the
                    aggregate to understand how our Users as a group use our
                    Services and resources provided on our Website.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To improve our Website
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use the information you provided to us to get
                    feedback.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To run a promotion, contest, survey, or other Website
                    feature
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We may use the information you provided to us to transmit
                    information that Users of our services have agreed to
                    receive about topics we think will be of interest to them.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    To send periodic emails
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    We use the information you provided to us such as your email
                    addresses to send you information and updates to Clients’
                    related services. Your email address may also be used to
                    answer your inquiries, questions, and/or other requests. If
                    you agree to be on our mailing lists, you will receive
                    emails that may include company news, updates, related
                    product or service information, etc.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Unsubscribe/Opt-out
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    If at any time the User would like to unsubscribe from
                    receiving future promotion, content, survey, feature, or
                    other marketing information or emails, we include detailed
                    unsubscribe instructions at the bottom of each email. You
                    may not, however, unsubscribe from emails about your account
                    status or order status.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">Other uses</h4>
                  <p className="fm darkGrey Privacy__para mb-0">
                    We may also use your personal information for a secondary
                    purpose that is related to a purpose for which we collected
                    it, where you would reasonably expect us to use your
                    personal information for that secondary purpose.
                  </p>
                </li>
              </ol>

              <h4 className="fm blue fs-5">How we protect your information</h4>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                IndexScore Inc. uses proper data collection, storage and
                processing practices, and information protection measures that
                are designed to protect against unauthorized access, alteration,
                disclosure, or destruction of your private data, username,
                password, transaction information, and data stored on our
                Website or Services.
              </p>

              <h4 className="fm blue fs-5">
                Disclosure related to Our Terms of Condition Agreement
              </h4>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                IndexScore Inc. believes in good faith that a User has breached
                the Terms of condition or any other IndexScore Inc. agreement,
                we may disclose such User’s personal information as found
                necessary and relevant to pursuing a claim or preventing further
                injury to IndexScore Inc., its Users, and others. Disclosure may
                be made without notice to User.
              </p>

              <h4 className="fm blue fs-5">
                Third Party Websites and Advertising
              </h4>
              <ul className="ms-0 pb-lg-5 pb-4 bullets--blue">
                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold mb-0 fs-6">
                    Third party websites
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    Users of our website may find ads on our Website that
                    connects to the sites and services of our partners,
                    suppliers, advertisers, sponsors, licensors, and other third
                    parties. We do not control the content or links that appear
                    on these sites and are not responsible for the practices
                    employed by websites linked to or from our Website. In
                    addition, these sites or services, including their content
                    and links, may be constantly changing. These sites and
                    services may have their own privacy policies and customer
                    service policies. Browsing and interacting on any other
                    website, including websites which have a link to our
                    Website, is subject to that website's own terms and
                    policies. We are not a party to those agreements; they are
                    solely between you and the third party.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold mb-0 fs-6">Advertising</h4>
                  <p className="fm darkGrey Privacy__para">
                    Advertisements appearing on our Website may be delivered to
                    Users by advertising partners, who may set cookies. The
                    cookies we set may allow the advertising server to recognize
                    your computer each time you send an online advertisement to
                    compile non-personal information about you or others who use
                    your computer. This data allows advertising networks to,
                    among other things, deliver targeted advertisements that
                    they believe will be of most interest to you. This Privacy
                    Policy does not cover the use of cookies by any advertiser.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
