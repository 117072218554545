import React, { useEffect, useState, useContext } from "react";
// import ProInboxDesktop from "./ProInboxDesktop";
import ProInboxMobile from "./ProInboxMobile";
import EnterpriseChat from "./EnterpriseChat";
import HrChat from "./HrChat";
import { MyContext } from "../../../Utilities/MyContextProvider";
import "./style.css";

const ProInboxHr = () => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];


  return (
    <>
      <div className="Dashboard__container container-lg chat__page">
               
        <div className="d-md-block d-none">
        {userData?.role === 'admin' ? (
          <>
            <EnterpriseChat />
          </>
        ) : 
        <>
            <HrChat />
          </>
        } 
         </div>
        <div className="d-md-none d-block">
        <ProInboxMobile /> 
        </div>
      </div>
    </>
  );
};

export default ProInboxHr;
