import React from "react";
import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const cards = [
  {
    id: 1,
    title: "The basics",
    para: "a candidate’s experience, skills, qualifications, certifications, awards, publications and more",
  },
  {
    id: 2,
    title: "Fact-checking",
    para: "references and background checks to proactively mitigate potential timely onboarding risk",
  },
  {
    id: 3,
    title: "Social Media Footprint",
    para: "insights and visibility into online footprint risk exposure",
  },
  {
    id: 4,
    title: "Comprehensive Assessment",
    para: "leaning on psychometrics to find out individual ways of reasoning & collaborating",
  },
  {
    id: 5,
    title: "Purpose Alignment",
    para: "drawing on multi-data points to find out if their values align with your company’s",
  },
  {
    id: 6,
    title: "Humanizing AI",
    para: "hear directly from job candidates through purpose statement for a complete picture",
  },
];

const UHSection2 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="uhs2 uhs4">
      <div className="main-padding">
        <div className="container-xl px-0">
          <h6 className="fm text-18 darkGrey text-center">
            JUST SOME OF <strong>THE 600+ DATA POINTS</strong> WE PULL TOGETHER
            FOR A SCORE RELEVANT TO YOUR HIRING NEEDS
          </h6>

          <div className="row py-5 px-3">
            {cards.map((item) => (
              <div className="col-lg-4 col-sm-6 px-0" key={item.id}>
                <div
                  className={`uhs4__card${item.id} h-100 position-relative px-3 uhs4__card text-center d-flex flex-column align-items-center`}
                >
                  <p className="blue fm text-center mb-0 text-capitalize">
                    {item.title}
                  </p>
                  <p className="fm darkGrey text-center uhs4__card__para">
                    {item.para}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* section2 */}
        <div className="pb-4">
          <div className="d-flex flex-column container-xl align-items-center  pt-5 px-0">
            <p className="fm darkGrey fs-6 es1__text  text-center">
              INTEGRATES SEAMLESSLY WITH YOUR EXISTING ATS
            </p>
          </div>
          <div className="container-xl my-4">
            <Slider {...settings}>
              <div className="d-flex justify-content-center  align-items-center mt-lg-4 mt-3">
                <img
                  src="/assets/images/Launch/company1.svg"
                  alt=""
                  className="es1__companyimg1 me-3 mb-2"
                />
              </div>
              <div className="d-flex justify-content-center  align-items-center mt-lg-3 mt-2">
                <img
                  src="/assets/images/Launch/company2.svg"
                  alt=""
                  className="es1__companyimg2 me-3 mb-2"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/Launch/company3.svg"
                  alt=""
                  className="es1__companyimg3 me-3 mb-2"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <img
                  src="/assets/images/Launch/company4.svg"
                  alt=""
                  className="es1__companyimg4 me-3 mb-2"
                />
              </div>
              <div className="d-flex justify-content-center align-items-center mt-lg-4 mt-3">
                <img
                  src="/assets/images/Launch/company5.png"
                  alt=""
                  className="es1__companyimg5 mb-2"
                />
              </div>
            </Slider>
          </div>
          <div className="container-xl px-0 d-flex justify-content-center mt-5">
            <Link to={"/about-us"} className="text-decoration-none">
              <button className="btn--blue text-white fm rounded-2">
                Find out more
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UHSection2;
