import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const HiringReport = () => {
    const styles = StyleSheet.create({
        topBar: {
            display: "block",
            height: 10,
            backgroundColor: '#B2DA66',
            marginBottom: 10,
            width: '100%',
        },
        page: {
            width: '100%',
            display: 'block',
            
        },
        doc: {
            width: '100%',
            display: 'block'
        },
        section: {
            textAlign: 'center',
            margin: 30
        },
        logo: {
            width: 300,
            display: 'block',
            marginBottom: 20
        },
        heading: {
            display: 'block',
            marginBottom: 5,
            fontSize: "40px",
            
            fontWeight: "600",
            color: "#0D2159",
            '@media max-width: 1200': {
                fontSize: "35px",
            },
        },
        subTitle: {
            display: 'block',
            fontSize: "16px",
            
            fontWeight: "600",
            color: "#9D9EA2"
        },
        container: {
            maxWidth: 1200,
            width: "100%",
            paddingLeft: "15px",
            paddingRight: "15px",
            margin: "auto",
            display: "block"
        },
        card: {
            border: "1px solid #B6B7BA",
            marginRight: "20px",
            display: "flex",
            fontSize: "16px",
            
            padding: "15px 10px",
            width: "50%",
            borderRadius: "5px"
        },
        avatar: {
            border: "1px solid #9D9EA2",
            borderRadius: "50%",
            minWidth: 50,
            minHeight: 50,
            width: 50,
            height: 50
        },
        progressGrey: {
            position: "relative",
            marginTop: "2px",
            marginBottom: "12px",
            borderRadius: "25px",
            height: "12px",
            backgroundColor: "#E3E3E3",
            display: "block",
            width: "100%"
        },
        progressFill: {
            position: "absolute",
            left: 0,
            top: 0,
            borderRadius: "25px",
            height: "12px",
            backgroundColor: "#B2DA66",
            display: "block",
            width: "95%"
        },
        tinyTitle: {
            display: "block",
            
            fontSize: "10px",
            color: "#CBCBCB",
            fontWeight: "light"
        },
        date: {
            display: "block",
            
            fontSize: "24px",
            color: "#797978",
            fontWeight: "normal",
            lineHeight: "24px"
        },
        cardBottom: {
            display: "flex",
            justifyContent: "space-between",
            textTransform: "uppercase",
            width: "100%",
            paddingRight: "40px"
        },
        cardOuter: {
            display: "flex",
            width: "100%",
            marginTop: 30
        },
        header: {
            backgroundColor: "#F5F5F5", 
            borderRadius: "50px", 
            display: "flex", 
            paddingLeft: "15px", 
            paddingRight: "15px", 
            paddingBottom: "5px", 
            paddingTop: "5px",
            justifyContent: "space-between",
            marginTop: "30px"
        },
        bullet: {
            display: "inline-block" ,
            color: "#B2DA66", 
            width: "25px", 
            fontWeight: "400"
        },
        star: {
            display: "inline-block" ,
            color: "#B2DA66", 
            width: "25px", 
            fontWeight: "400",
            fontSize:'25px'
        },
        question: { 
            color: "#4A4A49", 
            fontSize: "16px", 
            display: "block", 
            paddingBottom: "6px"
        },
        boldTitleDarkGrey: {
            fontWeight: "800", 
            color: "#4A4A49", 
            textTransform: "uppercase", 
            fontSize: "16px", 
            display: "block"
        }
    });
    return (
        <Document style={styles.doc}>
            <Page style={styles.page}>
                <View style={styles.topBar} />
                <View style={styles.container}>
                    <Image src="/assets/svg/logo.png" style={styles.logo} />

                    <Text style={styles.heading}>Hiring Recommendation Report</Text>
                    <Text style={styles.subTitle}>Tuesday 6 June, <Text style={{ color: "#CBCBCB", fontWeight: "normal" }}>18:00 - 18:30</Text> </Text>
                    <Text style={styles.subTitle}>Interview Type, <Text style={{ color: "#CBCBCB", fontWeight: "normal" }}>Web (Zoom)</Text></Text>

                    <View style={styles.cardOuter}>

                        {/* card */}
                        <View style={styles.card}>
                            <Image src="/assets/images/Users/user1.png" style={styles.avatar} />
                            <View style={{ display: "block", marginLeft: "12px", width: "100%" }}>
                                <Text style={{ fontSize: "18px",  color: "#4A4A49", fontWeight: 600, }}>Ben Smith <Text style={{ fontWeight: "normal", color: "#797978" }}>(He/Him)</Text></Text>
                                <View style={styles.progressGrey}><View style={styles.progressFill}></View></View>

                                <View style={styles.cardBottom}>
                                    <View style={{ display: "inline-block" }}>
                                        <Text style={styles.tinyTitle}>APPLICATION DATE</Text>
                                        <Text style={styles.date}>28/02/2023</Text>
                                    </View>

                                    <View style={{ display: "inline-block" }}>
                                        <Text style={styles.tinyTitle}>INDEXSCORE</Text>
                                        <Text style={styles.date}>789</Text>
                                    </View>

                                    <View style={{ display: "inline-block" }}>
                                        <Text style={styles.tinyTitle}>PURPOSE ALIGNMENT</Text>
                                        <Text style={styles.date}>95%</Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View style={{ display: "block" }}>
                            <Text style={{ textTransform: "uppercase", fontWeight: 800, fontSize: "20px", color: "#4A4A49", display: "block", paddingBottom: "10px", lineHeight: "14px" }}>Candidate Summary</Text>
                            <Text style={{ display: "flex", color: "#4A4A49", fontWeight: "light" }}>Sponsorphip: <Text style={{ fontWeight: "600", paddingLeft: "5px" }}>Yes</Text></Text>
                            <Text style={{ display: "flex", color: "#4A4A49", fontWeight: "light", paddingTop: "2px", paddingBottom: "2px" }}>Hiring Recommendation: <Text style={{ fontWeight: "600", paddingLeft: "5px" }}>Yes</Text></Text>
                            <Text style={{ display: "flex", color: "#4A4A49", fontWeight: "light" }}>Interview Rating: <Text style={{ fontWeight: "600", paddingLeft: "5px" }}>5 stars image</Text></Text>
                        </View>
                    </View>


                    {/* dotted border */}
                    <View style={{ borderBottom: "2px solid #B2DA66", width: "100%", display: "block", marginTop: "60px", marginBottom: "30px", borderBottomStyle: "dotted" }} />


                     {/* section2 */}
                    <Text style={{ textTransform: "uppercase", fontWeight: 800, fontSize: "20px", color: "#0D2159", display: "block", paddingBottom: "10px", lineHeight: "14px" }}>Notes Summary</Text>
                    <View style={{ display: "flex", width: "100%", marginBottom: "80px" }}>
                        <View style={{ width: "50%", display: "block" }}>
                            <Text style={styles.question}><View style={styles.bullet}>1</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>2</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>3</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>4</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>5</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>6</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>7</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>8</View>Arrival and network with refreshments</Text>
                        </View>
                        <View style={{ width: "50%", display: "block" }}>
                            <Text style={styles.question}><View style={styles.bullet}>9</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>10</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>11</View>Arrival and network with refreshments</Text>
                            <Text style={styles.question}><View style={styles.bullet}>12</View>Arrival and network with refreshments</Text>
                        </View>
                    </View>

                    {/* section3 */}
                    <View style={styles.header}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Ben Smith</Text>
                        <View style={{width: "40%", display: "flex", justifyContent: "space-between"}}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Hire</Text>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Rating</Text>
                        </View>
                    </View>
                    <View style={{display: "flex", width: "100%", marginTop: "5px"}}>
                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Question Answers</Text>
                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>Would you recommend to be a part of our organization?:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Yes</Text>

                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>If Yes:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Experience</Text>
                        </View>

                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Notes Summary</Text>
                            <Text style={styles.question}><View style={styles.bullet}>1</View>Answer to questionnaire, Interview Notes</Text>
                        </View>
                        
                    </View>

                    <View style={styles.header}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Stephen</Text>
                        <View style={{width: "40%", display: "flex", justifyContent: "space-between"}}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Hire</Text>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Rating</Text>
                        </View>
                    </View>
                    <View style={{display: "flex", width: "100%", marginTop: "5px"}}>
                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Question Answers</Text>
                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>Would you recommend to be a part of our organization?:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Yes</Text>

                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>If Yes:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Experience</Text>
                        </View>

                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Notes Summary</Text>
                            <Text style={styles.question}><View style={styles.bullet}>1</View>Answer to questionnaire, Interview Notes</Text>
                        </View>
                        
                    </View>

                    <View style={styles.header}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Maryam</Text>
                        <View style={{width: "40%", display: "flex", justifyContent: "space-between"}}>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Hire</Text>
                        <Text style={{color: "#4A4A49", fontWeight: "600"}}>Rating</Text>
                        </View>
                    </View>
                    <View style={{display: "flex", width: "100%", marginTop: "5px"}}>
                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Question Answers</Text>
                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>Would you recommend to be a part of our organization?:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Yes</Text>

                            <Text style={{fontWeight: "400", color: "#9D9EA2", display: "block"}}>If Yes:</Text>
                            <Text style={{fontWeight: "600", color: "#0D2159", display: "block", paddingBottom: "20px"}}>Experience</Text>
                        </View>

                        <View style={{width: "50%", display: "block"}}>
                            <Text style={styles.boldTitleDarkGrey}>Notes Summary</Text>
                            <Text style={styles.question}><View style={styles.bullet}>1</View>Answer to questionnaire, Interview Notes</Text>
                        </View>
                        
                    </View>

                </View>
            </Page>
        </Document>
    )
}

export default HiringReport