import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./style.css";

const Navbar = (props) => {
  const [scroll, setScroll] = useState(false);
  const [nav, setNav] = useState(false);
  const [dnav, setDnav] = useState(false);
  const [color, setColor] = useState(props.color);
  const [logo, setLogo] = useState(props.logo);

  function changeNav() {
    setNav(!nav);
  }

  function changeBackground(scroll) {
    if (window.scrollY >= 35) {
      setScroll(false);
      // setColor("white");
      // setLogo("Main");
    } else {
      setScroll(false);
      if (nav || dnav) {
        setColor("white");
        setLogo("Main");
      }
      else if (!nav || !dnav) {
        setColor(props.color);
        setLogo(props.logo);
      }
    }
  }
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <>
      <div
        className="Navbar d-lg-none main-padding"
        style={{
          boxShadow: scroll && !dnav
            ? "rgb(56 56 57 / 30%) 0px 15px 43px"
            : "0px 0px 0px #33333d",
          backgroundColor: scroll || nav ? "rgb(0,0,0)" : "rgba(0,0,0,0)",
          borderBottom: nav ? "1px solid #494F4F" : "0px",
          position: dnav ? "fixed" : "absolute",
        }}
      >
        <div className="container-lg h-100 px-0">
          <div className="Navbar__container d-flex justify-content-between align-items-center h-100">
            <Link to={"/"} className="w-100">
              <div className="Navbar__container__logo h-100 d-flex align-items-center">
                <img
                  src={`/assets/images/navbar/logo${logo}.svg`}
                  alt=""
                  className="Navbar__container__logo--img"
                  style={{transition: "all 0.1s ease-in-out"}}
                />
              </div>
            </Link>
            <div
              className="Border h-100 me-2"
              style={{
                borderRight: nav ? "1px solid #494F4F" : "0px",
              }}
            ></div>
            <div className="Navbar__container__menu d-sm-flex align-items-center d-none">
              <div className="ms-5  d-block">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  className="check"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={()=>setDnav(!dnav)}
                />
                <div className="ham-menu" id="ham-menu">
                  <span className={`line line1 line--${color}`}></span>
                  <span className={`line line2 line--${color}`}></span>
                  <span className={`line line3 line--${color}`}></span>
                </div>
              </div>
            </div>
            <div className="Navbar__container__menu d-sm-none d-flex align-items-center">
              <div className="ms-5  d-block">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  className="check"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasRight"
                  onClick={changeNav}
                />
                <div className="ham-menu" id="ham-menu">
                  <span className={`line line1 line--${color}`}></span>
                  <span className={`line line2 line--${color}`}></span>
                  <span className={`line line3 line--${color}`}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
