import React from 'react'
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from '../components/Footer/Footer.jsx';
import Privacy from '../pageComponents/PrivacyPolicy/Privacy.jsx';
import Terms from '../pageComponents/Terms/Terms.jsx';
import UpdatedNav from '../UpdatedFrontend/Navbar/UpdatedNav.jsx';

const TermsOfUse = () => {
  return (
    <>
    <NavbarDemo logo="MainColor" color="blue"  />
    <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <Terms />
      <Footer />
    </>
  )
}

export default TermsOfUse