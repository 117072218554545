import React from "react";
import "./style.css";

const WhySection2 = () => {
  return (
    <div className="Whysection2 main-padding">
      <div className="container-lg px-0 py-lg-5 py-4">
        {/* row1 */}
        <div className="Whysection2__row d-flex align-items-center justify-content-between flex-md-nowrap flex-wrap mb-5 mt-5">
          <img
            src="./assets/images/WhyIndexcore/Whyindexcore_1.png"
            alt=""
            className="Whysection2__row--img mb-md-0 mb-4"
          />
          <div className="Whysection2__row__content ps-md-4">
            <p className="fm darkBlue text-18 fw-semibold mb-2">
              Score your role with one simple score
            </p>
            <p className="fm darkGrey text-18 Whysection2__row__content__para mb-0">
              All candidates are pre-scored based on IndexScores AI using all
              information they have provided us, we select and curate these
              users that best fit for your advertised role based on work
              history, referenced and integrated Criteria psychometric tests +
              other categories each candidate fills out to give one overall
              score out of 800.
            </p>
          </div>
        </div>

        {/* row2 */}
        <div className="Whysection2__row d-flex align-items-center flex-md-row-reverse flex-md-nowrap flex-wrap justify-content-between mb-5">
          <img
            src="./assets/images/WhyIndexcore/Whyindexcore_2.png"
            alt=""
            className="Whysection2__row--img mb-md-0 mb-4"
          />
          <div className="Whysection2__row__content pe-md-4">
            <p className="fm darkBlue text-18 fw-semibold mb-2">
              Score your role with one simple score
            </p>
            <p className="fm darkGrey text-18 Whysection2__row__content__para mb-0">
              All candidates are pre-scored based on IndexScores AI using all
              information they have provided us, we select and curate these
              users that best fit for your advertised role based on work
              history, referenced and integrated Criteria psychometric tests +
              other categories each candidate fills out to give one overall
              score out of 800.
            </p>
          </div>
        </div>

        {/* row3 */}
        <div className="Whysection2__row d-flex align-items-center flex-md-nowrap flex-wrap justify-content-between mb-5">
          <img
            src="./assets/images/WhyIndexcore/Whyindexcore_3.png"
            alt=""
            className="Whysection2__row--img mb-md-0 mb-4"
          />
          <div className="Whysection2__row__content ps-md-4">
            <p className="fm darkBlue text-18 fw-semibold mb-2">
              Simplify your hiring process
            </p>
            <p className="fm darkGrey text-18 Whysection2__row__content__para mb-0">
              We make creating new job positions as streamlined as possible to
              speed up your hiring process. We then curate each candidate that
              has applied based on your perimeters and job and define a score
              for each candidate so you can see the best fits at a glance.
            </p>
          </div>
        </div>

        {/* row4 */}
        <div className="Whysection2__row d-flex align-items-center flex-md-row-reverse flex-md-nowrap flex-wrap justify-content-between mb-5">
          <img
            src="./assets/images/WhyIndexcore/Whyindexcore_4.png"
            alt=""
            className="Whysection2__row--img mb-md-0 mb-4"
          />
          <div className="Whysection2__row__content pe-md-4">
            <p className="fm darkBlue text-18 fw-semibold mb-2">
              Diversity Equality & Inclusion
            </p>
            <p className="fm darkGrey text-18 Whysection2__row__content__para mb-0">
            IndexScore gives you greater DEI as we remove any unconscious bias
              from the first stage of hiring. Each candidate is blind scored –
              perfect for ESG and DEI – to allow true comparison of candidates.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySection2;
