import React, {useEffect} from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
// import GetStarted from "../components/GetStarted/GetStarted";
import SignupAsEnterprise from "../Launch/Signup/SignupAsEnterprise.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const EnterpriseGetStarted = () => {
  useEffect(() => {
    document.body.classList.add('signup')
    return () => {
      document.body.classList.remove('signup')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      {/* <GetStarted
        title="Enterprise"
        para1="Get an IndexScore account and cut your recruitment time by up to 95%!"
        para2="Our process is simple; just complete this quick form to get started. You'll receive a confirmation email via your corporate email address to complete your account set up process."
        color="green"
        page="/enterprise/signup/details"
        review="The IndexScore team is truly amazing; they are outside the box thinkers and challenging
        the status quo. I realized within the first 15 minutes of the first demo, that their
        platform is unique – they are truly changing our industry"
        name="Lauren M."
        position="Talent Acquisition Director"
        org ="Global Consulting Organization"
      /> */}
      <SignupAsEnterprise />
      <Footer />
    </>
  );
};

export default EnterpriseGetStarted;
