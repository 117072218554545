import React, { useState,useEffect } from "react";
import "./style.css";

import ProJobs from "./Jobs";
import ProOffers from "../Contracts/Offers";
import ProInterviews from "../Contracts/Interviews";
import ProInterviewPrep from "../Contracts/InterviewPrep";
import ProContracts from "../Contracts/Contracts";
import { useNavigate } from "react-router-dom";

const JobsBox = (props) => {
  console.log("props.name",props);
  const navigate = useNavigate();
  const [jobstab, setJobstab] = useState(true);
  const [interviews, setInterviews] = useState(false);
  const [offers, setOffers] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [interviewPreparation, setInterviewPreparation] = useState(false);
  
  const func_jobstab = () =>{
    setJobstab(true)
    setInterviews(false)
    setOffers(false)    
    setContracts(false);
    setInterviewPreparation(false);
    navigate("/candidate/dashboard/jobs");
  }
  const func_interviews = () =>{
    setJobstab(false)
    setInterviews(true)
    setOffers(false)    
    setContracts(false);
    setInterviewPreparation(false);
    navigate("/candidate/dashboard/interviews");
  }
  const func_offers = () =>{
    setJobstab(false)
    setInterviews(false)
    setOffers(true)    
    setContracts(false);
    setInterviewPreparation(false);    
    navigate("/candidate/dashboard/offers");
  }
  const func_contracts = () =>{
    setJobstab(false)
    setInterviews(false)
    setOffers(false)  
    setContracts(true);  
    setInterviewPreparation(false);
    navigate("/candidate/dashboard/contracts");
  }

  const func_interviewPreparation = () =>{
    setJobstab(false)
    setInterviews(false)
    setOffers(false)  
    setContracts(false);  
    setInterviewPreparation(true);
    navigate("/candidate/dashboard/interview-preparation");
  }

  useEffect(() => {
    if (props.name === "offers") {
      func_offers();
    } else if (props.name === "interviews") {
      func_interviews();
    }
    else if (props.name === "contracts") {
      func_contracts();
    }
    else if (props.name === "interviewPreparation") {
      func_interviewPreparation();
    }
  }, [props.name]);


  return (
    <div className="DAccount">
      <div className="DAccount__top d-lg-block d-none mt-lg-0 mt-4">
        <div className="container-dash py-2">
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${jobstab  ? "fw-normal": "fw-light"}`} onClick={func_jobstab}>Open Roles</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${interviews ? "fw-normal": "fw-light"}`} onClick={func_interviews}>Interviews</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${interviewPreparation ? "fw-normal": "fw-light"}`} onClick={func_interviewPreparation}>Interview Prep</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${offers ? "fw-normal": "fw-light"}`} onClick={func_offers}>Offers</button>        
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${contracts ? "fw-normal": "fw-light"}`} onClick={func_contracts}>Contracts</button>        
        </div>
      </div>
      <div className="container-dash">
        {jobstab && <ProJobs />}
        
        {/* {jobstab && <YDetail />} */}
        {interviews && <ProInterviews />} 
        {interviewPreparation && <ProInterviewPrep />} 
        {offers && <ProOffers/>} 
        {contracts && <ProContracts/>} 
        {/* {(api || props.name=='api') && <API />}  */}
      </div>
    </div>
  );
};

export default JobsBox;
