import React from "react";

const Terms = () => {
  return (
    <div className="Privacy">
      <div className="Privacy__top py-sm-5">
        <div className="main-padding pt-5">
          <div className="container-xl px-0 py-5 mt-5 mb-3">
            <h1 className="fs-1 fm darkBlue">Terms & Conditions</h1>
            {/* <p className="fm darkGrey Privacy__para">Blurb goes here…</p> */}
          </div>
        </div>
      </div>
      <div className="Privacy__bottom py-5">
        <div className="main-padding">
          <div className="container-lg px-0">
            <div className="Privacy__bottom__text">
              <p className="fm darkGrey Privacy__para">
                This Terms and Conditions clearly define how we, IndexScore
                Inc., uses, stores, obtains, and use your personal information
                when you use or interact with our website, This Terms and
                Condition is effective 01 day of November, 2023.
              </p>
              <p className="fm darkGrey Privacy__para">
                Please read this Terms and Condition carefully. We recommend
                that you print a copy of this Terms and Condition and any
                applicable future versions from time to time for your records
              </p>
              <p className="fm darkGrey Privacy__para pb-lg-5 pb-4">
                These Terms and Conditions must be read in conjunction with the
                corresponding Privacy Policy.
              </p>

              <ol className="ms-0 pb-lg-5 pb-4">
                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold mb-0 fs-6">
                    Our Services
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    IndexScore Inc. is an AI-driven platform designed to help
                    organizations recruit job candidates based on candidate
                    scoring system. IndexScore Inc., also helps job seekers
                    build profiles and based on candidate’s qualifications is
                    able secure a job opportunity with a single click.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">Eligibility</h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        For you to be eligible to use our services or access the
                        contents on this website, you must be 18 years or older
                        to use or access the Service. But if you are below the
                        age of 18 and wish to use the Service you must submit to
                        IndexScore Inc. in writing consent to do so by a parent
                        or guardian and IndexScore Inc. must approve your use of
                        the Service in writing.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        The Service may not be available to any Users previously
                        removed from the Service by IndexScore Inc.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You further represent and warrant that you are under no
                        legal disability or contractual restriction that
                        prevents you from entering into this Agreement.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Rules you must Obey
                  </h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        If you find something that violates our rules, please
                        let us know, and we’ll review it.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Your purpose of accessing this website is to use the
                        information obtain as any market research for a
                        competing business;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You will not disassemble any software or processes
                        accessible through our Platform;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You will comply with all applicable laws in your use of
                        the Platform and will not use the Platform for any
                        unlawful purpose;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You agree not to interrupt the proper operation of the
                        Platform by the application of any virus, device, data
                        collection or transmission mechanism, software or
                        routine, or access or attempt to gain control to any
                        Content, information, files, or passwords related to
                        IndexScore Inc. through hacking, password or information
                        mining, or through other means;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You agree not to block, or in any way disturb with any
                        advertisements and/or safety features on our Platform;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You will not use any robot, scraper, or other automated
                        means to gain access to our website and Platform for any
                        purpose without IndexScore Inc. express written
                        permission; provided, however, we grant the operators of
                        public search engines permission to use spiders to copy
                        materials from the public portions of our website for
                        the sole purpose of and solely to the extent necessary
                        for creating publicly-available searchable indices of
                        the materials, but not caches or archives of such
                        materials.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">Your Accounts</h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        To access our service, you may need to create IndexScore
                        Inc. account on our Website. This account gives you
                        access to the features and functionality of the relevant
                        Service that we may establish and maintain from time to
                        time and in our sole discretion.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        We may maintain different types of accounts for
                        different types of Users.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        If you open an account on the websites on behalf of a
                        company, organization, or other entity, then (a) “you”
                        include you and that entity, and (b) you represent and
                        warrant that you are an authorized representative of the
                        entity with the authority to bind the entity to this
                        Agreement, and that you agree to this Agreement on the
                        entity’s behalf.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        To the extent that you connect to our websites via a
                        third-party service (for example through LinkedIn), you
                        give us permission to access and use your information
                        from that service as permitted by that service, and to
                        store your log-in credentials and information for that
                        service. You must only use third party service accounts
                        owned by you, and not by any other person or entity.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You may never use another User’s account without
                        permission.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        When creating your account, you must provide accurate
                        and complete information.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You are solely responsible for the activity that occurs
                        on your account, and you must keep your account password
                        secure. We encourage you to use “strong” passwords with
                        your account.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You may not allow any other person to access or use the
                        Service with your unique username, password, or other
                        security code. You must notify IndexScore Inc.
                        immediately of any breach of security or unauthorized
                        use of your account. IndexScore Inc. will not be liable
                        for any losses caused by any unauthorized use of your
                        account.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. reserves the right to terminate accounts
                        that are inactive for an extended period of time, as we
                        reasonably determine, with or without notice.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. may permanently or temporarily terminate
                        or suspend your access to the Service without notice and
                        liability for any reason, including if in our sole
                        determination you violate any provision of these Terms
                        and Conditions, or for no reason.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        If your account is terminated for any reason, you must
                        obtain written authorization from IndexScore Inc. prior
                        to establishing another account. If you attempt to
                        establish another account without obtaining such
                        authorization, IndexScore Inc. may permanently ban you
                        from the Service.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        A person may not have more than one active User account
                        at any time without the written consent of IndexScore
                        Inc. in each instance.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You are solely responsible for your interactions with
                        other IndexScore Inc. Users. IndexScore Inc. reserves
                        the right, but have no obligation, to monitor disputes
                        between you and other Users. IndexScore Inc. will not
                        have any liability for your interactions with other
                        Users, or for any User’s action or inaction.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    General Obligations of Use
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    Users are not permitted to engage in any of the following
                    prohibited activities:
                  </p>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        hacking to the Service or any content other than your
                        own without the prior written consent of IndexScore
                        Inc.;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        attempting to compromise the system integrity or
                        security or decipher any transmissions to or from the
                        servers running the Service;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        copying, distributing, or disclosing any part of the
                        Service in any medium, including without limitation by
                        any automated or non-automated “scraping
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        using any automated system, including without limitation
                        “robots,’ “spiders,” etc., to access the Service;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        transmitting spam, chain letters, or other unsolicited
                        email;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        taking any action that imposes, or may impose at our
                        sole discretion an unreasonable or disproportionately
                        large load on our infrastructure;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        uploading invalid data, viruses, worms, trojans or other
                        malicious software through the Service;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        accessing any content on the Service through any
                        technology or means other than those provided or
                        authorized by the Service;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        collecting or harvesting any personally identifiable
                        information, including account names, email addresses,
                        from the Service, accessing without authority,
                        interfering with, damaging or disrupting any part of the
                        Web sites;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        bypassing the measures, we may use to prevent or
                        restrict access to the Service, including without
                        limitation features that prevent or restrict use or
                        copying of any content or enforce initiations use of the
                        Service or the content therein;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        using the Service to support, incite or promote
                        discrimination, hostility or violence;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        using any IndexScore Inc. trademark or any variant
                        thereof including misspellings as a domain name or as
                        part of a domain name, as a metatag, keyword, or any
                        other type of programming code or data;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        adopting or using, without our prior written consent,
                        any word or mark which is similar to or likely to be
                        confused with IndexScore Inc. trademarks,
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        hacking to the Service or any content other than your
                        own without the prior written consent of IndexScore
                        Inc.,
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        making derivative works based on the Websites;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        removing or altering any copyright, trade mark, logo or
                        other proprietary notice or label appearing on the
                        Websites, or materials provided on the Websites;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        impersonating another person or otherwise
                        misrepresenting your affiliation with a person or
                        entity, conducting fraud, hiding or attempting to hide
                        your identity
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        interfering with the proper working of the Platform;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        copying, imitating or using, in whole or in part, the
                        look and feel of the Service (including but not limited
                        to all page headers, custom graphics, button icons, and
                        scripts) without the prior written consent of IndexScore
                        Inc.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Availability and Warranties
                  </h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. has the sole discretion modify, review,
                        and update terms and conditions including its Services
                        (or any part thereof, including features or
                        functionality) with or without notice.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        By using our website, you accept that we will not be
                        liable to any third party, including you, should we
                        decide to modify, suspend or discontinuance the
                        Services.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. does not warrant that our website or
                        platform will be always compatible with all hardware and
                        software which you may use.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. Warrants that it will not be responsible
                        for any damage, or viruses or other code that may
                        affect, any equipment (including but not limited to your
                        mobile device), software, data or other property as a
                        result of your access to or use of the Service or your
                        obtaining any material from, or as a result of using,
                        the Service.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        We will not be held liable for the actions of third
                        parties.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">Storage</h4>
                  <p className="fm darkGrey Privacy__para">
                    IndexScore Inc. does not store your information other than
                    to process our website use requests, however, should we
                    store your information for whatever reason, we may, with or
                    without prior notice, set or change the maximum period of
                    time that documents, data or content will be retained by the
                    Service and the maximum storage space that will be allotted
                    on IndexScore Inc.’ servers or systems on your behalf. You
                    hereby agree that IndexScore Inc. has no liability for the
                    deletion or failure to store any documents, data or other
                    content maintained or uploaded in our Platform.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Serviceing and Delivery
                  </h4>
                  <p className="fm darkGrey Privacy__para">
                    You may place an service for particular Services through the
                    Websites, by logging into your registered account.
                  </p>
                  <p className="fm darkGrey Privacy__para">
                    We will deliver your services at the agreed date after full
                    payment of the agreed about.
                  </p>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">Fees</h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        The Services are offered at the prices and fees set
                        forth on the Websites (and customer portals accessible
                        through the Website) or as otherwise notified by us to
                        you, and as those prices and fees are amended by us from
                        time to time. By requesting Services, you agree to pay
                        the applicable prices and fees for those Services.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        The prices and fees are exclusive of any applicable
                        taxes.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You will pay any applicable taxes, if any, relating to
                        any such purchases, licenses, royalties, transactions,
                        or other monetary transaction interactions in connection
                        with the Services.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Payment Terms and Payment Method
                  </h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You may pay your service by a valid credit card, or by
                        any other method that IndexScore Inc. may determine from
                        time to time.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. accepts payment by Visa, Mastercard and
                        American Express, but IndexScore Inc. may change the
                        cards that we accept from time to time.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Rejection and Cancellation by IndexScore Inc.
                  </h4>
                  <ol type="1">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        In addition to any Client General Service Agreement, if
                        an service for Services has been placed by you with us,
                        we may in our absolute discretion reject an service (or
                        any part thereof) where:
                      </p>
                      <ol type="i">
                        <li>
                          <p className="fm darkGrey Privacy__para">
                            Client has violated the Terms and Conditions or any
                            other subsidiary agreement thereto.
                          </p>
                        </li>
                        <li>
                          <p className="fm darkGrey Privacy__para">
                            IndexScore Inc. has not received payment for
                            Services previously provided to you: or
                          </p>
                        </li>
                        <li>
                          <p className="fm darkGrey Privacy__para">
                            In the event of a “Force Majeure Event" (for the
                            purpose of this terms, Force Majeure means, any
                            event beyond the reasonable control of IndexScore
                            Inc., in consequence of which we cannot reasonably
                            be required to perform obligations under this
                            Agreement including but not limited to, fire,
                            outbreaks of war, pandemics, epidemics, acts of
                            hostility, terrorist activity, national emergency,
                            government action, or any act of God, where any of
                            our employees, experts who are directly involved in
                            providing the Services are sick or suffer some other
                            emergency or unexpected event).
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You can request to cancel a service in writing by
                        contacting us via email at{" "}
                        <a href="mailto:info@IndexScore.com">
                          Info@IndexScore.com
                        </a>{" "}
                        or through the Platform and shall be subject to our
                        Client General Service Agreement.
                      </p>
                    </li>
                  </ol>
                </li>

                <li className="fm Privacy__para ms-0">
                  <h4 className="fm blue fw-semibold fs-6">
                    Intellectual Property Rights and Content.
                  </h4>
                  <ol type="i">
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Subject to the Client General Service Agreement and
                        Provided, IndexScore Inc. has received full payment of
                        the relevant service of Clients, the Intellectual
                        Property Rights in the transcripts, caption files or
                        other directly relevant files that Global Consulting
                        delivers you for a service (“Delivered Materials'") are
                        owned by you.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You hereby grant us a perpetual, irrevocable,
                        non-exclusive, transferable, royalty-free, worldwide to
                        store and use the ‘Delivered Materials’ for the purposes
                        of archiving and quality assurance testing and controls.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        In respect of the information or content, ‘including but
                        not limited to images, videos, audio recordings,
                        illustration or texts, regardless’ of the medium, that
                        you share, use, upload or submit in connection with your
                        access to or use of the Service ( “User Content"), you
                        agree, represent and warrant that you own (or have
                        sufficient lights to) all right, title and interest in
                        and to such User Content, including, without limitation,
                        all Intellectual Property Rights contained therein.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        By uploading or providing any User Content you hereby
                        grant and will grant IndexScore Inc. and its related and
                        affiliated companies, contractors and partners a
                        nonexclusive, worldwide, royalty free, fully paid up,
                        transferable, sub-license-able, perpetual, irrevocable
                        license to copy, display, upload, perform, distribute,
                        store, modify and otherwise use your User Content in
                        connection with the operation of the Service and as
                        otherwise permitted under this Agreement, including for
                        clarity’s sake the Privacy Policy, in any form, medium
                        or technology now known or later developed.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        In all cases, you agree, represent and warrant that
                        ‘User Content’ and our (and our related and affiliated
                        companies, and contractor’s) use of the ‘User Content
                        will not violate any law or infringe any rights of any
                        third party’, including but not limited to any
                        ‘Intellectual Property Rights and privacy and publicity
                        rights’.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You must not upload, use, share or submit any User
                        Content in our website and other Platform that: 1. that
                        violates third-party rights of any kind, including
                        without limitation any Intellectual Property Rights or
                        rights of privacy or publicity.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Contains any information or content that we deem to be
                        unlawful, harmful, abusive, racially or ethnically
                        offensive, defamatory, infringing, invasive of personal
                        privacy or publicity rights, harassing, humiliating to
                        ‘other people (publicly or otherwise), libelous,
                        threatening, hostile, obscene violent’, or that which
                        provokes violence or hostility-, profane, or otherwise
                        objectionable
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        May create a risk of harm, loss, physical or ‘mental
                        injury, emotional distress, death, disability,
                        disfigurement, or physical’ or mental illness to you, to
                        any other person, or to any animal;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        May create a risk of any other loss or damage to any
                        person or property;
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Seeks to harm or exploit children including by exposing
                        them to inappropriate content, asking for personally
                        identifiable details or otherwise,
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        May constitute or contribute to a crime or tort:
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Contains any information which discriminates against
                        others based on race, religion, sex, sexual orientation,
                        age, disability, ancestry or national origin:
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Contains any information or content that is illegal
                        (including, without limitation, the disclosure of
                        insider information under securities law or of ‘another
                        party’s trade secrets’); (h) contains any information or
                        content that you do not have a right to make available
                        under any law or under contractual or fiduciary
                        relationships; or
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        Contains any information or content that you know is nor
                        correct and current.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        IndexScore Inc. reserves ‘the right to reject and/or
                        remove any User’. Content that IndexScore Inc. believes,
                        in its sole discretion.
                      </p>
                    </li>
                    <li>
                      <p className="fm darkGrey Privacy__para">
                        You ‘hereby agree that IndexScore Inc. may advertise you
                        as a customer of our services and accordingly, you
                        hereby grant us a non-exclusive license to use your name
                        and trade mark for inclusion in our marketing,
                        advertising, and or publicity materials’.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
