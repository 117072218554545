import React, { useEffect, useRef, useState,useContext } from "react";
import { Input, Space, Tag, Tooltip, Checkbox, Select, DatePicker } from "antd";
import moment from "moment";
import { getPackages } from "../../../../services/criteria";
import { Country } from "../../../../Utilities/Country.js";
import { companies } from "../../../../Utilities/Companies.js";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { getJobDescription } from "../../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
// app.post("/api/get-jobDescription", jobDescription);

const Role = ({
  handleRoleData,
  handleTagsData,
  handleRoleEmploymentType,
  handleRoleSelectedStartDate,
  handleRoleSelectedEndDate,
}) => {
  // const [tags, setTags] = useState(["Location1", "Location2", "Location3"]);
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
 console.log("userData.companyDescription",userData.companyDescription);

  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  const [tags, setTags] = useState([]);
  const [employmentType, setEmploymentType] = useState(myContext.contextData && myContext.contextData.employment_type ? myContext.contextData.employment_type : []);
  const [selectedStartDate, setSelectedStartDate] = useState(
    myContext.contextData && myContext.contextData.start_date
      ? moment(myContext.contextData.start_date).format("YYYY-MM-DD")
      : ""
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    myContext.contextData && myContext.contextData.end_date
      ? moment(myContext.contextData.end_date).format("YYYY-MM-DD")
      : ""
  );

  const { enqueueSnackbar } = useSnackbar();

 

  const [roleData, setRoleData] = useState({
    job_title: "",
    company: userData.companyName ? userData.companyName : userData.company_name,
    division: "",
    job_locations:"",
    min_salary: "",
    max_salary: "",
    salary_currency: "",
    company_bio: userData.companyDescription,
    department: ""
  });

  const {
    job_title,
    company,
    division,
    min_salary,
    max_salary,
    salary_currency,
    company_bio,
    job_locations,
    department
  } = roleData;



  const updateCompanyBio = (newCompanyBio) => {
    setRoleData((prevRoleData) => ({
      ...prevRoleData,
      company_bio: newCompanyBio,
    }));
  };

  
  const disabled =
    !job_title ||
    !company ||
    !division ||
    !min_salary ||
    !max_salary ||
    !salary_currency ||
    !company_bio ||
    !department ||
    !job_locations;

  const handleChange = (key, value) => {
    setRoleData((pre) => ({ ...pre, [key]: value }));    
    handleRoleData(roleData);    
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setEmploymentType([...employmentType, value]);
      handleRoleEmploymentType([...employmentType, value]);
    } else {
      setEmploymentType(employmentType.filter((item) => item !== value));
      handleRoleEmploymentType(employmentType.filter((item) => item !== value));
    }
  };

  const handleStartDateChange = (date) => {
    // console.log("date",date);
    // console.log("dateString",dateString);
    
    setSelectedStartDate(moment(date).format("YYYY-MM-DD"));
    handleRoleSelectedStartDate(moment(date).format("YYYY-MM-DD"));    
  };
  const handleEndDateChange = (date) => {
    setSelectedEndDate(moment(date).format("YYYY-MM-DD"));
    handleRoleSelectedEndDate(moment(date).format("YYYY-MM-DD"));    
  };

  const { Option, OptGroup } = Select;

  useEffect(() => {
    getPackages().then((data) => {      
      // debugger
    });
  }, []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);    
    setTags(newTags);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      handleTagsData([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
    // handleTagsData(tags);
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };

  return (
    <div className="Role pb-5 General">
      <style>
        {`.ant-select-selector {
            max-width: 100% !important;
        } 
        .Login--select1::before {
          position: absolute !important;
          content: "" !important;
          right: 0px !important;
          width: 40px;
          height: 100% !important;
          background-image: url(/assets/images/Dashboard/arrow_white.svg) !important;
          background-repeat: no-repeat !important;
          background-size: 18px !important;
          background-position: center !important;
          z-index: 1111 !important;
      }    
      .wd-100{
        width: 100% !important;
        max-width: 100% !important;
      }     
            
        }`}
      </style>

      <div className="Dashboard__container pb-2 pt-4">
        <div className="Dashboard__container__content pt-2">
          <div className="container-fluid px-0">
            <div className="row">
              {/* <div className="col-xxl-8 col-md-10">
                <p className="darkGrey fm">
                  Set up an Open Role on IndexScore.
                </p>
              </div> */}
              <div className="col-xl-8 col-12">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Let’s get started:
                </label>
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14 mb-3"
                  placeholder="Job title"
                  value={job_title}
                  id="sector1"
                  onChange={(value) =>
                    handleChange("job_title", value.target.value)
                  }
                />                
              </div>
              <div className="col-xl-8 col-12 mb-4">                
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => 
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                  size="small"
                  value={job_locations}
                  placeholder="Job location"
                  onChange={(value) => {                    
                    handleChange("job_locations", value);
                  }}
                >
                  <OptGroup>
                    {Country.map((item) => (
                      <Option
                        value={item.code}
                        label={item.name.toLowerCase()}
                        key={item.code}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
              </div>
             
             <div className="col-xl-8">
              <div className="row">
              <div className="col-sm-4 pe-sm-0 mb-2">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14"
                  placeholder="Company"
                  value={company}
                  readOnly={true}
                  onChange={(value) => {                    
                    handleChange('company', value.target.value);                    
                  }}
                />               
              </div>
              <div className="col-sm-4 pe-sm-0 mb-2">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14"
                  placeholder="Division"
                  value={division}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("division", value.target.value);                    
                  }}
                />
              </div>
              <div className="col-sm-4 mb-2">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14"
                  placeholder="Department"
                  value={department}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("department", value.target.value);                    
                  }}
                />
              </div>
              </div>
             </div>
              <div className="col-xl-8 col-12 mt-4">
                <p className="darkGrey fm mb-0 fw-semibold fs-6">
                Employment Type:
                </p>
                <p className="darkGrey fm mt-0 text-14">Choose one or multiple options</p>
                <div className="d-flex flex-wrap">
                  <Checkbox
                    checked={employmentType.includes("Full time")}
                    value="Full time"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                  >
                    Full time
                  </Checkbox>
                  <Checkbox
                    checked={employmentType.includes("Part time")}
                    value="Part time"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                  >
                    Part time
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                    checked={employmentType.includes("Hybrid")}
                    value="Hybrid"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Hybrid
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                    checked={employmentType.includes("Remote")}
                    value="Remote"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Remote
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                    checked={employmentType.includes("Freelance")}
                    value="Freelance"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Freelance
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 text-14 me-xxl-4 me-3"
                    checked={employmentType.includes("Temporary")}
                    value="Temporary"
                    name="employmentType"
                    onChange={handleCheckboxChange}
                  >
                    Temporary
                  </Checkbox>
                </div>
              </div>
              <p className="darkGrey fm mb-0 fw-semibold fs-6 mb-2 mt-4">
              Compensation Range:
              </p>
              <div className="col-xl-8">
                <div className="row">
                <div className="col-sm-4 h-100 pe-sm-0">
              <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14 mb-2"
                  placeholder="Min ($)"
                  value={min_salary}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("min_salary", value.target.value);                    
                  }}
                />                
              </div>
              <div className="col-sm-4 h-100 pe-sm-0">
              <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input text-14 mb-2"
                  placeholder="Max ($)"
                  value={max_salary}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("max_salary", value.target.value);                  
                  }}
                />                
              </div>
              <div className="col-sm-4 h-100">
                <Select
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  value={salary_currency}
                  placeholder="USD"
                  onChange={(value) => {
                    // alert(value)
                    handleChange("salary_currency", value);                    
                  }}
                >
                  <Option value="usd">USD</Option>
                  <Option value="cad">CAD</Option>
                  <Option value="aud">AUD</Option>
                  <Option value="gbp">GBP</Option>
                  <Option value="eur">EUR</Option>                  
                </Select>
              </div>
                </div>
              </div>
              <p className="darkGrey text-capitalize fm fw-semibold fs-6 mt-4 mb-2">
                Application Window:
              </p>
             <div className="col-xl-8">
            <div className="row">
            <div className="col-sm-4 mb-2 pe-sm-0">
                {/* <DatePicker

                  // value={new Date(myContext.contextData.start_date)}
                  className="Login__input w-100"                  
                  onChange={handleStartDateChange}
                  size="large"
                  placeholder="Start date"
                /> */}
                    <input
                        type="date"
                        className="form-control form-control-md w-100 Dashboard__input mb-1"
                        placeholder="YYYY-MM-DD"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={selectedStartDate}
                        id="sector1"
                        onChange={(value) => {                          
                          handleStartDateChange(value.target.value)
                          }
                        }
                      />
              </div>
              <div className="col-sm-4 mb-2 pe-sm-0">
              <input
                        type="date"
                        className="form-control form-control-md w-100 Dashboard__input mb-1"
                        pattern="\d{4}-\d{2}-\d{2}"
                        value={moment(selectedEndDate).format("YYYY-MM-DD")}
                        id="sector1"
                        onChange={(value) => {                          
                          handleEndDateChange(value.target.value)
                          
                        }

                        }
                      />
                {/* <DatePicker
                  // value={new Date(myContext.contextData.end_date)}
                  className="Login__input w-100"
                  onChange={handleEndDateChange}
                  size="large"
                  placeholder="End date"
                /> */}
              </div>
            </div>
             </div>
              <div className="col-xl-8 col-12 mt-4">
                <div className="row">
                  <div className="col-md-9">
                    <label
                      htmlFor="text"
                      className="darkGrey text-capitalize fm fw-semibold fs-6"
                    >
                      Company details:
                    </label>
                    <p className="fm darkGrey mb-3 mt-0 text-14">
                      If you provided detailed background info of your company
                      during your onboarding process, this form will be
                      pre-filled for you else please provide a short description
                      of your company in the following form.
                    </p>
                  </div>
                  {/* <div className="col-md-3 ">
                    <button
                      disabled={loadingAi}
                      onClick={getJobDescriptionHandler}
                      className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3 mt-4 wd-100`}
                    >
                      {loadingAi ? <Loader /> : "Generate with AI"}
                    </button>
                  </div> */}
                </div>
                <textarea
                  className="form-control form-control-lg Dashboard__input text-14 mb-4 w-100 text-14"
                  placeholder="What we’re looking for…"
                  value={company_bio}
                  onBlur={(value) => {
                    // alert(value)
                    handleChange("company_bio", value.target.value);                    
                  }}
                  onChange={(value) => {
                    // alert(value)
                    handleChange("company_bio", value.target.value);                    
                  }}
                  id="text"
                  rows={6}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Role;
