import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const UHSection3 = () => {
  return (
    <div className="uhs5 main-padding py-5">
      <div className="container-xl px-0 pt-4">
        <div className="text-center">
          <p className="fm darkGrey text-14  rounded-pill uhs3__title mb-2">
            For Candidates
          </p>
          <h1 className="fs-1 darkBlue text-center fw-semibold mb-0">
            Advance your career
          </h1>
          <h1 className="fw-light darkBlue fs-1">
            without the repetitive processes & bias
          </h1>
        </div>

        <div className="uhs5__container px-3 mt-5 rounded-3 d-flex flex-column align-items-center">
          <p className="fm darkGrey text-center uhs5__para">
            Doing away with the need for endless applications & going through
            biased recruitment, IndexScore is a platform where your true value
            can be discovered by those who need you. Launch your profile with
            us, get assigned a score with guidance for improvement,
            <strong className="fw-semibold">
              {" "}
              then get found by organizations who know your worth.
            </strong>
          </p>
          <img
            src="/assets/UpdatedFrontend/Home/homeCandidate.png"
            alt=""
            className="img-fluid"
          />
        </div>

        <div className="d-flex justify-content-center">
          <Link to={"/about-us"} className="text-decoration-none">
            <button className="btn--main darkGrey fw-semibold fm rounded-2 mt-5">
              Find out more
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UHSection3;
