import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  Checkbox,
  Slider,
  Tooltip,
  Table,
  Modal,
  Dropdown,
  Button,
  Select,
  Input,
  Radio,
  Space,
  Pagination,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import CircularProgressBar from "../ComplianceReport/CircularProgressBar";
import { HiOutlineCheckCircle } from "react-icons/hi";
import {
  BsFilterCircle,
  BsSearch,
  BsFilter,
  BsLinkedin,
  BsThreeDotsVertical,
  BsArrowDown,
} from "react-icons/bs";
import { TiArrowUnsorted } from "react-icons/ti";
import { CiLock } from "react-icons/ci";
import { IoIosArrowDown } from "react-icons/io";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import YourProfile from "../../ProfessionalDashboard/YourProfile/YourProfile";
import MeetingDate from "../OffersManagement/MeetingDate";
import MeetingTime from "../OffersManagement/MeetingTime";
import MeetingDetails from "../OffersManagement/MeetingDetails";
import MeetingConfirm from "../OffersManagement/MeetingConfirm";
import {
  AtsToken,
  getAtsCandidates,
  createAtsCandidates,
  requestAtsScore,
} from "../../../services/auth";
import moment from "moment";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { RxEnterFullScreen, RxReload } from "react-icons/rx";
import { Country } from "../../../Utilities/Country";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { useMediaQuery } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate, Link } from "react-router-dom";
import { useMergeLink } from "@mergeapi/react-merge-link";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";

const ExperienceProgressBars = [
  {
    id: 1,
    progress: 300,
    businessTitle: ">1",
    color: "#436C03",
  },
  {
    id: 2,
    progress: 270,
    businessTitle: "1 - 5",
    color: "#A6DD50",
  },
  {
    id: 3,
    progress: 270,
    businessTitle: "5 - 10",
    color: "#CFFD5E",
  },
  {
    id: 4,
    progress: 220,
    businessTitle: "10 - 15",
    color: "#0D2159",
  },
  {
    id: 5,
    progress: 210,
    businessTitle: "15 - 20",
    color: "#486FD9",
  },
  {
    id: 6,
    progress: 210,
    businessTitle: "20 - 25",
    color: "#BFD0FF",
  },
  {
    id: 7,
    progress: 190,
    businessTitle: "25+",
    color: "#2D6980",
  },
  {
    id: 8,
    progress: 140,
    businessTitle: "",
    color: "#10BAC1",
  },
  {
    id: 9,
    progress: 140,
    businessTitle: "",
    color: "#12D3DB",
  },
  {
    id: 10,
    progress: 20,
    businessTitle: "",
    color: "#4A4A49",
  },
];
const CompaniesProgressBars = [
  {
    id: 1,
    progress: 300,
    businessTitle: "Google",
    color: "#436C03",
  },
  {
    id: 2,
    progress: 260,
    businessTitle: "Microsoft",
    color: "#A6DD50",
  },
  {
    id: 3,
    progress: 190,
    businessTitle: "Amazon",
    color: "#CFFD5E",
  },
  {
    id: 4,
    progress: 200,
    businessTitle: "Novartis",
    color: "#0D2159",
  },
  {
    id: 5,
    progress: 110,
    businessTitle: "Accenture",
    color: "#BFD0FF",
  },
  {
    id: 6,
    progress: 140,
    businessTitle: "PwC",
    color: "#BFD0FF",
  },
  {
    id: 7,
    progress: 150,
    businessTitle: "BCG",
    color: "#2D6980",
  },
  {
    id: 8,
    progress: 230,
    businessTitle: "McKinsey",
    color: "#10BAC1",
  },
  {
    id: 9,
    progress: 260,
    businessTitle: "Bain",
    color: "#12D3DB",
  },
  {
    id: 10,
    progress: 50,
    businessTitle: "Apple",
    color: "#4A4A49",
  },
];

const sortingMenus = [
  {
    key: "1",
    label: <span>Most Recent</span>,
  },
  {
    key: "2",
    label: <span>Oldest</span>,
  },
];

function ATSBox({
  boxState,
  handleProviderChange,
  handleKeyChange,
  handleDelete,
}) {
  const { selectedImage, showInput } = boxState;
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Choose your provider");

  const options = [
    {
      value: "GreenHouse",
      label: "GreenHouse",
      image: "greenhouse-image-url.jpg",
    },
    { value: "Workable", label: "Workable", image: "workday-image-url.jpg" },
    { value: "Workday", label: "Workday", image: "workday-image-url.jpg" },
    { value: "BambooHR", label: "BambooHR", image: "bamboohr-image-url.jpg" },
  ];
  const handleProviderChangeLocal = (value) => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedValue(
      selectedOption ? selectedOption.label : "Choose your provider"
    );
    handleProviderChange(value);
  };

  return (
    <div className="ATS__box p-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <div className="ATS__box__dp rounded-3">
            <img src={selectedImage} alt="" className="img-fluid" />
          </div>
          <div className="custom-selectbox">
            <p
              className="selected-value mb-0 d-inline-block fm transition text-14"
              style={{ color: open ? " rgb(95, 137, 255)" : "var(--darkgrey" }}
            >
              {selectedValue}
              <BsArrowDown
                className="custom-select-icon"
                style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
              />
            </p>
            <Select
              className="ATS__select"
              size="small"
              placeholder="Choose your provider"
              optionFilterProp="children"
              // onChange={handleProviderChangeLocal}
              onClick={() => setOpen(!open)}
              open={open}
            >
              {options.map((option) => (
                <Select.Option
                  value={option.value}
                  key={option.value}
                  onClick={() => setOpen(!open)}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <button
          className="ATS__box__dp bg-white rounded-3 d-flex justify-content-center align-items-center"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <BsThreeDotsVertical className="darkGrey fs-5" />
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <button
              class="dropdown-item fm text-14"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </li>
        </ul>
      </div>

      <div className="ATS__box__bottom rounded-2 p-2 mt-3 d-flex align-items-center justify-content-center mb-4">
        {showInput ? (
          <div className="w-100 mb-3">
            <label htmlFor="pass" className="darkGrey fm text-12">
              KEY
            </label>
            <Input.Password
              placeholder="***************"
              id="pass"
              onChange={(e) => handleKeyChange(e.target.value)}
            />
          </div>
        ) : (
          <p className="darkGrey fm text-12 mb-0 text-center">
            Please select ATS provider above
          </p>
        )}
      </div>

      {/*this is the code for warning text */}
      {/* <div className="ATS__warning fm rounded-pill text-center p-1">
        No API Key connected, Please enter company ATS API token ID
      </div>
      <button className="repost__btn filter__btn fm px-3 fw-light d-flex justify-content-center align-items-center nowrap text-14 mx-auto my-3">
        Update API Key
      </button> */}
    </div>
  );
}

const ATSCandidates = (props) => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const { enqueueSnackbar } = useSnackbar();
  const [slidervalue, onChangeSlider] = useState(0);
  const [purposeValue, setPurposeValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(false);
  const [candidates, setCandidates] = useState(true);
  const [meeting, setMeeting] = useState(false);
  const [time, setTime] = useState(false);
  const [detail, setDetail] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [filter, setFilter] = useState(false);
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const inputRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [relevance, setRelevance] = useState(true);
  const [sortingOption, setSortingOption] = useState("1");
  const [relevanceValue, setRelevanceValue] = useState(0);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [dropfav, setDropfav] = useState(true);
  const [droplist, setDroplist] = useState(true);
  const [dropinterview, setDropinterview] = useState(true);
  const isMediumScreen = useMediaQuery("(max-width:767px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [professionals, setProfessionals] = useState([]);
  const [experienceModal, setExperienceModal] = useState(false);
  const [companiesModal, setCompaniesModal] = useState(false);
  const [educationModal, setEducationModal] = useState(false);

  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [atsCandidates, setAtsCandidates] = useState([]);
  const [selectedAts, setSelectedAts] = useState(false);
  const [selectedAtsCandidates, setSelectedAtsCandidates] = useState([]);
  const [requestLoader, setRequestLoader] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [integrationNameDataVal, setIntegrationNameDataVal] = useState(null);
  const [integrationPublicToken, setIntegrationPublicToken] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange1 = (item) => {
    if (selectedAtsCandidates.includes(item)) {
      setSelectedAtsCandidates(
        selectedAtsCandidates.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedAtsCandidates([...selectedAtsCandidates, item]);
    }
  };

  const handleButtonClick = async () => {
    try {
      setRequestLoader(true);
      const payloadCandidates = selectedAtsCandidates.map((item) => ({
        enterpriseID: userData?.companyID,
        id: item.item.id,
        score: "requested",
        firstName: item.first_name,
        emailAddress: item.item.email_addresses[0].value,
      }));

      const payloadRequestScore = {
        enterpriseId: userData?.companyID,
        candidates: payloadCandidates,
      };
      const responseScoreCandidate = await requestAtsScore(payloadRequestScore);
      if (responseScoreCandidate.data.statusCode == 1) {
        enqueueSnackbar("Request sent", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        handleOk();
        setRequestLoader(false);
        setSelectedAtsCandidates([]);
        getAllATsCandidates();
      }
    } catch (error) {
      // Handle errors
      console.error("Error while requesting scores:", error);
    }
  };

  const EducationData = [
    { label: "Group1", value: 6, color: "#436C03" },
    { label: "Group2", value: 25, color: "#A6DD50" },
    { label: "Group3", value: 12, color: "#CFFD5E" },
    { label: "Group4", value: 6, color: "#0D2159" },
    { label: "Group5", value: 30, color: "#486FD9" },
    { label: "Group6", value: 5, color: "#BFD0FF" },
    { label: "Group7", value: 5, color: "#2D6980" },
    { label: "Group8", value: 5, color: "#10BAC1" },
    { label: "Group9", value: 5, color: "#12D3DB" },
    { label: "Group10", value: 3, color: "#4A4A49" },
    { label: "Group11", value: 2, color: "#969696" },
    { label: "Group12", value: 1, color: "#CBCBCB" },
  ];

  const showModal1 = () => {
    setExperienceModal(true);
  };
  const showModal2 = () => {
    setCompaniesModal(true);
  };
  const showModal4 = () => {
    setEducationModal(true);
  };

  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen1(nextOpen);
    }
  };


  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    filterRef.current = document.getElementById("filter_btn");
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    }
    if (isSmallScreen) {
      setDropfav(true);
      setDroplist(false);
      setDropinterview(false);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);
  // var allcandidates;
  var myObject;
  var myObjectEnterprise;
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    // fetchJobs();

    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedIndex(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tokenData, setTokenData] = useState("");
  const [tokenIntegrationName, setTokenIntegrationName] = useState("");
  const [integrationName, setIntegrationName] = useState();
  const [token, setToken] = useState("");

  const [candidatesList, setCandidatesList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [relevanceFilter, setRelevanceFilter] = useState(null);
  const [reversedCandidates, setReversedCandidates] = useState([]);
  const [newFilteredCandidates,setNewFilteredCandidates] = useState([]);
  const fetchToken = async () => {
    try {
      // userEmail
      const payload = {
        // _id: userData?.companyID,
        // emailAddress: "info@indexscore.com",
        end_user_email_address: "info@indexscore.com",
        end_user_organization_name: "Indexscore",
        end_user_origin_id: "1234343453499",
        categories: ["ats"],
        link_expiry_mins: 30,
      };
      const response = await AtsToken(payload);

      const fetchedToken = response.data?.data?.link_token;
      const integrationNameVal = response.data?.data?.integration_name;
      setTokenData(fetchedToken);
      // setTokenIntegrationName(integrationNameVal);
      if (integrationNameVal) {
        localStorage.setItem("integrationNameData", integrationNameVal);
        localStorage.setItem("integrationToken", fetchedToken);
        // open();
      } else {
      }
      if (fetchedToken) {
        open();
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  // useEffect(() => {
  //   fetchToken();
  // }, [integrationName]);

  // const fetchCandidates = async () => {
  //   try {
  //     const response = await AtsToken();
  //     const fetchedToken = response.data?.data?.link_token;
  //     setTokenData(fetchedToken);
  //   } catch (error) {
  //     console.error('Error fetching token:', error);
  //   }
  // };

  // useEffect(() => {

  //   fetchCandidates();
  // }, []);

  const getAllATsCandidates = async () => {
    setIsFetching(true);

    // const enterpriseId = localStorage.getItem('enterpriseID');
    const payload = {
      enterpriseId: userData?.companyID,
    };

    try {
      const responeFromAts = await getAtsCandidates(payload);
      const dataFromAts = responeFromAts?.data?.data;

      if (dataFromAts && dataFromAts[0]?.candidates.length > 0) {
        const atsCandidatesData = dataFromAts[0]?.candidates.map((person) => ({
          id: person.id,
          index_score: person.score ? person.kycVerification ? Math.round(person.index_score) : 2: 1,
          type: person.type,
          candidatePhoto:person.photoUrl?  person.photoUrl : "",
          purposeScore: person.purposeScore
            ? person.index_score ? person.score ? person.kycVerification ? Math.round(person.purposeScore?.[userData.companyName]) : 2: 1 : 1
            : 1,
          first_name: person.first_name,
          score_request: person.score ? person.score : false,
          last_name: person.last_name,
          onboarded: person.index_score ? "Yes" : "No",
          veteran: "No",
          contacted: "Yes",
          // alignment: "Pending",
          title: person.person,
          locations: person.locations,
          date: person.createdAt,
          tags: person.tags,
          purpose_Score: person.purposeScore
            ? Math.round(person.purposeScore?.[userData.companyName]) <= 33
              ? "low"
              : Math.round(person.purposeScore?.[userData.companyName]) > 33 &&
                Math.round(person.purposeScore?.[userData.companyName]) <= 66
              ? "average"
              : "high"
            : "Pending",
          relevance_score: person.relevance_score
            ? Math.round(person.relevance_score) <= 33
              ? "low"
              : Math.round(person.relevance_score) > 33 &&
                Math.round(person.relevance_score) <= 66
              ? "average"
              : "high"
            : "Pending",
          relevance_scoreVal: person.relevance_score ? person.relevance_score : 1,
          jobDetails: person.jobDetails?.name,
          jobId: person.jobDetails?.id,
          item: person,
          kycVerification: person.kycVerification
            ? person.kycVerification
            : false,
        }));        
        setCandidatesList(atsCandidatesData);
        setReversedCandidates(atsCandidatesData.reverse());
        // setCandidatesList(dataFromAts[0]);
      } else if (dataFromAts && dataFromAts[1]?.candidates.length > 0) {
        setCandidatesList(dataFromAts[1]);
        
      }
    } catch (error) {
      console.error("Error fetching ATs candidates", error);
    } finally {
      setIsFetching(false);
    }
  };

  const createATsCandidates = async () => {
    setIsFetching(true);

    const enterpriseId = localStorage.getItem("enterpriseID");
    const publicToken = localStorage.getItem("public_token");
    const payload = {
      type: "workable",
      token: publicToken,
      enterpriseId: enterpriseId,
    };

    try {
      const responseFromAts = await createAtsCandidates(payload);
      if (responseFromAts) {
        const dataFromAts = responseFromAts?.data?.data;

        if (dataFromAts) {
          // setAtsCandidates(dataFromAts);
          fetchDataWithDelay();
          setIsCheckboxChecked(false);
        }
      }
    } catch (error) {
      console.error("Error creating ATs candidates", error);
    } finally {
      // setIsFetching(false);
    }
  };

  const fetchDataWithDelay = async () => {
    const delayMilliseconds = 3000;

    await new Promise((resolve) => setTimeout(resolve, delayMilliseconds));

    // Now call your fetchData function
    await getAllATsCandidates();
  };

  // Call the fetchDataWithDelay function

  useEffect(() => {
    const fetchData = async () => {
      await getAllATsCandidates();
    };

    fetchData();
  }, []);

  const onExit = useCallback((TokenData) => {
    // Send public_token to server (Step 3)
    const integrationNameData = localStorage.getItem("integrationNameData");

    if (integrationNameData == null) {
      fetchToken();
      open();
    }
  }, []);

  const onSuccess = useCallback((public_token) => {
    const integrationNameData = localStorage.getItem("integrationNameData");

    if (integrationNameData != null) {
      // createATsCandidates(public_token);
      localStorage.setItem("public_token", public_token);
      setIntegrationNameDataVal(integrationNameData);
      setIntegrationPublicToken(public_token);
      enqueueSnackbar("Please get ats candidates", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }

    // if(integrationName==null){
    //   fetchToken();
    //   open();

    // }
    // else{
    //   createATsCandidates(public_token);
    // }
  }, []);

  const handleSortByDate = () => {
    if (sortBy === "date") {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy("date");
      setSortOrder("desc");
    }
  };

  

  const handleRelevanceFilter = (value) => {
    setRelevanceFilter(value);
  };

  const clearRelevanceFilter = () => {
    setRelevanceFilter(null);
  };
  
  
  const getFiltered = () => {
    if (!searchQuery && slidervalue === 0 && purposeValue === 0 && relevanceValue === 0 && sortingOption === '0') {
      // No filters active, return complete data
      return reversedCandidates;
    }

    const displayedCandidates = reversedCandidates?.filter(
      (candidate) =>
      candidate.first_name.toLowerCase().includes(searchQuery.toLowerCase())  &&
       candidate.index_score >= slidervalue &&
       candidate.purposeScore >= purposeValue &&
       candidate.relevance_scoreVal >= relevanceValue
    ).sort((a, b) => {
      
      const sortingNumber = parseInt(sortingOption, 10); // Convert to number
      if (sortingNumber === 1) {
        // Sort by most recent
        return new Date(b.date) - new Date(a.date);
      } else if (sortingNumber === 2) {
        // Sort by oldest
        return new Date(a.date) - new Date(b.date);
      }
      // Default sorting
      return 0;
    });
  
  
    return displayedCandidates;
  }
  
  
  useEffect(() => {
    const result = getFiltered();    
    setNewFilteredCandidates(result);
  }, [
    searchQuery,
    slidervalue,
    reversedCandidates,
    purposeValue,
    relevanceValue,
    sortingOption,
  ]);
  
  

  
  // Make sure to update the property names used for filtering and sorting
  

  // const sortedCandidates = filteredCandidates?.sort((a, b) => {
  //   // Compare function based on the selected field and sort order
  //   if (sortBy === "date") {
  //     const dateA = new Date(a.date);
  //     const dateB = new Date(b.date);
  //     return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  //   }

  //   return 0;
  // });

  // const filteredByRelevance = sortedCandidates.filter((candidate) => {
  //   if (!relevanceFilter) return true; // If no filter selected, return all candidates
  //   return candidate.relevance_score === relevanceFilter;
  // });

  // const displayedCandidates = relevanceFilter
  //   ? filteredByRelevance
  //   : sortedCandidates;

  const { open, isReady } = useMergeLink({
    linkToken: tokenData,
    onSuccess,
    onExit,
  });

  const handleSortingChange = (key) => {
    
    setSortingOption(key.key);
  };

 
  const tableColumns = [
    {
      title: (
        <>
          Candidates{" "}
          <span className="fw-normal">
            ({newFilteredCandidates ? newFilteredCandidates.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Source",
      width: 100,
      dataIndex: "source",
      key: "source",
      sorter: true,
    },
    {
      title: "Veteran",
      dataIndex: "veteran",
      key: "1",
      sorter: (a, b) => {
        const veteranA = (a.veteran || "").toLowerCase();
        const veteranB = (b.veteran || "").toLowerCase();
        return veteranA.localeCompare(veteranB);
      },
    },
    {
      title: "Contacted",
      dataIndex: "contacted",
      key: "1",
      sorter: (a, b) => {
        const contactedA = (a.contacted || "").toLowerCase();
        const contactedB = (b.contacted || "").toLowerCase();
        return contactedA.localeCompare(contactedB);
      },
    },
    {
      title: "Onboarded",
      dataIndex: "onboarded",
      key: "2",
      sorter: (a, b) => {
        const onboardedA = (a.onboarded || "").toLowerCase();
        const onboardedB = (b.onboarded || "").toLowerCase();
        return onboardedA.localeCompare(onboardedB);
      },
    },
    {
      title: "candidateData",
      key: "candidateData",
      dataIndex: "candidateData",
      hidden: true
      },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "3",
      sorter: (a, b) => a.indexscore - b.indexscore,
      render: (indexscore, candidate) => (
        <div className="indexScore__box fm">
          {indexscore === 2 ? (
            <span className="text-14 fm darkGrey">Pending</span>
          ) : (
            <>
              {indexscore > 2 ? (
                <div
                  className={`score__text d-inline darkGrey rounded-pill prog-${
                    (parseInt(indexscore) / 850) * 100 <= 50 ? "red" : ""
                  } prog-${
                    (parseInt(indexscore) / 850) * 100 > 50 ? "main" : ""
                  } text-13 fm fw-semibold`}
                >
                  {indexscore}
                </div>
              ) : (
                <Checkbox
                  className="fm"
                  disabled={candidate.onboarded==='No'}
                  onChange={() => handleCheckboxChange1(candidate.candidateData)}
                  checked={selectedAtsCandidates.includes(candidate.candidateData)}
                >
                  Request
                </Checkbox>
              )}
            </>
          )}
        </div>
      ),
    },
    {
      title: "Alignment",
      dataIndex: "alignment",
      sorter: (a, b) => a.alignment - b.alignment,
      render: (alignment) => (
        <>
          {userData.subscription.name === "Predict" ||
          userData.subscription.name === "Scale" ? alignment ===1 || alignment ===2 ?
          'Pending' : (
            <div
              className={`score__text d-inline darkGrey rounded-pill prog-${
                (parseInt(alignment) / 100) * 100 <= 50 ? "red" : ""
              } 
                prog-${
                  (parseInt(alignment) / 100) * 100 > 50 ? "main" : ""
                } text-13 fm fw-semibold`}
            >
              {alignment}%
            </div>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <CiLock className="lock-item fs-6" />
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: "Relevance",
      dataIndex: "relevance",
      key: "9",
      width: 100,
      sorter: (a, b) => a.relevance - b.relevance,
      render:(relevance)=>(
        <div>
          {relevance!==1 ? (
            relevance + "%"
          ) : (
            "Pending"
          )}
        </div>
      )
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "10",
      width: 150,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Job Role/Id",
      dataIndex: "jobRole",
      key: "11",
      width: 200,
    },
  ];
  const formattedCandidates = newFilteredCandidates.map((candidate, index) => ({
    key: candidate.id, // Assuming id uniquely identifies each candidate
    name: (
      <>
        <div
          className="user__box cursor-default"
          ref={buttonRef}
          onClick={() => handleClick(index)}
        >
          <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${candidate.candidatePhoto ? 'candidate__photo' : ''}`}>
            <img
              src={candidate.candidatePhoto ? candidate.candidatePhoto :  `/assets/images/user.svg`}
              alt=""
              className="candidates__avatar--img img-fluid"
            />
          </div>
          <div className="Experts__name fm mb-0 ms-2">
            <h6 className="mb-0 me-1 text-14">
              {candidate.first_name + " " + candidate.last_name}
            </h6>{" "}
          </div>
        </div>
      </>
    ),
    source: candidate.type,
    veteran: candidate.veteran,
    contacted: candidate.contacted,
    onboarded: candidate.onboarded,
    indexscore: candidate.index_score,
    alignment: candidate.purposeScore,
    relevance: candidate.relevance_scoreVal,
    date: moment(candidate.date).format("L"),
    jobRole: (
      <div
        onClick={() => {
          navigateTo(candidate.item);
        }}
        className="word-wrap clickATS"
      >
        <span title={candidate?.jobDetails}>{candidate?.jobDetails}</span>
        <span className="job__id__trim word-wrap h-100 w-100">
          {candidate?.jobId}{" "}
        </span>
      </div>
    ),
    candidateData:candidate
  }));

  const handleClickMerge = () => {
    // if (isCheckboxChecked) {
    fetchToken();
    // } else {
    //   enqueueSnackbar(
    //     "Please accept the Terms and Conditions before proceeding.",
    //     {
    //       variant: "error",
    //       anchorOrigin: {
    //         vertical: "bottom",
    //         horizontal: "right",
    //       },
    //     }
    //   );
    // }
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked((prevState) => !prevState);
  };

  const showProfile = (key) => {
    // setCandidate(key);
    // Profile();
    // setClickedIndex(null);
    navigate(`/enterprise/dashboard/professionals/${key.id}`);
  };

  const Profile = () => {
    setConfirm(false);
    setCandidates(false);
    setProfile(true);
    setMeeting(false);
    setTime(false);
    setAnchorEl(null);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (profile) {
    document.body.style.background = "#F8F8F8";
  } else document.body.style.background = "#fff";

  const items = [    
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">IndexScore Range</p>
          {/* <Slider range defaultValue={[20, 50]} /> */}
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="850"
              value={slidervalue}
              onChange={({ target: { value } }) => {
                onChangeSlider(value);
                const ele = document.querySelector(".buble");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble">{slidervalue}</div>
          </div>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">
            Purpose Alignment Score
          </p>
          {/* <Slider range defaultValue={[0, 100]} /> */}
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="100"
              value={purposeValue}
              onChange={({ target: { value } }) => {
                setPurposeValue(value);
                const ele = document.querySelector(".buble1");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble1">{purposeValue}</div>
          </div>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">Job Relevance </p>
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="100"
              value={relevanceValue}
              onChange={({ target: { value } }) => {
                setRelevanceValue(value);
                // console.log("relevance value", value);
                const ele = document.querySelector(".buble1");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble1">{relevanceValue}</div>
          </div>
        </div>
      ),
      key: "3",
    },
    // {
    //   label: (
    //     <div className="mb-2">
    //       <p
    //         className="mb-2 fm darkGrey fw-semibold d-flex justify-content-between align-items-center"
    //         onClick={() => setLocation(!location)}
    //       >
    //         Location{" "}
    //         <MdOutlineKeyboardArrowUp
    //           className="fs-4 transition"
    //           style={{
    //             transform: location ? "rotate(0deg)" : "rotate(180deg)",
    //           }}
    //         />
    //       </p>
    //       <div
    //         className="d-flex flex-column overflow-hidden transition"
    //         style={{ height: location ? "auto" : "0px" }}
    //       >
    //         <input
    //           type="search"
    //           placeholder="UK, London…"
    //           className="fm dropdown--input text-15 darkGrey"
    //           ref={inputRef}
    //           onChange={handleInputChange}
    //           value={searchTerm}
    //         />
    //         <ul
    //           className="m-0 list-unstyled dropdown__list mt-2 pe-2"
    //           onClick={() => {
    //             setDlocation(!dlocation);
    //           }}
    //         >
    //           {filteredCountries.map((item) => (
    //             <li
    //               key={item.code}
    //               onClick={() => {
    //                 setDlocation(false);
    //               }}
    //               className="text-15 mb-1 pointer dropdown__list__item darkGrey"
    //             >
    //               {item.name}
    //             </li>
    //           ))}
    //         </ul>
    //       </div>
    //     </div>
    //   ),
    //   key: "4",
    // },
  ];

  const [boxes, setBoxes] = useState([
    { selectedProvider: "", selectedImage: "", showInput: false },
  ]);
  const [selectedValue, setSelectedValue] = useState("Choose your provider");

  const options = [
    {
      value: "GreenHouse",
      label: "GreenHouse",
      image: "greenhouse-image-url.jpg",
    },
    { value: "Workday", label: "Workday", image: "workday-image-url.jpg" },
    { value: "BambooHR", label: "BambooHR", image: "bamboohr-image-url.jpg" },
  ];

  const handleProviderChange = (value, index) => {
    const updatedBoxes = [...boxes];
    const selectedOption = options.find((option) => option.value === value);
    updatedBoxes[index].selectedProvider = value;
    updatedBoxes[index].selectedImage = selectedOption
      ? selectedOption.image
      : "";
    updatedBoxes[index].showInput = value !== "";
    setBoxes(updatedBoxes);
    if (selectedOption) {
      setSelectedValue(selectedOption.label);
    }
  };

  const handleKeyChange = (value, index) => {
    const updatedBoxes = [...boxes];
    setBoxes(updatedBoxes);
  };

  const handleDelete = (index) => {
    const updatedBoxes = [...boxes];
    updatedBoxes.splice(index, 1);
    setBoxes(updatedBoxes);
  };

  const handleAddBox = () => {
    setBoxes([
      ...boxes,
      { selectedProvider: "", selectedImage: "", showInput: false },
    ]);
  };

  const navigateTo = (item) => {
    navigate("/enterprise/dashboard/ats-candidates/candidate", {
      state: { item },
    });
  };

  const [currentPage1, setCurrentPage1] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedCandidates.length;

  const onPageChange = (page) => {
    setCurrentPage1(page);
  };

  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  return (
    <div className="edash edash3">
      <style>
        {`:where(.css-dev-only-do-not-override-1e3x2xa).ant-avatar >img {object-fit: contain;
            width: 65%;
            margin: auto;}
        .resume-icon-download{
          text-align:left;
        }
        .linkedin-icon{
          font-size: 28px;
        }
        .resume-icon-download img{
              cursor:pointer;
              width:30px;
          }          
        .marginTop{
        margin-top:100px;
        }
        }`}
      </style>
      <div className="pt-4">
        <div className="">
          <div className={` ${profile ? "bg-lightgrey" : ""}`}>
            {candidates && (
              <div className="Dashboard__container ATS ">
                <div className="Experts__container container-dash pb-4 ">
                  {/* <div className="jobs__content__hiring enterprise d-flex align-items-center justify-content-between p-3 flex-lg-nowrap flex-wrap">
                    <div style={{ marginRight: "50px" }}>
                      <h1 className="text-35 fw-semibold fm">
                        Let’s integrate with your ATS
                      </h1>
                      <p className="jobs__content__hiring__para  fm">
                        There is value in analyzing all candidates
                      </p>
                      <div className="form-check mt-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="terms"
                          disabled={candidatesList.length > 0}
                          onChange={handleCheckboxChange}
                          checked={isCheckboxChecked}
                        />
                        <label
                          className="form-check-label Get__left__content__para Get__right__label ms-2"
                          htmlFor="gridCheck1"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          I/We as a company agree to the{" "}
                          <Link
                            to={"/terms-of-use"}
                            className="Get__right__label__link"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            Terms & Conditions,
                          </Link>
                          <Link
                            to={"/privacy-policy"}
                            className="Get__right__label__link"
                            style={{
                              fontSize: "14px",
                            }}
                          >
                            Privacy Policy{" "}
                          </Link>
                          of IndexScore and permit IndexScore to connect with
                          your ATS platform to collect and score existing
                          candidates.
                        </label>
                      </div>
                    </div>
                    <Link
                      to="#"
                      disabled={candidatesList?.length > 0}
                      onClick={handleClickMerge}
                      className={`jobs__content__hiring__btn fm text-decoration-none ${
                        candidatesList?.length > 0 ? "disabled-link" : ""
                      }`}
                    >
                      <button
                        disabled={candidatesList?.length > 0}
                        className="jobs__content__hiring__btn--button p-2 transition"
                      >
                        Get ATS Candidates
                      </button>
                    </Link>
                  </div> */}
                  <h2 className="text-35 darkBlue fm">Your ATS candidates</h2>
                  <h6 className="darkGrey fw-light text-14 mt-4 fm">
                    Connected ATS
                  </h6>

                  <div className="d-flex flex-wrap gap-xxl-3 gap-2">
                    <div className="ATS__box p-2">
                      <div className="d-flex justify-content-between">
                        {/* <div className="d-flex">
                          <div className="custom-selectbox">
                            <p
                              className="selected-value mb-0 d-inline-block fm transition text-14"
                              style={{
                                color: open
                                  ? " rgb(95, 137, 255)"
                                  : "var(--darkgrey",
                              }}
                            >
                              <BsArrowDown
                                className="custom-select-icon"
                                style={{
                                  transform: open
                                    ? "rotate(180deg)"
                                    : "rotate(0deg)",
                                }}
                              />
                            </p>
                          </div>
                        </div> */}
                        {/* <button
          className="ATS__box__dp bg-white rounded-3 d-flex justify-content-center align-items-center"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <BsThreeDotsVertical className="darkGrey fs-5" />
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <button
              class="dropdown-item fm text-14"
              type="button"
              onClick={handleDelete}
            >
              Delete
            </button>
          </li>
        </ul> */}
                      </div>
                      {candidatesList.length > 0 ||
                      (integrationNameDataVal && integrationPublicToken) ? (
                        <>
                          <h6 className="darkGrey text-14">
                            Your selected provider
                          </h6>
                          <div className="ATS__box__bottom rounded-2 p-2 mt-3 d-flex align-items-center justify-content-center mb-4">
                            <img
                              className="provider-logo"
                              src="/assets/images/enterprises/workable.png"
                            />
                            <h5 className="darkGrey fm  mb-0 text-center">
                              Workable
                            </h5>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            handleClickMerge();
                          }}
                          className="ATS__box__bottom connect__ats  rounded-2 p-2 mt-3 d-flex align-items-center justify-content-center mb-4"
                        >
                          <p className="darkGrey fm text-12 mb-0 text-center">
                            Please select ATS provider
                          </p>
                        </div>
                      )}
                    </div>
                    {/* {boxes.map((boxState, index) => (
                <ATSBox
                  key={index}
                  boxState={boxState}
                  handleProvi derChange={(value) =>
                    handleProviderChange(value, index)
                  }
                  handleKeyChange={(value) => handleKeyChange(value, index)}
                  handleDelete={() => handleDelete(index)}
                />
              ))} */}
                    {/* <div
                      className="ATS__add d-flex justify-content-center align-items-center pointer"
                      onClick={handleAddBox}
                    >
                      <h1 className="mb-0 fs-1 darkGrey fw-light mt-0">+</h1>
                    </div> */}
                  </div>
                </div>
                <Tabs className="jobs__tabs Ejobs__tabs candidates__tabs">
                  <TabList>
                    {/* <Tab>Overview</Tab> */}
                    <Tab>Candidates</Tab>
                  </TabList>
                  {/* <TabPanel>
                    <div className="Experts__container container-dash pb-4">
                      <div className="row">
                        <div className="d-sm-flex candidates__filter3 col-md-4 col-sm-5 pe-sm-0 pb-sm-3 pb-2">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center bg-white">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              // value={searchQuery}
                              // onChange={(e) => setSearchQuery(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search..."
                            />
                          </div>
                        </div>
                        <div className="col-sm-2 col-6 pb-sm-3">
                          <div className="d-flex h-100 justify-content-start filter__right justify-content-lg-between">
                            <Dropdown
                              ref={filterRef}
                              menu={{
                                items,
                                onClick: handleMenuClick,
                              }}
                              onOpenChange={handleOpenChange}
                              open={open1}
                              trigger={["click"]}
                              overlayClassName={"dropdown__filter"}
                            >
                              <button
                                className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition me-sm-2 me-0"
                                onClick={filterDrop}
                                style={{
                                  border: filter ? "0.5px solid #5F89FF" : "",
                                  color: filter ? "#5F89FF" : "",
                                }}
                                id="filter_btn"
                                ref={filterRef}
                              >
                                <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                                Filter
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-5 col-6 pb-sm-3 d-flex justify-content-end">
                          <button className="candidates__filter jobd__btn px-2 d-flex align-items-center justify-content-center h-100 transition">
                            <RxReload className="fs-5" />
                          </button>
                        </div>
                      </div>
                      <div className="row py-4">
                        <div className="col-xxl-4 col-md-6 pe-xl-0 mb-3">
                          <div className="stat border rounded bg-white px-xxl-4 px-3 py-4 h-100">
                            <div className="stat__top d-flex justify-content-between align-items-center">
                              <h3 className="fm darkGrey text-uppercase fs-6 mb-0">
                                Years of experience
                              </h3>
                              <RxEnterFullScreen
                                className="fs-4 pointer"
                                onClick={showModal1}
                              />
                            </div>

                            <div className="d-flex mt-3 pb-3">
                              {loading ? (
                                <Loader />
                              ) : (
                                <>
                                  <ul className="mb-0 list-unstyled stat__left  pe-1">
                                    {ExperienceProgressBars.map((item) => (
                                      <li
                                        className={`fm stat__text darkGrey nowrap fw-semibold ${
                                          item.id ===
                                          ExperienceProgressBars.length
                                            ? ""
                                            : "mb-sm-1"
                                        }`}
                                        key={item.id}
                                      >
                                        {item.businessTitle}{" "}
                                        {item.businessTitle != "" && "Years"}
                                      </li>
                                    ))}
                                  </ul>
                                  <div className="d-flex position-relative w-100 me-3">
                                    
                                    <div className="stat__border-right position-relative">
                                      <span className="stat__index-bottom0">
                                        0
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom1">
                                        50
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        100
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        150
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        200
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        250
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        300
                                      </span>
                                    </div>

                                    
                                    <div className="stat__bars w-100">
                                      {ExperienceProgressBars.map((item) => (
                                        <div
                                          className="d-flex align-items-center"
                                          key={item.id}
                                        >
                                          <Tooltip
                                            title={item.progress}
                                            color={item.color}
                                            placement="right"
                                          >
                                            <div
                                              className={`stat__bar stat__bar${item.id} position-relative`}
                                              style={{
                                                width: `${
                                                  (item.progress / 300) * 100
                                                }%`,
                                                backgroundColor: item.color,
                                              }}
                                            >
                                              {" "}
                                              <span className="stat__text ms-1 midGrey position-absolute stat__index-right">
                                                {item.progress}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="text-center">
                              <span className="fs-6 darkGrey mb-0">Amount</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-4 col-md-6 pe-xxl-0 mb-3">
                          <div className="stat border rounded bg-white px-xxl-4 px-3 py-4 h-100">
                            <div className="stat__top d-flex justify-content-between align-items-center">
                              <h3 className="fm darkGrey text-uppercase fs-6 mb-0">
                                Previous Companies
                              </h3>
                              <RxEnterFullScreen
                                className="fs-4 pointer"
                                onClick={showModal2}
                              />
                            </div>

                            <div className="d-flex mt-3 pb-3">
                              {loading ? (
                                <Loader />
                              ) : (
                                <>
                                  <ul className="mb-0 list-unstyled stat__left  pe-1">
                                    {CompaniesProgressBars.map((item) => (
                                      <li
                                        className={`fm stat__text nowrap darkGrey fw-semibold ${
                                          item.id ===
                                          CompaniesProgressBars.length
                                            ? ""
                                            : "mb-sm-1"
                                        }`}
                                        key={item.id}
                                      >
                                        {item.businessTitle}
                                      </li>
                                    ))}
                                  </ul>
                                  <div className="d-flex position-relative w-100 me-3">
                                    
                                    <div className="stat__border-right position-relative">
                                      <span className="stat__index-bottom0">
                                        0
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom1">
                                        50
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        100
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        150
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        200
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        250
                                      </span>
                                    </div>
                                    <div className="stat__dottedline position-relative">
                                      <span className="stat__index-bottom">
                                        300
                                      </span>
                                    </div>

                                    
                                    <div className="stat__bars w-100">
                                      {CompaniesProgressBars.map((item) => (
                                        <div
                                          className="d-flex align-items-center"
                                          key={item.id}
                                        >
                                          <Tooltip
                                            title={item.progress}
                                            color={item.color}
                                            placement="right"
                                          >
                                            <div
                                              className={`stat__bar stat__bar${item.id} position-relative`}
                                              style={{
                                                width: `${
                                                  (item.progress / 300) * 100
                                                }%`,
                                                backgroundColor: item.color,
                                              }}
                                            >
                                              {" "}
                                              <span className="stat__text ms-1 midGrey position-absolute stat__index-right">
                                                {item.progress}
                                              </span>
                                            </div>
                                          </Tooltip>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="text-center">
                              <span className="fs-6 darkGrey mb-0">Amount</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-4 pe-xxl-2 pe-xl-0 col-md-6 mb-3">
                          <div className="stat bg-white border rounded px-xxl-4 px-3 py-4 h-100">
                            <div className="stat__top d-flex justify-content-between align-items-center mb-3">
                              <h3 className="fm darkGrey text-uppercase fs-6 mb-0">
                                Highest level of Education
                              </h3>
                              <RxEnterFullScreen
                                className="fs-4 pointer"
                                onClick={showModal4}
                              />
                            </div>
                            <div className="d-xl-flex d-md-block d-sm-flex justify-content-between flex-md-wrap pe-2">
                              <CircularProgressBar
                                segments={EducationData}
                                maxValue={EducationData.length}
                              />

                              <div className="d-xl-flex d-md-none d-sm-flex d-none ps-md-0 ps-sm-5">
                                <div className="mt-3 chartslice">
                                  {EducationData.map((item, index) => (
                                    <div
                                      className="d-flex mb-2 align-items-center"
                                      key={index}
                                    >
                                      <div
                                        className="bullet me-1 rounded-circle"
                                        style={{ background: item.color }}
                                      ></div>
                                      <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                        <span className="nowrap">
                                          {item.label}
                                        </span>{" "}
                                        <span className="midGrey nowrap ps-1">
                                          {item.value}% /{" "}
                                          {(
                                            (item.value *
                                              EducationData.length) /
                                            100
                                          ).toFixed(0)}
                                        </span>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="d-xl-none d-md-flex d-sm-none d-flex">
                                <div className="mt-3 chartslice">
                                  {EducationData.slice(0, 6).map((item) => (
                                    <div
                                      className="d-flex mb-2 align-items-center"
                                      key={item.value}
                                    >
                                      <div
                                        className="bullet me-1 rounded-circle"
                                        style={{ background: item.color }}
                                      ></div>
                                      <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                        <span className="nowrap">
                                          {item.label}
                                        </span>{" "}
                                        <span className="midGrey nowrap ps-1">
                                          {item.value}% /{" "}
                                          {(
                                            (item.value *
                                              EducationData.length) /
                                            100
                                          ).toFixed(0)}
                                        </span>
                                      </p>
                                    </div>
                                  ))}
                                </div>
                                {EducationData.length > 6 && (
                                  <div className="mt-3 chartslice">
                                    {EducationData.slice(6, 13).map((item) => (
                                      <div
                                        className="d-flex mb-2 align-items-center"
                                        key={item.value}
                                      >
                                        <div
                                          className="bullet me-1 rounded-circle"
                                          style={{ background: item.color }}
                                        ></div>
                                        <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                                          <span className="nowrap">
                                            {item.label}
                                          </span>{" "}
                                          <span className="midGrey nowrap ps-1">
                                            {item.value}% /{" "}
                                            {(
                                              (item.value *
                                                EducationData.length) /
                                              100
                                            ).toFixed(0)}
                                          </span>
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                    <div className="Experts__container candidates__opt container-dash pb-4 ">
                      <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                        <div className="d-flex justify-content-sm-start justify-content-between">
                          <Dropdown
                            menu={{
                              items: sortingMenus,
                              onClick: handleSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottomLeft"
                            trigger={["click"]}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingMenus.find(
                                      (menu) => menu.key === sortingOption
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                          </Dropdown>
                          <Dropdown
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition me-sm-2 me-0"
                              style={{
                                border: open1 ? "0.5px solid #5F89FF" : "",
                                color: open1 ? "#5F89FF" : "",
                              }}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                        {/* <button
                            onClick={() => {
                              getAllATsCandidates();
                            }}
                            className="candidates__filter px-2 d-flex align-items-center justify-content-center py-2 transition"
                          >
                            {isFetching ? (
                              <Loader />
                            ) : (
                              <RxReload className="fs-5" />
                            )}
                          </button> */}

                        <div className="d-sm-flex justify-content-end mb-md-0 mb-2">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              // value={searchQuery}
                              // onChange={(e) => setSearchQuery(e.target.value)}
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search..."
                            />
                          </div>
                          <button
                            className="bluegradient__btn jobd__btn text-whitefm transition me-2 fm  candidate__score__button"
                            disabled={selectedAtsCandidates.length === 0}
                            // onClick={handleButtonClick}
                            onClick={showModal}
                          >
                            Request Score
                          </button>

                          <button
                            disabled={isFetching}
                            onClick={() => {
                              if (candidatesList.length > 0) {
                                getAllATsCandidates();
                              } else {
                                if (
                                  integrationPublicToken &&
                                  integrationNameDataVal
                                ) {
                                  createATsCandidates();
                                } else {
                                  enqueueSnackbar(
                                    "Please select your provider",
                                    {
                                      variant: "error",
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                      },
                                    }
                                  );
                                }
                              }
                            }}
                            className="candidates__filter jobd__btn text-14 px-4 d-flex mt-sm-0 mt-2 get__candidates__button nowrap align-items-center justify-content-center py-2 transition"
                          >
                            {isFetching ? (
                              <>
                                <span className="nowrap fm">
                                  Get Candidates
                                </span>
                              </>
                            ) : (
                              // <RxReload className="fs-5" />
                              <span className="nowrap fm">Get Candidates</span>
                            )}
                          </button>
                          {/* <button disabled={!localStorage.getItem('atsToken')} onClick={()=>{
                            if(localStorage.getItem('atsToken')){
                              createATsCandidates()

                            }
                          }} className="candidates__filter jobd__btn px-2 d-flex align-items-center justify-content-center h-100 transition">
                            <RxReload className="fs-5" />
                          </button> */}

                          <Modal
                            title="Scoring"
                            className="scoring__popup"
                            open={isModalOpen}
                            footer={null}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <div className="scoring__popup__body">
                              <h4>
                                You’ve selected{" "}
                                <strong>{selectedAtsCandidates.length}</strong>{" "}
                                candidates to score
                              </h4>
                              <p>Approximate cost: $139.50 per candidate</p>
                              <button
                                disabled={
                                  selectedAtsCandidates.length === 0 ||
                                  requestLoader
                                }
                                onClick={() => {
                                  handleButtonClick();
                                }}
                                className="bluegradient__btn text-whitefm transition me-2 w-100"
                              >
                                {requestLoader ? (
                                  <Loader />
                                ) : (
                                  "Score Candidates"
                                )}
                              </button>
                              <p className="scoring__popup__pricing">
                                Learn about our{" "}
                                <Link className="text-decoration-none">
                                  cost & pricing
                                </Link>
                              </p>
                            </div>
                            <div className="scoring__popup__footer">
                              <p>
                                All your login details are stored in your
                                browser, you are able to remove any connected
                                app or API with in your API tab
                              </p>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <div>
                        {isFetching ? (
                          <div className="d-md-flex d-none">
                            {" "}
                            <Loader size="md" />
                          </div>
                        ) : (
                          <>
                            <div className="table__outer table__outer__pro d-md-block d-none rounded-3 position-relative">
                              <Table
                                className="dash__table"
                                columns={tableColumns}
                                dataSource={formattedCandidates}
                                pagination={false}
                                scroll={{
                                  x: 1400,
                                }}
                              />
                            </div>
                            {/* {newFilteredCandidates.length > 10 && (
                              <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                                <button className="fm text-12 table__bottom__btn1">
                                  Load all candidates (
                                  {newFilteredCandidates.length})
                                </button>
                                <button className="fm text-12 table__bottom__btn2">
                                  Scroll to load more{" "}
                                  <HiOutlineArrowDownCircle className="fs-6" />
                                </button>
                              </div>
                            )} */}
                          </>
                        )}
                      </div>
                      <div>
                        <div className="row d-md-none Experts__header transition">
                          <div className="favoriteCandidates pt-3 pb-2">
                            <div className="row bordertop--grey">
                              <div className="col-md-6  d-flex align-items-end pb-3">
                                <h1 className="favoriteCandidates__heading text-dark fm mb-0">
                                  Candidates
                                  <span className="favoriteCandidates__heading--span ps-2">
                                    {newFilteredCandidates?.length}
                                  </span>
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        {isFetching ? (
                          <div className="p-3 d-md-none">
                            <Loader size="md" />
                          </div>
                        ) : newFilteredCandidates.length > 0 ? (
                          newFilteredCandidates.map((item, index) => (
                            <div
                              className={`row d-md-none enterprise h-auto Experts__row ${
                                item.cat === "fav"
                                  ? "candidates__fav"
                                  : "candidates__all"
                              } mx-0 mt-2 py-xl-2 py-4 my-3 transition pointer`}
                              key={item.id}
                            >
                              <div className="col-xl-3 col-12">
                                <div className="d-flex h-100  align-items-center w-100">
                                  <div className="d-flex justify-content-between align-items-start w-100">
                                    <div
                                      className="d-flex align-items-center me-xl-0 me-2"
                                      ref={buttonRef}
                                      onClick={() => handleClick(index)}
                                    >
                                      <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                        <img
                                          src={`/assets/images/userAvatar.svg`}
                                          alt=""
                                          className="candidates__avatar--img img-fluid"
                                        />
                                      </div>
                                      <h6 className="Experts__name fm mb-0 ms-2 d-flex align-items-center">
                                        <h6 className="mb-0 me-1">
                                          {item.first_name} {item.last_name}
                                        </h6>{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-6 px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Source:
                                </span>
                                {item.type}
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-6 px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Veteran:
                                </span>
                                {item.veteran}
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-6 px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3 ">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Contacted:
                                </span>
                                {item.contacted}
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-6 px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3  ">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Onboarded:
                                </span>
                                {item.onboarded}
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-sm-6  px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  IndexScore:
                                </span>
                                {candidate.score_request ? (
                                  candidate.kycVerification ? (
                                    candidate.index_score
                                  ) : (
                                    "Pending"
                                  )
                                ) : candidate.onboarded === "Yes" ? (
                                  <div className="indexScore__box">
                                    <Checkbox
                                      onChange={() =>
                                        handleCheckboxChange1(candidate)
                                      }
                                      checked={selectedAtsCandidates.includes(
                                        candidate
                                      )}
                                    ></Checkbox>
                                    <span>Request</span>
                                  </div>
                                ) : (
                                  <div className="indexScore__box">
                                    <Checkbox disabled></Checkbox>
                                    <span>Request</span>
                                  </div>
                                )}
                                {/* {item.index_score} */}
                              </div>
                              <div className="col-xl-1 col-sm-6 col-lg-3 col-md-6 text-14 text-capitalize px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Purpose Alignment:
                                </span>
                                <div>
                                  {userData.subscription.name === "Predict" ||
                                  userData.subscription.name === "Scale" ? (
                                    <div>
                                      {candidate.purposeScore ? (
                                        <span
                                          className={`tag-${candidate.purpose_Score} fm tag-14 text-capitalize rounded-pill`}
                                        >
                                          {candidate.purposeScore + "%"}
                                        </span>
                                      ) : (
                                        "Pending"
                                      )}
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center ">
                                      <Tooltip
                                        title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                                        placement="top"
                                      >
                                        <CiLock className="lock-item" />
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-xl-1 text-14 text-capitalize col-md-3 col-6 px-xl-0 d-flex align-items-center justify-content-xl-center mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Relevance:
                                </span>
                                {item.relevance_score === "Pending" ? (
                                  "Pending"
                                ) : (
                                  <span
                                    className={`tag-${item.relevance_score} fm tag-14 text-capitalize rounded-pill`}
                                  >
                                    {item.relevance_score}
                                  </span>
                                )}
                              </div>
                              <div className="col-xl-2 col-sm-6 text-14 text-capitalize ps-xl-0 col-lg-3 d-flex  justify-content-xl-center justify-content-end flex-column mt-xl-0 mt-3">
                                <span className="fm darkGrey fw-semibold me-1 d-xl-none">
                                  Job Role/Id:
                                </span>
                                <div
                                  onClick={() => {
                                    navigateTo(item.item);
                                  }}
                                  className="word-wrap"
                                >
                                  <span title={item?.jobDetails}>
                                    {item?.jobDetails}
                                  </span>
                                  <span className="job__id__trim word-wrap h-100 w-100">
                                    {item?.jobId}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="d-md-none d-flex justify-content-center fm py-3">
                            <p className="darkGrey">No record exist.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            )}
            {/* modal1 */}
            <Modal
              title="YEARS OF EXPERIENCE"
              open={experienceModal}
              centered
              zIndex={20000}
              onCancel={() => setExperienceModal(false)}
              className="stats__modal"
              footer={null}
            >
              <div className="stat  bg-white pt-4">
                <div className="d-flex h-100 pb-3 modal__stat">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <ul className="mb-0 list-unstyled stat__left  pe-1">
                        {ExperienceProgressBars.map((item) => (
                          <li
                            className={`fm stat__text darkGrey nowrap fw-semibold ${
                              item.id === ExperienceProgressBars.length
                                ? ""
                                : "mb-sm-1"
                            }`}
                            key={item.id}
                          >
                            {item.businessTitle} Years
                          </li>
                        ))}
                      </ul>
                      <div className="d-flex position-relative w-100 me-3">
                        {/* dottedlines */}
                        <div className="stat__border-right position-relative">
                          <span className="stat__index-bottom0">0</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom1">50</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom">100</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom">150</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom">200</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom">250</span>
                        </div>
                        <div className="stat__dottedline position-relative">
                          <span className="stat__index-bottom">300</span>
                        </div>

                        {/* topbars */}
                        <div className="stat__bars w-100">
                          {ExperienceProgressBars.map((item) => (
                            <div
                              className="d-flex align-items-center"
                              key={item.id}
                            >
                              <Tooltip
                                title={item.progress}
                                color={item.color}
                                placement="right"
                              >
                                <div
                                  className={`stat__bar stat__bar${item.id} position-relative`}
                                  style={{
                                    width: `${(item.progress / 300) * 100}%`,
                                    backgroundColor: item.color,
                                  }}
                                >
                                  {" "}
                                  <span className="stat__text ms-1 midGrey position-absolute stat__index-right">
                                    {item.progress}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="text-center mt-3">
                  <span className="fs-6 darkGrey mb-0">Amount</span>
                </div>
              </div>
            </Modal>

            {/* modal2 */}
            <Modal
              title="previous companies"
              open={companiesModal}
              centered
              zIndex={20000}
              onCancel={() => setCompaniesModal(false)}
              className="stats__modal"
              footer={null}
            >
              <div className="stat bg-white pt-4">
                <div className="d-flex h-100 pb-3 modal__stat">
                  <ul className="mb-0 list-unstyled stat__left  pe-1">
                    {CompaniesProgressBars.map((item) => (
                      <li
                        className={`fm stat__text nowrap darkGrey fw-semibold ${
                          item.id === ExperienceProgressBars.length
                            ? ""
                            : "mb-sm-1"
                        }`}
                        key={item.id}
                      >
                        {item.businessTitle}
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex position-relative w-100 me-3">
                    {/* dottedlines */}
                    <div className="stat__border-right position-relative">
                      <span className="stat__index-bottom0">0</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom1">50</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom">100</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom">150</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom">200</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom">250</span>
                    </div>
                    <div className="stat__dottedline position-relative">
                      <span className="stat__index-bottom">300</span>
                    </div>

                    {/* topbars */}
                    <div className="stat__bars w-100">
                      {CompaniesProgressBars.map((item) => (
                        <div
                          className="d-flex align-items-center"
                          key={item.id}
                        >
                          <Tooltip
                            title={item.progress}
                            color={item.color}
                            placement="right"
                          >
                            <div
                              className={`stat__bar stat__bar${item.id} position-relative`}
                              style={{
                                width: `${(item.progress / 300) * 100}%`,
                                backgroundColor: item.color,
                              }}
                            >
                              {" "}
                              <span className="stat__text ms-1 midGrey position-absolute stat__index-right">
                                {item.progress}
                              </span>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="text-center mt-3">
                  <span className="fs-6 darkGrey mb-0">Amount</span>
                </div>
              </div>
            </Modal>

            {/* modal 3 */}
            <Modal
              title="HIGHEST LEVEL OF EDUCATION"
              open={educationModal}
              centered
              zIndex={20000}
              onCancel={() => setEducationModal(false)}
              className="stats__modal"
              footer={null}
            >
              <div className="stat bg-white pt-4">
                <CircularProgressBar
                  segments={EducationData}
                  maxValue={EducationData.length}
                />

                <div className="d-flex">
                  <div className="mt-3 chartslice">
                    {EducationData.slice(0, 6).map((item) => (
                      <div
                        className="d-flex mb-2 align-items-center"
                        key={item.value}
                      >
                        <div
                          className="bullet me-1 rounded-circle"
                          style={{ background: item.color }}
                        ></div>
                        <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                          <span className="nowrap">{item.label}</span>{" "}
                          <span className="midGrey nowrap ps-1">
                            {item.value}% /{" "}
                            {(
                              (item.value * EducationData.length) /
                              100
                            ).toFixed(0)}
                          </span>
                        </p>
                      </div>
                    ))}
                  </div>
                  {EducationData.length > 6 && (
                    <div className="mt-3 chartslice">
                      {EducationData.slice(6, 13).map((item) => (
                        <div
                          className="d-flex mb-2 align-items-center"
                          key={item.value}
                        >
                          <div
                            className="bullet me-1 rounded-circle"
                            style={{ background: item.color }}
                          ></div>
                          <p className="mb-0 darkGrey fm text-10 fw-semibold d-flex">
                            <span className="nowrap">{item.label}</span>{" "}
                            <span className="midGrey nowrap ps-1">
                              {item.value}% /{" "}
                              {(
                                (item.value * EducationData.length) /
                                100
                              ).toFixed(0)}
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ATSCandidates;
