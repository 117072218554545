import React, { useState, useContext } from "react";
import "./style.css";
import { Upload, Button } from "antd";
import { Link } from "react-router-dom";
import { RxArrowTopRight } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

import PasswordStrengthBar from "react-password-strength-bar";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import { isEmail, isLinkedIn, isPassword } from "../../Utilities/utilities";
import { MyContext } from "../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { Loader, Uploader } from "rsuite";
import {
  professionalSignup,
  uploadFile,
} from "../../services/auth";
import "rsuite/dist/rsuite.min.css";


const SignupAsCandidate = () => {
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const { clearContextData } = useContext(MyContext);

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    referenceSource: "",
    linkedinPublicUrl: "",
    password: "",
    confirmPassword: "",
    loading: false,
    invalidEmail: false,
    invalidConfirmPassword: false,
    invalidPassword: false,
    invalidPhone: false,
    files: null,
    required: false,
  });
  const {
    loading,
    required,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    linkedinPublicUrl,
    password,
    confirmPassword,
    invalidConfirmPassword,
    invalidEmail,
    invalidPassword,
    files,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !firstName ||
    !lastName ||
    !emailAddress ||
    !phoneNumber ||
    !linkedinPublicUrl ||
    isLinkedIn(linkedinPublicUrl) ||
    !password ||
    !confirmPassword ||
    files == null ||
    password !== confirmPassword;

    const handleSignup = async () => {
      clearContextData();
    
      try {
        if (disabled) {
          handleChange("required", true);
          return;
        }
    
        handleChange("required", false);
        handleChange("loading", true);
    
        const signupPayload = {
          firstName,
          lastName,
          emailAddress,
          phoneNumber,
          linkedinPublicUrl,
          password,
          usertype: "professional",
        };
    
        const signupResponse = await professionalSignup(signupPayload);
    
        if (signupResponse.data.statusCode === 1) {
          if (files) {
            await uploadFiles(signupResponse.data.data._id, files);
          }
    
          handleChange("loading", false);
          setSubmit(!submit);
    
          enqueueSnackbar("Verification link sent on your email!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        handleSignupError(error);
      }
    };
    
    const uploadFiles = async (_id, files) => {
      const formData = new FormData();
      formData.append("_id", _id);
      formData.append("usertype", "professional");
      formData.append("files", files[0].blobFile);
      await uploadFile(formData);
    };
    
    const handleSignupError = (error) => {
      handleChange("loading", false);
    
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    };
    


  return (
    <div className="sc top-padding">
      <div className="main-padding pb-5">
        <div className="container-lg px-0">
          <div>
            <h1 className="fm darkBlue fs-1 fw-light">
              Welcome, let’s create your account
            </h1>
            <div className="row mt-5">
              {!submit ? (
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-6 pe-sm-0 mb-3">
                      <label htmlFor="fname" className="fm darkGrey mb-1">
                        First name
                      </label>
                      <input
                        id="fname"
                        type="text"
                        className="form-control"
                        placeholder="Your first name"
                        onChange={(value) =>
                          handleChange("firstName", value.target.value)
                        }
                      />
                      {required && !firstName && (
                        <div>
                          <small className="error__message fm ps-1">
                            {" "}
                            First Name is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-sm-6 mb-3">
                      <label htmlFor="lname" className="fm darkGrey mb-1">
                        Last name
                      </label>
                      <input
                        id="lname"
                        type="text"
                        className="form-control"
                        placeholder="Your last name"
                        onChange={(value) =>
                          handleChange("lastName", value.target.value)
                        }
                      />
                      {required && !lastName && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Last Name is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="email" className="fm darkGrey mb-1">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Your email address"
                        onChange={(value) =>
                          handleChange("emailAddress", value.target.value)
                        }
                      />
                      {emailAddress && isEmail(emailAddress) && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Email must be a valid email address !
                          </small>
                        </div>
                      )}
                      {required && !emailAddress && (
                        <div>
                          <small className="error__message fm">
                            Email Address is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-6 mb-3">
                      <label htmlFor="phone" className="fm darkGrey ">
                        Phone Number
                      </label>
                      <PhoneInput
                        id="phone"
                        country={'us'}
                        className=""
                        placeholder="Enter your phone number"
                        value={phoneNumber}
                        onChange={(value) =>
                          handleChange("phoneNumber", value)
                        }
                      />
                      {required && !phoneNumber && (
                        <div>
                          <small className="error__message fm">
                            Phone number is required!
                          </small>
                        </div>
                      )}
                    </div>

                    <div className="col-12 mb-3">
                      <label htmlFor="pass" className="fm darkGrey mb-1">
                        Password
                      </label>
                      <input
                        id="pass"
                        type="password"
                        className="form-control"
                        placeholder="Must be 12 characters"
                        onChange={(value) =>
                          handleChange("password", value.target.value)
                        }
                      />
                      {password && isPassword(password) && (
                        <div>
                          {/* <small >passWord is required</small> */}
                          <small className="error__message fm">
                            Must be at least 8 characters long and include upper and
                            lowercase letters and at least one number
                          </small>
                        </div>
                      )}
                      {required && !password && (
                        <div>
                          <small className="error__message fm">
                            Password is required!
                          </small>
                        </div>
                      )}
                      <PasswordStrengthBar
                        style={{ marginTop: 10 }}
                        password={password}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="pass" className="fm darkGrey mb-1">
                        Confirm Password
                      </label>
                      <input
                        id="pass"
                        type="password"
                        className="form-control"
                        placeholder="Must be 12 characters"
                        onChange={(value) =>
                          handleChange("confirmPassword", value.target.value)
                        }
                      />
                      {confirmPassword && password !== confirmPassword && (
                        <div>
                          <small className="error__message fm">
                            Passwords must match!
                          </small>
                        </div>
                      )}
                      {required && !confirmPassword && (
                        <div>
                          <small className="error__message fm">
                            Confirm password is required!
                          </small>
                        </div>
                      )}

                    </div>
                    <div className="col-12 mb-3">
                      <label htmlFor="link" className="fm darkGrey mb-1">
                        LinkedIn
                      </label>
                      <input
                        id="link"
                        type="text"
                        className="form-control"
                        placeholder="Paste your linkedIn profile URL"
                        onChange={(value) =>
                          handleChange("linkedinPublicUrl", value.target.value)
                        }
                      />
                      {linkedinPublicUrl && isLinkedIn(linkedinPublicUrl) && (
                        <div>
                          <small className="error__message fm">
                            {" "}
                            Use a valid linkedin profile!
                          </small>
                        </div>
                      )}
                      {required && !linkedinPublicUrl && (
                        <div>
                          <small className="error__message fm">
                            Linkedin public profile is required!
                          </small>
                        </div>
                      )}
                    </div>
                    <div className="col-12 mb-3">
                      {/* <label htmlFor="pass" className="fm darkGrey">
                        Supporting documents:
                      </label>
                      <p className="fm darkGrey fw-light mb-0 pb-1">
                        Video or documents must be under 100MB.
                      </p>                     */}
                      <Uploader
                        listType="picture-text"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        action="//jsonplaceholder.typicode.com/posts/"
                        onChange={(value) => handleChange("files", value)}
                        renderFileInfo={(file, fileElement) => {
                          return (
                            <div>
                              <span>File Name: {file.name}</span>
                              <p style={{ marginLeft: 13 }}>
                                File type: {file.blobFile.type}
                              </p>
                            </div>
                          );
                        }}
                        className="pt-4"
                      >
                        <div>
                          <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                            Upload your resume / CV
                          </Button>
                          <small className="Get__right__upload__small ps-sm-3">
                            File formats: Word or PDF
                          </small>
                        </div>
                      </Uploader>
                      {required && !files && (
                        <div>
                          <small className="error__message fm">
                            Resume is required!
                          </small>
                        </div>
                      )}

                      <div className="mt-4">
                        <p className="fm darkGrey text-14">

                          <label
                            className="form-check-label Get__left__content__para Get__right__label d-flex"
                            htmlFor="gridCheck1"
                          >
                            <input
                              style={{
                                marginRight: '10px'
                              }}
                              className="form-check-input mr-2"
                              type="checkbox"
                              id="terms"
                            />
                            <span style={{
                              width: "90%"
                            }}
                            >
                              By clicking the button below, I agree to IndexScore’s{" "}
                              <Link
                                to={"/terms-of-use"}
                                className="text-decoration-none darkGrey fw-semibold"
                              >
                                Terms of Service
                              </Link>
                              , including{" "}
                              <Link
                                to={"privacy-policy"}
                                className="text-decoration-none darkGrey fw-semibold"
                              >
                                Privacy Policy
                              </Link>
                              .
                            </span>
                          </label>
                        </p>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={loading}
                          className={`Get__left__content__para Get__right__button Get__right__button--blue me-3`}
                          // onClick={() => setSubmit(!submit)}
                          onClick={() => handleSignup()}
                        >
                          {loading ? <Loader /> : "Continue"}
                        </button>
                        <small className="fm darkGrey">
                          Already joined?{" "}
                          <Link
                            to={"/log-in"}
                            className="fm darkGrey fw-semibold text-decoration-none"
                          >
                            Sign in
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <h1 className="fm blue fw-light fs-4 pb-2">
                    Thank you for for signing up!
                  </h1>
                  <p className="fm darkGrey text-14 sc__right__submitext">
                    You will shortly receive an email from IndexScore.com to
                    authenticate your account to continue to your account you
                    can then set-up your profile.
                  </p>
                  <p className="fm midGrey text-14 pb-4">
                    Email sent to: {state ? emailAddress : ' '}
                  </p>
                  {/* <p className="fm darkGrey text-14 fw-light">
                    Did not receive your email? Please{" "}
                    <Link to={"/"} className="darkGrey fw-normal">
                      click here
                    </Link>{" "}
                    to resend.
                  </p> */}
                </div>
              )}

              <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center mt-lg-0 mt-3">
                <div className="sc__right rounded-3  d-flex flex-column justify-content-between">
                  <div className="px-3 pt-4 pb-3">
                    <h1 className="fm text-white fs-2 mb-0">
                      Not a job Candidate?
                    </h1>
                    <h1 className="fs-2 fm text-white fw-light">
                      Need an Enterprise account?
                    </h1>
                    <p className="fm text-white fw-light">
                      Why join
                      <Link
                        className="fm text-white fw-normal"
                        to={"http://www.indexscore.com/"}
                      > IndexScore</Link> as an enterprise?{" "}

                      Sign-up for a
                      {" "}
                      <Link
                        className="fm text-white fw-normal"
                        to={"/book-a-demo"}
                      >
                        guided demo{" "}
                      </Link>
                      and see IndexScore in action!
                    </p>
                  </div>

                  <div className="d-flex justify-content-end align-items-end ms-3">
                    <img
                      src="/assets/images/signup_group.png"
                      alt=""
                      className="sc__right--img ms-auto mb-3"
                    />
                  </div>
                  <Link
                    to={"/enterprise/signup"}
                    className="text-decoration-none text-white"
                  >
                    <div className="sc__right__icon rounded-circle d-flex justify-content-center align-items-center transition">
                      <RxArrowTopRight className="fs-2 text-white transition" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupAsCandidate;
