import React, { useState, useContext } from "react";
import { Button } from "antd";
// import { Link } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Loader, Uploader } from "rsuite";
import { uploadVideo, updateEnterprise } from "../../../services/auth";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";

const prolist = [
  {
    id: 1,
    text: "What are your unique skills?",
  },
  {
    id: 2,
    text: "What is your working style?",
  },
  {
    id: 3,
    text: "What do you want from a new role?",
  },
];

const enterlist = [
  {
    id: 1,
    text: "What your unique mission is - ",
  },
  {
    id: 2,
    text: "What it’s like to work at your company - ",
  },
  {
    id: 3,
    text: "Who you’re looking for - ",
  },
];

const Intro = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);
  const myContext = useContext(MyContext);
  const { clearContextData } = useContext(MyContext);
  const myData = myContext;

  const [state, setState] = useState({
    files: "",
    loading: false,
    loading2: false,
    required: false,
  });

  const { files, loading, loading2, required } = state;
  const handleChange = (key, value) => {
    
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const disabled = !files;


  let res;
  var resp;

  var aidata;
  var getFBdata = myContext.contextData.ai_data.fb;

  const handleUpload = async () => {
    if (props.page == "/enterprise/signup/submit") {
      handleChange("loading", true);
      if (files) {
        
        const formData = new FormData();
        formData.append("_id", myContext.contextData._id);
        formData.append("usertype", "enterprise");
        formData.append("files", files[0].blobFile);
        resp = await uploadVideo(formData);
        console.log(
          "response of upload video enterprise" + JSON.stringify(resp)
        );
        if (resp.data.statusCode == 1) {
          handleChange("loading", false);
          aidata = resp.data.data.ai_data;
          aidata.fb = getFBdata;
          aidata.fb_sentiment = "";
          aidata.insta_sentiment = "";
          aidata.twitter_sentiment = "";
          console.log("getFBdata" + JSON.stringify(getFBdata));
          console.log("aidata video may say" + JSON.stringify(aidata));
          var payloadUpdate = {
            ai_data: aidata,
          };
          myContext.updateContextData(payloadUpdate);
          enqueueSnackbar("Video Uploaded!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    } else {
      if (files) {
        
        handleChange("loading", true);
        const formData = new FormData();
        formData.append("_id", myContext.contextData._id);
        formData.append("usertype", "professional");
        formData.append("files", files[0].blobFile);
        resp = await uploadVideo(formData);
        console.log("response of upload video" + JSON.stringify(resp));
        if (resp.data.statusCode == 1) {
          aidata = resp.data.data.ai_data;
          var elevatorPitch = resp.data.data.elevatorPitch;
          aidata.fb = getFBdata;
          console.log("getFBdata" + JSON.stringify(getFBdata));
          console.log("aidata video may say" + JSON.stringify(aidata));
          var payloadUpdate = {
            ai_data: aidata,
            elevatorPitch: elevatorPitch,
          };
          myContext.updateContextData(payloadUpdate);
          handleChange("loading", false);
          enqueueSnackbar("Video Uploaded!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    }
  };

  const handleUpdate = async () => {
    handleChange("loading2", true);
    if (!files) {
      handleChange("required", true);
      enqueueSnackbar("Video is required", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } else {
      handleChange("required", false);
      if (props.page == "/enterprise/signup/submit") {
        console.log("final payload" + JSON.stringify(myContext.contextData));
        res = await updateEnterprise(myContext.contextData);
       

        if (res.data.statusCode == 1) {
          // localStorage.removeItem(myContext.contextData);
          handleChange("loading2", false);
          enqueueSnackbar("Enterprise Profile Completed!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          clearContextData();
          navigate(props.page);
        } else {
        }
      } else {
        navigate(props.page);
      }
    }
  };

  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Video Introduction
          </h1>
          {props.pagename === "professional" && (
            <div>
              <p className="Get__left__content__para Signup__para mb-5">
                Our goal is to get you hired in days and your elevator pitch is
                critical input to the process; first impression is important. In
                less than two minutes:
              </p>
              {prolist.map((item) => (
                <p
                  className="Get__left__content__para Signup__para mb-1"
                  key={item.id}
                >
                  {item.text}
                </p>
              ))}
            </div>
          )}

          {props.pagename === "enterprise" && (
            <div>
              <p className="Get__left__content__para Signup__para mb-5">
                IndexScore's primary objective is to help organizations hire the
                best and qualified candidates. You get to know our candidates
                via their purpose video presentations, and we would like for you
                to share your organization's purpose to ensure alignment. We
                have provided three questions as a guide as you share your
                employee value proposition (EVP).
              </p>
              <p className="Get__left__content__para Signup__para fw-light mb-2">
                Please upload a two minutes video that tells us and the
                candidates:
              </p>
              {enterlist.map((item) => (
                <p
                  className="Get__left__content__para Signup__para mb-1"
                  key={item.id}
                >
                  {item.text}
                </p>
              ))}
            </div>
          )}

          <p className="Get__left__content__para Get__right__para fw-light mt-4 mb-2">
            Introduction video:
          </p>
          <p className="Get__left__content__para Signup__para mb-5">
            Video must be up to 2 minutes in length and under 100MB.
          </p>
          <Uploader
            listType="picture-text"
            accept="video/mp4,video/mov,video/wmv"
            action="//jsonplaceholder.typicode.com/posts/"
            onChange={(value) => handleChange("files", value)}
            renderFileInfo={(file, fileElement) => {
              return (
                <div>
                  <span>File Name: {file.name}</span>
                  <p style={{ marginLeft: 13 }}>
                    File type: {file.blobFile.type}
                  </p>
                </div>
              );
            }}
            className="pt-4"
          >
            <div>
              <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                Browse your video
              </Button>
              <small className="Get__right__upload__small ps-sm-3">
                File formats: MOV,MP$ or WMV
              </small>
            </div>
          </Uploader>
          <div className="row px-lg-2">
            <div className="col-sm-4 ps-lg-1 pe-sm-1 mb-3">
              <div className="d-flex flex-sm-row flex-column">
                <button
                  onClick={() => handleUpload()}
                  type="button"
                  className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-5 me-4`}
                >
                  {/* <Link
                to={`${props.page}`}
                className="text-decoration-none text-white"
              > */}
                  {loading ? <Loader /> : "Upload Video"}

                  {/* </Link> */}
                </button>
              </div>
            </div>
            <div className="col-sm-6 ps-lg-1 pe-sm-1 mb-3">
              <div className="d-flex flex-sm-row flex-column">
                <button
                  onClick={() => handleUpdate()}
                  type="button"
                  className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-5 me-4`}
                >
                  {required && !files && (
                    <div>
                      <small className="error__message">
                        Video is required!
                      </small>
                    </div>
                  )}
                  {/* <Link
                to={`${props.page}`}
                className="text-decoration-none text-white"
              > */}
                  {props.page == "/enterprise/signup/submit" ? (
                    loading2 ? (
                      <Loader />
                    ) : (
                      "Submit"
                    )
                  ) : loading2 ? (
                    <Loader />
                  ) : (
                    "Continue"
                  )}

                  {/* </Link> */}
                </button>
              </div>
            </div>
          </div>

          {/* {props.color==="green" && 
            <Link
              // to={"/enterprise/signup/submit"}
              className="text-decoration-none text-white"
            > */}
          {/* <button
              className={`Get__left__content__para Get__right__button Get__right__button--darkGrey mt-sm-5 mt-3`}
            >
              Next
            </button> */}
          {/* </Link>} */}
        </div>
      </div>
    </div>
  );
};

export default Intro;
