import React, { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

const SignupOptions = () => {
  const [pro, setPro] = useState(true);
  const [entr, setEntr] = useState(false);
  const [width, setWidth] = useState("64%");

  useEffect(() => {
    if (window.innerWidth > 992) {
      setWidth("64%");
      setPro(true);
      setEntr(false);
    } else {
      setWidth("100%");
      setEntr(true);
    }
  }, []);

  const funcPro = () => {
    if (window.innerWidth > 992) {
      setPro((flag) => !flag);
      setEntr((flag) => !flag);
    } else {
      setPro(true);
      setEntr(true);
    }
  };
  return (
    <div className="sc so top-padding">
      <div className="main-padding pb-5">
        <div className="container-xl px-0">
          <h1 className="fm text-center fs-1 darkBlue fw-light">
            Your next opportunity begins with IndexScore
          </h1>

          <div className="d-lg-flex gap-3 mt-5">
            <div
              style={{
                width: pro ? width : "36%",
                background: pro ? "" : "black",
              }}
              className="so__prof rounded-3 mb-lg-0 mb-3 p-3 pt-5 pe-sm-0 pe-3 d-flex justify-content-between align-items-md-center align-items-end pointer"
              onClick={funcPro}
            >
              <div className="w-100 mb-3 d-flex flex-column justify-content-between h-100">
                <div>
                  <h3
                    className={`${
                      pro ? "fs-3" : "fs-4"
                    } fm text-white fw-normal`}
                  >
                    For Candidates
                  </h3>
                  <p
                    className={`sc__prof__para sc__prof__para2 fm fw-light text-14 text-white ${
                      pro ? "d-block" : "d-none"
                    }`}
                  >
                    Every job candidate is unique, and a resume doesn’t do a
                    great a job sharing our unique background and experiences.
                    IndexScore makes you unique!
                  </p>
                </div>
                <div>
                  <div className={`ops ${pro ? "d-block" : "d-none"}`}>
                    <div className="mb-1 d-flex align-items-start">
                      <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        Fair and Unbiased Hiring Process
                      </span>
                    </div>
                    <div className="mb-1 d-flex align-items-start">
                    <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        No More Unnecessary Job Applications and Interviews
                      </span>
                    </div>
                    <div className="mb-1 d-flex align-items-start">
                    <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        One Comprehensive IndexScore for Every Job Opportunity
                      </span>
                    </div>
                  </div>
                  <p
                    className={`sc__prof__para fm mb-0 fw-light text-14 text-white ${
                      pro ? "d-none" : "d-block"
                    }`}
                  >
                    Every job candidate is unique, and a resume doesn’t do a
                    great a job sharing our unique background and experiences.
                    IndexScore makes you unique!
                  </p>
                  <Link
                    to={"/professional/signup"}
                    className="text-decoration-none transition text-white text-14 d-inline-flex mt-3"
                  >
                    <div
                      style={{ padding: pro ? "7px 10px 7px 13px" : "5px" }}
                      className={`${
                        pro ? "rounded-pill" : "rounded-circle"
                      } so__prof__link`}
                    >
                      <span className={`${pro ? "d-inline" : "d-none"}`}>
                        Sign-up now
                      </span>{" "}
                      <BsArrowRight
                        className="fs-3 transition"
                        style={{
                          transform: pro ? "rotate(0deg)" : "rotate(-45deg)",
                        }}
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <img
                src="/assets/images/signup_pro.png"
                alt=""
                className={`so_prof--img d-sm-block d-none`}
                style={{ opacity: pro ? "1" : "0" }}
              />
            </div>

            <div
              style={{
                width: entr ? width : "36%",
                background: entr ? "" : "black",
              }}
              className="so__prof so__prof2 rounded-3 p-3 pt-5 pe-sm-0 pe-3 d-flex justify-content-between align-items-md-center align-items-end pointer"
              onClick={funcPro}
            >
              <div className="w-100 mb-3 d-flex flex-column justify-content-between h-100">
                <div>
                  <h3
                    className={`${
                      entr ? "fs-3" : "fs-4"
                    } fm text-white fw-normal`}
                  >
                    Ready for your next opportunity?
                  </h3>
                  <p
                    className={`sc__prof__para sc__prof__para2 fm fw-light text-14 text-white ${
                      entr ? "d-block" : "d-none"
                    }`}
                  >
                    Some text about professional account goes here over a line
                    or two. Some text about professional account goes here over
                    a line or two.
                  </p>
                </div>
                <div>
                  <div className={`ops ${entr ? "d-block" : "d-none"}`}>
                    <div className="mb-1 d-flex align-items-start">
                    <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        Hire and Onboard Qualified Candidates in 5 Business Days
                      </span>
                    </div>
                    <div className="mb-1 d-flex align-items-start">
                    <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        Score Qualified Applicants, Not all Applicants
                      </span>
                    </div>
                    <div className="mb-1 d-flex align-items-start">
                    <div>
                        <BsCheckLg className="main fs-5 me-2 mb-2" />
                      </div>
                      <span className="text-white fm text-13 fw-light">
                        Integrate with Your Existing ATS in Minutes
                      </span>
                    </div>
                  </div>
                  <p
                    className={`sc__prof__para fm mb-0 fw-light text-14 text-white ${
                      entr ? "d-none" : "d-block"
                    }`}
                  >
                    You are closer to your next opportunity than you think;
                    IndexScore connects candidates and companies faster than any
                    other service on the market.
                  </p>
                  <Link
                    to={"/enterprise/signup"}
                    className="text-decoration-none transition text-white text-14 d-inline-flex mt-3"
                  >
                    <div
                      style={{ padding: entr ? "7px 10px 7px 13px" : "5px" }}
                      className={`${
                        entr ? "rounded-pill" : "rounded-circle"
                      } so__prof__link`}
                    >
                      <span className={`${entr ? "d-inline" : "d-none"}`}>
                        Sign-up now
                      </span>{" "}
                      <BsArrowRight
                        className="fs-3 transition"
                        style={{
                          transform: entr ? "rotate(0deg)" : "rotate(-45deg)",
                        }}
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <img
                src="/assets/images/signup_ent.png"
                alt=""
                className={`so_prof--img so_prof--img2 d-sm-block d-none`}
                style={{ opacity: entr ? "1" : "0" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupOptions;
