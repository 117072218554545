import React,{useEffect} from "react";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ESection2 from "../Launch/Enterprises/ESection2";
// import SavingContainer from "../Launch/SavingCalculator/SavingContainer";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
import UESection1 from "../UpdatedFrontend/EnterprisePage/UESection1.jsx";
import UESection2 from "../UpdatedFrontend/EnterprisePage/UESection2.jsx";
import UESection3 from "../UpdatedFrontend/EnterprisePage/UESection3.jsx";

const Individuals = () => {
  useEffect(() => {
    document.body.classList.add('enterprise')
    return () => {
      document.body.classList.remove('enterprise')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <UESection1 />
      <UESection2 />
      <UESection3 />
      {/* <SavingContainer /> */}
      <ESection2 />
      <Footer />
    </>
  );
};

export default Individuals;
