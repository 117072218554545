import React from "react";
import { Switch } from 'antd';
import './style.css'

const API = () => {
  return (
    <div className="API edash mt-4 pb-5">
      <div className="Dashboard__container__content">
        <h4 className="YDetail__heading darkBlue fs-2 fm ">Connected Apps/APIs</h4>
        <p className="darkGrey fm pb-4">
        Here are some of our external partners we work with.
        </p>
        <div className="row mb-3">
                <div className="col-md-12">
                  <h5 className="YDetail__heading darkBlue ">Recommended</h5>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/persona.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Persona</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/criteria.png" /> </div>
                      <div><button className="connect__button connected">Connected</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Criteria</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                    <div><img src="/assets/images/api/accurate.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Accurate</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col-md-12">                  
                  <h5 className="YDetail__heading darkBlue ">Other Available APIs</h5>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/greenhouse.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Greenhouse</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/slack.png" /> </div>
                      <div><button className="connect__button connected">Connected</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Slack</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                    <div><img src="/assets/images/api/workable.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Workable</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/teams.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Microsoft Teams</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                      <div><img src="/assets/images/api/google.png" /> </div>
                      <div><button className="connect__button connected">Connected</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Google Suite</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="api__boxes">
                    <div className="api__box__header">
                    <div><img src="/assets/images/api/workday.png" /> </div>
                      <div><button className="connect__button pending">Connect</button></div>
                    </div>
                    <div className="api__box__body">
                      <h4>Workday</h4>
                      <p>APIs and apps integrated with IndexScore click learn more on each one to find out more about our integrations</p>
                    </div>
                  </div>
                </div>
              </div>
        {/* <div className="d-md-flex justify-content-between API__row border-bottom pb-3">
        <div className="d-flex API__row__left align-items-center justify-content-md-start justify-content-between">
            <img src="/assets/images/Dashboard/Persona.png" alt="" className="API__row__left--img px-3 pb-3 pt-4" />
            <div className="API__row__right d-flex d-md-none align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Learn more</p>
                <Switch defaultChecked/>
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-block d-none">We use Persona for real-time Government issued ID verification</p>
            </div>
            <div className="API__row__right d-md-flex d-none align-items-md-center align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Verified</p>
                <Switch defaultChecked/>
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-none d-block">We use Persona for real-time Government issued ID verification</p>
        </div>
        <div className="d-md-flex justify-content-between API__row border-bottom py-3">
        <div className="d-flex API__row__left align-items-center justify-content-md-start justify-content-between">
            <img src="/assets/images/Dashboard/Criteria.svg" alt="" className="API__row__left--img px-3 pb-3 pt-4" />
            <div className="API__row__right d-flex d-md-none align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Verified</p>
                <Switch defaultChecked/>
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-block d-none">We use Criteria for scientific-based psychometric assessment</p>
            </div>
            <div className="API__row__right d-md-flex d-none align-items-md-center align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Verified</p>
                <Switch defaultChecked/>
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-none d-block">We use Criteria for scientific-based psychometric assessment</p>
        </div>
        <div className="d-md-flex justify-content-between API__row border-bottom py-3">
        <div className="d-flex API__row__left align-items-center justify-content-md-start justify-content-between">
            <img src="/assets/images/Dashboard/Accurate.svg" alt="" className="API__row__left--img px-3 pb-3 pt-4" />
            <div className="API__row__right d-flex d-md-none align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Verified</p>
                <Switch defaultChecked/>
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-block d-none">We use accurate for criminal background checks. previous employers and education verification</p>
            </div>
            <div className="API__row__right d-md-flex d-none align-items-md-center align-items-start">
                <p className="darkGrey fm me-3 mb-0 nowrap">Verified</p>
                <Switch />
            </div>
            <p className="darkGrey fm mb-0 ms-2 mt-md-0 mt-2 d-md-none d-block">We use accurate for criminal background checks. previous employers and education verification</p>
        </div> */}
      </div>
    </div>
  );
};

export default API;
