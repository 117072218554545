import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Dropdown,
  Checkbox,
  Slider,
  Button,
  Table,
  Menu,
  Pagination,
  Modal,
  Select,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { IoIosArrowDown } from "react-icons/io";
import {
  BsFilterCircle,
  BsSearch,
  BsFilter,
  BsLinkedin,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { TiArrowUnsorted } from "react-icons/ti";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import YourProfile from "../../ProfessionalDashboard/YourProfile/YourProfile";
import MeetingDate from "./MeetingDate";
import MeetingTime from "./MeetingTime";
import MeetingDetails from "./MeetingDetails";
import MeetingConfirm from "./MeetingConfirm";
import {
  getProfessionalsList,
  downloadAttachment,
  getJobs,
  sendJobInvite,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import moment from "moment";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { Country } from "../../../Utilities/Country";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";

const cardData_fav = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 5,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_short = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_interview = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_select = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
var favourites = [];

var rejectedCand = [];

var allcandidates = [];
var fetchedCandidatesList = [];

const team = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 5,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
];

const sortingMenus = [
  {
    key: "1",
    label: <span>Most Recent</span>,
  },
  {
    key: "2",
    label: <span>Oldest</span>,
  },
];
const ProfessionalsTab = (props) => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const { Option, OptGroup } = Select;
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(false);
  const [candidates, setCandidates] = useState(true);
  const [meeting, setMeeting] = useState(false);
  const [time, setTime] = useState(false);
  const [detail, setDetail] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [all, setAll] = useState(true);
  const [fav, setFav] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [filter, setFilter] = useState(false);
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const inputRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [relevance, setRelevance] = useState(true);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [dropfav, setDropfav] = useState(true);
  const [droplist, setDroplist] = useState(true);
  const [dropinterview, setDropinterview] = useState(true);
  const isMediumScreen = useMediaQuery("(max-width:767px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [professionals, setProfessionals] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [sortField, setSortField] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const navigate = useNavigate();
  const [sortingOption, setSortingOption] = useState("1");
  const [slidervalue, onChangeSlider] = useState(0);
  const [locationFilter, setLocationFilter] = useState(null);
  const [jobsData, setJobsData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [selectedInviteCandidate, setSelectedInviteCandidate] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

 
  const showModal = (item) => {
    setSelectedInviteCandidate([item.id]); 
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await getJobs();
      var myObjectEnterprise;
      const responseData = response.data.data.filter(
        (obj) => obj.enterpriseID === userData?.companyID
      );
      myObjectEnterprise = responseData.slice().reverse();
      // console.log("myObjectEnterprise", myObjectEnterprise);
      setJobsData(
        myObjectEnterprise
          .filter((job) => job.status === "Open")
          .map((jobs) => ({
            key: jobs._id,
            candidates: jobs.candidates.length,
            small_text: "Accenture",
            heading: jobs.company.toUpperCase(),
            para: jobs.job_title.toUpperCase(),
            companylogo: "/assets/images/Dashboard/logo-5.png",
            department: jobs.department,
            tags: jobs.job_sectors,
            start_date: jobs.start_date,
            end_date: jobs.end_date,
            min_salary: jobs.min_salary,
            max_salary: jobs.max_salary,
            team_members: jobs.team_members,
            employment_type: jobs.employment_type,
            indexScore: jobs.min_index_core,
            culture_fit: jobs.min_culture_fit_score,
            job_role_text: jobs.job_role_text,
            status: jobs.status            
          }))
      );
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  const sendInviteJobs = async () => {
    try {
      setRequestLoader(true);

      const payload = {
        candidateIds: selectedInviteCandidate,
        jobId: selectedJob,
      };
      console.log("payload", payload);
      const response = await sendJobInvite(payload);
      console.log("response", response);
      if (response.data.statusCode == 1) {
        enqueueSnackbar(
          "Invite sent",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );
        setRequestLoader(false);
        handleOk();
      }
    } catch (error) {
      console.log(error);
      setRequestLoader(false);
    }
  };

  const dropfav_func = () => {
    if (isMediumScreen) {
      setDropfav(!dropfav);
    }
  };
  const droplist_func = () => {
    if (isMediumScreen) {
      setDroplist(!droplist);
    }
  };
  const dropinterview_func = () => {
    if (isMediumScreen) {
      setDropinterview(!dropinterview);
    }
  };

  const handleMenuClick = (e) => {
    setOpen1(true)
  };
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen1(nextOpen);
    }
  };

  const handleSortingChange = (key) => {
    // console.log("Sorting option changed to:", key.key);
    setSortingOption(key.key);
  };
  const applyLocationFilter = async (filterValue) => {
    // console.log("location filter value", filterValue);
    setLocationFilter(filterValue);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    filterRef.current = document.getElementById("filter_btn");
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    }
    if (isSmallScreen) {
      setDropfav(true);
      setDroplist(false);
      setDropinterview(false);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);
  // var allcandidates;
  var myObject;
  var myObjectEnterprise;
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    // fetchJobs();
    fetchProfesionals();

    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  const fetchProfesionals = async () => {
    try {
      
      handleChange("loading", true);
      const responseProfessional = await getProfessionalsList();
      setProfessionals(responseProfessional.data.data);
      
      handleChange("loading", false);
      return responseProfessional.data.data; // Assuming the data returned by the API is the candidate object
    } catch (error) {
      console.log(`Error fetching candidate`, error);
      return null; // Or handle the error as per your requirements
    }    
  };

  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    var payloadAttachment = {
      Key: pdfUrl,
    };
    try {
      const response = await downloadAttachment(payloadAttachment).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "resume.pdf");
          document.body.appendChild(link);
          link.click();
        }
      );

      const responseData = response;
      // console.log("Access token:", responseData);
      // console.log('userID:', userID);
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle sorting order if the same field is clicked
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set the new sorting field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
  };

  // console.log("professionals data" + JSON.stringify(professionals[0]));
  const fetchedCandidatesList = professionals    
    .map((person) => ({
      id: person._id,
      cat: "all",
      tagClass:
        Math.round(person?.ai_data?.job_relevancy_score?.jobID) <= 33
          ? "low"
          : Math.round(person?.ai_data?.job_relevancy_score?.jobID) > 33 &&
            Math.round(person?.ai_data?.job_relevancy_score?.jobID) <= 66
          ? "average"
          : "high",
      tagTitle:
        Math.round(person?.ai_data?.job_relevancy_score?.jobID) <= 33
          ? "low"
          : Math.round(person?.ai_data?.job_relevancy_score?.jobID) > 33 &&
            Math.round(person?.ai_data?.job_relevancy_score?.jobID) <= 66
          ? "rejected"
          : "accepted",
      name: person.firstName + " " + person.lastName,
      pronouns: "He/him",
      date: person.createdAt,
      kycVerification:person.kycVerification ? person.kycVerification : false,
      country: Country.find((country) => country.code === person.country)?.name,
      countryCode:person.country,
      linkedinProfile: person.linkedinPublicUrl,
      indexCore: person.kycVerification ? Math.round(person.ai_data?.index_core) : 0,
      CultureFit: Math.round(person?.ai_data?.job_relevancy_score?.jobID),
      indexColor: "main",
      title: person.title,
      resumeUrl: person?.resumes?.Key,
      video: person?.elevatorPitch?.file,      
      status: person.status,
      candidatePhoto:person.profileImage && person.profileImage.docs ? person.profileImage.docs : ''

    }));

    const filteredCandidates = fetchedCandidatesList
    .filter(
        (candidate) =>
            ((candidate.name ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
                (candidate.title ?? '').toLowerCase().includes(searchQuery.toLowerCase())) &&
                candidate.indexCore >= slidervalue &&
            (locationFilter ? candidate.country === locationFilter : true)
    )
    .sort((a, b) => {
        const sortingNumber = parseInt(sortingOption, 10); // Convert to number
        if (sortingNumber === 1) {
            // Sort by most recent
            return new Date(b.date) - new Date(a.date);
        } else if (sortingNumber === 2) {
            // Sort by oldest
            return new Date(a.date) - new Date(b.date);
        }
        // Default sorting
        return 0;
    });



  const [state, setState] = useState({
    loading: false,
  });

  const { loading } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const allCandidates = () => {
    setAll(true);
    setFav(false);
    setRejected(false);
  };
  const favCandidates = () => {
    setAll(false);
    setFav(true);
    setRejected(false);
  };
  const rejectedCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedIndex(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const showProfile = (key) => {
    // console.log("current candidate" + JSON.stringify(key));
    // setCandidate(key);
    // Profile();
    // setClickedIndex(null);
    navigate(`/enterprise/dashboard/candidate/${key.id}`);
  };

  const Profile = () => {
    setConfirm(false);
    setCandidates(false);
    setProfile(true);
    setMeeting(false);
    setTime(false);
    setAnchorEl(null);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (profile) {
    document.body.style.background = "#F8F8F8";
  } else document.body.style.background = "#fff";

  const items = [
    // {
    //   label: (
    //     <div className="d-flex flex-column">
    //       <p className="mb-2 fm darkGrey fw-semibold">Category</p>
    //       <Checkbox className="fm darkGrey">Candidate Name</Checkbox>
    //       <Checkbox className="fm darkGrey">Country</Checkbox>
    //     </div>
    //   ),
    //   key: "0",
    // },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">IndexScore Range</p>
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="850"
              value={slidervalue}
              onChange={({ target: { value } }) => {
                onChangeSlider(value);
                const ele = document.querySelector(".buble");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble">{slidervalue}</div>
          </div>
        </div>
      ),
      key: "1",
    },   
    {
      label: (
        <div className="mb-2">
          <p
            className="mb-2 fm darkGrey fw-semibold d-flex justify-content-between align-items-center"
            onClick={() => setLocation(!location)}
          >
            Location{" "}
            <MdOutlineKeyboardArrowUp
              className="fs-4 transition ms-auto me-0"
              style={{
                transform: location ? "rotate(0deg)" : "rotate(180deg)",
                marginRight: "0 !important",
              }}
            />
          </p>
          <div
            className="d-flex flex-column overflow-hidden transition"
            style={{ height: location ? "auto" : "0px" }}
          >
            <input
              type="search"
              placeholder="UK, London…"
              className="fm dropdown--input text-15 darkGrey"
              ref={inputRef}
              onChange={handleInputChange}
              value={searchTerm}
            />
            <ul
              className="m-0 list-unstyled dropdown__list mt-2 pe-2"
              onClick={() => {
                setDlocation(!dlocation);
              }}
            >
              {filteredCountries.map((item) => (
                <li
                  key={item.code}
                  onClick={() => {
                    setDlocation(false);
                    applyLocationFilter(item.name);
                  }}
                  className="text-15 mb-1 pointer dropdown__list__item darkGrey"
                  style={{
                    paddingTop: "10px !important",
                    paddingBottom: "10px !important",
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            <div>
              {locationFilter && (
                <>
                  <hr className="mt-2" />
                  <p className="fm darkGrey text-14 mb-0">
                    Location:{" "}
                    <span className="fm midGrey">{locationFilter}</span>
                    <MdCancel
                      onClick={() => {
                        applyLocationFilter(null);
                      }}
                      className="pointer cancel__icon ms-1"
                    />
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      ),
      key: "4",
    },
  ];

  const [displayedCandidates, setDisplayedCandidates] = useState([]);
  const [displayedMobCandidates, setDisplayedMobCandidates] = useState([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const loaderRef = useRef(null);

  const tableColumns = [
    {
      title: (
        <>
          Candidates{" "}
          <span className="fw-normal">
            ({filteredCandidates ? displayedCandidates.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 150,
      sorter: (a, b) => {
        const roleA = (a.role || "").toLowerCase();
        const roleB = (b.role || "").toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "indexscore",
      width: 100,
      sorter: (a, b) => a.indexscore - b.indexscore,
      render: (indexscore) => (
        <>
        {indexscore !==0 ? 
        <div
          className={`score__text d-inline darkGrey rounded-pill prog-${
            (parseInt(indexscore) / 850) * 100 <= 50 ? "red" : ""
          }  prog-${
            (parseInt(indexscore) / 850) * 100 > 50 ? "main" : ""
          } text-13 fm fw-semibold`}
        >
          {indexscore}
        </div>
        : "Pending"
        }
        </>
        
      ),
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      width: 80,      
    },
    {
      title: "Location",
      dataIndex: "country",
      key: "country",
      width: 120,
      sorter: (a, b) => {
        const locationA = (typeof a.country === 'string' ? a.country : '').toLowerCase();
        const locationB = (typeof b.country === 'string' ? b.country : '').toLowerCase();
        return locationA.localeCompare(locationB);        
      },
      render: (country) => <span className="job__type text-13 rounded-pill">{country}</span>,
    },
    {
      title: "Date",
      width: 90,
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
    },
  ];
  const formattedCandidates = filteredCandidates.map((candidate, index) => ({
    key: candidate.id, // Assuming id uniquely identifies each candidate
    name: (
      <>
        <div className="d-flex align-items-center w-100">
          <div className="d-flex justify-content-between align-items-start w-100">
            <div
              className="d-flex align-items-center me-lg-0 me-2"
              // ref={buttonRef}
              // onClick={() => handleClick(index)}
            >
              <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                <img
                  src={candidate.candidatePhoto ? candidate.candidatePhoto : '/assets/images/userAvatar.svg'}
                  alt=""
                  className={`candidates__avatar--img img-fluid ${candidate.candidatePhoto ? 'candidate-image' : ''}`}
                />
              </div>
              <h6 className="Experts__name fm mb-0 ms-2">
                <h6 className="mb-0 me-1 text-14">{candidate.name}</h6>{" "}
                {/* <span>({candidate.pronouns})</span> */}
              </h6>
            </div>
          </div>
          {/* <Popover
            id={candidate.id}
            className="popover__box"
            open={clickedIndex === index}
            anchorEl={buttonRef.current}
            onClose={() => setClickedIndex(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <div className="candidate__popup">
              <div className="candidate__detail__popup ">
                <div className="d-flex align-items-center">
                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                    <img
                      src={`/assets/images/userAvatar.svg`}
                      alt=""
                      className="candidates__avatar--img img-fluid"
                    />
                  </div>

                  <h6 className="Experts__name fm mb-0 ms-2">
                    {candidate.name} <span>({candidate.pronouns})</span>
                  </h6>
                </div>
              </div>
              <Typography>{candidate.Descriptions}</Typography>
              <div className="candidate__popup__button">
                <div
                  className="candidate__popup__check__profile"
                  onClick={() => showProfile(candidate)}
                >
                  Profile
                </div>
                <div
                  onClick={() => handleDownload(candidate.resumeUrl)}
                  className="candidate__popup__download text-white d-flex mt-sm-0 mt-2"
                >
                  {" "}
                  <img
                    src={`/assets/images/Dashboard/downloadcv-icon.png`}
                  />{" "}
                  Download CV
                  
                </div>
              </div>
            </div>
          </Popover> */}
        </div>
      </>
    ),
    date: moment(candidate.date).format("L"),
    role: candidate.title,
    country: candidate.country,
    indexscore: candidate.indexCore,
    verified: (
      <div className="verified__box">
        <div
          className={`${candidate.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
        >
          <HiOutlineCheckCircle
            className={`${candidate.tagClass}__icon fs-4`}
          />
        </div>
      </div>
    ),
    resume: (
      <span className={` resume-icon-download`}>
        <img
          onClick={() => {
            handleDownload(candidate.resumeUrl);
          }}
          src="/assets/images/resume-icon.png"
        />
      </span>
    ),
    linkedin: (
      <span className="text-14">
        <a
          target={"_blank"}
          href={candidate.linkedinProfile}
          className="text-decoration-none"
        >
          <BsLinkedin className="darkGrey linkedin-icon m-0" />{" "}
        </a>
      </span>
    ),
    action: (
      <button
        className="bluegradient__btn candidates__invite__btn text-whitefm transition me-2  text-white px-3"
        onClick={() => {
          showModal(candidate);
        }}
      >
        Invite
      </button>
    ),
    // action: (
    //   <>
    //     <Dropdown
    //       placement="top"
    //       arrow={{ pointAtCenter: true }}
    //       overlay={
    //         <Menu>
    //           <Menu.Item key="Reject">Reject</Menu.Item>
    //         </Menu>
    //       }
    //       trigger={["click"]}
    //     >
    //       <Button className="candidates__menu">
    //         <BsThreeDotsVertical className="text-decoration-none" />
    //       </Button>
    //     </Dropdown>
    //   </>
    // ),
  }));

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedCandidates.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    document.body.classList.add("candidates");
    return () => {
      document.body.classList.remove("candidates");
    };
  }, []);
  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  useEffect(() => {
    setDisplayedCandidates(formattedCandidates.slice(0, visibleCount));
  }, [visibleCount, formattedCandidates]);
  useEffect(() => {
    setDisplayedMobCandidates(filteredCandidates.slice(0, visibleCount));
  }, [visibleCount, filteredCandidates]);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [visibleCount, formattedCandidates.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [visibleCount, filteredCandidates.length]);

  const loadMore = () => {
    if (visibleCount < formattedCandidates.length) {
      setVisibleCount((prevCount) =>
        Math.min(prevCount + 20, formattedCandidates.length)
      );
    }
  };

  const loadAll = () => {
    setVisibleCount(formattedCandidates.length);
  };

  return (
    <div className="edash edash3">
      <div className="pt-4">
        <div className={`${profile ? "bg-lightgrey" : ""}`}>
          <style>
            {`:where(.css-dev-only-do-not-override-1e3x2xa).ant-avatar >img {object-fit: contain;
      width: 65%;
      margin: auto;}
  .resume-icon-download{
    text-align:left;
  }
  .linkedin-icon{
    font-size: 28px;
  }
  .resume-icon-download img{
        cursor:pointer;
        width:30px;
    }          
      
  }`}
          </style>
          {candidates && (
            <div className="container-dash">
              <div className="">
                <div className="Experts__container candidates__opt pb-4">
                  <div className="mb-3">
                    <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                      <div className="d-flex justify-content-sm-start justify-content-between">
                        <div>
                          <Dropdown
                            menu={{
                              items: sortingMenus,
                              onClick: handleSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2 "
                            placement="bottomLeft"
                            trigger={['click']}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingMenus.find(
                                      (menu) => menu.key === sortingOption
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                          </Dropdown>
                        </div>
                        <div className=" d-block h-100">
                          <Dropdown
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                              style={{
                                border: open1 ? "0.5px solid #5F89FF" : "",
                                color: open1 ? "#5F89FF" : "",
                              }}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                        <div className="candidates__searchbox jobd__btn d-flex align-items-center mb-sm-0 mb-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search users..."
                          />
                        </div>
                        {/* <button className="bluegradient__btn nowrap jobd__btn text-capitalize text-white fm transition ms-2 px-3 addcandidate__button ">
                          Add Candidate
                        </button> */}
                      </div>
                    </div>
                  </div>
                  {all && (
                    <div>
                      {/* header row */}
                      {loading ? (
                        <div className="align-items-center d-md-flex d-none">
                          <Loader size="md" content="loading" />
                        </div>
                      ) : (
                        <div>
                          <div className="table__outer  d-md-block d-none rounded-3 position-relative">
                            <Table
                              className="dash__table"
                              columns={tableColumns}
                              dataSource={displayedCandidates}
                              pagination={false}
                              scroll={{ x: 992 }}
                            />
                            <div
                              ref={loaderRef}
                              style={{
                                height: 0,
                                backgroundColor: "transparent",
                              }}
                            />
                          </div>
                          {formattedCandidates.length > visibleCount && (
                            <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                              {/* <button
                                className="text-12 table__bottom__btn1"
                                onClick={loadAll}
                              >
                                Load all candidates (
                                {formattedCandidates.length})
                              </button> */}
                              <span className="text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {loading ? (
                        <div className="d-md-none d-flex align-items-center pt-lg-3 pt-2">
                          <Loader size="md" content="loading" />
                        </div>
                      ) : (
                        displayedMobCandidates.map((item, index) => (
                          <div
                            className={`d-md-none d-flex border row enterprise Experts__row ${
                              item.cat === "fav"
                                ? "candidates__fav"
                                : "candidates__all"
                            } mx-0 py-lg-2 pt-3 pb-4 my-3 transition pointer`}
                            key={item.id}
                          >
                            <div className="col-lg-3 col-12 pb-lg-0 pb-3">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-between align-items-start w-100">
                                  <div
                                    className="d-flex align-items-center me-lg-0 me-2"
                                    // ref={buttonRef}
                                    // onClick={() => handleClick(index)}
                                  >
                                    <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                      <img
                                        src={`/assets/images/userAvatar.svg`}
                                        alt=""
                                        className="candidates__avatar--img img-fluid"
                                      />
                                    </div>
                                    <h6 className="Experts__name fm mb-0 ms-2">
                                      <h6 className="mb-0 me-1">{item.name}</h6>{" "}
                                      <span>({item.pronouns})</span>
                                    </h6>
                                  </div>
                                </div>
                                {/* <Popover
                                  id={item.id}
                                  className="popover__box"
                                  open={clickedIndex === index}
                                  anchorEl={buttonRef.current}
                                  onClose={() => setClickedIndex(null)}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "center",
                                  }}
                                >
                                  <div className="candidate__popup">
                                    <div className="candidate__detail__popup ">
                                      <div className="d-flex align-items-center">
                                        <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                          <img
                                            src={`/assets/images/userAvatar.svg`}
                                            alt=""
                                            className="candidates__avatar--img img-fluid"
                                          />
                                        </div>

                                        <h6 className="Experts__name fm mb-0 ms-2">
                                          {item.name}{" "}
                                          <span>({item.pronouns})</span>
                                        </h6>
                                      </div>
                                    </div>
                                    <Typography>{item.Descriptions}</Typography>
                                    <div className="candidate__popup__button">
                                      <div
                                        className="candidate__popup__check__profile"
                                        onClick={() => showProfile(item)}
                                      >
                                        Profile
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleDownload(item.resumeUrl)
                                        }
                                        className="candidate__popup__download text-white d-flex mt-sm-0 mt-2"
                                      >
                                        {" "}
                                        <img
                                          src={`/assets/images/Dashboard/downloadcv-icon.png`}
                                        />{" "}
                                        Download CV                                        
                                      </div>
                                    </div>
                                  </div>
                                </Popover> */}
                              </div>
                            </div>

                            <div className="col-sm-12 d-flex align-items-center mb-3">
                              <span className="text-14 enterprise__experts Experts__date fm">
                                <span className="text-14 fm darkGrey fw-semibold me-1 d-lg-none">
                                  Role:
                                </span>
                                <span className="text-14">{item.role}</span>
                              </span>
                            </div>

                            <div className="col-sm-12 d-flex align-items-center mb-3">
                              <span className="text-14 enterprise__experts Experts__date fm">
                                <span className="text-14 fm darkGrey fw-semibold me-1 d-lg-none">
                                  Location:
                                </span>
                                <span className="job__type text-13 rounded-pill">
                                  {item.country}
                                </span>
                              </span>
                            </div>

                            <div className="col-sm-6 d-flex align-items-center mb-sm-0 mb-3">
                              <span className="text-14 enterprise__experts Experts__date fm">
                                <span className="text-14 fm darkGrey fw-semibold me-1 d-lg-none">
                                  Date:
                                </span>
                                <span className=" text-14 fm darkGrey">
                                  {moment(item.date).format("L")}
                                </span>
                              </span>
                            </div>

                            <div className="col-sm-6">
                              <div className="indexCore__score">
                                <div className="d-flex">
                                  <span className="text-14 fm darkGrey fw-semibold me-1 d-lg-none">
                                    Indexscore:
                                  </span>
                                  <span className="fm main">
                                    {item.indexCore}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-lg-1 col-sm-2 col-4 order-lg-3 order-4 d-flex flex-column align-items-lg-center align-items-start justify-content-lg-center my-lg-0 mt-3 ">
                              <div className="d-inline-flex flex-column align-items-center">
                                <p className="fm d-lg-none text-14 darkGrey fw-semibold ">
                                  LinkedIn
                                </p>
                                <span className="text-14 enterprise__experts Experts__date fm">
                                  <a
                                    target={"_blank"}
                                    href={item.linkedinProfile}
                                  >
                                    <BsLinkedin className="darkGrey linkedin-icon" />{" "}
                                  </a>
                                </span>
                              </div>
                            </div>

                            <div className="col-lg-1 col-sm-2 col-4 order-lg-3 order-4 d-flex flex-column align-items-center justify-content-lg-center mt-lg-0 mt-3">
                              <div className="d-inline-flex flex-column align-items-center">
                                <p className="fm text-14 fw-semibold darkGrey d-lg-none">
                                  Resume
                                </p>
                                <span
                                  className={` fm tag-14 p-0 text-capitalize rounded-pill text-center resume-icon-download`}
                                >
                                  <img
                                    onClick={() => {
                                      handleDownload(item.resumeUrl);
                                    }}
                                    src="/assets/images/resume-icon.png"
                                  />
                                </span>
                              </div>
                            </div> */}
                            
                            <div className="col-lg-1 col-12 mt-lg-0 mt-2 order-last">
                              <div className="d-flex align-items-center">
                                <p className="fm text-14 fw-semibold darkGrey mb-0 me-1">
                                  Verified
                                </p>
                                <div
                                  className={`${"high"} rounded-circle d-flex justify-content-center align-items-center`}
                                >
                                  <HiOutlineCheckCircle className="high__icon fs-4" />
                                  {/* {item.tagClass === "high" && (
              <HiOutlineCheckCircle className="high__icon fs-4" />
            )}
            {item.tagClass === "average" && (
              <HiOutlineQuestionMarkCircle className="high__icon fs-4" />
            )}
            {item.tagClass === "low" && (
              <HiOutlineExclamationCircle className="high__icon fs-4" />
            )} */}
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-xl-1 col-6 order-xl-5 order-3 d-flex align-items-center justify-content-end">
                <img
                  src={`/assets/images/Dashboard/fav_${item.cat === "fav" ? "selected" : "unselected"
                    }.svg`}
                  alt=""
                  className="me-2 candidates__icon1"
                />
                <img
                  src={`/assets/images/Dashboard/settings_more_${item.cat === "fav" ? "selected" : "unselected"
                    }.svg`}
                  alt=""
                  className="img-fluid candidates__icon2"/>
              </div> */}
                          </div>
                        ))
                      )}
                      <div
                              ref={loaderRef}
                              style={{
                                height: 0,
                                backgroundColor: "transparent",
                              }}
                            />
                      {filteredCandidates.length > visibleCount && (
                            <div className="d-md-none d-flex justify-content-center pt-3 gap-2">
                              <button
                                className="text-12 table__bottom__btn1"
                                onClick={loadAll}
                              >
                                Load all candidates (
                                {filteredCandidates.length})
                              </button>
                              <span className="text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </span>
                            </div>
                          )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {profile && (
            <YourProfile
              page="enterprise"
              data={candidate}
              meeting={setMeeting}
              profile={setProfile}
              candidate={setCandidates}
              confirm={setConfirm}
            />
          )}

          {meeting && (
            <MeetingDate
              meeting={setMeeting}
              profile={setProfile}
              time={setTime}
              detail={setDetail}
              confirm={setConfirm}
            />
          )}
          {time && (
            <MeetingTime
              meeting={setMeeting}
              profile={setProfile}
              time={setTime}
              detail={setDetail}
              confirm={setConfirm}
            />
          )}
          {detail && (
            <MeetingDetails
              meeting={setMeeting}
              profile={setProfile}
              time={setTime}
              detail={setDetail}
              confirm={setConfirm}
            />
          )}
          {confirm && (
            <MeetingConfirm
              meeting={setMeeting}
              profile={setProfile}
              time={setTime}
              detail={setDetail}
              confirm={setConfirm}
            />
          )}
        </div>
      </div>
      <Modal
        title="Job Invitation"
        className="scoring__popup"
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="scoring__popup__body">
          <h4 className="mb-3">Select job to invite candidate</h4>

          {/* <p>Approximate cost: $139.50 per candidate</p> */}
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              ((optionA?.label ?? "") || "")
                .toLowerCase()
                .localeCompare(((optionB?.label ?? "") || "").toLowerCase())
            }
            className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
            size="small"
            // value={updateCountry}
            placeholder="Select Job"
            onChange={(value) => {
              // handleChange("job_locations", value);
              // setUpdateCountry(value);
              setSelectedJob(value);
              // console.log("value ", value);
            }}
          >
            <OptGroup>
              {jobsData &&
                jobsData.map((item) => (
                  <Option value={item.key} label={item.para} key={item._id}>
                    {item.para}
                  </Option>
                ))}
            </OptGroup>
          </Select>
          <button
            onClick={() => {
              // handleButtonClick();
              sendInviteJobs();
            }}
            className="bluegradient__btn text-white fm transition my-2 me-2 w-100"
          >
            {requestLoader ? <Loader /> : "Send invite"}
          </button>
        </div>
        <div className="scoring__popup__footer">
          <p>
            All your login details are stored in your browser, you are able to
            remove any connected app or API with in your API tab
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ProfessionalsTab;
