import React, { useState, useRef, useEffect, useContext } from "react";
import {
  getInterviewPrepById,
  createtInterviewPrep,
  getProDashboard,
} from "../../../services/auth";
import { AutoComplete, Table, Dropdown, Menu, Button, Tooltip } from "antd";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import "./pre-style.css";
import { useSnackbar } from "notistack";
import { companies } from "../../../Utilities/Companies.js";
import { BsArrowUpRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import moment from "moment";
import { RiStarSLine } from "react-icons/ri";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  PDFDownloadLink,
  Link,
  Font,
} from "@react-pdf/renderer";
// import { PDFViewer } from '@react-pdf/renderer';

const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});

const getPanelValue = (searchText) => {
  const lowerSearchText = searchText.toLowerCase();

  if (!searchText) {
    return [];
  }

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(lowerSearchText)
  );

  const filteredOptions = filteredCompanies.map((company) =>
    mockVal(company.name)
  );

  // Include the typed value if it doesn't match any of the companies
  if (
    !filteredOptions.some(
      (option) => option.value.toLowerCase() === lowerSearchText
    )
  ) {
    filteredOptions.unshift(mockVal(searchText));
  }

  return filteredOptions;
};
const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button
        className="paginate-button"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <IoChevronBack />
      </button>
      <button
        className="paginate-button"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <IoChevronForward />
      </button>

      <span>
        Page {currentPage} of {totalPages}
      </span>
    </div>
  );
};

const PrepSheet = ({ staticData, indexScore }) => {
  const hrQuestionsArray = staticData.sheet.hr_questions_reply.split("\n");
  const filteredHrQuestionsArray = hrQuestionsArray.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredHrQuestionsArray = filteredHrQuestionsArray.filter(
    (question) => question.trim() !== ""
  );
  const interviewQuestionsArray =
    staticData.sheet.interview_questions_reply.split("\n");
  const interviewHrQuestionsArray = interviewQuestionsArray.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredinterviewQuestionsArray =
    interviewHrQuestionsArray.filter((question) => question.trim() !== "");
  const interviewJobCompReply = staticData.sheet.job_comp_reply.split("\n");
  const interviewJobCompReplyArray = interviewJobCompReply.flatMap(
    (question, index) => {
      const parts = question.split(",");
      const formattedParts = parts.map((part) =>
        part.replace(/^-|\d+\.\s*/g, "").trim()
      );
      const nonEmptyFormattedParts = formattedParts.filter(Boolean);
      return nonEmptyFormattedParts.join(", ");
    }
  );
  const withoutEmptyfilteredCompReplyArray = interviewJobCompReplyArray.filter(
    (question) => question.trim() !== ""
  );

  Font.register({
    family: "Open Sans",
    fonts: [
      { src: "/assets/fonts/OpenSans-Regular.ttf", fontWeight: 600 },
      { src: "/assets/fonts/OpenSans-Bold.ttf", fontWeight: 800 },
      { src: "/assets/fonts/OpenSans-ExtraBold.ttf", fontWeight: 900 },
      { src: "/assets/fonts/OpenSans-SemiBold.ttf", fontWeight: 700 },
      { src: "/assets/fonts/OpenSans-Light.ttf", fontWeight: 400 },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topBar: {
      height: 6,
      backgroundColor: "#B2DA66",
      marginBottom: 10,
    },

    doc: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 30,
    },
    section: {
      textAlign: "center",
      margin: 30,
      fontFamily: "Open Sans",
    },
    logo: {
      width: 135,
      display: "block",
      marginBottom: 17,
      marginTop: 20,
    },
    rating: {
      width: 9,
      height: 9,
      display: "block",
      marginRight: 3,
    },
    heading: {
      display: "block",
      marginBottom: 10,
      fontSize: 24,
      fontWeight: 800,
      color: "#0D2159",
      fontFamily: "Open Sans",
    },
    subTitle: {
      display: "block",
      fontSize: 14,
      color: "black",
      fontFamily: "Open Sans",
      fontWeight: 800,
    },
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: "Open Sans",
    },
    card: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#E6E6E6",
      marginTop: 5,
      marginBottom: 25,
    },
    cardTop: {
      borderRadius: "5px",
      fontFamily: "Open Sans",
      backgroundColor: "#F5F5F5",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 12,
      paddingBottom: 12,
    },
    cardBottom: {
      fontFamily: "Open Sans",
      paddingLeft: 12,
      paddingRight: 12,
      paddingTop: 10,
      paddingBottom: 18,
    },
    avatar: {
      border: "1px solid #9D9EA2",
      borderRadius: "50%",
      minWidth: 18,
      minHeight: 18,
      width: 15,
      height: 15,
      marginRight: 5,
    },
    date: {
      fontSize: 8,
      color: "#797978",
      fontWeight: "normal",
      fontFamily: "Open Sans",
    },
    header: {
      backgroundColor: "#F5F5F5",
      borderRadius: "50px",
      display: "flex",
      flexDirection: "row",
      paddingLeft: "15px",
      paddingRight: "15px",
      paddingBottom: "5px",
      paddingTop: "5px",
      justifyContent: "space-between",
      fontFamily: "Open Sans",
    },
    bullet: {
      color: "#B2DA66",
      fontSize: 9,
      fontWeight: "600",
      display: "block",
      marginRight: 5,
    },
    list: {
      width: "80%",
      marginBottom: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
    },
    viewer: {
      width: 200,
      height: 400,
    },
    bulletDot: {
      color: "black",
      fontSize: 8,
      fontFamily: "Open Sans",
      fontWeight: 600,
      marginRight: 2,
      marginLeft: 3,
    },
  });
  return (
    <Document style={styles.doc}>
      <Page style={styles.page}>
        <View>
          <View style={styles.topBar} />
          <View style={styles.container}>
            <Image src="/assets/svg/logo.png" style={styles.logo} alt="logo" />

            <Text style={styles.heading}>Your Interview Prep Sheet</Text>

            <View style={styles.card}>
              {/* card */}
              <View style={styles.cardTop}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 3,
                  }}
                >
                  <Image
                    src="/assets/images/Users/user1.png"
                    style={styles.avatar}
                  />
                  <Text
                    style={{
                      color: "#9e9e9e",
                      fontSize: 10,
                      fontFamily: "Open Sans",
                      fontWeight: 600,
                    }}
                  >
                    {staticData.company_name}
                  </Text>
                </View>
                <Text style={styles.subTitle}>{staticData.job_title}</Text>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 10,
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    marginTop: 1,
                  }}
                >
                  {staticData.sheet.job_desc_reply}
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-evenly",
                    marginTop: 8,
                    width: "100%",
                    paddingBottom: 10,
                  }}
                >
                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      CEO Recommendation : {staticData.sheet.ceo_recommendation}
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 2,
                      }}
                    >
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                      <Image
                        src="/assets/images/star-image.png"
                        style={styles.rating}
                        alt="star"
                      />
                    </View>
                  </View>

                  {/* <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Founded: Date
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Industry: Name
                    </Text>
                  </View>

                  <View style={{ width: "33%" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 10,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                        textAlign: "start",
                      }}
                    >
                      High-level description of their service
                    </Text>
                  </View> */}
                </View>
              </View>

              <View style={styles.cardBottom}>
                <Text
                  style={{
                    color: "#4A4A49",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    fontWeight: 800,
                    marginBottom: 5,
                  }}
                >
                  REVIEWS
                </Text>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{1}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[0].review_summary}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{2}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[1].review_summary}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{3}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[2].review_summary}
                      </Text>
                    </View>
                  </View>
                  <View style={{ width: "50%" }}>
                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{4}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[3].review_summary}
                      </Text>
                    </View>

                    <View style={styles.list}>
                      <View>
                        <Text style={styles.bullet}>{5}</Text>
                      </View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {staticData.sheet.reviews[4].review_summary}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                style={{
                  color: "black",
                  fontSize: 14,
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                  width: "45%",
                }}
              >
                Your Index score's in relation to this positon:
              </Text>

              <View>
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  INDEXSCORE
                </Text>
                <Text
                  style={{
                    color: "#B2DA66",
                    fontSize: 22,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    marginTop: -5,
                  }}
                >
                  {indexScore}
                </Text>
              </View>

              {/* <View
                style={{
                  marginRight: 20,
                }}
              >
                <Text
                  style={{
                    color: "#9e9e9e",
                    fontSize: 8,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                  }}
                >
                  PURPOSE ALIGNMENT
                </Text>
                <Text
                  style={{
                    color: "#B2DA66",
                    fontSize: 22,
                    fontFamily: "Open Sans",
                    fontWeight: 400,
                    marginTop: -5,
                  }}
                >
                  98%
                </Text>
              </View> */}
            </View>

            {/* 1st */}
            {/* <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            /> */}
            {/* <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                HR
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{1}</Text>
                    </View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Arrival and network with refreshments
                    </Text>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{2}</Text>
                    </View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Arrival and network with refreshments
                    </Text>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{3}</Text>
                    </View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Arrival and network with refreshments
                    </Text>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{4}</Text>
                    </View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Arrival and network with refreshments
                    </Text>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{5}</Text>
                    </View>
                    <Text
                      style={{
                        color: "black",
                        fontSize: 8,
                        fontFamily: "Open Sans",
                        fontWeight: 600,
                      }}
                    >
                      Arrival and network with refreshments
                    </Text>
                  </View>
                </View>
              </View>
            </View> */}

            {/* 2nd */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                QUESTIONS HR SHOULD ASK
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{1}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[0]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[1]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{2}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[2]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[3]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{3}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredHrQuestionsArray[4]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredHrQuestionsArray[5]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* 3rd */}
            <View
              style={{
                borderBottom: "1.5px solid #B2DA66",
                borderBottomStyle: "dotted",
                marginTop: 20,
                marginBottom: 10,
              }}
            />
            <View>
              <Text
                style={{
                  color: "#0D2159",
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  textTransform: "uppercase",
                  fontWeight: 900,
                  marginBottom: 6,
                }}
              >
                QUESTIONS TECHNICAL INTERVIEWER SHOULD ASK
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: 30,
                }}
              >
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{1}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[0]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[1]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{2}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[2]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[3]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{3}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[4]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[5]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "50%" }}>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{4}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[6]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[7]}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.list}>
                    <View>
                      <Text style={styles.bullet}>{5}</Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: 8,
                          fontFamily: "Open Sans",
                          fontWeight: 600,
                        }}
                      >
                        {withoutEmptyfilteredinterviewQuestionsArray[8]}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <Text style={styles.bulletDot}>{"\u2022" + " "}</Text>
                        <Text
                          style={{
                            color: "black",
                            fontSize: 8,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                          }}
                        >
                          {withoutEmptyfilteredinterviewQuestionsArray[9]}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {/* 4th */}
            {withoutEmptyfilteredCompReplyArray && (
              <>
                <View
                  style={{
                    borderBottom: "1.5px solid #B2DA66",
                    borderBottomStyle: "dotted",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                />
                <View>
                  <Text
                    style={{
                      color: "#0D2159",
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      textTransform: "uppercase",
                      fontWeight: 900,
                      marginBottom: 6,
                    }}
                  >
                    JOB COMPANY REPLY
                  </Text>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 30,
                    }}
                  >
                    <View style={{ width: "50%" }}>
                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[0] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{1}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[0]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[1] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{2}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[1]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[2] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{3}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[2]}
                              </Text>
                            </View>
                          </View>
                        )}
                    </View>
                    <View style={{ width: "50%" }}>
                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[3] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{4}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[3]}
                              </Text>
                            </View>
                          </View>
                        )}

                      {withoutEmptyfilteredCompReplyArray &&
                        withoutEmptyfilteredCompReplyArray[4] && (
                          <View style={styles.list}>
                            <View>
                              <Text style={styles.bullet}>{5}</Text>
                            </View>
                            <View>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: 8,
                                  fontFamily: "Open Sans",
                                  fontWeight: 600,
                                }}
                              >
                                {withoutEmptyfilteredCompReplyArray[4]}
                              </Text>
                            </View>
                          </View>
                        )}
                    </View>
                  </View>
                </View>
              </>
            )}
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#B2DA66",
            padding: 13,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            marginLeft: 40,
            marginRight: 40,
          }}
        >
          <View>
            <Text
              style={{
                color: "#0D2159",
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 800,
                textTransform: "uppercase",
              }}
            >
              Next Steps
            </Text>
            <Text
              style={{
                fontSize: 9,
                color: "#4A4A49",
                fontWeight: 600,
                fontFamily: "Open Sans",
              }}
            >
              Please contact{" "}
              <Text
                style={{
                  color: "#4A4A49",
                  fontFamily: "Open Sans",
                  fontWeight: 800,
                }}
              >
                IndexScore
              </Text>{" "}
              at{" "}
              <Link
                src="help@IndexScore.com"
                style={{
                  color: "#0D2159",
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  textDecoration: "none",
                }}
              >
                help@IndexScore.com
              </Link>{" "}
              if you have any questions.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const ProInterviewPrep = () => {
  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const [companyName, setCompanyName] = useState("");
  const [jobRole, setJobRole] = useState("");

  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;

  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [anotherOptions, setAnotherOptions] = useState([]);
  const [prepSheets, setPrepSheets] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  const onSelect = (data) => {
    console.log("onSelect", data);
    setCompanyName(data);
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleSubmit = async () => {
    handleChange("loading", true);
    try {
      const payload = {
        emailAddress: userData.emailAddress,
        firstName: userData.firstName,
        candidateId: userData._id,
        company_name: companyName,
        job_title: jobRole,
      };
      const response = await createtInterviewPrep(payload);
      if (response.data.statusCode === 1) {
        enqueueSnackbar("You will get an email once your prep sheet is ready", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        // Clear the fields
        handleChange("jobRole", "");
        handleChange("companyName", "");
        setOptions([]);
        fetchPrepSheets();
      }
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
      console.error("API Error:", error);
    }
  };

  const fetchProDashboard = async () => {
    try {
      const payload = {
        _id: userData._id,
      };
      const response = await getProDashboard(payload);
      console.log("dashboard Data pro", response);
      if (response.data.data) {
        setDashboardData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPrepSheets = async () => {
    const payload = {
      candidateId: userData._id,
    };

    const response = await getInterviewPrepById(payload);
    if (response.data.statusCode == 1) {
      console.log("prepsheets", response);

      setPrepSheets(response.data.data.reverse());
    }
  };

  useEffect(() => {
    fetchPrepSheets();
    fetchProDashboard();
  }, []);

  const [currentItems, setCurrentItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsLoading, setItemsLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const initialItems = prepSheets.slice(0, itemsPerPage);
    setCurrentItems(initialItems);
    setTotalItems(prepSheets.length);
  }, [prepSheets]); // Update dependency to currentItems
  

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreItems();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentItems, itemsLoading]); // Update dependencies
  
  
  const loadMoreItems = () => {
    if (itemsLoading || currentItems.length >= totalItems) return;
  
    setItemsLoading(true);
    setTimeout(() => {
      const newItems = prepSheets.slice(
        currentItems.length,
        currentItems.length + itemsPerPage
      );
      setCurrentItems([...currentItems, ...newItems]);
      setItemsLoading(false);
    }, 500); // Simulated loading delay, replace with actual data fetching
  };
  const tableColumns = [
    {
      title: (
        <>
          Company{" "}
          <span className="fw-normal">
            ({currentItems ? currentItems.length : ""})
          </span>
        </>
      ),
      width: 200,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Job Title",
      dataIndex: "role",
      width: 150,
      key: "5",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "4",
      width: 100,
    },
    {
      title: "Created at",
      dataIndex: "created",
      key: "3",
      width: 100,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),
      render: (extended) => (
        <span className="text-14 darkGrey text-capitalize fm">{extended}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 90,
      fixed: "right",
    },
  ];
  const formattedInterviews = currentItems.map((item) => ({
    key: item.id,
    name: (
      <div className="d-flex align-items-center">
        <div
          className={`expiring__body_detail_image candidates__interview__icon jobs__image rounded-circle d-flex justify-content-center align-items-center`}
        >
          <img src="/assets/images/expiring_icon.svg" alt="" className="me-0" />
        </div>
        <h4 className="my-0 text-14">{item.company_name}</h4>
      </div>
    ),

    status: (
      <span
        className={`text-13 job__category fm darkGrey text-capitalize rounded-pill status-${
          item && item.sheet ? "Generated" : "Pending"
        }`}
      >
        {item && item.sheet ? "Generated" : "Pending"}
      </span>
    ),
    created: (
      <span className="text-14 darkGrey fm">
        {moment(item.createdAt).format("L")}
      </span>
    ),
    role: (
      <span className="darkGrey text-capitalize text-14">
        {item.job_title}
      </span>
    ),
    action: (
      <>
        <div className="fm darkGrey">
          {item && item.sheet ? (
            <PDFDownloadLink
              className="fm py-2 d-flex darkGrey align-items-center text-decoration-none"
              document={
                <PrepSheet
                  staticData={item}
                  indexScore={dashboardData?.indexScore}
                />
              }
              fileName="PrepSheet.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading ..."
                ) : (
                  <Tooltip title="Download Prepsheet" color="#B2DA66">
                    {" "}
                    <div className="d-flex align-items-center">
                      <img
                        src="/assets/images/icon_export.svg"
                        alt=""
                        className="img-fluid candidates__button--img me-2"
                      />{" "}
                      <span className="darkGrey fm">Download</span>
                    </div>{" "}
                  </Tooltip>
                )
              }
            </PDFDownloadLink>
          ) : (
            "Pending"
          )}
        </div>
      </>
    ),
  }));
  return (
    <>
      <div className="edash">
        <div className="pt-4 pb-5">
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <h1 className="fm text-35 darkBlue">
                  Interview Preparation
                </h1>
              </div>
            </div>
            <div className="row pb-5 g-sm-3 align-items-end mt-3">
              <div className="col-md-4 col-sm-6 pe-sm-0 mb-sm-0 mb-3 fm">
                <label for="jobRole" class="fm darkGrey mb-1 text-14">
                  Name of company
                </label>
                <AutoComplete
                  className="prep__field form-control"
                  options={options}
                  onSelect={onSelect}
                  onSearch={(text) => setOptions(getPanelValue(text))}
                  placeholder="Enter company name"
                />
              </div>
              <div className="col-md-4 col-sm-6 pe-md-0 fm">
                <label for="jobRole" class="fm darkGrey mb-1 text-14 ">
                  Job Role
                </label>
                <input
                  id="jobRole"
                  type="text"
                  value={jobRole}
                  className="form-control prep__field"
                  placeholder="Enter job role"
                  onChange={(e) => setJobRole(e.target.value)}
                />
              </div>
              <div className="col-md-4 h-100 d-flex align-items-end fm">
                <button
                  type="submit"
                  className={`Get__left__content__para mt-sm-0 mt-3 job__role___btn Get__right__button d-flex align-items-center prep__field Get__right__button--blue interview-btn`}
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? <Loader /> : "Generate"}
                </button>
              </div>
            </div>
            <div className="">
              <div className="d-md-block d-none lazy_table">
                {loading ? (
                  <Loader size="md" className="" content="loading" />
                ) : (
                  <>
                  <div className="table__outer position-relative table__outer__pro  radius8">
                    <Table
                      className="dash__table"
                      columns={tableColumns}
                      dataSource={formattedInterviews}
                      pagination={false}
                      scroll={{
                        x: 992,
                      }}
                    />
                  </div>
                  {/* {currentItems && currentItems.length > 0 && <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                    <button className="fm text-12 table__bottom__btn1">Load all candidates{" "}({currentItems.length})</button>
                    <button className="fm text-12 table__bottom__btn2">Scroll to load more <HiOutlineArrowDownCircle className="fs-6" /></button>
                  </div>} */}
                  </>
                )}
              </div>
              <div className="offers__data pb-0 bg-white px-3 d-md-none">
                <div className="container-fluid px-0">
                  {currentItems && currentItems.length > 0 ? (
                    currentItems.map((item) => (
                      <div className="row align-items-center offers__data py-3 mb-3">
                        <div className="col-md-3">
                          <div className="d-flex align-items-center">
                            <div
                              className={`expiring__body_detail_image jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                            >
                              <img
                                src="/assets/images/expiring_icon.svg"
                                alt=""
                                className="me-0"
                              />
                            </div>
                            <h4 className="my-0 text-14">
                              {item.company_name}
                            </h4>
                          </div>
                        </div>
                        <div className="col-md-3 mt-md-0 mt-3">
                          <p className="text-14 my-0">
                            <span className="fm darkGrey fw-semibold me-1 d-md-none">
                              Job Title:
                            </span>
                            <span className="job__category darkBlue rounded-pill text-13 text-capitalize">
                              {item.job_title}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-2 col-sm-6 mt-md-0 mt-3">
                          <p className="text-14 my-0">
                            <span className="fm darkGrey fw-semibold me-1 d-md-none">
                              Status:
                            </span>
                            <span
                              className={`text-13 job__category fm darkGrey text-capitalize rounded-pill status-${
                                item && item.sheet ? "Generated" : "Pending"
                              }`}
                            >
                              {item && item.sheet ? "Generated" : "Pending"}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-2 col-sm-6 mt-md-0 mt-3">
                          <p className="text-14 my-0">
                            <span className="fm darkGrey fw-semibold me-1 d-md-none">
                              Created at:
                            </span>
                            {moment(item.createdAt).format("DD.MM.YYYY")}
                          </p>
                        </div>

                        <div className="col-md-2 mt-md-0 mt-3 fm darkGrey">
                          <div className="fm darkGrey">
                            {item && item.sheet ? (
                              <PDFDownloadLink
                                className="fm d-flex darkGrey align-items-center text-decoration-none"
                                document={
                                  <PrepSheet
                                    staticData={item}
                                    indexScore={dashboardData?.indexScore}
                                  />
                                }
                                fileName="PrepSheet.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? (
                                    "Loading ..."
                                  ) : (
                                    <Tooltip
                                      title="Download Prepsheet"
                                      color="#B2DA66"
                                    >
                                      {" "}
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/assets/images/icon_export.svg"
                                          alt=""
                                          className="img-fluid candidates__button--img me-2"
                                        />{" "}
                                        <span className="darkGrey fm">
                                          Download
                                        </span>
                                      </div>{" "}
                                    </Tooltip>
                                  )
                                }
                              </PDFDownloadLink>
                            ) : (
                              "Pending"
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-3 d-flex justify-content-center">
                      <h3 className="fs-3 fm">No Prep Sheets</h3>
                    </div>
                  )}
                </div>

                <div className="">
                  {currentItems && currentItems.length > 0 && (
                    <div className="d-flex justify-content-center pt-3 gap-2">
                    <button className="fm text-12 table__bottom__btn1">Load all candidates{" "}({currentItems.length})</button>
                    <button className="fm text-12 table__bottom__btn2">Scroll to load more <HiOutlineArrowDownCircle className="fs-6" /></button>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProInterviewPrep;
