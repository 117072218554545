const environment = {
    REACT_APP_DEV_SERVER: "https://indexcore-dev.com/",
    REACT_APP_DEV_PROXY: "https://indexcore-dev.com/api",
    Criteria_Base_URL: "https://api.criteriacorp.com/api/v1",
    FB_ID: "1945512265840708",
    Insta_ID: "1115549692466103",
    Linkedin_Client_ID: "77yoapq098cye7",
    Linkedin_Client_Secret: "cVMoAiA2VK4Vlme2",
    Criteria_Token: "08da1708d8fe25ef87db7ef8cee1da0b68048a6e",
    Persona_Environment: "sandbox",
    CALENDY_URL: "indexscore-demo",
  };
  
  export default environment;