import React, {useEffect} from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import WhyBanner from "../pageComponents/WhyIndexcore/WhyBanner/WhyBanner.jsx";
import WhySection1 from "../pageComponents/WhyIndexcore/WhySection1/WhySection1.jsx";
import WhySection2 from "../pageComponents/WhyIndexcore/WhySection2/WhySection2.jsx";
import WhyFaq from "../pageComponents/WhyIndexcore/WhyFaq/WhyFaq.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const WhyIndexCore = () => {
  useEffect(() => {
    document.body.classList.add('whyIndexcore')
    return () => {
      document.body.classList.remove('whyIndexcore')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar page="why-indexcore" />
      <MobileSidebar />
      <WhyBanner />
      <WhySection1 />
      <WhySection2 />
      <WhyFaq />
      <Footer />
    </>
  );
};

export default WhyIndexCore;
