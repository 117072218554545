import React, { useEffect, useRef, useState } from "react";
import { Input, Space, Tag, Tooltip, Upload, Button, Checkbox } from "antd";
import "./style.css";

const SDetails = ({ handleJobTags }) => {
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      handleJobTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };
  return (
    <div className="Role pb-2">
      <div className="Dashboard__container pt-4 pb-2">
        <div className="Dashboard__container___content pt-2">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-xxl-6 col-md-9">
                <p className="fm darkGrey mt-0">
                  Please provide any extra information that can be provided to
                  help the listing. Whats sectors/industries this role will be
                  involved in, skills and programs needed or what will be useful
                  to know. You may also upload and supporting documents that
                  will help.
                </p>
              </div>
              <div className="col-xxl-8 col-md-10">
                <Space className="d-block">
                  <label
                    htmlFor="job"
                    className="darkGrey fm mb-2 fw-semibold fs-6"
                  >
                    Job Tags
                  </label>
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    className="w-100 tag__input form-control form-control-sm input mb-2"
                    id="job"
                  />
                  <Space className="d-flex">
                    {tags.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <div className="d-block">
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          </div>
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={index !== -1}
                          style={{
                            userSelect: "none",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== -1) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </Space>
                </Space>
              </div>

              <div className="col-xxl-8 col-md-10">
                <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-1">
                  Supporting Documents:
                </p>
                <p className="fm darkGrey mb-4 mt-0">
                  Video must be up to 2 minutes in length and under 100MB.
                </p>

                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="document"
                  maxCount={1}
                  className="px-0"
                >
                  <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                    Upload Supporting Documents
                  </Button>
                  <small className="Get__right__upload__small ps-sm-3">
                    File Format: Word or PDF
                  </small>
                </Upload>
              </div>

              <div className="col-xxl-8">
                <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-1">
                  Multiple Candidates:
                </p>
                <p className="fm darkGrey mb-2 mt-0">
                  Are you looking to hire multiple candidates for this job role?
                </p>
                <Checkbox>Yes</Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SDetails;
