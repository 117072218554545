import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import {
  AiFillCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineExclamationCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { CiCirclePlus } from "react-icons/ci";
import { Input, Space, Tag, Tooltip, Upload, Button, Modal } from "antd";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import "rsuite/dist/rsuite.min.css";
import { MyContext } from "../../../Utilities/MyContextProvider";
import {
  improveEmotions,
  getProfessionalById,
  purchaseCourse,
  getPaymentMethods,
} from "../../../services/auth";

const YourIndexScore = () => {
  const { confirm } = Modal;
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { setUserData, getUserData } = useContext(MyContext);
  const responseProfessionalData = JSON.parse(
    localStorage.getItem("professionalData")
  );
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];

  const [state, setState] = useState({
    files: null,
  });

  const { files } = state;

  const [loadingImproveAi, setLoadingImproveAi] = useState(false);
  const [uploadResumeAi, setUploadResumeAi] = useState(false);
  const [uploadVideoLoader, setUploadVideoLoader] = useState(false);
  const [improveVideoLoader, setImproveVideoLoader] = useState(false);
  const [editImprovementVisible, setEditImprovementVisible] = useState(false);
  const [editResumeImprovementsVisible, setEditResumeImprovementsVisible] =
    useState(false);
  const [editVideoImprovementsVisible, setEditVideoImprovementsVisible] =
    useState(false);
  const [paymentCards, setPaymentCards] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const getCandidatePaymentMethods = async () => {
    try {
      setCardLoading(true);
      const paymentMethodPayload = {
        customerId: userData.customerId,
      };
      const response = await getPaymentMethods(paymentMethodPayload);
      setPaymentCards(response.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setCardLoading(false);
    }
  };

  useEffect(() => {
    getCandidatePaymentMethods();
  }, []);
  const showPromiseAiConfirm = () => {
    confirm({
      title: "Pay Now",
      content: "To get AI feedback on improvements, you need to pay $68.",
      onOk() {
        purchaseImprovement("improveAI_feedback", 68);
      },
      onCancel() {},
    });
  };
  const showPromiseResumeConfirm = () => {
    confirm({
      title: "Pay Now",
      content: "To get AI feedback on resume improvements, you need to pay $8.",
      onOk() {
        purchaseImprovement("improve_grammer", 8);
      },
      onCancel() {},
    });
  };
  const showPromiseVideoConfirm = () => {
    confirm({
      title: "Pay Now",
      content: "To get AI feedback on video improvements, you need to pay $8.",
      onOk() {
        purchaseImprovement("video_feedback", 8);
      },
      onCancel() {},
    });
  };

  const updatedProfessionalData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    const responseProfessionalData = respProfessionalByID.data.data;
    localStorage.setItem(
      "professionalData",
      JSON.stringify(respProfessionalByID.data.data)
    );
    setUserData(responseProfessionalData);
  };
  const purchaseImprovement = async (paidFor, amount) => {
    const payloadImprovement = {
      professionalId: userData._id,
      amount: amount,
      cardId: paymentCards[0].id,
      key: paidFor, //video_feedback, video_emotion
    };
    const responsePurchase = await purchaseCourse(payloadImprovement);
    if (responsePurchase.data.statusCode) {
      updatedProfessionalData();
      enqueueSnackbar("Paid Successfully.", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const improveIntroVideoHandler = async (resume) => {
    setImproveVideoLoader(true);
    console.log("resume", resume);
    console.log("resume", resume[0].blobFile);
    const formData = new FormData();
    formData.append("_id", responseProfessionalData[0]._id);
    formData.append("usertype", "professional");
    formData.append("files", resume[0].blobFile);
    var resp = await improveEmotions(formData);

    const professionalId = responseProfessionalData[0]._id;
    var payLoadtoGetPro = {
      _id: professionalId,
    };

    const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    const responseProfessionalApi = respProfessionalByID.data.data;
    setImproveVideoLoader(false);
  };
  const videoEmotionData =
    userData?.improved_indexscores?.video_emotion || null;

  const EditImprovementModal = () => {
    setEditImprovementVisible(true);
  };
  const EditImprovementhandleOk = () => {
    setEditImprovementVisible(false);
  };
  const EditImprovementhandleCancel = () => {
    setEditImprovementVisible(false);
  };

  const EditResumeImprovementsModal = () => {
    setEditResumeImprovementsVisible(true);
  };
  const EditResumeImprovementshandleOk = () => {
    setEditResumeImprovementsVisible(false);
  };
  const EditResumeImprovementshandleCancel = () => {
    setEditResumeImprovementsVisible(false);
  };

  const EditVideoImprovementsModal = () => {
    setEditVideoImprovementsVisible(true);
  };
  const EditVideoImprovementshandleOk = () => {
    setEditVideoImprovementsVisible(false);
  };
  const EditVideoImprovementshandleCancel = () => {
    setEditVideoImprovementsVisible(false);
  };

  const attentionbox = [
    {
      id: 1,
      title: "Social media",
      desc: "Please link you social account to help improve your score. Social accounts missing: Facebook, Instagram, LinkedIn",
    },
    {
      id: 2,
      title: "Social media",
      desc: "Linking your Github account though IndexScore will improve your score",
    },
    {
      id: 3,
      title: "Resume",
      desc: "Our algorithm has found some weak areas within your resume, we have some feeback and suggestions below to improve on.",
    },
  ];
  const improvebox = [
    {
      id: 1,
      title: "Resume",
      desc: "You could add more skills to help improve your score.",
    },
    {
      id: 2,
      title: "references",
      desc: "Reference <Name here> is not as strong as other references it could help to add more references to your profile.",
    },
  ];

  const wellbox = [
    {
      id: 1,
      title: "Security",
      desc: "Your profile security is fully complete!",
    },
  ];

  return (
    <>
      <div className="edash edash2">
        <div className="">
          <style>
            {` 
          .custom-button .custom-button-upload {
            width: 180px !important;
            height: 40px !important;
        }
        
        .custom-button .custom-button-improve {
            width: 180px;
            height: 40px !important;
        }

        .improvement-list {
          list-style: none;
          padding: 0px;
      }
      
      .improvement-list svg {
          
          margin-right: 10px;
      }
      .improvement-list svg.checkMark{
        color: #b2da66;
      }
      .improvement-list svg.need-attention{
        color:#FE0001
      }

      .improvement-list.video-feedback li{
        text-transform:capitalize;
      }
        
        `}
          </style>
          <div className="Dashboard__container pt-lg-0 pt-4">
            <div className="pro__index__page mt-0 border-bottom">
              <div className="container-dash">
                {userData?.kycVerification ? (
                  <div className="mb-4">
                  <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-end mb-2">
                    <div className="indexscore__info d-flex mt-0">
                      <span className="indexscore__val fm fs-1">
                        {userData?.ai_data?.index_core
                          ? userData?.ai_data?.index_core
                          : 0}
                      </span>
                      <span className="mx-1 fw-light fm fs-1">/</span>
                      <span className="indexscore__out_of fw-light fm fs-1">
                        850
                      </span>
                    </div>
                    <div className="indexscore__info__text ">
                      <p className=" fm text-14">
                        <span className="main">+25</span> in the last week from
                        improvements!
                      </p>
                    </div>
                  </div>
                  <ProgressBar
                    className={`progressBar__candidates rounded-pill 
              prog-${
                userData?.ai_data &&
                (parseInt(userData.ai_data.index_core) / 850) * 100 <= 50
                  ? "red"
                  : ""
              } 
              prog-${
                userData?.ai_data &&
                (parseInt(userData.ai_data.index_core) / 850) * 100 <= 80
                  ? "orange"
                  : ""
              } 
              prog-${
                userData?.ai_data &&
                (parseInt(userData.ai_data.index_core) / 850) * 100 > 80
                  ? "main"
                  : ""
              }`}
                    now={
                      userData?.ai_data
                        ? (parseInt(userData.ai_data.index_core) / 850) * 100
                        : 0
                    }
                  />
                </div>
                ) : (
                  <h3 className="text-35 darkBlue fw-lighter fm mb-3">
                          Pending
                        </h3>
                )}
                
              </div>
            </div>
            <div className="pro__index__page mt-4">
              <div className="container-dash">
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="yourIndex__pro__left__box ps-0 pe-0">
                      <div className="yourIndex__insights__detail">
                        <h3 className="text-35 darkBlue fw-lighter fm">
                          Insights
                        </h3>
                        <p className="yourIndex__insights__detail__para text-14">
                          Review your insights and see how you could improve
                          your score.
                        </p>
                      </div>

                      <div className="yourIndex__insights__list my-5">
                        {/* <div className="row">
                          <div className="col-lg-4 col-sm-6 pe-sm-0 mb-2">
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Needs Attention
                            </p>

                            {attentionbox.map((item) => (
                              <div
                                className="yourIndex__insights__box d-flex flex-column justify-content-between"
                                key={item.id}
                              >
                                <div>
                                <div className="yourIndex__insights__label danger__label fm">
                                  Needs attention
                                </div>

                                <p className="fm grey mb-0">{item.title}</p>
                                <p className="fm darkGrey yourIndex__insights__box__desc fw-semibold mt-0">
                                  {item.desc}
                                </p>
                                </div>
                                <p className="blue mb-0 fm d-flex align-items-center text-decoration-none">
                                  <CiCirclePlus className="fs-2 me-1" /> Learn
                                  more
                                </p>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-4 col-sm-6 pe-lg-0 mb-2">
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Let’s improve
                            </p>

                            {improvebox.map((item) => (
                              <div
                                className="yourIndex__insights__box d-flex flex-column justify-content-between"
                                key={item.id}
                              >
                                <div>
                                <div className="yourIndex__insights__label warning__label  fm">
                                  Let’s improve
                                </div>

                                <p className="fm grey mb-0">{item.title}</p>
                                <p className="fm darkGrey yourIndex__insights__box__desc fw-semibold mt-0">
                                  {item.desc}
                                </p>
                                </div>
                                <p className="blue mb-0 fm d-flex align-items-center text-decoration-none">
                                  <CiCirclePlus className="fs-2 me-1" /> Learn
                                  more
                                </p>
                              </div>
                            ))}
                          </div>

                          <div className="col-lg-4 col-sm-6 pe-sm-0 mb-2">
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Doing well
                            </p>

                            {wellbox.map((item) => (
                              <div
                                className="yourIndex__insights__box d-flex flex-column justify-content-between"
                                key={item.id}
                              >
                                <div>
                                <div className="yourIndex__insights__label good__label  fm">
                                  Good
                                </div>

                                <p className="fm grey mb-0">{item.title}</p>
                                <p className="fm darkGrey yourIndex__insights__box__desc fw-semibold mt-0">
                                  {item.desc}
                                </p>
                                </div>
                                <p className="blue mb-0 fm d-flex align-items-center text-decoration-none">
                                  <CiCirclePlus className="fs-2 me-1" /> Learn
                                  more
                                </p>
                              </div>
                            ))}
                          </div>
                        </div> */}

                        <div className="yourIndex__insights__group row flex-grow g-2 mb-0">
                          <div
                            className={`col-md-4 col-sm-6 ${
                              userData?.kycVerification &&
                              userData?.criteria_authorised &&
                              userData?.accurate_authorised
                                ? ""
                                : "disabled-box"
                            }`}
                          >
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Needs Attention
                            </p>
                            <div className="yourIndex__insights__box mb-0 h-100">
                              {userData.improved_indexscores &&
                              userData.improved_indexscores
                                .improveAI_feedback &&
                              userData.improved_indexscores.improveAI_feedback
                                .social_media_feedback ? (
                                <div className="yourIndex__insights__label danger__label">
                                  Needs attention
                                </div>
                              ) : (
                                <div className="yourIndex__insights__label danger__label">
                                  Needs attention
                                </div>
                              )}
                              <h4 className="yourIndex__insights__group__hd">
                                Ai Feedback
                              </h4>
                              <p className="fm darkGrey yourIndex__insights__box__desc mt-0">
                                We have identified opportunity to improve your
                                score.
                              </p>
                              {userData.improved_indexscores &&
                                userData.improved_indexscores
                                  .improveAI_feedback &&
                                userData.improved_indexscores.improveAI_feedback
                                  .paid && (
                                  <p className="yourIndex__insights__para">
                                    {" "}
                                    {userData.improved_indexscores &&
                                    userData.improved_indexscores
                                      .improveAI_feedback &&
                                    userData.improved_indexscores
                                      .improveAI_feedback
                                      .social_media_feedback ? (
                                      <ul className="improvement-list">
                                        <li className="fm text-14">
                                          Please check the improvements{" "}
                                          <AiOutlineQuestionCircle
                                            onClick={EditImprovementModal}
                                            className="text-22 mx-2"
                                          />
                                        </li>
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </p>
                                )}
                              {userData.improved_indexscores &&
                              userData.improved_indexscores
                                .improveAI_feedback &&
                              userData.improved_indexscores.improveAI_feedback
                                .paid ? (
                                ""
                              ) : (
                                <button
                                  className="py-2 fm radius8 btn--main transition px-3 me-2 mb-2"
                                  onClick={() => {
                                    if (
                                      paymentCards &&
                                      Array.isArray(paymentCards) &&
                                      paymentCards.length > 0
                                    ) {
                                      showPromiseAiConfirm();
                                    } else {
                                      enqueueSnackbar(
                                        "Please add payment card first",
                                        {
                                          variant: "error",
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right",
                                          },
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Purchase
                                </button>
                              )}

                              <Modal
                                className="certification-popup improvement__popup"
                                title="Improvement"
                                visible={editImprovementVisible}
                                onOk={EditImprovementhandleOk}
                                onCancel={EditImprovementhandleCancel}
                                footer={null}
                                width={600}
                              >
                                <div className="add-cert-form personal__information edit-name-popup">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div>
                                        <h4 className="yourIndex__insights__group__hd">
                                          Social media feedback
                                        </h4>
                                        {userData.improved_indexscores &&
                                          userData.improved_indexscores
                                            .improveAI_feedback &&
                                          userData.improved_indexscores
                                            .improveAI_feedback
                                            .social_media_feedback && (
                                            <ul className="improvement-list">
                                              <li>
                                                <AiFillCheckCircle className="checkMark" />{" "}
                                                Facebook:{" "}
                                                {userData.improved_indexscores &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback
                                                  .social_media_feedback.fb_auth
                                                  ? "Authorized"
                                                  : "unauthorized"}
                                              </li>
                                              <li>
                                                <AiFillCheckCircle className="checkMark" />{" "}
                                                Twitter:{" "}
                                                {userData.improved_indexscores &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback
                                                  .social_media_feedback
                                                  .twitter_auth
                                                  ? "Authorized"
                                                  : "unauthorized"}
                                              </li>
                                              <li>
                                                <AiFillCheckCircle className="checkMark" />{" "}
                                                Instagram:{" "}
                                                {userData.improved_indexscores &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback
                                                  .social_media_feedback
                                                  .insta_auth
                                                  ? "Authorized"
                                                  : "unauthorized"}
                                              </li>
                                            </ul>
                                          )}
                                      </div>
                                      <hr />
                                      {userData.improved_indexscores &&
                                        userData.improved_indexscores
                                          .improveAI_feedback &&
                                        userData.improved_indexscores
                                          .improveAI_feedback
                                          .references_feedback && (
                                          <>
                                            <div>
                                              <h4 className="yourIndex__insights__group__hd">
                                                References Feedback
                                              </h4>
                                              <p>
                                                {userData.improved_indexscores &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback &&
                                                userData.improved_indexscores
                                                  .improveAI_feedback
                                                  .references_feedback
                                                  .references_analysis ? (
                                                  <>
                                                    <AiOutlineCloseCircle className="required__error" />
                                                    <span className="px-2">
                                                      {" "}
                                                      References feedback
                                                      required
                                                    </span>
                                                  </>
                                                ) : (
                                                  " "
                                                )}
                                              </p>
                                              {userData.improved_indexscores &&
                                              userData.improved_indexscores
                                                .improveAI_feedback &&
                                              userData.improved_indexscores
                                                .improveAI_feedback
                                                .references_feedback
                                                .references_analysis ? (
                                                ""
                                              ) : (
                                                <p className="text-12">
                                                  We have identified opportunity
                                                  to improve your score.
                                                </p>
                                              )}
                                            </div>
                                            <hr />
                                          </>
                                        )}

                                      <div>
                                        <h4 className="yourIndex__insights__group__hd">
                                          Criteria feedback
                                        </h4>
                                        {userData.improved_indexscores &&
                                          userData.improved_indexscores
                                            .improveAI_feedback &&
                                          userData.improved_indexscores
                                            .improveAI_feedback
                                            .criteria_feedback && (
                                            <p className="text-14">
                                              <AiOutlineExclamationCircle className="required__warning" />
                                              <span className="px-2">
                                                {" "}
                                                {userData.improved_indexscores &&
                                                  userData.improved_indexscores
                                                    .improveAI_feedback &&
                                                  userData.improved_indexscores
                                                    .improveAI_feedback
                                                    .criteria_feedback
                                                    .criteria_feedback}
                                              </span>
                                            </p>
                                          )}
                                        <p className="text-12">
                                          We have identified opportunity to
                                          improve your score.
                                        </p>
                                      </div>
                                      {userData && userData.accurate && (
                                        <>
                                          <hr />
                                          <div>
                                            <h4 className="yourIndex__insights__group__hd">
                                              Accurate
                                            </h4>

                                            <p className="text-14">
                                              <AiOutlineExclamationCircle className="required__warning" />
                                              <span className="px-2">
                                                Verification process will take 3
                                                to 5 days for completion of your
                                                background check.
                                              </span>
                                            </p>
                                          </div>
                                        </>
                                      )}
                                      {userData.improved_indexscores &&
                                        userData.improved_indexscores
                                          .improveAI_feedback &&
                                        userData.improved_indexscores
                                          .improveAI_feedback
                                          .open_questions_feedback &&
                                        Object.entries(
                                          userData.improved_indexscores
                                            .improveAI_feedback
                                            .open_questions_feedback
                                        ).map(([key, value]) => (
                                          <>
                                            <hr />
                                            <div>
                                              <h4 className="yourIndex__insights__group__hd">
                                                Open questions Feedback
                                              </h4>
                                              <ul className="improvement-list">
                                                <li key={key}>
                                                  <AiFillCheckCircle className="checkMark" />{" "}
                                                  {value[0]}
                                                </li>
                                              </ul>
                                            </div>
                                          </>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                            </div>
                            {userData?.kycVerification &&
                            userData?.accurate_authorised &&
                            userData?.criteria_authorised ? (
                              ""
                            ) : (
                              <div className="disabled-message">
                                This feature will be enabled once payment is
                                received from the enterprise.
                              </div>
                            )}
                          </div>
                          <div
                            className={`col-md-4 col-sm-6 ${
                              userData?.kycVerification &&
                              userData?.criteria_authorised &&
                              userData?.accurate_authorised
                                ? ""
                                : "disabled-box"
                            }`}
                          >
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Needs Attention
                            </p>
                            <div className="yourIndex__insights__box mb-0 h-100">
                              <div className="yourIndex__insights__label danger__label">
                                Needs attention
                              </div>
                              <h4 className="yourIndex__insights__group__hd">
                                Resume Feedback
                              </h4>
                              <p className="fm darkGrey yourIndex__insights__box__desc mt-0">
                                Our algorithm has found some weak areas within
                                your resume, we have some feeback and
                                suggestions below to improve on.
                              </p>
                              {userData.improved_indexscores &&
                                userData.improved_indexscores.improve_grammer
                                  ?.choices &&
                                userData.improved_indexscores.improve_grammer
                                  .paid && (
                                  <p className="yourIndex__insights__para">
                                    {" "}
                                    {userData.improved_indexscores &&
                                    userData.improved_indexscores
                                      .improve_grammer &&
                                    userData.improved_indexscores
                                      .improve_grammer.choices ? (
                                      <ul className="improvement-list ">
                                        <li className="text-14 fm">
                                          Please check the improvements{" "}
                                          <AiOutlineQuestionCircle
                                            onClick={
                                              EditResumeImprovementsModal
                                            }
                                            className="text-22 mx-2"
                                          />
                                        </li>
                                      </ul>
                                    ) : (
                                      "Improve your resume"
                                    )}
                                  </p>
                                )}

                              <Modal
                                className="certification-popup improvement__popup"
                                title="Resume Improvement"
                                visible={editResumeImprovementsVisible}
                                onOk={EditResumeImprovementshandleOk}
                                onCancel={EditResumeImprovementshandleCancel}
                                footer={null}
                                width={600}
                              >
                                <div className="add-cert-form personal__information edit-name-popup">
                                  <div className="row">
                                    <div className="col-md-12">
                                      {userData.improved_indexscores &&
                                        userData.improved_indexscores
                                          .improve_grammer &&
                                        userData.improved_indexscores
                                          .improve_grammer.choices && (
                                          <ul className="improvement-list improve scroll__data">
                                            {userData.improved_indexscores
                                              .improve_grammer.choices[0]
                                              ?.message?.content && (
                                              <li>
                                                {
                                                  userData.improved_indexscores
                                                    .improve_grammer.choices[0]
                                                    .message.content
                                                }
                                              </li>
                                            )}
                                          </ul>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                              {userData.improved_indexscores &&
                              userData.improved_indexscores.improve_grammer
                                ?.choices &&
                              userData.improved_indexscores.improve_grammer
                                .paid ? (
                                ""
                              ) : (
                                <button
                                  className="py-2 fm transition radius8 btn--main px-3 me-2 mb-2"
                                  onClick={() => {
                                    if (
                                      paymentCards &&
                                      Array.isArray(paymentCards) &&
                                      paymentCards.length > 0
                                    ) {
                                      showPromiseResumeConfirm();
                                    } else {
                                      enqueueSnackbar(
                                        "Please add payment card first",
                                        {
                                          variant: "error",
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right",
                                          },
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Purchase
                                </button>
                              )}
                            </div>
                            {userData?.kycVerification &&
                            userData?.accurate_authorised &&
                            userData?.criteria_authorised ? (
                              ""
                            ) : (
                              <div className="disabled-message">
                                This feature will be enabled once payment is
                                received from the enterprise.
                              </div>
                            )}
                          </div>
                          <div
                            className={`col-md-4 col-sm-6 position-relative ${
                              userData?.kycVerification &&
                              userData?.criteria_authorised &&
                              userData?.accurate_authorised
                                ? ""
                                : "disabled-box"
                            }`}
                          >
                            <p className="fm darkGrey fs-6 fw-semibold mb-2">
                              Needs Attention
                            </p>
                            <div className="yourIndex__insights__box position-relative mb-0 h-100">
                              {userData.improved_indexscores &&
                              userData.improved_indexscores.video_feedback ? (
                                <div className="yourIndex__insights__label danger__label">
                                  Needs attention
                                </div>
                              ) : (
                                <div className="yourIndex__insights__label danger__label">
                                  Needs attention
                                </div>
                              )}
                              <h4 className="yourIndex__insights__group__hd">
                                Video Presentation
                              </h4>
                              <p className="fm darkGrey yourIndex__insights__box__desc mt-0">
                                We have identified opportunity to improve your
                                score.
                              </p>
                              <p className="yourIndex__insights__para mb-0">
                                {" "}
                                {userData.improved_indexscores &&
                                userData.improved_indexscores.video_feedback &&
                                userData.improved_indexscores.video_feedback
                                  .paid ? (
                                  <ul className="improvement-list">
                                    <li className="fm text-14">
                                      Please check the improvements{" "}
                                      <AiOutlineQuestionCircle
                                        onClick={EditVideoImprovementsModal}
                                        className="text-22 mx-2 mb-2"
                                      />
                                    </li>
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </p>

                              <Modal
                                className="certification-popup improvement__popup"
                                title="Video  Improvement"
                                visible={editVideoImprovementsVisible}
                                onOk={EditVideoImprovementshandleOk}
                                onCancel={EditVideoImprovementshandleCancel}
                                footer={null}
                                width={600}
                              >
                                <div className="add-cert-form personal__information edit-name-popup">
                                  <div className="row">
                                    <div className="col-md-12">
                                      {userData.improved_indexscores &&
                                        userData.improved_indexscores
                                          ?.video_feedback?.feedback && (
                                          <ul className="improvement-list improve scroll__data">
                                            {userData.improved_indexscores &&
                                              userData.improved_indexscores.video_feedback.feedback.map(
                                                (item, index) => (
                                                  <li key={index}>{item}</li>
                                                )
                                              )}
                                          </ul>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </Modal>
                              {userData.improved_indexscores &&
                              userData.improved_indexscores.video_feedback &&
                              userData.improved_indexscores.video_feedback
                                .paid ? (
                                ""
                              ) : (
                                <button
                                  className="py-2 fm transition radius8 btn--main px-3 me-2 mb-2"
                                  onClick={() => {
                                    if (
                                      paymentCards &&
                                      Array.isArray(paymentCards) &&
                                      paymentCards.length > 0
                                    ) {
                                      showPromiseVideoConfirm();
                                    } else {
                                      enqueueSnackbar(
                                        "Please add payment card first",
                                        {
                                          variant: "error",
                                          anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "right",
                                          },
                                        }
                                      );
                                    }
                                  }}
                                >
                                  Purchase
                                </button>
                              )}
                            </div>
                            {userData?.kycVerification &&
                            userData?.accurate_authorised &&
                            userData?.criteria_authorised ? (
                              ""
                            ) : (
                              <div className="disabled-message">
                                This feature will be enabled once payment is
                                received from the enterprise.
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourIndexScore;
