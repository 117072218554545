import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import {
  Avatar,
  Progress,
  Dropdown,
  Menu,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  message,
  DatePicker,
} from "antd";
import { Loader, useToaster, Uploader, Message } from "rsuite";
import { CiEdit } from "react-icons/ci";
import { BsPlusCircle, BsCheckCircle } from "react-icons/bs";
import {
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaGithub,
  FaLink,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { AiFillLike, AiOutlineDelete } from "react-icons/ai";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import { isLinkedIn, isTwitter } from "../../../../Utilities/utilities";
import { genderList } from "../../../../Utilities/Genders.js";
import { degreesList } from "../../../../Utilities/Degrees.js";
import { EthnicitiesList } from "../../../../Utilities/Ethnicities.js";
import { Country } from "../../../../Utilities/Country.js";
import { companies } from "../../../../Utilities/Companies.js";
import skillsData from "../../../../Utilities/skills.json";
import { TiDelete } from "react-icons/ti";
import environment from '../../../../environment/index.js';
import config from '../../../../services/apisConfig';
import {
  updateEnterprise,
  uploadprofileImage,
  questionParser,
  uploadVideo,
  uploadFile,
  getEnterpriseByID,
  uploadCompanyImage,
  instaAuthorize
} from "../../../../services/auth";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-input-2";
import { useSnackbar } from "notistack";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Typography,
  Box,
} from "@mui/material";
import FacebookLogin from "@greatsumini/react-facebook-login";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="m-2"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const YDetail = () => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [progress, setProgress] = useState(
    userData?.progress ? userData?.progress?.total : 0
  );
  const { enqueueSnackbar } = useSnackbar();
  const { Option, OptGroup } = Select;
  const skills = skillsData.skills;
  const [options, setOptions] = useState([
    "Energy",
    "Technology",
    "Financial Sevices",
    "Helath Care",
    "Telecommunications",
    "Basic Materials",
    "Industrials",
    "Services",
    "Food Industry",
    "Fashion",
    "Animal",
  ]);
  const optionsArray = [
    {
      values: [
        { name: "Petroleum products and oil" },
        { name: "Natural gas" },
        { name: "Gasoline" },
        { name: "Diesel fuel" },
        { name: "Heating oil" },
        { name: "Nuclear" },
        { name: "Hydropower" },
        { name: "Biofuels such as ethanol" },
        { name: "Wind power" },
        { name: "Solar power" },
        { name: "Chemicals" },
      ],
    },
    {
      values: [
        { name: "Software and services" },
        { name: "Digital Transformation" },
        { name: "Computers and Information" },
        { name: "Technology hardware and equipment " },
        { name: "Semiconductors " },
        { name: "Data Processing & Outsourced Services" },
        { name: "Communication Technology" },
        { name: "Medical Technology" },
        { name: "Product Technology" },
        { name: "Business Technology" },
        { name: "Educational Technology" },
        { name: "Information Technology" },
        { name: "Construction Technology" },
      ],
    },
    {
      values: [
        { name: "Real estate" },
        { name: "Investment" },
        { name: "Trade" },
        { name: "Accounting" },
        { name: "Insurance " },
        { name: "Credit" },
        { name: "Commercial  Banking" },
        { name: "Foreign exchange" },
        { name: "Stock market" },
        { name: "Audit & Advisory" },
        { name: "Wealth Management" },
        { name: "Mortgage" },
        { name: "Capital Markets" },
        { name: "Brokerage" },
        { name: "Consumer Finance" },
        { name: "Financial Exchanges & Data" },
      ],
    },
    {
      values: [
        { name: "Pharmaceutical" },
        { name: "Hospital" },
        { name: "biotechnology" },
        { name: "Medical  equipment" },
        { name: "distribution" },
        { name: "hospital supplies manufacturers" },
      ],
    },
    {
      values: [
        { name: "Entertainment" },
        { name: "Movies" },
        { name: "Music" },
        { name: "Media" },
      ],
    },
    {
      values: [{ name: "Garden" }, { name: "Home" }],
    },
    {
      values: [
        { name: "Mining" },
        { name: "Automotive" },
        { name: "Steel" },
        { name: "Auto Parts & Accessories" },
        { name: "Electronics" },
        { name: "Tools & Equipment" },
        { name: "Toys" },
        { name: "fertilizers" },
      ],
    },
    {
      values: [
        { name: "Academic Services" },
        { name: "Active Life Services" },
        { name: "Agriculutural Services" },
        { name: "Amusement & Recreation Services" },
        { name: "Arts & Marketing Services" },
        { name: "Arts Services" },
        { name: "Automotive Services" },
        { name: "Beauty Services" },
        { name: "Business Services" },
        { name: "Children & Baby Services" },
        { name: "Construction Services" },
        { name: "Consulting Services" },
        { name: "Delivery Services" },
        { name: "Electronic/Technology Services" },
        { name: "Engineering & Management Services" },
        { name: "Entertainment Services" },
        { name: "Concierge Service" },
        { name: "Event Planning Services" },
        { name: "Fashion Services" },
        { name: "Health & Fitness Services" },
        { name: "Health & Medical Services" },
        { name: "Food & Nutrition Services" },
        { name: "Finance Services" },
        { name: "Home Services" },
        { name: "Hospitality Services" },
        { name: "Information Services" },
        { name: "Legal Services" },
        { name: "Lifestyle Services" },
      ],
    },
    {
      values: [
        { name: "Sale and marketing" },
        { name: "Market research" },
        { name: "Content marketing" },
        { name: "Database marketing" },
        { name: "Social media marketing" },
        { name: "Influencer marketing." },
        { name: "Event management" },
      ],
    },
    {
      values: [
        { name: "Books" },
        { name: "Early education" },
        { name: "Primary education" },
        { name: "Secondary" },
        { name: "technical" },
        { name: "vocational" },
        { name: "stationary" },
      ],
    },
    {
      values: [
        { name: "Food & Nutrition" },
        { name: "Hotel" },
        { name: "Restaurant" },
        { name: "beverage" },
        { name: "Dairy" },
        { name: "Fruit and Vegitables" },
        { name: "sugar and confectionery" },
        { name: "Grain an oilseeds" },
        { name: "Bakeries" },
        { name: "Agriculture" },
      ],
    },
    {
      values: [
        { name: "Beauty & Cosmetics" },
        { name: "Lifestyle " },
        { name: "fabrics " },
        { name: "leather Industry" },
        { name: "Footwear" },
      ],
    },
    {
      values: [
        { name: "Pet" },
        { name: "Live stock" },
        { name: "Animal Breeding" },
        { name: "Farming" },
        { name: "Food" },
        { name: "Animal husbandry" },
      ],
    },
    {
      values: [{ name: "sport products" }],
    },
    {
      values: [
        { name: "transport services" },
        { name: "accommodation" },
        { name: "food and drink establishments" },
        { name: "travel agencies" },
        { name: "transport rental and cultural" },
        { name: "sport and recreation services" },
      ],
    },
    {
      values: [{ name: "Bricks" }, { name: "Steel" }, { name: "Cheimical" }],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [visible, setVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [companyPhotoVisible, setCompanyPhotoVisible] = useState(false);
  const [editNameVisible, setEditNameVisible] = useState(false);
  const [editContactVisible, setEditContactVisible] = useState(false);
  const [editHqVisible, setEditHqVisible] = useState(false);
  const [resumeVisible, setResumeVisible] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [editEducationVisible, setEditEducationVisible] = useState(false);
  const [editAreaFocusVisible, setEditAreaFocusVisible] = useState(false);
  const [editAboutYouVisible, setEditAboutYouVisible] = useState(false);
  const [editBioVisible, setEditBioVisible] = useState(false);
  const [editSocialsVisible, setEditSocialsVisible] = useState(false);
  const [editValuesVisible, setEditValuesVisible] = useState(false);

  const [editSkillsVisible, setEditSkillsVisible] = useState(false);
  const [loadingInstagram, setLoadingInstagram] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [updateSocialsLoader, setUpdateSocialsLoader] = useState(false);

  const [state, setState] = useState({
    selectedSkills: userData.skills ? userData.skills : [],
    companyLinkedin: userData.linkedinPublicUrl
      ? userData.linkedinPublicUrl
      : "",
    companyLinkedinData: "",
    twitterURL: "",
    twitterData: "",
    loading: false,
    loading5: false,
    loadingLinkedin: false,
    instagramURL: "",
    loading2: false,
    instagramData: {},
    facebookURL: "",
    facebookData: "",
    loading3: false,
    loading4: false,
    tiktokURL: "",
    companyCareerUrl: userData ? userData?.companyCareerUrl : "",
    companyGlassdoorUrl: userData ? userData?.companyGlassdoorUrl : "",
    emailAddress:
      userData && userData?.Identification?.email
        ? userData?.Identification?.email
        : "",
    phoneNumber:
      userData && userData?.Identification?.phoneNumber
        ? userData?.Identification?.phoneNumber
        : "",
    companyName:
      userData && userData?.Identification?.companyName
        ? userData?.Identification?.companyName
        : "",
    federalTaxID:
      userData && userData?.Identification?.fedaralTaxId
        ? userData?.Identification?.fedaralTaxId
        : "",
    companyfederalTaxID:
      userData &&
      userData?.companyAddress &&
      userData?.companyAddress?.fedaralTaxId
        ? userData?.companyAddress?.fedaralTaxId
        : "",
    corporateCountry:
      userData && userData?.Identification?.country
        ? userData?.Identification?.country
        : "",
    companyCountry:
      userData && userData?.companyAddress && userData?.companyAddress?.country
        ? userData?.companyAddress?.country
        : "",
    corporateContactAddress:
      userData && userData?.Identification?.corpContact
        ? userData?.Identification?.corpContact
        : "",
    companyContactAddress:
      userData &&
      userData?.companyAddress &&
      userData?.companyAddress?.corpContact
        ? userData?.companyAddress?.corpContact
        : "",
    corporateState:
      userData && userData?.Identification?.state
        ? userData?.Identification?.state
        : "",
    companyState:
      userData && userData?.companyAddress && userData?.companyAddress?.state
        ? userData?.companyAddress?.state
        : "",
    corporateZip:
      userData && userData?.Identification?.zip
        ? userData?.Identification?.zip
        : "",
    companyZip:
      userData && userData?.companyAddress && userData?.companyAddress?.zip
        ? userData?.companyAddress?.zip
        : "",
  });
  const {
    selectedSkills,
    showSkills,
    skilllist,
    email,
    searchInput,
    loading5,
    companyCareerUrl,
    companyGlassdoorUrl,
    loadingLinkedin,
    companyLinkedin,
    companyLinkedinData,
    twitterURL,
    twitterData,
    instagramURL,
    instagramData,
    facebookURL,
    facebookData,
    tiktokURL,
    companyName,
    emailAddress,
    federalTaxID,
    phoneNumber,
    corporateContactAddress,
    corporateState,
    corporateCountry,
    corporateZip,
    companyContactAddress,
    companyState,
    companyCountry,
    companyZip,
    companyfederalTaxID,
  } = state;

  const [emails, setEmails] = useState(
    userData.references ? userData.references : [""]
  );
  const [isEmailUpdate, setIsEmailUpdate] = useState(false);

  const [questionsLoading, setQuestionsLoading] = useState(false);
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleChangeSkills = (key, value) => {
    // console.log("key " + key);
    // console.log("value " + value);
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !companyLinkedin || !facebookURL || !facebookData || !selectedSkills;
  isLinkedIn(companyLinkedin);

  const [videoFile, setVideoFile] = useState(null);
  const [videoUrlLoading, setVideoUrlLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [companyImageLoading, setCompanyImageLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [companyImageUrl, setCompanyImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(
    userData ? userData.phoneNumber : ""
  );
  const [updateAddress, setUpdateAddress] = useState(
    userData ? userData.address : ""
  );
  const [updateFirstName, setUpdateFirstName] = useState(
    userData ? userData.firstName : ""
  );
  const [updateLastName, setUpdateLastName] = useState(
    userData ? userData.lastName : ""
  );
  const [updateDob, setUpdateDob] = useState(userData ? userData.dob : "");
  const [updateGender, setUpdateGender] = useState(
    userData ? userData.gender : ""
  );
  const [updateEthnicity, setUpdateEthnicity] = useState(
    userData ? userData.ethnicity : ""
  );
  const [updateCountry, setUpdateCountry] = useState(
    userData ? userData.country : ""
  );
  const [updateState, setUpdateState] = useState(
    userData ? userData.state : ""
  );
  const [updatePostalCode, setUpdatePostalCode] = useState(
    userData ? userData.postalCode : ""
  );

  const [selectedCertification, setSelectedCertification] = useState("");
  const [updateAchievements, setUpdateAchievements] = useState(
    userData ? userData.acheivementsUrl : ""
  );
  const [updateEducation, setUpdateEducation] = useState(
    userData ? userData.education : ""
  );
  const [addCertificateLoader, setAddCertificateLoader] = useState(false);

  const [resumeFile, setResumeFile] = useState(null);
  const [resumeFileLoader, setResumeFileLoader] = useState(false);

  const [updateContactLoader, setUpdateContactLoader] = useState(false);
  const [contactError, setContactError] = useState("");

  const [updateSector, setUpdateSector] = useState(
    userData ? userData.sector : ""
  );
  const [updateIndustry, setuUpdateIndustry] = useState(
    userData ? userData.industry : ""
  );
  const [updateYearlyRevenue, setUpdateYearlyRevenue] = useState(
    userData ? userData.yearlyRevenue : ""
  );
  const [updateNoOfEmployees, setUpdateNoOfEmployees] = useState(
    userData ? userData.noOfEmployees : ""
  );
  const [updateCompanyName, setUpdateCompanyName] = useState(
    userData ? userData.companyName : ""
  );
  const [updateCompanyUrl, setUpdateCompanyUrl] = useState(
    userData ? userData.companyUrl : ""
  );
  const [updateCompanyDescription, setUpdateCompanyDescription] = useState(
    userData ? userData.companyDescription : ""
  );

  const [updateLinkedin, setUpdateLinkedin] = useState(
    userData ? userData?.companySocials?.linkedin : ""
  );
  const [updateInstaLink, setUpdateInstaLink] = useState(
    userData ? userData?.companySocials?.insta : ""
  );
  const [updateTwitterLink, setUpdateTwitterLink] = useState(
    userData ? userData?.companySocials?.twitter : ""
  );
  const [updatefbLink, setUpdatefbLink] = useState(
    userData ? userData?.companySocials?.fb : ""
  );
  const [updateGithubLink, setUpdateGithubLink] = useState(
    userData ? userData?.companySocials?.github : ""
  );

  const [updateAreaFocusLoader, setUpdateAreaFocusLoader] = useState(false);
  const [updateAboutYouLoader, setUpdateAboutYouLoader] = useState(false);
  const [updateBioLoader, setUpdateBioLoader] = useState(false);
  const [areaFocusError, setAreaFocusError] = useState("");
  const [aboutYouError, setaboutYouError] = useState("");
  const [searchInputs, setSearchInputs] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [inputValues, setInputValues] = useState("");
  const [valueItems, setValueItems] = useState(
    userData.companyValues ? userData.companyValues : []
  );
  const [inputBenefits, setInputBenefits] = useState("");
  const [benefitsItems, setBenefitsItems] = useState(
    userData.companyBenefits ? userData.companyBenefits : []
  );

  const filteredSkills = skills.filter((skill) => {
    return skill.toLowerCase().includes(searchInputs.toLowerCase());
  });

  const [updateEducationLoader, setUpdateEducationLoader] = useState(false);
  const [educationError, setEducationError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabClick = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  const updatedEnterpriseData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respEnterpriseByID = await getEnterpriseByID(payLoadtoGetPro);
    const responseEnterpriseData = respEnterpriseByID.data.data;
    localStorage.setItem(
      "enterpriseData",
      JSON.stringify(respEnterpriseByID.data.data)
    );
    setUserData(responseEnterpriseData);
    setProgress(responseEnterpriseData[0]?.progress?.total);
  };

  const uploadPhoto = async () => {
    if (imageUrl) {
      setImageLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", imageUrl.blobFile);
      formData.append("_id", userData._id);
      formData.append("usertype", "enterprise");
    
      try {
        const response = await uploadprofileImage(formData);
        if (response.status == 200) {
          // localStorage.setItem("enterpriseData", JSON.stringify([response.data.data]));
          updatedEnterpriseData();          
          setPhotoVisible(false);
          setImageUrl(null);          
          setImageLoading(false);

          enqueueSnackbar("Photo Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setLoading(false);
    }
  };

  const uploadCompanyPhoto = async () => {
    if (companyImageUrl) {
      setCompanyImageLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", companyImageUrl.blobFile);
      formData.append("_id", userData._id);
      formData.append("usertype", "enterprise");

      try {
        const response = await uploadCompanyImage(formData);
        if (response.status == 200) {
          updatedEnterpriseData();
          setCompanyPhotoVisible(false);
          setCompanyImageUrl(null);
          setCompanyImageLoading(false);

          enqueueSnackbar("Company Photo Updated!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setCompanyImageLoading(false);
    }
  };

  const updateProContactHandler = async () => {
    setUpdateContactLoader(true);
    setContactError(""); // Clear any previous errors

    if (!updatePhoneNumber) {
      setContactError("Phone number is required");
    } else if (!updateAddress) {
      setContactError("Address is required");
    } else {
      const payloadUpdateContact = {
        _id: userData._id,
        firstName: updateFirstName,
        lastName: updateLastName,
        phoneNumber: updatePhoneNumber,
        dob: updateDob,
        gender: updateGender,
        companyName: updateCompanyName,
        country: updateCountry,
        state: updateState,
        postalCode: updatePostalCode,
        address: updateAddress,
      };

      const responseUpdate = await updateEnterprise(payloadUpdateContact);
      updatedEnterpriseData();
      setUpdateContactLoader(false);
      setEditContactVisible(false);
    }
  };
  const updateSocialsHandler = async () => {
    setUpdateSocialsLoader(true);

    const payloadSocials = {
      _id: userData._id,
      companyCareerUrl: companyCareerUrl,
      companyChannels: {
        linkedin: updateLinkedin,
        insta: updateInstaLink,
        twitter: updateTwitterLink,
        fb: updatefbLink,
        github: updateGithubLink,
      },
    };

    const responseUpdate = await updateEnterprise(payloadSocials);
    updatedEnterpriseData();

    setUpdateSocialsLoader(false);
    setEditSocialsVisible(false);
    enqueueSnackbar("Links Updated!", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  };
  const updateProAreaFocusHandler = async () => {
    setUpdateAreaFocusLoader(true);
    setAreaFocusError(""); // Clear any previous errors

    if (!updateSector) {
      setAreaFocusError("Sector is required");
    } else {
      const payloadUpdateEducation = {
        _id: userData._id,
        sector: updateSector,
        industry: updateIndustry,
        yearlyRevenue: updateYearlyRevenue,
        noOfEmployees: updateNoOfEmployees,
        companyGlassdoorUrl: companyGlassdoorUrl,
        companyName: companyName,
        Identification: {
          ...userData.Identification,
          companyName: companyName,
          email: emailAddress,
          phoneNumber: phoneNumber,
        },
        companyUrl: updateCompanyUrl,
      };
      payloadUpdateEducation.Identification.phoneNumber =
        "+" + payloadUpdateEducation.Identification.phoneNumber;

      const responseUpdate = await updateEnterprise(payloadUpdateEducation);
      updatedEnterpriseData();
      setUpdateAreaFocusLoader(false);
      setEditAreaFocusVisible(false);
    }
  };

  const handleSubmitValues = async (e) => {
    e.preventDefault();
    if (!inputValues) return;

    try {
      const updatedValuesItems = [...valueItems, inputValues];
      const payload = {
        _id: userData._id,
        companyValues: updatedValuesItems,
      };
      const responseBenefits = await updateEnterprise(payload);
      setValueItems(updatedValuesItems);
      updatedEnterpriseData();
      setInputValues("");
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleRemoveValues = async (index) => {
    try {
      const updatedItems = [...valueItems];
      updatedItems.splice(index, 1);
      setValueItems(updatedItems);

      const payload = {
        _id: userData._id,
        companyValues: updatedItems,
      };
      const responseBenefits = await updateEnterprise(payload);
      updatedEnterpriseData(); // Assuming this function is correct and updates UI/state

      console.log("Item removed successfully");
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  const handleSubmitBenefits = async (e) => {
    e.preventDefault();
    if (!inputBenefits) return;

    try {
      const updatedBenefitsItems = [...benefitsItems, inputBenefits];
      const payload = {
        _id: userData._id,
        companyBenefits: updatedBenefitsItems,
      };
      const responseBenefits = await updateEnterprise(payload);
      setBenefitsItems(updatedBenefitsItems);
      updatedEnterpriseData(); // Assuming this function is correct and updates UI/state
      setInputBenefits("");
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  const handleRemoveBenefits = async (index) => {
    try {
      const updatedItems = [...benefitsItems];
      updatedItems.splice(index, 1);
      setBenefitsItems(updatedItems);

      const payload = {
        _id: userData._id,
        companyBenefits: updatedItems,
      };
      const responseBenefits = await updateEnterprise(payload);
      updatedEnterpriseData(); // Assuming this function is correct and updates UI/state

      console.log("Item removed successfully");
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  const updateProAboutYouHandler = async () => {
    // alert();
    setUpdateAboutYouLoader(true);
    try {
      var payload = {
        _id: userData._id,
        companyAddress: {
          country: companyCountry,
          fedaralTaxId: companyfederalTaxID,
          corpContact: companyContactAddress,
          state: companyState,
          zip: companyZip,
        },
      };
      const responseUpdate = await updateEnterprise(payload);
      updatedEnterpriseData();
      setUpdateAboutYouLoader(false);
      setEditAboutYouVisible(false);
    } catch (error) {
      setUpdateAboutYouLoader(false);
    }
  };

  const updateBioHandler = async () => {
    // alert();
    setUpdateBioLoader(true);
    try {
      var payload = {
        _id: userData._id,
        companyDescription: updateCompanyDescription,
      };

      const responseUpdate = await updateEnterprise(payload);
      updatedEnterpriseData();
      setUpdateBioLoader(false);
      setEditBioVisible(false);
    } catch (error) {
      setUpdateBioLoader(false);
    }
  };
  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume.pdf");
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const handleResumeUpload = async () => {
    setResumeFileLoader(true);

    console.log("resume", resumeFile);
    console.log("resume", resumeFile[0].blobFile);
    const formData = new FormData();
    formData.append("_id", userData._id);
    formData.append("usertype", "enterprise");
    formData.append("files", resumeFile[0].blobFile);
    var resp = await uploadFile(formData);
    if (resp.status == 200) {
      // localStorage.setItem("enterpriseData", JSON.stringify([resp.data.data]));
      updatedEnterpriseData();
      // setUserData([resp.data.data]);
      setResumeFile(null);
      setResumeFileLoader(false);
      setResumeVisible(false);
    }
  };
  const handleUpdateClick = async () => {
    const areFieldsValid = Object.values(textAreaValues).every(
      (value) => value.trim() !== ""
    );

    if (!areFieldsValid) {
      enqueueSnackbar("All questions must be answered", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }

    setQuestionsLoading(true);

    const updatedValues = {
      question1: textAreaValues.question1,
      question2: textAreaValues.question2,
      question3: textAreaValues.question3,
      question4: textAreaValues.question4,
      question5: textAreaValues.question5,
    };
    const payload = {
      _id: userData._id,
      openAnswers: {
        openQ1: updatedValues.question1,
        openQ2: updatedValues.question2,
        openQ3: updatedValues.question3,
        openQ4: updatedValues.question4,
        openQ5: updatedValues.question5,
      },
    };
    var responseProUpdate = await updateEnterprise(payload);
    if (responseProUpdate.status == 200) {
      enqueueSnackbar("Questions Updated!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setIsEditing(false);
      setQuestionsLoading(true);

      updatedEnterpriseData();
    }
  };

  const handleClose = () => {
    handleChangeSkills("showSkills", false);
  };
  useEffect(() => {
    getDropDownLists();
  }, []);

  const getDropDownLists = async () => {
    try {
      handleChangeSkills("loading", true);

      handleChangeSkills("skilllist", skillsData.skills);
      console.log("skilllist" + JSON.stringify(skilllist));
      handleChangeSkills("loading", false);
    } catch (error) {
      handleChangeSkills("loading", false);
    }
  };
  const handleChange = (key, value) => {
    
    if (key === "files" && value.length > 0) {
        const lastIndex = value.length - 1;
        const lastValue = value[lastIndex];
      // Assuming you want to display only the first uploaded file
      
      setImageUrl(lastValue);
    }

    if (key === "companyFile" && value.length > 0) {
      const lastIndex = value.length - 1;
      const lastValue = value[lastIndex];
      console.log("value[0]", lastValue);
      setCompanyImageUrl(lastValue);
    }
  };
  function handleEmailChange(index, event) {
    const updatedEmails = [...emails];
    updatedEmails[index] = event.target.value;
    setEmails(updatedEmails);
    setIsEmailUpdate(true);
  }
  function addEmail() {
    if (emails.length <= 3) {
      setEmails([...emails, ""]);
    }
  }
  const handleChange1 = (key, value) => {
    const selectedIndex = options.indexOf(value);
    setSelectedOptions(null);
    const selectedOptionValues = optionsArray[selectedIndex].values;
    setUpdateSector(value);
    setSelectedOptions(selectedOptionValues);
  };

  const handleTextAreaChange = (e, questionName) => {
    setTextAreaValues({
      ...textAreaValues,
      [questionName]: e.target.value,
    });
  };

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {
    setVisible(false); // Close the modal after submission
  };
  const handleCancel = () => {
    setVisible(false); // Close the modal without saving changes
  };

  const EditNameModal = () => {
    setEditNameVisible(true);
  };
  const EditNamehandleOk = () => {
    setEditNameVisible(false); // Close the modal after submission
  };
  const EditNamehandleCancel = () => {
    setEditNameVisible(false); // Close the modal without saving changes
  };

  const EditContactModal = () => {
    setEditContactVisible(true);
  };
  const EditContacthandleOk = () => {
    setEditContactVisible(false); // Close the modal after submission
  };
  const EditContacthandleCancel = () => {
    setEditContactVisible(false); // Close the modal without saving changes
  };

  const EditHqModal = () => {
    setEditHqVisible(true);
  };
  const EditHqhandleOk = () => {
    setEditHqVisible(false); // Close the modal after submission
  };
  const EditHqhandleCancel = () => {
    setEditHqVisible(false); // Close the modal without saving changes
  };

  const EditEducationModal = () => {
    setEditEducationVisible(true);
  };
  const EditEducationhandleOk = () => {
    setEditEducationVisible(false); // Close the modal after submission
  };
  const EditEducationhandleCancel = () => {
    setEditEducationVisible(false); // Close the modal without saving changes
  };

  const EditSkillsModal = () => {
    setEditSkillsVisible(true);
  };
  const EditSkillshandleOk = () => {
    setEditSkillsVisible(false); // Close the modal after submission
  };
  const EditSkillshandleCancel = () => {
    setEditSkillsVisible(false); // Close the modal without saving changes
  };

  const EditAreaFocusModal = () => {
    setEditAreaFocusVisible(true);
  };
  const EditAreaFocushandleOk = () => {
    setEditAreaFocusVisible(false); // Close the modal after submission
  };
  const EditAreaFocushandleCancel = () => {
    setEditAreaFocusVisible(false); // Close the modal without saving changes
  };

  const EditAboutYouModal = () => {
    setEditAboutYouVisible(true);
  };
  const EditAboutYouhandleOk = () => {
    setEditAboutYouVisible(false); // Close the modal after submission
  };
  const EditAboutYouhandleCancel = () => {
    setEditAboutYouVisible(false); // Close the modal without saving changes
  };

  const EditValuesModal = () => {
    setEditValuesVisible(true);
  };
  const EditValueshandleOk = () => {
    setEditValuesVisible(false); // Close the modal after submission
  };
  const EditValueshandleCancel = () => {
    setEditValuesVisible(false); // Close the modal without saving changes
  };

  const EditBioModal = () => {
    setEditBioVisible(true);
  };
  const EditBiohandleOk = () => {
    setEditBioVisible(false); // Close the modal after submission
  };
  const EditBiohandleCancel = () => {
    setEditBioVisible(false); // Close the modal without saving changes
  };

  const EditSocialsModal = () => {
    setEditSocialsVisible(true);
  };
  const EditSocialshandleOk = () => {
    setEditSocialsVisible(false); // Close the modal after submission
  };
  const EditSocialshandleCancel = () => {
    setEditSocialsVisible(false); // Close the modal without saving changes
  };

  //photo
  const EditPhotoModal = () => {
    setPhotoVisible(true);
  };
  const EditPhotohandleOk = () => {
    setPhotoVisible(false);
  };
  const EditPhotohandleCancel = () => {
    setPhotoVisible(false);
  };

  //company photo
  const EditCompanyPhotoModal = () => {
    setCompanyPhotoVisible(true);
  };
  const EditCompanyPhotohandleOk = () => {
    setCompanyPhotoVisible(false);
  };
  const EditCompanyPhotohandleCancel = () => {
    setCompanyPhotoVisible(false);
  };

  // Resume modal
  const EditResumeModal = () => {
    setResumeVisible(true);
  };
  const EditResumehandleOk = () => {
    setResumeVisible(false);
  };
  const EditResumehandleCancel = () => {
    setResumeVisible(false);
  };

  const EditVideoModal = () => {
    setVideoVisible(true);
  };
  const EditVideohandleOk = () => {
    //
    setVideoVisible(false); // Close the modal after submission
  };
  const EditVideohandleCancel = () => {
    setVideoVisible(false); // Close the modal without saving changes
  };

  const handleDob = (date, dateString) => {
    handleChange("dob", moment(dateString).format("YYYY-MM-DD"));
    console.log("start date" + moment(dateString).format("YYYY-MM-DD"));
  };

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    return maxDate.toISOString().split("T")[0];
  };

  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 32,
      today.getMonth(),
      today.getDate()
    ); // Set to 16 years before the current date
    return minDate.toISOString().split("T")[0];
  };

  const beforeUpload = (file) => {
    // Check if the selected file is a video
    const isVideo = file.type.startsWith("video/");
    if (!isVideo) {
      console.log("You can only upload video files!");
      // message.error('You can only upload video files!');
    } else {
      setVideoFile(file);
    }
    return false;
  };

  const handleVideoUpload = async () => {
    if (videoFile) {
      setVideoUrlLoading(true);

      // Create a FormData object to send the file to the server
      const formData = new FormData();
      formData.append("files", videoFile[0].blobFile);
      formData.append("_id", userData._id);
      formData.append("usertype", "enterprise");

      try {
        const response = await uploadVideo(formData);
        if (response.status == 200) {
          // localStorage.setItem("enterpriseData", JSON.stringify([response.data.data]));
          const videoData = {
            _id: userData._id,
            videoUploaded: true,
          };
          const responseEnterprise = await updateEnterprise(videoData);
          if (responseEnterprise.status == 200) {
            updatedEnterpriseData();

            setVideoVisible(false);
            setVideoUrl(null);
            setVideoFile(null);
            setVideoUrlLoading(false);

            enqueueSnackbar("Video Updated!", {
              variant: "success",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            });

            if (responseEnterprise.data.profileStatus == 100) {
              enqueueSnackbar(
                "You will get an email once your profile is approved for creating job",
                {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                }
              );
            }
          }
        }
      } catch (error) {
        console.error("Image upload error:", error);
      }

      setVideoUrlLoading(false);
    }
  };

  const DeleteSelectedVideo = () => {
    setVideoFile(null);
  };

  const handleCertificationChange = (value) => {
    setSelectedCertification(value);
  };

  const [textAreaValues, setTextAreaValues] = useState({
    question1: userData.openAnswers
      ? userData.openAnswers.openQ1
        ? userData.openAnswers.openQ1
        : ""
      : "",
    question2: userData.openAnswers
      ? userData.openAnswers.openQ2
        ? userData.openAnswers.openQ2
        : ""
      : "",
    question3: userData.openAnswers
      ? userData.openAnswers.openQ3
        ? userData.openAnswers.openQ3
        : ""
      : "",
    question4: userData.openAnswers
      ? userData.openAnswers.openQ4
        ? userData.openAnswers.openQ4
        : ""
      : "",
    question5: userData.openAnswers
      ? userData.openAnswers.openQ5
        ? userData.openAnswers.openQ5
        : ""
      : "",
  });

  // online presence functions

  const FB_ID = environment.FB_ID;
  const InstaID = environment.Insta_ID;
  const redirectUri = window.location.href;
  const uriInsta = window.location.href;

  const getUrlParameter = (e, uri) => {
    e = e.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var t = new RegExp("[\\?&]" + e + "=([^&#]*)").exec(uri);
    return null === t ? null : decodeURIComponent(t[1].replace(/\+/g, " "));
  };

  const buildUrlFb = () => {
    let params = "fbloged=1";
    let uriX2 = encodeURIComponent(redirectUri);
    return `https://www.facebook.com/v8.0/dialog/oauth?client_id=${FB_ID}&redirect_uri=${uriX2}&state=${params}`;
  };

  const buildUrlInsta = () => {
    let params = "fbloged=1";
    // let uriX2 = encodeURIComponent('https://c56e-101-53-228-14.ngrok-free.app/professional/signup/online-presence/');
    let uriX2 = encodeURIComponent(redirectUri);
    console.log("redirectUri" + redirectUri);
    return `https://api.instagram.com/oauth/authorize?client_id=${InstaID}&redirect_uri=${uriX2}/&scope=user_profile,user_media&response_type=code`;
  };

  const popupWindow = (url, windowName, win, w, h) => {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  };

  const openFbDialog = async () => {
    return new Promise((resolve, reject) => {
      let uri = buildUrlFb();
      let window01 = popupWindow(uri, "", window, 500, 500);
      // fire this immediately after the user accept the logged in
      window01.addEventListener("load", async (event) => {
        try {
          let uri02 = window01.location.href;
          console.log("token from url", uri02);
          let token = getUrlParameter("code", uri02);
          console.log("token from fb", token);
          resolve(token);
          setTimeout(() => {
            window01.close();
          }, 100);
        } catch (ex) {
          reject(null);
        }
      });
    });
  };

  const [code, setCode] = useState(null);
  var codeGrabbed;
  const instagramOAuth = () => {
    const clientId = InstaID;

    const redirectUriInsta = encodeURIComponent(uriInsta);
    const authUrl = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;

    const width = 500;
    const height = 500;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    setLoadingInstagram(true);
    const popup = window.open(
      authUrl,
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        try {
          if (popup.closed) {
            clearInterval(interval);
            reject(new Error("Popup closed"));
          }

          console.log(popup.location.href);
          console.log(redirectUriInsta);

          if (
            popup.location.href.includes(decodeURIComponent(redirectUriInsta))
          ) {
            const url = new URL(popup.location.href);
            codeGrabbed = url.searchParams.get("code");
            console.log("code milgya" + codeGrabbed);
            clearInterval(interval);
            popup.close();
            setCode(codeGrabbed);
            resolve(codeGrabbed);
            getToken();
            setLoadingInstagram(false);
          }
        } catch (error) {
          setLoadingInstagram(false);
          console.error(error);
        }
      }, 1000);
    });
  };

  const getToken = async () => {
    try {
      const payloadVerify = {
        redirect_uri: uriInsta,
        code: codeGrabbed
      }
      const response = await instaAuthorize(payloadVerify);
      const payloadInstagram = {
        _id: userData._id,
        ai_data: {
          ...userData.ai_data,
          insta: response.data,
        },
        instagram_profile_data: response.data,
        instagram_authorised: true,
      };

      const responseInstagram = await updateEnterprise(payloadInstagram);
      updatedEnterpriseData();
      if (responseInstagram) {
        enqueueSnackbar(
          "Instagram Authorized!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );
      }
      
    } catch (error) {
      setLoadingInstagram(false);
      console.error("Error fetching access token:", error);
    }
  };

  const openInstaDialog = async () => {    
    return new Promise((resolve, reject) => {
      let uri = buildUrlInsta();
      let window01 = popupWindow(uri, "", window, 500, 500);      
      window01.addEventListener("load", async (event) => {
        try {
          let uri02 = window01.location.href;
          let token = getUrlParameter("code", uri02);
          resolve(token);
          window01.close();
        } catch (ex) {
          reject(null);
        }
      });
    });
  };

  const myLinkedinLogin = async () => {
    console.log("companyLinkedin", companyLinkedin);
    if (companyLinkedin == "") {
      enqueueSnackbar(
        "Error: Please enter linkedin url!",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        { autoHideDuration: 1000 }
      );
    } else {
      handleChangeSkills("loadingLinkedin", true);

      const payload = {
        _id: userData._id,
        linkedin_purl: companyLinkedin,
        linkedIn_authorised: true,
      };

      const response = await updateEnterprise(payload);
      if (response.data.statusCode == 1) {
        updatedEnterpriseData();
        handleChangeSkills("loadingLinkedin", false);
        enqueueSnackbar(
          "Linkedin Authorized!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );
      }
    }
  };

  const myTwitterLogin = async () => {
    if (twitterURL == "") {
      enqueueSnackbar(
        "Error: Please enter twitter username",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        },
        { autoHideDuration: 1000 }
      );
    } else {
      handleChangeSkills("loading5", true);
      const payload = {
        _id: userData._id,
        twitter_purl: twitterURL,
        twitter_authorised: true,
      };

      const response = await updateEnterprise(payload);
      console.log("response twitter", response);

      if (response.data.statusCode == 1) {
        updatedEnterpriseData();

        handleChangeSkills("loading5", false);
        enqueueSnackbar(
          "Twitter Authorized!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          },
          { autoHideDuration: 1000 }
        );
      }
    }
  };

  return (
    <>
      <div className="edash">
        <style>
          {`
        .meeting--button:hover {
          border-color:"#000  "
        }

        .send-contract-icon img {
          transform: rotate(-90deg);
      }
      
      .item-right button {
          float: right;
      }
      
      .invitation-button {
          width: 240px;
          margin-bottom: 20px;
          padding: 14px;
          line-height: 1;
          border-radius: 25px;
          color: #fff;
          font-size: 16px;
          font-weight: 500;
          border: 2px;
      }
      
      .invitation-button.accept {
          background: #14a800;
          border: 2px solid #14a800;
      }

      
      .certifications-box {
          text-align: center;
          margin-bottom: 20px;
      }
      
      .resume-icons a{
        color:#000;
        text-decoration:none;
        margin-left: 20px;
      }
      
      .resume-icons .download-link img {
        margin-right: 0px;
        }
        .resume-icons a img {
            margin-right: 5px;
            max-width:24px;
        }
        .authorized-icon {
          color: #a6dd50;
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 20px;
          z-index: 999;
      }
      .certifications-list img {
            max-width: 40px;
            margin-right: 20px;
        }
      .certifications-list a{
        text-decoration:none;
        color:#000
      }
      .certifications-box p {
          color: #000;
          margin-bottom: 0px;
      }
      
      .cert-popup-buttons {
          display: flex;
          justify-content: flex-end;
      }
      
      .cert-popup-buttons .primary {
          height: 40px;
      }
      
      .add-cert-form {
          margin-bottom: 200px;
      }
      .add-cert-form.personal__information{
        margin-bottom:50px;
      }
      
      .certification-popup .ant-modal-title {
          color: #000 !important;
          font-weight: 600;
          font-size: 22px;
      }
      
      .certification-popup .ant-modal-header {
          margin-bottom: 30px;
      }
      
      .certification-popup span.anticon.anticon-close.ant-modal-close-icon svg {
          font-size: 16px;
          color: #000;
      }

      .certification-add-dropdown.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::before {
        background: #4a4a49 !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow {
        display: block !important;
    }
    
    .certification-add-dropdown span.ant-select-arrow svg {
        color: #fff;
        font-size: 15px;
        margin-right: 2px;
    }
      
      .edit-name-popup label {
          width: 100%;
          font-size: 16px;
          margin-bottom: 20px;
      }
      
      .edit-name-popup input {
          width: 100%;
          margin-top: 5px;
      }
      
      .profile-image {
          position: relative;
      }
      
      .edit-image {
          position: absolute;
          top: -19px;
          left: -26px;
          width: 40px;
          height: 40px;
      }
      
      .photo-popup.add-cert-form {
          text-align: center;
          margin-bottom: 40px;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-modal-title {
          margin-bottom: 50px;
      }
      
      .upload-photo-box {
          width: 300px;
          height: 300px;
          border: 4px dashed #b8e472;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      
      .video-box.upload-photo-box {
          border-radius: 10px;
          width: 100%;
      }
      
      .upload-video .ant-upload.ant-upload-select {
          width: 100%;
      }
      
      .upload-photo-box p {
          width: 50%;
          font-size: 20px;
          font-weight: 600;
      }
      
      .photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: none;
      }
      .photo-popup.add-cert-form .ant-upload.ant-upload-select{
        // display: none;
      }
      
      .resume-popup.photo-popup.add-cert-form .ant-upload-list.ant-upload-list-document {
          display: inherit;
          margin: auto;
          width: 100%;
          justify-content: center;
          margin-bottom: 30px;
      }
      
      .video-preview {
          position: relative;
      }
      
      .delete-button {
          background: #ff0000;
          color: #fff !important;
          box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.1);
          position: absolute;
          top: 10px;
          right: 10px;
          z-index:100;
      
      }
      
      .delete-button svg {
          color: #fff !important;
      }


      .browse-photo-box .ant-upload.ant-upload-select {
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
    }

    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text{
        width: 300px !important;
        height: 300px !important;
        background: none !important;
        border: 5px dashed #a6dd50 !important;
        display:flex;
        justify-content: center;
        align-items: center;
        margin:0 auto;
        border-radius:50%;
        overflow:hidden;
    }
    .browse-photo-box .pt-4.rs-uploader.rs-uploader-picture-text img{
      border-radius:50%;
    }

    .uploaded-image {
      width: 300px;
      height: 300px;
      max-width: 300px;
      overflow: hidden;
      display: flex;
  }
  .uploaded-image img {
    max-width: 100%;
    width:100%;
    object-fit: cover;
    object-position: center;
}
    
    .browse-photo-box .photo-upload-button span {
        opacity: 0;
        position: absolute;
    }
    
    .browse-photo-box .photo-upload-button div {
        font-size: 20px;
        max-width: 80%;
        margin: 0 auto;
        font-weight: 500;
    }

    .browse-photo-box .ant-upload.ant-upload-select img {
      border-radius: 50%;
    }


    .sharingDropdown h3 {
      font-size: 20px;
      font-weight: 600;
      color: #0d2159;
        }
        
        .sharingDropdown p {
            margin: 0px;
        }
        
        .sharingDropdown li.ant-dropdown-menu-item-divider {
            display: none !important;
        }
        
        .sharingDropdown svg {
            color: #000 !important;
        }
        
        .sharingDropdown button {
            padding: 5px 20px;
            background: #a6dd50;
            color: #fff;
            border-radius: 5px;
        }

        .profile__avatar--img{
          max-width: 100%;
          width: 100%;
          border-radius: 50%;
        }
        .edit-image{
          top: 0px !important;
          left: 0px !important;
        }




        `}
        </style>
        <div className="YDetail ">
          <div className=" position-relative mb-3">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <Tab disabled={true}></Tab>
              {/* <Tab disabled={true}></Tab> */}
              <Tab disabled={true}></Tab>

              <Tab disabled={true}></Tab>
              <Tab disabled={true}></Tab>

              <TabPanel>
                <div className="PDetail__2 position-relative overflow-hidden mb-3">
                  <Progress
                    percent={progress}
                    steps={5}
                    showInfo={false}
                    className="w-100 position-absolute top-0 start-0"
                  />
                  <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                    <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                      Account completion{" "}
                    </h6>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                        {progress}% complete
                      </span>
                      {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                    </div>
                  </div>

                  <div className="PDetail__top--blue p-3">
                    <div className="d-flex flex-md-nowrap w-100 flex-wrap justify-content-between">
                      <div className=" PDetail__top--blue__left me-md-3">
                        <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                          Company Profile{" "}
                        </h6>
                        <p className="fm darkGrey mb-md-0 text-14">
                          We can effectively promote your organization the more
                          we know about your company. Please complete every
                          section to established winning IndexScore enterprise
                          profile.
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {/* <button className="PDetail__top--blue__skip fm  me-2">
                          Skip
                        </button> */}
                        <button
                          onClick={() => {
                            userData?.progress?.profile > 0
                              ? handleTabClick(1)
                              : enqueueSnackbar(
                                  "Please complete company profile to proceed",
                                  {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "right",
                                    },
                                  },
                                  { autoHideDuration: 1000 }
                                );
                          }}
                          className="PDetail__top--blue__goto fm"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="resume__bar rounded-3 p-3 d-md-flex justify-content-between align-items-center gap-3 mb-3">
                  <p className="fm mb-0 text-14">Company page</p>
                  <div className="d-flex gap-2">
                    <Link
                      target={"_blank"}
                      to={`/enterprise-profile/${userData.companyID}`}
                      className="fm text-decoration-none"
                    >
                      <button className="btn--white mt-md-0 mt-3 fm rounded-3 px-3 text-nowrap">
                        View
                      </button>
                    </Link>
                    {/* <Link
                      target={"_blank"}
                      to={`/enterprise-profile-edit/${userData.companyID}`}
                      className="fm text-decoration-none"
                    >
                      <button className="btn--white mt-md-0 mt-3 fm rounded-3 px-3 text-nowrap">
                        Edit
                      </button>
                    </Link> */}
                  </div>
                </div>

                <div className="PDetail__2 p-3 mb-3">
                  <div className="d-flex justify-content-end d-sm-none">
                    <button
                      onClick={EditPhotoModal}
                      className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                    >
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                    <div>
                      <Modal
                        className="certification-popup"
                        title="Change photo"
                        visible={photoVisible}
                        onOk={EditPhotohandleOk}
                        onCancel={EditPhotohandleCancel}
                        footer={null}
                        width={600}
                      >
                        <div className="add-cert-form edit-name-popup photo-popup">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="browse-photo-box">
                                <Uploader
                                  draggable
                                  multiple={false}
                                  listType="picture-text"
                                  fileListVisible={false}
                                  // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  action="//jsonplaceholder.typicode.com/posts/"
                                  onChange={(value) =>
                                    handleChange("files", value)
                                  }
                                  renderFileInfo={(file, fileElement) => {
                                    return (
                                      <div>
                                        <span>File Name: {file.name}</span>
                                        <p style={{ marginLeft: 13 }}>
                                          File type: {file.blobFile.type}
                                        </p>
                                      </div>
                                    );
                                  }}
                                  className="pt-4"
                                >
                                  <div>
                                    {imageUrl && (
                                      <div className="uploaded-image">
                                        {imageUrl.blobFile.type.startsWith(
                                          "image/"
                                        ) && (
                                          <img
                                            src={URL.createObjectURL(
                                              imageUrl.blobFile
                                            )}
                                            alt="File Preview"
                                          />
                                        )}
                                      </div>
                                    )}
                                    {!imageUrl && (
                                      <img src="/assets/images/userAvatar.svg" />
                                    )}
                                  </div>
                                </Uploader>
                              </div>
                            </div>
                            <div className="col-md-12 photo-popup-detail">
                              <p className="mt-3">Photo Formats: jpeg,png</p>
                              <h4 className="px-5">
                                Please select a representative image for your
                                profile
                              </h4>
                              <p>Must be an actual photo of you</p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "16px" }}
                          className="cert-popup-buttons"
                        >
                          <Button
                            className="primary"
                            onClick={EditPhotohandleCancel}
                            style={{ marginRight: "8px" }}
                          >
                            Cancel
                          </Button>
                          <button
                            className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                            onClick={uploadPhoto}
                          >
                            {imageLoading ? <Loader /> : "Upload Photo"}
                          </button>
                        </div>
                      </Modal>

                      {/* company photo */}
                      <Modal
                        className="certification-popup"
                        title="Company photo"
                        visible={companyPhotoVisible}
                        onOk={EditCompanyPhotohandleOk}
                        onCancel={EditCompanyPhotohandleCancel}
                        footer={null}
                        width={600}
                      >
                        <div className="add-cert-form edit-name-popup photo-popup">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="browse-photo-box">
                                <Uploader
                                  draggable
                                  multiple={false}
                                  listType="picture-text"
                                  fileListVisible={false}
                                  // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  action="//jsonplaceholder.typicode.com/posts/"
                                  onChange={(value) =>
                                    handleChange("companyFile", value)
                                  }
                                  renderFileInfo={(file, fileElement) => {
                                    return (
                                      <div>
                                        <span>File Name: {file.name}</span>
                                        <p style={{ marginLeft: 13 }}>
                                          File type: {file.blobFile.type}
                                        </p>
                                      </div>
                                    );
                                  }}
                                  className="pt-4"
                                >
                                  <div>
                                    {companyImageUrl && (
                                      <div className="uploaded-image">
                                        {companyImageUrl.blobFile.type.startsWith(
                                          "image/"
                                        ) && (
                                          <img
                                            src={URL.createObjectURL(
                                              companyImageUrl.blobFile
                                            )}
                                            alt="File Preview"
                                          />
                                        )}
                                      </div>
                                    )}
                                    {!companyImageUrl && (
                                      <img src="/assets/images/userAvatar.svg" />
                                    )}
                                  </div>
                                </Uploader>
                              </div>
                            </div>
                            <div className="col-md-12 photo-popup-detail">
                              <p className="mt-3">Photo Formats: jpeg,png</p>
                              <h4 className="px-5">
                                Please select a representative image for your
                                company profile
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "16px" }}
                          className="cert-popup-buttons"
                        >
                          <Button
                            className="primary"
                            onClick={EditCompanyPhotohandleCancel}
                            style={{ marginRight: "8px" }}
                          >
                            Cancel
                          </Button>
                          <button
                            className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                            onClick={uploadCompanyPhoto}
                          >
                            {companyImageLoading ? (
                              <Loader />
                            ) : (
                              "Upload company photo"
                            )}
                          </button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-start">
                    <div
                      className="d-flex align-items-center"
                      style={{ maxWidth: "500px", width: "100%" }}
                    >
                      <div className="dash__avatar border d-flex justify-content-center align-items-center rounded-circle">
                        <img
                          className="dash__avatar--img p-0 w-100 h-100"
                          // src={"/assets/images/userAvatar.svg"}
                          src={`${
                            userData.companyImage
                              ? userData.companyImage.docs
                              : "/assets/images/userAvatar.svg"
                          }
                          `}
                        />
                      </div>
                      <div className="ms-sm-3 ms-2">
                        <h6 className="fm darkBlue text-20 mb-0">
                          {userData.companyName ? userData.companyName : "----"}
                          {/* Benjamin Smith{" "} */}{" "}
                          {/* {userData.firstName + " " + userData.lastName} */}
                          {/* <span className="midGrey fw-normal text-14">(He/Him)</span> */}
                        </h6>
                        {/* <p className="fm darkGrey text-14 my-1">
                          {userData.companyName ? userData.companyName : "----"}
                        </p> */}
                        <p className="fm grey text-14 my-0">
                          {userData?.Indentification?.state
                            ? userData?.Identification?.state + ","
                            : " "}
                          {userData?.Indentification?.country
                            ? userData?.Identification?.country + ","
                            : " "}
                        </p>
                      </div>
                    </div>
                    <div className="d-sm-flex d-none justify-content-end">
                      <button
                        onClick={EditCompanyPhotoModal}
                        className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                      >
                        <CiEdit className="darkGrey me-1 fs-6" />
                        Edit
                      </button>
                      <div>
                        <Modal
                          className="certification-popup"
                          title="Change photo"
                          visible={photoVisible}
                          onOk={EditPhotohandleOk}
                          onCancel={EditPhotohandleCancel}
                          footer={null}
                          width={600}
                        >
                          <div className="add-cert-form edit-name-popup photo-popup">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="browse-photo-box">
                                  <Uploader
                                    draggable
                                    multiple={false}
                                    listType="picture-text"
                                    fileListVisible={false}
                                    // accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    action="//jsonplaceholder.typicode.com/posts/"
                                    onChange={(value) =>
                                      handleChange("files", value)
                                    }
                                    renderFileInfo={(file, fileElement) => {
                                      return (
                                        <div>
                                          <span>File Name: {file.name}</span>
                                          <p style={{ marginLeft: 13 }}>
                                            File type: {file.blobFile.type}
                                          </p>
                                        </div>
                                      );
                                    }}
                                    className="pt-4"
                                  >
                                    <div>
                                      {imageUrl && (
                                        <div className="uploaded-image">
                                          {imageUrl.blobFile.type.startsWith(
                                            "image/"
                                          ) && (
                                            <img
                                              src={URL.createObjectURL(
                                                imageUrl.blobFile
                                              )}
                                              alt="File Preview"
                                            />
                                          )}
                                        </div>
                                      )}
                                      {!imageUrl && (
                                        <img src="/assets/images/userAvatar.svg" />
                                      )}
                                    </div>
                                  </Uploader>
                                </div>
                              </div>
                              <div className="col-md-12 photo-popup-detail">
                                <p className="mt-3">Photo Formats: jpeg,png</p>
                                <h4 className="px-5">
                                  Please select a representative image for your
                                  profile
                                </h4>
                                <p>Must be an actual photo of you</p>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "16px" }}
                            className="cert-popup-buttons"
                          >
                            <Button
                              className="primary"
                              onClick={EditPhotohandleCancel}
                              style={{ marginRight: "8px" }}
                            >
                              Cancel
                            </Button>
                            <button
                              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              onClick={uploadPhoto}
                            >
                              {imageLoading ? <Loader /> : "Upload Photo"}
                            </button>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-3 pb-4 mb-3 PDetail__2">
                  <div className="pb-2">
                    <div className="d-flex justify-content-between align-items-start">
                      <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                        Personal Information
                      </h6>
                      <button
                        onClick={EditContactModal}
                        className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                      >
                        <CiEdit className="darkGrey me-1 fs-6" />
                        Edit
                      </button>

                      <Modal
                        className="certification-popup"
                        title="Edit personal information"
                        visible={editContactVisible}
                        onOk={EditContacthandleOk}
                        onCancel={EditContacthandleCancel}
                        footer={null}
                        width={600}
                      >
                        <div className="add-cert-form personal__information edit-name-popup">
                          <div className="row">
                            <div className="col-md-6">
                              <label>
                                First name
                                <input
                                  type="text"
                                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                                  placeholder="Enter first name"
                                  id="sector1"
                                  value={updateFirstName}
                                  onChange={(e) =>
                                    setUpdateFirstName(e.target.value)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Last name
                                <input
                                  type="text"
                                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                                  placeholder="Enter last name"
                                  id="sector1"
                                  value={updateLastName}
                                  onChange={(e) =>
                                    setUpdateLastName(e.target.value)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Phone number
                                <PhoneInput
                                  defaultCountry={"us"}
                                  className=""
                                  placeholder="Enter your phone number"
                                  value={updatePhoneNumber}
                                  onChange={(phone) =>
                                    setUpdatePhoneNumber(phone)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Date of birth
                                <input
                                  type="date"
                                  className="form-control form-control-md w-100 Dashboard__input "
                                  placeholder="Date of birth"
                                  title="Select date of birth"
                                  value={updateDob || ""}
                                  onChange={(value) => {
                                    console.log("dob", value.target.value);
                                    setUpdateDob(value.target.value);
                                  }}
                                  max={getMaxDate()}
                                  min={getMinDate()}
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Gender
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                                  size="small"
                                  placeholder="Select gender"
                                  value={updateGender}
                                  onChange={(value) => {
                                    setUpdateGender(value);
                                  }}
                                >
                                  {genderList.map((item) => (
                                    <Option
                                      value={item.name}
                                      label={item.name.toLowerCase()}
                                      key={item.name}
                                    >
                                      {item.name}
                                    </Option>
                                  ))}
                                </Select>
                              </label>
                            </div>

                            <div className="col-md-6">
                              <label>
                                Country
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare(
                                        (optionB?.label ?? "").toLowerCase()
                                      )
                                  }
                                  className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                                  size="small"
                                  value={updateCountry}
                                  placeholder="Job location"
                                  onChange={(value) => {
                                    // handleChange("job_locations", value);
                                    setUpdateCountry(value);
                                  }}
                                >
                                  <OptGroup>
                                    {Country.map((item) => (
                                      <Option
                                        value={item.code}
                                        label={item.name.toLowerCase()}
                                        key={item.code}
                                      >
                                        {item.name}
                                      </Option>
                                    ))}
                                  </OptGroup>
                                </Select>
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                State/City
                                <input
                                  type="text"
                                  className="form-control form-control-md  w-100 Dashboard__input mb-2"
                                  placeholder="Enter state/city"
                                  id="sector1"
                                  value={updateState}
                                  onChange={(e) =>
                                    setUpdateState(e.target.value)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Postal/Zip Code
                                <input
                                  type="text"
                                  className="form-control form-control-md  w-100 Dashboard__input mb-2"
                                  placeholder="Enter postal/zip code"
                                  id="sector1"
                                  value={updatePostalCode}
                                  onChange={(e) =>
                                    setUpdatePostalCode(e.target.value)
                                  }
                                />
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label>
                                Your address
                                <input
                                  type="text"
                                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                                  placeholder="Enter your address"
                                  id="sector1"
                                  value={updateAddress}
                                  onChange={(e) =>
                                    setUpdateAddress(e.target.value)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {contactError && (
                          <div className="error-message">{contactError}</div>
                        )}
                        <div
                          style={{ marginTop: "16px" }}
                          className="cert-popup-buttons"
                        >
                          <Button
                            className="primary"
                            onClick={EditContacthandleCancel}
                            style={{ marginRight: "8px" }}
                          >
                            Cancel
                          </Button>
                          <button
                            className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                            onClick={updateProContactHandler}
                          >
                            {updateContactLoader ? <Loader /> : "Update"}
                          </button>
                        </div>
                      </Modal>
                    </div>
                    <div className="row mt-4">
                      <div className="col-xl-2 mb-xl-0 mb-4">
                        <div className="d-flex mb-2 align-items-center">
                          <p className="fm darkGrey mb-2 text-14 fw-light">
                            Profile Picture
                          </p>
                          <button
                            onClick={EditPhotoModal}
                            className="d-flex align-items-center bg-white rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14 me-4"
                          >
                            <CiEdit className="darkGrey fs-6" />
                          </button>
                        </div>
                        <div className="dashprofile__avatar mx-sm-0 mx-auto border d-flex justify-content-center align-items-center rounded-circle">
                          <img
                            className="PDetail__2__avatar--img p-0 w-100 h-100"
                            // src={"/assets/images/userAvatar.svg"}
                            src={`${
                              userData.profileImage
                                ? userData.profileImage.docs
                                : "/assets/images/userAvatar.svg"
                            }
                          `}
                          />
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-10">
                        <div className="row">
                          <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light ">
                              First name
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.firstName}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6 mb-md-0 mb-4 d-md-none">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Last name
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.lastName}
                            </p>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-sm-0 mb-4 d-md-none d-block">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Gender
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.gender}
                            </p>
                          </div>
                          <div className="col-md-3 col-sm-6 mb-sm-0 mb-4 d-md-none d-block">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Date of birth
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.dob}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light ">
                              Email address
                            </p>
                            <p className="text-14 fm darkGrey my-0 word-wrap">
                              {userData.emailAddress}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Country
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.country
                                ? Country.find(
                                    (country) =>
                                      country.code === userData.country
                                  )?.name
                                : "--"}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              State/City
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.state ? userData.state : "--"}
                            </p>
                          </div>
                        </div>

                        <div className="row d-flex mt-4">
                          <div className="col-md-3 col-sm-6 mb-md-0 mb-4 d-md-block d-none">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Last name
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.lastName}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6 mb-md-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Phone
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.phoneNumber}
                            </p>
                          </div>

                          {/* <div className="col-md-3 col-sm-6 d-md-none d-block">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Mobile
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.phoneNumber}
                            </p>
                          </div> */}

                          <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Address
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.address ? userData.address : "--"}
                            </p>
                          </div>

                          <div className="col-md-3 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Postcode/Zip
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.postalCode ? userData.postalCode : "--"}
                            </p>
                          </div>
                        </div>

                        <div className="row d-flex mt-md-4 d-md-flex d-none">
                          {/* <div className="col-md-3 col-sm-6 mb-sm-0 mb-4 ">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Job title
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.jobTitle}
                            </p>
                          </div> */}

                          {/* <div className="col-md-3 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Mobile
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.phoneNumber}
                            </p>
                          </div> */}

                          <div className="col-md-3 col-sm-6 mb-sm-0 mb-4">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Gender
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.gender}
                            </p>
                          </div>
                          <div className="col-md-3 col-sm-6">
                            <p className="text-14 fm darkGrey mb-0 fw-light">
                              Dob
                            </p>
                            <p className="text-14 fm darkGrey my-0">
                              {userData.dob}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-2 mt-lg-0 mt-4">
                        <p className="text-14 fm darkGrey mb-0 fw-light">
                          Social channels
                        </p>
                        <a
                          className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                          href={updateLinkedin}
                        >
                          <FaLinkedin className="text-14 me-1" /> LinkedIn
                        </a>
                        <a
                          className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                          href={updateInstaLink}
                        >
                          <FaInstagram className="text-14 me-1" /> Instagram
                        </a>
                        <a
                          className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                          href={updateTwitterLink}
                        >
                          <FaSquareXTwitter className="text-14 me-1" /> Twitter
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3 pe-md-1">
                    <div className="p-3 pb-4 PDetail__2 h-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          Company Information
                        </h6>
                        <button
                          onClick={EditAreaFocusModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Name
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.companyName
                              ? userData.companyName
                              : "----"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Phone
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData?.Identification?.phoneNumber
                              ? userData?.Identification?.phoneNumber
                              : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-lg-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Email Address
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData?.Identification?.email
                              ? userData?.Identification?.email
                              : "--"}
                          </p>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Website
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.companyUrl ? userData.companyUrl : "----"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Sector
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.sector ? userData.sector : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-lg-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Industry
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.industry ? userData.industry : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Glassdoor Url
                          </p>
                          <p className="text-14 fm darkGrey my-0 custom-class">
                            {userData.companyGlassdoorUrl
                              ? userData.companyGlassdoorUrl
                              : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Size (People)
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.noOfEmployees
                              ? userData.noOfEmployees
                              : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-lg-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Size (Turnover)
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            $
                            {userData.yearlyRevenue
                              ? userData.yearlyRevenue
                              : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="p-3 pb-4  PDetail__2 h-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          Company HQ Address
                        </h6>
                        <button
                          onClick={EditAboutYouModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Country
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData &&
                            userData.companyAddress &&
                            userData.companyAddress.country
                              ? Country.find(
                                  (country) =>
                                    country.code ===
                                    userData.companyAddress.country
                                )?.name
                              : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            State/City
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData &&
                            userData.companyAddress &&
                            userData.companyAddress.state
                              ? userData.companyAddress.state
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-sm-6">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Address
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData &&
                            userData.companyAddress &&
                            userData.companyAddress.corpContact
                              ? userData.companyAddress.corpContact
                              : "--"}
                          </p>
                        </div>

                        <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Postcode/Zip
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData &&
                            userData.companyAddress &&
                            userData.companyAddress.zip
                              ? userData.companyAddress.zip
                              : "--"}
                          </p>
                        </div>
                        <div className="col-lg-4 col-sm-6 mt-lg-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Federal Tax ID
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData &&
                            userData.companyAddress &&
                            userData.companyAddress.fedaralTaxId
                              ? userData.companyAddress.fedaralTaxId
                              : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row equal">
                  <div className="col-md-6 mb-3 pe-md-1">
                    <div className="p-3 pb-4 PDetail__2 h-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          Company Biography
                        </h6>
                        <button
                          onClick={EditBioModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>

                      <p className="text-14 fm darkGrey mb-0 mt-4">
                        {userData.companyDescription
                          ? userData.companyDescription
                          : "--"}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="p-3 pb-4 PDetail__2 h-100">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          Company Social Channels
                        </h6>
                        <button
                          onClick={EditSocialsModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-4">
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={updateLinkedin}
                          >
                            <FaLinkedin className="fs-6 me-1" /> LinkedIn
                          </a>
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={updateInstaLink}
                          >
                            <FaInstagram className="fs-6 me-1" /> Instagram
                          </a>
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={updateTwitterLink}
                          >
                            <FaSquareXTwitter className="fs-6 me-1" /> Twitter
                          </a>
                        </div>
                        <div className="col-md-4 mt-md-0 mt-4">
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={updatefbLink}
                          >
                            <FaFacebook className="fs-6 me-1" /> Facebook
                          </a>
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={updateGithubLink}
                          >
                            <FaGithub className="fs-6 me-1" /> Github
                          </a>
                          <a
                            className="text-14 fm darkGrey mb-1 d-flex align-items-center text-decoration-none"
                            target={"_blank"}
                            href={userData?.companyCareerUrl}
                          >
                            <FaLink className="fs-6 me-1" /> Career url
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="PDetail__2 p-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          Company Values & Benefits
                        </h6>
                        <button
                          onClick={EditValuesModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 pe-md-1">
                      <p className="text-14 fm darkGrey mb-1 mt-4 fw-semibold text-uppercase">
                        Values:
                      </p>

                      <ul className="list-unstyled mb-0">
                        {valueItems.map((item, index) => (
                          <li key={index} className="text-14 fm darkGrey mb-1">
                            —{" "}{item}
                          </li>
                        ))}
                      </ul>
                      {/* <ul className="list-unstyled mb-0">
                        <li className="text-14 fm darkGrey">
                          — We value honesty.
                        </li>
                        <li className="text-14 fm darkGrey">
                          — We are pragmatic.
                        </li>
                        <li className="text-14 fm darkGrey">
                          — We love ambition.
                        </li>
                        <li className="text-14 fm darkGrey">
                          — We challenge the status quo.
                        </li>
                        <li className="text-14 fm darkGrey">
                          — We champion talent.
                        </li>
                        <li className="text-14 fm darkGrey">
                          — We support growth.
                        </li>
                      </ul> */}
                    </div>

                    <div className="col-md-6 mb-3 pe-md-1 mt-md-0 mt-4">
                      <div className="ps-md-3">
                        <p className="text-14 fm darkGrey mb-1 mt-4 fw-semibold text-uppercase">
                          Benefits:
                        </p>
                        <ul className="list-unstyled mb-0">
                          {benefitsItems.map((item, index) => (
                            <li key={index} className="text-14 fm darkGrey mb-1">
                              —{" "}{item}
                            </li>
                          ))}
                          {/* <li className="text-14 fm darkGrey">
                            — We value honesty.
                          </li>
                          <li className="text-14 fm darkGrey">
                            — We are pragmatic.
                          </li>
                          <li className="text-14 fm darkGrey">
                            — We love ambition.
                          </li>
                          <li className="text-14 fm darkGrey">
                            — We challenge the status quo.
                          </li>
                          <li className="text-14 fm darkGrey">
                            — We champion talent.
                          </li>
                          <li className="text-14 fm darkGrey">
                            — We support growth.
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="PDetail__2 p-3">
                      <h6 className="fs-6 darkGrey fw-semibold">
                        Hide/Delete your account
                      </h6>
                      <p className="text-14 fm darkGrey dash__del__text">
                        It is possible to delete your account but once deleted
                        there's no way to recover any information lined to the
                        account . Please be sure you want to delete your account
                        before confirming.{" "}
                      </p>
                      <button className="PDetail__3__delete fm text-14 text-white transition mt-md-0 mt-3 mb-3">
                        Delete account
                      </button>
                    </div>
                  </div>
                </div> */}
              </TabPanel>
              {/* <TabPanel>
                <div className="PDetail__2 position-relative overflow-hidden mb-3">
                  <Progress
                    percent={progress}
                    steps={5}
                    showInfo={false}
                    className="w-100 position-absolute top-0 start-0"
                  />
                  <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                    <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                      Account completion{" "}
                    </h6>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                        {progress}% complete
                      </span>                      
                    </div>
                  </div>

                  <div className="PDetail__top--blue p-3 ">
                    <div className="w-100 d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                      <div className=" PDetail__top--blue__left me-md-3">
                        <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                          Company Details{" "}
                        </h6>
                        <p className="fm text-14 darkGrey mb-md-0">
                          We can effectively promote your organization the more
                          we know about your company. Please complete every
                          section to established winning IndexScore enterprise
                          profile.
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          onClick={() => {
                            handleTabClick(0);
                          }}
                          className="PDetail__top--blue__skip fm  me-2"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => {
                            handleTabClick(2);
                          }}
                          className="PDetail__top--blue__goto fm"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="PDetail__3 p-3 mb-3">
                  <div className=" pb-2">
                    <div className="pb-4 ">
                      <div className="d-flex justify-content-between align-items-start">
                        <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                          About Company
                        </h6>
                        <button
                          onClick={EditAboutYouModal}
                          className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        >
                          <CiEdit className="darkGrey me-1 fs-6" />
                          Edit
                        </button>
                      </div>
                      <div className="mt-2 d-md-flex justify-content-md-start justify-content-between">
                        <div className="PDetail__3__row__col company-col pe-sm-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Company name
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.Identification.companyName
                              ? userData.Identification.companyName
                              : "--"}
                          </p>
                        </div>
                        <div className="PDetail__3__row__col company-col pe-sm-4 mt-md-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Email
                          </p>
                          <p className="text-14 fm darkGrey my-0 word-wrap">
                            {userData?.Identification?.email
                              ? userData?.Identification?.email
                              : "--"}
                          </p>
                        </div>
                        <div className="PDetail__3__row__col company-col mt-md-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Phone number
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData?.Identification?.phoneNumber
                              ? userData?.Identification?.phoneNumber
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="PDetail__3__row   mt-4 d-md-flex justify-content-start">
                        <div className="PDetail__3__row__col company-col pe-sm-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Fedaral tax id
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData?.Identification?.fedaralTaxId
                              ? userData?.Identification?.fedaralTaxId
                              : "--"}
                          </p>
                        </div>
                        <div className="PDetail__3__row__col company-col mt-md-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Corporate contact address
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData?.Identification?.corpContact
                              ? userData?.Identification?.corpContact
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="PDetail__3__row   mt-4 d-md-flex justify-content-start">
                        <div className="PDetail__3__row__col company-col pe-sm-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Company career url
                          </p>
                          <p className="text-14 fm darkGrey my-0 word-wrap">
                            {userData?.companyCareerUrl
                              ? userData?.companyCareerUrl
                              : "--"}
                          </p>
                        </div>
                        <div className="PDetail__3__row__col company-col mt-md-0 mt-4">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Company glassdoor url
                          </p>
                          <p className="text-14 fm darkGrey my-0 word-wrap">
                            {userData?.companyGlassdoorUrl
                              ? userData?.companyGlassdoorUrl
                              : "--"}
                          </p>
                        </div>
                      </div>
                      <div className="PDetail__3__row mt-4 mb-3 d-flex flex-md-row flex-column">
                        <div className="col-md-12">
                          <p className="text-14 fm darkGrey mb-0 fw-light">
                            Company Description
                          </p>
                          <p className="text-14 fm darkGrey my-0">
                            {userData.companyDescription
                              ? userData.companyDescription
                              : "--"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-3 mb-3 PDetail__2">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-start">
                      <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                        Your area of focus:
                      </h6>
                      <button
                        onClick={EditAreaFocusModal}
                        className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                      >
                        <CiEdit className="darkGrey me-1 fs-6" />
                        Edit
                      </button>
                    </div>
                    <div className=" mt-2 d-flex justify-content-md-start justify-content-between flex-xl-nowrap flex-wrap">
                      <div className="PDetail__3__row__col mb-4">
                        <p className="text-14 fm darkGrey mb-0 fw-light">
                          Sector
                        </p>
                        <p className="text-14 fm darkGrey my-0">
                          {userData.sector ? userData.sector : "--"}
                        </p>
                      </div>
                      <div className="PDetail__3__row__col mb-4">
                        <p className="text-14 fm darkGrey mb-0 fw-light">
                          Industry
                        </p>
                        <p className="text-14 fm darkGrey my-0">
                          {userData.industry ? userData.industry : "--"}
                        </p>
                      </div>
                      <div className="PDetail__3__row__col mb-4">
                        <p className="text-14 fm darkGrey mb-0 fw-light">
                          Yearly Revenue
                        </p>
                        <p className="text-14 fm darkGrey my-0">
                          {userData.yearlyRevenue
                            ? userData.yearlyRevenue
                            : "--"}
                        </p>
                      </div>
                      <div className="PDetail__3__row__col mb-4">
                        <p className="text-14 fm darkGrey mb-0 fw-light">
                          No of total employees
                        </p>
                        <p className="text-14 fm darkGrey my-0">
                          {userData.noOfEmployees
                            ? userData.noOfEmployees
                            : "--"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                
              </TabPanel> */}
              {/* <Extra /> */}
              <TabPanel>
                <div className="PDetail__2 position-relative overflow-hidden mb-3">
                  <Progress
                    percent={progress}
                    steps={5}
                    showInfo={false}
                    className="w-100 position-absolute top-0 start-0"
                  />
                  <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                    <h6 className="fm darkGrey pt-2 fw-semibold fs-6 mb-0 nowrap">
                      Account completion{" "}
                    </h6>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                        {progress}% complete
                      </span>
                      {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                    </div>
                  </div>

                  <div className="PDetail__top--blue p-3">
                    <div className="w-100 d-flex flex-md-nowrap flex-wrap justify-content-between w-100">
                      <div className=" PDetail__top--blue__left me-md-3">
                        <h6 className="fm darkGrey fw-semibold fs-6 mb-2 nowrap">
                          Online Presence{" "}
                        </h6>
                        <p className="fm darkGrey mb-md-0 text-14">
                          Your corporate web presence, messaging, engagement,
                          all are critical ingredients to your IndexScore
                          enterprise profile. Please provide as much info as
                          possible.
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          onClick={() => {
                            handleTabClick(0);
                          }}
                          className="PDetail__top--blue__skip fm  me-2"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => {
                            handleTabClick(2);
                          }}
                          className="PDetail__top--blue__goto fm"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2 p-3 PDetail__2">
                  <div className="row mb-3 g-3">
                    <div className="col-md-12">
                      <h5 className="fm text-20 darkBlue fw-semibold ">
                        Online Presense
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <div
                        className={`api__boxes h-100 mb-0 ${
                          !userData?.facebook_authorised ? "not-authorised" : ""
                        }`}
                      >
                        <div className="api__box__header d-flex align-items-start">
                          <div>
                            <img src="/assets/images/api/fb.png" />{" "}
                          </div>
                          <div>
                            {/* <button 
                            disabled={userData.facebook_authorised} 
                            className={`connect__button ${userData.facebook_authorised ? 'connected' : 'pending'}`} 
                            onClick={() => { myFbLogin(); }}>
                            {userData.facebook_authorised ? 'Connected' : loadingFacebook ? <Loader /> : 'Connect'}</button> */}
                            <FacebookLogin
                              appId={FB_ID}
                              className={`connect__button ${
                                userData.facebook_authorised
                                  ? "connected"
                                  : "pending"
                              }`}
                              useRedirect={false}
                              children={
                                <div>
                                  {userData.facebook_authorised
                                    ? "Connected"
                                    : "Connect"}
                                </div>
                              }
                              fields="id,name,email,picture,posts,friends,likes"
                              onSuccess={(sucessdata) => {
                                console.log("sucessdata", sucessdata);
                              }}
                              onFail={(faildata) => {
                                console.log("faildata", faildata);
                                enqueueSnackbar(
                                  "Facebook not authorized",
                                  {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "right",
                                    },
                                  },
                                  { autoHideDuration: 1000 }
                                );
                              }}
                              onProfileSuccess={async (onprofilesuccess) => {
                                console.log(
                                  "onprofilesuccess",
                                  onprofilesuccess
                                );

                                const payloadFacebook = {
                                  _id: userData._id,
                                  ai_data: {
                                    ...userData.ai_data,
                                    fb: onprofilesuccess,
                                  },
                                  facebook_profile_data: onprofilesuccess,
                                  facebook_authorised: true,
                                };

                                const responseFacebook = await updateEnterprise(
                                  payloadFacebook
                                );                                
                                if (responseFacebook) {
                                  updatedEnterpriseData();
                                  setLoadingFacebook(false);
                                  enqueueSnackbar(
                                    "Facebook Authorized!",
                                    {
                                      variant: "success",
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "right",
                                      },
                                    },
                                    { autoHideDuration: 1000 }
                                  );
                                }
                              }}
                              initParams={{
                                version: "v10.0",
                                xfbml: true,
                              }}
                              dialogParams={{
                                response_type: "token",
                              }}
                              loginOptions={{
                                return_scopes: true,
                              }}
                            />
                          </div>
                        </div>
                        <div className="api__box__body">
                          {userData?.facebook_authorised ? (
                            ""
                          ) : (
                            <h4 className="fs-6 fm darkGrey">Remember to connect!</h4>
                          )}
                          <p className="fm text-14 darkGrey">
                            We use corporate social media presence to understand
                            organizational culture, purpose and talent
                            engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className={`api__boxes h-100 mb-0 ${
                          !userData?.instagram_authorised
                            ? "not-authorised"
                            : ""
                        }`}
                      >
                        <div className="api__box__header d-flex align-items-start">
                          <div>
                            <img src="/assets/images/api/insta.png" />{" "}
                          </div>
                          <div>
                            <button
                              disabled={userData.instagram_authorised}
                              className={`connect__button ${
                                userData.instagram_authorised
                                  ? "connected"
                                  : "pending"
                              }`}
                              onClick={instagramOAuth}
                            >
                              {userData.instagram_authorised ? (
                                "Connected"
                              ) : loadingInstagram ? (
                                <Loader />
                              ) : (
                                "Connect"
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="api__box__body">
                          {userData?.instagram_authorised ? (
                            ""
                          ) : (
                            <h4 className="fm darkGrey fs-6">Remember to connect!</h4>
                          )}
                          <p className="fm darkGrey text-14">
                            We use corporate social media presence to understand
                            organizational culture, purpose and talent
                            engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className={`api__boxes h-100 mb-0 ${
                          !userData.twitter_authorised ? "not-authorised" : ""
                        }`}
                      >
                        <div className="api__box__header d-flex align-items-start">
                          <div>
                            <img src="/assets/images/api/twitter.png" />{" "}
                          </div>
                          <div>
                            <button
                              disabled={userData.twitter_authorised}
                              className={`connect__button ${
                                userData.twitter_authorised
                                  ? "connected"
                                  : "pending"
                              }`}
                              onClick={myTwitterLogin}
                            >
                              {userData.twitter_authorised ? (
                                "Connected"
                              ) : loading5 ? (
                                <>
                                  <Loader className="fs-3 loader-icon" />
                                </>
                              ) : (
                                "Connect"
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="api__box__body">
                          {/* <h4>Twitter</h4> */}
                          {userData?.instagram_authorised ? (
                            ""
                          ) : (
                            <h4 className="fm darkGrey fs-6">Remember to connect!</h4>
                          )}
                          {userData &&
                            userData?.twitter_authorised == false && (
                              <input
                                type="url"
                                className="form-control form-control-lg"
                                placeholder="Twitter"
                                onChange={(value) =>
                                  handleChangeSkills(
                                    "twitterURL",
                                    value.target.value
                                  )
                                }
                              />
                            )}
                          <p className="fm darkGrey text-14">
                            We use corporate social media presence to understand
                            organizational culture, purpose and talent
                            engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className={`api__boxes h-100 mb-0 ${
                          !userData?.linkedIn_authorised ? "not-authorised" : ""
                        }`}
                      >
                        <div className="api__box__header d-flex align-items-start">
                          <div>
                            <img src="/assets/images/api/linkedin.png" />{" "}
                          </div>
                          <div>
                            <button
                              disabled={userData.linkedIn_authorised}
                              className={`connect__button ${
                                userData.linkedIn_authorised
                                  ? "connected"
                                  : "pending"
                              }`}
                              onClick={myLinkedinLogin}
                            >
                              {userData.linkedIn_authorised ? (
                                "Connected"
                              ) : loadingLinkedin ? (
                                <>
                                  <Loader className="fs-3 loader-icon" />
                                </>
                              ) : (
                                "Connect"
                              )}{" "}
                            </button>
                          </div>
                        </div>
                        <div className="api__box__body">
                          {/* <h4>Linkedin</h4> */}
                          {userData?.linkedIn_authorised ? (
                            ""
                          ) : (
                            <h4 className="fm darkGrey fs-6">Remember to connect!</h4>
                          )}
                          {userData &&
                            userData?.linkedIn_authorised == false && (
                              <input
                                type="url"
                                value={companyLinkedin}
                                className="form-control form-control-lg"
                                placeholder="Company LinkedIn"
                                onChange={(value) =>
                                  handleChangeSkills(
                                    "companyLinkedin",
                                    value.target.value
                                  )
                                }
                              />
                            )}
                          <p className="fm darkGrey text-14">
                            We use corporate social media presence to understand
                            organizational culture, purpose and talent
                            engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Online para="Employers check your online activities as part of their hiring process; we assess your online activities to help you identify areas of improvement opportunities."
                    color="blue"
                    page="professional" /> */}
              </TabPanel>

              <TabPanel>
                <div className="PDetail__2 position-relative overflow-hidden mb-3">
                  <Progress
                    percent={progress}
                    steps={5}
                    showInfo={false}
                    className="w-100 position-absolute top-0 start-0"
                  />
                  <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                    <h6 className="fm darkGrey fw-semibold pt-2 fs-6 mb-0 nowrap">
                      Account completion{" "}
                    </h6>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                        {progress}% complete
                      </span>
                      {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                    </div>
                  </div>

                  <div className="PDetail__top--blue p-3">
                    <div className="w-100 d-flex flex-md-nowrap flex-wrap justify-content-between">
                      <div className=" PDetail__top--blue__left me-md-3">
                        <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                          Your Perspective{" "}
                        </h6>
                        <p className="fm darkGrey mb-md-0 text-14">
                          Help candidates learn about your unique culture and
                          the opportunity your organization provides to aspiring
                          leaders
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        <button
                          onClick={() => {
                            handleTabClick(1);
                          }}
                          className="PDetail__top--blue__skip fm  me-2"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => {
                            handleTabClick(3);
                          }}
                          className="PDetail__top--blue__goto fm"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-3 mb-4 p-3 PDetail__2">
                  <div className="d-flex justify-content-between align-items-start">
                    <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                      Your Perspective
                    </h6>
                    {isEditing ? (
                      <button
                        className="profile__update d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        onClick={handleUpdateClick}
                      >
                        {questionsLoading ? (
                          <Loader />
                        ) : (
                          <>
                            <CiEdit className="darkGrey me-1 fs-6" /> Update
                          </>
                        )}
                      </button>
                    ) : (
                      <button
                        className="profile__edit d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                        onClick={handleEditClick}
                      >
                        <CiEdit className="darkGrey me-1 fs-6" />
                        Edit
                      </button>
                    )}
                  </div>
                  <div className="yourProfile__questions__list pt-3">
                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                      <div className="me-2">1.</div>What’s your corporate
                      mission and how do you achieve it?
                    </p>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-3"
                        id="exampleInputEmail1"
                        aria-describedby="question1"
                        placeholder="Your message..."
                        rows={3}
                        readOnly={!isEditing}
                        value={textAreaValues.question1}
                        onChange={(e) => handleTextAreaChange(e, "question1")}
                      />
                    </div>
                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                      <div className="me-2">2.</div> What opportunities are
                      there for growth and how do you ensure diversity, equity
                      and inclusion?
                    </p>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-3"
                        id="exampleInputEmail1"
                        aria-describedby="question1"
                        placeholder="Your message..."
                        rows={3}
                        readOnly={!isEditing}
                        value={textAreaValues.question2}
                        onChange={(e) => handleTextAreaChange(e, "question2")}
                      />
                    </div>
                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                      <div className="me-2">3.</div> Who is the ideal candidate
                      for your organization?
                    </p>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-3"
                        id="exampleInputEmail1"
                        aria-describedby="question1"
                        placeholder="Your message..."
                        rows={3}
                        readOnly={!isEditing}
                        value={textAreaValues.question3}
                        onChange={(e) => handleTextAreaChange(e, "question3")}
                      />
                    </div>
                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                      <div className="me-2">4.</div> What does it take for an
                      employee to succeed in your organization?
                    </p>
                    <div className="form-group mb-3">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-md-0 mb-4"
                        id="exampleInputEmail1"
                        aria-describedby="question1"
                        placeholder="Your message..."
                        rows={3}
                        readOnly={!isEditing}
                        value={textAreaValues.question4}
                        onChange={(e) => handleTextAreaChange(e, "question4")}
                      />
                    </div>
                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                      <div className="me-2">5.</div> How do you define future of
                      work?
                    </p>
                    <div className="form-group">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-md-0 mb-4"
                        id="exampleInputEmail1"
                        aria-describedby="question1"
                        placeholder="Your message..."
                        rows={3}
                        readOnly={!isEditing}
                        value={textAreaValues.question5}
                        onChange={(e) => handleTextAreaChange(e, "question5")}
                      />
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="PDetail__2 position-relative overflow-hidden mb-3">
                  <Progress
                    percent={progress}
                    steps={5}
                    showInfo={false}
                    className="w-100 position-absolute top-0 start-0"
                  />
                  <div className="PDetail__top--white px-3 py-3 d-flex flex-sm-nowrap flex-wrap align-items-end">
                    <h6 className="fm darkGrey pt-2 fw-semibold fs-6 mb-0 nowrap">
                      Account completion{" "}
                    </h6>
                    <div className="d-flex justify-content-between w-100 flex-wrap">
                      <span className="text-14 midGrey fm fw-normal ms-sm-1 me-2">
                        {progress}% complete
                      </span>
                      {/* <span className="text-14 midGrey fm fw-normal ms-1">
                        2 steps left to complete
                      </span> */}
                    </div>
                  </div>

                  <div className="PDetail__top--blue p-3">
                    <div className="w-100 d-flex flex-md-nowrap flex-wrap justify-content-between">
                      <div className="PDetail__top--blue__left me-md-3">
                        <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                          Video introduction{" "}
                        </h6>
                        <p className="fm darkGrey mb-md-0 text-14">
                          Our candidates provide impact statement via video
                          presentation and we encourage organizations to provide
                          candidates with a short presentation on what to expect
                          if/when they join your organization.
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-end">
                        {/* <button className="PDetail__top--blue__skip fm  me-2">
                          Skip
                        </button> */}
                        <button
                          onClick={() => {
                            handleTabClick(2);
                          }}
                          className="PDetail__top--blue__goto fm"
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-3 p-3 PDetail__2">
                  <div className="d-flex justify-content-between align-items-start">
                    <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                      Video
                    </h6>
                    <button
                      onClick={EditVideoModal}
                      className="profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                    >
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                  </div>
                  <div className="yourProfile__videoUpload mb-0 rounded-4 mt-3">
                    <div className="yourProfile__videoUpload__frame h-100">
                      {userData.elevatorPitch && (
                        <iframe
                          frameborder="0"
                          className="w-100 h-100 rounded-4"
                          src={userData.elevatorPitch.file}
                          title="2"
                        ></iframe>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <Modal
                    className="certification-popup"
                    title="Change Video"
                    visible={videoVisible}
                    onOk={EditVideohandleOk}
                    onCancel={EditVideohandleCancel}
                    footer={null}
                    width={600}
                  >
                    <div className="add-cert-form edit-name-popup photo-popup">
                      <div className="row">
                        <div className="col-md-12">
                          {videoFile && (
                            <div className="video-preview">
                              <button
                                onClick={DeleteSelectedVideo}
                                className="delete-button profile__eidt d-flex align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14"
                              >
                                <AiOutlineDelete className="darkGrey me-1 fs-6" />
                                Delete
                              </button>
                              <video width="100%">
                                <source
                                  src={
                                    videoFile &&
                                    URL.createObjectURL(videoFile[0].blobFile)
                                  }
                                  type={videoFile.type}
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                          {!videoFile && (
                            <Uploader
                              draggable
                              multiple={false}
                              fileListVisible={false}
                              listType="picture-text"
                              accept="video/mp4,video/mov,video/wmv"
                              action="//jsonplaceholder.typicode.com/posts/"
                              onChange={
                                (value) => {                                  
                                  setVideoFile(value);
                                }
                                // handleChange("files", value)
                              }
                              renderFileInfo={(file, fileElement) => {
                                return (
                                  <div>
                                    <span>File Name: {file.name}</span>
                                    <p style={{ marginLeft: 13 }}>
                                      File type: {file.blobFile.type}
                                    </p>
                                  </div>
                                );
                              }}
                              className="pt-4"
                            >
                              <div>
                                {!videoFile && (
                                  <div className="upload-photo-box video-box">
                                    <p>Attach or drag Video here</p>
                                  </div>
                                )}
                              </div>
                            </Uploader>
                          )}
                        </div>
                        <div className="col-md-12 photo-popup-detail">
                          <p className="mt-3">Video Formats: mp4</p>
                          {/* <h4>Show clients the best version of yourself</h4> */}
                          <p>Must be an actual video of you</p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "16px" }}
                      className="cert-popup-buttons"
                    >
                      <Button
                        className="primary"
                        onClick={EditVideohandleCancel}
                        style={{ marginRight: "8px" }}
                      >
                        Cancel
                      </Button>
                      <button
                        className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                        onClick={handleVideoUpload}
                      >
                        {videoUrlLoading ? <Loader /> : "Upload Video"}
                      </button>
                    </div>
                  </Modal>
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <div>
            {/* <div className="PDetail__2 p-3 mb-3">
                <div className="d-flex flex-sm-row flex-column-reverse justify-content-between align-items-start">
                  <div className="d-flex align-items-center">
                    <div className="profile__avatar PDetail__2__avatar border d-flex justify-content-center align-items-center rounded-circle">
                      <img
                        className="PDetail__2__avatar--img"
                        src={"/assets/images/userAvatar.svg"}
                      />
                    </div>
                    <div className="ms-2">
                      <h6 className="fm darkBlue text-20 mb-1">
                        Benjamin Smith{" "}
                        <span className="midGrey fw-normal text-14">(He/Him)</span>
                      </h6>
                      <p className="fm darkGrey mb-0">Graphic Designer</p>
                      <p className="fm midGrey my-0">London. United Kingdom</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end PDetail__2__right">
                    <button className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              <div className="PDetail__3 p-3 mb-3">
                <div className="border-05 pb-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                      Personal Information
                    </h6>
                    <button className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                  </div>
                  <div className="PDetail__3__row mt-2 d-flex justify-content-md-start justify-content-between">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Title</p>
                      <p className="text-14 fm darkGrey my-0">Mr</p>
                    </div>
                    <div className="PDetail__3__row__col">
                      <p className="text-14 fm darkGrey mb-0 fw-light">First name</p>
                      <p className="text-14 fm darkGrey my-0">Benjamin</p>
                    </div>
                    <div className="PDetail__3__row__col">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Second name</p>
                      <p className="text-14 fm darkGrey my-0">Smith</p>
                    </div>
                  </div>
                  <div className="PDetail__3__row mt-4 d-flex flex-md-row flex-column-reverse">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Email</p>
                      <p className="text-14 fm darkGrey my-0">ben@indexscore.com</p>
                    </div>
                    <div className="PDetail__3__row__col my-md-0 my-3">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Phone</p>
                      <p className="text-14 fm darkGrey my-0">+44 712345 6789</p>
                    </div>
                    <div className="PDetail__3__row__col">
                      <p className="text-14 fm darkGrey mb-0 fw-light">DOB</p>
                      <p className="text-14 fm darkGrey my-0">28/02/1997</p>
                    </div>
                  </div>
                  <div className="PDetail__3__row mt-4 d-flex flex-md-row flex-column">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Preferred pronoun</p>
                      <p className="text-14 fm darkGrey my-0">He/Him</p>
                    </div>
                    <div className="PDetail__3__row__col my-md-0 my-3">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Bio/Title</p>
                      <p className="text-14 fm darkGrey my-0">Graphic Designer</p>
                    </div>
                  </div>
                </div>

                <div className="py-4">
                  <img
                    src="/assets/images/Dashboard/Persona.png"
                    alt=""
                    className="PDetail__3__row--img"
                  />
                  <div className="PDetail__3__row mt-2 d-flex flex-md-row flex-column">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Identity verification</p>
                    </div>
                    <div className="PDetail__3__row__col">
                      <p className="text-14 fm darkGrey mb-0 fw-light">
                        Government Identification
                      </p>
                      <div className="reference__verification--main nowrap mb-3">
                        <span>Verified</span> <BsCheckCircle />
                      </div>
                    </div>
                    <div className="PDetail__3__row__col">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Right to work</p>
                      <div className="reference__verification--main nowrap mb-3">
                        <span>Verified</span> <BsCheckCircle />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="PDetail__3 p-3 mb-3">
                <div className="pb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="fm text-20 mb-0 darkBlue fw-semibold">Address</h6>
                    <button className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Edit
                    </button>
                  </div>
                  <div className="PDetail__3__row mt-2 d-flex flex-md-row flex-column">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Country</p>
                      <p className="text-14 fm darkGrey my-0">United Kingdom</p>
                    </div>
                    <div className="PDetail__3__row__col my-md-0 my-3">
                      <p className="text-14 fm darkGrey mb-0 fw-light">State/City</p>
                      <p className="text-14 fm darkGrey my-0">London</p>
                    </div>
                  </div>
                  <div className="PDetail__3__row mt-4 d-flex flex-md-row flex-column">
                    <div className="PDetail__3__row__col PDetail__3__row__col1">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Address</p>
                      <p className="text-14 fm darkGrey my-0">24 London Bridge</p>
                    </div>
                    <div className="PDetail__3__row__col my-md-0 my-3">
                      <p className="text-14 fm darkGrey mb-0 fw-light">Postcode/Zip</p>
                      <p className="text-14 fm darkGrey my-0">CO2 6XZ</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="PDetail__3 p-3 mb-5">
                <div className="">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                      Public facing profile
                    </h6>
                    <button className="profile__eidt d-flex mb-2 align-items-center rounded-pill py-1 px-2 justify-content-center fm darkGrey text-14">
                      <CiEdit className="darkGrey me-1 fs-6" />
                      Go to
                    </button>
                  </div>
                  <p className="fm darkGrey mt-2 PDetail__3__row mb-0">
                    Your public profile is located on the ‘My Profile” tab, this will
                    have all employment history, open questions, video and you
                    social/achievement information on either click the go to button here
                    or use the tab on the tool bar.
                  </p>
                </div>
              </div> */}
          </div>
          <div className="py-md-4 py-2"></div>
          {/* <div className="PDetail__3 p-3 d-flex flex-md-row flex-column justify-content-between align-items-md-center">
              <div className="me-2">
                <h6 className="fm text-20 mb-0 darkGrey fw-semibold">
                  Delete your account
                </h6>
                <p className="fm darkGrey mt-2 PDetail__3__row mb-0">
                  It is possible to delete your account but once deleted there is no
                  way to recover any information linked to the account. Please be sure
                  you want to delete your account before confirming.
                </p>
              </div>
              <button className="PDetail__3__delete fm text-white transition mt-md-0 mt-3">Delete account</button>
            </div> */}
        </div>
        <Modal
          className="certification-popup"
          title="Company Biography"
          visible={editBioVisible}
          onOk={EditBiohandleOk}
          onCancel={EditBiohandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-12">
                <label>
                  Company Biography
                  <textarea
                    type="text"
                    className="form-control-lg form-control mb-3"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Company description"
                    rows={3}
                    value={updateCompanyDescription}
                    onChange={(e) =>
                      setUpdateCompanyDescription(e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditBiohandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateBioHandler}
            >
              {updateBioLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>
        <Modal
          className="certification-popup"
          title="Company HQ Address"
          visible={editAboutYouVisible}
          onOk={EditAboutYouhandleOk}
          onCancel={EditAboutYouhandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Country
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                    size="small"
                    value={companyCountry}
                    placeholder="Country"
                    onChange={(value) =>
                      handleChangeSkills("companyCountry", value)
                    }
                  >
                    <OptGroup>
                      {Country.map((item) => (
                        <Option
                          value={item.code}
                          label={item.name.toLowerCase()}
                          key={item.code}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  State/City
                  <input
                    type="text"
                    className="form-control form-control-md  w-100 Dashboard__input mb-2"
                    placeholder="Enter state/city"
                    id="sector1"
                    value={companyState}
                    onChange={(value) =>
                      handleChangeSkills("companyState", value.target.value)
                    }
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Postal/Zip Code
                  <input
                    type="text"
                    className="form-control form-control-md  w-100 Dashboard__input mb-2"
                    placeholder="Enter postal/zip code"
                    id="sector1"
                    value={companyZip}
                    onChange={(value) =>
                      handleChangeSkills("companyZip", value.target.value)
                    }
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Address
                  <input
                    type="text"
                    className="form-control form-control-lg mt-1"
                    placeholder="Corporate contact address"
                    value={companyContactAddress}
                    onChange={(value) =>
                      handleChangeSkills(
                        "companyContactAddress",
                        value.target.value
                      )
                    }
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Federal Tax ID
                  <input
                    type="text"
                    className="form-control form-control-lg mt-1"
                    placeholder="Federal Tax ID / VAT"
                    value={companyfederalTaxID}
                    onChange={(value) =>
                      handleChangeSkills(
                        "companyfederalTaxID",
                        value.target.value
                      )
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditAboutYouhandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateProAboutYouHandler}
            >
              {updateAboutYouLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>
        <Modal
          className="certification-popup"
          title="Company Information"
          visible={editAreaFocusVisible}
          onOk={EditAreaFocushandleOk}
          onCancel={EditAreaFocushandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-6">
                <label>
                  Company name
                  <input
                    type="text"
                    className="form-control form-control-lg mt-1"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(value) => {
                      handleChangeSkills("companyName", value.target.value);
                    }
                  }
                  />
                  {/* <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.name ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.name ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.name ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2 mt-1 select-signup mb-2 mx-0 d-block w-100"
                    size="small"
                    value={companyName}
                    placeholder="Company Name"
                    onChange={(value) => {
                      handleChangeSkills("companyName", value);
                    }}
                  >
                    <OptGroup>
                      {companies.map((item) => (
                        <Option
                          value={item.name}
                          label={item.name.toLowerCase()}
                          key={item.name}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select> */}
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Phone number
                  <PhoneInput
                    defaultCountry={"us"}
                    className=""
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(value) =>
                      handleChangeSkills("phoneNumber", value)
                    }
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  Email address
                  <input
                    type="email"
                    className="form-control form-control-lg mt-1"
                    title="Email (secondary email for corporate inquiries)"
                    placeholder="Email (secondary email for corporate inquiries)"
                    value={emailAddress}
                    onChange={(value) =>
                      handleChangeSkills("emailAddress", value.target.value)
                    }
                  />
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Website
                  <input
                    type="text"
                    className="form-control form-control-lg mt-1"
                    placeholder="Company career url"
                    value={updateCompanyUrl}
                    onChange={(value) =>
                      // handleChangeSkills("companyCareerUrl", value.target.value)
                      setUpdateCompanyUrl(value.target.value)
                    }
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  Sector
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                    size="small"
                    placeholder="Select sector"
                    value={updateSector}
                    onChange={(value) => {
                      handleChange1("sector", value);
                    }}
                  >
                    {options.map((option, index) => (
                      <Option key={index} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Industry
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2  select-signup mb-2 mx-0 d-block w-100 "
                    size="small"
                    placeholder="Select industry"
                    value={updateIndustry}
                    onChange={(value) => {
                      setuUpdateIndustry(value);
                    }}
                  >
                    {selectedOptions?.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </label>
              </div>
              <div className="col-md-12">
                <label>
                  Company glassdoor url
                  <input
                    type="text"
                    className="form-control form-control-lg mt-1"
                    placeholder="Corporate contact address"
                    value={companyGlassdoorUrl}
                    onChange={(value) =>
                      handleChangeSkills(
                        "companyGlassdoorUrl",
                        value.target.value
                      )
                    }
                  />
                </label>
              </div>

              <div className="col-md-6">
                <label>
                  Size (People)
                  <Select
                    className="Login--Input mt-1 mx-0 d-block Login--select"
                    size="large"
                    placeholder="Number of total employees"
                    value={updateNoOfEmployees}
                    onChange={(value) => setUpdateNoOfEmployees(value)}
                  >
                    <Option value="1-10 employees">1-10 employees</Option>
                    <Option value="11-50 employees">11-50 employees</Option>
                    <Option value="51-200 employees">51-200 employees</Option>
                    <Option value="201-500 employees">201-500 employees</Option>
                    <Option value="501-1000 employees">
                      501-1000 employees
                    </Option>
                    <Option value="1001-5000 employees">
                      1001-5000 employees
                    </Option>
                    <Option value="5001-10,000 employees">
                      5001-10,000 employees
                    </Option>
                    <Option value="10,001+ employees">10,001+ employees</Option>
                  </Select>
                </label>
              </div>
              <div className="col-md-6">
                <label>
                  Size (Turnover)
                  <Select
                    className="Login--Input mt-1 mx-0 d-block Login--select"
                    size="large"
                    placeholder="Expected yearly salary"
                    value={updateYearlyRevenue}
                    onChange={(value) => setUpdateYearlyRevenue(value)}
                  >
                    <Option value="1000 - 5000"> 1000 - 5000</Option>
                    <Option value="5000 - 10000"> 5000 - 10000</Option>
                    <Option value="10000 - 20000"> 10000 - 20000</Option>
                    <Option value="20,000 - 50,000"> 20,000 - 50,000</Option>
                    <Option value="50,000 - 80,000"> 50,000 - 80,000</Option>
                    <Option value="80,000 - 120,000"> 80,000 - 120,000</Option>
                    <Option value="120,000 - 150,000">
                      {" "}
                      120,000 - 150,000
                    </Option>
                    <Option value="150,000 -200,000"> 150,000 -200,000</Option>
                    <Option value="200,000 - 350,000">
                      {" "}
                      200,000 - 350,000
                    </Option>
                    <Option value="350,000$ +"> 350,000$ +</Option>
                  </Select>
                </label>
              </div>
            </div>
          </div>
          {areaFocusError && (
            <div className="error-message">{areaFocusError}</div>
          )}
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditAreaFocushandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateProAreaFocusHandler}
            >
              {updateAreaFocusLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>
        <Modal
          className="certification-popup"
          title="Company Social Channels"
          visible={editSocialsVisible}
          onOk={EditSocialshandleOk}
          onCancel={EditSocialshandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-1">
                <FaLinkedin className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Linkedin url"
                  value={updateLinkedin}
                  onChange={(value) => {
                    setUpdateLinkedin(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaInstagram className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Instagram url"
                  value={updateInstaLink}
                  onChange={(value) => {
                    setUpdateInstaLink(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaSquareXTwitter className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Twitter url"
                  value={updateTwitterLink}
                  onChange={(value) => {
                    setUpdateTwitterLink(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaFacebook className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Facebook url"
                  value={updatefbLink}
                  onChange={(value) => {
                    setUpdatefbLink(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaGithub className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Github url"
                  value={updateGithubLink}
                  onChange={(value) => {
                    setUpdateGithubLink(value.target.value);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1">
                <FaLink className="fs-1 me-1 mt-2" />
              </div>
              <div className="col-md-11">
                <input
                  type="url"
                  className="form-control form-control-lg mt-1"
                  placeholder="Company Career Url"
                  value={companyCareerUrl}
                  onChange={(value) =>
                    handleChangeSkills("companyCareerUrl", value.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditSocialshandleCancel}
              style={{ marginRight: "8px" }}
            >
              Cancel
            </Button>
            <button
              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
              onClick={updateSocialsHandler}
            >
              {updateSocialsLoader ? <Loader /> : "Update"}
            </button>
          </div>
        </Modal>
        <Modal
          className="certification-popup"
          title="Company Values & Benefits"
          visible={editValuesVisible}
          onOk={EditValueshandleOk}
          onCancel={EditValueshandleCancel}
          footer={null}
          width={600}
        >
          <div className="add-cert-form personal__information edit-name-popup">
            <div className="row">
              <div className="col-md-6">
                <p>Enter Values</p>
                <form onSubmit={handleSubmitValues}>
                  <div className="d-flex">
                    <input
                      type="text"
                      value={inputValues}
                      className="add__vb__points__inputs my-0"
                      onChange={(e) => setInputValues(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="add__vb__points bluegradient__btn text-white"
                    >
                      {/* <BsPlusCircle /> */}
                      Add
                    </button>
                  </div>
                </form>
                <ul className="list-unstyled mb-0">
                  {valueItems.map((item, index) => (
                    <li key={index} className="text-14 fm darkGrey">
                      -{item}
                      <button
                        className="remove__values__benefits"
                        onClick={() => handleRemoveValues(index)}
                      >
                        <TiDelete />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <p>Enter Benefits</p>
                <form onSubmit={handleSubmitBenefits}>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="add__vb__points__inputs my-0"
                      value={inputBenefits}
                      onChange={(e) => setInputBenefits(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="add__vb__points bluegradient__btn text-white"
                    >
                      {/* <BsPlusCircle /> */}
                      Add
                    </button>
                  </div>
                </form>
                <ul className="list-unstyled mb-0">
                  {benefitsItems.map((item, index) => (
                    <li key={index} className="text-14 fm darkGrey">
                      -{item}
                      <button
                        className="remove__values__benefits"
                        onClick={() => handleRemoveBenefits(index)}
                      >
                        <TiDelete />
                      </button>
                    </li>
                  ))}
                  {/* {Object.entries(benefitsItems).map(([key, value], index) => (
                    <li key={index} className="text-14 fm darkGrey">
                      {key}: {value} <button
                                          className="remove__values__benefits"
                                          onClick={() => handleRemoveBenefits(index)}
                                        >
                                          <TiDelete />
                                        </button>
                    </li>
                  ))} */}
                </ul>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "16px" }} className="cert-popup-buttons">
            <Button
              className="primary"
              onClick={EditValueshandleCancel}
              style={{ marginRight: "8px" }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default YDetail;
