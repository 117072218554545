import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import { AiOutlineUser, AiOutlineSetting } from "react-icons/ai";
// import { BsChevronDown } from "react-icons/bs";
// import { TbPencil } from "react-icons/tb";
// import { BiHelpCircle } from "react-icons/bi";
import "./style.css";

const NavbarDemo = (props) => {
  const [scroll, setScroll] = useState(false);
  const [nav, setNav] = useState(false);
  const [dnav, setDnav] = useState(false);
  const [color, setColor] = useState(props.color);
  const [logo, setLogo] = useState(props.logo);

  function changeNav() {
    setNav(!nav);
  }

  function changeBackground(scroll) {
    if (window.scrollY >= 35) {
      if (dnav || nav) {
        setScroll(true);
      } else {
        setScroll(false);
      }
      // setColor("white");
      // setLogo("Main");
    } else {
      setScroll(false);
      if (nav || dnav) {
        setColor("white");
        setLogo("Main");
      } else if (!nav || !dnav) {
        setColor(props.color);
        setLogo(props.logo);
      }
    }
  }
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  useEffect(() => {
    const offcanvasElement = document.getElementById("offcanvasRight");
    const checkOffcanvasStatus = () => {
      const isOffcanvasShown = offcanvasElement.classList.contains("show");
      setDnav(isOffcanvasShown);
      const checkbox = document.getElementById("checkbox1");
      checkbox.checked = isOffcanvasShown;
    };
    offcanvasElement.addEventListener(
      "hidden.bs.offcanvas",
      checkOffcanvasStatus
    );
    offcanvasElement.addEventListener(
      "shown.bs.offcanvas",
      checkOffcanvasStatus
    );
    checkOffcanvasStatus();
  }, []);
  useEffect(() => {
    const offcanvasElement = document.getElementById("offcanvasLeft");
    const checkOffcanvasStatus = () => {
      const isOffcanvasShown = offcanvasElement.classList.contains("show");
      setNav(isOffcanvasShown);
      const checkbox = document.getElementById("checkbox");
      checkbox.checked = isOffcanvasShown;
    };
    offcanvasElement.addEventListener(
      "hidden.bs.offcanvas",
      checkOffcanvasStatus
    );
    offcanvasElement.addEventListener(
      "shown.bs.offcanvas",
      checkOffcanvasStatus
    );
    checkOffcanvasStatus();
  }, []);

  return (
    <>
      <div
        className="Navbar d-lg-none main-padding"
        style={{
          boxShadow:
            scroll && !nav
              ? "rgb(56 56 57 / 30%) 0px 15px 43px"
              : "0px 0px 0px #33333d",
          backgroundColor: nav ? "var(--darkBlue)" : "rgb(0,0,0,0)",
          borderBottom: nav ? "1px solid #494F4F" : "0px",
          paddingTop: nav ? "0px" : "10px",
          position: dnav || nav ? "fixed" : "fixed",
        }}
      >
        <div className={`container-xl h-100 rounded-pill position-relative ${nav? "pe-0":"pe-3"}`} style={{backgroundColor: nav ? "var(--darkBlue)" : "var(--darkBlue)", padding: nav ? "0px 0px": "0px 8px"}}>
          <div className="Navbar__container d-flex justify-content-between align-items-center h-100">
            <Link to={"/"} className="w-100">
              <div className="Navbar__container__logo h-100 d-flex align-items-center">
                <img
                  src={`/assets/images/navbar/logoWhite.svg`}
                  alt=""
                  className="Navbar__container__logo--img"
                />
              </div>
            </Link>
            <div
              className="Border h-100 me-2"
              style={{
                borderRight: nav ? "1px solid #494F4F" : "0px",
              }}
            ></div>
            <div className="Navbar__container__menu  d-lg-flex align-items-center d-none">
              <Link
                to={"/book-a-demo"}
                className={`text-decoration-none  navbar-demo-text ${
                  color === "blue" && !dnav ? "demo-text-blue" : ""
                } transition d-flex align-items-center`}
                style={{
                  color: color === "blue" && !dnav ? "var(--darkBlue) " : "",
                }}
              >
                Book a Demo
              </Link>
              {/* <span className={`nav-user-icon transition ${color === "blue" ? "blue__user" : ""}`}>
                <AiOutlineUser
                  className={`user-account-icon transition ${
                    color === "blue" ? "darkBlue-color" : ""
                  }`}
                />
                <small
                  className={`transition ${
                    color === "blue" ? "darkBlue-color" : ""
                  } fm transition`}
                >
                  Account
                </small>
                <BsChevronDown
                  className={`user-arrow-icon transition mt-1 ${
                    color === "blue" ? "darkBlue-color" : ""
                  }`}
                />

                <div className="nav__user__dropdown">
                  <div className="nav__user__dropdown__box">
                    <div className="nav__user__dropdown__box__top">
                      <Link
                        className="nav__user__dropdown__login"
                        to={"/log-in"}
                      >
                        Login
                      </Link>
                      <Link className="hover--main" to={"/"}>
                        <AiOutlineSetting /> <span>Account Settings</span>{" "}
                      </Link>
                      <Link className="hover--main" to={"/professional/signup"}>
                        <TbPencil /> <span>Sign Up</span>{" "}
                      </Link>
                    </div>
                    <div className="nav__user__dropdown__box__bottom">
                      <Link to={"/"} className="hover--main">
                        <BiHelpCircle /> <span>Support</span>{" "}
                      </Link>
                    </div>
                  </div>
                </div>
              </span> */}

              <div className="ms-5  d-block">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox1"
                  className="check"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => setDnav(!dnav)}
                />
                <div className="ham-menu" id="ham-menu">
                  <span className={`line line1 line--white`}></span>
                  <span className={`line line2 line--white`}></span>
                  <span className={`line line3 line--white`}></span>
                </div>
              </div>
            </div>
            <div className="Navbar__container__menu d-lg-none d-flex align-items-center">
              <div className="ms-5  d-block">
                <input
                  type="checkbox"
                  name=""
                  id="checkbox"
                  className="check"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasLeft"
                  aria-controls="offcanvasLeft"
                  onClick={changeNav}
                />
                <div className="ham-menu" id="ham-menu">
                  <span className={`line line1 line--white`}></span>
                  <span className={`line line2 line--white`}></span>
                  <span className={`line line3 line--white`}></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarDemo;
