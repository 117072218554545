import React from "react";
import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const holding4 = [
  {
    id: 1,
    heading: "Everything in one number",
    para: "All candidates are pre-scored based on IndexScore’s AI using all the information you have provided us – this is all then checked and verified. We then combine results from all these elements to create one score out of 850 that encapsulates everything about you as a candidate, your IndexScore.",
  },
  {
    id: 2,
    heading: "Peace of mind",
    para: "We sort everything for you and verify identity and right to work all in once place so you don’t need to go though the hassle of confirming multiple identity checks and visa for each application.",
  },
  {
    id: 3,
    heading: "Diversity Equality & Inclusion",
    para: "DEI has never been more important and unconscious bias can easily affect the quality and diversity. Our standardize scoring helps to remove this from the initial stages of the hiring process to ensuring everyone is scored on a fair and even basis to allow a true comparison of all users.",
  },
];

const Hsection4 = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 400,
            settings: {
              arrows: false,
              slidesToShow: 1,
            },
          },
        ],
      };
  return (
    <div className="holding4">
         <div className="holding4__slider pt-3 pb-2">
          <div className="main-padding">
            <div className="container-lg">
                <p className="text-uppercase fm text-white-50 mb-lg-0 text-14">Companies we work with:</p>
            <Slider {...settings}>
            <div className="d-flex justify-content-center  align-items-center mt-lg-4 mt-3">
              <img
                src="/assets/images/Launch/company1.svg"
                alt=""
                className="es1__companyimg1 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center  align-items-center mt-lg-3 mt-2">
              <img
                src="/assets/images/Launch/company2.svg"
                alt=""
                className="es1__companyimg2 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="/assets/images/Launch/company3.svg"
                alt=""
                className="es1__companyimg3 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src="/assets/images/Launch/company4.svg"
                alt=""
                className="es1__companyimg4 me-3 mb-2"
              />
            </div>
            <div className="d-flex justify-content-center align-items-center mt-lg-4 mt-3">
              <img
                src="/assets/images/Launch/company5.png"
                alt=""
                className="es1__companyimg5 mb-2"
              />
            </div>
          </Slider>
            </div>
          </div>
          </div>
      <div className="main-padding">
        <div className="container-lg px-0">
          <div className="row">
            {holding4.map((item)=>(
                <div className="col-lg-4 col-sm-6 pb-4" key={item.id}>
                <img
                  src={`/assets/images/Launch/holding${item.id}.png`}
                  alt=""
                  className="w-100"
                />
                <h1 className="fm main fs-5 pt-3">{item.heading}</h1>
                <p className="fm text-white fw-light">
                  {item.para}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hsection4;
