import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProThankYou from "../pageComponents/Professionals/ProThankYou/ProThankYou.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const ProfessionalsThankYou = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="professional-signup" />
      <MobileSidebar />
      <ProThankYou
        title="Candidate"
        para1="Get your IndexScore, understand your employability, and accelerate your new role."
        para2="You’ll then be able to add in more data, more details or anything else you think is relevant to customize, and update your score. You can log in to your account at any time to update things, add new skills and experiences and find out new ways to boost your score."
        color="blue"
        page="professional"
      />
      <Footer />
    </>
  );
};

export default ProfessionalsThankYou;
