import React from "react";
import "./style.css";

const card=[
  {
    id: 1,
    title: "Flexible pricing",
    para: "Select the pricing the suits you best select from any of our plans or contact us for a bespoke package to fit your enterprises needs."
  },
  {
    id: 2,
    title: "Immediately find candidates that match",
    para: "Select the pricing the suits you best select from any of our plans or contact us for a bespoke package to fit your enterprises needs."
  },
  {
    id: 3,
    title: "Takes weeks off the process",
    para: "IndexScore hiring process is simple. We cut down hiring time by up to 75%."
  },
]

const WhyBanner = () => {
  return (
    <div className="Whybanner main-padding">
      <div className="container-lg px-0 h-100 d-flex flex-column justify-content-center align-items-center">
        <div className="text-center d-flex flex-column align-items-center">
          <h1 className="hero__overlay__content__heading text-white">
            Empowering everyone with a{" "}
            <span className="main">
              better <br /> recruitment process experience.
            </span>
          </h1>
          <p className="hero__overlay__content__para w-100 why__para pb-xxl-5 pb-3">
            Resumes became popular in the 1930s and have changed little in
            almost 100 years. They’re great at documenting experience, not so
            good at explaining skills, and are poor at illustrating personality.
            Plus they leverage nothing of our digital world today.
          </p>
        </div>
        <div className="Why__bottom">
          <img
            src="./assets/images/WhyIndexcore/WhyIndexcore_main.jpeg"
            alt=""
            className="img-fluid Why__bottom--img"
          />
          <div className="row d-md-flex d-none mt-4">
            {card.map((item)=>(
              <div className="col-lg-4 col-sm-6 pt-5 pe-4">
              <div className="Whysection1__top d-flex justify-content-center align-items-center p-2">
                <img
                  src={`./assets/images/WhyIndexcore/WhySection1Icon${item.id}.png`}
                  alt=""
                  className="Whysection1__top--img img-fluid"
                />
              </div>
              <p className="fm text-18 fw-semibold darkBlue mt-2 mb-2">{item.title}</p>
              <p className="fm text-18 darkGrey">
               {item.para}
              </p>
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyBanner;
