import { createContext, useState, useEffect,useReducer,useContext  } from 'react';

export const MyContext = createContext();

const initialState = {
  user: null,
  usertype: null, // Add usertype
  userRole: null,
  isAuthenticated: false,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
        usertype: action.payload.usertype, // Update usertype
        userRole: action.payload.usertype === 'enterprise' || action.payload.usertype === 'member' ? action.payload.userRole : null,
        isAuthenticated: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        usertype: null, // Clear usertype
        userRole:null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};


// Action types
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';



function MyContextProvider(props) {

    const [state, dispatch] = useReducer(authReducer, initialState);

  const [contextData, setContextData] = useState(() => {
    const savedData = localStorage.getItem('contextData');
    return savedData ? JSON.parse(savedData) : {};
  });

  const [user, setUser] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });



  useEffect(() => {
    localStorage.setItem('contextData', JSON.stringify(contextData));
  }, [contextData]);


  useEffect(() => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      setUser(JSON.parse(savedUserData));
    }
  }, []);

  useEffect(() => {
    const savedUserData = localStorage.getItem('userData');
    const savedUserType = localStorage.getItem('usertype');
    const savedUserRole = localStorage.getItem('userRole');
    if (savedUserData && savedUserType) {
      const user = JSON.parse(savedUserData);
      dispatch({ type: 'LOGIN', payload: { user, usertype: savedUserType,userRole:savedUserRole } });
    }
  }, []);  

  const updateContextData = (newData) => {
    setContextData((prevContextData) => ({
      ...prevContextData,
      ...newData,
    }));
  };

  const clearContextData = () => {
    setContextData({});
    localStorage.removeItem('contextData');
  };

  const getContextValue = (key) => {
    if (contextData.hasOwnProperty(key)) {
      return contextData[key];
    }
    return null;
  };


  const loginAction = (user, usertype,userRole) => {
    // Save the user data and usertype to local storage
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('usertype', usertype);
    localStorage.setItem('userRole', usertype === 'enterprise' || usertype === 'member' ? userRole : null); // Save userRole for enterprise only
    // Dispatch the LOGIN action
    dispatch({ type: 'LOGIN', payload: { user, usertype,userRole } });
  };

  const logoutAction = () => {
    // Remove the user data and usertype from local storage
    localStorage.removeItem('userData');
    localStorage.removeItem('usertype');
    localStorage.removeItem('userRole');
    // Dispatch the LOGOUT action
    dispatch({ type: 'LOGOUT' });
  };
  

  const setUserData = (userData) => {
    // Save the user data to localStorage
    localStorage.setItem('userData', JSON.stringify(userData));

    // Update the user state
    setUser(userData);
  };

  // Function to clear user data
  const clearUserData = () => {
    // Remove the user data from localStorage
    localStorage.removeItem('userData');
    localStorage.removeItem('usertype');
    // Clear the user state
    setUser(null);
    dispatch({ type: 'LOGOUT' });
  };


  // Function to get user data
  const getUserData = () => {
    return user;
  };

  return (
    <MyContext.Provider value={{
      state, dispatch,
      loginAction,
      logoutAction,
      contextData,
      updateContextData,
      clearContextData,
      getContextValue,
      user,
      setUserData,
      clearUserData,
      getUserData
    }}>
      {props.children}
    </MyContext.Provider>
  );
}

 
const useAuth = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export {MyContextProvider,useAuth };