import React from "react";
import { Link } from "react-router-dom";

const UCSection2 = () => {
  return (
    <div className="ues3 main-padding mt-4 py-4">
      <div className="container-xl px-0 py-5">
        <div className="text-center">
          <p className="fm darkGrey text-14 text-center  rounded-pill uhs3__title mb-2">
            The process
          </p>
          <h1 className="fs-1 darkBlue text-center text-center fw-semibold mb-0">
            How we help you get your perfect job
          </h1>
        </div>

        <div className="row pt-5">
          <div className="col-md-6 pe-md-0 mb-3">
            <div className="ues3__card1 border bg-white ps-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm darkBlue fs-4">Sign up for free</h6>
                <p className="fm darkBlue text-14 ">
                  No membership or subscription costs — simply sign up for free
                  to access your personal dashboard within minutes
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Candidate/processCard1.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="ucs3__card2 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4 ps-4">
                <h6 className="fm darkGrey fs-4">One simple score</h6>
                <p className="fm darkGrey text-14">
                  Complete your indexScore profile to get your single score that
                  sums up your skills, working style, personality, and so much
                  more.
                </p>
              </div>

              <div className="d-flex align-items-center justify-content-center h-100">
                <h1 className="fm ucs3__card2__h1 fw-light">684</h1>
              </div>
            </div>
          </div>

          <div className="col-md-8 pe-md-0 mb-3">
            <div className="ues3__card3 ps-4 ucs3__card3 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="pe-4">
                <h6 className="fm darkGrey fs-4">Improve where needed</h6>
                <p className="fm darkGrey text-14">
                  Like Credit Scores, see our recommendations for improvement to
                  know how to increase your score.
                </p>
              </div>

              <img
                src="/assets/UpdatedFrontend/Candidate/processcard3.png"
                alt=""
              />
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="ues3__card4 bg-darkBlue pe-4 h-100 pt-4 rounded-3 d-flex flex-column justify-content-between">
              <div className="ps-4">
                <h6 className="fm text-white fs-4">One click</h6>
                <p className="fm text-white text-14">
                  Apply to any job with one click — no custom cover letters,
                  adapted resumes or taking the same tests time and time again
                </p>
              </div>

              <div>
                <img
                  src="/assets/UpdatedFrontend/Candidate/processCard4.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UCSection2;
