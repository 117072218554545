import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import { Tabs } from "antd";
import { TbCurrencyDollar } from "react-icons/tb";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { CiClock2 } from "react-icons/ci";

import { Link, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import { getJobs, getEnterpriseByID } from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";

const tags = ["full-time", "remote", "UI/UX designer"];
const tech = ["javascript", "c#", "c++", "java", "html"];

const EditProfile = (props) => {
  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobsToShow, setJobsToShow] = useState(2); // Initial number of jobs to show
  const [activeTab, setActiveTab] = useState('tab 1');
  const handleSeeAllJobs = () => {
    setJobsToShow(jobsToShow + 4); // Increase the number of jobs to show by 2
  };

  const handleShowAllDetails = () => {
    setActiveTab('tab 2'); // Switch to the 2nd tab
  };

  const handleSeeAllJobsTab = () => {
    setActiveTab('tab 3'); // Switch to the 3rd tab
  };

  useEffect(() => {
    updatedEnterpriseData();
  }, []);
  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const updatedEnterpriseData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respProEnterpriseByID = await getEnterpriseByID(payLoadtoGetPro);
    const responseEnterpriseData = respProEnterpriseByID.data.data;
    // fetchJobs(responseEnterpriseData[0].companyID);
    // setUserData(responseEnterpriseData[0]);
  };

  useEffect(() => {
    updatedEnterpriseData();
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    // handleChange("loading2", true);
    setLoading(true);

    try {
      const response = await getJobs();
      var myObjectEnterprise;
      const responseData = response.data.data.filter(
        (obj) => obj.enterpriseID === userData.companyID
      );

      myObjectEnterprise = responseData.slice().reverse();
      console.log("myObjectEnterprise", myObjectEnterprise);

      setFilterData(
        myObjectEnterprise.map((jobs) => ({
          key: jobs._id,
          candidates: jobs.candidates.length,
          small_text: "Accenture",
          heading: jobs.company.toUpperCase(),
          para: jobs.job_title.toUpperCase(),
          companylogo: "/assets/images/Dashboard/logo-5.png",
          department: jobs.department,
          enterpriseLogo: jobs.enterpriseData
            ? jobs.enterpriseData.companyImage.docs
            : "",
          tags: jobs.job_sectors,
          start_date: jobs.start_date,
          end_date: jobs.end_date,
          min_salary: jobs.min_salary,
          max_salary: jobs.max_salary,
          team_members: jobs.team_members,
          employment_type: jobs.employment_type,
          indexScore: jobs.min_index_core,
          culture_fit: jobs.min_culture_fit_score,
          job_role_text: jobs.job_role_text,
          status: jobs.status,          
          source: "IndexScore",
        }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const bannerImg = "";
  const profileImg = "";
  const TabPane = Tabs.TabPane;
  return (
    <div className="eprofile  profile">
      <div className="">
        <div className="eprofile__container overflow-hidden mx-auto">
          <div className={`${props.radius === "no" ? "border-TB border-top-0" : "rounded-border"} overflow-hidden eprofile__top`}>
            <div
              className="eprofile__banner"
              style={{
                background: bannerImg ? `url(${bannerImg})` : "#E6E6E6",
              }}
            ></div>
            <div className="px-4 pb-3">
              <div
                className={`eprofile__dp rounded-circle overflow-hidden`}
                style={{
                  background: userData.companyImage ? "#fff" : "#E6E6E6",
                }}
              >
                {userData.companyImage ? (
                  userData.companyImage.docs && (
                    <img
                      src={userData.companyImage.docs}
                      alt=""
                      className="eprofile__img"
                    />
                  )
                ) : (
                  <img src={profileImg} alt="" className="eprofile__img" />
                )}
              </div>
              <h2 className="fm darkBlue text-capitalize fs-3 fw-semibold mt-3 mb-2">
                {userData.companyName}
              </h2>
              <p className="darkGrey fm text-14 my-2 fw-light">
                {userData?.sector} <span className="mx-2">–</span>{" "}
                {userData && userData.state ? userData.state : "--"}{" "}
                {userData && userData.country ? userData.country : "--"}{" "}
                <span className="mx-2">–</span>{" "}
                {userData && userData.noOfEmployees
                  ? userData.noOfEmployees
                  : "--"}
              </p>
              <a
                href=""
                className="text-decoration-none darkGrey text-14 fw-semibold"
              ></a>
            </div>
          </div>
          <Tabs className="" activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Home" key="tab 1">
              <div className={`${props.radius === "no" ? "border-TB" : "rounded-border"} mt-3 overflow-hidden`}>
                <div className="p-4">
                  <h4 className="fm darkBlue fw-semibold fs-5 mb-2 me-2">
                    About
                  </h4>
                  <p className="darkGrey fm mb-4 mt-0 prof__para">
                    {userData.companyDescription
                      ? userData.companyDescription
                      : "--"}
                  </p>
                </div>

                <button onClick={()=>{
                  handleShowAllDetails()
                }} className="w-100 text-center eprofile__button darkGrey fm prof__para radius-0">
                  Show all details
                </button>
              </div>

              <div className={`${props.radius === "no" ? "border-TB" : "rounded-border"} mt-3 overflow-hidden`}>
                <div className="p-4">
                  <h4 className="fm darkBlue fw-semibold fs-5 mb-4 me-2">
                    Recent Job offerings
                  </h4>
                  <div className="row g-xl-3 g-2">
                    {loading ? (
                      <Loader size="md" className="pt-4" content="loading" />
                    ) : (
                      filterData &&
                      filterData.slice(0, jobsToShow).map((item, index) => (
                        <div className="col-xl-6">
                          {console.log(item)}
                          <Link
                            className="py-0 px-0 text-decoration-none"
                            to={" "}
                            // to={`/enterprise/dashboard/jobs/${item.key}`}
                          >
                            <div className="expiring__body_detail filter__border jobs__border transition rounded-border p-3 ">
                              <div className="d-flex justify-content-start align-items-md-start">
                                <div
                                  className={`expiring__body_detail_image ${
                                    item.enterpriseLogo
                                      ? "company__image__detail"
                                      : ""
                                  }   rounded-circle d-flex justify-content-center align-items-center`}
                                >
                                  <img
                                    src={
                                      item.enterpriseLogo
                                        ? item.enterpriseLogo
                                        : `/assets/images/expiring_icon.svg`
                                    }
                                    alt=""
                                    className="me-0"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-md-start justify-content-center">
                                  <div className="expiring__body_detail__info">
                                    <h4 className="mb-sm-2 mb-md-1 mb-0 fm darkGrey text-22">
                                      {capitalizeWords(item.para)}
                                    </h4>
                                  </div>
                                  <div className="d-sm-block d-none">
                                    <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-xl-nowrap flex-wrap justify-content-start">
                                      <span className="text-14 me-3 fm mb-0 fw-semibold">
                                        {userData.companyName}
                                      </span>
                                      <div className="d-flex">
                                        <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                          <HiOutlineSquare3Stack3D className="me-1" />
                                          {item.department}
                                        </span>
                                        <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                          <TbCurrencyDollar className="fs-6" />
                                          {item.min_salary} -{item.max_salary}
                                        </span>
                                        <span className="text-14 fm fw-normal d-flex align-items-center ms-2 mb-2">
                                          <CiClock2 className="fs-6" />
                                          {item.employment_type}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="expiring__body_detail__info__tags mt-md-2 mt-1">
                                      <ul className="d-flex flex-wrap mb-0 gap-2 list-unstyled ms-0">
                                        {item.tags.map((item, index) => (
                                          <li
                                            className=" filter__content__tag__content px-2 rounded-pill"
                                            key={index}
                                          >
                                            <small className="filter__content__tag__content--small text-capitalize darkBlue fm text-12">
                                              {item}
                                            </small>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-sm-none d-block pt-2">
                                <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-xl-nowrap flex-wrap justify-content-start">
                                  <span className="text-14 me-3 fm mb-0 fw-semibold">
                                    {item.para}
                                  </span>
                                  <div className="d-flex">
                                    <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                      <HiOutlineSquare3Stack3D className="me-1" />
                                      {item.department}
                                    </span>
                                    <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                      <TbCurrencyDollar className="fs-6" />
                                      {item.min_salary} -{item.max_salary}{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="expiring__body_detail__info__tags mt-md-2 mt-1">
                                  <ul className="d-flex flex-wrap mb-0 gap-2 list-unstyled ms-0">
                                    {item.tags.map((item, index) => (
                                      <li
                                        className=" filter__content__tag__content px-2 rounded-pill"
                                        key={index}
                                      >
                                        <small className="filter__content__tag__content--small text-capitalize darkBlue fm text-12">
                                          {item}
                                        </small>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    )}
                  </div>
                </div>

                <button
                  // onClick={handleSeeAllJobs}
                  // disabled={jobsToShow >= filterData.length}
                  onClick={()=>{
                    handleSeeAllJobsTab()
                  }}
                  className="w-100 text-center eprofile__button darkGrey fm prof__para radius-0"
                >
                  See all jobs
                </button>
              </div>

              {/* <div className={`${props.radius === "no" ? "border-TB" : "rounded-border"} eprofile__tech mt-3 overflow-hidden`}>
                <div className="p-4">
                  <h4 className="fm darkBlue fw-semibold fs-5 mb-4 me-2">
                    
                  </h4>

                  <div className="d-flex flex-wrap gap-3">                    
                    <div className="techbox techbox__light"></div>
                    <div className="techbox techbox__light"></div>
                    <div className="techbox techbox__light"></div>
                    <div className="techbox techbox__light"></div>
                  </div>
                </div>
              </div> */}

              <div className={`${props.radius === "no" ? "border-TB border-bottom-0" : "rounded-border"} mt-3 p-4 overflow-hidden`}>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="fm text-20 mb-0 darkBlue fw-semibold">
                        Company Values & Benefits
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-6 pe-md-1">
                    <p className="fs-6 fm darkGrey mb-0 mt-4 fs-5 fw-semibold text-uppercase">
                      Values:
                    </p>

                    <ul className="list-unstyled mb-0">
                      {userData &&
                        Array.isArray(userData.companyValues) &&
                        userData.companyValues.map((item, index) => (
                          <li key={index} className="fs-6 fm darkGrey">
                            -{item}
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="col-md-6 mb-3 pe-md-1 mt-md-0 mt-4">
                    <div className="ps-md-3">
                      <p className="fs-6 fm darkGrey mb-0 mt-4 fs-5 fw-semibold text-uppercase">
                        Benefits:
                      </p>
                      <ul className="list-unstyled mb-0">
                        {userData &&
                          Array.isArray(userData.companyBenefits) &&
                          userData.companyBenefits.map((item, index) => (
                            <li key={index} className="fs-6 fm darkGrey">
                              -{item}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="About" key="tab 2">
            <div className="rounded-border mt-3 overflow-hidden">
                <div className="p-4">
                  <h4 className="fm darkBlue fw-semibold fs-5 mb-2 me-2">
                    About
                  </h4>
                  <p className="darkGrey fm mb-4 mt-0 prof__para">
                    {userData.companyDescription
                      ? userData.companyDescription
                      : "--"}
                  </p>
                </div>
                
              </div>
            </TabPane>
            <TabPane tab="Jobs" key="tab 3">
              <div className="rounded-border mt-3 overflow-hidden">
                <div className="p-4">
                  <h4 className="fm darkBlue fw-semibold fs-5 mb-4 me-2">
                    Jobs
                  </h4>
                  <div className="row g-xl-3 g-2">
                    {loading ? (
                      <Loader size="md" className="pt-4" content="loading" />
                    ) : (
                      filterData &&
                      filterData.map((item, index) => (
                        <div className="col-xl-6">
                          {console.log(item)}
                          <Link
                            className="py-0 px-0 text-decoration-none"
                            to={" "}
                            // to={`/enterprise/dashboard/jobs/${item.key}`}
                          >
                            <div className="expiring__body_detail filter__border jobs__border transition rounded-border p-3 ">
                              <div className="d-flex justify-content-start align-items-md-start">
                                <div
                                  className={`expiring__body_detail_image ${
                                    item.enterpriseLogo
                                      ? "company__image__detail"
                                      : ""
                                  }   rounded-circle d-flex justify-content-center align-items-center`}
                                >
                                  <img
                                    src={
                                      item.enterpriseLogo
                                        ? item.enterpriseLogo
                                        : `/assets/images/expiring_icon.svg`
                                    }
                                    alt=""
                                    className="me-0"
                                  />
                                </div>
                                <div className="d-flex flex-column justify-content-md-start justify-content-center">
                                  <div className="expiring__body_detail__info">
                                    <h4 className="mb-sm-2 mb-md-1 mb-0 fm darkGrey text-22">
                                      {capitalizeWords(item.para)}
                                    </h4>
                                  </div>
                                  <div className="d-sm-block d-none">
                                    <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-xl-nowrap flex-wrap justify-content-start">
                                      <span className="text-14 me-3 fm mb-0 fw-semibold">
                                        {userData.companyName}
                                      </span>
                                      <div className="d-flex">
                                        <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                          <HiOutlineSquare3Stack3D className="me-1" />
                                          {item.department}
                                        </span>
                                        <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                          <TbCurrencyDollar className="fs-6" />
                                          {item.min_salary} -{item.max_salary}
                                        </span>
                                        <span className="text-14 fm fw-normal d-flex align-items-center ms-2 mb-2">
                                          <CiClock2 className="fs-6" />
                                          {item.employment_type}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="expiring__body_detail__info__tags mt-md-2 mt-1">
                                      <ul className="d-flex flex-wrap mb-0 gap-2 list-unstyled ms-0">
                                        {item.tags.map((item, index) => (
                                          <li
                                            className=" filter__content__tag__content px-2 rounded-pill"
                                            key={index}
                                          >
                                            <small className="filter__content__tag__content--small text-capitalize darkBlue fm text-12">
                                              {item}
                                            </small>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-sm-none d-block pt-2">
                                <div className="expiring__body_detail__info__cat mb-0 darkGrey d-flex flex-xl-nowrap flex-wrap justify-content-start">
                                  <span className="text-14 me-3 fm mb-0 fw-semibold">
                                    {item.para}
                                  </span>
                                  <div className="d-flex">
                                    <span className="text-14 me-2 fm fw-normal d-flex align-items-center mb-2">
                                      <HiOutlineSquare3Stack3D className="me-1" />
                                      {item.department}
                                    </span>
                                    <span className="text-14 fm fw-normal d-flex align-items-center mb-2">
                                      <TbCurrencyDollar className="fs-6" />
                                      {item.min_salary} -{item.max_salary}{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="expiring__body_detail__info__tags mt-md-2 mt-1">
                                  <ul className="d-flex flex-wrap mb-0 gap-2 list-unstyled ms-0">
                                    {item.tags.map((item, index) => (
                                      <li
                                        className=" filter__content__tag__content px-2 rounded-pill"
                                        key={index}
                                      >
                                        <small className="filter__content__tag__content--small text-capitalize darkBlue fm text-12">
                                          {item}
                                        </small>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
