import React from "react";
import "./style.css";
import { HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { AiOutlineClockCircle } from "react-icons/ai";

const job = [
  {
    id: 1,
    heading: "Senior Product Designer",
    para: "All candidates are pre-scored on IndexScore's AI using all information they have provided us - this is all then checked and verified whilst they complete psychometric tests. Their results from all these elements are the combined to create one score out of 800 that encapsulates.",
    prof: "Banking",
    minSalary: 80,
    maxSalary: 100,
    time: "8 hours",
    tags: [
      "Full time",
      "Remote",
      "United Kingdom, London",
      "Design",
      "UI/UX Design",
    ],
  },
];

const Hsection1 = () => {
  return (
    <div className="holding1">
      <div className="main-padding">
        <div className="container-lg px-0 py-5">
          {job.map((item) => (
            <div className="holding1__job   bg-white p-3">
              <div className="d-flex align-items-sm-start align-items-center">
                <div className="filter__image holding1__image me-3 bg-darkGrey rounded-3 d-flex justify-content-center align-items-center">
                  <h1 className="fs-1 text-white  mb-0">
                    {item.heading.charAt(0)}
                  </h1>
                </div>
                <div className=" filter__content__items d-flex flex-column mb-md-0 mb-sm-3 w-100">
                  <h3 className="filter__content__heading dark text-22 mb-2 mb-0 fw-semibold">
                    {item.heading}
                  </h3>
                  <div className="filter__content__para mt-0 d-flex justify-content-between align-items-start w-100">
                    <div className="d-sm-block d-none">
                      <p className="pro__dash__jobs__para holding1__para darkGrey fw-normal mb-0 text-14 fm">
                        {item.para}
                      </p>
                    </div>
                    <div className="d-lg-flex d-none flex-wrap holding1__details mx-3">
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3 pb-3">
                        <HiOutlineSquare3Stack3D /> {item.prof}
                      </span>
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14 pb-3">
                        <TbCurrencyDollar /> {item.minSalary}-{item.maxSalary}k
                        USD
                      </span>
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14">
                        <AiOutlineClockCircle /> Posted{" "}
                        <span className="midGrey">{item.time} ago</span>
                      </span>
                    </div>

                    <div className="d-xl-flex flex-wrap holding1__tags d-none">
                      {item.tags.map((items) => (
                        <div className="pe-2 mb-sm-0 mb-2">
                          <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-2">
                            <small className="filter__content__tag__content--small text-capitalize text-12">
                              {items}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-sm-none d-block">
                      <p className="pro__dash__jobs__para holding1__para darkGrey fw-normal mb-0 text-14 fm mt-sm-0 mt-3">
                        {item.para}
                      </p>
                    </div>

              <div className="d-lg-none d-flex flex-wrap holding1__details">
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3 pb-2">
                        <HiOutlineSquare3Stack3D /> {item.prof}
                      </span>
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14 pb-2 me-3">
                        <TbCurrencyDollar /> {item.minSalary}-{item.maxSalary}k
                        USD
                      </span>
                      <span className="mb-0 fm darkGrey mt-0 mb-0 text-14 pb-2">
                        <AiOutlineClockCircle /> Posted{" "}
                        <span className="midGrey">{item.time} ago</span>
                      </span>
                    </div>

              <div className="d-xl-none flex-wrap holding1__tags d-flex w-100 mt-sm-0 mt-2">
                {item.tags.map((items) => (
                  <div className="pe-2 mb-sm-0 mb-1">
                    <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-2">
                      <small className="filter__content__tag__content--small text-capitalize text-12">
                        {items}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Hsection1;
