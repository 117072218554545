import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
// import DataContext from '../../../Utilities/MyContextProvider'
import { isEmail } from '../../../Utilities/utilities';
import { MyContext } from '../../../Utilities/MyContextProvider';
import { useSnackbar } from 'notistack'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const Identification = () => {

  const navigate = useNavigate();
  const myContext = useContext(MyContext);
  const myData = myContext;
  

  const [state, setState] = useState({
    emailAddress: '',
    phoneNumber: '',
    required: false,
    companyName: '',
    federalTaxID: '',
    corporateContactAddress: '',

  })
  const {
    loading,
    required,
    companyName,
    emailAddress,
    federalTaxID,
    phoneNumber,
    corporateContactAddress

  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))

  }
  const disabled =
    !companyName ||
    !emailAddress ||
    !federalTaxID ||
    !phoneNumber ||
    !corporateContactAddress
  const handleSignup = async () => {
    
    try {
      if (disabled) {

        handleChange('required', true)
        return
      }

      var payload = {
        Identification: {
          companyName: companyName,
          email: emailAddress,
          fedaralTaxId: federalTaxID,
          phoneNumber: phoneNumber,
          corpContact: corporateContactAddress,
        }
      };

      payload.Identification.phoneNumber = '+' + payload.Identification.phoneNumber;



      handleChange('required', false)      
      myContext.updateContextData(payload);
      navigate(`/enterprise/signup/online-presence`);

    } catch (error) {

    }
  }
  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-2">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Identification
          </h1>
          <p className="Get__left__content__para Signup__para mb-5">
            Please provide any government issued license IndexScore can use to authenticate your organization.
          </p>
          <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
            Company details:
          </p>

          <input
            type="text"
            className="form-control form-control-lg"
            title="Company name (if your company is a subsidiary, please use parent's entity name)"
            placeholder="Company name (if your company is a subsidiary, please use parent's entity name)"
            onChange={value =>
              handleChange('companyName', value.target.value)
            }
          />
          {required && !companyName && (
            <div>
              <small className="error__message"> Company name is required!</small>
            </div>
          )}
          <input
            type="email"
            className="form-control form-control-lg mt-3"
            title="Email (secondary email for corporate inquiries)"
            placeholder="Email (secondary email for corporate inquiries)"
            onChange={value =>
              handleChange('emailAddress', value.target.value)
            }
          />
          {emailAddress && isEmail(emailAddress) && (
            <div>
              <small className="error__message">
                {' '}
                Email must be a valid email address !
              </small>
            </div>
          )}
          {required && !emailAddress && (
            <div>
              <small className="error__message">Email Address is required!</small>
            </div>
          )}
          <input
            type="text"
            className="form-control form-control-lg mt-3"
            placeholder="Federal Tax ID / VAT"
            onChange={value =>
              handleChange('federalTaxID', value.target.value)
            }
          />
          {required && !federalTaxID && (
            <div>
              <small className="error__message"> Federal Tax ID is required!</small>
            </div>
          )}
          <PhoneInput
            country={'us'}
            inputStyle={{ width: '100%' }}
            placeholder="Phone number for general inquiries"
            value={phoneNumber}
            className="form-control form-control-lg mt-3"
            onChange={value => handleChange('phoneNumber', value)}
          />
          {required && !phoneNumber && (
            <div>
              <small className="error__message">  Phone number is required!</small>
            </div>
          )}
          <input
            type="text"
            className="form-control form-control-lg mt-3"
            placeholder="Corporate contact address"
            onChange={value =>
              handleChange('corporateContactAddress', value.target.value)
            }
          />
          {required && !corporateContactAddress && (
            <div>
              <small className="error__message"> Corporate contact address is required!</small>
            </div>
          )}
          {/* <Link
              to={"/enterprise/signup/onl ine-presence"}
              className="text-decoration-none text-white"
            > */}
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--green mt-5`}
          >
            Continue
          </button>
          {/* </Link> */}

        </div>
      </div>
    </div>
  );
};

export default Identification;
