import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const UCSection3 = () => {
  return (
    <div className="ues5 main-padding">
      <div className="container-xl px-0 mt-5 py-5 d-flex flex-column align-items-center">
        <img src="/assets/UpdatedFrontend/Candidate/getStarted.png" alt="" />
        <h1 className="fm darkBlue fs-2 mt-5 text-center">
          Find the one score behind your career
        </h1>
        <p className="fm darkGrey text-center mt-3 pb-4">
          Whether you’re actively searching for a new job—or want to see what
          your IndexScore is—sign up now to set up your profile. Discover what
          employers can see about you & how to improve it.
        </p>
        <Link to={"/professional/signup"} className="text-decoration-none">
          <button className="btn--main darkGrey fm fw-semibold rounded-2 py-2">
            Get started now
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UCSection3;
