import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import GradientSVG from "./gradientSVG";
import { FiShare } from "react-icons/fi";
import { IoIosArrowRoundForward } from "react-icons/io";
import { TbCurrencyDollar } from "react-icons/tb";
import { CiSearch } from "react-icons/ci";
import { PiDotsThreeCircleThin } from "react-icons/pi";
import {
  getJobs,
  applyJob,
  getInboxChats,
  getCandidatesAppointments,
  getProDashboard,
  getInterviews,
  showHideIndexscore,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import JobDescription from "../Jobs/JobDescription";
import JobApply from "../Jobs/JobApply";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment from "moment";
import { RiStarSLine } from "react-icons/ri";
import { Switch, Button, Menu, Dropdown, Spin } from "antd";
import { HiArrowRight } from "react-icons/hi2";
import { BsTrash } from "react-icons/bs";

const ProDashboardScreen = () => {
  const idCSS = "hello";
  const responseProfessionalData = JSON.parse(
    localStorage.getItem("professionalData")
  );
  const { setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];
  const navigate = useNavigate();
  const [jobs, setJobs] = useState(true);
  const [desc, setDesc] = useState(false);
  const [currentJob, setCurrentJob] = useState(false);
  const [acceptedInterviewsInvite, setAcceptedInterviewsInvite] = useState([]);
  const [apply, setApply] = useState(false);
  const [applied, setApplied] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const [jobTitle, setJobTitle] = useState("");
  const [locationSearch, setLocationSearch] = useState("");

  const [inboxEmails, setInboxEmails] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const { loading, loading2 } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const location = useLocation();
  if (responseProfessionalData) {
  }

  const [filteredJobs, setFilteredJobs] = useState([]);

  const fetchInterviews = async () => {
    handleChange("loading2", true);

    const payload = {
      candidateID: userData._id,
    };
    try {
      const response = await getInterviews(payload);
      const data = response.data.data;
      setAcceptedInterviewsInvite(
        data
          .filter((job) => job.status === "accepted")
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            companyName: job.company_name.toUpperCase(),
            jobTitle: job.job_title.toUpperCase(),
            companylogo: "/assets/images/Dashboard/logo-5.png",
            role_overview: job.job_role_text,
            date_start: job.interview_start_Date,
            date_end: job.interview_end_Date,
            locations: job.job_location,
            status: job.status,
          }))
      );

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
    }
  };

  const applyJobData = (key) => {
    setCurrentJob(key);

    var isApplied = false;
    var currentSelectedJob = filterData.find((job) => job.key === key);
    if (currentSelectedJob.candidates) {
      isApplied = currentSelectedJob.candidates.includes(
        localStorage.getItem("enterpriseID")
      );
    }
    setApplied(isApplied);

    jobDesc();
  };

  const handleOfferClick = (contractData) => {
    navigate("/candidate/dashboard/contracts/invitations", {
      state: { contractData },
    });
  };

  const fetchAppointments = async () => {
    try {
      var payload = {
        _id: localStorage.getItem("enterpriseID"),
      };
      const responseAppointments = await getCandidatesAppointments(payload);
      if (responseAppointments.data.statusCode === 1) {
        const appointmentsData = responseAppointments.data.data;
        setAppointments(appointmentsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const jobDesc = () => {
    setJobs(false);
    setDesc(true);
    setApply(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const applyNow = () => {
    setJobs(false);
    setDesc(false);
    setApply(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const Jobs = () => {
    setJobs(true);
    setDesc(false);
    setApply(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const [filterData, setFilterData] = useState([]);
  const [countJobs, setCountJobs] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [stepsLeft, setStepsLeft] = useState(0);
  const [indexScoreVisiblity, setIndexScoreVisiblity] = useState(
    userData?.show_indexscore ? userData?.show_indexscore : false
  );

  useEffect(() => {
    fetchJobs();
    fetchAppointments();
    fetchProDashboard();
    fetchInterviews();
  }, []);

  const brekdown_value = [
    {
      percentage: dashboardData?.progress?.profile
        ? dashboardData?.progress?.profile + "%"
        : 0 + "%",
      title: "Profile",
    },
    {
      percentage: dashboardData?.progress?.elevator
        ? dashboardData?.progress?.elevator + "%"
        : 0 + "%",
      title: "Video Presentation",
    },
    {
      percentage: dashboardData?.progress?.openQuestions
        ? dashboardData?.progress?.openQuestions + "%"
        : 0 + "%",
      title: "Interview questions",
    },
    {
      percentage: dashboardData?.progress?.references
        ? dashboardData?.progress?.references + "%"
        : 0 + "%",
      title: "References",
    },
    {
      percentage: dashboardData?.progress?.onlinePresence
        ? dashboardData?.progress?.onlinePresence + "%"
        : 0 + "%",
      title: "Online presence",
    },
  ];

  const fetchJobs = async () => {
    handleChange("loading", true);

    try {
      const response = await getJobs();
      setCountJobs(
        response.data.data.map((jobs) => ({
          key: jobs._id,
        }))
      );
      const sortedItems = response.data.data.reverse();
      const firstFiveItems = sortedItems.slice(0, 100);
      setFilterData(
        firstFiveItems
          .filter((job) => job.status === "Open")
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            heading: job.company.toUpperCase(),
            para: job.job_title.toUpperCase(),
            company_bio: job.company_bio,
            companylogo: "/assets/images/EnterpriseAvatar.svg",
            enterpriseID: job.enterpriseID,
            icon: "",
            tags: job.job_sectors,
            role_overview: job.job_role_text,
            candidates: job.candidates,
            status: job.candidates,
            price_start: job.min_salary,
            price_end: job.max_salary,
            date_start: job.start_date,
            date_end: job.end_date,
            locations: job.job_locations,
          }))
      );
      handleChange("loading", false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setJobTitle(searchText);

    const filtered = filterData.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      const locationsSearch = job.locations.toLowerCase();
      return (
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase()) ||
        locationsSearch.includes(searchText.toLowerCase())
      );
    });

    setFilteredJobs(searchText.trim() === "" ? [] : filtered);
  };

  const handleClear = () => {
    setJobTitle("");
    setLocationSearch("");

    setFilteredJobs([]);
  };

  function calculateStepsLeft(ratio) {
    const percentageLeft = 100 - ratio * 100;

    if (percentageLeft == 100) {
      return 5;
    } else if (percentageLeft == 80) {
      return 4;
    } else if (percentageLeft == 60) {
      return 3;
    } else if (percentageLeft == 40) {
      return 2;
    } else if (percentageLeft == 20) {
      return 1;
    } else {
      return 0;
    }
  }

  function parseUserAvailability(userSpokenInput) {
    const dateRegex = /\bon\s+(\d{1,2}(?:th|st|nd|rd)?\s+\w+\s+\d{4})/i;
    const timeRegex = /\bat\s+(\d{1,2}:\d{2}(?:\s*[APMapm]{2}))/i;

    const dateMatch = userSpokenInput.match(dateRegex);
    const timeMatch = userSpokenInput.match(timeRegex);

    if (!dateMatch || !timeMatch) {
      console.log("Date and time are required in the response.");
      return null;
    }

    const formattedDate = moment(dateMatch[1], "DD MMMM YYYY").format(
      "YYYY-MM-DD"
    );
    const formattedTime = moment(timeMatch[1], "hh:mm A").format("HH:mm");

    const userAvailability = {
      datetime: formattedDate + formattedTime,
    };

    return userAvailability;
  }

  const fetchProDashboard = async () => {
    handleChange("loading", true);
    const userSpokenInput =
      "Yes, I can do next week on January 5th, 2024, at 3:15 PM";
    const userAvailability = parseUserAvailability(userSpokenInput);
    try {
      const payload = {
        _id: userData._id,
      };
      const response = await getProDashboard(payload);
      if (response.data.data) {
        setDashboardData(response.data.data);
        console.log("dashboardData", response.data.data);
        const ratio = response.data.data.progress?.total
          ? response.data.data.progress?.total / 100
          : 0 / 100;
        if (ratio !== undefined) {
          const stepsLeftData = calculateStepsLeft(ratio);
          setStepsLeft(stepsLeftData);
        } else {
          console.log(
            "Unable to calculate the ratio. 'total' property is not available."
          );
        }

        handleChange("loading", false);
      } else {
        handleChange("loading", false);
        console.log("data is not coming");
      }
    } catch (error) {
      console.log(error);
    }
  };

  var myObjectEnterprise;
  const applyForJob = async (event) => {
    event.preventDefault();
    handleChange("loading", true);

    myObjectEnterprise = filterData.find((obj) => obj.key === currentJob);

    var payload = {
      _id: localStorage.getItem("enterpriseID"),
      enterpriseID: myObjectEnterprise["enterpriseID"],
      jobID: currentJob,
    };

    var res = await applyJob(payload);
    if (res.data.statusCode === 1) {
      enqueueSnackbar("Applied Successfully!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      applyNow();
      fetchJobs();
      handleChange("loading", false);
    }
  };

  const toggleIndexScore = async (value) => {
    setIndexScoreVisiblity(value);
    try {
      const payload = {
        _id: userData._id,
        show_indexscore: value,
      };
      const toggleResponse = await showHideIndexscore(payload);

      if (value) {
        enqueueSnackbar("IndexScore Visible", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        enqueueSnackbar("IndexScore hidden", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }

      setUserData([toggleResponse.data.user]);
    } catch (error) {
      console.error("Error toggling IndexScore:", error);

      // Handle the error, show a notification, or perform any other necessary actions.
      enqueueSnackbar("An error occurred. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <>
      <div className="edash overflow-hidden">
        <div className="pt-4">
          <div>
            {jobs && (
              <div className="Dashboard__container me-0">
                <div className="jobs fm pb-5">
                  <div className="">
                    <div className="dashboardScreen__content">
                      {/* <h2 className="fs-1 darkGrey fm mt-xl-2 mt-4">Welcome back!</h2> */}
                      {dashboardData &&
                      dashboardData?.progress?.total != 100 ? (
                        <div className="PDetail__top--blue p-3 d-flex justify-content-between flex-sm-nowrap flex-wrap mb-3 radius-box">
                          <div className="d-flex flex-md-nowrap flex-wrap justify-content-between">
                            <div className="d-flex">
                              <div className="dash__account_image">
                                <Spin />
                              </div>
                              <div className=" PDetail__top--blue__left me-md-3">
                                <h6 className="fm darkGrey fw-semibold fs-6 mb-1 nowrap">
                                  Account completion{" "}
                                </h6>
                                <p className="fm darkGrey mb-0 text-14">
                                  Complete your social presence to unlock
                                  IndexScore fully. You can still apply to jobs
                                  without socials but it may effect your
                                  IndexScore.{" "}
                                  <span className="blue">Learn more. </span>
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                              <button
                                className="PDetail__top--blue__goto fm"
                                onClick={() => {
                                  navigate("/candidate/dashboard/account");
                                }}
                              >
                                Go to
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}

                      <div className="row">
                        <div className="col-xl-4 pe-xl-0 mb-xl-0 mb-3">
                          <div className="dashboard__profile">
                            <div className="dashboard__profile__box d-flex flex-sm-nowrap gap-2 justify-content-between flex-wrap-reverse align-items-sm-center">
                              <div className="dashboard__profile__info">
                                <div>
                                  <Link
                                    to={`/profile/${userData._id}`}
                                    target="_blank"
                                  >
                                    <img
                                      className="profile__avatar--img "
                                      src={`${
                                        userData.profileImage
                                          ? userData.profileImage.docs
                                          : "/assets/images/userAvatar.svg"
                                      }`}
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <p className="my-0 fm text-20">
                                    {userData.firstName
                                      .charAt(0)
                                      .toUpperCase() +
                                      userData.firstName.slice(1) +
                                      " " +
                                      userData.lastName}
                                  </p>
                                  {userData.uniqueId && (
                                    <strong className="fm fw-semibold darkGrey">
                                      {userData.uniqueId}
                                    </strong>
                                  )}
                                </div>
                              </div>
                              {/* <div className="dashboard__profile_share ms-auto">
                                <Link
                                  to="/candidate/dashboard/profile/sharing"
                                  state={
                                    dashboardData?.indexScore
                                      ? dashboardData?.indexScore
                                      : 0
                                  }
                                  className="d-flex mr-2"
                                >
                                  <FiShare />
                                  Share Your Profile
                                </Link>
                              </div> */}
                            </div>
                            <div className="dashboard__profile__progress">
                              <div className="d-flex justify-content-between mb-1">
                                <div className="dashboard__profile__progress__text fm">
                                  <strong className="fm fw-semibold">
                                    Account completion
                                  </strong>
                                  <span className="grey fm">
                                    {dashboardData &&
                                    dashboardData?.progress?.total
                                      ? dashboardData?.progress?.total
                                      : 0}
                                    % complete
                                  </span>
                                </div>
                                <div className="dashboard__profile__progress__steps fm grey">
                                  <span>
                                    {dashboardData && stepsLeft} steps left
                                  </span>
                                  <HiArrowRight />
                                </div>
                              </div>
                              <ProgressBar
                                className={`progressBar__candidates rounded-pill`}
                                now={parseInt(dashboardData?.progress?.total)}
                              />
                            </div>
                          </div>
                          <div className="pro__dash__score__box position-relative pt-md-0 pt-2">
                            {/* {dashboardData?.indexScore ? (
                              <div className="indexscore__toggle"></div>
                              // <div className="indexscore__toggle">
                              //   <span>IndexScore Visibility </span>
                              //   <Switch
                              //     onChange={(value) => {
                              //       toggleIndexScore(value)
                              //     }}
                              //     checkedChildren="Show" unCheckedChildren="Hide"
                              //     checked={indexScoreVisiblity} className="mt-1" />
                              // </div>
                            ) : <div className="indexscore__toggle"></div>} */}

                            <GradientSVG />
                            <CircularProgressbarWithChildren
                              strokeWidth={5}
                              value={
                                dashboardData?.indexScore
                                  ? userData?.kycVerification
                                    ? dashboardData?.indexScore
                                    : 0
                                  : 0
                              }
                              text={"600"}
                              className="progressBars my-0"
                              maxValue={850}
                              styles={{
                                path: {
                                  stroke: `url(#${idCSS})`,
                                  height: "100%",
                                },
                                trail: {
                                  stroke: "#F0F0F0",
                                },
                              }}
                            ></CircularProgressbarWithChildren>
                            <div
                              className="pro__index_score position-absolute  w-100"
                              style={{ fontSize: 14, marginTop: -5 }}
                            >
                              <strong className=" fm main d-flex flex-column align-items-center pro__index_score__para">
                                {loading ? <Loader/> : dashboardData && dashboardData.indexScore ? (
                                  userData?.kycVerification ? (
                                    <div>
                                      {dashboardData.indexScore}
                                      <p
                                        className="mb-0 text-14 grey text-center"
                                        style={{ marginTop: "-10px" }}
                                      >
                                        /850
                                      </p>
                                    </div>
                                  ) : (
                                    "Pending"
                                  )
                                ) : (
                                  "Pending"
                                )}
                              </strong>
                            </div>

                            {/* <p className="pro__dash__score__box_title">Breakdown</p>
                            <div className="pro__dash__score__boxes">
                              <div className="row">
                                {brekdown_value.map((item) => (
                                  <div className="col-md-6">
                                    <div className="pro_brekdown_value">
                                      <h4 className="fm fs-5">{item.percentage}</h4>
                                      <p className="text-14 fm text-capitalize">{item.title}</p>
                                    </div>

                                  </div>
                                ))}
                              </div>
                            </div> */}
                            <div className="text-center mt-4 mb-2">
                              <Link
                                to={"/candidate/dashboard/indexscore"}
                                className="fm grey text-14 text-decoration-none w-100 mx-auto transition"
                              >
                                Go to Score Insights <HiArrowRight />{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 ">
                          {/* <div className="row">
                            <div className="col-md-4 pb-md-0 pb-2 pe-md-0 ">
                              <Link to={'/candidate/dashboard/inbox'} className="text-decoration-none">
                                <div className="stats__boxes filled__box pb-2 mb-0 my-0 text-decoration-none">
                                  <span className="fm">{dashboardData && dashboardData.newMessages}</span>
                                  <p className="fm mb-0">Unread Messages</p>
                                  <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 pb-md-0 pb-2 pe-md-0">
                              <Link to={'/candidate/dashboard/offers'} className="text-decoration-none">
                                <div className="stats__boxes filled__box  my-0 pb-2 mb-0">
                                  <span className="fm">{dashboardData ? dashboardData.totalOffers : 0}</span>
                                  <p className="fm mb-0">Offers </p>
                                  <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                                </div>
                              </Link>
                            </div>
                            <div className="col-md-4 pb-md-0 pb-2 ">
                              <Link to={'/candidate/dashboard/jobs'} className="text-decoration-none">
                                <div className="stats__boxes filled__box my-0 pb-2 mb-0">
                                  <span className="fm">{dashboardData ? dashboardData.latestOpenJobs : 0}</span>
                                  <p className="fm mb-0">New Open Jobs</p>
                                  <img src={`/assets/images/Dashboard/angle-arrow.svg`} alt="" className="lightBlueBar__content__icon__image" />
                                </div>
                              </Link>

                            </div>
                          </div> */}
                          <div>
                            <div className="job__top radius8 border-0 d-md-flex px-md-3 justify-content-between position-relative">
                              <div className="d-sm-flex w-100">
                                <div className="job__top__search w-100 job__top__search1 border-end d-flex align-items-center ps-md-0 ps-3 mb-md-0 mb-2">
                                  <CiSearch className="fs-4 job__top__search__icon " />
                                  <input
                                    value={jobTitle}
                                    onChange={handleSearch}
                                    type="text"
                                    className="job__top__search py-2 fm ps-2 w-100"
                                    placeholder="Job title or keyword"
                                  />
                                </div>
                                {/* <div className="job__top__search w-100 job__top__search2 d-flex align-items-center ps-3">
                                  <CiSearch className="fs-4 job__top__search__icon " />
                                  <input
                                    type="text"
                                    className="job__top__search py-2 fm ps-2 w-100"
                                    placeholder="Location"
                                  />
                                </div> */}
                              </div>
                              <button
                                onClick={() => handleClear()}
                                className="btn--main rounded-2 text-decoration-none fm my-2 ml-2 text-14 ms-3"
                              >
                                Clear
                              </button>
                              {filteredJobs.length > 0 && (
                                <div className="filteredJobs__show">
                                  <ul className="search__jobs__list">
                                    {filteredJobs.map((job) => (
                                      <li key={job.key}>
                                        <Link
                                          to={`/candidate/dashboard/jobs/${job.key}`}
                                          className="text-decoration-none"
                                        >
                                          <div className="d-flex align-items-center ">
                                            <span className="offers__company">
                                              <RiStarSLine />
                                            </span>
                                            <h6 className="text-black">
                                              {job.para}
                                            </h6>
                                          </div>
                                          <p className="text-black">
                                            <strong>Company:</strong>{" "}
                                            <span>{job.heading} </span>{" "}
                                            <strong>Location:</strong>{" "}
                                            <span>{job.locations}</span>
                                          </p>
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>

                            <div className="radius8 interview__box px-3 py-4 my-3">
                              <h5 className="fs-5 darkBlue fm fw-semibold">
                                Upcoming Interviews
                              </h5>

                              <div className="row mt-3 g-3">
                                {dashboardData ? (
                                  dashboardData.upcomingInterviews &&
                                  dashboardData.upcomingInterviews.length >
                                    0 ? (
                                    dashboardData.upcomingInterviews.map(
                                      (interviews) => (
                                        <div
                                          className="col-md-6"
                                          key={interviews.key}
                                          onClick={() => {
                                            navigate(
                                              `/candidate/dashboard/jobs/interview/${interviews._id}`
                                            );
                                          }}
                                          role="button"
                                        >
                                          <div className="dashboard__profile  bg-white radius8 p-2">
                                            <div className="d-flex">
                                              <div
                                                className={`expiring__body_detail_image  rounded-circle d-flex justify-content-center align-items-center ${
                                                  interviews.enterpriseData &&
                                                  interviews.enterpriseData
                                                    .companyImage.docs
                                                    ? "company__image"
                                                    : ""
                                                }`}
                                              >
                                                <img
                                                  src={
                                                    interviews.enterpriseData &&
                                                    interviews.enterpriseData
                                                      .companyImage.docs
                                                      ? interviews
                                                          .enterpriseData
                                                          .companyImage.docs
                                                      : `/assets/images/expiring_icon.svg`
                                                  }
                                                  alt=""
                                                  className="me-0"
                                                />
                                              </div>
                                              <div className="w-100">
                                                <h6 className="fm darkGrey mb-0 fs-6 fw-semibold mt-2">
                                                  {interviews.job_title}
                                                </h6>
                                                <p className="fm darkGrey mb-0 text-14">
                                                  {interviews.company_name}
                                                </p>

                                                <div className="d-xxl-flex d-md-block d-sm-flex w-100 justify-content-between mt-3 gap-3">
                                                  <div className=" mb-3">
                                                    <p className="fm grey text-12 mb-0">
                                                      Proposed Date/Time
                                                    </p>
                                                    <p className="fm darkGrey text-14 my-0 text-capitalize">
                                                      {moment(
                                                        interviews.interview_start_Date
                                                      ).format(
                                                        "ddd, MMM D YYYY, h:mm:ss a"
                                                      )}
                                                    </p>
                                                  </div>

                                                  {/* <div className="pe-3 mb-2">
                                                    <p className="fm grey text-12 mb-0">
                                                      Location
                                                    </p>
                                                    <p className="fm darkGrey text-14 my-0">
                                                      Google Meet
                                                    </p>
                                                    <p className="fm text-14 my-0 grey">
                                                      meet.google.com/bao-bjuh-ngg
                                                    </p>
                                                  </div> */}
                                                </div>
                                              </div>
                                              {/* <Dropdown
                                                placement="top"
                                                arrow={{ pointAtCenter: true }}
                                                overlay={
                                                  <Menu>
                                                    <Menu.Item
                                                      key="delete"
                                                      className="text-decoration-none fm text-12"
                                                    >
                                                      Delete
                                                    </Menu.Item>
                                                  </Menu>
                                                }
                                                trigger={["click"]}
                                              >
                                                <Button className="candidates__menu ms-auto p-0">
                                                  <PiDotsThreeCircleThin className="text-decoration-none fs-4" />
                                                </Button>
                                              </Dropdown> */}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )
                                  ) : (
                                    <p className="text-12 px-3 fm darkGrey">
                                      No interviews
                                    </p>
                                  )
                                ) : (
                                  <Loader />
                                )}
                              </div>
                            </div>

                            <div className="radius8 interview__box bg-white px-3 py-4">
                              <div className="d-flex flex-sm-nowrap flex-wrap gap-3 justify-content-between align-items-center">
                                <div>
                                  <h5 className="fs-5 darkBlue fm fw-semibold mb-0">
                                    Offers
                                  </h5>
                                  {dashboardData ? (
                                    dashboardData.latestOffers &&
                                    dashboardData.latestOffers.length > 0 ? (
                                      <p className="fm darkGrey mb-0">
                                        You have a new offer!
                                      </p>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <Link
                                  to={"/candidate/dashboard/offers"}
                                  className="text-decoration-none"
                                >
                                  <button className="btn--main rounded-2 text-14 py-2">
                                    Go To Offers
                                  </button>
                                </Link>
                              </div>

                              <div className="row mt-4 g-3">
                                {/* offer box */}
                                {dashboardData
                                  ? dashboardData.latestOffers &&
                                    dashboardData.latestOffers.length > 0
                                    ? dashboardData.latestOffers.map(
                                        (offers) => (
                                          <div className="col-md-6">
                                            <div
                                              onClick={() => {
                                                handleOfferClick(offers);
                                              }}
                                              className="radius8 overflow-hidden"
                                              role="button"
                                            >
                                              <div className="d-flex p-2">
                                                <div
                                                  className={`expiring__body_detail_image offer_image rounded-circle d-flex justify-content-center align-items-center ${
                                                    offers.enterpriseData &&
                                                    offers.enterpriseData
                                                      .companyImage.docs
                                                      ? "company__image"
                                                      : ""
                                                  }`}
                                                >
                                                  <img
                                                    src={
                                                      offers.enterpriseData &&
                                                      offers.enterpriseData
                                                        .companyImage.docs
                                                        ? offers.enterpriseData
                                                            .companyImage.docs
                                                        : `/assets/images/expiring_icon.svg`
                                                    }
                                                    alt=""
                                                    className="me-0"
                                                  />
                                                </div>
                                                <div>
                                                  <h6 className="fm darkGrey mb-0 fs-6 fw-semibold mt-2">
                                                    {offers.contract_title}
                                                  </h6>
                                                  <p className="fm darkGrey mb-0 text-14">
                                                    {offers.company_name}
                                                  </p>
                                                </div>
                                              </div>

                                              {/* <div className="offer__attached mt-3">
                                      <p className="text-uppercase fm darkGrey text-12 fw-semibold">
                                        FILE ATTACHED
                                      </p>

                                     <div className="d-flex align-items-center">
                                     <img
                                        className="ms-0 contract-icon me-2"
                                        src="/assets/images/pdf_icon.png"
                                      />
                                      <span className="fm text-12 darkGrey">Contact-Name.pdf</span>
                                     </div>
                                    </div> */}
                                            </div>
                                          </div>
                                        )
                                      )
                                    : " "
                                  : ""}
                              </div>
                            </div>
                          </div>
                          {/* 
                          <div className="row mt-4">
                            <div className="col-md-8 pe-md-0">
                              <h3 className="dashboard__heading">
                                Ready to find your next career move? Start your
                                search below
                              </h3>
                              <div className="job__top border-0 radius8 mb-4">
                                <div className="dashboard__job__search job__top d-md-flex px-md-3">
                                  <div className="job__top__search w-100  d-flex align-items-center ps-md-0 ps-3 mb-md-0 mb-2">
                                    <CiSearch className="fs-4 job__top__search__icon " />
                                    <input
                                      type="text"
                                      className="job__top__search py-2 fm ps-2 w-100"
                                      placeholder="Job title or keyword"
                                      value={jobTitle}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    onClick={() => handleClear()}
                                    className="job__top__search__clear d-md-block d-none fm darkGrey ms-auto me-2 my-2 py-1"
                                  >
                                    Clear
                                  </button>
                                </div>
                                <div className="filteredJobs__show">
                                  {filteredJobs.length > 0 && (
                                    <ul className="search__jobs__list">
                                      {filteredJobs.map((job) => (
                                        <li key={job.key}>
                                          <Link
                                            to={`/candidate/dashboard/jobs/${job.key}`}
                                            className="text-decoration-none"
                                          >
                                            <div className="d-flex align-items-center ">
                                              <span className="offers__company">
                                                <RiStarSLine />
                                              </span>
                                              <h6 className="text-black">
                                                {job.para}
                                              </h6>
                                            </div>
                                            <p className="text-black">
                                              <strong>Company:</strong>{" "}
                                              <span>{job.heading} </span>{" "}
                                              <strong>Location:</strong>{" "}
                                              <span>{job.locations}</span>
                                            </p>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              </div>

                              {loading2 ? (
                                <Loader
                                  size="lg"
                                  className="pt-4"
                                  content="loading"
                                />
                              ) : (
                                filterData.map((item) => (
                                  <div
                                    className="col-12 filter__border jobs__border d-flex flex-column pt-3 pb-3 transition pointer mb-2 px-2"
                                    key={item.key}
                                  >
                                    <Link
                                      to={`/candidate/dashboard/jobs/${item.key}`}
                                      className="text-decoration-none"
                                    >
                                      <div className="container-fluid px-0 d-flex">
                                        <div className="filter__data d-flex align-items-start left__data">
                                          <div className="jobs__icon__image d-flex justify-content-center align-items-center">
                                            <span className="offers__company__dashboard">
                                              <RiStarSLine />
                                            </span>
                                          </div>
                                          <div className="filter__content ">
                                            <div className="d-flex">
                                              <h3 className="filter__content__heading text-16  mb-0 fw-semibold darkGrey mb-0">
                                                {item.para}
                                              </h3>
                                            </div>
                                            <div className="">
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex flex-wrap align-items-center">
                                                  <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-12 fm me-2">
                                                    {item.heading}{" "}
                                                    <img
                                                      className="pro__dash__jobs__icon"
                                                      src={`${item.icon}`}
                                                      alt=""
                                                    />
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                    <TbCurrencyDollar />{" "}
                                                    {item.price_start}-
                                                    {item.price_end} USD
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                    Deadline: {item.date_end}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <img
                                          src={`/assets/images/Dashboard/angle-arrow.svg`}
                                          alt=""
                                          className="right__data lightBlueBar__content__icon__image"
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                ))
                              )}
                            </div>
                            <div className="col-md-4 mt-md-0 mt-2">
                              <div className="sideBar__boxes mb-2">
                                <h3 className="dashboard__heading px-4">
                                  Upcoming Interviews
                                </h3>
                                <div className="contractsBox">
                                  {dashboardData ? (
                                    dashboardData.upcomingInterviews &&
                                    dashboardData.upcomingInterviews.length >
                                      0 ? (
                                      dashboardData.upcomingInterviews.map(
                                        (interviews) => (
                                          <div
                                            key={interviews.key}
                                            className="dash__singl__contract"
                                          >
                                            <span className="offers__company__dashboard">
                                              <RiStarSLine />
                                            </span>
                                            <div>
                                              <h5 className="text-14">
                                                {interviews.job_title}
                                              </h5>
                                              <p className="text-12">
                                                Company :
                                                <span className="text-capitalize">
                                                  {interviews.company_name}
                                                </span>
                                              </p>
                                              <p className="text-12 ">
                                                Interview :{" "}
                                                <span className="text-capitalize">
                                                  {moment(
                                                    interviews.interview_start_Date
                                                  ).format(
                                                    "ddd, MMM D YYYY, h:mm:ss a"
                                                  )}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <p className="text-12 px-3">
                                        No interviews
                                      </p>
                                    )
                                  ) : (
                                    <Loader />
                                  )}
                                  {dashboardData ? (
                                    dashboardData.upcomingInterviews &&
                                    dashboardData.upcomingInterviews.length >
                                      0 ? (
                                      <div className="dash__singl__contract">
                                        <Link
                                          to={"/candidate/dashboard/interviews"}
                                        >
                                          View all{" "}
                                          <img src="/assets/images/Dashboard/angle-arrow.svg" />
                                        </Link>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="sideBar__boxes mb-2">
                                <h3 className="dashboard__heading px-4">
                                  Offers
                                </h3>
                                <div className="contractsBox">
                                  {dashboardData ? (
                                    dashboardData.latestOffers &&
                                    dashboardData.latestOffers.length > 0 ? (
                                      dashboardData.latestOffers.map(
                                        (offers) => (
                                          <div
                                            key={offers.key}
                                            className="dash__singl__contract"
                                          >
                                            <span className="offers__company__dashboard">
                                              <RiStarSLine />
                                            </span>
                                            <div>
                                              <h5 className="text-14">
                                                {offers.contract_title}
                                              </h5>
                                              <p className="text-12">
                                                Status :
                                                <span className="text-capitalize">
                                                  {offers.status}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <p className="text-12 px-3">No offers</p>
                                    )
                                  ) : (
                                    <Loader />
                                  )}
                                  {dashboardData ? (
                                    dashboardData.latestOffers &&
                                    dashboardData.latestOffers.length > 0 ? (
                                      <div className="dash__singl__contract">
                                        <Link
                                          to={"/candidate/dashboard/offers"}
                                        >
                                          View all{" "}
                                          <img src="/assets/images/Dashboard/angle-arrow.svg" />
                                        </Link>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="sideBar__boxes mb-2">
                                <h3 className="dashboard__heading px-4">
                                  Active contracts
                                </h3>
                                <div className="contractsBox">
                                  {dashboardData ? (
                                    dashboardData.activeContracts &&
                                    dashboardData.activeContracts.length > 0 ? (
                                      dashboardData.activeContracts.map(
                                        (contracts) => (
                                          <div
                                            key={contracts.key}
                                            className="dash__singl__contract"
                                          >
                                            <span className="offers__company__dashboard">
                                              <RiStarSLine />
                                            </span>
                                            <div>
                                              <h5 className="text-14">
                                                {contracts.contract_title}
                                              </h5>
                                              <p className="text-12">
                                                Status :
                                                <span className="text-capitalize">
                                                  {contracts.status}
                                                </span>
                                              </p>
                                              <Link
                                                to={
                                                  "/candidate/dashboard/contracts"
                                                }
                                              >
                                                View all{" "}
                                                <img src="/assets/images/Dashboard/angle-arrow.svg" />
                                              </Link>
                                            </div>
                                          </div>
                                        )
                                      )
                                    ) : (
                                      <p className="text-12 px-3">
                                        No contracts
                                      </p>
                                    )
                                  ) : (
                                    <Loader />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="Dashboard__container">
              <div className="jobs fm ms-xxl-5 ms-lg-4 ms-3 mt-lg-0 mt-4 mb-5">
                <div className="container-fluid px-0">
                  {desc && (
                    <>
                      <JobDescription data={currentJob} jobsData={filterData} />
                      <div className="mt-5">
                        {applied === false && (
                          <button
                            className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                            onClick={applyForJob}
                          >
                            {loading ? <Loader /> : "Apply now"}
                          </button>
                        )}
                        {applied === true && (
                          <button
                            className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                            disabled
                          >
                            Already applied
                          </button>
                        )}

                        <button
                          className="desc__back py-2 fm transition px-3 mb-2"
                          onClick={Jobs}
                        >
                          Back
                        </button>
                      </div>
                    </>
                  )}
                  {apply && (
                    <>
                      <JobApply data={currentJob} jobsData={filterData} />
                      <button
                        className="apply__back py-2 fm transition px-3 mb-2"
                        // onClick={jobDesc}
                        onClick={Jobs}
                      >
                        Back
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProDashboardScreen;
