import React, { useState, useContext, useEffect } from "react";
import { Select, Space, Avatar, Tooltip, Tag, Modal, Button } from "antd";

import "./style.css";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import {
  getEnterprises,
  getMembersByCompany,
  CreateMemberrHandler,
} from "../../../../services/auth";
import { useSnackbar } from "notistack";
import { Loader } from "rsuite";
const IndexCore = ({ handleIndexCore, handleInvitedMembers }) => {
  const myContext = useContext(MyContext);
  const { setUserData, getUserData } = useContext(MyContext);
  
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];
  const myData = myContext;
  
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  

  const [options, setOptions] = useState([]);
  const [dataInvited, setDataInvited] = useState([]);
  const [response, setResponse] = useState([]);

  const [editAboutYouVisible, setEditAboutYouVisible] = useState(false);
  const { Option } = Select;
  const { enqueueSnackbar } = useSnackbar();

  const [indexCoreData, setIndexCoreData] = useState({
    min_index_core: "",
    min_culture_fit_score: "",
  });

  const { min_index_core, min_culture_fit_score } = indexCoreData;

  const handleChange = (key, value) => {
    setIndexCoreData((pre) => ({ ...pre, [key]: value }));    
    handleIndexCore(indexCoreData);
  };

  // var getProfessionalsData= await getProfessionals();

  useEffect(() => {
    fetchData();
  }, []);

  const EditAboutYouModal = () => {
    setEditAboutYouVisible(true);
  };
  const EditAboutYouhandleOk = () => {
    setEditAboutYouVisible(false); // Close the modal after submission
  };
  const EditAboutYouhandleCancel = () => {
    setEditAboutYouVisible(false); // Close the modal without saving changes
  };

  const fetchData = async () => {
    try {
      const myId = localStorage.getItem("enterpriseID");
      const payloadGetMember = {
        enterpriseID: userData?.companyID,
      };

      const response = await getMembersByCompany(payloadGetMember);
      setResponse(response.data.data);

      const newOptions = response.data.data
        .filter(
          (professional) =>
            professional._id !== myId &&
            professional.firstName !== null &&
            professional.firstName.trim() !== " " &&
            professional.role !== "hr"
        )
        .map((professional) => ({
          label: professional.firstName,
          value: professional._id,
        }));

      setOptions(newOptions);
    } catch (error) {
      
    }

  };

  const handleDropChange = (value) => {
    
    const selectedObject = response.find((obj) => obj._id === value);    
    
    if (selectedObject) {
      setDataInvited([...dataInvited, selectedObject]);
      handleInvitedMembers([...dataInvited, selectedObject]);      
    }
  };

  const handleRemoveDrop = (value) => {
    const indexToRemove = dataInvited.findIndex((obj) => obj._id === value);
    if (indexToRemove !== -1) {
      const updatedDataInvited = [...dataInvited];
      updatedDataInvited.splice(indexToRemove, 1);
      setDataInvited(updatedDataInvited);
      handleInvitedMembers(updatedDataInvited); // Pass the updated array
    }
  };

  // add member form

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    department: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    role: "",
    email: "",
    department: "",
  });

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    // If `value` is an object representing the selected option, access its `value` property
    if (value && value.hasOwnProperty("value")) {
      value = value.value;
    }

    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      department: "",
    };

    // Add your validation logic here
    if (formData.firstName.trim() === "") {
      isValid = false;
      errors.firstName = "First name is required";
    }

    if (formData.lastName.trim() === "") {
      isValid = false;
      errors.lastName = "Last name is required";
    }

    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      isValid = false;
      errors.email = "Invalid email address";
    }
    if (formData.role === "") {
      isValid = false;
      errors.role = "Role is required";
    }

    if (formData.department === "") {
      isValid = false;
      errors.department = "Department is required";
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Add email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddButtonClick = async () => {
    try {
      if (validateForm()) {
        setLoading(true);        
        const enterpriseId = userData?.companyID;
        const payload = {
          enterpriseID: enterpriseId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          role: formData.role,
          usertype: "member",
          department: formData.department,
          company_name: userData.companyName,
          company_email: userData.emailAddress,
        };        
        EditAboutYouhandleOk();
        const responseAddMember = await CreateMemberrHandler(payload);
       
        setLoading(false);

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          role: "",
          department: "",
        });
        fetchData();
        

        if (responseAddMember.data.statusCode === 1) {
          enqueueSnackbar("Member added successfully!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        } else {
          // Handle other cases if needed
          enqueueSnackbar("Failed to add member. Please try again.", {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      }
    } catch (error) {      
      enqueueSnackbar("Error adding member. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <div className="Indexcore pb-2">
      <div className="Dashboard__container pt-4 pb-5">
        <div className="Dashboard__container___content pt-2">
          
          <div className="create__indexCore__chart  d-flex flex-sm-row flex-column align-items-center">
            <div className="me-sm-4 me-0 mb-sm-0 mb-5 position-relative d-flex justify-content-center">
              <div className="circular__text text-center darkGrey fm">
              <p className="fm darkGrey my-0 text-16 fw-semibold">IndexScore</p>
                <p className="fm darkGrey text-14 my-0 fw-light">Select the minimum score:</p>
                <p className="fs-1 main mb-0 circular__text__num mt-3 fm fw-semibold">
                  {Math.round(value * 850)}
                </p>
              </div>
              <CircularInput
                className="Indexcore__progress"
                value={value}
                onChange={(value) => {
                  // alert(value)
                  handleChange(
                    "min_index_core",
                    Math.round(value * 850).toString()
                  );
                  setValue(value);
              
                }}
              >
                <CircularTrack strokeWidth={8} stroke="#eee" />
                <CircularThumb
                  r="10"
                  stroke="#A6DD50"
                  fill="#A6DD50"
                  strokeWidth={0}
                />
                <CircularProgress strokeWidth={8} stroke="#A6DD50" />
              </CircularInput>
            </div>
            <div className="me-sm-4 me-0 mb-sm-0 mb-3  position-relative d-flex justify-content-center">
              <div className="circular__text text-center darkGrey fm">
                <p className="fm darkGrey my-0 text-16 fw-semibold">Purpose Alignment</p>
                <p className="fm darkGrey text-14 my-0 fw-light">Select the minimum score:</p>
                <p className="fs-1 main mb-0 circular__text__num mt-3 fm fw-semibold">
                  {Math.round(value2 * 100)}%
                </p>
              </div>
              <CircularInput
                className="Indexcore__progress"
                value={value2}
                onChange={(value) => {
                  // alert(value)
                  handleChange(
                    "min_culture_fit_score",
                    Math.round(value2 * 100)
                  );
                  setValue2(value);            
                }}
              >
                <CircularTrack strokeWidth={8} stroke="#eee" />
                <CircularThumb
                  r="10"
                  stroke="#A6DD50"
                  fill="#A6DD50"
                  strokeWidth={0}
                  className="circular-thumb"
                />
                <CircularProgress strokeWidth={8} stroke="#A6DD50" />
              </CircularInput>
            </div>
          </div>
          <div className="General">
            <p className="darkGrey fm mb-0 fw-semibold fs-6 mb-0 mt-4 fw-semibold">
              Invite interviewers:
            </p>
            {/* <p className="fm darkGrey text-14 my-0">Enter the email of team members that will be able to view and interact with this role.</p> */}
            <button
              onClick={EditAboutYouModal}
              className="desc__applynow py-2 my-2"
            >
              Add Member
            </button>
            <p className="fm darkGrey mb-2 mt-3 text-14 my-0">
              Please add additional members by clicking here
            </p>

            <Select
              className="Login--Input mb-2 mx-0 d-block w-100 Login--Select"
              mode="multiple"
              style={{
                width: "100%",
                maxWidth: "650px",
              }}
              placeholder="Invited Members"
              onSelect={handleDropChange}
              onDeselect={handleRemoveDrop}
              options={options}
              value={dataInvited.map((member) => member._id)}
            />
            <div>
              <Modal
                className="certification-popup"
                title="Add Members"
                visible={editAboutYouVisible}
                onOk={EditAboutYouhandleOk}
                onCancel={EditAboutYouhandleCancel}
                footer={null}
                width={600}
              >
                <div className="add-cert-form personal__information edit-name-popup">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-box">
                        <label>
                          First name
                          <input
                            type="text"
                            name="firstName"
                            className="form-control form-control-md w-100 Dashboard__input mb-2"
                            placeholder="Enter first name"
                            value={formData.firstName}
                            onChange={(value) =>
                              handleInputChange(value, "firstName")
                            }
                          />
                          <div className="error-message">
                            {formErrors.firstName}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-box">
                        <label>
                          Last name
                          <input
                            type="text"
                            name="lastName"
                            className="form-control form-control-md w-100 Dashboard__input mb-2"
                            placeholder="Enter last name"
                            value={formData.lastName}
                            onChange={(value) =>
                              handleInputChange(value, "lastName")
                            }
                          />
                          <div className="error-message">
                            {formErrors.lastName}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-box">
                        <label>
                          Email
                          <input
                            type="email"
                            name="email"
                            className="form-control form-control-md w-100 Dashboard__input mb-2"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={(value) =>
                              handleInputChange(value, "email")
                            }
                          />
                          <div className="error-message">
                            {formErrors.email}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-box">
                        <label>
                          Department Name
                          <input
                            type="text"
                            name="department"
                            className="form-control form-control-md w-100 Dashboard__input mb-2"
                            placeholder="Enter Department Name"
                            value={formData.department}
                            onChange={(value) =>
                              handleInputChange(value, "department")
                            }
                          />
                          <div className="error-message">
                            {formErrors.department}
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 General">
                      <div className="">
                        <label>Select Role</label>
                        <Select
                          className="Login--Input mb-2 mx-0 d-block w-100"
                          size="small"
                          placeholder="Select role"
                          value={formData.role}
                          onChange={(value) =>
                            handleSelectChange(value, "role")
                          }
                        >
                          <Option value="hr">HR</Option>
                          <Option value="interviewer">Interviewer</Option>
                        </Select>
                        <div className="error-message">{formErrors.role}</div>
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end mt-5 ">
                      <Button
                        className="primary cancel-button jobd__btn"
                        onClick={EditAboutYouhandleCancel}
                        style={{ marginRight: "8px" }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="desc__applynow"
                        onClick={handleAddButtonClick}
                      >
                        {loading ? <Loader /> : "Add"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexCore;
