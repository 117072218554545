import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import "./style.css";
import { contactUs } from "../../services/auth";
import { isEmail, isLinkedIn, isPassword } from "../../Utilities/utilities";
import { useSnackbar } from "notistack";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";


const ContactForm = () => {

  const { enqueueSnackbar } = useSnackbar();
  const [loading,setLoading] = useState(false);
  const [state, setState] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    message: "",
    invalidEmail: false,
    invalidPhone: false,
    required: false,
  });
  const {    
    required,
    name,
    phoneNumber,
    emailAddress,
    message,
    invalidEmail, 
    invalidPhone
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    !name ||
    !emailAddress ||
    !message ||
    !phoneNumber;

  const validateForm = () => {
    // Validation checks
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
    const isPhoneNumberValid = /^[0-9]+$/.test(phoneNumber);

    setState((prev) => ({
      ...prev,
      invalidEmail: !isValidEmail,
      invalidPhone: !isPhoneNumberValid,
      required: !name || !emailAddress || !message || !phoneNumber,
    }));

    return isValidEmail && isPhoneNumberValid && name && emailAddress && message && phoneNumber;
  };


  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true);
      const dataToSend = {
        name: name,
        emailAddress: emailAddress,
        phone: phoneNumber,
        message: message,
      };

      const responseContactUs = await contactUs(dataToSend);
      if(responseContactUs.status==200){
        enqueueSnackbar(`Thank you for the submission.`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setLoading(false);

        setState({
          name: "",
          emailAddress: "",
          phoneNumber: "",
          message: "",
          invalidEmail: false,
          invalidPhone: false,
          required: false,
        });

      }
     
      
    }
  };


  return (
    <>
      <div className="contactform top-padding d-flex flex-column">
        <div className="container-xl px-0 h-100 pb-5">
          <div className="main-padding ">
            <h1 className="contactform__heading banner__overlay__content__heading mb-4 mb-xxl-5 ">
              We believe in dialogue
            </h1>
            <div className="row h-100">
              <div className="col-lg-6 col-12 h-100 d-flex flex-column justify-content-center mb-4 mb-lg-0 ">
                <p className="contactform__para section2__content__para ">
                  We strongly believe the current hiring process can be
                  considerably improved and hoping to learn more about your
                  journey. We can get you up and running within few hours; no
                  complicated training manuals and contract structures.
                  Remember, we are Future of Work; we are simple, efficient and
                  productive!
                </p>
              </div>
              <div className="col-lg-6 col-12  ">
                <div className="signIn__form contact__form__fields">
                  <div className="form-group">
                    {required && <div className="invalid-feedback">Name is required.</div>}
                    <input
                      required
                      type="text"
                      className={`form-control-lg form-control signIn__form--input mb-3 ${required && "is-invalid"}`}
                      id="input1"
                      aria-describedby="emailHelp"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => handleChange("name", e.target.value)}
                    />
                    
                  </div>
                  <div className="form-group">
                    {invalidEmail && <div className="invalid-feedback">Invalid email format.</div>}
                    <input
                      required
                      type="email"
                      className={`form-control-lg form-control signIn__form--input mb-3 ${invalidEmail && "is-invalid"}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      value={emailAddress}
                      onChange={(e) => handleChange("emailAddress", e.target.value)}
                    />
                    
                  </div>
                  <div className="form-group">
                    {required && <span className="invalid-feedback">Phone number is required.</span>}
                    {invalidPhone && <span className="invalid-feedback">Invalid phone number format.</span>}
                    <input
                      required
                      type="text"
                      className={`form-control-lg form-control signIn__form--input mb-3 ${required && "is-invalid"}`}
                      id="input2"
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(e) => handleChange("phoneNumber", e.target.value)}
                    />                    
                  </div>
                  <div className="form-group">
                    {required && <div className="invalid-feedback">Message is required.</div>}
                    <textarea
                      required
                      type="text"
                      className={`form-control-lg form-control signIn__form--input mb-3 ${required && "is-invalid"}`}
                      id="input3"
                      placeholder="Your message..."
                      rows={6}
                      value={message}
                      onChange={(e) => handleChange("message", e.target.value)}
                    />
                    
                  </div>
                </div>
                <button
                  className="signIn__form__button contactform--button mt-4 btn btn-lg"
                  type="button"
                  onClick={handleSubmit}
                >
                  {loading ? <Loader/> : 'Submit'} 
                </button>

              </div>
            </div>
          </div>
        </div>
        <div className="contactform-border mt-md-5">
          <Footer />
        </div>
      </div >
    </>
  );
};

export default ContactForm;
