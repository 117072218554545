import React, { useContext, useState, useEffect } from "react";
import { Select } from "antd";
import { BsPlusCircle } from "react-icons/bs";
import "./style.css";

import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { Loader } from "rsuite";
import { MyContext } from "../../../Utilities/MyContextProvider";
import skillsData from "../../../Utilities/skills.json";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  Button,
  Box,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          className="m-2"
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Extra = () => {
  const { Option } = Select;
  const skills = skillsData.skills;
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar()
  const myContext = useContext(MyContext);
  const handleClose = () => {
    handleChange("showSkills", false);
  };
  useEffect(() => {
    getDropDownLists();
  }, []);

  const getDropDownLists = async () => {
    try {
      handleChange("loading", true);
      // const skills = await skillList()

      handleChange("skilllist", skillsData.skills);
      console.log("skilllist" + JSON.stringify(skilllist));
      // debugger
      handleChange("loading", false);
    } catch (error) {
      handleChange("loading", false);
      // console.log('err', error);
      // alert(error.message)
    }
  };

  

  const [emails, setEmails] = useState([""]); // initialize with one empty email field

  function handleEmailChange(index, event) {
    const updatedEmails = [...emails];
    updatedEmails[index] = event.target.value;
    setEmails(updatedEmails);
  }

  function addEmail() {
    if (emails.length < 3) {
      setEmails([...emails, ""]);
    }
  }

  const [state, setState] = useState({
    // firstName: '',
    // lastName: '',
    email: [],
    // linkedinPublicUrl: '',
    loading: false,
    // organization: '',
    // position: '',
    yearlyRevenue: '',
    sector: "",
    industry: "",
    businessFunction: "",
    required: false,
    selectedSkills: [],
  });
  const {
    loading,
    required,
    // firstName,
    // lastName,
    email,
    // linkedinPublicUrl,
    // organization,
    // position,
    yearlyRevenue,
    sector,
    industry,
    businessFunction,
    selectedSkills,
    showSkills,
    skilllist,
    searchInput,
  } = state;

  const handleChange = (key, value) => {
    // console.log("key " + key);
    // console.log("value " + value);
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    // !firstName ||
    // !lastName ||
    // !email ||
    // !organization ||
    // !linkedinPublicUrl ||
    !yearlyRevenue ||
    emails[0] === "" ||
    !selectedSkills ||
    !sector ||
    !industry ||
    !businessFunction;
  // || isLinkedIn(linkedinPublicUrl)

  const handleSignup = async () => {
    // alert();
    try {
      if (disabled) {
        console.log("iskay ander arha");
        handleChange("required", true);
        return;
      }

      // history.push('/signup/email-verification')
      handleChange("required", false);
      handleChange("loading", true);

      const emailObject = emails.map((emailval, index) => emailval.trim());

      var referencesData;

      referencesData = {
        sector: sector,
        industry: industry,
        businessFunction: businessFunction,
        skills: selectedSkills,
        references: emailObject,
      };     
      
      myContext.updateContextData(referencesData);
      navigate(`/professional/signup/identification`);
    } catch (error) { }
  };

  const [options, setOptions] = useState([
    "Energy",
    "Technology",
    "Financial Sevices",
    "Helath Care",
    "Telecommunications",
    "Basic Materials",
    "Industrials",
    "Services",
    "Food Industry",
    "Fashion",
    "Animal",
  ]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange1 = (key, value) => {
    const selectedIndex = options.indexOf(value);
    setSelectedOptions(null);
    
    // selectedOptions=null;
    const selectedOptionValues = optionsArray[selectedIndex].values;
    setState((pre) => ({ ...pre, [key]: value }));

    setSelectedOptions(selectedOptionValues);
    // this.props.form.setFieldsValue({
    //   industry: undefined
    // });
  };

  // const handleChange = (key, value) => {
  //   setState(pre => ({ ...pre, [key]: value }))
  // }

  const optionsArray = [
    {
      values: [
        { name: "Petroleum products and oil" },
        { name: "Natural gas" },
        { name: "Gasoline" },
        { name: "Diesel fuel" },
        { name: "Heating oil" },
        { name: "Nuclear" },
        { name: "Hydropower" },
        { name: "Biofuels such as ethanol" },
        { name: "Wind power" },
        { name: "Solar power" },
        { name: "Chemicals" },
      ],
    },
    {
      values: [
        { name: "Software and services" },
        { name: "Digital Transformation" },
        { name: "Computers and Information" },
        { name: "Technology hardware and equipment " },
        { name: "Semiconductors " },
        { name: "Data Processing & Outsourced Services" },
        { name: "Communication Technology" },
        { name: "Medical Technology" },
        { name: "Product Technology" },
        { name: "Business Technology" },
        { name: "Educational Technology" },
        { name: "Information Technology" },
        { name: "Construction Technology" },
      ],
    },
    {
      values: [
        { name: "Real estate" },
        { name: "Investment" },
        { name: "Trade" },
        { name: "Accounting" },
        { name: "Insurance " },
        { name: "Credit" },
        { name: "Commercial  Banking" },
        { name: "Foreign exchange" },
        { name: "Stock market" },
        { name: "Audit & Advisory" },
        { name: "Wealth Management" },
        { name: "Mortgage" },
        { name: "Capital Markets" },
        { name: "Brokerage" },
        { name: "Consumer Finance" },
        { name: "Financial Exchanges & Data" },
      ],
    },
    {
      values: [
        { name: "Pharmaceutical" },
        { name: "Hospital" },
        { name: "biotechnology" },
        { name: "Medical  equipment" },
        { name: "distribution" },
        { name: "hospital supplies manufacturers" },
      ],
    },
    {
      values: [
        { name: "Entertainment" },
        { name: "Movies" },
        { name: "Music" },
        { name: "Media" },
      ],
    },
    {
      values: [{ name: "Garden" }, { name: "Home" }],
    },
    {
      values: [
        { name: "Mining" },
        { name: "Automotive" },
        { name: "Steel" },
        { name: "Auto Parts & Accessories" },
        { name: "Electronics" },
        { name: "Tools & Equipment" },
        { name: "Toys" },
        { name: "fertilizers" },
      ],
    },
    {
      values: [
        { name: "Academic Services" },
        { name: "Active Life Services" },
        { name: "Agriculutural Services" },
        { name: "Amusement & Recreation Services" },
        { name: "Arts & Marketing Services" },
        { name: "Arts Services" },
        { name: "Automotive Services" },
        { name: "Beauty Services" },
        { name: "Business Services" },
        { name: "Children & Baby Services" },
        { name: "Construction Services" },
        { name: "Consulting Services" },
        { name: "Delivery Services" },
        { name: "Electronic/Technology Services" },
        { name: "Engineering & Management Services" },
        { name: "Entertainment Services" },
        { name: "Concierge Service" },
        { name: "Event Planning Services" },
        { name: "Fashion Services" },
        { name: "Health & Fitness Services" },
        { name: "Health & Medical Services" },
        { name: "Food & Nutrition Services" },
        { name: "Finance Services" },
        { name: "Home Services" },
        { name: "Hospitality Services" },
        { name: "Information Services" },
        { name: "Legal Services" },
        { name: "Lifestyle Services" },
      ],
    },
    {
      values: [
        { name: "Sale and marketing" },
        { name: "Market research" },
        { name: "Content marketing" },
        { name: "Database marketing" },
        { name: "Social media marketing" },
        { name: "Influencer marketing." },
        { name: "Event management" },
      ],
    },
    {
      values: [
        { name: "Books" },
        { name: "Early education" },
        { name: "Primary education" },
        { name: "Secondary" },
        { name: "technical" },
        { name: "vocational" },
        { name: "stationary" },
      ],
    },
    {
      values: [
        { name: "Food & Nutrition" },
        { name: "Hotel" },
        { name: "Restaurant" },
        { name: "beverage" },
        { name: "Dairy" },
        { name: "Fruit and Vegitables" },
        { name: "sugar and confectionery" },
        { name: "Grain an oilseeds" },
        { name: "Bakeries" },
        { name: "Agriculture" },
      ],
    },
    {
      values: [
        { name: "Beauty & Cosmetics" },
        { name: "Lifestyle " },
        { name: "fabrics " },
        { name: "leather Industry" },
        { name: "Footwear" },
      ],
    },
    {
      values: [
        { name: "Pet" },
        { name: "Live stock" },
        { name: "Animal Breeding" },
        { name: "Farming" },
        { name: "Food" },
        { name: "Animal husbandry" },
      ],
    },
    {
      values: [{ name: "sport products" }],
    },
    {
      values: [
        { name: "transport services" },
        { name: "accommodation" },
        { name: "food and drink establishments" },
        { name: "travel agencies" },
        { name: "transport rental and cultural" },
        { name: "sport and recreation services" },
      ],
    },
    {
      values: [{ name: "Bricks" }, { name: "Steel" }, { name: "Cheimical" }],
    },
  ];

  return (
    <div className="Extra Signup main-padding">
      <style>
        {`.skillDialogue {
            width: 600px;
            max-width: 100%;
        }
        }`}
      </style>
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Now we'd like to know:
          </h1>
          <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
            Your area of focus:
          </p>

          <Select
            className="Login--Input mx-0 d-block Login--select"
            size="large"
            placeholder="Your sector"
            onChange={(value) => {
              handleChange1("sector", value);
            }}
          // onChange={handleChange1}
          >
            {options.map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
            {/* <Option value="email">Email from egtos</Option>
            <Option value="google">Google</Option>
            <Option value="event">In person event</Option>
            <Option value="news">In the news</Option>
            <Option value="linkedin">Linkedin</Option> */}

            {/* <Option value="Automobiles and Components">Automobiles and Components</Option>
            <Option value="Banks">Banks</Option>
            <Option value="Capital Goods">Capital Goods</Option>
            <Option value="Commercial and Professional Services">Commercial and Professional Services</Option>
            <Option value="Consumer Durables and Apparel">Consumer Durables and Apparel</Option>
            <Option value="Consumer Services">Consumer Services</Option>
            <Option value="Diversified Financials">Diversified Financials</Option>
            <Option value="Energy">Energy</Option>
            <Option value="Food, Beverage, and Tobacco">Food, Beverage, and Tobacco</Option>
            <Option value="Food and Staples Retailing">Food and Staples Retailing</Option>
            <Option value="Health Care Equipment and Services">Health Care Equipment and Services</Option>
            <Option value="Household and Personal Products">Household and Personal Products</Option>
            <Option value="Insurance">Insurance</Option>
            <Option value="Materials">Materials</Option>
            <Option value="Media and Entertainment">Media and Entertainment</Option>
            <Option value="Pharmaceuticals, Biotechnology, and Life Sciences">Pharmaceuticals, Biotechnology, and Life Sciences</Option>
            <Option value="Real Estate">Real Estate</Option>
            <Option value="Retailing">Retailing</Option>
            <Option value="Semiconductors and Semiconductor Equipment">Semiconductors and Semiconductor Equipment</Option>
            <Option value="Software and Services">Software and Services</Option>
            <Option value="Technology Hardware and Equipment">Technology Hardware and Equipment</Option>
            <Option value="Telecommunication Services">Telecommunication Services</Option>
            <Option value="Transportation">Transportation</Option>
            <Option value="Utilities">Utilities</Option> */}

            {/* <Option value="Energy">Energy</Option>
            <Option value="Technology">Technology</Option>
            <Option value="Financial Sevices">Financial Sevices</Option>
            <Option value="Helath Care">Helath Care</Option>
            <Option value="Telecommunications">Telecommunications</Option>
            <Option value="Basic Materials">Basic Materials</Option>
            <Option value="Industrials">Industrials</Option>
            <Option value="Services">Services</Option>
            <Option value="Food Industry">Food Industry</Option>
            <Option value="Fashion">Fashion</Option>
            <Option value="Animal">Animal</Option> */}
          </Select>
          {required && !sector && (
            <div>
              <small className="error__message">Sector is required !</small>
            </div>
          )}
          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Industry"
            onChange={(value) => {
              // alert(value)
              handleChange("industry", value);
            }}
          >
            {/* <Option value="email">Email from egtos</Option>
            <Option value="google">Google</Option>
            <Option value="event">In person event</Option>
            <Option value="news">In the news</Option>
            <Option value="linkedin">Linkedin</Option> */}
            {selectedOptions.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
          {required && !industry && (
            <div>
              <small className="error__message">Industry is required !</small>
            </div>
          )}
          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Business function"
            onChange={(value) => handleChange("businessFunction", value)}
          >
            <Option value="Strategy">Strategy</Option>
            <Option value="Finance">Finance</Option>
            <Option value="Sales and marketing">Sales and marketing</Option>
            <Option value="Research and development">
              Research and development
            </Option>
            <Option value="Information technology">
              Information technology
            </Option>
            <Option value="Customer service">Customer service</Option>
            <Option value="Human resources">Human resources</Option>
            <Option value="Design">Design</Option>
            <Option value="Communications">Communications</Option>
            <Option value="Governance">Governance</Option>
            <Option value="Production">Production</Option>
            <Option value="Sourcing">Sourcing</Option>
            <Option value="Quality management">Quality management</Option>
            <Option value="Distribution">Distribution</Option>
            <Option value="Operations">Operations</Option>
          </Select>
          {required && !businessFunction && (
            <div>
              <small className="error__message">
                Business Function is required !
              </small>
            </div>
          )}
          <p className="Get__left__content__para Get__right__para fw-semibold mb-2 mt-4">
          What salary range are you expecting for your next job opportunity?
          </p>
          
          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Expected yearly salary"
            onChange={value =>
              handleChange('yearlyRevenue', value)
            }
          >
            <Option value="1000 - 5000"> 1000 - 5000</Option>
            <Option value="5000 - 10000"> 5000 - 10000</Option>
            <Option value="10000 - 20000"> 10000 - 20000</Option>
            <Option value="20,000 - 50,000"> 20,000 - 50,000</Option>
            <Option value="50,000 - 80,000"> 50,000 - 80,000</Option>
            <Option value="80,000 - 120,000"> 80,000 - 120,000</Option>
            <Option value="120,000 - 150,000"> 120,000 - 150,000</Option>
            <Option value="150,000 -200,000"> 150,000 -200,000</Option>
            <Option value="200,000 - 350,000"> 200,000 - 350,000</Option>
            <Option value="350,000$ +"> 350,000$ +</Option>
          </Select>
          {required && !yearlyRevenue && (
            <div>
              <small className="error__message"> Yearly revenues is required!</small>
            </div>
          )}

          {/* <select onChange={handleChange1}>
            {options.map((option, index) => (
              <option key={index} value={index}>
                {option}
              </option>
            ))}
          </select>

          <select>
            {selectedOptions.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select> */}

          <div class="question_box my-3">
            <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
              Please add technical skills
            </p>
            {/* <ul>
              {skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul> */}

            <div class="">
              <div
                className="d-flex align-items-center mt-3 pointer"
                onClick={() => handleChange("showSkills", true)}
              >
                <BsPlusCircle className="Extra__icon me-2 fs-2 " />
                <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
                  Add technical skills?
                </p>
              </div>
            </div>
            {required && selectedSkills.length === 0 && (
              <div>
                <small className="error__message">Skills are required !</small>
              </div>
            )}
          </div>
          <div className="row mb-4">
            <Box
              style={{
                marginTop: "30px",
              }}
            >
              {selectedSkills?.map((skill, index) => (
                <Chip
                  key={index}
                  color={"primary"}
                  onDelete={() => {
                    selectedSkills?.forEach((element, index) => {
                      if (element === skill) {
                        selectedSkills?.splice(index, 1);
                      }
                    });
                    handleChange("selectedSkills", [...selectedSkills]);
                  }}
                  variant={"outlined"}
                  label={skill}
                  style={{
                    marginRight: "10px",
                  }}
                  className="mr-2 mb-2 selectedSkill"
                />
              ))}
            </Box>
          </div>
          <BootstrapDialog
            onClose={handleClose}
            maxWidth={"md"}
            style={{ minwidth: "100%" }}
            classes={{ paper: "skillDialogue" }}
            aria-labelledby="customized-dialog-title"
            open={showSkills}
          >
            <BootstrapDialogTitle
            // id='customized-dialog-title'
            // onClose={handleClose}
            >
              <div className="d-flex">
                {/* <p className='font-24 font-weight-bold m-1'></p> */}
                <div
                  style={{ width: "100%" }}
                  class="logingroup ml-4 inner-addon right-addon"
                >
                  <i class="glyphicon fa fa-search"></i>
                  <input
                    style={{
                      width: "100%",
                      borderRadius: "8px",
                      padding: "5px 10px",
                      fontSize: "12px",
                      border: "1px solid #ccc",
                    }}
                    value={searchInput}
                    onChange={(value) => {
                      handleChange("searchInput", value.target.value);
                    }}                    
                    type="text"
                    placeholder="Search here"
                    min="0"
                  />
                </div>
              </div>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Box>
                {/* <p className='font-24 mb-4'>Select here</p> */}
                {skilllist
                  ?.filter((chatIdKey) => {
                    if (searchInput === "") {
                      //if query is empty
                      return "";
                    } else if (
                      chatIdKey?.includes(searchInput?.toLowerCase())
                    ) {
                      //returns filtered array
                      // console.log("chatIdKey filtered" + chatIdKey);
                      return chatIdKey;
                    }
                  })
                  .map((skill, index) => {
                    // console.log("skill----" + skill);
                    // console.log("index----" + index);
                    if (index < 12) {
                      return (
                        <Chip
                          key={index}
                          onClick={() => {
                            if (!selectedSkills?.includes(skill)) {
                              handleChange("selectedSkills", [
                                ...selectedSkills,
                                skill,
                              ]);
                            } else {
                              selectedSkills?.forEach((element, index) => {
                                if (element === skill) {
                                  selectedSkills?.splice(index, 1);
                                }
                              });
                              handleChange("selectedSkills", [
                                ...selectedSkills,
                              ]);
                            }
                          }}
                          color={"primary"}
                          variant={
                            selectedSkills?.includes(skill)
                              ? "filled"
                              : "outlined"
                          }
                          label={skill}
                          style={{
                            marginRight: "10px",
                          }}
                          className="mr-5 mb-2 skillsChip"
                        />
                      );
                    }
                  })}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                className={`Get__left__content__para Get__right__button Get__right__button--darkGrey `}
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className={`Get__left__content__para Get__right__button Get__right__button--blue `}
                autoFocus
                onClick={handleClose}
              >
                Save
              </Button>
            </DialogActions>
          </BootstrapDialog>

          <p className="Get__left__content__para Get__right__para fw-semibold mt-5">
            Your References:
          </p>
          <p className="Get__left__content__para Signup__para mb-5">
            References are vital to securing any job. IndexScore asks for
            references on your behalf up front, so that the reference process
            doesn’t hold up the hiring process when you apply for a new
            position.
          </p>
          <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-2">
            Please add up to three references
          </p>
          {/* <div className="row px-lg-2">
            <div className="col-6 ps-lg-1 pe-1">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="First name"
                onChange={value =>
                  handleChange('firstName', value.target.value)
                }
              />
              {required && !firstName && (
                <div>
                  <small className="error__message"> First Name is required!</small>
                </div>
              )}
            </div>
            <div className="col-6 pe-lg-1 ps-2">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Last name"
                onChange={value =>
                  handleChange('lastName', value.target.value)
                }
              />
              {required && !lastName && (
                <div>
                  <small className="error__message"> Surname is required!</small>
                </div>
              )}
            </div>
          </div> */}
          {emails.map((emailinput, index) => (
            <div key={index}>
              {/* Email {index + 1}: */}
              <input
                type="email"
                value={emailinput}
                className="form-control form-control-lg mt-3"
                placeholder="Email"
                onChange={(event) => handleEmailChange(index, event)}
                required
              />
            </div>
          ))}
          {required && emails[0] === "" && (
            <div>
              <small className="error__message">
                Email Address is required!
              </small>
            </div>
          )}
          {/* <input
            type="text"
            className="form-control form-control-lg mt-3"
            placeholder="Organization"
            onChange={value =>
              handleChange('organization', value.target.value)
            }
          />
          {required && !organization && (
            <div>
              <small className="error__message">Organization is required!</small>
            </div>
          )}
          <input
            type="text"
            className="form-control form-control-lg mt-3"
            placeholder="Position"
            onChange={value =>
              handleChange('position', value.target.value)
            }
          />
          {required && !position && (
            <div>
              <small className="error__message">Organization is required!</small>
            </div>
          )}
          <input
            type="url"
            className="form-control form-control-lg mt-3"
            placeholder="Linkedin public profile url"
            onChange={value =>
              handleChange(
                'linkedinPublicUrl',
                value.target.value
              )
            }
          />
          {linkedinPublicUrl && isLinkedIn(linkedinPublicUrl) && (
            <div>
              <small className="error__message"> Use a valid linkedin profile!</small>
            </div>
          )}
          {required && !linkedinPublicUrl && (
            <div>
              <small className="error__message">Linkedin public profile is required!</small>
            </div>
          )} */}
          <div className="d-flex align-items-center mt-3 pointer" onClick={addEmail}>
            <BsPlusCircle className="Extra__icon me-2 fs-2 " />
            <p className="Get__left__content__para Get__right__para Get__right__para-grey fw-normal mb-0">
              Add another reference?
            </p>
          </div>
          {/* <Link
              to={"/professional/signup/identification"}
              className="text-decoration-none text-white"
            > */}
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--blue mt-5`}
          >
            {loading ? <Loader /> : "Continue"}
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Extra;

