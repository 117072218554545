import React, { useState, useContext } from "react";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom'
import { MyContext } from '../../../Utilities/MyContextProvider';
import { useSnackbar } from 'notistack'

const Info = () => {
  const { Option } = Select

  const navigate = useNavigate();
  const myContext = useContext(MyContext);
  const myData = myContext;
  console.log("myData from context" + myData[0]);


  const [state, setState] = useState({
    sector: '',
    industry: '',
    yearlyRevenue: '',
    required: false,
    noOfEmployees: '',


  })
  const {
    loading,
    required,
    sector,
    industry,
    yearlyRevenue,
    noOfEmployees
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const disabled =
    !sector ||
    !industry ||
    !yearlyRevenue ||
    !noOfEmployees


  const handleSignup = async () => {


    try {
      if (disabled) {

        handleChange('required', true)
        return
      }

      var payload = {
        sector: sector,
        industry: industry,
        yearlyRevenue: 500000,
        noOfEmployees: 100
      };
      handleChange('required', false)

      myContext.updateContextData(payload);
      navigate(`/enterprise/signup/identification`);


    } catch (error) {


    }
  }



  const [options, setOptions] = useState(["Energy", "Technology", "Financial Sevices", "Helath Care", "Telecommunications", "Basic Materials", "Industrials", "Services", "Food Industry", "Fashion", "Animal"]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange1 = (key, value) => {
    const selectedIndex = options.indexOf(value);;
    setSelectedOptions(null);
    // selectedOptions=null;
    const selectedOptionValues = optionsArray[selectedIndex].values;
    setState(pre => ({ ...pre, [key]: value }))

    setSelectedOptions(selectedOptionValues);
    // this.props.form.setFieldsValue({
    //   industry: undefined
    // });

  };

  // const handleChange = (key, value) => {
  //   setState(pre => ({ ...pre, [key]: value }))
  // }

  const optionsArray = [
    {
      values: [{ name: 'Petroleum products and oil' }, { name: 'Natural gas' }, { name: 'Gasoline' }, { name: 'Diesel fuel' }, { name: 'Heating oil' }, { name: 'Nuclear' }, { name: 'Hydropower' }, { name: 'Biofuels such as ethanol' }, { name: 'Wind power' }, { name: 'Solar power' }, { name: 'Chemicals' }],
    },
    {
      values: [{ name: 'Software and services' }, { name: 'Digital Transformation' }, { name: 'Computers and Information' }, { name: 'Technology hardware and equipment ' }, { name: 'Semiconductors ' }, { name: 'Data Processing & Outsourced Services' }, { name: 'Communication Technology' }, { name: 'Medical Technology' }, { name: 'Product Technology' }, { name: 'Business Technology' }, { name: 'Educational Technology' }, { name: 'Information Technology' }, { name: 'Construction Technology' }],
    },
    {
      values: [{ name: 'Real estate' }, { name: 'Investment' }, { name: 'Trade' }, { name: 'Accounting' }, { name: 'Insurance ' }, { name: 'Credit' }, { name: 'Commercial  Banking' }, { name: 'Foreign exchange' }, { name: 'Stock market' }, { name: 'Audit & Advisory' }, { name: 'Wealth Management' }, { name: 'Mortgage' }, { name: 'Capital Markets' }, { name: 'Brokerage' }, { name: 'Consumer Finance' }, { name: 'Financial Exchanges & Data' }],
    },
    {
      values: [{ name: 'Pharmaceutical' }, { name: 'Hospital' }, { name: 'biotechnology' }, { name: 'Medical  equipment' }, { name: 'distribution' }, { name: 'hospital supplies manufacturers' }],
    },
    {
      values: [{ name: 'Entertainment' }, { name: 'Movies' }, { name: 'Music' }, { name: 'Media' }],
    },
    {
      values: [{ name: 'Garden' }, { name: 'Home' }],
    },
    {
      values: [{ name: 'Mining' }, { name: 'Automotive' }, { name: 'Steel' }, { name: 'Auto Parts & Accessories' }, { name: 'Electronics' }, { name: 'Tools & Equipment' }, { name: 'Toys' }, { name: 'fertilizers' }],
    },
    {
      values: [{ name: 'Academic Services' }, { name: 'Active Life Services' }, { name: 'Agriculutural Services' }, { name: 'Amusement & Recreation Services' }, { name: 'Arts & Marketing Services' }, { name: 'Arts Services' }, { name: 'Automotive Services' }, { name: 'Beauty Services' }, { name: 'Business Services' }, { name: 'Children & Baby Services' }, { name: 'Construction Services' }, { name: 'Consulting Services' }, { name: 'Delivery Services' }, { name: 'Electronic/Technology Services' }, { name: 'Engineering & Management Services' }, { name: 'Entertainment Services' }, { name: 'Concierge Service' }, { name: 'Event Planning Services' }, { name: 'Fashion Services' }, { name: 'Health & Fitness Services' }, { name: 'Health & Medical Services' }, { name: 'Food & Nutrition Services' }, { name: 'Finance Services' }, { name: 'Home Services' }, { name: 'Hospitality Services' }, { name: 'Information Services' }, { name: 'Legal Services' }, { name: 'Lifestyle Services' }],
    },
    {
      values: [{ name: 'Sale and marketing' }, { name: 'Market research' }, { name: 'Content marketing' }, { name: 'Database marketing' }, { name: 'Social media marketing' }, { name: 'Influencer marketing.' }, { name: 'Event management' }],
    },
    {
      values: [{ name: 'Books' }, { name: 'Early education' }, { name: 'Primary education' }, { name: 'Secondary' }, { name: 'technical' }, { name: 'vocational' }, { name: 'stationary' }],
    },
    {
      values: [{ name: 'Food & Nutrition' }, { name: 'Hotel' }, { name: 'Restaurant' }, { name: 'beverage' }, { name: 'Dairy' }, { name: 'Fruit and Vegitables' }, { name: 'sugar and confectionery' }, { name: 'Grain an oilseeds' }, { name: 'Bakeries' }, { name: 'Agriculture' }],
    },
    {
      values: [{ name: 'Beauty & Cosmetics' }, { name: 'Lifestyle ' }, { name: 'fabrics ' }, { name: 'leather Industry' }, { name: 'Footwear' }],
    },
    {
      values: [{ name: 'Pet' }, { name: 'Live stock' }, { name: 'Animal Breeding' }, { name: 'Farming' }, { name: 'Food' }, { name: 'Animal husbandry' }],
    },
    {
      values: [{ name: 'sport products' }],
    },
    {
      values: [{ name: 'transport services' }, { name: 'accommodation' }, { name: 'food and drink establishments' }, { name: 'travel agencies' }, { name: 'transport rental and cultural' }, { name: 'sport and recreation services' }],
    },
    {
      values: [{ name: 'Bricks' }, { name: 'Steel' }, { name: 'Cheimical' }]
    }
  ];


  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Now we’d like to know:
          </h1>
          <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
            About your organization:
          </p>
          {/* <form> */}
          <Select
            className="Login--Input mx-0 d-block Login--select"
            size="large"
            placeholder="Your sector"
            onChange={(value) => {
              handleChange1('sector', value);
            }}
          // onChange={handleChange1}
          >

            {options.map((option, index) => (
              <Option key={index} value={option}>
                {option}
              </Option>
            ))}
          </Select>
          {required && !sector && (
            <div>
              <small className="error__message"> Sector is required!</small>
            </div>
          )}

          {/* <Select
              mode="multiple"
              className="Login--Input mb-3 mx-0 d-block Login--select"
              size="large"
              placeholder="Your area of focus (Can select multiple)"
            >
              <Option value="email">Email from IndexScore</Option>
              <Option value="google">Google</Option>
              <Option value="event">In person event</Option>
              <Option value="news">In the news</Option>
              <Option value="linkedin">Linkedin</Option>
            </Select> */}

          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Industry"
            onChange={(value) => {
              // alert(value)
              handleChange('industry', value);
            }}

          >
            {selectedOptions.map((item, index) => (
              <Option key={index} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
          {required && !industry && (
            <div>
              <small className="error__message"> Industry is required!</small>
            </div>
          )}
          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Yearly revenues (range)"
            onChange={value =>
              handleChange('yearlyRevenue', value)
            }
          >
            <Option value="1000 - 5000"> 1000 - 5000</Option>
            <Option value="5000 - 10000"> 5000 - 10000</Option>
            <Option value="10000 - 20000"> 10000 - 20000</Option>
            <Option value="20,000 - 50,000"> 20,000 - 50,000</Option>
            <Option value="50,000 - 80,000"> 50,000 - 80,000</Option>
            <Option value="80,000 - 120,000"> 80,000 - 120,000</Option>
            <Option value="120,000 - 150,000"> 120,000 - 150,000</Option>
            <Option value="150,000 -200,000"> 150,000 -200,000</Option>
            <Option value="200,000 - 350,000"> 200,000 - 350,000</Option>
            <Option value="350,000$ +"> 350,000$ +</Option>
          </Select>
          {required && !yearlyRevenue && (
            <div>
              <small className="error__message"> Yearly revenues is required!</small>
            </div>
          )}
          <Select
            className="Login--Input mt-3 mx-0 d-block Login--select"
            size="large"
            placeholder="Number of total employees"
            onChange={value =>
              handleChange('noOfEmployees', value)
            }
          >
            <Option value="1-10 employees">1-10 employees</Option>
            <Option value="11-50 employees">11-50 employees</Option>
            <Option value="51-200 employees">51-200 employees</Option>
            <Option value="201-500 employees">201-500 employees</Option>
            <Option value="501-1000 employees">501-1000 employees</Option>
            <Option value="1001-5000 employees">1001-5000 employees</Option>
            <Option value="5001-10,000 employees">5001-10,000 employees</Option>
            <Option value="10,001+ employees">10,001+ employees</Option>
          </Select>
          {required && !noOfEmployees && (
            <div>
              <small className="error__message"> Number of total employees is required!</small>
            </div>
          )}
          {/* <Link
              to={"/enterprise/signup/identification"}
              className="text-decoration-none text-white"
            > */}
          <button
            type="submit"
            onClick={() => handleSignup()}

            className={`Get__left__content__para Get__right__button Get__right__button--green mt-5`}
          >
            Continue
          </button>
          {/* </Link> */}
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Info;
