import React, { useEffect, useState, useContext } from "react";
import { getJobById, applyJob, getJobs } from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Country } from "../../../Utilities/Country";

const JobDescription = () => {
  const { setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];

  const [applied, setApplied] = useState(false);
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const [jobData, setJobData] = useState([]);
  const [relatedJobs, setRelatedJobs] = useState([]);
  const { loading, loading2 } = state;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  var isApplied = false;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const { id } = useParams();

  const isAuthenticated = localStorage.getItem("enterpriseID");

  if (isAuthenticated) {
  } else {
    navigate("/professional/signup");
  }

  const fetchJobs = async (idparameter) => {
    handleChange("loading2", true);
    try {
      var payload = {
        _id: idparameter,
      };
      const response = await getJobById(payload);
      console.log(
        "response.data.data.find((obj) => obj._id === id)",
        response.data.data.find((obj) => obj._id === idparameter)
      );
      console.log("response jobid",response);
      setJobData(response.data.data.find((obj) => obj._id === idparameter));

      const responseJobs = await getJobs();
      const responseData = responseJobs.data.data;
      const data = responseData.slice().reverse();
      if (data) {
        const relatedJobsData = data.filter(
          (obj) =>
            obj.department === response.data.data[0].department &&
            obj._id !== id
        );
        const relatedJobsLimit = relatedJobsData.slice(0, 2);
        setRelatedJobs(relatedJobsLimit);
      }

      // console.log("response.data.data.find((obj) => obj._id === id)",response.data.data[0].department);
      console.log(
        "response.data.data.find((obj) => obj._id === id)",
        data.filter(
          (obj) =>
            obj.department === response.data.data[0].department &&
            obj._id !== id
        )
      );

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  useEffect(() => {
    fetchJobs(id);
  }, []);

  // Use a separate useEffect hook to update the applied state when jobData changes
  useEffect(() => {
    if (jobData && jobData["candidates"]) {
      const isApplied = jobData["candidates"].includes(
        localStorage.getItem("enterpriseID")
      );
      setApplied(isApplied);
    }
  }, [jobData]);

  const applyForJob = async (event) => {
    event.preventDefault();

    if (userData?.progress?.total == 100 && userData?.ai_data.index_core) {
      handleChange("loading", true);

      var payload = {
        _id: localStorage.getItem("enterpriseID"),
        enterpriseID: jobData["enterpriseID"],
        jobID: id,
      };

      var res = await applyJob(payload);
      if (res.data.statusCode == 1) {
        enqueueSnackbar("Applied Successfully!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        navigate(`/candidate/dashboard/jobs/${id}/apply`);
        handleChange("loading", false);
      }
    } else {
      enqueueSnackbar(
        "To apply for a job, you must have a completed profile and indexscore.",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        }
      );
    }
  };

  const skills = [
    {
      id: 1,
      skill: "figma",
    },
    {
      id: 2,
      skill: "XD",
    },
  ];

  const tags = [
    {
      id: 1,
      skill: "Full-Time",
    },
    {
      id: 2,
      skill: "Remote",
    },
    {
      id: 3,
      skill: "Hiring Multiple Candidates",
    },
    {
      id: 4,
      skill: "UI/UX Designer",
    },
    {
      id: 5,
      skill: "Remote",
    },
  ];
  const departmentCounts = [
    {
      id: 1,
      role: "Associate Consultant",
      company: "HCL",
      link: "",
    },
    {
      id: 2,
      role: "Consultant",
      company: "Meta",
      link: "",
    },
  ];

  const handleItemClick = (itemId) => {
    fetchJobs(itemId);
    navigate(`/candidate/dashboard/jobs/${itemId}`);
  };

  return (
    <>
      <div className="edash">
        <div className="pt-4">
          <div className="">
            {loading2 ? (
              <Loader />
            ) : (
              <div>
                <div className="Dashboard__container">
                  <div className="row mt-2">
                    <div className="col-lg-8 pe-xxl-2 pe-lg-5 order-lg-1 order-2">
                      <div className="jobs fm mb-5">
                        <div className="">
                          <div className="d-flex flex-column pb-4">
                            <div className="filter__data d-flex align-items-md-center">
                              <div
                                className={`expiring__body_detail_image ${ jobData && jobData["enterpriseData"]? 'company__image__detail' : ''} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                              >
                                <img
                                  // src={jobData && jobData["enterpriseData"] ? jobData["enterpriseData.companyImage.docs"] : "/assets/images/expiring_icon.svg"}
                                  src={jobData && jobData.enterpriseData ? jobData.enterpriseData.companyImage.docs : "/assets/images/expiring_icon.svg"}
                                  alt=""
                                  className="me-0"
                                />
                              </div>
                              <div className="filter__content ">
                                <div className="d-flex justify-content-between">
                                  <div className="filter__content__items">
                                    <h3 className=" mb-0 text-35 darkGrey fw-normal fm text-capitalize">
                                      {jobData && jobData["job_title"]}
                                    </h3>
                                    <div className="filter__content__para mt-0">
                                      <Link to={`/enterprise-profile/${jobData["enterpriseID"]}`} target={'_blank'} className="text-decoration-none"><p className="pro__dash__jobs__para mb-0 fm darkBlue text-capitalize fm">
                                        {jobData && jobData["company"]}
                                      </p></Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="filter__content__tag d-flex mt-4 flex-wrap">
                          {jobData && jobData['job_sectors'] && jobData['job_sectors'].map((tag, index) => {
                            return (
                              <div className="pe-2 mb-2 w-100 desc__button" key={index}>
                                <div className="filter__content__tag__content d-flex w-100 justify-content-center py-1 h-auto">
                                  <small className="filter__content__tag__content--small darkBlue fm desc__button--small">
                                    {tag}
                                  </small>
                                </div>
                              </div>
                            )
                          })}

                          <div className="pe-2 mb-2 w-100 desc__button">
                            <div className="filter__content__tag__content d-flex w-100 justify-content-center py-1 h-auto">
                              <small className="filter__content__tag__content--small darkBlue fm desc__button--small">
                                ${jobData['min_salary']} - ${jobData['max_salary']}
                              </small>
                            </div>
                          </div>
                        </div> */}
                          </div>

                          <h3 className=" fm darkBlue text-14 fw-semibold mt-3 text-uppercase">
                            {jobData && jobData["company"]}
                          </h3>
                          <p className="fm darkGrey mb-2 text-14 desc__para">
                            {jobData && jobData["company_bio"]}
                          </p>

                          <h3 className=" fm darkBlue text-14 fw-semibold mt-4 text-uppercase">
                            Role Overview
                          </h3>
                          <p className="fm darkGrey mb-2 text-14 desc__para">
                            {jobData && jobData["job_role_text"]}
                          </p>

                          <h3 className=" fm darkBlue text-14 text-uppercase fw-semibold mt-4">
                            What We Offer You
                          </h3>
                          <p className="fm darkGrey mb-2 text-14 desc__para">
                            {jobData && jobData["company_benefits"]}
                          </p>

                          <h3 className=" fm darkBlue text-14 text-uppercase fw-semibold mt-4">
                            What you'll do
                          </h3>
                          <p className="fm darkGrey mb-2 text-14 desc__para">
                            {jobData && jobData["company_benefits"]}
                          </p>
                          {/* <p className="fm darkGrey mb-2">
                  A hybrid working model, allowing for in-office / work from home
                  flexibility, generous vacation, personal and volunteer days
                </p>
                <p className="fm darkGrey mb-2">
                  Employee Resource Groups support an inclusive workplace for everyone and
                  promote community engagement
                </p>
                <p className="fm darkGrey mb-2">
                  Competitive compensation packages including health and wellbeing
                  benefits, retirement savings plans, parental leave, and family building
                  benefits
                </p>
                <p className="fm darkGrey pb-4 mb-2">
                  Educational resources, matching gift and volunteer programs
                      </p> */}

                          <h3 className="fm darkBlue text-14 fw-semibold text-uppercase mt-4">
                            Skills You’ll Need
                          </h3>
                          <p className="fm darkGrey mb-2 text-14 desc__para">
                            {jobData && jobData["job_expectation"]}
                            {/* </p>
                <p className="fm darkGrey mb-2">
                  Educated to bachelor’s degree level or equivalent qualification/relevant
                  work experience
                </p>
                <p className="fm darkGrey mb-2">
                  Strong analytical and communication skills, with an excellent ability to
                  communicate both in written and verbal forms
                </p>
                <p className="fm darkGrey mb-2">
                  Demonstrable ability to work autonomously with high quality outcomes in
                  demanding environments, as well as the ability to collaborate within a
                  team
                </p>
                <p className="fm darkGrey mb-2">
                  A desire to drive change at the Bank and gain excellent exposure across
                  multiple business areas */}
                          </p>
                        </div>

                        <div className="">
                          <div className="mt-5">
                            {applied === false && (
                              // <Link to={`/candidate/dashboard/jobs/${id}/apply`} className="text-decoration-none">
                              <button
                                onClick={applyForJob}
                                className="desc__applynow btn-main py-2 fm transition px-3 me-2 mb-2"
                              >
                                {loading ? <Loader /> : "Apply now"}
                              </button>
                              // </Link>
                            )}
                            {applied === true && (
                              // <button
                              //   className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              //   disabled
                              // >
                              //   Already applied
                              // </button>
                              <button
                                disabled
                                className="desc__applynow py-2 fm transition px-3 me-2 mb-2  application-sent fm radius8"
                              >
                                Application Sent
                              </button>
                            )}
                            <Link
                              to={`/candidate/dashboard/jobs`}
                              className="text-decoration-none"
                            >
                              <button className="desc__back py-2 fm transition px-3 mb-2">
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 order-lg-2 order-1 mb-4">
                      {applied ? (
                        <div>
                          <h4 className="fm fs-4 fw-semibold">
                            We've sent you an application over!
                          </h4>
                          <p className="fm mt-0 darkGrey text-14">
                            You'll shortly receive an email confirming your
                            application, you can view the role in your archived
                            roles tab here. Id you'd like to look at more roles
                            IndexScore has to offer click back to search through
                            our list of current positions.
                          </p>
                          <button
                            disabled
                            className="btn--main  application-sent fm w-100 radius8 mb-4"
                          >
                            Application Sent
                          </button>
                        </div>
                      ) : (
                        <div>
                          <h4 className="fm fs-4 fw-semibold">Apply Now!</h4>
                          <p className="fm mt-0 darkGrey text-14">
                            One click and your application is in along with your
                            IndexScore
                          </p>
                          <button
                            className="btn--main fm w-100 radius8 mb-4"
                            onClick={applyForJob}
                          >
                            Apply
                          </button>
                        </div>
                      )}
                      <div className="radius8 border pb-3">
                        <div className=" border-05 p-3">
                          <p className="fm fw-semibold">About the role</p>

                          <p className="grey fm text-12 mb-0 text-capitalize">
                            Posted
                          </p>
                          <p className="darkGrey fm mt-0 text-14">
                            {jobData && jobData["start_date"]}
                          </p>

                          <p className="grey fm text-12 mb-0 text-capitalize">
                            Deadline
                          </p>
                          <p className="darkGrey fm mt-0 text-14">
                            {jobData && jobData["end_date"]}
                          </p>

                          <p className="grey fm text-12 mb-0 text-capitalize">
                            Job type
                          </p>
                          <p className="darkGrey fm mt-0 text-14">
                            {jobData && jobData["employment_type"]}
                          </p>

                          <p className="grey fm text-12 mb-0 text-capitalize">
                            {/* Experience */}
                            Department
                          </p>
                          <p className="darkGrey fm mt-0 text-14">
                            {/* Mid-Weight (4-6 Years) */}
                            {jobData && jobData["department"]}
                          </p>

                          <p className="grey fm text-12 mb-0 text-capitalize">
                            Location
                          </p>
                          <p className="darkGrey fm mt-0 text-14">
                            {jobData &&
                              Country.find(
                                (country) =>
                                  country.code === jobData["job_locations"]
                              )?.name}
                          </p>

                          {/* <p className="grey fm text-12 mb-1 text-capitalize">
                            Skills
                          </p>
                          <div className="d-flex gap-1 flex-wrap">
                            {skills.map((item) => (
                              <div
                                key={item.id}
                                className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 "
                              >
                                <small className="filter__content__tag__content--small text-capitalize text-12">
                                  {item.skill}
                                </small>
                              </div>
                            ))}
                          </div> */}
                        </div>
                        <div className="p-3">
                          <p className="grey fm text-12 mb-2 text-capitalize">
                            Tags
                          </p>
                          <div className="d-flex flex-wrap gap-1">
                            {jobData &&
                              jobData["job_role_text_keywords"] &&
                              jobData["job_role_text_keywords"]
                                .slice(0, 10)
                                .map((item) =>
                                  item.skill ===
                                  "Hiring Multiple Candidates" ? (
                                    <button className="desc__hiring text-12 rounded-pill fm text-white fm d-flex align-items-center">
                                      <img
                                        className="pro__dash__jobs__icon"
                                        src="/assets/images/Dashboard/user-blue-icon.png"
                                      />
                                      Hiring multiple candidates
                                    </button>
                                  ) : (
                                    <div
                                      // key={item.i}
                                      className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 "
                                    >
                                      <small className="filter__content__tag__content--small fm text-capitalize text-12">
                                        {item}
                                      </small>
                                    </div>
                                  )
                                )}
                            <button className="desc__hiring text-12 rounded-pill text-white fm d-flex align-items-center">
                              <img
                                className="pro__dash__jobs__icon"
                                src="/assets/images/Dashboard/user-blue-icon.png"
                              />
                              Hiring multiple candidates
                            </button>
                          </div>
                        </div>
                      </div>
                      {relatedJobs && relatedJobs.length > 0 && (
                        <div className="radius8 px-3 py-4 mt-2 jobs__box">
                          <h6 className="fm fw-semibold mb-1">
                            More roles that match this role:
                          </h6>
                          <p className="fm text-14 darkGrey">
                            Click below to view
                          </p>
                          <div>
                            {relatedJobs.map((item) => (
                              <div
                                className="d-flex justify-content-between gap-2 align-items-center mb-2"
                                key={item.id}
                              >
                                <p className="mb-0 fm text-14 black text-capitalize">
                                  {item.job_title} –{" "}
                                  <span className="blue">{item.company}</span>
                                </p>
                                <button
                                  // to={`/candidate/dashboard/jobs/${item._id}`}
                                  onClick={() => {
                                    handleItemClick(item._id);
                                  }}
                                  className="filter__content__tag__content--small filter__content__tag__content text-12 px-2 darkBlue rounded-pill fm text-capitalize text-decoration-none"
                                >
                                  View
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDescription;
