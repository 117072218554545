import React, { useEffect, useRef, useState, useContext } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IoArrowRedoOutline, IoArrowUndoOutline, IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaUser, FaRegPaperPlane } from "react-icons/fa";
import { MdGroups2, MdOutlineRefresh } from "react-icons/md";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Upload, Button, Select } from "antd";
import { Loader, Uploader } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from 'notistack'
import moment from "moment";
import config from '../../../services/apisConfig';
import {
  sendMessageToChat,
  getChatMessages,
  getChannelsByManagerID,
  sendMessageTogroup,
  getChatsByManagerID,
  getGroupChatMessages,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import "./style.css"
import io from 'socket.io-client';


const EnterpriseChat = () => {

  const { Option } = Select;

  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [applicantsList, setApplicantsList] = useState([]);
  const [channelsList, setChannelsList] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [channelMessageInput, setChannelMessageInput] = useState('');
  const [historyChannel, setHistoryChannel] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [channelchatHistory, setChannelChatHistory] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [socket, setSocket] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const chatContainerRef = useRef();
  const channelChatContainerRef = useRef();
  const fetchAllApplicantsData = async () => {
    try {

      var payload = {
        managerID: userData._id,
      };      
      const response = await getChatsByManagerID(payload);      
      setApplicantsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllApplicantsData();
  }, []);

  const fetchChannelsData = async () => {
    try {

      var payload = {
        managerID: userData._id,
      };
      const response = await getChannelsByManagerID(payload);      
      setChannelsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchChannelsData();
  }, []);
  // chat functions
  useEffect(() => {
    const newSocket = io.connect(config.server.serverUrl);

    // Handle successful connection
    newSocket.on('connect', () => {
      console.log('Connected to Socket.IO');
    });

    // Handle reconnection attempts
    newSocket.on('reconnect_attempt', () => {
      console.log('Attempting to reconnect...');
    });

    // Handle disconnection
    newSocket.on('disconnect', (reason) => {
      console.log(`Disconnected: ${reason}`);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('joinChannel', (channelName) => {
        socket.join(channelName);
        console.log(`User joined channel: ${channelName}`);
      });

      socket.on('leaveChannel', (channelName) => {
        socket.leave(channelName);
        console.log(`User left channel: ${channelName}`);
      });
    }

    return () => {
      if (socket) {
        socket.off('joinChannel');
        socket.off('leaveChannel');
      }
    };
  }, [socket]);


  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (channelChatContainerRef.current) {
      channelChatContainerRef.current.scrollTop = channelChatContainerRef.current.scrollHeight;
    }
  }, [channelchatHistory]);


  const timeAgo = (datetime) => {
    const createdAt = new Date(datetime);
    const currentTime = new Date();
    const timeDifference = Math.floor((currentTime - createdAt) / 60000); // Calculate the difference in minutes

    let timeAgo;
    if (timeDifference < 1) {
      timeAgo = 'just now';
    } else if (timeDifference < 60) {
      timeAgo = `${timeDifference} min${timeDifference > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 1440) {
      const hours = Math.floor(timeDifference / 60);
      timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(timeDifference / 1440);
      timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
    }

    return timeAgo;
  }

  useEffect(() => {
    if (socket) {
      socket.on('newChatMessage', (data) => {
        
        const newMessage = {
          senderId: data.senderId,
          interviewID: data.interviewID,
          message: data.message,
          timestamp:data.timestamp,
        };
        
        setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
      });
    }

    return () => {
      if (socket) {
        socket.off('newChatMessage');
      }
    };
  }, [socket, selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      fetchChatHistory(selectedUser);
    }
  }, [selectedUser]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    if (socket) {
      socket.emit('joinChat', user.interviewID?._id);
    }

    fetchChatHistory(user);
  };

  const handleChannelClick = (channel) => {
    setSelectedUser(null);
    setSelectedChannel(channel);

    if (socket) {
      console.log("channel.channelName", channel);
      socket.emit('joinChannel', channel.channelName);
    }
    fetchChannelChatHistory(channel);
  };



  const sendNewMessage = async () => {
    if (!selectedUser || !messageInput.trim() || !socket) {
      return;
    }
    try {
      const sendChatPayload = {
        senderId: userData._id,
        interviewID: selectedUser.interviewID._id,
        message: messageInput.trim(),
        senderType:'enterprise',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await sendMessageToChat(sendChatPayload);

      if (socket) {
        const messageData = {
          senderId: userData._id,
          message: messageInput.trim(),
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss')
        };

        // Emit an event to send a message
        socket.emit('newChatMessage', messageData);
        console.log("messageData", messageData);
        // Update chat history in the state        

        if (chatContainerRef.current) {
          chatContainerRef.current.lastChild.scrollIntoView({ behavior: 'smooth' });
        }
        // Clear the input field after sending the message
        setMessageInput('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };


  useEffect(() => {
    if (socket) {
      socket.on('newChannelMessage', (messageData) => {
        // setReceivedMessages((prevMessages) => [...prevMessages, data]);
        // if(messageData.sender?._id!==userData._id){
          console.log("messageData", messageData);
          setChannelChatHistory((prevChatHistory) => {
            const newChatHistory = [...(prevChatHistory || []), messageData];
            return newChatHistory;
          });
        // }
      });

      // Clean up the event listener when the component unmounts
      return () => {
        socket.off('newChannelMessage');
      };
    }
  }, [socket]);


  const sendNewMessageToGroup = async () => {
    if (!selectedChannel || !channelMessageInput.trim() || !socket) {
      return;
    }

    try {
      // Emit an event to send a new message
      const sendChatPayload = {
        channelName: selectedChannel?.channelName,
        senderId: userData._id,
        senderType:'enterprise',
        message: channelMessageInput,
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      await sendMessageTogroup(sendChatPayload);

      // Emit an event to notify the other users in the channel about the new message
      setChannelMessageInput('');
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };


  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };
  const handleChannelInputChange = (e) => {
    setChannelMessageInput(e.target.value);
  };

  const fetchChatHistory = async (selectedUser) => {
    try {
      // Fetch or load chat history for the selected user from your backend
      const payloadPrivateChat = {
        interviewID:selectedUser?.interviewID?._id
      }
      console.log("response getPrivatChat payload", payloadPrivateChat);
      const response = await getChatMessages(payloadPrivateChat);
      console.log("response getPrivatChat", response);
      setChatHistory(response.data.messages); // Assuming your chat history is in the response

    } catch (error) {
      console.error('Error fetching chat history:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };


  const fetchChannelChatHistory = async (selectedChannel) => {
    try {

      const payloadPrivateChat = {
        channelId: selectedChannel._id
      }
      console.log("response channel payload", payloadPrivateChat);
      const response = await getGroupChatMessages(payloadPrivateChat);
      console.log("response channel", response);
      setChannelChatHistory(response.data.messages); // Assuming your chat history is in the response

    } catch (error) {
      console.error('Error fetching chat history:', error);

    }
  };


  const handleTabClick = (tabIndex) => {
    setTabIndex(tabIndex);
  };


  return (
    <>
      <div className="">
        <div className="chat__wrapper">
          {/* left sidebar */}
          <div className="chat__sidebar">
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList className="chat__sidebar__tabs">
                <Tab
                  onClick={() => handleTabClick(0)}
                >
                  <FaUser />
                </Tab>
                <Tab
                  onClick={() => handleTabClick(1)}
                >
                  <MdGroups2 />
                </Tab>
              </TabList>


              <TabPanel>
                <div className="chat-aside-list">
                  <ul className="chat__convo__list__group">
                    {applicantsList && applicantsList.map((item) => (
                      <li key={item.key} className="chat__convo__list"
                        onClick={() => handleUserClick(item)}
                      >
                        <div className="chat__convo">
                          <div className="chat__convo__image">
                            <img src={item.candidateID?.profileImage?.docs} alt={item.candidateID.firstName} />
                          </div>
                          <div className="chat__convo__detail">
                            <div className="chat__convo__detail_row">
                              <h6>{item.candidateID?.firstName} {item.candidateID?.lastName}</h6>
                            </div>
                            {/* <div className="chat__convo__detail_row">
                              <p className="content">Hey, how's it going?</p>
                              <span className="meta">2 days</span>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    ))}



                  </ul>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="chat-aside-list">
                  <ul className="chat__convo__list__group">
                    {channelsList && channelsList.map((item) => (
                      <li key={item.key} className="chat__convo__list"
                        onClick={() => handleChannelClick(item)}
                      >
                        <div className="chat__convo">
                          <div className="chat__convo__image">
                            <img
                              // src="/assets/images/user3.png" 
                              src={item.managerID.profileImage.docs}
                              alt={item.channelName} />
                          </div>
                          <div className="chat__convo__detail">
                            <div className="chat__convo__detail_row">
                              <h6>{item.channelName}</h6>
                              <p>{item?.jobID?.company}</p>
                            </div>
                            {/* <div className="chat__convo__detail_row">
                              <p className="content">Hey, how's it going?</p>
                              <span className="meta">2 days</span>
                            </div> */}
                          </div>
                        </div>
                      </li>
                    ))}



                  </ul>
                </div>
              </TabPanel>
            </Tabs>
            {/* <ul>
                <li className="single-chat"><IoChatbubbleEllipsesOutline /></li>
                <li className="group-chat"><MdGroups2 /></li>
              </ul> */}
            {/* </div> */}


          </div>
          {/* center portion */}
          <div className="chat__center__box">

            {/* <img src="/assets/images/google-symbol.png" /> */}
            {/* <h6>Google</h6> */}
            {tabIndex === 0 && selectedUser && (
              <div className="chat__header">
                <div className="chat__header__info d-flex align-items-center">
                  <div className="chat__convo__image">
                   <img
                    className="me-3 ms-1"
                      src={selectedUser.candidateID?.profileImage?.docs}
                      alt={selectedUser.candidateID?.firstName}
                    />
                   </div>
                  <h6 className="mb-0 fm">{selectedUser.candidateID?.firstName + ' ' + selectedUser.candidateID?.lastName}</h6>
                </div>
              </div>
            )}
            {tabIndex === 1 && selectedChannel && (
              <div className="chat__header">
                <div className="chat__header__info">
                  <div className="chat__convo__image">
                  <img
                    // src={`/assets/images/google-symbol.png`} 
                    className="me-3 ms-1"
                    src={selectedChannel.managerID.profileImage.docs}
                    alt={selectedChannel.channelName} />
                  </div>
                  <h6 className="fm mb-0">{selectedChannel.channelName}</h6>
                </div>
              </div>
            )}

            {tabIndex === 1 ?
              <>
                <div className="chat__content__scrollable" ref={channelChatContainerRef}>
                  <div className="chat-container" >
                    {channelchatHistory && channelchatHistory.map((message, index) => (
                      <div key={index} className={`message ${message.sender._id === userData._id ? 'my-message' : 'incoming-message'}`}>
                        <div className="message-content">
                        <strong>{message.sender.firstName + ' ' + message.sender.lastName}</strong>
                        <p className="my-0">{message.message}</p>
                      </div>
                        <div className="message__time">{timeAgo(message.timestamp)}</div>
                      </div>
                    ))}

                  </div>

                </div>
                {selectedChannel && (
                  <div className="chat__sending__form">
                    <div className="chat__sending__form__inner">
                      <input
                        type="text"
                        placeholder="Enter your message here"
                        value={channelMessageInput}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            sendNewMessageToGroup();
                          }
                        }}
                        onChange={handleChannelInputChange}
                      />
                      <button onClick={sendNewMessageToGroup}>
                        <FaRegPaperPlane />
                      </button>
                    </div>
                  </div>
                )}

              </>
              :
              <>
                <div className="chat__content__scrollable" ref={chatContainerRef}>
                  <div className="chat-container" >
                    {chatHistory && chatHistory.map((message, index) => (
                      <div key={index} className={`message ${message.senderId === userData._id ? 'my-message' : 'incoming-message'}`}>
                        <div className="message-content">{message.message}</div>
                        <div className="message__time">{timeAgo(message.timestamp)}</div>
                      </div>
                    ))}

                  </div>

                </div>
                {selectedUser && (
                  <div className="chat__sending__form">
                    <div className="chat__sending__form__inner">
                      <input
                        type="text"
                        placeholder="Enter your message here"
                        value={messageInput}
                        onChange={handleInputChange}
                      />
                      <button onClick={sendNewMessage}>
                        <FaRegPaperPlane />
                      </button>
                    </div>
                  </div>
                )}

              </>
            }

          </div>
          {tabIndex === 0 && selectedUser && (
            <div className="chat__info__box">
              <div className="chat__info__box__top">
                <div className="chat__info__box__top__image">
                  <img src={selectedUser?.candidateID?.profileImage?.docs} />
                </div>
                <h6 className="my-2 fs-6 fm">{selectedUser?.candidateID?.firstName + ' ' + selectedUser?.candidateID?.lastName}</h6>
                <p className="mb-2 mt-2 text-grey">{selectedUser?.interviewID?.company_name}</p>
                <p className="my-0 text-grey text-14">{selectedUser?.interviewID?.job_title}</p>

              </div>
              <div className="chat__info__details">
                {/* <div className="chat__info__details__info">
                <img src="/assets/images/location-icon.png" /> <span className="text-grey text-14">New York, USA</span>
              </div> */}
                {/* <div className="chat__info__details__info">
                  <img src="/assets/images/phone-icon.png" /> <span className="text-grey text-14">{selectedUser?.candidatePhoneNumber}</span>
                </div>
                <div className="chat__info__details__info">
                  <img src="/assets/images/email-icon.png" /> <span className="text-grey text-14">{selectedUser?.candidateEmail}</span>
                </div> */}
              </div>

            </div>
          )}

          {tabIndex === 1 && selectedChannel && (
            <div className="chat__info__box">
              <div className="chat__info__box__top">
                <div className="chat__info__box__top__image">
                  <img
                    src={selectedChannel?.managerID?.profileImage?.docs}
                  />
                </div>
                <h6 className="my-2 fm fs-6">{selectedChannel?.channelName}</h6>
                <p className="fm my-0 text-grey">{selectedChannel?.jobID?.company}</p>

              </div>
              <div className="chat__info__details">
                {/* <div className="chat__info__details__info">
                <img src="/assets/images/location-icon.png" /> <span className="text-grey text-14">New York, USA</span>
              </div> */}
                {/* <div className="chat__info__details__info">
                  <img src="/assets/images/phone-icon.png" /> <span className="text-grey text-14">------------</span>
                </div>
                <div className="chat__info__details__info">
                  <img src="/assets/images/email-icon.png" /> <span className="text-grey text-14">------------</span>
                </div> */}
              </div>
              <div className="chat__info__box__team fm">
                <h3 className="fs-6">Team Members</h3>
                <ul>
                  {selectedChannel?.members.map((item) => (
                    <li className="me-2">
                      <img src="/assets/images/user.svg" />
                      <p className="fm">{item.lastName}</p>
                    </li>
                  ))}
                </ul>                
              </div>

            </div>
          )}



        </div>

      </div>
    </>
  );
};

export default EnterpriseChat;
