import React from "react";
import ProInboxDesktop from "./ProInboxDesktop";
import ProInboxMobile from "./ProInboxMobile";
import ProfessionalChat from "./ProfessionalChat";
import "./style.css";

const ProDInbox = () => {
  return (
    <>
      <div className="Dashboard__container container-lg chat__page">
        <div></div>
        <div className="d-md-block d-none">
          <ProfessionalChat />
        </div>
        <div className="d-md-none d-block">
          <ProInboxMobile />
        </div>
      </div>
    </>
  );
};

export default ProDInbox;
