import axios from 'axios';
import environment from "../environment/index";
const baseUrl = environment.REACT_APP_DEV_PROXY;

const instance = axios.create({
  baseURL: baseUrl,
  
});

instance.interceptors.request.use(
  async (config) => {
    // Get the token from your token storage (e.g., localStorage or Redux store)
    const token = localStorage.getItem('token');
    
    if (token) {
      // Add the token to the request headers
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
