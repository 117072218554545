import React from "react";
import "./style.css";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const features = [
  {
    id: 1,
    text: "IndexScore",
  },
  {
    id: 2,
    text: "Job Relevance",
  },
  {
    id: 3,
    text: "ATS Integration",
  },
  {
    id: 4,
    text: "Compliance Report",
  },
  {
    id: 5,
    text: "Purpose Alignment",
  },
  {
    id: 6,
    text: "Integrated Candidate Engagement",
  },
  {
    id: 7,
    text: "Offer & Contract Management",
  },
  {
    id: 8,
    text: "Talent Availability Insights",
  },
];

const PSection1 = () => {
  return (
    <div className="ps1 overflow-hidden top-padding">
      <div className="main-padding">
        <div className="container-xl px-0 pb-5">
          <h1 className="fm darkBlue fw-semibold text-center fs-1 mb-lg-5 mb-4">
            One data-driven, <br /> people-first employability score
          </h1>

          <div className="row pt-4">
            <div className="col-md-5 order-md-1 order-2 ">
              <div className="ps1__features px-4 py-3 ms-md-auto d-flex flex-column justify-content-between">
                <div>
                  <h1 className="text-white fs-3 fw-normal fm mb-0">
                    Features include:
                  </h1>
                  <p className="text-white fm fw-light text-14">
                    Find a plan that fits your enterprise needs and see the
                    benefits straight away
                  </p>
                  {features.map((item) => (
                    <div
                      className="mb-2 d-flex align-items-start"
                      key={item.id}
                    >
                      <div className="d-flex align-items-start">
                        <BsCheckLg className="blue fs-5 me-2" />
                      </div>
                      <span className="text-white fm text-14">{item.text}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-md-7  px-xl-2 px-0 order-md-2 order-1 mb-lg-0 mb-4">
              <div className="w-100 ps1__right d-flex justify-content-lg-start justify-content-center">
                <img
                  src="/assets/images/Launch/pricing_group.png"
                  alt=""
                  className="img-fluid me-auto"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Link to={"/enterprise/signup"} className="text-decoration-none">
              <button className="btn--blue fm text-14 text-white py-2 rounded-2">
                Start hiring more strategically
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PSection1;
