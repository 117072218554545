import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import EThankYou from "../components/EThankYou/EThankYou";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const EnterpriseThankYou = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="enterprise-signup" />
      <MobileSidebar />
      <EThankYou
        title="Enterprise"
        para1="Get a IndexScore account and cut your recruitment time by up to 75%."
        para2="Our process is simple; just complete this quick form to get started. You'll receive a confirmation email via your corporate email address to complete your account set up process."
        color="green"
        page="enterprise"
      />
      <Footer />
    </>
  );
};

export default EnterpriseThankYou;
