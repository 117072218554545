import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { Input } from 'antd';
import { isEmail, } from '../../Utilities/utilities'
import { forgotPasswordApi } from "../../services/auth";
import { Loader } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import 'rsuite/dist/rsuite.min.css'


const Forgot = () => {

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate();

  const [disabledState, setDisabledState] = useState(false);



  const [state, setState] = useState({
    emailAddress: '',
    loading: false,
    required: false,
  })
  const {
    loading,
    required,
    emailAddress,
  } = state


  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }
  const disabled = !emailAddress

  const handleForgot = async () => {
    try {
      if (disabled) {
        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        emailAddress: emailAddress,
      }
      const res = await forgotPasswordApi(payload);      
      if (res) {
        console.log("response of sign in" + JSON.stringify(res));
        if (res.status === 200) {          
          handleChange('loading', false)
          enqueueSnackbar('Email has been sent with a link to change password', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          });
          handleChange('emailAddress', '');
          setDisabledState(true);
          // navigate(`/reset-password`);
        }
      }
      else {
        handleChange('loading', false);
        enqueueSnackbar(`Error!${res.message}`, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });

      }



    } catch (error) {
      handleChange('loading', false)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }
  }



  return (
    <div className="forgot  d-flex align-items-center w-100 pt-5">
      <div className="main-padding w-100 h-100 ">
        <div className="container-xl px-0 py-5">
          <div className="forgot__box p-sm-4 px-3 py-4 fm">
            <h4 className="fs-5 fm darkBlue fw-semibold text-uppercase">
              FORGOTTEN PASSWORD
            </h4>
            <h1 className="fw-light fm darkgrey fs-2 mt-4">
              Change or reset your password
            </h1>
            <p className="fm darkGrey text-14">
              You can change your password for security reasons or reset it if
              you forget it.
            </p>

            <Input size="large" placeholder="Email" type='email'
              value={emailAddress}
              onChange={value =>
                handleChange('emailAddress', value.target.value)
              }
              className='forgot__input ' /> <br />
            {emailAddress && isEmail(emailAddress) && (
              <div>
                <small style={{ color: "red", marginLeft: 20 }}> Email must be a valid email address !</small>
              </div>
            )}
            {required && !emailAddress && (
              <div>
                <small style={{ color: "red", marginLeft: 20 }}>Email Address is required !</small>
              </div>
            )}
            {/* <Link to={"/reset-password"}> */}
            <button onClick={() => handleForgot()} className={`forgot__btn fm py-2 px-3 mt-3${disabled ? ' disabled' : ''}`}
              disabled={disabled}>{loading ? <Loader /> : disabledState ? 'Email Sent' : 'Request a new password'}</button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
