import React from "react";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Candidate from "../pageComponents/Verification/Candidate";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const CandidateVerification = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <Candidate />
      <Footer />
    </>
  );
};

export default CandidateVerification;
