import React, { useState } from "react";
import "./style.css";
import { Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { Country } from "../../Utilities/Country";
import { CreateVisitorHandler } from "../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";


// CreateVisitorHandler
const SignupModal = (props) => {
  const { Option, OptGroup } = Select;
  const [submit, setSubmit] = useState(false);
  const [loading,setLoading]= useState(false);

  const handleClose = () => {
    props.func(false);
    setSubmit(false);
  };




  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
  });

  const initialValues= {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
  });

  // Define a function to handle form submissions
  const handleSubmit = async (e) => {
    
    const validationErrors = {};
    let isFormValid = true;

    if (formData.firstName.trim() === "") {
      validationErrors.firstName = "First name is required";
      isFormValid = false;
    }

    if (formData.lastName.trim() === "") {
      validationErrors.lastName = "Last Name is required";
      isFormValid = false;
    }

    if (formData.email.trim() === "") {
      validationErrors.email = "Email is required";
      isFormValid = false;
    }

    if (formData.phoneNumber.trim() === "") {
      validationErrors.phoneNumber = "Phone number is required";
      isFormValid = false;
    }

    if (formData.country.trim() === "") {
      validationErrors.country = "Country is required";
      isFormValid = false;
    }

    if (!isFormValid) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);    
    const postData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      emailAddress: formData.email,
      phoneNumber: formData.phoneNumber,
      country: formData.country,
    };

    const responsePopupForm = await CreateVisitorHandler(postData);
    if(responsePopupForm && responsePopupForm.data){
      setLoading(false);
      setSubmit(true);
      setFormData(initialValues);
      setErrors(initialValues);

      
    }
    
  
  };

  
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  const handleChange = (key, value) => {
    setFormData((pre) => ({ ...pre, [key]: value }));
   
   
  };    



  return (
    <div className="smodal">
      <style>
        {`
          .error-message{
            color:red;
          }
        `}
      </style>
      <Modal centered footer="" open={props.state} onCancel={handleClose} closeIcon={false}>
        <div className="smodal__inner">
        <div className="d-flex justify-content-center mt-4 mb-5">
          <Link to={"/"} className="">
            <div className="Navbar__container__logo h-100 d-flex align-items-center">
              <img
                src={`/assets/images/navbar/logoMainColor.svg`}
                alt=""
                className="Navbar__container__logo--img"
              />
            </div>
          </Link>
        </div>
        <h1 className="fm main fs-4 fw-light text-center ">
          Thank you for your interest!
        </h1>
        {/* {submit ? (
          <div className="d-flex flex-column align-items-center">
            <p className="fm darkGrey text-center smodal__text mx-auto">
              A member of the IndexScore team will contact you to coordinate a
              quick demo session.
            </p>
            <button
              type="submit"
              onClick={handleClose}
              className={`Get__left__content__para transition Get__right__button Get__right__button--muted mb-3 mt-4`}
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <p className="fm darkGrey text-center smodal__text2 mx-auto">
              Our outstanding customer success team ensures clients are
              onboarded seamlessly and conducts a short welcome session prior to
              account creations. Please complete this short form to get started.
            </p>
            <div className="row">
              <div className="col-sm-6 pe-sm-1 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  value={formData.firstName}
                  onChange={(value) =>
                    handleChange("firstName", value.target.value)
                  }
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
              </div>
              <div className="col-sm-6 ps-sm-1 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  value={formData.lastName}
                  onChange={(value) =>
                    handleChange("lastName", value.target.value)
                  }
                />
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
              </div>
              <div className="col-12 mb-2">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(value) =>
                    handleChange("email", value.target.value)
                  }
                />
                {errors.email && <div className="error-message">{errors.email}</div>}
              </div>
              <div className="col-12 mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone number"
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    handleChange("phoneNumber", value.target.value)
                  }
                  onKeyPress={(e) => {
                    if (e.which < 48 || e.which > 57) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
              </div>
              <div className="col-12">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  className="Login--Input Login--select2 new--select smodal__select  mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="Country"
                  onChange={(value) =>
                    handleChange("country", value)
                  }

                >
                  <OptGroup>
                    {Country.map((item) => (
                      <Option
                        value={item.name}
                        label={item.name.toLowerCase()}
                        key={item.code}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </OptGroup>
                </Select>
                {errors.country && <div className="error-message">{errors.country}</div>}
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => 
                    // setSubmit(true)
                    handleSubmit()
                  }
                  className={`Get__left__content__para Get__right__button Get__right__button--green mb-3 mt-4`}
                >
                  {loading ? <Loader/> : 'Submit'} 
                </button>
              </div>
            </div>
          </>
        )} */}

       <div className="d-flex gap-2 my-4">
       <Link to={"/candidate/signup"} className="w-100">
        <button className="rounded py-2 fm text-white  Get__right__button--blue w-100">
          Signup as Candidate
        </button></Link>
        <Link to={"/enterprise/signup"} className="w-100">
        <button className="rounded py-2 fm text-white Get__right__button--green w-100">
          Signup as Enterprise
        </button></Link>
       </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignupModal;
