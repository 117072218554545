import React from "react";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Navbar from "../components/Navbar/Navbar.jsx";
import Banner from "../components/Banner/Banner";
import Explore from "../pageComponents/JobsForum/ExploreMoreJobs/Explore";
import Filter from "../pageComponents/JobsForum/Filter/Filter";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const Individuals = () => {
  return (
    <>
      <Navbar logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <Banner
        image="/assets/images/banner/banner_9.jpg"
        heading="Start doing work that matters"
        para="???"
      />
      <Explore />
      <Filter />
      <Footer />
    </>
  );
};

export default Individuals;
