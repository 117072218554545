import React, { useEffect, useRef, useState, useContext } from "react";
import { FaRegPaperPlane } from "react-icons/fa";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Upload, Button, Select } from "antd";
import { Loader, Uploader } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from 'notistack'
import moment from "moment";
import config from "../../../services/apisConfig";
import {
  getManagerIdsByCandidateId,
  sendChat,
  getChatsByCandidateID,
  sendMessageToChat,  
  getChatMessages
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import "./style.css"
import io from 'socket.io-client';


const ProfessionalChat = () => {

  const { Option } = Select;
  const { enqueueSnackbar } = useSnackbar()

  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const [managers, setManagers] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [historyChannel, setHistoryChannel] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [socket, setSocket] = useState(null);
  const chatContainerRef = useRef();



  const fetchAllApplicantsManagers = async () => {
    // handleChange("loading", true);
    try {

      var payload = {
        candidateID: userData._id,
      };
      const response = await getChatsByCandidateID(payload);
      console.log("response candidate", response.data);
      setManagers(response.data);
      


      // handleChange("loading", false);
    } catch (error) {
      console.log(error);
      // handleChange("loading", false);
    }
  };

  useEffect(() => {
    fetchAllApplicantsManagers();
  }, []);


  // chat functions



  useEffect(() => {
    const newSocket = io.connect(config.server.serverUrl);

    // Handle successful connection
    newSocket.on('connect', () => {
      console.log('Connected to Socket.IO');
    });

    // Handle reconnection attempts
    newSocket.on('reconnect_attempt', () => {
      console.log('Attempting to reconnect...');
    });

    // Handle disconnection
    newSocket.on('disconnect', (reason) => {
      console.log(`Disconnected: ${reason}`);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);


  useEffect(() => {
    if (socket) {
      socket.on('newChatMessage', (data) => {
        console.log('New message received:', data);

        const newMessage = {
          senderId: data.senderId,
          interviewID: data.interviewID,
          message: data.message,
          timestamp:data.timestamp,
        };
        
        setChatHistory((prevChatHistory) => [...prevChatHistory, newMessage]);
      });
    }

    return () => {
      if (socket) {
        socket.off('newChatMessage');
      }
    };
  }, [socket, selectedUser]);

  useEffect(() => {
    if (selectedUser) {
      fetchChatHistory(selectedUser);
    }
  }, [selectedUser]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    console.log("user in professional",user.interviewID._id);
    // Emit an event to join the chat room for the selected user
    if (socket) {
      socket.emit('joinChat', user.interviewID?._id);
    }

    // Fetch or load the chat history for the selected user (optional)
    fetchChatHistory(user);
  };


  const sendNewMessage = async () => {
    if (!selectedUser || !messageInput.trim() || !socket) {
      return;
    }

    try {
      // Emit an event to send a new message
      const sendChatPayload = {
        senderId: userData._id,
        interviewID: selectedUser.interviewID._id,
        message: messageInput.trim(),
        senderType:'professional',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await sendMessageToChat(sendChatPayload);

      // Emit an event to notify the other user about the new message
      if (socket) {
        const messageData = {
          senderId: userData._id,
          interviewID: selectedUser.interviewID?._id,
          message: messageInput.trim(),
          timestamp: moment().format('YYYY-MM-DD HH:mm:ss')
        };

        // Emit an event to send a message
        socket.emit('newChatMessage', messageData);
        console.log("messageData", messageData);
        // Update chat history in the state        

        if (chatContainerRef.current) {
          chatContainerRef.current.lastChild.scrollIntoView({ behavior: 'smooth' });
        }
        // Clear the input field after sending the message
        setMessageInput('');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };

  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  const fetchChatHistory = async (selectedUser) => {
    try {
      // Fetch or load chat history for the selected user from your backend
      const payloadPrivateChat = {
        interviewID:selectedUser?.interviewID?._id
      }
      console.log("response getPrivatChat payload", payloadPrivateChat);
      const response = await getChatMessages(payloadPrivateChat);
      console.log("response getPrivatChat", response);
      setChatHistory(response.data.messages); // Assuming your chat history is in the response

    } catch (error) {
      console.error('Error fetching chat history:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [chatHistory]);

  const timeAgo = (datetime) => {
    const createdAt = new Date(datetime);
    const currentTime = new Date();
    const timeDifference = Math.floor((currentTime - createdAt) / 60000); // Calculate the difference in minutes

    let timeAgo;
    if (timeDifference < 1) {
      timeAgo = 'just now';
    } else if (timeDifference < 60) {
      timeAgo = `${timeDifference} min${timeDifference > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 1440) {
      const hours = Math.floor(timeDifference / 60);
      timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(timeDifference / 1440);
      timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
    }

    return timeAgo;
  }


  return (
    <>
      <div className="">
        <div className="chat__wrapper overflow-hidden">
          {/* left sidebar */}
          <div className="chat__sidebar">
            {/* <div class="tyn-aside-search">
              <div class="form-group tyn-pill">
                <div class="form-control-wrap">
                  <div class="form-control-icon start">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                    </svg>
                  </div>
                  <input type="text" class="form-control form-control-solid" id="search" placeholder="Search contact / chat" />
                </div>
              </div>
            </div> */}
            {/* <div className="chats__header__icons"> */}
            <div className="chat-aside-list chat-aside-list2">
              <ul className="chat__convo__list__group">
                {managers && managers.map((item, index) => (
                  <li key={item.key || index} className="chat__convo__list"
                    onClick={() => handleUserClick(item)}
                  >
                    <div className="chat__convo">
                      <div className="chat__convo__image">
                        <img src={item?.managerID?.profileImage?.docs} alt={item.interviewID?.job_title} />
                      </div>
                      <div className="chat__convo__detail">
                        <div className="chat__convo__detail_row">
                          <h6>{item.interviewID?.job_title}</h6>
                          <p>{item?.interviewID?.company_name}</p>
                        </div>
                        {/* <div className="chat__convo__detail_row">
                              <p className="content">Hey, how's it going?</p>
                              <span className="meta">2 days</span>
                            </div> */}
                      </div>
                    </div>
                  </li>
                ))}



              </ul>
            </div>
            {/* <ul>
                <li className="single-chat"><IoChatbubbleEllipsesOutline /></li>
                <li className="group-chat"><MdGroups2 /></li>
              </ul> */}
            {/* </div> */}


          </div>
          {/* center portion */}
          <div className="chat__center__box">
          {selectedUser && (
            <div className="chat__header">
              <div className="chat__header__info">                                              
                    <div className="chat__convo__image">
                    <img className="me-3 ms-1" src={selectedUser?.managerID?.profileImage?.docs} alt={selectedUser.job_title} />
                    </div>
                    <h6 className="mb-0">{selectedUser?.interviewID?.job_title}</h6>                  
              </div>
            </div>
            )}
            <div className="chat__content__scrollable" ref={chatContainerRef}>
              <div className="chat-container">
                {chatHistory && chatHistory.map((message, index) => (
                  <div key={index} className={`message ${message.senderId === userData._id ? 'my-message' : 'incoming-message'}`}>
                    <div className="message-content">{message.message}</div>
                    <div className="message__time">{timeAgo(message.timestamp)}</div>
                  </div>
                ))}

              </div>

            </div>
            {selectedUser && (
            <div className="chat__sending__form">
              <div className="chat__sending__form__inner">
                <input
                  type="text"
                  placeholder="Enter your message here"
                  value={messageInput}                  
                  onChange={handleInputChange}
                />
                <button onClick={sendNewMessage}>
                  <FaRegPaperPlane />
                </button>
              </div>
            </div>
            )}
          </div>
          {selectedUser && (
            <div className="chat__info__box">
              <div className="chat__info__box__top">
                <div className="chat__info__box__top__image">
                  <img src={selectedUser?.managerID?.profileImage?.docs} />
                </div>
                <h6 className="my-2 fm fs-6">{selectedUser?.managerID?.firstName + ' ' + selectedUser?.managerID?.lastName}</h6>
                <p className="mb-2 mt-2 text-grey">{selectedUser?.interviewID?.company_name}</p>
                <p className="my-0 text-grey text-14">{selectedUser?.interviewID?.job_title}</p>

              </div>
              <div className="chat__info__details">
                {/* <div className="chat__info__details__info">
                <img src="/assets/images/location-icon.png" /> <span className="text-grey text-14">New York, USA</span>
              </div> */}
                {/* <div className="chat__info__details__info">
                  <img src="/assets/images/phone-icon.png" /> <span className="text-grey text-14">{selectedUser?.candidatePhoneNumber}</span>
                </div>
                <div className="chat__info__details__info">
                  <img src="/assets/images/email-icon.png" /> <span className="text-grey text-14">{selectedUser?.candidateEmail}</span>
                </div> */}
              </div>

            </div>
          )}




        </div>

      </div>
    </>
  );
};

export default ProfessionalChat;
