import React, { useEffect, useState } from "react";
import { getJobById, getJobs } from "../../../services/auth";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useParams } from "react-router-dom";
import { Country } from "../../../Utilities/Country";

const JobPublicDetail = () => {
  const [state, setState] = useState({
    loading2: false,
  });

  const [jobData, setJobData] = useState([]);

  const { loading2 } = state;
  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const fetchJobs = async (idparameter) => {
    handleChange("loading2", true);
    try {
      var payload = {
        _id: idparameter,
      };
      const response = await getJobById(payload);
      setJobData(response.data.data.find((obj) => obj._id === idparameter));

      const responseJobs = await getJobs();
      const responseData = responseJobs.data.data;
      const data = responseData.slice().reverse();
      if (data) {
        const relatedJobsData = data.filter(
          (obj) =>
            obj.department === response.data.data[0].department &&
            obj._id !== id
        );
        const relatedJobsLimit = relatedJobsData.slice(0, 2);
      }

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
      handleChange("loading2", false);
    }
  };

  useEffect(() => {
    fetchJobs(id);
  }, []);

  const applyForJob = async () => {
    navigate("/log-in");
  };

  return (
    <>
      <div className="px-4">
        <div className="">
          {loading2 ? (
            <Loader />
          ) : (
            <div>
              <div className="Dashboard__container">
                <div className="row mt-2">
                  <div className="col-lg-8 pe-xxl-2 pe-lg-5 order-lg-1 order-2">
                    <div className="jobs fm mb-5">
                      <div className="">
                        <div className="d-flex flex-column pb-4">
                          <div className="filter__data d-flex align-items-md-center">
                            <div
                              className={`expiring__body_detail_image ${
                                jobData && jobData["enterpriseData"]
                                  ? "company__image__detail"
                                  : ""
                              } jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                            >
                              <img
                                // src={jobData && jobData["enterpriseData"] ? jobData["enterpriseData.companyImage.docs"] : "/assets/images/expiring_icon.svg"}
                                src={
                                  jobData && jobData.enterpriseData
                                    ? jobData.enterpriseData.companyImage.docs
                                    : "/assets/images/expiring_icon.svg"
                                }
                                alt=""
                                className="me-0"
                              />
                            </div>
                            <div className="filter__content ">
                              <div className="d-flex justify-content-between">
                                <div className="filter__content__items">
                                  <h3 className=" mb-0 text-35 darkGrey fw-normal fm text-capitalize">
                                    {jobData && jobData["job_title"]}
                                  </h3>
                                  <div className="filter__content__para mt-0">
                                    <Link
                                      to={`/enterprise-profile/${jobData["enterpriseID"]}`}
                                      target={"_blank"}
                                      className="text-decoration-none"
                                    >
                                      <p className="pro__dash__jobs__para mb-0 fm darkBlue text-capitalize fm">
                                        {jobData && jobData["company"]}
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h3 className=" fm darkBlue text-14 fw-semibold mt-3 text-uppercase">
                          {jobData && jobData["company"]}
                        </h3>
                        <p className="fm darkGrey mb-2 text-14 desc__para">
                          {jobData && jobData["company_bio"]}
                        </p>

                        <h3 className=" fm darkBlue text-14 fw-semibold mt-4 text-uppercase">
                          Role Overview
                        </h3>
                        <p className="fm darkGrey mb-2 text-14 desc__para">
                          {jobData && jobData["job_role_text"]}
                        </p>

                        <h3 className=" fm darkBlue text-14 text-uppercase fw-semibold mt-4">
                          What We Offer You
                        </h3>
                        <p className="fm darkGrey mb-2 text-14 desc__para">
                          {jobData && jobData["company_benefits"]}
                        </p>

                        <h3 className=" fm darkBlue text-14 text-uppercase fw-semibold mt-4">
                          What you'll do
                        </h3>
                        <p className="fm darkGrey mb-2 text-14 desc__para">
                          {jobData && jobData["company_benefits"]}
                        </p>

                        <h3 className="fm darkBlue text-14 fw-semibold text-uppercase mt-4">
                          Skills You’ll Need
                        </h3>
                        <p className="fm darkGrey mb-2 text-14 desc__para">
                          {jobData && jobData["job_expectation"]}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 order-lg-2 order-1 mb-4">
                    <div>
                      <h4 className="fm fs-4 fw-semibold">Apply Now!</h4>
                      <p className="fm mt-0 darkGrey text-14">
                        One click and your application is in along with your
                        IndexScore
                      </p>
                      <button
                        className="btn--main fm w-100 radius8 mb-4"
                        onClick={applyForJob}
                      >
                        Apply
                      </button>
                    </div>

                    <div className="radius8 border pb-3">
                      <div className=" border-05 p-3">
                        <p className="fm fw-semibold">About the role</p>

                        <p className="grey fm text-12 mb-0 text-capitalize">
                          Posted
                        </p>
                        <p className="darkGrey fm mt-0 text-14">
                          {jobData && jobData["start_date"]}
                        </p>

                        <p className="grey fm text-12 mb-0 text-capitalize">
                          Deadline
                        </p>
                        <p className="darkGrey fm mt-0 text-14">
                          {jobData && jobData["end_date"]}
                        </p>

                        <p className="grey fm text-12 mb-0 text-capitalize">
                          Job type
                        </p>
                        <p className="darkGrey fm mt-0 text-14">
                          {jobData && jobData["employment_type"]}
                        </p>

                        <p className="grey fm text-12 mb-0 text-capitalize">
                          Department
                        </p>
                        <p className="darkGrey fm mt-0 text-14">
                          {jobData && jobData["department"]}
                        </p>

                        <p className="grey fm text-12 mb-0 text-capitalize">
                          Location
                        </p>
                        <p className="darkGrey fm mt-0 text-14">
                          {jobData &&
                            Country.find(
                              (country) =>
                                country.code === jobData["job_locations"]
                            )?.name}
                        </p>
                      </div>
                      <div className="p-3">
                        <p className="grey fm text-12 mb-2 text-capitalize">
                          Tags
                        </p>
                        <div className="d-flex flex-wrap gap-1">
                          {jobData &&
                            jobData["job_role_text_keywords"] &&
                            jobData["job_role_text_keywords"]
                              .slice(0, 10)
                              .map((item) =>
                                item.skill === "Hiring Multiple Candidates" ? (
                                  <button className="desc__hiring text-12 rounded-pill fm text-white fm d-flex align-items-center">
                                    <img
                                      className="pro__dash__jobs__icon"
                                      src="/assets/images/Dashboard/user-blue-icon.png"
                                    />
                                    Hiring multiple candidates
                                  </button>
                                ) : (
                                  <div
                                    // key={item.i}
                                    className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 "
                                  >
                                    <small className="filter__content__tag__content--small fm text-capitalize text-12">
                                      {item}
                                    </small>
                                  </div>
                                )
                              )}
                          <button className="desc__hiring text-12 rounded-pill text-white fm d-flex align-items-center">
                            <img
                              className="pro__dash__jobs__icon"
                              src="/assets/images/Dashboard/user-blue-icon.png"
                            />
                            Hiring multiple candidates
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobPublicDetail;
