import React from "react";
import "./style.css";
import { BsLinkedin, BsTwitterX } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";
import { TfiArrowCircleUp } from "react-icons/tfi";
import { useState, useEffect } from "react";
import { Button, Modal } from "antd";

const Footer = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [year, setYear] = useState();
  const getYear = () => setYear(new Date().getFullYear());
  useEffect(() => {
    getYear();
  }, []);
  return (
    <>
      {/* footer starts */}
      <div className="footer main-padding pt-5 pb-4">
        <div className="footer__container container-lg px-0">
          <TfiArrowCircleUp
            onClick={handleScrollToTop}
            className="footer__scrollToTop d-sm-none d-block"
          />
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-4 col-12 d-flex flex-column justify-content-between h-100">
              <div className="footer__text1 d-flex flex-column">
                <h6 className="text-white fm mt-0">Product</h6>
                <Link
                  to={"/enterprise"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Enterprises
                </Link>
                <Link
                  to={"/candidate"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Candidates
                </Link>
                <Link
                  to={"/pricing"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Pricing
                </Link>
                <Link
                  to={"/book-a-demo"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Demo
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-4 mt-sm-0 mt-4">
              <div className="footer__text1 d-flex flex-column">
                <h6 className="text-white fm mt-0">Company</h6>
                <Link
                  to={"/about-us"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  About Us
                </Link>

                <div
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1 pointer"
                  onClick={() => setModal2Open(true)}
                >
                  Newsletter
                </div>
                <Link
                  to={"/terms-of-use"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to={"/privacy-policy"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 mt-sm-0 mt-4">
              <div className="footer__text1 d-flex flex-column">
                <h6 className="text-white fm mt-0">Support</h6>
                <Link
                  to={"/contact-us"}
                  className=" footer__text1__link text-decoration-none mb-sm-2 mb-1"
                >
                  Contact Us
                </Link>
                <a
                  href="mailto:info@indexscore.com"
                  className=" footer__text1__link text-decoration-none"
                >
                  info@indexscore.com
                </a>
              </div>
              {/* <div className="footer__icons d-flex align-items-start mb-3">
                <a href="https://www.twitter.com" target="_blank" className="text-white">
                  <FaTwitterSquare className="twitter" />
                </a>
                <a href="https://www.twitter.com" target="_blank" className="mx-2 text-white ">
                  <BsLinkedin className="linkedin" />
                </a>
                <a href="https://www.twitter.com" target="_blank" className="text-white ">
                  <BsInstagram className="instagram" />
                </a>
              </div> */}
            </div>
            <div className="col-lg-3 col-md-4 offset-lg-3 col-md-3 col-sm-4 mt-md-0 mt-4 ">
              <div className="footer__text3 d-flex flex-column justify-content-between h-100">
                <div className="footer__icons d-flex align-items-start justify-content-md-end">
                  <a
                    href="www.linkedin.com"
                    target="_blank"
                    className=" text-white"
                  >
                    <BsLinkedin className="linkedin fs-6" />
                  </a>
                  <a
                    href="www.twitter.com"
                    target="_blank"
                    className="mx-2 text-white"
                  >
                    <BsTwitterX className="linkedin fs-6" />
                  </a>
                  <a
                    href="www.instagram.com"
                    target="_blank"
                    className="  text-white"
                  >
                    <BsInstagram className="instagram fs-6" />
                  </a>
                </div>
                <div className=" text-md-end text-start">
                  <p className="fm text-white-50 text-capitalize mb-0 text-14 mt-0 fw-500">
                    Europe
                  </p>
                  <p className="fm text-white-50 text-capitalize mb-0 text-14 mt-0">
                    Gertrude-Fröhlich-Sander
                  </p>
                  <p className="fm text-white-50 text-14 mb-0 mt-0">
                    STR. 4/603
                  </p>
                  <p className="fm text-white-50 text-14 mb-0 mt-0">
                    Icon Tower 1040 Wien
                  </p>
                  <p className="fm text-white-50 text-14 mb-0 mt-0">Austria</p>
                </div>
                <div className="footer__text2 d-flex flex-column justify-content-between h-100">
                  <div className="text-md-end text-start">
                    <p className="fm text-white-50 text-capitalize mb-0 text-14 mt-4 fw-500">
                      APAC
                    </p>
                    <p className="fm text-white-50 mb-0 text-14 mt-0">
                      61 Robinson Road
                    </p>
                    <p className="fm text-white-50 text-14 mb-0 mt-0">
                      068893 Singapore{" "}
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <p className="fm text-white-50 text-capitalize text-md-end text-start mb-0 text-14 fw-500">
                    North America
                  </p>
                  <p className="fm text-white-50 mb-0 text-14 text-md-end mt-0">
                    200 Park Ave 11th floor
                  </p>
                  <p className="fm text-white-50 text-14 mb-0 text-md-end mt-0">
                    New York, NY 10166
                  </p>
                  <p className="fm text-white-50 text-14 mb-0 text-md-end mt-0">
                    USA
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-3 col-sm-6 mb-sm-0 mb-2 order-sm-1 order-2">
              <div className="h-100 d-flex align-items-center">
                <p className=" text-14 mb-0 text-white-50">
                  &#169; Copyright {year}. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Newsletter"
        centered
        open={modal2Open}
        // onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={""}
      >
        <input
          required
          type="email"
          className={`form-control-md form-control mt-4`}
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Please enter your email address"
        />
        <div className="d-flex justify-content-end mt-2">
        <button className="btn--main px-3 py-2 rounded-2 fm darkGrey" onClick={() => setModal2Open(false)}>Send</button>
        </div>
      </Modal>
      {/* footer ends */}
    </>
  );
};

export default Footer;
