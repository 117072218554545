import React, { useEffect, useRef, useState, useContext } from "react";
import "./style.css";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
  getPaymentMethods,
  createEnterpriseSession,
  subscriptionInvoices,
} from "../../../../services/auth";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { Loader } from "rsuite";
import moment from "moment";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  usePDF,
  Image,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { Dropdown, Menu, Button } from "antd";
import EnterpriseInvoices from "../../../EnterpriseInvoice";
import UpgradeEnterprisePlan from "./UpgradeEnterprisePlan";

const table = [
  {
    id: 1,
    invoice: "Invoice #005",
    date: "Jan 1 2023",
    price: "$XXX",
    plan: "Plan",
  },
  {
    id: 2,
    invoice: "Invoice #005",
    date: "Jan 1 2023",
    price: "$XXX",
    plan: "Plan",
  },
  {
    id: 3,
    invoice: "Invoice #005",
    date: "Jan 1 2023",
    price: "$XXX",
    plan: "Plan",
  },
  {
    id: 4,
    invoice: "Invoice #005",
    date: "Jan 1 2023",
    price: "$XXX",
    plan: "Plan",
  },
  {
    id: 5,
    invoice: "Invoice #005",
    date: "Jan 1 2023",
    price: "$XXX",
    plan: "Plan",
  },
];

const Plan = () => {
  const navigate = useNavigate();
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  console.log("userData", userData);
  const [paymentCards, setPaymentCards] = useState([]);
  const [cardLoading, setCardLoading] = useState(false);
  const [invoicesLoading, setInvoicesLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getEnterprisePaymentMethods = async () => {
    try {
      setCardLoading(true);
      const paymentMethodPayload = {
        customerId: userData.customerId,
      };
      const response = await getPaymentMethods(paymentMethodPayload);
      setPaymentCards(response.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setCardLoading(false);
    }
  };

  const getEnterpriseInvoices = async () => {
    try {
      setInvoicesLoading(true);
      const paymentMethodPayload = {
        subscriptionId: userData.subscriptionId,
        limit: 10,
      };
      console.log("response", paymentMethodPayload);
      const response = await subscriptionInvoices(paymentMethodPayload);
      console.log("response", response);
      if (response.data) {
        setInvoices(response.data);
      } else {
        console.error("Invalid response data:", response.data);
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setInvoicesLoading(false);
    }
  };

  const updateEnterpriseBilling = async () => {
    try {
      // setCardLoading(true);
      const updateBillingPayload = {
        customerId: userData.customerId,
        redirectUrl: window.location.href,
      };
      const response = await createEnterpriseSession(updateBillingPayload);
      if (response.data.url) {
        window && window.open(response.data.url, "_self");
      }
      // setPaymentCards(response.data);
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      // setCardLoading(false);
    }
  };

  useEffect(() => {
    getEnterprisePaymentMethods();
    getEnterpriseInvoices();
  }, []);

  const upgradeRef = useRef(null);

  const navigateTo = () => {
    upgradeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className="Plan edash mt-4 pb-5">
        <div className="Dashboard__container__content">
          <h5 className="text-35 darkBlue ">Plans and billing</h5>
          <p className="darkGrey fm text-14 ">
            Manage your plan and billing details
          </p>
          <div className=" mt-4">
            <div className="row g-3">
              <div className="col-md-6 mb-4">
                <div className="Plan__card w-100 border rounded p-3 h-100 d-flex flex-column justify-content-between cursor-default">
                  <div>                    
                    <p className="darkGrey fm text-14 mt-1">
                      <strong className="darkBlue">{userData.subscription
                        ? userData.subscription.name
                        : "No Plan"}</strong> is your current plan
                    </p>
                    <h1 className="darkGrey fm mb-3 fs-1">{userData.subscription
                        ? userData.subscription.amountPerMonth
                        : "$****"}/MO</h1>
                  </div>
                  <button
                    className="Plan__card__button fm jobd__btn rounded py-1 mt-2"
                    onClick={() => {
                      if (
                        paymentCards &&
                        Array.isArray(paymentCards) &&
                        paymentCards.length > 0
                      ) {
                        navigateTo();
                      } else {
                        enqueueSnackbar("Please add payment card first", {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                          },
                        });
                      }
                    }}
                  >
                    Upgrade plan
                  </button>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="Plan__card w-100 border rounded p-3 h-100 cursor-default">
                  <div className="d-flex justify-content-between">
                    <p className="darkBlue fm mb-0 fw-semibold fs-6">
                      Payment method
                    </p>
                  </div>
                  <p className="darkGrey fm text-14 mt-1">
                    Change how you’d like to pay for your IndexScore plan
                  </p>
                  <div className="card__parent gap-2 h-auto">
                    {cardLoading ? (
                      <Loader />
                    ) : paymentCards &&
                      Array.isArray(paymentCards) &&
                      paymentCards.length > 0 ? (
                      paymentCards.map((cardDetails) => (
                        <div
                          key={cardDetails.id}
                          className="card__container border rounded-3 bg-white d-flex flex-sm-nowrap gap-3 flex-wrap-reverse align-items-start justify-content-between"
                        >
                          <div className="card__header d-flex align-items-start justify-content-start">
                            <div className="card__header__logo border rounded-2 d-flex align-items-center justify-content-center">
                              {cardDetails.vendor === "visa" ? (
                                <img
                                  src="/assets/images/visa-logo.png"
                                  alt=""
                                  className="chip img-fluid"
                                />
                              ) : (
                                <img
                                  src="/assets/images/Mastercard-Logo.png"
                                  alt=""
                                  className="chip img-fluid"
                                />
                              )}
                            </div>
                            <div className="ms-3">
                              <h5 className="number fm darkGrey text-14 fw-normal">
                                <span className="fm text-capitalize">
                                  {cardDetails.vendor}
                                </span>{" "}
                                ending in {cardDetails.last4}
                              </h5>
                              <h5 className="fm darkGrey text-14 fw-normal">
                                Expiry {cardDetails.exp_month}/
                                {cardDetails.exp_year}
                              </h5>

                              <p className="fm darkGrey text-14 mt-4 mb-0">
                                {/* zxy@industies.com */}
                                {userData && userData.emailAddress}
                              </p>
                            </div>
                          </div>

                          <button
                            onClick={() => {
                              updateEnterpriseBilling();
                            }}
                            className="bg-darkGrey text-white rounded px-2 Plan__card__container__button mb-auto ms-auto"
                          >
                            Edit
                          </button>
                        </div>
                      ))
                    ) : (
                      <>
                        <p className="fm darkGrey">No card added</p>
                        <button
                          onClick={() => {
                            updateEnterpriseBilling();
                          }}
                          className="bg-darkGrey text-white rounded px-2 Plan__card__container__button mb-auto ms-auto"
                        >
                          Add
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div ref={upgradeRef}><UpgradeEnterprisePlan /></div>
            <div className="pb-4">
              <div className="border rounded Plan__table">
                <div className="d-flex justify-content-between align-items-center pt-3 Plan__row p-3">
                  <div className="Plan__table__content">
                    <p className="darkBlue fm mb-0 fw-semibold fs-6">
                      Billing History
                    </p>
                    <p className="darkGrey fm text-14">
                      Download and view your previous plan receipts
                    </p>
                  </div>
                  {/* <button className="bg-darkGrey text-white fm jobd__btn rounded px-2 Plan__table__content__button">
                Download all
              </button> */}
                </div>
                {invoicesLoading ? (
                  <div className="p-2"><Loader /></div>
                ) : (
                  invoices.map((item) => (
                    <div
                      className="row border-top ms-0 py-2 Plan__row mx-0"
                      key={item.id}
                    >
                      <div className="col-4 d-flex align-items-center">
                        <p className="darkGrey mb-0 fm text-14">
                          {item.number}
                        </p>
                      </div>
                      <div className="col-2 text-center d-flex align-items-center">
                        <p className="darkGrey mb-0 fm text-14">
                          {moment(item.created * 1000).format("YYYY-MM-DD")}{" "}
                        </p>
                      </div>
                      <div className="col-2 d-flex align-items-center">
                        <p className="darkGrey mb-0 fm text-14">
                          <span className="text-uppercase">
                            {item.currency}
                          </span>{" "}
                          {item.amount_due}
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-end">
                        <div className="Plan__table__right d-flex align-items-center">
                          <PDFDownloadLink
                            className="text-dark fm rounded px-2 Plan__table__content__button2 text-decoration-none"
                            document={<EnterpriseInvoices invoiceData={item} />}
                            fileName="EnterpriseInvoice.pdf"
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? <Loader /> : "Download"
                            }
                          </PDFDownloadLink>
                          {/* EnterpriseInvoices */}
                          <Dropdown
                            placement="top"
                            arrow={{ pointAtCenter: true }}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="download"
                                  className="text-decoration-none delete-button darkGrey fm text-14 d-flex align-items-center"
                                >
                                  <PDFDownloadLink
                                    className="text-decoration-none"
                                    document={
                                      <EnterpriseInvoices invoiceData={item} />
                                    }
                                    fileName="EnterpriseInvoice.pdf"
                                  >
                                    {({ blob, url, loading, error }) =>
                                      loading ? <Loader /> : "Download"
                                    }
                                  </PDFDownloadLink>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <Button className="rounded dots d-flex align-items-center justify-content-center ms-2 px-1">
                              <BiDotsVerticalRounded className="text-dark fs-4" />
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Plan;
