import React,{useEffect} from "react";
import Form from "../pageComponents/SignIn/Form";

const SignIn = () => {
  useEffect(() => {
    document.body.classList.add('sign-in')
    return () => {
      document.body.classList.remove('sign-in')
    }
  }, [])
  return (
    <>
      <Form />
    </>
  );
};

export default SignIn;
