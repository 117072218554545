import React, { useEffect } from "react";
import Hero from "../pageComponents/home/banner/Hero.jsx";
import HSection1 from "../Launch/Home/HSection1.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import CookieConsent from "react-cookie-consent";
import HSection2 from "../Launch/Home/HSection2.jsx";
import Demo from "../Launch/Demo/Demo.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
import UHSection1 from "../UpdatedFrontend/HomePage/UHSection1.jsx";
import UHSection2 from "../UpdatedFrontend/HomePage/UHSection2.jsx";
import UHSection3 from "../UpdatedFrontend/HomePage/UHSection3.jsx";
import UHSection4 from "../UpdatedFrontend/HomePage/UHSection4.jsx";


const Home = () => {
  useEffect(() => {
    document.body.classList.add('home')
    return () => {
      document.body.classList.remove('home')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <UHSection1 />
      <UHSection2/>
      <UHSection3 />
      <UHSection4 />
      {/* <Hero /> */}
      {/* <HSection1 />
      <HSection2 />
      <Demo /> */}
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#A6DD50", fontSize: "13px", borderColor:"#A6DD50"  }}
        expires={150}
      >
        <h4>Cookies</h4>
        This website uses cookies to improve your experience and give us insights in how our website is used, we may also use them to provide promotional materials, communications and content. By using our website you agree to the use of cookies. Please read our Privacy Policy for further information and how to disable cookies.
      </CookieConsent>
    </>
  );
};

export default Home;
