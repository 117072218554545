import React from "react";
import "./style.css";
import { InlineWidget } from "react-calendly";
import environment from "../../environment";

const BookDemo = () => {
  const calendlyUrl = "https://calendly.com/" + environment.CALENDY_URL + "/30min";
  return (
    <>
      <div className="why main-padding top-padding demobook d-flex align-items-center">
        <div className="container-xl px-0">
          <div className="row pb-5">
            <div className="col-xxl-4 col-xl-4 d-flex flex-column justify-content-center align-items-xl-start align-items-center ">
              <h1 className="fs-1 main fw-light text-xl-start text-center pt-md-3 pb-sm-3">Book a demo now</h1>
              <p className="demo-text fm text-white fs-6 fw-light mb-0 pt-xl-0 pt-3 text-xl-start text-center pe-xxl-0 pe-xl-4">
                If you can give us just 45 minutes of your time we can show you
                how IndexScore can make your hiring process easier, fairer and
                an awful lot faster.
              </p>
              <p className="demo-text fm text-white fs-6 fw-light text-xl-start text-center mb-0 pe-xxl-0 pe-xl-4">
                We can then find out more about your specific needs and put
                together a custom proposal and quote to help your organization
                improve its HR function.
              </p>
            </div>
            <div className="col-xxl-8 col-xl-8  px-sm-0">
              <div className="calendly-box">
                <InlineWidget url={calendlyUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookDemo;
