import React, { useState, useEffect, useContext, useRef } from "react";
import { Switch } from "antd";
import "./style.css";
import Persona from "persona";
import environment from "../../../../environment/index";
import {
  updateProfessional,
  accurtateCreateCandidate,
  accurtateCreateOrder,
  getProfessionalById,
  kycVerifcation
} from "../../../../services/auth";
import { useSnackbar } from "notistack";
import { Loader, useToaster, Uploader, Message } from "rsuite";
import { MyContext } from "../../../../Utilities/MyContextProvider";
import { createOrder } from "../../../../services/criteria";

const KYCVerification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  console.log("userData",userData);
  const [accurateLoader, setAccurateLoader] = useState(false);
  const [criteriaLoader, setCriteriaLoader] = useState(false);

  const handlePersonaLink = () => {
    const currentUrl = window.location.href;
    const personaLink = `https://withpersona.com/verify?inquiry-template-id=itmpl_8puaBDx8HQ6MGwTf1AdbmnkZ&environment-id=env_njehZjmGfeDjy6wovb73DRpp&redirect-uri=${currentUrl}`;
    window.location.href = personaLink;
  };

  const [personaOptions, setPersonaOptions] = useState({
    templateId: "itmpl_g5dqDwogkc37Dos1GhiqkJvw",
  });
  const createClient = () => {
    const client = new Persona.Client({
      ...personaOptions,
      environment: environment.Persona_Environment,
      onLoad: (error) => {
        if (error) {
          console.error(
            `Failed with code: ${error.code} and message ${error.message}`
          );
        }

        client.open();
      },
      onStart: (inquiryId) => {
        console.log(`Started inquiry ${inquiryId}`);
      },
      onComplete: (inquiryId) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        //fetch(`/server-handler?inquiry-id=${inquiryId}`);
      },
      onEvent: (name, meta) => {
        switch (name) {
          case "start":
            console.log(`Received event: start`);
            break;
          default:
            console.log(
              `Received event: ${name} with meta: ${JSON.stringify(meta)}`
            );
        }
      },
    });

    window.exit = (force) =>
      client ? client.exit(force) : alert("Initialize client first");
  };

  const updateCriteriaHandler = async () => {
    setCriteriaLoader(true);
    const criteriaData = {
      packageId: { value: "JB-T6AVVPFQL" },
      orderId: { value: "OrderId12345" },
      candidate: {
        first: userData.firstName,
        last: userData.lastName,
        email: userData.emailAddress,
      },
      sendCandidateEmail: true,
      returnURL: {
        uri: "https://www.criteriacorp.com",
      },
    };
    createOrder(criteriaData).then(async (response) => {
      const payloadCriteria = {
        _id: userData._id,
        criteria_authorised: true,
        employeePsychometricEval: {
          orderId: "OrderId12345",
          candidate: {
            first: "Michael",
            last: "Scott",
            email: "michael@dm.com",
            date: "2021-03-01T12:00:00.000Z",
            eventId: "AAA1111ZZZZ",
            testTakerId: "1122333",
          },
          scores: {
            CCATRawScore: 91,
            CCATPercentile: 93,
            CCATInvalidScore: 0,
            CCATAlternateForm: 0,
            CCATSpatialPercentile: 32,
            CCATVerbalPercentile: 0,
            CCATMathPercentile: 17,
            EPPPercentMatch: 51,
            EPPAchievement: 12,
            EPPMotivation: 21,
            EPPCompetitiveness: 12,
            EPPManagerial: 12,
            EPPAssertiveness: 31,
            EPPExtroversion: 12,
            EPPCooperativeness: 35,
            EPPPatience: 54,
            EPPSelfConfidence: 38,
            EPPConscientiousness: 1,
            EPPOpenness: 3,
            EPPStability: 7,
            EPPStressTolerance: 7,
            EPPInconsistency: 0,
            EPPInvalid: false,
            Accounting: 60,
            AdminAsst: 49,
            Analyst: 17,
            BankTeller: 60,
            Collections: 34,
            CustomerService: 42,
            FrontDesk: 50,
            Manager: 42,
            MedicalAsst: 50,
            Production: 42,
            Programmer: 51,
            Sales: 33,
          },
          reportUrl: "https://www.criteriacorp.com/link_to_report",
          candidateReportUrl: "https://www.criteriacorp.com/link_to_report",
          metAllScoreRanges: "Yes",
        },
      };
      if(userData.ats_member){
        payloadCriteria.candidateId=userData.atsCandidate.id;
        payloadCriteria.enterpriseId=userData.atsCandidate.enterpriseID;
      }

      const responseCriteria = await kycVerifcation(payloadCriteria);
      if (responseCriteria) {
        updatedProfessionalData();
        enqueueSnackbar("Test sent to Your email Successfully!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setCriteriaLoader(false);
      }
    });
  };

  const updatedProfessionalData = async () => {
    const payLoadtoGetPro = {
      _id: userData._id,
    };

    const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
    const responseProfessionalData = respProfessionalByID.data.data;
    localStorage.setItem(
      "professionalData",
      JSON.stringify(respProfessionalByID.data.data)
    );
    setUserData(responseProfessionalData);
  };

  const handleVerification = async () => {
    if (!userData?.emailAddress) {
      enqueueSnackbar("You have not signed up yet", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
    setAccurateLoader(true);
    try {
      if (!userData?._id) {
        throw new Error("User ID is missing.");
      }

      var createcandidate = {
        firstName: "Bugs",
        lastName: "Bunny",
        middleName: null,
        suffix: "Jr",
        dateOfBirth: "1973-09-10",
        ssn: "444401223",
        email: userData.emailAddress,
        phone: "2063695205",
        address: "12345 Mockingbird",
        city: "Hollywood",
        region: "WA",
        country: "US",
        postalCode: "98117",
      };
      const responseCreateCandidate = await accurtateCreateCandidate(
        createcandidate
      );
      if (responseCreateCandidate.status !== 201) {
        throw new Error("Failed to create candidate.");
      }

      var createOrder = {
        jobLocation: {
          country: "US",
          region: "CA",
          region2: "Orange",
          city: "Irvine",
        },
        candidateId: responseCreateCandidate.data.data.data.id,
        packageType: "PKG_BASIC",
        workflow: "EXPRESS",
      };
      const responseCreateOrder = await accurtateCreateOrder(createOrder);
      if (responseCreateOrder.status !== 201) {
        throw new Error("Failed to create order.");
      }

      // Update professional
      var payloadData = {
        _id: userData._id,
        accurate_authorised: true,
        accurate: responseCreateOrder.data.data.data,
      };
      if(userData.ats_member){
        payloadData.candidateId=userData.atsCandidate.id;
        payloadData.enterpriseId=userData.atsCandidate.enterpriseID;
      }
      const responseProUpdate = await kycVerifcation(payloadData);

      enqueueSnackbar("Verification is in progress, you can proceed!", {
        variant: "pending",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      updatedProfessionalData();
      setAccurateLoader(false);
    } catch (error) {
      enqueueSnackbar(error.message || "An error occurred", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      setAccurateLoader(false);
    }
  };

  return (
    <>
      <div className="edash">
        <div className="">
          <div className="API pt-4">
            <div className="Dashboard__container__content">
              <h5 className="text-35 darkBlue ">KYC Verification</h5>
              <p className="darkGrey fm pb-4 text-14 w-100" style={{maxWidth: '600px'}}>
                KYC verification is the process of confirming the identity of
                customers or clients, typically for financial institutions,
                through documentation and authentication procedures.
              </p>
              <div className="row mb-3 g-3">
                <div
                  className={`col-md-4 ${
                    userData?.score ? "" : "disabled-box"
                  }`}
                >
                  <div className="api__boxes h-100">
                    <div className="api__box__header align-items-start">
                      <div>
                        <img src="/assets/images/api/persona.png" className="kyc__img" />{" "}
                      </div>
                      <div>
                        <button
                          onClick={handlePersonaLink}
                          className={`connect__button ${
                            userData.persona ? "connected" : "pending"
                          }`}
                        >
                          {userData.persona ? "Connected" : "Connect"}
                        </button>
                      </div>
                    </div>
                    <div className="api__box__body">
                      <h4 className="fm darkBlue fs-5 fw-semibold">Persona</h4>
                      <p className="mb-0 fm darkGrey text-14">
                        Part of the onboarding process of new employees is ID
                        Verification. We ensure employers have what they need to
                        enable effective onboarding of future employees. Persona
                        helps us achieve this objective by authenticating
                        government issued IDs; real-time.
                      </p>
                    </div>
                  </div>
                  {!userData?.score && (
                    <div className="disabled-message">
                      This feature will be enabled once payment is received from
                      the enterprise.
                    </div>
                  )}
                </div>

                <div
                  className={`col-md-4 ${
                    userData?.score ? "" : "disabled-box"
                  }`}
                >
                  <div className="api__boxes h-100">
                    <div className="api__box__header align-items-start">
                      <div>
                        <img src="/assets/images/api/criteria.png" className="kyc__img" />{" "}
                      </div>
                      <div>
                        <button
                          className={`connect__button ${
                            userData.criteria_authorised
                              ? "connected"
                              : "pending"
                          }`}
                          onClick={updateCriteriaHandler}
                        >
                          {userData.criteria_authorised ? (
                            "Connected"
                          ) : criteriaLoader ? (
                            <Loader />
                          ) : (
                            "Connect"
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="api__box__body">
                      <h4 className="fm darkBlue fs-5 fw-semibold">Criteria</h4>
                      <p className="mb-0 fm darkGrey text-14">
                        Psychometric assessment is part of your score not your
                        score. It helps employers understand your capabilities,
                        which enables them to position you for success.
                      </p>
                    </div>
                  </div>
                  {!userData?.score && (
                    <div className="disabled-message">
                      This feature will be enabled once payment is received from
                      the enterprise.
                    </div>
                  )}
                </div>
                <div
                  className={`col-md-4 ${
                    userData?.score ? "" : "disabled-box"
                  }`}
                >
                  <div className="api__boxes h-100">
                    <div className="api__box__header align-items-start">
                      <div>
                        <img src="/assets/images/api/accurate.png" className="kyc__img" />{" "}
                      </div>
                      <div>
                        <button
                          disabled={userData.accurate_authorised}
                          onClick={handleVerification}
                          className={`connect__button ${
                            userData.accurate_authorised
                              ? "connected"
                              : "pending"
                          }`}
                        >
                          {userData.accurate_authorised ? (
                            "Connected"
                          ) : accurateLoader ? (
                            <Loader />
                          ) : (
                            "Connect"
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="api__box__body">
                      <h4 className="fm darkBlue fs-5 fw-semibold">Accurate</h4>
                      <p className="mb-0 fm darkGrey text-14">
                        All job applicants have to clear background checks.
                        IndexScore completes this process for future employers
                        so candidates are ready to go in days as opposed to
                        weeks!
                      </p>
                    </div>
                  </div>
                  {!userData?.score && (
                    <div className="disabled-message">
                      This feature will be enabled once payment is received from
                      the enterprise.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KYCVerification;
