import React, { useState, useContext } from "react";
import "react-calendar/dist/Calendar.css";
import "./style.css";
import { useSnackbar } from "notistack";
import { HiArrowLeft } from "react-icons/hi";
import { createContract, uploadOfferLetter } from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Input, Space, Tag, Tooltip, Upload, Button } from "antd";

const SendContract = (props) => {
  const [value, onChange] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const myContext = useContext(MyContext);
  const [uploadError, setUploadError] = useState(null);

  const myData = myContext;

  const [state, setState] = useState({
    company_name: "",
    job_description: "",
    offer_date: "",
    offer_expire_date: "",
    status: "",
    required: false,
    files: null,
    loading: false,
  });
  const {
    loading,
    required,
    company_name,
    job_description,
    offer_date,
    offer_expire_date,
    status,
    files,
  } = state;
  const disabled = !job_description || !offer_date || !offer_expire_date;

  const handleSubmit = async () => {    
    try {
      if (disabled) {
        handleChange("required", true);
        return;
      }

      handleChange("required", false);
      handleChange("loading", true);

      if (!files) {
        // Handle the case where files are not uploaded yet
        setUploadError("Please upload files before submitting.");

        handleChange("loading", false);
        return;
      }

      setUploadError(null);

      const payload = {
        job: props.data.jobId,
        candidateID: props.data.id,
        managerID: localStorage.getItem("companyID"),
        enterpriseData:localStorage.getItem("companyID"),
        contract_title: props.data.jobTitle,
        company_name: props.data.jobCompany,
        invite_note: job_description,
        offer_date,
        offer_expire_date,
        status: "offered",
        applicationID: props.data.applicantID,
        interviewersID: props.data.interviewers,
      };      
      let res;
      res = await createContract(payload);      
      if (res.status === 200) {
        if (files) {          
          const formData = new FormData();
          formData.append("contractID", res.data.data._id);
          formData.append("status", "offered");
          formData.append("jobID", props.data.jobId);
          formData.append("files", files[0].blobFile);
          var resp = await uploadOfferLetter(formData);          
          enqueueSnackbar(`Contract send Successfully`, {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          navigate("/hr/dashboard/offers");
        }
      }
    } catch (error) {
      handleChange("loading", false);
      console.log("errssssssss", error);
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  const handleChange = (key, value) => {    
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const Profile = () => {
    props.confirm(false);
    props.profile(true);
    props.meeting(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const Time = () => {
    props.confirm(false);
    props.profile(false);
    props.meeting(false);
    props.time(true);
    props.detail(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="Dashboard__container ">
      <style>
        {`
          .offer-title{
            color:#14a800;
          }
        `}
      </style>
      <div className="">
        <div className="container-dash pt-2">
          <div className="d-flex justify-content-between">
            <div
              className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center"
              onClick={Profile}
            >
              <HiArrowLeft className="" />
            </div>
          </div>
        </div>

        <div className="container-dash pb-4 pt-4 d-flex flex-column align-items-center justify-content-between">
          <div className="d-flex flex-column  meeting__container ">
            {/* <span className="meeting__container__step fm">
            Step <span className="fw-semibold">1/3</span>
          </span> */}
            <h3 className="fm darkGrey fw-semibold fs-3  text-center">
              Send Contract to {props.data.name}
            </h3>

            <h4 className="mb-4 offer-title fs-6 text-center">
              {props.data.jobTitle} ({props.data.jobCompany})
            </h4>
            {/* <p><strong>Company: </strong>{props.data.jobCompany}</p> */}

            <div className="row">
              <div className="offset-md-2 col-md-8 col-sm-9">
                <p className="mt-2 mb-2">Invite Note: </p>
                <textarea
                  className="form-control fs-6 form-control-lg input mb-2 w-100"
                  placeholder="Write Invite note to candidate"
                  id="text"
                  rows={3}
                  cols={20}
                  onChange={(value) => {
                    
                    handleChange("job_description", value.target.value);                    
                  }}
                />
              </div>
              <div className="offset-md-2  col-md-8 col-sm-9">
                <p className="mt-2 mb-2">Offer Date:</p>
                <input
                  type="datetime-local"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Start date"
                  id="sector1"
                  onChange={(value) =>
                    handleChange("offer_date", value.target.value)
                  }
                />
                {required && !offer_date && (
                  <div>
                    <small className="error__message ps-1">
                      Date & Time are required!
                    </small>
                  </div>
                )}
              </div>
              <div className="offset-md-2 col-md-8 col-sm-9">
                <p className="mt-2 mb-2">Offer Expiry Date:</p>
                <input
                  type="datetime-local"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="End date"
                  id="sector1"
                  onChange={(value) =>
                    handleChange("offer_expire_date", value.target.value)
                  }
                />
                {required && !offer_expire_date && (
                  <div>
                    <small className="error__message ps-1">
                      Date & Time are required!
                    </small>
                  </div>
                )}
              </div>
              <div className="offset-md-2 col-md-8 col-sm-9 mb-4">
                <Uploader
                  listType="picture-text"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  action="//jsonplaceholder.typicode.com/posts/"
                  onChange={(value) => handleChange("files", value)}
                  renderFileInfo={(file, fileElement) => {
                    return (
                      <div>
                        <span>File Name: {file.name}</span>
                        <p style={{ marginLeft: 13 }}>
                          File type: {file.blobFile.type}
                        </p>
                      </div>
                    );
                  }}
                  className="pt-4"
                >
                  <div className="d-flex flex-sm-nowrap flex-wrap">
                    <Button
                      className="Get__right__upload text-14 Role__upload mb-2 Get__left__bottom__info__content__para"
                      style={{ maxWidth: "180px" }}
                    >
                      Upload Offer Letter
                    </Button>
                    <small className="Get__right__upload__small ps-3">
                      File formats: Word or PDF
                    </small>
                  </div>
                </Uploader>
                {uploadError && <p style={{ color: "red" }}>{uploadError}</p>}
              </div>

              <div className="offset-md-2 col-md-8 col-sm-9">
                <button onClick={handleSubmit} className="invite-button ">
                  {loading ? <Loader /> : "Send Offer"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendContract;
