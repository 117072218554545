import React from "react";
import { Switch } from "antd";

const Notification = () => {
  return (
    <div className="Notif pt-lg-4 pb-5">
      <div className="Dashboard__container pe-2">
        <div className="Dashboard__container__content">
          <div className="container-fluid px-0">
            <div className="row border-bottom pb-4">
              <div className="col-md-5 pb-md-0 pb-2">
                <p className="darkBlue fm mb-0 fw-semibold fs-6">
                  Email notifications
                </p>
                <p className="fm darkGrey mb-2 mt-0 text-14">
                  Get emails to find out what’s going on, you can turn these off
                  as needed.
                </p>
              </div>
              <div className="col-md-7">
                <div className="d-flex align-items-start pb-3">
                  <Switch disabled  className="mt-1" />
                  <div className="ps-2 w-100 h-100">
                    <p className="DSidebar__item fs-6  mb-0 black fw-semibold">
                      News and updates
                    </p>
                    <p className="fm darkGrey mb-0 mt-0 text-14">
                      News about changes and feature updates
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start pb-3">
                  <Switch disabled  className="mt-1" />
                  <div className="ps-2 w-100 h-100">
                    <p className="DSidebar__item fs-6 mb-0 black fw-semibold">
                      New applicants
                    </p>
                    <p className="fm darkGrey mb-0 mt-0 text-14">
                      Find out when you’ve had a new application submitted to
                      your active job listings
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start pb-3">
                  <Switch disabled className="mt-1" />
                  <div className="ps-2 w-100 h-100">
                    <p className="DSidebar__item mb-0 black fw-semibold">
                    Reminders
                    </p>
                    <p className="fm darkGrey mb-0 mt-0 text-14">
                    Notifications reminding you on expiring job listings or application dates
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-5">
                <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                  Push notifications
                </p>
                <p className="fm darkGrey mb-2 mt-0 text-14">
                  Get push notifications in-app to find out what’s happening
                  live or while you have been away.
                </p>
              </div>
              <div className="col-md-7 pt-md-4 pt-2">
                <div className="d-flex align-items-start pb-3">
                  <Switch disabled  className="mt-1" />
                  <div className="ps-2 w-100 h-100">
                    <p className="DSidebar__item mb-0 fs-6 black fw-semibold">
                      New applicants
                    </p>
                    <p className="fm darkGrey mb-0 mt-0 text-14">
                      Find out when you’ve had a new application submitted to
                      your active job listings
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start pb-3">
                  <Switch disabled className="mt-1" />
                  <div className="ps-2 w-100 h-100">
                    <p className="DSidebar__item fs-6 mb-0 black fw-semibold">
                    Reminders
                    </p>
                    <p className="fm darkGrey mb-0 mt-0 text-14">
                    Notifications reminding you on expiring job listings or application dates
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
