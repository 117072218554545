import React, { useState,useEffect } from "react";
import "./style.css";
import YDetail from "./YourDetail/YDetail";
import API from "./API/API";
import ProDashboardScreen from "../DashboardScreen/DashboardScreen";
import Plan from "./Plan/Plan";
import KYCVerification from "./KYCVerification/KYCVerification";
import { useNavigate } from "react-router-dom";

const PAccount = (props) => {
  const navigate = useNavigate();
  const [overview, setOverview] = useState(true);
  const [profile, setProfile] = useState(false);
  const [plan, setPlan] = useState(false);
  const [api, setApi] = useState(false);
  const [kycVerification,setKycVerification] = useState(false);

  const func_overview = () =>{
    setOverview(true)
    setProfile(false)
    setPlan(false)
    setKycVerification(false);
    navigate("/candidate/dashboard");
  }
  const func_profile = () =>{

    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParameter = urlSearchParams.get("code");
    const stateParameter = urlSearchParams.get("state");


    setOverview(false)
    setProfile(true)
    setPlan(false)
    setKycVerification(false);
    if (props.name === "profile" && codeParameter && stateParameter) {
      navigate("/candidate/dashboard/account" + window.location.search);
    } else {
      navigate("/candidate/dashboard/account");
    }
  }
  const func_plan = () =>{
    setOverview(false)
    setProfile(false)
    setPlan(true)
    setKycVerification(false);
    navigate("/candidate/dashboard/plan");
  }
  const func_kyc = () =>{
    setOverview(false)
    setProfile(false)
    setPlan(false)
    setKycVerification(true);
    navigate("/candidate/dashboard/kyc-verification");
  }


  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParameter = urlSearchParams.get("code");
    const stateParameter = urlSearchParams.get("state");
  
    if (!codeParameter) {     
      if (props.name === "dashboard") {
        func_overview();
      } else if (props.name === "profile") {
        func_profile();
      }
      else if (props.name === "plan") {
        func_plan();
      }
      else if (props.name === "kyc") {
        func_kyc();
      }
    }    
  }, [props.name]);
  

  return (
    <div className="DAccount">
      <div className="DAccount__top d-lg-block d-none mt-lg-0 mt-4 ">
      <div className="position-relative">
          <div className="DAccount__top2"></div>
        </div>
        <div className="container-dash py-2 ">
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${overview ? "fw-normal": "fw-light"}`} onClick={func_overview}>Overview</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${profile ? "fw-normal": "fw-light"}`} onClick={func_profile}>Account</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${plan ? "fw-normal": "fw-light"}`} onClick={func_plan}>Plan</button>
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${kycVerification ? "fw-normal": "fw-light"}`} onClick={func_kyc}>KYC Verification</button>
          {/* <button className={`DAccount__btn me-sm-4 me-2 transition fm ${api ? "fw-normal": "fw-light"}`} onClick={func_api}>API</button> */}
        </div>
      </div>
      <div className="container-dash">
        {overview && <ProDashboardScreen />}
        {/* {overview && <YDetail />} */}
        {(profile || props.name=='profile' ) && <YDetail />} 
        {plan && <Plan/>} 
        {kycVerification && <KYCVerification/>} 
        {/* {(api || props.name=='api') && <API />}  */}
      </div>
    </div>
  );
};

export default PAccount;
