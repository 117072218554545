import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Dropdown,
  Checkbox,
  Slider,
  Menu,
  Button,
  Table,
  Tooltip,
  Pagination,
  Radio,
  Modal,
} from "antd";
import { IoIosArrowDown } from "react-icons/io";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import {
  BsFilterCircle,
  BsSearch,
  BsFilter,
  BsEye,
  BsTrash,
  BsThreeDotsVertical,
} from "react-icons/bs";
import config from '../../../services/apisConfig';
import {
  getProfessionalsList,
  downloadAttachment,
  getContractsById,
  acceptContract,
  deleteContract,
} from "../../../services/auth";
import moment from "moment";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { Country } from "../../../Utilities/Country";
import { useMediaQuery } from "@material-ui/core";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";

const cardData_fav = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 5,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_short = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_interview = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
const cardData_select = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
  },
];
var favourites = [];

var rejectedCand = [];

var allcandidates = [];
var fetchedCandidatesList = [];

const team = [
  {
    id: 1,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 2,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 3,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 4,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
  {
    id: 5,
    name: "Ben Smith",
    gender: "(He/Him)",
    role: "Administrator",
  },
];

const HrOffersTab = (props) => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(false);
  const [candidates, setCandidates] = useState(true);
  const [meeting, setMeeting] = useState(false);
  const [time, setTime] = useState(false);
  const [detail, setDetail] = useState(false);
  const [all, setAll] = useState(true);
  const [fav, setFav] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [filter, setFilter] = useState(false);
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const inputRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [relevance, setRelevance] = useState(true);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [dropfav, setDropfav] = useState(true);
  const [droplist, setDroplist] = useState(true);
  const [dropinterview, setDropinterview] = useState(true);
  const isMediumScreen = useMediaQuery("(max-width:767px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [contracts, setContracts] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const { confirm } = Modal;
  const [sorting, setSorting] = useState({
    field: "id",
    order: "desc",
  });
  const [selectedValue, setSelectedValue] = useState("all");
  const [sortingOption, setSortingOption] = useState("1");

  const navigate = useNavigate();

  // console.log("props from jobs enterprise" + JSON.stringify());

  const dropfav_func = () => {
    if (isMediumScreen) {
      setDropfav(!dropfav);
    }
  };
  const droplist_func = () => {
    if (isMediumScreen) {
      setDroplist(!droplist);
    }
  };
  const dropinterview_func = () => {
    if (isMediumScreen) {
      setDropinterview(!dropinterview);
    }
  };

  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (flag) => {
    setOpen1(flag);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", filterClick);
    return () => {
      window.removeEventListener("mousedown", filterClick);
    };
  });
  const filterDrop = () => {
    setFilter(!filter);
    setOpen1(!open1);
    console.log("open:" + open1 + "filter:" + filter);
  };
  function filterClick(event) {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilter(false);
    }
  }
  useEffect(() => {
    filterRef.current = document.getElementById("filter_btn");
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    }
    if (isSmallScreen) {
      setDropfav(true);
      setDroplist(false);
      setDropinterview(false);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);
  // var allcandidates;
  var myObject;
  var myObjectEnterprise;
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    // fetchJobs();
    fetchContracts();

    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "accepted-contract.pdf");
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const fetchContracts = async () => {
    setLoading(true);
    try {
      
      var payloadPro = {
        _id: userData?.companyID,
      };
      

      const contractsList = await getContractsById(payloadPro);
      
      setContracts(contractsList.data.data);
      setLoading(false);
      
      return contractsList.data.data; // Assuming the data returned by the API is the candidate object
    } catch (error) {
      console.log(`Error fetching candidate`, error);
      return null; // Or handle the error as per your requirements
    }
  };

  const contractHandler = async (contractId, status) => {
    
    try {
      setLoading(true);
      const payload = {
        _id: contractId,
        status: status,
      };

      const responseAccepted = await acceptContract(payload);

      if (responseAccepted.data.statusCode === 1) {
        enqueueSnackbar(`Contract sent successfully`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchContracts();
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed, such as showing an error message to the user.
      // You can also rethrow the error if necessary.
    }
  };

  const showPromiseConfirm = (contractId) => {
    confirm({
      title: "Delete Contract",
      content: "Are you sure you want to delete the contract",
      onOk() {
        const payload = {
          contractId: contractId,
        };
        
        deleteContract(payload)
          .then((response) => {
            
            if (response.data.statusCode == 1) {
              enqueueSnackbar(`Contract Deleted Successfully`, {
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "right",
                },
              });
              fetchContracts();
            }
          })
          .catch((error) => {
            console.error("Error deleting contract:", error);
          });
      },
      onCancel() {},
    });
  };

  const handleDeleteClick = (contractId, event) => {
    showPromiseConfirm(contractId);
  };

  const handleSorting = (field) => {
    
    setSorting((prevSorting) => {
      if (prevSorting.field === field) {
        // Toggle between ascending and descending order if the same field is clicked
        return {
          field,
          order: prevSorting.order === "asc" ? "desc" : "asc",
        };
      } else {
        // If a different field is clicked, sort in ascending order by default
        return {
          field,
          order: "asc",
        };
      }
    });
  };

  // console.log("professionals data" + JSON.stringify(professionals[0]));
  const fetchedCandidatesList = contracts.map((person) => ({
    _id: person._id,
    name: person.candidateID.firstName + " " + person.candidateID.lastName,
    candidatePhoto:person.candidateID && person.candidateID.profileImage.docs
    ? person.candidateID.profileImage.docs
    : "",
    offerDate: moment(person.offer_date).format("DD.MM.YYYY"),
    offerExpiryDate: moment(person.offer_expire_date).format("DD.MM.YYYY"),
    contractTitle: person.contract_title,
    date:person.createdAt,
    acceptedLetter: person.accepted_Letter
      ? person.accepted_Letter.Key
      : "Pending",
    status: person.status,

  }));

  // console.log(fetchedCandidatesList);
  
  const filteredCandidates = fetchedCandidatesList.filter((candidate) => {
    const candidateData = [
      candidate.name.toLowerCase(),
      candidate.offerDate,
      candidate.offerExpiryDate.toLowerCase(),
      candidate.contractTitle.toLowerCase(),
      candidate.status.toLowerCase(),
    ];

    // Check if any of the fields contain the searchQuery
    return candidateData.some((field) =>
      field.includes(searchQuery.toLowerCase())
    );
  });

  const sortedCandidates = [...filteredCandidates].sort((a, b) => {
    const sortingNumber = parseInt(sortingOption, 10); // Convert to number
    if (sortingNumber === 1) {
        // Sort by most recent
        return new Date(b.date) - new Date(a.date);
    } else if (sortingNumber === 2) {
        // Sort by oldest
        return new Date(a.date) - new Date(b.date);
    }
    // Default sorting
    return 0;
});

  const [loading, setLoading] = useState(false);

  // const handleChange = (key, value) => {
  //   setState((pre) => ({ ...pre, [key]: value }));
  // };

  const allCandidates = () => {
    setAll(true);
    setFav(false);
    setRejected(false);
  };
  const favCandidates = () => {
    setAll(false);
    setFav(true);
    setRejected(false);
  };
  const rejectedCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedIndex(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  if (profile) {
    document.body.style.background = "#F8F8F8";
  } else document.body.style.background = "#fff";

  const items = [
    {
      label: (
        <div className="d-flex flex-column">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox
            className="fm darkGrey"
            onChange={() => handleSorting("offerDate")}
          >
            Offer Extended
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            onChange={() => handleSorting("offerExpiryDate")}
          >
            Offer Expiration
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            onChange={() => handleSorting("contractTitle")}
          >
            The Role
          </Checkbox>
          <Checkbox
            className="fm darkGrey"
            onChange={() => handleSorting("status")}
          >
            The Status
          </Checkbox>
        </div>
      ),
      key: "0",
    },
  ];
  const tableColumns = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({sortedCandidates ? sortedCandidates.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = typeof a.status === "string" ? a.status.toLowerCase() : "";
        const statusB = typeof b.status === "string" ? b.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
      render: (status) => (        
        <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${status}`}
      >
        {status}
      </span>
      ),
    },
    {
      title: "Offer Extended",
      dataIndex: "extended",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),      
    },
    {
      title: "Offer Expiration",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) => moment(a.expiration).unix() - moment(b.expiration).unix(),
      
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
      render: (role) => (
        <span className="text-14 darkGrey text-capitalize fm">{role}</span>
      ),
    },
    // {
    //   title: "Location",
    //   dataIndex: "country",
    //   key: "country",
    //   width: 150,
    //   sorter: (a, b) => {
    //     const locationA = (a.location || "").toLowerCase();
    //     const locationB = (b.location || "").toLowerCase();
    //     return locationA.localeCompare(locationB);
    //   },
    // },
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 55,
      fixed: "right",
    },
  ];

  const formattedOffers = sortedCandidates.map((item, index) => ({
    key: item.id,
    name: (
      <div
        className="d-flex align-items-center me-xl-0 me-2"
        ref={buttonRef}
        onClick={() => handleClick(index)}
      >
        <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${item.candidatePhoto ? 'candidate__photo' : ''}`}>
          <img
            src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
            alt=""
            className="candidates__avatar--img img-fluid"
          />
        </div>
        <div className="Experts__name fm mb-0 ms-2">
          <h6 className="mb-0 me-1 text-14">{item.name} </h6>
        </div>
      </div>
    ),

    status:item.status,
    extended: moment(item.offerDate).format("L"),
    expiration: moment(item.offerExpiryDate).format("L"),
    role: item.contractTitle,
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item.acceptedLetter == "Pending" ? (
          "Pending"
        ) : (
          <Tooltip title="Download Contract" color="#B2DA66">
            <img
              className="ms-0"
              onClick={() => handleDownload(item.acceptedLetter)}
              src="/assets/images/pdf_icon.png"
            />
          </Tooltip>
        )}
      </div>
    ),
    country: (
      <span className="job__type text-13 rounded-pill">{item.country}</span>
    ),

    action: (
      <>
        {" "}
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {item.status === "accepted" && (
                <Menu.Item
                  key="start"
                  className="text-decoration-none darkGrey fm d-flex align-items-center"
                  onClick={() => contractHandler(item._id, "started")}
                >
                  Start
                </Menu.Item>
              )}
              <Menu.Item
                key="start"
                onClick={(event) => handleDeleteClick(item._id, event)}
                className="text-decoration-none darkGrey fm d-flex align-items-center"
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));


  const tableColumnsOffered = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({sortedCandidates ? sortedCandidates.filter((item) => item.status === "offered").length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = typeof a.status === "string" ? a.status.toLowerCase() : "";
        const statusB = typeof b.status === "string" ? b.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
      render: (status) => (        
        <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${status}`}
      >
        {status}
      </span>
      ),
    },
    {
      title: "Offer Extended",
      dataIndex: "extended",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),      
    },
    {
      title: "Offer Expiration",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) => moment(a.expiration).unix() - moment(b.expiration).unix(),
      
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
      render: (role) => (
        <span className="text-14 darkGrey text-capitalize fm">{role}</span>
      ),
    },    
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 55,
      fixed: "right",
    },
  ];

  const formattedOfferedOffers = sortedCandidates
  .filter((item) => item.status === "offered")
  .map((item, index) => ({
    key: item.id,
    name: (
      <div
        className="d-flex align-items-center me-xl-0 me-2"
        ref={buttonRef}
        onClick={() => handleClick(index)}
      >
        <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${item.candidatePhoto ? 'candidate__photo' : ''}`}>
          <img
            src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
            alt=""
            className="candidates__avatar--img img-fluid"
          />
        </div>
        <div className="Experts__name fm mb-0 ms-2">
          <h6 className="mb-0 me-1 text-14">{item.name} </h6>
        </div>
      </div>
    ),

    status:item.status,
    extended: moment(item.offerDate).format("L"),
    expiration: moment(item.offerExpiryDate).format("L"),
    role: item.contractTitle,
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item.acceptedLetter == "Pending" ? (
          "Pending"
        ) : (
          <Tooltip title="Download Contract" color="#B2DA66">
            <img
              className="ms-0"
              onClick={() => handleDownload(item.acceptedLetter)}
              src="/assets/images/pdf_icon.png"
            />
          </Tooltip>
        )}
      </div>
    ),
    country: (
      <span className="job__type text-13 rounded-pill">{item.country}</span>
    ),

    action: (
      <>
        {" "}
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {item.status === "accepted" && (
                <Menu.Item
                  key="start"
                  className="text-decoration-none darkGrey fm d-flex align-items-center"
                  onClick={() => contractHandler(item._id, "started")}
                >
                  Start
                </Menu.Item>
              )}
              <Menu.Item
                key="start"
                onClick={(event) => handleDeleteClick(item._id, event)}
                className="text-decoration-none darkGrey fm d-flex align-items-center"
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));

  
  const tableColumnsAccepted = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({sortedCandidates ? sortedCandidates.filter((item) => item.status === "accepted").length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = typeof a.status === "string" ? a.status.toLowerCase() : "";
        const statusB = typeof b.status === "string" ? b.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
      render: (status) => (        
        <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${status}`}
      >
        {status}
      </span>
      ),
    },
    {
      title: "Offer Extended",
      dataIndex: "extended",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),      
    },
    {
      title: "Offer Expiration",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) => moment(a.expiration).unix() - moment(b.expiration).unix(),
      
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
      render: (role) => (
        <span className="text-14 darkGrey text-capitalize fm">{role}</span>
      ),
    },    
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 55,
      fixed: "right",
    },
  ];

  const formattedAcceptedOffers = sortedCandidates
  .filter((item) => item.status === "accepted")
  .map((item, index) => ({
    key: item.id,
    name: (
      <div
        className="d-flex align-items-center me-xl-0 me-2"
        ref={buttonRef}
        onClick={() => handleClick(index)}
      >
        <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${item.candidatePhoto ? 'candidate__photo' :  ''}`}>
          <img
            src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
            alt=""
            className="candidates__avatar--img img-fluid"
          />
        </div>
        <div className="Experts__name fm mb-0 ms-2">
          <h6 className="mb-0 me-1 text-14">{item.name} </h6>
        </div>
      </div>
    ),

    status:item.status,
    extended: moment(item.offerDate).format("L"),
    expiration: moment(item.offerExpiryDate).format("L"),
    role: item.contractTitle,
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item.acceptedLetter == "Pending" ? (
          "Pending"
        ) : (
          <Tooltip title="Download Contract" color="#B2DA66">
            <img
              className="ms-0"
              onClick={() => handleDownload(item.acceptedLetter)}
              src="/assets/images/pdf_icon.png"
            />
          </Tooltip>
        )}
      </div>
    ),
    country: (
      <span className="job__type text-13 rounded-pill">{item.country}</span>
    ),

    action: (
      <>
        {" "}
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {item.status === "accepted" && (
                <Menu.Item
                  key="start"
                  className="text-decoration-none darkGrey fm d-flex align-items-center"
                  onClick={() => contractHandler(item._id, "started")}
                >
                  Start
                </Menu.Item>
              )}
              <Menu.Item
                key="start"
                onClick={(event) => handleDeleteClick(item._id, event)}
                className="text-decoration-none darkGrey fm d-flex align-items-center"
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));
  
  const tableColumnsRejected = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({sortedCandidates ? sortedCandidates.filter((item) => item.status === "rejected").length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = typeof a.status === "string" ? a.status.toLowerCase() : "";
        const statusB = typeof b.status === "string" ? b.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
      render: (status) => (        
        <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${status}`}
      >
        {status}
      </span>
      ),
    },
    {
      title: "Offer Extended",
      dataIndex: "extended",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),      
    },
    {
      title: "Offer Expiration",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) => moment(a.expiration).unix() - moment(b.expiration).unix(),
      
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
      render: (role) => (
        <span className="text-14 darkGrey text-capitalize fm">{role}</span>
      ),
    },    
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 55,
      fixed: "right",
    },
  ];

  const formattedRejectedOffers = sortedCandidates
  .filter((item) => item.status === "rejected")
  .map((item, index) => ({
    key: item.id,
    name: (
      <div
        className="d-flex align-items-center me-xl-0 me-2"
        ref={buttonRef}
        onClick={() => handleClick(index)}
      >
        <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${item.candidatePhoto ? 'candidate__photo' :  ''}`}>
          <img
            src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
            alt=""
            className="candidates__avatar--img img-fluid"
          />
        </div>
        <div className="Experts__name fm mb-0 ms-2">
          <h6 className="mb-0 me-1 text-14">{item.name} </h6>
        </div>
      </div>
    ),

    status:item.status,
    extended: moment(item.offerDate).format("L"),
    expiration: moment(item.offerExpiryDate).format("L"),
    role: item.contractTitle,
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item.acceptedLetter == "Pending" ? (
          "Pending"
        ) : (
          <Tooltip title="Download Contract" color="#B2DA66">
            <img
              className="ms-0"
              onClick={() => handleDownload(item.acceptedLetter)}
              src="/assets/images/pdf_icon.png"
            />
          </Tooltip>
        )}
      </div>
    ),
    country: (
      <span className="job__type text-13 rounded-pill">{item.country}</span>
    ),

    action: (
      <>
        {" "}
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {item.status === "accepted" && (
                <Menu.Item
                  key="start"
                  className="text-decoration-none darkGrey fm d-flex align-items-center"
                  onClick={() => contractHandler(item._id, "started")}
                >
                  Start
                </Menu.Item>
              )}
              <Menu.Item
                key="start"
                onClick={(event) => handleDeleteClick(item._id, event)}
                className="text-decoration-none darkGrey fm d-flex align-items-center"
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));


  const tableColumnsStarted = [
    {
      title: (
        <>
          Applicant{" "}
          <span className="fw-normal">
            ({sortedCandidates ? sortedCandidates.filter((item) => item.status === "started").length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = typeof a.status === "string" ? a.status.toLowerCase() : "";
        const statusB = typeof b.status === "string" ? b.status.toLowerCase() : "";
        return statusA.localeCompare(statusB);
      },
      render: (status) => (        
        <span
        className={`text-12 fm darkGrey text-capitalize rounded-pill status-${status}`}
      >
        {status}
      </span>
      ),
    },
    {
      title: "Offer Extended",
      dataIndex: "extended",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.extended).unix() - moment(b.extended).unix(),      
    },
    {
      title: "Offer Expiration",
      dataIndex: "expiration",
      key: "4",
      width: 130,
      sorter: (a, b) => moment(a.expiration).unix() - moment(b.expiration).unix(),
      
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = typeof a.role === "string" ? a.role.toLowerCase() : "";
        const roleB = typeof b.role === "string" ? b.role.toLowerCase() : "";
        return roleA.localeCompare(roleB);
      },
      render: (role) => (
        <span className="text-14 darkGrey text-capitalize fm">{role}</span>
      ),
    },    
    {
      title: "Contract",
      dataIndex: "contract",
      width: 100,
      key: "date",
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 55,
      fixed: "right",
    },
  ];

  const formattedStartedOffers = sortedCandidates
  .filter((item) => item.status === "started")
  .map((item, index) => ({
    key: item.id,
    name: (
      <div
        className="d-flex align-items-center me-xl-0 me-2"
        ref={buttonRef}
        onClick={() => handleClick(index)}
      >
        <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-center ${item.candidatePhoto ? 'candidate__photo' :  ''} `}>
          <img
            src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
            alt=""
            className="candidates__avatar--img img-fluid"
          />
        </div>
        <div className="Experts__name fm mb-0 ms-2">
          <h6 className="mb-0 me-1 text-14">{item.name} </h6>
        </div>
      </div>
    ),

    status:item.status,
    extended: moment(item.offerDate).format("L"),
    expiration: moment(item.offerExpiryDate).format("L"),
    role: item.contractTitle,
    contract: (
      <div className="contract-icon fm darkGrey text-14 mx-xl-auto pointer">
        {item.acceptedLetter == "Pending" ? (
          "Pending"
        ) : (
          <Tooltip title="Download Contract" color="#B2DA66">
            <img
              className="ms-0"
              onClick={() => handleDownload(item.acceptedLetter)}
              src="/assets/images/pdf_icon.png"
            />
          </Tooltip>
        )}
      </div>
    ),
    country: (
      <span className="job__type text-13 rounded-pill">{item.country}</span>
    ),

    action: (
      <>
        {" "}
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {item.status === "accepted" && (
                <Menu.Item
                  key="start"
                  className="text-decoration-none darkGrey fm d-flex align-items-center"
                  onClick={() => contractHandler(item._id, "started")}
                >
                  Start
                </Menu.Item>
              )}
              <Menu.Item
                key="start"
                onClick={(event) => handleDeleteClick(item._id, event)}
                className="text-decoration-none darkGrey fm d-flex align-items-center"
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
  }));
  

   

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedOffers.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };
  
  const handleSortingChange = (key) => {
    // console.log("Sorting option changed to:", key.key);
    setSortingOption(key.key);
  };
  const sortingMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];
  return (
    <div className={` ${profile ? "bg-lightgrey" : ""}`}>
      <style>
        {`:where(.css-dev-only-do-not-override-1e3x2xa).ant-avatar >img {object-fit: contain;
            width: 65%;
            margin: auto;}          
          
            .contract-icon img{
              width:34px;
              margin-left: -18px;
            }
            
        }`}
      </style>
      {candidates && (
        <div className=" edash">
          <div className="container-dash pb-4 mt-4">
            <div className=" align-items-center mb-3">
              {/* <div className="jobs__content__hiring enterprise d-flex align-items-center justify-content-between mt-4  p-3 flex-lg-nowrap flex-wrap">
                <div>
                  <h1 className="jobs__content__hiring__heading fw-semibold fm">
                    Let’s Manage Your Offers
                  </h1>
                  <p className="jobs__content__hiring__para  fm">
                    We have streamlined the offer process to enable effective
                    engagement
                  </p>
                </div>
              </div> */}
              <div className="my-4 candidates__opt d-xl-flex justify-content-between align-items-xl-center align-items-start w-100">
                <div className="d-xl-flex align-items-center">
                  <div className="candidates jobspage d-flex me-2">
                    <Radio.Group
                      defaultValue="all"
                      className="jobd__btn"
                      size={"large"}
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                    >
                      <Radio.Button value="all" className="h-100">
                        All
                      </Radio.Button>
                      <Radio.Button value="offered" className="h-100">
                        Offerd
                      </Radio.Button>
                      <Radio.Button value="accepted" className="h-100">
                        Accepted
                      </Radio.Button>
                      <Radio.Button value="rejected" className="h-100">
                        Rejected
                      </Radio.Button>
                      <Radio.Button value="started" className="h-100">
                        Started
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className=" d-xl-flex d-none justify-content-sm-start justify-content-between">
                    <Dropdown
                      menu={{
                        items: sortingMenus,
                        onClick: handleSortingChange,
                      }}
                      className="sorting__menus jobd__btn me-2"
                      placement="bottom"
                      arrow={{
                        pointAtCenter: true,
                      }}
                    >
                      <Button>
                        <>
                          <span className="fm">Sort By: </span>
                          <strong className="fm">
                            {
                              sortingMenus.find(
                                (menu) => menu.key === sortingOption
                              )?.label
                            }{" "}
                            <IoIosArrowDown />
                          </strong>
                        </>
                      </Button>
                    </Dropdown>
                    {/* <Dropdown
                      ref={filterRef}
                      menu={{
                        items,
                        onClick: handleMenuClick,
                      }}
                      onOpenChange={handleOpenChange}
                      open={open1}
                      trigger={["click"]}
                      overlayClassName={"dropdown__filter"}
                    >
                      <button
                        className="candidates__filter fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                        onClick={filterDrop}
                        style={{
                          border: filter ? "0.5px solid #5F89FF" : "",
                          color: filter ? "#5F89FF" : "",
                        }}
                        id="filter_btn"
                        ref={filterRef}
                      >
                        <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                        Filter
                      </button>
                    </Dropdown> */}
                  </div>
                </div>
                <div className="d-flex flex-md-nowrap flex-wrap-reverse justify-content-md-between candidates__filter3 mt-xl-0 mt-2">
                  <div className=" d-xl-none d-flex justify-content-sm-start offers__search justify-content-between mt-md-0 mt-2">
                    <Dropdown
                      menu={{
                        items: sortingMenus,
                        onClick: handleSortingChange,
                      }}
                      className="sorting__menus jobd__btn me-2"
                      placement="bottom"
                      arrow={{
                        pointAtCenter: true,
                      }}
                    >
                      <Button>
                        <>
                          <span className="fm">Sort By: </span>
                          <strong className="fm">
                            {
                              sortingMenus.find(
                                (menu) => menu.key === sortingOption
                              )?.label
                            }{" "}
                            <IoIosArrowDown />
                          </strong>
                        </>
                      </Button>
                    </Dropdown>
                    <Dropdown
                      ref={filterRef}
                      menu={{
                        items,
                        onClick: handleMenuClick,
                      }}
                      onOpenChange={handleOpenChange}
                      open={open1}
                      trigger={["click"]}
                      overlayClassName={"dropdown__filter"}
                    >
                      <button
                        className="candidates__filter fm px-2 d-flex text-14 align-items-center fw-500 transition me-2"
                        onClick={filterDrop}
                        style={{
                          border: filter ? "0.5px solid #5F89FF" : "",
                          color: filter ? "#5F89FF" : "",
                        }}
                        id="filter_btn"
                        ref={filterRef}
                      >
                        <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                        Filter
                      </button>
                    </Dropdown>
                  </div>
                  <div className="d-sm-flex offers__search">
                    <div className="candidates__searchbox d-flex align-items-center">
                      <BsSearch className="candidates__searchbox__icon mx-2" />
                      <input
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        type="text"
                        className="fm candidates__searchbox__input text-14"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {selectedValue === "all" && (
              <div className="">
                <div className="d-md-block d-none lazy_table">
                  {loading ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <div>
                      <div className="table__outer position-relative table__outer__pro  rounded-3">
                        <Table
                          className="dash__table"
                          columns={tableColumns}
                          dataSource={formattedOffers}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {sortedCandidates.length > 0 && (
                        <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                          <button className="fm text-12 table__bottom__btn2">
                            Scroll to load more{" "}
                            <HiOutlineArrowDownCircle className="fs-6" />
                          </button>
                        </div>
                      )} */}
                    </div>
                  )}
                </div>
                <div className="design__revamp d-md-none py-2 px-1 rounded-2">
                  {loading ? (
                    <div className="align-items-center justify-content-center p-3">
                      <Loader size="md" content="loading" />
                    </div>
                  ) : contracts.length === 0 ? (
                    <div className="d-flex justify-content-center py-3 fm">
                      No record exists
                    </div>
                  ) : (
                    sortedCandidates.map((item, index) => (
                      <>
                        <div
                          className={`row border enterprise Experts__row ${
                            item.cat === "fav"
                              ? "candidates__fav"
                              : "candidates__all"
                          } mt-2 pb-xl-2 pt-2 pb-3  my-3 transition pointer`}
                          key={item.id}
                        >
                          <div className="col-xl-3 col-10 order-1 pb-xl-0 pb-2">
                            <div className="d-flex align-items-center w-100">
                              <div className="d-flex justify-content-between align-items-start w-100">
                                <div
                                  className="d-flex align-items-center me-xl-0 me-2"
                                  ref={buttonRef}
                                  onClick={() => handleClick(index)}
                                >
                                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                    <img
                                      src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
                                      alt=""
                                      className="candidates__avatar--img img-fluid"
                                    />
                                  </div>
                                  <div className="Experts__name fm mb-0 ms-2">
                                    <h6 className="mb-0 me-1">{item.name} </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-1 col-sm-6 d-flex align-items-xl-center order-xl-3 order-4 mb-xl-0 mb-3">
                            <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                              Offer Extended:
                            </span>
                            <span className="text-14 Experts__date fm">
                              {item.offerDate}
                            </span>
                          </div>
                          <div className="col-xl-2  col-sm-6 order-xl-3 order-5 d-flex align-items-xl-center d-flex mb-xl-0 mb-3">
                            <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                              Offer Expiration:
                            </span>
                            <span className="text-14 enterprise__experts Experts__date fm ps-xl-5">
                              {item.offerExpiryDate}
                            </span>
                          </div>
                          <div className="col-xl-2 col-sm-6 order-xl-4 order-3 d-flex align-items-xl-center  mb-xl-0 mb-3">
                            <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                              The Role:
                            </span>
                            <span className="text-14 enterprise__experts Experts__date fm">
                              {item.contractTitle}
                            </span>
                          </div>
                          <div className="col-xl-1 col-sm-6 order-xl-5 d-flex align-items-xl-center align-items-sm-start align-items-center order-3 mb-xl-0 mb-3">
                            <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                              Contract:
                            </span>
                            <div className="contract-icon mx-xl-auto">
                              {item.acceptedLetter == "Pending" ? (
                                "Pending"
                              ) : (
                                <Tooltip
                                  title="Download Contract"
                                  color="#B2DA66"
                                >
                                  <img
                                    className="ms-0"
                                    onClick={() =>
                                      handleDownload(item.acceptedLetter)
                                    }
                                    src="/assets/images/pdf_icon.png"
                                  />
                                </Tooltip>
                              )}
                            </div>
                          </div>
                          <div
                            className={`col-xl-2  col-12 order-xl-5 order-last order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-start justify-content-end text-capitalize `}
                          >
                            <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                              Status:
                            </span>
                            <span
                              className={`text-12 fm rounded-pill status-${item.status}`}
                            >
                              {item.status}
                            </span>
                          </div>
                          {item.status === "accepted" && (
                            <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-xl-center align-items-start pt-xl-0 pt-2 justify-content-xl-center justify-content-end">
                              <img
                                src={`/assets/images/Dashboard/settings_more_${
                                  item.cat === "fav" ? "selected" : "unselected"
                                }.svg`}
                                alt=""
                                className="img-fluid candidates__icon2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              />
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <button
                                    class="dropdown-item"
                                    onClick={() =>
                                      contractHandler(item._id, "started")
                                    }
                                    type="button"
                                  >
                                    Start
                                  </button>
                                </li>
                                {/* <li>
                            <button
                              class="dropdown-item"
                              onClick={() => contractHandler(item._id, 'decline')}
                              type="button"
                            >
                              Decline
                            </button>
                          </li> */}
                              </ul>
                            </div>
                          )}
                        </div>

                        {/* {sortedCandidates.length > 0 && (
                          <div className="d-flex justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({sortedCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                      </>
                    ))
                  )}
                </div>
              </div>
            )}
            {selectedValue === "offered" && (
              <div className="">
                <div className="d-md-block d-none lazy_table">
                  {loading ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <div>
                      <div className="table__outer position-relative table__outer__pro  rounded-3">
                        <Table
                          className="dash__table"
                          columns={tableColumnsOffered}
                          dataSource={formattedOfferedOffers}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {sortedCandidates.filter(
                        (item) => item.status === "offered"
                      ).length > 0 && (
                        <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                          <button className="fm text-12 table__bottom__btn1">
                            Load all offers ({sortedCandidates.length})
                          </button>
                          <button className="fm text-12 table__bottom__btn2">
                            Scroll to load more{" "}
                            <HiOutlineArrowDownCircle className="fs-6" />
                          </button>
                        </div>
                      )} */}
                    </div>
                  )}
                </div>
                <div className="design__revamp d-md-none py-2 px-1 rounded-2">
                  {loading ? (
                    <div className="align-items-center justify-content-center p-3">
                      <Loader size="md" content="loading" />
                    </div>
                  ) : contracts.length === 0 ? (
                    <div className="d-flex justify-content-center py-3 fm">
                      No record exists
                    </div>
                  ) : (
                    sortedCandidates
                      .filter((item) => item.status === "offered")
                      .map((item, index) => (
                        <>
                          <div
                            className={`row border enterprise Experts__row ${
                              item.cat === "fav"
                                ? "candidates__fav"
                                : "candidates__all"
                            } mt-2 pb-xl-2 pt-2 pb-3  my-3 transition pointer`}
                            key={item.id}
                          >
                            <div className="col-xl-3 col-10 order-1 pb-xl-0 pb-2">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-between align-items-start w-100">
                                  <div
                                    className="d-flex align-items-center me-xl-0 me-2"
                                    ref={buttonRef}
                                    onClick={() => handleClick(index)}
                                  >
                                    <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                      <img
                                        src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
                                        alt=""
                                        className="candidates__avatar--img img-fluid"
                                      />
                                    </div>
                                    <div className="Experts__name fm mb-0 ms-2">
                                      <h6 className="mb-0 me-1">
                                        {item.name}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-1 col-sm-6 d-flex align-items-xl-center order-xl-3 order-4 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Extended:
                              </span>
                              <span className="text-14 Experts__date fm">
                                {item.offerDate}
                              </span>
                            </div>
                            <div className="col-xl-2  col-sm-6 order-xl-3 order-5 d-flex align-items-xl-center d-flex mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Expiration:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm ps-xl-5">
                                {item.offerExpiryDate}
                              </span>
                            </div>
                            <div className="col-xl-2 col-sm-6 order-xl-4 order-3 d-flex align-items-xl-center  mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                The Role:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm">
                                {item.contractTitle}
                              </span>
                            </div>
                            <div className="col-xl-1 col-sm-6 order-xl-5 d-flex align-items-xl-center align-items-sm-start align-items-center order-3 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Contract:
                              </span>
                              <div className="contract-icon mx-xl-auto">
                                {item.acceptedLetter == "Pending" ? (
                                  "Pending"
                                ) : (
                                  <Tooltip
                                    title="Download Contract"
                                    color="#B2DA66"
                                  >
                                    <img
                                      className="ms-0"
                                      onClick={() =>
                                        handleDownload(item.acceptedLetter)
                                      }
                                      src="/assets/images/pdf_icon.png"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div
                              className={`col-xl-2  col-12 order-xl-5 order-last order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-start justify-content-end text-capitalize `}
                            >
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Status:
                              </span>
                              <span
                                className={`text-12 fm rounded-pill status-${item.status}`}
                              >
                                {item.status}
                              </span>
                            </div>
                            {item.status === "accepted" && (
                              <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-xl-center align-items-start pt-xl-0 pt-2 justify-content-xl-center justify-content-end">
                                <img
                                  src={`/assets/images/Dashboard/settings_more_${
                                    item.cat === "fav"
                                      ? "selected"
                                      : "unselected"
                                  }.svg`}
                                  alt=""
                                  className="img-fluid candidates__icon2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      onClick={() =>
                                        contractHandler(item._id, "started")
                                      }
                                      type="button"
                                    >
                                      Start
                                    </button>
                                  </li>
                                  {/* <li>
                            <button
                              class="dropdown-item"
                              onClick={() => contractHandler(item._id, 'decline')}
                              type="button"
                            >
                              Decline
                            </button>
                          </li> */}
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* {sortedCandidates.length > 0 && (
                            <div className="d-flex justify-content-center pt-4 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates ({sortedCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )} */}
                        </>
                      ))
                  )}
                </div>
              </div>
            )}
            {selectedValue === "accepted" && (
              <div className="">
                <div className="d-md-block d-none lazy_table">
                  {loading ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <div>
                      <div className="table__outer position-relative table__outer__pro  rounded-3">
                        <Table
                          className="dash__table"
                          columns={tableColumnsAccepted}
                          dataSource={formattedAcceptedOffers}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {sortedCandidates.length > 0 && (
                          <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all offers ({sortedCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                    </div>
                  )}
                </div>
                <div className="design__revamp d-md-none py-2 px-1 rounded-2">
                  {loading ? (
                    <div className="align-items-center justify-content-center p-3">
                      <Loader size="md" content="loading" />
                    </div>
                  ) : contracts.length === 0 ? (
                    <div className="d-flex justify-content-center py-3 fm">
                      No record exists
                    </div>
                  ) : (
                    sortedCandidates
                      .filter((item) => item.status === "accepted")
                      .map((item, index) => (
                        <>
                          <div
                            className={`row border enterprise Experts__row ${
                              item.cat === "fav"
                                ? "candidates__fav"
                                : "candidates__all"
                            } mt-2 pb-xl-2 pt-2 pb-3  my-3 transition pointer`}
                            key={item.id}
                          >
                            <div className="col-xl-3 col-10 order-1 pb-xl-0 pb-2">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-between align-items-start w-100">
                                  <div
                                    className="d-flex align-items-center me-xl-0 me-2"
                                    ref={buttonRef}
                                    onClick={() => handleClick(index)}
                                  >
                                    <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                      <img
                                        src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
                                        alt=""
                                        className="candidates__avatar--img img-fluid"
                                      />
                                    </div>
                                    <div className="Experts__name fm mb-0 ms-2">
                                      <h6 className="mb-0 me-1">
                                        {item.name}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-1 col-sm-6 d-flex align-items-xl-center order-xl-3 order-4 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Extended:
                              </span>
                              <span className="text-14 Experts__date fm">
                                {item.offerDate}
                              </span>
                            </div>
                            <div className="col-xl-2  col-sm-6 order-xl-3 order-5 d-flex align-items-xl-center d-flex mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Expiration:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm ps-xl-5">
                                {item.offerExpiryDate}
                              </span>
                            </div>
                            <div className="col-xl-2 col-sm-6 order-xl-4 order-3 d-flex align-items-xl-center  mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                The Role:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm">
                                {item.contractTitle}
                              </span>
                            </div>
                            <div className="col-xl-1 col-sm-6 order-xl-5 d-flex align-items-xl-center align-items-sm-start align-items-center order-3 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Contract:
                              </span>
                              <div className="contract-icon mx-xl-auto">
                                {item.acceptedLetter == "Pending" ? (
                                  "Pending"
                                ) : (
                                  <Tooltip
                                    title="Download Contract"
                                    color="#B2DA66"
                                  >
                                    <img
                                      className="ms-0"
                                      onClick={() =>
                                        handleDownload(item.acceptedLetter)
                                      }
                                      src="/assets/images/pdf_icon.png"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div
                              className={`col-xl-2  col-12 order-xl-5 order-last order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-start justify-content-end text-capitalize `}
                            >
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Status:
                              </span>
                              <span
                                className={`text-12 fm rounded-pill status-${item.status}`}
                              >
                                {item.status}
                              </span>
                            </div>
                            {item.status === "accepted" && (
                              <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-xl-center align-items-start pt-xl-0 pt-2 justify-content-xl-center justify-content-end">
                                <img
                                  src={`/assets/images/Dashboard/settings_more_${
                                    item.cat === "fav"
                                      ? "selected"
                                      : "unselected"
                                  }.svg`}
                                  alt=""
                                  className="img-fluid candidates__icon2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      onClick={() =>
                                        contractHandler(item._id, "started")
                                      }
                                      type="button"
                                    >
                                      Start
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* {sortedCandidates.length > 0 && (
                            <div className="d-flex justify-content-center pt-4 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates ({sortedCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )} */}
                        </>
                      ))
                  )}
                </div>
              </div>
            )}

            {selectedValue === "rejected" && (
              <div className="">
                <div className="d-md-block d-none lazy_table">
                  {loading ? (
                    <Loader size="md" className="" content="loading" />
                  ) : (
                    <div>
                      <div className="table__outer position-relative table__outer__pro  rounded-3">
                        <Table
                          className="dash__table"
                          columns={tableColumnsRejected}
                          dataSource={formattedRejectedOffers}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {sortedCandidates.length > 0 && (
                          <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all offers ({sortedCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                    </div>
                  )}
                </div>
                <div className="design__revamp d-md-none py-2 px-1 rounded-2">
                  {loading ? (
                    <div className="align-items-center justify-content-center p-3">
                      <Loader size="md" content="loading" />
                    </div>
                  ) : contracts.length === 0 ? (
                    <div className="d-flex justify-content-center py-3 fm">
                      No record exists
                    </div>
                  ) : (
                    sortedCandidates
                      .filter((item) => item.status === "rejected")
                      .map((item, index) => (
                        <>
                          <div
                            className={`row border enterprise Experts__row ${
                              item.cat === "fav"
                                ? "candidates__fav"
                                : "candidates__all"
                            } mt-2 pb-xl-2 pt-2 pb-3  my-3 transition pointer`}
                            key={item.id}
                          >
                            <div className="col-xl-3 col-10 order-1 pb-xl-0 pb-2">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-between align-items-start w-100">
                                  <div
                                    className="d-flex align-items-center me-xl-0 me-2"
                                    ref={buttonRef}
                                    onClick={() => handleClick(index)}
                                  >
                                    <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                      <img
                                        src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
                                        alt=""
                                        className="candidates__avatar--img img-fluid"
                                      />
                                    </div>
                                    <div className="Experts__name fm mb-0 ms-2">
                                      <h6 className="mb-0 me-1">
                                        {item.name}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-1 col-sm-6 d-flex align-items-xl-center order-xl-3 order-4 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Extended:
                              </span>
                              <span className="text-14 Experts__date fm">
                                {item.offerDate}
                              </span>
                            </div>
                            <div className="col-xl-2  col-sm-6 order-xl-3 order-5 d-flex align-items-xl-center d-flex mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Expiration:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm ps-xl-5">
                                {item.offerExpiryDate}
                              </span>
                            </div>
                            <div className="col-xl-2 col-sm-6 order-xl-4 order-3 d-flex align-items-xl-center  mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                The Role:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm">
                                {item.contractTitle}
                              </span>
                            </div>
                            <div className="col-xl-1 col-sm-6 order-xl-5 d-flex align-items-xl-center align-items-sm-start align-items-center order-3 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Contract:
                              </span>
                              <div className="contract-icon mx-xl-auto">
                                {item.acceptedLetter == "Pending" ? (
                                  "Pending"
                                ) : (
                                  <Tooltip
                                    title="Download Contract"
                                    color="#B2DA66"
                                  >
                                    <img
                                      className="ms-0"
                                      onClick={() =>
                                        handleDownload(item.acceptedLetter)
                                      }
                                      src="/assets/images/pdf_icon.png"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div
                              className={`col-xl-2  col-12 order-xl-5 order-last order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-start justify-content-end text-capitalize `}
                            >
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Status:
                              </span>
                              <span
                                className={`text-12 fm rounded-pill status-${item.status}`}
                              >
                                {item.status}
                              </span>
                            </div>
                            {item.status === "accepted" && (
                              <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-xl-center align-items-start pt-xl-0 pt-2 justify-content-xl-center justify-content-end">
                                <img
                                  src={`/assets/images/Dashboard/settings_more_${
                                    item.cat === "fav"
                                      ? "selected"
                                      : "unselected"
                                  }.svg`}
                                  alt=""
                                  className="img-fluid candidates__icon2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      onClick={() =>
                                        contractHandler(item._id, "started")
                                      }
                                      type="button"
                                    >
                                      Start
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* {sortedCandidates.length > 0 && (
                            <div className="d-flex justify-content-center pt-4 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates ({sortedCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )} */}
                        </>
                      ))
                  )}
                </div>
              </div>
            )}

            {selectedValue === "started" && (
              <div className="">
                <div className="d-md-block d-none lazy_table">
                  {loading ? (
                    <Loader size="md" cflassName="" content="loading" />
                  ) : (
                    <div>
                      <div className="table__outer position-relative table__outer__pro  rounded-3">
                        <Table
                          className="dash__table"
                          columns={tableColumnsStarted}
                          dataSource={formattedStartedOffers}
                          pagination={false}
                          scroll={{
                            x: 992,
                          }}
                        />
                      </div>
                      {/* {sortedCandidates.length > 0 && (
                          <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all offers ({sortedCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )} */}
                    </div>
                  )}
                </div>
                <div className="design__revamp d-md-none py-2 px-1 rounded-2">
                  {loading ? (
                    <div className="align-items-center justify-content-center p-3">
                      <Loader size="md" content="loading" />
                    </div>
                  ) : contracts.length === 0 ? (
                    <div className="d-flex justify-content-center py-3 fm">
                      No record exists
                    </div>
                  ) : (
                    sortedCandidates
                      .filter((item) => item.status === "started")
                      .map((item, index) => (
                        <>
                          <div
                            className={`row border enterprise Experts__row ${
                              item.cat === "fav"
                                ? "candidates__fav"
                                : "candidates__all"
                            } mt-2 pb-xl-2 pt-2 pb-3  my-3 transition pointer`}
                            key={item.id}
                          >
                            <div className="col-xl-3 col-10 order-1 pb-xl-0 pb-2">
                              <div className="d-flex align-items-center w-100">
                                <div className="d-flex justify-content-between align-items-start w-100">
                                  <div
                                    className="d-flex align-items-center me-xl-0 me-2"
                                    ref={buttonRef}
                                    onClick={() => handleClick(index)}
                                  >
                                    <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                      <img
                                        src={item.candidatePhoto ? item.candidatePhoto :  `/assets/images/userAvatar.svg`}
                                        alt=""
                                        className="candidates__avatar--img img-fluid"
                                      />
                                    </div>
                                    <div className="Experts__name fm mb-0 ms-2">
                                      <h6 className="mb-0 me-1">
                                        {item.name}{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-1 col-sm-6 d-flex align-items-xl-center order-xl-3 order-4 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Extended:
                              </span>
                              <span className="text-14 Experts__date fm">
                                {item.offerDate}
                              </span>
                            </div>
                            <div className="col-xl-2  col-sm-6 order-xl-3 order-5 d-flex align-items-xl-center d-flex mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Offer Expiration:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm ps-xl-5">
                                {item.offerExpiryDate}
                              </span>
                            </div>
                            <div className="col-xl-2 col-sm-6 order-xl-4 order-3 d-flex align-items-xl-center  mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                The Role:
                              </span>
                              <span className="text-14 enterprise__experts Experts__date fm">
                                {item.contractTitle}
                              </span>
                            </div>
                            <div className="col-xl-1 col-sm-6 order-xl-5 d-flex align-items-xl-center align-items-sm-start align-items-center order-3 mb-xl-0 mb-3">
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Contract:
                              </span>
                              <div className="contract-icon mx-xl-auto">
                                {item.acceptedLetter == "Pending" ? (
                                  "Pending"
                                ) : (
                                  <Tooltip
                                    title="Download Contract"
                                    color="#B2DA66"
                                  >
                                    <img
                                      className="ms-0"
                                      onClick={() =>
                                        handleDownload(item.acceptedLetter)
                                      }
                                      src="/assets/images/pdf_icon.png"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                            <div
                              className={`col-xl-2  col-12 order-xl-5 order-last order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-start justify-content-end text-capitalize `}
                            >
                              <span className="nowrap text-14 fm darkGrey fw-semibold me-1 d-xl-none">
                                Status:
                              </span>
                              <span
                                className={`text-12 fm rounded-pill status-${item.status}`}
                              >
                                {item.status}
                              </span>
                            </div>
                            {item.status === "accepted" && (
                              <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-xl-center align-items-start pt-xl-0 pt-2 justify-content-xl-center justify-content-end">
                                <img
                                  src={`/assets/images/Dashboard/settings_more_${
                                    item.cat === "fav"
                                      ? "selected"
                                      : "unselected"
                                  }.svg`}
                                  alt=""
                                  className="img-fluid candidates__icon2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      class="dropdown-item"
                                      onClick={() =>
                                        contractHandler(item._id, "started")
                                      }
                                      type="button"
                                    >
                                      Start
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* {sortedCandidates.length > 0 && (
                            <div className="d-flex justify-content-center pt-4 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates ({sortedCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )} */}
                        </>
                      ))
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HrOffersTab;
