import React, { useState, useRef, useEffect, useContext } from "react";
import "./style.css";
import { BsFilter } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { IoChevronForward, IoChevronBack } from "react-icons/io5";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import JobDescription from "./JobDescription";
import JobApply from "./JobApply";
import {
  getJobs,
  applyJob,
  getInterviews,
  saveJob,
  getSavedJobs,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { TiArrowUnsorted } from "react-icons/ti";
import { HiOutlineArrowDownCircle, HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { TbCurrencyDollar } from "react-icons/tb";
import { CiSearch, CiStar } from "react-icons/ci";
import { RiStarSLine } from "react-icons/ri";
import { Radio, Button } from "antd";
import { FaStar, FaRegStar } from "react-icons/fa";
import { HiOutlineStar, HiStar } from "react-icons/hi2";
import { Country } from "../../../Utilities/Country";

var filter_data;

const filter_options = [
  {
    key: 1,
    title: "Locations",
  },
  {
    key: 2,
    title: "Remote role",
  },
  {
    key: 3,
    title: "Contract type",
  },
  {
    key: 4,
    title: "Industry",
  },
  {
    key: 5,
    title: "Min range",
  },
];
const getRandomBackgroundClass = (prevClass) => {
  const classes = ["bg-blue", "bg-purple", "bg-brown", "bg-main"];
  let randomIndex = Math.floor(Math.random() * classes.length);
  while (classes[randomIndex] === prevClass) {
    randomIndex = Math.floor(Math.random() * classes.length);
  }

  return classes[randomIndex];
};

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="pagination">
      <button
        className="paginate-button"
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <IoChevronBack />
      </button>
      <button
        className="paginate-button"
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <IoChevronForward />
      </button>

      <span>
        Page {currentPage} of {totalPages}
      </span>
    </div>
  );
};

const ProJobs = (props) => {
  const { setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];  
  const [jobs, setJobs] = useState(true);
  const [desc, setDesc] = useState(false);
  const [currentJob, setCurrentJob] = useState(false);
  const [apply, setApply] = useState(false);
  const [applied, setApplied] = useState(false);

  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [filterData, setFilterData] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [filteredAppliedJobs, setFilteredAppliedJobs] = useState([]);
  const [invitedJobsData, setInvitedJobsData] = useState([]);
  const [filteredInvitedJobsData, setFilteredInvitedJobsData] = useState([]);
  const [interviewsInvite, setInterviewsInvite] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [secondSearchText, setSecondSearchText] = useState("");

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [departmentCounts, setDepartmentCounts] = useState([]);
  const [savedJobsData,setSavedJobsData] = useState([]); 

  const applyJobData = (key) => {
    setCurrentJob(key);

    var isApplied = false;
    var currentSelectedJob = filterData.find((job) => job.key === key);

    if (currentSelectedJob.candidates) {
      isApplied = currentSelectedJob.candidates.includes(
        localStorage.getItem("enterpriseID")
      );
    }

    setApplied(isApplied);

    jobDesc();
  };

  const jobDesc = () => {
    setJobs(false);
    setDesc(true);
    // localStorage.setItem("currentClicked",key);
    // setCurrentJob(jobID);
    setApply(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const applyNow = () => {
    setJobs(false);
    setDesc(false);
    setApply(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const Jobs = () => {
    setJobs(true);
    setDesc(false);
    setApply(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchJobs();
    fetchInterviews();
  }, []);

  const fetchJobs = async () => {
    handleChange("loading2", true);
    try {
      const response = await getJobs();
      const responseData = response.data.data;
      const data = responseData.slice().reverse();
      console.log("data,",data);

      setFilterData(
        data
          .filter((job) => job.status === "Open")
          .map((job) => ({
            key: job._id,
            small_text: "Accenture",
            heading: job.company.toUpperCase(),
            para: job.job_title.toUpperCase(),
            company_bio: job.company_bio,
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseID: job.enterpriseID,
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            icon: "",
            tags: job.job_sectors,
            role_overview: job.job_role_text,
            candidates: job.candidates,
            status: job.candidates,
            price_start: job.min_salary,
            price_end: job.max_salary,
            date_start: job.start_date,
            date_end: job.end_date,
            department: job.department,
            locations: job.job_locations
              ? Country.find((country) => country.code === job.job_locations)
                  ?.name
              : "United States",
          }))
      );

      const counts = data.reduce((acc, job) => {
        acc[job.department] = (acc[job.department] || 0) + 1;
        return acc;
      }, {});

      const departmentList = Object.entries(counts).map(
        ([department, count]) => ({
          department,
          count,
        })
      );

      setDepartmentCounts(departmentList);

      handleChange("loading2", false);

      if (data) {
        const jobsWithCandidate = data.filter(
          (job) =>
            job.candidates &&
            Array.isArray(job.candidates) &&
            job.candidates.some(
              (candidate) => candidate === localStorage.getItem("enterpriseID")
            )
        );

        setAppliedJobs(
          jobsWithCandidate.map((job) => ({
            key: job._id,
            small_text: "Accenture",
            heading: job.company.toUpperCase(),
            para: job.job_title.toUpperCase(),
            company_bio: job.company_bio,
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseID: job.enterpriseID,
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            icon: "",
            tags: job.job_sectors,
            role_overview: job.job_role_text,
            candidates: job.candidates,
            status: job.candidates,
            price_start: job.min_salary,
            price_end: job.max_salary,
            date_start: job.start_date,
            date_end: job.end_date,
            department: job.department,
            locations: job.job_locations
              ? Country.find((country) => country.code === job.job_locations)
                  ?.name
              : "United States",
          }))
        );

        const invitedJobsList =
          userData &&
          userData.invitedJobs
            .map((invitedJobId) => data.find((job) => job._id === invitedJobId))
            .filter(Boolean);        

        setInvitedJobsData(
          invitedJobsList.map((job) => ({
            key: job._id,
            small_text: "Accenture",
            heading: job.company.toUpperCase(),
            para: job.job_title.toUpperCase(),
            company_bio: job.company_bio,
            companylogo: "/assets/images/Dashboard/logo-5.png",
            enterpriseID: job.enterpriseID,
            enterpriseLogo:job.enterpriseData ? job.enterpriseData.companyImage.docs : '',
            icon: "",
            tags: job.job_sectors,
            role_overview: job.job_role_text,
            candidates: job.candidates,
            status: job.candidates,
            price_start: job.min_salary,
            price_end: job.max_salary,
            date_start: job.start_date,
            date_end: job.end_date,
            department: job.department,
            locations: job.job_locations
              ? Country.find((country) => country.code === job.job_locations)
                  ?.name
              : "United States",
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchInterviews = async () => {
    handleChange("loading2", true);
    const payload = {
      candidateID: localStorage.getItem("enterpriseID"),
    };
    try {
      const response = await getInterviews(payload);
      const data = response.data.data;
      setInterviewsInvite(
        data.map((job) => ({
          key: job._id,
          small_text: "Accenture",
          heading: job.company_name.toUpperCase(),
          para: job.job_title.toUpperCase(),
          companylogo: "/assets/images/Dashboard/logo-5.png",
          role_overview: job.job_role_text,
          date_start: job.interview_start_Date,
          date_end: job.interview_end_Date,
          locations: job.job_location,
        }))
      );

      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);

    const filtered = filterData.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      return (
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });

    const secondFiltered = filtered.filter((job) => {
      const locations = job.locations.toLowerCase();
      return locations.includes(secondSearchText.toLowerCase());
    });

    //applied jobs
    const filteredApplied = appliedJobs.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      return (
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });

    const secondFilteredAppliedJobs = filteredApplied.filter((job) => {
      const locations = job.locations.toLowerCase();
      return locations.includes(secondSearchText.toLowerCase());
    });


    //invited jobs
    const filteredInvited = invitedJobsData.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      return (
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });

    const secondFilteredInvited = filteredInvited.filter((job) => {
      const locations = job.locations.toLowerCase();
      return locations.includes(secondSearchText.toLowerCase());
    });

    setFilteredAppliedJobs(secondFilteredAppliedJobs);
    setFilteredInvitedJobsData(secondFilteredInvited);
    setFilteredData(secondFiltered);
  };

  const handleSecondSearch = (e) => {
    const secondSearchText = e.target.value;
    setSecondSearchText(secondSearchText);

    const filtered = filterData.filter((job) => {
      const locations = job.locations.toLowerCase();

      return locations.includes(secondSearchText.toLowerCase());
    });
    const secondFiltered = filtered.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      const locations = job.locations.toLowerCase();
      return (
        locations.includes(secondSearchText.toLowerCase()) ||
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });

    const filteredApplied = appliedJobs.filter((job) => {
      const locations = job.locations.toLowerCase();

      return locations.includes(secondSearchText.toLowerCase());
    });

    const secondFilteredApplied = filteredApplied.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      const locations = job.locations.toLowerCase();
      return (
        locations.includes(secondSearchText.toLowerCase()) ||
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });


    const filteredInvited = invitedJobsData.filter((job) => {
      const locations = job.locations.toLowerCase();
      return locations.includes(secondSearchText.toLowerCase());
    });

    const secondFilteredInvited = filteredInvited.filter((job) => {
      const heading = job.heading.toLowerCase();
      const para = job.para.toLowerCase();
      const locations = job.locations.toLowerCase();
      return (
        locations.includes(secondSearchText.toLowerCase()) ||
        heading.includes(searchText.toLowerCase()) ||
        para.includes(searchText.toLowerCase())
      );
    });

    setFilteredAppliedJobs(secondFilteredApplied);
    setFilteredInvitedJobsData(secondFilteredInvited);
    setFilteredData(secondFiltered);
  };
  const dataToRender =
    secondSearchText || searchText || filteredData.length > 0
      ? filteredData
      : filterData;

  const appliedDataRender =
    secondSearchText || searchText || filteredAppliedJobs.length > 0
      ? filteredAppliedJobs
      : appliedJobs; 
  
  const invitedDataRender  =
  secondSearchText || searchText || filteredInvitedJobsData.length > 0
    ? filteredInvitedJobsData
    : invitedJobsData; 

  const handleSort = (field) => {
    if (sortBy === field) {
      // if the same field is clicked again, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // if a different field is clicked, default to ascending order
      setSortBy(field);
      setSortOrder("asc");
    }
  };

  let sortedData = dataToRender;

  if (sortBy && sortOrder) {
    if (sortBy === "date_start") {
      sortedData = sortedData.sort((a, b) => {
        const dateA = new Date(a.date_start);
        const dateB = new Date(b.date_start);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    } else if (sortBy === "date_end") {
      sortedData = sortedData.sort((a, b) => {
        const dateA = new Date(a.date_end);
        const dateB = new Date(b.date_end);
        return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
      });
    } else if (sortBy === "price_start") {
      sortedData = sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a.price_start - b.price_start;
        } else {
          return b.price_start - a.price_start;
        }
      });
    } else if (sortBy === "price_end") {
      sortedData = sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a.price_end - b.price_end;
        } else {
          return b.price_end - a.price_end;
        }
      });
    }
  }

  const handleClear = () => {
    setSearchText("");
    setSecondSearchText("");
    // setFilterData([]);
  };

  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  var myObjectEnterprise;
  const applyForJob = async (event) => {
    event.preventDefault();
    handleChange("loading", true);

    myObjectEnterprise = filterData.find((obj) => obj.key === currentJob);

    var payload = {
      _id: localStorage.getItem("enterpriseID"),
      enterpriseID: myObjectEnterprise["enterpriseID"],
      jobID: currentJob,
    };

    var res = await applyJob(payload);
    if (res.data.statusCode == 1) {
      enqueueSnackbar("Applied Successfully!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      applyNow();
      fetchJobs();
      handleChange("loading", false);
    }
  };
  const [selectedValue, setSelectedValue] = useState("all");
  const [clickedIndex, setClickedIndex] = useState(null);
  const prevClasses = {};
  let prevClass = null;

  const [clickedStars, setClickedStars] = useState({});

  // const handleStarClick = (index) => {
  //   setClickedStars((prevClickedStars) => ({
  //     ...prevClickedStars,
  //     [index]: !prevClickedStars[index],
  //   }));
  // };


  const handleStarClick = async (jobId,action) =>{
    // userid,jobid,action
    
    const payloadSave ={
      userId:userData._id,
      jobId:jobId,
      action:action
    }

    const saveResponse = await saveJob(payloadSave);
    if(saveResponse.data.statusCode==1){
      handleSavedJobs();            
    }
    
  };

  const handleSavedJobs = async () =>{
    // userid,jobid,action
    const payloadSave ={
      userId:userData._id      
    }

    const saveResponse = await getSavedJobs(payloadSave);
    if(saveResponse.data.statusCode==1){
      setSavedJobsData(saveResponse.data.savedJobs);
    }    
  };

  useEffect(() => {
    handleSavedJobs();    
  }, []);

  // getSavedJobs
  
  // const departmentCounts = [
  //   {
  //     department: "software",
  //     count: 20,
  //   },
  //   {
  //     department: "tech",
  //     count: 10,
  //   },
  // ];

  const currentItems = dataToRender;
  const [loadedItems, setLoadedItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsLoading, setItemsLoading] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const initialItems = currentItems.slice(0, itemsPerPage);
    setLoadedItems(initialItems);
    setTotalItems(currentItems.length);
  }, [currentItems]); // Update dependency to currentItems
  

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreItems();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loadedItems, itemsLoading]); // Update dependencies
  
  
  const loadMoreItems = () => {
    if (itemsLoading || loadedItems.length >= totalItems) return;
  
    setItemsLoading(true);
    setTimeout(() => {
      const newItems = currentItems.slice(
        loadedItems.length,
        loadedItems.length + itemsPerPage
      );
      setLoadedItems([...loadedItems, ...newItems]);
      setItemsLoading(false);
    }, 500); // Simulated loading delay, replace with actual data fetching
  };
  
  
  
  

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  const navigate = useNavigate();

  return (
    <>
      <div className="edash">
        <div className="pt-4">
          <div>
            <style>
              {`
          .Pjobs__tabs .react-tabs__tab-list{
            max-width: 330px !important;
            width: 330px !important;
          }
          .Pjobs__tabs .react-tabs__tab{
            width:50% !important;
          }
          .Pjobs__tabs .react-tabs__tab:last-child{
            border-left:0.5px solid #41414080 !important;
          }
        `}
            </style>
            <div className="">
              {jobs && (
                <div className=" ">
                  <div className="Dashboard__container" ref={myRef}>
                    <div className=" pt-0 fm">
                      <div className="container-fluid px-0 px-0 mb-3">
                        <h1 className="fm darkBlue fw-normal text-35">
                          <span className="fw-semibold">
                            {" "}
                            {filterData.length}
                          </span>{" "}
                          roles are currently available
                        </h1>
                      </div>
                      <div className="job__top border-0 radius8 overflow-hidden">
                        <div className="job__top d-md-flex px-md-3">
                          <div className="job__top__search job__top__search1 d-flex align-items-center ps-md-0 ps-3 mb-md-0 mb-2">
                            <CiSearch className="fs-4 job__top__search__icon " />
                            <input
                              value={searchText}
                              onChange={handleSearch}
                              type="text"
                              className="job__top__search py-2 fm ps-2 w-100"
                              placeholder="Job title or keyword"
                            />
                          </div>
                          <div className="job__top__search job__top__search2 d-flex align-items-center ps-3">
                            <CiSearch className="fs-4 job__top__search__icon " />
                            <input
                              value={secondSearchText}
                              onChange={handleSecondSearch}
                              type="text"
                              className="job__top__search py-2 fm ps-2 w-100"
                              placeholder="Location"
                            />
                          </div>
                          <button
                            onClick={handleClear}
                            className="job__top__search__clear d-md-block d-none fm darkGrey ms-auto me-2 my-2 py-1"
                          >
                            Clear
                          </button>
                          <button className="btn--main rounded-2 search__button text-decoration-none fm my-2 text-14 ms-md-0 ms-sm-3">
                            Search
                          </button>
                        </div>
                        <div className="d-sm-flex d-none flex-xl-nowrap flex-wrap justify-content-between py-xl-3 pt-2 px-3">
                          {/* <div className="job__bottom  d-flex flex-wrap align-items-center mb-xl-0 mb-3">
                            <button className="candidates__filter transition jobs__filter__btn fm d-sm-flex d-none text-14 align-items-center fw-500 me-2">
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Industry
                            </button>
                            <button className="candidates__filter transition jobs__filter__btn fm d-sm-flex d-none text-14 align-items-center fw-500 me-2">
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Company
                            </button>
                            <button className="candidates__filter transition  jobs__filter__btn fm  d-sm-flex d-none text-14 align-items-center fw-500 me-2">
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Contract Type
                            </button>
                            <button className="candidates__filter transition jobs__filter__btn fm d-md-flex d-none text-14 align-items-center fw-500">
                              <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                              Remote
                            </button>
                          </div> */}
                          {/* <div className="d-flex mb-xl-0 mb-3">
                            <button className="candidates__filter jobs__filter__btn px-3 py-2 bg-midGrey border-0 text-white h-100 fm darkGrey me-2 d-sm-flex d-none text-14 align-items-center fw-500">
                              Save Search
                            </button>
                            <button className="candidates__filter jobs__filter__btn px-3 py-2 h-100 fm darkGrey d-sm-flex d-none text-14 align-items-center fw-500">
                              Saved Searches
                            </button>
                          </div> */}
                        </div>
                      </div>
                      <button className="candidates__filter bg-darkGrey py-2 border-0 w-100 text-white h-100 fm darkGrey px-3 d-sm-none d-flex text-14 align-items-center justify-content-center">
                        More Filters
                      </button>
                    </div>
                  </div>
                  <div className="candidates jobspage mt-4 mb-3">
                    <Radio.Group
                      defaultValue="all"
                      className="jobd__btn"
                      size={"large"}
                      value={selectedValue}
                      onChange={(e) => setSelectedValue(e.target.value)}
                    >
                      <Radio.Button value="all" className="h-100">
                        All jobs
                      </Radio.Button>
                      {/* <Radio.Button value="matches" className="h-100">
                        Matches
                      </Radio.Button> */}
                      <Radio.Button value="applied" className="h-100">
                        Applied
                      </Radio.Button>
                      <Radio.Button value="saved" className="h-100">
                        Saved
                      </Radio.Button>
                      <Radio.Button value="invited" className="h-100">
                        Invited
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  {/* <div className="">
                      <div className="jobs d-flex align-items-center mt-lg-0 mt-4 w-100 mb-3">
                        <div className="d-md-flex d-none justify-content-between mx-3 w-100">
                          <div
                            onClick={() => handleSort("price_start")}
                            className=" ps-0 text-center"
                          >
                            <span className="Experts__title fw-semibold text-15 Candidates__title enterprise__experts fm text-capitalize">
                              Salary
                            </span>
                            <TiArrowUnsorted className="ms-2 darkGrey enterprise__experts__arrow Dteams__box__title" />
                          </div>

                          <div className="d-flex">
                            <div
                              onClick={() => handleSort("date_start")}
                              className=" ps-0 text-center me-4"
                            >
                              <span className="Experts__title fw-semibold text-15 Candidates__title enterprise__experts fm text-capitalize">
                                Opening date
                              </span>
                              <TiArrowUnsorted className="ms-2 darkGrey enterprise__experts__arrow Dteams__box__title" />
                            </div>
                            <div
                              onClick={() => handleSort("date_end")}
                              className=" ps-0 text-center me-2"
                            >
                              <span className="Experts__title fw-semibold text-15 Candidates__title enterprise__experts fm text-capitalize">
                                Deadline
                              </span>
                              <TiArrowUnsorted className="ms-2 darkGrey enterprise__experts__arrow Dteams__box__title" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  <div className="row">
                    <div className="col-lg-9 order-lg-1 order-2">
                      {selectedValue === "all" && (
                        <div className="Dashboard__container">
                          <div className="jobs mt-lg-0 mt-3">
                            <div className="container-fluid">
                              <div className="row">
                                {loading2 ? (
                                  <Loader
                                    size="md"
                                    className=""
                                    content="loading"
                                  />
                                ) : (
                                  loadedItems.map((item, index) => {                                   
                                    const randomClass =
                                      getRandomBackgroundClass(prevClass);
                                    prevClass = randomClass;

                                    const isJobSaved = savedJobsData.includes(item.key);
                                    return (
                                      <div
                                        onClick={()=>{
                                          navigate(`/candidate/dashboard/jobs/${item.key}`);
                                        }
                                      }
                                        className="filter__border jobs__border d-flex flex-column py-md-2 py-3 transition mb-2 pointer"
                                        key={item.key}
                                      >
                                        <div className="container-fluid d-sm-flex justify-content-between px-0 px-0">
                                          <div className="filter__data w-100 d-flex align-items-center">
                                            <div
                                              // className={`expiring__body_detail_image ${randomClass} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                              className={`expiring__body_detail_image ${item.enterpriseLogo ? 'company__image' : ''} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                            >
                                              <img
                                                src={item.enterpriseLogo ? item.enterpriseLogo : `/assets/images/expiring_icon.svg`}
                                                alt=""
                                                className="me-0"
                                              />
                                            </div>
                                            <div className="filter__content">
                                              <div className="d-flex justify-content-between align-items-end">
                                                <span                                                  
                                                  className="text-decoration-none d-flex align-items-center"
                                                >
                                                  <h3 className="filter__content__heading text-capitalize fs-6  mb-0 fw-semibold darkGrey mb-0">
                                                    {capitalizeWords(item.para)}
                                                  </h3>
                                                </span>
                                                <div className="d-sm-block d-none">
                                                  {isJobSaved ? (
                                                    <HiStar
                                                      className="main pointer"
                                                      onClick={(event) =>{
                                                        event.stopPropagation();
                                                        handleStarClick(item.key,'unsave')
                                                      }
                                                    }
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <HiOutlineStar
                                                      className="grey pointer"
                                                      onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleStarClick(item.key,'save')
                                                      }
                                                    }
                                                      style={{
                                                        fontSize: "20px",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <div className="d-sm-flex d-none flex-lg-row mt-1 flex-column gap-2 justify-content-lg-between">
                                                <div className="d-flex flex-wrap gap-2 align-items-start">
                                                  <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-12 fm">
                                                    {capitalizeWords(
                                                      item.heading
                                                    )}{" "}
                                                    <img
                                                      className="pro__dash__jobs__icon"
                                                      src={`${item.icon}`}
                                                      alt=""
                                                    />
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <FiUsers /> 500–4,000
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <HiOutlineSquare3Stack3D />{" "}
                                                    {item.department}
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <TbCurrencyDollar />{" "}
                                                    {item.price_start}-
                                                    {item.price_end} USD
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    Closes: {item.date_end}
                                                  </p>
                                                </div>
                                                <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                  {item.tags.map((items) => (
                                                    <div className="">
                                                      <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                        <small className="filter__content__tag__content--small text-capitalize text-12">
                                                          {items}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 br-200 d-flex align-items-center justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {item.locations}
                                                      </small>
                                                    </div>
                                                  </div>
                                                  {index === 0 && (
                                                    <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0  d-flex align-items-center justify-content-center ">
                                                      <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                        New!
                                                      </small>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-sm-none d-flex mt-2 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                            <div className="d-flex flex-wrap  align-items-center">
                                              <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                                {capitalizeWords(item.heading)}{" "}
                                                <img
                                                  className="pro__dash__jobs__icon ms-0"
                                                  src={`${item.icon}`}
                                                  alt=""
                                                />
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <FiUsers /> 500–4,000
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <HiOutlineSquare3Stack3D />{" "}
                                                {/* Creative & Strategy */}
                                                {item.department}
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <TbCurrencyDollar />{" "}
                                                {item.price_start}-
                                                {item.price_end} USD
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                Closes: {item.date_end}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-end gap-2">
                                              <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                {item.tags.map((items) => (
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {items}
                                                      </small>
                                                    </div>
                                                  </div>
                                                ))}
                                                <div className="">
                                                  <div className="filter__content__tag__content px-2 rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                    <small className="filter__content__tag__content--small text-capitalize text-12">
                                                      {item.locations}
                                                    </small>
                                                  </div>
                                                </div>
                                                <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                  <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                    New!
                                                  </small>
                                                </div>
                                              </div>
                                              {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                                {!loading2 ? (currentItems.length > 0 && (
                                  <div className="d-flex justify-content-center pt-4 gap-2">                                    
                                    <button className="fm text-12 table__bottom__btn2">
                                      Scroll to load more{" "}
                                      <HiOutlineArrowDownCircle className="fs-6" />
                                    </button>
                                  </div>
                                )): ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectedValue === "applied" && (
                        <div className="Dashboard__container">
                          <div className="jobs fm mt-lg-0 mt-3">
                            <div className="container-fluid">
                              <div className="row">
                                {loading2 ? (
                                  <Loader
                                    size="md"
                                    className=""
                                    content="loading"
                                  />
                                ) : (
                                  appliedDataRender.map((item, index) => {
                                    const randomClass =
                                      getRandomBackgroundClass(prevClass);
                                    prevClass = randomClass;
                                    const isJobSaved = savedJobsData.includes(item.key);
                                    return (
                                      <div
                                        onClick={()=>{
                                          navigate(`/candidate/dashboard/jobs/${item.key}`);
                                          }
                                        }
                                        className="filter__border jobs__border d-flex flex-column py-md-2 py-3 transition mb-2 pointer"
                                        key={item.key}
                                      >
                                        <div className="container-fluid d-sm-flex justify-content-between px-0 px-0">
                                          <div className="filter__data w-100 d-flex align-items-center">
                                            <div
                                              // className={`expiring__body_detail_image ${randomClass} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                              className={`expiring__body_detail_image ${item.enterpriseLogo ? 'company__image' : ''} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                            >
                                              <img
                                              src={item.enterpriseLogo ? item.enterpriseLogo : `/assets/images/expiring_icon.svg`}
                                                // src="/assets/images/expiring_icon.svg"
                                                alt=""
                                                className="me-0"
                                              />
                                            </div>
                                            <div className="filter__content">
                                              <div className="d-flex justify-content-between align-items-end">
                                                <span                                                 
                                                  className="text-decoration-none d-flex align-items-center"
                                                >
                                                  <h3 className="filter__content__heading fs-6  mb-0 fw-semibold darkGrey mb-0">
                                                    {capitalizeWords(item.para)}
                                                  </h3>
                                                </span>
                                                <div className="d-sm-block d-none">
                                                {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                                </div>
                                              </div>
                                              <div className="d-sm-flex d-none mt-1 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                                <div className="d-flex flex-wrap gap-2  align-items-start">
                                                  <p className="pro__dash__jobs__para text-12 darkGrey fw-semibold mb-0 text-capitalize text-12 fm me-2">
                                                    {capitalizeWords(
                                                      item.heading
                                                    )}{" "}
                                                    <img
                                                      className="pro__dash__jobs__icon"
                                                      src={`${item.icon}`}
                                                      alt=""
                                                    />
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <FiUsers /> 500–4,000
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <HiOutlineSquare3Stack3D />{" "}
                                                    {item.department}
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <TbCurrencyDollar />{" "}
                                                    {item.price_start}-
                                                    {item.price_end} USD
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    Closes: {item.date_end}
                                                  </p>
                                                </div>
                                                <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                  {item.tags.map((items) => (
                                                    <div className="">
                                                      <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                        <small className="filter__content__tag__content--small text-capitalize text-12">
                                                          {items}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {item.locations}
                                                      </small>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-sm-none d-flex mt-2 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                            <div className="d-flex flex-wrap  align-items-center">
                                              <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                                {capitalizeWords(item.heading)}{" "}
                                                <img
                                                  className="pro__dash__jobs__icon"
                                                  src={`${item.icon}`}
                                                  alt=""
                                                />
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <FiUsers /> 500–4,000
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <HiOutlineSquare3Stack3D />{" "}
                                                {item.department}
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <TbCurrencyDollar />{" "}
                                                {item.price_start}-
                                                {item.price_end} USD
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                Closes: {item.date_end}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-2">
                                              <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                {item.tags.map((items) => (
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {items}
                                                      </small>
                                                    </div>
                                                  </div>
                                                ))}
                                                <div className="">
                                                  <div className="filter__content__tag__content px-2 rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                    <small className="filter__content__tag__content--small text-capitalize text-12">
                                                      {item.locations}
                                                    </small>
                                                  </div>
                                                </div>
                                                <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                  <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                    New!
                                                  </small>
                                                </div>
                                              </div>
                                              {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                                {/* {!loading2 ? (appliedJobs.length > 0 && (
                                  <div className="d-flex justify-content-center pt-4 gap-2">
                                    <button className="fm text-12 table__bottom__btn1">
                                      Load all jobs ({appliedJobs.length}
                                      )
                                    </button>
                                    <button className="fm text-12 table__bottom__btn2">
                                      Scroll to load more{" "}
                                      <HiOutlineArrowDownCircle className="fs-6" />
                                    </button>
                                  </div>
                                )): ""} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedValue === "saved" && (
                        <div className="Dashboard__container">
                          <div className="jobs mt-lg-0 mt-3">
                            <div className="container-fluid">
                              <div className="row">
                                {loading2 ? (
                                  <Loader
                                    size="md"
                                    className=""
                                    content="loading"
                                  />
                                ) : (
                                  savedJobsData && currentItems.map((item, index) => {
                                    const randomClass =
                                      getRandomBackgroundClass(prevClass);
                                    prevClass = randomClass;
                                    const isMainStarClicked =
                                      clickedStars[index];
                                      const isJobSaved = savedJobsData.includes(item.key);

                                    if (isJobSaved) {
                                      return (
                                        <div
                                        onClick={()=>{
                                          navigate(`/candidate/dashboard/jobs/${item.key}`);
                                          }
                                        }
                                          className="filter__border jobs__border d-flex flex-column py-md-2 py-3 transition mb-2 pointer"
                                          key={item.key}
                                        >
                                          <div className="container-fluid d-sm-flex justify-content-between px-0 px-0">
                                            <div className="filter__data w-100 d-flex  align-items-center">
                                              <div
                                                // className={`expiring__body_detail_image ${randomClass} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                                className={`expiring__body_detail_image ${item.enterpriseLogo ? 'company__image' : ''} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                              >
                                                <img
                                                  // src="/assets/images/expiring_icon.svg"
                                                  src={item.enterpriseLogo ? item.enterpriseLogo : `/assets/images/expiring_icon.svg`}
                                                  alt=""
                                                  className="me-0"
                                                />
                                              </div>
                                              <div className="filter__content">
                                                <div className="d-flex justify-content-between align-items-end">
                                                  <span                                                    
                                                    className="text-decoration-none d-flex align-items-center"
                                                  >
                                                    <h3 className="filter__content__heading fs-6  mb-0 fw-semibold darkGrey mb-0">
                                                      {capitalizeWords(
                                                        item.para
                                                      )}
                                                    </h3>
                                                  </span>
                                                  <div className="d-sm-block d-none">                                                   
                                                      <HiStar
                                                        className="main pointer jobs__star"
                                                        onClick={(event) =>{
                                                          event.stopPropagation();
                                                          handleStarClick(item.key,'unsave')
                                                          }
                                                        }
                                                      />                                                    
                                                  </div>
                                                </div>
                                                <div className="d-sm-flex d-none mt-1 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                                  <div className="d-flex flex-wrap gap-2 align-items-start">
                                                    <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-12 fm">
                                                    {capitalizeWords(
                                                        item.heading
                                                      )}{" "}
                                                      <img
                                                        className="pro__dash__jobs__icon"
                                                        src={`${item.icon}`}
                                                        alt=""
                                                      />
                                                    </p>
                                                    <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                      <FiUsers /> 500–4,000
                                                    </p>
                                                    <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                      <HiOutlineSquare3Stack3D />{" "}
                                                      {item.department}
                                                    </p>
                                                    <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                      <TbCurrencyDollar />{" "}
                                                      {item.price_start}-
                                                      {item.price_end} USD
                                                    </p>
                                                    <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                      Closes: {item.date_end}
                                                    </p>
                                                  </div>
                                                  <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                    {item.tags.map((items) => (
                                                      <div className="">
                                                        <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                          <small className="filter__content__tag__content--small text-capitalize text-12">
                                                            {items}
                                                          </small>
                                                        </div>
                                                      </div>
                                                    ))}
                                                    <div className="">
                                                      <div className="filter__content__tag__content px-2 br-200  d-flex align-items-center justify-content-center mb-0 ">
                                                        <small className="filter__content__tag__content--small text-capitalize text-12">
                                                          {item.locations}
                                                        </small>
                                                      </div>
                                                    </div>
                                                    <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0 d-flex align-items-center justify-content-center">
                                                      <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                        New!
                                                      </small>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-sm-none d-flex mt-2 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                              <div className="d-flex flex-wrap  align-items-center">
                                                <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                                  {capitalizeWords(
                                                    item.heading
                                                  )}{" "}
                                                  <img
                                                    className="pro__dash__jobs__icon"
                                                    src={`${item.icon}`}
                                                    alt=""
                                                  />
                                                </p>
                                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                  <FiUsers /> 500–4,000
                                                </p>
                                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                  <HiOutlineSquare3Stack3D />{" "}
                                                  {item.department}
                                                </p>
                                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                  <TbCurrencyDollar />{" "}
                                                  {item.price_start}-
                                                  {item.price_end} USD
                                                </p>
                                                <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                  Closes: {item.date_end}
                                                </p>
                                              </div>
                                              <div className="d-flex justify-content-between gap-2">
                                                <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                  {item.tags.map((items) => (
                                                    <div className="">
                                                      <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-0 ">
                                                        <small className="filter__content__tag__content--small text-capitalize text-12">
                                                          {items}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {item.locations}
                                                      </small>
                                                    </div>
                                                  </div>
                                                  <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                    <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                      New!
                                                    </small>
                                                  </div>
                                                </div>
                                                {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })
                                )}
                                {/* {!loading2 ?  (savedJobsData.length > 0 && (
                                  <div className="d-flex justify-content-center align-items-center pt-4 gap-2">
                                    <button className="fm text-12 table__bottom__btn1">
                                      Load all jobs ({savedJobsData.length}
                                      )
                                    </button>
                                    <button className="fm text-12 table__bottom__btn2">
                                      Scroll to load more{" "}
                                      <HiOutlineArrowDownCircle className="fs-6" />
                                    </button>
                                  </div>
                                )) : ""} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {selectedValue === "invited" && (
                        <div className="Dashboard__container">
                          <div className="jobs fm mt-lg-0 mt-3">
                            <div className="container-fluid">
                              <div className="row">
                                {loading2 ? (
                                  <Loader
                                    size="md"
                                    className=""
                                    content="loading"
                                  />
                                ) : (
                                  invitedDataRender.map((item, index) => {
                                    const randomClass =
                                      getRandomBackgroundClass(prevClass);
                                    prevClass = randomClass;
                                    const isJobSaved = savedJobsData.includes(item.key);
                                    return (
                                      <div
                                      onClick={()=>{
                                        navigate(`/candidate/dashboard/jobs/${item.key}`);
                                        }
                                      }
                                        className="filter__border jobs__border d-flex flex-column py-md-2 py-3 transition mb-2 pointer"
                                        key={item.key}
                                      >
                                        <div className="container-fluid d-sm-flex justify-content-between px-0 px-0">
                                          <div className="filter__data w-100 d-flex align-items-center">
                                            <div
                                              // className={`expiring__body_detail_image ${randomClass} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                              className={`expiring__body_detail_image ${item.enterpriseLogo ? 'company__image' : ''} jobs__image rounded-circle d-flex justify-content-center align-items-center`}
                                            >
                                              <img
                                                // src="/assets/images/expiring_icon.svg"
                                                src={item.enterpriseLogo ? item.enterpriseLogo : `/assets/images/expiring_icon.svg`}
                                                alt=""
                                                className="me-0"
                                              />
                                            </div>
                                            <div className="filter__content">
                                              <div className="d-flex justify-content-between align-items-end">
                                                <span                                                 
                                                  className="text-decoration-none d-flex align-items-center "
                                                >
                                                  <h3 className="filter__content__heading fs-6 mb-0 fw-semibold darkGrey mb-0">
                                                    {capitalizeWords(item.para)}
                                                  </h3>
                                                </span>
                                                <div className="d-sm-block d-none">
                                                {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                                </div>
                                              </div>
                                              <div className="d-sm-flex d-none mt-1 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                                <div className="d-flex flex-wrap gap-2 align-items-start">
                                                  <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-12 fm">
                                                    {capitalizeWords(
                                                      item.heading
                                                    )}{" "}
                                                    <img
                                                      className="pro__dash__jobs__icon"
                                                      src={`${item.icon}`}
                                                      alt=""
                                                    />
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <FiUsers /> 500–4,000
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <HiOutlineSquare3Stack3D />{" "}
                                                    {item.department}
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    <TbCurrencyDollar />{" "}
                                                    {item.price_start}-
                                                    {item.price_end} USD
                                                  </p>
                                                  <p className="mb-0 fm darkGrey mt-0 mb-0 text-12">
                                                    Closes: {item.date_end}
                                                  </p>
                                                </div>
                                                <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                  {item.tags.map((items) => (
                                                    <div className="">
                                                      <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                        <small className="filter__content__tag__content--small text-capitalize text-12">
                                                          {items}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  ))}
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 br-200 d-flex flex-column justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {item.locations}
                                                      </small>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="d-sm-none d-flex mt-2 flex-lg-row flex-column gap-2 justify-content-lg-between">
                                            <div className="d-flex flex-wrap  align-items-center">
                                              <p className="pro__dash__jobs__para darkGrey fw-semibold mb-0 text-capitalize text-16 fm me-2">
                                                {capitalizeWords(item.heading)}{" "}
                                                <img
                                                  className="pro__dash__jobs__icon"
                                                  src={`${item.icon}`}
                                                  alt=""
                                                />
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <FiUsers /> 500–4,000
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <HiOutlineSquare3Stack3D />{" "}
                                                {item.department}
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                <TbCurrencyDollar />{" "}
                                                {item.price_start}-
                                                {item.price_end} USD
                                              </p>
                                              <p className="mb-0 fm darkGrey mt-0 mb-0 text-14 me-3">
                                                Closes: {item.date_end}
                                              </p>
                                            </div>
                                            <div className="d-flex justify-content-between gap-2">
                                              <div className="d-flex flex-sm-nowrap gap-1 flex-wrap">
                                                {item.tags.map((items) => (
                                                  <div className="">
                                                    <div className="filter__content__tag__content px-2 rounded-pill d-flex flex-column justify-content-center mb-0 ">
                                                      <small className="filter__content__tag__content--small text-capitalize text-12">
                                                        {items}
                                                      </small>
                                                    </div>
                                                  </div>
                                                ))}
                                                <div className="">
                                                  <div className="filter__content__tag__content px-2 rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                    <small className="filter__content__tag__content--small text-capitalize text-12">
                                                      {item.locations}
                                                    </small>
                                                  </div>
                                                </div>
                                                <div className="filter__content__tag__content px-2 bg-main rounded-pill mb-0  d-flex align-items-center justify-content-center">
                                                  <small className="filter__content__tag__content--small darkBlue text-capitalize text-12">
                                                    New!
                                                  </small>
                                                </div>
                                              </div>
                                              {isJobSaved ? (
                                                  <HiStar
                                                    className="main pointer jobs__star"
                                                    onClick={(event) =>{
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'unsave')
                                                    }
                                                  }                                                     
                                                  />
                                                ) : (
                                                  <HiOutlineStar
                                                    className="grey pointer jobs__star"
                                                    onClick={(event) => {
                                                      event.stopPropagation();
                                                      handleStarClick(item.key,'save')
                                                    }
                                                  }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                                {/* {invitedJobsData.length > 0 && (
                                  <div className="d-flex justify-content-center pt-4 gap-2">
                                    <button className="fm text-12 table__bottom__btn1">
                                      Load all jobs ({invitedJobsData.length}
                                      )
                                    </button>
                                    <button className="fm text-12 table__bottom__btn2">
                                      Scroll to load more{" "}
                                      <HiOutlineArrowDownCircle className="fs-6" />
                                    </button>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-3 order-lg-2 order-1 ps-lg-0">
                      <div className="radius8 px-3 py-4 jobs__box">
                        <h6 className="fm fw-semibold mb-1">
                          Roles by category
                        </h6>
                        <p className="fm text-14 darkGrey">
                          Click below to view all your jobs in category.
                        </p>
                        <div>
                        {departmentCounts
                          .sort((a, b) => b.count - a.count)
                          .slice(0, 10)
                          .map(({ department, count }) => (
                            <div
                              className="d-flex justify-content-between align-items-end mb-2"
                              key={department}
                            >
                              <p className="mb-0 fw-semibold fm text-14 text-capitalize">
                                {department}
                              </p>
                              <button className="jobs__pill darkBlue rounded-pill fm text-capitalize">
                                {count}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <TabPanel>
                      <div className="Dashboard__container">
                        <div className="jobs fm ms-xxl-5 ms-lg-4 ms-3 mt-lg-0 mt-4">
                          <div className="container-fluid">
                            <div className="row">
                              {loading2 ? (
                                <Loader size="md" className="pt-4" content="loading" />
                              ) : (
                                interviewsInvite.map((item) => (
                                  <div
                                    className="col-12 filter__border jobs__border d-flex flex-column pt-md-4 pt-3 pb-3 transition pointer mb-2"
                                    key={item.key}
                                  // onClick={() => applyJobData(item.key)}
                                  >
                                    <Link to={`/candidate/dashboard/jobs/interview/${item.key}`} className="text-decoration-none">
                                      <div className="container-fluid px-0 px-0">
                                        <div className="filter__data d-flex align-items-center">
                                          <div className="filter__image radius8">
                                            <img
                                              src={`/assets/images/EnterpriseAvatar.svg`}
                                              alt=""
                                              className="lightBlueBar__content__icon__image border-0 rounded-0"
                                            />
                                          </div>
                                          <div className="filter__content ">
                                            <div className="d-flex flex-sm-row flex-column justify-content-between">
                                              <div className=" filter__content__items d-flex flex-column mb-md-0 mb-sm-3">
                                                <div className="d-flex justify-content-between">
                                                  <h3 className="filter__content__heading text-22 mb-md-2 mb-0 fw-semibold darkGrey">
                                                    {capitalizeWords(item.heading)}
                                                  </h3>
                                                </div>
                                                <div className="filter__content__para mt-0 d-md-flex d-none flex-wrap justify-content-start align-items-center">
                                                  <div className="">
                                                    <p className="pro__dash__jobs__para fw-semibold mb-0 text-capitalize text-16 fm me-2 darkGrey">
                                                      You’ve been selected for an interview <strong>{capitalizeWords(item.para)}</strong>{" "} job
                                                      <img
                                                        className="pro__dash__jobs__icon"
                                                        src={`${item.icon}`}
                                                        alt=""
                                                      />
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="d-md-flex flex-column justify-content-between d-none">
                                                <div className="d-flex justify-content-end align-items-end filter__content__dates">
                                                  <div className="w-100 d-flex justify-content-end">
                                                    <div className="text-end">
                                                      <small className="text-muted text-12">
                                                        From:
                                                      </small>
                                                      <p className="text-16 text-muted mb-0">
                                                        {item.date_start}
                                                      </p>
                                                    </div>
                                                    <div className="text-end ms-4">
                                                      <small className="text-muted text-12">
                                                        Deadline:
                                                      </small>
                                                      <p className="text-16 text-muted mb-0">
                                                        {item.date_end}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                  <Link
                                                    to={""}
                                                    className="text-decoration-none "
                                                  >
                                                    <p className="text-16 fm mb-0">
                                                      View <BsArrowUpRight />{" "}
                                                    </p>
                                                  </Link>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="filter__content__para pt-3 mt-0 d-md-none d-flex flex-wrap justify-content-start align-items-center">
                                          <div className="">
                                            <p className="pro__dash__jobs__para fw-semibold darkGrey mb-0 text-capitalize text-16 fm me-2">
                                              {capitalizeWords(item.para)}{" "}
                                              <img
                                                className="pro__dash__jobs__icon"
                                                src={`${item.icon}`}
                                                alt=""
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel> */}
                </div>
              )}
              <div className="Dashboard__container">
                <div className="jobs fm  mb-5">
                  <div className="container-fluid px-0">
                    {desc && (
                      <>
                        <JobDescription
                          data={currentJob}
                          jobsData={filterData}
                        />
                        <div className="mt-5">
                          {applied === false && (
                            <button
                              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              onClick={applyForJob}
                            >
                              {loading ? <Loader /> : "Apply now"}
                            </button>
                          )}
                          {applied === true && (
                            <button
                              className="desc__applynow py-2 fm transition px-3 me-2 mb-2"
                              disabled
                            >
                              Already applied
                            </button>
                          )}

                          <button
                            className="desc__back py-2 fm transition px-3 mb-2"
                            onClick={Jobs}
                          >
                            Back
                          </button>
                        </div>
                      </>
                    )}
                    {apply && (
                      <>
                        <JobApply data={currentJob} jobsData={filterData} />
                        <button
                          className="apply__back py-2 fm transition px-3 mb-2"
                          // onClick={jobDesc}
                          onClick={Jobs}
                        >
                          Back
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProJobs;
