export const languages = [
    { country: "China", language: "Mandarin" },
    { country: "India", language: "Hindi" },
    { country: "United States", language: "English" },
    { country: "Indonesia", language: "Indonesian" },
    { country: "Brazil", language: "Portuguese" },
    { country: "Russia", language: "Russian" },
    { country: "Bangladesh", language: "Bengali" },
    { country: "Japan", language: "Japanese" },
    { country: "Mexico", language: "Spanish" },
    { country: "Philippines", language: "Filipino" },
    { country: "Germany", language: "German" },
    { country: "Egypt", language: "Arabic" },
    { country: "Iran", language: "Persian" },
    { country: "Vietnam", language: "Vietnamese" },
    { country: "France", language: "French" },
    { country: "South Africa", language: "Afrikaans" },
    { country: "Italy", language: "Italian" },
  ];