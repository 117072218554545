import React, { useState, useContext } from "react";
import { MyContext, clearContextData } from '../../../../Utilities/MyContextProvider';
import { getJobDescription } from "../../../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";

const AboutRole = ({ handleAboutRoleData }) => {
  const myContext = useContext(MyContext);
  const JobData = myContext.contextData;
  const { enqueueSnackbar } = useSnackbar();
  
  const [loadingAi, setLoadingAi] = useState(false);
  const [aboutRoleData, setAboutRoleData] = useState({
    job_role_text: '',
    job_expectation: '',
    company_benefits: ''
  });

  const {
    job_role_text,
    job_expectation,
    company_benefits
  } = aboutRoleData


  const handleChange = (key, value) => {
    setAboutRoleData(prevData => {
      const newData = { ...prevData, [key]: value };
      handleAboutRoleData(newData);
      return newData;
    });
  };
  


  const getJobDescriptionHandler = async () => {
    try {
      if (!JobData.job_title) {
        enqueueSnackbar("Please enter Job title", {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } else {
        setLoadingAi(true);
        const title = encodeURIComponent(JobData.job_title);
        const payload = {
          title: title,
        };
  
        const getResponse = await getJobDescription(payload);
        const textArray = getResponse?.data?.data?.choices[0]?.message?.content.split("Job Summary:");
        const jobDescription = textArray.length > 1 ? textArray[1].trim() : "";
  
        // handleChange("job_role_text", jobDescription);
        setAboutRoleData((prevData) => ({
          ...prevData,
          job_role_text: jobDescription,
        }));
        const payloadUpdate = {
          job_role_text:jobDescription
        }
        myContext.updateContextData(payloadUpdate);

      }
    } catch (error) {      
      enqueueSnackbar("Error fetching job description", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoadingAi(false);
    }
  };
  
  return (
    <div className="About pb-2">
      <div className="Dashboard__container pt-4 pb-2">
        <div className="Dashboard__container___content pt-2">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-xl-8 ">
                <p className="fm darkGrey mt-0 text-14">Tell us more about the role you are hiring for? What skills are needed what day to day activities will be etc.</p>
                <div className="d-flex gap-2 flex-sm-row flex-column align-item-center justify-content-between mb-3">
                  <label
                    htmlFor="text"
                    className="darkGrey fm fw-semibold fs-6 d-flex align-items-center"
                  >
                    Role Description:
                  </label>
                  <button
                    disabled={loadingAi}
                    onClick={getJobDescriptionHandler}
                    className={`Get__left__content__para Get__right__button Get__right__button--blue text-14 fm jobd__btn wd-100`}
                  >
                    {loadingAi ? <Loader /> : "Generate with AI"}
                  </button>
                </div>
                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="About the role…"
                  value={job_role_text}        
                  id="text"
                  rows={6}
                  onChange={(value) => {                    
                    handleChange('job_role_text', value.target.value);
                    
                  }}
                />
              </div>
              <div className="col-xl-8 mt-4">
                <label
                  htmlFor="text"
                  className="darkGrey fm fw-semibold fs-6 mb-2"
                >
                  Role Key Expectations:
                </label>

                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  value={job_expectation}
                  rows={6}
                  onChange={(value) => {
                    
                    handleChange('job_expectation', value.target.value);
                    
                  }}
                />
              </div>
              <div className="col-xl-8 mt-4">
                <label
                  htmlFor="text"
                  className="darkGrey fm fw-semibold fs-6 mb-2"
                >
                  Your Company Benefits:
                </label>                
                <textarea
                  className="form-control form-control-lg mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  value={company_benefits}
                  rows={6}
                  onChange={(value) => {
                    
                    handleChange('company_benefits', value.target.value);
                    
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutRole;
