import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBar from "../components/ProgressBar/ProgressBar.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Identity from "../pageComponents/Professionals/Identification/Indentity.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const ProfessionalIdentification = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="professional-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBar page="3" title="Candidate" />}
        right={<Identity />}
      />
      <Footer />
    </>
  );
};

export default ProfessionalIdentification;
