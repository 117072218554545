import React,{useEffect} from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ContactForm from "../pageComponents/Contactus/ContactForm.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const Contactus = () => {
  useEffect(() => {
    document.body.classList.add('contact-us')
    return () => {
      document.body.classList.remove('contact-us')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <ContactForm/>
    </>
  );
};

export default Contactus;
