import React, { useEffect, useRef, useState, useContext } from "react";
import "./style.css";
import {
  Avatar,
  Tooltip,
  Dropdown,
  Checkbox,
  Radio,
  Space,
  Slider,
  Menu,
  Select,
  Collapse,
  Panel,
  Modal,
  Button,
  Table,
  Pagination,
  Flex,
  Progress,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import { CiLock } from "react-icons/ci";
import {
  HiOutlineArrowDownCircle,
  HiOutlineArrowSmallDown,
} from "react-icons/hi2";
import {
  BsFilterCircle,
  BsSearch,
  BsFilter,
  BsFillEmojiSmileFill,
  BsCheckCircle,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { TiArrowUnsorted } from "react-icons/ti";
import { SlLocationPin, SlGlobe } from "react-icons/sl";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import YourProfile from "../../ProfessionalDashboard/YourProfile/YourProfile";
import SendContract from "./SendContract";
import MeetingDate from "./MeetingDate";
import MeetingConfirm from "./MeetingConfirm";
import {
  getJobById,
  getEnterpriseByID,
  getProfessionalById,
  downloadAttachment,
  updateCandidateStatus,
  getApplications,
  createComment,
  getComments,
  createReply,
  updateApplicantsFeedback,
  UpdateJob,
  getMembersByCompany,
  requestScore,
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import moment from "moment";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { Country } from "../../../Utilities/Country";
import { IoIosArrowDown } from "react-icons/io";
import { useMediaQuery } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useParams, useNavigate, Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { SlOptionsVertical } from "react-icons/sl";
import { AiFillLike } from "react-icons/ai";
// import { UncontrolledBoard } from '@caldwell619/react-kanban';
import {
  UncontrolledBoard,
  moveCard,
  KanbanBoard,
  OnDragEndNotification,
  Card,
} from "@caldwell619/react-kanban";
import "@caldwell619/react-kanban/dist/styles.css";
import {
  PiDotsThreeCircleLight,
  PiDotsThreeCircleThin,
  PiDotsThreeCircleVerticalLight,
} from "react-icons/pi";

var comments = [];

var favourites = [];
var favouritesDND = [];
var shortLists = [];
var shortListsDND = [];
var interViewedList = [];
var interViewedListDND = [];
var offeredCandidates = [];

var rejectedCand = [];
var rejectedCandDND = [];

var selectedList = [];
var selectedListDND = [];

var allcandidates = [];
var fetchedCandidatesList = [];

const team = [
  {
    id: 1,
    name: "Ben Smith",
    department: "IT",
    status: "Accepted",
  },
  {
    id: 2,
    name: "Ben Smith",
    department: "Admin",
    status: "Accepted",
  },
  {
    id: 3,
    name: "Ben Smith",
    department: "Admin",
    status: <span className="text-14 fm darkGrey">Pending</span>,
  },
  {
    id: 4,
    name: "Ben Smith",
    department: "Hr",
    status: "Accepted",
  },
  {
    id: 5,
    name: "Ben Smith",
    department: "Admin",
    status: "Accepted",
  },
];

const sortingMenus = [
  {
    key: "1",
    label: <span>Most Recent</span>,
  },
  {
    key: "2",
    label: <span>Oldest</span>,
  },
];

const Candidates = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profile, setProfile] = useState(false);
  const [candidatesAvailability, setCandidatesAvailability] = useState(true);
  const [meeting, setMeeting] = useState(false);
  const [time, setTime] = useState(false);
  const [detail, setDetail] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [all, setAll] = useState(true);
  const [fav, setFav] = useState(false);
  const [shortListed, setShortListed] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [selected, setSelected] = useState(false);
  const [candidate, setCandidate] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [filter, setFilter] = useState(false);
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const inputRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [relevance, setRelevance] = useState(true);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [dropfav, setDropfav] = useState(true);
  const [droplist, setDroplist] = useState(true);
  const [dropinterview, setDropinterview] = useState(true);
  const isMediumScreen = useMediaQuery("(max-width:767px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInterviewFeedbackQuery, setSearchInterviewFeedbackQuery] =
    useState("");

  const [candidateData, setCandidateData] = useState([]);
  const [interview, setInterview] = useState([]);
  const [interviewScheduleInfo, setInterviewScheduleInfo] = useState([]);
  const { id } = useParams();
  const [myObjectData, setMyObjectData] = useState([]);

  const [showReplies, setShowReplies] = useState(false);
  const [typeReply, setTypeReply] = useState(false);
  const [showReplyBoxIndex, setShowReplyBoxIndex] = useState(-1);
  const [applicants, setApplicants] = useState([]);

  const [contract, setContract] = useState(false);

  const [newComment, setNewComment] = useState(null);
  const [commentReply, setCommentReply] = useState(null);
  const [newCommentLoading, setNewCommentLoading] = useState(false);
  const { Option } = Select;
  const [candidateFeedback, setCandidateFeedback] = useState({
    id: null,
    name: "",
    indexScore: null,
    applicantID: "",
  });
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [recommend, setRecommend] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [rating, setRating] = useState(0);
  const [recommendedFeedback, setRecommendedFeedback] = useState(null);
  const [interviewerExists, setInterviewerExists] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [interviewerLoading, setInterviewerLoading] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    loading: false,
  });

  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [relevanceFilter, setRelevanceFilter] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortingOption, setSortingOption] = useState("1");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    document.body.classList.add("candidates");
    return () => {
      document.body.classList.remove("candidates");
    };
  }, []);

  const [board, setBoard] = useState({
    columns: [
      {
        id: "column-1", // Set the ID for the Favorited column
        title: "Shortlisted",
        cards: [],
      },
      {
        id: "column-2", // Set the ID for the Shortlist column
        title: "Interviewed",
        cards: [],
      },
      {
        id: "column-3", // Set the ID for the Interviewed column
        title: "Selected",
        cards: [],
      },
      {
        id: "column-4", // Set the ID for the Selected column
        title: "Offer Extended",
        cards: [],
      },
      {
        id: "column-5", // Set the ID for the Selected column
        title: "Rejected",
        cards: [],
      },
    ],
  });

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  const itemsInterview = [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
    },
  ];

  const [selectedAtsCandidates, setSelectedAtsCandidates] = useState([]);
  const [requestLoader, setRequestLoader] = useState(false);

  const handleCheckboxChange1 = (item) => {
    // alert(item);
    console.log("items check",item);
    
    if (selectedAtsCandidates.includes(item)) {
      setSelectedAtsCandidates(
        selectedAtsCandidates.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedAtsCandidates([...selectedAtsCandidates, item]);
    }
  };

  const handleButtonClick = async () => {
    try {
      setRequestLoader(true);
      const payloadCandidates = selectedAtsCandidates.map((item) => ({
        enterpriseID: userData?._id,
        applicationID: item.applicantID,
        score: "requested",
        firstName: item.name,
        emailAddress: item.candidateEmail,
      }));

      const payloadScore = {
        enterpriseId: userData.companyID,
        applicants: payloadCandidates,
      };
      const responseScoreCandidate = await requestScore(payloadScore);
      if (responseScoreCandidate.data.statusCode == 1) {
        enqueueSnackbar("Request sent", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        setRequestLoader(false);
        setSelectedAtsCandidates([]);
        fetchApplications();
        handleOk();
        navigate(`/hr/dashboard/jobs/${id}`);
      }
    } catch (error) {
      console.error("Error while requesting scores:", error);
    }
  };

  const handleRadioChange = (event) => {
    console.log("selected data in feedback", event.target.value);
    setSelectedFeedback(event.target.value);
  };

  const handleTabClick = (tabIndex) => {
    setTabIndex(tabIndex);
    updatingBoard();
  };

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleSubmitFeedback = async () => {
    setFeedbackLoading(true);
    try {
      const InterviewerFeedback = {
        interviewerID: userData?.companyID,
        interviewerName: localStorage.getItem("userProfileName"),
        candidateID: candidateFeedback.id,
        candidateName: candidateFeedback.name,
        recommendation: recommend,
        feedback: selectedFeedback,
        rating: rating,
        feedbackComment: recommendedFeedback,
      };

      const payloadFeedback = {
        _id: candidateFeedback.applicantID,
        feedback: InterviewerFeedback,
      };

      const responseFeedbackSubmit = await updateApplicantsFeedback(
        payloadFeedback
      );
      if (responseFeedbackSubmit) {
        enqueueSnackbar("Feedback Submitted!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setFeedbackLoading(false);

        navigate("/hr/dashboard/jobs");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      enqueueSnackbar("An error occurred while submitting feedback", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setFeedbackLoading(false);
    }
  };

  // console.log("props from jobs enterprise" + JSON.stringify());

  const dropfav_func = () => {
    if (isMediumScreen) {
      setDropfav(!dropfav);
    }
  };
  const droplist_func = () => {
    if (isMediumScreen) {
      setDroplist(!droplist);
    }
  };
  const dropinterview_func = () => {
    if (isMediumScreen) {
      setDropinterview(!dropinterview);
    }
  };

  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (flag) => {
    setOpen1(flag);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", filterClick);
    return () => {
      window.removeEventListener("mousedown", filterClick);
    };
  });

  const filterDrop = () => {
    setFilter(!filter);
    setOpen1(!open1);
    // console.log("open:" + open1 + "filter:" + filter);
  };
  function filterClick(event) {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilter(false);
    }
  }
  useEffect(() => {
    filterRef.current = document.getElementById("filter_btn");
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    }
    if (isSmallScreen) {
      setDropfav(true);
      setDroplist(false);
      setDropinterview(false);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);
  // var allcandidates;
  var myObject;
  var myObjectEnterprise;
  // const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isMediumScreen) {
      setDropfav(false);
      setDroplist(false);
      setDropinterview(true);
    } else {
      setDropfav(true);
      setDroplist(true);
      setDropinterview(true);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetchJobs();
      await fetchApplications();
      updatingBoard();
      // fetchComments();
    }

    fetchData();
  }, []);

  // console.log("job data from jobs-----------------" + JSON.stringify(props));
  const fetchJobs = async () => {
    handleChange("loading", true);
    try {
      var payload = {
        _id: id,
      };
      const response = await getJobById(payload);
      myObject = response.data.data.find((obj) => obj._id === id);
      // console.log("myObject", myObject);
      setMyObjectData(myObject);
      handleChange("loading", false);
    } catch (error) {
      console.log(error);
      handleChange("loading", false);
    }
  };

  const fetchComments = async () => {
    try {
      handleChange("loading", true);
      const commentsPayload = {
        jobID: id,
      };

      const commentsResponse = await getComments(commentsPayload);
      if (commentsResponse.status == 200) {
        comments = commentsResponse.data.data;
        handleChange("loading", false);
      }
    } catch (error) {
      console.error("Error while fetching comments:", error);
      handleChange("loading", false);
      // You can handle the error here, for example, showing a notification to the user
      enqueueSnackbar(error, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const createNewComment = async () => {
    try {
      handleChange("newCommentLoading", true);

      const payloadComment = {
        jobID: id,
        content: newComment,
        author: {
          name: localStorage.getItem("userProfileName"),
          id: userData?.companyID,
        },
      };

      const newCommentResponse = await createComment(payloadComment);
      
      if (newCommentResponse.data.statusCode == 1) {
        enqueueSnackbar("Comment Posted!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        await fetchJobs();
        await fetchApplications();
        await fetchComments();
        setNewComment("");
        handleChange("newCommentLoading", false);
      }
    } catch (error) {
      console.error("Error while creating new comment:", error);
      handleChange("newCommentLoading", false);
      enqueueSnackbar("Failed to post comment. Please try again later.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setSortingOption(key.key);
  };

  const toggleReplyBox = (index) => {
    setShowReplyBoxIndex((prevIndex) => (prevIndex === index ? -1 : index));
    setCommentReply(""); // Clear the input box content when toggling
  };

  const createCommentReply = async (replyId) => {    
    try {
      handleChange("newCommentLoading", true);

      const payloadComment = {
        commentId: replyId,
        content: commentReply,
        author: {
          name: localStorage.getItem("userProfileName"),
          id: userData?.companyID,
        },
      };

      const replyResponse = await createReply(payloadComment);
      if (replyResponse.data.statusCode == 1) {
        enqueueSnackbar("Comment Replied", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        await fetchJobs();
        await fetchApplications();
        await fetchComments();
        setShowReplyBoxIndex(-1);
        setCommentReply(null);

        handleChange("newCommentLoading", false);
      }
    } catch (error) {
      console.error("Error while creating new comment:", error);
      handleChange("newCommentLoading", false);
      enqueueSnackbar("Failed to post comment. Please try again later.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const fetchApplications = async () => {
    handleChange("loading", true);
    try {
      // console.log("id parameter" + id);

      var payload = {
        jobID: id,
      };
      const response = await getApplications(payload);
      const applicantsData = response.data.data.slice().reverse();
      
      setApplicants(applicantsData);
      if (applicantsData) {
        fetchedCandidatesList = applicantsData.map((person) => ({
          id: person.candidateID,
          applicantID: person._id,
          cat: "all",
          relevanceScoreVal: Math.round(person.relevanceScore?.[payload.jobID]),
          tagClass:
            Math.round(person.relevanceScore?.[payload.jobID]) <= 33
              ? "low"
              : Math.round(person.relevanceScore?.[payload.jobID]) > 33 &&
                Math.round(person.relevanceScore?.[payload.jobID]) <= 66
              ? "average"
              : "high",
          tagTitle:
            Math.round(person.relevanceScore?.[payload.jobID]) <= 33
              ? "low"
              : Math.round(person.relevanceScore?.[payload.jobID]) > 33 &&
                Math.round(person.relevanceScore?.[payload.jobID]) <= 66
              ? "average"
              : "high",
          name: person.candidateName,
          pronouns: "He/him",
          date: person.createdAt,
          indexCore: person.score ? person.kycVerification ? Math.round(person.indexScore) : 2: 1 ,
          CultureFit: person.score ? person.kycVerification ? Math.round(person.culturefit?.[person.company_Name]) : 2: 1 ,
          indexColor: "main",
          candidatePhoneNumber: "+" + person.candidatePhoneNumber,
          candidateEmail: person.candidateEmail,
          jobLocation: person.company_Name,
          country: person.country
            ? Country.find((country) => country.code === person.country)?.name
            : "United States",
          jobCompany: person.company_Name,
          jobId: person.jobID,
          jobTitle: person.job_title,
          resumeUrl: person.candidateResume.Key,
          managerID: person.managerID,
          color: "red",
          team_members: person.team_members,
          interviewers: person.interviewersID,
          status: person.status,
          applicationData: person,
          show_indexscore: person.show_indexscore,
          score_request: person.score ? person.score : false,
          kycVerification: person.kycVerification
            ? person.kycVerification
            : false,
          Descriptions:
            "BreifCover letter of job/position here of job/position here Description of job/position here Description here of job/position here. Description of job/position here of job/position here Description of job/position here.",
          profileImage: person.candidateData
            ? person.candidateData.profileImage.docs
            : "/assets/images/user.svg",
        }));
      }

      handleChange("loading", false);
    } catch (error) {
      console.log(error);
      handleChange("loading", false);
    }
  };

  const handleDownload = async (pdfUrl) => {    
    var payloadAttachment = {
      Key: pdfUrl,
    };
    try {
      const response = await downloadAttachment(payloadAttachment).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "resume.pdf");
          document.body.appendChild(link);
          link.click();
        }
      );

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const favouriteCandidate = async (candidateID) => {
    try {
      var payloadFavourite = {
        _id: candidateID,
        status: "favourite",
      };
      const response = await updateCandidateStatus(payloadFavourite);
      if (response.data.statusCode == 1) {
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchJobs();
        fetchApplications();
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const shortlistedCandidate = async (candidateID) => {
    try {
      var payloadShortlist = {
        _id: candidateID,
        // usertype: "professional",
        status: "shortlisted",
      };

      const response = await updateCandidateStatus(payloadShortlist);
      if (response.data.statusCode == 1) {
        updatingBoard();
        fetchJobs();
        fetchApplications();

        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        // handleTabClick(0);

        // console.log("response from payloadShortlist api" + JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const interviewCandidate = async (candidateID) => {
    try {
      var payloadShortlist = {
        _id: candidateID,
        // usertype: "professional",
        status: "interviewed",
      };
      // console.log("response from payload Shortliste api" + JSON.stringify(payloadShortlist));
      const response = await updateCandidateStatus(payloadShortlist);
      if (response.data.statusCode == 1) {
        fetchJobs();
        fetchApplications();
        updatingBoard();
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        // handleTabClick(0);

        // console.log("response from payloadShortlist api" + JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  const selectedCandidate = async (candidateID) => {
    // console.log("response from payloadFavourite api" + candidateID);

    try {
      var payloadShortlist = {
        _id: candidateID,
        // usertype: "professional",
        status: "selected",
      };
      // console.log("response from payload Shortliste api" + JSON.stringify(payloadShortlist));
      const response = await updateCandidateStatus(payloadShortlist);
      if (response.data.statusCode == 1) {
        fetchJobs();
        fetchApplications();
        updatingBoard();
        // handleTabClick(0);
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        // console.log("response from payloadShortlist api" + JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const rejectCandidate = async (candidateID) => {
    // alert(candidateID);
    try {
      var payloadRejected = {
        _id: candidateID,
        // usertype: "professional",
        status: "rejected",
      };
      const response = await updateCandidateStatus(payloadRejected);
      if (response.data.statusCode == 1) {
        fetchJobs();
        fetchApplications();
        updatingBoard();
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const offeredCandidate = async (candidateID) => {
    // alert(candidateID);
    try {
      var payloadOffered = {
        _id: candidateID,
        status: "offered",
      };
      const response = await updateCandidateStatus(payloadOffered);
      if (response.data.statusCode == 1) {
        fetchJobs();
        fetchApplications();
        updatingBoard();
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const filteredCandidates = fetchedCandidatesList.filter((candidate) =>
    candidate.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [slidervalue, onChangeSlider] = useState(0);
  const [purposeValue, setPurposeValue] = useState(0);
  const [relevanceValue, setRelevanceValue] = useState(0);
  const [newFilteredCandidates, setNewFilteredCandidates] = useState([]);

  const handleRelevanceFilter = (value) => {
    setRelevanceFilter(value);
  };

  const clearRelevanceFilter = () => {
    setRelevanceFilter(null);
  };

  const getFiltered = () => {
    const sortedCandidates = fetchedCandidatesList
    .filter(
      (candidate) =>
        (candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          candidate.tagTitle.toLowerCase().includes(searchQuery.toLowerCase())) &&
         candidate.indexCore >= slidervalue && candidate.CultureFit >= purposeValue &&
        candidate.relevanceScoreVal >= relevanceValue
    ).sort((a, b) => {
            console.log("Sorting option:", sortingOption);
            const sortingNumber = parseInt(sortingOption, 10); // Convert to number
            if (sortingNumber === 1) {
                // Sort by most recent
                return new Date(b.date) - new Date(a.date);
            } else if (sortingNumber === 2) {
                // Sort by oldest
                return new Date(a.date) - new Date(b.date);
            }
            // Default sorting
            return 0;
        });
    console.log("sortedCandidates", sortedCandidates);
    return sortedCandidates;
  };

  useEffect(() => {
    const result = getFiltered();
    setNewFilteredCandidates(result);
  }, [
    searchQuery,
    slidervalue,
    fetchedCandidatesList,
    purposeValue,
    relevanceValue,
    sortingOption,
  ]);

  const updateCandidateStage = async (candidateID, status) => {
    try {
      var payloadShortlist = {
        _id: candidateID,
        status: status,
      };

      const response = await updateCandidateStatus(payloadShortlist);
      if (response.data.statusCode == 1) {
        await fetchJobs();
        await fetchApplications();
        const newData = fetchedCandidatesList.filter(
          (candidate) => candidate.applicantID === candidateID
        );
        showProfile(newData[0]);
        await updatingBoard();
        enqueueSnackbar(`Status Updated`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        // handleTabClick(0);

        // console.log("response from payloadShortlist api" + JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  const tableColumns = [
    {
      title: (
        <>
          Candidates{" "}
          <span className="fw-normal">
            ({newFilteredCandidates ? newFilteredCandidates.length : ""})
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Relevence",
      width: 100,
      dataIndex: "relevance",
      key: "relevance",
      sorter: (a, b) => a.relevance - b.relevance,
      render: (relevance) => <span>{relevance}%</span>,
    },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "3",
      width: 110,
      sorter: (a, b) => a.indexscore - b.indexscore,
      render: (indexscore, candidate) => (
        <div className="indexScore__box fm">
          {indexscore === 2 ? (
            <span className="text-14 fm darkGrey">Pending</span>
          ) : (
            <>
              {indexscore > 2 ? (
                <div
                  className={`score__text d-inline darkGrey rounded-pill prog-${
                    (parseInt(indexscore) / 850) * 100 <= 50 ? "red" : ""
                  } prog-${
                    (parseInt(indexscore) / 850) * 100 > 50 ? "main" : ""
                  } text-13 fm fw-semibold`}
                >
                  {indexscore}
                </div>
              ) : (
                <Checkbox
                  className="fm"
                  onChange={() => handleCheckboxChange1(candidate.candidateData)}
                  checked={selectedAtsCandidates.includes(candidate.candidateData)}
                >
                  Request
                </Checkbox>
              )}
            </>
          )}
        </div>
      ),
      
    },
    {
    title: "candidateData",
    key: "candidateData",
    dataIndex: "candidateData",
    hidden: true
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "4",
      width: 90,
      sorter: (a, b) => a.purpose - b.purpose,
      render: (purpose) => (
        <>
          {userData.subscription.name === "Predict" ||
          userData.subscription.name === "Scale" ? purpose ===1 || purpose ===2 ?
          'Pending' : (
            <div
              className={`score__text d-inline darkGrey rounded-pill prog-${
                (parseInt(purpose) / 100) * 100 <= 50 ? "red" : ""
              } 
                prog-${
                  (parseInt(purpose) / 100) * 100 > 50 ? "main" : ""
                } text-13 fm fw-semibold`}
            >
              {purpose}%
            </div>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <CiLock className="lock-item fs-6" />
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: "Verified",
      dataIndex: "verified",
      width: 80,
      key: "2",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 150,
      key: "2",
      sorter: (a, b) => {
        const locationA = (a.location || "").toLowerCase();
        const locationB = (b.location || "").toLowerCase();
        return locationA.localeCompare(locationB);
      },
      render: (location) => (
        <div>
          <span className="job__type rounded-pill darkBlue text-13">
            {location}
          </span>
        </div>
      ),
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      width: 100,
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = (a.status || "").toLowerCase();
        const statusB = (b.status || "").toLowerCase();
        return statusA.localeCompare(statusB);
      },
      render: (status) => (
        <div>
          <span
            className={`job__status rounded-pill text-capitalize ${
              status === "selected" && "status__selected"
            } 
            ${status === "shortlisted" && "status__grey"}
            ${status === "interviewed" && "status__interviewed"}
            ${status === "offered" && "status__live"}
            ${status === "pending" && "status__hold"} ${
              status === "rejected" && "status__closed"
            }`}
          >
            {status}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];
  const tableColumnsShortlisted = [
    {
      title: (
        <>
          Candidates{" "}
          <span className="fw-normal">
            (
            {newFilteredCandidates
              ? newFilteredCandidates.filter(
                  (candidate) =>
                    candidate.status === "shortlisted" ||
                    candidate.status === "interviewed" ||
                    candidate.status === "selected" ||
                    candidate.status === "offered"
                ).length
              : ""}
            )
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Relevence",
      width: 100,
      dataIndex: "relevance",
      key: "relevance",
      sorter: (a, b) => a.relevance - b.relevance,
      render: (relevance) => <span>{relevance}%</span>,
    },
    {
      title: "candidateData",
      key: "candidateData",
      dataIndex: "candidateData",
      hidden: true
      },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "3",
      width: 110,
      sorter: (a, b) => a.indexscore - b.indexscore,
      render: (indexscore, candidate) => (
        <div className="indexScore__box fm">
          {indexscore === 2 ? (
            <span className="text-14 fm darkGrey">Pending</span>
          ) : (
            <>
              {indexscore > 2 ? (
                <div
                  className={`score__text d-inline darkGrey rounded-pill prog-${
                    (parseInt(indexscore) / 850) * 100 <= 50 ? "red" : ""
                  } prog-${
                    (parseInt(indexscore) / 850) * 100 > 50 ? "main" : ""
                  } text-13 fm fw-semibold`}
                >
                  {indexscore}
                </div>
              ) : (
                <Checkbox
                  className="fm"
                  onChange={() => handleCheckboxChange1(candidate.candidateData)}
                  checked={selectedAtsCandidates.includes(candidate.candidateData)}
                >
                  Request
                </Checkbox>
              )}
            </>
          )}
        </div>
      ),
      
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "4",
      width: 90,
      sorter: (a, b) => a.purpose - b.purpose,
      render: (purpose) => (
        <>
          {userData.subscription.name === "Predict" ||
          userData.subscription.name === "Scale" ? purpose ===1 || purpose ===2 ?
          'Pending' : (
            <div
              className={`score__text d-inline darkGrey rounded-pill prog-${
                (parseInt(purpose) / 100) * 100 <= 50 ? "red" : ""
              } 
                prog-${
                  (parseInt(purpose) / 100) * 100 > 50 ? "main" : ""
                } text-13 fm fw-semibold`}
            >
              {purpose}%
            </div>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <CiLock className="lock-item fs-6" />
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: "Verified",
      dataIndex: "verified",
      width: 80,
      key: "2",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 150,
      key: "2",
      sorter: (a, b) => {
        const locationA = (a.location || "").toLowerCase();
        const locationB = (b.location || "").toLowerCase();
        return locationA.localeCompare(locationB);
      },
      render: (location) => (
        <div>
          <span className="job__type rounded-pill darkBlue text-13">
            {location}
          </span>
        </div>
      ),
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      width: 100,
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = (a.status || "").toLowerCase();
        const statusB = (b.status || "").toLowerCase();
        return statusA.localeCompare(statusB);
      },
      render: (status) => (
        <div>
          <span
            className={`job__status rounded-pill text-capitalize ${
              status === "selected" && "status__selected"
            } 
            ${status === "shortlisted" && "status__grey"}
            ${status === "interviewed" && "status__interviewed"}
            ${status === "offered" && "status__live"}
            ${status === "pending" && "status__hold"} ${
              status === "rejected" && "status__closed"
            }`}
          >
            {status}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];

  const tableColumnsRejected = [
    {
      title: (
        <>
          Candidates{" "}
          <span className="fw-normal">
            (
            {newFilteredCandidates
              ? newFilteredCandidates.filter(
                  (candidate) => candidate.status === "rejected"
                ).length
              : ""}
            )
          </span>
        </>
      ),
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Relevence",
      width: 100,
      dataIndex: "relevance",
      key: "relevance",
      sorter: (a, b) => a.relevance - b.relevance,
      render: (relevance) => <span>{relevance}%</span>,
    },
    {
      title: "candidateData",
      key: "candidateData",
      dataIndex: "candidateData",
      hidden: true
      },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "3",
      width: 110,
      sorter: (a, b) => a.indexscore - b.indexscore,
      render: (indexscore, candidate) => (
        <div className="indexScore__box fm">
          {indexscore === 2 ? (
            <span className="text-14 fm darkGrey">Pending</span>
          ) : (
            <>
              {indexscore > 2 ? (
                <div
                  className={`score__text d-inline darkGrey rounded-pill prog-${
                    (parseInt(indexscore) / 850) * 100 <= 50 ? "red" : ""
                  } prog-${
                    (parseInt(indexscore) / 850) * 100 > 50 ? "main" : ""
                  } text-13 fm fw-semibold`}
                >
                  {indexscore}
                </div>
              ) : (
                <Checkbox
                  className="fm"
                  onChange={() => handleCheckboxChange1(candidate.candidateData)}
                  checked={selectedAtsCandidates.includes(candidate.candidateData)}
                >
                  Request
                </Checkbox>
              )}
            </>
          )}
        </div>
      ),
      
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "4",
      width: 90,
      sorter: (a, b) => a.purpose - b.purpose,
      render: (purpose) => (
        <>
          {userData.subscription.name === "Predict" ||
          userData.subscription.name === "Scale" ? purpose ===1 || purpose ===2 ?
          'Pending' : (
            <div
              className={`score__text d-inline darkGrey rounded-pill prog-${
                (parseInt(purpose) / 100) * 100 <= 50 ? "red" : ""
              } 
                prog-${
                  (parseInt(purpose) / 100) * 100 > 50 ? "main" : ""
                } text-13 fm fw-semibold`}
            >
              {purpose}%
            </div>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <CiLock className="lock-item fs-6" />
            </Tooltip>
          )}
        </>
      )
    },
    {
      title: "Verified",
      dataIndex: "verified",
      width: 80,
      key: "2",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 150,
      key: "2",
      sorter: (a, b) => {
        const locationA = (a.location || "").toLowerCase();
        const locationB = (b.location || "").toLowerCase();
        return locationA.localeCompare(locationB);
      },
      render: (location) => (
        <div>
          <span className="job__type rounded-pill darkBlue text-13">
            {location}
          </span>
        </div>
      ),
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      width: 100,
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 100,
      key: "5",
      sorter: (a, b) => {
        const statusA = (a.status || "").toLowerCase();
        const statusB = (b.status || "").toLowerCase();
        return statusA.localeCompare(statusB);
      },
      render: (status) => (
        <div>
          <span
            className={`job__status rounded-pill text-capitalize ${
              status === "selected" && "status__selected"
            } 
            ${status === "shortlisted" && "status__grey"}
            ${status === "interviewed" && "status__interviewed"}
            ${status === "offered" && "status__live"}
            ${status === "pending" && "status__hold"} ${
              status === "rejected" && "status__closed"
            }`}
          >
            {status}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 60,
      fixed: "right",
    },
  ];

  const formattedCandidates = newFilteredCandidates.map((candidate, index) => ({
    key: candidate.id,
    candidateData:candidate,
    name: (
      <>
        <div
          className="user__box pointer"
          // ref={buttonRef}
          onClick={() => showProfile(candidate)}
        >
          <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center ">
            <img
              src={candidate.profileImage}
              alt=""
              className="candidates__avatar--img img-fluid"
            />
          </div>
          <div className="Experts__name fm mb-0 ms-2">
            <h6 className="mb-0 me-1 text-14">{candidate.name}</h6>{" "}
          </div>
        </div>        
      </>
    ),
    relevance: candidate.relevanceScoreVal, // Assuming tagTitle holds the relevance percentage
    indexscore: candidate.indexCore,
    purpose: candidate.CultureFit,
    verified: (
      <div className="verified__box">
        <div
          className={`${candidate.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
        >
          <HiOutlineCheckCircle
            className={`${candidate.tagClass}__icon fs-4`}
          />
        </div>
      </div>
    ),
    location: candidate.country ? candidate.country : "United States",
    status: candidate.status,
    date: moment(candidate.date).format("L"),
    action: (
      <>
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {/* {candidate.status === "applied" && ( */}
              <Menu.Item
                key="shortlist"
                onClick={() => shortlistedCandidate(candidate.applicantID)}
              >
                Shortlist
              </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "shortlisted" && ( */}
              <Menu.Item
                key="interview"
                onClick={() => interviewCandidate(candidate.applicantID)}
              >
                Interviewed
              </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "interviewed" && ( */}
              <Menu.Item
                key="interviewed"
                onClick={() => selectedCandidate(candidate.applicantID)}
              >
                Select
              </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "selected" && ( */}
              <Menu.Item
                key="selected"
                onClick={() => offeredCandidate(candidate.applicantID)}
              >
                Extend
              </Menu.Item>
              {/* )} */}
              <Menu.Item
                key="Reject"
                onClick={() => rejectCandidate(candidate.applicantID)}
              >
                Reject
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
    candidateData:candidate
  }));

  const formattedCandidatesShortlisted = newFilteredCandidates
    .filter(
      (candidate) =>
        candidate.status === "shortlisted" ||
        candidate.status === "interviewed" ||
        candidate.status === "selected" ||
        candidate.status === "offered"
    )
    .map((candidate, index) => ({
      key: candidate.id,
      candidateData:candidate,
      name: (
        <>
          <div
            className="user__box pointer"
            onClick={() => showProfile(candidate)}
          >
            <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center ">
              <img
                src={candidate.profileImage}
                alt=""
                className="candidates__avatar--img img-fluid"
              />
            </div>
            <div className="Experts__name fm mb-0 ms-2">
              <h6 className="mb-0 me-1 text-14">{candidate.name}</h6>{" "}
            </div>
          </div>
        </>
      ),
      relevance: candidate.relevanceScoreVal, // Assuming tagTitle holds the relevance percentage
    indexscore: candidate.indexCore,
    purpose: candidate.CultureFit,
    verified: (
      <div className="verified__box">
        <div
          className={`${candidate.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
        >
          <HiOutlineCheckCircle
            className={`${candidate.tagClass}__icon fs-4`}
          />
        </div>
      </div>
    ),
    location: candidate.country ? candidate.country : "United States",
    status: candidate.status,
    date: moment(candidate.date).format("L"),
    action: (
      <>
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {/* {candidate.status === "applied" && ( */}
                <Menu.Item
                  key="shortlist"
                  onClick={() => shortlistedCandidate(candidate.applicantID)}
                >
                  Shortlist
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "shortlisted" && ( */}
                <Menu.Item
                  key="interview"
                  onClick={() => interviewCandidate(candidate.applicantID)}
                >
                  Interviewed
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "interviewed" && ( */}
                <Menu.Item
                  key="interviewed"
                  onClick={() => selectedCandidate(candidate.applicantID)}
                >
                  Select
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "selected" && ( */}
                <Menu.Item
                  key="selected"
                  onClick={() => offeredCandidate(candidate.applicantID)}
                >
                  Extend
                </Menu.Item>
              {/* )} */}
              <Menu.Item
                key="Reject"
                onClick={() => rejectCandidate(candidate.applicantID)}
              >
                Reject
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
    candidateID: candidate.id
    }));

  const formattedCandidatesRejected = newFilteredCandidates
    .filter((candidate) => candidate.status === "rejected")
    .map((candidate, index) => ({
      key: candidate.id,
      name: (
        <>
          <div
            className="user__box pointer"
            onClick={() => showProfile(candidate)}
          >
            <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center ">
              <img
                src={candidate.profileImage}
                alt=""
                className="candidates__avatar--img img-fluid"
              />
            </div>
            <div className="Experts__name fm mb-0 ms-2">
              <h6 className="mb-0 me-1 text-14">{candidate.name}</h6>{" "}
            </div>
          </div>
        </>
      ),
      relevance: candidate.relevanceScoreVal, // Assuming tagTitle holds the relevance percentage
    indexscore: candidate.indexCore,
    purpose: candidate.CultureFit,
    verified: (
      <div className="verified__box">
        <div
          className={`${candidate.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
        >
          <HiOutlineCheckCircle
            className={`${candidate.tagClass}__icon fs-4`}
          />
        </div>
      </div>
    ),
    location: candidate.country ? candidate.country : "United States",
    status: candidate.status,
    date: moment(candidate.date).format("L"),
    action: (
      <>
        <Dropdown
          placement="top"
          arrow={{ pointAtCenter: true }}
          overlay={
            <Menu>
              {/* {candidate.status === "applied" && ( */}
                <Menu.Item
                  key="shortlist"
                  onClick={() => shortlistedCandidate(candidate.applicantID)}
                >
                  Shortlist
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "shortlisted" && ( */}
                <Menu.Item
                  key="interview"
                  onClick={() => interviewCandidate(candidate.applicantID)}
                >
                  Interviewed
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "interviewed" && ( */}
                <Menu.Item
                  key="interviewed"
                  onClick={() => selectedCandidate(candidate.applicantID)}
                >
                  Select
                </Menu.Item>
              {/* )} */}
              {/* {candidate.status === "selected" && ( */}
                <Menu.Item
                  key="selected"
                  onClick={() => offeredCandidate(candidate.applicantID)}
                >
                  Extend
                </Menu.Item>
              {/* )} */}
              <Menu.Item
                key="Reject"
                onClick={() => rejectCandidate(candidate.applicantID)}
              >
                Reject
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button className="candidates__menu">
            <BsThreeDotsVertical className="text-decoration-none" />
          </Button>
        </Dropdown>
      </>
    ),
    }));

  const filteredApplicantsInterview =
    applicants &&
    applicants.filter((item) => {
      const candidateName = item.candidateName
        ? item.candidateName.toLowerCase()
        : "";
      const searchQueryLower = searchInterviewFeedbackQuery.toLowerCase();
      return candidateName.includes(searchQueryLower);
    });

  const teamColumns = [
    {
      title: (
        <>
          Team members{" "}
          <span className="fw-normal">
            (
            {myObjectData["team_members"] &&
              myObjectData["team_members"].length}
            )
          </span>
        </>
      ),
      width: 120,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Department",
      dataIndex: "department",
      width: 80,
      key: "2",
      sorter: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 65,
      key: "3",
      sorter: true,
    },
  ];

  const formattedTeam =
    myObjectData["team_members"] &&
    myObjectData["team_members"].map((member, index) => ({
      key: index + 1,
      name: (
        <>
          <div className="user__box pointer">
            <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
              <img
                src={`/assets/images/user.svg`}
                alt=""
                className=" candidates__avatar--img2 img-fluid"
              />
            </div>
            <div className="Experts__name fm mb-0 ms-2">
              <h6 className="mb-0 me-1 text-14">
                {member.firstName} {member.lastName}
              </h6>{" "}
            </div>
          </div>
        </>
      ),
      department: (
        <div>
          <span className="job__type rounded-pill darkGrey text-13">
            {member.department}{" "}
            {/* Assuming location data is not available in the given team_members data */}
          </span>{" "}
        </div>
      ),
      role: (
        <div>
          <button className="download__button text-14 d-flex justify-content-between text-capitalize align-items-center ps-xl-3 ps-2 py-1">
            {member.role}
          </button>
        </div>
      ),
    }));

  favourites = fetchedCandidatesList.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      candidate.status === "favourite" // Replace 'favorite' with the desired status value
  );

  favouritesDND = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "favourite" // Replace 'favorite' with the desired status value
  );

  offeredCandidates = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "offered" // Replace 'favorite' with the desired status value
  );
  // console.log("favourites list" + favouritesDND);

  shortLists = fetchedCandidatesList.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      candidate.status === "shortlisted" // Replace 'favorite' with the desired status value
  );

  shortListsDND = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "shortlisted" // Replace 'favorite' with the desired status value
  );
  // console.log("shortLists list" , shortListsDND);

  interViewedList = fetchedCandidatesList.filter(
    (candidate) =>
      (candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        candidate.status === "Interviewd") ||
      candidate.status === "interviewed" // Replace 'favorite' with the desired status value
  );

  interViewedListDND = fetchedCandidatesList.filter(
    (candidate) =>
      candidate.status === "interviewd" || candidate.status === "interviewed" // Replace 'favorite' with the desired status value
  );
  // console.log("interViewedList list" + interViewedList);
  rejectedCand = fetchedCandidatesList.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      candidate.status === "rejected" // Replace 'favorite' with the desired status value
  );

  rejectedCandDND = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "rejected" // Replace 'favorite' with the desired status value
  );
  // console.log("rejected list" + favourites);

  selectedList = fetchedCandidatesList.filter(
    (candidate) =>
      candidate.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
      candidate.status === "selected" // Replace 'favorite' with the desired status value
  );

  selectedListDND = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "selected" // Replace 'favorite' with the desired status value
  );

  offeredCandidates = fetchedCandidatesList.filter(
    (candidate) => candidate.status === "offered" // Replace 'favorite' with the desired status value
  );
  // checkMeAsInterviewer();

  // console.log("selectedListDND", selectedListDND);

  const checkMeAsInterviewer = () => {
    const enterpriseID = userData?.companyID;
    // console.log("selectedListDND", selectedListDND);
    if (interViewedListDND && Array.isArray(interViewedListDND)) {
      const foundMember = interViewedListDND.some((item) => {
        return (
          item.interviewers &&
          Array.isArray(item.interviewers) &&
          item.interviewers.some((member) => member.id === enterpriseID)
        );
      });

      // console.log("foundMember", foundMember);

      if (foundMember) {
        setInterviewerLoading(true);
        setInterviewerExists(true);
        // console.log('Your ID exists in at least one interviewers list.');
      } else {
        setInterviewerLoading(false);
        setInterviewerExists(false);
        // console.log('Your ID does not exist in any interviewers list.');
      }
    } else {
      console.log(
        "selectedListDND is not properly defined or is not an array."
      );
    }
  };

  const updatingBoard = () => {
    console.log("call hua hy");
    setBoard((prevBoard) => {
      const updatedColumns = prevBoard.columns.map((column) => {
        let updatedCards = column.cards;

        if (column.id === "column-1") {
          updatedCards = shortListsDND.map((item) => ({
            id: item.id,
            title: item.name,
            description: "Index Score " + item.indexCore,
            assigneeId: item.applicantID,
            completeData: item,
          }));
        } else if (column.id === "column-2") {
          updatedCards = interViewedListDND.map((item) => ({
            id: item.id,
            title: item.name,
            description: "Index Score " + item.indexCore,
            assigneeId: item.applicantID,
            completeData: item,
          }));
        } else if (column.id === "column-3") {
          updatedCards = selectedListDND.map((item) => ({
            id: item.id,
            title: item.name,
            description: "Index Score " + item.indexCore,
            assigneeId: item.applicantID,
            completeData: item,
          }));
        } else if (column.id === "column-4") {
          updatedCards = offeredCandidates.map((item) => ({
            id: item.id,
            title: item.name,
            description: "Index Score " + item.indexCore,
            assigneeId: item.applicantID,
            completeData: item,
          }));
        } else if (column.id === "column-5") {
          updatedCards = rejectedCandDND.map((item) => ({
            id: item.id,
            title: item.name,
            description: "Index Score " + item.indexCore,
            assigneeId: item.applicantID,
            completeData: item,
          }));
        }

        return {
          ...column,
          title: column.title, // You can keep the title the same if it's not changing
          cards: updatedCards,
        };
      });

      return {
        ...prevBoard,
        columns: updatedColumns,
      };
    });
  };

  const handleCardMove = async (
    sourceColumnId,
    destinationColumnId,
    sourceCardId,
    destinationCardId,
    source
  ) => {
    const getdestination = destinationCardId;

    if (destinationCardId.toColumnId == "column-1") {
      await shortlistedCandidate(destinationColumnId.assigneeId);
    }

    if (destinationCardId.toColumnId == "column-2") {
      await interviewCandidate(destinationColumnId.assigneeId);
    }
    if (destinationCardId.toColumnId == "column-3") {
      //interviewed column
      await selectedCandidate(destinationColumnId.assigneeId);
      // alert("destinationColumnId"+ destinationColumnId.assigneeId);
    }
    if (destinationCardId.toColumnId == "column-4") {
      //selected column
      await offeredCandidate(destinationColumnId.assigneeId);
      // alert("destinationColumnId"+ destinationColumnId.assigneeId);
    }
    if (destinationCardId.toColumnId == "column-5") {
      //selected column
      await rejectCandidate(destinationColumnId.assigneeId);
      // alert("destinationColumnId"+ destinationColumnId.assigneeId);
    }

    updatingBoard();
    // if(destinationCardId.toColumnId=='column-5'){
    //   // rejected column

    //   // alert("destinationColumnId"+ destinationColumnId.assigneeId);
    // }

    // Perform any other logic or state updates based on the card movement
    // ...
  };

  const { loading } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const allCandidates = () => {
    setAll(true);
    setFav(false);
    setRejected(false);
    setShortListed(false);
    setSelected(false);
  };
  const favCandidates = () => {
    setAll(false);
    setFav(true);
    setRejected(false);
    setShortListed(false);
    setSelected(false);
  };

  const shortListCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(false);
    setShortListed(true);
    setSelected(false);
  };

  const rejectedCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(true);
    setShortListed(false);
    setSelected(false);
  };

  const SelectedCandidates = () => {
    setAll(false);
    setFav(false);
    setRejected(false);
    setShortListed(false);
    setSelected(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setClickedIndex(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const showProfile = (key) => {
    // console.log("current candidate" + JSON.stringify(key));
    setCandidate(key);
    Profile();
    setClickedIndex(null);
  };

  const Profile = () => {
    setConfirm(false);
    setCandidatesAvailability(false);
    setProfile(true);
    setMeeting(false);
    setTime(false);
    setContract(false);
    setAnchorEl(null);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const Contract = () => {
    setConfirm(false);
    setCandidatesAvailability(false);
    setProfile(false);
    setMeeting(false);
    setTime(false);
    setContract(true);
    setAnchorEl(null);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  if (profile) {
    document.body.style.background = "#F8F8F8";
  } else document.body.style.background = "#fff";

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // add member states and code
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [editJobLoader, setEditJobLoader] = useState(false);
  const [options, setOptions] = useState([]);

  const [editAddMemberVisible, setEditAddMemberVisible] = useState(false);

  const EditAddMemberModal = () => {
    setEditAddMemberVisible(true);
  };
  const EditAddMemberhandleOk = () => {
    setEditAddMemberVisible(false); // Close the modal after submission
  };
  const EditAddMemberhandleCancel = () => {
    setEditAddMemberVisible(false); // Close the modal without saving changes
  };

  useEffect(() => {
    if (myObjectData) {
      setState((prevState) => ({
        ...prevState,
        team_members:
          myObjectData.team_members &&
          myObjectData.team_members
            .filter(
              (members) =>
                members.firstName !== null && members.firstName.trim() !== " "
            )
            .map((members) => ({
              label: members.firstName,
              value: members._id,
            })),
      }));
    }
  }, [myObjectData]);

  const [stateTeam, setStateTeam] = useState({
    team_members: [],
  });
  const { job_title, team_members } = state;

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const myId = userData?.companyID;
      const payloadGetMember = {
        enterpriseID: myId,
      };

      const response = await getMembersByCompany(payloadGetMember);
      setEnterpriseList(response.data.data);

      const newOptions = response.data.data
        .filter(
          (existingMembers) =>
            existingMembers._id !== myId &&
            existingMembers.firstName !== null &&
            existingMembers.firstName.trim() !== " "
        )
        .map((existingMembers) => ({
          label: existingMembers.firstName,
          value: existingMembers._id,
        }));
      setOptions(newOptions);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTeamMembersChange = (newTeamMembers) => {
    setState((prevState) => ({
      ...prevState,
      team_members: newTeamMembers,
    }));
  };

  const handleDropChange = (value) => {
    const selectedObject = options.find((obj) => obj.value === value);
    if (selectedObject) {
      const updatedTeamMembers = [...team_members, selectedObject];
      handleTeamMembersChange(updatedTeamMembers);
    }
  };

  const handleRemoveDrop = (value) => {
    const indexToRemove = team_members.findIndex((obj) => obj.value === value);
    if (indexToRemove !== -1) {
      const updatedTeamMembers = [...team_members];
      updatedTeamMembers.splice(indexToRemove, 1);
      handleTeamMembersChange(updatedTeamMembers);
    }
  };

  const updateJobHandler = async () => {
    try {
      setEditJobLoader(true);

      const matchedData = team_members.map((itemToMatch) => {
        const matchedObject = enterpriseList.find(
          (obj) => obj._id === itemToMatch.value
        );
        if (matchedObject) {
          return matchedObject;
        }
        return null; // If no match is found, you can handle it accordingly
      });

      const jobData = {
        jobId: id,
        team_members: matchedData,
      };

      const responseUpdateJob = await UpdateJob(jobData);
      if (responseUpdateJob.statusCode === 1) {
        setEditJobLoader(false);
        EditAddMemberhandleOk();

        enqueueSnackbar("Member added successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        fetchJobs();
        // navigate('/hr/dashboard/jobs');
      } else {
        setEditJobLoader(false);
      }
    } catch (error) {
      console.error("Error updating job:", error);
      setEditJobLoader(false); // Stop loader in case of an error
      // Handle error as needed, e.g., show an error message to the user
    }
  };

  // add member states and code end

  const items = [
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">IndexScore Range</p>
          {/* <Slider range defaultValue={[20, 50]} /> */}
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="850"
              value={slidervalue}
              onChange={({ target: { value } }) => {
                onChangeSlider(value);
                const ele = document.querySelector(".buble");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble">{slidervalue}</div>
          </div>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">
            Purpose Alignment Score
          </p>
          {userData.subscription.name === "Predict" ||
          userData.subscription.name === "Scale" ? (
            <div className="slider-parent">
              <input
                type="range"
                min="1"
                max="100"
                value={purposeValue}
                onChange={({ target: { value } }) => {
                  setPurposeValue(value);
                  console.log("purpose value", value);
                  const ele = document.querySelector(".buble1");
                  if (ele) {
                    ele.style.left = `${Number(value / 4)}px`;
                  }
                }}
              />
              <div className="buble1">{purposeValue}</div>
            </div>
          ) : (
            <Tooltip
              title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
              placement="top"
            >
              <CiLock className="fs-6 grey69 mb-1" />
            </Tooltip>
          )}
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">Job Relevance </p>
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="100"
              value={relevanceValue}
              onChange={({ target: { value } }) => {
                setRelevanceValue(value);
                console.log("relevance value", value);
                const ele = document.querySelector(".buble1");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble1">{relevanceValue}</div>
          </div>
        </div>
      ),
      key: "3",
    },
  ];

  const editProfile = (
    <Menu>
      <Menu.Item key="1">
        <p className="fm darkGrey mb-0">Edit</p>
      </Menu.Item>
      <Menu.Item key="2">
        <p className="fm darkGrey mb-0">Delete</p>
      </Menu.Item>
    </Menu>
  );

  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedCandidates.length;

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  return (
    <div className={`${profile ? "bg-lightgrey" : ""}`}>
      <style>
        {`:where(.css-dev-only-do-not-override-1e3x2xa).ant-avatar >img {object-fit: contain;
            width: 65%;
            margin: auto;}          
            .filters__group__btn3 {
              // border-left: 0.5px solid #41414080;
              border-right: 0.5px solid #41414080;
          }
          .react-kanban-board{
            max-width:1000px;
          }
          .react-kanban-column-header__spacer>span:last-child {
            display: none;
        }
        .candidates__avatar--img{
          width: 40px;
          height: 40px !important;
          border-radius: 50%;
          object-fit: cover;
        }
        .candidates__avatar--img2{
          width: 35px;
        }
        .candidates__avatar{
          padding-top:0px;
        }
        
        .react-kanban-card__title>span:last-child {
            display: none;
        }    

        
      
      .react-kanban-card__title {
          font-weight: 500;
      }

      .star-rating-container {
        
        margin: 20px 0;
      }

      .custom-card {
        background: #fff;
        min-width: 200px;
        margin-bottom: 5px;
    }
    
    .custom-card h3 {
        font-size: 16px;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        margin: 0px;
    }
    
      
      .star-rating {
        font-size: 30px;
        margin-bottom:0px;
      }

      .pipeline-link {
        background: #fff;
        font-size: 14px;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
    }
    
    .pipeline-link img {
        width: 14px;
        margin-left: 5px;
    }
      
      .star {
        cursor: pointer;
        color: gray;
      }
      
      .selected {
        color: #14a800;
      }
      .filled{
        color:#b2da66;
        font-weight:500;
      }
      .filled img{
        width:14px;
      }
      .popup-details-box li .item-label {display: inline-block;width: 40%;}

.popup-details-box {
    list-style: none;
    padding: 0px;
}

.popup-details-box li .position-relative {
    width: 60%;
    display: inline-block;
}

.popup-details-box li {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.slider-parent{
  position:relative;
}

.slider-parent input[type="range"] {
  background: #91caff;
  border-radius: 20px;
  width: 100%;
  height: 4px;
  position: relative;
  z-index: 2;
}
.buble {
  position: absolute;
  z-index: 2;
  display: block;
}

.showmore-button {
  background: none;
  color: #0d7cca;
  padding: 0px;
}
      
        }`}
      </style>
      <div className="edash edash2">
        <div className={`container-dash pt-4`}>
          {/* <small className="jobs__content__para text-14 fm midGrey fm pe-3 mt-lg-0 mt-5">
          Jobs <span className="mx-1 fm">{">"}</span>{" "}
          <span
            className={`${candidatesAvailability ? "darkGrey" : "midGrey"} fm`}
          >
            {" "}
            Job overview
          </span>
          {profile && (
            <span className={`darkGrey fm`}>
              {" "}             
              <span className="mx-1">{}</span>{" "}
              {candidate ? (
                <span className="text-capitalize"></span>
              ) : (
                "Ben Smith profile"
              )}
            </span>
          )}
          {meeting && (
            <span className={` midGrey fm`}>
              {" "}
              <span className="mx-1">{">"}</span>{" "}
              {candidate
                ? <span className="text-capitalize">--{candidate.name}</span> +
                " profile"
                : "Ben Smith profile"}{" "}
              <span className={`darkGrey fm`}>
                {" "}
                <span className="mx-1">{">"}</span> Set a meeting
              </span>
            </span>
          )}
          {time && (
            <span className={` midGrey fm`}>
              {" "}
              <span className="mx-1">{">"}</span> Ben Smith profile{" "}
              <span className={`darkGrey fm`}>
                {" "}
                <span className="mx-1">{">"}</span> Set a meeting
              </span>
            </span>
          )}
          {detail && (
            <span className={` midGrey fm`}>
              {" "}
              <span className="mx-1">{">"}</span> Ben Smith profile{" "}
              <span className={`darkGrey fm`}>
                {" "}
                <span className="mx-1">{">"}</span> Set a meeting
              </span>
            </span>
          )}
          {confirm && (
            <span className={` midGrey fm`}>
              {" "}
              <span className="mx-1">{">"}</span> Ben Smith profile{" "}
              <span className={`darkGrey fm`}>
                {" "}
                <span className="mx-1">{">"}</span> Set a meeting
              </span>
            </span>
          )}
        </small> */}
        </div>
      </div>
      {candidatesAvailability && (
        <div className="cand">
          <div className="Dashboard__container">
            <div className="container-dash pt-lg-4 pb-4">
              <div className="candidate pb-4">
                {loading ? (
                  <Loader size="md" className="pt-4" content="loading" />
                ) : (
                  <div className="candidate__content p-sm-4 p-3">
                    <h1 className="candidate__content__left__heading text-capitalize fw-semibold fs-3 fm">
                      {myObjectData["job_title"]}
                    </h1>
                    <div className="d-md-flex align-items-start justify-content-between">
                      <div className="candidate__content__left me-md-4">
                        <p
                          className="candidate__content__left__para mb-0"
                          id="jobDescription"
                        >
                          {myObjectData["job_role_text"]}
                        </p>
                        <button className="showmore-button">
                          <Link
                            to={`/hr/dashboard/jobs/edit-job/${id}`}
                            className="candidate__content__left__para__note text-decoration-none transition  mb-0"
                          >
                            <HiOutlineExclamationCircle className="fs-3" />{" "}
                            <span>View/edit full job description</span>{" "}
                          </Link>
                        </button>
                        {/* <p className="candidate__content__left__para__note d-md-block d-none">
                        <HiOutlineExclamationCircle className="fs-3" />{" "}
                        <span>View/edit full job description</span>{" "}
                      </p> */}
                      </div>
                      <div className="enterprise candidate__content__right mt-md-0 mt-3 ">
                        <div className="candidate__content__right__items d-flex justify-content-between">
                          <div className="candidate__content__right__iconContainer d-flex align-items-center">
                            <SlLocationPin className="candidate__content__right__iconContainer__icon me-2 fs-4" />
                            <p className="candidate__content__right__iconContainer__para m-0 text-14">
                              {/* Remote */}
                              {myObjectData &&
                                myObjectData["employment_type"]?.map((item) => (
                                  <div>{item}</div>
                                ))}
                            </p>
                          </div>
                          <div className="candidate__content__right__iconContainer d-flex align-items-center ">
                            <p className="candidate__content__right__iconContainer__para text-14 m-0">
                              <span className="fm darkGrey text-14">
                                IndexScore:
                              </span>{" "}
                              <span className="candidate__content__main fw-semibold">
                                {myObjectData["min_index_core"]}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="candidate__content__right__items">
                          <div className="candidate__content__right__iconContainer d-flex align-items-center mt-3">
                            <SlGlobe className="candidate__content__right__iconContainer__icon me-2 fs-4" />
                            <p className="candidate__content__right__iconContainer__para m-0 text-14">
                              {myObjectData["department"]}
                            </p>
                          </div>
                          <div className="candidate__content__right__iconContainer d-flex align-items-center  mt-3">
                            <p className="candidate__content__right__iconContainer__para text-14 m-0">
                              <span className=" fm darkGrey text-14">
                                {" "}
                                Purpose:
                              </span>{" "}
                              <span className="fw-semibold darkGrey">
                                {myObjectData["min_culture_fit_score"]}%
                              </span>
                            </p>
                          </div>
                        </div>
                        {/* <div className="candidate__content__right__items mt-1">
                          <div className="candidate__content__right__iconContainer d-flex align-items-center mt-3">
                            <p className="candidate__content__right__iconContainer__para m-0">
                              <span className="candidate__content__label">
                                Posted:
                              </span>{" "}
                              {moment(myObjectData["start_date"]).format(
                                "MMMM DD YYYY"
                              )}
                            </p>
                          </div>
                          <div className="candidate__content__right__iconContainer d-flex align-items-center mt-3">
                            <p className="candidate__content__right__iconContainer__para m-0">
                              <span className="candidate__content__label">
                                Closing:
                              </span>{" "}
                              {moment(myObjectData["end_date"]).format(
                                "MMMM DD YYYY"
                              )}
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className="d-md-flex py-3 mb-4 align-items-center candidates__email justify-content-between">
                <div className="avatar__left">
                  <h6 className="fs-6 fm darkBlue mb-0 pb-1 fw-semibold">
                    Role Interviewers:
                  </h6>
                  <p className="fm darkGrey mb-0 text-14">
                    These are your colleagues invited to interview applicants if
                    necessary. You can add/delete from the role edit page under
                    Open Roles in the main menu
                  </p>
                </div>
                <div className="d-flex mt-md-0 mt-3 align-items-center">
                  <Avatar.Group
                    maxCount={3}
                    maxPopoverTrigger="click"
                    size={{
                      xs: 40,
                      sm: 40,
                      md: 40,
                      lg: 55,
                      xl: 55,
                      xxl: 55,
                    }}
                    gap="0"
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      cursor: "pointer",
                    }}
                    className="candidates__avatarGroup fm me-3"
                  >
                    {myObjectData &&
                      myObjectData["team_members"]?.map((item) => (
                        <Tooltip
                          title={item.firstName + item.lastName}
                          placement="top"
                        >
                          <Avatar
                            src={`/assets/images/user.svg`}
                            size={{
                              xs: 40,
                              sm: 40,
                              md: 40,
                              lg: 55,
                              xl: 55,
                              xxl: 55,
                            }}
                            className="border bg-lightGrey"
                          />
                        </Tooltip>
                      ))}
                    
                  </Avatar.Group>
                  <p className="darkGrey fm text-14 mb-0 d-flex flex-wrap">
                    
                    {myObjectData &&
                      myObjectData["team_members"]?.map((item) => (
                        <span style={{ marginRight: "10px" }}>
                          {item.firstName + item.lastName},
                        </span>
                      ))}
                  </p>
                </div>
                <div>
                  <button
                    onClick={EditAddMemberModal}
                    className="btn-primary text-decoration-none nowrap mt-md-0 mt-2 button__to__add__member"
                  >
                    Add Member
                  </button>

                  <Modal
                    className="certification-popup"
                    title="Add interview members"
                    visible={editAddMemberVisible}
                    onOk={EditAddMemberhandleOk}
                    onCancel={EditAddMemberhandleCancel}
                    footer={null}
                    width={600}
                  >
                    <div className="add-cert-form personal__information edit-name-popup">
                      <div className="row">
                        <div className="col-md-12">
                          <Select
                            className="Login--Input mx-0 d-block Login--select"
                            mode="multiple"
                            allowClear
                            style={{
                              width: "100%",
                              maxWidth: "650px",
                            }}
                            placeholder="Invited Members"
                            onSelect={handleDropChange}
                            onDeselect={handleRemoveDrop}
                            options={options}
                            value={
                              team_members &&
                              team_members.map((member) => member.value)
                            }
                          />
                        </div>
                        <div className="col-md-12 d-flex justify-content-end mt-5">
                          <Button
                            className="primary cancel-button"
                            style={{ marginRight: "8px" }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="desc__applynow"
                            onClick={updateJobHandler}
                          >
                            {editJobLoader ? <Loader /> : "Update"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div> */}
            </div>
          </div>
          <div className="">
            <Tabs
              className="jobs__tabs Ejobs__tabs candidates__tabs"
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              {interviewerExists && interviewerLoading ? (
                <TabList>
                  <Tab onClick={() => handleTabClick(0)}>Candidates</Tab>
                  {interViewedListDND.length > 0 && interviewerExists ? (
                    <Tab onClick={() => handleTabClick(4)}>Feedback</Tab>
                  ) : (
                    ""
                  )}
                </TabList>
              ) : (
                ""
              )}

              {myObjectData["manager"] == userData?.companyID ? (
                <TabList>
                  <Tab onClick={() => handleTabClick(0)}>Candidates</Tab>
                  <Tab onClick={() => handleTabClick(1)}>Pipeline</Tab>
                  <Tab onClick={() => handleTabClick(2)}>Interviewers</Tab>
                  <Tab onClick={() => handleTabClick(2)}>Team</Tab>
                </TabList>
              ) : (
                ""
              )}

              {/* <TabList>
           {selectedListDND.length > 0 && interviewerExists ?
               '' : <Tab onClick={() => handleTabClick(1)}>Pipeline</Tab>}
             {selectedListDND.length > 0 && interviewerExists ?
               '' : <Tab onClick={() => handleTabClick(2)}>Team</Tab> }
               {selectedListDND.length > 0 && interviewerExists ?
               '' :
              <Tab onClick={() => handleTabClick(3)}>Comments {comments.length > 0 ? "(" + comments.length + ")" : ''}</Tab>
               }
              {selectedListDND.length > 0 && interviewerExists ?
                <Tab onClick={() => handleTabClick(4)}>Feedback</Tab> :
                ''
              }
            </TabList>  */}

              <TabPanel>
                <div className="Dashboard__container container-dash">
                  <div className="Experts__container candidates pb-4  mt-2">
                    <Radio.Group
                      defaultValue="candidates"
                      className="jobd__btn me-2 d-xl-none mb-2"
                      size={"large"}
                    >
                      <Radio.Button
                        value="candidates"
                        onClick={allCandidates}
                        className="h-100"
                      >
                        Candidates
                      </Radio.Button>
                      <Radio.Button
                        value="favorites"
                        onClick={shortListCandidates}
                        className="h-100"
                      >
                        Favorites
                      </Radio.Button>
                      <Radio.Button
                        value="rejected"
                        onClick={rejectedCandidates}
                        className="h-100"
                      >
                        Rejected
                      </Radio.Button>
                    </Radio.Group>
                    <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                      <div className="d-flex candidates candidates__opt justify-content-sm-start justify-content-between">
                        <Radio.Group
                          defaultValue="candidates"
                          className="jobd__btn me-2 d-xl-block d-none"
                          size={"large"}
                        >
                          <Radio.Button
                            value="candidates"
                            onClick={allCandidates}
                            className="h-100"
                          >
                            Candidates
                          </Radio.Button>
                          <Radio.Button
                            value="favorites"
                            onClick={shortListCandidates}
                            className="h-100"
                          >
                            Favorites
                          </Radio.Button>
                          <Radio.Button
                            value="rejected"
                            onClick={rejectedCandidates}
                            className="h-100"
                          >
                            Rejected
                          </Radio.Button>
                        </Radio.Group>
                        <div>
                          <Dropdown
                            menu={{
                              items: sortingMenus,
                              onClick: handleSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottomLeft"
                            trigger={["click"]}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingMenus.find(
                                      (menu) => menu.key === sortingOption
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                          </Dropdown>
                        </div>
                        <div className=" d-block h-100">
                          <Dropdown
                            ref={filterRef}
                            menu={{
                              items,
                              onClick: handleMenuClick,
                            }}
                            onOpenChange={handleOpenChange}
                            open={open1}
                            trigger={["click"]}
                            overlayClassName={"dropdown__filter"}
                          >
                            <button
                              className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition"
                              onClick={filterDrop}
                              style={{
                                border: filter ? "0.5px solid #5F89FF" : "",
                                color: filter ? "#5F89FF" : "",
                              }}
                              id="filter_btn"
                              ref={filterRef}
                            >
                              <BsFilter className="fs-4 candidates__filter__icon" />{" "}
                              Filter
                            </button>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                        <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search..."
                          />
                        </div>
                        <button
                          className="bluegradient__btn jobd__btn text-whitefm transition me-2  candidate__score__button "
                          disabled={
                            selectedAtsCandidates.length === 0 || requestLoader
                          }
                          // onClick={handleButtonClick}
                          onClick={showModal}
                        >
                          Request Score
                        </button>

                        <Modal
                          title="Scoring"
                          className="scoring__popup"
                          open={isModalOpen}
                          footer={null}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          <div className="scoring__popup__body">
                            <h4>
                              You’ve selected{" "}
                              <strong>{selectedAtsCandidates.length}</strong>{" "}
                              candidates to score
                            </h4>
                            <p>Approximate cost: $139.50 per candidate</p>
                            <button
                              disabled={
                                selectedAtsCandidates.length === 0 ||
                                requestLoader
                              }
                              onClick={() => {
                                handleButtonClick();
                              }}
                              className="bluegradient__btn text-whitefm transition me-2 w-100"
                            >
                              {requestLoader ? <Loader /> : "Score Candidates"}
                            </button>
                            <p className="scoring__popup__pricing">
                              Learn about our{" "}
                              <Link className="text-decoration-none">
                                cost & pricing
                              </Link>
                            </p>
                          </div>
                          <div className="scoring__popup__footer">
                            <p>
                              All your login details are stored in your browser,
                              you are able to remove any connected app or API
                              with in your API tab
                            </p>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    {all && (
                      <div>
                        {loading ? (
                          <div className="align-items-center d-md-flex d-none">
                            <Loader size="md" content="loading" />
                          </div>
                        ) : (
                          <>
                            <div className="table__outer table__outer__pro d-md-block d-none rounded-3">
                              <Table
                                className="dash__table"
                                columns={tableColumns}
                                dataSource={formattedCandidates}
                                pagination={false}
                                scroll={{
                                  x: 992,
                                }}
                              />
                            </div>
                            {/* {newFilteredCandidates.length > 0 && (
                              <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                                <button className="fm text-12 table__bottom__btn1">
                                  Load all candidates (
                                  {newFilteredCandidates.length})
                                </button>
                                <button className="fm text-12 table__bottom__btn2">
                                  Scroll to load more{" "}
                                  <HiOutlineArrowDownCircle className="fs-6" />
                                </button>
                              </div>
                            )} */}
                          </>
                        )}

                        <div className="d-md-none">
                          {loading ? (
                            <div className="align-items-center d-md-none d-flex">
                              <Loader size="md" content="loading" />
                            </div>
                          ) : (
                            newFilteredCandidates.map((item, index) => (
                              <div
                                className={`row d-md-none d-flex enterprise Experts__row ${
                                  item.cat === "fav"
                                    ? "candidates__fav"
                                    : "candidates__all"
                                } mx-0 mt-2 py-xl-2 py-4 my-3 transition pointer`}
                                key={item.id}
                              >
                                <div className="col-10 pe-xl-0 order-1 pb-xl-0 pb-3">
                                  <div className="d-flex align-items-center w-100">
                                    <div className="d-flex align-items-center">
                                      <div
                                        className="d-flex align-items-center me-xl-0 me-2"
                                        onClick={() => showProfile(item)}
                                      >
                                        <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                          <img
                                            src={item.profileImage}
                                            alt=""
                                            className="candidates__avatar--img img-fluid"
                                          />
                                        </div>
                                        <div className="Experts__name fm mb-0 ms-2">
                                          <h6 className="mb-0 me-1">
                                            {item.name}
                                          </h6>{" "}
                                          <span>({item.pronouns})</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-3">
                                  <h1 className=" fw-semibold text-14 me-2 mb-0">
                                    Location
                                  </h1>
                                  <span className="text-14 enterprise__experts Experts__date fm">
                                    {item.country}
                                  </span>
                                </div>
                                <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-1">
                                  <h1 className=" fw-semibold text-14 me-2 mb-0">
                                    Applied Date
                                  </h1>
                                  <span className="text-14 enterprise__experts Experts__date fm">
                                    {moment(candidate.date).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </div>

                                <div className="col-xl-2 col-sm-6 order-xl-3 order-4 pe-1 d-flex align-items-self justify-content-center flex-column  my-3">
                                  <div className="indexCore__score">
                                    <div className="fm d-flex align-items-center">
                                      <span className=" text-14 fw-semibold pe-2">
                                        IndexScore
                                      </span>
                                      {item?.score_request ? (
                                        item?.kycVerification ? (
                                          <div
                                            className={`score__text darkGrey rounded-pill prog-${
                                              (parseInt(item.indexCore) / 850) *
                                                100 <=
                                              50
                                                ? "red"
                                                : ""
                                            }  prog-${
                                              (parseInt(item.indexCore) / 850) *
                                                100 >
                                              50
                                                ? "main"
                                                : ""
                                            } text-13 fm fw-semibold`}
                                          >
                                            {item.indexCore}
                                          </div>
                                        ) : (
                                          <span className="text-14 fm darkGrey">
                                            Pending
                                          </span>
                                        )
                                      ) : (
                                        <>
                                          <Checkbox
                                            className="fm"
                                            onChange={() =>
                                              handleCheckboxChange1(item)
                                            }
                                            checked={selectedAtsCandidates.includes(
                                              item
                                            )}
                                          >
                                            Request
                                          </Checkbox>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-2 col-sm-6 pe-xl-0 order-md-4 order-5 d-flex me-0 align-items-self justify-content-center flex-column ps-xl-4 me-auto">
                                  <div className="fm d-flex align-items-center">
                                    <span className=" text-14 fw-semibold">
                                      Purpose Alignment
                                    </span>
                                    {userData.subscription.name === "Predict" ||
                                    userData.subscription.name === "Scale" ? item.CultureFit ===1 || item.CultureFit ===2 ?
                                    'Pending' : (
                                      <div
                                        className={`score__text darkGrey rounded-pill prog-${
                                          (parseInt(item.CultureFit) / 100) *
                                            100 <=
                                          50
                                            ? "red"
                                            : ""
                                        } 
                                          prog-${
                                            (parseInt(item.CultureFit) / 100) *
                                              100 >
                                            50
                                              ? "main"
                                              : ""
                                          } text-13 fm fw-semibold`}
                                      >
                                        {item.CultureFit}%
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                                        placement="top"
                                      >
                                        <CiLock className="lock-item ms-2" />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>

                                <div className="col-xl-2 col-12 order-xl-5 order-last ps-xxl-2 ps-xl-4 order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-center justify-content-end">
                                  <span
                                    className={`tag-${item.tagClass} fm tag-14 d-xl-block d-none text-capitalize rounded-pill`}
                                  >
                                    {item.tagTitle}
                                  </span>
                                  <div className="d-xl-none d-flex justify-content-sm-start justify-content-between mt-xl-0 mt-4">
                                    <div className="me-sm-4 d-flex flex-column justify-content-between align-items-center">
                                      <h1 className=" fw-semibold text-14 d-xl-none">
                                        Role Relevance
                                      </h1>
                                      <span
                                        className={`tag-${item.tagClass} fm tag-14 text-capitalize rounded-pill`}
                                      >
                                        {item.tagTitle}
                                      </span>
                                    </div>
                                    <div className="mx-3 d-flex flex-column justify-content-between align-items-center">
                                      <h1 className=" fw-semibold text-14 d-xl-none">
                                        Verified
                                      </h1>
                                      <div
                                        className={`${item.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                      >
                                        {item.tagClass === "high" && (
                                          <HiOutlineCheckCircle className="high__icon fs-4" />
                                        )}
                                        {item.tagClass === "average" && (
                                          <HiOutlineQuestionMarkCircle className="high__icon fs-4" />
                                        )}
                                        {item.tagClass === "low" && (
                                          <HiOutlineExclamationCircle className="high__icon fs-4" />
                                        )}
                                      </div>
                                    </div>
                                    <div className="ms-sm-4 d-flex flex-column justify-content-between pb-1 align-items-start">
                                      <h1 className=" fw-semibold text-14 d-xl-none">
                                        Status
                                      </h1>
                                      <span
                                        className={`job__status rounded-pill text-capitalize ${
                                          item.status === "selected" &&
                                          "status__selected"
                                        } 
                                          ${
                                            item.status === "shortlisted" &&
                                            "status__grey"
                                          }
                                          ${
                                            item.status === "interviewed" &&
                                            "status__interviewed"
                                          }
                                          ${
                                            item.status === "offered" &&
                                            "status__live"
                                          }
                                          ${
                                            item.status === "pending" &&
                                            "status__hold"
                                          } 
                                          ${
                                            item.status === "rejected" &&
                                            "status__closed"
                                          }`}
                                      >
                                        {item.status}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2">
                                  <div
                                    className={`${"high"} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                  >
                                    <HiOutlineCheckCircle className="high__icon fs-4" />
                                  </div>
                                </div>
                                <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2 text-capitalize">
                                  <span className="fm darkGrey text-13 text-end ps-4">
                                    {item.status}
                                  </span>
                                </div>
                                {selectedListDND.length > 0 &&
                                interviewerExists ? (
                                  <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                    &nbsp;{" "}
                                  </div>
                                ) : (
                                  <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                    <img
                                      src={`/assets/images/Dashboard/fav_${
                                        item.cat === "fav"
                                          ? "selected"
                                          : "unselected"
                                      }.svg`}
                                      alt=""
                                      className="me-2 candidates__icon1"
                                    />
                                    <img
                                      src={`/assets/images/Dashboard/settings_more_${
                                        item.cat === "fav"
                                          ? "selected"
                                          : "unselected"
                                      }.svg`}
                                      alt=""
                                      className="img-fluid candidates__icon2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    />
                                    <ul class="dropdown-menu dropdown-menu-end">
                                      {/* {item.status === "applied" && ( */}
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          onClick={() =>
                                            shortlistedCandidate(
                                              item.applicantID
                                            )
                                          }
                                          type="button"
                                        >
                                          Shortlist
                                        </button>
                                      </li>
                                      {/* )} */}
                                      {/* {item.status === "shortlisted" && ( */}
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          onClick={() =>
                                            interviewCandidate(item.applicantID)
                                          }
                                          type="button"
                                        >
                                          Interviewed
                                        </button>
                                      </li>
                                      {/* )} */}
                                      {/* {item.status === "interviewed" && ( */}
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          onClick={() =>
                                            selectedCandidate(item.applicantID)
                                          }
                                          type="button"
                                        >
                                          Select
                                        </button>
                                      </li>
                                      {/* )} */}
                                      {/* {item.status === "selected" && ( */}
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          onClick={() =>
                                            offeredCandidate(item.applicantID)
                                          }
                                          type="button"
                                        >
                                          Extend
                                        </button>
                                      </li>
                                      {/* )} */}
                                      <li>
                                        <button
                                          class="dropdown-item"
                                          onClick={() =>
                                            rejectCandidate(item.applicantID)
                                          }
                                          type="button"
                                        >
                                          Reject
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))
                          )}
                          {/* {newFilteredCandidates.length > 0 && (
                            <div className="d-flex justify-content-center pt-3 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates (
                                {newFilteredCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )} */}
                        </div>
                      </div>
                    )}

                    {shortListed && (
                      <div>
                        {loading ? (
                          <div className="align-items-center d-md-flex d-none">
                            <Loader size="md" content="loading" />
                          </div>
                        ) : (
                          <>
                            <div className="table__outer table__outer__pro d-md-block d-none rounded-3">
                              <Table
                                className="dash__table"
                                columns={tableColumnsShortlisted}
                                dataSource={formattedCandidatesShortlisted}
                                pagination={false}
                                scroll={{
                                  x: 992,
                                }}
                              />
                            </div>
                            {newFilteredCandidates.filter(
                              (candidate) =>
                                candidate.status === "shortlisted" ||
                                candidate.status === "interviewed" ||
                                candidate.status === "selected" ||
                                candidate.status === "offered"
                            ).length > 0 && (
                              <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                                {/* <button className="fm text-12 table__bottom__btn1">
                                  Load all candidates (
                                  {newFilteredCandidates.length})
                                </button>
                                <button className="fm text-12 table__bottom__btn2">
                                  Scroll to load more{" "}
                                  <HiOutlineArrowDownCircle className="fs-6" />
                                </button> */}
                              </div>
                            )}
                          </>
                        )}

                        <div className="d-md-none">
                          {loading ? (
                            <div className="align-items-center d-flex">
                              <Loader size="md" content="loading" />
                            </div>
                          ) : (
                            newFilteredCandidates
                              .filter(
                                (candidate) =>
                                  candidate.status === "shortlisted" ||
                                  candidate.status === "interviewed" ||
                                  candidate.status === "selected" ||
                                  candidate.status === "offered"
                              )
                              .map((item, index) => (
                                <div
                                  className={`row d-md-none d-flex enterprise Experts__row ${
                                    item.cat === "fav"
                                      ? "candidates__fav"
                                      : "candidates__all"
                                  } mx-0 mt-2 py-xl-2 py-4 my-3 transition pointer`}
                                  key={item.id}
                                >
                                  <div className="col-10 pe-xl-0 order-1 pb-xl-0 pb-3">
                                    <div className="d-flex align-items-center w-100">
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="d-flex align-items-center me-xl-0 me-2"
                                          onClick={() => showProfile(item)}
                                        >
                                          <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                            <img
                                              src={item.profileImage}
                                              alt=""
                                              className="candidates__avatar--img img-fluid"
                                            />
                                          </div>
                                          <div className="Experts__name fm mb-0 ms-2">
                                            <h6 className="mb-0 me-1">
                                              {item.name}
                                            </h6>{" "}
                                            <span>({item.pronouns})</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-3">
                                    <h1 className=" fw-semibold text-14 me-2 mb-0">
                                      Location
                                    </h1>
                                    <span className="text-14 enterprise__experts Experts__date fm">
                                      {item.country}
                                    </span>
                                  </div>
                                  <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-1">
                                    <h1 className=" fw-semibold text-14 me-2 mb-0">
                                      Applied Date
                                    </h1>
                                    <span className="text-14 enterprise__experts Experts__date fm">
                                      {moment(item.date).format("DD-MM-YYYY")}
                                    </span>
                                  </div>

                                  <div className="col-xl-2 col-sm-6 order-xl-3 order-4 pe-1 d-flex align-items-self justify-content-center flex-column  my-3">
                                    <div className="indexCore__score">
                                      <div className="fm d-flex align-items-center">
                                        <span className=" text-14 fw-semibold pe-2">
                                          IndexScore
                                        </span>
                                        {item?.score_request ? (
                                          item?.kycVerification ? (
                                            <div
                                              className={`score__text darkGrey rounded-pill prog-${
                                                (parseInt(item.indexCore) /
                                                  850) *
                                                  100 <=
                                                50
                                                  ? "red"
                                                  : ""
                                              }  prog-${
                                                (parseInt(item.indexCore) /
                                                  850) *
                                                  100 >
                                                50
                                                  ? "main"
                                                  : ""
                                              } text-13 fm fw-semibold`}
                                            >
                                              {item.indexCore}
                                            </div>
                                          ) : (
                                            <span className="text-14 fm darkGrey">
                                              Pending
                                            </span>
                                          )
                                        ) : (
                                          <>
                                            <Checkbox
                                              className="fm"
                                              onChange={() =>
                                                handleCheckboxChange1(item)
                                              }
                                              checked={selectedAtsCandidates.includes(
                                                item
                                              )}
                                            >
                                              Request
                                            </Checkbox>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-sm-6 pe-xl-0 order-md-4 order-5 d-flex me-0 align-items-self justify-content-center flex-column ps-xl-4 me-auto">
                                    <div className="fm d-flex align-items-center">
                                      <span className=" text-14 fw-semibold">
                                        Purpose Alignment
                                      </span>
                                      {userData.subscription.name ===
                                        "Predict" ||
                                      userData.subscription.name === "Scale" ? item.CultureFit ===1 || item.CultureFit ===2 ?
                                      'Pending' : (
                                        <div
                                          className={`score__text darkGrey rounded-pill prog-${
                                            (parseInt(item.CultureFit) / 100) *
                                              100 <=
                                            50
                                              ? "red"
                                              : ""
                                          } 
                                            prog-${
                                              (parseInt(item.CultureFit) /
                                                100) *
                                                100 >
                                              50
                                                ? "main"
                                                : ""
                                            } text-13 fm fw-semibold`}
                                        >
                                          {item.CultureFit}%
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                                          placement="top"
                                        >
                                          <CiLock className="lock-item ms-2" />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-xl-2 col-12 order-xl-5 order-last ps-xxl-2 ps-xl-4 order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-center justify-content-end">
                                    <span
                                      className={`tag-${item.tagClass} fm tag-14 d-xl-block d-none text-capitalize rounded-pill`}
                                    >
                                      {item.tagTitle}
                                    </span>
                                    <div className="d-xl-none d-flex justify-content-sm-start justify-content-between mt-xl-0 mt-4">
                                      <div className="me-sm-4 d-flex flex-column justify-content-between align-items-center">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Role Relevance
                                        </h1>
                                        <span
                                          className={`tag-${item.tagClass} fm tag-14 text-capitalize rounded-pill`}
                                        >
                                          {item.tagTitle}
                                        </span>
                                      </div>
                                      <div className="mx-3 d-flex flex-column justify-content-between align-items-center">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Verified
                                        </h1>
                                        <div
                                          className={`${item.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                        >
                                          {item.tagClass === "high" && (
                                            <HiOutlineCheckCircle className="high__icon fs-4" />
                                          )}
                                          {item.tagClass === "average" && (
                                            <HiOutlineQuestionMarkCircle className="high__icon fs-4" />
                                          )}
                                          {item.tagClass === "low" && (
                                            <HiOutlineExclamationCircle className="high__icon fs-4" />
                                          )}
                                        </div>
                                      </div>
                                      <div className="ms-sm-4 d-flex flex-column justify-content-between pb-1 align-items-start">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Status
                                        </h1>
                                        <span
                                          className={`job__status rounded-pill text-capitalize ${
                                            item.status === "selected" &&
                                            "status__selected"
                                          } 
                                          ${
                                            item.status === "shortlisted" &&
                                            "status__grey"
                                          }
                                          ${
                                            item.status === "interviewed" &&
                                            "status__interviewed"
                                          }
                                          ${
                                            item.status === "offered" &&
                                            "status__live"
                                          }
                                          ${
                                            item.status === "pending" &&
                                            "status__hold"
                                          } 
                                          ${
                                            item.status === "rejected" &&
                                            "status__closed"
                                          }`}
                                        >
                                          {item.status}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2">
                                    <div
                                      className={`${"high"} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                    >
                                      <HiOutlineCheckCircle className="high__icon fs-4" />
                                    </div>
                                  </div>
                                  <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2 text-capitalize">
                                    <span className="fm darkGrey text-13 text-end ps-4">
                                      {item.status}
                                    </span>
                                  </div>
                                  {selectedListDND.length > 0 &&
                                  interviewerExists ? (
                                    <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                      &nbsp;{" "}
                                    </div>
                                  ) : (
                                    <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                      <img
                                        src={`/assets/images/Dashboard/fav_${
                                          item.cat === "fav"
                                            ? "selected"
                                            : "unselected"
                                        }.svg`}
                                        alt=""
                                        className="me-2 candidates__icon1"
                                      />
                                      <img
                                        src={`/assets/images/Dashboard/settings_more_${
                                          item.cat === "fav"
                                            ? "selected"
                                            : "unselected"
                                        }.svg`}
                                        alt=""
                                        className="img-fluid candidates__icon2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      />
                                      <ul class="dropdown-menu dropdown-menu-end">
                                        {/* {item.status === "applied" && ( */}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              shortlistedCandidate(
                                                item.applicantID
                                              )
                                            }
                                            type="button"
                                          >
                                            Shortlist
                                          </button>
                                        </li>
                                        {/* )} */}
                                        {/* {item.status === "shortlisted" && ( */}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              interviewCandidate(
                                                item.applicantID
                                              )
                                            }
                                            type="button"
                                          >
                                            Interviewed
                                          </button>
                                        </li>
                                        {/* )} */}
                                        {/* {item.status === "interviewed" && ( */}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              selectedCandidate(
                                                item.applicantID
                                              )
                                            }
                                            type="button"
                                          >
                                            Select
                                          </button>
                                        </li>
                                        {/* )} */}
                                        {/* {item.status === "selected" && ( */}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              offeredCandidate(item.applicantID)
                                            }
                                            type="button"
                                          >
                                            Extend
                                          </button>
                                        </li>
                                        {/* )} */}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              rejectCandidate(item.applicantID)
                                            }
                                            type="button"
                                          >
                                            Reject
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              ))
                          )}
                          {newFilteredCandidates.filter(
                            (candidate) =>
                              candidate.status === "shortlisted" ||
                              candidate.status === "interviewed" ||
                              candidate.status === "selected" ||
                              candidate.status === "offered"
                          ).length > 0 && (
                            <div className="d-flex justify-content-center pt-3 gap-2">
                              {/* <button className="fm text-12 table__bottom__btn1">
                                Load all candidates (
                                {newFilteredCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button> */}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {rejected && (
                      <div>
                        {loading ? (
                          <div className="align-items-center d-md-flex d-none">
                            <Loader size="md" content="loading" />
                          </div>
                        ) : (
                          <>
                            <div className="table__outer table__outer__pro d-md-block d-none rounded-3">
                              <Table
                                className="dash__table"
                                columns={tableColumnsRejected}
                                dataSource={formattedCandidatesRejected}
                                pagination={false}
                                scroll={{
                                  x: 992,
                                }}
                              />
                            </div>
                            {newFilteredCandidates.filter(
                              (candidate) => candidate.status === "rejected"
                            ).length > 0 && (
                              <div className="d-md-flex d-none justify-content-center pt-4 gap-2">
                                {/* <button className="fm text-12 table__bottom__btn1">
                                  Load all candidates (
                                  {newFilteredCandidates.length})
                                </button>
                                <button className="fm text-12 table__bottom__btn2">
                                  Scroll to load more{" "}
                                  <HiOutlineArrowDownCircle className="fs-6" />
                                </button> */}
                              </div>
                            )}
                          </>
                        )}

                        <div className="d-md-none">
                          {loading ? (
                            <div className="align-items-center d-md-none d-flex justify-content-center">
                              <Loader size="md" content="loading" />
                            </div>
                          ) : (
                            newFilteredCandidates
                              .filter(
                                (candidate) => candidate.status === "rejected"
                              )
                              .map((item, index) => (
                                <div
                                  className={`row d-md-none enterprise Experts__row ${
                                    item.cat === "fav"
                                      ? "candidates__fav"
                                      : "candidates__all"
                                  } mx-0 mt-2 py-xl-2 py-4 my-3 transition pointer`}
                                  key={item.id}
                                >
                                  <div className="col-10 pe-xl-0 order-1 pb-xl-0 pb-3">
                                    <div className="d-flex align-items-center w-100">
                                      <div className="d-flex align-items-center">
                                        <div
                                          className="d-flex align-items-center me-xl-0 me-2"
                                          onClick={() => showProfile(item)}
                                        >
                                          <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center">
                                            <img
                                              src={item.profileImage}
                                              alt=""
                                              className="candidates__avatar--img img-fluid"
                                            />
                                          </div>
                                          <div className="Experts__name fm mb-0 ms-2">
                                            <h6 className="mb-0 me-1">
                                              {item.name}
                                            </h6>{" "}
                                            <span>({item.pronouns})</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-3">
                                    <h1 className=" fw-semibold text-14 me-2 mb-0">
                                      Location
                                    </h1>
                                    <span className="text-14 enterprise__experts Experts__date fm">
                                      {item.country}
                                    </span>
                                  </div>
                                  <div className="col-12 ps-xl-0 d-flex align-items-center order-3 mb-1">
                                    <h1 className=" fw-semibold text-14 me-2 mb-0">
                                      Applied Date
                                    </h1>
                                    <span className="text-14 enterprise__experts Experts__date fm">
                                      {moment(item.date).format("DD-MM-YYYY")}
                                    </span>
                                  </div>

                                  <div className="col-xl-2 col-sm-6 order-xl-3 order-4 pe-1 d-flex align-items-self justify-content-center flex-column  my-3">
                                    <div className="indexCore__score">
                                      <div className="fm d-flex align-items-center">
                                        <span className=" text-14 fw-semibold pe-2">
                                          IndexScore
                                        </span>
                                        {item?.score_request ? (
                                          item?.kycVerification ? (
                                            <div
                                              className={`score__text darkGrey rounded-pill prog-${
                                                (parseInt(item.indexCore) /
                                                  850) *
                                                  100 <=
                                                50
                                                  ? "red"
                                                  : ""
                                              }  prog-${
                                                (parseInt(item.indexCore) /
                                                  850) *
                                                  100 >
                                                50
                                                  ? "main"
                                                  : ""
                                              } text-13 fm fw-semibold`}
                                            >
                                              {item.indexCore}
                                            </div>
                                          ) : (
                                            <span className="text-14 fm darkGrey">
                                              Pending
                                            </span>
                                          )
                                        ) : (
                                          <>
                                            <Checkbox
                                              className="fm"
                                              onChange={() =>
                                                handleCheckboxChange1(item)
                                              }
                                              checked={selectedAtsCandidates.includes(
                                                item
                                              )}
                                            >
                                              Request
                                            </Checkbox>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-2 col-sm-6 pe-xl-0 order-md-4 order-5 d-flex me-0 align-items-self justify-content-center flex-column ps-xl-4 me-auto">
                                    <div className="fm d-flex align-items-center">
                                      <span className=" text-14 fw-semibold">
                                        Purpose Alignment
                                      </span>
                                      {userData.subscription.name ===
                                        "Predict" ||
                                      userData.subscription.name === "Scale" ?  item.CultureFit ===1 || item.CultureFit ===2 ?
                                      'Pending' :(
                                        <div
                                          className={`score__text darkGrey rounded-pill prog-${
                                            (parseInt(item.CultureFit) / 100) *
                                              100 <=
                                            50
                                              ? "red"
                                              : ""
                                          } 
                                            prog-${
                                              (parseInt(item.CultureFit) /
                                                100) *
                                                100 >
                                              50
                                                ? "main"
                                                : ""
                                            } text-13 fm fw-semibold`}
                                        >
                                          {item.CultureFit}%
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
                                          placement="top"
                                        >
                                          <CiLock className="lock-item ms-2" />
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-xl-2 col-12 order-xl-5 order-last ps-xxl-2 ps-xl-4 order-2 me-auto d-xl-flex align-items-xl-center align-items-start justify-content-xl-center justify-content-end">
                                    <span
                                      className={`tag-${item.tagClass} fm tag-14 d-xl-block d-none text-capitalize rounded-pill`}
                                    >
                                      {item.tagTitle}
                                    </span>
                                    <div className="d-xl-none d-flex justify-content-sm-start justify-content-between mt-xl-0 mt-4">
                                      <div className="me-sm-4 d-flex flex-column justify-content-between align-items-center">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Role Relevance
                                        </h1>
                                        <span
                                          className={`tag-${item.tagClass} fm tag-14 text-capitalize rounded-pill`}
                                        >
                                          {item.tagTitle}
                                        </span>
                                      </div>
                                      <div className="mx-3 d-flex flex-column justify-content-between align-items-center">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Verified
                                        </h1>
                                        <div
                                          className={`${item.tagClass} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                        >
                                          {item.tagClass === "high" && (
                                            <HiOutlineCheckCircle className="high__icon fs-4" />
                                          )}
                                          {item.tagClass === "average" && (
                                            <HiOutlineQuestionMarkCircle className="high__icon fs-4" />
                                          )}
                                          {item.tagClass === "low" && (
                                            <HiOutlineExclamationCircle className="high__icon fs-4" />
                                          )}
                                        </div>
                                      </div>
                                      <div className="ms-sm-4 d-flex flex-column justify-content-between pb-1 align-items-start">
                                        <h1 className=" fw-semibold text-14 d-xl-none">
                                          Status
                                        </h1>
                                        <span
                                          className={`job__status rounded-pill text-capitalize ${
                                            item.status === "selected" &&
                                            "status__selected"
                                          } 
                                          ${
                                            item.status === "shortlisted" &&
                                            "status__grey"
                                          }
                                          ${
                                            item.status === "interviewed" &&
                                            "status__interviewed"
                                          }
                                          ${
                                            item.status === "offered" &&
                                            "status__live"
                                          }
                                          ${
                                            item.status === "pending" &&
                                            "status__hold"
                                          } 
                                          ${
                                            item.status === "rejected" &&
                                            "status__closed"
                                          }`}
                                        >
                                          {item.status}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2">
                                    <div
                                      className={`${"high"} rounded-circle d-inline-flex justify-content-center align-items-center`}
                                    >
                                      <HiOutlineCheckCircle className="high__icon fs-4" />
                                    </div>
                                  </div>
                                  <div className="col-xl-1 col-4 order-xl-5 d-xl-flex d-none align-items-center justify-content-center order-2 text-capitalize">
                                    <span className="fm darkGrey text-13 text-end ps-4">
                                      {item.status}
                                    </span>
                                  </div>
                                  {selectedListDND.length > 0 &&
                                  interviewerExists ? (
                                    <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                      &nbsp;{" "}
                                    </div>
                                  ) : (
                                    <div className="col-xl-1 col-2 order-xl-5 order-2 d-flex align-items-start justify-content-end">
                                      <img
                                        src={`/assets/images/Dashboard/fav_${
                                          item.cat === "fav"
                                            ? "selected"
                                            : "unselected"
                                        }.svg`}
                                        alt=""
                                        className="me-2 candidates__icon1"
                                      />
                                      <img
                                        src={`/assets/images/Dashboard/settings_more_${
                                          item.cat === "fav"
                                            ? "selected"
                                            : "unselected"
                                        }.svg`}
                                        alt=""
                                        className="img-fluid candidates__icon2"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      />
                                      <ul class="dropdown-menu dropdown-menu-end">
                                        {item.status === "applied" && (
                                          <li>
                                            <button
                                              class="dropdown-item"
                                              onClick={() =>
                                                shortlistedCandidate(
                                                  item.applicantID
                                                )
                                              }
                                              type="button"
                                            >
                                              Shortlist
                                            </button>
                                          </li>
                                        )}
                                        {item.status === "shortlisted" && (
                                          <li>
                                            <button
                                              class="dropdown-item"
                                              onClick={() =>
                                                interviewCandidate(
                                                  item.applicantID
                                                )
                                              }
                                              type="button"
                                            >
                                              Interviewed
                                            </button>
                                          </li>
                                        )}
                                        {item.status === "interviewed" && (
                                          <li>
                                            <button
                                              class="dropdown-item"
                                              onClick={() =>
                                                selectedCandidate(
                                                  item.applicantID
                                                )
                                              }
                                              type="button"
                                            >
                                              Select
                                            </button>
                                          </li>
                                        )}
                                        {item.status === "selected" && (
                                          <li>
                                            <button
                                              class="dropdown-item"
                                              onClick={() =>
                                                offeredCandidate(
                                                  item.applicantID
                                                )
                                              }
                                              type="button"
                                            >
                                              Extend
                                            </button>
                                          </li>
                                        )}
                                        <li>
                                          <button
                                            class="dropdown-item"
                                            onClick={() =>
                                              rejectCandidate(item.applicantID)
                                            }
                                            type="button"
                                          >
                                            Reject
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              ))
                          )}
                          {newFilteredCandidates.filter(
                            (candidate) => candidate.status === "rejected"
                          ).length > 0 && (
                            <div className="d-flex justify-content-center pt-4 gap-2">
                              <button className="fm text-12 table__bottom__btn1">
                                Load all candidates (
                                {newFilteredCandidates.length})
                              </button>
                              <button className="fm text-12 table__bottom__btn2">
                                Scroll to load more{" "}
                                <HiOutlineArrowDownCircle className="fs-6" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>

              {/* pipeline */}
              {interViewedListDND.length > 0 && interviewerExists ? (
                ""
              ) : (
                <TabPanel>
                  <div className="Dashboard__container container-dash">
                    <div className="Experts__container pb-4  mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <UncontrolledBoard
                            onCardDragEnd={handleCardMove}
                            initialBoard={board}
                            removeColumn={false}
                            disableHeaderEdit={true}
                            disableColumnRemoval={true}
                            disableColumnDrag={true}
                            renameColumn={false}
                            renderColumnHeader={(column, { dragging }) => {
                              const totalCardsCount = column.cards.length; // Calculate total cards count
                              return (
                                <div
                                  className={`react-kanban-column-header d-flex align-items-center ${
                                    dragging ? "dragging" : ""
                                  }`}
                                >
                                  <div className="react-kanban-column-header__spacer gap-2 fm d-flex align-items-center">
                                    <div className="card__dot rounded-circle ms-1"></div>
                                    {column.title}
                                  </div>
                                  <span className="card-count fm fw-semibold fm">
                                    {totalCardsCount}
                                  </span>
                                </div>
                              );
                            }}
                            renderCard={(card, { removeCard, dragging }) => (
                              <div
                                className={`custom-card ${
                                  dragging ? "dragging" : ""
                                }`}
                              >
                                <div className="d-flex mb-2 justify-content-between">
                                  {/* <Avatar src={card.completeData.profileImage} className="me-1" /> */}
                                  <div className="d-flex align-items-center">
                                    <div className="candidates__avatar--img">
                                      <img
                                        src={card.completeData.profileImage}
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <h3 className="fm darkGrey border-0 fw-semibold mb-0">
                                        {card.title}
                                      </h3>
                                    </div>
                                  </div>
                                  <Dropdown
                                    placement="top"
                                    overlay={
                                      <Menu>
                                        <Menu.Item
                                          key="delete"
                                          className="text-decoration-none darkGrey d-flex align-items-center"
                                          onClick={() =>
                                            showProfile(card.completeData)
                                          }
                                        >
                                          View Profile
                                        </Menu.Item>
                                      </Menu>
                                    }
                                  >
                                    <Button className="candidates__menu p-0">
                                      <PiDotsThreeCircleLight className="text-decoration-none fs-4 darkGrey" />
                                    </Button>
                                  </Dropdown>
                                </div>

                                <div className="d-flex gap-sm-4 justify-content-sm-start justify-content-between">
                                  <div>
                                    <p className="fm darkGrey fw-normal mb-0 text-uppercase text-10">
                                      Indexscore
                                    </p>
                                    {card.completeData?.score_request ? (
                                      card.completeData?.kycVerification ? (
                                        <h4 className="fm darkGrey job__status bg-main darkGrey rounded-pill my-0">
                                          {card.completeData.indexCore}
                                        </h4>
                                      ) : (
                                        <span className="text-14 fm darkGrey">
                                          Pending
                                        </span>
                                      )
                                    ) : (
                                      <>
                                        <span className="text-14 fm darkGrey">
                                          Hidden
                                        </span>
                                      </>
                                    )}
                                    {/* <h4 className="fm darkGrey job__status bg-main darkGrey rounded-pill my-0">
                                      {card.completeData.indexCore}
                                    </h4> */}
                                  </div>
                                  <div>
                                    <p className="fm darkGrey fw-normal mb-0 text-uppercase text-10">
                                      Purpose
                                    </p>
                                    {userData.subscription.name === "Predict" ||
        userData.subscription.name === "Scale" ? card.completeData.CultureFit ===1 || card.completeData.CultureFit ===2 ?
          'Pending' : (
          <h4 className="fm darkGrey job__status bg-main darkGrey rounded-pill my-0">
                                      {card.completeData.CultureFit}%
                                    </h4>
        ) : (
          <Tooltip
            title="This feature is not available on this account, you’ll need to upgrade your plan to be able to access this feature."
            placement="top"
          >
            <CiLock className="lock-item fs-6" />
          </Tooltip>
        )}
                                    
                                  </div>
                                  <div>
                                    <p className="fm darkGrey fw-normal mb-0 text-uppercase text-10">
                                      Relevence
                                    </p>
                                    <h4 className="fm darkGrey job__status bg-white darkGrey rounded-pill my-0">
                                      {card.completeData.relevanceScoreVal}%
                                    </h4>
                                  </div>
                                  <div>
                                    <div>
                                      <p className="fm darkGrey fw-normal mb-0 text-uppercase text-10">
                                        Verified
                                      </p>
                                      <div
                                        className={`${"high"} rounded-circle d-inline-flex mt-1 justify-content-center align-items-center`}
                                      >
                                        <HiOutlineCheckCircle className="high__icon fs-6" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}

              {/* INTERVIEWER */}
              <TabPanel>
                <div className="Dashboard__container container-dash">
                  <div className="Experts__container pb-4 pt-2">
                    <div className="d-flex w-100 justify-content-between flex-sm-nowrap flex-sm-row flex-column-reverse mb-4">
                      <div className=" d-flex">
                        <div className="candidates__searchbox jobd__btn candidates__searchbox2 d-flex align-items-center me-2">
                          <BsSearch className="candidates__searchbox__icon mx-2" />
                          <input
                            type="text"
                            className="fm candidates__searchbox__input text-14"
                            placeholder="Search..."
                            value={searchInterviewFeedbackQuery}
                            onChange={(e) =>
                              setSearchInterviewFeedbackQuery(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-sm-0 mb-2">
                        <button className="repost__btn jobd__btn repost__btn2 fm px-3 nowrap ms-sm-2">
                          + Add Interviewers
                        </button>
                      </div>
                    </div>
                    <h3 className="fm darkGrey text-35 pb-3">
                      Candidates’ Interview Feedback
                    </h3>

                    {/* team members */}
                    {
                      // team.map((item) => (
                      <div>
                        {filteredApplicantsInterview ? (
                          <Collapse defaultActiveKey={["1"]}>
                            {filteredApplicantsInterview.map(
                              (item, index) =>
                                (item.status === "interviewed" ||
                                  item.status === "selected" ||
                                  item.status === "offered") && (
                                  <Collapse.Panel
                                    header={
                                      <div>
                                        <Avatar
                                          src={"/assets/images/userAvatar2.svg"}
                                        />{" "}
                                        <strong>{item.candidateName}</strong>
                                      </div>
                                    }
                                    key={index + 1}
                                  >
                                    <div>
                                      <div className="row d-md-flex d-none Experts__header transition">
                                        <div className="col-3 pe-md-0 ">
                                          <div className="d-flex align-items-center ">
                                            <div className="Experts__title__box px-0 d-md-flex d-none">
                                              <span className="Experts__title Candidates__title enterprise__experts fm text-capitalize">
                                                <strong> Interviewers</strong>
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-3 pe-md-0 px-3">
                                          <div className="d-flex align-items-center ">
                                            <div>
                                              <span className="Experts__title Candidates__title enterprise__experts fm text-capitalize">
                                                <strong> Department</strong>
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-3 pe-md-0 ">
                                          <div className="d-flex align-items-center ">
                                            <div>
                                              <span className="Experts__title Candidates__title enterprise__experts fm text-capitalize">
                                                <strong>Feedback Status</strong>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="col-3 pe-md-0 px-0">
                                      <div className="d-flex align-items-center ">
                                        <div>
                                          <span className="Experts__title Candidates__title enterprise__experts fm text-capitalize">
                                            <strong> Date and Time</strong>
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                      </div>
                                      {item.interviewersID.map(
                                        (interviewer) => {
                                          const matchingFeedback =
                                            item.interviewers_feedback.find(
                                              (feedback) =>
                                                feedback.interviewerID ===
                                                interviewer.id
                                            );

                                          return (
                                            <div
                                              className={`row enterprise Experts__row candidates__all mx-0 mt-2 py-xl-2 py-4 my-3 transition pointer`}
                                              key={item.id}
                                            >
                                              <div
                                                key={interviewer.id}
                                                className=" col-md-3  align-items-center"
                                              >
                                                <Avatar
                                                  src={
                                                    "/assets/images/userAvatar2.svg"
                                                  }
                                                  className="img-fluid"
                                                />{" "}
                                                {interviewer.name}
                                              </div>
                                              <div
                                                key={interviewer.id}
                                                className=" col-md-3 col-sm-4 order-xl-5 order-3 px-2 d-flex align-items-center text-capitalize"
                                              >
                                                <span className="Experts__title mb-0 Candidates__title d-md-none enterprise__experts fm text-capitalize me-sm-0 me-2">
                                                  <strong>Department: </strong>
                                                </span>
                                                <div>
                                                  {interviewer.department}
                                                </div>
                                              </div>

                                              <div
                                                key={interviewer.id}
                                                className=" col-md-3 col-sm-4 order-xl-5 order-3 px-2 pb-sm-0 pb-2 pt-sm-0 pt-1 d-flex align-items-center"
                                              >
                                                <span className="Experts__title Candidates__title d-md-none enterprise__experts fm text-capitalize me-sm-0 me-2">
                                                  <strong>
                                                    Feedback Status:{" "}
                                                  </strong>
                                                </span>
                                                {matchingFeedback ? (
                                                  <div className="filled">
                                                    Done{" "}
                                                    <img src="/assets/images/check-mark.png" />
                                                  </div>
                                                ) : (
                                                  <div>Pending</div>
                                                )}
                                              </div>
                                              {/* <div
                                          key={interviewer.id}
                                          className=" col-md-3 col-sm-4 order-xl-5 order-3 px-2 d-flex align-items-center text-capitalize"
                                        >
                                          <span className="Experts__title mb-0 Candidates__title d-md-none enterprise__experts fm text-capitalize me-sm-0 me-2">
                                            <strong>Start Date: </strong>
                                          </span>
                                          <div>{interviewer.startDate}</div>
                                        </div> */}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                    {/* You can also add other content here */}
                                  </Collapse.Panel>
                                )
                            )}
                          </Collapse>
                        ) : (
                          <div>No Results</div>
                        )}
                      </div>
                    }
                  </div>
                </div>
              </TabPanel>

              {/* team */}
              <TabPanel>
                <div className="container-dash">
                  <div>
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={EditAddMemberModal}
                        className="repost__btn jobd__btn repost__btn2 fm px-3 nowrap ms-sm-2"
                      >
                        Add Member
                      </button>
                    </div>

                    <Modal
                      className="certification-popup"
                      title="Add interview members"
                      visible={editAddMemberVisible}
                      onOk={EditAddMemberhandleOk}
                      onCancel={EditAddMemberhandleCancel}
                      footer={null}
                      width={600}
                    >
                      <div className="add-cert-form personal__information edit-name-popup">
                        <div className="row">
                          <div className="col-md-12">
                            <Select
                              className="Login--Input mx-0 d-block Login--select"
                              mode="multiple"
                              allowClear
                              style={{
                                width: "100%",
                                maxWidth: "650px",
                              }}
                              placeholder="Invited Members"
                              onSelect={handleDropChange}
                              onDeselect={handleRemoveDrop}
                              options={options}
                              value={
                                team_members &&
                                team_members.map((member) => member.value)
                              }
                            />
                          </div>
                          <div className="col-md-12 d-flex justify-content-end mt-5">
                            <Button
                              className="primary cancel-button"
                              style={{ marginRight: "8px" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="desc__applynow"
                              onClick={updateJobHandler}
                            >
                              {editJobLoader ? <Loader /> : "Update"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <Table
                    className="dash__table mb-5 d-md-block d-none mt-2"
                    columns={teamColumns}
                    dataSource={formattedTeam}
                    pagination={false}
                    scroll={{
                      x: 768,
                    }}
                  />

                  <div className="rounded-3 border px-2 py-3 d-md-none d-block mb-2">
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="user__box pointer">
                        <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-center ">
                          <img
                            src={`/assets/images/user.svg`}
                            alt=""
                            className="candidates__avatar--img img-fluid"
                          />
                        </div>
                        <div className="Experts__name fm mb-0 ms-2">
                          <h6 className="mb-0 me-1">Chloe Chang</h6>{" "}
                        </div>
                      </div>
                      <Dropdown
                        placement="top"
                        arrow={{ pointAtCenter: true }}
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="interviewed"
                              onClick={() =>
                                selectedCandidate(candidate.applicantID)
                              }
                            >
                              Select
                            </Menu.Item>
                            <Menu.Item
                              key="Reject"
                              onClick={() =>
                                rejectCandidate(candidate.applicantID)
                              }
                            >
                              Reject
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <Button className="candidates__menu pe-1">
                          <BsThreeDotsVertical className="text-decoration-none" />
                        </Button>
                      </Dropdown>
                    </div>

                    <div className="d-sm-flex align-items-center mt-2 ps-1">
                      <div className="w-sm-50 d-flex align-items-center">
                        <span className="fm darkGrey fw-semibold mb-0 text-14 me-2">
                          Location:
                        </span>
                        <span className="job__type rounded-pill darkGrey text-10">
                          United Kingdom
                        </span>
                      </div>

                      <div className="w-sm-50 d-flex align-items-center mt-sm-0 mt-3">
                        <span className="fm darkGrey fw-semibold mb-0 text-14 me-2">
                          Role:
                        </span>
                        <button className="download__button text-14 d-flex justify-content-between text-capitalize align-items-center ps-xl-3 ps-2 py-1">
                          Administrator
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* comments */}
            </Tabs>
          </div>
        </div>
      )}

      {profile && (
        <YourProfile
          page="enterprise"
          data={candidate}
          shortlistedCandidate={shortlistedCandidate}
          updateCandidateStage={updateCandidateStage}
          interviewCandidate={interviewCandidate}
          rejectCandidate={rejectCandidate}
          selectedCandidate={selectedCandidate}
          offeredCandidate={offeredCandidate}
          meeting={setMeeting}
          interviewersList={myObjectData["team_members"]}
          interviewData={setInterview}
          profile={setProfile}
          candidate={setCandidatesAvailability}
          confirm={setConfirm}
          contract={setContract}
          updatingBoard={updatingBoard}
        />
      )}

      {contract && (
        <SendContract
          page="enterprise"
          data={candidate}
          meeting={setMeeting}
          profile={setProfile}
          candidate={setCandidatesAvailability}
          confirm={setConfirm}
        />
      )}

      {meeting && (
        <MeetingDate
          meeting={setMeeting}
          profile={setProfile}
          data={candidate}
          time={setTime}
          interviewers={interview}
          interviewResponse={setInterviewScheduleInfo}
          detail={setDetail}
          confirm={setConfirm}
        />
      )}
      {confirm && (
        <MeetingConfirm
          interviewResponse={interviewScheduleInfo}
          meeting={setMeeting}
          data={candidate}
          profile={setProfile}
          time={setTime}
          detail={setDetail}
          confirm={setConfirm}
        />
      )}
    </div>
  );
};

export default Candidates;
