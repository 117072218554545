import React, { useEffect, useRef, useState, useContext } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { IoArrowRedoOutline, IoArrowUndoOutline, IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaUser, FaRegPaperPlane } from "react-icons/fa";
import { MdGroups2, MdOutlineRefresh } from "react-icons/md";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Upload, Button, Select } from "antd";
import { Loader, Uploader } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from 'notistack'
import moment from "moment";
import config from "../../../services/apisConfig";
import {
  sendMessageTogroup,
  getGroupChatMessages,
  getChannelsByMemberID
} from "../../../services/auth";
import { MyContext } from "../../../Utilities/MyContextProvider";
import "./style.css"
import io from 'socket.io-client';


const HrChat = () => {

  const { Option } = Select;

  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  
  const [channelsList, setChannelsList] = useState([]);
  const [channelMessageInput, setChannelMessageInput] = useState('');
  const [historyChannel, setHistoryChannel] = useState('');
  const [channelchatHistory, setChannelChatHistory] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [socket, setSocket] = useState(null);

  const channelChatContainerRef = useRef();

  const fetchChannelsData = async () => {
    try {
      
      var payload = {
        memberID: userData._id,
      };
      const response = await getChannelsByMemberID(payload);      
      setChannelsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchChannelsData();
  }, []);
  // chat functions
  useEffect(() => {
    const newSocket = io.connect(config.server.serverUrl);

    // Handle successful connection
    newSocket.on('connect', () => {
      // console.log('Connected to Socket.IO');
    });

    // Handle reconnection attempts
    newSocket.on('reconnect_attempt', () => {
      // console.log('Attempting to reconnect...');
    });

    // Handle disconnection
    newSocket.on('disconnect', (reason) => {
      // console.log(`Disconnected: ${reason}`);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('joinChannel', (channelName) => {
        socket.join(channelName);
        // console.log(`User joined channel: ${channelName}`);
      });

      socket.on('leaveChannel', (channelName) => {
        socket.leave(channelName);
        // console.log(`User left channel: ${channelName}`);
      });
    }

    return () => {
      if (socket) {
        socket.off('joinChannel');
        socket.off('leaveChannel');
      }
    };
  }, [socket]);

  useEffect(() => {
    // Scroll to the bottom of the chat container
    if (channelChatContainerRef.current) {
      channelChatContainerRef.current.scrollTop = channelChatContainerRef.current.scrollHeight;
    }
  }, [channelchatHistory]);

  const timeAgo = (datetime) => {
    const createdAt = new Date(datetime);
    const currentTime = new Date();
    const timeDifference = Math.floor((currentTime - createdAt) / 60000); // Calculate the difference in minutes

    let timeAgo;
    if (timeDifference < 1) {
      timeAgo = 'just now';
    } else if (timeDifference < 60) {
      timeAgo = `${timeDifference} min${timeDifference > 1 ? 's' : ''} ago`;
    } else if (timeDifference < 1440) {
      const hours = Math.floor(timeDifference / 60);
      timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(timeDifference / 1440);
      timeAgo = `${days} day${days > 1 ? 's' : ''} ago`;
    }

    return timeAgo;
  }


  const handleChannelClick = (channel) => {
    setSelectedChannel(channel);

    if (socket) {
      
      socket.emit('joinChannel', channel.channelName);
    }
    fetchChannelChatHistory(channel);
  };


  useEffect(() => {
    if (socket) {
      socket.on('newChannelMessage', (messageData) => {
          setChannelChatHistory((prevChatHistory) => {
            const newChatHistory = [...(prevChatHistory || []), messageData];
            return newChatHistory;
          });        

      });      
      return () => {
        socket.off('newChannelMessage');
      };
    }
  }, [socket]);


  const sendNewMessageToGroup = async () => {
    if (!selectedChannel || !channelMessageInput.trim() || !socket) {
      return;
    }

    try {      
      const sendChatPayload = {
        channelName: selectedChannel?.channelName,
        senderId: userData._id,
        senderType: 'member',
        message: channelMessageInput,
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss')
      };
      await sendMessageTogroup(sendChatPayload);

      setChannelMessageInput('');
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error appropriately, e.g., display an error message
    }
  };


  const handleChannelInputChange = (e) => {
    setChannelMessageInput(e.target.value);
  };


  const fetchChannelChatHistory = async (selectedChannel) => {
    try {
      const payloadPrivateChat = {
        channelId: selectedChannel._id
      }      
      const response = await getGroupChatMessages(payloadPrivateChat);      
      setChannelChatHistory(response.data.messages); 
    } catch (error) {
      console.error('Error fetching chat history:', error);

    }
  };



  return (
    <>
      <div className="">
        <div className="chat__wrapper">
          {/* left sidebar */}
          <div className="chat__sidebar">
            <div className="chat-aside-list">
              <ul className="chat__convo__list__group">
                {channelsList && channelsList.map((item) => (
                  <li key={item.key} className="chat__convo__list"
                    onClick={() => handleChannelClick(item)}
                  >
                    <div className="chat__convo">
                      <div className="chat__convo__image">
                        <img
                          // src="/assets/images/user3.png" 
                          src={item.managerID.profileImage.docs}
                          alt={item.channelName} />
                      </div>
                      <div className="chat__convo__detail">
                        <div className="chat__convo__detail_row">
                          <h6>{item.channelName}</h6>
                          <p>{item?.jobID?.company}</p>
                        </div>
                        {/* <div className="chat__convo__detail_row">
                              <p className="content">Hey, how's it going?</p>
                              <span className="meta">2 days</span>
                            </div> */}
                      </div>
                    </div>
                  </li>
                ))}



              </ul>
            </div>
          </div>
          {/* center portion */}
          <div className="chat__center__box">
            {selectedChannel && (
              <div className="chat__header">
                <div className="chat__header__info">
                  <img
                    // src={`/assets/images/google-symbol.png`} 
                    src={selectedChannel.managerID.profileImage.docs}
                    alt={selectedChannel.channelName} />
                  <h6>{selectedChannel.channelName}</h6>
                </div>
              </div>
            )}
            <>
              <div className="chat__content__scrollable" ref={channelChatContainerRef}>
                <div className="chat-container" >
                  {channelchatHistory && channelchatHistory.map((message, index) => (
                    <div key={index} className={`message ${message.sender._id === userData._id ? 'my-message' : 'incoming-message'}`}>
                      <div className="message-content">
                        <strong>{message.sender.firstName + ' ' + message.sender.lastName}</strong>
                        <p className="my-0">{message.message}</p>
                      </div>
                      <div className="message__time">{timeAgo(message.timestamp)}</div>
                    </div>
                  ))}

                </div>

              </div>
              {selectedChannel && (
                <div className="chat__sending__form">
                  <div className="chat__sending__form__inner">
                    <input
                      type="text"
                      placeholder="Enter your message here"
                      value={channelMessageInput}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          sendNewMessageToGroup();
                        }
                      }}
                      onChange={handleChannelInputChange}
                    />
                    <button onClick={sendNewMessageToGroup}>
                      <FaRegPaperPlane />
                    </button>
                  </div>
                </div>
              )}
            </>


          </div>
          {selectedChannel && (
            <div className="chat__info__box">
              <div className="chat__info__box__top">
                <div className="chat__info__box__top__image">
                  <img
                    src={selectedChannel?.managerID?.profileImage?.docs}
                  />
                </div>
                <h6 className="my-2">{selectedChannel?.channelName}</h6>
                <p>{selectedChannel?.jobID?.company}</p>

              </div>
              <div className="chat__info__details">
                {/* <div className="chat__info__details__info">
                <img src="/assets/images/location-icon.png" /> <span className="text-grey text-14">New York, USA</span>
              </div> */}
                {/* <div className="chat__info__details__info">
                  <img src="/assets/images/phone-icon.png" /> <span className="text-grey text-14">------------</span>
                </div>
                <div className="chat__info__details__info">
                  <img src="/assets/images/email-icon.png" /> <span className="text-grey text-14">------------</span>
                </div> */}
              </div>
              <div className="chat__info__box__team">
                <h3>Team Members</h3>
                <ul>
                  {selectedChannel?.members.map((item) => (
                    <li>
                      <img src="/assets/images/user.svg" />
                      <p>{item.lastName}</p>
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          )}



        </div>

      </div>
    </>
  );
};

export default HrChat;
