import React, {useEffect} from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import BookDemo from "../components/BookDemo/BookDemo.jsx";

import Footer from "../components/Footer/Footer";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const BookADemo = () => {
  useEffect(() => {
    document.body.classList.add('book-a-demo')
    return () => {
      document.body.classList.remove('book-a-demo')
    }
  }, [])
  return (
    <>
      <div className="book-demo-page">
      <NavbarDemo logo="Main" color="white"  />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      
      <div className="d-flex flex-column justify-content-between min-vh-100">
      <BookDemo/>
      <Footer/>
      </div>
      </div>
    </>
  );
};

export default BookADemo;

