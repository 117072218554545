import React,{useEffect} from "react";
import Footer from "../components/Footer/Footer";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Values from "../pageComponents/Aboutus/values/Values";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
import UASection1 from "../UpdatedFrontend/AboutUsPage/UASection1.jsx";

const Individuals = () => {
  useEffect(() => {
    document.body.classList.add('about-us')
    return () => {
      document.body.classList.remove('about-us')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="Main" color="white" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <UASection1 />
      <Values />
      <Footer />
    </>
  );
};

export default Individuals;
