import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import {
  BsCheckCircle,
  BsFillEmojiSmileFill,
  BsThreeDots,
  BsThreeDotsVertical
} from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { VscArrowRight } from "react-icons/vsc";
import { HiArrowLeft } from "react-icons/hi";
import GradientSVG from "./gradientSVG";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { CiEdit, CiLinkedin } from "react-icons/ci";
import { Avatar, Progress, Tabs, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { SlOptionsVertical, SlArrowDown } from "react-icons/sl";
import { AiFillLike } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { getProfessionalById } from "../../../services/auth";
import config from '../../../services/apisConfig';
import { useLocation } from "react-router-dom";
import { MyContext } from '../../../Utilities/MyContextProvider';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Loader } from "rsuite";
import { useSnackbar } from 'notistack'


const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text fm mb-0 prof__para fw-normal">
      {isReadMore ? text.slice(0, 450) + "..." : text}
      <p
        onClick={toggleReadMore}
        className="read-or-hide fm blue pointer mb-0"
      >
        {isReadMore ? "Read more" : " Show less"}
      </p>
    </p>
  );
};

const social = [
  {
    id: 1,
    name: "Facebook",
    username: "Username",
    profileLink: "",
  },
  {
    id: 2,
    name: "Messenger",
    username: "Username",
    profileLink: "",
  },
  {
    id: 3,
    name: "Instagram",
    username: "Username",
    profileLink: "",
  },
  {
    id: 4,
    name: "LinkedIn",
    username: "Username",
    profileLink: "",
  },
  {
    id: 5,
    name: "YouTube",
    username: "Username",
    profileLink: "",
  },
  {
    id: 6,
    name: "Twitter",
    username: "Username",
    profileLink: "",
  },
  {
    id: 7,
    name: "WeChat",
    username: "Username",
    profileLink: "",
  },
  {
    id: 8,
    name: "Github",
    username: "Username",
    profileLink: "",
  },
  {
    id: 9,
    name: "Tiktok",
    username: "Username",
    profileLink: "",
  },
  {
    id: 10,
    name: "Medium",
    username: "Username",
    profileLink: "",
  },
];
const awards = [
  {
    id: 1,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
  {
    id: 2,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
  {
    id: 3,
    name: "Award Name",
    year: "2017",
    certificate: "",
  },
];
const publications = [
  {
    id: 1,
    name: "Publication",
    year: "2017",
    certificate: "",
  },
];
const comments = [
  {
    id: 1,
    name: "Adu Opoki-Boahin",
    date: "2023.04.30 / 14:54",
    message:
      "Comment about user/candidate that reflect job and other issues. Comment about user/candidate that reflect job and other issues. Comment about user/candidate that reflect job and other issues.",
    replies: [
      {
        id: 1,
        message: "Great work.",
      },
      {
        id: 2,
        message: "Well done!",
      },
    ],
  },
];
const idCSS = "hello";

const items = [
  {
    key: "1",
    label: <p className="fm darkGrey mb-0">Delete</p>,
  },
  {
    key: "2",
    label: <p className="fm darkGrey mb-0">Edit</p>,
  },
];
const experience = [
  {
    id: 1,
    jobname: "Job title",
    company: "Company",
    desc: "Description of job/position here of job/position here Description of job/position. of job/position here of job/position here Description of job/position. Description of job/position here of job/position here Description of job/position.",
    tag: "2017 – Present",
  },
  {
    id: 2,
    jobname: "Job title",
    company: "Company",
    desc: "Description of job/position here of job/position here Description of job/position. of job/position here of job/position here Description of job/position. Description of job/position here of job/position here Description of job/position.",
    tag: "2017 – Present",
  },
];
const reference = [
  {
    id: 1,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
  {
    id: 2,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
  {
    id: 3,
    refname: "Reference Name",
    jobname: "Job title",
    company: "Company",
    tag: "Verified",
  },
];
const OffersProfile = (props) => {
  const [purpose, setPurpose] = useState(0);
  const [showReplies, setShowReplies] = useState(false);
  const [typeReply, setTypeReply] = useState(false);
  const [indexcore, setIndexcore] = useState(0);
  const [state, setState] = useState({
    loading: false,
  });
  const { loading } = state;
  const { id } = useParams();
  var respProfessionalByID;
  // var professionalData;
  const navigate = useNavigate();
  const [professionalData, setProfessionalData] = useState(null);
  const isAuthenticated = localStorage.getItem("enterpriseID");
  const { enqueueSnackbar } = useSnackbar();

  if (isAuthenticated) { }
  else { navigate('/enterprise/signup'); }
  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    const fetchProfessional = async () => {
      handleChange("loading", true);
      try {
        const payLoadtoGetPro = {
          "_id": id
        };
        const respProfessionalByID = await getProfessionalById(payLoadtoGetPro);
        setProfessionalData(respProfessionalByID.data.data[0]);

        console.log("respProfessionalByID.data.data: ", respProfessionalByID.data.data[0]);
        console.log("professionalData: ", professionalData);

        // if (respProfessionalByID.data.data) {
        //   console.log("professionalData.indexCore: " + respProfessionalByID.data.data[0]?.ai_data?.indexCore);
        //   setIndexcore(respProfessionalByID.data.data[0]?.ai_data?.indexCore);
        //   setPurpose(respProfessionalByID.data.data[0]?.ai_data?.CultureFit);
        // }

        handleChange("loading", false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProfessional();
  }, []);


  useEffect(() => {
    const timerId = setTimeout(() => {
      // if (professionalData) {
      // console.log("professionalData.indexCore:  " + professionalData.ai_data.index_core);
      setIndexcore(professionalData?.ai_data.index_core);
      // setPurpose(professionalData?.ai_data.job_relevancy_score.jobID.toFixed(0));
      // }
    }, 500);
    return () => clearTimeout(timerId);
  }, [professionalData]);


  const handleDownload = async (pdfUrl) => {
    console.log('pdfurl' + pdfUrl);
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: 'POST',
        data: {
          "Key": pdfUrl
        },
        responseType: 'blob', // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'resume.pdf');
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
      console.log('Access token:', responseData);
      // console.log('userID:', userID);



    } catch (error) {
      console.error('Error fetching access token:', error);
    }

  }


  const Meeting = () => {
    props.meeting(true);
    props.confirm(false);
    props.profile(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

  };
  const Candidates = () => {
    // props.confirm(false);
    // props.meeting(false);
    // props.profile(false);
    // props.candidate(true);
    navigate('/enterprise/dashboard/candidates')
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const TabPane = Tabs.TabPane;


  const copyPageUrl = () => {
    const url = window.location.href; // Get the current page URL

    const tempInput = document.createElement('input');
    tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // alert('Page URL copied!');
    enqueueSnackbar('Copied Successfully!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    });
  };

  return (
    <div className="bg-lightgrey prof-profile">
      {loading ? <Loader /> : (
        <>
          {/* <div className="Dashboard__container edash pro-profile-container bg-lightgrey mb-0">
            <div className="container-lg mt-4 bg-lightgrey">


              <div className="row align-items-center mb-sm-3 mt-4 bg-lightgrey">
                <div className="col-md-4 d-flex align-items-center">
                  <small
                    className="fm darkGrey pointer nowrap d-flex align-items-center"
                  onClick={Candidates}
                  >
                    <HiArrowLeft className="me-2" />
                    Back                    
                  </small>
                </div>                
              </div>

            </div>
          </div> */}
          <div className="edash ">
            <div className="">
            <div className="bg-white mb-0 border-05 py-2">
          <div className="container-dash ">
            <div className="d-flex justify-content-between">
              <div
                className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center"
                onClick={Candidates}
              >
                <HiArrowLeft className="" />
              </div>
            </div>
          </div>
        </div>
              <div className="Dashboard__container pt-3">
                <div className="container-dash">
                  <div className="row pb-4">
                    <div className="col-md-4 pb-md-0 pb-4 pe-md-0">
                      <div className="profile__left bg-white rounded-3 pb-4 pt-3">
                        <div className="profile__left__top d-flex flex-column align-items-center px-3 pb-4 pt-2">
                          <div

                            className="profile__avatar border d-flex justify-content-center align-items-center rounded-circle">
                            <img
                              className="profile__avatar--img "
                              src={"/assets/images/userAvatar.svg"}
                            />
                          </div>
                          <h1 className="darkBlue fs-5 fm fw-semibold mt-2 mb-0">
                            {professionalData?.firstName + professionalData?.lastName}
                          </h1>
                          <span className="fm midGrey">(He/Him)</span>
                          <span className="fm blue mt-2">Applied Today</span>
                          <div className="d-flex mt-3">
                            <div onClick={() => copyPageUrl()} className="profile__icon d-flex justify-content-center align-items-center mx-2">
                              <img
                                src="/assets/images/icon_fav.svg"
                                alt=""
                                className="profile__icon--img1 img-fluid"
                              />
                            </div>
                      
                            <div onClick={() => handleDownload(professionalData?.resumes?.Key)} className="profile__icon d-flex justify-content-center align-items-center me-2">
                              <img
                                src="/assets/images/icon_export.svg"
                                alt=""
                                className="img-fluid candidates__button--img"
                              />
                            </div>
                            <div className="profile__icon profile__icon__del d-flex justify-content-center align-items-center me-2">
                      <img
                        src="/assets/images/toolbar/delete_icon.svg"
                        alt=""
                        className="img-fluid candidates__button--img"
                      />
                    </div>
                          </div>
                        </div>

                        {/* circular progress */}
                        <div className="profile__left__circular w-100 position-relative pb-4">
                          <GradientSVG />
                          <CircularProgressbarWithChildren
                            strokeWidth={5}
                            value={indexcore}
                            // value={
                            //   props.page === "enterprise" ? props.data.indexCore : "600"
                            // }
                            // text={"600"}
                            className="progressBars mb-md-0 mb-4 px-2 position-relative"
                            maxValue={850}
                            styles={{
                              path: { stroke: `url(#${idCSS})`, height: "100%" },
                              trail: {
                                stroke: "#F8F8F8",
                              },
                            }}
                          ></CircularProgressbarWithChildren>
                          <div className="indexcore_score text-center d-flex flex-column align-items-center justify-content-center">
                            <h5 className="fm darkGrey mb-0 fs-6">IndexScore Score </h5>

                            <strong className="score-value fw-light mb-0 fm">
                              {indexcore}
                              {/* {props.page === "enterprise" ? props.data.indexCore : 600}{" "}
                       */}
                              {/* {props.page === 'professional' ? responseProfessionalData?.ai_data.index_core : props.data.indexCore} */}
                            </strong>
                          </div>
                        </div>

                        {/* purpose alignment */}
                        {/* {props.page === "enterprise" && ( */}
                        {/* <div className="profile__left__purpose py-3 px-3">
                      <div className=" text-center d-flex flex-column align-items-center justify-content-center mb-2">
                        <h6 className="fm darkGrey mb-0 fs-6">
                          Purpose Alignment
                        </h6>

                        <strong className="score-value fw-light mb-0 fm">
                          {purpose}%
                        </strong>
                      </div>
                      <Progress
                        percent={purpose}
                        showInfo={false}
                        strokeColor={{
                          "0%": "#A7DB5A",
                          "100%": "#A7DB5A",
                        }}
                      />
                    </div> */}
                        {/* )} */}

                        {/* contact */}
                        <div className="profile__left__contact px-3 pt-3 pb-2">
                          <h5 className="fs-5 fm darkBlue mb-3">Contact</h5>
                          <div className="d-flex align-items-center">
                            <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                              <img
                                src="/assets/images/mail_dark.svg"
                                alt=""
                                className="profile__icon--img img-fluid"
                              />
                            </div>
                            <a href={`mailto:${professionalData?.emailAddress}`} className="fm darkGrey text-decoration-none">{professionalData?.emailAddress}</a>
                          </div>
                          <div className="d-flex align-items-center my-2">
                            <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                              <img
                                src="/assets/images/inbox_dark.svg"
                                alt=""
                                className="profile__icon--img img-fluid"
                              />
                            </div>
                            <a className="fm darkGrey text-decoration-none" href={`tel:${professionalData?.phoneNumber}`}>{professionalData?.phoneNumber}</a>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                              <IoLocationOutline className="fs-4 darkGrey" />
                            </div>
                            <span className="fm darkGrey">Address</span>
                          </div>
                          <div className="d-flex align-items-center mt-2">
                            <div className="profile__icon d-flex justify-content-center align-items-center me-2">
                              <CiLinkedin className="fs-4 darkGrey" />
                            </div>
                            <a href={professionalData?.linkedinPublicUrl} target="_blank" className="fm darkGrey text-decoration-none">LinkedIn Profile</a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8">
                      <div className="profile__right bg-white rounded-3 pb-4 pt-2">
                        <Tabs>
                          <TabPane tab="Profile" key="tab 1">
                            <div className="px-3 py-4">
                              <div className="d-flex justify-content-sm-between flex-sm-row flex-column border-05 pb-3">
                                <div className="d-inline-flex flex-sm-nowrap flex-wrap">
                                  <div>
                                    <p className="fm darkGrey mb-0">Identity</p>
                                    <div className="reference__verification--main nowrap mb-3 fw-semibold fm d-flex align-items-center gap-1">
                                      <span>Verified</span> <BsCheckCircle />
                                    </div>
                                  </div>
                                  <div className="mx-sm-4 mx-3">
                                    <p className="fm darkGrey fm mb-0">Right to work</p>
                                    <div className="reference__verification--main nowrap mb-3 fw-semibold fm d-flex align-items-center gap-1">
                                      <span>Verified</span> <BsCheckCircle />
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <p className="fm darkGrey fm mb-0">Nationality</p>
                                    <div className="reference__verification--main nowrap mb-3 fw-semibold fm d-flex align-items-center gap-1">
                                      <span>United Kingdom</span>
                                    </div>
                                  </div>

                                  <div className="">
                                    <p className="fm darkGrey fm mb-0">Sponsorship</p>
                                    <div className="reference__verification--main nowrap mb-3 fw-semibold fm d-flex align-items-center gap-1">
                                      <span>Yes</span>  <BsCheckCircle />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="border-05 py-3">
                                <div className="d-flex justify-content-between align-items-sm-center align-items-start">
                                  <h4 className="fm darkBlue fw-semibold fs-5 mb-0 me-2">
                                  Biography
                                  </h4>
                                </div>
                                <p className="darkGrey fm mb-0 mt-3 prof__para ">
                                  <ReadMore className="darkGrey fm">
                                    Breif Cover letter of job/position here of
                                    job/position here Description of job/position here
                                    Description here of job/position here. Breif Cover
                                    letter of job/position here of job/position here
                                    Description of job/position here Description here of
                                    job/position here. Cover letter of job/position here
                                    of job/position here Description of job/position
                                    here Description here of job/position here
                                    Description of job/position here of job/position
                                    here. Description of job/position here of
                                    job/position here Description of job/position here
                                    here of job/position here. Description of
                                    job/position here of job/position here Description
                                    of job/position here job/position here Description
                                    of job/position here Description here of
                                    job/position here Description of job/position here
                                    of job/position here.
                                  </ReadMore>
                                </p>
                              </div>
                              <div className="d-flex justify-content-between align-items-center pt-3">
                                <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                  Experience
                                </h4>
                              </div>
                              {experience.map((item) => (
                                <div className="border-05" key={item.id}>
                                  <div className="work__exp__box__item pt-3">
                                    <div className="work__exp__box__item__content">
                                      <div className="work__exp__box__item__image">
                                        <img
                                          src={`/assets/images/EnterpriseAvatar.svg`}
                                          alt=""
                                          className="profile-company-avatar border-0 rounded-0"
                                        />
                                      </div>
                                      <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                                        <div className="d-flex">
                                          <h5 className="fm me-4 nowrap mb-0 fs-6 fw-semibold">
                                            {item.jobname}{" "}
                                            <span className="midGrey fw-normal">
                                              – {item.company}
                                            </span>
                                          </h5>
                                        </div>
                                        <p className="mt-1 fm darkGrey prof__para">{item.desc}</p>
                                        <div className="filter__content__tag__content px-2 rounded-pill ">
                                          <small className="filter__content__tag__content--small text-12 darkBlue fm text-capitalize">
                                            {item.tag}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="d-flex justify-content-between align-items-center pt-3">
                                <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                  References
                                </h4>
                              </div>
                              {reference.map((item) => (
                                <div className="border-05" key={item.id}>
                                  <div className="work__exp__box__item pt-3 d-flex ">
                                    <div className="work__exp__box__item__content">
                                      <div className="work__exp__box__item__image">
                                        <img
                                          src={`/assets/images/EnterpriseAvatar.svg`}
                                          alt=""
                                          className="profile-company-avatar border-0 rounded-0"
                                        />
                                      </div>
                                      <div className="d-flex flex-sm-row flex-column justify-content-sm-between w-100">
                                        <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                                          <div className="">
                                            <h5 className="fm me-4 nowrap mb-0 fs-6 fw-semibold">
                                              {item.refname}
                                            </h5>
                                            <span className="darkGrey">
                                              {item.jobname} – {item.company}
                                            </span>
                                          </div>
                                          <div className="filter__content__tag__content filter__content__tag__content-verified rounded-pill px-2 mt-3">
                                            <small className="filter__content__tag__content--small text-12 filter__content__tag__content-verified fm text-capitalize">
                                              {item.tag}
                                            </small>
                                          </div>
                                        </div>
                                        <div className="d-flex ms-sm-0 ms-3 mt-sm-0 mt-3">
                                          <div className="profile__icon d-flex justify-content-center align-items-center me-3">
                                            <img
                                              src="/assets/images/mail_dark.svg"
                                              alt=""
                                              className="profile__icon--img img-fluid"
                                            />
                                          </div>
                                          <div className="profile__icon d-flex justify-content-center align-items-center">
                                            <img
                                              src="/assets/images/inbox_dark.svg"
                                              alt=""
                                              className="profile__icon--img img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="d-flex justify-content-between align-items-center pt-3">
                                <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                  Education
                                </h4>
                              </div>
                              <div className="">
                                <div className="work__exp__box__item pt-3 d-flex justify-content-between">
                                  <div className="work__exp__box__item__content">
                                    <div className="work__exp__box__item__image">
                                      <img
                                        src={`/assets/images/EnterpriseAvatar.svg`}
                                        alt=""
                                        className="profile-company-avatar border-0 rounded-0"
                                      />
                                    </div>
                                    <div className="work__exp__box__company__info work__exp__box__item__duration pe-0">
                                      <div className="">
                                        <h5 className="fm me-4 nowrap darkGrey mb-0 fs-6 fw-semibold">
                                          School / College / University Name
                                        </h5>
                                        <span className="darkGrey">
                                          Qualification • Location
                                        </span>
                                      </div>
                                      <p className="darkGrey fm mb-0 prof__para">
                                        <ReadMore>
                                          Description of job/position here of
                                          job/position here Description of job/position.
                                          of job/position here of job/position here
                                          Description of job/position. Description of
                                          job/position here of job/position here
                                          Description of job/position.
                                        </ReadMore>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tab="Video & Questions" key="tab 2">
                            <div className="px-3">
                              <div className="work__exp__box border-05 py-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                    Video
                                  </h4>
                                </div>
                                <div className="yourProfile__videoUpload mb-0 rounded-4 mt-3">
                                  <div className="yourProfile__videoUpload__frame h-100">

                                    {<iframe frameborder="0" className="w-100 h-100 rounded-4" src={professionalData?.elevatorPitch.file}></iframe>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="work__exp__box open__questions__box mb-0 pt-4 pb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                    Questions
                                  </h4>

                                </div>
                                <div className="yourProfile__questions__list pt-3">
                                  <form>
                                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                                      <div className="me-2">1.</div> What do you like
                                      doing outside of work and why?
                                    </p>
                                    <div className="form-group">
                                      <textarea
                                        type="text"
                                        className="form-control-lg form-control mb-3"
                                        id="exampleInputEmail1"
                                        aria-describedby="question1"
                                        placeholder="Your message..."
                                        rows={3}
                                        value={professionalData?.openAnswers?.openQ1}
                                      />
                                    </div>
                                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                                      <div className="me-2">2.</div> What would a
                                      perfect role look like for you and who else would
                                      be on the team?
                                    </p>
                                    <div className="form-group">
                                      <textarea
                                        type="text"
                                        className="form-control-lg form-control mb-3"
                                        id="exampleInputEmail1"
                                        aria-describedby="question1"
                                        placeholder="Your message..."
                                        rows={3}
                                        value={professionalData?.openAnswers?.openQ2}
                                      />
                                    </div>
                                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                                      <div className="me-2">3.</div> Which company would
                                      you most like to work for and how does their
                                      culture align with your values?
                                    </p>
                                    <div className="form-group">
                                      <textarea
                                        type="text"
                                        className="form-control-lg form-control mb-3"
                                        id="exampleInputEmail1"
                                        aria-describedby="question1"
                                        placeholder="Your message..."
                                        rows={3}
                                        value={professionalData?.openAnswers?.openQ3}
                                      />
                                    </div>
                                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                                      <div className="me-2">4.</div> In 10 years, how
                                      will you have fulfilled your purpose?
                                    </p>
                                    <div className="form-group mb-3">
                                      <textarea
                                        type="text"
                                        className="form-control-lg form-control mb-md-0 mb-4"
                                        id="exampleInputEmail1"
                                        aria-describedby="question1"
                                        placeholder="Your message..."
                                        rows={3}
                                        value={professionalData?.openAnswers?.openQ4}
                                      />
                                    </div>
                                    <p className="Get__left__content__para Signup__para mb-3 d-flex fm darkGrey">
                                      <div className="me-2">5.</div> In five years, how will you have fulfilled your purpose?
                                    </p>
                                    <div className="form-group">
                                      <textarea
                                        type="text"
                                        className="form-control-lg form-control mb-md-0 mb-4"
                                        id="exampleInputEmail1"
                                        aria-describedby="question1"
                                        placeholder="Your message..."
                                        rows={3}
                                        value={professionalData?.openAnswers?.openQ5}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          <TabPane tab="Online Presence" key="tab 3">
                            <div className="px-3 py-4">
                              <div className="border-05 pb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                    Social channels
                                  </h4>

                                </div>
                                <div className="d-flex flex-wrap mt-3">
                                  {social.map((item) => (
                                    <div
                                      className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                                      key={item.id}
                                    >
                                      <img
                                        src={`/assets/images/profile/social${item.id}.svg`}
                                        alt=""
                                        className="profile__social--img"
                                      />
                                      <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                        <div className="text-center mt-3">
                                          <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                            {item.name}
                                          </h6>
                                          <p className="fm darkGrey text-14">
                                            @{item.username}
                                          </p>
                                        </div>
                                        <Link
                                          to={item.profileLink}
                                          className="blue text-decoration-none mb-2 text-14"
                                        >
                                          View <VscArrowRight />
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="border-05 pb-3 pt-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="fm darkBlue fw-semibold fs-5 mb-0">
                                    Awards
                                  </h4>

                                </div>
                                <div className="d-flex flex-wrap  mt-3">
                                  {awards.map((item) => (
                                    <div
                                      className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                                      key={item.id}
                                    >
                                      <img
                                        src="/assets/images/profile/Awards.svg"
                                        alt=""
                                        className="profile__social--img2"
                                      />
                                      <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                        <div className="text-center mt-3">
                                          <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                            {item.name}
                                          </h6>
                                          <p className="fm darkGrey text-14">
                                            <span className="fw-semibold">Year :</span>{" "}
                                            {item.year}
                                          </p>
                                        </div>
                                        <Link
                                          to={item.certificate}
                                          className="blue text-decoration-none mb-2 text-14"
                                        >
                                          View <VscArrowRight />
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div className="pt-4">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h4 className="fm darkBlue fw-semibold fs-4 mb-0">
                                    Publications
                                  </h4>

                                </div>
                                <div className="d-flex flex-wrap  mt-3">
                                  {publications.map((item) => (
                                    <div
                                      className="profile__social d-flex justify-content-center align-items-center me-2 mb-2"
                                      key={item.id}
                                    >
                                      <img
                                        src="/assets/images/profile/Publications.svg"
                                        alt=""
                                        className="profile__social--img2"
                                      />
                                      <div className="profile__social__overlay transition position-absolute h-100 w-100 d-flex flex-column justify-content-between align-items-center">
                                        <div className="text-center mt-3">
                                          <h6 className="fm text-14 darkGrey mb-0 fw-semibold">
                                            {item.name}
                                          </h6>
                                          <p className="fm darkGrey text-14">
                                            <span className="fw-semibold">Year :</span>{" "}
                                            {item.year}
                                          </p>
                                        </div>
                                        <Link
                                          to={item.certificate}
                                          className="blue text-decoration-none mb-2 text-14"
                                        >
                                          View <VscArrowRight />
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          {/* {props.page === "enterprise" && ( */}
                          <TabPane tab="Comments" key="tab 4">
                            <div className="px-3 py-4">
                              <div className="d-flex justify-content-end">
                                <button className="fm text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center">
                                  <span className="fs-4 me-1">+</span> New Entry
                                </button>
                              </div>

                              {comments.map((item) => (
                                <div className="profile__comment mt-3">
                                  <div className="profile__comment__top border-05 p-2 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <Avatar
                                        icon={<UserOutlined />}
                                        size="large"
                                        className="me-2"
                                      />
                                      <div>
                                        <h6 className="text-14 fm darkGrey fw-semibold mb-0">
                                          {item.name}
                                        </h6>
                                        <p className="fm text-12 darkGrey mb-0">
                                          {item.date}
                                        </p>
                                      </div>
                                    </div>
                                    <Dropdown
                                      menu={{
                                        items,
                                      }}
                                      placement="bottomRight"
                                    >
                                      <SlOptionsVertical className="fs-5 pointer" />
                                    </Dropdown>
                                  </div>
                                  <div className="profile__comment__mid p-2">
                                    <p className="darkGrey fm">{item.message}</p>
                                  </div>
                                  <div className="d-flex p-2 align-items-end">
                                    <AiFillLike className="fs-5 me-1 pointer" />
                                    <BsFillEmojiSmileFill className="fs-6 pointer" />
                                  </div>
                                  <div className="px-2 pb-2 d-flex align-items-center">
                                    <div
                                      className="lightblue__btn d-inline-flex px-2 py-1 pointer align-items-center me-2"
                                      onClick={() => setShowReplies(!showReplies)}
                                    >
                                      <Avatar.Group className="me-2">
                                        {item.replies.map((item) => (
                                          <Avatar
                                            style={{
                                              backgroundColor: "#1890ff",
                                            }}
                                            key={item.id}
                                            size="small"
                                            icon={<UserOutlined />}
                                          />
                                        ))}
                                      </Avatar.Group>
                                      <span className="fm blue fw-semibold">
                                        {item.replies.length} replies
                                      </span>
                                    </div>
                                    <span
                                      className="fm darkGrey fw-semibold fs-6 pointer"
                                      onClick={() => setTypeReply(!typeReply)}
                                    >
                                      {typeReply ? "Done" : "Reply"}
                                    </span>
                                  </div>
                                  {showReplies && (
                                    <div className="profile__comment__reply p-2 ">
                                      {item.replies.map((item) => (
                                        <p className="border-05 fm border p-2 blue bg-pastel mb-1">
                                          {item.message}
                                        </p>
                                      ))}
                                    </div>
                                  )}
                                  {typeReply && (
                                    <div className="p-2">
                                      <input
                                        type="text"
                                        className="profile__comment--input form-control mb-2"
                                        placeholder="Type a message"
                                      />
                                      <button className="fm bg-pastel fw-semibold blue px-3 py-2">
                                        Send
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </TabPane>
                          {/* )} */}
                          {/* {props.page === "enterprise" && ( */}
                          <TabPane tab="Candidate Process" key="tab 5">
                            <div className="px-3 py-4">
                              <div className="profile__comment">
                                <div className="border-05 p-2 d-flex flex-sm-row flex-column justify-content-between align-items-xl-center">
                                  <div className=" mb-sm-0 mb-2">
                                    <h5 className="fs-5 darkGrey mb-0 pb-1">
                                      Ben Smith
                                    </h5>
                                    <p className="fm mb-0 darkGrey">
                                      Applied for{" "}
                                      <span className="blue">
                                        Senior Product Designer
                                      </span>
                                    </p>
                                  </div>
                                  <div className="d-flex ">
                                    <Dropdown
                                      menu={{
                                        items,
                                      }}
                                      placement="bottomRight"
                                    >
                                      <button className="profile__more me-2">
                                        <BsThreeDots className="fs-5 darkGrey" />
                                      </button>
                                    </Dropdown>
                                    <button className="profile__btn fm px-2 nowrap">
                                      <span className="fw-fw-semibold darkGrey">
                                        Stage:
                                      </span>{" "}
                                      <span className="blue nowrap">
                                        {" "}
                                        Intervewing <SlArrowDown />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="p-2 d-flex align-items-center">
                                  <img
                                    src="/assets/images/inbox_dark.svg"
                                    alt=""
                                    className="profile__icon--img img-fluid"
                                  />
                                  <span className="mx-2 darkGrey fm">Messages</span>
                                  <div className="message_index text-center darkGrey fm">
                                    2
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          {/* )} */}
                          {/* {props.page === "enterprise" && ( */}
                          <TabPane tab="Reports" key="tab 6">
                            <div className="px-3 py-4">
                              <div className="profile__comment mb-4">
                                <div className="border-05 p-2 d-flex flex-sm-row flex-column justify-content-between align-items-xl-center">
                                  <div className=" mb-sm-0 mb-2">
                                    <h5 className="fs-5 darkGrey mb-0 pb-1">
                                      Download  Criteria Report
                                    </h5>

                                  </div>
                                  <div className="d-flex ">

                                    {/* <button className="profile__btn fm px-2 nowrap"> */}
                                    {/* <span className="fw-fw-semibold darkGrey">
                                  Download
                                </span> */}
                                    <img
                                      src={`/assets/images/criteria-button.png`}
                                      // onClick={handleVerification}
                                      alt=""
                                      className="DNavbar__logo--img Navbar__container__logo--img accurate-image"
                                    />

                                    {/* </button> */}
                                  </div>
                                </div>
                              </div>
                              <div className="profile__comment mb-4">
                                <div className="border-05 p-2 d-flex flex-sm-row flex-column justify-content-between align-items-xl-center">
                                  <div className=" mb-sm-0 mb-2">
                                    <h5 className="fs-5 darkGrey mb-0 pb-1">
                                      Download Accurate Report
                                    </h5>

                                  </div>
                                  <div className="d-flex ">

                                    {/* <button className="profile__btn fm px-2 nowrap"> */}
                                    {/* <span className="fw-fw-semibold darkGrey">
                                  Download
                                </span> */}
                                    <img
                                      src={`/assets/images/accurate-button.png`}
                                      // onClick={handleVerification}
                                      alt=""
                                      className="DNavbar__logo--img Navbar__container__logo--img accurate-image"
                                    />



                                    {/* </button> */}
                                  </div>
                                </div>
                              </div>
                              <div className="profile__comment mb-4">
                                <div className="border-05 p-2 d-flex flex-sm-row flex-column justify-content-between align-items-xl-center">
                                  <div className=" mb-sm-0 mb-2">
                                    <h5 className="fs-5 darkGrey mb-0 pb-1">
                                      Download Resume
                                      {/* {professionalData?.resumes.resume} */}
                                    </h5>

                                  </div>
                                  <div className="d-flex ">
                                    <button
                                      // onClick={() => handleDownload(professionalData?.resumes.resume)}
                                      onClick={() => handleDownload(professionalData?.resumes?.Key)}
                                      className="fm text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center">
                                      Resume
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                          {/* )} */}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OffersProfile;
