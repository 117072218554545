import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const UASection1 = () => {
  return (
    <>
      <div className="main-padding">
        <div className="container-xl px-0">
          <div className="h-100 fm d-flex flex-column align-items-center overflow-hidden top-padding ">
            <h1 className="darkBlue fw-semibold fm fs-1 uhs1__heading text-center">
              The Future of Work <br /> with a single source of truth
            </h1>
            <p className="darkGrey fm ues1__para text-center text-14">
              Being about a quarter-way through the 21st century, we believe
              it’s time you had a better way to share credentials than a 2-page
              resume. With a score that sums up your experience, working style,
              thought leadership, and more for employers — data puts you at the
              front as you should be
            </p>

            <div className="ues1__image d-flex rounded-3 px-md-4 mt-md-4 mt-2">
              <div className="row py-5 ps-lg-5 pe-lg-4">
                <div className="col-md-5 d-flex align-items-center">
                  <div className="uas1__content px-md-0 px-3">
                    <h1 className="fm fs-2 text-white">
                      Becoming the de-facto way to acquire talent
                    </h1>
                    <p className="fm text-white fw-light mb-md-0">
                      By pre-verifying and approving candidates using a 600+
                      data system, we shorten the recruitment process for Chief
                      HR Officers to concentrate on what matters:{" "}
                      <strong className="fw-semibold">
                        getting the best talent for the job, quickly and fairly.
                      </strong>
                    </p>
                  </div>
                </div>
                <div className="col-md-7">
                  <img
                    src="/assets/UpdatedFrontend/About/ATS.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ues5">
          <div className="container-xl px-0 mt-5 py-5 d-flex flex-column align-items-center">
            <img
              src="/assets/UpdatedFrontend/Candidate/getStarted.png"
              alt=""
            />
            <h1 className="fm darkBlue fs-2 mt-5 text-center">
              Doing justice to your skills, unique background & values
            </h1>
            <p className="fm darkGrey text-center mt-3 pb-4">
              By assigning to candidates one score based on a transparent, and
              fair assessment of 3rd party data—you’ll be matched with an
              employer whose mission aligns with your values and professional
              goals
            </p>
          </div>
        </div>

        <div className="container-xl px-0 uas3 d-flex align-items-center justify-content-center rounded-3 mb-5">
          <div>
            <h1 className="fm fs-1 text-center mx-auto main text-white pb-4">
              A team of former strategy consultants
            </h1>
            <p className="text-white fm text-center mx-auto">
              With a background of decades in helping organizations hire people,
              optimizing their teams, and designing digital solutions to make
              processes simple — in hindsight, IndexScore is a fitting
              congruence of it all.
            </p>
            <p className="text-white fm text-center mx-auto">
              As entrepreneurs in tech & consulting who spent a lot of time
              working with and thinking about data and AI, we knew a data
              revolution in recruitment was still pending. And we knew the
              Future of Work relied on it.
            </p>
            <div className="d-flex justify-content-center">
              <Link to={"/signup"} className="text-decoration-none">
                {" "}
                <button className="btn--main-light text-dark mx-auto fw-semibold fm rounded-2 mt-4">
                  Get Started With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UASection1;
