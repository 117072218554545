import React from "react";
import { ImRadioChecked,ImRadioUnchecked } from "react-icons/im";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RxCircle } from "react-icons/rx";

const Mobile = (props) => {
  return (
    <div className="Mobile d-flex justify-content-start w-100">
      {/* box1 */}
      <div className="Mobile__box">
        {props.page === "1" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" && (
          <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page === "1" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-1 Bar__container__box__top__title-main mb-0">
            Confirm details
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop:
            props.page === "1"
              ? "2px solid var(--borderGrey)"
              : "2px solid var(--main)",
        }}
      ></div>

      {/* box2 */}
      <div className="Mobile__box">
        {props.page === "1" && (
          <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
        )}
        {props.page === "2" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" && props.page !== "2" && (
          <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page === "2" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-2 Bar__container__box__top__title-main mb-0">
            About you
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop:
            props.page === "3" ||
            props.page === "4" ||
            props.page === "5" ||
            props.page === "6" ||
            props.page === "7" ||
            props.page === "8"
              ? "2px solid var(--main)"
              : "2px solid var(--borderGrey)",
        }}
      ></div>

      {/* box3 */}
      <div className="Mobile__box">
        {props.page !== "8" &&
          props.page !== "7" &&
          props.page !== "6" &&
          props.page !== "5" &&
          props.page !== "4" &&
          props.page !== "3" && (
            <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
          )}
        {props.page === "3" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" && props.page !== "2" && props.page !== "3" && (
          <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page === "3" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-3 Bar__container__box__top__title-main mb-0">
            Identification
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop:
            props.page === "4" ||
            props.page === "5" ||
            props.page === "6" ||
            props.page === "7" ||
            props.page === "8" 
              ? "2px solid var(--main)"
              : "2px solid var(--borderGrey)",
        }}
      ></div>

      {/* box4 */}
      <div className="Mobile__box">
        {props.page !== "8" &&
        props.page !== "7" &&
          props.page !== "6" &&
          props.page !== "5" &&
          props.page !== "4" && (
            <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
          )}
        {props.page === "4" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" &&
          props.page !== "2" &&
          props.page !== "3" &&
          props.page !== "4" && (
            <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
          )}
        {props.page === "4" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-4 Bar__container__box__top__title-main mb-0">
            Online presence
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop:
            props.page === "5" || props.page === "6" || props.page === "7" || props.page === "8"
              ? "2px solid var(--main)"
              : "2px solid var(--borderGrey)",
        }}
      ></div>

      {/* box5 */}
      <div className="Mobile__box">
        {props.page !== "8" && props.page !== "7" && props.page !== "6" && props.page !== "5" && (
          <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
        )}
        {props.page === "5" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" &&
          props.page !== "2" &&
          props.page !== "3" &&
          props.page !== "4" &&
          props.page !== "5" && (
            <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
          )}
        {props.page === "5" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-5 Bar__container__box__top__title-main mb-0">
            Open questions
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop:
            props.page === "6" || props.page === "7" || props.page === "8"
              ? "2px solid var(--main)"
              : "2px solid var(--borderGrey)",
        }}
      ></div>

      {/* box6 */}
      <div className="Mobile__box">
        {props.page !== "8" && props.page !== "7" && props.page !== "6" && (
          <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
        )}
        {props.page === "6" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" &&
          props.page !== "2" &&
          props.page !== "3" &&
          props.page !== "4" &&
          props.page !== "5" &&
          props.page !== "6" && (
            <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
          )}
        {props.page === "6" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-6 Bar__container__box__top__title-main mb-0">
            Video introduction
          </h6>
        )}
      </div>
      <div
        className="Mobile__bar"
        style={{
          borderTop: props.page === "7" || props.page === "8"
              ? "2px solid var(--main)"
              : "2px solid var(--borderGrey)",
        }}></div>

        {/* box7 */}
      <div className="Mobile__box">
        {props.page !== "8" && props.page !== "7" && (
          <ImRadioUnchecked className="Bar__container__box__top__icon Bar__container__box__top__icon-circle " />
        )}
        {props.page === "7" && (
          <ImRadioChecked className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
        )}
        {props.page !== "1" &&
          props.page !== "2" &&
          props.page !== "3" &&
          props.page !== "4" &&
          props.page !== "5" &&
          props.page !== "6" && 
          props.page !== "7" &&
          (
            <BsFillCheckCircleFill className="Bar__container__box__top__icon Bar__container__box__top__icon-radio " />
          )}
        {props.page === "7" && (
          <h6 className="Bar__container__box__top__title Bar__container__box__top__title-7 Bar__container__box__top__title-main mb-0">
            Submit
          </h6>
        )}
      </div>
    </div>
  );
};

export default Mobile;
