import React, { useState, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { HiArrowRightCircle, HiInformationCircle } from "react-icons/hi2";

import { Slider, Input, Select, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Country } from "../../Utilities/Country.js";
import { CountryCities } from "../../Utilities/CountryCities.js";
import { SavingCalculator } from "../../services/auth";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";


function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}


function formatNumberNew(num) {
  const numStr = num.toString().replace(/,/g, '').replace(/(\d*[.]\d*?[1-9]*?)0*$/, '$1');
  const parsedNum = parseFloat(numStr);

  if (!isNaN(parsedNum)) {
    if (parsedNum >= 1000000 && parsedNum <= 10000000) {
      return (parsedNum / 1000000).toFixed(0) + "M";
    } else if (parsedNum > 10000000) {
      return "10M";
    } else if (parsedNum === 0) {
      return "0";
    } else {
      return parsedNum.toLocaleString();
    }
  } else {

    return "0";
  }
}


function formatNumberTASpend(num) {
  const numStr = num.toString().replace(/,/g, '');

  if (numStr.trim() === '' || isNaN(numStr)) {
    return "0";
  }

  const parsedNum = parseFloat(numStr);

  if (parsedNum >= 1000000) {
    return "950,000";
  } else if (parsedNum === 0) {
    return "0";
  } else {
    return parsedNum.toLocaleString();
  }
}

function reverseFormattedNumber(formattedNumber) {

  if (formattedNumber.endsWith("M")) {
    const numericPart = formattedNumber.slice(0, -1);
    return parseFloat(numericPart) * 1000000;
  } else {

    return parseFloat(formattedNumber.replace(/,/g, ""));
  }
}




const SavingDashboardContainer = () => {

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(false);
  const [resultsData, setResultsData] = useState(false);
  const childRef = useRef(null);
  const [citiesList, setCitiesList] = useState([]);

  const handleClick2 = () => {
    setResults(false);
  };



  const { Option, OptGroup } = Select;
  const [formValues, setFormValues] = useState({
    companySize: 5000,
    revenue: 40000000,
    medianSalary: 120500,
    positions: 27,
    BackgroundCheck: 0,
    SkillTest: 0,
    linkedInRecruiter: 0,
    aTsSoftware: 0,
    agencySpend: 0,
    teamSize: 0,
    // jobRole: "",
    location: "",
    city: ""
  });


  const clearFormValues = {
    companySize: 5000,
    revenue: 40000000,
    medianSalary: 120500,
    positions: 27,
    BackgroundCheck: 0,
    SkillTest: 0,
    linkedInRecruiter: 0,
    aTsSoftware: 0,
    agencySpend: 0,
    teamSize: 0,
    // jobRole: "",
    location: "",
    city: ""
  }

  const [errors, setErrors] = useState({});


  const validateForm = () => {
    const newErrors = {};

    if (!formValues.companySize) {
      newErrors.companySize = "Field must be completed";
    }

    if (!formValues.revenue) {
      newErrors.revenue = "Field must be completed";
    }
    // else if (isNaN(formValues.revenue)) {
    //   newErrors.revenue = "Revenue must be a number";
    // }

    if (!formValues.medianSalary) {
      newErrors.medianSalary = "Field must be completed";
    }
    // else if (isNaN(formValues.medianSalary)) {
    //   newErrors.medianSalary = "Median Salary must be a number";
    // }

    if (!formValues.positions) {
      newErrors.medianSalary = "Field must be completed";
    }
    // else if (isNaN(formValues.medianSalary)) {
    //   newErrors.medianSalary = "Positions must be a number";
    // }

    if (!formValues.BackgroundCheck || formValues.BackgroundCheck == 0) {
      newErrors.BackgroundCheck = "Field must be completed";
    }
    // else if (isNaN(formValues.BackgroundCheck)) {
    //   newErrors.BackgroundCheck = "Background Check must be a number";
    // }

    if (!formValues.SkillTest || formValues.SkillTest == 0) {
      newErrors.SkillTest = "Field must be completed";
    }
    // else if (isNaN(formValues.SkillTest)) {
    //   newErrors.SkillTest = "Skill Test must be a number";
    // }


    if (!formValues.linkedInRecruiter || formValues.linkedInRecruiter == 0) {
      newErrors.linkedInRecruiter = "Field must be completed";
    }
    // else if (isNaN(formValues.linkedInRecruiter)) {
    //   newErrors.linkedInRecruiter = "LinkedIn Recruiter must be a number";
    // }


    if (!formValues.aTsSoftware || formValues.aTsSoftware == 0) {
      newErrors.aTsSoftware = "Field must be completed";
    }
    // else if (isNaN(formValues.aTsSoftware)) {
    //   newErrors.aTsSoftware = "ATsSoftware must be a number";
    // }

    if (!formValues.agencySpend || formValues.agencySpend == 0) {
      newErrors.agencySpend = "Field must be completed";
    }
    // else if (isNaN(formValues.agencySpend)) {
    //   newErrors.agencySpend = "Agency Spend must be a number";
    // }

    if (!formValues.teamSize || formValues.teamSize == 0) {
      newErrors.teamSize = "Field must be completed";
    }
    // else if (isNaN(formValues.teamSize)) {
    //   newErrors.teamSize = "teamSize must be a number";
    // }

    // Repeat the validation for other fields like BackgroundCheck, SkillTest, etc.

    // if (!formValues.jobRole) {
    //   newErrors.jobRole = "Job Role is required";
    // }

    if (!formValues.location) {
      newErrors.location = "Field must be completed";
    }
    if (!formValues.city) {
      newErrors.city = "Field must be completed";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleChange = (rangeId, newValue) => {
    setFormValues({
      ...formValues,
      [rangeId]: newValue,
    });
  };

  const handleInputChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  const handleJobRoleSearch = (value) => {
    setFormValues({
      ...formValues,
      jobRole: value,
    });
  };

  const handleLocationChange = (value) => {


    // console.log('Updated citiesList:', citiesList);
    setFormValues({
      ...formValues,
      location: value,
    });

    setCitiesList([]); // Clear the previous cities list

    // Use a setTimeout to update the cities list after clearing
    setTimeout(() => {
      const selectedCountryCities = CountryCities[value];
      setCitiesList(selectedCountryCities); // Set the new cities list
    }, 100);

  };

  const handleCityChange = (value) => {
    setFormValues({
      ...formValues,
      city: value,
    });

  };


  const sendPayloadToAPI = async () => {
    if (validateForm()) {
      setLoading(true);
      const payload = {
        companySize: formValues.companySize,
        revenue: formValues.revenue,
        medianSalary: formValues.medianSalary,
        positions: formValues.positions,
        BackgroundCheck: parseInt(reverseFormattedNumber(formValues.BackgroundCheck)),
        SkillTest: parseInt(reverseFormattedNumber(formValues.SkillTest)),
        linkedInRecruiter: parseInt(reverseFormattedNumber(formValues.linkedInRecruiter)),
        aTsSoftware: parseInt(reverseFormattedNumber(formValues.aTsSoftware)),
        agencySpend: parseInt(reverseFormattedNumber(formValues.agencySpend)),
        teamSize: parseInt(reverseFormattedNumber(formValues.teamSize)),
        location: formValues.location,
        state: formValues.city
      };

      // console.log("payload", payload);

      const response = await SavingCalculator(payload);

      if (response && response?.data?.data) {
        setResultsData(response.data.data.savings);
        setResults(true);
        setLoading(false);

        setFormValues(clearFormValues);
        setCitiesList([]);
      }


      // Send the payload to the API here.
    }
  };


  return (
    <div className="scontainer  edash">
      <div className="container-dash">
        <div className="Dashboard__container mb-0">
          <div className="Experts__container mt-3">
            <div className=" overflow-hidden">
              <div className="px-0">
                <h1 className="fs-1 fm fw-light darkBlue">
                  IndexScore Saving's Calculator
                </h1>
                <p className="darkGrey scontainer__para fm pb-3">
                  Many organizations have multiple Talent Acquisition
                  applications, support from external recruiting agencies,
                  expensive subscription services, in addition to internal
                  full-time employees. Imagine having a true
                  single-source-of-truth, eliminating all your third-party
                  subscription services while accelerating time-to-hire by as much
                  as 95%. That's the Future of Work - That's IndexScore. We are
                  all about ROI!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4 overflow-hidden">
        <div className="scontainer__calc pt-4 position-relative">
          {!results && (
            <div className="Dashboard__container mb-0 overflow-hidden">
              <div className="Experts__container">
                <div className="main-padding">
                  <div className="container-dash">
                    <div className="calc">
                      <style>
                        {`
          .error-message{
            color:red;
          }
          .info-icon{
            color:blue;
          }
        `}
                      </style>
                      <div className="dashboard">
                        <div className="row">
                          <div className={`pe-lg-5 mb-md-0 mb-sm-5 mb-0 col-xl-6`}>
                            <div className="calc__rangebox" >
                              <small className="fm darkGrey">Company Size<Tooltip placement="topLeft" title={'The approximate total employee of your organization '}><HiInformationCircle className="info-icon" />
                              </Tooltip></small>

                              <h1 className="fm fs-3 darkBlue fw-semibold mt-2">
                                <span className="me-1">~</span>
                                {formValues.companySize !== undefined
                                  ? formatNumber(formValues.companySize)
                                  : formatNumber(5000)}
                              </h1>
                              <Slider
                                min={1}
                                max={100000}
                                onChange={(newValue) => handleChange('companySize', newValue)}
                                defaultValue={5000}
                                tooltip={{
                                  open: false,
                                }}
                              />
                              <div className="d-flex justify-content-between calc__rangetext">
                                <small className="fm text-muted">
                                  {formatNumber(1)}
                                </small>
                                <small className="fm text-muted">
                                  {formatNumber(100000)}+
                                </small>
                              </div>
                              {errors.companySize && <div className="error-message">{errors.companySize}</div>}
                            </div>

                            <div className="calc__rangebox" >
                              <small className="fm darkGrey">Revenue<Tooltip placement="topLeft" title={'The approximate yearly revenue of your company '}><HiInformationCircle className="info-icon" />
                              </Tooltip></small>
                              <h1 className="fm fs-3 darkBlue fw-semibold mt-2">
                                <span className="">$</span>
                                {formValues.revenue !== undefined
                                  ? formatNumber(formValues.revenue)
                                  : formatNumber(40000000)}
                              </h1>
                              <Slider
                                min={100000}
                                max={100000000}
                                onChange={(newValue) => handleChange('revenue', newValue)}
                                defaultValue={40000000}
                                tooltip={{
                                  open: false,
                                }}
                              />
                              <div className="d-flex justify-content-between calc__rangetext">
                                <small className="fm text-muted">
                                  <span>$</span>
                                  {formatNumber(100000)}
                                </small>
                                <small className="fm text-muted">
                                  <span>{`<`}</span>
                                  {formatNumber(100000000)}+
                                </small>
                              </div>
                              {errors.revenue && <div className="error-message">{errors.revenue}</div>}
                            </div>
                            <div className="calc__rangebox" >
                              <small className="fm darkGrey">Median Salary<Tooltip placement="topLeft" title={'The approximate median salary of your Talent Acquisition team '}><HiInformationCircle className="info-icon" />
                              </Tooltip></small>
                              <h1 className="fm fs-3 darkBlue fw-semibold mt-2">
                                <span className="">$</span>
                                {formValues.medianSalary !== undefined
                                  ? formatNumber(formValues.medianSalary)
                                  : formatNumber(120500)}
                              </h1>
                              <Slider
                                min={20000}
                                max={250000}
                                onChange={(newValue) => handleChange('medianSalary', newValue)}
                                defaultValue={120500}
                                tooltip={{
                                  open: false,
                                }}
                              />
                              <div className="d-flex justify-content-between calc__rangetext">
                                <small className="fm text-muted">
                                  <span>$</span>
                                  {formatNumber(20000)}
                                </small>
                                <small className="fm text-muted">
                                  <span>$</span>
                                  {formatNumber(250000)}+
                                </small>
                              </div>
                              {errors.medianSalary && <div className="error-message">{errors.medianSalary}</div>}
                            </div>

                            <div className="calc__rangebox" >
                              <small className="fm darkGrey">No. of Positions<Tooltip placement="topLeft" title={'The approximate number of people you are looking hire '}><HiInformationCircle className="info-icon" />
                              </Tooltip></small>
                              <h1 className="fm fs-3 darkBlue fw-semibold mt-2">
                                {formValues.positions !== undefined
                                  ? formatNumber(formValues.positions)
                                  : formatNumber(27)}
                              </h1>
                              <Slider
                                min={1}
                                max={100}
                                onChange={(newValue) => handleChange('positions', newValue)}
                                defaultValue={27}
                                tooltip={{
                                  open: false,
                                }}
                              />
                              <div className="d-flex justify-content-between calc__rangetext">
                                <small className="fm text-muted">
                                  {formatNumber(1)}
                                </small>
                                <small className="fm text-muted">

                                  {formatNumber(100)}+
                                </small>
                              </div>
                              {errors.positions && <div className="error-message">{errors.positions}</div>}
                            </div>
                          </div>

                          <div className={`col-xl-6 ps-xl-5`}>
                            <div className="row g-lg-5 g-md-3 g-sm-5">
                              <div className={`col-xxl-5 calc__inputbox col-xl-6 col-md-5 col-sm-6`} >
                                <label htmlFor={`BackgroundCheck`} className="fm darkGrey nowrap">
                                  <small>Background Check </small>
                                  <span className="midGrey text-14">per year</span>
                                  <small><Tooltip placement="topLeft" title={'The approximate yearly cost of your talent acquisition background check services '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonBefore="$"
                                  defaultValue={formatNumber(0)}
                                  value={formatNumberNew(formValues.BackgroundCheck)}
                                  className="darkBlue mt-2"
                                  id="BackgroundCheck"
                                  onChange={(e) => handleInputChange('BackgroundCheck', e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.BackgroundCheck && <div className="error-message">{errors.BackgroundCheck}</div>}
                              </div>

                              <div className={`calc__inputbox col-xl-6 col-md-5 col-xxl-5 col-sm-6`} >
                                <label htmlFor={`SkillTest`} className="fm darkGrey nowrap">
                                  <small>Skill Test Software </small>
                                  <span className="midGrey text-14">per year</span>
                                  <small><Tooltip placement="topLeft" title={'The approximate yearly cost of your skill test (for example, psychometric testing) service provider '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonBefore="$"
                                  defaultValue={formatNumber(0)}
                                  value={formatNumberNew(formValues.SkillTest)}
                                  className="darkBlue mt-2"
                                  id="SkillTest"
                                  onChange={(e) => handleInputChange('SkillTest', e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.SkillTest && <div className="error-message">{errors.SkillTest}</div>}
                              </div>

                              <div className={`calc__inputbox col-xl-6 col-md-5 col-xxl-5 col-sm-6`} >
                                <label htmlFor={`linkedInRecruiter`} className="fm darkGrey nowrap">
                                  <small>LinkedIn Recruiter</small>
                                  <span className="midGrey text-14"> per year</span>
                                  <small><Tooltip placement="topLeft" title={'The approximate yearly cost of using LinkedIn for recruiting '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonBefore="$"
                                  defaultValue={formatNumber(0)}
                                  className="darkBlue mt-2"
                                  value={formatNumberNew(formValues.linkedInRecruiter)}
                                  id="linkedInRecruiter"
                                  onChange={(e) => handleInputChange('linkedInRecruiter', e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.linkedInRecruiter && <div className="error-message">{errors.linkedInRecruiter}</div>}
                              </div>

                              <div className={`calc__inputbox col-xl-6 col-md-5 col-xxl-5 col-sm-6`} >
                                <label htmlFor={`aTsSoftware`} className="fm darkGrey nowrap">
                                  <small>ATS Software</small>
                                  <span className="midGrey text-14"> per year</span>
                                  <small><Tooltip placement="topLeft" title={'The approximate yearly cost of using a 3rd ATS (Applicant Tracking System) '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonBefore="$"
                                  defaultValue={formatNumber(0)}
                                  className="darkBlue mt-2"
                                  value={formatNumberNew(formValues.aTsSoftware)}
                                  id="aTsSoftware"
                                  onChange={(e) => handleInputChange('aTsSoftware', e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.aTsSoftware && <div className="error-message">{errors.aTsSoftware}</div>}
                              </div>

                              <div className={`col-xxl-5 col-xl-6 col-md-5 calc__inputbox col-sm-6`} >
                                <label htmlFor={`agencySpend`} className="fm darkGrey nowrap">
                                  <small>TA Agency Spend</small>
                                  <span className="midGrey text-14"> per year</span>
                                  <small><Tooltip placement="topLeft" title={'The approximate yearly cost using 3rd party recruiting agencies and independent recruiters '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonBefore="$"
                                  defaultValue={formatNumber(0)}
                                  className="darkBlue mt-2"
                                  value={formatNumberTASpend(formValues.agencySpend)}
                                  id="agencySpend"
                                  onChange={(e) => handleInputChange('agencySpend', e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.agencySpend && <div className="error-message">{errors.agencySpend}</div>}
                              </div>


                              <div className={`col-xxl-5 col-sm-6 col-xl-6 col-md-5 calc__inputbox calc__member`}>
                                <label htmlFor="input8" className="fm darkGrey nowrap">
                                  <small>TA Team Size </small>
                                  <span className="midGrey text-14">per year</span>
                                  <small><Tooltip placement="topLeft" title={'The number of people dedicated to your recruiting efforts '}><HiInformationCircle className="info-icon" />
                                  </Tooltip></small>
                                </label>
                                <Input
                                  addonAfter="Team members"
                                  defaultValue={formatNumber(0)}
                                  className="darkBlue mt-2 calc__memberinput"
                                  id="input8"
                                  value={formatNumberNew(formValues.teamSize)}
                                  onChange={(e) => handleInputChange("teamSize", e.target.value)}
                                  onKeyPress={(e) => {
                                    if (e.which < 48 || e.which > 57) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {errors.teamSize && <div className="error-message">{errors.teamSize}</div>}
                              </div>

                              {/* <div className="col-12 calc__search ">
                                <label htmlFor="input10" className="fm darkGrey">
                                  <small>Job Role</small>
                                </label>
                                <Input
                                  value={formValues.jobRole}
                                  className="darkBlue mt-2 calc__memberinput"
                                  id="input8"
                                  onChange={(e) => handleInputChange("jobRole", e.target.value)}
                                />
                                {errors.jobRole && <div className="error-message">{errors.jobRole}</div>}
                              </div> */}
                              <div className="General col-xxl-10 col-xl-12 col-md-10 calculator col-12 calc__search mb-xl-0 mb-4">
                                <label htmlFor="input10" className="fm darkGrey">
                                  <small>Location</small>
                                </label>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    (option?.label ?? "").includes(input)
                                  }
                                  filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? "")
                                      .toLowerCase()
                                      .localeCompare((optionB?.label ?? "").toLowerCase())
                                  }
                                  className="Login--Input Login--select2 mt-1 select-signup mb-2 mx-0 d-block w-100"
                                  size="small"
                                  placeholder="Select Location"
                                  onChange={handleLocationChange}
                                >
                                  <OptGroup>
                                    {Country.map((item) => (
                                      <Option
                                        value={item.name}
                                        label={item.name.toLowerCase()}
                                        key={item.name}
                                      >
                                        {item.name}
                                      </Option>
                                    ))}
                                  </OptGroup>
                                </Select>
                                {errors.location && <div className="error-message">{errors.location}</div>}
                              </div>
                              {citiesList.length > 0 && (
                                <div className="col-12 calc__search">
                                  <label htmlFor="input10" className="fm darkGrey">
                                    <small>City</small>
                                  </label>
                                  <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').includes(input)
                                    }
                                    filterSort={(optionA, optionB) =>
                                      (optionA?.label ?? '')
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                    className="Login--Input Login--select2 select-signup mb-2 mx-0 d-block w-100"
                                    size="small"
                                    placeholder="Select City"
                                    onChange={handleCityChange}
                                  >
                                    <OptGroup>
                                      {citiesList.map((city) => (
                                        <Option
                                          value={city}
                                          label={city.toLowerCase()}
                                          key={city}
                                        >
                                          {city}
                                        </Option>
                                      ))}
                                    </OptGroup>
                                  </Select>
                                  {errors.city && <div className="error-message">{errors.city}</div>}
                                </div>
                              )}
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="position-absolute top-0 w-100 h-100 scontainer__calc__loading"
            style={{
              opacity: loading ? "1" : "0",
              zIndex: loading ? "2" : "0",
            }}
          >
            <div className="main-padding scontainer__calc__loading--inner h-100 d-flex align-items-center">
              <div className="container-dash">
                <h1 className="darkGrey fm fs-3 fw-light text-center">
                  Calculating your savings with IndexScore...
                </h1>
              </div>
            </div>
          </div>
          <div
            className="position-absolute top-0 start-0 w-100"
            style={{
              opacity: results ? "1" : "0",
              zIndex: results ? "2" : "0",
            }}
          >
            <div className="result dashResults position-relative">
              {/* strip1 */}
              <div className="result__strip1 result__strip--height">
                <div
                  className={`position-absolute result__strip--height result__strip1__overlay top-0 ${results ? "start-0" : ""
                    }`}
                ></div>
                <div className="px-0 h-100 position-relative result__strip1__container">
                  <div className="position-relative overflow-hidden w-100 h-100">

                    <div
                      className="main-padding result__strip1__box h-100 d-flex flex-column justify-content-center"
                      style={{ opacity: results ? "1" : "0" }}
                    >
                      <h1 className="darkGreen fm result__heading mb-0">${resultsData && resultsData.indexscore_cost.toLocaleString()}</h1>
                      <p className="fm darkGreen fw-light text-14 mb-0 py-1">
                        Cost per hire = ${resultsData && resultsData.indexscore_cph.toLocaleString()}
                      </p>
                      <div className="d-inline-block">
                        <Link
                          to={"/book-a-demo"}
                          className="text-decoration-none text-14 darkGreen fw-semibold fm d-inline-flex align-items-center transition result__strip1__link"
                        >
                          <HiArrowRightCircle className="fs-5 me-1" /> Book a demo with
                          us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* strip2 */}
              <div className="result__strip2 result__strip--height position-relative">
                <div
                  className={`position-absolute result__strip--height result__strip2__overlay top-0 ${results ? "start-0" : ""
                    }`}
                ></div>
                <div className="Experts__container ms-xxl-5 ms-lg-4 ms-3 px-0 h-100 position-relative result__strip2__container">

                  {/* 3x line start */}
                  <div className="line-3x position-absolute">
                    <div className="position-relative">
                      <div className="pill-3x fm text-white rounded-pill position-absolute d-flex justify-content-center align-items-center">{resultsData && resultsData.inhouse_x}</div>
                    </div>
                  </div>
                  {/* 3x line ends */}

                  <div className="position-relative w-100 h-100 overflow-hidden d-flex align-items-center">
                    <div
                      className="result__strip2__box ps-0 d-flex justify-content-between align-items-sm-center"
                      style={{ opacity: results ? "1" : "0" }}
                    >
                      <div>
                        <h1 className="mb-0 text-white fm fw-normal result__heading">
                          Talent Acquisition Org Investment
                        </h1>
                        <p>Your core talent acquisition team approximate yearly cost structure</p>
                      </div>
                      <div className="text-end">
                        <h1 className="fm result__heading mb-sm-0 mb-3 text-white">
                          ${resultsData && resultsData.inhouse_cost.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0, })}
                        </h1>
                        <p className="fm fw-light text-14 mb-0 py-1 text-white nowrap">
                          Cost per hire = ${resultsData && resultsData.inhouse_cph.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0, })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* strip3 */}
              <div className="result__strip3 w-100 result__strip--height position-relative">
                <div
                  className={`position-absolute result__strip--height result__strip3__overlay top-0 ${results ? "start-0" : ""
                    }`}
                ></div>
                <div className="w-100 px-0 h-100 position-relative result__strip3__container">
                  <div className="line-4x position-absolute">
                    <div className="position-relative">
                      <div className="pill-4x fm text-white rounded-pill position-absolute d-flex justify-content-center align-items-center">{resultsData && resultsData.ex_agency_x}</div>
                    </div>
                  </div>
                  <div className="position-relative w-100 h-100 overflow-hidden d-flex align-items-center">
                    <div
                      className=" result__strip3__box ps-0 w-100 d-flex justify-content-between align-items-sm-center"
                      style={{ opacity: results ? "1" : "0" }}
                    >
                      <div>
                        <h1 className="mb-0 text-white fm fw-normal result__heading nowrap">
                          External Recruitment Support
                        </h1>
                        <p>Your non-FTE related recruiting support approximate yearly cost structure
                        </p>
                      </div>
                      <div className="text-end">
                        <h1 className="fm result__heading mb-sm-0 mb-3 text-white">
                          ${resultsData && resultsData.ex_agency_cost.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0, })}
                        </h1>
                        <p className="fm fw-light text-14 mb-0 py-1 text-white nowrap">
                          Cost per hire = ${resultsData && resultsData.ex_agency_cph.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0, })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" mb-0 overflow-hidden container-dash">
        <div className="Experts__container">
          {!results ? (
            <div className="mt-3 pb-4">
              <div className="px-0">
                <button
                  className="text-decoration-none px-4 mt-4 navbar-demo-button d-inline-flex fw-semibold align-items-center"
                  onClick={() => {
                    sendPayloadToAPI()
                  }}
                >
                  {loading ? <Loader /> : 'Calculate'}
                </button>
              </div>
            </div>
          ) : (
            <div className="mt-3 pb-4">
              <div className="">
                <button
                  className="text-decoration-none px-4 mt-4 navbar-demo-button d-inline-flex fw-semibold align-items-center"
                  onClick={handleClick2}
                >
                  Back to Calculations
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingDashboardContainer;
