import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
const PSubmit = (props) => {
  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading Extra__heading--main fw-light mt-lg-5 mb-lg-5 mb-3">
            Just about done!
          </h1>
          <div class="pro__submit__para">
            <p className="Get__left__content__para Signup__para mb-5">
              Thank you for completing your IndexScore application. You can now
              login to view your initial score. We are here to help; you can
              contact can contact us at{" "}
              <a href="mailto:support@indexscore.com" className="mailto__link">
                support@indexscore.com
              </a>
              .
            </p>
          </div>
          <div className="psubmit__criteria p-md-4 py-4 px-3">
            <div className="">
              <img
                src="/assets/images/submit/CriteriaWhite.svg"
                alt=""
                className="psubmit__criteria--img"
              />
            </div>
            <div className="mw-320">
              <p className="psubmit__criteria__para font-16 fm text-white mt-2 mb-4">
                Please complete Criteria psychometric test to be able to fully
                access IndexScore.
              </p>
            </div>
            <Link to="/" className="">
              <button className="psubmit--button text-white w-100 py-2 fm">
                Take the Test
              </button>
            </Link>
          </div>
          <map name="image-map">
            <area
              target=""
              alt=""
              title=""
              href=""
              coords="25,180,746,229"
              shape="rect"
            />
          </map>
          <br /> <br />
          <Link to={"/"} className="text-decoration-none text-white">
            <button
              className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-3 Get__right__button--home`}
            >
              Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PSubmit;
