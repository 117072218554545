import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import SignupModal from "../../Launch/SignupModal/SignupModal";

const menu = [
  {
    id: 0,
    title: "Home",
    link: "/",
    class: "home",
  },
  {
    id: 1,
    title: "Candidate",
    link: "/professional",
    class: "professionals",
  },
  {
    id: 2,
    title: "Enterprise",
    link: "/enterprise",
    class: "enterprise",
  },
  {
    id: 3,
    title: "Pricing",
    link: "/pricing",
    class: "pricing",
  },
  // {
  //   id: 4,
  //   title: "Why IndexScore?",
  //   link: "/why-indexcore",
  //   class:'why-indexcore',
  // },
  {
    id: 5,
    title: "About us",
    link: "/about-us",
    class: "about-us",
  },
  // {
  //   id: 6,
  //   title: "Contact",
  //   link: "/contact-us",
  //   class:'contact-us'
  // },
];

function handleClose() {}

function Sidebar(props) {
  const [scroll, setScroll] = useState(false);
  const [modal, setModal] = useState(false);
  const changeBorder = () => {
    if (window.scrollY >= 35) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    changeBorder();
    window.addEventListener("scroll", changeBorder);
    if (modal) {
      document.body.classList.add("smodal");
    } else document.body.classList.remove("smodal");
  });
  return (
    <>
      <div
        className="offcanvas bow offcanvas-end w-100 d-sm-block d-none justify-content-end"
        data-bs-backdrop="false"
        data-bs-scroll="false"
        tabIndex="-1"
        id="offcanvasRight"
        onClick={handleClose}
        aria-labelledby="offcanvasRightLabel"
        style={{
          borderTop: scroll ? "1px solid var(--bordergrey)" : "0px solid",
        }}
      >
        <div className="offcanvas-body d-flex flex-column justify-content-between desktop__offcanvas-body sidebar__offcanvas">
          <div className="container-xl h-100">
            <div className="main-padding d-flex justify-content-end h-100">
              <div className="sidebar-menu d-flex flex-column justify-content-between h-100">
                <ul className="list-unstyled offcanvas__list d-flex flex-column ">
                  {menu.map((item) => (
                    <li
                      className="offcanvas__list__item text-end mb-2"
                      key={item.id}
                    >
                      <Link
                        to={item.link}
                        className={`${item.class}-active offcanvas__list__item--Link text-end text-decoration-none align-items-center`}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>

                <div>
                  {/* <p className="fm text-end text-grey mb-2">Sign up as:</p>
                  <ul className="list-unstyled offcanvas__list d-flex flex-column ">
                    <li className="offcanvas__list__item text-end mb-2">
                      <Link
                        to={"/professional/signup"}
                        className={`${
                          props.page === "professional-signup"
                            ? "offcanvas__list__item--Link__active"
                            : ""
                        } offcanvas__list__item--Link text-end text-decoration-none align-items-center`}
                      >
                        Candidate
                      </Link>
                    </li>
                    <li className="offcanvas__list__item text-end mb-2">
                      <Link
                        to={"/enterprise/signup"}
                        className={`${
                          props.page === "enterprise-signup"
                            ? "offcanvas__list__item--Link__active"
                            : ""
                        } offcanvas__list__item--Link text-end text-decoration-none align-items-center`}
                      >
                        Enterprise
                      </Link>
                    </li>
                  </ul> */}
                  <Link
                    to={"/log-in"}
                    className="offcanvas__link text-decoration-none"
                  >
                    <button className="offcanvas__link__button  py-1 text-end">
                      Login
                    </button>
                  </Link>
                  <Link
                    to={"/signup"}
                    className="offcanvas__link text-decoration-none"
                  >
                    <button className="offcanvas__link__button  py-1 text-end">
                      Signup
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="smodal">
        <SignupModal state={modal} func={setModal} />
      </div> */}
    </>
  );
}

export default Sidebar;
