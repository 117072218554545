import React from "react";
import { Link } from "react-router-dom";

const Submit = (props) => {
  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading Extra__heading--main fw-light mt-lg-5 mb-lg-5 mb-3">
            All done!
          </h1>
          <p className="Get__left__content__para Signup__para mb-5">
            Thank you for submitting your company account application; you are
            all set to start posting jobs, invite colleagues to participate in
            your interview process, and start hiring qualified candidates in
            days. We are here to support if you need help – we are seconds away
            at{" "}
            <a href="mailto:support@Indexscore.com" className="mailto__link">
              support@Indexscore.com
            </a>
            .
          </p>
          <Link to={"/"} className="text-decoration-none text-white">
            <button
              className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-3 Get__right__button--home`}
            >
              Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Submit;
