import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { BsArrowUpRight } from "react-icons/bs";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useState } from "react";

const filter_data = [
  {
    key: 1,
    small_text: "Accenture",
    heading: "Project manager",
    para: "Description of job/position here",
  },
  {
    key: 2,
    small_text: "IBM:",
    heading: "Lead developer",
    para: "Description of job/position here",
  },
  {
    key: 3,
    small_text: "Google:",
    heading: "L4 Engineer",
    para: "Description of job/position here",
  },
];

const Filter = () => {
  const [industry, setIndustry] = useState(true);
  const [service, setService] = useState(false);
  const [type, setType] = useState(false);

  const toggle1 = () => {
    setIndustry(true);
    setService(false);
    setType(false);
  };
  const toggle2 = () => {
    setIndustry(false);
    setService(true);
    setType(false);
  };
  const toggle3 = () => {
    setIndustry(false);
    setService(false);
    setType(true);
  };
  return (
    <>
      <div className="filter main-padding">
        <div className="container-lg px-0 py-5">
          <div className=" pb-sm-5 pb-2 ">
            <div className="Insight__header d-flex justify-content-between align-items-center p-xxl-4 p-md-3 px-sm-3 py-2">
              <h4 className="Insight__header__title mb-0 fw-semibold">
                Filter:
              </h4>
              <ul className="Insight__header__list list-unstyled d-flex mb-0">
                <li
                  className="Insight__header__list__item text-uppercase"
                  onClick={toggle1}
                  style={{
                    color: industry ? "var(--lightblue)" : "",
                  }}
                >
                  Industry
                  <MdKeyboardArrowDown
                    className="Insight__header__list__item__icon"
                    style={{
                      display: !industry ? "inline" : "none",
                    }}
                  />
                  <MdKeyboardArrowUp
                    className="Insight__header__list__item__icon"
                    style={{
                      display: industry ? "inline" : "none",
                      color: industry ? "var(--lightblue)" : "",
                    }}
                  />
                </li>
                <li
                  className="Insight__header__list__item Insight__header__list__item2 mx-lg-5 mx-sm-4 text-uppercase"
                  onClick={toggle2}
                  style={{
                    color: service ? "var(--lightblue)" : "",
                  }}
                >
                  Service
                  <MdKeyboardArrowDown
                    className="Insight__header__list__item__icon"
                    style={{
                      display: !service ? "inline" : "none",
                    }}
                  />
                  <MdKeyboardArrowUp
                    className="Insight__header__list__item__icon"
                    style={{
                      display: service ? "inline" : "none",
                      color: service ? "var(--lightblue)" : "",
                    }}
                  />
                </li>
                <li
                  className="Insight__header__list__item text-uppercase"
                  onClick={toggle3}
                  style={{
                    color: type ? "var(--lightblue)" : "",
                  }}
                >
                  Type
                  <MdKeyboardArrowDown
                    className="Insight__header__list__item__icon"
                    style={{
                      display: !type ? "inline" : "none",
                    }}
                  />
                  <MdKeyboardArrowUp
                    className="Insight__header__list__item__icon"
                    style={{
                      display: type ? "inline" : "none",
                      color: type ? "var(--lightblue)" : "",
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>
          {filter_data.map((item) => (
            <div className="row">
              <div className="col-12 filter__border py-sm-5 pb-5 pt-4">
                <div className="filter__content ">
                  <small className="filter__content--small">
                    {item.small_text}
                  </small>
                  <div className="d-flex justify-content-between">
                    <h3 className="filter__content__heading mb-0 fw-semibold">
                      {item.heading}
                    </h3>
                    <Link
                      to={"/"}
                      className="text-decoration-none filter__content__link d-sm-block d-none"
                    >
                      <h3 className=" filter__content__link__heading">
                        Share your score{" "}
                        <BsArrowUpRight className="filter__content__link__heading__icon" />
                      </h3>
                    </Link>
                  </div>
                  <p className="filter__content__para mt-0">{item.para}</p>
                  <div className="filter__content__tag d-flex flex-sm-nowrap flex-wrap">
                    <div className="pe-2 mb-sm-0 mb-2">
                      <div className="filter__content__tag__content px-3 ">
                        <small className="filter__content__tag__content--small">
                          Temporary
                        </small>
                      </div>
                    </div>
                    <div className="pe-2 mb-sm-0 mb-2">
                      <div className="filter__content__tag__content px-3">
                        <small className="filter__content__tag__content--small">
                          Remote
                        </small>
                      </div>
                    </div>
                    <div className="pe-2 mb-sm-0 mb-2">
                      <div className="filter__content__tag__content px-3">
                        <small className="filter__content__tag__content--small">
                          Felexible
                        </small>
                      </div>
                    </div>
                  </div>
                  <Link
                    to={"/"}
                    className="text-decoration-none filter__content__link d-block d-sm-none my-3"
                  >
                    <h3 className=" filter__content__link__heading">
                      Share your score{" "}
                      <BsArrowUpRight className="filter__content__link__heading__icon" />
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Filter;
