import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import {
  BsFilter,
  BsSearch,
  BsThreeDotsVertical,
} from "react-icons/bs";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  Avatar,
  Progress,
  Dropdown,
  Button,
  Modal,
  Input,
  Select,
  Upload,
  List,
  Space,
  Checkbox,
  Slider,
  Table,
  Menu,
  Pagination,
  Radio,
} from "antd";
import config from "../../services/apisConfig";
import {
  getInterviewsbyMemberId,
  updateApplicantsFeedback,
  getApplications,
  updateInterviewsStatus,
} from "../../services/auth";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TiArrowUnsorted } from "react-icons/ti";
import { BiChevronDown, BiLogOut } from "react-icons/bi";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Loader } from "rsuite";
import { useSnackbar } from "notistack";
import { MyContext, useAuth } from "../../Utilities/MyContextProvider";
import { Country } from "../../Utilities/Country";
import "rsuite/dist/rsuite.min.css";
import {
  HiOutlineExclamationCircle,
  HiOutlineCheckCircle,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi";
import moment from "moment";
import axios from "axios";
import InterviewerChat from "./InterviewerChat";
import InterviewMobileChat from "./InterviewMobileChat";
import { HiOutlineArrowDownCircle } from "react-icons/hi2";

const InterviewPortal = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { clearUserData, clearContextData, getUserData, logoutAction } =
    useContext(MyContext);
  const { dispatch } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabClick = (tabIndex) => {
    setTabIndex(tabIndex);
  };
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const filterRef = useRef(null);
  const [open1, setOpen1] = useState(false);
  const [filter, setFilter] = useState(false);
  const inputRef = useRef(null);
  const [location, setLocation] = useState(false);
  const [dlocation, setDlocation] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country);
  const [recommendedFeedback, setRecommendedFeedback] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [interviewersData, setInterviewersData] = useState([]);
  const [interviewersDeclineData, setInterviewersDeclineData] = useState([]);
  const [interviewersAcceptedData, setInterviewersAcceptedData] = useState([]);
  const [interviewersPendingData, setInterviewersPendingData] = useState([]);
  const [currentInterviewerId, setCurrentInterviewerId] = useState(
    localStorage.getItem("enterpriseID")
  );
  const [recommend, setRecommend] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [hiredCandidates, setHiredCandidates] = useState([]);
  const [slidervalue, onChangeSlider] = useState(850);
  const [applicantsForInterview, setApplicantsForInterview] = useState([]);
  const [candidateFeedback, setCandidateFeedback] = useState({
    id: null,
    name: "",
    indexScore: null,
    applicantID: "",
    managerID: null,
  });

  const [applicants, setApplicants] = useState([]);

  const memberDataString = localStorage.getItem("memberData");
  const [memberData, setMemberData] = useState(JSON.parse(memberDataString));
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryInterviews, setSearchQueryInterviews] = useState("");
  const [searchQueryAccepted, setSearchQueryAccepted] = useState("");
  const [searchQueryDeclined, setSearchQueryDeclined] = useState("");
  const [state, setState] = useState({
    loading: false,
    loading2: false,
  });

  const { loading, loading2 } = state;
  const [newFilteredCandidates, setNewFilteredCandidates] = useState([]);

  const [appName,setAppName] = useState(false);
  const [appRole,setAppRole] = useState(false);
  const [appCountry,setAppCountry] = useState(false);
  const [sortingOptionApplication, setSortingOptionApplication] = useState("1");

  const [interviewName,setinterviewName] = useState(false);
  const [interviewRole,setInterviewRole] = useState(false);
  const [interviewCompany,setInterviewCompany] = useState(false);
  const [sortingOptionInterview, setSortingOptionInterview] = useState("1");

  const [interviewAcceptedName,setinterviewAcceptedName] = useState(false);
  const [interviewAcceptedRole,setInterviewAcceptedRole] = useState(false);
  const [interviewAcceptedCompany,setInterviewAcceptedCompany] = useState(false);
  const [acceptedSortingOptionInterview, setAcceptedSortingOptionInterview] = useState("1");

  const [interviewDeclineName,setinterviewDeclineName] = useState(false);
  const [interviewDeclineRole,setInterviewDeclineRole] = useState(false);
  const [interviewDeclineCompany,setInterviewDeclineCompany] = useState(false);
  const [declinedSortingOptionInterview, setDeclinedSortingOptionInterview] = useState("1");


  const sortingMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];

  const sortingInterviewMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];


  const sortingAcceptedInterviewMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];

  const sortingDeclinedInterviewMenus = [
    {
      key: "1",
      label: <span>Most Recent</span>,
    },
    {
      key: "2",
      label: <span>Oldest</span>,
    },
  ];


  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleRadioChange = (event) => {
    setSelectedFeedback(event.target.value);
  };

  const handleMenuClick = (e) => {
    setOpen1(true);
  };
  const handleOpenChange = (flag) => {
    setOpen1(flag);
  };

  const filterDrop = () => {
    setFilter(!filter);
    setOpen1(!open1);
    console.log("open:" + open1 + "filter:" + filter);
  };
  function filterClick(event) {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilter(false);
    }
  }

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query === "") {
      setFilteredCountries(Country);
    } else {
      const filtered = Country.filter((item) =>
        item.name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredCountries(filtered);
    }
  };

  useEffect(() => {
    // Check if any of the input fields have been modified
    if (
      recommend !== null &&
      candidateFeedback !== null &&
      recommendedFeedback !== null &&
      rating !== 0
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [recommend, selectedFeedback, candidateFeedback, recommendedFeedback]);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const { Option } = Select;

  const items = [
    {
      key: "1",
      label: <span className="fm darkGrey text-14">Accept</span>,
    },
    {
      key: "2",
      label: <span className="fm darkGrey text-14">Decline</span>,
    },
  ];



  const itemsMenu = [
    {
      label: (
        <div className="d-flex flex-column">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox 
          checked={appName}
          onChange={(e) => setAppName(e.target.checked)}
          className="fm darkGrey">Candidate Name</Checkbox>
          <Checkbox 
          checked={appRole}
          onChange={(e) => setAppRole(e.target.checked)}
          className="fm darkGrey">Role</Checkbox>
          <Checkbox 
          checked={appCountry}
          onChange={(e) => setAppCountry(e.target.checked)}
          className="fm darkGrey">Country</Checkbox>          
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="mb-2">
          <p className="mb-2 fm darkGrey fw-semibold">IndexScore Range</p>
          <div className="slider-parent">
            <input
              type="range"
              min="1"
              max="850"
              value={slidervalue}
              onChange={({ target: { value } }) => {
                onChangeSlider(value);
                const ele = document.querySelector(".buble");
                if (ele) {
                  ele.style.left = `${Number(value / 4)}px`;
                }
              }}
            />
            <div className="buble">{slidervalue}</div>
          </div>
        </div>
      ),
      key: "1",
    },
    
  ];

  const handleSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setSortingOptionApplication(key.key);
  };


  const itemsInterviewsMenu = [
    {
      label: (
        <div className="d-flex flex-column">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox 
          checked={interviewName}
          onChange={(e) => setinterviewName(e.target.checked)}
          className="fm darkGrey">Candidate Name</Checkbox>
          <Checkbox 
          checked={interviewRole}
          onChange={(e) => setInterviewRole(e.target.checked)}
          className="fm darkGrey">Role</Checkbox>
          <Checkbox 
          checked={interviewCompany}
          onChange={(e) => setInterviewCompany(e.target.checked)}
          className="fm darkGrey">Company</Checkbox>          
        </div>
      ),
      key: "0",
    },
  ];

  const itemsAccpetedInterviewsMenu = [
    {
      label: (
        <div className="d-flex flex-column">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox 
          checked={interviewAcceptedName}
          onChange={(e) => setinterviewAcceptedName(e.target.checked)}
          className="fm darkGrey">Candidate Name</Checkbox>
          <Checkbox 
          checked={interviewAcceptedRole}
          onChange={(e) => setInterviewAcceptedRole(e.target.checked)}
          className="fm darkGrey">Role</Checkbox>
          <Checkbox 
          checked={interviewAcceptedCompany}
          onChange={(e) => setInterviewAcceptedCompany(e.target.checked)}
          className="fm darkGrey">Company</Checkbox>          
        </div>
      ),
      key: "0",
    },
  ];

  const itemsDeclinedInterviewsMenu = [
    {
      label: (
        <div className="d-flex flex-column">
          <p className="mb-2 fm darkGrey fw-semibold">Category</p>
          <Checkbox 
          checked={interviewDeclineName}
          onChange={(e) => setinterviewDeclineName(e.target.checked)}
          className="fm darkGrey">Candidate Name</Checkbox>
          <Checkbox 
          checked={interviewDeclineRole}
          onChange={(e) => setInterviewDeclineRole(e.target.checked)}
          className="fm darkGrey">Role</Checkbox>
          <Checkbox 
          checked={interviewDeclineCompany}
          onChange={(e) => setInterviewDeclineCompany(e.target.checked)}
          className="fm darkGrey">Company</Checkbox>          
        </div>
      ),
      key: "0",
    },
  ];


  const handleInterviewSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setSortingOptionInterview(key.key);
  };


  const handleAcceptedInterviewSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setAcceptedSortingOptionInterview(key.key);
  };

  const handleDeclinedInterviewSortingChange = (key) => {
    console.log("Sorting option changed to:", key.key);
    setDeclinedSortingOptionInterview(key.key);
  };

  function logOutUser() {
    const rememberedEmail = localStorage.getItem('emailAddress');
    const rememberedPassword = localStorage.getItem('password');
    const rememberedRememberMe = localStorage.getItem('rememberMe');
    navigate(`/log-in`);

    localStorage.clear();
    if (rememberedEmail && rememberedPassword && rememberedRememberMe) {
      localStorage.setItem('emailAddress', rememberedEmail);
      localStorage.setItem('password', rememberedPassword);
      localStorage.setItem('rememberMe', rememberedRememberMe);
    }
    clearUserData();
    clearContextData();
    logoutAction();
  }

  useEffect(() => {
    fetchInterviews();
    // fetchApplications();
  }, []);

  const fetchInterviews = async () => {
    handleChange("loading2", true);
    const payload = {
      memberID: localStorage.getItem("enterpriseID"),
    };
    try {
      const response = await getInterviewsbyMemberId(payload);
      const data = response.data.data;
      console.log("data",data);
      if (data) {
        const interviewsData = [];
        const declinedInterviews = [];
        const acceptedInterviews = [];
        const pendingInterviews = [];
        var applicatantsRecord;
        for (const job of data) {
          const payload = {
            jobID: job.jobID, // Assuming jobID is the identifier for applications
          };
          const appsResponse = await getApplications(payload);
          console.log("getApplications",appsResponse);
          const applicantsData = appsResponse.data.data;

          const interviewObject = {
            id: job._id,
            key: job._id,
            jobid: job.jobID,
            applicantID: job.applicationID,
            small_text: "Accenture",
            companyName: job.company_name,
            candidateID: job.candidateID,
            candidateName: job.candidateName,
            status: job.status,
            jobTitle: job.job_title,
            candidateData:job.candidateData,
            candidatePhoto:job.candidateData && job.candidateData.profileImage ? job.candidateData.profileImage.docs : '',
            companylogo: "/assets/images/Dashboard/logo-5.png",
            role_overview: job.job_role_text,
            date_start: job.interview_start_Date,
            interviewers: job.interviewers,
            date_end: job.interview_end_Date,
            date:job.createdAt,
            locations: job.job_location,
            managerID: job.managerID,
            zoomLink: job?.zoom_meeting ? job.zoom_meeting.join_url : null,
          };
          console.log("interviewObject",interviewObject);

          const applicantsDataWithManagerID = applicantsData.map(
            (applicant) => ({
              ...applicant,
              managerID: interviewObject.managerID,
            })
          );

          applicatantsRecord = applicantsDataWithManagerID;

          interviewsData.push(interviewObject);

          setApplicantsForInterview(applicantsData);
        }

        const matchedApplicants = applicatantsRecord.filter((applicant) => {
          return interviewsData.some(
            (interview) => interview.applicantID === applicant._id
          );
        });

        const myId = localStorage.getItem("enterpriseID");
        const filteredInterviews = matchedApplicants.filter((applicant) => {
          return interviewsData.some((interview) => {
            if (applicant._id === interview.applicantID) {
              return interview.interviewers.some((interviewer) => {
                if (interviewer.id === myId) {
                  // Check the status here
                  if (
                    interviewer.status === "accepted" ||
                    (interviewer.status !== "pending" &&
                      interviewer.status !== "rejected")
                  ) {
                    return true;
                  }
                }
              });
            }
            return false;
          });
        });        
        setHiredCandidates(filteredInterviews);

        // Filter interviews based on the status of any interviewer
        const filteredAccepted = interviewsData.filter((interviewObject) => {
          if (interviewObject.interviewers.length > 0) {
            // Check if at least one interviewer has the desired status and matches myId
            return interviewObject.interviewers.some((interviewer) => {
              return (
                interviewer.status === "accepted" && interviewer.id === myId
              );
            });
          }
          // Handle the case where interviewers array is empty
          return false;
        });

        const filteredRejected = interviewsData.filter((interviewObject) => {
          if (interviewObject.interviewers.length > 0) {
            // Check if at least one interviewer has the desired status
            return interviewObject.interviewers.some((interviewer) => {
              return (
                interviewer.status === "rejected" && interviewer.id === myId
              );
            });
          }
          // Handle the case where interviewers array is empty
          return false;
        });

        const filteredNoStatus = interviewsData.filter((interviewObject) => {
          if (interviewObject.interviewers.length > 0) {
            // Check if at least one interviewer has the desired status
            return interviewObject.interviewers.some((interviewer) => {
              return (
                interviewer.status === "pending" && interviewer.id === myId
              );
            });
          }
          // Handle the case where interviewers array is empty
          return false;
        });

        // Now, 'filteredNoStatus' contains interviews with neither 'accepted' nor 'rejected' status.

        setInterviewersData(interviewsData);
        setInterviewersDeclineData(filteredRejected);
        setInterviewersAcceptedData(filteredAccepted);
        setInterviewersPendingData(filteredNoStatus);
      }
      handleChange("loading2", false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitFeedback = async () => {
    setFeedbackLoading(true);
    try {
      const InterviewerFeedback = {
        interviewerID: localStorage.getItem("enterpriseID"),
        interviewerName: localStorage.getItem("userProfileName"),
        candidateID: candidateFeedback.id,
        candidateName: candidateFeedback.name,
        department: memberData.department,
        managerID: candidateFeedback.managerID,
        recommendation: recommend,
        feedback: selectedFeedback,
        rating: rating,
        feedbackComment: recommendedFeedback,
      };

      const payloadFeedback = {
        _id: candidateFeedback.applicantID,
        feedback: InterviewerFeedback,
      };

      const responseFeedbackSubmit = await updateApplicantsFeedback(
        payloadFeedback
      );
      if (responseFeedbackSubmit) {
        enqueueSnackbar("Feedback Submitted!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });

        setCandidateFeedback({
          id: null,
          name: "",
          indexScore: null,
          applicantID: "",
        });
        setRecommend(null);
        setSelectedFeedback(null);
        setRating(0);
        setRecommend("");
        setRecommendedFeedback("");
        setFeedbackLoading(false);
        setCandidateFeedback({ candidateID: "" });

        fetchInterviews();

        // navigate('/enterprise/dashboard/jobs');
      }
    } catch (error) {
      // Handle the error here
      console.error("Error submitting feedback:", error);
      enqueueSnackbar("An error occurred while submitting feedback", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setFeedbackLoading(false);
    }
  };

  const handleInterviewsStatus = async (interviewerData, status) => {
    
    const interviewerName = localStorage.getItem("userProfileName");
    const interviewerId = localStorage.getItem("enterpriseID");
    const payload = {
      _id: interviewerData.id,
      interviewerId: interviewerId,
      status: status,
      candidateName: interviewerName,
      department: memberData.department,
      job_title: interviewerData.jobTitle,
    };

    const responseInterviewStatus = await updateInterviewsStatus(payload);
    if (responseInterviewStatus.status == 200) {
      enqueueSnackbar("Status Submitted!", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      fetchInterviews();
    }
  };

  const handleDownload = async (pdfUrl) => {
    // console.log("pdfurl" + pdfUrl);
    try {
      const response = await axios({
        url: config.downloadURL.downloadURL,
        method: "POST",
        data: {
          Key: pdfUrl,
        },
        responseType: "blob", // very very important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "resume.pdf");
        document.body.appendChild(link);
        link.click();
      });

      const responseData = response;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  

  const filteredCandidates =
  hiredCandidates &&
  hiredCandidates.filter((item) => {
    const searchQueryLower = searchQuery.toLowerCase();
    const candidateName = item.candidateName
      ? item.candidateName.toLowerCase()
      : "";
    const jobTitle = item.job_title ? item.job_title.toLowerCase() : "";
    const dateEnd = item.createdAt
      ? moment(item.createdAt).format("YY/MM/DD").toLowerCase()
      : "";

    const nameChecked = appName;
    const roleChecked = appRole;
    const countryChecked = appCountry;

    if (nameChecked || roleChecked || countryChecked) {
      return (
        (nameChecked && candidateName.includes(searchQueryLower)) ||
        (roleChecked && jobTitle.includes(searchQueryLower)) ||
        (countryChecked &&  dateEnd.includes(searchQueryLower)) 
      ) && Number(item.indexScore) < slidervalue;
    } else {
      return (
        candidateName.includes(searchQueryLower) ||
        jobTitle.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      ) && Number(item.indexScore) < slidervalue;
    }
  }).sort((a, b) => {    
    const sortingNumber = parseInt(sortingOptionApplication, 10); // Convert to number
    if (sortingNumber === 1) {
      // Sort by most recent
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sortingNumber === 2) {
      // Sort by oldest
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    // Default sorting
    return 0;
  });



  const [sortingOption, setSortingOption] = useState("1"); // Initial sorting option

  const sortedCandidates = useMemo(() => {
    if (sortingOption === "1") {
      // Sort by most recent
      return [...filteredCandidates].sort(
        (a, b) => moment(b.date_start) - moment(a.date_start)
      );
    } else if (sortingOption === "2") {
      // Sort by oldest
      return [...filteredCandidates].sort(
        (a, b) => moment(a.date_start) - moment(b.date_start)
      );
    }
    // Default return the unsorted array
    return filteredCandidates;
  }, [filteredCandidates, sortingOption]);

  const tableColumns = [
    {
      title: <>Candidates <span className="fw-normal">({
        filteredCandidates ? filteredCandidates.length : ""
      })</span></>,
      width: 300,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Applied Date",
      dataIndex: "date",
      width: 150,
      key: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix()
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 250,
      key: "2",
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
      width: 200,
      key: "2",
      sorter: true,
    },
    {
      title: "IndexScore",
      dataIndex: "indexscore",
      key: "3",
      width: 120,
      sorter: {
        compare: (a, b) => a.indexScore - b.indexScore,
        multiple: 3,
      },
      render: (indexScore) => (
        <div className="text-start main__color__text">
          <h6 className="text-14 fm text-start">{indexScore}</h6>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      key: "5",
      sorter: (a, b) => {
        const roleA = (a.role || '').toLowerCase();
        const roleB = (b.role || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Resume",
      dataIndex: "resume",
      width: 100,
      key: "2",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      width: 130,
      key: "2",
      sorter: true,
    },
  ];

  const formattedCandidates = filteredCandidates.map((candidate, index) => ({
    key: candidate.id,
    name: (
      <>
        <div className="d-flex align-items-center me-xl-0 me-2">
          <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-start ${candidate.candidatePhoto ? 'candidate__photo' : ''}`}>
            <img
              src={candidate.candidatePhoto ? candidate.candidatePhoto : `/assets/images/user.svg`}
              alt=""
              className="candidates__avatar--img img-fluid"
            />
          </div>
          <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
            {candidate.candidateName}{" "}
          </h6>
        </div>
      </>
    ),
    date: moment(candidate.createdAt).format('L'),
    role: candidate.job_title,
    location: candidate.country ? candidate.country : 'United States',
    indexscore: candidate.indexScore,
    status: candidate.feedback_result ? "Recommended" : "Pending",
    resume: (
      <span
        className={` fm tag-14 text-capitalize rounded-pill resume-icon-download d-flex justify-content-start`}
      >
        <img
          onClick={() => {
            handleDownload(candidate.candidateResume.Key);
          }}
          src="/assets/images/resume-icon.png"
        />
      </span>
    ),
    rating: (
      <div className="rounded-circle d-inline-flex justify-content-center align-items-center">
        {candidate?.feedback_result?.final_rating !== undefined
          ? Array(5)
              .fill(null)
              .map((_, index) =>
                index < candidate.feedback_result.final_rating ? (
                  <AiFillStar key={index} className="stars filled-star" />
                ) : (
                  <AiOutlineStar className="stars" key={index} />
                )
              )
          : Array(5)
              .fill(null)
              .map((_, index) => <AiOutlineStar key={index} />)}
      </div>
    ),
  }));

  const [currentPage1, setCurrentPage1] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = formattedCandidates.length;

  const onPageChange = (page) => {
    setCurrentPage1(page);
  };

  const showTotal = (total, range) => {
    const currentPageNo = Math.ceil(range[1] / itemsPerPage);
    const totalPages = Math.ceil(total / itemsPerPage);
    return (
      <div className="fm text-12 mt-2">
        Page {currentPageNo} of {totalPages}
      </div>
    );
  };

  const filteredCandidatesPending =
  interviewersData &&
  interviewersPendingData.filter((item) => {
    const searchQueryLower = searchQueryInterviews.toLowerCase();
    const candidateName = item.candidateName
      ? item.candidateName.toLowerCase()
      : "";
    const candidateCompany = item.companyName
      ? item.companyName.toLowerCase()
      : "";
    const jobTitle = item.jobTitle ? item.jobTitle.toLowerCase() : "";
    const dateStart = item.date_start
      ? moment(item.date_start).format("YY/MM/DD").toLowerCase()
      : "";
    const dateEnd = item.date_end
      ? moment(item.date_end).format("YY/MM/DD").toLowerCase()
      : "";

    if (interviewName || interviewRole || interviewCompany) {
      return (
        (interviewName && candidateName.includes(searchQueryLower)) ||
        (interviewRole && jobTitle.includes(searchQueryLower)) ||
        (interviewCompany && candidateCompany.includes(searchQueryLower)) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    } else {
      // If no checkbox is checked, search from all fields
      return (
        candidateName.includes(searchQueryLower) ||
        candidateCompany.includes(searchQueryLower) ||
        jobTitle.includes(searchQueryLower) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    }
  }).sort((a, b) => {
    console.log("Sorting option:", sortingOptionInterview);    
    const sortingNumber = parseInt(sortingOptionInterview, 10); // Convert to number
    if (sortingNumber === 1) {
      // Sort by most recent
      return new Date(b.date) - new Date(a.date);
    } else if (sortingNumber === 2) {
      // Sort by oldest
      return new Date(a.date) - new Date(b.date);
    }
    // Default sorting
    return 0;
  });


  const interviewColumns = [
    {
      title: <>Candidates <span className="fw-normal">({
        filteredCandidatesPending ? filteredCandidatesPending.length : ""
      })</span></>,
      width: 230,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 200,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.role || '').toLowerCase();
        const roleB = (b.role || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 100,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.role || '').toLowerCase();
        const roleB = (b.role || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Interview Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
    },
    {
      title: "Interview End Date",
      dataIndex: "endDate",
      key: "3",
      width: 130,
      sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix()
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "9",
      width: 140,
      fixed: "right",
    },
  ];

  const formattedInterviews = filteredCandidatesPending.map(
    (candidate, index) => ({
      key: candidate.id,
      name: (
        <>
          <div className="d-flex align-items-center me-xl-0 me-2">
            <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-start ${candidate.candidatePhoto ? 'candidate__photo' : ''}`}>
              <img
                src={candidate.candidatePhoto? candidate.candidatePhoto : `/assets/images/user.svg`}
                alt=""
                className="candidates__avatar--img img-fluid"
              />
            </div>
            <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
              {candidate.candidateName}{" "}
            </h6>
          </div>
        </>
      ),
      startDate: moment(candidate.date_start).format("L"),
      endDate: moment(candidate.date_end).format("L"),
      role: candidate.jobTitle,
      company: candidate.companyName,
      action: (
        <>
          <div className="d-flex gap-2">
            <button
              onClick={() => handleInterviewsStatus(candidate, "accepted")}
              className="btn--main fm rounded-2"
            >
              Accept
            </button>
            <button
              onClick={() => handleInterviewsStatus(candidate, "rejected")}
              className="bg-transparent fm darkGrey rounded-2"
            >
              Decline
            </button>
          </div>
        </>
      ),
    })
  );

  const filteredCandidatesAccepted =
  interviewersData &&
  interviewersAcceptedData.filter((item) => {
    const searchQueryLower = searchQueryAccepted.toLowerCase();
    const candidateName = item.candidateName
      ? item.candidateName.toLowerCase()
      : "";
    const candidateCompany = item.companyName
      ? item.companyName.toLowerCase()
      : "";
    const jobTitle = item.jobTitle ? item.jobTitle.toLowerCase() : "";
    const dateStart = item.date_start
      ? moment(item.date_start).format("YY/MM/DD").toLowerCase()
      : "";
    const dateEnd = item.date_end
      ? moment(item.date_end).format("YY/MM/DD").toLowerCase()
      : "";

    if (interviewAcceptedName || interviewAcceptedRole || interviewAcceptedCompany) {
      return (
        (interviewAcceptedName && candidateName.includes(searchQueryLower)) ||
        (interviewAcceptedRole && jobTitle.includes(searchQueryLower)) ||
        (interviewAcceptedCompany && candidateCompany.includes(searchQueryLower)) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    } else {
      // If no checkbox is checked, search from all fields
      return (
        candidateName.includes(searchQueryLower) ||
        candidateCompany.includes(searchQueryLower) ||
        jobTitle.includes(searchQueryLower) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    }
  }).sort((a, b) => {    
    const sortingNumber = parseInt(acceptedSortingOptionInterview, 10); // Convert to number
    if (sortingNumber === 1) {
      // Sort by most recent
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sortingNumber === 2) {
      // Sort by oldest
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    // Default sorting
    return 0;
  });

  const interviewColumnsAccepted = [
    {
      title: <>Candidates <span className="fw-normal">({
        filteredCandidatesAccepted ? filteredCandidatesAccepted.length : ""
      })</span></>,
      width: 170,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 130,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.role || '').toLowerCase();
        const roleB = (b.role || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 100,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.company || '').toLowerCase();
        const roleB = (b.company || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Interview Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 100,
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
    },
    {
      title: "Interview End Date",
      dataIndex: "endDate",
      key: "3",
      width: 100,
      sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix()
    },
    {
      title: "Meeting",
      dataIndex: "action",
      key: "9",
      width: 50,
      fixed: "right",
    },
  ];

  const formattedInterviewsAccepted = filteredCandidatesAccepted.map(
    (candidate, index) => ({
      key: candidate.id,
      name: (
        <>
          <div className="d-flex align-items-center me-xl-0 me-2">
            <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-start ${candidate.candidatePhoto ? 'candidate__photo' : ''}`}>
              <img
                src={candidate.candidatePhoto ? candidate.candidatePhoto : `/assets/images/user.svg`}
                alt=""
                className="candidates__avatar--img img-fluid"
              />
            </div>
            <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
              {candidate.candidateName}{" "}
            </h6>
          </div>
        </>
      ),
      startDate: moment(candidate.date_start).format("L"),
      endDate: moment(candidate.date_end).format("L"),
      role: candidate.jobTitle,
      company: candidate.companyName,
      action: (
        <>
          {candidate.zoomLink ? (
            <Link to={candidate.zoomLink} target="_blank">
              <img
                className="zoom-icon"
                src="/assets/images/icon_zoom_positive.svg"
              />
            </Link>
          ) : (
            <div>
              <img
                className="zoom-icon"
                src="/assets/images/icon_zoom_positive.svg"
                alt="Zoom Icon"
              />
            </div>
          )}
        </>
      ),
    })
  );

  const filteredCandidatesDecline =
  interviewersData &&
  interviewersDeclineData.filter((item) => {
    const searchQueryLower = searchQueryDeclined.toLowerCase();
    const candidateName = item.candidateName
      ? item.candidateName.toLowerCase()
      : "";
    const candidateCompany = item.companyName
      ? item.companyName.toLowerCase()
      : "";
    const jobTitle = item.jobTitle ? item.jobTitle.toLowerCase() : "";
    const dateStart = item.date_start
      ? moment(item.date_start).format("YY/MM/DD").toLowerCase()
      : "";
    const dateEnd = item.date_end
      ? moment(item.date_end).format("YY/MM/DD").toLowerCase()
      : "";

    if (interviewDeclineName || interviewDeclineRole || interviewDeclineCompany) {
      return (
        (interviewDeclineName && candidateName.includes(searchQueryLower)) ||
        (interviewDeclineRole && jobTitle.includes(searchQueryLower)) ||
        (interviewDeclineCompany && candidateCompany.includes(searchQueryLower)) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    } else {
      // If no checkbox is checked, search from all fields
      return (
        candidateName.includes(searchQueryLower) ||
        candidateCompany.includes(searchQueryLower) ||
        jobTitle.includes(searchQueryLower) ||
        dateStart.includes(searchQueryLower) ||
        dateEnd.includes(searchQueryLower)
      );
    }
  }).sort((a, b) => {    
    const sortingNumber = parseInt(declinedSortingOptionInterview, 10); // Convert to number
    if (sortingNumber === 1) {
      // Sort by most recent
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else if (sortingNumber === 2) {
      // Sort by oldest
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    // Default sorting
    return 0;
  });

  const interviewColumnsDeclined = [
    {
      title: <>Candidates <span className="fw-normal">({
        filteredCandidatesDecline ? filteredCandidatesDecline.length : ""
      })</span></>,
      width: 170,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Role",
      dataIndex: "role",
      width: 130,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.role || '').toLowerCase();
        const roleB = (b.role || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      width: 100,
      key: "2",
      sorter: (a, b) => {
        const roleA = (a.company || '').toLowerCase();
        const roleB = (b.company || '').toLowerCase();
        return roleA.localeCompare(roleB);
      },
    },
    {
      title: "Interview Start Date",
      dataIndex: "startDate",
      key: "3",
      width: 100,
      sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()
    },
    {
      title: "Interview End Date",
      dataIndex: "endDate",
      key: "3",
      width: 100,
      sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix()
    },
    {
      title: "Meeting",
      dataIndex: "action",
      key: "9",
      width: 50,
      fixed: "right",
    },
  ];

  const formattedInterviewsDeclined = filteredCandidatesDecline.map(
    (candidate, index) => ({
      key: candidate.id,
      name: (
        <>
          <div className="d-flex align-items-center me-xl-0 me-2">
            <div className={`rounded-circle candidates__avatar d-flex justify-content-center align-items-start ${candidate.candidatePhoto ? 'candidate__photo' : ''}`}>
              <img
                src={candidate.candidatePhoto ? candidate.candidatePhoto : `/assets/images/user.svg`}
                alt=""
                className="candidates__avatar--img img-fluid"
              />
            </div>
            <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
              {candidate.candidateName}{" "}
            </h6>
          </div>
        </>
      ),
      startDate: moment(candidate.date_start).format("L"),
      endDate: moment(candidate.date_end).format("L"),
      role: candidate.jobTitle,
      company: candidate.companyName,
      action: (
        <>
          {candidate.zoomLink ? (
            <Link to={candidate.zoomLink} target="_blank">
              <img
                className="zoom-icon"
                src="/assets/images/icon_zoom_positive.svg"
              />
            </Link>
          ) : (
            <div>
              <img
                className="zoom-icon"
                src="/assets/images/icon_zoom_positive.svg"
                alt="Zoom Icon"
              />
            </div>
          )}
        </>
      ),
    })
  );
  const nameopt = [
    {
      value: "Admin",
      label: "Admin",
      avatar: "/assets/images/userAvatar2.svg",
    },
    {
      value: "Manager",
      label: "Manager",
      avatar: "/assets/images/userAvatar2.svg",
    },
  ];

  return (
    <>
      <div className="fm overflow-hidden">
        <section className="">
          <style>
            {`
                    
                    .header-dropdown>.ant-space-item:first-child {
                        width: 30px;
                        height: 30px;
                        border: 1px solid #ddd;
                        padding: 0px;
                        text-align: center;
                        border-radius:50%;
                    }
                    
                    .header-dropdown>.ant-space-item:first-child img {
                        width: 100%;
                        object-fit: contain;
                        height: 70%;
                    }

                    .header-right {
                        margin-top: 15px;
                    }
                    
                    .header-right .header-dropdown {
                        padding: 5px;
                        color: #333;
                        font-weight: 600;
                    }
                    .interviewer-main-section .container {                        
                        margin-top: 30px;
                        border-radius: 15px;
                    }
                    .interviewer-main-section-border {
                        opacity: 1;
                        border-color: #70707040;
                    }
                    .interviewerHeader {
                        text-align: center;
                        padding:20px;
                        display: flex;
                        align-items: center;
                        border: 1px solid #70707040;
                        border-radius: 8px;
                        
                    }
                    .empty-box {
                        display: flex;
                        height: 100%;
                        border: 1px solid #70707040;
                        border-radius: 8px;
                    }
                    .interviewerDetail{
                        margin-left:10px;
                    }
                    .interviewerDetail .interviewer-name h3 {
                        font-size: 20px;
                        text-align: left;
                        margin-bottom: 0px;
                        color:#0D2159;
                    }
                    
                    .interviewerHeader .imageBox {
                        width: 68px;
                        height: 68px;
                        display: inline-block;
                        border-radius: 50%;
                        background: #5F89FF;
                        padding: 15px;
                    }
                    
                    .interviewerHeader .imageBox img {
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                    }
                    
                    .interviewerDetail .emailBox {
                        color: #108a01;
                    }
                    
                    .interviewerDetail .emailBox span,.interviewerDetail .emailBox a {
                        color: #4A4A49;
                        text-decoration: none;
                        
                    }

                    .interviewerDetail .departmentBox svg{
                        color: #108a01;
                    }
                    .interviewerDetail .departmentBox span{
                        color: #000;
                        font-weight:600;
                    }
                    
                    .interviewerDetail .headerButtons {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                    }
                    
                    .interviewerDetail .headerButtons button {
                        padding: 10px 30px;
                        border-radius: 25px;
                        text-transform: capitalize;
                        font-weight: 600;
                    }
                    
                    .interviewerDetail .headerButtons button.primary-button {
                        border: 2px solid #108a01;
                        background: transparent;
                        color: #108a01;
                    }
                    
                    .interviewerDetail .headerButtons button.secondary-button {
                        background: #108a01;
                        color: #fff;
                        min-width: 150px;
                    }
                    
                    
                    

                    .tab-contents .row.enterprise {
                        padding: 10px 0;
                        background:#fff;
                        border-radius:6px;
                        margin-bottom:2px;
                    }
                    .tab-contents .row.enterprise:hover {
                        background: #F8F8F8;
                    }
                    .candidates__avatar {
                        padding-top: 7px;
                    }
                    .interview-tab-items {
                        border-bottom: 1px solid #70707040;
                    }
                    .interviewer-main-section .interview-tab-items .react-tabs__tab {
                        font-weight: 400;
                    }

                    .interviewer-main-section .interview-tab-items .react-tabs__tab--selected {
                        border: none;
                        font-weight:500;
                        color:#486FD9;
                        position: relative;
                        background: transparent;
                        padding-bottom: 10px;                        
                    }
                    
                    .interviewer-main-section .interview-tab-items .react-tabs__tab--selected:before {
                        width: 50px;
                        height: 4px;
                        background: #486FD9;
                        content: "";
                        position: absolute;
                        bottom: 0px;
                        left: 0;
                        right: 0;
                        margin: auto;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                    .main__color__text{
                        color:#A6DD50;
                    }
                                        
                    .applicants__data {
                        background: rgb(230 230 230 / 25%);
                        border-radius: 6px;
                        
                    }
                    .applicants__data__footer p {
                        color: rgb(74 74 73);
                        font-size: 12px;
                        margin:0px;
                    }
                    
                    .applicants__data__footer p span {
                        opacity: 0.75;
                    }
                    .location {
                        background: #bfd0ff;
                        padding: 5px 8px;
                        border-radius: 20px;
                    }
                    .filled-star{
                        color:#4A4A49;
                    }
                    .stars{
                        font-size:14px;
                    }
                    .zoom-icon{
                        max-width:30px;
                    }
                    .star-rating {
                            font-size: 30px;
                            margin-bottom:0px;
                        }
                    .star {
                        cursor: pointer;
                        color: gray;
                      }
                      
                      .selected {
                        color: #14a800;
                      }
                      .no-record-message{
                        text-align:center;
                        padding:20px;
                      }
                      .menu-button{
                        background:transparent;
                      }
                     
                      .menu-button svg {
                        margin-right: 10px;
                        margin-top: 3px;
                    }
                    .resume-icon-download img{
                        cursor:pointer;
                        width:30px;
                    }

                    .slider-parent input[type="range"] {
                        background: #91caff;
                        border-radius: 20px;
                        width: 100%;
                        height: 4px;
                        position: relative;
                        z-index: 2;
                    }
                    
                    
                    `}
          </style>
          <div className="enav__top bg-darkBlue position-fixed top-0 w-100 start-0">
            <div className="d-flex container-dash justify-content-between align-items-center h-100">
              <Link to={"/"}>
                <img
                  src={`/assets/images/navbar/logoMain.svg`}
                  alt=""
                  className="enav__top__logo"
                />
              </Link>
              <div>
                <button
                  id="dropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="enav__top__right__dropdown pointer ms-2 rounded-pill d-flex align-items-center"
                >
                  <img
                    src={`/assets/images/userAvatar.jpg`}
                    alt=""
                    className="enav__top__right__user rounded-circle"
                  />

                  <span className="px-1 text-white d-sm-block d-none">
                    {" "}
                    {localStorage.getItem("userProfileName")}
                  </span>
                  <BiChevronDown className="mt-1 text-white" />
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end enav__top__right__dropdown--ul"
                  aria-labelledby="dropdown"
                >
                  <li className="dropdown-item">
                    <span className="px-1 darkGrey d-sm-none d-block">
                      <span className="fw-semibold">Name:</span>{" "}
                      {localStorage.getItem("userProfileName")}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item darkGrey text-decoration-none fm"
                      href="javascript:"
                      onClick={logOutUser}
                    >
                      <BiLogOut /> Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="interviewer-main-section">
          {/* <div className="container-dash border-05 prof">
            <div className="d-flex justify-content-end pb-2 flex-sm-row flex-column w-100 ">
              <div className="d-flex justify-content-end mb-sm-0 mb-2">
                <div
                  className="fm darkGrey profile__cell pointer nowrap d-flex align-items-center"
                >
                  <img
                    src="/assets/images/toolbar/icon_calendar_dark.svg"
                    alt=""
                    className=""
                  />
                </div>
                <div
                  className="fm darkGrey profile__cell pointer nowrap ms-1 d-flex align-items-center"
                >
                  <img
                    src="/assets/images/toolbar/icon_inbox_dark.svg"
                    alt=""
                    className=""
                  />
                </div>
                <div
                  className="fm darkGrey profile__cell mx-1 pointer nowrap d-flex align-items-center"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <BsThreeDotsVertical />
                </div>
                <ul class="dropdown-menu py-0 dropdown-menu-end">
                  <li className="py-1 job__dropdown--li px-2">option 1</li>
                  <li className="py-1 job__dropdown--li px-2">option 2</li>
                </ul>
              </div>
              <div
                className="d-flex profile__cell__last pointer interview__drop"
              >
                <Space.Compact className="w-100">
                  <Input value={"Assigned Team"} />
                  <Select
                    defaultValue="Vacheron Constantin"
                    className="w-100"
                  >
                    {nameopt.map((option) => (
                      <Select.Option key={option.value} value={option.value} className="fm text-14">
                        <Avatar src={option.avatar} className="me-1 border" />{option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space.Compact>
              </div>
            </div>
          </div> */}
          <div className="container-dash mt-4">
            <div className="row g-2 ">
              <div className="col-lg-6">
                <div className="interviewerHeader align-items-lg-center align-items-start p-3">
                  <div className="imageBox">
                    {/* <img src="/assets/images/userAvatar.svg" /> */}
                  </div>
                  <div className="interviewerDetail fm">
                    <div className="interviewer-name">
                      <h3>{localStorage.getItem("userProfileName")}</h3>
                    </div>
                    <div className="emailBox text-start">
                      <span className="text-14 word-wrap">
                        {localStorage.getItem("userEmail")}
                      </span>
                    </div>
                    {/* <div className="departmentBox">
                    <BsFillBuildingsFill /> Department:{" "}
                    <span>{memberData && memberData.department}</span>
                  </div> */}
                    {/* <div className="headerButtons"> */}
                    {/* <button className='primary-button'>first button</button> */}
                    {/* <button className='secondary-button'>second</button> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                  <Link
                    // to={"/hr/dashboard/candidates"}
                    className="text-decoration-none h-100"
                  >
                    <div className="dashboardScreen__active__job_roles h-100 mt-0 outlined__box edashboard__box position-relative p-3">                      
                      <span>{hiredCandidates ?  + hiredCandidates.length  : " "}</span>
                      <br />
                      <span className="fm fs-6 fw-normal">Applicants</span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <Link
                    // to={"/hr/dashboard/jobs"}
                    className="text-decoration-none h-100"
                  >
                    <div className="dashboardScreen__active__job_roles h-100 mt-0 outlined__box edashboard__box position-relative p-3">
                    {/* <div className="dashboardScreen__active__job_roles mt-0 outlined__box edashboard__box position-relative p-3">
                      {dashbordloading ? (
                        <Loader />
                      ) : (
                        <span>{dashboardData && dashboardData?.totalJobs}</span>
                      )} */}
                      <span>{interviewersData && interviewersPendingData
                      ?  interviewersPendingData.length
                      : " "}</span>
                      <br />
                      <span className="fm fs-6 fw-normal">
                        Interviews
                      </span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div>
                {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <Link
                    // to={"/hr/dashboard/jobs"}
                    className="text-decoration-none h-100"
                  >
                    <div className="dashboardScreen__active__draft_roles h-100 mt-0 mb-0 outlined__box edashboard__box position-relative p-3">
                      <span>0</span>
                      <br />
                      <span className="fm fs-6 fw-normal">Messages</span>
                      <img
                        src={`/assets/images/Dashboard/angle-arrow.svg`}
                        alt=""
                        className="lightBlueBar__content__icon__image"
                      />
                    </div>
                  </Link>
                </div> */}
            </div>
          </div>

          <div className="mt-4 mb-4">
            <div className="">
              <Tabs
                className={`jobs__tabs Ejobs__tabs candidates__tabs int_tabs`}
              >
                <TabList className="border-05 react-tabs__tab-list ">
                  <Tab onClick={() => handleTabClick(0)}>
                    Applicants{" "}
                    {/* {hiredCandidates ? "(" + hiredCandidates.length + ")" : " "} */}
                  </Tab>
                  <Tab onClick={() => handleTabClick(1)}>
                    Interviews{" "}
                    {/* {interviewersData && interviewersPendingData
                      ? "(" + interviewersPendingData.length + ")"
                      : " "} */}
                  </Tab>
                  <Tab onClick={() => handleTabClick(2)}>
                    Accepted{" "}
                    {/* {interviewersData && interviewersAcceptedData
                      ? "(" + interviewersAcceptedData.length + ")"
                      : " "} */}
                  </Tab>
                  <Tab onClick={() => handleTabClick(3)}>
                    Declined{" "}
                    {/* {interviewersData && interviewersDeclineData
                      ? "(" + interviewersDeclineData.length + ")"
                      : " "} */}
                  </Tab>
                  <Tab onClick={() => handleTabClick(4)}>Feedback</Tab>
                  {/* <Tab onClick={() => handleTabClick(5)}>Messages</Tab> */}
                </TabList>
                <TabPanel className="container-dash tab-contents ">
                  <div className="pt-3">
                    <div className=" mb-3">
                      <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                        <div className="d-flex justify-content-sm-start justify-content-between">
                          <div>
                          <Dropdown
                            menu={{
                              items: sortingMenus,
                              onClick: handleSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottom"
                            arrow={{
                              pointAtCenter: true,
                            }}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingMenus.find(
                                      (menu) => menu.key === sortingOptionApplication
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                        </Dropdown>
                          </div>
                          <div className=" d-block h-100">
                            <Dropdown
                              ref={filterRef}
                              menu={{
                                items: itemsMenu,
                                onClick: handleMenuClick,
                              }}
                              onOpenChange={handleOpenChange}
                              open={open1}
                              trigger={["click"]}
                              overlayClassName={"dropdown__filter"}
                            >
                              <button
                                className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition"
                                onClick={filterDrop}
                                style={{
                                  border: filter ? "0.5px solid #5F89FF" : "",
                                  color: filter ? "#5F89FF" : "",
                                }}
                                id="filter_btn"
                                ref={filterRef}
                              >
                                <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                                Filter
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search users..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table__outer table__outer__pro d-lg-block d-none rounded-3 position-relative">
                      <Table
                        className="dash__table"
                        columns={tableColumns}
                        dataSource={formattedCandidates.slice(
                          (currentPage1 - 1) * itemsPerPage,
                          currentPage1 * itemsPerPage
                        )}
                        pagination={false}
                        scroll={{
                          x: 1200,
                        }}
                      />
                    </div>
                    {hiredCandidates && hiredCandidates.length > 10 && (
                          <div className="d-lg-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({hiredCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}

                    <div className="applicants__data bg-white">
                      <div className="applicants__data__inner d-lg-none py-2">
                        {hiredCandidates && hiredCandidates.length > 0 ? (
                          hiredCandidates.map((item) => (
                            <div
                              className={`mx-2 enterprise bg-white rounded-3 border mb-3 p-2`}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center me-xl-0 me-2">
                                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-start">
                                    <img
                                      src={`/assets/images/user.svg`}
                                      alt=""
                                      className="candidates__avatar--img img-fluid"
                                    />
                                  </div>
                                  <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
                                    {item.candidateName}{" "}
                                  </h6>
                                </div>
                                <Dropdown
                                  menu={{
                                    items,
                                  }}
                                  placement="bottomRight"
                                >
                                  <img
                                    src={`/assets/images/Dashboard/settings_more_unselected.svg`}
                                    alt=""
                                    className="img-fluid candidates__icon2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  />
                                </Dropdown>
                              </div>
                              <div className="d-flex align-items-center mb-2">
                                <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                  Applied Date:
                                </h1>
                                <span className="fm text-14">
                                  {moment(item.createdAt).format("YY/MM/DD")}
                                </span>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Role:
                                  </h1>
                                  <span className="text-14 text-center">
                                    {" "}
                                    {item.job_title}
                                  </span>
                                </div>
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Location:
                                  </h1>
                                  <span className="location fm text-14">
                                    {item.location}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xl-2 col-6 ps-xl-0 d-flex align-items-center order-3"></div>
                              <div className="d-sm-flex">
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Indexscore:
                                  </h1>
                                  <h6 className="fs-6 main__color__text mb-0">
                                    {item.indexScore}
                                  </h6>
                                </div>
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Status:
                                  </h1>
                                  <span className="text-14">
                                    {item.feedback_result
                                      ? item.feedback_result
                                          .hiring_recommendation
                                      : "Pending"}
                                  </span>
                                </div>
                              </div>

                              <div className="d-sm-flex">
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Resume:
                                  </h1>
                                  <span
                                    className={` fm tag-14 d-flex justify-content-start p-0 text-capitalize rounded-pill resume-icon-download`}
                                  >
                                    <img
                                      onClick={() => {
                                        handleDownload(
                                          item.candidateResume.Key
                                        );
                                      }}
                                      src="/assets/images/resume-icon.png"
                                    />
                                  </span>
                                </div>

                                <div className="w-sm-50 d-flex mb-2">
                                  <div className="d-flex align-items-center">
                                    <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                      Rating:
                                    </h1>
                                    {item?.feedback_result?.final_rating !==
                                    undefined
                                      ? Array(5)
                                          .fill(null)
                                          .map((_, index) =>
                                            index <
                                            item.feedback_result
                                              .final_rating ? (
                                              <AiFillStar
                                                key={index}
                                                className="stars filled-star"
                                              />
                                            ) : (
                                              <AiOutlineStar
                                                className="stars"
                                                key={index}
                                              />
                                            )
                                          )
                                      : Array(5)
                                          .fill(null)
                                          .map((_, index) => (
                                            <AiOutlineStar key={index} />
                                          ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record-message">
                            No record exists
                          </div>
                        )}
                        {hiredCandidates && hiredCandidates.length > 10 && (
                          <div className="d-lg-none d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({hiredCandidates.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="container-dash tab-contents ">
                  <div className="pt-3">
                    <div className=" mb-3">
                      <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                        <div className="d-flex justify-content-sm-start justify-content-between">
                          <div>
                          <Dropdown
                            menu={{
                              items: sortingInterviewMenus,
                              onClick: handleInterviewSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottom"
                            arrow={{
                              pointAtCenter: true,
                            }}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingInterviewMenus.find(
                                      (menu) => menu.key === sortingOptionInterview
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                        </Dropdown>
                          </div>
                          <div className=" d-block h-100">
                            <Dropdown
                              ref={filterRef}
                              menu={{
                                items: itemsInterviewsMenu,
                                onClick: handleMenuClick,
                              }}
                              onOpenChange={handleOpenChange}
                              open={open1}
                              trigger={["click"]}
                              overlayClassName={"dropdown__filter"}
                            >
                              <button
                                className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition"
                                onClick={filterDrop}
                                style={{
                                  border: filter ? "0.5px solid #5F89FF" : "",
                                  color: filter ? "#5F89FF" : "",
                                }}
                                id="filter_btn"
                                ref={filterRef}
                              >
                                <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                                Filter
                              </button>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              value={searchQueryInterviews}
                              onChange={(e) => setSearchQueryInterviews(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search users..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table__outer table__outer__pro d-lg-block d-none rounded-3 position-relative">
                      <Table
                        className="dash__table"
                        columns={interviewColumns}
                        dataSource={formattedInterviews.slice(
                          (currentPage1 - 1) * itemsPerPage,
                          currentPage1 * itemsPerPage
                        )}
                        pagination={false}
                        scroll={{
                          x: 1200,
                        }}
                      />
                    </div>
                    {interviewersPendingData && interviewersPendingData.length > 10 && (
                          <div className="d-lg-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({interviewersPendingData.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                    <div className="applicants__data bg-white ">
                      <div className="applicants__data__inner d-lg-none py-2">
                        {interviewersData &&
                        interviewersPendingData &&
                        interviewersPendingData.length > 0 ? (
                          interviewersPendingData.map((item) => (
                            <div
                              className={`mx-2 enterprise bg-white rounded-3 border p-2 mb-3`}
                              key={item.key}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center me-xl-0 me-2">
                                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-start">
                                    <img
                                      src={`/assets/images/user.svg`}
                                      alt=""
                                      className="candidates__avatar--img img-fluid"
                                    />
                                  </div>
                                  <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
                                    {item.candidateName}{" "}
                                  </h6>
                                </div>
                                <Dropdown
                                  menu={{
                                    items,
                                  }}
                                  placement="bottomRight"
                                >
                                  <img
                                    src={`/assets/images/Dashboard/settings_more_unselected.svg`}
                                    alt=""
                                    className="img-fluid candidates__icon2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  />
                                </Dropdown>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Role:
                                  </h1>
                                  <span className="text-14 text-center">
                                    {" "}
                                    {item.jobTitle}
                                  </span>
                                </div>
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Company:
                                  </h1>
                                  <span className="fm text-14">
                                    {item.companyName}
                                  </span>
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Start Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_start).format("YY/MM/DD")}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    End Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_end).format("YY/MM/DD")}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record-message fm darkGrey">
                            No record exists
                          </div>
                        )}
                        {interviewersPendingData && interviewersPendingData.length > 10 && (
                          <div className="d-lg-none d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({interviewersPendingData.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="container-dash tab-contents ">
                  <div className="pt-3">
                    <div className=" mb-3">
                      <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                        <div className="d-flex justify-content-sm-start justify-content-between">
                          <div>
                          <Dropdown
                            menu={{
                              items: sortingAcceptedInterviewMenus,
                              onClick: handleAcceptedInterviewSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottom"
                            arrow={{
                              pointAtCenter: true,
                            }}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingAcceptedInterviewMenus.find(
                                      (menu) => menu.key === acceptedSortingOptionInterview
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                        </Dropdown>
                          </div>
                          {/* <div className=" d-block h-100">
                            <Dropdown
                              ref={filterRef}
                              menu={{
                                items: itemsAccpetedInterviewsMenu,
                                onClick: handleMenuClick,
                              }}
                              onOpenChange={handleOpenChange}
                              open={open1}
                              trigger={["click"]}
                              overlayClassName={"dropdown__filter"}
                            >
                              <button
                                className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition"
                                onClick={filterDrop}
                                style={{
                                  border: filter ? "0.5px solid #5F89FF" : "",
                                  color: filter ? "#5F89FF" : "",
                                }}
                                id="filter_btn"
                                ref={filterRef}
                              >
                                <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                                Filter
                              </button>
                            </Dropdown>
                          </div> */}
                        </div>
                        <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              value={searchQueryAccepted}
                              onChange={(e) => setSearchQueryAccepted(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search users..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table__outer table__outer__pro d-lg-block d-none rounded-3 position-relative">
                      <Table
                        className="dash__table"
                        columns={interviewColumnsAccepted}
                        dataSource={formattedInterviewsAccepted.slice(
                          (currentPage1 - 1) * itemsPerPage,
                          currentPage1 * itemsPerPage
                        )}
                        pagination={false}
                        scroll={{
                          x: 1200,
                        }}
                      />
                    </div>
                    {interviewersAcceptedData && interviewersAcceptedData.length > 10 && (
                          <div className="d-lg-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({interviewersAcceptedData.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                    <div className="applicants__data bg-white">
                      <div className="applicants__data__inner d-lg-none py-2">
                        {interviewersData &&
                        interviewersAcceptedData &&
                        interviewersAcceptedData.length > 0 ? (
                          interviewersAcceptedData.map((item) => (
                            <div
                              className={`mx-2 enterprise bg-white rounded-3 border mb-3 p-2`}
                              key={item.key}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center me-xl-0 me-2">
                                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-start">
                                    <img
                                      src={`/assets/images/user.svg`}
                                      alt=""
                                      className="candidates__avatar--img img-fluid"
                                    />
                                  </div>
                                  <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
                                    {item.candidateName}{" "}
                                  </h6>
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Role:
                                  </h1>
                                  <span className="text-14 text-center">
                                    {" "}
                                    {item.jobTitle}
                                  </span>
                                </div>
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Company:
                                  </h1>
                                  <span className="fm text-14">
                                    {item.companyName}
                                  </span>
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Start Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_start).format("YY/MM/DD")}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    End Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_end).format("YY/MM/DD")}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex align-items-center">
                                <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                  Meeting:
                                </h1>
                                {item.zoomLink ? (
                                  <Link to={item.zoomLink} target="_blank">
                                    <img
                                      className="zoom-icon"
                                      src="/assets/images/icon_zoom_positive.svg"
                                    />
                                  </Link>
                                ) : (
                                  <div>
                                    <img
                                      className="zoom-icon"
                                      src="/assets/images/icon_zoom_positive.svg"
                                      alt="Zoom Icon"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record-message fm darkGrey">
                            No record exists
                          </div>
                        )}
                        {interviewersAcceptedData && interviewersAcceptedData.length > 10 && (
                          <div className="d-lg-none d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({interviewersAcceptedData.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="container-dash tab-contents ">
                  <div className="pt-3">
                    <div className=" mb-3">
                      <div className="d-flex flex-md-row flex-column-reverse align-items-md-center justify-content-md-between mb-3">
                        <div className="d-flex justify-content-sm-start justify-content-between">
                          <div>
                          <Dropdown
                            menu={{
                              items: sortingAcceptedInterviewMenus,
                              onClick: handleDeclinedInterviewSortingChange,
                            }}
                            className="sorting__menus jobd__btn me-2"
                            placement="bottom"
                            arrow={{
                              pointAtCenter: true,
                            }}
                          >
                            <Button>
                              <>
                                <span className="fm">Sort By: </span>
                                <strong className="fm">
                                  {
                                    sortingDeclinedInterviewMenus.find(
                                      (menu) => menu.key === declinedSortingOptionInterview
                                    )?.label
                                  }{" "}
                                  <IoIosArrowDown />
                                </strong>
                              </>
                            </Button>
                        </Dropdown>
                          </div>
                          {/* <div className=" d-block h-100">
                            <Dropdown
                              ref={filterRef}
                              menu={{
                                items: itemsDeclinedInterviewsMenu,
                                onClick: handleMenuClick,
                              }}
                              onOpenChange={handleOpenChange}
                              open={open1}
                              trigger={["click"]}
                              overlayClassName={"dropdown__filter"}
                            >
                              <button
                                className="candidates__filter jobd__btn fm px-2 d-flex text-14 align-items-center fw-500 transition"
                                onClick={filterDrop}
                                style={{
                                  border: filter ? "0.5px solid #5F89FF" : "",
                                  color: filter ? "#5F89FF" : "",
                                }}
                                id="filter_btn"
                                ref={filterRef}
                              >
                                <BsFilter className="fs-4 candidates__filter__icon me-1" />{" "}
                                Filter
                              </button>
                            </Dropdown>
                          </div> */}
                        </div>
                        <div className="d-sm-flex justify-content-end mb-md-0 mb-2 ">
                          <div className="candidates__searchbox jobd__btn d-flex align-items-center me-2 mb-sm-0 mb-2">
                            <BsSearch className="candidates__searchbox__icon mx-2" />
                            <input
                              value={searchQueryDeclined}
                              onChange={(e) => setSearchQueryDeclined(e.target.value)}
                              type="text"
                              className="fm candidates__searchbox__input text-14"
                              placeholder="Search users..."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table__outer table__outer__pro d-lg-block d-none rounded-3 position-relative">
                      <Table
                        className="dash__table"
                        columns={interviewColumnsDeclined}
                        dataSource={formattedInterviewsDeclined.slice(
                          (currentPage1 - 1) * itemsPerPage,
                          currentPage1 * itemsPerPage
                        )}
                        pagination={false}
                        scroll={{
                          x: 1200,
                        }}
                      />
                    </div>
                    {filteredCandidatesDecline && filteredCandidatesDecline.length > 10 && (
                          <div className="d-lg-flex d-none justify-content-center pt-4 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({filteredCandidatesDecline.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                    <div className="applicants__data bg-white">
                      <div className="applicants__data__inner d-lg-none py-2">
                        {interviewersData &&
                        filteredCandidatesDecline &&
                        filteredCandidatesDecline.length > 0 ? (
                          filteredCandidatesDecline.map((item) => (
                            <div
                              className={`mx-2 enterprise bg-white rounded-3 border mb-3 p-2`}
                              key={item.key}
                            >
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center me-xl-0 me-2">
                                  <div className="rounded-circle candidates__avatar d-flex justify-content-center align-items-start">
                                    <img
                                      src={`/assets/images/user.svg`}
                                      alt=""
                                      className="candidates__avatar--img img-fluid"
                                    />
                                  </div>
                                  <h6 className=" fm mb-0 ms-2 mb-0 me-1 text-14">
                                    {item.candidateName}{" "}
                                  </h6>
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Role:
                                  </h1>
                                  <span className="text-14 text-center">
                                    {" "}
                                    {item.jobTitle}
                                  </span>
                                </div>
                                <div className="w-sm-50 d-flex align-items-center mb-2">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Company:
                                  </h1>
                                  <span className="fm text-14">
                                    {item.companyName}
                                  </span>
                                </div>
                              </div>
                              <div className="d-sm-flex">
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    Start Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_start).format("YY/MM/DD")}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center mb-2 w-sm-50">
                                  <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                    End Date:
                                  </h1>
                                  <span className="fm text-14">
                                    {moment(item.date_end).format("YY/MM/DD")}
                                  </span>
                                </div>
                              </div>

                              <div className="d-flex align-items-center">
                                <h1 className=" fw-semibold text-14 my-0 d-xl-none me-1">
                                  Meeting:
                                </h1>
                                {item.zoomLink ? (
                                  <Link to={item.zoomLink} target="_blank">
                                    <img
                                      className="zoom-icon"
                                      src="/assets/images/icon_zoom_positive.svg"
                                    />
                                  </Link>
                                ) : (
                                  <div>
                                    <img
                                      className="zoom-icon"
                                      src="/assets/images/icon_zoom_positive.svg"
                                      alt="Zoom Icon"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record-message">
                            No record exists
                          </div>
                        )}
                        {filteredCandidatesDecline && filteredCandidatesDecline.length > 10 && (
                          <div className="d-lg-none d-flex justify-content-center pt-3 gap-2">
                            <button className="fm text-12 table__bottom__btn1">
                              Load all candidates ({filteredCandidatesDecline.length})
                            </button>
                            <button className="fm text-12 table__bottom__btn2">
                              Scroll to load more{" "}
                              <HiOutlineArrowDownCircle className="fs-6" />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="container-dash tab-contents General">
                  <div className="row pt-3">
                    <div className="col-sm-10 col-12 fm">
                      <h3 className="fm darkGrey">Feedback Form</h3>
                    </div>
                    <div className="col-sm-10 col-12 mb-2">
                      <p className="fm  mt-4 mb-0 pb-2 darkGrey">
                        Select Candidate
                      </p>
                      <Select
                        className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                        size="small"
                        placeholder="Select Candidate"
                        value={candidateFeedback.candidateID} // Set the value of the selected option
                        onChange={(value, option) => {
                          const selectedName = option.children;
                          const selectedId = value;
                          const selectedUser = applicantsForInterview.find(
                            (item) => item.candidateID === selectedId
                          );
                          const selectedUserApplicantID = selectedUser
                            ? selectedUser._id
                            : null;
                          const selectedApplicantManagerID = selectedUser
                            ? selectedUser.managerID
                            : null;
                          setCandidateFeedback({
                            id: selectedId,
                            name: selectedName,
                            applicantID: selectedUserApplicantID,
                            managerID: selectedApplicantManagerID,
                          });
                        }}
                      >
                        {hiredCandidates
                          .filter(
                            (item) =>
                              item.status === "interviewed" &&
                              !item.interviewers_feedback.some(
                                (feedback) =>
                                  feedback.interviewerID ===
                                  currentInterviewerId
                              )
                          )
                          .map((item, index) => (
                            <Option
                              key={item.candidateID}
                              value={item.candidateID}
                            >
                              {item.candidateName}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-sm-10 col-12 pb-3">
                      <p className="fm darkGrey  mt-4 mb-0 pb-2">
                        Would you recommend{" "}
                        <strong>{candidateFeedback.name}</strong> to be part of
                        our organization?
                      </p>
                      <Select
                        className="Login--Input feedback--select mb-2 mx-0 d-block w-100"
                        size="small"
                        placeholder="Yes/No"
                        value={recommend}
                        onChange={(value) => {
                          setRecommend(value);
                          setSelectedFeedback(null);
                        }}
                      >
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                      </Select>
                    </div>
                    {recommend && recommend === "yes" ? (
                      <div className="col-sm-10 col-12 mb-2">
                        <p className="darkGrey fm mb-0 fw-semibold  mt-4 mb-0 pb-2">
                          If yes, what won you over?
                        </p>

                        <div className="row">
                          <div className="col-md-12">
                            <label
                              htmlFor="check1"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                              onChange={handleRadioChange}
                            >
                              <Radio
                                value="Great communicator"
                                id="check1"
                                checked={
                                  selectedFeedback === "Great communicator"
                                }
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Great communicator
                                </small>
                              </div>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="check2"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                              onChange={handleRadioChange}
                            >
                              <Radio
                                id="check2"
                                value="Great Listener"
                                checked={selectedFeedback === "Great Listener"}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Great Listener
                                </small>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label
                              htmlFor="check3"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                              onChange={handleRadioChange}
                            >
                              <Radio
                                id="check3"
                                value="Experience"
                                checked={selectedFeedback === "Experience"}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Experience
                                </small>
                              </div>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="check4"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                              onChange={handleRadioChange}
                            >
                              <Radio
                                id="check4"
                                value="Authentic"
                                checked={selectedFeedback === "Authentic"}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Authentic
                                </small>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label
                              htmlFor="check5"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                              onChange={handleRadioChange}
                            >
                              <Radio
                                id="check5"
                                value="Over Background"
                                checked={selectedFeedback === "Over Background"}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Over Background
                                </small>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : recommend && recommend === "no" ? (
                      <div className="col-sm-10 col-12 mb-2">
                        <p className="darkGrey fm mb-0 fw-semibold  mt-4 mb-0 pb-2">
                          If no, no worries - tell us more
                        </p>
                        <div className="row">
                          <div className="col-md-12">
                            <label
                              htmlFor="check6"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                            >
                              <Radio
                                value="Felt short on experience"
                                id="check6"
                                checked={
                                  selectedFeedback ===
                                  "Felt short on experience"
                                }
                                onChange={handleRadioChange}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Felt short on experience
                                </small>
                              </div>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="check7"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                            >
                              <Radio
                                id="check7"
                                value="I couldnt get a word in"
                                checked={
                                  selectedFeedback === "I couldnt get a word in"
                                }
                                onChange={handleRadioChange}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  I couldn't get a word in
                                </small>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label
                              htmlFor="check8"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                            >
                              <Radio
                                id="check8"
                                value="Style might be tough to be successful in our culture"
                                checked={
                                  selectedFeedback ===
                                  "Style might be tough to be successful in our culture"
                                }
                                onChange={handleRadioChange}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  Style might be tough to be successful in our
                                  culture
                                </small>
                              </div>
                            </label>
                          </div>

                          <div className="col-md-12">
                            <label
                              htmlFor="check9"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                            >
                              <Radio
                                id="check9"
                                value="I didnt feel the authenticity zero eye contact"
                                checked={
                                  selectedFeedback ===
                                  "I didnt feel the authenticity zero eye contact"
                                }
                                onChange={handleRadioChange}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  I didn't feel the authenticity; zero eye
                                  contact
                                </small>
                              </div>
                            </label>
                          </div>
                          <div className="col-md-12">
                            <label
                              htmlFor="check10"
                              className=" px-2 py-1  d-flex  align-items-center pointer"
                            >
                              <Radio
                                id="check10"
                                value="We need somebody who can hit the ground running"
                                checked={
                                  selectedFeedback ===
                                  "We need somebody who can hit the ground running"
                                }
                                onChange={handleRadioChange}
                              ></Radio>
                              <div>
                                <small className="fm darkGrey fw-semibold">
                                  We need somebody who can hit the ground
                                  running
                                </small>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-md-12 mb-2">
                      <div className="star-rating-container my-3">
                        <p className="my-0 fm darkGrey">
                          How do you rate your experience with candidate?
                        </p>
                        <div className="star-rating">
                          {[1, 2, 3, 4, 5].map((value) => (
                            <span
                              key={value}
                              className={`star ${
                                value <= rating ? "selected" : ""
                              }`}
                              onClick={() => handleStarClick(value)}
                            >
                              ★
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-10 col-12">
                      <textarea
                        type="text"
                        className="form-control-lg form-control mb-3 inter__message"
                        value={recommendedFeedback}
                        onChange={(value) =>
                          setRecommendedFeedback(value.target.value)
                        }
                        placeholder="Your Feedback..."
                        rows={3}
                      />
                    </div>

                    {/* {recommend != null && selectedFeedback != null && candidateFeedback != null ? */}

                    <div className="col-sm-10 col-12">
                      <button
                        disabled={!hasChanges}
                        onClick={() => handleSubmitFeedback()}
                        className="fm mt-4 text-18 text-white bluegradient__btn px-3 py-2 d-inline-flex align-items-center"
                      >
                        {feedbackLoading ? <Loader /> : "Submit Feedback"}
                      </button>
                    </div>
                    {/* : ''} */}
                  </div>
                </TabPanel>
                {/* <TabPanel className="tab-contents interviewportal">
                  <div className="d-md-block d-none container-dash ">
                  <InterviewerChat />
                  </div>
                  <div className="d-md-none d-block">
                    <InterviewMobileChat />
                  </div>
                </TabPanel> */}
              </Tabs>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default InterviewPortal;
