import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";

const CircularProgressBar = ({ segments, maxValue }) => {
  const data = {
    labels: segments.map((segment) => segment.label),
    datasets: [
      {
        data: segments.map((segment) => segment.value),
        backgroundColor: segments.map((segment) => segment.color),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "85%",
    plugins: {
      legend: {
        align: "start",
        position: "bottom",
        display: false
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const value = data.datasets[0].data[tooltipItem.index];
          return `${value}%: ${((value * maxValue) / 100).toFixed(0)}`;
        },
      },
    },

  };

  return (
    <div className="chart-container">
      <Doughnut data={data} options={options} />
    </div>
  );
};

CircularProgressBar.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  maxValue: PropTypes.number.isRequired,
};

export default CircularProgressBar;
