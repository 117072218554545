import React from "react";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import ProgressBar from "../components/ProgressBar/ProgressBar.jsx";
import Container from "../components/SignupContainer/Container.jsx";
import Online from "../pageComponents/Enterprise/OnlinePresence/Online.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";

const ProfessionalOnlinePresence = () => {
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar page="professional-signup" />
      <MobileSidebar />
      <Container
        progress={<ProgressBar page="4" title="Candidate" />}
        right={<Online para="Employers check your online activities as part of their hiring process; we assess your online activities to help you identify areas of improvement opportunities."
        color="blue"
        page="professional" />}
      />
      <Footer />
    </>
  );
};

export default ProfessionalOnlinePresence;
