import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const UESection3 = () => {
  return (
    <div className="ues5 main-padding">
      <div className="container-xl px-0 mt-5 py-5 d-flex flex-column align-items-center">
        <img
          src="/assets/UpdatedFrontend/Enterprise/enterprisedemo.svg"
          alt=""
        />
        <h1 className="fm darkBlue fs-2 mt-5 text-center">
          Taking Chief HR Officers to new heights of strategy
        </h1>
        <p className="fm darkGrey text-center mt-3 pb-4">
          With human bias, expensive recruitment agencies, and month-long time
          lags removed from your hiring picture: you can start making the most
          efficient & informed decisions on who to bring into your organization
          for the long term.
        </p>
        <Link to={"/book-a-demo"} className="text-decoration-none">
          <button className="btn--main darkGrey fm fw-semibold rounded-2 py-2">
            Book a demo
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UESection3;
