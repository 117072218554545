import React, { useState } from "react";
import { Select } from "antd";
import { languages } from "../../../../Utilities/Languages";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";

const General = () => {
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedCurrency, setSelectedCurrency] = useState("usd");

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  function getBaseUrl() {
    const { protocol, hostname, port } = window.location;
    const baseUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    return baseUrl;
  }

  const baseUrl = getBaseUrl();

  return (
    <div className="General enterprise__general selectboxes pt-lg-4 pb-5">
      <div className="Dashboard__container pe-2">
        <div className="Dashboard__container__content">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-sm-4 h-100 pe-md-2 pe-0">
                <p className="darkBlue fm mb-0 fw-semibold fs-6">Language</p>
                <p className="fm darkGrey mb-2 text-14 mt-0">
                  Default language
                </p>
                <Select
                  value={selectedCountry}
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="English (UK)"
                  defaultValue={
                    languages.find((item) => item.country === "United States")
                      ?.country
                  }
                >
                  {languages.map((item) => (
                    <Select.Option value={item.country}>
                      {item.language}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="col-md-9 pe-md-2 pe-0">
                <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                  Currency
                </p>
                <p className="fm darkGrey mb-2 text-14 mt-0">
                  United States Dollars is the default currency when creating
                  new jobs. You can change to other currencies during your job
                  creation process.
                </p>
              </div>
              <div className="col-sm-4 h-100 pe-md-2 pe-0">
                <Select
                  value={selectedCurrency}
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="USD ($)"
                >
                  <Select.Option value="usd">USD</Select.Option>
                  <Select.Option value="gbp">GBP</Select.Option>
                  <Select.Option value="eur">EUR</Select.Option>
                  <Select.Option value="aud">AUD</Select.Option>
                  <Select.Option value="cad">CAD</Select.Option>
                </Select>
              </div>
              <div className="col-md-9 pe-md-2 pe-0">
                <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                  Privacy
                </p>
                <p className="fm darkGrey mb-2 mt-0 text-14">
                  Privacy and the protection of our members’ data is the heart
                  of what we do. We are GDPR compliant and leverage multiple
                  layers of security architecture to ensure proactive risk
                  mitigation.
                </p>
                <Link
                  to={"/privacy-policy"}
                  className="lighBlue2 fm text-decoration-none text-14"
                >
                  View our Privacy Policy here{" "}
                  <BsArrowRightShort className="fs-3" />
                </Link> <br />
                <Link
                  to={"/terms-of-use"}
                  className="lighBlue2 fm text-decoration-none text-14"
                >
                  View our Terms and Conditions page here{" "}
                  <BsArrowRightShort className="fs-3" />
                </Link>
              </div>
              <div className="col-md-9 pe-md-2 pe-0">
                <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">About</p>
                <p className="fm darkGrey mb-2 text-14 mt-0">
                  Want to know about the IndexScore journey? Please visit us at{" "}
                  <Link
                    className="lighBlue2 fm text-decoration-none"
                    to={"/about-us"}
                  >
                    {`${baseUrl}/about-us`}
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
