import React, { useState, useContext, useEffect } from "react";
import "./style.css";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { genderList } from "../../../Utilities/Genders.js";
import { EthnicitiesList } from "../../../Utilities/Ethnicities.js";
import { degreesList } from "../../../Utilities/Degrees.js";
import { isEmail, isLinkedIn } from '../../../Utilities/utilities'
import { useSnackbar } from 'notistack'
import { Select, DatePicker } from "antd";
import { MyContext } from '../../../Utilities/MyContextProvider';
import { getProfessionalById, updateProfileStatus } from "../../../services/auth";
import moment from "moment";

const Signup = () => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  // const { contextData, setContextData } = useContext(DataContext);
  const myContext = useContext(MyContext);
  const { Option, OptGroup } = Select;
  const [selectedDegrees, setSelectedDegrees] = useState([]);



  const myData = myContext;

  const { id } = useParams();

  useEffect(() => {
    fetchProId();
  }, []);


  const fetchProId = async () => {
    try {

      var payloadGetPro = {
        _id: id
      };
      // console.log("response from payloadGetPro api----" + JSON.stringify(payloadGetPro));
      const response = await getProfessionalById(payloadGetPro);
      // console.log("response from payloadGetPro api" + JSON.stringify(response.data.data[0]));
      // setProfessional(response.data.data[0]);



      if (response && response.data.data[0]) {
        // update profile status       
        myContext.updateContextData(response.data.data[0]);
        var payloadProfessional = {
          _id: id,
          usertype: 'professional'
        };
        const responseUpdate = await updateProfileStatus(payloadProfessional);

        if (responseUpdate.data.statusCode == 1) {
          // enqueueSnackbar(`Verified`, {
          //   variant: 'success',
          //   anchorOrigin: {
          //     vertical: 'bottom',
          //     horizontal: 'right'
          //   }
          // })
        }



        //


      }

    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };






  const [state, setState] = useState({
    firstName: myContext.contextData.firstName,
    lastName: myContext.contextData.lastName,
    emailAddress: myContext.contextData.emailAddress,
    referenceSource: '',
    dob: '',
    gender: '',
    ethnicities:'',
    education:'',
    phoneNumber: myContext.contextData.phoneNumber,
    linkedinPublicUrl: myContext.contextData.linkedinPublicUrl,
    // password: '',
    // confirmPassword: '',
    loading: false,
    // invalidConfirmPassword: false,
    invalidEmail: false,
    // invalidPassword: false,
    invalidPhone: false,
    files: null,
    required: false,

  })
  const {
    loading,
    required,
    firstName,
    lastName,
    emailAddress,
    dob,
    gender,
    ethnicities,
    education,
    referenceSource,
    phoneNumber,
    linkedinPublicUrl,
    // password,
    // confirmPassword,
    // invalidConfirmPassword,
    invalidEmail,
    // invalidPassword,
    invalidPhone,
    files,
  } = state



  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
    // DataContext.set

  }
  const disabled =
    !dob ||
    !gender ||
    !education ||
    !ethnicities;
  // !firstName ||
  // !lastName ||
  // !emailAddress ||
  // !phoneNumber ||
  // !linkedinPublicUrl ||
  // isLinkedIn(linkedinPublicUrl)
  // !password ||
  // !confirmPassword ||
  // !files ||
  // (usertype !== 'client' && !files) ||
  // password !== confirmPassword


  const handleDob = (date, dateString) => {
    handleChange("dob", moment(dateString).format("YYYY-MM-DD"))
    console.log("start date" + moment(dateString).format("YYYY-MM-DD"));
  };



  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return maxDate.toISOString().split('T')[0];
  }

  const getMinDate = () => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 32, today.getMonth(), today.getDate()); // Set to 16 years before the current date
    return minDate.toISOString().split('T')[0];
  }


  const handleSignup = async () => {
    // alert();
    try {
      if (disabled) {

        handleChange('required', true)
        return
      }

      // history.push('/signup/email-verification')
      handleChange('required', false)
      handleChange('loading', true)
      // const usertype = localStorage.getItem('type')
      const payload = {
        firstName: myContext.contextData.firstName,
        lastName: myContext.contextData.lastName,
        emailAddress: myContext.contextData.emailAddress,
        age: dob,
        gender: gender,
        ethnicity: ethnicities,
        education:education,
        // referenceSource,
        phoneNumber: myContext.contextData.phoneNumber,
        linkedinPublicUrl: myContext.contextData.linkedinPublicUrl,

        // password,        
      }
      payload.phoneNumber = '+' + payload.phoneNumber;
      console.log("payload of pro signup",payload);

      myContext.updateContextData(payload);
      // debugger
      // const res = await professionalSignup(payload)
      // console.log('res', res)

      // setContextData({ ...contextData, ...state });

      
      // console.log("setContextData" + state);

      // if (res.data.statusCode === 200) {

      //   enqueueSnackbar(`Signup Successfully!`, {
      //     variant: 'success',
      //     anchorOrigin: {
      //       vertical: 'bottom',
      //       horizontal: 'right'
      //     }
      //   })
      //   const body = {
      //     files,
      //     userID: res.data.result._id,
      //     userfiletype: 'expertresume'
      //   }

      //   console.log('res.data.result', res.data.result)
      //   localStorage.setItem('userData', JSON.stringify(res.data.result))
      //   localStorage.setItem('_id', res.data.result._id)
      //   localStorage.setItem('email', emailAddress);

      navigate(`/professional/signup/extra-info`);



      // } else {
      //   handleChange('loading', false)
      //   console.log('ressss', res)
      //   enqueueSnackbar(`Error: ${res.data.name}`, {
      //     variant: 'error',
      //     anchorOrigin: {
      //       vertical: 'bottom',
      //       horizontal: 'right'
      //     }
      //   })
      // }
    } catch (error) {
      handleChange('loading', false)
      console.log('errssssssss', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }
  }


  return (
    <div className="Signup main-padding">
      <style>
        {`
        .select-signup .ant-select-arrow{
          display:block !important;
        }
        .ant-select-selector::before {
          background: #fff;
        }
      `}
      </style>
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-2">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading mt-lg-5 mb-lg-5 mb-3">
            Welcome back!
          </h1>
          <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
            Please provide your information and confirm information submitted:
          </p>

          <div className="row px-lg-2">
            <div className="col-6 ps-lg-1 pe-1">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="First name"
                // value={myData.firstName}
                value={myContext.contextData.firstName || ''}
                onChange={value =>
                  handleChange('firstName', value.target.value)
                }
              />
              {/* {required && !firstName && (
                <div>
                  <small className="error__message"> First Name is required!</small>
                </div>
              )} */}
            </div>
            <div className="col-6 pe-lg-1 ps-2">
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Last name"
                value={myContext.contextData.lastName || ''}
                onChange={value =>
                  handleChange('lastName', value.target.value)
                }
              />
              {/* {required && !lastName && (
                <div>
                  <small className="error__message"> Last Name is required!</small>
                </div>
              )} */}
            </div>
          </div>
          <input
            type="email"
            className="form-control form-control-lg mt-3"
            readOnly
            placeholder="Email"
            value={myContext.contextData.emailAddress || ''}
            onChange={value =>
              handleChange('emailAddress', value.target.value)
            }
          />
          {/* {emailAddress && isEmail(emailAddress) && (
            <div>
              <small className="error__message">
                {' '}
                Email must be a valid email address !
              </small>
            </div>
          )}
          {required && !emailAddress && (
            <div>
              <small className="error__message">Email Address is required !</small>
            </div>
          )} */}
          <PhoneInput
            country={'us'}
            placeholder="Phone number"
            inputStyle={{ width: '100%' }}
            // value={phoneNumber}
            value={myContext.contextData.phoneNumber || phoneNumber}
            className="form-control form-control-lg mt-3"
            onChange={value => handleChange('phoneNumber', value)}
          />
          {/* <input type="number" placeholder="Phone Number" /> */}
          {/* {invalidPhone && (
            <div>
              <small className="error__message">Phone Number is not valid !</small>
            </div>
          )}
          {required && !phoneNumber && (
            <div>
              <small className="error__message">Phone Number is required !</small>
            </div>
          )} */}
          <input
            type="url"
            className="form-control form-control-lg mt-3"
            placeholder="Linkedin public profile link"
            value={myContext.contextData.linkedinPublicUrl || ''}
            onChange={value =>
              handleChange(
                'linkedinPublicUrl',
                value.target.value
              )
            }
          />
          <input
            type="date"
            className="form-control form-control-md w-100 Dashboard__input mt-3"
            placeholder="Date of birth"
            title="Select date of birth"
            value={dob || ''} // Use an empty string as a placeholder
            onChange={(value) => {
              console.log("dob", value.target.value);
              handleChange("dob", value.target.value);
            }}
            max={getMaxDate()}
            min={getMinDate()}
          />
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="Login--Input Login--select2 mt-3 select-signup mb-2 mx-0 d-block w-100 "
            size="small"
            placeholder="Select gender"
            onChange={(value) => {
              // alert(value)
              // handleChange('govtIdCountryName', value);
              handleChange("gender", value);
            }}
          >
            {genderList.map((item) => (
              <Option
                value={item.name}
                label={item.name.toLowerCase()}
                key={item.name}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="Login--Input Login--select2 mt-3 select-signup mb-2 mx-0 d-block w-100"
            size="small"
            placeholder="Select Ethnicities"
            onChange={(value) => {
              // alert(value)
              // handleChange('govtIdCountryName', value);
              handleChange("ethnicities", value);
            }}
          >
            {EthnicitiesList.map((item) => (
              <Option
                value={item.name}
                label={item.name.toLowerCase()}
                key={item.name}
              >
                {item.name}
              </Option>
            ))}
          </Select>

          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            className="Login--Input Login--select2 mt-3 select-signup mb-2 mx-0 d-block w-100"
            size="small"
            placeholder="Select Education"
            onChange={(value) => {
              // alert(value)
              // handleChange('govtIdCountryName', value);
              handleChange("education", value);
            }}
          >
            {degreesList.map((item) => (
              <Option
                value={item.name}
                label={item.name.toLowerCase()}
                key={item.name}
              >
                {item.name}
              </Option>
            ))}
          </Select>
          {/* {linkedinPublicUrl && isLinkedIn(linkedinPublicUrl) && (
            <div>
              <small className="error__message"> Use a valid linkedin profile!</small>
            </div>
          )}
          {required && !linkedinPublicUrl && (
            <div>
              <small className="error__message">Linkedin public profile is required!</small>
            </div>
          )} */}
          {/* <input
            type="password"
            className="form-control form-control-lg mt-3"
            onChange={value =>
              handleChange('password', value.target.value)
            }
            placeholder='Password'
          />
          {password && isPassword(password) && (
            <div>

              <small className="error__message">
                Must be at least 8 characters long and include
                upper and lowercase letters and at least one
                number
              </small>
            </div>
          )}
          {required && !password && (
            <div>
              <small className="error__message">Password is required!</small>
            </div>
          )}
          <PasswordStrengthBar
            style={{ marginTop: 10 }}
            password={password}
          />
          <input
            type="password"
            className="form-control form-control-lg mt-3"
            placeholder="Confirm password"           
            onChange={value =>
              handleChange('confirmPassword', value.target.value)
            }
          />
          {confirmPassword && password !== confirmPassword && (
            <div>
              <small className="error__message">Passwords must match!</small>
            </div>
          )}
          {confirmPassword && isPassword(confirmPassword) && (
            <div>
              <small className="error__message">
                Must be at least 8 characters long and include
                upper and lowercase letters and at least one
                number !
              </small>
            </div>
          )}
          {required && !confirmPassword && (
            <div>
              <small className="error__message">Confirm password is required!</small>
            </div>
          )} */}
          {/* <Upload
            name="logo"
            action="/upload.do"
            listType="document"
            maxCount={1}
            onChange={value => handleChange('files', value)}
            className="px-0"
          >
            <Button className="Get__right__upload mt-4 mb-2 Get__left__bottom__info__content__para">
              Upload your resume / CV
            </Button>
            <small className="Get__right__upload__small ps-sm-3">
              File formats: Word or PDF
            </small>
          </Upload>
          {required && !files && (
            <div>
              <small className="error__message">Resume is required!</small>
            </div>
          )} */}
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--blue mt-5`}
          >
            Continue
          </button>

        </div>
      </div>
    </div>
  );
};

export default Signup;
