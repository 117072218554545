import React, { useState, useContext } from "react";
import 'react-calendar/dist/Calendar.css';
import { useSnackbar } from 'notistack'
import { createAppointment, composeEmail } from "../../../services/auth";
import { MyContext } from '../../../Utilities/MyContextProvider';
import { Loader } from 'rsuite'
import 'rsuite/dist/rsuite.min.css'
import moment from "moment";

const MeetingDate = (props) => {
  const [value, onChange] = useState(new Date());
  const { enqueueSnackbar } = useSnackbar()

  const myContext = useContext(MyContext);
  const myData = myContext;
 
  const [state, setState] = useState({
    title: '',
    startDate: '',
    endDate: '',
    location: '',
    required: false,

  })
  const {
    loading,
    required,
    title,
    startDate,
    endDate,
    location,

  } = state
  const disabled =
    !title ||
    !startDate ||
    !endDate ||
    !location;

  const handleSubmit = async () => {
    try {
      if (disabled) {

        handleChange('required', true)
        return
      }
      handleChange('required', false)
      handleChange('loading', true)
      const payload = {
        title: title,
        startDate: startDate,
        endDate: endDate,
        createdBy: localStorage.getItem('enterpriseID'),
        receivedBy: myData.contextData[0]._id,
        location: location,

      }
      let res;

      res = await createAppointment(payload);

      if (res.data.statusCode === 1) {

        const payload = {
          subject: title,
          senderID: localStorage.getItem('userEmail'),
          receiverIDs: myData.contextData[0].emailAddress,
          body: title + ' ' + moment(new Date()).format("YYYY-MM-DD hh:mm:ss a") + ' ' + moment(new Date()).format("YYYY-MM-DD hh:mm:ss a")
        }
        let resp;
        resp = await composeEmail(payload)

        if (resp.data.statusCode === 1) {
          handleChange('loading', false)
          enqueueSnackbar(`Appointment Submitted Successfully`, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          })


          Profile();
        }
      }


    } catch (error) {
      handleChange('loading', false)
      console.log('errssssssss', error)
      enqueueSnackbar(`Error: ${error.message}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }
  }



  const handleChange = (key, value) => {
    // console.log("key" + key + ' ->' + value);
    setState(pre => ({ ...pre, [key]: value }))
  }

  const Profile = () => {
    props.confirm(false);
    props.profile(true)
    props.meeting(false)
    props.time(false)
    props.detail(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  const Time = () => {
    props.confirm(false);
    props.profile(false)
    props.meeting(false)
    props.time(true)
    props.detail(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className="Dashboard__container edash">
      <div className=" container-lg pb-4 pt-5 meeting__container d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column ">
          {/* <span className="meeting__container__step fm">
            Step <span className="fw-semibold">1/3</span>
          </span> */}
          <h5 className="fm darkGrey fw-light mt-2 fs-5 pb-3">Select your preferred <span className="fw-semibold">method & date for meeting</span></h5>
          <div className="col-12 mb-3">
            <label
              htmlFor="sector1"
              className="darkGrey fm mb-2 fw-semibold fs-6"
            >
              Title
            </label>
            <input
              type="text"
              className="form-control form-control-md w-100 Dashboard__input mb-1"
              placeholder=" title"
              id="sector1"
              onChange={value =>
                handleChange('title', value.target.value)
              }
            />
            {required && !title && (
              <div>
                <small className="error__message fm ps-1"> Title is required!</small>
              </div>
            )}
          </div>
          <div className="col-12 mb-3">
            <div className="row">
              <div class="col-md-6">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Start Date
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-control-md w-100 Dashboard__input mb-1"
                  placeholder="Start date"
                  id="sector1"
                  onChange={value =>
                    handleChange('startDate', value.target.value)
                  }
                />
                {required && !startDate && (
                  <div>
                    <small className="error__message fm ps-1"> Start Date is required!</small>
                  </div>
                )}
              </div>
              <div class="col-md-6">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  End Date
                </label>
                <input
                  type="datetime-local"
                  className="form-control form-control-md w-100 Dashboard__input mb-1"
                  placeholder="End date"
                  id="sector1"
                  onChange={value =>
                    handleChange('endDate', value.target.value)
                  }
                />
                {required && !endDate && (
                  <div>
                    <small className="error__message fm ps-1"> End Date is required!</small>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <label
              htmlFor="sector1"
              className="darkGrey fm mb-2 fw-semibold fs-6"
            >
              Location
            </label>
            <input
              type="text"
              className="form-control form-control-md w-100 Dashboard__input mb-1"
              placeholder="Location"
              id="sector1"
              onChange={value =>
                handleChange('location', value.target.value)
              }
            />
            {required && !location && (
              <div>
                <small className="error__message fm ps-1"> Location is required!</small>
              </div>
            )}
          </div>
          {/* <div className="col-12 mb-3">
            <label
              htmlFor="sector1"
              className="darkGrey fm mb-2 fw-semibold fs-6"
            >
              Notes
            </label>
            <textarea
              className="form-control form-control-lg Dashboard__input mb-4 w-100"
              placeholder="Notes"
              onChange={(value) => {
                // alert(value)
                handleChange('company_bio', value.target.value);
                console.log('company_bio', value.target.value);
              }}
              id="text"
              rows={6}
            />
          </div> */}

          <div className="d-flex flex-wrap w-100 justify-content-center pt-sm-5">
            <button
              className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2 border-darkGrey`}
              onClick={Profile}
            >
              Cancel
            </button>

            <button
              className={`Get__left__content__para Get__right__button Get__right__button--main mb-3`}
              // onClick={Confirm}
              onClick={() => handleSubmit()}>

              {loading ? <Loader /> : 'Schedule Meeting'}
            </button>

            {/* <button
            className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
            onClick={Time}>
            Next
          </button> */}
          </div>

          {/* <div className="d-flex meetingDate">
            <ul className="list-unstyled me-2 meetingDate--ul">
              <li className="mb-1"><Link to={""}><img src="/assets/images/icon_zoom_positive.svg" alt="" className="img-fluid meetingDate--img me-1" /></Link></li>
              <li className="mb-1"><Link to={""}><img src="/assets/images/icon_teams_positive.svg" alt="" className="img-fluid meetingDate--img me-1" /></Link></li>
              <li className="mb-1"><Link to={""}><img src="/assets/images/icon_meet_positive.svg" alt="" className="img-fluid meetingDate--img me-1" /></Link></li>
              <li className="mb-1"><Link to={""}><img src="/assets/images/icon_mobile_positive.svg" alt="" className="img-fluid meetingDate--img me-1" /></Link></li>
            </ul>
            <Calendar onChange={onChange} value={value} />
          </div> */}

        </div>

        {/* <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-5">
          <button
            className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2 border-darkGrey`}
            onClick={Profile}
          >
            Cancel
          </button>

          <button
            className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
            onClick={Time}>
            Next
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default MeetingDate;
