import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateMemberrHandler } from "../../../services/auth";
import { Avatar, Progress, Tabs, Dropdown, Menu, Button, Modal, Input, Select, Upload, message } from "antd";
import "rsuite/dist/rsuite.min.css";
import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom';
import { Loader } from "rsuite";
import GradientSVG from "./gradientSVG";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { useLocation } from 'react-router-dom';
import "./style.css";
import { MyContext } from "../../../Utilities/MyContextProvider";


const AddMemmbers = () => {
  const myContextData = useContext(MyContext);
  const { setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext[0];
  
  const { Option } = Select;


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  // add member form
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    department: '',
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    department: '',
  });

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value, name) => {
    // If `value` is an object representing the selected option, access its `value` property
    if (value && value.hasOwnProperty('value')) {
      value = value.value;
    }

    setFormData({ ...formData, [name]: value });
  };


  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      department: '',
    };

    // Add your validation logic here
    if (formData.firstName.trim() === '') {
      isValid = false;
      errors.firstName = 'First name is required';
    }

    if (formData.lastName.trim() === '') {
      isValid = false;
      errors.lastName = 'Last name is required';
    }

    if (formData.email.trim() === '' || !isValidEmail(formData.email)) {
      isValid = false;
      errors.email = 'Invalid email address';
    }

    if (formData.role === '') {
      isValid = false;
      errors.role = 'Role is required';
    }

    if (formData.department === '') {
      isValid = false;
      errors.department = 'Department is required';
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Add email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleAddButtonClick = async () => {
    if (validateForm()) {
      setLoading(true);            
      const payload = {
        enterpriseID: userData?.companyID,
        firstName: formData.firstName,
        lastName: formData.lastName,
        emailAddress: formData.email,
        role: formData.role,
        usertype: 'member',
        department: formData.department,
        company_name:userData.companyName,
        company_email:userData.emailAddress
      };
      const responseAddMember = await CreateMemberrHandler(payload);      
      setLoading(false);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        department: '',
      });

      navigate(`/enterprise/dashboard/department`);
      

    }
  };






  return (
    <>
      <style>
        {`
          .add-member-popup-form-buttons button {
            font-size: 16px;
            height: 40px;
        }
        
        .add-member-popup-form-buttons button.desc__applynow:hover {
            color: #b2da66;
            background: #fff;
            outline: none;
            border: 1px solid #b2da66;
        }
        
        .add-member-popup .ant-modal-title {
            font-size: 24px;
            font-weight: 600;
            color: #000;
            padding-bottom: 10px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 30px;
        }
        
        .add-member-popup .ant-modal-close svg {
            font-size: 16px;
        }
        .form-box label {
          width: 100%;
          font-size:16px;
          font-weight:600;
          }
          
          .form-box .ant-select {
              width: 100%;
              font-size:16px;
          }
          .form-box .ant-select .ant-select-arrow{
            display:block !important;
            color:#fff;
            font-size:16px;
          }
          .form-box input{
              height:46px;
          }
          .add-member-popup-form-buttons button {
            font-size: 16px;
            height: 40px;
        }
        
        .add-member-popup-form-buttons button.desc__applynow:hover {
            color: #b2da66;
            background: #fff;
            outline: none;
            border: 1px solid #b2da66;
        }
        .desc__applynow {
          height: 40px;
      }
      .cancel-button {
        height: 40px;
        width: 200px;
    }
        `}
      </style>
      <div className="edash ">
        <div className="container-dash pb-4 d-flex flex-column align-items-center justify-content-between">
          {/* <div className="d-flex flex-column w-100 align-items-center text-center meeting__container ">

            <h3 className="fm darkGrey fw-normal mt-2 fs-3  text-center">
              <strong>Add Member</strong>
            </h3>
            <p className="fm darkGrey fw-light">For position:</p>

          </div> */}
          <div className="jobs__content__hiring enterprise d-flex align-items-center justify-content-between mt-4  p-3 flex-lg-nowrap flex-wrap">
              <div style={{ marginRight: '50px' }}>
                <h1 className="jobs__content__hiring__heading fw-semibold fm">
                Add Members
                </h1>
                <p className="jobs__content__hiring__para  fm">
                Do you want future colleagues of potential candidate to participate in the process? No problem; our candidate engagement module enables 360 collaboration. You can add a department or departments and invite future colleagues of the potential candidate as an interviewer.
                </p>
              </div>              
            </div>
          <div className="add-member-popup-form mt-5">
            <div className="row align-item-center justify-content-center">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-box">
                      <label>First name
                        <input
                          type="text"
                          name="firstName"
                          className="form-control form-control-md w-100 Dashboard__input mb-2"
                          placeholder="Enter first name"
                          value={formData.firstName}
                          onChange={(value) => handleInputChange(value, 'firstName')}
                        />
                        <div className="error-message">{formErrors.firstName}</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-box">
                      <label>Last name
                        <input
                          type="text"
                          name="lastName"
                          className="form-control form-control-md w-100 Dashboard__input mb-2"
                          placeholder="Enter last name"
                          value={formData.lastName}

                          onChange={(value) => handleInputChange(value, 'lastName')}
                        />
                        <div className="error-message">{formErrors.lastName}</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-box">
                      <label>Email
                        <input
                          type="email"
                          name="email"
                          className="form-control form-control-md w-100 Dashboard__input mb-2"
                          placeholder="Enter email"
                          value={formData.email}
                          onChange={(value) => handleInputChange(value, 'email')}

                        />
                        <div className="error-message">{formErrors.email}</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-box">
                      <label>Select Role
                        <Select name="role" placeholder="Select role" value={formData.role}
                          onChange={(value) => handleSelectChange(value, 'role')}

                        >
                          <Option value="hr">HR</Option>
                          <Option value="interviewer">Interviewer</Option>
                        </Select>
                        <div className="error-message">{formErrors.role}</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-box">
                      <label>Department Name
                        <input
                          type="text"
                          name="department"
                          className="form-control form-control-md w-100 Dashboard__input mb-2"
                          placeholder="Enter Department Name"
                          value={formData.department}
                          onChange={(value) => handleInputChange(value, 'department')}
                        />
                        <div className="error-message">{formErrors.department}</div>
                      </label>

                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center mt-5">
                    <Button className="primary cancel-button" style={{ marginRight: '8px' }}>
                      Cancel
                    </Button>
                    <Button className="desc__applynow" onClick={handleAddButtonClick}>
                      {loading ? <Loader /> : 'Add'}
                    </Button>

                  </div>
                </div>
              </div>
            </div>
          </div>




        </div>
      </div>
    </>

  );
};

export default AddMemmbers;
