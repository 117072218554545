import React from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import "./style.css";

const Submitted = () => {
  return (
    <div className="bg-black pt-5">
      <div className="container-md d-flex flex-column align-items-center">
        <Link to={"/"}>
          <div className="Navbar__container__logo d-flex align-items-center justify-content-center mb-md-5 mb-4">
            <img
              src={`/assets/images/navbar/logoWhite.svg`}
              alt=""
              className="Navbar__container__logo--img"
            />
          </div>
        </Link>

        <h1 className="fm main fw-light text-center fs-1 pt-5">
          Thank you! Your reference form is completed.
        </h1>
        <p className="submitted__para text-white pb-sm-5 pb-3 text-center">
          If you are a recruiter or company why not try our enterprise IndexScore
          account we will speed up your recruitment process by 75%.
        </p>
        <div className="position-relative d-sm-block d-flex flex-column align-items-center">
            <Link to={'/'} className="text-decotaion-none"><button className="fm submitted--btn transition mb-sm-0 mb-3">Find out more</button></Link>
          <img
            src="/assets/images/WhyIndexcore/WhyIndexcore_main.jpeg"
            alt=""
            className="img-fluid Why__bottom--img "
          />
        </div>
      </div>
      <div className="contactform-border mt-md-5 mt-4">
        <Footer />
      </div>
    </div>
  );
};

export default Submitted;
