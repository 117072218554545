export const companies = [
    {name: "Hilton"},
    {name: "Google"},
    {name : "Jack Henry"},
    {name : "CACI"},
    {name : "NBCUniversal"},
    {name : "Banner Health"},
    {name : "Adobe"},
    {name : "H&R Block"},
    {name : "General Mills"},
    {name : "BMS"},
    {name : "Merck"},
    {name : "Urban Outfitters"},
    {name : "AIG"},
    {name : "FedEx"},
    {name : "Tyson Foods"},
    {name : "UPS"},
    {name : "Party City"},
    {name : "Thermo Fisher Scientific"},
    {name : "DaVita"},
    {name : "JPMorgan"},
    {name : "Northwestern Mutual"},
    {name : "Schneider Electric"},
    {name : "Six Flags"},
    {name : "Intuit"},
    {name : "Nielsen"},
    {name : "Safeway"},
    {name : "UTC Aerospace"},
    {name : "Best Western"},
    {name : "Marshalls"},
    {name : "Hyatt"},
    {name : "Vanguard"},
    {name : "NCR"},
    {name : "Frontier"},
    {name : "Conagra Brands"},
    {name : "SkyWest Airlines"},
    {name : "Campbell"},
    {name : "Fifth Third"},
    {name : "Ulta Beauty"},
    {name : "Capri Holdings"},
    {name : "Bayer"},
    {name : "MetLife"},
    {name : "Atos-Syntel"},
    {name : "Concentrix"},
    {name : "Subway"},
    {name : "Cognizant"},
    {name : "Publix"},
    {name : "Siemens"},
    {name : "United Airlines"},
    {name : "Discovery"},
    {name : "Hertz"},
    {name : "Panasonic"},
    {name : "Gilead"},
    {name : "Dell"},
    {name : "Pizza Hut"},
    {name : "Maximus"},
    {name : "Henkel"},
    {name : "Infosys"},
    {name : "FactSet"},
    {name : "Mastercard"},
    {name : "Teva"},
    {name : "Humana"},
    {name : "Charles Schwab"},
    {name : "SpaceX"},
    {name : "Chase"},
    {name : "NortonLifeLock"},
    {name : "Trane Technologies"},
    {name : "Windstream"},
    {name : "Prudential"},
    {name : "Cigna"},
    {name : "Micron"},
    {name : "Pfizer"},
    {name : "Disney"},
    {name : "HCA Healthcare"},
    {name : "PPG"},
    {name : "Amgen"},
    {name : "First Data"},
    {name : "Johnson Controls"},
    {name : ".DS"},
    {name : "Inovalon"},
    {name : "UBS"},
    {name : "John Deere"},
    {name : "Motorola Solutions"},
    {name : "Yahoo"},
    {name : "BlackRock"},
    {name : "Nike"},
    {name : "Fresenius"},
    {name : "Takeda"},
    {name : "Comerica"},
    {name : "KFC"},
    {name : "Cracker Barrel"},
    {name : "Big Lots"},
    {name : "AstraZeneca"},
    {name : "Meijer"},
    {name : "Collins Aero"},
    {name : "Brookdale"},
    {name : "Mount Sinai Health System"},
    {name : "Choice Hotels"},
    {name : "BNY Mellon"},
    {name : "LexisNexis"},
    {name : "Old Navy"},
    {name : "Trilogy Health Services"},
    {name : "Molina Healthcare"},
    {name : "Morgan Stanley"},
    {name : "Capital Group"},
    {name : "Capital One"},
    {name : "PacSun"},
    {name : "T-Mobile"},
    {name : "Citrix"},
    {name : "Huntington"},
    {name : "Hy-Vee"},
    {name : "Texas Roadhouse"},
    {name : "Baker Hughes"},
    {name : "USAA"},
    {name : "The Cheesecake Factory"},
    {name : "Nationwide"},
    {name : "Amazon"},
    {name : "CSAA Insurance"},
    {name : "UPMC"},
    {name : "Regeneron"},
    {name : "Red Robin"},
    {name : "Amedisys"},
    {name : "Liberty Mutual"},
    {name : "Honeywell"},
    {name : "Experian"},
    {name : "Fastenal"},
    {name : "Dow"},
    {name : "Jack in the Box"},
    {name : "Stop & Shop"},
    {name : "Northrop Grumman"},
    {name : "Genesis"},
    {name : "Red Hat"},
    {name : "Anthropologie"},
    {name : "Conduent"},
    {name : "Giant Eagle"},
    {name : "Capgemini"},
    {name : "eBay"},
    {name : "New York Life"},
    {name : "Genentech"},
    {name : "Allergan"},
    {name : "Cisco Systems"},
    {name : "Deloitte"},
    {name : "Red Bull"},
    {name : "USPS"},
    {name : "Northwell"},
    {name : "Reynolds and Reynolds"},
    {name : "Kiewit"},
    {name : "Airbnb"},
    {name : "Caterpillar"},
    {name : "Nuance"},
    {name : "Arrow"},
    {name : "Carnival"},
    {name : "Accenture"},
    {name : "Four Seasons"},
    {name : "Philips"},
    {name : "Intel"},
    {name : "Roche"},
    {name : "Sykes"},
    {name : "Alaska Airlines"},
    {name : "Forrester"},
    {name : "Allscripts"},
    {name : "SeaWorld"},
    {name : "Walgreens"},
    {name : "Lockheed Martin"},
    {name : "Goodyear"},
    {name : "Fresh Market"},
    {name : "ESPN"},
    {name : "Harris"},
    {name : "Autodesk"},
    {name : "P&G"},
    {name : "SunTrust"},
    {name : "FIS"},
    {name : "Western Digital"},
    {name : "Pier 1 Imports"},
    {name : "Steak n Shake"},
    {name : "Giant Food"},
    {name : "Tommy Hilfiger"},
    {name : "Juniper Networks"},
    {name : "Cleveland Clinic"},
    {name : "Akamai"},
    {name : "Kate Spade"},
    {name : "Bain & Company"},
    {name : "Wells Fargo"},
    {name : "ResCare"},
    {name : "Flextronics"},
    {name : "Verizon"},
    {name : "Cummins"},
    {name : "McKesson"},
    {name : "CenturyLink"},
    {name : "CVS"},
    {name : "Burlington Stores"},
    {name : "AT&T"},
    {name : "Abercrombie"},
    {name : "Assurant"},
    {name : "MD Anderson Cancer Center"},
    {name : "Massachusetts General Hospital"},
    {name : "JetBlue"},
    {name : "Office Depot"},
    {name : "ICF"},
    {name : "Citizens Bank"},
    {name : "Amdocs"},
    {name : "Tenet Health"},
    {name : "Atos"},
    {name : "XPO Logistics"},
    {name : "Stryker"},
    {name : "Seagate"},
    {name : "Vonage"},
    {name : "In-N-Out"},
    {name : "ABC"},
    {name : "Under Armour"},
    {name : "iHeartMedia"},
    {name : "World Wide Technology"},
    {name : "GlobalFoundries"},
    {name : "Benefitfocus"},
    {name : "Vail Resorts"},
    {name : "Kmart"},
    {name : "Advance Auto Parts"},
    {name : "VMware"},
    {name : "Ascension"},
    {name : "Centene"},
    {name : "Alcon"},
    {name : "Aon"},
    {name : "Groupon"},
    {name : "Target"},
    {name : "Enterprise"},
    {name : "PNC"},
    {name : "Food Lion"},
    {name : "Panda Express"},
    {name : "Lam Research"},
    {name : "Family Dollar Stores"},
    {name : "Levi Strauss"},
    {name : "Leidos"},
    {name : "Applied Materials"},
    {name : "Ralph Lauren"},
    {name : "Regions"},
    {name : "Tesla"},
    {name : "Chick-fil-A"},
    {name : "Southwest"},
    {name : "NOV"},
    {name : "DoorDash"},
    {name : "DSW"},
    {name : "Gartner"},
    {name : "The Gap"},
    {name : "AbbVie"},
    {name : "Nvidia"},
    {name : "McKinsey"},
    {name : "Bank of America"},
    {name : "Darden"},
    {name : "Expedia"},
    {name : "Alphabet"},
    {name : "Zillow"},
    {name : "KPMG"},
    {name : "Lenovo"},
    {name : "Mercer"},
    {name : "Oracle"},
    {name : "ABB"},
    {name : "Intermountain"},
    {name : "Chipotle"},
    {name : "Costco"},
    {name : "Grainger"},
    {name : "Fred Meyer"},
    {name : "NYU Langone"},
    {name : "Providence"},
    {name : "Fiserv"},
    {name : "Gallagher"},
    {name : "Disney Parks"},
    {name : "Winn-Dixie"},
    {name : "Wipro"},
    {name : "BB&T"},
    {name : "Geico"},
    {name : "Chevron"},
    {name : "Sprouts"},
    {name : "WellCare"},
    {name : "Taco Bell"},
    {name : "PayPal"},
    {name : "Marriott"},
    {name : "Whirlpool Corporation"},
    {name : "Starbucks"},
    {name : "Stanford Health Care"},
    {name : "Merrill Lynch"},
    {name : "Dignity Health"},
    {name : "Colgate-Palmolive"},
    {name : "Ceridian"},
    {name : "Wyndham"},
    {name : "Thomson Reuters"},
    {name : "Wolters Kluwer"},
    {name : "The Buckle"},
    {name : "Raytheon"},
    {name : "Anthem"},
    {name : "Bed Bath & Beyond"},
    {name : "CA Technologies"},
    {name : "A\u00e9ropostale"},
    {name : "InterContinental"},
    {name : "Alorica"},
    {name : "The Home Depot"},
    {name : "Weatherford"},
    {name : "Kraft Heinz"},
    {name : "Postmates"},
    {name : "Sherwin-Williams"},
    {name : "Atrium Health"},
    {name : "Parker Hannifin"},
    {name : "Kimberly-Clark"},
    {name : "Northern Trust"},
    {name : "Rackspace"},
    {name : "Goldman Sachs"},
    {name : "Avnet"},
    {name : "Workday"},
    {name : "Farmers"},
    {name : "H&M"},
    {name : "ExxonMobil"},
    {name : "Harris Teeter"},
    {name : "GameStop"},
    {name : "Wegmans"},
    {name : "GoDaddy"},
    {name : "Baxter"},
    {name : "Boston Scientific"},
    {name : "M&T Bank"},
    {name : "Expeditors"},
    {name : "Rite Aid"},
    {name : "Williams-Sonoma"},
    {name : "Ericsson"},
    {name : "Aldi"},
    {name : "DuPont"},
    {name : "Lincoln Financial"},
    {name : "Aflac"},
    {name : "Schlumberger"},
    {name : "Medtronic"},
    {name : "Adidas"},
    {name : "Mars"},
    {name : "Advocate Aurora Health"},
    {name : "Rent-A-Center"},
    {name : "Biogen"},
    {name : "Edward Jones"},
    {name : "Baylor Scott & White"},
    {name : "Kaiser Permanente"},
    {name : "Nokia"},
    {name : "CDK Global"},
    {name : "TTEC"},
    {name : "Magellan Health"},
    {name : "Novartis"},
    {name : "Kroger"},
    {name : "Unilever"},
    {name : "Barnes & Noble"},
    {name : "Rockwell"},
    {name : "Xerox"},
    {name : "Avis Budget"},
    {name : "Comcast"},
    {name : "Jabil"},
    {name : "Booz Allen"},
    {name : "ServiceSource"},
    {name : "Dairy Queen"},
    {name : "Shell"},
    {name : "Raymond James"},
    {name : "Discount Tire"},
    {name : "Apple"},
    {name : "Equifax"},
    {name : "IHS Markit"},
    {name : "MGM Resorts"},
    {name : "S&P Global"},
    {name : "Viacom"},
    {name : "Lyft"},
    {name : "Northwestern Medicine"},
    {name : "Schneider"},
    {name : "Cox"},
    {name : "Emerson"},
    {name : "Support.com"},
    {name : "Sanofi"},
    {name : "KeyBank"},
    {name : "CGI"},
    {name : "TransUnion"},
    {name : "Cerner"},
    {name : "Coca-Cola"},
    {name : "Best Buy"},
    {name : "CDW"},
    {name : "MathWorks"},
    {name : "Turner Construction"},
    {name : "Delta"},
    {name : "Unisys"},
    {name : "AutoZone"},
    {name : "Ford"},
    {name : "RBC"},
    {name : "Cinemark"},
    {name : "DXC"},
    {name : "Ally Financial"},
    {name : "American Eagle"},
    {name : "The Hartford"},
    {name : "Sears"},
    {name : "CarMax"},
    {name : "Advisory Board"},
    {name : "Kellogg"},
    {name : "Serco Group"},
    {name : "AMC"},
    {name : "Qualcomm"},
    {name : "Kronos"},
    {name : "Fossil"},
    {name : "Jacobs"},
    {name : "Panera"},
    {name : "Paycom"},
    {name : "NetApp"},
    {name : "Whole Foods"},
    {name : "Ruby Tuesday"},
    {name : "Discover"},
    {name : "Citi"},
    {name : "Hershey"},
    {name : "Sutherland"},
    {name : "Dollar General"},
    {name : "ADP"},
    {name : "PepsiCo"},
    {name : "Netflix"},
    {name : "Nordstrom"},
    {name : "Albertsons"},
    {name : "TriNet"},
    {name : "H E B"},
    {name : "Epic"},
    {name : "Fannie Mae"},
    {name : "Nordstrom Rack"},
    {name : "Fluor"},
    {name : "Caesars"},
    {name : "PwC"},
    {name : "Tractor Supply"},
    {name : "Slalom"},
    {name : "Samsung Electronics"},
    {name : "Deutsche Bank"},
    {name : "MassMutual"},
    {name : "Michaels"},
    {name : "General Motors"},
    {name : "Banana Republic"},
    {name : "CHS"},
    {name : "GNC"},
    {name : "Spectrum"},
    {name : "Credit Suisse"},
    {name : "Fidelity"},
    {name : "Pitney Bowes"},
    {name : "Fiat Chrysler Automobiles"},
    {name : "CompuCom"},
    {name : "Broadcom"},
    {name : "J&J"},
    {name : "Uber"},
    {name : "Travelers"},
    {name : "Sunrise"},
    {name : "Memorial Sloan Kettering"},
    {name : "Sysco"},
    {name : "Halliburton"},
    {name : "Burger King"},
    {name : "American Airlines"},
    {name : "Kindred Healthcare"},
    {name : "Walmart"},
    {name : "IBM"},
    {name : "Clorox"},
    {name : "Illumina"},
    {name : "Express Scripts"},
    {name : "Home Instead"},
    {name : "Allstate"},
    {name : "Est\u00e9e Lauder"},
    {name : "Dish"},
    {name : "Aecom"},
    {name : "Paychex"},
    {name : "Mayo Clinic"},
    {name : "Abbott"},
    {name : "Aetna"},
    {name : "Microsoft"},
    {name : "Yelp"},
    {name : "Twitter"},
    {name : "Bose"},
    {name : "Foot Locker"},
    {name : "Boeing"},
    {name : "Five Guys"},
    {name : "AdventHealth"},
    {name : "Save-A-Lot"},
    {name : "Genpact"},
    {name : "L3 Technologies"},
    {name : "Asurion"},
    {name : "Cardinal Health"},
    {name : "HCL Tech"},
    {name : "SAP"},
    {name : "HD Supply"},
    {name : "Lululemon"},
    {name : "TCS"},
    {name : "Guess"},
    {name : "Ryder System"},
    {name : "Sutter Health"},
    {name : "Paylocity"},
    {name : "State Street"},
    {name : "UnitedHealth"},
    {name : "Barclays"},
    {name : "Royal Caribbean"},
    {name : "Ameriprise Financial"},
    {name : "Eli Lilly"},
    {name : "Sprint"},
    {name : "Progressive"},
    {name : "Ross Stores"},
    {name : "Apria"},
    {name : "Stitch Fix"},
    {name : "U-Haul"},
    {name : "Convergys"},
    {name : "GSK"},
    {name : "Varian"},
    {name : "Cedars-Sinai Medical Center"},
    {name : "Union Pacific"},
    {name : "Chubb"},
    {name : "HubSpot"},
    {name : "American Express"},
    {name : "State Farm"},
    {name : "HCR ManorCare"},
    {name : "AutoNation"},
    {name : "Universal Studios"},
    {name : "REI"}
];