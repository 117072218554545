import React, { useEffect, useRef, useState, useContext } from "react";
import { Input, Space, Tag, Tooltip, Select, DatePicker } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment";
import { CountryForCities } from '../../../Utilities/Country.js';
import { CountriesWithCities } from '../../../Utilities/cities.js';
import { companies } from '../../../Utilities/Companies.js'
import {
  createLandscape,
} from "../../../services/auth";
import { useSnackbar } from "notistack";
import { Loader } from "rsuite";
import { MyContext } from "../../../Utilities/MyContextProvider";

const Landscape = () => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];

  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const { Option, OptGroup } = Select;

  const [selectedBrand, setSelectedBrand] = useState([]);
  const [cityNames, setCityNames] = useState([]);
  const [brandInputVisible, setBrandInputVisible] = useState(false);
  const [brandInputValue, setBrandInputValue] = useState("");
  const [brandEditInputIndex, setBrandEditInputIndex] = useState(-1);
  const [brandEditInputValue, setBrandEditInputValue] = useState("");
  const brandInputRef = useRef(null);
  const brandEditInputRef = useRef(null);

  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: false,
  });
  const { loading } = state;


  const [formData, setFormData] = useState({
    jobTitle: '',
    city: '',
    country: '',
    skills: [],
    employer_brand: '',
    selectedRoles: [],
    minSalary: '',
    maxSalary: '',
    currency: '',
    startDate: null,
    endDate: null,
    yoe_min: '',
    yoe_max: ''
  });


  const [errors, setErrors] = useState({
    jobTitle: '',
    city: '',
    country: '',
    skills: '',
    employer_brand: '',
    // selectedRoles: '',
    minSalary: '',
    maxSalary: '',
    currency: '',
    // dateRange: '',
    yoe_min: '',
    yoe_max: ''
  });


  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);



  useEffect(() => {
    if (brandInputVisible) {
      brandInputRef.current?.focus();
    }
  }, [brandInputVisible]);
  useEffect(() => {
    brandEditInputRef.current?.focus();
  }, [brandInputValue]);


  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    
    handleFieldChange('skills', newTags);
    setTags(newTags);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      handleFieldChange('skills', [...tags, inputValue]);
      
      // handleJobTags([...tags, inputValue])
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    
    setEditInputIndex(-1);
    setInputValue("");
  };



  const handleBrandClose = (removedTag) => {
    const newTags = selectedBrand.filter((tag) => tag !== removedTag);
    
    handleFieldChange('employer_brand', newTags);
    setSelectedBrand(newTags);
  };
  const handleBrandInputChange = (e) => {
    setBrandInputValue(e.target.value);
  };
  const handleBrandInputConfirm = () => {
    if (brandInputValue && selectedBrand.indexOf(brandInputValue) === -1) {
      setSelectedBrand([...selectedBrand, brandInputValue]);
      handleFieldChange('employer_brand', [...selectedBrand, brandInputValue]);
      // handleJobTags([...tags, inputValue])
    }
    setBrandInputVisible(false);
    setBrandInputValue("");
  };
  const handleBrandEditInputChange = (e) => {
    setBrandEditInputValue(e.target.value);
  };
  const handleBrandEditInputConfirm = () => {
    const newTags = [...selectedBrand];
    newTags[brandEditInputIndex] = brandEditInputValue;
    setSelectedBrand(newTags);
    setBrandEditInputIndex(-1);
    setBrandInputValue("");
  };



  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };



  const handleFieldChange = (fieldName, value) => {
    
    setFormData({
      ...formData,
      [fieldName]: value,
    });


  };


  const handleCountryChange = (fieldName, value) => {
    const countryCode = value;
    handleFieldChange('country', countryCode);
    

    const countryName = CountryForCities.find(country => country.code === value)?.name;
    // const selectedCities = CountriesWithCities[value] || [];
    setCityNames([]);
    setCityNames(CountriesWithCities[countryName]);
    
    // handleFieldChange('city', null);
  };

  const handleStartDateChange = (date, dateString) => {
    handleFieldChange('startDate', moment(dateString).format("YYYY-MM-DD"));    
  };


  const handleEndDateChange = (date, dateString) => {
    handleFieldChange('endDate', moment(dateString).format("YYYY-MM-DD"));    
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };


  const validateForm = () => {
    const newErrors = { ...errors };

    // Validate jobTitle
    if (!formData.jobTitle.trim()) {
      newErrors.jobTitle = 'Job Title is required.';
    } else {
      newErrors.jobTitle = '';
    }

    if (!formData.country.trim()) {
      newErrors.country = 'Country is required.';
    } else {
      newErrors.country = '';
    }

    if (!formData.city.trim()) {
      newErrors.city = 'City is required.';
    } else {
      newErrors.city = '';
    }

    // Validate skills
    if (formData.skills.length === 0) {
      newErrors.skills = 'At least one skills is required.';
    } else {
      newErrors.skills = '';
    }

    if (formData.employer_brand.length === 0) {
      newErrors.employer_brand = 'At least one employer brand is required.';
    } else {
      newErrors.employer_brand = '';
    }

    // handleCloseBrand

    // Validate selectedRoles
    // if (formData.selectedRoles.length === 0) {
    //   newErrors.selectedRoles = 'At least one role must be selected.';
    // } else {
    //   newErrors.selectedRoles = '';
    // }

    // Validate salary-related fields
    if (!formData.minSalary || !formData.maxSalary || !formData.currency) {
      newErrors.minSalary = 'Minimum salary required.';
      newErrors.maxSalary = 'Maximum salary required.';
      newErrors.currency = 'Currency required';
    } else {
      newErrors.minSalary = '';
      newErrors.maxSalary = '';
      newErrors.currency = '';
    }

    // Validate date range
    // if (!formData.startDate || !formData.endDate) {
    //   newErrors.dateRange = 'Start date and end date are required.';
    // } else {
    //   newErrors.dateRange = '';
    // }

    setErrors(newErrors);
    
    // Return true if the form is valid, false otherwise
    return Object.values(newErrors).every(error => !error);
  };

  const handleSubmitLandscape = async () => {
    // Validate the form
    handleChange("loading", true);
    if (validateForm()) {
      // Proceed with form submission

      
      // const enterpriseID = localStorage.getItem('enterpriseID');
      // You can submit the form data here using an API call or any other method.
      const payload = {
        enterpriseID: userData?.companyID,
        job: formData.jobTitle,
        city: formData.city,
        country: formData.country,
        skills: formData.skills,
        colleges: [],
        employer_brand: formData.employer_brand,
        salary: {
          currency: formData.currency,
          min: formData.minSalary,
          max: formData.maxSalary,
        },
        yoe: {
          min: formData.yoe_min,
          max: formData.yoe_max,
        }
      };
      const responseLanscape = await createLandscape(payload);
      if (responseLanscape) {
        enqueueSnackbar('Submitted successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          }
        });
      }      


      // Reset the form data after submission if needed
      setFormData({
        jobTitle: '',
        skills: [],
        employer_brand: [],
        // selectedRoles: [],
        minSalary: '',
        maxSalary: '',
        currency: '',
        // startDate: null,
        // endDate: null,
        yoe_min: '',
        yoe_max: ''
      });
      navigate(`/enterprise/dashboard/candidate-landscape/overview`);

    } else {
      handleChange("loading", false);
      // Form is not valid; do not submit and display error messages
      
    }
  };



  return (
    <>
      <div className=" bg-lightgrey edash edash3 min-vh-100 transition-1">        
        <style>
          {`
        .error-message small {
          display: flex;
          max-width: 100%;
          margin-top: 10px !important;
          margin-bottom:10px !important;
          }
          .ant-space-item {
            margin-bottom: 5px;
          }
          .ant-select-selector{
            max-width:100% !Important;
          }
          `}
        </style>
        <div className="landscape__form Dashboard__container bg-lightgrey mb-0 overflow-hidden">
          <div className=" mt-4 bg-lightgrey fs-2">
            <h1 className=" dashboardScreen__content__hiring__heading darkGrey fw-normal fm">
              Candidate Landscape
            </h1>
            <div className="row">
              <div className="col-xl-12 order-1">
                <p className="darkGrey fm fs-6">
                  Now it’s time to get your company set up a job post on
                  IndexScore. Let’s start sharing roles and finding new hires.
                  Please fill in the details below to start creating a new job
                  post and move onto the next step.
                </p>
              </div>
              <div className="col-xl-12 order-xl-2 order-3">
                <div className="row">
                  <div className="col-12">
                    {/* <Input
                      type="text"
                      size="medium"
                      className="form-control form-control-md mb-2 landscape__input bg-white"
                      id="jobTitle"
                      placeholder="Job Title"
                      value={formData.jobTitle}
                      onChange={(e) => handleFieldChange('jobTitle', e.target.value)}
                    /> */}
                    <Select
                      onChange={(value) => {
                        // alert(value)
                        handleFieldChange('jobTitle', value);
                        
                      }}
                      style={{
                        width: '100%'
                      }}
                      className="mb-2 landscape__select mx-0 d-block w-100"
                      size="small"
                      placeholder="Job Title"

                    >
                      <Option value="Software Engineer">Software Engineer</Option>
                      <Option value="Product Manager">Product Manager</Option>
                      <Option value="Software Architect">Software Architect</Option>
                      <Option value="HR Manager">HR Manager</Option>
                      <Option value="Machine Learning Engineer">Machine Learning Engineer</Option>
                      <Option value="Big Data Engineer">Big Data Engineer</Option>
                      <Option value="Data Analyst">Data Analyst</Option>
                      <Option value="DevOps Engineer">DevOps Engineer</Option>
                      <Option value="Site Reliability Engineer">Site Reliability Engineer</Option>
                      <Option value="Database Administrator">Database Administrator</Option>
                      <Option value="Data Scientist">Data Scientist</Option>
                      <Option value="Front End Developer">Front End Developer</Option>
                      <Option value="Full Stack Developer">Full Stack Developer</Option>
                      <Option value="Mobile Developer">Mobile Developer</Option>
                      <Option value="UX designer">UX designer</Option>
                      <Option value="Network Engineer">Network Engineer</Option>
                      <Option value="Backend Developer">Backend Developer</Option>

                    </Select>
                    {errors.jobTitle ? <div className="error-message"><small className="error__message">{errors.jobTitle}</small></div> : ''}

                  </div>

                  <div className="col-lg-6 col-12">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      className="Login--Input Login--select2  mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="Country"
                      onChange={(value) => { handleCountryChange('country', value); }}
                    >
                      <OptGroup>
                        {CountryForCities.map((item) => (
                          <Option
                            value={item.code}
                            label={item.name.toLowerCase()}
                            key={item.code}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </OptGroup>
                    </Select>
                    {errors.country ? <div className="error-message"><small className="error__message">{errors.country}</small></div> : ''}

                  </div>

                  <div className="col-lg-6 col-12">

                    <Select
                      // showSearch
                      optionFilterProp="children"
                      // filterOption={(input, option) =>
                      //   (option?.label ?? '').includes(input)
                      // }
                      // filterSort={(optionA, optionB) =>
                      //   (optionA?.label ?? '')
                      //     .toLowerCase()
                      //     .localeCompare((optionB?.label ?? '').toLowerCase())
                      // }
                      className="Login--Input Login--select2 mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="City"
                      // value={} // You can set the initial value to the first city if needed
                      onChange={(value) => handleFieldChange('city', value)}
                    >
                      {cityNames.map((cityName, index) => (
                        <Option value={cityName} key={index}>
                          {cityName}
                        </Option>
                      ))}
                    </Select>
                    {/* <Input
                      type="text"
                      size="medium"
                      className="form-control form-control-md mb-2 landscape__input bg-white"
                      id="city"
                      placeholder="City"
                      value={formData.city}
                      onChange={(e) => handleFieldChange('city', e.target.value)}
                    />
                    {errors.city ? <div className="error-message"><small className="error__message">{errors.city}</small></div> : ''} */}

                  </div>

                  <div className="col-12">
                    <Space className="d-block">
                      <Input
                        ref={inputRef}
                        type="text"
                        style={tagInputStyle}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                        size="medium"
                        className="form-control form-control-md mb-2 landscape__input bg-white"
                        id="job"
                        placeholder="skills"
                      />
                      <Space className="d-flex">
                        {tags.map((tag, index) => {
                          if (editInputIndex === index) {
                            return (
                              <div className="d-block">
                                <Input
                                  ref={editInputRef}
                                  key={tag}
                                  size="small"
                                  style={tagInputStyle}
                                  value={editInputValue}
                                  onChange={handleEditInputChange}
                                  onBlur={handleEditInputConfirm}
                                  onPressEnter={handleEditInputConfirm}
                                />
                              </div>
                            );
                          }
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag}
                              closable={index !== -1}
                              style={{
                                userSelect: "none",
                              }}
                              onClose={() => handleClose(tag)}
                            >
                              <span
                                onDoubleClick={(e) => {
                                  if (index !== -1) {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}
                      </Space>
                    </Space>
                    {errors.skills ? <div className="error-message"><small className="error__message">{errors.skills}</small></div> : ''}

                  </div>

                  <div className="col-12">
                    {/* <Space className="d-block">
                      <Input
                        ref={brandInputRef}
                        type="text"
                        style={tagInputStyle}
                        value={brandInputValue}
                        onChange={handleBrandInputChange}
                        onBlur={handleBrandInputConfirm}
                        onPressEnter={handleBrandInputConfirm}
                        size="medium"
                        className="form-control form-control-md mb-2 landscape__input bg-white"
                        id="job"
                        placeholder="Employer Brand"
                      />
                      <Space className="d-flex">
                        {selectedBrand.map((tag, index) => {
                          if (brandEditInputIndex === index) {
                            return (
                              <div className="d-block">
                                <Input
                                  ref={brandEditInputRef}
                                  key={tag}
                                  size="small"
                                  style={tagInputStyle}
                                  value={brandEditInputValue}
                                  onChange={handleBrandEditInputChange}
                                  onBlur={handleBrandEditInputConfirm}
                                  onPressEnter={handleBrandEditInputConfirm}
                                />
                              </div>
                            );
                          }
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag}
                              closable={index !== -1}
                              style={{
                                userSelect: "none",
                              }}
                              onClose={() => handleBrandClose(tag)}
                            >
                              <span
                                onDoubleClick={(e) => {
                                  if (index !== -1) {
                                    setBrandEditInputIndex(index);
                                    setBrandEditInputValue(tag);
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}
                      </Space>
                    </Space> */}

                    <Select
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option.props.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA.props.label ?? "").toLowerCase().localeCompare(optionB.props.label.toLowerCase())
                      }
                      className="mb-2 landscape__select mx-0 d-block w-100"
                      size="small"
                      placeholder="Employer brand"
                      onChange={(value) => handleFieldChange('employer_brand', value)}
                    >
                      <OptGroup>
                        {companies.map((item) => (
                          <Option
                            value={item.name}
                            label={item.name.toLowerCase()}
                            key={item.name}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </OptGroup>
                    </Select>
                    {errors.employer_brand ? <div className="error-message"><small className="error__message">{errors.employer_brand}</small></div> : ''}

                  </div>

                  <div className="col-12 pe-md-2 pe-0 mt-3">
                    <p className="darkBlue fm mb-0 fw-semibold fs-6 mt-4">
                      Advanced Filters
                    </p>
                    <p className="fm darkGrey mb-2 mt-0 fs-6">
                      Choose one or multiple options
                    </p>
                  </div>

                  <div className="col-12 my-2">
                    <Select
                      className="mb-2 landscape__select mx-0 d-block w-100"
                      size="small"
                      placeholder="Role"
                      mode="multiple"
                      value={formData.selectedRoles}
                      onChange={(value) => handleFieldChange('selectedRoles', value)}
                    >
                      <Select.Option value="senior">Senior</Select.Option>
                      <Select.Option value="inter">Intermediate</Select.Option>
                      <Select.Option value="junior">Junior</Select.Option>
                    </Select>
                    {errors.selectedRoles ? <div className="error-message"><small className="error__message">{errors.selectedRoles}</small></div> : ''}

                  </div>

                  <p className="darkGrey fm fw-semibold fs-6 mb-3 mt-4">
                    Salary bracket:
                  </p>
                  <div className="col-sm-4 h-100">
                    <Select
                      className="Login--Input mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="Min ($)"
                      onChange={(value) => {
                        // alert(value)
                        handleFieldChange('minSalary', value);
                        
                      }}
                    >
                      <Option value="1000">1000</Option>
                      <Option value="5000">5000</Option>
                      <Option value="10000">10,000</Option>
                      <Option value="20000">20,000</Option>
                      <Option value="50000">50,000</Option>
                      <Option value="80000">80,000</Option>
                      <Option value="120000">120,000</Option>
                      <Option value="150000">150,000</Option>
                      <Option value="200000">200,000</Option>
                    </Select>
                    {errors.minSalary ? <div className="error-message"><small className="error__message">{errors.minSalary}</small></div> : ''}

                  </div>
                  <div className="col-sm-4 h-100 px-sm-0">
                    <Select
                      className="Login--Input mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="Max ($)"
                      onChange={(value) => {
                        handleFieldChange('maxSalary', value);
                        
                      }}
                    >
                      <Option value="5000">5000</Option>
                      <Option value="10000">10,000</Option>
                      <Option value="20000">20,000</Option>
                      <Option value="50000">50,000</Option>
                      <Option value="80000">80,000</Option>
                      <Option value="120000">120,000</Option>
                      <Option value="150000">150,000</Option>
                      <Option value="200000">200,000</Option>
                      <Option value="350000">350,000</Option>
                    </Select>
                    {errors.maxSalary ? <div className="error-message"><small className="error__message">{errors.maxSalary}</small></div> : ''}

                  </div>
                  <div className="col-sm-4 h-100">
                    <Select
                      className="Login--Input mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="USD"
                      onChange={(value) => {
                        handleFieldChange('currency', value);
                        
                      }}
                    >
                      <Option value="usd">USD</Option>
                      <Option value="gbp">GBP</Option>
                      <Option value="eur">EUR</Option>
                    </Select>
                    {errors.currency ? <div className="error-message"><small className="error__message">{errors.currency}</small></div> : ''}

                  </div>

                  <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-0">YOE</p>
                  <div className="col-sm-6 mb-2 pe-sm-0">
                    {/* <DatePicker
                      className="Login__input w-100"
                      size="large"
                      placeholder="Start date"
                      onChange={handleStartDateChange}
                    /> */}
                    <Select
                      onChange={(value) => {
                        // alert(value)
                        handleFieldChange('yoe_min', value);
                        
                      }}
                      className="Login--Input mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="Min "

                    >
                      <Option value="0">0</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5</Option>
                      <Option value="6">6</Option>
                      <Option value="7">7</Option>
                      <Option value="8">8</Option>
                      <Option value="9">9</Option>
                      <Option value="10">10</Option>
                      <Option value="11">11</Option>
                      <Option value="12">12</Option>
                      <Option value="13">13</Option>
                      <Option value="14">14</Option>
                      <Option value="15">15</Option>
                      <Option value="16">16</Option>
                      <Option value="17">17</Option>
                      <Option value="18">18</Option>
                      <Option value="19">19</Option>
                      <Option value="20">20</Option>
                      <Option value="21">21</Option>
                      <Option value="22">22</Option>
                      <Option value="23">23</Option>
                      <Option value="24">24</Option>
                      <Option value="25">25</Option>

                    </Select>
                    {errors.yoe_min ? <div className="error-message"><small className="error__message">{errors.yoe_min}</small></div> : ''}
                  </div>
                  <div className="col-sm-6 mb-2">
                    {/* <DatePicker
                      className="Login__input w-100"
                      size="large"
                      placeholder="End date"
                      onChange={handleEndDateChange}
                    /> */}

                    <Select
                      onChange={(value) => {
                        // alert(value)
                        handleFieldChange('yoe_max', value);
                        
                      }}
                      className="Login--Input mb-2 mx-0 d-block w-100"
                      size="small"
                      placeholder="Max "

                    >
                      <Option value="26">26</Option>
                      <Option value="27">27</Option>
                      <Option value="28">28</Option>
                      <Option value="29">29</Option>
                      <Option value="30">30</Option>
                      <Option value="31">31</Option>
                      <Option value="32">32</Option>
                      <Option value="33">33</Option>
                      <Option value="34">34</Option>
                      <Option value="35">35</Option>
                      <Option value="36">36</Option>
                      <Option value="37">37</Option>
                      <Option value="38">38</Option>
                      <Option value="39">39</Option>
                      <Option value="40">40</Option>
                      <Option value="41">41</Option>
                      <Option value="42">42</Option>
                      <Option value="43">43</Option>
                      <Option value="44">44</Option>
                      <Option value="45">45</Option>
                      <Option value="46">46</Option>
                      <Option value="47">47</Option>
                      <Option value="48">48</Option>
                      <Option value="49">49</Option>
                      <Option value="50">50</Option>

                    </Select>
                    {errors.yoe_max ? <div className="error-message"><small className="error__message">{errors.yoe_max}</small></div> : ''}
                  </div>

                  <div className="d-flex justify-content-start">
                    {/* <Link
                    to={"/enterprise/dashboard/candidate-landscape/overview"}
                  > */}
                    <button
                      onClick={() => handleSubmitLandscape()}
                      className="repost__btn fm px-3 text-14 d-inline-flex align-items-center me-auto my-5 nowrap ms-lg-2">
                      {/* <img
                        src="/assets/images/icon_export-white.svg"
                        alt=""
                        className="me-1 py-1"
                      />{" "} */}
                      {loading ? <Loader /> : "Get candidate insights"}
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 order-xl-3 order-2 mb-3">
                <div className="landscape__right p-1 rounded-3">
                  <div className="d-flex justify-content-between mt-1 px-1">
                    <p className="text-14 fm mb-2 fw-semibold">
                      {" "}
                      Trending Insights{" "}
                    </p>
                    <BsThreeDots className="fs-5 menu__dots pointer" />
                  </div>
                  <div className="bg-white p-2 rounded-3 landscape__right__inner">
                    <img
                      src="/assets/images/map.svg"
                      alt=""
                      className="img-fluid mt-3 mb-2"
                    />
                    <div className="border-05 pt-3"></div>
                    <div className="d-flex justify-content-between fm text-12 pt-3">
                      <p className="mb-0">
                        Number of candidates looking for{" "}
                        <span className="blue">SWE</span> role:
                      </p>{" "}
                      <span className="blue fm fw-500 ms-3">25,467</span>
                    </div>
                    <div className="d-flex justify-content-between fm text-12 pt-2">
                      <p className="mb-2">
                        Number of candidates looking for{" "}
                        <span className="blue">Data Science</span> role:
                      </p>{" "}
                      <span className="blue fm fw-500 ms-3">25,467</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landscape;
