import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Avatar,
  Input,
  Space,
  Tag,
  Tooltip,
  Checkbox,
  Select,
  DatePicker,
  Upload,
  Button,
} from "antd";
import "./style.css";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";
import moment from "moment";
import { Country } from "../../../../Utilities/Country.js";

import { getPackages } from "../../../../services/criteria";
import { MyContext } from "../../../../Utilities/MyContextProvider";

const EConfirm = ({ handleIndexCore }) => {
  const myContext = useContext(MyContext);
  const myData = myContext;
  const [tags, setTags] = useState(myContext.contextData.job_sectors);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [jobPackages, setJobPackages] = useState([]);
  const [value, setValue] = useState(
    myContext.contextData.min_index_core / 1000
  );
  const [value2, setValue2] = useState(
    myContext.contextData.min_culture_fit_score / 100
  );
  const [employmentType, setEmploymentType] = useState(
    myContext.contextData.employment_type
  );
  const countryName = Country.find(
    (country) => country.code === myContext.contextData.job_locations
  )?.name;

  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const firstNames = myContext.contextData.team_members.map(
    (user) => user.firstName
  );
  console.log("myContext.contextData", myContext.contextData);

  useEffect(() => {
    getPackages().then((data) => {
      setJobPackages(data.data);
      // debugger
    });
  }, []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue("");
  };
  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };

  const [indexCoreData, setIndexCoreData] = useState({
    min_index_core: "",
    min_culture_fit_score: "",
    team_members: "",
  });

  const { min_index_core, min_culture_fit_score, team_members } = indexCoreData;

  const handleChange = (key, value) => {
    setIndexCoreData((pre) => ({ ...pre, [key]: value }));
    console.log("key" + key + " ->" + value);
    handleIndexCore(indexCoreData);
  };

  return (
    <div className="Role pb-5 General">
      <div className="Dashboard__container pb-5 pt-4">
        <div className="Dashboard__container__content pt-2">
          <div className="container-fluid px-0">
            <div className="row">
              <div className="col-xxl-8 col-md-10">
                <p className="lightBlue2 fm mb-5 mt-0">
                  Please check all listed details below for final review.
                </p>
              </div>
              <div className="col-12">
                <label
                  htmlFor="sector1"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Full job description
                </label>
                <p className="darkGrey fm mt-0">
                  One final check before we review and post your job.
                </p>
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  value={myContext.contextData.job_title}
                  id="sector1"
                />
              </div>
              <div className="col-12 mb-4">
                <input
                  type="hidden"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  value={myContext.contextData.job_locations}
                  id="sector1"
                />
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Job title"
                  value={countryName}
                />
                {/* <Space className="d-block">
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    // value={inputValue}
                    // onChange={handleInputChange}
                    // onBlur={handleInputConfirm}
                    // onPressEnter={handleInputConfirm}
                    className="w-100 tag__input form-control form-control-md Dashboard__input mb-2"
                    id="job"
                    placeholder="Locations"
                  />
                  <Space className="d-flex">
                    {tags.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <div className="d-block">
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          </div>
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={index !== -1}
                          style={{
                            userSelect: "none",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== -1) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </Space>
                </Space> */}
              </div>
              <div className="col-sm-4 pe-sm-0">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Company"
                  value={myContext.contextData.company}
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Division"
                  value={myContext.contextData.division}
                />
              </div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Department"
                  value={myContext.contextData.department}
                />
              </div>
              <div className="col-12 mt-4">
                <p className="darkGrey mt-0 fm mb-0 fw-semibold fs-6">
                  Employment type:
                </p>
                <p className="darkGrey fm mt-0">
                  Choose one or multiple options
                </p>
                <div className="d-flex flex-wrap">
                  <Checkbox
                    checked={employmentType.includes("Full time")}
                    value="Full time"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                    className="fm darkGrey me-3 mb-2 fs-6"
                  >
                    Full time
                  </Checkbox>
                  <Checkbox
                    checked={employmentType.includes("Part time")}
                    value="Part time"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                    className="fm darkGrey me-3 mb-2 fs-6"
                  >
                    Part time
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 fs-6"
                    checked={employmentType.includes("Hybrid")}
                    value="Hybrid"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                  >
                    Hybrid
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 fs-6"
                    checked={employmentType.includes("Remote")}
                    value="Remote"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                  >
                    Remote
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey me-3 mb-2 fs-6"
                    checked={employmentType.includes("Freelance")}
                    value="Freelance"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                  >
                    Freelance
                  </Checkbox>
                  <Checkbox
                    className="fm darkGrey mb-3 fs-6"
                    checked={employmentType.includes("Temporary")}
                    value="Temporary"
                    name="employmentType"
                    // onChange={handleCheckboxChange}
                  >
                    Temporary
                  </Checkbox>
                </div>
              </div>
              <p className="darkGrey fm mb-0 fw-semibold fs-6 mb-2 mt-4">
                Compensation Range:
              </p>
              <div className="col-sm-4 h-100">
                <Select
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="Min ($)"
                  value={myContext.contextData.min_salary}
                >
                  <Select.Option value="1000">1000</Select.Option>
                  <Select.Option value="1500">1500</Select.Option>
                  <Select.Option value="2000">2000</Select.Option>
                  <Select.Option value="2500">2500</Select.Option>
                  <Select.Option value="3000">3000</Select.Option>
                </Select>
              </div>
              <div className="col-sm-4 h-100">
                <Select
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="Max ($)"
                  value={myContext.contextData.max_salary}
                >
                  <Select.Option value="5000">5000</Select.Option>
                  <Select.Option value="6000">6000</Select.Option>
                  <Select.Option value="7000">7000</Select.Option>
                  <Select.Option value="8000">8000</Select.Option>
                  <Select.Option value="9000">9000</Select.Option>
                </Select>
              </div>
              <div className="col-sm-4 h-100">
                <Select
                  className="Login--Input mb-2 mx-0 d-block w-100"
                  size="small"
                  placeholder="USD"
                  value={myContext.contextData.salary_currency}
                >
                  <Select.Option value="usd">USD</Select.Option>
                  <Select.Option value="gbp">GBP</Select.Option>
                  <Select.Option value="eur">EUR</Select.Option>
                  <Select.Option value="cad">CAD</Select.Option>
                  <Select.Option value="aud">AUD</Select.Option>
                </Select>
              </div>
              <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-2">
                Application Window:
              </p>
              <div className="col-sm-6 mb-2">
                {/* <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="Start date"
                  format="YYYY-MM-DD"
                  value={myContext.contextData.start_date}
                /> */}
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Division"
                  readOnly
                  value={myContext.contextData.start_date}
                />
              </div>
              <div className="col-sm-6 mb-2">
                {/* <DatePicker
                  className="Login__input w-100"
                  size="large"
                  placeholder="End date"
                  format="YYYY-MM-DD"
                  value={myContext.contextData.end_date}
                /> */}
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Division"
                  readOnly
                  value={myContext.contextData.end_date}
                />
              </div>
              <div className="col-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Company Details:
                </label>
                <p className="fm darkGrey mb-3 mt-0">
                  If you provided detailed background info of your company
                  during your onboarding process, this form will be pre-filled
                  for you else please provide a short description of your
                  company in the following form.
                </p>
                <textarea
                  className="form-control form-control-lg Dashboard__input mb-4 w-100"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  value={myContext.contextData.company_bio}
                />
              </div>

              <div className="col-xxl-12 col-md-12">
                <p className="fm darkGrey mt-0">
                  Tell us more about the role you are hiring for? What skills
                  are needed what day to day activities will be etc.
                </p>
                <label
                  htmlFor="text"
                  className="darkGrey fm mb-2 fw-semibold fs-6"
                >
                  Role Description:
                </label>
                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="About the role…"
                  id="text"
                  rows={6}
                  value={myContext.contextData.job_role_text}
                />
              </div>
              <div className="col-xxl-12 col-md-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Role Key Expectations:
                </label>
                <p className="fm darkGrey mb-2 mt-0">
                  What will the candidate expect to do in the role?
                </p>
                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  value={myContext.contextData.job_expectation}
                />
              </div>

              <div className="col-xxl-12 col-md-12 mt-4">
                <label htmlFor="text" className="darkGrey fm fw-semibold fs-6">
                  Your Company Benefits:
                </label>

                <textarea
                  className="form-control form-control-lg  mb-4"
                  placeholder="What we’re looking for…"
                  id="text"
                  rows={6}
                  value={myContext.contextData.company_benefits}
                />
              </div>

              <div className="create__indexCore__chart d-flex mt-4 flex-sm-row flex-column align-items-center">
                <div className="me-sm-4 me-0 mb-sm-0 mb-5 position-relative d-flex justify-content-center">
                  <div className="circular__text text-center darkGrey fm">
                    Please select your minimum IndexScore for this role:
                    <p className="fs-1 main mb-0 circular__text__num mt-3 fm">
                      {Math.round(value * 1000)}
                    </p>
                  </div>
                  <CircularInput
                    className="Indexcore__progress"
                    // value={Math.round(myContext.contextData.min_index_core * 1000)}
                    value={value}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange("min_index_core", Math.round(value * 1000));
                      setValue(value);
                      console.log("min_index_core", Math.round(value * 1000));
                    }}
                  >
                    <CircularTrack strokeWidth={8} stroke="#eee" />
                    <CircularThumb
                      r="16"
                      stroke="#A6DD50"
                      fill="#A6DD50"
                      strokeWidth={0}
                    />
                    <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                  </CircularInput>
                </div>
                <div className="me-sm-4 me-0 position-relative d-flex justify-content-center">
                  <div className="circular__text text-center darkGrey">
                    Please select the minimum Purpose Alignment % for this role:
                    <p className="fs-1 main mb-0 circular__text__num mt-3">
                      {Math.round(value2 * 100)}
                    </p>
                  </div>
                  <CircularInput
                    className="Indexcore__progress"
                    // value={myContext.contextData.min_culture_fit_score}
                    value={value2}
                    // value={Math.round(myContext.contextData.min_culture_fit_score * 1000)}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange(
                      //   "min_culture_fit_score",
                      //   Math.round(value2 * 1000)
                      // );
                      setValue2(value);

                      console.log(
                        "min_culture_fit_score",
                        Math.round(value2 * 1000)
                      );
                    }}
                  >
                    <CircularTrack strokeWidth={8} stroke="#eee" />
                    <CircularThumb
                      r="16"
                      stroke="#A6DD50"
                      fill="#A6DD50"
                      strokeWidth={0}
                    />
                    <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                  </CircularInput>
                </div>
              </div>

              <div className="col-xxl-12 col-md-10">
                <Space className="d-block">
                  <label
                    htmlFor="job"
                    className="darkGrey fm mb-2 fw-semibold fs-6"
                  >
                    Job Tags
                  </label>
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    // value={inputValue}
                    // onChange={handleInputChange}
                    // onBlur={handleInputConfirm}
                    // onPressEnter={handleInputConfirm}
                    className="w-100 tag__input form-control form-control-sm  mb-2"
                    id="job"
                  />
                  <Space className="d-flex">
                    {tags.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <div className="d-block">
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          </div>
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={index !== -1}
                          style={{
                            userSelect: "none",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== -1) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </Space>
                </Space>
              </div>

              {/* <div className="col-xxl-8 col-md-10 mt-4">
                <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-1">
                  Supporting documents:
                </p>
                <p className="fm darkGrey mb-4 mt-0">
                  Video must be up to 2 minutes in length and under 100MB.
                </p>

                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="document"
                  maxCount={1}
                  className="px-0"
                >
                  <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                    Upload your documents
                  </Button>
                  <small className="Get__right__upload__small ps-sm-3">
                    File formats: Word or PDF
                  </small>
                </Upload>
              </div> */}

              <div className="col-12 mb-3 mt-4">
                <p className="darkGrey fm mb-0 fw-semibold fs-6">
                  Multiple Candidates:
                </p>
                <p className="darkGrey fm mt-0">
                  Are you looking to hire multiple candidates for this job role?
                </p>
                <div className="d-flex flex-wrap">
                  <Checkbox className="fm darkGrey me-3 mb-2" defaultChecked>
                    Yes
                  </Checkbox>
                </div>
              </div>
              <div className="col-12 mb-3 mt-4">
                <hr />
              </div>
              <div className="mt-4">
                <p className="darkGrey fm mb-0 fw-semibold fs-6">
                  Potential Interviewers:
                </p>
                {/* <p className="fm darkGrey mb-1 mt-0">
                Please add additional members by clicking here
                </p> */}
                <div className="d-flex flex-md-nowrap flex-wrap">
                  <input
                    type="text"
                    value={firstNames.map((firstName) => {
                      return firstName;
                    })}
                    className="form-control form-control-md w-100  me-2 mt-2"
                  />
                  {/* <button className={`fm invite-button mt-2`}>
                    Invite People
                  </button> */}
                </div>
                {/* <div className="d-md-flex flex-wrap mt-2 align-items-center">
                  <Avatar.Group
                    maxCount={2}
                    maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    size="large"
                    className="me-2 mb-2"
                  >
                    <Tooltip title="Adu Opoki-Boahin" placement="top">
                      <Avatar />
                    </Tooltip>
                    <Tooltip title="Kaushik" placement="top">
                      <Avatar style={{ backgroundColor: "var(--darkGrey)" }} />
                    </Tooltip>
                    <Tooltip title="Ben" placement="top">
                      <Avatar style={{ backgroundColor: "var(--darkBlue)" }} />
                    </Tooltip>
                    <Tooltip title="Maryam" placement="top">
                      <Avatar style={{ backgroundColor: "var(--main)" }} />
                    </Tooltip>
                  </Avatar.Group>
                  <div className="mb-2">
                    <small className="fm midGrey">
                      Ben Smith, Stephen Holmes, Katie Lips, Maryam Rasheed &
                      Adu Opoki-Boahin
                    </small>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EConfirm;
