import React from "react";
import "./style.css";

const UHSection1 = () => {
  return (
    <div className="uhs1">
      <div className="container-xl px-0 uhs1__container">
        <div className="main-padding h-100 fm d-flex flex-column align-items-center overflow-hidden top-padding ">
          <h1 className="darkBlue fm fs-1 uhs1__heading text-center">
            Everything you need to hire in one IndexScore
          </h1>
          <p className="darkGrey fm uhs1__para text-center text-xxl-16">
            Find the right talent for your organization with the ease of a
            Credit Score, but this is so much more. IndexScore blends over 600
            data points across a candidate’s verified skills, experience,
            emotional intelligence, identity and more — for a Future of Work
            that’s efficient & unbiased.
          </p>

          <img
            src="/assets/UpdatedFrontend/Home/FrontImg1.png"
            alt=""
            className="uhs1--img mt-2"
          />
        </div>
      </div>

      {/* section2 */}

      <div className="uhs2 main-padding">
        <div className="container-xl px-0">
          <h1 className="fm darkGrey text-uppercase text-18 text-center mb-4">
            HELPING YOU STREAMLINE YOUR PROCESS
          </h1>
          <div className="row">
            <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
              <div className="uhs2__card1 uhs2__card h-100 position-relative rounded-3 overflow-hidden d-flex flex-column align-items-center justify-content-between">
                <img
                  src="/assets/UpdatedFrontend/Home/homecard1.png"
                  alt=""
                  className="uhs2__card--img"
                />
                <p className="text-14 uhs2__card1__text text-center darkBlue mb-0 mt-4">
                  Pooling candidates from all industries and levels below
                  C-suite
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
              <div className="uhs2__card2 uhs2__card h-100 position-relative rounded-3 overflow-hidden d-flex flex-column align-items-center justify-content-between">
                <img
                  src="/assets/UpdatedFrontend/Home/homecard2.svg"
                  alt=""
                  className="uhs2__card--img"
                />
                <p className="text-14 uhs2__card1__text text-center darkGrey mb-0 mt-4">
                  Developed by global strategic consultants focused on ESG
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
              <div className="uhs2__card3 position-relative h-100 rounded-3 overflow-hidden d-flex flex-column align-items-center justify-content-end">
                {/* <img src="" alt="" className="img-fluid" /> */}
                <p className="text-14 uhs2__card1__text text-center mb-0 uhs2__card3__text text-white">
                  600+ data points to form a single unbiased, IndexScore
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-4 col-sm-6 mb-3">
              <div className="uhs2__card2 uhs2__card h-100 position-relative rounded-3 overflow-hidden d-flex flex-column align-items-center justify-content-between">
                <img
                  src="/assets/UpdatedFrontend/Home/homecard4.svg"
                  alt=""
                  className="uhs2__card--img"
                />
                <p className="text-14 uhs2__card1__text text-center darkGrey mb-0 mt-4">
                  Strategic partners to CHROs, enablers to worldwide talent
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section3 */}
      <div className="uhs3 main-padding bg-white pt-5 fm">
        <div className="container-xl px-0">
          <div className="text-center">
            <p className="fm darkGrey text-14  rounded-pill uhs3__title mb-2">
              For enterprises
            </p>
            <h1 className="fs-1 darkBlue text-center fw-semibold mb-0">
              Find your shortlist of candidates
            </h1>
            <h1 className="fw-light darkBlue fs-1">
              95% faster & far more effectively
            </h1>
          </div>
          <div className="row pt-5">
            <div className="col-md-6 mb-md-0 mb-3">
              <div className="uhs3__card1 h-100 border rounded-3 pt-4 ps-lg-4 ps-3 d-flex flex-column justify-content-between">
                <div className=" pt-2 uhs3__card1__content pe-3">
                  <h1 className="fm text-white fs-4 fw-semibold">
                    Faster & more effective
                  </h1>
                  <p className="text-white fm fw-light text-xxl-16">
                    With wait times of 40+ days and spending in a millions on
                    recruitment related activities, we use data to cut things
                    down massively. Through pre-clearing candidates over an
                    intelligent, 600+ data point system, search for teh skills
                    you need -- and let an unbiased IndexScore bring to your
                    attention star talent.
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="/assets/UpdatedFrontend/Home/sec3_1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="uhs3__card2 h-100 border rounded-3 pt-4 ps-lg-4 ps-3 d-flex flex-column justify-content-between">
                <div className=" pt-2  pe-3">
                  <h1 className="fm text-white fs-4 fw-semibold mb-0">
                    The Era of Data-Driven Decision Making
                  </h1>
                  <h1 className="fm text-white fs-4 fw-light">
                    accelerating your due diligence
                  </h1>
                  <p className="text-white fm fw-light uhs3__card2__content text-xxl-16">
                    While resumes were a nice idea back in the 1930s-- in this
                    era of data, there's so much more you can be privy to before
                    forming your strategic hiring decision.{" "}
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src="/assets/UpdatedFrontend/Home/sec3_2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UHSection1;
