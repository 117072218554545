import React, { useState, useRef, useContext } from "react";
import "./style.css";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import SDetails from "./Details/SDetails";
import AboutRole from "./AboutRole/AboutRole";
import Role from "./Role/Role";
import IndexCore from "./Indexcore/IndexCore";
import EConfirm from "./Confirm/EConfirm";

import { Loader } from "rsuite";
import { useSnackbar } from "notistack";
import "rsuite/dist/rsuite.min.css";
import {
  MyContext,
  clearContextData,
} from "../../../Utilities/MyContextProvider";
import { useNavigate } from "react-router-dom";
import { createJob,draftJobs } from "../../../services/auth";

const NewJobContainer = () => {
  const [role, setRole] = useState(true);
  const [about, setAbout] = useState(false);
  const [indexcore, setIndexcore] = useState(false);
  const [details, setDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const myRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const myContext = useContext(MyContext);
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "false",
    loading: false,
    required: false,
  });
  const { loading, email, firstName, lastName } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  // createJob

  const createJobSubmit = async (event) => {
    try {
      event.preventDefault();
      handleChange("loading", true);

      const contextData = localStorage.getItem("contextData");

      const data = JSON.parse(contextData);
      delete data.team_members;

      localStorage.removeItem("contextData");

      const modifiedData = JSON.stringify(data);

      localStorage.setItem("contextData", modifiedData);
      let res;
      const dataFromContext = myContext.contextData;
      var jobDescription = {};
      jobDescription.job_title = dataFromContext.job_title;
      jobDescription.company_bio = dataFromContext.company_bio;
      jobDescription.job_expectation = dataFromContext.job_expectation;
      dataFromContext.JobDescription = jobDescription;

      if (userData?.role === "hr") {
        dataFromContext.team_members.push(userData);
      }
      

      res = await createJob(myContext.contextData);

      if (res.data.statusCode == 1) {
        localStorage.removeItem("contextData");
        clearContextData();
        handleChange("loading", false);
        enqueueSnackbar("Job Created Successfully!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        navigate(`/enterprise/dashboard/jobs`);
      }
    } catch (error) {
      enqueueSnackbar("Error creating job. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      handleChange("loading", false);
    }
  };

  const About = () => {
    setRole(false);
    setAbout(true);
    setIndexcore(false);
    setDetails(false);
    setConfirm(false);
    myRef.current.scrollTop = 0;
  };
  const Indexcore = () => {
    setRole(false);
    setAbout(false);
    setIndexcore(true);
    setDetails(false);
    setConfirm(false);
    myRef.current.scrollTop = 0;
  };
  const IndexcoreBack = () => {
    setRole(false);
    setAbout(true);
    setIndexcore(false);
  };
  const Details = () => {
    setRole(false);
    setAbout(false);
    setIndexcore(false);
    setDetails(true);
    setConfirm(false);
    myRef.current.scrollTop = 0;
  };
  const DetailsBack = () => {
    setRole(false);
    setAbout(false);
    setIndexcore(true);
    setDetails(false);
    setConfirm(false);
  };
  const Confirm = () => {
    setRole(false);
    setAbout(false);
    setIndexcore(false);
    setDetails(false);
    setConfirm(true);
    myRef.current.scrollTop = 0;
  };
  const Back = () => {
    setRole(true);
    setAbout(false);
    setIndexcore(false);
    setDetails(false);
    setConfirm(false);
  };
  const ConfirmBack = () => {
    setRole(false);
    setAbout(false);
    setIndexcore(false);
    setDetails(true);
    setConfirm(false);
  };

  const [roleData, setRoleData] = useState({});
  const [roleTagsData, setRoleTagsData] = useState([]);
  const [roleEmploymentType, setRoleEmploymentType] = useState([]);
  const [roleSelectedStartDate, setRoleSelectedStartDate] = useState(null);
  const [roleSelectedEndDate, setRoleSelectedEndDate] = useState(null);

  const [aboutRoleData, setAboutRoleData] = useState({});

  const [indexCoreData, setIndexCoreData] = useState({});
  const [indexCoreMembersData, setIndexCoreMembersData] = useState([]);

  const [supportDetailsDoc, setSupportDetailsDoc] = useState({});
  const [supportDetailsTagsData, setSupportDetailsTagsData] = useState([]);

  const handleRoleData = (data) => {
    setRoleData(data);
  };
  const handleTagsData = (data) => {
    setRoleTagsData(data);
  };
  const handleRoleEmploymentType = (data) => {
    setRoleEmploymentType(data);
  };
  const handleRoleSelectedStartDate = (data) => {
    setRoleSelectedStartDate(data);
  };
  const handleRoleSelectedEndDate = (data) => {
    setRoleSelectedEndDate(data);
  };

  const handleAboutRoleData = (data) => {
    setAboutRoleData(data);
  };

  const handleIndexCore = (data) => {
    setIndexCoreData(data);
  };
  const handleInvitedMembers = (data) => {
    setIndexCoreMembersData(data);
  };
  const handleJobTags = (data) => {
    setSupportDetailsTagsData(data);
  };
  const handleDocument = (data) => {
    setSupportDetailsDoc(data);
  };

  const handleNext = () => {
    if (validateRoleData()) {
      var payload = {
        job_title: roleData.job_title,
        company: roleData.company,
        division: roleData.division,
        min_salary: roleData.min_salary,
        max_salary: roleData.max_salary,
        salary_currency: roleData.salary_currency,
        company_bio: roleData.company_bio,
        job_locations: roleData.job_locations,
        department: roleData.department,
        employment_type: roleEmploymentType,
        start_date: roleSelectedStartDate,
        end_date: roleSelectedEndDate,
        manager: userData?.companyID,
        status: "Open",
      };
      myContext.updateContextData(payload);

      About();
    } else {
      alert("Please fill all required fields.");
    }
  };

  

  const handleNextSubmit = async (event) => {
    try {
      event.preventDefault();
      // handleChange("loading", true);

      const contextData = localStorage.getItem("contextData");

      const data = JSON.parse(contextData);
      delete data.team_members;

      localStorage.removeItem("contextData");

      const modifiedData = JSON.stringify(data);

      localStorage.setItem("contextData", modifiedData);
      let res;
      const dataFromContext = myContext.contextData;
      var jobDescription = {};
      jobDescription.job_title = dataFromContext.job_title;
      jobDescription.company_bio = dataFromContext.company_bio;
      jobDescription.job_expectation = dataFromContext.job_expectation;
      dataFromContext.JobDescription = jobDescription;
      dataFromContext.status= 'Draft';

      
      console.log("myContext.contextData",myContext.contextData);
      res = await draftJobs(myContext.contextData);

      if (res.data.statusCode == 1) {
        localStorage.removeItem("contextData");
        clearContextData();
        handleChange("loading", false);
        enqueueSnackbar("Job saved in draft!", {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        navigate(`/enterprise/dashboard/jobs`);
      }
    } catch (error) {
      enqueueSnackbar("Error creating job. Please try again.", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      // handleChange("loading", false);
    }
  };
  const handleAboutNext = () => {
    if (validateAboutRoleData()) {
      var payload = {
        job_role_text: aboutRoleData.job_role_text,
        job_expectation: aboutRoleData.job_expectation,
        company_benefits: aboutRoleData.company_benefits,
      };
      myContext.updateContextData(payload);

      Indexcore();
    } else {
      alert("Please fill all required fields.");
    }
  };
  const IndexCoreNext = () => {
    if (validateIndexCoreData()) {
      var payload = {
        min_index_core: indexCoreData.min_index_core,
        min_culture_fit_score: indexCoreData.min_culture_fit_score,
        team_members: indexCoreMembersData,
      };
      myContext.updateContextData(payload);

      Details();
    } else {
      alert("Please fill all required fields.");
    }
  };

  const supportDetailsConfirm = () => {
    if (validateSupportData()) {
      var payload = {
        job_sectors: supportDetailsTagsData,
        enterpriseID: userData?.companyID,
        enterpriseData: userData?.companyID,
        job_video_pitch: {},
        job_video_pitch_keywords: [
          "Saint Paul University",
          "experience",
          "education",
          "Bachelor of Science",
          "peace",
          "Justin Bieber",
          "credit",
          "hospitality",
          "promotion",
          "field",
          "Science",
          "TV",
          "beverage",
          "test",
          "servicing",
          "management",
          "bread",
          "assessment",
        ],
      };
      myContext.updateContextData(payload);

      Confirm();
    } else {
      alert("Please fill all required fields.");
    }
  };

  const handleSave = () => {
    if (validateRoleData()) {
      var payload = {
        job_title: roleData.job_title,
        company: roleData.company,
        division: roleData.division,
        min_salary: roleData.min_salary,
        max_salary: roleData.max_salary,
        salary_currency: roleData.salary_currency,
        company_bio: roleData.company_bio,
        job_locations: roleTagsData,
        department: roleData.department,
        employment_type: roleEmploymentType,
        start_date: roleSelectedStartDate,
        end_date: roleSelectedEndDate,
      };

      myContext.updateContextData(payload);
    } else {
      alert("Please fill all required fields.");
    }
  };
  const handleAboutSave = () => {
    if (validateAboutRoleData()) {
      var payload = {
        job_role_text: aboutRoleData.job_role_text,
        job_expectation: aboutRoleData.job_expectation,
        company_benefits:aboutRoleData.company_benefits,
      };
      myContext.updateContextData(payload);
    } else {
      alert("Please fill all required fields.");
    }
  };
  const IndexCoreSave = () => {
    if (validateIndexCoreData()) {
      var payload = {
        min_index_core: indexCoreData.min_index_core,
        min_culture_fit_score: indexCoreData.min_culture_fit_score,
        team_members: indexCoreMembersData,
      };
      myContext.updateContextData(payload);
    } else {
      alert("Please fill all required fields.");
    }
  };

  const supportDetailsSave = () => {
    if (validateSupportData()) {
      var payload = {
        job_sectors: supportDetailsTagsData,
      };
      myContext.updateContextData(payload);
    } else {
      alert("Please fill all required fields.");
    }
  };

  const validateRoleData = () => {
    if (
      !roleData.job_title ||
      !roleData.company ||
      !roleData.division ||
      !roleData.company_bio
    ) {
      return false;
    }
    return true;
  };

  const validateAboutRoleData = () => {
    if (!aboutRoleData.job_role_text || !aboutRoleData.job_expectation) {
      return false;
    }
    return true;
  };

  const validateIndexCoreData = () => {
    if (!indexCoreData.min_index_core || !indexCoreData.min_culture_fit_score) {
      return false;
    }
    return true;
  };

  const validateSupportData = () => {
    if (!supportDetailsTagsData) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="edash">
        <div className="pt-3">
          <div className="container-dash">
            <div className="DAccount">
              <div className=" NewJob__top pb-3">
                <div className="d-md-flex d-none NewJob__bar">
                  <div className="d-flex mt-3 Tab" onClick={Back}>
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: role ? "#1677ff" : "",
                        color: role ? "white" : "",
                        borderColor: role ? "#1677ff" : " ",
                        paddingBottom: "1px",
                      }}
                    >
                      1
                    </div>{" "}
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: role ? "var(--darkGrey)" : "" }}
                    >
                      Role <BsArrowRightShort className="fs-5" />
                    </small>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 Tab ms-2"
                    // onClick={About}
                  >
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: about ? "#1677ff" : "",
                        color: about ? "white" : "",
                        borderColor: about ? "#1677ff" : " ",
                        paddingBottom: "1px",
                      }}
                    >
                      2
                    </div>{" "}
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: about ? "var(--darkGrey)" : "" }}
                    >
                      About the role <BsArrowRightShort className="fs-5" />
                    </small>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 Tab ms-2"
                    // onClick={Indexcore}
                  >
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: indexcore ? "#1677ff" : "",
                        color: indexcore ? "white" : "",
                        borderColor: indexcore ? "#1677ff" : " ",
                        paddingBottom: "1px",
                      }}
                    >
                      3
                    </div>{" "}
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: indexcore ? "var(--darkGrey)" : "" }}
                    >
                      IndexScore <BsArrowRightShort className="fs-5" />
                    </small>
                  </div>
                  <div
                    className="d-flex align-items-center mt-3 Tab ms-2"
                    // onClick={Details}
                  >
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: details ? "#1677ff" : "",
                        color: details ? "white" : "",
                        borderColor: details ? "#1677ff" : " ",
                        paddingBottom: "1px",
                      }}
                    >
                      4
                    </div>{" "}
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: details ? "var(--darkGrey)" : "" }}
                    >
                      Supporting details <BsArrowRightShort className="fs-5" />
                    </small>
                  </div>
                  <div 
                  // onClick={Confirm}
                  className="d-flex align-items-center mt-3 Tab ms-2">
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: confirm ? "#1677ff" : "",
                        color: confirm ? "white" : "",
                        borderColor: confirm ? "#1677ff" : " ",
                        paddingBottom: "1px",
                      }}
                    >
                      5
                    </div>{" "}
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: confirm ? "var(--darkGrey)" : "" }}
                    >
                      Confirm
                    </small>
                  </div>
                </div>
                <div className="d-md-none d-flex justify-content-between mt-3 pe-2">
                  <div className="d-flex">
                    <div
                      className="circle d-flex justify-content-center align-items-center me-2"
                      style={{
                        background: role ? "#1677ff" : "",
                        color: role ? "white" : "",
                        borderColor: role ? "#1677ff" : " ",
                      }}
                      // onClick={Back}
                    >
                      1
                    </div>
                    <div
                      className="circle  justify-content-center align-items-center me-2"
                      style={{
                        background: about ? "#1677ff" : "",
                        color: about ? "white" : "",
                        borderColor: about ? "#1677ff" : " ",
                        display: role ? "none" : "flex",
                      }}
                      // onClick={About}
                    >
                      2
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: indexcore ? "#1677ff" : "",
                        color: indexcore ? "white" : "",
                        borderColor: indexcore ? "#1677ff" : " ",
                        display: role || about ? "none" : "flex",
                      }}
                      // onClick={Indexcore}
                    >
                      3
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: details ? "#1677ff" : "",
                        color: details ? "white" : "",
                        borderColor: details ? "#1677ff" : " ",
                        display: role || about || indexcore ? "none" : "flex",
                      }}
                      // onClick={Details}
                    >
                      4
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: confirm ? "#1677ff" : "",
                        color: confirm ? "white" : "",
                        borderColor: confirm ? "#1677ff" : " ",
                        display:
                          role || about || indexcore || details
                            ? "none"
                            : "flex",
                      }}
                      // onClick={Confirm}
                    >
                      5
                    </div>
                    <small
                      className="fw-semibold Tab__item text-capitalize"
                      style={{ color: "var(--darkGrey)" }}
                    >
                      {role ? "Role" : ""}
                      {about ? "About the role" : ""}
                      {indexcore ? "IndexScore" : ""}
                      {details ? "Supporting details" : ""}
                      {confirm ? "Confirm" : ""}
                    </small>
                  </div>
                  <div className="d-flex">
                    <div
                      className="circle  justify-content-center align-items-center me-2"
                      style={{
                        background: about ? "#1677ff" : "",
                        color: about ? "white" : "",
                        borderColor: about ? "#1677ff" : " ",
                        display: role ? "flex" : "none",
                      }}
                      // onClick={About}
                    >
                      2
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: indexcore ? "#1677ff" : "",
                        color: indexcore ? "white" : "",
                        borderColor: indexcore ? "#1677ff" : " ",
                        display: role || about ? "flex" : "none",
                      }}
                      // onClick={Indexcore}
                    >
                      3
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: details ? "#1677ff" : "",
                        color: details ? "white" : "",
                        borderColor: details ? "#1677ff" : " ",
                        display: role || about || indexcore ? "flex" : "none",
                      }}
                      // onClick={Details}
                    >
                      4
                    </div>
                    <div
                      className="circle justify-content-center align-items-center me-2"
                      style={{
                        background: confirm ? "#1677ff" : "",
                        color: confirm ? "white" : "",
                        borderColor: confirm ? "#1677ff" : " ",
                        display:
                          role || about || indexcore || details
                            ? "flex"
                            : "none",
                      }}
                      onClick={Confirm}
                    >
                      5
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" pe-0 " ref={myRef}>
              {role && (
                <div>
                  <Role
                    handleRoleData={handleRoleData}
                    handleTagsData={handleTagsData}
                    handleRoleEmploymentType={handleRoleEmploymentType}
                    handleRoleSelectedStartDate={handleRoleSelectedStartDate}
                    handleRoleSelectedEndDate={handleRoleSelectedEndDate}
                  />

                  <div className="Dashboard__container">
                    <div className="d-flex flex-wrap">
                      <button
                        className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {about && (
                <div>
                  <AboutRole handleAboutRoleData={handleAboutRoleData} />

                  <div className="Dashboard__container">
                    <div className="d-flex flex-wrap">
                      <button
                        className={`Get__left__content__para draft__button mb-3 me-sm-2`}
                        onClick={Back}
                      >
                        Back
                      </button>
                      {/* <button
                        className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2`}
                        onClick={handleNextSubmit}
                      >
                        Save draft
                      </button> */}

                      <button
                        className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
                        onClick={handleAboutNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {indexcore && (
                <div>
                  <IndexCore
                    handleIndexCore={handleIndexCore}
                    handleInvitedMembers={handleInvitedMembers}
                  />
                  <div className="Dashboard__container">
                    <div className="d-flex flex-wrap">
                      
                      <button
                        className={`Get__left__content__para draft__button mb-3 me-sm-2`}
                        onClick={IndexcoreBack}
                      >
                        Back
                      </button>
                      {/* <button
                        className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2`}
                        onClick={handleNextSubmit}
                      >
                        Save draft
                      </button> */}

                      <button
                        className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
                        // onClick={Details}
                        onClick={IndexCoreNext}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {details && (
                <div>
                  <SDetails
                    handleJobTags={handleJobTags}
                    handleDocument={handleDocument}
                  />

                  <div className="Dashboard__container">
                    <div className="d-flex flex-wrap mt-5">
                      
                      <button
                        className={`Get__left__content__para draft__button mb-3 me-sm-2`}
                        onClick={DetailsBack}
                      >
                        Back
                      </button>
                      {/* <button
                        className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2`}
                        onClick={handleNextSubmit}
                      >
                        Save draft
                      </button> */}

                      <button
                        className={`Get__left__content__para Get__right__button Get__right__button--blue mb-3`}
                        onClick={supportDetailsConfirm}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {confirm && (
                <div>
                  <EConfirm handleIndexCore={handleIndexCore} finalData={myContext.contextData} />
                  {/* <div className="row">
              <div className="col-xxl-4 col-md-6">
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="First Name"
                  id="sector1"
                  onChange={value =>
                    handleChange('firstName', value.target.value)
                  }
                />
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Last Name"
                  id="sector2"
                  onChange={value =>
                    handleChange('lastName', value.target.value)
                  }
                />
                <input
                  type="text"
                  className="form-control form-control-md w-100 Dashboard__input mb-2"
                  placeholder="Email"
                  id="sector3"
                  onChange={value =>
                    handleChange('email', value.target.value)
                  }
                />
              </div>
            </div> */}

                  <div className="Dashboard__container">
                    <div className="d-flex flex-wrap mt-sm-5">
                      
                      <button
                        className={`Get__left__content__para draft__button mb-3 me-sm-2`}
                        onClick={ConfirmBack}
                      >
                        Back
                      </button>
                      {/* <button
                        className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2`}
                      >
                        Save draft
                      </button> */}
                      <button
                        className={`Get__left__content__para draft__button darkGrey mb-3 me-sm-2`}
                        onClick={handleNextSubmit}
                      >
                        Save draft
                      </button>
                      <button
                        onClick={createJobSubmit}
                        className={`Get__left__content__para Get__right__button Get__right__button--main mb-3 d-flex justify-content-center align-items-center`}
                      >
                        {loading ? <Loader /> : "Publish"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewJobContainer;
