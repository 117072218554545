import Axios from 'axios';
import config from './apisConfig';
import instance from './index';



// const contentType = body instanceof FormData ?
//     'multipart/form-data' :
//     'application/json';

// const headers = {
//     'content-type': contentType,
//     'Access-Control-Allow-Origin': '*'
// };


// login
export const login = async (payload) => {
    try {

        const endpoint = config.auth.login;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const sendOTP = async (payload) => {
    try {

        const endpoint = config.auth.SendOtp;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const verifyOTP = async (payload) => {
    try {

        const endpoint = config.auth.VerifyOtp;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
// forgot password
export const forgotPasswordApi = async (payload) => {
    try {

        const endpoint = config.auth.forgotPassword;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const resetPasswordApi = async (payload) => {
    try {

        const endpoint = config.auth.resetPassword;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
// professional signup 
export const professionalSignup = async (payload) => {
    try {

        const endpoint = config.professionalSignup.register;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
// enterprise signup 
export const enterpiseSignup = async (payload) => {
    try {

        const endpoint = config.enterpriseSignup.register;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
// Upload Resume 
export const uploadFile = async (payload) => {
    try {

        const endpoint = config.fileUpload.fileUpload;
        const res = await Axios.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
// Upload Video
export const uploadVideo = async (payload) => {
    try {

        const endpoint = config.videoUpload.uploadVideo;
        const res = await Axios.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

// updateProfessional
export const updateProfessional = async (payload) => {
    try {

        const endpoint = config.updatePro.updatePro;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}



// updateEnterprise
export const updateEnterprise = async (payload) => {
    try {

        const endpoint = config.updateEnterprise.updateEnterprise;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

// get professionals
export const getProfessionals = async () => {
    try {

        const endpoint = config.getProfessionals.getProfessionals;
        const res = await instance.get(endpoint)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getProfessionalsList = async () => {
    try {

        const endpoint = config.getProfessionals.getProfessionalById;
        const res = await instance.post(endpoint)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// get professional by id
export const getProfessionalById = async (payload) => {
    try {

        const endpoint = config.getProfessionals.getProfessionalById;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// get Single Job by Id
export const getJobById = async (payload) => {
    try {

        const endpoint = config.getJobById.getJobById;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// get applications

export const getApplications = async (payload) => {
    try {

        const endpoint = config.applicants.getApplications;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const downloadDocument = async (payload) => {
    try {

        const endpoint = config.downloadURL.downloadURL;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}



export const getJobs = async () => {
    try {

        const endpoint = config.getJob.getJob;
        const res = await instance.post(endpoint)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const getInterviews = async (payload) => {
    try {

        const endpoint = config.getInterviews.getInterviewsProffesional;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getInterviewsbyMemberId = async (payload) => {
    try {

        const endpoint = config.getInterviews.getInterviewsbyMember;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

// getInterviewById

export const getInterviewById = async (payload) => {
    try {

        const endpoint = config.getInterviews.getInterview;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const acceptInterviewApi = async (payload) => {
    try {
        const endpoint = config.getInterviews.acceptInterviews;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}
export const declineInterview = async (payload) => {
    try {
        const endpoint = config.getInterviews.declineInterview;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}
// get Single Job by Id
export const getEnterpriseByID = async (payload) => {
    try {

        const endpoint = config.enterpriseByID.enterpriseByID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getEnterprises = async (payload) => {
    try {

        const endpoint = config.getEnterprises.getEnterprises;
        const res = await instance.get(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const applyJob = async (payload) => {
    try {

        const endpoint = config.proApplyJob.proApplyJob;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const createJob = async (payload) => {
    try {
        const endpoint = config.createJob.createJob;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const draftJobs = async (payload) => {
    try {
        const endpoint = config.createJob.draftJobs;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const deleteJob = async (payload) => {
    try {
        const endpoint = config.createJob.deleteJob;
        const res = await instance.post(endpoint, payload);
        return res.data; // Return the response data
    } catch (err) {
        console.log(err);
        return err;
    }
};


export const UpdateJob = async (jobData) => {
    try {
        const endpoint = config.createJob.updateJob;
        const res = await instance.post(endpoint, jobData);
        return res.data; // Return the response data
    } catch (err) {
        console.log(err);
        return err;
    }
};


// question parser api
export const questionParser = async (payload) => {
    try {

        const endpoint = config.questionParser.questionParser;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

// linkedinReferenceParser
export const linkedinReferenceParser = async (payload) => {
    try {

        const endpoint = config.linkedinReferenceParser.linkedinReferenceParser;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// linkedinReferenceParser
export const accurtateCreateCandidate = async (payload) => {
    try {

        const endpoint = config.accurtateCreateCandidate.createCandidate;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// linkedinReferenceParser
export const accurtateCreateOrder = async (payload) => {
    try {

        const endpoint = config.accurtateCreateOrder.createOrder;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

// feedback
export const updateFeedback = async (payload) => {
    try {

        const endpoint = config.feedback.updateFeedback;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


// compose email
export const composeEmail = async (payload) => {
    try {

        const endpoint = config.inboxModule.compose;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const uploadAttachments = async (payload) => {
    try {

        const endpoint = config.inboxModule.uploadAttachments;
        const res = await Axios.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}



// get Single Job by Id
export const getSentChats = async (payload) => {
    try {

        const endpoint = config.inboxModule.getSent;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getInboxChats = async (payload) => {
    try {

        const endpoint = config.inboxModule.getInbox;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getAppointments = async (payload) => {
    try {

        const endpoint = config.appointments.getAppointments;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getCandidatesAppointments = async (payload) => {
    try {

        const endpoint = config.appointments.getCandidatesAppointments;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const getClientAppointments = async (payload) => {
    try {

        const endpoint = config.appointments.getClientAppointments;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const createAppointment = async (payload) => {
    try {

        const endpoint = config.appointments.createAppointment;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const downloadAttachment = async (payload) => {
    try {

        const endpoint = config.downloadAttachment.downloadAttachment;
        const res = await Axios.post(endpoint, payload, {
            responseType: 'blob'
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const updateCandidateStatus = async (payload) => {
    try {

        const endpoint = config.updateCandidate.status;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const getRefById = async (payload) => {
    try {

        const endpoint = config.feedback.getRef;
        const res = await Axios.post(endpoint, payload);
        const data = res;

        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}



export const updateProfileStatus = async (payload) => {
    try {
        const endpoint = config.auth.verifyEmail;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const sendInvite = async (payload) => {
    try {
        const endpoint = config.sendInvite.sendInvitation;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}
export const sendCallInvite = async (payload) => {
    try {
        const endpoint = config.sendInvite.sendCallInvitation;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}



export const createComment = async (payload) => {
    try {
        const endpoint = config.comments.createComment;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const getComments = async (payload) => {
    try {
        const endpoint = config.comments.getComments;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const createReply = async (payload) => {
    try {
        const endpoint = config.comments.createReply;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const createContract = async (payload) => {
    try {
        const endpoint = config.contract.createContract;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const deleteContract = async (payload) => {
    try {
        const endpoint = config.contract.deleteContract;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const uploadOfferLetter = async (payload) => {
    try {

        const endpoint = config.contract.uploadOfferLetter;
        const res = await instance.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const getContracts = async (payload) => {
    try {
        const endpoint = config.contract.getContracts;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const acceptContract = async (payload) => {
    try {
        const endpoint = config.contract.acceptContract;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const updateApplicantsFeedback = async (payload) => {
    try {
        const endpoint = config.contract.updateApplicantsFeedback;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const downloadApplicantsFeedback = async (payload) => {
    try {
        const endpoint = config.contract.downloadApplicantsFeedback;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const createLandscape = async (payload) => {
    try {
        const endpoint = config.landscape.createLandscape;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getLandscapes = async (payload) => {
    try {
        const endpoint = config.landscape.getLandscapes;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getLandscapeById = async (payload) => {
    try {
        const endpoint = config.landscape.getLandscapeById;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getJobDescription = async (payload) => {
    try {
        const endpoint = config.createJob.getDescription;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const ImproveAIFeedback = async (payload) => {
    try {
        const endpoint = config.improveIndexScore.improveAi;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const improveGrammer = async (payload) => {
    try {
        const endpoint = config.improveIndexScore.improveGrammer;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const improveEmotions = async (payload) => {
    try {
        const endpoint = config.improveIndexScore.improveEmotions;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const improveVideo = async (payload) => {
    try {
        const endpoint = config.improveIndexScore.improveVideo;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const getContractsById = async (payload) => {
    try {
        const endpoint = config.contract.getContractsById;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const contactUs = async (payload) => {
    try {
        const endpoint = config.auth.contactUs;
        const res = await Axios.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}



export const UpdateSimpleProfessional = async (payload) => {
    try {
        const endpoint = config.professionalSignup.updateSimpleProfessional;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getNotifications = async (payload) => {
    try {
        const endpoint = config.notifications.getNotifications;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const uploadprofileImage = async (payload) => {
    try {

        const endpoint = config.auth.uploadprofileImage;
        const res = await instance.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}



export const CreateMemberrHandler = async (payload) => {
    try {
        const endpoint = config.auth.addMember;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}
export const deleteMember = async (payload) => {
    try {
        const endpoint = config.enterpriseTeamMembers.deleteMember;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const shareIndexScore = async (payload) => {
    try {
        const endpoint = config.share.shareIndexScore;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const updateMember = async (payload) => {
    try {
        const endpoint = config.enterpriseTeamMembers.updateMember;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const getMemberById = async (payload) => {
    try {
        const endpoint = config.enterpriseTeamMembers.getMemberById;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const getMembersByCompany = async (payload) => {
    try {
        const endpoint = config.enterpriseTeamMembers.getMembersByCompany;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


// 

export const updateInterviewsStatus = async (payload) => {
    try {
        const endpoint = config.getInterviews.updateInterviewsStatus;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const UpdateSimpleEnterprise = async (payload) => {
    try {
        const endpoint = config.enterpriseSignup.updateSimpleEnterprise;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getAllJobDetailsAndCandidateStats = async (payload) => {
    try {
        const endpoint = config.report.getAllJobDetailsAndCandidateStats;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const SavingCalculator = async (payload) => {
    try {
        const endpoint = config.savingCalculator.savingCalculator;
        const res = await Axios.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const CreateVisitorHandler = async (payload) => {
    try {
        const endpoint = config.CreateVisitorHandler.createVisitorHandler;
        const res = await Axios.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const AtsToken = async (payload) => {
    try {
        const endpoint = config.Ats.getAtsToken;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}


export const createAtsCandidates = async (payload) => {
    try {
        const endpoint = config.Ats.createAtsCandidatesData;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getAtsCandidates = async (payload) => {
    try {
        const endpoint = config.Ats.getAtsCandidates;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getProDashboard = async (payload) => {
    try {
        const endpoint = config.Dashboard.professionalDashboard;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}

export const getEnterpriseDashboard = async (payload) => {
    try {
        const endpoint = config.Dashboard.enterpriseDashboard;
        const res = await instance.post(endpoint, payload);
        const data = res
        return data
    } catch (err) {
        console.log(err)
        return err;

    }
}
export const getAllApplicants = async (payload) => {
    try {

        const endpoint = config.applicants.getAllApplications;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

//chat API's start
export const sendChat = async (payload) => {
    try {

        const endpoint = config.chat.sendChat;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const sendMessageToChat = async (payload) => {
    try {

        const endpoint = config.chat.sendMessageToChat;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getPrivateChat = async (payload) => {
    try {

        const endpoint = config.chat.getPrivateChat;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getManagerIdsByCandidateId = async (payload) => {
    try {

        const endpoint = config.chat.getManagerIdsByCandidateId;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChannelsByJobID = async (payload) => {
    try {

        const endpoint = config.chat.getChannelsByJobID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChannelsByManagerID = async (payload) => {
    try {

        const endpoint = config.chat.getChannelsByManagerID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const sendMessageTogroup = async (payload) => {
    try {

        const endpoint = config.chat.sendMessageTogroup;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChatsByManagerID = async (payload) => {
    try {

        const endpoint = config.chat.getChatsByManagerID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChatsByCandidateID = async (payload) => {
    try {

        const endpoint = config.chat.getChatsByCandidateID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getGroupChatMessages = async (payload) => {
    try {

        const endpoint = config.chat.getGroupChatMessages;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChatMessages = async (payload) => {
    try {

        const endpoint = config.chat.getChatMessages;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getChannelsByMemberID = async (payload) => {
    try {

        const endpoint = config.chat.getChannelsByMemberID;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
//chat API's end

export const getPaymentMethods = async (payload) => {
    try {

        const endpoint = config.stripe.getPaymentMethods;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const createEnterpriseSession = async (payload) => {
    try {

        const endpoint = config.stripe.createEnterpriseSession;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const getInterviewPrepById = async (payload) => {
    try {

        const endpoint = config.getInterviews.getInterviewPrepById;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const createtInterviewPrep = async (payload) => {
    try {

        const endpoint = config.getInterviews.createtInterviewPrep;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const showHideIndexscore = async (payload) => {
    try {

        const endpoint = config.updatePro.showHideIndexscore;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const requestScore = async (payload) => {
    try {

        const endpoint = config.Ats.requestScore;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getInterviewPrepByAppId = async (payload) => {
    try {

        const endpoint = config.getInterviews.getInterviewPrepByAppId;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const subscriptionListAll = async () => {
    try {

        const endpoint = config.payment.subscriptionListAll;
        const res = await Axios.post(endpoint)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const subscribeSubscription = async (payload) => {
    try {

        const endpoint = config.payment.subscribeSubscription;
        const res = await Axios.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const purchaseCourse = async (payload) => {
    try {

        const endpoint = config.payment.purchaseCourse;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const createCandidateSession = async (payload) => {
    try {

        const endpoint = config.stripe.createCandidateSession;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const subscriptionInvoices = async (payload) => {
    try {
        const endpoint = config.payment.subscriptionInvoices;
        const res = await Axios.post(endpoint, payload);        
        return res.data; // Return only the data part of the response
    } catch (err) {        
        throw err; // Rethrow the error to propagate it to the caller
    }
}
export const upgradeSubscription = async (payload) => {
    try {

        const endpoint = config.payment.subscriptionIdDetails;
        const res = await Axios.put(endpoint, payload);
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const candidateInvoices = async (payload) => {
    try {

        const endpoint = config.payment.candidateInvoices;
        const res = await Axios.get(endpoint, { params: payload });
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}


export const requestAtsScore = async (payload) => {
    try {

        const endpoint = config.Ats.requestAtsScore;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const kycVerifcation = async (payload) => {
    try {

        const endpoint = config.professionalSignup.kycVerifcation;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const updateJobStatus = async (payload) => {
    try {

        const endpoint = config.createJob.updateJobStatus;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const sendJobInvite = async (payload) => {
    try {

        const endpoint = config.createJob.sendJobInvite;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const authorizeLinkedinUser = async (payload) => {
    try {

        const endpoint = config.linkedinShare.authorizeLinkedinUser;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const getlinkedInUserId = async (payload) => {
    try {

        const endpoint = config.linkedinShare.getlinkedInUserId;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const createLinkedinPost = async (payload) => {
    try {

        const endpoint = config.linkedinShare.createLinkedinPost;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const uploadCompanyImage = async (payload) => {
    try {

        const endpoint = config.auth.uploadCompanyImage;
        const res = await instance.post(endpoint, payload, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const saveJob = async (payload) => {
    try {

        const endpoint = config.createJob.saveJob;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const getSavedJobs = async (payload) => {
    try {

        const endpoint = config.createJob.getSavedJobs;
        const res = await instance.post(endpoint, payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const generate2faSecret = async () => {
    try {

        const endpoint = config.twoFa.generate2faSecret;
        const res = await instance.post(endpoint)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}

export const verify2faSecret = async (payload) => {
    try {

        const endpoint = config.twoFa.verify2faSecret;
        const res = await instance.post(endpoint,payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}
export const instaAuthorize = async (payload) => {
    try {

        const endpoint = config.insta.insta;
        const res = await instance.post(endpoint,payload)
        const data = res
        return data

    } catch (err) {

        console.log(err)
        return err;

    }
}