import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { questionParser } from "../../../services/auth";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const proQuestions = [
  {
    id: 1,
    question: "What do you like doing outside of work and why?",
  },
  {
    id: 2,
    question: "What would a perfect role look like for you?",
  },
  {
    id: 3,
    question:
      "Which company would you most like to work for and how does their culture align with your values?",
  },
  {
    id: 4,
    question: "How would you describe the perfect team mate?",
  },
  {
    id: 5,
    question: "In five years, how will you have fulfilled your purpose?",
  },
];
const enterQuestions = [
  {
    id: 1,
    question: "What’s your corporate mission and how do you achieve it?",
  },
  {
    id: 2,
    question:
      "What opportunities are there for growth and how do you ensure diversity, equity and inclusion?",
  },
  {
    id: 3,
    question: "Who is the ideal candidate for your organization?",
  },
  {
    id: 4,
    question:
      "What does it take for an employee to succeed in your organization?",
  },
  {
    id: 5,
    question: "How do you define future of work?",
  },
];
const Open = (props) => {
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar()
  const myContext = useContext(MyContext);
  const myData = myContext;

  const [state, setState] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    required: false,
  });
  const { loading, required, question1, question2, question3, question4, question5 } =
    state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled = !question1 || !question2 || !question3 || !question4 || !question5;  
  const handleSignup = async () => {
    // alert();
    try {
      if (disabled) {
        handleChange("required", true);
        return;
      }

      handleChange("required", false);
      handleChange("loading", true);
      var payload = {
        openAnswers: {
          open_question_one: question1,
          open_question_two: question2,
          open_questions_three: question3,
          open_questions_four: question4,
          open_questions_five: question5,
        },
      };

      if (props.page == "enterprise") {        
        // navigate(`/enterprise/signup/video-introduction`);
        myContext.updateContextData(payload);
        payload._id = myContext.contextData._id;
        payload.usertype = "enterprise";
        console.log(
          "final payload question parser enterprise" + JSON.stringify(payload)
        );
        var res = await questionParser(payload);

        console.log(
          "response of question parser api enterprise" + JSON.stringify(res)
        );
        if (res.data.statusCode == 1) {
          handleChange("loading", false);

          navigate(`/enterprise/signup/video-introduction`);
        }
      } else {
        
        var proTempPayload = {
          employeePsychometricEval: {
            orderId: "OrderId12345",
            candidate: {
              first: "Michael",
              last: "Scott",
              email: "michael@dm.com",
              date: "2021-03-01T12:00:00.000Z",
              eventId: "AAA1111ZZZZ",
              testTakerId: "1122333",
            },
            scores: {
              CCATRawScore: 91,
              CCATPercentile: 93,
              CCATInvalidScore: 0,
              CCATAlternateForm: 0,
              CCATSpatialPercentile: 32,
              CCATVerbalPercentile: 0,
              CCATMathPercentile: 17,
              EPPPercentMatch: 51,
              EPPAchievement: 12,
              EPPMotivation: 21,
              EPPCompetitiveness: 12,
              EPPManagerial: 12,
              EPPAssertiveness: 31,
              EPPExtroversion: 12,
              EPPCooperativeness: 35,
              EPPPatience: 54,
              EPPSelfConfidence: 38,
              EPPConscientiousness: 1,
              EPPOpenness: 3,
              EPPStability: 7,
              EPPStressTolerance: 7,
              EPPInconsistency: 0,
              EPPInvalid: false,
              Accounting: 60,
              AdminAsst: 49,
              Analyst: 17,
              BankTeller: 60,
              Collections: 34,
              CustomerService: 42,
              FrontDesk: 50,
              Manager: 42,
              MedicalAsst: 50,
              Production: 42,
              Programmer: 51,
              Sales: 33,
            },
            reportUrl: "https://www.criteriacorp.com/link_to_report",
            candidateReportUrl: "https://www.criteriacorp.com/link_to_report",
            metAllScoreRanges: "Yes",
          },
        };
        myContext.updateContextData(payload);
        myContext.updateContextData(proTempPayload);
        payload._id = myContext.contextData._id;
        payload.usertype = "professional";
        console.log(
          "final payload question parser professional" + JSON.stringify(payload)
        );
        var res = await questionParser(payload);

        console.log(
          "response of question parser api professional" + JSON.stringify(res)
        );
        if (res.data.statusCode == 1) {
          handleChange("loading", false);
          navigate(`/professional/signup/video-introduction`);
        }
      }
    } catch (error) { }
  };

  return (
    <div className="Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Open Questions
          </h1>
          {props.page === "professional" && (
            <p className="Get__left__content__para Signup__para mb-5">
              We like to get to know our Index Corers! Please tell us a little
              more about yourself.
            </p>
          )}
          {props.page === "enterprise" && (
            <p className="Get__left__content__para Signup__para mb-5">
              Our candidates would love to know more your organization as well,
              in one statement per question, how would you answer the five
              questions presented below?
            </p>
          )}

          {/* <form> */}
          {props.page === "professional" && (
            <div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">1.</span> What do you like doing outside of work and why?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question1', value.target.value)
                    }
                  />
                  {required && !question1 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">2.</span> What would a perfect role look like for you?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question2', value.target.value)
                    }
                  />
                  {required && !question2 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">3.</span> Which company would you most like to work for and how does their culture align with your values?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question3', value.target.value)
                    }
                  />
                  {required && !question3 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">4.</span> How would you describe the perfect team mate?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question4', value.target.value)
                    }
                  />
                  {required && !question4 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">5.</span> In five years, how will you have fulfilled your purpose?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question5', value.target.value)
                    }
                  />
                  {required && !question5 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
            // proQuestions.map((item) => (
            // <div key={item.id}>
            //   <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
            //     <span className="me-1">{item.id}.</span> {item.question}
            //   </p>
            //   <div className="form-group">
            //     <textarea
            //       type="text"
            //       className="form-control-lg form-control"
            //       id="exampleInputEmail1"
            //       aria-describedby="question1"
            //       placeholder="Your message..."
            //       rows={6}
            //       onChange={(value) =>
            //         handleChange(`{question${item.id}}`, value.target.value)
            //       }
            //     />
            //     {required && !question1 && (
            //       <div>
            //         <small className="error__message">
            //           {" "}
            //           Answer is required!
            //         </small>
            //       </div>
            //     )}
            //   </div>
            // </div>

          )}

          {props.page === "enterprise" && (
            // enterQuestions.map((item) => (
            // <div key={item.id}>
            //   <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
            //     <span className="me-1">{item.id}.</span> {item.question}
            //   </p>
            //   <div className="form-group">
            //     <textarea
            //       type="text"
            //       className="form-control-lg form-control"
            //       id="exampleInputEmail1"
            //       aria-describedby="question1"
            //       placeholder="Your message..."
            //       rows={6}
            //       onChange={(value) =>
            //         handleChange(`{question${item.id}}`, value.target.value)
            //       }
            //     />
            //     {required && !question1 && (
            //       <div>
            //         <small className="error__message">
            //           {" "}
            //           Answer is required!
            //         </small>
            //       </div>
            //     )}
            //   </div>
            // </div>
            // )
            <div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">1.</span> What’s your corporate mission and how do you achieve it?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question1', value.target.value)
                    }
                  />
                  {required && !question1 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">2.</span> What opportunities are there for growth and how do you ensure diversity, equity and inclusion?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question2', value.target.value)
                    }
                  />
                  {required && !question2 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">3.</span> Who is the ideal candidate for your organization?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question3', value.target.value)
                    }
                  />
                  {required && !question3 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">4.</span> What does it take for an employee to succeed in your organization?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question4', value.target.value)
                    }
                  />
                  {required && !question4 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
              <div >
                <p className="Get__left__content__para Signup__para mb-2 mt-5 d-flex">
                  <span className="me-1">5.</span> How do you define future of work?
                </p>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control-lg form-control"
                    id="exampleInputEmail1"
                    aria-describedby="question1"
                    placeholder="Your message..."
                    rows={6}
                    onChange={(value) =>
                      handleChange('question5', value.target.value)
                    }
                  />
                  {required && !question5 && (
                    <div>
                      <small className="error__message">
                        {" "}
                        Answer is required!
                      </small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <button
            onClick={() => handleSignup()}
            type="submit"
            className={`Get__left__content__para Get__right__button Get__right__button--${props.color} mt-5`}
          >
            {loading ? <Loader /> : "Continue"}
          </button>
          {/* </Link> */}
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Open;
