import React,{useEffect} from "react";
import NavbarDemo from "../components/NavbarDemo/NavbarDemo.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import MobileSidebar from "../components/Sidebar/MobileSidebar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import PSection1 from "../Launch/Pricing/PSection1.jsx";
import PSection2 from "../Launch/Pricing/PSection2.jsx";
import PSection3 from "../Launch/Pricing/PSection3.jsx";
import PSection4 from "../Launch/Pricing/PSection4.jsx";
import Demo from "../Launch/Demo/Demo.jsx";
import UpdatedNav from "../UpdatedFrontend/Navbar/UpdatedNav.jsx";
// import PricingBanner from "../pageComponents/Pricing/Hero/PricingBanner.jsx";
// import PricingCards from "../pageComponents/Pricing/PricingCards/PricingCards.jsx";

const Pricing = () => {
  useEffect(() => {
    document.body.classList.add('pricing')
    return () => {
      document.body.classList.remove('pricing')
    }
  }, [])
  return (
    <>
      <NavbarDemo logo="MainColor" color="blue" page="pricing" />
      <UpdatedNav />
      <Sidebar />
      <MobileSidebar />
      <PSection1 />
      <PSection3 />
      <Demo />
      <PSection2 />
      {/* <PricingBanner />
      <PricingCards /> */}
      <Footer />
    </>
  );
};

export default Pricing;
