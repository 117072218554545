import React, { useState } from "react";
import { Select } from "antd";
import { languages } from "../../../../Utilities/Languages";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { FaHandsHelping, FaDesktop, FaRegFolder } from "react-icons/fa";
import { Zendesk } from '@rathpc/zendesk-react';
const zendeskKey = "c893a791-cd2c-49fe-8900-394ef2c31595";


const Support = () => {
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const [selectedCurrency, setSelectedCurrency] = useState("usd");

  const zendeskSettings = {
    contactForm: {
      suppress: true,
    },
    position: {
      horizontal: 'left',
      vertical: 'bottom',
    },
  };

  const initCallback = () => {
    console.log('Script loaded and ready!');
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const cards = [
    {
      id: 1,
      title: "Jobs",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
    {
      id: 2,
      title: "Interviews",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
    {
      id: 3,
      title: "Your IndexScore",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
    {
      id: 4,
      title: "Preparation Sheet",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
    {
      id: 5,
      title: "Offers",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
    {
      id: 6,
      title: "Kyc Verification",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas auctor ligula vitae libero laoreet aliquet.",
    },
  ];

  const faq = [
    {
      id: "one",
      question:
        "Can I connect IndexScore with my existing applicant tracking systems?",
      answer:
        "Yes, our flexible platform allows organizations to connect with existing ATS in minutes. We then automatically pull all your applicants and score them for you - going from hundreds of applicants to top 5-10 qualified and pre-cleared candidates helping you focus on what matters!",
    },
    {
      id: "two",
      question: "How is the IndexScore calculated?",
      answer:
        "We leverage over 600+ data points, including assessment, background check, reference check, certifications, and many more to ensure a comprehensive candidate view.",
    },
    {
      id: "three",
      question: "Can we post our job roles outside of IndexScore?",
      answer:
        "Yes, companies can post jobs within the IndexScore platform and or outside such as LinkedIn for bigger reach. And because we can integrate with your existing ATS, we can also pull all your applicants from different sources and score them for you.",
    },
  ];

  return (
    <div className="General enterprise__general  pb-5">
      <div className="uhs6 main-padding d-flex align-items-center mt-0">
        <div className="container-xl px-0 ">
          <h1 className="fm fs-1 text-center mx-auto main uhs6__heading">
            Looking for Support?
          </h1>
          <p className="text-white fm text-center mx-auto uhs6__para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
            auctor ligula vitae libero laoreet aliquet. Nullam vel elit eros.
            Sed tortor tortor, sagittis ut dignissim quis, condimentum vitae
            tortor.
          </p>
          <div className="d-flex justify-content-center">
            <Link className="text-decoration-none">
              <button className="btn--main-light text-dark mx-auto fw-semibold fm rounded-2 mt-4">
                Call Us at (123) 123 1234
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="uhs2 bg-white pb-0">
        <div className="main-padding">
          <div className="container-xl px-0">
            <h1 class="fs-1 darkBlue text-center text-center fw-semibold mb-0">
            We're here to help
            </h1>
            <div className="row py-5 px-3">
              {cards.map((item) => (
                <div className="col-lg-4 col-sm-6 px-0" key={item.id}>
                  <div
                    className={`uhs4__card${item.id} h-100 position-relative px-3 uhs4__card text-center d-flex flex-column align-items-center`}
                  >
                    <p className="blue fm text-center mb-0 text-capitalize">
                      {item.title}
                    </p>
                    <p className="fm darkGrey text-center uhs4__card__para">
                      {item.para}
                    </p>
                    <button className="btn--blue fm text-14 text-white py-2 rounded-2">
                      View
                    </button>
                  </div>
                </div>
              ))}
            </div>

             <h1 class="fs-1 darkBlue text-center text-center fw-semibold mb-0">
             Are you Candidate looking for help? 
            </h1>   
            <div className="row py-5 px-3">
              <video width="100%">
                <source
                  src={
                    "https://indexscore-assets.s3.eu-north-1.amazonaws.com/professionals/65c49bf63e31fc18ed7853ae/elevatorpitch/1707383981857.mp4"
                  }
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className="ps2 main-padding ">
        <div className="container-xl px-0 pb-5 pt-4">
          <h1 className="fm darkBlue fw-semibold fs-1">FAQ</h1>
          <p className="fm darkGrey mt-0 ps2__para">
            {" "}
            Below are are most frequently asked questions you may have about
            IndexScore. If you still can’t find the answer to what you are
            looking for below please feel free to contact us at{" "}
            <span className="italic">info@indexscore.com</span>.
          </p>
          <div class="accordion" id="accordion">
            {faq.map((item) => (
              <div class="accordion-item border-bottom" key={item.id}>
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed fm text-18 darkGrey fw-semibold pt-4 pb-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${item.id}`}
                    aria-expanded="false"
                    aria-controls={`#${item.id}`}
                  >
                    <p className="mb-0 me-4">{item.question}</p>
                  </button>
                </h2>
                <div
                  id={item.id}
                  class="accordion-collapse collapse text-16"
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent="#accordion"
                >
                  <div class="accordion-body fm text-18 darkGrey">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Zendesk zendeskKey={zendeskKey} zendeskSettings={zendeskSettings} initCallback={initCallback} defer />
    </div>
  );
};

export default Support;
