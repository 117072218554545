import React, { useEffect, useState, useContext } from "react";
import "./style.css";
import YDetail from "./YourDetail/YDetail";
import Profile from "./Profile/Profile";
import Plan from "./Plan/Plan";
import API from "./API/API";
import DashboardScreen from "../DashboardScreen/DashboardScreen";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";

const DAccount = (props) => {
  const { clearContextData, setUserData, getUserData } = useContext(MyContext);
  const userDataFromContext = getUserData();
  const userData = userDataFromContext && userDataFromContext[0];
  
  const navigate = useNavigate();
  const [overview, setOverview] = useState(true);
  const [profile, setProfile] = useState(false);
  const [plan, setPlan] = useState(false);
  const [api, setApi] = useState(false);

  const func_overview = () => {
    setOverview(true)
    setProfile(false)
    setPlan(false)
    setApi(false);
    navigate("/enterprise/dashboard");
  }
  const func_profile = () => {
    setOverview(false)
    setProfile(true)
    setPlan(false)
    setApi(false)
    navigate("/enterprise/dashboard/account");
  }
  const func_plan = () => {
    setOverview(false)
    setProfile(false)
    setPlan(true)
    setApi(false);
    navigate("/enterprise/dashboard/plan");
  }
  const func_api = () => {
    setOverview(false)
    setProfile(false)
    setPlan(false)
    setApi(true)
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const codeParameter = urlSearchParams.get("code");

    if (!codeParameter) {
      // The code parameter is not present in the URL
      if (props.name === "dashboard") {
        func_overview();
      } else if (props.name === "profile") {
        func_profile();
      }
      else if (props.name === "plan") {
        func_plan();
      }
    }
  }, [props.name]);



  return (
    <div className="DAccount">
      <div className="DAccount__top d-lg-block d-none mt-lg-0 mt-4">
        <div className="position-relative">
          <div className="DAccount__top2"></div>
        </div>
        <div className="container-dash py-2 ">
          <button className={`DAccount__btn me-sm-4 me-2 transition fm ${overview ? "fw-normal" : "fw-light"}`} onClick={func_overview}>Overview</button>
          {userData?.role === 'admin' && (
            <>
              <button className={`DAccount__btn me-sm-4 me-2 transition fm ${profile ? "fw-normal" : "fw-light"}`} onClick={func_profile}>Profile</button>
              <button className={`DAccount__btn me-sm-4 me-2 transition fm ${plan ? "fw-normal" : "fw-light"}`} onClick={func_plan}>Plan</button>
            </>
          )}

          {/* <button className={`DAccount__btn me-sm-4 me-2 transition fm ${api ? "fw-normal": "fw-light"}`} onClick={func_api}>API</button> */}
        </div>
      </div>
      <div className="container-dash">
        {overview && <DashboardScreen />}
        {/* {overview && <YDetail />} */}
        {profile && <YDetail />}
        {plan && <Plan />}
        {api && <API />}
      </div>
    </div>
  );
};

export default DAccount;
