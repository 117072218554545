import React from "react";
import {RxCross2} from "react-icons/rx"

const MeetingDetails = (props) => {

    const Profile = ()=>{
        props.confirm(false);
        props.profile(true)
        props.meeting(false)
        props.time(false)
        props.detail(false)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }
    const Confirm = ()=>{
        props.confirm(true);
        props.profile(false)
        props.meeting(false)
        props.time(false)
        props.detail(false)
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }
  return (
    <div className="Dashboard__container edash">
      <div className="container-lg pb-4 pt-5 meeting__container d-flex flex-column align-items-center justify-content-between">
        <div className="d-flex flex-column w-100 align-items-center text-center">
          <span className="meeting__container__step fm">
            Step <span className="fw-semibold">3/3</span>
          </span>
          <h5 className="fm darkGrey fw-light mt-2 fs-5 pb-3">Enter details</h5>
          <div className="meetingDetails">
          <div className="d-flex justify-content-between align-items-center w-100 pb-2 mb-2 meetingDetails__top">
            <div className="d-flex align-items-center flex-wrap">
                <label htmlFor="email" className="midGrey fm text-14 ">Email(s): </label>
                <span className="darkGrey text-12 email__tag py-1 ps-2 pe-1 ms-2 d-flex align-items-center">email@indexscore.com <RxCross2 className="ms-1 text-14 pointer" /></span>
                {/* <input type="email" placeholder="" className="meetingdetails--input px-2 ms-1 py-1 darkGrey" /> */}
            </div>
            <small className="fm darkGrey text-14 nowrap">+ Add guests</small>
          </div>
          <textarea name="" id="" rows={10} placeholder="Please share any information that will help for the meeting…" className="meetingdetails--textarea p-2 fm w-100 h-100"></textarea>
          </div>
        </div>

        <div className="d-flex flex-wrap mt-5 w-100 justify-content-center pt-sm-5">
              <button
                className={`Get__left__content__para draft__button darkGrey border-darkGrey mb-3 me-sm-2`}
                onClick={Profile}
              >
                Cancel
              </button>

              <button
                className={`Get__left__content__para Get__right__button Get__right__button--main mb-3`} 
                onClick={Confirm}>
                Schedule Meeting
              </button>
            </div>
      </div>
    </div>
  );
};

export default MeetingDetails;
