import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { createOrder } from "../../../services/criteria";
import { updateProfessional } from "../../../services/auth";
import { useSnackbar } from "notistack";
import { Loader, Uploader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const consentfields = [
  {
    id: 1,
    text: "I give consent for IndexScore to use the data I have provided above including data residing on third-party service providers’ platforms.",
  },
  {
    id: 2,
    text: "I give consent for IndexScore to seek references on my behalf.",
  },
  {
    id: 3,
    text: "I give consent for IndexScore to perform background checks as part of my application process.",
  },
  {
    id: 4,
    text: "I give consent for IndexScore to perform and use my psychometric tests results as part of the IndexScore process.",
  },
  {
    id: 5,
    text: "I give IndexScore permission to use data found about me on the web via third-party service providers as part of the IndexScore process.",
  },
  {
    id: 6,
    text: "I give consent for IndexScore to share my score and related reports produced to hiring managers and recruiters.",
  },
];

const Consent = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const myData = myContext;

  const [state, setState] = useState({
    consent1: false,
    consent2: false,
    consent3: false,
    consent4: false,
    consent5: false,
    consent6: false,
    required: false,
    loading: "",
  });
  const {
    loading,
    required,
    consent1,
    consent2,
    consent3,
    consent4,
    consent5,
    consent6,
  } = state;

  const handleChangeCheckbox = (event) => {
    const { name, value } = event.target;
    console.log("event.target" + event.target.name);
    console.log("event.target" + event.target.value);
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const isCheckboxChecked = (name) => state[name];

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (
      !isCheckboxChecked("consent1") ||
      !isCheckboxChecked("consent2") ||
      !isCheckboxChecked("consent3") ||
      !isCheckboxChecked("consent4") ||
      !isCheckboxChecked("consent5") ||
      !isCheckboxChecked("consent6")
    ) {
      setError("Please select all checkboxes");
    } else {
      // setError('');

      // if (props.page == 'enterprise') {

      handleChange("loading", true);
      var payload = {
        consent_data: {
          consent1: consent1,
          consent2: consent2,
          consent3: consent3,
          consent4: consent4,
          consent5: consent5,
          consent6: consent6,
        },
        personaVerification: true,
      };
      myContext.updateContextData(payload);

      let res;

      // if (props.title == "ENTERPRISE") {it
      // payload.usertype = 'enterprise';
      console.log("final payload" + JSON.stringify(myContext.contextData));
      res = await updateProfessional(myContext.contextData);
      // debugger
      console.log("response professional" + res.data.statusCode);
      console.log("final payload" + JSON.stringify(res.data.data));
      if (res.data.statusCode == 1) {
        const data = {
          packageId: { value: "JB-T6AVVPFQL" },
          orderId: { value: "OrderId12345" },
          candidate: {
            first: myContext.contextData.firstName,
            last: myContext.contextData.lastName,
            email: myContext.contextData.emailAddress,
          },
          sendCandidateEmail: true,
          returnURL: {
            uri: "https://www.criteriacorp.com",
          },
        };
        
        createOrder(data).then((response) => {
          console.log("test sent", response);
          handleChange("loading", false);
          enqueueSnackbar("Test sent to Your email Successfully!", {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        });

        // localStorage.removeItem(myContext.contextData);
        localStorage.removeItem("contextData");
        handleChange("loading", false);
        navigate(`/professional/signup/submit`);

        enqueueSnackbar(`Professional Created Successfully!`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }

      // }

      // }
      // else {
      //   navigate(`/professional/signup/video-introduction`);
      //   myContext.updateContextData(state);
      // }
      // submit form
    }
  };

  const [error, setError] = useState("");

  // const handleSignup = async () => {
  //   // alert();
  //   try {
  //     if (disabled) {

  //       handleChange('required', true)
  //       return
  //     }

  //     handleChange('required', false)

  //     if (props.page == 'enterprise') {
  //       navigate(`/enterprise/signup/video-introduction`);
  //       myContext.updateContextData(state);
  //     }
  //     else {
  //       navigate(`/professional/signup/video-introduction`);
  //       myContext.updateContextData(state);
  //     }

  //   } catch (error) {

  //   }
  // }

  return (
    <div className="Extra Signup main-padding">
      <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-3">
        <div className="Signup__right--width">
          <h1 className="Get__left__content__heading fw-light mt-lg-5 mb-lg-5 mb-3">
            Almost there!
          </h1>
          <p className="Get__left__content__para Get__right__para fw-semibold">
            Consent:
          </p>
          <form>
            {consentfields.map((item)=>(
              <div className="form-check mt-4" key={item.id}>
              <input
                className="form-check-input"
                value="I give consent for IndexScore to use the data I have provided
                above including data that resides on third-party sites that I
                have connected"
                type="checkbox"
                checked={state.checkbox1}
                onChange={handleChangeCheckbox}
                name={`consent${item.id}`}
                id={`gridCheck${item.id}`}
              />
              <label
                className="form-check-label Get__left__content__para Signup__para  ms-2"
                htmlFor="gridCheck1"
              >
               {item.text}*
              </label>
            </div>
            ))}
            <br />
            {error && (
              <p>
                <small className="error__message"> {error}</small>
              </p>
            )}
            <button
              onClick={handleFormSubmit}
              type="button"
              className={`Get__left__content__para Get__right__button Get__right__button--blue mt-5`}
            >
              {loading ? <Loader /> : "Submit"}
            </button>
            {/* </Link> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Consent;
