import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Avatar,
  Input,
  Space,
  Tag,
  Tooltip,
  Checkbox,
  Select,
  DatePicker,
  Upload,
  Button,
  Radio
} from "antd";
import "./style.css";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";
import moment from "moment";
import { Country } from "../../../Utilities/Country.js";
import { companies } from "../../../Utilities/Companies.js";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  getJobById,
  UpdateJob,
  getMembersByCompany,
} from "../../../services/auth";
import { useSnackbar } from "notistack";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";

// import { getPackages } from "../../../../services/criteria";
// import { MyContext } from '../../../../Utilities/MyContextProvider';

const EditJob = () => {
  // const myContext = useContext(MyContext);
  // const myData = myContext;
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();
  const [myObjectData, setMyObjectData] = useState([]);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      console.log("id parameter" + id);
      var payload = {
        _id: id,
      };
      const response = await getJobById(payload);
      const myObject = response.data.data.find((obj) => obj._id === id);
      setMyObjectData(myObject);
    
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (myObjectData) {
      setState((prevState) => ({
        ...prevState,
        job_title: myObjectData.job_title,
        job_locations: myObjectData.job_locations,
        company: myObjectData.company,
        division: myObjectData.division,
        department: myObjectData.department,
        min_salary: myObjectData.min_salary,
        max_salary: myObjectData.max_salary,
        salary_currency: myObjectData.salary_currency,
        company_bio: myObjectData.company_bio,
        job_expectation: myObjectData.job_expectation,
        company_benefits: myObjectData.company_benefits,
        employmentType: myObjectData.employment_type,
        start_date: myObjectData.start_date,
        end_date: myObjectData.end_date,
        job_role_text: myObjectData.job_role_text,
        min_index_core: myObjectData.min_index_core,
        min_culture_fit_score: myObjectData.min_culture_fit_score,
        job_sectors: myObjectData.job_sectors,
        team_members:
          myObjectData.team_members &&
          myObjectData.team_members
            .filter(
              (professional) =>
                professional.firstName !== null &&
                professional.firstName.trim() !== " "
            )
            .map((professional) => ({
              label: professional.firstName,
              value: professional._id,
            })),
      }));

      setValue(myObjectData.min_index_core / 850);
      setValue2(myObjectData.min_culture_fit_score / 100);
      console.log("team_members", team_members);
    }
  }, [myObjectData]);

  const [state, setState] = useState({
    job_title: "",
    company: "",
    division: "",
    department: "",
    min_salary: "",
    max_salary: "",
    salary_currency: "",
    company_bio: "",
    job_expectation: "",
    employmentType: [],
    start_date: "",
    end_date: "",
    job_role_text: "",
    company_benefits: "",
    min_index_core: "",
    min_culture_fit_score: "",
    job_sectors: [],
    team_members: [],
  });
  const {
    job_title,
    company,
    division,
    department,
    min_salary,
    max_salary,
    salary_currency,
    company_bio,
    job_locations,
    employmentType,
    start_date,
    end_date,
    job_expectation,
    job_role_text,
    company_benefits,
    min_index_core,
    min_culture_fit_score,
    job_sectors,
    team_members,
  } = state;

  const disabled =
    !job_title ||
    !company ||
    !division ||
    !department ||
    !min_salary ||
    !max_salary ||
    !salary_currency ||
    !company_bio ||
    !job_locations ||
    employmentType.length === 0 ||
    !start_date ||
    !end_date ||
    !job_expectation ||
    !job_role_text ||
    !company_benefits ||
    job_sectors.length === 0 ||
    team_members.length === 0;
  const { Option, OptGroup } = Select;

  const [tags, setTags] = useState(["job"]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const [jobPackages, setJobPackages] = useState([]);
  const [value, setValue] = useState(100 / 850);
  const [value2, setValue2] = useState(10 / 100);
  // const [employmentType, setEmploymentType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [options, setOptions] = useState([]);
  const [dataInvited, setDataInvited] = useState([]);
  const [editJobLoader, setEditJobLoader] = useState(false);
  const countryName = null;
  // Country.find(country => country.code === myContext.contextData.job_locations)?.name;
  const handleDateChange = (newDate) => {
    // Handle the date change, e.g., update the state or make an API call to save it
    console.log("New Date:", newDate.format("YYYY-MM-DD"));
  };

  console.log("id", id);
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const firstNames = "test";
  // myContext.contextData.team_members.map(user => user.firstName);
  const handleGoBack = () => {
    // Use the navigate function to go back to the previous page.
    navigate(-1); // This is equivalent to clicking the browser's back button.
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const myId = localStorage.getItem("enterpriseID");
      const payloadGetMember = {
        enterpriseID: myId,
      };

      const response = await getMembersByCompany(payloadGetMember);
      setEnterpriseList(response.data.data);

      const newOptions = response.data.data
        .filter(
          (professional) =>
            professional._id !== myId &&
            professional.firstName !== null &&
            professional.firstName.trim() !== " "
        )
        .map((professional) => ({
          label: professional.firstName,
          value: professional._id,
        }));
      console.log("newOptions", newOptions);
      setOptions(newOptions);
    } catch (error) {
      console.log(error);
    }

    console.log("enterprises data----------------------------------", options);
  };

  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    if (editInputValue) {
      setState((prevState) => ({
        ...prevState,
        job_sectors: prevState.job_sectors.map((tag, index) =>
          index === editInputIndex ? editInputValue : tag
        ),
      }));
    }
    setEditInputIndex(-1);
    setInputValue("");
  };

  const handleInputConfirm = () => {
    if (inputValue && !state.job_sectors.includes(inputValue)) {
      setState((prevState) => ({
        ...prevState,
        job_sectors: [...prevState.job_sectors, inputValue],
      }));
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setState((prevState) => ({
        ...prevState,
        employmentType: [...prevState.employmentType, value],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        employmentType: prevState.employmentType.filter(
          (item) => item !== value
        ),
      }));
    }
  };

  // useEffect(() => {
  //   getPackages().then((data) => {
  //     setJobPackages(data.data);
  //     // debugger
  //   });
  // }, []);

  // useEffect(() => {
  //   if (inputVisible) {
  //     inputRef.current?.focus();
  //   }
  // }, [inputVisible]);
  // useEffect(() => {
  //   editInputRef.current?.focus();
  // }, [inputValue]);
  const handleClose = (removedTag) => {
    // Create a new array of tags without the tag being closed
    const updatedTags = job_sectors.filter((tag) => tag !== removedTag);
    // Use your actual state updater function to update the state
    setState((prevState) => ({
      ...prevState,
      job_sectors: updatedTags,
    }));
  };

  // const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  // const handleEditInputChange = (e) => {
  //   setEditInputValue(e.target.value);
  // };
  // const handleEditInputConfirm = () => {
  //   const newTags = [...tags];
  //   newTags[editInputIndex] = editInputValue;
  //   setTags(newTags);
  //   setEditInputIndex(-1);
  //   setInputValue("");
  // };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleTeamMembersChange = (newTeamMembers) => {
    setState((prevState) => ({
      ...prevState,
      team_members: newTeamMembers,
    }));
  };

  // const handleDropChange = (value) => {

  //   const selectedObject = enterpriseList.find(obj => obj._id === value);
  //   debugger
  //   if (selectedObject) {
  //     // If the object exists, copy it to the dataInvited array
  //     setDataInvited([...dataInvited, selectedObject]);
  //     console.log("dataInvited" + [...dataInvited, selectedObject]);
  //   }
  // }

  // const handleRemoveDrop = (value) => {
  //   const indexToRemove = dataInvited.findIndex(obj => obj._id === value);
  //   debugger
  //   if (indexToRemove !== -1) {
  //     // If the object exists, remove it from the dataInvited array
  //     const updatedDataInvited = [...dataInvited];
  //     updatedDataInvited.splice(indexToRemove, 1);
  //     setDataInvited(updatedDataInvited);

  //   }
  // }

  const handleDropChange = (value) => {
    const selectedObject = options.find((obj) => obj.value === value);
    if (selectedObject) {
      const updatedTeamMembers = [...team_members, selectedObject];
      handleTeamMembersChange(updatedTeamMembers);
    }
  };

  const handleRemoveDrop = (value) => {
    const indexToRemove = team_members.findIndex((obj) => obj.value === value);
    if (indexToRemove !== -1) {
      const updatedTeamMembers = [...team_members];
      updatedTeamMembers.splice(indexToRemove, 1);
      handleTeamMembersChange(updatedTeamMembers);
    }
  };

  const tagInputStyle = {
    width: 78,
    verticalAlign: "top",
  };

  const updateJobHandler = async () => {
    setEditJobLoader(true);

    const matchedData = team_members.map((itemToMatch) => {
      const matchedObject = enterpriseList.find(
        (obj) => obj._id === itemToMatch.value
      );
      if (matchedObject) {
        return matchedObject;
      }
      return null; // If no match is found, you can handle it accordingly
    });

    // console.log("matchedData", matchedData);

    const jobData = {
      jobId: id,
      job_title,
      company,
      division,
      department,
      min_salary,
      max_salary,
      salary_currency,
      job_locations,
      employment_type: employmentType,
      start_date,
      end_date,
      job_role_text,
      min_index_core: Math.round(value * 850),
      min_culture_fit_score: Math.round(value2 * 100),
      company_benefits:company_benefits,
      job_sectors,
      job_expectation,
      company_bio,
      JobDescription: {
        job_title:state.job_title,
        company_bio:state.company_bio,
        job_expectation:state.job_expectation,
      },
      team_members: matchedData,
    };
    
    const responseUpdateJob = await UpdateJob(jobData);
    if (responseUpdateJob.statusCode == 1) {
      setEditJobLoader(false);
      
      enqueueSnackbar("Updated Successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      navigate("/enterprise/dashboard/jobs");
    }
  };

  return (
    <>
      <style>
        {`
        .back-link,.back-link:hover{
          text-decoration: none;
          color: #212529;
          font-size: 14px;          
          display: block;
        }
        .ant-select-selector {
          max-width: unset !important;
        }
      `}
      </style>
      <div className="edash edash2">
        <div className="pt-4 container-dash editJob">
          <div className="Role pb-5 General">
            <div className="px-0">
              <div className="row">
                <div className="col-xxl-8 col-md-10">
                  <div className="col-md-12">
                    <span className="mx-1 fm">
                      {" "}
                      <Link className="back-link" onClick={handleGoBack}>
                        {" "}
                        {"<"} Back
                      </Link>
                    </span>{" "}
                  </div>
                  <h3 className=" fm mb-5">Modify the job details</h3>
                </div>
                <div className="col-12">
                  <label
                    htmlFor="sector1"
                    className="darkGrey fm mb-2 fw-semibold fs-6"
                  >
                    Full job description
                  </label>
                  <p className="darkGrey fm">
                    One final check before we review and post your job.
                  </p>
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Job title"
                    value={job_title}
                    onChange={(value) =>
                      handleChange("job_title", value.target.value)
                    }
                    id="sector1"
                  />
                </div>
                <div className="col-12 mb-4">
                  <input
                    type="hidden"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Job Location"
                    value="United States"
                    // value={myContext.contextData.job_locations}
                    id="sector1"
                  />
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2 new--select  mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="Job location"
                    value={job_locations} // Set the initial selected value here
                    onChange={(value) => {
                      handleChange("job_locations", value);
                    }}
                  >
                    <OptGroup>
                      {Country.map((item) => (
                        <Option
                          value={item.code} // Set the value to item.code
                          label={item.name.toLowerCase()}
                          key={item.code}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select>

                  {/* <Space className="d-block">
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    // value={inputValue}
                    // onChange={handleInputChange}
                    // onBlur={handleInputConfirm}
                    // onPressEnter={handleInputConfirm}
                    className="w-100 tag__input form-control form-control-md Dashboard__input mb-2"
                    id="job"
                    placeholder="Locations"
                  />
                  <Space className="d-flex">
                    {tags.map((tag, index) => {
                      if (editInputIndex === index) {
                        return (
                          <div className="d-block">
                            <Input
                              ref={editInputRef}
                              key={tag}
                              size="small"
                              style={tagInputStyle}
                              value={editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          </div>
                        );
                      }
                      const isLongTag = tag.length > 20;
                      const tagElem = (
                        <Tag
                          key={tag}
                          closable={index !== -1}
                          style={{
                            userSelect: "none",
                          }}
                          onClose={() => handleClose(tag)}
                        >
                          <span
                            onDoubleClick={(e) => {
                              if (index !== -1) {
                                setEditInputIndex(index);
                                setEditInputValue(tag);
                                e.preventDefault();
                              }
                            }}
                          >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                          </span>
                        </Tag>
                      );
                      return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                          {tagElem}
                        </Tooltip>
                      ) : (
                        tagElem
                      );
                    })}
                  </Space>
                </Space> */}
                </div>
                <div className="col-sm-4 pe-sm-0">
                  {/* <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.name ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.name ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.name ?? "").toLowerCase())
                    }
                    className="Login--Input Login--select2  mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="Company"
                    value={company}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange('govtIdCountryName', value);
                      handleChange("company", value);
                    }}
                  >
                    <OptGroup>
                      {companies.map((item) => (
                        <Option
                          value={item.name}
                          label={item.name.toLowerCase()}
                          key={item.name}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </OptGroup>
                  </Select> */}
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Company Name"
                    value={company}
                    id="sector1"
                  />
                </div>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Division"
                    value={division}
                    onChange={(value) =>
                      handleChange("division", value.target.value)
                    }
                    // value={myContext.contextData.division}
                  />
                </div>
                <div className="col-sm-4">
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Department"
                    value={department}
                    onChange={(value) =>
                      handleChange("department", value.target.value)
                    }
                    id="sector1"
                  />
                </div>
                <div className="col-12 mt-4">
                  <p className="darkGrey fm mb-0 fw-semibold fs-6">
                    Employment type:
                  </p>
                  <p className="darkGrey fm">Choose one or multiple options</p>
                  {employmentType && (
                    <div className="d-flex flex-wrap">
                      <Checkbox
                        checked={employmentType.includes("Full time")}
                        value="Full time"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                        className="fm darkGrey me-3 mb-2 fs-6"
                      >
                        Full time
                      </Checkbox>
                      <Checkbox
                        checked={employmentType.includes("Part time")}
                        value="Part time"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                        className="fm darkGrey me-3 mb-2 fs-6"
                      >
                        Part time
                      </Checkbox>
                      <Checkbox
                        className="fm darkGrey me-3 mb-2 fs-6"
                        checked={employmentType.includes("Hybrid")}
                        value="Hybrid"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                      >
                        Hybrid
                      </Checkbox>
                      <Checkbox
                        className="fm darkGrey me-3 mb-2 fs-6"
                        checked={employmentType.includes("Remote")}
                        value="Remote"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                      >
                        Remote
                      </Checkbox>
                      <Checkbox
                        className="fm darkGrey me-3 mb-2 fs-6"
                        checked={employmentType.includes("Freelance")}
                        value="Freelance"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                      >
                        Freelance
                      </Checkbox>
                      <Checkbox
                        className="fm darkGrey mb-3 fs-6"
                        checked={employmentType.includes("Temporary")}
                        value="Temporary"
                        name="employmentType"
                        onChange={handleCheckboxChange}
                      >
                        Temporary
                      </Checkbox>
                    </div>
                  )}
                </div>
                <p className="darkGrey fm mb-0 fw-semibold fs-6 mb-2 mt-4">
                  Compensation Range:
                </p>
                <div className="col-sm-4 h-100">
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Min ($)"
                    value={min_salary}
                    onChange={(value) =>
                      handleChange("min_salary", value.target.value)
                    }
                    // value={myContext.contextData.division}
                  />
                  {/* <Select
                    className="Login--Input mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="Min ($)"
                    value={min_salary}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange('govtIdCountryName', value);
                      handleChange("min_salary", value);
                    }}
                  // value={myContext.contextData.min_salary}
                  >
                    <Option value="1000">1000</Option>
                    <Option value="5000">5000</Option>
                    <Option value="10000">10,000</Option>
                    <Option value="20000">20,000</Option>
                    <Option value="50000">50,000</Option>
                    <Option value="80000">80,000</Option>
                    <Option value="120000">120,000</Option>
                    <Option value="150000">150,000</Option>
                    <Option value="200000">200,000</Option>
                  </Select> */}
                </div>
                <div className="col-sm-4 h-100">
                  <input
                    type="text"
                    className="form-control form-control-md w-100 Dashboard__input mb-2"
                    placeholder="Max ($)"
                    value={max_salary}
                    onChange={(value) =>
                      handleChange("max_salary", value.target.value)
                    }
                    // value={myContext.contextData.division}
                  />
                  {/* <Select
                    className="Login--Input mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="Max ($)"
                    value={max_salary}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange('govtIdCountryName', value);
                      handleChange("max_salary", value);
                    }}
                  // value={myContext.contextData.max_salary}
                  >
                    <Option value="5000">5000</Option>
                    <Option value="10000">10,000</Option>
                    <Option value="20000">20,000</Option>
                    <Option value="50000">50,000</Option>
                    <Option value="80000">80,000</Option>
                    <Option value="120000">120,000</Option>
                    <Option value="150000">150,000</Option>
                    <Option value="200000">200,000</Option>
                    <Option value="350000">350,000</Option>
                  </Select> */}
                </div>
                <div className="col-sm-4 h-100">
                  <Select
                    className="Login--Input mb-2 mx-0 d-block w-100"
                    size="small"
                    placeholder="USD"
                    value={salary_currency}
                    onChange={(value) => {
                      // alert(value)
                      // handleChange('govtIdCountryName', value);
                      handleChange("salary_currency", value);
                    }}
                    // value={myContext.contextData.salary_currency}
                  >
                    <Select.Option value="usd">USD</Select.Option>
                    <Select.Option value="gbp">GBP</Select.Option>
                    <Select.Option value="eur">EUR</Select.Option>
                    <Select.Option value="cad">CAD</Select.Option>
                    <Select.Option value="aud">AUD</Select.Option>
                  </Select>
                </div>
                <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-2">
                  Application Window:
                </p>
                <div className="col-sm-6 mb-2">                 
                  <input
                    type="date"
                    className="form-control form-control-md w-100 Dashboard__input mb-1"
                    placeholder="Start date"
                    value={start_date}
                    id="sector1"
                    onChange={(value) => {
                      console.log("date", value.target.value);
                      handleChange("start_date", value.target.value);
                    }}
                  />
                 
                </div>
                <div className="col-sm-6 mb-2">
                  <input
                    type="date"
                    className="form-control form-control-md w-100 Dashboard__input mb-1"
                    placeholder="Start date"
                    value={end_date}
                    id="sector1"
                    onChange={(value) => {                      
                      handleChange("end_date", value.target.value);
                    }}
                  />                  
                </div>
                <div className="col-12 mt-4">
                  <label
                    htmlFor="text"
                    className="darkGrey fm fw-semibold fs-6"
                  >
                    Company Details:
                  </label>
                  <p className="fm darkGrey mb-3">
                    If you provided detailed background info of your company
                    during your onboarding process, this form will be pre-filled
                    for you else please provide a short description of your
                    company in the following form.
                  </p>
                  <textarea
                    className="form-control form-control-lg Dashboard__input fs-6 mb-4 w-100"
                    placeholder="What we’re looking for…"
                    id="text"
                    rows={6}
                    value={company_bio}
                    onChange={(value) => {
                      // alert(value)
                      handleChange("company_bio", value.target.value);
                    }}
                    // value={myContext.contextData.company_bio}
                  />
                </div>

                <div className="col-xxl-12 col-md-12">
                  <p className="fm darkGrey">
                    Tell us more about the role you are hiring for? What skills
                    are needed what day to day activities will be etc.
                  </p>
                  <label
                    htmlFor="text"
                    className="darkGrey fm mb-2 fw-semibold fs-6"
                  >
                    Role Description:
                  </label>
                  <textarea
                    className="form-control form-control-lg fs-6 mb-4"
                    placeholder="About the role…"
                    id="text"
                    rows={6}
                    value={job_role_text}
                    onChange={(value) => {
                      // alert(value)
                      handleChange("job_role_text", value.target.value);
                    }}
                    // value={myContext.contextData.job_role_text}
                  />
                </div>
                <div className="col-xxl-12 col-md-12 mt-4">
                  <label
                    htmlFor="text"
                    className="darkGrey fm fw-semibold fs-6 mb-3"
                  >
                    Role Key Expectations:
                  </label>
                  {/* <p className="fm darkGrey mb-2">
                    What will the candidate expect to do in the role?
                  </p> */}
                  <textarea
                    className="form-control form-control-lg fs-6  mb-4"
                    placeholder="What we’re looking for…"
                    id="text"
                    rows={6}
                    value={job_expectation}
                    onChange={(value) => {
                      // alert(value)
                      handleChange("job_expectation", value.target.value);
                    }}
                    // value={myContext.contextData.job_expectation}
                  />
                </div>

                <div className="col-xxl-12 col-md-12 mt-4">
                  <label
                    htmlFor="text"
                    className="darkGrey fm fw-semibold fs-6 mb-3"
                  >
                    Your Company Benefits:
                  </label>
                  {/* <p className="fm darkGrey mb-2">
                    What will the candidate expect to do in the role?
                  </p> */}
                  <textarea
                    className="form-control form-control-lg fs-6 mb-4"
                    placeholder="What we’re looking for…"
                    id="text"
                    rows={6}
                    value={company_benefits}
                    onChange={(value) => {
                      // alert(value)
                      handleChange("company_benefits", value.target.value);
                    }}
                    // value={myContext.contextData.job_expectation}
                  />
                </div>

                <div className="create__indexCore__chart d-flex mt-4 flex-sm-row flex-column align-items-center">
                  <div className="me-sm-4 me-0 mb-sm-0 mb-5 position-relative d-flex justify-content-center">
                    <div className="circular__text text-center darkGrey fm">
                      <p className="fm darkGrey my-0 text-16 fw-semibold">
                        IndexScore
                      </p>
                      <p className="fm darkGrey text-14 my-0 fw-light">
                        Select the minimum score:
                      </p>
                      <p className="fs-1 main mb-0 circular__text__num mt-3 fm fw-semibold">
                        {Math.round(value * 850)}
                      </p>
                    </div>
                    <CircularInput
                      className="Indexcore__progress"
                      value={value}
                      onChange={(value) => {
                        // alert(value)
                        handleChange(
                          "min_index_core",
                          Math.round(value * 850).toString()
                        );
                        setValue(value);
                      }}
                    >
                      <CircularTrack strokeWidth={8} stroke="#eee" />
                      <CircularThumb
                        r="10"
                        stroke="#A6DD50"
                        fill="#A6DD50"
                        strokeWidth={0}
                      />
                      <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                    </CircularInput>
                  </div>
                  <div className="me-sm-4 me-0 mb-sm-0 mb-3  position-relative d-flex justify-content-center">
                    <div className="circular__text text-center darkGrey fm">
                      <p className="fm darkGrey my-0 text-16 fw-semibold">
                        Purpose Alignment
                      </p>
                      <p className="fm darkGrey text-14 my-0 fw-light">
                        Select the minimum score:
                      </p>
                      <p className="fs-1 main mb-0 circular__text__num mt-3 fm fw-semibold">
                        {Math.round(value2 * 100)}%
                      </p>
                    </div>
                    <CircularInput
                      className="Indexcore__progress"
                      value={value2}
                      onChange={(value) => {
                        // alert(value)
                        handleChange(
                          "min_culture_fit_score",
                          Math.round(value2 * 100)
                        );
                        setValue2(value);
                      }}
                    >
                      <CircularTrack strokeWidth={8} stroke="#eee" />
                      <CircularThumb
                        r="10"
                        stroke="#A6DD50"
                        fill="#A6DD50"
                        strokeWidth={0}
                        className="circular-thumb"
                      />
                      <CircularProgress strokeWidth={8} stroke="#A6DD50" />
                    </CircularInput>
                  </div>
                </div>

                <div className="col-xxl-12 col-md-10">
                  <Space className="d-block">
                    <label
                      htmlFor="job"
                      className="darkGrey fm mb-2 fw-semibold fs-6"
                    >
                      Job Tags
                    </label>
                    <Input
                      ref={inputRef}
                      type="text"
                      size="small"
                      style={tagInputStyle}
                      value={inputValue}
                      onChange={handleInputChange}
                      onBlur={handleInputConfirm}
                      onPressEnter={handleInputConfirm}
                      className="w-100 tag__input form-control form-control-sm  mb-2"
                      id="job"
                    />
                    <Space className="d-flex">
                      {job_sectors &&
                        job_sectors.map((tag, index) => {
                          if (editInputIndex === index) {
                            return (
                              <div className="d-block">
                                <Input
                                  ref={editInputRef}
                                  key={tag}
                                  size="small"
                                  style={tagInputStyle}
                                  value={editInputValue}
                                  onChange={handleEditInputChange}
                                  onBlur={handleEditInputConfirm}
                                  onPressEnter={handleEditInputConfirm}
                                />
                              </div>
                            );
                          }
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag}
                              closable={index !== -1}
                              style={{
                                userSelect: "none",
                              }}
                              onClose={() => handleClose(tag)}
                            >
                              <span
                                onDoubleClick={(e) => {
                                  if (index !== -1) {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </span>
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })}
                    </Space>
                  </Space>
                </div>

                {/* <div className="col-xxl-8 col-md-10 mt-4">
                <p className="darkGrey fm fw-semibold fs-6 mt-4 mb-1">
                  Supporting documents:
                </p>
                <p className="fm darkGrey mb-4">
                  Video must be up to 2 minutes in length and under 100MB.
                </p>

                <Upload
                  name="logo"
                  action="/upload.do"
                  listType="document"
                  maxCount={1}
                  className="px-0"
                >
                  <Button className="Get__right__upload Role__upload mb-2 Get__left__bottom__info__content__para">
                    Upload your documents
                  </Button>
                  <small className="Get__right__upload__small ps-sm-3">
                    File formats: Word or PDF
                  </small>
                </Upload>
              </div> */}

                <div className="col-12 mb-3 mt-4">
                  <p className="darkGrey fm mb-0 fw-semibold fs-6">
                    Multiple Candidates:
                  </p>
                  <p className="darkGrey fm">
                    Are you looking to hire multiple candidates for this job role?
                  </p>
                  <div className="d-flex flex-wrap">
                  <Radio.Group >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  </div>
                </div>
                <div className="col-12 mb-3 mt-4">
                  <hr />
                </div>
                <div className="mt-4">
                  <p className="darkGrey fm mb-0 fw-semibold fs-6">
                    Add Potential Interviewers:
                  </p>
                  <p className="fm darkGrey mb-1">
                    Please add additional members by clicking here
                  </p>
                  <div className="d-flex flex-md-nowrap flex-wrap">
                    <Select
                      className="Login--Input mx-0 d-block Login--select"
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                        maxWidth: "650px",
                      }}
                      placeholder="Invited Members"
                      onSelect={handleDropChange}
                      onDeselect={handleRemoveDrop}
                      options={options}
                      value={
                        team_members &&
                        team_members.map((member) => member.value)
                      }
                    />
                    {/* <button className={`fm invite-button mt-2`}>
                    Invite People
                  </button> */}
                  </div>
                  {/* <div className="d-md-flex flex-wrap mt-2 align-items-center">
                  <Avatar.Group
                    maxCount={2}
                    maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
                    size="large"
                    className="me-2 mb-2"
                  >
                    <Tooltip title="Adu Opoki-Boahin" placement="top">
                      <Avatar />
                    </Tooltip>
                    <Tooltip title="Kaushik" placement="top">
                      <Avatar style={{ backgroundColor: "var(--darkGrey)" }} />
                    </Tooltip>
                    <Tooltip title="Ben" placement="top">
                      <Avatar style={{ backgroundColor: "var(--darkBlue)" }} />
                    </Tooltip>
                    <Tooltip title="Maryam" placement="top">
                      <Avatar style={{ backgroundColor: "var(--main)" }} />
                    </Tooltip>
                  </Avatar.Group>
                  <div className="mb-2">
                    <small className="fm midGrey">
                      Ben Smith, Stephen Holmes, Katie Lips, Maryam Rasheed &
                      Adu Opoki-Boahin
                    </small>
                  </div>
                </div> */}
                </div>
                <div className="col-12 mb-3 mt-4">
                  <button
                    className="custom-button-improve desc__applynow py-2 fm transition px-3 me-2 mb-2"
                    onClick={updateJobHandler}
                  >
                    {editJobLoader ? <Loader /> : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditJob;
