export const EthnicitiesList = [
{name:"Abruzzi"},
{name:"Acadian/Cajun"},
{name:"Aden"},
{name:"Afghan/Afghanistani"},
{name:"African"},
{name:"African American"},
{name:"African Islands"},
{name:"Afrikaner"},
{name:"Afro"},
{name:"Afro-American"},
{name:"Agikuyu/Kikuyu"},
{name:"Akan"},
{name:"Alabama"},
{name:"Aland Islander"},
{name:"Alaska"},
{name:"Alaska Athabascan"},
{name:"Alaska Native"},
{name:"Albanian"},
{name:"Aleut"},
{name:"Algerian"},
{name:"Alhucemas"},
{name:"Alsatian"},
{name:"Amalfi"},
{name:"Amara/Amhara"},
{name:"Amazigh/Imazighen/Berber"},
{name:"Amerasian"},
{name:"American Indian"},
{name:"American Indian and Alaska Native"},
{name:"American Indian-English-French"},
{name:"American Indian-English-German"},
{name:"American Indian-English-Irish"},
{name:"American Indian-German-Irish"},
{name:"American Samoan"},
{name:"American/United States"},
{name:"Amerindian/Indigena/Indio"},
{name:"Andalusian"},
{name:"Andaman Islander"},
{name:"Andhra Pradesh"},
{name:"Andorran"},
{name:"Anglo"},
{name:"Angolan"},
{name:"Anguilla Islander"},
{name:"Antiguan/Barbudan"},
{name:"Apache"},
{name:"Appalachian"},
{name:"Apulian"},
{name:"Arab/Arabic"},
{name:"Arapaho"},
{name:"Arawak"},
{name:"Argentinean/Argentine"},
{name:"Arizona"},
{name:"Arkansas"},
{name:"Armenian"},
{name:"Aruba Islander"},
{name:"Aryan"},
{name:"Asian"},
{name:"Assamese"},
{name:"Assiniboine Sioux"},
{name:"Assyrian/Chaldean/Syriac"},
{name:"Asturian"},
{name:"Australian"},
{name:"Australian Aborigine"},
{name:"Austrian"},
{name:"Azerbaijani"},
{name:"Azeri"},
{name:"Azorean"},
{name:"Baggara"},
{name:"Bahamian"},
{name:"Bahraini"},
{name:"Bajan/Barbadian"},
{name:"Balearic Islander"},
{name:"Baluchi"},
{name:"Bamar/Burman"},
{name:"Bangladeshi"},
{name:"Bantu"},
{name:"Barbadian"},
{name:"Bashkir"},
{name:"Basilicata"},
{name:"Basque"},
{name:"Bavarian"},
{name:"Bedouin"},
{name:"Belarusian/Belorussian"},
{name:"Belgian"},
{name:"Belizean"},
{name:"Belorussian"},
{name:"Bengali"},
{name:"Benin"},
{name:"Berber"},
{name:"Berliner"},
{name:"Bermudan"},
{name:"Bessarabian"},
{name:"Bhutanese"},
{name:"Bioko"},
{name:"Black"},
{name:"Black Thai"},
{name:"Black or African American"},
{name:"Blackfeet"},
{name:"Blackfeet Tribe of the Blackfeet Indian Reservation of Montana"},
{name:"Bohemian"},
{name:"Bolivian"},
{name:"Borneo"},
{name:"Bosniak"},
{name:"Bosnian/Herzegovinian"},
{name:"Botswana"},
{name:"Brazilian"},
{name:"Breton"},
{name:"British"},
{name:"British Isles"},
{name:"British Virgin Islander"},
{name:"British West Indian/Indies"},
{name:"Briton/British"},
{name:"Bucovina"},
{name:"Bulgarian"},
{name:"Burman"},
{name:"Burmese"},
{name:"Burundian"},
{name:"Cabo Verdean"},
{name:"Cajun"},
{name:"Calabrian"},
{name:"California"},
{name:"Californio"},
{name:"Cambodian"},
{name:"Cameroonian/Cameroon"},
{name:"Campbell Islander"},
{name:"Canadian"},
{name:"Canadian American Indian"},
{name:"Canadian and French American Indian"},
{name:"Canal Zone"},
{name:"Canary Islander"},
{name:"Cantonese"},
{name:"Cape Verdean"},
{name:"Caribbean"},
{name:"Caroline Islander"},
{name:"Carpathian"},
{name:"Carpatho Rusyn"},
{name:"Castillian"},
{name:"Catalonian"},
{name:"Cayenne"},
{name:"Cayman Islander"},
{name:"Celtic"},
{name:"Central African"},
{name:"Central African Republic"},
{name:"Central American"},
{name:"Central American Indian"},
{name:"Central European"},
{name:"Chadian"},
{name:"Chamolinian"},
{name:"Chamorro Islander"},
{name:"Channel Islander"},
{name:"Cherokee"},
{name:"Chevash"},
{name:"Cheyenne"},
{name:"Chicano/Chicana"},
{name:"Chickasaw"},
{name:"Chilean"},
{name:"Chinese"},
{name:"Chippewa"},
{name:"Chippewa-Cree Indians of the Rocky Boy's Reservation"},
{name:"Choctaw"},
{name:"Christmas Islander"},
{name:"Chumash"},
{name:"Chuukese"},
{name:"Colombian"},
{name:"Colorado"},
{name:"Colored"},
{name:"Colville"},
{name:"Comanche"},
{name:"Comanche Nation"},
{name:"Confederated Salish and Kootenai Tribes of the Flathead Nation"},
{name:"Confederated Tribes and Bands of the Yakama Nation"},
{name:"Confederated Tribes of the Colville Reservation"},
{name:"Congo-Brazzaville"},
{name:"Congolese/Congo"},
{name:"Connecticut"},
{name:"Cook Islander"},
{name:"Cornish"},
{name:"Corsican"},
{name:"Corsico Islander"},
{name:"Cossack"},
{name:"Costa Rican"},
{name:"Cree"},
{name:"Creek"},
{name:"Creole"},
{name:"Cretan"},
{name:"Crimean"},
{name:"Criollo/Criolla"},
{name:"Croatian/Croat"},
{name:"Crow"},
{name:"Crow Tribe of Montana"},
{name:"Cuban"},
{name:"Cuban American"},
{name:"Cycladic Islander"},
{name:"Cypriot"},
{name:"Czech"},
{name:"Czechoslovakian"},
{name:"Danish/Dane"},
{name:"Delaware"},
{name:"Dinka"},
{name:"District of Columbia"},
{name:"Djibouti"},
{name:"Dominica Islander"},
{name:"Dominican"},
{name:"Dutch"},
{name:"Dutch West Indian"},
{name:"Dutch West Indian/Indies"},
{name:"Dutch-French-Irish"},
{name:"Dutch-German-Irish"},
{name:"Dutch-Irish-Scotch"},
{name:"East African"},
{name:"East German"},
{name:"East Indian"},
{name:"East Indies"},
{name:"Eastern Archipelago"},
{name:"Eastern Cherokee"},
{name:"Eastern European"},
{name:"Eastern Tribes"},
{name:"Ecuadorian"},
{name:"Egyptian"},
{name:"Emilia Romagna"},
{name:"English"},
{name:"English-French-German"},
{name:"English-French-Irish"},
{name:"English-German-Irish"},
{name:"English-German-Swedish"},
{name:"English-Irish-Scotch"},
{name:"English-Scotch-Welsh"},
{name:"Equatorial Guinea"},
{name:"Eritrean"},
{name:"Eskimo"},
{name:"Estonian"},
{name:"Ethiopian"},
{name:"Eurasian"},
{name:"European"},
{name:"Faeroe Islander"},
{name:"Fijian"},
{name:"Filipino/Philippine"},
{name:"Finnish/Finn"},
{name:"Finno Ugrian"},
{name:"Fleming/Flemish"},
{name:"Florida"},
{name:"Formosan"},
{name:"French"},
{name:"French Basque"},
{name:"French Canadian"},
{name:"French Samoan"},
{name:"French West Indies"},
{name:"Frisian"},
{name:"Friulian"},
{name:"Fulani/Hausa"},
{name:"Fur"},
{name:"Gabonese"},
{name:"Gagauz"},
{name:"Galician"},
{name:"Gallego"},
{name:"Gambian"},
{name:"Gazan"},
{name:"Georgia"},
{name:"Georgian/Georgia CIS"},
{name:"German"},
{name:"German Russian"},
{name:"German-French-Irish"},
{name:"German-Irish-Italian"},
{name:"German-Irish-Scotch"},
{name:"German-Irish-Swedish"},
{name:"Germanic"},
{name:"Ghanaian/Ghanian"},
{name:"Gibraltan"},
{name:"Goanese"},
{name:"Gosei"},
{name:"Greek"},
{name:"Greek Cypriote"},
{name:"Greenlander"},
{name:"Grenadian"},
{name:"Gruziia"},
{name:"Guadeloupe Islander"},
{name:"Guamanian"},
{name:"Guatemalan"},
{name:"Guinea Bissau"},
{name:"Guinean"},
{name:"Gujarati"},
{name:"Guyanese"},
{name:"Guyanese/British Guiana"},
{name:"Guyanese/French Guiana"},
{name:"Haitian"},
{name:"Hall Islander"},
{name:"Hamburger"},
{name:"Hanoverian"},
{name:"Hausa"},
{name:"Hawaiian"},
{name:"Hawaiian/Native Hawaiian"},
{name:"Hessian"},
{name:"Hispanic"},
{name:"Hmong"},
{name:"Honduran"},
{name:"Hong Kong"},
{name:"Hopi"},
{name:"Hungarian"},
{name:"Husel"},
{name:"Ibo/Igbo"},
{name:"Icelander"},
{name:"Idaho"},
{name:"Ifni"},
{name:"Illinois"},
{name:"India"},
{name:"Indian"},
{name:"Indiana"},
{name:"Indochinese"},
{name:"Indonesian"},
{name:"Inuit"},
{name:"Inupiat"},
{name:"Inupiat Eskimo"},
{name:"Iowa"},
{name:"Iranian"},
{name:"Iraqi"},
{name:"Irish"},
{name:"Irish Scotch"},
{name:"Iroquois"},
{name:"Israeli"},
{name:"Issei"},
{name:"Italian"},
{name:"Ivoirian/Ivory Coast"},
{name:"Jamaican"},
{name:"Japanese"},
{name:"Javanese/Java/Jawa"},
{name:"Jebel Druse"},
{name:"Jewish/Jew"},
{name:"Jordanian"},
{name:"Kalmyk"},
{name:"Kansas"},
{name:"Kapinagamarangan"},
{name:"Karelian"},
{name:"Karnatakan"},
{name:"Kashmiri"},
{name:"Kashubian"},
{name:"Katu"},
{name:"Kazakh/Qazaq"},
{name:"Kazakhstani"},
{name:"Kentucky"},
{name:"Kenyan"},
{name:"Keralan"},
{name:"Keres"},
{name:"Kermadec Islander"},
{name:"Khmer"},
{name:"Kinh/Viet"},
{name:"Kiowa"},
{name:"Kirghiz"},
{name:"Kiribatese"},
{name:"Kittitian/Nevisian"},
{name:"Kitts/Nevis Islander"},
{name:"Korean"},
{name:"Kosraean"},
{name:"Kurdish/Kurd"},
{name:"Kuria Muria Islander"},
{name:"Kuwaiti"},
{name:"La Raza"},
{name:"Ladin"},
{name:"Lamotrekese"},
{name:"Lao Loum/Lowland Lao"},
{name:"Laotian/Lao"},
{name:"Lapp"},
{name:"Latakian"},
{name:"Latin"},
{name:"Latin American"},
{name:"Latin American Indian"},
{name:"Latino/Latina"},
{name:"Latvian"},
{name:"Lebanese"},
{name:"Lemko"},
{name:"Lesotho"},
{name:"Liberian"},
{name:"Libyan"},
{name:"Liechtensteiner"},
{name:"Ligurian"},
{name:"Lithuanian"},
{name:"Livonian"},
{name:"Lombardian"},
{name:"Lorrainian"},
{name:"Louisiana"},
{name:"Lubecker"},
{name:"Luiseno"},
{name:"Lumbee"},
{name:"Lumbee Indian"},
{name:"Luxemburger"},
{name:"Ma"},
{name:"Macao"},
{name:"Macedonian"},
{name:"Madagascan"},
{name:"Madeiran"},
{name:"Madhya Pradesh"},
{name:"Madrasi"},
{name:"Magyar"},
{name:"Maharashtran"},
{name:"Maine"},
{name:"Malawian"},
{name:"Malay"},
{name:"Malaysian"},
{name:"Maldivian"},
{name:"Malian"},
{name:"Maltese"},
{name:"Manchurian"},
{name:"Mandarin"},
{name:"Manx"},
{name:"Maori"},
{name:"Marches"},
{name:"Marshall Islander"},
{name:"Marshallese"},
{name:"Maryland"},
{name:"Massachusetts"},
{name:"Mauritanian"},
{name:"Mauritius"},
{name:"Melanesian"},
{name:"Melanesian Islander"},
{name:"Mende"},
{name:"Menominee Indian"},
{name:"Meo"},
{name:"Mesknetian"},
{name:"Mestizo"},
{name:"Mexican"},
{name:"Mexican American"},
{name:"Mexican American Indian"},
{name:"Mexican Indian"},
{name:"Mexican State"},
{name:"Mexicano/Mexicana"},
{name:"Miami"},
{name:"Michigan"},
{name:"Micmac"},
{name:"Micronesian"},
{name:"Middle Eastern"},
{name:"Midway Islander"},
{name:"Minnesota"},
{name:"Mississippi"},
{name:"Missouri"},
{name:"Mnong"},
{name:"Mohawk"},
{name:"Mokilese"},
{name:"Moldovan/Moldavian"},
{name:"Molise"},
{name:"Monegasque"},
{name:"Mongolian"},
{name:"Montagnard"},
{name:"Montana"},
{name:"Montenegrin"},
{name:"Montserrat Islander"},
{name:"Moor"},
{name:"Moravian"},
{name:"Mordovian"},
{name:"Moroccan"},
{name:"Mortlockese"},
{name:"Mozambican"},
{name:"Mulatto"},
{name:"Muscat"},
{name:"Muscogee (Creek) Nation"},
{name:"Muscovite"},
{name:"Mysore"},
{name:"Naga"},
{name:"Namanouito"},
{name:"Namibian"},
{name:"Natalian"},
{name:"Native American"},
{name:"Native Hawaiian"},
{name:"Nauruan"},
{name:"Navajo"},
{name:"Navajo Nation"},
{name:"Neapolitan"},
{name:"Nebraska"},
{name:"Nepalese/Nepali"},
{name:"Nevada"},
{name:"New Caledonian Islander"},
{name:"New Guinean"},
{name:"New Hampshire"},
{name:"New Jersey"},
{name:"New Mexico"},
{name:"New York"},
{name:"New Zealander"},
{name:"Newfoundland"},
{name:"Ngatikese"},
{name:"Nicaraguan"},
{name:"Niger"},
{name:"Nigerian"},
{name:"Nisei"},
{name:"Niuean"},
{name:"Nonwhite"},
{name:"Norfolk Islander"},
{name:"North African"},
{name:"North American"},
{name:"North Borneo"},
{name:"North Carolina"},
{name:"North Caucasian"},
{name:"North Caucasian Turkic"},
{name:"North Dakota"},
{name:"Northern European"},
{name:"Northern Irelander"},
{name:"Northern Marianas"},
{name:"Norwegian"},
{name:"Nova Scotian"},
{name:"Nuer"},
{name:"Nuevo Mexicano"},
{name:"Nukuoroan"},
{name:"Occitan"},
{name:"Oceania"},
{name:"Oglala Sioux"},
{name:"Ohio"},
{name:"Okinawan"},
{name:"Oklahoma"},
{name:"Oklahoma Choctaw"},
{name:"Omani"},
{name:"Oneida Nation of New York/Oneida"},
{name:"Oneida Tribe of Indians of Wisconsin"},
{name:"Oregon"},
{name:"Orissa"},
{name:"Oromo"},
{name:"Osage"},
{name:"Ossetian"},
{name:"Ottawa"},
{name:"Pacific Islander"},
{name:"Paiute"},
{name:"Pakistani"},
{name:"Palauan"},
{name:"Palestinian"},
{name:"Panamanian"},
{name:"Papuan"},
{name:"Paraguayan"},
{name:"Pashtun/Pathan"},
{name:"Pennsylvania"},
{name:"Pennsylvania German"},
{name:"Persian"},
{name:"Peruvian"},
{name:"Phoenix Islander"},
{name:"Piedmontese"},
{name:"Pima"},
{name:"Pingelapese"},
{name:"Polish/Pole"},
{name:"Polynesian"},
{name:"Pomeranian"},
{name:"Pomo"},
{name:"Ponapean"},
{name:"Pondicherry"},
{name:"Portuguese"},
{name:"Potawatomi"},
{name:"Providencia"},
{name:"Prussian"},
{name:"Pueblo"},
{name:"Puerto Rican"},
{name:"Puget Sound Salish"},
{name:"Puglia"},
{name:"Pulawatese"},
{name:"Punjabi"},
{name:"Qatar"},
{name:"Quechua"},
{name:"Rajasthani"},
{name:"Rhode Island"},
{name:"Rio de Oro"},
{name:"Rom"},
{name:"Romani"},
{name:"Romanian"},
{name:"Romansch"},
{name:"Romanscho"},
{name:"Rome"},
{name:"Rosebud Sioux"},
{name:"Rumanian"},
{name:"Russian"},
{name:"Rusyn"},
{name:"Ruthenian"},
{name:"Rwandan"},
{name:"Ryukyu Islander"},
{name:"Sac and Fox"},
{name:"Saint Lucian"},
{name:"Saipanese"},
{name:"Salvadoran"},
{name:"Samoan"},
{name:"San Andres"},
{name:"San Carlos Apache"},
{name:"San Marino"},
{name:"Sansei"},
{name:"Sardinian"},
{name:"Saudi Arabian/Saudi"},
{name:"Sault Ste. Marie Chippewa"},
{name:"Saxon"},
{name:"Scandinavian/Nordic"},
{name:"Scotch-Irish"},
{name:"Scottish"},
{name:"Seminole"},
{name:"Seneca"},
{name:"Senegalese"},
{name:"Serbian/Serb"},
{name:"Shan"},
{name:"Shawnee"},
{name:"Shona"},
{name:"Shoshone"},
{name:"Sicilian"},
{name:"Sierra Leonean"},
{name:"Sikkim"},
{name:"Silesian"},
{name:"Singaporean"},
{name:"Singhalese/Sinhalese"},
{name:"Sioux"},
{name:"Slavic/Slav"},
{name:"Slavonian"},
{name:"Slovak"},
{name:"Slovenian/Sloven"},
{name:"Solomon Islander"},
{name:"Somalian/Somali"},
{name:"Sorb/Wend"},
{name:"South African"},
{name:"South American"},
{name:"South American Indian"},
{name:"South Carolina"},
{name:"South Dakota"},
{name:"South Yemeni"},
{name:"Southern European"},
{name:"Southerner"},
{name:"Soviet Central Asia"},
{name:"Soviet Turkic"},
{name:"Soviet/Soviet Union"},
{name:"Spaniard"},
{name:"Spanish"},
{name:"Spanish American"},
{name:"Spanish American Indian"},
{name:"Spanish Basque"},
{name:"Sri Lankan"},
{name:"St. Christopher"},
{name:"St. Croix Islander"},
{name:"St. John Islander"},
{name:"St. Lucia Islander"},
{name:"St. Maarten Islander"},
{name:"St. Thomas Islander"},
{name:"St. Vincent Islander/Vincent-Grenadine Islander"},
{name:"St. Vincent and Grenadine Islander"},
{name:"Subsaharan African"},
{name:"Sudanese"},
{name:"Sudetenlander"},
{name:"Suisse"},
{name:"Sumatran"},
{name:"Surinamese"},
{name:"Surinamese/Dutch Guiana"},
{name:"Swaziland"},
{name:"Swedish/Swede"},
{name:"Swiss"},
{name:"Switzer"},
{name:"Syrian"},
{name:"Tadzhik"},
{name:"Tagalog"},
{name:"Tahitian"},
{name:"Taiwanese"},
{name:"Tamil"},
{name:"Tanganyikan"},
{name:"Tanzanian"},
{name:"Tarawa Islander"},
{name:"Tartar"},
{name:"Tasmanian"},
{name:"Tatar"},
{name:"Tejano/Tejana"},
{name:"Temne/Temme/Themne"},
{name:"Tennessee"},
{name:"Teton Sioux"},
{name:"Texas"},
{name:"Thai"},
{name:"Three Affiliated Tribes of North Dakota"},
{name:"Tibetan"},
{name:"Ticino"},
{name:"Tigrinya/Tigray/Tigraway"},
{name:"Tinian Islander"},
{name:"Tirolean"},
{name:"Tiv"},
{name:"Tlingit"},
{name:"Tlingit-Haida"},
{name:"Tobagonian"},
{name:"Togolese/Togo"},
{name:"Tohono O'Odham"},
{name:"Tokelauan"},
{name:"Tongan"},
{name:"Transjordan"},
{name:"Transylvanian"},
{name:"Trentino"},
{name:"Trieste"},
{name:"Trinidadian/Tobagonian"},
{name:"Trucial Oman"},
{name:"Truk Islander"},
{name:"Trust Territory of the Pacific Islands"},
{name:"Tsimshian"},
{name:"Tunisian"},
{name:"Turcoman"},
{name:"Turkestani"},
{name:"Turkish Cypriote"},
{name:"Turkish/Turk"},
{name:"Turks and Caicos Islander"},
{name:"Turtle Mountain"},
{name:"Tuscan"},
{name:"Tuvinian"},
{name:"U.S. Virgin Islander"},
{name:"Udmurt"},
{name:"Ugandan"},
{name:"Ukrainian"},
{name:"Ulithian"},
{name:"Umbrian"},
{name:"Union of South Africa"},
{name:"United Arab Emirates/Emirati"},
{name:"United Houma Nation"},
{name:"Upper Voltan"},
{name:"Uruguayan"},
{name:"Utah"},
{name:"Ute"},
{name:"Uttar Pradesh"},
{name:"Uzbek/Uzbeg"},
{name:"Uzbekistani"},
{name:"Valencian"},
{name:"Valle d'Aosta"},
{name:"Vanuatuan"},
{name:"Veddah"},
{name:"Venetian"},
{name:"Venezuelan"},
{name:"Vermont"},
{name:"Vietnamese"},
{name:"Virginia"},
{name:"Volga"},
{name:"Volta"},
{name:"Voytak"},
{name:"Wake Islander"},
{name:"Wallachian"},
{name:"Walloon"},
{name:"Washington"},
{name:"Welsh"},
{name:"West African"},
{name:"West Bank"},
{name:"West German"},
{name:"West Indian"},
{name:"West Virginia"},
{name:"Western European"},
{name:"Western Lao"},
{name:"Westphalian"},
{name:"White Mountain Apache"},
{name:"White/Caucasian"},
{name:"Windish"},
{name:"Wisconsin"},
{name:"Woleaian"},
{name:"Wyoming"},
{name:"Yakama"},
{name:"Yakut"},
{name:"Yap Islander"},
{name:"Yaqui"},
{name:"Yemeni"},
{name:"Yonsei"},
{name:"Yoruba"},
{name:"Yugoslavian"},
{name:"Yuman"},
{name:"Yupik/Yup'ik Eskimo"},
{name:"Zairian"},
{name:"Zambian"},
{name:"Zanzibar Islander"},
{name:"Zimbabwean"},
{name:"Zulu"},
{name:"Zuni"}
]