import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isEmail } from "../../../Utilities/utilities";
import { MyContext } from "../../../Utilities/MyContextProvider";
import { useSnackbar } from "notistack";
import { companies } from "../../../Utilities/Companies.js";
import { Select } from "antd";
import { getEnterpriseByID, updateProfileStatus } from "../../../services/auth";

const ESignup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const myContext = useContext(MyContext);
  const myData = myContext;
  const [enterprise, setEnterprise] = useState([]);
  const { Option, OptGroup } = Select;

  const { id } = useParams();
  const fetchEnterpriseId = async () => {
    try {

      var payloadGetEnterprise = {
        _id: id
      };
      console.log("response from payloadGetPro api----" + JSON.stringify(payloadGetEnterprise));
      const response = await getEnterpriseByID(payloadGetEnterprise);
      console.log("response from payloadGetPro api" + JSON.stringify(response.data.data[0]));
      setEnterprise(response.data.data[0]);

      myContext.updateContextData(response.data.data[0]);

      if (response.data.data[0]) {
        console.log("enterprise object variable updated" + JSON.stringify(response.data.data[0]));

        // update profile status
        var payloadEnterprise = {
          _id: id,
          usertype: 'enterprise',

        };
        console.log("response from payloadFavourite api" + JSON.stringify(payloadEnterprise));
        const responseUpdate = await updateProfileStatus(payloadEnterprise);
        if (responseUpdate.data.statusCode == 1) {

          // enqueueSnackbar(`Verified`, {
          //   variant: 'success',
          //   anchorOrigin: {
          //     vertical: 'bottom',
          //     horizontal: 'right'
          //   }
          // })
        }


        //



      }




    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  useEffect(() => {
    fetchEnterpriseId();
  }, []);





  const [state, setState] = useState({
    firstName: myContext.contextData.firstName,
    lastName: myContext.contextData.lastName,
    emailAddress: myContext.contextData.emailAddress,
    referenceSource: "",
    phoneNumber: myContext.contextData.phoneNumber,
    linkedinPublicUrl: myContext.contextData.linkedinPublicUrl,
    // password: '',
    // confirmPassword: '',
    loading: false,
    // invalidConfirmPassword: false,
    invalidEmail: false,
    // invalidPassword: false,
    invalidPhone: false,
    files: null,
    required: false,
    companyName: "",
    companyUrl: "",
  });
  const {
    loading,
    required,
    firstName,
    lastName,
    emailAddress,
    referenceSource,
    phoneNumber,
    linkedinPublicUrl,
    // password,
    // confirmPassword,
    // invalidConfirmPassword,
    invalidEmail,
    // invalidPassword,
    invalidPhone,
    files,
    companyName,
    companyUrl,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const disabled =
    // !firstName ||
    // !lastName ||
    // !emailAddress ||
    // !phoneNumber ||
    !companyName ||
    !companyUrl;
  const handleSignup = async () => {
    // alert();
    try {
      if (disabled) {
        handleChange("required", true);
        return;
      }

      // history.push('/signup/email-verification')
      handleChange("required", false);
      // handleChange('loading', true)
      // const usertype = localStorage.getItem('type')
      const payload = {
        // firstName,
        // lastName,
        // emailAddress,
        // referenceSource,
        // phoneNumber,
        // linkedinPublicUrl,
        companyName,
        companyUrl,
      };
      // payload.phoneNumber = "+" + payload.phoneNumber;

      // debugger

      myContext.updateContextData(payload);

      navigate(`/enterprise/signup/extra-info`);
      // const res = await signupUser(payload)
      // console.log('res', res)
      // if (res.data.statusCode === 200) {
      //   enqueueSnackbar(`Signup Successfully!`, {
      //     variant: 'success',
      //     anchorOrigin: {
      //       vertical: 'bottom',
      //       horizontal: 'right'
      //     }
      //   })
      //   // const body = {
      //   //   files,
      //   //   userID: res.data.result._id,
      //   //   userfiletype: 'expertresume'
      //   // }

      //   console.log('res.data.result', res.data.result)
      //   localStorage.setItem('userData', JSON.stringify(res.data.result))
      //   localStorage.setItem('_id', res.data.result._id)
      //   localStorage.setItem('email', emailAddress)

      // } else {
      //   handleChange('loading', false)
      //   console.log('ressss', res)
      //   enqueueSnackbar(`Error: ${res.data.name}`, {
      //     variant: 'error',
      //     anchorOrigin: {
      //       vertical: 'bottom',
      //       horizontal: 'right'
      //     }
      //   })
      // }
    } catch (error) {
      // handleChange('loading', false)
      // console.log('errssssssss', error)
      // enqueueSnackbar(`Error: ${error.message}`, {
      //   variant: 'error',
      //   anchorOrigin: {
      //     vertical: 'top',
      //     horizontal: 'right'
      //   }
      // })
    }
  };
  return (
    <>
      <style>
        {`
        .select-signup .ant-select-arrow{
          display:block !important;
        }
      `}
      </style>
      <div className="Signup main-padding">
        <div className="Signup__right p-xl-5 p-lg-4 py-lg-5 pb-4 h-100 mt-2">
          <div className="Signup__right--width">
            <h1 className="Get__left__content__heading Signup__heading mt-lg-5 mb-lg-5 mb-3">
              Welcome back!
            </h1>
            <p className="Get__left__content__para Signup__para mb-5">
              Now, it’s time to get your company set up on IndexScore, post jobs
              and start hiring in minutes.
            </p>
            <p className="Get__left__content__para Get__right__para fw-semibold mb-2">
              We already have the following:
            </p>

            <div className="row px-lg-2">
              <div className="col-sm-6 ps-lg-1 pe-sm-1">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="First name"
                  value={myContext.contextData.firstName || ""}
                  onChange={(value) =>
                    handleChange("firstName", value.target.value)
                  }
                />
                {/* {required && !firstName && (
                <div>
                  <small className="error__message">
                    {" "}
                    First Name is required!
                  </small>
                </div>
              )} */}
              </div>
              <div className="col-sm-6 pe-lg-1 ps-sm-2 mt-sm-0 mt-3">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Last name"
                  value={myContext.contextData.lastName || ""}
                  onChange={(value) =>
                    handleChange("lastName", value.target.value)
                  }
                />
                {/* {required && !lastName && (
                <div>
                  <small className="error__message">
                    {" "}
                    Surname is required!
                  </small>
                </div>
              )} */}
              </div>
            </div>
            <input
              type="email"
              className="form-control form-control-lg mt-3"
              placeholder="Email"
              readOnly
              value={myContext.contextData.emailAddress || ""}
              onChange={(value) =>
                handleChange("emailAddress", value.target.value)
              }
            />
            {/* {emailAddress && isEmail(emailAddress) && (
            <div>
              <small className="error__message">
                {" "}
                Email must be a valid email address !
              </small>
            </div>
          )}
          {required && !emailAddress && (
            <div>
              <small className="error__message">
                Email Address is required!
              </small>
            </div>
          )} */}
            <PhoneInput
              country={"us"}
              placeholder="Phone number"
              inputStyle={{ width: "100%" }}
              value={myContext.contextData.phoneNumber || phoneNumber}
              className="form-control form-control-lg mt-3"
              onChange={(value) => handleChange("phoneNumber", value)}
            />
            {/* <input type="number" placeholder="Phone Number" /> */}
            {/* {invalidPhone && (
            <div>
              <small className="error__message">
                Phone Number is not valid !
              </small>
            </div>
          )}
          {required && !phoneNumber && (
            <div>
              <small className="error__message">
                Phone Number is required!
              </small>
            </div>
          )} */}
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.name ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.name ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.name ?? "").toLowerCase())
              }
              className="Login--Input Login--select2 mt-3 select-signup mb-2 mx-0 d-block w-100"
              size="small"
              placeholder="Company Name"
              onChange={(value) => {
                // alert(value)
                // handleChange('govtIdCountryName', value);
                handleChange("companyName", value);
              }}
            >
              <OptGroup>
                {companies.map((item) => (
                  <Option
                    value={item.name}
                    label={item.name.toLowerCase()}
                    key={item.name}
                  >
                    {item.name}
                  </Option>
                ))}
              </OptGroup>
            </Select>
            {required && !companyName && (
              <div>
                <small className="error__message">
                  {" "}
                  Company Name is required!
                </small>
              </div>
            )}
            <input
              type="url"
              className="form-control form-control-lg mt-3"
              placeholder="Company URL"
              onChange={(value) => handleChange("companyUrl", value.target.value)}
            />
            {required && !companyUrl && (
              <div>
                <small className="error__message">
                  {" "}
                  Company URL is required!
                </small>
              </div>
            )}
            {/* <Link
              to={"/enterprise/signup/extra-info"}
              className="text-decoration-none text-white"
            > */}
            <button
              type="submit"
              onClick={() => handleSignup()}
              className={`Get__left__content__para Get__right__button Get__right__button--green mt-5`}
            >
              Continue
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ESignup;
